<template>
  <div>
    <b-row class="breadcumb-location" v-if="!slidebar && list.length > 0">
      <b-col>
        <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="d-inline">
          <CustomButton type="excel" />
        </download-csv>
      </b-col>
    </b-row>
    <table class="table" style="table-layout: fixed; font-size: 14px">
      <thead>
        <tr class="table-search">
          <td :colspan="slidebar ? 3 : 3" :class="{ 'pb-0': detailedSearch }">
            <b-form-input
              v-model="search.search"
              class="search"
              v-on:keyup.enter="loadList"
              placeholder="Leita eftir bekk"
              ref="focusInput"
            />
          </td>
          <td colspan="1" :class="{ 'pb-0': detailedSearch }">
            <b-btn variant="primary" class="w-100" style="margin-top: 25px" @click="loadList">Leita</b-btn>
            <div class="cursor-pointer text-right pt-2" @click="detailedSearch = !detailedSearch">
              <span v-if="detailedSearch"><i class="fa fa-angle-up"></i> Fela ítarleit</span>
              <span v-else><i class="fa fa-angle-up"></i> Sýna ítarleit</span>
            </div>
          </td>
        </tr>
        <tr v-if="detailedSearch" class="table-detail-search">
          <td colspan="2">
            <b-form-group label="Kennslutímabil" label-for="term">
              <v-select id="term" name="term" v-model="search.term" :options="terms" :clearable="false"> </v-select>
            </b-form-group>
          </td>
          <td colspan="2">
            <b-form-group label="Ár" label-for="year">
              <v-select id="year" name="year" v-model="search.year" :options="years" :clearable="true"> </v-select>
            </b-form-group>
          </td>
        </tr>
        <tr v-if="firsttime">
          <th><LinkOrder title="Heiti bekkjar" :filteredList="list" :list="list" column="heiti" /> ({{ list.length }})</th>
          <th>
            <LinkOrder title="Skólaár" :filteredList="list" :list="list" column="onn_heiti" />
          </th>
          <th>
            <LinkOrder title="Umsjónarkennari" :filteredList="list" :list="list" column="nafn" />
          </th>
          <th>
            <LinkOrder title="Ár" :filteredList="list" :list="list" column="ar_heiti" />
          </th>
        </tr>
      </thead>
      <tbody>
        <TableRowLoader v-if="loading && firsttime" :colspan="slidebar ? 4 : 4" :center="true" />
        <TableRowEmpty v-if="!loading && firsttime && this.list.length === 0" :colspan="slidebar ? 4 : 4" :center="true" />
        <TableRowSearchForResults v-if="!firsttime" :colspan="slidebar ? 4 : 4" :center="true" />
        <tr v-for="item in getPaginatedList" :key="item.bekkir_id">
          <td>
            <b-link @click="changeClass(item)" title="Velja bekk" v-b-tooltip>
              {{ item.heiti }}
            </b-link>
          </td>
          <td>{{ item.onn_heiti }}</td>
          <td>{{ item.umsjonarkennari }}</td>
          <td>{{ item.ar_heiti }}</td>
        </tr>
      </tbody>
    </table>

    <Pagination
      :currentPage="currentPage"
      :totalRows="list.length"
      :perPage="perPage"
      v-if="list.length > 25"
      @change-page="changePage"
      @change-limit="changeLimit"
    />
  </div>
</template>

<script>
import moment from 'moment';
import classes from '@/api/classes';
import terms from '@/api/terms';
import { mapGetters, mapActions } from 'vuex';

import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import TableRowSearchForResults from '@/components/common/TableRowSearchForResults.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';
import Pagination from '@/components/common/Pagination.vue';
import CustomButton from '@/components/common/CustomButton.vue';

export default {
  name: 'module-search',
  components: {
    TableRowLoader,
    TableRowEmpty,
    TableRowSearchForResults,
    LinkOrder,
    Pagination,
    CustomButton,
  },
  props: ['slidebar'],
  computed: {
    getExcelData() {
      const newList = [
        [],
        ['Bekkir'],
        [],
        ['Áfangi', 'Heiti', 'Einingar', 'Undirskóli', 'Deild'],
        ...this.list.map(x => [x.namsgrein_afanganr, x.afanga_heiti, x.einingar, x.undirsk_audk, x.deild]),
      ];
      return newList;
    },
    getExcelFileName() {
      return `bekkir_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    rows() {
      return this.list.length;
    },
    getPaginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.list.slice(start, end);
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      perPage: 25,
      currentPage: 1,
      detailedSearch: false,
      firsttime: false,
      loading: false,
      list: [],
      terms: [],
      departments: [],
      divisions: [],
      courses: [],
      search: {
        search: '',
        active: 1,
        showDepartment: 1,
        term: '',
        department: '',
        division: '',
        course: '',
      },
      showTimetableSlidebar: false,
      selectedItem: {},
    };
  },
  methods: {
    async loadList() {
      try {
        this.firsttime = true;
        this.loading = true;
        const search = {
          search: this.search.search,
          term: this.search.term ? this.search.term.onn_id : '',
          year: this.search.year ? this.search.year.skolaar_skola_id : '',
        };
        const response = await classes.getClassList(search);
        this.list = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadTerms() {
      try {
        const response = await terms.getTerms();
        this.terms = response.data.items.map(x => ({
          ...x,
          label: `${x.undirsk_audk} - ${x.onn_heiti}`,
        }));
        this.search.term = this.terms.find(x => x.is_default === 1);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadYears() {
      try {
        const response = await classes.getSchoolYears();
        this.years = response.data.items.map(x => ({
          ...x,
          label: `${x.heiti}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    changeClass(item) {
      this.$emit('changeClass', {
        id: item.bekkir_id,
        term: this.search.term,
      });
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadTerms();
    this.loadYears();
    this.$nextTick(() => {
      if (this.$refs.focusInput) {
        this.$refs.focusInput.focus();
      }
    });
  },
};
</script>
