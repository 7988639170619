<template>
  <Slidebar :autoClose="false" @closeMe="closeSlidebar">
    <h4>Breyta tengingu áfanga við braut</h4>
    <strong>Braut</strong>
    <div>{{ item.heiti }}</div>
    <strong>Áfangi</strong>
    <div>{{ item.namsgrein_afanganr }}</div>

    <b-form @submit.prevent="validateBeforeSubmit" novalidate class="mt-3">
      <b-form-group :state="submitted && errors.has('onn_nr') ? false : ''" :invalid-feedback="errors.first('onn_nr')">
        <label for="onn_nr"
          >Tímabil
          <i
            class="fa fa-info-circle"
            title="Ef taka á áfangann á ákveðnu kennslutímabili þarf að fylla út númer tímabilsins sem á að taka áfangann á hérna.
            Ef nemandi á að taka áfangann á öðru kennslutímabili þá er sett 2."
            v-b-tooltip
          ></i>
        </label>
        <b-form-input type="number" id="onn_nr" name="onn_nr" v-model="form.onn_nr" data-vv-as="önn" min="0" step="1" style="width: 100px">
        </b-form-input>
      </b-form-group>
      <b-form-group :state="submitted && errors.has('forgangsrodun') ? false : ''" :invalid-feedback="errors.first('forgangsrodun')">
        <label for="forgangsrodun"
          >Forgangsröðun
          <i
            class="fa fa-info-circle"
            title="Ef áfangi á að vera í forgangi þegar það er sjálfvirkt lesið í feril er hægt að setja inn tölu hér.
            Það er síðan raðað eftir tölunum. Þannig sá sem er í mestum forgang væri með 1, næsti 2 osfrv.
            Ef forgangur skiptir ekki máli þá er þetta skilið eftir tómt."
            v-b-tooltip
          ></i>
        </label>
        <b-form-input
          type="number"
          id="forgangsrodun"
          name="forgangsrodun"
          v-model="form.forgangsrodun"
          data-vv-as="forgangsröðun"
          min="0"
          step="1"
          style="width: 100px"
        >
        </b-form-input>
      </b-form-group>
      <b-form-group
        label-for="flokkun"
        label="Flokkun"
        :state="submitted && errors.has('flokkun') ? false : ''"
        :invalid-feedback="errors.first('flokkun')"
        class="mb-3"
      >
        <v-select
          id="flokkun"
          name="flokkun"
          v-model="form.uppbygging"
          :clearable="false"
          v-validate="'required'"
          :state="submitted && errors.has('flokkun') ? false : ''"
          :options="structures"
          data-vv-as="flokkun"
          label="heiti"
          disabled
        >
        </v-select>
      </b-form-group>

      <b-btn variant="primary" @click="validateBeforeSubmit()">Vista</b-btn>
      <b-btn variant="danger" class="float-right" @click="deleteModule()">Eyða</b-btn>
    </b-form>
  </Slidebar>
</template>

<script>
import curriculum from '@/api/curriculum';

import Slidebar from '@/components/common/Slidebar.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'course-module-slidebar',
  components: {
    Slidebar,
  },
  props: ['id'],
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_afangar');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('namskra_afangar');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      submitted: false,
      loading: false,
      item: '',
      structures: [],
      form: {
        onn_nr: '',
        forgangsrodun: '',
        uppbygging: '',
      },
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async loadItem() {
      try {
        this.loading = true;
        const response = await curriculum.getModuleCourseConnection(this.id);
        this.item = response.data;
        this.form.onn_nr = this.item.onn_nr;
        this.form.forgangsrodun = this.item.forgangsrodun;
        this.loadStructure();
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadStructure() {
      try {
        const response = await curriculum.getCourseStructure(this.item.braut_id);
        this.structures = response.data.items;
        this.form.uppbygging = this.structures.find(x => x.braut_uppbygg_id === this.item.braut_uppbygg_id);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        const item = {
          onn_nr: this.form.onn_nr,
          forgangsrodun: this.form.forgangsrodun,
          braut_uppbygg_id: this.form.uppbygging.braut_uppbygg_id,
        };

        await curriculum.updateCourseModule(this.id, item);
        this.displaySuccess('Tenging uppfærð');
        this.$emit('closeAndReloadSlidebar');
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);

        if (e.response && e.response.data && e.response.data.error) {
          this.displayError(e.response.data.error);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    async deleteModule() {
      try {
        await curriculum.updateCourseModule(this.id, { delete: 1 });
        this.displaySuccess('Tengingu eytt');
        this.$emit('closeAndReloadSlidebar');
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);

        if (e.response && e.response.data && e.response.data.error) {
          this.displayError(e.response.data.error);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadItem();
  },
};
</script>

<style lang="scss"></style>
