<template>
  <Slidebar @closeMe="closeSlidebar">
    <h4 v-if="!creating">{{ item.stofa_heiti }} - {{ item.stofa_eink }}</h4>
    <h4 v-else>Stofna stofu</h4>
    <br />
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <table class="table table-fixed createClassroomTable" :class="{ 'less-padding': this.edit }">
        <tr>
          <td style="width: 150px">Númer stofu*</td>
          <td v-if="!edit">{{ item.stofa_eink }}</td>
          <td v-show="edit">
            <b-form-group
              label-for="stofa_eink"
              :state="submitted && errors.has('stofa_eink') ? false : ''"
              :invalid-feedback="errors.first('stofa_eink')"
            >
              <b-form-input
                id="stofa_eink"
                name="stofa_eink"
                type="text"
                v-model="form.stofa_eink"
                @change="validateCode()"
                :state="(submitted && errors.has('stofa_eink')) || classroomExists ? false : ''"
                maxLength="20"
                v-validate="'required'"
                data-vv-as="einkenni"
              ></b-form-input>
            </b-form-group>
            <div class="text-danger" style="font-weight: bold; font-size: 13px" v-if="classroomExists">
              Ath. það er nú þegar til stofa með þessu einkenni. Vinsamlegast veljið annað.
            </div>
          </td>
        </tr>
        <tr>
          <td>Heiti stofu</td>
          <td v-if="!edit">{{ item.stofa_heiti }}</td>
          <td v-show="edit">
            <b-form-group
              label-for="stofa_heiti"
              :state="submitted && errors.has('stofa_heiti') ? false : ''"
              :invalid-feedback="errors.first('stofa_heiti')"
            >
              <b-form-input
                id="stofa_heiti"
                name="stofa_heiti"
                type="text"
                v-model="form.stofa_heiti"
                maxLength="100"
                :state="submitted && errors.has('stofa_heiti') ? false : ''"
                data-vv-as="heiti"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Heiti byggingar*</td>
          <td v-if="!edit">{{ item.bygging_heiti }}</td>
          <td v-show="edit">
            <b-form-group
              label-for="bygging"
              :state="submitted && errors.has('bygging') ? false : ''"
              :invalid-feedback="errors.first('bygging')"
            >
              <v-select
                id="bygging"
                name="bygging"
                v-model="form.bygging"
                :state="submitted && errors.has('bygging') ? false : ''"
                v-validate="'required'"
                :options="buildings"
                label="name"
                data-vv-as="bygging"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Tegund stofu*</td>
          <td v-if="!edit">
            {{ item.teg_stofu }}
          </td>
          <td v-show="edit">
            <b-form-group
              label-for="tegund"
              :state="submitted && errors.has('tegund') ? false : ''"
              :invalid-feedback="errors.first('tegund')"
            >
              <v-select
                id="tegund"
                name="tegund"
                v-model="form.tegund"
                :state="submitted && errors.has('tegund') ? false : ''"
                v-validate="'required'"
                :options="types"
                label="heiti"
                data-vv-as="tegund"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Sérkenni</td>
          <td v-if="!edit">{{ item.serkenni }}</td>
          <td v-show="edit">
            <b-form-group
              label-for="serkenni"
              :state="submitted && errors.has('serkenni') ? false : ''"
              :invalid-feedback="errors.first('serkenni')"
            >
              <b-form-input
                id="serkenni"
                name="serkenni"
                type="text"
                v-model="form.serkenni"
                :state="submitted && errors.has('serkenni') ? false : ''"
                maxLength="20"
                data-vv-as="sérkenni"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Fjöldi</td>
          <td v-if="!edit">{{ item.fjoldi }}</td>
          <td v-show="edit">
            <b-form-group
              label-for="fjoldi"
              :state="submitted && errors.has('fjoldi') ? false : ''"
              :invalid-feedback="errors.first('fjoldi')"
            >
              <b-form-input
                id="fjoldi"
                name="fjoldi"
                type="number"
                v-model="form.fjoldi"
                :state="submitted && errors.has('fjoldi') ? false : ''"
                v-validate="'min_value:0'"
                min="0"
                data-vv-as="fjöldi"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Frávik</td>
          <td v-if="!edit">{{ item.fravik }}</td>
          <td v-show="edit">
            <b-form-group
              label-for="fravik"
              :state="submitted && errors.has('fravik') ? false : ''"
              :invalid-feedback="errors.first('fravik')"
            >
              <b-form-input
                id="fravik"
                name="fravik"
                type="number"
                v-model="form.fravik"
                :state="submitted && errors.has('fravik') ? false : ''"
                v-validate="'min_value:0'"
                min="0"
                data-vv-as="frávik"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Fj. í prófi</td>
          <td v-if="!edit">{{ item.fjoldi_i_profi }}</td>
          <td v-show="edit">
            <b-form-group
              label-for="fjoldi_i_profi"
              :state="submitted && errors.has('fjoldi_i_profi') ? false : ''"
              :invalid-feedback="errors.first('fjoldi_i_profi')"
            >
              <b-form-input
                id="fjoldi_i_profi"
                name="fjoldi_i_profi"
                type="number"
                v-model="form.fjoldi_i_profi"
                :state="submitted && errors.has('fjoldi_i_profi') ? false : ''"
                v-validate="'min_value:0'"
                min="0"
                data-vv-as="fjöldi í prófi"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Lykill stofu</td>
          <td v-if="!edit">{{ item.lykill_stofu }}</td>
          <td v-show="edit">
            <b-form-group
              label-for="lykill_stofu"
              :state="submitted && errors.has('lykill_stofu') ? false : ''"
              :invalid-feedback="errors.first('lykill_stofu')"
            >
              <b-form-input
                id="lykill_stofu"
                name="lykill_stofu"
                type="text"
                v-model="form.lykill_stofu"
                :state="submitted && errors.has('lykill_stofu') ? false : ''"
                maxLength="20"
                data-vv-as="lykill stofu"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Sími stofu</td>
          <td v-if="!edit">{{ item.simi_innanh }}</td>
          <td v-else>
            <b-form-group
              label-for="simi_innanh"
              :state="submitted && errors.has('simi_innanh') ? false : ''"
              :invalid-feedback="errors.first('simi_innanh')"
            >
              <b-form-input
                id="simi_innanh"
                name="simi_innanh"
                type="text"
                v-model="form.simi_innanh"
                :state="submitted && errors.has('simi_innanh') ? false : ''"
                maxLength="7"
                data-vv-as="sími"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Hjólastólaðgengi</td>
          <td v-if="!edit">
            <span v-if="item.fatladir === 1"> Já </span>
            <span v-else> Nei </span>
          </td>
          <td v-else>
            <b-form-group
              label-for="fatladir"
              :state="submitted && errors.has('fatladir') ? false : ''"
              :invalid-feedback="errors.first('fatladir')"
            >
              <b-form-checkbox id="fatladir" name="fatladir" v-model="form.fatladir"></b-form-checkbox>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Má starfsmaður panta</td>
          <td v-if="!edit">
            <span v-if="item.ma_panta === 1"> Já </span>
            <span v-else> Nei </span>
          </td>
          <td v-show="edit">
            <b-form-group
              label-for="ma_panta"
              :state="submitted && errors.has('ma_panta') ? false : ''"
              :invalid-feedback="errors.first('ma_panta')"
            >
              <b-form-checkbox id="ma_panta" name="ma_panta" v-model="form.ma_panta"></b-form-checkbox>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Virk</td>
          <td v-if="!edit">
            <span v-if="item.virk === 1"> Já </span>
            <span v-else> Nei </span>
          </td>
          <td v-show="edit">
            <b-form-group label-for="virk" :state="submitted && errors.has('virk') ? false : ''" :invalid-feedback="errors.first('virk')">
              <b-form-checkbox id="virk" name="virk" v-model="form.virk"></b-form-checkbox>
            </b-form-group>
          </td>
        </tr>
        <tr v-if="creating && !item.stofa_id">
          <td>Stofa í boði</td>
          <td>
            <b-form-group
              class="d-inline-block"
              label-for="i_bodi"
              :state="submitted && errors.has('i_bodi') ? false : ''"
              :invalid-feedback="errors.first('i_bodi')"
            >
              <b-form-checkbox id="i_bodi" name="i_bodi" v-model="form.i_bodi"></b-form-checkbox>
            </b-form-group>
            <i
              class="fa fa-info-circle"
              title="Setja stofuna í boði á virkum kennslutímabilum og kennslutímabilum í framtíð þar sem stofur eru nú þegar í boði."
              v-b-tooltip
            ></i>
          </td>
        </tr>
      </table>
      <div v-if="!edit">
        <b-btn variant="primary" @click="editItem" v-if="canEdit">Breyta</b-btn>
        <b-btn variant="danger" class="float-right" @click="deleteClassroom" v-if="canEdit && item.stofa_id">Eyða</b-btn>
      </div>
      <div v-else>
        <b-btn variant="primary" class="mr-2" @click="validateBeforeSubmit">Vista</b-btn>
        <b-btn variant="dark" @click="closeSlidebar" v-if="creating">Hætta við</b-btn>
        <b-btn variant="dark" @click="stopEdit" v-else>Hætta við</b-btn>
        <b-btn variant="danger" class="float-right" @click="deleteClassroom" v-if="canEdit && item.stofa_id">Eyða</b-btn>
      </div>
    </b-form>
  </Slidebar>
</template>

<script>
import types from '@/api/types';
import structure from '@/api/structure';
import { mapActions, mapGetters } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';

export default {
  name: 'classroom-slide-bar',
  props: ['item', 'editing', 'creating'],
  components: {
    Slidebar,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      classroomExists: false,
      loading: {
        building: false,
        type: false,
      },
      edit: false,
      submitted: false,
      form: {},
      buildings: [],
      types: [],
    };
  },
  methods: {
    closeSlidebar() {
      this.stopEdit();
      this.$emit('closeSlidebar');
    },
    editItem() {
      this.edit = true;
      this.form = JSON.parse(JSON.stringify(this.item));
      this.form.bygging = this.buildings.find(x => x.building_id === this.form.bygging_id);
      this.form.tegund = this.types.find(x => x.teg_stofu === this.form.teg_stofu_id);
      this.form.fatladir = this.form.fatladir === 1;
      this.form.virk = this.form.virk === 1;
      this.form.ma_panta = this.form.ma_panta === 1;
    },
    stopEdit() {
      this.edit = false;
      this.form = {};
    },
    async validateCode() {
      this.classroomExists = false;
      try {
        const response = await structure.checkClassroomCodeExists({ id: this.item.stofa_id, code: this.form.stofa_eink });
        this.classroomExists = response.data.already_exists > 0;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadData() {
      await this.loadBuildings();
      await this.loadTypes();
      if (this.editing) {
        this.editItem();
      } else if (this.creating) {
        this.edit = true;
        this.form = {
          stofa_heiti: '',
          stofa_eink: '',
          bygging: this.buildings.find(x => x.building_id === this.creating),
          tegund: '',
          serkenni: '',
          fjoldi: '',
          fravik: '',
          fjoldi_i_profi: '',
          lykill_stofu: '',
          simi_innanh: '',
          fatladir: false,
          virk: true,
          ma_panta: true,
          i_bodi: false,
        };
      }
    },
    async loadBuildings() {
      this.loading.building = true;
      try {
        const response = await structure.getBuildingList();
        this.buildings = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.building = false;
      }
    },
    async loadTypes() {
      this.loading.type = true;
      try {
        const response = await types.getClassroomList();
        this.types = response.data.items;
        if (this.form.type) {
          this.form.type = this.types.find(x => x.teg_stofu === this.form.type);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.type = false;
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.saving = true;
          const data = {
            name: this.form.stofa_heiti,
            code: this.form.stofa_eink,
            building_id: this.form.bygging.building_id,
            type_id: this.form.tegund.teg_stofu,
            characteristics: this.form.serkenni,
            count: this.form.fjoldi,
            count_deviations: this.form.fravik,
            count_exam: this.form.fjoldi_i_profi,
            key: this.form.lykill_stofu,
            tel: this.form.simi_innanh,
            accessible: this.form.fatladir ? 1 : 0,
            active: this.form.virk ? 1 : 0,
            can_order: this.form.ma_panta ? 1 : 0,
            make_available: this.form.i_bodi ? 1 : 0,
          };
          if (this.item.stofa_id) {
            await structure.updateClassroom(this.item.stofa_id, data);
            this.displaySuccess(`Stofu ${this.form.stofa_heiti} hefur verið breytt.`);
          } else {
            await structure.createClassroom(data);
            this.displaySuccess(`Stofa ${this.form.stofa_heiti} hefur verið stofnuð.`);
          }
          this.closeSlidebar();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.saving = false;
      }
    },
    async deleteClassroom() {
      try {
        const conf = confirm(`Ert þú viss um að eyða ${this.item.stofa_heiti} - ${this.item.stofa_eink}?`); // eslint-disable-line
        if (conf) {
          await structure.updateClassroom(this.item.stofa_id, { delete: 1 });

          this.displaySuccess(`${this.item.stofa_eink} hefur verið eytt.`);
          this.closeSlidebar();
        }
      } catch (e) {
        // Aðgerð mistókst.
        this.$log.debug(e);
        if (e.response && e.response.data && e.response.data.error) {
          this.displayError(e.response.data.error);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    item() {
      this.stopEdit();
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<style lang="scss"></style>
