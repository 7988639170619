<template>
  <div>
    <b-row>
      <b-col>
        <!--EventType v-if="tabIndex === 'event'" /-->
        <CommentType v-if="tabIndex === 'comment'" />
        <ModuleType v-if="tabIndex === 'module'" />
        <AttendanceType v-if="tabIndex === 'attendance'" />
        <AbsenceType v-if="tabIndex === 'absence'" />
        <StaffAbsenceType v-if="tabIndex === 'staffAbsence'" />
        <GradeType v-if="tabIndex === 'grade'" />
        <GradeScaleType v-if="tabIndex === 'gradeScale'" />
        <PriceType v-if="tabIndex === 'price'" />
        <BookType v-if="tabIndex === 'bookType'" />
        <ExamPlaces v-if="tabIndex === 'examPlaces'" />
        <StaffType v-if="tabIndex === 'staff'" />
        <ClassroomType v-if="tabIndex === 'classroom'" />
        <ProcessingStatusType v-if="tabIndex === 'processingStatus'" />
        <TermType v-if="tabIndex === 'term'" />
        <SpecialResourceType v-if="tabIndex === 'specialResource'" />
        <BehaviorType v-if="tabIndex === 'behavior'" />
      </b-col>
      <b-col cols="2">
        <div class="right-side-nav">
          <b-nav vertical>
            <!--b-nav-item @click.prevent="changeTab('event')" :active="tabIndex === 'event'">Atburður</b-nav-item-->
            <b-nav-item @click.prevent="changeTab('comment')" :active="tabIndex === 'comment'">Athugasemdir</b-nav-item>
            <b-nav-item @click.prevent="changeTab('module')" :active="tabIndex === 'module'">Áfangi</b-nav-item>
            <b-nav-item
              @click.prevent="changeTab('behavior')"
              :active="tabIndex === 'behavior'"
              v-if="schoolHasSettings('nota_hegdunarskraningu')"
              >Ástundun og hegðun</b-nav-item
            >
            <b-nav-item @click.prevent="changeTab('attendance')" :active="tabIndex === 'attendance'">Fjarvistir</b-nav-item>
            <b-nav-item @click.prevent="changeTab('absence')" :active="tabIndex === 'absence'">Forföll</b-nav-item>
            <b-nav-item @click.prevent="changeTab('staffAbsence')" :active="tabIndex === 'staffAbsence'">Forföll stm</b-nav-item>
            <b-nav-item @click.prevent="changeTab('gradeScale')" :active="tabIndex === 'gradeScale'">Einkunnaskali</b-nav-item>
            <b-nav-item @click.prevent="changeTab('grade')" :active="tabIndex === 'grade'">Einkunnir</b-nav-item>
            <b-nav-item @click.prevent="changeTab('price')" :active="tabIndex === 'price'">Gjöld</b-nav-item>
            <b-nav-item @click.prevent="changeTab('term')" :active="tabIndex === 'term'">Kennslutímabil</b-nav-item>
            <b-nav-item @click.prevent="changeTab('bookType')" :active="tabIndex === 'bookType'">Námsgögn</b-nav-item>
            <b-nav-item @click.prevent="changeTab('examPlaces')" :active="tabIndex === 'examPlaces'">Prófstaðir</b-nav-item>
            <b-nav-item @click.prevent="changeTab('specialResource')" :active="tabIndex === 'specialResource'">Sérúrræði</b-nav-item>
            <b-nav-item @click.prevent="changeTab('staff')" :active="tabIndex === 'staff'">Starfsmaður</b-nav-item>
            <b-nav-item @click.prevent="changeTab('classroom')" :active="tabIndex === 'classroom'">Stofa</b-nav-item>
            <b-nav-item @click.prevent="changeTab('processingStatus')" :active="tabIndex === 'processingStatus'"
              >Vinnslust. nema</b-nav-item
            >
          </b-nav>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import ClassroomType from '@/components/school/school/types/ClassroomType.vue';
import ExamPlaces from '@/components/school/school/types/ExamPlaces.vue';
import AbsenceType from '@/components/school/school/types/AbsenceType.vue';
import AttendanceType from '@/components/school/school/types/AttendanceType.vue';
import CommentType from '@/components/school/school/types/CommentType.vue';
// import EventType from '@/components/school/school/types/EventType.vue';
import ModuleType from '@/components/school/school/types/ModuleType.vue';
import ProcessingStatusType from '@/components/school/school/types/ProcessingStatusType.vue';
import StaffAbsenceType from '@/components/school/school/types/StaffAbsenceType.vue';
import StaffType from '@/components/school/school/types/StaffType.vue';
import TermType from '@/components/school/school/types/TermType.vue';
import GradeType from '@/components/school/school/types/GradeType.vue';
import GradeScaleType from '@/components/school/school/types/GradeScaleType.vue';
import BookType from '@/components/school/school/types/BookType.vue';
import SpecialResourceType from '@/components/school/school/types/SpecialResourceType.vue';
import PriceType from '@/components/school/school/types/PriceType.vue';
import BehaviorType from '@/components/school/school/types/BehaviorType.vue';

export default {
  name: 'types',
  components: {
    ExamPlaces,
    ClassroomType,
    AbsenceType,
    AttendanceType,
    CommentType,
    // EventType,
    ModuleType,
    ProcessingStatusType,
    StaffAbsenceType,
    StaffType,
    TermType,
    GradeType,
    GradeScaleType,
    BookType,
    SpecialResourceType,
    PriceType,
    BehaviorType,
  },
  computed: {
    ...mapGetters(['schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      tabIndex: 'comment',
      page: 'types',
    };
  },
  methods: {
    toggle(item) {
      this.open[item] = !this.open[item];
    },
    changeTab(tab) {
      this.tabIndex = tab;
      this.$router.push({ name: 'School', query: { page: this.page, tab: this.tabIndex } });
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  beforeRouteUpdate(to, from, next) {
    if (to && to.query && to.query.page) {
      this.page = to.query.page;
    }
    if (to && to.query && to.query.tab) {
      this.tabIndex = to.query.tab;
    }
    next();
  },
  created() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
    if (this.$route.query.tab) {
      this.tabIndex = this.$route.query.tab;
    }
  },
};
</script>

<style lang="scss"></style>
