import Vue from 'vue';

const API_URL = '/api/classes';

export default {
  getSchoolYears() {
    return Vue.http
      .get(`${API_URL}/school-years`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getClassList(params) {
    return Vue.http
      .get(`${API_URL}/classes/`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getClassDetail(id) {
    return Vue.http
      .get(`${API_URL}/classes/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  transferStudents(data) {
    return Vue.http
      .post(`${API_URL}/move-up`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
};
