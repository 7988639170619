<template>
  <div>
    <Loader v-if="loading" />
    <div v-else>
      <div>
        <i>Skoða og tengja brautir við samþykktar brautir MRN.</i>
      </div>
      <div class="cursor-pointer float-right" @click="detailedSearch = !detailedSearch">
        <span v-if="detailedSearch"><i class="fa fa-angle-up" /> Fela ítarleit</span>
        <span v-else><i class="fa fa-angle-down" /> Sýna ítarleit</span>
      </div>
      <b-row v-if="detailedSearch">
        <b-col>
          <b-form-group label="Heiti" label-for="name">
            <b-form-input id="name" name="name" type="text" v-model="search.name"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Kóði" label-for="code">
            <b-form-input id="code" name="code" type="text" v-model="search.code"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Undirskóli" label-for="division">
            <v-select id="division" name="division" v-model="search.division" :options="divisions" label="name"> </v-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Deild" label-for="department">
            <v-select id="department" name="department" v-model="search.department" :options="departments" label="name"> </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="detailedSearch">
        <b-col cols="3">
          <b-form-group label="Í gildi" label-for="active">
            <v-select id="active" name="active" v-model="search.active" :options="active" :clearable="false"> </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Tengt samþykktri braut" label-for="connected">
            <v-select id="connected" name="connected" v-model="search.connected" :options="connected" :clearable="false"> </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <table class="table" style="font-size: 14px">
        <thead>
          <tr class="table-search" v-if="!detailedSearch">
            <td colspan="8">
              <b-form-input v-model="search.text" class="search" style="margin-top: 0px; margin-bottom: 15px" placeholder="Leita" />
            </td>
          </tr>
          <tr>
            <th colspan="4">Braut skóla</th>
            <th colspan="4" style="border-left: 1px solid #dee2e6">Samþykkt braut</th>
          </tr>
          <tr>
            <th>
              <LinkOrder title="Heiti" :filteredList="getList" :list="list" column="heiti" second_column="braut_kodi" />
              ({{ getList.length }})
            </th>
            <th>
              <LinkOrder title="Kóði" :filteredList="getList" :list="list" column="braut_kodi" second_column="heiti" />
            </th>
            <th>
              <LinkOrder title="Undirskóli" :filteredList="getList" :list="list" column="undirsk_heiti" second_column="heiti" />
            </th>
            <th>Í gildi</th>
            <th style="border-left: 1px solid #dee2e6">
              <LinkOrder title="Heiti" :filteredList="getList" :list="list" column="title" second_column="heiti" />
            </th>
            <th>
              <LinkOrder title="Staðfestingarnúmer" :filteredList="getList" :list="list" column="ministry_serial" second_column="heiti" />
            </th>
            <th>
              <LinkOrder title="Flokkur" :filteredList="getList" :list="list" column="name" second_column="heiti" />
            </th>
            <th>
              <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="cursor-pointer float-right">
                <i class="fa fa-file-excel-o excelText"></i>
              </download-csv>
            </th>
          </tr>
        </thead>
        <tr
          v-for="(item, index) in getPaginatedList"
          :key="index"
          :class="{ 'active-table-row': selectedItem && selectedItem.braut_id === item.braut_id, 'cursor-pointer': showSlidebar }"
          @click="checkSlidebar(item)"
        >
          <td>{{ item.heiti }}</td>
          <td>{{ item.braut_kodi }}</td>
          <td>{{ item.undirsk_heiti }}</td>
          <td><i class="fa" :class="{ 'fa-check text-success': item.gildi === 1, 'fa-remove text-danger': item.gildi === 0 }" /></td>
          <td>{{ item.title }}</td>
          <td>
            <a
              v-if="item.external_id"
              :href="`http://namskra.is/programmes/${item.external_id}`"
              target="_blank"
              class="btn btn-primary btn-sm"
            >
              <i class="fa fa-search"></i>
            </a>
            {{ item.ministry_serial }}
          </td>
          <td>
            {{ item.name }} <span v-if="item.description">- {{ item.description }}</span>
          </td>
          <td>
            <b-btn class="float-right" variant="primary" size="sm" @click.prevent="openSlidebar(item)" v-if="canEdit">
              <i class="fa fa-pencil" />
            </b-btn>
          </td>
        </tr>
      </table>
      <br />
      <Pagination
        :currentPage="currentPage"
        :totalRows="getList.length"
        :perPage="perPage"
        v-if="list.length > 25"
        @change-page="changePage"
        @change-limit="changeLimit"
      />
    </div>
    <connect-course-slidebar v-if="showSlidebar" :item="selectedItem" @closeSlidebar="closeSlidebar"> </connect-course-slidebar>
  </div>
</template>

<script>
import structure from '@/api/structure';
import reports from '@/api/reports';
import { mapActions, mapGetters } from 'vuex';

import Loader from '@/components/common/Loader.vue';
import Pagination from '@/components/common/Pagination.vue';

import ConnectCourseSlidebar from '@/components/reports/mainschool/ConnectCourseSlidebar.vue';
import moment from 'moment';
import LinkOrder from '@/components/common/LinkOrder.vue';

export default {
  name: 'mainschool-courses',
  components: {
    Loader,
    Pagination,
    ConnectCourseSlidebar,
    LinkOrder,
  },
  computed: {
    getExcelData() {
      const newLine = [
        [],
        ['Aðalskóli - Tengja brautir'],
        [],
        ['Braut skóla - Heiti', 'BrautKóði', 'Undirskóli', 'Í gildi', 'Samþykkt braut - Heiti', 'Staðfestingarnúmer', 'Flokkur'],
        ...this.getList.map(x => [
          x.heiti,
          x.braut_kodi,
          x.undirsk_heiti,
          x.gildi === 1 ? 'Já' : 'Nei',
          x.title,
          x.ministry_serial,
          x.name ? `="${x.name}-${x.description}"` : ' ',
        ]),
      ];
      return newLine;
    },
    getExcelFileName() {
      return `adalskoli_tengja_brautir${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    getList() {
      if (this.detailedSearch) {
        return this.list
          .filter(x => !this.search.name || (x.heiti || '').toUpperCase().indexOf(this.search.name.toUpperCase()) > -1)
          .filter(x => !this.search.code || (x.braut_kodi || '').toUpperCase().indexOf(this.search.code.toUpperCase()) > -1)
          .filter(x => !this.search.division || x.undirskoli_id === this.search.division.division_id)
          .filter(x => !this.search.department || x.deild_id === this.search.department.department_id)
          .filter(x => this.search.active.id === null || x.gildi === this.search.active.id)
          .filter(
            x =>
              this.search.connected.id === null ||
              (this.search.connected.id === 0 && !x.ny_samr_braut_id) ||
              (this.search.connected.id === 1 && x.ny_samr_braut_id),
          );
      }
      return this.list
        .filter(x => this.search.text.length === 0 || (x.heiti || '').toUpperCase().indexOf(this.search.text.toUpperCase()) > -1)
        .filter(x => x.gildi === 1);
    },
    getPaginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.getList.slice(start, end);
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skyrslur_adalskoli');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      perPage: 25,
      currentPage: 1,
      search: {
        text: '',
        name: '',
        code: '',
        division: '',
        department: '',
        active: { id: 1, label: 'Já' },
        connected: { id: null, label: 'Bæði tengt og ótengt' },
      },
      detailedSearch: false,
      loading: false,
      list: [],
      departments: [],
      divisions: [],
      active: [
        { id: null, label: 'Bæði í gildi og ekki' },
        { id: 1, label: 'Já' },
        { id: 0, label: 'Nei' },
      ],
      connected: [
        { id: null, label: 'Bæði tengt og ótengt' },
        { id: 1, label: 'Bara tengdar' },
        { id: 0, label: 'Bara ótengdar' },
      ],
      showSlidebar: false,
      selectedItem: {},
    };
  },
  methods: {
    async loadList() {
      try {
        this.list = [];
        this.loading = true;
        const response = await reports.getHomeschoolCourselist();
        this.list = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadDivisions() {
      try {
        const response = await structure.getDivisionList();
        this.divisions = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadDepartments() {
      try {
        const response = await structure.getDepartmentList();
        this.departments = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    openSlidebar(item) {
      this.selectedItem = item;
      this.showSlidebar = true;
    },
    closeSlidebar() {
      this.showSlidebar = false;
      this.selectedItem = {};
      this.loadList();
    },
    checkSlidebar(item) {
      if (this.showSlidebar) {
        this.openSlidebar(item);
      }
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadList();
    this.loadDivisions();
    this.loadDepartments();
  },
};
</script>

<style lang="scss"></style>
