<template>
  <Slidebar @closeMe="closeSlidebar" :large="true">
    <h4>Atburður</h4>
    <b-form @submit.prevent="save" novalidate>
      <table class="table less-padding no-border">
        <tr>
          <td width="30%">Heiti atburðar*</td>
          <td>
            <b-form-group
              label-for="atburdur"
              :state="submitted && errors.has('atburdur') ? false : ''"
              :invalid-feedback="errors.first('atburdur')"
            >
              <b-form-input
                id="atburdur"
                name="atburdur"
                label="heiti"
                v-model="form.heiti"
                v-validate="'required'"
                maxLength="40"
                @input="checkCollision = true"
              >
              </b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td width="30%">
            Undirskóli*
            <i
              class="fa fa-info-circle"
              title="Til þess að atburður birtist nemanda þarf nemandi að vera skráður í valinn undirskóla."
              v-b-tooltip
            ></i>
          </td>
          <td>
            <b-form-group
              label-for="undirskoli"
              :state="submitted && errors.has('undirskoli') ? false : ''"
              :invalid-feedback="errors.first('undirskoli')"
            >
              <v-select
                id="undirskoli"
                name="undirskoli"
                label="name"
                v-model="form.undirskoli"
                :options="getDivisions"
                :clearable="false"
                v-validate="'required'"
                multiple
                @input="
                  loadSchoolHours();
                  checkCollision = true;
                "
                :disabled="id != ''"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td width="30%">Dags. byrjun*</td>
          <td>
            <b-form-group
              label-for="dagsByrjun"
              :state="submitted && errors.has('dagsByrjun') ? false : ''"
              :invalid-feedback="errors.first('dagsByrjun')"
            >
              <datepicker
                id="dagsByrjun"
                name="dagsByrjun"
                v-model="form.dags_byrjun"
                :language="lang"
                :monday-first="true"
                format="dd.MM.yyyy"
                :typeable="false"
                :clear-button="false"
                v-validate="'required'"
                data-vv-as="dags. byrjun"
                :state="submitted && errors.has('dagsByrjun') ? false : ''"
                ref="dateFromOpenPickr"
                @input="
                  form.dags_endar = form.dags_byrjun;
                  loadSchoolHours();
                  checkCollision = true;
                "
              ></datepicker>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td width="30%">Dags. endar*</td>
          <td>
            <b-form-group
              label-for="dagsEndar"
              :state="submitted && errors.has('dagsEndar') ? false : ''"
              :invalid-feedback="errors.first('dagsEndar')"
            >
              <datepicker
                id="dagsEndar"
                name="dagsEndar"
                v-model="form.dags_endar"
                :language="lang"
                :monday-first="true"
                format="dd.MM.yyyy"
                :typeable="false"
                :clear-button="false"
                v-validate="'required'"
                data-vv-as="dags. endar"
                :state="submitted && errors.has('dagsEndar') ? false : ''"
                ref="dateToOpenPickr"
                @input="
                  loadSchoolHours();
                  checkCollision = true;
                "
              ></datepicker>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td width="30%">Takmarka við ákveðinn notendahóp</td>
          <td>
            <b-form-group
              label-for="notendahop"
              :state="submitted && errors.has('notendahop') ? false : ''"
              :invalid-feedback="errors.first('notendahop')"
            >
              <b-form-checkbox id="notendahop" name="notendahop" v-model="form.notendahop"></b-form-checkbox>
            </b-form-group>
          </td>
        </tr>
        <tr v-if="form.notendahop">
          <td width="30%">Notendahópur</td>
          <td>
            <b-form-group
              label-for="notendahopur"
              :state="submitted && errors.has('notendahopur') ? false : ''"
              :invalid-feedback="errors.first('notendahopur')"
            >
              <v-select
                id="notendahopur"
                name="notendahopur"
                label="heiti"
                v-model="form.notendahopur"
                :options="userGroups"
                :clearable="true"
                v-validate="form.notendahop ? 'required' : ''"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
        <tr v-if="!form.notendahop">
          <td width="30%">Birtist starfsmönnum</td>
          <td>
            <b-form-group
              label-for="starfsmenn"
              :state="submitted && errors.has('starfsmenn') ? false : ''"
              :invalid-feedback="errors.first('starfsmenn')"
            >
              <b-form-checkbox id="starfsmenn" name="starfsmenn" v-model="form.starfsmenn"></b-form-checkbox>
            </b-form-group>
          </td>
        </tr>
        <tr v-if="!form.notendahop">
          <td width="30%">Birtist nemendum</td>
          <td>
            <b-form-group
              label-for="nemendur"
              :state="submitted && errors.has('nemendur') ? false : ''"
              :invalid-feedback="errors.first('nemendur')"
            >
              <b-form-checkbox id="nemendur" name="nemendur" v-model="form.nemendur"></b-form-checkbox>
            </b-form-group>
          </td>
        </tr>
        <tr v-if="form.starfsmenn && form.nemendur && !form.notendahop">
          <td width="30%">Nemendur eiga að mæta í tíma skv. stundatöflu</td>
          <td>
            <b-form-group
              label-for="maeting"
              :state="submitted && errors.has('maeting') ? false : ''"
              :invalid-feedback="errors.first('maeting')"
            >
              <b-form-checkbox id="maeting" name="maeting" v-model="form.maeting"></b-form-checkbox>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td width="30%">
            Heill dagur

            <i class="fa fa-info-circle" title="Ef atburðurinn er hluta úr degi eru tímarnir valdir sem eiga við." v-b-tooltip></i>
          </td>
          <td>
            <b-form-group
              label-for="dagsEndar"
              :state="submitted && errors.has('heillDagur') ? false : ''"
              :invalid-feedback="errors.first('heillDagur')"
            >
              <v-select
                id="heillDagur"
                name="heillDagur"
                v-model="form.heill_dagur"
                :options="getTypes"
                :disabled="id != ''"
                @input="checkCollision = true"
                :clearable="false"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
      </table>
      <div class="alert alert-danger p-2" v-if="multiplePartOfDayError">
        <i class="fa fa-exclamation-triangle"></i> Ath. ekki er hægt að stofna atburði fyrir marga undirskóla sem eru hluti úr degi.
      </div>
      <div class="alert alert-danger p-2" v-if="mustSelectError">
        <i class="fa fa-exclamation-triangle"></i> Ath. atburður verður að vera fyrir starfsmenn, nemendur eða notendahóp.
      </div>
      <b-row v-if="form.heill_dagur && form.heill_dagur.id === 0 && hours.length > 0">
        <b-col cols="3" v-for="(date, idx) in form.dates" :key="idx" class="pb-2 pl-0">
          <strong>{{ date.date | moment('DD.MM.YYYY') }}</strong>
          <div v-for="(time, index) in date.times" :key="`date${idx}time${index}`">
            <b-form-checkbox v-model="time.selected" @input="checkCollision = true" class="d-inline-block"></b-form-checkbox>
            <div class="d-inline-block">{{ time.timi_fra }} - {{ time.timi_til }}</div>
          </div>
        </b-col>
      </b-row>
      <div class="alert alert-info p-2" v-if="form.heill_dagur && form.heill_dagur.id === 0 && hours.length === 0" style="font-size: 13px">
        <i class="fa fa-info-circle"></i> Ath. ekki eru til tímar/tímatafla fyrir þennan dag svo ekki er hægt að velja hvaða tíma þessi
        atburður á við. Atburðurinn verður því skráður allan daginn hjá viðkomandi.
      </div>

      <div class="alert alert-info p-2" v-if="form.notendahop && !form.maeting" style="font-size: 13px">
        <i class="fa fa-info-circle"></i> Ef valinn er notendahópur þá skráist atburður í stundatöflu þeirra sem eru í notendahópnum. Ef
        nemendur eru skráðir í kennslustund á þessum tíma þarf að skrá á þá viðveru óháð því hvort það sé mætingaskylda eða ekki á
        atburðinum.
      </div>

      <b-btn variant="primary" @click="save()" v-if="checkCollision" :disabled="multiplePartOfDayError || mustSelectError">
        <span v-if="id">Breyta atburði</span>
        <span v-else>Skrá</span>
      </b-btn>
      <b-btn variant="primary" @click="validateBeforeSubmit()" v-if="!checkCollision" :disabled="multiplePartOfDayError || mustSelectError">
        <span v-if="id">Breyta atburði</span>
        <span v-else>Skrá</span>
      </b-btn>

      <b-btn variant="danger" class="ml-2" @click="deleteCollision()" v-if="nrCollisionToDelete > 0" :disabled="deletingCollision">
        Eyða {{ nrCollisionToDelete }} kennslustundum
      </b-btn>

      <b-btn variant="danger" class="float-right" v-if="id" @click="deleteEvent()"> Eyða atburði </b-btn>
    </b-form>
    <div v-if="collision.length > 0" class="alert alert-info mt-2 p-2" style="font-size: 13px">
      <i class="fa fa-exclamation-triangle"> </i> Það er ekki mætingaskylda þessa daga, hægt er að eyða út kennslustundum með því að haka
      við þær hér fyrir neðan.
      <div>Ef kennslustundum er ekki eytt þá þarf að skrá mætingu á þær.</div>
      <div>
        Það fundust <strong>{{ collision.length }}</strong> tímar í stundatöflu fyrir eftirfarandi dagsetningar:
      </div>
    </div>
    <table class="table" v-if="collision.length > 0" style="font-size: 13px">
      <tr>
        <th><b-form-checkbox v-model="selectAll" @input="updateAll()"></b-form-checkbox></th>
        <th>Hópur</th>
        <th>Dags</th>
        <th>Tími frá</th>
        <th>
          Tími til

          <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="d-inline">
            <i class="fa fa-file-excel-o cursor-pointer float-right" style="color: #1d6f42"></i>
          </download-csv>
        </th>
      </tr>
      <tr v-for="(item, index) in getPaginatedCollision" :key="index">
        <td><b-form-checkbox v-model="item.selected"></b-form-checkbox></td>
        <td>{{ item.heiti }}</td>
        <td>{{ item.dags | moment('DD.MM.YYYY') }}</td>
        <td>{{ item.timi_fra }}</td>
        <td>{{ item.timi_til }}</td>
      </tr>
    </table>
    <Pagination
      :currentPage="currentPage"
      :totalRows="collision.length"
      :perPage="perPage"
      v-if="collision.length > 5"
      @change-page="changePage"
      @change-limit="changeLimit"
    ></Pagination>
  </Slidebar>
</template>

<script>
import moment from 'moment';
import common from '@/api/common';
import users from '@/api/users';
import structure from '@/api/structure';
import { mapActions, mapGetters } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';
import Pagination from '@/components/common/Pagination.vue';

export default {
  name: 'school-event-slidebar',
  props: ['id', 'date'],
  components: {
    Slidebar,
    Datepicker,
    Pagination,
  },
  computed: {
    multiplePartOfDayError() {
      return this.form.undirskoli.length > 1 && this.form.heill_dagur && this.form.heill_dagur.id === 0;
    },
    mustSelectError() {
      return !this.form.nemendur && !this.form.starfsmenn && !this.form.notendahop;
    },
    getTypes() {
      return [
        { id: 1, label: 'Heill dagur' },
        { id: 0, label: 'Hluti úr degi' },
      ];
    },
    getDivisions() {
      return this.divisions.filter(
        x =>
          !this.form.undirskoli ||
          this.form.undirskoli.length === 0 ||
          this.form.undirskoli.filter(y => y.division_id === x.division_id).length === 0,
      );
    },
    getPaginatedCollision() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.collision.slice(start, end);
    },
    nrCollisionToDelete() {
      return this.collision.filter(x => x.selected).length;
    },
    getExcelData() {
      const newList = [
        [],
        ['Stundatafla - árekstrar við atburði'],
        [],
        ['Heiti', 'Dags', 'Tími frá', 'Tími til'],
        ...this.collision.map(x => [x.heiti, moment(x.dags).format('DD.MM.YYYY'), x.timi_fra, x.timi_til]),
      ];
      return newList;
    },
    getExcelFileName() {
      return `atburdur_stundatafla_arekstur_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    canEditType() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      collision: [],
      lang: is,
      perPage: 5,
      currentPage: 1,
      item: '',
      submitted: false,
      selectAll: false,
      checkCollision: true,
      deletingCollision: false,
      form: {
        heiti: '',
        maeting: true,
        undirskoli: [],
        dags_byrjun: null,
        dags_endar: null,
        heill_dagur: { id: 1, label: 'Heill dagur' },
        starfsmenn: true,
        nemendur: true,
        notendahop: false,
        notendahopur: '',
        dates: [],
      },
      divisions: [],
      hours: [],
      userGroups: [],
      loading: {
        info: false,
        divisions: false,
        hours: false,
        userGroups: false,
      },
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    updateDates() {
      this.form.dates = [];

      const startDate = moment(this.form.dags_byrjun).startOf('day').toDate();
      const endDate = moment(this.form.dags_endar).startOf('day').toDate();
      const currentDate = startDate;
      while (currentDate <= endDate) {
        const hours = this.hours
          .filter(x => x.vikudagar_id === currentDate.getDay() || (x.vikudagar_id === 7 && currentDate.getDay() === 0))
          .map(x => ({ ...x, selected: (this.item.timar || []).find(y => y.skolatimar_id === x.skolatimar_id) !== undefined }));
        this.form.dates.push({
          date: new Date(currentDate),
          times: hours,
        });
        currentDate.setDate(currentDate.getDate() + 1);
      }
    },
    async loadInfo() {
      await this.loadDivisions();
      await this.loadUserGroups();
      if (this.id) {
        this.loading.info = true;
        try {
          const response = await common.getSchoolEvent(this.id);
          this.item = response.data.items.length > 0 ? response.data.items[0] : '';
          this.form.heiti = this.item.heiti;
          this.form.maeting = this.item.maeting === 1;
          this.form.undirskoli = [{ ...this.divisions.find(x => x.division_id === this.item.undirskoli_id) }];
          this.form.dags_byrjun = moment(this.item.dags_byrjun).toDate();
          this.form.dags_endar = moment(this.item.dags_endar).toDate();
          this.form.heill_dagur = this.getTypes.find(x => x.id === this.item.heill_dagur);
          this.form.nemendur = this.item.nemendur === 1;
          this.form.starfsmenn = this.item.starfsmenn === 1;
          this.form.notendahop = this.item.notendahopur !== null;
          this.form.notendahopur = this.userGroups.find(x => x.hopur_id === this.item.notendahopur);
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading.info = false;
        }
      } else {
        this.form.undirskoli = this.divisions.length > 0 ? [{ ...this.divisions[0] }] : [];
        this.form.dags_byrjun = this.date ? moment(this.date).toDate() : moment().toDate();
        this.form.dags_endar = this.date ? moment(this.date).toDate() : moment().toDate();
      }
      await this.loadSchoolHours();
    },
    async loadDivisions() {
      try {
        this.loading.divisions = true;
        const response = await structure.getDivisionList();
        this.divisions = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.divisions = false;
      }
    },
    async loadSchoolHours() {
      if (this.form.undirskoli.length === 1) {
        try {
          this.loading.hours = true;
          const response = await common.getSchoolHours({
            divisionId: this.form.undirskoli[0].division_id,
            dateFrom: moment(this.form.dags_byrjun).format('DD.MM.YYYY'),
            dateTo: moment(this.form.dags_endar).format('DD.MM.YYYY'),
          });
          this.hours = response.data.items;
          this.updateDates();
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading.hours = false;
        }
      } else {
        this.hours = [];
        this.updateDates();
      }
    },
    async loadUserGroups() {
      try {
        this.loading.userGroups = true;
        const response = await users.getUserGroupsList({ active: 1 });
        this.userGroups = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.userGroups = false;
      }
    },
    async save() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        if (this.form.nemendur && this.form.starfsmenn && !this.form.notendahop && !this.form.maeting) {
          await this.checkForCollision();
        } else {
          await this.validateBeforeSubmit();
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    async checkForCollision() {
      try {
        const timar = [];
        if (this.form.heill_dagur && this.form.heill_dagur.id === 0) {
          this.form.dates.forEach(x => {
            x.times
              .filter(y => y.selected)
              .forEach(t => {
                timar.push({
                  dags: moment(x.date).format('DD.MM.YYYY'),
                  timi_fra: t.timi_fra,
                  timi_til: t.timi_til,
                });
              });
          });
        }
        const data = {
          undirskoli_id: this.form.undirskoli.map(x => x.division_id).join(','), // this.form.undirskoli.division_id,
          dags_fra: moment(this.form.dags_byrjun).format('DD.MM.YYYY'),
          dags_til: moment(this.form.dags_endar).format('DD.MM.YYYY'),
          timar,
        };

        const response = await common.checkSchoolEventsCollision(data);
        this.collision = response.data;
        this.checkCollision = false;
        if (this.collision.length === 0) {
          this.validateBeforeSubmit();
        }
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async validateBeforeSubmit() {
      try {
        const promises = [];
        if ((this.form.heill_dagur && this.form.heill_dagur.id === 1) || this.form.undirskoli.length > 1) {
          this.form.undirskoli.forEach(undirskoli => {
            const data = {
              heill_dagur: this.form.heill_dagur.id,
              heiti: this.form.heiti,
              maeting: this.form.nemendur && this.form.starfsmenn && !this.form.notendahop && !this.form.maeting ? 0 : 1,
              undirskoli: undirskoli.division_id,
              dags_byrjun: moment(this.form.dags_byrjun).format('DD.MM.YYYY'),
              dags_endar: moment(this.form.dags_endar).format('DD.MM.YYYY'),
              nemendur: this.form.notendahop || this.form.nemendur ? 1 : 0,
              starfsmenn: this.form.notendahop || this.form.starfsmenn ? 1 : 0,
              notendahopur: this.form.notendahopur ? this.form.notendahopur.hopur_id : '',
              timar: '',
            };
            if (this.id) {
              promises.push(common.updateSchoolEvent(this.id, data));
            } else {
              promises.push(common.createSchoolEvent(data));
            }
          });
        } else {
          this.form.dates.forEach(item => {
            const data = {
              heill_dagur: this.form.heill_dagur.id,
              heiti: this.form.heiti,
              maeting: this.form.nemendur && this.form.starfsmenn && !this.form.notendahop && !this.form.maeting ? 0 : 1,
              undirskoli: this.form.undirskoli[0].division_id,
              dags_byrjun: moment(item.date).format('DD.MM.YYYY'),
              dags_endar: moment(item.date).format('DD.MM.YYYY'),
              nemendur: this.form.notendahop || this.form.nemendur ? 1 : 0,
              starfsmenn: this.form.notendahop || this.form.starfsmenn ? 1 : 0,
              notendahopur: this.form.notendahopur ? this.form.notendahopur.hopur_id : '',
              timar: item.times
                .filter(x => x.selected)
                .map(x => x.skolatimar_id)
                .join(','),
            };
            if (this.id) {
              promises.push(common.updateSchoolEvent(this.id, data));
            } else {
              promises.push(common.createSchoolEvent(data));
            }
          });
        }

        Promise.all(promises)
          .then(() => {
            this.displaySuccess('Atburður stofnaður í skóladagatali');
            this.$emit('closeAndReloadSlidebar');
          })
          .catch(e => {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          });
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async deleteEvent() {
      try {
        const conf = confirm(`Ertu viss um að þú viljir eyða viðburðinum ${this.item.heiti}?`); // eslint-disable-line
        if (conf) {
          await common.updateSchoolEvent(this.id, { delete: 1 });
          this.displaySuccess('Atburði eytt');

          this.$emit('closeAndReloadSlidebar');
        }
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async deleteCollision() {
      try {
        // eslint-disable-next-line no-restricted-globals, no-alert
        const conf = confirm(
          // eslint-disable-line
          `Ertu alveg viss um að þú viljir eyða ${this.nrCollisionToDelete} kennslustundum úr stundatöflunni? Þessa aðgerð er ekki hægt að afturkalla!`, // eslint-disable-line
        );
        if (conf) {
          const data = {
            tableIds: this.collision.filter(x => x.selected).map(x => x.tafla_id),
          };
          this.deletingCollision = true;
          await common.massDeleteTimetable(data);
          this.checkForCollision();
        }
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.deletingCollision = false;
      }
    },
    updateAll() {
      this.collision.forEach(c => {
        this.$set(c, 'selected', this.selectAll);
      });
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    id() {
      this.loadInfo();
    },
    date() {
      this.loadInfo();
    },
  },
  async created() {
    this.loadInfo();
  },
};
</script>

<style lang="scss"></style>
