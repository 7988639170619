import Vue from 'vue';

const API_URL = '/api/curriculum';

export default {
  /**
   * Vefþjónusta til að sækja lista yfir áfanga
   */
  getModuleList(params) {
    return Vue.http
      .get(`${API_URL}/module`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getModuleFewDetails(id) {
    const params = {
      id,
    };
    return Vue.http
      .get(`${API_URL}/module`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createModule(data) {
    return Vue.http
      .post(`${API_URL}/module`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getModuleDetails(id) {
    return Vue.http
      .get(`${API_URL}/module/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateModule(id, data) {
    return Vue.http
      .post(`${API_URL}/module/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til að sækja lista yfir brautir
   */
  getCourseList(params) {
    return Vue.http
      .get(`${API_URL}/course/`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getCourseById(id) {
    return Vue.http
      .get(`${API_URL}/course/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til að sækja lista yfir línur brautar
   */
  getCourseLinesList(courseId) {
    return Vue.http
      .get(`${API_URL}/course/${courseId}/lines`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til að sækja lista tegundir áfanga á braut
   */
  getCourseModuleTypes(params) {
    return Vue.http
      .get(`${API_URL}/course-module-types`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getSubjectsList(params) {
    return Vue.http
      .get(`${API_URL}/subjects/`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getSubjectDetail(id) {
    return Vue.http
      .get(`${API_URL}/subjects/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createSubject(data) {
    return Vue.http
      .post(`${API_URL}/subjects/`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateSubject(id, data) {
    return Vue.http
      .post(`${API_URL}/subjects/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getModulePrecursorList(id) {
    return Vue.http
      .get(`${API_URL}/module/${id}/precursor`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getModulePrecursorListGraph(id) {
    return Vue.http
      .get(`${API_URL}/module/${id}/precursor-graph`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getModulePrecursorAllList(id) {
    return Vue.http
      .get(`${API_URL}/module/${id}/precursor-all`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getModuleEquivalentList(id) {
    return Vue.http
      .get(`${API_URL}/module/${id}/equivalent`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getModuleEquivalentAll(id) {
    return Vue.http
      .get(`${API_URL}/module/${id}/equivalent-all`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getModuleEquivalentListGraph(id) {
    return Vue.http
      .get(`${API_URL}/module/${id}/equivalent-graph`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getModuleParallelList(id) {
    return Vue.http
      .get(`${API_URL}/module/${id}/parallel`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  setModuleParallel(id, data) {
    return Vue.http
      .post(`${API_URL}/module/${id}/parallel`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  setModuleEquivalent(id, data) {
    return Vue.http
      .post(`${API_URL}/module/${id}/equivalent`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  setModulePrecursor(id, data) {
    return Vue.http
      .post(`${API_URL}/module/${id}/precursor`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getModuleDescriptions(id) {
    return Vue.http
      .get(`${API_URL}/module/${id}/description`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  setModuleDescription(id, data) {
    return Vue.http
      .post(`${API_URL}/module/${id}/description`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getModuleCourses(id) {
    return Vue.http
      .get(`${API_URL}/module/${id}/course`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getModuleCourseConnection(id) {
    return Vue.http
      .get(`${API_URL}/course-module/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getModuleTerms(id, params) {
    return Vue.http
      .get(`${API_URL}/module/${id}/terms`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateModules(data) {
    return Vue.http
      .post(`${API_URL}/modules`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /* Sækir alla nemendur í áfanga á ákveðinni önn (m.v. annir_afangar_id) */
  getModuleTermStudents(termCourseId) {
    return Vue.http
      .get(`${API_URL}/module/${termCourseId}/students`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getCourseStructure(id) {
    return Vue.http
      .get(`${API_URL}/course/${id}/structure`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateCourseModule(id, data) {
    return Vue.http
      .post(`${API_URL}/course-module/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createCourse(data) {
    return Vue.http
      .post(`${API_URL}/course/`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateCourse(id, data) {
    return Vue.http
      .post(`${API_URL}/course/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateCourseApplication(data) {
    return Vue.http
      .post(`${API_URL}/course-application`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getCourseStructureModules(id) {
    return Vue.http
      .get(`${API_URL}/course/${id}/structure-modules/`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createCourseStructure(data) {
    return Vue.http
      .post(`${API_URL}/course-structure/`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  editCourseStructure(id, data) {
    return Vue.http
      .post(`${API_URL}/course-structure/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  copyCourseStructure(idTo, idFrom, data) {
    return Vue.http
      .post(`${API_URL}/course/${idTo}/structure-copy/${idFrom}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  addCourseStructureModules(id, data) {
    return Vue.http
      .post(`${API_URL}/course-structure/${id}/modules`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getCourseModules(id) {
    return Vue.http
      .get(`${API_URL}/course/${id}/modules`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateCourses(data) {
    return Vue.http
      .post(`${API_URL}/courses`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  checkCourseCodeExists(params) {
    return Vue.http
      .get(`${API_URL}/course-exists`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getCourseStudents(id) {
    return Vue.http
      .get(`${API_URL}/course/${id}/students`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getSecondLanguages() {
    return Vue.http
      .get(`${API_URL}/second-language`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getThirdLanguages() {
    return Vue.http
      .get(`${API_URL}/third-language`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getFourthLanguages() {
    return Vue.http
      .get(`${API_URL}/fourth-language`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getModuleEvaluation(params) {
    return Vue.http
      .get(`${API_URL}/module-evaluation`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
};
