<template>
    <Slidebar @closeMe="closeSlidebar" :large="true">
        <div class="d-inline-block mt-2">
            <h4>Magnbreyta viðveru</h4>
            <b-card>
                <b-row>
                    <b-col class="pl-0" colspan="3">
                        Hér er hægt að breyta fjarvistum, forföllum og athugasemdum. 
                        Ef valið er að breyta fjarvistum/forföllum á tíma þar engar skráningar eru fyrir þá er búin til skráning á þá tíma. 
                        <div class="font-weight-bold">
                            Allar breytingar sem eru gerðar og skrifað er yfir fyrri skráningar eru ekki afturkræfar.
                        </div>
                    </b-col>
                </b-row>
                <b-row class="pt-3 pb-3">
                    <b-col cols="2" class="pl-0">
                        Fjarvistarkóða:
                        <input class="d-inline-block" type="text" v-model="tegundFjarvista" maxLength="1"
                            style="width: 35px;height: calc(1.5em + 0.75rem + 2px); padding-left: 3px" :class="{
                                attendanceInvalidInput: !attendanceValid(tegundFjarvista),
                                attendanceValidInput: attendanceValid(tegundFjarvista),
                            }" />
                    </b-col>
                    <b-col  cols="2">
                        Forfallakóða:
                        <input class="d-inline-block" type="text" v-model="tegundForfalla" maxLength="1"
                            style="width: 35px; height: calc(1.5em + 0.75rem + 2px); padding-left: 3px" :class="{
                                attendanceInvalidInput: !absenceValid(tegundForfalla),
                                attendanceValidInput: absenceValid(tegundForfalla),
                            }" />
                    </b-col>
                    <b-col  cols="5">
                        <span class="d-inline-block">Athugasemd:
                            <i class="fa fa-info-circle"  v-b-tooltip
                            title="Ef útfyllt er athugasemd sett á valdnar færslur" aria-hidden="true"></i>

                        </span>
                        <b-form-input id="comment" class="d-inline-block" name="comment" type="text"
                            v-model="comment" 
                            maxLength="4000" data-vv-as="comment"></b-form-input>
                    </b-col>
                    <b-col  cols="3" class="pr-0">
                        <b-btn variant="primary" size="sm" class="mr-2 float-right mt-4"
                            :disabled="invalidCode() || saving || (!this.tegundFjarvista && !this.tegundForfalla && !this.comment)"
                            style="font-size: 13px; width:75px" v-if="canEdit" @click="editAttendances(0)">
                            Breyta </b-btn>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="pl-0" style="font-size: 12px;">
                        <b>Fjarvistarkóðar:</b>
                        <div v-for="a in attendanceCodes" :key="a.teg_fjarvista" class="pl-2 d-inline-block">{{ a.kodi }} -
                            {{ a.heiti }}</div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="pl-0" style="font-size: 12px;">
                        <b>Forfallakóðar:</b>
                        <div v-for="a in absenceCodes" :key="a.teg_forfalla" class="pl-2 d-inline-block">{{ a.kodi }} - {{
                            a.heiti
                        }}</div>
                    </b-col>
                </b-row>
            </b-card>
            <b-row class="mt-3 mb-2">
                <b-col class="pl-0 pr-0 text-right">
                    <b-form-radio-group stacked id="typeOfDelete" v-model="typeOfDelete" name="typeOfDelete"
                        class="d-inline-block">
                        <b-form-radio class="mr-2 d-inline-block" :value=0>Eyða fjarvistum <span class="font-weight-bold"
                                title="Færslur sem hafa viðverukóða" v-b-tooltip>
                                ({{ deleteCounterCheck(0) }}) </span></b-form-radio>
                        <b-form-radio class="mr-2 d-inline-block" :value=1>Eyða forföllum <span class="font-weight-bold"
                                title="Færslur sem hafa forfallakóða" v-b-tooltip>({{ deleteCounterCheck(1)
                                }})</span></b-form-radio>
                        <b-form-radio class="mr-2 d-inline-block" :value=2>Eyða öllu <span class="font-weight-bold"
                                title="Allar færslur" v-b-tooltip>({{ deleteCounterCheck(2)
                                }})</span></b-form-radio>
                    </b-form-radio-group>
                    <b-btn variant="danger" class="mr-2 mt--1" v-if="canEdit" size="sm"
                        style="margin-right: 0px !important;" @click="editAttendances(1)"> Eyða færslum </b-btn>
                </b-col>
            </b-row>
            <b-row class="mt-3 mb-2">
                <b-col class="pl-0 pr-0">
                    <strong class="pr-3">Færslur sem verið er að breyta ({{ items.length }})</strong>
                </b-col>
            </b-row>
            <table class="table mt-2" style="font-size: 13px">
                <thead>
                    <tr>
                        <td>Áfangi</td>
                        <td>Dags.</td>
                        <td>Tími</td>
                        <td>Fjarvistarkóða</td>
                        <td>Forfallakóði</td>
                        <td>Athugasemd</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in items" :key="item.fjarv_forfoll_id">
                        <td> {{ item.namsgrein_afanganr }}</td>
                        <td> {{ item.dags }}</td>
                        <td> {{ item.timi_fra }} - {{ item.timi_til }}</td>
                        <td> {{ item.kodi_fjarvist }}</td>
                        <td> {{ item.kodi_forfoll }}</td>
                        <td> {{ item.athugasemd }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </Slidebar>
</template>
  
<script>
import students from '@/api/students';
import types from '@/api/types';
import Slidebar from '@/components/common/Slidebar.vue';

import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'attendance-mass-edit-slidebar',
    props: ['items', 'studentId', 'dateFrom', 'dateTo'],
    components: {
        Slidebar,
    },
    computed: {
        canEdit() {
            return this.loggedInUserHasWritePermission('nemendahald_nemendur');
        },
        ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
    },
    data() {
        return {
            saving: false,
            form: {
                gildi: { id: 1, label: 'Já' },
            },
            yesNo: [
                { id: 1, label: 'Já' },
                { id: 0, label: 'Nei' },
            ],
            attendanceCodes: [],
            absenceCodes: [],
            tegundFjarvista: '',
            tegundForfalla: '',
            comment: '',
            typeOfDelete: 0,
            loading: {
                absenceCodes: false,
                attendanceCodes: false,
            },
            deleteCounter: '',
        };
    },
    methods: {
        closeSlidebar() {
            this.$emit('closeSlidebar');
        },
        async editAttendances(type) {
            try {
                this.saving = true;
                const list = [];
                let conf;
                this.items.forEach((s) => {
                    list.push({ fjarv_forfoll_id: s.fjarv_forfoll_id });
                });
                // edit
                if (type === 0) {
                    conf = confirm(`Ertu viss að þú viljir breyta ${this.items.length} færslum? Ath. þessi vinnsla er ekki afturkræf.`); // eslint-disable-line
                    const tegForfallaId = (this.absenceCodes.find(x => (x.kodi || '').toUpperCase() ===
                        (this.tegundForfalla || '').toUpperCase()) || {
                        teg_forfalla: '',
                    }
                    ).teg_forfalla;
                    const tegFjarvistaId = (
                        this.attendanceCodes.find(x => (x.kodi || '').toUpperCase() ===
                            (this.tegundFjarvista || '').toUpperCase()) || {
                            tegund_fjarvista: '',
                        }
                    ).teg_fjarvista;
                    const data = {
                        ids: JSON.stringify(list),
                        dags_fra: this.dateFrom ? moment(this.dateFrom).format('DD.MM.YYYY') : '',
                        dags_til: this.dateTo ? moment(this.dateTo).format('DD.MM.YYYY') : '',
                        teg_fjarvista_id: tegFjarvistaId,
                        teg_forfalla_id: tegForfallaId, // this.tegundForfalla,
                        delete: 0,
                        athugasemd: this.comment ? this.comment : '',
                    }
                    if (conf) {
                        await students.editSelectedStudentsAttendance(this.studentId, data);
                        this.displaySuccess(`${this.items.length} færslum hafa verið eytt`);
                        this.closeAndReloadSlidebar();
                    }
                } else {
                    // 0 = viðvera, 1 = forföllum , 2 = bæði
                    if (this.typeOfDelete === 0) {
                        this.deleteCounter = this.items.filter(x => x.teg_fjarvista_id).length;
                    } else if (this.typeOfDelete === 1) {
                        // forföll
                        this.deleteCounter = this.items.filter(x => x.teg_forfalla_id).length;
                    } else {
                        this.deleteCounter = this.items.length;
                    }
                    conf = confirm(`Ertu viss að þú viljir eyða ${this.deleteCounter} færslum? Ath. þessi vinnsla er ekki afturkræf.`); // eslint-disable-line
                    const data = {
                        ids: JSON.stringify(list),
                        dags_fra: this.dateFrom ? moment(this.dateFrom).format('DD.MM.YYYY') : '',
                        dags_til: this.dateTo ? moment(this.dateTo).format('DD.MM.YYYY') : '',
                        delete: 1,
                        type_delete: this.typeOfDelete
                    }
                    if (conf) {
                        await students.editSelectedStudentsAttendance(this.studentId, data);
                        this.displaySuccess(`${this.deleteCounter} færslum hafa verið eytt`);
                        this.closeAndReloadSlidebar();
                    }
                }

            } catch (e) {
                this.failed = true;
                this.$log.error(e);
                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            } finally {
                this.saving = false;
            }
        },
        closeAndReloadSlidebar() {
            this.$emit('closeAndReload');
        },
        invalidCode() {
            return (
                !this.attendanceValid(this.tegundFjarvista) || !this.absenceValid(this.tegundForfalla));
        },
        attendanceValid(code) {
            return !code || this.attendanceCodes.find(x => (x.kodi || '').toUpperCase() === code.toUpperCase());
        },
        absenceValid(code) {
            return !code || this.absenceCodes.find(x => (x.kodi || '').toUpperCase() === code.toUpperCase());
        },
        async loadAttendance() {
            try {
                this.loading.attendanceCodes = true;
                const response = await types.getAttendanceList({ active: 1 });
                this.attendanceCodes = response.data.items.map(x => ({
                    ...x,
                    label: `${x.kodi} - ${x.heiti}`,
                }));
            } catch (e) {
                this.$log.error(e);
                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            } finally {
                this.loading.attendanceCodes = false;
            }
        },
        async loadAbsence() {
            try {
                this.loading.absenceCodes = true;
                const response = await types.getAbsenceList({ active: 1 });
                this.absenceCodes = response.data.items.map(x => ({
                    ...x,
                    label: `${x.kodi} - ${x.heiti}`,
                }));
            } catch (e) {
                this.$log.error(e);
                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            } finally {
                this.loading.absenceCodes = false;
            }
        },
        deleteCounterCheck(type) {
            let counter = ''
            if (type === 0) {
                counter = this.items.filter(x => x.teg_fjarvista_id).length;
            } else if (type === 1) {
                // forföll
                counter = this.items.filter(x => x.teg_forfalla_id).length;
            } else {
                counter = this.items.length;
            }
            return counter;
        },
        ...mapActions({
            displayError: 'displayError',
            displaySuccess: 'displaySuccess',
        }),
    },
    created() {
        this.loadAbsence();
        this.loadAttendance();
    }
};
</script>
  
<style lang="scss">
.attendanceInvalidInput {
    border: 1px solid red;
    color: red;
}

.attendanceValidInput {
    color: blue;
}
</style>
  