<template>
  <Slidebar @closeMe="closeSlidebar" :large="true">
    <h4><span v-if="groupId">Breyta hóp</span><span v-else>Stofna hóp</span></h4>
    <b-row class="pb-2">
      <b-col class="pl-0">
        <div>{{ item.onn_eink }} - {{ item.namsgrein_afanganr }}</div>
      </b-col>
      <b-col>
        <div v-if="!groupId">
          <div>Aðrir hópar áfanga:</div>
          <div v-if="item && item.hopar.length === 0"><i>Engir hópar fundust</i></div>
          <div v-for="hopur in item.hopar" :key="hopur.hopur_id">{{ hopur.hopur }}</div>
        </div>
      </b-col>
    </b-row>
    <b-form novalidate>
      <b-row>
        <b-col class="pl-0">
          <b-form-group
            label-for="name"
            label="Hópur*"
            :state="submitted && errors.has('name') ? false : ''"
            :invalid-feedback="errors.first('name')"
          >
            <b-form-input
              id="name"
              name="name"
              v-model="form.name"
              maxLength="20"
              type="text"
              :state="submitted && errors.has('name') ? false : ''"
              v-validate="'required'"
              data-vv-as="hópur"
              ref="focusInput"
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <label label-for="maxStudents">
              Hámarks fj. nema
              <i
                class="fa fa-info-circle"
                v-b-tooltip
                title="Hámarksfjöldi nema sem verður tekinn inn í hópinn, ef ekkert hámark er skráð á þessi dálkur að vera tómur."
              ></i>
            </label>
            <b-form-input
              id="maxStudents"
              name="maxStudents"
              v-model="form.maxStudents"
              type="number"
              min="0"
              :state="submitted && errors.has('maxStudents') ? false : ''"
              data-vv-as="hámarksfjöldi"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            v-if="!afangaskoli"
            label="Bekkur"
            label-for="bekkur"
            :state="submitted && errors.has('bekkur') ? false : ''"
            :invalid-feedback="errors.first('bekkur')"
          >
            <v-select
              id="bekkur"
              name="bekkur"
              v-model="form.class"
              :clearable="true"
              :state="submitted && errors.has('bekkur') ? false : ''"
              :options="classes"
              data-vv-as="bekkur"
              label="heiti"
            >
            </v-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Dags.frá" label-for="dateFrom">
            <datepicker
              id="dateFrom"
              name="dateFrom"
              class="datepicker_90_width"
              v-model="form.dateFrom"
              :language="lang"
              :monday-first="true"
              format="dd.MM.yyyy"
              :typeable="false"
              :clear-button="true"
              ref="dateFromOpenPickr"
            ></datepicker>
          </b-form-group>
          <b-form-group label="Dags.til" label-for="dateTo">
            <datepicker
              id="dateTo"
              name="dateTo"
              class="datepicker_90_width"
              v-model="form.dateTo"
              :language="lang"
              :monday-first="true"
              format="dd.MM.yyyy"
              :typeable="false"
              :clear-button="true"
              ref="dateToOpenPickr"
            ></datepicker>
          </b-form-group>
          <b-form-group
            v-if="!afangaskoli"
            label="Bekkjahópur"
            label-for="bekkjahopur"
            :state="submitted && errors.has('bekkjahopur') ? false : ''"
            :invalid-feedback="errors.first('bekkjahopur')"
          >
            <v-select
              id="bekkjahopur"
              name="bekkjahopur"
              v-model="form.classGroup"
              :clearable="true"
              :state="submitted && errors.has('bekkjahopur') ? false : ''"
              :options="yesNo"
              data-vv-as="bekkjahópur"
              v-validate="form.class ? 'required' : ''"
            >
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="pl-0">
          <b-form-group
            label="Kennari - forsendur"
            label-for="teacher"
            :state="submitted && errors.has('teacher') ? false : ''"
            :invalid-feedback="errors.first('teacher')"
          >
            <v-select
              id="teacher"
              name="teacher"
              v-model="form.teachers"
              :clearable="true"
              :state="submitted && errors.has('teacher') ? false : ''"
              :options="staffList"
              data-vv-as="kennari"
              multiple
            >
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group
        label-for="applicationInfo"
        label="Setja inn upplýsingar fyrir umsóknarvef"
        :state="submitted && errors.has('applicationInfo') ? false : ''"
        :invalid-feedback="errors.first('applicationInfo')"
      >
        <b-form-checkbox
          id="applicationInfo"
          name="applicationInfo"
          v-model="applicationInfo"
          :disabled="!canChangeApplication"
        ></b-form-checkbox>
      </b-form-group>

      <div v-if="applicationInfo">
        <div class="text-primary text-center" style="margin-top: -20px">Eftirfarandi upplýsingar er í boði að skrá fyrir umsóknarvef:</div>
        <b-row>
          <b-col class="pl-0">
            <b-form-group :state="submitted && errors.has('minStudents') ? false : ''" :invalid-feedback="errors.first('minStudents')">
              <label label-for="minStudents">
                Lágmarksfjöldi nema
                <i
                  class="fa fa-info-circle"
                  v-b-tooltip
                  title="Lágmarksfjöldi nema sem þarf til að hópurinn verði kenndur,
                  ef enginn lágmarksfjöldi er skilgreindur kemur sjálfkrafa 0."
                ></i>
              </label>
              <b-form-input
                id="minStudents"
                name="minStudents"
                v-model="form.minStudents"
                type="number"
                min="0"
                :state="submitted && errors.has('minStudents') ? false : ''"
                data-vv-as="lágmarksfjöldi"
              ></b-form-input>
            </b-form-group>
            <b-form-group :state="submitted && errors.has('timing') ? false : ''" :invalid-feedback="errors.first('timing')">
              <label label-for="timing">
                Tímasetning
                <i class="fa fa-info-circle" v-b-tooltip title="Hvenær dags hópur er kenndur"></i>
              </label>
              <b-form-input
                id="timing"
                name="timing"
                v-model="form.timing"
                maxLength="1000"
                type="text"
                :state="submitted && errors.has('timing') ? false : ''"
                data-vv-as="tímasetning"
              ></b-form-input>
            </b-form-group>
            <b-form-group :state="submitted && errors.has('location') ? false : ''" :invalid-feedback="errors.first('location')">
              <label label-for="location">
                Staðsetning
                <i class="fa fa-info-circle" v-b-tooltip title="Hvar verður kennt t.d. ef skóli er í fleiri en einu húsnæði."></i>
              </label>
              <b-form-input
                id="location"
                name="location"
                v-model="form.location"
                maxLength="1000"
                type="text"
                :state="submitted && errors.has('location') ? false : ''"
                data-vv-as="staðsetning"
              ></b-form-input>
            </b-form-group>
            <b-form-group :state="submitted && errors.has('days') ? false : ''" :invalid-feedback="errors.first('days')">
              <label label-for="days">
                Kennsludagar
                <i class="fa fa-info-circle" v-b-tooltip title="Hvaða dag/a verður kennt."></i>
              </label>

              <b-form-input
                id="days"
                name="days"
                v-model="form.days"
                maxLength="1000"
                type="text"
                :state="submitted && errors.has('days') ? false : ''"
                data-vv-as="kennsludagar"
              ></b-form-input>
            </b-form-group>

            <b-form-group :state="submitted && errors.has('contact') ? false : ''" :invalid-feedback="errors.first('contact')"
              ><label label-for="contact">
                Tengiliður
                <i class="fa fa-info-circle" v-b-tooltip title="Hver heldur utan um námið, þetta þarf ekki endilega að vera kennarinn."></i>
              </label>
              <b-form-input
                id="contact"
                name="contact"
                v-model="form.contact"
                maxLength="1000"
                type="text"
                :state="submitted && errors.has('contact') ? false : ''"
                data-vv-as="tengiliður"
              ></b-form-input>
            </b-form-group>
            <b-form-group :state="submitted && errors.has('open') ? false : ''" :invalid-feedback="errors.first('open')"
              ><label label-for="open">
                Hópur opinn til umsóknar
                <i class="fa fa-info-circle" v-b-tooltip title="Má sækja um í hópinn."></i>
              </label>
              <b-form-checkbox id="open" name="open" v-model="form.open"></b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label-for=""
              label=""
              :state="submitted && errors.has('waitlist') ? false : ''"
              :invalid-feedback="errors.first('waitlist')"
            >
              <label label-for="waitlist">
                Biðlisti
                <i
                  class="fa fa-info-circle"
                  v-b-tooltip
                  title="Hversu margir geta verið skráðir á biðlista, ef biðlisti er fullur birtast umsækjanda skilaboð að biðlisti
                  sé fullur og ekki tekið á móti fleiri skráningum."
                ></i>
              </label>
              <b-form-input
                id="waitlist"
                name="waitlist"
                v-model="form.waitlist"
                type="number"
                min="0"
                :state="submitted && errors.has('waitlist') ? false : ''"
                data-vv-as="biðlisti"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              ><label label-for="applicationFrom">
                Opna fyrir umsóknir
                <i class="fa fa-info-circle" v-b-tooltip title="Má sækja um þegar þessi dagssetning hefst."></i>
              </label>
              <datepicker
                id="applicationFrom"
                name="applicationFrom"
                class="datepicker_90_width"
                v-model="form.applicationFrom"
                :language="lang"
                :monday-first="true"
                format="dd.MM.yyyy"
                :typeable="false"
                :clear-button="true"
                ref="applicationFromOpenPickr"
              ></datepicker>
            </b-form-group>
            <b-form-group>
              <label label-for="applicationTo">
                Loka fyrir umsóknir
                <i class="fa fa-info-circle" v-b-tooltip title="Ekki hægt að sækja um þegar þessi dagssetning hefst."></i>
              </label>
              <datepicker
                id="applicationTo"
                name="applicationTo"
                class="datepicker_90_width"
                v-model="form.applicationTo"
                :language="lang"
                :monday-first="true"
                format="dd.MM.yyyy"
                :typeable="false"
                :clear-button="true"
                ref="applicationToOpenPickr"
              ></datepicker>
            </b-form-group>
            <b-form-group>
              <label label-for="payDay">
                Fyrsti greiðsludagur hóps
                <i class="fa fa-info-circle" v-b-tooltip title="Byrjað verður að gjaldfæra greiðslur þennan dag."></i>
              </label>
              <datepicker
                id="payDay"
                name="payDay"
                class="datepicker_90_width"
                v-model="form.payDay"
                :language="lang"
                :monday-first="true"
                format="dd.MM.yyyy"
                :typeable="false"
                :clear-button="true"
                ref="payDayOpenPickr"
              ></datepicker>
            </b-form-group>
            <b-form-group :state="submitted && errors.has('amount') ? false : ''" :invalid-feedback="errors.first('amount')">
              <label label-for="amount">
                Upphæð
                <i
                  class="fa fa-info-circle"
                  v-b-tooltip
                  title="Gjald sem þarf að greiða fyrir námsskeiðið/hópinn, ef skóli er með skilgreint einingagjald
                  á leggst þessi upphæð ofan á það."
                ></i>
              </label>
              <b-form-input
                id="amount"
                name="amount"
                v-model="form.amount"
                type="number"
                min="0"
                :state="submitted && errors.has('amount') ? false : ''"
                data-vv-as="upphæð"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="pl-0">
            <b-form-group :state="submitted && errors.has('description') ? false : ''" :invalid-feedback="errors.first('description')"
              ><label label-for="description">
                Lýsing
                <i class="fa fa-info-circle" v-b-tooltip title="Lýsing á námsskeiðinu/hópnum."></i>
              </label>
              <b-form-textarea
                id="description"
                name="description"
                v-model="form.description"
                maxLength="4000"
                :state="submitted && errors.has('description') ? false : ''"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-form>

    <b-btn variant="primary" class="mt-2" @click="createGroup()">
      <span v-if="groupId">Breyta hóp</span><span v-else>Stofna hóp</span>
    </b-btn>

    <b-btn variant="danger" class="float-right mt-2" v-if="groupId" @click="deleteGroup()"> Eyða hóp </b-btn>
  </Slidebar>
</template>

<script>
import moment from 'moment';
import terms from '@/api/terms';
import groups from '@/api/groups';
import classes from '@/api/classes';
import school from '@/api/school';
import staff from '@/api/staff';

import { mapGetters, mapActions } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';

export default {
  name: 'create-group-slidebar',
  props: ['moduleTermId', 'groupId'],
  components: {
    Slidebar,
    Datepicker,
  },
  computed: {
    canRead() {
      return this.loggedInUserHasReadPermission('nemendahald_hopar');
    },
    afangaskoli() {
      return this.schoolSettings && this.schoolSettings.afangaskoli ? this.schoolSettings.afangaskoli === 1 : false;
    },
    canChangeApplication() {
      return (
        !this.form.minStudents &&
        !this.form.waitlist &&
        !this.form.timing &&
        !this.form.location &&
        !this.form.days &&
        !this.form.contact &&
        !this.form.amount &&
        !this.form.applicationFrom &&
        !this.form.applicationTo &&
        !this.form.payDay &&
        !this.form.description
      );
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {
      lang: is,
      loading: {
        details: false,
        staff: false,
        premises: false,
      },
      submitted: false,
      saving: false,
      item: '',
      applicationInfo: false,
      form: {
        name: '',
        maxStudents: '',
        dateFrom: '',
        dateTo: '',
        class: '',
        classGroup: '',

        minStudents: '',
        waitlist: '',
        timing: '',
        location: '',
        days: '',
        contact: '',
        amount: '',
        applicationFrom: '',
        applicationTo: '',
        open: false,
        payDay: '',
        description: '',

        teachers: [],
      },
      classes: [],
      staffList: [],
      yesNo: [
        { id: 1, label: 'Já' },
        { id: 0, label: 'Nei' },
      ],
    };
  },
  methods: {
    async loadGroup() {
      try {
        this.loading.details = true;
        const response = await groups.getGroupDetails(this.groupId);
        this.item = response.data;
        this.form.name = this.item.hopur;
        this.form.maxStudents = this.item.fjoldi_nema;
        this.form.dateFrom = this.item.hopur_dags_fra ? moment(this.item.hopur_dags_fra).toDate() : '';
        this.form.dateTo = this.item.hopur_dags_til ? moment(this.item.hopur_dags_til).toDate() : '';
        this.form.class = '';
        this.form.classGroup = this.item.bekkjarhopur ? this.yesNo.find(x => x.id === this.item.bekkjarhopur) : 0;

        this.form.minStudents = this.item.fj_umsokn_min;
        this.form.waitlist = this.item.bidlisti;
        this.form.timing = this.item.timasetning;
        this.form.location = this.item.stadsetning;
        this.form.days = this.item.kennsludagar;
        this.form.contact = this.item.tengilidur;
        this.form.open = this.item.hopur_opinn === 1;
        this.form.amount = this.item.upphaed;
        this.form.applicationFrom = this.item.umsokn_dags_fra ? moment(this.item.umsokn_dags_fra).toDate() : '';
        this.form.applicationTo = this.item.umsokn_dags_til ? moment(this.item.umsokn_dags_til).toDate() : '';
        this.form.payDay = this.item.fyrsti_greidsludags;
        this.form.description = this.item.lysing;
        if (!this.canChangeApplication) {
          this.applicationInfo = true;
        }
        this.loadClasses();
        this.loadTeachers();
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.details = false;
      }
    },
    async loadModule() {
      try {
        if (this.canRead) {
          this.item = '';
          const response = await terms.getTermModule(this.moduleTermId);
          this.item = response.data;
          if (this.item.max_fj_nema) {
            this.form.maxStudents = this.item.max_fj_nema;
          }
          this.loadClasses();
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadClasses() {
      try {
        const response = await classes.getClassList({
          term: this.item.onn_id,
        });
        this.classes = response.data.items;
        if (this.groupId && this.item.bekkur_id) {
          this.form.class = this.classes.find(x => x.bekkur_id === this.item.bekkur_id);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async createGroup() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        const item = {
          annir_afangar_id: this.moduleTermId,
          hopur: this.form.name,
          fjoldi_nema: this.form.maxStudents,
          dags_fra: this.form.dateFrom ? moment(this.form.dateFrom).format('DD.MM.YYYY') : '',
          dags_til: this.form.dateTo ? moment(this.form.dateTo).format('DD.MM.YYYY') : '',
          bekkjarhopur: this.form.classGroup ? this.form.classGroup.id : 0, // alltaf 0/Nei ef ekkert er valið
          bekkur_id: this.form.class ? this.form.class.bekkir_id : '',
          fj_umsokn_min: this.form.minStudents,
          bidlisti: this.form.waitlist,
          timasetning: this.form.timing,
          stadsetning: this.form.location,
          kennsludagar: this.form.days,
          tengilidur: this.form.contact,
          hopur_opinn: this.form.open ? 1 : 0,
          upphaed: this.form.amount,
          umsokn_dags_fra: this.form.applicationFrom ? moment(this.form.applicationFrom).format('DD.MM.YYYY') : '',
          umsokn_dags_til: this.form.applicationTo ? moment(this.form.applicationTo).format('DD.MM.YYYY') : '',
          fyrsti_greidsludags: this.form.payDay ? moment(this.form.payDay).format('DD.MM.YYYY') : '',
          lysing: this.form.description,
          kennarar: this.form.teachers.map(x => x.starfsm_id).join(','),
        };

        if (this.groupId) {
          await groups.updateGroup(this.groupId, item);
          this.displaySuccess('Hóp breytt');
        } else {
          await groups.createGroup(item);
          this.displaySuccess('Hópur stofnaður');
        }

        this.$emit('closeAndReloadSlidebar');
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);

        if (e.response && e.response.data && e.response.data.error) {
          this.displayError(e.response.data.error);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } finally {
        this.saving = false;
      }
    },
    async deleteGroup() {
      try {
        const conf = confirm(`Ert þú viss um að þú viljir eyða hópnum ${this.item.namsgrein_afanganr}(${this.item.hopur})?`); // eslint-disable-line
        if (conf) {
          await groups.updateGroup(this.groupId, { delete: 1 });
          this.displaySuccess('Hópi eytt');

          this.$emit('closeAndReloadSlidebar');
        }
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);

        if (e.response && e.response.data && e.response.data.error) {
          this.displayError(e.response.data.error);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    async loadTeachers() {
      try {
        this.loading.premises = true;
        this.form.teachers = [];
        const response = await school.getGroupTeachers(this.groupId);
        const teachers = response.data.hopar_kennarar.map(x => x.starfsm_id);
        this.form.teachers = this.staffList.filter(x => teachers.indexOf(x.starfsm_id) > -1);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.premises = false;
      }
    },
    async loadStaff() {
      try {
        this.loading.staff = true;
        const response = await staff.getStaffList();
        this.staffList = response.data.items;
        this.staffList.forEach(x => {
          const staffName = x.starfsheiti ? ` - ${x.starfsheiti}` : '';
          this.$set(x, 'label', `${x.nafn} (${x.skammst})${staffName}`);
        });
        if (this.groupId) {
          this.loadTeachers();
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.staff = false;
      }
    },
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.$nextTick(() => {
      if (this.$refs.focusInput) {
        this.$refs.focusInput.focus();
      }
    });
    if (this.groupId) {
      this.loadGroup();
      this.loadStaff();
    } else {
      this.loadModule();
      this.loadStaff();
    }
  },
};
</script>

<style lang="scss"></style>
