<template>
  <div>
    <b-modal
      id="commentModal"
      title="Breyta athugasemd"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Vista"
      cancel-title="Hætta við"
      @ok.prevent="validateBeforeSubmit"
      ref="commentModal"
    >
      <div>
        <table class="table">
          <tr>
            <td>Nemandi</td>
            <td>{{ item.nafn }} {{ item.kennitala }}</td>
          </tr>
          <tr>
            <td>Núverandi braut</td>
            <td>{{ item.current_course }}</td>
          </tr>
          <tr>
            <td>Umbeðin braut</td>
            <td>{{ item.change_course }}</td>
          </tr>
          <tr>
            <td>Staðfest braut</td>
            <td>{{ item.confirmed_course }}</td>
          </tr>
          <tr>
            <td>Athugasemd nema</td>
            <td>{{ item.commentfromstudent }}</td>
          </tr>
          <tr>
            <td>Atugasemd afgreiðslu</td>
            <td>
              <b-form-group label-for="comment">
                <b-form-textarea v-model="comment" class="w-100" />
              </b-form-group>
            </td>
          </tr>
        </table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import requests from '@/api/requests';
import { mapActions } from 'vuex';

export default {
  name: 'comment-modal',
  data() {
    return {
      item: '',
      comment: '',
    };
  },
  methods: {
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          await requests.updateChangeCourseComment(this.item.course_change_id, {
            school_comment: this.comment.length > 0 ? this.comment : ' ',
          });
          this.displaySuccess('Athugasemd breytt.');
          this.$emit('successful', {});
          this.$refs.commentModal.hide();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    show(item) {
      this.item = item;
      this.comment = this.item.commentfromschool === ' ' ? '' : this.item.commentfromschool;
      this.$refs.commentModal.show();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
<style lang="scss">
.error-modal-description {
  margin-top: 30px;
}
</style>
