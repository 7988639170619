<template>
  <div>
    <Loader v-if="loading.application"></Loader>
    <b-row>
      <b-col class="pl-0"> Dags.: {{ application.dags_umsoknar | moment('DD.MM.YYYY HH:mm') }} </b-col>
      <b-col
        ><span v-if="application && application.er_nynemi === 0">
          Staða: <strong>{{ application.stada_heiti }}</strong>
        </span></b-col
      >
      <b-col></b-col>
    </b-row>
    <h5 class="student-title">Umsækjandi</h5>
    <b-row class="student-settings-border">
      <b-col class="pl-0">
        <div>
          <label>Nafn</label>
          <div class="text">
            <span>{{ application.nafn }}</span>
          </div>
        </div>
        <div>
          <label>Kennitala</label>
          <div class="text">
            <span>{{ application.ktala }}</span>
          </div>
        </div>
        <div>
          <label>Netfang</label>
          <div class="text">
            <span>{{ application.netfang }}</span>
          </div>
        </div>
        <div>
          <label>Farsími</label>
          <div class="text">
            <span>{{ application.farsimi }}</span>
          </div>
        </div>
        <div>
          <label>Kyn</label>
          <div class="text">
            <span>{{ application.kyn }}</span>
          </div>
        </div>
      </b-col>
      <b-col class="pl-0">
        <div>
          <label>Heimilisfang</label>
          <div class="text">
            <span>{{ application.heimilisfang }} {{ application.postnumer }} {{ application.heiti_poststodvar }}</span>
          </div>
        </div>
        <div>
          <label>Sími lögheimilis</label>
          <div class="text">
            <span>{{ application.simi_lh }}</span>
          </div>
        </div>
        <div>
          <label>Aðsetur</label>
          <div class="text">
            <span>{{ application.adsetur }} {{ application.postnr_as }} {{ application.adsetur_poststod }}</span>
          </div>
        </div>
        <div>
          <label>Sími aðseturs</label>
          <div class="text">
            <span>{{ application.simi_as }}</span>
          </div>
        </div>
        <div>
          <label>Ríkisfang</label>
          <div class="text">
            <span>{{ application.rikisfang }}</span>
          </div>
        </div>
      </b-col>
      <b-col class="pl-0" v-if="!showDetails">
        <div>
          <label>Vinnusími</label>
          <div class="text">
            <span>{{ application.simi_vs }}</span>
          </div>
        </div>
        <div>
          <label>Yngri en 18</label>
          <div class="text">
            <span><span v-if="application.aldur < 18">Já</span><span v-else>Nei</span></span>
          </div>
        </div>
      </b-col>
      <b-col class="pl-0" v-if="showDetails">
        <div>
          <label>Framhaldsskóli frá</label>
          <div class="text">
            <span>{{ application.framhaldsskoli_fra }}</span>
          </div>
        </div>
        <div>
          <label>Staða umsækjanda</label>
          <div class="text">
            <span>
              <span v-if="application.stada_umsaekjanda === 0">Hvorugt á við</span>
              <span v-if="application.stada_umsaekjanda === 1">Er í námi í dagskóla á framhaldsskólastigi</span>
              <span v-if="application.stada_umsaekjanda === 2">Án atvinnu</span>
              <span v-if="[0, 1, 2].indexOf(application.stada_umsaekjanda) === -1">Ekki skráð</span>
            </span>
          </div>
        </div>
        <div v-if="application.namsstada === 0 || application.namsstada === 1">
          <label>Upplýsingar um námsstöðu eru.</label>
          <div class="text">
            <span>
              <span v-if="application.namsstada === 0">rangar</span>
              <span v-if="application.namsstada === 1">réttar</span>
            </span>
          </div>
        </div>
        <div v-if="application.namsstada_aths">
          <label>Skýring</label>
          <div class="text">
            <span>{{ application.namsstada_aths }}</span>
          </div>
        </div>
        <div v-if="currentStudies.length > 0">
          <table class="table no-padding no-border" style="font-size: 12px">
            <thead>
              <tr>
                <th>Skóli <i class="fa fa-info-circle" title="Umsækjandi er nemandi í"></i></th>
                <th>Braut</th>
                <th>Lokið <i class="fa fa-info-circle" title="Fjöldi eininga lokið"></i></th>
                <th>Ein. <i class="fa fa-info-circle" title="Fjöldi eininga á núverandi kennslutímabili"></i></th>
              </tr>
            </thead>
            <tr v-for="(c, idx) in currentStudies" :key="idx">
              <td :title="c.skoli_heiti" v-b-tooltip>{{ c.skoli_heiti.substr(0, 20) }}<span v-if="c.skoli_heiti.length > 20">..</span></td>
              <td>{{ c.braut }}</td>
              <td>{{ c.ein_lokid }}</td>
              <td>{{ c.ein_nu_onn }}</td>
            </tr>
          </table>
        </div>
      </b-col>
    </b-row>
    <div v-if="application.rafraen_umsokn === 1" class="mb-3">
      Rafræn umsókn sem kom frá: <span v-if="application.is_umsoknarvefur">Umsóknarvefur Innu</span><span v-else>Menntagátt</span>
    </div>
    <h5 class="student-title">Nám</h5>
    <b-row class="student-settings-border">
      <b-col class="pl-0">
        <div>
          <label>Umsóknarbraut</label>
          <div class="text">
            <span>{{ application.braut_heiti }} {{ application.braut_kodi }}</span>
          </div>
        </div>
        <div v-if="showDetails">
          <div>
            <label>Innritunarbraut</label>
            <div class="text">
              <span>{{ application.braut_innrit_heiti }} {{ application.braut_innrit_kodi }}</span>
            </div>
          </div>
          <div>
            <label>Braut 2</label>
            <div class="text">
              <span>{{ application.braut_vara_heiti }} {{ application.braut_vara_kodi }}</span>
            </div>
          </div>
        </div>
      </b-col>
      <b-col>
        <div v-if="showDetails">
          <label>Önn</label>
          <div class="text">
            <span>{{ application.onn_eink }}</span>
          </div>
        </div>
        <div>
          <label>Undirskóli</label>
          <div class="text">
            <span>{{ application.undirsk_heiti }}</span>
          </div>
        </div>
        <div v-if="showDetails">
          <label>Þriðja mál</label>
          <div class="text">
            <span>{{ application.thridja_mal }}</span>
          </div>
        </div>
      </b-col>
      <b-col>
        <b-row v-if="showDetails">
          <b-col class="pl-0">
            <div>
              <label>Táknmálstúlkun</label>
              <div class="text">
                <span><yes-no :item="application.taknmalstulkun"></yes-no></span>
              </div>
            </div>
            <div>
              <label>Hjólastólaaðgengi</label>
              <div class="text">
                <span><yes-no :item="application.hjolastolaadgengi"></yes-no></span>
              </div>
            </div>
            <div>
              <label>Mötuneyti</label>
              <div class="text">
                <span><yes-no :item="application.motuneyti"></yes-no></span>
              </div>
            </div>
          </b-col>
          <b-col>
            <div class="d-inline-block">
              <label>Heimavist</label>
              <div class="text">
                <span><yes-no :item="application.heimavist"></yes-no></span>
              </div>
            </div>
            <div>
              <label>Skólabíll</label>
              <div class="text">
                <span><yes-no :item="application.skolabill"></yes-no></span>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <h5 class="student-title" v-if="showDetails">Greiningar og athugasemdir</h5>
    <b-row class="student-settings-border">
      <b-col class="pl-0">
        <div>
          <label>Athugasemd</label>
          <div class="text">
            <span>{{ application.aths }}</span>
          </div>
        </div>
        <div v-if="showDetails">
          <label>Námsörðugleikar</label>
          <div class="text">
            <span>{{ application.namsordugleikar }}</span>
          </div>
        </div>
      </b-col>
      <b-col v-if="showDetails">
        <div>
          <label>Fötlunargreining liggur fyrir</label>
          <div class="text">
            <span>
              <span v-if="application.fotlunargreining === 0">Nei</span>
              <span v-if="application.fotlunargreining === 1">Já</span>
            </span>
          </div>
        </div>
        <div>
          <label>Fötlun</label>
          <div class="text">
            <span>{{ application.fotlun }}</span>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-tabs content-class="mt-3" v-model="tabIndex">
      <b-tab title="Grunnskólaeinkunnir">
        <b-row>
          <b-col class="pl-0"> Grunnskóli frá: {{ application.grunnskoli }} </b-col>
          <b-col> Útskriftarár grunnskóla: {{ application.grunnskoli_ar }} </b-col>
        </b-row>
        <div class="text-danger" v-if="application.annad_nordurlandamal === 1 && application.grunnskolaeink.length > 0">
          Valið norðurlandamál á umsókn er annað en í grunnskóla
        </div>
        <div v-if="application && application.grunnskolaeink.length === 0" class="mt-3"><i>Engar grunnskólaeinkunnir fundust.</i></div>
        <table class="table less-padding" style="font-size: 13px" v-if="application && application.grunnskolaeink.length > 0">
          <thead>
            <tr>
              <th>Fag</th>
              <th>Skólaeinkunn</th>
              <th>Áætlun</th>
              <th>Samræmd einkunn</th>
              <th>Umsögn</th>
              <th>Matsviðmið</th>
            </tr>
          </thead>
          <tr v-for="(g, idx) in application.grunnskolaeink" :key="idx">
            <td>{{ g.namsgrein }}</td>
            <td>
              <span v-if="g.einkunn_skoli">{{ g.einkunn_skoli }}</span
              ><span v-else>{{ g.einkunn_skoli_a }}</span>
            </td>
            <td>{{ g.aetlun_heiti }}</td>
            <td>{{ g.einkunn_samraemd }}</td>
            <td>{{ g.umsogn }}</td>
            <td>{{ g.matsvidmid }} {{ g.matsvidmid_uri }}</td>
          </tr>
        </table>
      </b-tab>
      <b-tab title="Mat">
        <div v-if="application.stada_id === 1 || application.stada_id === 5">
          <table class="table less-padding" style="font-size: 13px; width: 50%" v-if="gratuations.length > 0">
            <thead>
              <tr>
                <th>Útskriftir frá skóla</th>
                <th>Braut</th>
                <th>Önn</th>
                <th>Dags útskriftar</th>
              </tr>
            </thead>
            <tr v-for="(g, idx) in gratuations" :key="idx">
              <td>{{ g.skoli_heiti }} {{ g.undirsk_heiti }}</td>
              <td>{{ g.heiti }}</td>
              <td>{{ g.onn_heiti }}</td>
              <td>{{ g.dags_utskr }}</td>
            </tr>
          </table>

          <table class="table less-padding" style="font-size: 13px">
            <thead>
              <tr>
                <th colspan="7">Námsferill</th>
                <th colspan="8">Metið sem</th>
              </tr>
              <tr>
                <th>Skóli</th>
                <th>Dags.lokið</th>
                <th>Einkunn</th>
                <th>Einingar</th>
                <th>Þrep</th>
                <th>Áfangi</th>
                <th>Staða</th>
                <th>Áfangi</th>
                <th>Einkunn</th>
                <th>Uppbygging</th>
                <th>Önn</th>
                <th>Staða</th>
                <th>Skóli</th>
                <th>Dags.lokið</th>
                <th>Athugasemd</th>
              </tr>
            </thead>
            <tr v-for="(e, idx) in evaluations" :key="idx">
              <td>{{ e.skoli_fra }}</td>
              <td>{{ e.dags_lokid_fra | moment('DD.MM.YYYY') }}</td>
              <td>{{ e.einkunn_fra }}</td>
              <td>{{ e.einingar_fra }}</td>
              <td>{{ e.trep_fra }}</td>
              <td>{{ e.afangi_fra }}</td>
              <td>{{ e.stada_fra }}</td>
              <td>{{ e.afangi_til }}</td>
              <td>{{ e.einkunn_til }}</td>
              <td>{{ e.uppbygging_til }}</td>
              <td>{{ e.onn_til }}</td>
              <td>{{ e.stada_til }}</td>
              <td>{{ e.dags_lokid_til | moment('DD.MM.YYYY') }}</td>
              <td>{{ e.aths_til }}</td>
              <td>{{ e.skoli_fra_opid }}</td>
            </tr>
          </table>
        </div>
        <div v-else>
          <div>
            Námsferil úr öðrum skólum og rafrænt mat er aðeins hægt að skoða og vinna með í umsókn ef umsókn er í stöðunni Í vinnslu.
          </div>
          <div>Ef umsókn hefur verið samþykkt þá skal skoða flipann Mat á nemendaspjaldi.</div>
        </div>
      </b-tab>
      <b-tab title="Skóli / Varaskólar">
        <table class="table less-padding" style="font-size: 13px">
          <thead>
            <tr>
              <th>Skóli</th>
              <th>Undirskóli</th>
              <th>Braut</th>
              <th>Braut 2</th>
              <th>Innritunarbraut</th>
              <th>Önn</th>
              <th>Forgangur</th>
              <th>Staða</th>
              <th>Dags.</th>
            </tr>
          </thead>
          <tr v-for="(s, idx) in application.skolar" :key="idx">
            <td>{{ s.skoli_heiti }}</td>
            <td>{{ s.undirsk_heiti }}</td>
            <td>{{ s.braut_heiti }} {{ s.braut_kodi }}</td>
            <td>{{ s.braut_vara_heiti }} {{ s.braut_vara_kodi }}</td>
            <td>{{ s.braut_innrit_heiti }} {{ s.braut_innrit_kodi }}</td>
            <td>{{ s.onn_eink }}</td>
            <td>{{ s.forgangur }}</td>
            <td>{{ s.stada_heiti }}</td>
            <td>{{ s.dags_stodu | moment('DD.MM.YYYY') }}</td>
          </tr>
        </table>
      </b-tab>
      <b-tab title="Tungumál">
        <table>
          <tr>
            <th>Móðurmál 1:</th>
            <td>{{ application.modurmal }}</td>
          </tr>
          <tr>
            <th>Móðurmál 2:</th>
            <td>{{ application.modurmal_2 }}</td>
          </tr>
          <tr>
            <th>Norðurlandamál:</th>
            <td>{{ application.thridja_mal }}</td>
          </tr>
          <tr>
            <th>Fjórða mál:</th>
            <td>{{ application.fjorda_mal }}</td>
          </tr>
        </table>
      </b-tab>
      <b-tab title="Aðstandendur">
        <div class="mb-2"><strong>Aðstandendur</strong></div>
        <table class="table less-padding" style="font-size: 13px">
          <thead>
            <tr>
              <th>Nafn</th>
              <th>Kennitala</th>
              <th>Heimilisfang</th>
              <th>Sími</th>
              <th>Farsími</th>
              <th>Netfang</th>
              <th>Forráðamaður</th>
              <th>Tengsl</th>
              <th>Röðun</th>
            </tr>
          </thead>
          <tr v-for="(r, idx) in relatives" :key="idx">
            <td>{{ r.nafn }}</td>
            <td>{{ r.ktala }}</td>
            <td>{{ r.postnr_adsetur }}</td>
            <td>{{ r.simi_as }}</td>
            <td>{{ r.simi_gsm }}</td>
            <td>{{ r.netfang }}</td>
            <td><yes-no :item="r.forrad_adstand"></yes-no></td>
            <td>{{ r.heiti }}</td>
            <td>{{ r.rodun }}</td>
          </tr>
        </table>
        <div v-if="guardians.length > 0">
          <div class="mb-2"><strong>Aðstandendur skv. þjóðskrá</strong></div>
          <table class="table less-padding" style="font-size: 13px">
            <thead>
              <tr>
                <th>Nafn</th>
                <th>Kennitala</th>
                <th>Heimilisfang</th>
                <th>Fjölskyldunúmer</th>
              </tr>
            </thead>
            <tr v-for="(g, idx) in guardians" :key="idx">
              <td>{{ g.nafn }}</td>
              <td>{{ g.kennitala }}</td>
              <td>{{ g.heimilisfang }}</td>
              <td>{{ g.kennitala_fjolskyldunumer }}</td>
            </tr>
          </table>
        </div>
      </b-tab>
      <b-tab title="Samskipti">
        <table class="table less-padding" style="font-size: 13px">
          <thead>
            <tr>
              <th>Dagsetning</th>
              <th>Athugasemd</th>
              <th>Skráð af</th>
            </tr>
          </thead>
          <tr v-for="(c, idx) in communications" :key="idx">
            <td>{{ c.dags | moment('DD.MM.YYYY HH:mm') }}</td>
            <td>{{ c.aths }}</td>
            <td>{{ c.skrad_af }}</td>
          </tr>
        </table>
      </b-tab>
      <!--b-tab :title="`Viðhengi (${attachments.length})`">
        <table class="table less-padding" style="font-size: 13px">
          <thead>
            <tr>
              <th>Viðhengi</th>
              <th>Skýring</th>
            </tr>
          </thead>
          <tr v-for="(a, idx) in attachments" :key="idx">
            <td>{{ a.vidhengi_heiti }}</td>
            <td>{{ a.skyring }}</td>
          </tr>
        </table>
      </b-tab-->
      <b-tab title="Afgreiðslusaga">
        <table class="table less-padding" style="font-size: 13px">
          <thead>
            <tr>
              <th>Skóli</th>
              <th>Forgangur</th>
              <th>Staða umsóknar</th>
              <th>Dags. stöðu</th>
              <th>Framkvæmt af</th>
            </tr>
          </thead>
          <tr v-for="(a, idx) in application.afgreidslusaga" :key="idx">
            <td>{{ a.skoli_heiti }}</td>
            <td>{{ a.forgangur }}</td>
            <td>{{ a.stada_heiti }}</td>
            <td>{{ a.dags_fra | moment('DD.MM.YYYY HH:mm:ss') }}</td>
            <td>{{ a.framkvaemt_af }}</td>
          </tr>
        </table>
        <div style="font-size: 12px">
          <i class="fa fa-info-circle"></i> Ath. Ef "Framkvæmt af" í afgreiðsluferli er óútfyllt þá hefur umsókn að öllum líkindum verið
          meðhöndluð í miðlægri innritunarvinnslu
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import applications from '@/api/applications';
import students from '@/api/students';
import { mapGetters, mapActions } from 'vuex';
import Loader from '@/components/common/Loader.vue';
import YesNo from '@/components/common/YesNo.vue';

export default {
  name: 'student-application-list',
  props: ['applicationSchoolId', 'slidebar'],
  components: {
    Loader,
    YesNo,
  },
  computed: {
    showDetails() {
      return this.schoolSettings && (this.schoolSettings.tegund_skola_id === 1 || this.schoolSettings.tegund_skola_id === 2);
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('nemendahald_nemendur');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('nemendahald_nemendur');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {
      loading: {
        application: false,
        evaluation: false,
        communication: false,
        attachment: false,
        currentStudies: false,
        gratuation: false,
        relatives: false,
        guardians: false,
      },
      tabIndex: 0,
      application: '',
      evaluations: [],
      communications: [],
      attachments: [],
      currentStudies: [],
      gratuations: [],
      relatives: [],
      guardians: [],
    };
  },
  methods: {
    async loadEvaluation() {
      try {
        this.loading.evaluation = true;
        const response = await students.getEvaluation(this.application.ktala, { applicationId: this.application.umsokn_id });
        this.evaluations = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.evaluation = false;
      }
    },
    async loadGratuations() {
      try {
        this.loading.gratuation = true;
        const response = await applications.getApplicantGratuations(this.application.umsokn_id);
        this.gratuations = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.gratuation = false;
      }
    },
    async loadAttachments() {
      try {
        this.loading.attachments = true;
        const response = await applications.getApplicationAttahments(this.application.umsokn_id);
        this.attachments = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.attachments = false;
      }
    },
    async loadCommuncation() {
      try {
        this.loading.communication = true;
        const response = await applications.getApplicationCommunication(this.applicationSchoolId);
        this.communications = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.communication = false;
      }
    },
    async loadCurrentStudies() {
      try {
        this.loading.currentStudies = true;
        const response = await applications.getApplicantCurrentStudies(this.application.umsokn_id);
        this.currentStudies = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.currentStudies = false;
      }
    },
    async loadRelatives() {
      try {
        this.loading.relatives = true;
        const response = await applications.getApplicantRelatives(this.application.umsokn_id);
        this.relatives = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.relatives = false;
      }
    },
    async loadGuardians() {
      try {
        this.loading.guardians = true;
        const response = await applications.getApplicantGuardians(this.application.umsokn_id);
        this.guardians = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.guardians = false;
      }
    },
    async load() {
      try {
        this.loading.application = true;
        const response = await applications.getApplicationDetail(this.applicationSchoolId);
        this.application = response.data;
        if (this.application.stada_id === 1 || this.application.stada_id === 5) {
          this.loadEvaluation();
          this.loadGratuations();
        }
        if (this.application.aldur < 18) {
          this.loadGuardians();
        }
        this.loadAttachments();
        this.loadCurrentStudies();
        this.loadRelatives();
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.application = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    if (!this.showDetails) {
      this.tabIndex = 2;
    }
    this.load();
    this.loadCommuncation();
  },
};
</script>
<style lang="scss"></style>
