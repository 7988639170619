<template>
  <page>
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <PageTitle title="Námsgreinar"></PageTitle>

    <table class="table less-padding table-sticky-header table-hover" style="font-size: 13px; margin-top: -20px">
      <thead>
        <tr class="table-search">
          <td colspan="2"><b-form-input v-model="search.code" class="search" placeholder="Auðk" ref="focusInput" /></td>
          <td><b-form-input v-model="search.name" class="search" placeholder="Heiti" /></td>
          <td colspan="3">
            <div class="d-inline-block mr-2">
              <div>Sýna óvirk</div>
              <div><b-form-checkbox v-model="search.inactive"></b-form-checkbox></div>
            </div>
            <div class="d-inline-block">
              <div v-b-tooltip title="Eingöngu sýna námsgreinar sem ekki er búið að stofna áfanga fyrir">Án áfanga</div>
              <div><b-form-checkbox v-model="search.noModules"></b-form-checkbox></div>
            </div>
          </td>
          <td colspan="6">
            <div class="float-right">
              <CustomButton type="create" title="Ný námsgrein" @click.native="openSlidebar()" v-if="canEdit"/>
            </div>
          </td>
        </tr>
        <tr>
          <th style="white-space: nowrap">
            <b-form-checkbox v-model="allSelected" @input="updateAll()" class="d-inline-block"></b-form-checkbox>
            <b-btn
              variant="primary"
              v-if="getSelected.length > 0"
              @click="openMassEditSlidebar()"
              class="btn-xs d-inline-block"
              style="font-size: 10px"
              ><i class="fa fa-pencil"></i
            ></b-btn>
          </th>
          <th>
            <LinkOrder title="Auðkenni" :filteredList="getList" :list="list" column="kodi" />
          </th>
          <th>
            <LinkOrder title="Heiti" :filteredList="getList" :list="list" column="heiti" secondColumn="kodi" />
            ({{ this.getList.length }})
          </th>
          <th>
            <LinkOrder title="Heiti (enska)" :filteredList="getList" :list="list" column="heiti_ens" secondColumn="kodi" />
          </th>
          <th>
            <LinkOrder title="Heiti þgf" :filteredList="getList" :list="list" column="heiti_thgf" secondColumn="kodi" />
          </th>
          <th title="Norðurlandamál" v-b-tooltip>
            <LinkOrder title="Norðurl." :filteredList="getList" :list="list" column="annad_mal" secondColumn="kodi" />
          </th>
          <th title="Þriðja mál" v-b-tooltip>
            <LinkOrder title="Þriðja" :filteredList="getList" :list="list" column="thridja_mal" secondColumn="kodi" />
          </th>
          <th title="Fjórða mál" v-b-tooltip>
            <LinkOrder title="Fjórða" :filteredList="getList" :list="list" column="fjorda_mal" secondColumn="kodi" />
          </th>
          <th>
            <LinkOrder title="Yfirgrein" :filteredList="getList" :list="list" column="heiti_mal" secondColumn="kodi" />
          </th>
          <th>
            <LinkOrder title="Virk" :filteredList="getList" :list="list" column="virkur" secondColumn="kodi" />
          </th>
          <th title="Samræmd námsgrein" v-b-tooltip>
            <LinkOrder title="Samræmd" :filteredList="getList" :list="list" column="samr_heiti" secondColumn="kodi" />
          </th>
          <th>
            <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="cursor-pointer float-right">
              <i class="fa fa-file-excel-o excelText"></i>
            </download-csv>
          </th>
        </tr>
      </thead>
      <tbody>
        <TableRowLoader v-if="loading" colspan="12" :center="true" />
        <TableRowEmpty v-if="!loading && this.list.length === 0" colspan="12" :center="true" />
        <tr v-for="item in getPaginatedList" :key="item.namsgrein_id">
          <td style="width: 10px">
            <b-form-checkbox v-model="item.selected" v-if="canEdit"></b-form-checkbox>
          </td>
          <td style="width: 100px">
            <span v-if="canEdit">
              <b-link @click="openSlidebar(item)"> {{ item.kodi }}</b-link>
              <b-btn
                class="btn-xs"
                variant="primary"
                @click="openSlidebar(item)"
                v-if="!item.kodi || item.kodi.length === 0"
                style="font-size: 13px"
                ><i class="fa fa-pencil"></i
              ></b-btn>
            </span>
            <span v-else>
              {{ item.kodi }}
            </span>
          </td>
          <td>{{ item.heiti }}</td>
          <td>{{ item.heiti_ens }}</td>
          <td>{{ item.heiti_thgf }}</td>
          <td><yes-no :item="item.annad_mal"></yes-no></td>
          <td><yes-no :item="item.thridja_mal"></yes-no></td>
          <td><yes-no :item="item.fjorda_mal"></yes-no></td>
          <td>{{ item.heiti_mal }}</td>
          <td><yes-no :item="item.virkur"></yes-no></td>
          <td>{{ item.samr_heiti }}</td>
          <td>
            <b-btn
              class="btn-xs float-right"
              variant="primary"
              v-b-tooltip
              title="Skoða áfanga"
              @click.prevent="openModuleSlidebar(item)"
              style="font-size: 13px"
            >
              <i class="fa fa-book" />
            </b-btn>
            <b-btn
              class="btn-xs float-right mr-2"
              variant="primary"
              v-b-tooltip
              title="Skoða sögu"
              @click.prevent="openHistorySlidebar(item)"
              style="font-size: 13px"
            >
              <i class="fa fa-history" />
            </b-btn>
          </td>
        </tr>
      </tbody>
    </table>
    <Pagination
      :currentPage="currentPage"
      :totalRows="getList.length"
      :perPage="perPage"
      v-if="list.length > 20"
      @change-page="changePage"
      @change-limit="changeLimit"
    ></Pagination>
    <subject-slidebar
      v-if="showSlidebar"
      :item="selectedItem"
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
    >
    </subject-slidebar>
    <subject-mass-edit-slidebar
      v-if="showMassEditSlidebar"
      :items="getSelected"
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
    >
    </subject-mass-edit-slidebar>
    <subject-history-slidebar v-if="showHistorySlidebar" :item="selectedItem" @closeSlidebar="closeSlidebar"> </subject-history-slidebar>

    <module-slidebar v-if="showModuleSlidebar" :item="selectedItem" type="subject" @closeSlidebar="closeSlidebar" :extraLarge="true">
    </module-slidebar>
  </page>
</template>

<script>
import moment from 'moment';
import curriculum from '@/api/curriculum';
import { mapActions, mapGetters } from 'vuex';

import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import PageTitle from '@/components/common/PageTitle.vue';

import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';
import CustomButton from '@/components/common/CustomButton.vue';
import Pagination from '@/components/common/Pagination.vue';
import YesNo from '@/components/common/YesNo.vue';

import SubjectSlidebar from '@/components/curriculum/subjects/SubjectSlidebar.vue';
import SubjectMassEditSlidebar from '@/components/curriculum/subjects/SubjectMassEditSlidebar.vue';
import SubjectHistorySlidebar from '@/components/curriculum/subjects/SubjectHistorySlidebar.vue';
import ModuleSlidebar from '@/components/curriculum/modules/ModuleSlidebar.vue';

export default {
  name: 'subjects',
  components: {
    Breadcrumbs,
    PageTitle,
    TableRowLoader,
    TableRowEmpty,
    LinkOrder,
    CustomButton,
    Pagination,
    YesNo,
    SubjectSlidebar,
    ModuleSlidebar,
    SubjectMassEditSlidebar,
    SubjectHistorySlidebar,
  },
  computed: {
    getSelected() {
      return this.getList.filter(x => x.selected);
    },
    getList() {
      return this.list
        .filter(x => this.search.name.length === 0 || (x.heiti && x.heiti.toUpperCase().startsWith(this.search.name.toUpperCase())))
        .filter(x => this.search.code.length === 0 || (x.kodi && x.kodi.toUpperCase().startsWith(this.search.code.toUpperCase())))
        .filter(x => this.search.inactive || x.virkur === 1)
        .filter(x => !this.search.noModules || x.has_modules === 0);
    },
    getPaginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.getList.slice(start, end);
    },
    getExcelData() {
      const newList = [
        [],
        ['Námsgreinar'],
        [],
        [
          'Auðkenni',
          'Heiti',
          'Heiti (enska)',
          'Heiti þgf',
          'Norðurlandamál',
          'Þriðja mál',
          'Fjórða mál',
          'Yfirgrein',
          'Virkt',
          'Samræmt heiti',
        ],
        ...this.getList.map(x => [
          x.kodi,
          x.heiti,
          x.heiti_ens,
          x.heiti_thgf,
          x.annad_mal === 1 ? 'Já' : 'Nei',
          x.thridja_mal === 1 ? 'Já' : 'Nei',
          x.fjorda_mal === 1 ? 'Já' : 'Nei',
          x.heiti_mal,
          x.virkur === 1 ? 'Já' : 'Nei',
          x.samr_heiti,
        ]),
      ];
      return newList;
    },
    getExcelFileName() {
      return `namsgreinar_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_namsgreinar');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      breadcrumbs: [
        { name: 'Forsíða', route: 'Dashboard' },
        { name: 'Námskrá', route: '' },
        { name: 'Námsgreinar', route: 'Subjects' },
      ],
      perPage: 20,
      currentPage: 1,
      list: [],
      loading: false,
      search: {
        code: '',
        name: '',
        inactive: false,
        noModules: false,
      },
      showSlidebar: false,
      showMassEditSlidebar: false,
      showModuleSlidebar: false,
      showHistorySlidebar: false,
      allSelected: false,
    };
  },
  methods: {
    async loadList() {
      try {
        this.loading = true;
        this.allSelected = false;
        const response = await curriculum.getSubjectsList();
        this.list = response.data.items.map(x => ({
          ...x,
          selected: false,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    updateAll() {
      this.getList.forEach(item => {
        this.$set(item, 'selected', this.allSelected);
      });
    },
    openModuleSlidebar(item) {
      this.selectedItem = item;
      this.showModuleSlidebar = true;
      this.showSlidebar = false;
      this.showMassEditSlidebar = false;
      this.showHistorySlidebar = false;
    },
    openSlidebar(item) {
      this.selectedItem = item;
      this.showSlidebar = true;
      this.showModuleSlidebar = false;
      this.showMassEditSlidebar = false;
      this.showHistorySlidebar = false;
    },
    openMassEditSlidebar() {
      this.showMassEditSlidebar = true;
      this.showSlidebar = false;
      this.showModuleSlidebar = false;
      this.showHistorySlidebar = false;
    },
    openHistorySlidebar(item) {
      this.selectedItem = item;
      this.showHistorySlidebar = true;
      this.showSlidebar = false;
      this.showModuleSlidebar = false;
      this.showMassEditSlidebar = false;
    },
    closeAndReloadSlidebar() {
      this.closeSlidebar();
      this.loadList();
    },
    closeSlidebar() {
      this.selectedItem = null;
      this.showSlidebar = false;
      this.showModuleSlidebar = false;
      this.showMassEditSlidebar = false;
      this.showHistorySlidebar = false;
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadList();
    this.$nextTick(() => {
      if (this.$refs.focusInput) {
        this.$refs.focusInput.focus();
      }
    });
  },
};
</script>
