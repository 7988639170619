<template>
  <tbody style="border-width: 1px">
    <tr>
      <td @click="toggleModuleInfo(null, null)">
        {{ student.nafn }}
        <b-link
          :to="{ name: 'Student', params: { id: student.nemandi_id } }"
          target="_blank"
          v-if="canViewStudent"
          title="Skoða nemanda"
          v-b-tooltip
          ><i class="fa fa-external-link"></i
        ></b-link>
      </td>
      <td @click="toggleModuleInfo(null, null)">{{ student.nemandi_id }}</td>
      <td
        v-for="(att, idx) in student.fjarv_stls"
        class="cursor-pointer"
        :class="{ summaryThickerBorder: idx === student.fjarv_stls.length - 1 }"
        @click="toggleModuleInfo(att, null)"
        :key="`student${index}attendance${idx}`"
      >
        {{ att.fj > 0 ? att.fj : '' }}
      </td>
      <td
        v-for="(abs, idx) in student.forf_stls"
        class="cursor-pointer"
        @click="toggleModuleInfo(null, abs)"
        :key="`student${index}absence${idx}`"
      >
        {{ abs.fj > 0 ? abs.fj : '' }}
      </td>
      <td>{{ student.fjarvistarstig_ofl.length > 0 ? student.fjarvistarstig_ofl[0].fj_fjarv_forfoll_id : 0 }}</td>
      <td>{{ student.fjarvistarstig_ofl.length > 0 ? student.fjarvistarstig_ofl[0].fjarvistar_stig : '' }}</td>
      <td
        v-if="schoolSettings.maetingarhlutfall_stig === 1"
        :class="getAttendanceColor(student.maeting_onn_stls.length > 0 && student.maeting_onn_stls[0].maeting)"
      >
        {{ student.maeting_onn_stls.length > 0 && student.maeting_onn_stls[0].maeting ? `${student.maeting_onn_stls[0].maeting}%` : '' }}
      </td>
      <td
        v-if="schoolSettings.maetingarhlutfall_raun === 1"
        :class="getAttendanceColor(student.maeting_onn_stls.length > 0 && student.maeting_onn_stls[0].maeting_raun)"
      >
        {{
          student.maeting_onn_stls.length > 0 && student.maeting_onn_stls[0].maeting_raun
            ? `${student.maeting_onn_stls[0].maeting_raun}%`
            : ''
        }}
      </td>
      <td
        v-if="schoolSettings.maetingarhlutfall_onn === 1"
        :class="getAttendanceColor(student.maeting_onn_stls.length > 0 && student.maeting_onn_stls[0].maeting_onn)"
      >
        {{
          student.maeting_onn_stls.length > 0 && student.maeting_onn_stls[0].maeting_onn
            ? `${student.maeting_onn_stls[0].maeting_onn}%`
            : ''
        }}
      </td>
      <td
        v-if="schoolSettings.maetingarhlutfall_einkunn === 1"
        :class="getAttendanceColor(student.maeting_onn_stls.length > 0 && student.maeting_onn_stls[0].maeting_einkunn)"
      >
        {{
          student.maeting_onn_stls.length > 0 && student.maeting_onn_stls[0].maeting_einkunn
            ? `${student.maeting_onn_stls[0].maeting_einkunn}%`
            : ''
        }}
      </td>
      <td>{{ student.athugasemdir }}</td>
    </tr>
    <tr v-if="showDetails" style="background-color: #fff">
      <td colspan="100%" style="font-size: 12px">
        <div style="padding-left: 6px">
          <strong>Kóði: {{ selectedAbsence ? selectedAbsence.kodi : '' }}{{ selectedLeave ? selectedLeave.kodi : '' }}</strong>
          <div v-if="!loading && details.length === 0">Engar skráningar fundust</div>
        </div>
        <Loader v-if="loading"></Loader>
        <table class="table no-border less-padding" v-if="details.length > 0">
          <tr>
            <th>Vikudagur</th>
            <th>Dags.</th>
            <th>Frá</th>
            <th>Til</th>
            <th>Skráð</th>
            <th>Hópur</th>
            <th>Stig</th>
            <th>Viðvera</th>
            <th>Forföll</th>
            <th>Aths.</th>
            <th>Skráð af</th>
          </tr>
          <tr v-for="detail in details" :key="detail.fjarv_forfoll_id">
            <td>{{ detail.heiti_dags }}</td>
            <td>{{ detail.dags }}</td>
            <td>{{ detail.timi_fra }}</td>
            <td>{{ detail.timi_til }}</td>
            <td>{{ detail.dags_skrad }}</td>
            <td>{{ detail.hopur }}</td>
            <td>{{ detail.fjarvistar_stig }}</td>
            <td>{{ detail.kodi }}</td>
            <td>{{ detail.kodi1 }}</td>
            <td>{{ detail.athugasemd }}</td>
            <td>{{ detail.nafn }}</td>
          </tr>
        </table>
      </td>
    </tr>
  </tbody>
</template>

<script>
import attendance from '@/api/attendance';

import { mapActions, mapGetters } from 'vuex';
import Loader from '@/components/common/Loader.vue';

export default {
  name: 'attendance-summary-item',
  props: ['student', 'index'],
  components: {
    Loader,
  },
  computed: {
    canViewStudent() {
      return this.loggedInUserHasWritePermission('nemendahald_nemendur');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {
      showDetails: false,
      selectedAbsence: '',
      selectedLeave: '',
      details: [],
      loading: false,
    };
  },
  methods: {
    getAttendanceColor(item) {
      if (item < 80) {
        return 'attendancePercentageUnder80';
      }
      if (item >= 80 && item < 90) {
        return 'attendancePercentageUnder90';
      }
      return '';
    },
    async getAttendanceDetail() {
      try {
        this.showDetails = true;
        this.loading = true;
        const response = await attendance.getAttendanceDetails(
          this.student.ferill_id,
          this.selectedAbsence ? this.selectedAbsence.teg_fjarvista : '',
          this.selectedLeave ? this.selectedLeave.teg_forfalla : '',
        );
        this.details = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    toggleModuleInfo(absence, leaveOfAbsence) {
      const oldId = (this.selectedAbsence && this.selectedAbsence.teg_fjarvista) || (this.selectedLeave && this.selectedLeave.teg_forfalla);
      const currentId = (absence && absence.teg_fjarvista) || (leaveOfAbsence && leaveOfAbsence.teg_forfalla);
      this.details = [];
      if ((!absence && !leaveOfAbsence && this.showDetails) || oldId === currentId) {
        this.selectedAbsence = {};
        this.selectedLeave = {};
        this.showDetails = false;
      } else {
        this.selectedAbsence = absence || {};
        this.selectedLeave = leaveOfAbsence || {};
        this.getAttendanceDetail();
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
