<template>
  <div class="sidebar">
    <div class="logo">
      <b-link :to="{ name: 'Dashboard' }">
        <img src="../../assets/Inna-logo-small.svg" />
      </b-link>
    </div>
    <div class="sidebar-collase-icon" @click="collapse">
      <i class="fa fa-angle-right"></i>
    </div>
    <div class="collapse-category" v-for="(category, categoryIndex) in navItems" :key="categoryIndex">
      <div class="collapse-divider" />
      <img :src="getImgUrl(category)" />
    </div>
    <div class="collapse-category collapse-user">
      <div class="collapse-divider" />
      <img src="../../assets/notandi.svg" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'collapsed-sidebar',
  props: ['navItems'],
  computed: {
    getNavItems() {
      return this.navItems.filter(x => !x.is_user);
    },
    getUser() {
      return this.navItems.find(x => x.is_user);
    },
  },
  methods: {
    collapse() {
      this.$emit('collapse');
    },
    getImgUrl(category) {
      return category.icon ? require(`../../assets/${category.icon}`) : ''; // eslint-disable-line
    },
  },
};
</script>

<style lang="scss">
@import '../../style/variables';
.logo {
  text-align: center;
  a {
    color: inherit;
  }
  img {
    padding-top: 20px;
    padding-bottom: 30px;
  }
}
.sidebar-collase-icon {
  height: 24px;
  width: 24px;
  border-radius: 15px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.24);
  margin-left: 68px;
  margin-top: -10px;
  cursor: pointer;
  i {
    font-size: 18px;
    color: #303030;
    padding-left: 10px;
  }
}

.collapse-user {
  position: fixed;
  bottom: 0px;
  padding-top: 18px;
  padding-bottom: 5px;
}

.collapse-divider {
  height: 1px;
  width: 80px;
  opacity: 0.15;
  background-color: #ffffff;
}

.collapse-category {
  text-align: center;
  // margin-top: 20px;
  // padding-bottom: 10px;
  width: 80px;
  img {
    padding-top: 28px;
    padding-bottom: 28px;
  }
}
</style>
