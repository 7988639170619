<template>
    <Slidebar @closeMe="closeSlidebar">
        <h4>Breyta einkunn</h4>
        <b-form @submit.prevent="validateBeforeSubmit" class="pt-2" novalidate>
            <table class="table less-padding pb-3">
                <tr>
                    <td>Önn</td>
                    <td>
                        {{ item.onn_eink }}
                    </td>
                </tr>
                <tr>
                    <td>Áfangi</td>
                    <td>
                        {{ item.namsgrein_afanganr }}
                    </td>
                </tr>
                <tr>
                    <td>Heiti áfanga</td>
                    <td>
                        {{ item.afanga_heiti }}
                    </td>
                </tr>
                <tr>
                    <td>Lokaeinkunn</td>
                    <td>
                        <b-form-group label-for="einkunn" :state="submitted && errors.has('einkunn') ? false : ''"
                            :invalid-feedback="errors.first('einkunn')">
                            <b-form-input id="einkunn" name="einkunn" type="text" v-model="form.einkunn"
                                :state="submitted && errors.has('einkunn') ? false : ''" maxLength="2"
                                v-validate="'required'" ref="focusInput" data-vv-as="einkunn"></b-form-input>
                        </b-form-group>
                    </td>
                </tr>
                <tr>
                    <td>Einingar</td>
                    <td>
                        <b-form-input id="einingar" name="einingar" type="text" v-model="form.einingar"
                            :state="submitted && errors.has('einingar') ? false : ''" maxLength="2" ref="focusInput"
                            data-vv-as="einingar"></b-form-input>
                    </td>
                </tr>
                <tr>
                    <td>Staða</td>
                    <td>
                        <b-form-group label-for="moduleStatus">
                            <v-select id="moduleStatus" name="moduleStatus" v-model="form.stada_afanga"
                                :options="moduleStatuses" :clearable="false" label="heiti"> </v-select>
                        </b-form-group>
                    </td>
                </tr>
                <tr>
                    <td>Dags. lokið</td>
                    <td>
                        <b-form-group label-for="dags_lokid">
                            <datepicker id="date" name="date" v-model="form.dags_lokid" :language="lang"
                                :monday-first="true" format="dd.MM.yyyy" :typeable="false" :clear-button="true"
                                ref="dateOpenPickr" :class="{ 'datepicker-not-empty': form.dags_lokid }"></datepicker>
                        </b-form-group>
                    </td>
                </tr>
                <tr>
                    <td>Athugasemd</td>
                    <td>
                        <b-form-group label-for="aths" :state="submitted && errors.has('aths') ? false : ''"
                            :invalid-feedback="errors.first('aths')">
                            <b-form-textarea id="aths" name="aths" type="text" v-model="form.aths"
                                :state="submitted && errors.has('aths') ? false : ''" maxLength="200"
                                data-vv-as="aths"></b-form-textarea>
                        </b-form-group>

                    </td>
                </tr>
                <tr>
                    <td>Læsa einkunn (ekki reikna upp skv. reglu)</td>
                    <td>
                        <b-form-checkbox id="einkunn_laest" name="einkunn_laest" v-model="form.einkunn_laest"></b-form-checkbox>
                    </td>
                </tr>
            </table>
            <b-btn variant="primary" class="mr-2" v-if="canEdit" @click="validateBeforeSubmit(null)">Vista
            </b-btn>
            <b-btn variant="dark" @click="closeSlidebar">Hætta við</b-btn>
            <b-btn variant="danger" class="float-right" v-if="canEdit" @click="deleteGrade(1)">Eyða</b-btn>
        </b-form>
    </Slidebar>
</template>
  
<script>
import common from '@/api/common';
import students from '@/api/students';
import { mapActions, mapGetters } from 'vuex';
import Datepicker from 'vuejs-datepicker';

import Slidebar from '@/components/common/Slidebar.vue';
import { is } from 'vuejs-datepicker/dist/locale';

export default {
    name: 'student-edit-grade-slidebar',
    props: ['item', 'studentId'],
    components: {
        Slidebar,
        Datepicker,
    },
    computed: {
        canEdit() {
            return this.loggedInUserHasWritePermission('nemendahald_nemendur');
        },
        ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
    },
    data() {
        return {
            lang: is,
            loading: false,
            submitted: false,
            id: null,
            moduleStatuses: [],
            form: {
                einkunn: '',
                einkunn_a: '',
                einingar: '',
                stada_afanga: '',
                dags_lokid: '',
                aths: '',
                einkunn_laest: false,
            },
        };
    },
    methods: {
        closeSlidebar() {
            this.$emit('closeSlidebar');
        },
        async loadModuleStatuses() {
            try {
                const response = await common.getModuleStatuses();
                this.moduleStatuses = response.data.items;
            } catch (e) {
                this.$log.error(e);
                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            }
        },
        async deleteGrade(del) {
            const conf = confirm('Ertu viss um að þú viljir eyða færslunni?'); // eslint-disable-line
                if (conf) {
                    this.validateBeforeSubmit(del);
                }
        },
        async validateBeforeSubmit(del) {
            try {
                this.submitted = true;
                this.errorList = [];
                await this.$validator.validateAll().then(response => {
                    if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
                });
                // Engar villur í formi.
                try {
                    this.saving = true;
                    const data = {
                        ferill_id: this.item.ferill_id,
                        stada_afanga: this.form.stada_afanga.stada_afanga, 
                        einkunn: this.form.einkunn ? this.form.einkunn : '',
                        dags_lokid: this.form.dags_lokid,
                        einingar: this.form.einingar ? this.form.einingar : '',
                        laest: this.form.laest ? 1 : 0,
                        aths: this.form.aths,
                        delete: del,
                    };
                    await students.postStudentGrade(this.studentId, data);
                    this.displaySuccess(`${this.form.name} hefur verið stofnuð.`);
                    this.closeSlidebar();
                } catch (e) {
                    // Aðgerð mistókst.
                    this.failed = true;
                    this.$log.debug(e);
                    if (e.response && e.response.data && e.response.data.error) {
                        this.displayError(e.response.data.error);
                    } else {
                        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
                    }
                }
            } catch (e) {
                // Villur í formi.
                this.$log.debug(e);
            } finally {
                this.saving = false;
            }
        },
        ...mapActions({
            displayError: 'displayError',
            displaySuccess: 'displaySuccess',
        }),
    },
    created() {
        this.loadModuleStatuses();
        this.form = {
            einkunn: this.item.einkunn ? this.item.einkunn : this.item.einkunn_a,
            einingar: this.item.einingar_ferill,
            stada_afanga: this.item.stada,
            dags_lokid: this.item.dags_lokid,
            aths: this.item.aths,
            einkunn_laest: this.item.laest = !!this.item.laest,
        };
    },
};
</script>
  
<style lang="scss"></style>
  