<template>
  <div>
    <b-form-group>
      <label for="term">Afrita frá</label>
      <v-select id="term" name="term" v-model="form.term" :clearable="false" :options="terms"> </v-select>
    </b-form-group>
    <b-form-group class="mb-0 mt-3">
      <label for="copy-modules-radios">Afrita áfanga</label>
      <b-form-radio v-model="form.modules" name="copy-modules-radios" value="0">Sleppa því að afrita </b-form-radio>
      <b-form-radio v-model="form.modules" name="copy-modules-radios" value="1">Afrita áfanga frá völdu kennslutímabili </b-form-radio>
      <b-form-radio v-model="form.modules" name="copy-modules-radios" value="2" v-if="!creating"
        >Afrita áfanga frá völdu kennslutímabili og eyða þeim sem fyrir voru
        <i class="fa fa-info-circle" title="Ath ekki er hægt að eyða áföngum sem búið er að nota." v-b-tooltip></i>
      </b-form-radio>
    </b-form-group>
    <b-form-group class="mt-2">
      <label label-for="department" class="mb-0 d-inline-block">Eingöngu áfanga deildar: </label>
      <v-select
        id="department"
        name="department"
        v-model="form.department"
        :options="getDepartments"
        :clearable="true"
        class="d-inline-block ml-2"
        style="min-width: 300px; height: 25px"
        multiple
      >
      </v-select>
    </b-form-group>
    <b-form-group label="Afrita einkunnareglur" class="mt-4">
      <b-form-radio v-model="form.graderules" name="copy-graderules-radios" value="0"> Sleppa því að afrita </b-form-radio>
      <b-form-radio v-model="form.graderules" name="copy-graderules-radios" value="1"
        >Afrita einkunnareglur frá völdu kennslutímabili
        <i
          class="fa fa-info-circle"
          title="Ath. engar einkunnareglur verða afritaðar nema áfangar séu afritaðir."
          v-if="creating"
          v-b-tooltip
        ></i>
      </b-form-radio>
      <b-form-radio v-model="form.graderules" name="copy-graderules-radios" value="2" v-if="!creating"
        >Afrita einkunnareglur frá völdu kennslutímabili og eyða þeim sem fyrir voru

        <i class="fa fa-info-circle" title="Ath ekki er hægt að eyða einkunnareglum sem búið er að nota." v-b-tooltip></i>
      </b-form-radio>
    </b-form-group>
    <b-form-group label="Afrita stofur" class="mt-3" v-if="!schoolHasSettings('stundatoflukerfi')">
      <b-form-radio v-model="form.classrooms" name="copy-classrooms-radios" value="0">Sleppa því að afrita </b-form-radio>
      <b-form-radio v-model="form.classrooms" name="copy-classrooms-radios" value="1"
        >Afrita stofur frá völdu kennslutímabili
      </b-form-radio>
      <b-form-radio v-model="form.classrooms" name="copy-classrooms-radios" value="2" v-if="!creating">
        Afrita stofur frá völdu kennslutímabili og eyða þeim sem fyrir voru
        <i class="fa fa-info-circle" title="Ath. ekki er hægt að eyða stofum sem búið er að nota." v-b-tooltip></i>
      </b-form-radio>
    </b-form-group>
    <b-form-group label="Afrita námsgagnalista" class="mt-3">
      <b-form-radio v-model="form.booklist" name="copy-booklist-radios" :value="-1">Sleppa því að afrita </b-form-radio>
      <b-form-radio v-model="form.booklist" name="copy-booklist-radios" :value="0">
        <span v-if="!creating">Bæta við námsgagnalista á áfanga ef námsgagnalisti er ekki til fyrir</span>
        <span v-else
          >Afrita námsgagnalista frá völdu kennslutímabili
          <i
            class="fa fa-info-circle"
            title="Ath. enginn námsgagnalisti verður afritaður nema áfangar séu afritaðir."
            v-if="creating"
            v-b-tooltip
          ></i>
        </span>
      </b-form-radio>
      <b-form-radio v-model="form.booklist" name="copy-booklist-radios" :value="1" v-if="!creating">
        Yfirskrifa fyrri námsgagnalista
        <i class="fa fa-info-circle" title="Ef það eru námsgögn skráð fyrir er þeim eytt og svo afritað nýju." v-b-tooltip></i>
      </b-form-radio>
    </b-form-group>
    <b-btn variant="primary" class="float-right" @click="copy()" :disabled="!form.term || submitting">
      <i class="fa fa-spin fa-spinner" v-if="submitting"></i>
      Afrita
    </b-btn>
  </div>
</template>

<script>
import terms from '@/api/terms';
import structure from '@/api/structure';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'term-copy-slidebar',
  props: ['id', 'divisionId', 'name', 'copyTerm', 'creating'],
  computed: {
    getDepartments() {
      return this.departments.filter(x => !this.form.department || !this.form.department.find(y => x.department_id === y.department_id));
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolHasSettings']),
  },
  data() {
    return {
      form: {
        modules: '0',
        graderules: '0',
        classrooms: '0',
        booklist: -1,
        term: '',
        department: [],
      },
      terms: [],
      departments: [],
      loading: false,
      submitting: false,
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async loadTerms() {
      try {
        this.loading = true;
        this.firsttime = true;
        const params = {
          only_active: 0,
          division_id: this.divisionId,
        };
        const response = await terms.getTerms(params);
        this.terms = response.data.items
          .filter(x => x.onn_id !== parseInt(this.id, 10))
          .map(x => ({
            ...x,
            label: `${x.undirsk_audk} - ${x.onn_heiti}`,
          }));
        if (this.copyTerm) {
          this.form.term = this.terms.find(x => x.onn_id === parseInt(this.copyTerm, 10));
        }
        if (!this.form.term) {
          this.form.term = this.terms.find(x => x.is_active === 1);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadDepartments() {
      try {
        const response = await structure.getDepartmentList();
        this.departments = response.data.items.map(x => ({
          ...x,
          label: `${x.name}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async copy() {
      try {
        this.submitting = true;
        // Afrita áfanga, einkunnareglur og stofur
        await terms.copyTerm(this.id, this.form.term.onn_id, {
          modules: this.form.modules,
          graderules: this.form.graderules,
          classrooms: this.form.classrooms,
          departments: this.form.department.map(x => x.department_id).join(','),
        });
        // Afrita námsgagnalista
        if (this.form.booklist > -1) {
          await terms.copyBooklist(this.id, {
            latest: 0,
            onn_fra: this.form.term.onn_id,
            yfirskrifa: this.form.booklist,
            deild: this.form.department.map(x => x.department_id).join(','),
          });
        }

        this.submitting = false;
        this.displaySuccess('Afritun lokið.');
        this.$emit('finishCopy');
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.submitting = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadDepartments();
    this.loadTerms();
  },
};
</script>

<style lang="scss"></style>
