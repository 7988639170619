<template>
  <Slidebar @closeMe="closeSlidebar" :large="true">
    <h4 v-if="form.vottord">Óska eftir vottorði</h4>
    <h4 v-else>
      <span v-if="item && item.nemandi_athugasemdir_id">Breyta athugasemd</span>
      <span v-else>Skrá athugasemd</span>
    </h4>
    <div v-if="form.vottord" class="mb-2" style="font-size: 13px">
      Óskað er eftir því að í hvert skipti sem nemandi tilkynni sig veikan þurfi hann að skila vottorði. Skilaboðin „Ath! skila þarf inn
      vottorði“ birtast í rauðum borða á skjánum hjá nemandanum þegar hann ætlar að skrá veikindi.
    </div>
    <StudentInfo v-if="studentId && showDetails" :id="studentId" style="margin-bottom: -10px" />
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <b-row>
        <b-col class="pl-0">
          <b-form-group
            label-for="athugasemd"
            :state="submitted && errors.has('athugasemd') ? false : ''"
            :invalid-feedback="errors.first('athugasemd')"
          >
            <label for="athugasemd">
              Athugasemd*
              <i v-if="form.vottord" class="fa fa-info-circle" v-b-tooltip title="Textinn birtist í athugasemdum hjá nemandanum" />
            </label>
            <b-form-textarea
              id="athugasemd"
              name="athugasemd"
              type="text"
              v-model="form.athugasemd"
              :state="submitted && errors.has('athugasemd') ? false : ''"
              maxLength="4000"
              v-validate="'required'"
              ref="focusInput"
              data-vv-as="athugasemd"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="pl-0">
          <b-form-group
            label="Dags*"
            label-for="dags"
            :state="submitted && errors.has('dags') ? false : ''"
            :invalid-feedback="errors.first('dags')"
          >
            <datepicker
              id="dags"
              name="dags"
              v-model="form.dags"
              :language="lang"
              :monday-first="true"
              format="dd.MM.yyyy"
              :typeable="false"
              :clear-button="false"
              ref="datePickr"
              class="datepicker_100_width"
              :disabled="form.vottord"
            ></datepicker>
          </b-form-group>
          <b-form-group :state="submitted && errors.has('onn') ? false : ''" :invalid-feedback="errors.first('onn')">
            <label for="onn">
              Kennslutímabil
              <div v-if="form.vottord" style="font-size: 13px">
                Ef valið er kennslutímabil þá sést tilkynningin bara það kennslutímabil en ef ekkert er valið þá sést tilkynningin alltaf.
              </div>
            </label>
            <v-select id="onn" name="onn" v-model="form.onn" :options="terms" :clearable="true" label="onn_eink"> </v-select>
          </b-form-group>
          <div v-if="!form.vottord">
            <b-form-group :state="submitted && errors.has('afangi') ? false : ''" :invalid-feedback="errors.first('afangi')">
              <label for="afangi"> Áfangi </label>
              <v-select id="afangi" name="afangi" v-model="form.afangi" :options="modules" :clearable="true" label="namsgrein_afanganr">
              </v-select>
            </b-form-group>
            <b-form-group :state="submitted && errors.has('braut') ? false : ''" :invalid-feedback="errors.first('braut')">
              <label for="braut"> Braut </label>
              <v-select id="braut" name="braut" v-model="form.braut" :options="courses" :clearable="true"> </v-select>
            </b-form-group>
            <b-form-group :state="submitted && errors.has('flokkur') ? false : ''" :invalid-feedback="errors.first('flokkur')">
              <label for="flokkur"> Flokkur <i class="fa fa-info-circle" title="Flokkur sem skólinn hefur búið sér til"></i></label>
              <v-select id="flokkur" name="flokkur" v-model="form.flokkur" :options="categories" :clearable="true" label="heiti">
              </v-select>
            </b-form-group>
          </div>
        </b-col>
        <b-col>
          <b-form-group :state="submitted && errors.has('opin') ? false : ''" :invalid-feedback="errors.first('opin')">
            <label for="opin">
              Opin athugasemd
              <i
                v-if="form.vottord"
                class="fa fa-info-circle"
                v-b-tooltip.hover.html
                title="Já = Þá sjá kennarar og starfsmenn athugasemdina <br/>
                Nei = Þá sjá bara umsjónarkennari og stjórnendur athugasemdina."
              />
              <i
                v-if="!form.vottord"
                class="fa fa-info-circle"
                v-b-tooltip.hover.html
                title="Já = Þá sjá allir kennarar og starfsmenn athugasemdina <br/>
                Nei = Þá sjá bara umsjónarkennari og þeir starfsmenn sem hafa heimild til þess."
              />
            </label>
            <v-select id="opin" name="opin" v-model="form.opin" :options="openOptions" :clearable="false"> </v-select>
          </b-form-group>
          <div v-if="!form.vottord">
            <b-form-group :state="submitted && errors.has('a_feril') ? false : ''" :invalid-feedback="errors.first('a_feril')">
              <label for="a_feril"> Á námsferil </label>
              <v-select id="a_feril" name="a_feril" v-model="form.a_feril" :options="openOptions" :clearable="false"> </v-select>
            </b-form-group>
            <b-form-group
              :state="submitted && errors.has('a_fjarvistayfirlit') ? false : ''"
              :invalid-feedback="errors.first('a_fjarvistayfirlit')"
            >
              <label for="a_fjarvistayfirlit"> Á fjarvistayfirlit </label>
              <v-select
                id="a_fjarvistayfirlit"
                name="a_fjarvistayfirlit"
                v-model="form.a_fjarvistayfirlit"
                :options="openOptions"
                :clearable="false"
              >
              </v-select>
            </b-form-group>
            <b-form-group :state="submitted && errors.has('a_stundatoflu') ? false : ''" :invalid-feedback="errors.first('a_stundatoflu')">
              <label for="a_stundatoflu"> Á stundatöflu </label>
              <v-select id="a_stundatoflu" name="a_stundatoflu" v-model="form.a_stundatoflu" :options="openOptions" :clearable="false">
              </v-select>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="!form.vottord">
        <b-col class="pl-0">
          <b-form-group
            label-for="leitarord"
            :state="submitted && errors.has('leitarord') ? false : ''"
            :invalid-feedback="errors.first('leitarord')"
          >
            <label for="leitarord"> Leitarorð </label>
            <b-form-textarea
              id="leitarord"
              name="leitarord"
              type="text"
              v-model="form.leitarord"
              :state="submitted && errors.has('leitarord') ? false : ''"
              maxLength="500"
              data-vv-as="leitarord"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>

      <div v-if="form.vottord" style="font-size: 13px" class="mb-3">
        <i class="fa fa-info-circle"></i>
        Ath! Til að taka út tilkynninguna eða afturkalla aðgerðina þarf að fara í athugasemdir hjá nemandanum og eyða út athugasemdinni.
      </div>

      <b-btn variant="primary" class="mr-2" v-if="canEdit" @click="validateBeforeSubmit">
        <span v-if="item && item.nemandi_athugasemdir_id">Breyta</span>
        <span v-else>Stofna</span>
      </b-btn>
      <b-btn variant="dark" @click="closeSlidebar">Hætta við</b-btn>
      <!--b-btn variant="danger" class="float-right" v-if="canEdit && id" @click="deleteComment"> Eyða </b-btn-->
    </b-form>
  </Slidebar>
</template>

<script>
import students from '@/api/students';
import types from '@/api/types';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';
import StudentInfo from '@/components/common/StudentInfo.vue';

import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';

export default {
  name: 'student-add-comment-slidebar',
  props: ['item', 'studentId', 'showDetails', 'certificate'],
  components: {
    Slidebar,
    StudentInfo,
    Datepicker,
  },
  computed: {
    canEdit() {
      if (this.form.vottord) return this.loggedInUserHasWritePermission('beidnir_veikindi');
      return this.loggedInUserHasWritePermission('nemendahald_hopar') || this.loggedInUserHasWritePermission('nemendahald_nemendur');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {
      lang: is,
      form: {
        athugasemd: '',
        opin: { id: 0, label: 'Nei' },
        dags: moment().toDate(),
        a_feril: { id: 0, label: 'Nei' },
        a_fjarvistayfirlit: { id: 0, label: 'Nei' },
        a_stundatoflu: { id: 0, label: 'Nei' },
        onn: '',
        afangi: '',
        braut: '',
        flokkur: '',
        leitarord: '',
        vottord: false,
      },
      openOptions: [
        { id: 0, label: 'Nei' },
        { id: 1, label: 'Já' },
      ],
      terms: [],
      modules: [],
      courses: [],
      categories: [],
      loading: {
        terms: false,
        modules: false,
        courses: false,
        categories: false,
      },
      submitted: false,
    };
  },
  methods: {
    async loadTerms(id) {
      try {
        this.loading.terms = true;
        const response = await students.getStudentTerms(this.studentId, { future_terms: 1, past_terms: 1 });
        this.terms = response.data.items;
        if (id) {
          this.form.onn = this.terms.find(x => x.onn_id === id);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.terms = false;
      }
    },
    async loadModules(id) {
      try {
        this.loading.modules = true;
        const response = await students.getStudentModuleList(this.studentId);
        this.modules = response.data.items;
        if (id) {
          this.form.afangi = this.modules.find(x => x.afangi_id === id);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.modules = false;
      }
    },
    async loadCourses(id) {
      try {
        this.loading.courses = true;
        const response = await students.getStudentCourseList(this.studentId);
        this.courses = response.data.items.map(x => ({
          ...x,
          label: `${x.braut_kodi} ${x.heiti}`,
        }));
        if (id) {
          this.form.braut = this.courses.find(x => x.braut_id === id);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.courses = false;
      }
    },
    async loadCategories(id) {
      try {
        this.loading.categories = true;
        const response = await types.getCommentList();
        this.categories = response.data.items;
        if (id) {
          this.form.flokkur = this.categories.find(x => x.flokkur_id === id);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.categories = false;
      }
    },
    initialize() {
      if (this.item && this.item.nemandi_athugasemdir_id) {
        this.form.athugasemd = this.item.athugasemd;
        this.form.opin = this.openOptions.find(x => x.id === this.item.opin);
        this.form.dags = moment(this.item.dags, 'DD.MM.YYYY').toDate();
        this.form.a_feril = this.openOptions.find(x => x.id === this.item.a_feril);
        this.form.a_fjarvistayfirlit = this.openOptions.find(x => x.id === this.item.a_fjarvistayfirlit);
        this.form.a_stundatoflu = this.openOptions.find(x => x.id === this.item.a_stundatoflu);
        this.form.leitarord = this.item.leitarord;
        this.form.vottord = this.item.vottord === 1;
        this.loadTerms(this.item.onn_id);
        if (!this.form.vottord) {
          this.loadModules(this.item.afangi_id);
          this.loadCourses(this.item.braut_id);
          this.loadCategories(this.item.flokkur_id);
        }
      } else {
        if (this.certificate) {
          this.form.vottord = true;
          this.form.athugasemd = 'Þarf að skila vottorði';
          this.form.opin = { id: 0, label: 'Nei' };
        } else {
          this.form.opin = this.openOptions.find(x => x.id === this.schoolSettings.stilling_athugasemda);
          if (!this.form.opin) {
            this.form.opin = { id: 0, label: 'Nei' };
          }
          this.loadModules();
          this.loadCourses();
          this.loadCategories();
        }
        this.loadTerms();
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });
        const item = {
          nemandi_id: this.studentId,
          dags: moment(this.form.dags).format('DD.MM.YYYY'),
          vottord: this.form.vottord ? 1 : 0,
          athugasemd: this.form.athugasemd,
          opin: this.form.opin.id,
          onn_id: this.form.onn ? this.form.onn.onn_id : '',
        };
        if (!this.form.vottord) {
          item.a_feril = this.form.a_feril ? this.form.a_feril.id : '';
          item.a_fjarvistayfirlit = this.form.a_fjarvistayfirlit ? this.form.a_fjarvistayfirlit.id : '';
          item.a_stundatoflu = this.form.a_stundatoflu ? this.form.a_stundatoflu.id : '';
          item.afangi_id = this.form.afangi ? this.form.afangi.afangi_id : '';
          item.braut_id = this.form.braut ? this.form.braut.braut_id : '';
          item.flokkur_id = this.form.flokkur ? this.form.flokkur.flokkur_id : '';
          item.leitarord = this.form.leitarord;
        }
        if (this.item && this.item.nemandi_athugasemdir_id) {
          await students.updateComment(this.item.nemandi_athugasemdir_id, item);
          this.displaySuccess('Athugasemd breytt');
        } else {
          await students.createComment(item);
          this.displaySuccess('Athugasemd skráð');
        }
        this.$emit('closeAndReload');
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },

    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    studentId() {
      this.initialize();
    },
  },
  created() {
    this.initialize();
  },
};
</script>

<style lang="scss">
label {
  text-decoration: none;
}
</style>
