<template>
  <Slidebar @closeMe="closeSlidebar">
    <h4>Uppfæra kennitölu</h4>
    <div class="p-2 mb-3" style="border: 2px solid #ddd">
      <table class="table no-border mb-0">
        <tr>
          <th>Kennitala</th>
          <td>{{ user.kennitala }}</td>
        </tr>
        <tr>
          <th>Nafn</th>
          <td>{{ user.nafn }}</td>
        </tr>
      </table>
    </div>
    <b-form-group
      label="Fletta upp kennitölu"
      label-for="kennitala"
      :state="submitted && errors.has('kennitala') ? false : ''"
      :invalid-feedback="errors.first('kennitala')"
    >
      <b-form-input
        id="kennitala"
        name="kennitala"
        v-model="form.ssn"
        maxLength="10"
        minLength="10"
        v-validate="'required'"
        data-vv-as="kennitala"
        @input="searchSsn()"
        style="width: 40%"
      >
      </b-form-input>
    </b-form-group>
    <div v-if="form.ssn && form.name">
      <div class="mb-2 mt-4">Uppfæra kennitölu:</div>
      <div class="p-2" style="border: 2px solid #ddd">
        <table class="table no-border mb-0">
          <tr>
            <th>Kennitala</th>
            <td>{{ form.ssn }}</td>
          </tr>
          <tr>
            <th>Nafn</th>
            <td>{{ form.name }}</td>
          </tr>
          <tr>
            <th>Lögheimili</th>
            <td>{{ form.address }}</td>
          </tr>
          <tr v-if="form.exists">
            <td colspan="2" class="text-danger">
              <i class="fa fa-exclamation-triangle"></i> Ath! Ekki er hægt að uppfæra í eftirfarandi kennitölu þar sem þessi kennitala er nú
              þegar skráð í skólann!
            </td>
          </tr>
        </table>
      </div>
      <b-btn variant="primary" size="sm" class="mt-3" v-if="!form.exists" @click="updateSsn()" :disabled="saving"
        >Uppfæra kennitölu
        <i class="fa fa-spin fa-spinner" v-if="saving"></i>
      </b-btn>
    </div>
  </Slidebar>
</template>

<script>
import users from '@/api/users';
import common from '@/api/common';
import { mapActions, mapGetters } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';

export default {
  name: 'update-ssn-slidebar',
  props: ['userId'],
  components: {
    Slidebar,
  },
  computed: {
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      loading: false,
      saving: false,
      submitted: false,
      user: '',
      form: {
        ssn: '',
        name: '',
        address: '',
        exists: false,
      },
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async loadUser() {
      try {
        this.loading = true;
        const response = await users.getUserInfo(this.userId);
        this.user = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async searchSsn() {
      this.form.name = '';
      this.form.exists = true;
      if (this.form.ssn && this.form.ssn.length === 10) {
        try {
          const response = await common.searchSsn(this.form.ssn, { existsInSchool: 1 });
          this.form.name = response.data.nafn;
          this.form.address = response.data.heimilisfang_postnumer;
          this.form.exists = response.data.exists_in_school === 1;
          this.search = false;
        } catch (e) {
          this.$log.error(e);
          if (e.status === 404) {
            this.displayError('Kennitala fannst ekki. Vinsamlegast veljið aðra.');
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      }
    },
    async updateSsn() {
      const conf = confirm(`Ertu viss um að þú uppfæra ${this.user.nafn} kt.${this.user.kennitala} í ${this.form.name} kt.${this.form.ssn}?`); // eslint-disable-line
      if (conf) {
        try {
          this.saving = true;
          await users.updateUserSsn(this.userId, { kennitala: this.form.ssn });
          this.displaySuccess('Kennitala uppfærð');
          this.$emit('closeAndReloadSlidebar');
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.saving = false;
        }
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadUser();
  },
};
</script>

<style lang="scss"></style>
