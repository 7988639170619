<template>
  <div>
    <div class="page-title noprint">
      {{ title }}
    </div>
    <div v-if="subtitle" class="page-subtitle noprint">
      {{ subtitle }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'page-title',
  props: ['title', 'subtitle'],
};
</script>

<style lang="scss">
.page-title {
  height: 48px;
  color: #303030;
  font-family: 'Adelle Sans';
  font-size: 32px;
  font-weight: bold;
  line-height: 40px;
  margin-top: 10px;
  margin-bottom: 30px;
}

.subtitle {
  margin-top: -30px;
  margin-bottom: 20px;
  color: #606060;
  font-size: 18px;
}
</style>