<template>
  <div>
    <div>
      <b-link @click="showGradesMissing = !showGradesMissing">
        Hópar þar sem skráða einkunn vantar
        <i
          class="fa fa-info-circle"
          title="Ath. ef skráða einkunn vantar þá eru líkur á að einkunnir verði ekki rétt reiknaðar upp hjá þeim nemendum"
          v-b-tooltip
        ></i>
        <i class="fa ml-2" :class="{ 'fa-angle-up': !showGradesMissing, 'fa-angle-down': showGradesMissing }" />
      </b-link>
    </div>
    <term-grades-missing :id="id" :item="item" v-if="showGradesMissing"></term-grades-missing>
    <div>
      <b-link @click="showFinalMissing = !showFinalMissing">
        Hópar þar sem lokaeinkunn vantar í námsferil nemenda
        <i class="fa ml-2" :class="{ 'fa-angle-up': !showFinalMissing, 'fa-angle-down': showFinalMissing }" />
      </b-link>
    </div>
    <term-final-missing :id="id" :item="item" v-if="showFinalMissing"></term-final-missing>

    <div>
      <b-link @click="showFinalMismatch = !showFinalMismatch">
        Hópar þar sem lokaeinkunn og einkunn í námsferli eru ekki eins
        <i class="fa ml-2" :class="{ 'fa-angle-up': !showFinalMismatch, 'fa-angle-down': showFinalMismatch }" />
      </b-link>
    </div>
    <term-final-mismatch :id="id" :item="item" v-if="showFinalMismatch"></term-final-mismatch>
    <div>
      <b-link @click="showStudentUnits = !showStudentUnits">
        Einingar nemenda
        <i class="fa ml-2" :class="{ 'fa-angle-up': !showStudentUnits, 'fa-angle-down': showStudentUnits }" />
      </b-link>
    </div>
    <student-units :id="id" :item="item" v-if="showStudentUnits"></student-units>
    <div>
      <b-link @click="showStudentUnfinishedModules = !showStudentUnfinishedModules">
        Óloknir áfangar í námsferli nemenda
        <i class="fa ml-2" :class="{ 'fa-angle-up': !showStudentUnfinishedModules, 'fa-angle-down': showStudentUnfinishedModules }" />
      </b-link>
    </div>
    <student-unfinished-modules :id="id" :item="item" v-if="showStudentUnfinishedModules"></student-unfinished-modules>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import StudentUnits from '@/components/school/terms/end/StudentUnits.vue';
import StudentUnfinishedModules from '@/components/school/terms/end/StudentUnfinishedModules.vue';
import TermFinalMissing from '@/components/school/terms/end/TermFinalMissing.vue';
import TermFinalMismatch from '@/components/school/terms/end/TermFinalMismatch.vue';
import TermGradesMissing from '@/components/school/terms/end/TermGradesMissing.vue';

export default {
  name: 'term-lists',
  props: ['id', 'item'],
  components: {
    StudentUnits,
    StudentUnfinishedModules,
    TermGradesMissing,
    TermFinalMissing,
    TermFinalMismatch,
  },
  computed: {
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolHasSettings']),
  },
  data() {
    return {
      showStudentUnits: false,
      showStudentUnfinishedModules: false,
      showGradesMissing: false,
      showFinalMissing: false,
      showFinalMismatch: false,
    };
  },
  methods: {
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
