<template>
  <page>
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <b-row class="breadcumb-location">
      <b-col>
        <div class="float-right">
          <b-btn variant="dark" @click="openSearchSlidebar" class="ml-1"> <i class="fa fa-exchange" /> Skipta um kennslutímabil </b-btn>
        </div>
        <div class="float-right">
          <b-link :to="{ name: 'Terms', query: searchQuery }">
            <b-btn variant="dark" class="ml-1"> <i class="fa fa-arrow-left" /> Til baka í leit </b-btn>
          </b-link>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="pl-0">
        <PageTitle :title="title" />
        <div class="subtitle"> {{ subtitle }} </div>
      </b-col>
      <b-col class="pt-3"> </b-col>
    </b-row>
    <PageMenu route="Term" :selectedPage="page" :items="menu" />
    <TermDetail v-if="page === 'detail'" :item="term" @reload="loadTerm()" :termPassed="termPassed"></TermDetail>
    <TermGradeRule
      v-if="page === 'graderules'"
      :id="term.onn_id"
      :item="term"
      :divisionId="term.undirskoli_id"
      :termPassed="termPassed"
    ></TermGradeRule>
    <TermBooklist v-if="page === 'booklist' && term" :id="term.onn_id" :item="term"></TermBooklist>
    <TermTeachingPlans v-if="page === 'teachingplan' && term" :id="term.onn_id" :item="term"></TermTeachingPlans>
    <TermEnd v-if="page === 'end'" :id="term.onn_id" :item="term" :termPassed="termPassed"></TermEnd>
    <term-search-slidebar @closeSlidebar="closeSlidebar" v-if="showSearchSlidebar" :large="true"> </term-search-slidebar>
  </page>
</template>

<script>
import terms from '@/api/terms';

import { mapActions, mapGetters } from 'vuex';

import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import PageTitle from '@/components/common/PageTitle.vue';
import PageMenu from '@/components/common/PageMenu.vue';

import TermSearchSlidebar from '@/components/school/terms/TermSearchSlidebar.vue';
import TermDetail from '@/components/school/terms/TermDetail.vue';
import TermGradeRule from '@/components/school/terms/TermGradeRule.vue';
import TermBooklist from '@/components/school/terms/TermBooklist.vue';
import TermTeachingPlans from '@/components/school/terms/TermTeachingPlans.vue';
import TermEnd from '@/components/school/terms/TermEnd.vue';

export default {
  name: 'term',
  components: {
    Breadcrumbs,
    PageTitle,
    TermSearchSlidebar,
    TermDetail,
    PageMenu,
    TermGradeRule,
    TermBooklist,
    TermEnd,
    TermTeachingPlans,
  },
  computed: {
    termPassed() {
      return this.term && this.term.is_past === 1;
    },
    canRead() {
      return this.loggedInUserHasReadPermission('skolahald_annir');
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_annir') && !this.termPassed;
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolHasSettings']),
  },
  data() {
    return {
      breadcrumbs: [
        { name: 'Forsíða', route: 'Dashboard' },
        { name: 'Skólahald', route: '' },
        { name: 'Kennslutímabil', route: 'Terms' },
      ],
      menu: [
        {
          name: 'Stýringar',
          page: 'detail',
        },
        {
          name: 'Einkunnareglur',
          page: 'graderules',
        },
        {
          name: 'Námsgagnalisti',
          page: 'booklist',
        },
        {
          name: 'Námsáætlun',
          page: 'teachingplan',
        },
      ],
      page: 'detail',
      loading: {
        term: false,
      },
      term: '',
      title: '',
      subtitle: '',
      showSearchSlidebar: false,
      searchQuery: '',
    };
  },
  methods: {
    async loadTerm() {
      if (this.canRead) {
        try {
          this.loading.term = true;
          const response = await terms.getTerm(this.id);
          this.term = response.data.items ? response.data.items[0] : '';
          this.breadcrumbs = [
            { name: 'Forsíða', route: 'Dashboard' },
            { name: 'Skólahald', route: '' },
            { name: 'Kennslutímabil', route: 'Terms' },
            { name: this.term.onn_heiti, route: '' },
          ];
          this.title = `${this.term.undirsk_heiti} - ${this.term.onn_heiti}`;
          this.subtitle = this.term.onn_eink;
          if (this.term.is_future === 0 && !this.menu.find(x => x.name === 'Lok tímabils')) {
            this.menu.push({
              name: 'Lok tímabils',
              page: 'end',
            });
          }
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading.term = false;
        }
      }
    },
    openSearchSlidebar() {
      this.showSearchSlidebar = true;
    },
    closeSlidebar(item) {
      if (item && item.id) {
        let query = {};
        if (this.$route.query.page) {
          query.page = this.$route.query.page;
        }
        if (this.$route.query) {
          query = this.$route.query;
        }
        this.$router.push({ name: 'Term', params: { id: item.id }, query });
        this.$router.go();
      }
      this.showSearchSlidebar = false;
      this.loadTerm();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  beforeRouteUpdate(to, from, next) {
    if (to && to.query && to.query.page) {
      this.page = to.query.page;
    }
    next();
  },
  created() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
    if (this.$route.query) {
      this.searchQuery = this.$route.query;
    }
    this.id = this.$route.params.id;
    this.loadTerm();
  },
};
</script>
