<template>
  <div>
    <h5>Færslur úr dagbók</h5>
    <div>
      <Loader v-if="loading"></Loader>
    </div>
    <b-card>
      <b-row>
        <b-col>
          <b-form-group label="Dags. frá" label-for="dags_fra">
            <datepicker id="dags_fra" name="dags_fra" v-model="dags_fra" :language="lang" :monday-first="true"
              format="dd.MM.yyyy" :typeable="false" :clear-button="true" :disabled="loading" ref="dateFromOpenPickr">
            </datepicker>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Dags. til" label-for="dags_til">
            <datepicker id="dags_til" name="dags_til" v-model="dags_til" :language="lang" :monday-first="true"
              format="dd.MM.yyyy" :typeable="false" :clear-button="true" :disabled="loading" ref="dateFromOpenPickr">
            </datepicker>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Sía eftir vinnuleið" label-for="vinnuleid">
            <v-select class="multiSelectVinnuleid" id="vinnuleid" name="vinnuleid" v-model="vinnuleid"
              label="adgangslykill" :options="filterList" :clearable="true" multiple>
            </v-select>
          </b-form-group></b-col>
        <b-col>
          <b-form-group label="Leita eftir atriði" label-for="searchAtridi">
            <b-form-input id="searchAtridi" name="searchAtridi" v-model="searchAtridi" />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-btn variant="primary" class="w-100 pull-right"  style="margin-top: 23px" @click="loadList()"> Leita </b-btn>
        </b-col>
      </b-row>

    </b-card>
    <div class="pt-2 pb-2">
      <i> Ath. eftirfarandi færslur fundust í dagbók fyrir auðkenni nemanda. </i>
    </div>
    <b-card>
      <div v-if="list.length === 0 && !loading">Engar færslur fundust í dagbók</div>
      <table class="table table-hover less-padding no-border" style="font-size: 13px"
        v-if="list.length > 0 && !loading">
        <thead>
          <tr>
            <th>Dags. ({{ list.length }})</th>
            <th>Breytt af</th>
            <th>Vinnuleið</th>
            <th>Aðgerð</th>
            <th>Lykill</th>
            <th>Svæði</th>
            <th>Atriði</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, idx) in list" :key="idx">
            <td style="white-space: nowrap">{{ item.dags | moment('DD.MM.YYYY HH:mm:ss') }}</td>
            <td style="white-space: nowrap">{{ item.nafn }}</td>
            <td style="white-space: nowrap">{{ item.adgangslykill }}</td>
            <td style="white-space: nowrap">{{ item.adgerd_heiti }}</td>
            <td style="white-space: nowrap">{{ item.lykill }}</td>
            <td style="white-space: nowrap">{{ item.svaedi }}</td>
            <td>{{ item.atridi }}</td>
          </tr>
        </tbody>
      </table>
    </b-card>
  </div>
</template>

<script>
import common from '@/api/common';
import { mapGetters, mapActions } from 'vuex';
import Loader from '@/components/common/Loader.vue';
import moment from 'moment';

import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';

export default {
  name: 'student-history',
  components: { Loader, Datepicker },
  props: ['id'],
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('nemendahald_nemendur');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('nemendahald_nemendur');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      loading: false,
      list: [],
      showSlidebar: false,
      lang: is,
      searchAtridi: '',
      dags_fra: '',
      dags_til: '',
      vinnuleid: [],
      filterList: [],
    };
  },
  methods: {
    async loadList() {
      let adgangslykill = '';
      this.loading = true;
      adgangslykill = this.vinnuleid.map(x => x.adgangslykill).join(',');
      const params = {
        searchLike: `NemandiId=${this.id}`,
        types: adgangslykill,
        searchLikeAtridi: this.searchAtridi,
        dateFrom: this.dags_fra ? moment(this.dags_fra).format('DD.MM.YYYY') : '',
        dateTo: this.dags_til ? moment(this.dags_til).format('DD.MM.YYYY') : '',
      };
      try {
        const response = await common.getDiary('', params);
        this.list = response.data.items.map(x => ({
          ...x,
          svaedi: x.svaedi ? x.svaedi.replaceAll(',', ', ') : '',
          atridi: x.atridi ? x.atridi.replaceAll(',', ', ') : '',
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadFilter() {
      try {
        const response = await common.getDiaryFilterType({ searchLike: `NemandiId=${this.id}` });
        this.filterList = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.dags_fra = new Date().setMonth(new Date().getMonth() - 3);
    this.dags_til = new Date();
    this.loadFilter();
    this.loadList();
  },
};
</script>
<style lang="scss">
.multiSelectVinnuleid {
  font-size: 12px;
  .vs__selected {
    padding: 2px;
    font-size: 12px;
  }
  .vs__selected-options ,
  .vs__dropdown-toggle {
    padding: 4px;
  }
  .vs__dropdown-menu {
    font-size: 12px;
    padding: 4px;
    min-width: 50px;
  }
  .vs__open-indicator {
    display: none;
  }
}
</style>
