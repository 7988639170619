import Vue from 'vue';

const API_URL = '/api/single-classes';

export default {
  getList(params) {
    return Vue.http
      .get(`${API_URL}/list`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getCategories() {
    return Vue.http
      .get(`${API_URL}/category`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  saveCategory(data) {
    return Vue.http
      .post(`${API_URL}/category`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createClass(data) {
    return Vue.http
      .post(`${API_URL}/list`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  addParticipant(data) {
    return Vue.http
      .post(`${API_URL}/participant`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateParticipant(id, data) {
    return Vue.http
      .post(`${API_URL}/participant/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  deleteParticipant(id) {
    return Vue.http
      .delete(`${API_URL}/participant/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  sendMessage(data) {
    return Vue.http
      .post(`${API_URL}/send-message`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getPayments() {
    return Vue.http
      .get(`${API_URL}/payments`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
};
