<template>
  <Slidebar :autoClose="false" @closeMe="closeSlidebar" :large="true">
    <h4>Beiðnir nemanda</h4>
    <StudentInfo v-if="studentId" :id="studentId" style="margin-bottom: -10px" />
    <StudentAbsenceRequests :id="studentId" :absenceTypes="absenceTypes" />
  </Slidebar>
</template>

<script>
import { mapActions } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';
import StudentInfo from '@/components/common/StudentInfo.vue';
import StudentAbsenceRequests from '@/components/students/student/absenceRequests/StudentAbsenceRequests.vue';

export default {
  name: 'student-absence-requests-slidebar',
  props: ['studentId', 'absenceTypes'],
  components: {
    Slidebar,
    StudentInfo,
    StudentAbsenceRequests,
  },
  data() {
    return {};
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {},
};
</script>

<style lang="scss"></style>
