import Vue from 'vue';

const API_URL = '/api/counseling';

export default {
  getOverview(params) {
    return Vue.http
      .get(`${API_URL}/overview`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getList(params) {
    return Vue.http
      .get(`${API_URL}/list`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getItem(id) {
    return Vue.http
      .get(`${API_URL}/item/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getCategories() {
    return Vue.http
      .get(`${API_URL}/category`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getConclusions() {
    return Vue.http
      .get(`${API_URL}/conclusion`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getReasons() {
    return Vue.http
      .get(`${API_URL}/reason`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getSchoolLevels() {
    return Vue.http
      .get(`${API_URL}/school-level`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStatus() {
    return Vue.http
      .get(`${API_URL}/status`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getUnion() {
    return Vue.http
      .get(`${API_URL}/union`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getSearchPerson(ssn) {
    return Vue.http
      .get(`${API_URL}/search-person/${ssn}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getPricing(params) {
    return Vue.http
      .get(`${API_URL}/pricing/`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getNumber(params) {
    return Vue.http
      .get(`${API_URL}/number/`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getGroupNumber(params) {
    return Vue.http
      .get(`${API_URL}/group-number/`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getGroupList() {
    return Vue.http
      .get(`${API_URL}/group-list`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createInterview(data) {
    return Vue.http
      .post(`${API_URL}/interview`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
};
