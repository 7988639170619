import auth from '@/api/auth';
import store from '@/store';

const redirectUrl = process.env.VUE_APP_LOGIN_REDIRECT_URL || 'https://inna.is/innskraning?c=http://admin.inna.is/innskraning&s=2';

/*
 * Athuga hvort innskráning sé ekki til eða
 * er útrunnin.
 */
const checkExpired = token => {
  // eslint-disable-line no-unused-vars
  if (!token || auth.isTokenExpired()) {
    return true;
  }

  return false;
};

const doRequireAuth = (to, from, next) => {
  // eslint-disable-line no-unused-vars
  const token = auth.getToken();
  if (checkExpired(token)) {
    window.location.href = redirectUrl;
  } else {
    const q = [];
    if (store.state.loggedInUser == null) {
      q.push(store.dispatch('getLoggedInUser'));
    }
    Promise.all(q)
      .then(() => {})
      .catch(() => {})
      .then(() => {
        next();
      });
  }
};

export default {
  /*
   * Krefjast innskráningar.
   */
  requireAuth(to, from, next) {
    // eslint-disable-line no-unused-vars
    doRequireAuth(to, from, next);
  },
  /*
   * Skrá út fyrst
   */
  logoutBefore(to, from, next) {
    // eslint-disable-line no-unused-vars
    auth.removeToken();
    next();
    // store.dispatch('doClean');
    // window.location.href = redirectUrl;
  },
};
