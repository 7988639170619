<template>
  <Slidebar :autoClose="false" :large="true" @closeMe="closeSlidebar">
    <h4>Útskriftir</h4>
    <table class="table no-border pt-3">
      <tr>
        <td style="width: 20%">Braut:</td>
        <td>{{ graduationInfoCopy.heiti }}</td>
      </tr>
      <tr>
        <td style="width: 20%">Dags útskriftar:</td>
        <td>
          <datepicker
            id="date"
            name="date"
            v-model="graduationInfoCopy.dags"
            :language="lang"
            :monday-first="true"
            format="dd.MM.yyyy"
            :typeable="false"
            :clear-button="true"
            ref="dateOpenPickr"
          ></datepicker>
        </td>
      </tr>
      <tr>
        <td style="width: 20%">Önn:</td>
        <td>{{ graduationInfoCopy.onn_heiti }}</td>
      </tr>
      <tr>
        <td style="width: 20%">Skráð af:</td>
        <td>{{ graduationInfoCopy.skrad_af }}</td>
      </tr>

      <tr>
        <td style="width: 20%">Athugasemd</td>
        <td>
          <b-form-group>
            <b-form-input v-model="graduationInfoCopy.athugasemd" :maxLength="2000" />
          </b-form-group>
        </td>
      </tr>
    </table>
    <div class="pt-2 pb-2">
      <b-btn variant="primary" class="mr-2" @click="editGraduation()" v-if="canEdit">Breyta </b-btn>
      <b-btn variant="dark" @click="closeSlidebar">Hætta við</b-btn>
      <b-btn variant="danger" class="float-right" @click="deleteGraduation()" v-if="canEdit"> Eyða </b-btn>
    </div>
  </Slidebar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import students from '@/api/students';
import Slidebar from '@/components/common/Slidebar.vue';
import Datepicker from 'vuejs-datepicker';
import moment from 'moment';
import { is } from 'vuejs-datepicker/dist/locale';

export default {
  name: 'student-graduations-slidebar',
  props: ['studentId', 'graduationInfo'],
  components: {
    Slidebar,
    Datepicker,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('nemendahald_nemendur');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      saving: false,
      lang: is,
      graduationInfoCopy: [], // afrit svo það yfirskrifast ekki í bakgrunn fyrir save
    };
  },
  methods: {
    async editGraduation() {
      const data = {
        utskrift_id: this.graduationInfoCopy.utskriftir_id,
        athugasemd: this.graduationInfoCopy.athugasemd,
        dags: this.graduationInfoCopy.dags ? moment(this.graduationInfoCopy.dags).format('DD.MM.YYYY') : '',
        eyda: 0,
        braut_id: this.graduationInfoCopy.braut_id,
      };
      try {
        await students.postUpdateStudentsGraduation(this.studentId, data);
        this.displaySuccess(`Útskriftarfærsla hefur verið uppfærð.`);
        this.closeAndReloadSlidebar();
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async deleteGraduation() {
      const data = {
        utskrift_id: this.graduationInfoCopy.utskriftir_id,
        athugasemd: this.graduationInfoCopy.athugasemd,
        eyda: 1,
      };
      try {
        const conf = confirm('Ertu viss um að þú viljir eyða útskrift?'); // eslint-disable-line
        this.saving = true;
        if (conf) {
          await students.postUpdateStudentsGraduation(this.studentId, data);
          this.displaySuccess(`Útskrift hefur verið eytt.`);
          this.closeAndReloadSlidebar();
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`${e.response.data.error ? e.response.data.error : `Óvænt villa kom upp. Vinsamlegast reyndu aftur.`}`);
      } finally {
        this.saving = false;
      }
    },
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    closeAndReloadSlidebar() {
      this.$emit('closeAndReloadSlidebar');
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.graduationInfoCopy = JSON.parse(JSON.stringify(this.graduationInfo));
  },
};
</script>

<style lang="scss"></style>
