<template>
  <div>
    <Loader v-if="loading" />
    <div v-else>
      <div v-if="list.length > 0">
        <div>
          <i
            >Nemendur sem eru með annan skóla skráðan sem aðalskóla eða nemendur sem eru ekki með áfanga í stöðunni ólokið á
            kennslutímabilinu.</i
          >
        </div>
        <table class="table">
          <thead>
            <tr class="table-search">
              <td colspan="5">
                <b-form-input v-model="search" class="search" style="margin-top: 0px; margin-bottom: 15px" placeholder="Leita" />
              </td>
            </tr>
            <tr>
              <th>
                <LinkOrder title="Nafn" :filteredList="list" :list="list" column="nafn" second_column="kennitala" /> ({{ getList.length }})
              </th>
              <th><LinkOrder title="Kennitala" :filteredList="list" :list="list" column="kennitala" second_column="nafn" /></th>
              <th><LinkOrder title="Aðalskóli" :filteredList="list" :list="list" column="skoli_heiti" second_column="nafn" /></th>
              <th><LinkOrder title="Braut" :filteredList="list" :list="list" column="brautir" second_column="nafn" /></th>
              <th>
                <download-csv
                  v-if="!loading"
                  :data="getExcelData"
                  :name="getExcelFileName"
                  delimiter=";"
                  class="cursor-pointer float-right"
                >
                  <i class="fa fa-file-excel-o excelText"></i>
                </download-csv>
              </th>
            </tr>
          </thead>
          <tr
            v-for="(item, index) in getPaginatedList"
            :key="index"
            :class="{ 'active-table-row': selectedItem && selectedItem.kennitala === item.kennitala, 'cursor-pointer': showSlidebar }"
            @click="checkSlidebar(item)"
          >
            <td>
              <b-link @click="redirectoToStudyRecords(item.nemandi_id)" title="Námsferill">{{ item.nafn }}</b-link>
            </td>
            <td>{{ item.kennitala }}</td>
            <td>{{ item.skoli_heiti }}</td>
            <td>{{ item.brautir }}</td>
            <td>
              <b-btn class="float-right" variant="primary" size="sm" @click.prevent="openSlidebar(item)">
                <i class="fa fa-search" />
              </b-btn>
            </td>
          </tr>
        </table>
        <br />
        <Pagination
          :currentPage="currentPage"
          :totalRows="getList.length"
          :perPage="perPage"
          v-if="list.length > 25"
          @change-page="changePage"
          @change-limit="changeLimit"
        />
      </div>
      <div v-else>Engir nemendur fundust</div>
    </div>
    <student-course-slidebar v-if="showSlidebar" :item="selectedItem" @closeSlidebar="closeSlidebar"> </student-course-slidebar>
  </div>
</template>

<script>
import moment from 'moment';
import reports from '@/api/reports';
import { mapActions } from 'vuex';

import Loader from '@/components/common/Loader.vue';
import Pagination from '@/components/common/Pagination.vue';

import StudentCourseSlidebar from '@/components/reports/mainschool/StudentCourseSlidebar.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';

export default {
  name: 'mainschool-difference',
  components: {
    Loader,
    StudentCourseSlidebar,
    Pagination,
    LinkOrder,
  },
  computed: {
    getExcelData() {
      const newLine = [
        [],
        ['Aðalskóli - Nemendur mismunur'],
        [],
        ['Nafn', 'Kennitala', 'Aðalskóli', 'Braut'],
        ...this.list.map(x => [x.nafn, `="${x.kennitala}"`, x.skoli_heiti, x.brautir]),
      ];
      return newLine;
    },
    getExcelFileName() {
      return `adalskoli_nemendur_mismunur_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    getList() {
      return this.list.filter(
        x =>
          this.search.length === 0 ||
          (x.nafn || '').toUpperCase().indexOf(this.search.toUpperCase()) > -1 ||
          x.kennitala.indexOf(this.search) > -1 ||
          (x.skoli_heiti || '').toUpperCase().indexOf(this.search.toUpperCase()) > -1 ||
          (x.brautir || '').toUpperCase().indexOf(this.search.toUpperCase()) > -1,
      );
    },
    getPaginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.getList.slice(start, end);
    },
  },
  data() {
    return {
      perPage: 25,
      currentPage: 1,
      loading: false,
      list: [],
      showSlidebar: false,
      selectedItem: {},
      search: '',
    };
  },
  methods: {
    async loadList() {
      try {
        this.list = [];
        this.loading = true;
        const response = await reports.getNotHomeschool();
        this.list = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    openSlidebar(item) {
      this.selectedItem = item;
      this.showSlidebar = true;
    },
    closeSlidebar() {
      this.showSlidebar = false;
      this.selectedItem = {};
    },
    checkSlidebar(item) {
      if (this.showSlidebar) {
        this.openSlidebar(item);
      }
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
    redirectoToStudyRecords(notandiId) {
      const url = this.$router.resolve({ 
        path: `/nemandi/${notandiId}`, 
        query: { page: 'studyRecords' } 
      }).href;
      
      window.open(url, '_blank');
    },
  },
  created() {
    this.loadList();
  },
};
</script>

<style lang="scss"></style>
