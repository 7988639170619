<template>
  <Slidebar @closeMe="closeSlidebar" :large="large" :extraLarge="extraLarge">
    <h4 v-if="id">{{ item.heiti }}</h4>
    <h4 v-else>Stofna tegund forfalla starfsmanna</h4>
    <br />
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <table class="table less-padding">
        <tr>
          <td>Heiti*</td>
          <td>
            <b-form-group
              label-for="heiti"
              :state="submitted && errors.has('heiti') ? false : ''"
              :invalid-feedback="errors.first('heiti')"
            >
              <b-form-input
                id="heiti"
                name="heiti"
                type="text"
                v-model="form.name"
                :state="submitted && errors.has('heiti') ? false : ''"
                maxLength="40"
                v-validate="'required'"
                ref="focusInput"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Kóði*</td>
          <td>
            <b-form-group label-for="kodi" :state="submitted && errors.has('kodi') ? false : ''" :invalid-feedback="errors.first('kodi')">
              <b-form-input
                id="kodi"
                name="kodi"
                type="text"
                v-model="form.code"
                :state="submitted && errors.has('kodi') ? false : ''"
                maxLength="1"
                v-validate="'required'"
                data-vv-as="kóði"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Kóði - Nemandi</td>
          <td>
            <b-form-group label-for="kodiNem" :state="submitted && errors.has('kodiNem') ? false : ''" :feedback="errors.first('kodiNem')">
              <v-select
                id="kodiNem"
                name="kodiNem"
                v-model="form.attendance"
                :options="attendances"
                :clearable="true"
                data-vv-as="kóði - nemandi"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
      </table>
      <b-btn variant="primary" class="mr-2" v-if="canEdit" @click="validateBeforeSubmit">
        <span v-if="id">Breyta</span>
        <span v-else>Stofna</span>
      </b-btn>
      <b-btn variant="dark" @click="closeSlidebar">Hætta við</b-btn>
      <b-btn variant="danger" class="float-right" v-if="canEdit && id" @click="deleteStaffAbsence"> Eyða </b-btn>
    </b-form>
  </Slidebar>
</template>

<script>
import types from '@/api/types';
import { mapActions, mapGetters } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';

export default {
  name: 'staff-absence-type-slidebar',
  props: ['item', 'large', 'extraLarge'],
  components: {
    Slidebar,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      loading: false,
      submitted: false,
      id: null,
      attendances: [],
      form: {
        name: '',
        code: '',
        attendance: '',
      },
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async loadAttendance(id) {
      try {
        const response = await types.getAttendanceList();
        this.attendances = response.data.items.map(x => ({
          id: x.teg_fjarvista,
          label: x.kodi,
        }));
        if (this.id) {
          this.form.attendance = this.attendances.find(x => x.id === id);
        }
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadStaffAbsenceDetail() {
      try {
        this.loading = true;
        const response = await types.getStaffAbsenceDetail(this.id);
        if (response.data.items && response.data.items.length > 0) {
          const item = response.data.items[0];
          this.form.name = item.heiti;
          this.form.code = item.kodi;
          this.loadAttendance(item.teg_fjarvista);
        }
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.saving = true;
          const data = {
            name: this.form.name,
            code: this.form.code,
            attendance: this.form.attendance ? this.form.attendance.id : '',
          };
          if (this.id) {
            await types.updateStaffAbsence(this.id, data);
            this.displaySuccess(`${this.form.name} hefur verið breytt.`);
          } else {
            await types.createStaffAbsence(data);
            this.displaySuccess(`${this.form.name} hefur verið stofnuð.`);
          }
          this.closeSlidebar();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.data && e.response.data.error) {
            this.displayError(e.response.data.error);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.saving = false;
      }
    },
    async deleteStaffAbsence() {
      try {
        const conf = confirm(`Ert þú viss um að eyða ${this.item.heiti}?`); // eslint-disable-line
        if (conf) {
          await types.updateStaffAbsence(this.id, { delete: 1 });
          this.displaySuccess(`${this.item.heiti} hefur verið eytt.`);
          this.closeSlidebar();
        }
      } catch (e) {
        // Aðgerð mistókst.
        this.$log.debug(e);
        if (e.response && e.response.data && e.response.data.error) {
          this.displayError(e.response.data.error);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    item() {
      this.form.name = '';
      this.form.code = '';
      this.form.attendance = '';
      if (this.item && this.item.teg_forf_stm_id) {
        this.id = this.item.teg_forf_stm_id;
        this.loadStaffAbsenceDetail();
      }
      this.$nextTick(() => {
        if (this.$refs.focusInput) {
          this.$refs.focusInput.focus();
        }
      });
    },
  },
  created() {
    this.form.name = '';
    this.form.code = '';
    this.form.attendance = '';
    this.id = null;
    if (this.item && this.item.teg_forf_stm_id) {
      this.id = this.item.teg_forf_stm_id;
      this.loadStaffAbsenceDetail();
    } else {
      this.loadAttendance();
    }
    this.$nextTick(() => {
      if (this.$refs.focusInput) {
        this.$refs.focusInput.focus();
      }
    });
  },
};
</script>

<style lang="scss"></style>
