<template>
  <page>
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <PageTitle title="Stundatöflur" />
    <PageMenu route="Timetable" :selectedPage="page" :items="menu" />
    <ChangeTimetable v-if="page === 'changeTimetable' && canRead" :item="item" />
    <SchoolEventsTimetable v-if="page === 'school'"></SchoolEventsTimetable>
    <StaffTimetable v-if="page === 'staff'" />
    <StudentTimetable v-if="page === 'student'" />
    <ModuleTimetable v-if="page === 'module'" />
    <ClassroomTimetable v-if="page === 'room'" />
    <ClassTimetable v-if="page === 'class' && schoolSettings.afangaskoli === 0" />
  </page>
</template>

<script>
import { mapGetters } from 'vuex';

import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import PageTitle from '@/components/common/PageTitle.vue';
import PageMenu from '@/components/common/PageMenu.vue';
import ChangeTimetable from '@/components/school/timetable/ChangeTimetable.vue';
import StaffTimetable from '@/components/school/timetable/StaffTimetable.vue';
import StudentTimetable from '@/components/school/timetable/StudentTimetable.vue';
import ModuleTimetable from '@/components/school/timetable/ModuleTimetable.vue';
import ClassroomTimetable from '@/components/school/timetable/ClassroomTimetable.vue';
import ClassTimetable from '@/components/school/timetable/ClassTimetable.vue';
import SchoolEventsTimetable from '@/components/school/timetable/SchoolEventsTimetable.vue';

export default {
  name: 'timetable',
  components: {
    Breadcrumbs,
    PageTitle,
    PageMenu,
    ChangeTimetable,
    StaffTimetable,
    StudentTimetable,
    ModuleTimetable,
    ClassroomTimetable,
    ClassTimetable,
    SchoolEventsTimetable,
  },
  computed: {
    canRead() {
      return this.loggedInUserHasReadPermission('skolahald_stundatoflur');
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_stundatoflur');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {
      item: '',
      loading: false,
      page: 'changeTimetable',
      breadcrumbs: [
        { name: 'Forsíða', route: 'Dashboard' },
        { name: 'Skólahald', route: '' },
        { name: 'Stundatöflur', route: 'Timetable' },
      ],
      menu: [
        {
          name: 'Breyta stundatöflum',
          page: 'changeTimetable',
        },
        {
          name: 'Skóladagatal',
          page: 'school',
        },
        {
          name: 'Nemendatöflur',
          page: 'student',
        },
        {
          name: 'Kennaratöflur',
          page: 'staff',
        },
        {
          name: 'Áfangatöflur',
          page: 'module',
        },
        {
          name: 'Stofutöflur',
          page: 'room',
        },
      ],
    };
  },
  methods: {},
  beforeRouteUpdate(to, from, next) {
    if (to && to.query && to.query.page) {
      this.page = to.query.page;
    }
    next();
  },
  created() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
    if (this.schoolSettings.afangaskoli === 0) {
      this.menu.push({
        name: 'Bekkjatöflur',
        page: 'class',
      });
    }
  },
};
</script>
