<template>
  <Slidebar :autoClose="true" @closeMe="closeSlidebar" :large="large" :extraLarge="extraLarge">
    <h4>Einkunnayfirlit</h4>
    <AssessmentOverview
      :groupId="groupId"
      :showMissing="showMissing"
      :showFinalMissing="showFinalMissing"
      :showFinalMismatch="showFinalMismatch"
      :showGroupDetails="true"
    />
  </Slidebar>
</template>

<script>
import Slidebar from '@/components/common/Slidebar.vue';
import AssessmentOverview from '@/components/students/groups/grades/AssessmentOverview.vue';

export default {
  name: 'group-assessment-overview-slidebar',
  components: {
    Slidebar,
    AssessmentOverview,
  },
  props: ['groupId', 'showMissing', 'showFinalMissing', 'showFinalMismatch', 'large', 'extraLarge'],
  methods: {
    closeSlidebar(item) {
      this.$emit('closeSlidebar', item);
    },
  },
};
</script>

<style lang="scss"></style>
