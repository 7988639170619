<template>
  <div>
    <b-row class="breadcumb-location" v-if="this.list.length > 0">
      <b-col>
        <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="d-inline mr-3">
          <CustomButton type="excel" />
        </download-csv>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="pl-0">
        <b-form-group label="Dags.frá" label-for="dateFrom">
          <datepicker
            id="dateFrom"
            name="dateFrom"
            v-model="search.date_from"
            :language="lang"
            :monday-first="true"
            format="dd.MM.yyyy"
            :typeable="false"
            :clear-button="true"
            ref="dateFromOpenPickr"
          ></datepicker>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Dags.til" label-for="dateTo">
          <datepicker
            id="dateTo"
            name="dateTo"
            v-model="search.date_to"
            :language="lang"
            :monday-first="true"
            format="dd.MM.yyyy"
            :typeable="false"
            :clear-button="true"
            ref="dateToOpenPickr"
          ></datepicker>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Starfsmaður" label-for="name">
          <b-form-input id="name" name="name" v-model="search.staff_name" />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Tegund viðtals" label-for="category">
          <v-select
            id="category"
            name="category"
            v-model="search.categories"
            multiple
            :options="categories"
            label="counseling_category_name"
          >
          </v-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="pl-0">
        <b-form-group label="Einstaklingur kennitala" label-for="name">
          <b-form-input id="name" name="name" v-model="search.counselee_ssn" />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Einstaklingur nafn" label-for="name">
          <b-form-input id="name" name="name" v-model="search.counselee_name" />
        </b-form-group>
      </b-col>
      <b-col>
        <b-btn variant="primary" @click="loadList()" class="w-100" style="margin-top: 33px">Leita</b-btn>
      </b-col>
      <b-col />
    </b-row>
    <br />
    <Loader v-if="loading.list" />
    <div v-if="!loading.list && list.length === 0 && firsttime">Engar niðurstöður fundust.</div>
    <table class="table" v-if="list.length > 0">
      <thead>
        <tr>
          <th>Nafn ({{ list.length }})</th>
          <th>Kennitala</th>
          <th>Fjöldi viðtala</th>
        </tr>
      </thead>
      <tr v-for="(item, index) in getPaginatedList" :key="index">
        <td>{{ item.nafn }}</td>
        <td>{{ item.ssn }}</td>
        <td>{{ item.times }}</td>
      </tr>
    </table>
    <br />
    <Pagination
      :currentPage="currentPage"
      :totalRows="list.length"
      :perPage="perPage"
      v-if="list.length > 25"
      @change-page="changePage"
      @change-limit="changeLimit"
    />
  </div>
</template>

<script>
import counseling from '@/api/counseling';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

import Loader from '@/components/common/Loader.vue';
import Pagination from '@/components/common/Pagination.vue';
import CustomButton from '@/components/common/CustomButton.vue';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';

export default {
  name: 'counseling-overview',
  components: {
    Loader,
    Pagination,
    Datepicker,
    CustomButton,
  },
  computed: {
    getPaginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.list.slice(start, end);
    },
    getExcelData() {
      const newList = [
        [],
        ['Yfirlit yfir námsráðgjöf'],
        [],
        ['Nafn', 'Kennitala', 'Fjöldi viðtala'],
        ...this.list.map(x => [x.nafn, x.ssn, x.times]),
      ];
      return newList;
    },
    getExcelFileName() {
      return `namsradgjof_yfirlit_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      perPage: 25,
      currentPage: 1,
      firsttime: false,
      list: [],
      categories: [],
      loading: {
        categories: false,
        list: false,
      },
      lang: is,
      search: {
        staff_name: '',
        counselee_name: '',
        counselee_ssn: '',
        date_from: '',
        date_to: '',
        categories: [],
      },
    };
  },
  methods: {
    async loadList() {
      try {
        this.list = [];
        this.loading.list = true;
        const params = { ...this.search };
        params.date_from = params.date_from ? moment(params.date_from).format('DD.MM.YYYY') : '';
        params.date_to = params.date_to ? moment(params.date_to).format('DD.MM.YYYY') : '';
        params.categories = params.categories.map(x => x.nsr_counseling_category_id).join(',');
        const response = await counseling.getOverview(params);
        this.list = response.data.items;
        this.firsttime = true;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.list = false;
      }
    },
    async loadCategories() {
      try {
        this.categories = [];
        this.loading.categories = true;
        const response = await counseling.getCategories();
        this.categories = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.categories = false;
      }
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadCategories();
    this.loadList();
  },
};
</script>
