<template>
  <Slidebar :large="true" :autoClose="false" @closeMe="closeSlidebar">
    <div v-if="innrit_brautaskipti" style="margin-bottom: -10px">
      <i class="fa fa-info-circle"></i>
      Ef opið er fyrir brautarskipti í undirskólanum er hægt að sækja um eftirfarandi brautir. Ef smellt er á takkann „Breyta“ er hægt að
      eiga við stillingarnar.
    </div>
    <div v-if="innrit_menntagatt" style="margin-bottom: -10px">
      <i class="fa fa-info-circle"></i>
      Ef opið er fyrir umsóknir í Menntagátt í undirskólanum er hægt að sækja um eftirfarandi brautir. Ef smellt er á takkann „Breyta“ er
      hægt að eiga við stillingarnar.
    </div>
    <CoursesApplicationList
      :divisionId="divisionId"
      :innrit_menntagatt="innrit_menntagatt"
      :innrit_brautaskipti="innrit_brautaskipti"
    ></CoursesApplicationList>
  </Slidebar>
</template>

<script>
import Slidebar from '@/components/common/Slidebar.vue';
import { mapGetters, mapActions } from 'vuex';

import CoursesApplicationList from '@/components/curriculum/courses/CoursesApplicationList.vue';

export default {
  name: 'courses-applicaton-slidebar',
  components: {
    Slidebar,
    CoursesApplicationList,
  },
  props: ['divisionId', 'innrit_menntagatt', 'innrit_brautaskipti'],
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_brautir');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('namskra_brautir');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {};
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {},
};
</script>

<style lang="scss"></style>
