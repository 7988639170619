<template>
  <div>
    <div class="alert alert-info p-2" style="font-size: 13px" v-if="showAll">
      <div>
        <strong>Menntagátt</strong> er innritunarvefur Menntamálastofnunnar sem framhaldsskólar nota. Til þess að hægt sé að sækja um braut
        í menntagátt þarf að vera merkt við „Má sækja um í menntagátt“. Ath. til að hægt sé að sækja um á menntagátt þarf að vera opið fyrir
        það á kennslutímabilinu.
        <b-link v-if="canReadTerms" :to="{ name: 'Terms' }" target="_blank" style="color: inherit; font-style: italic"
          >Smelltu hér til að skoða kennslutímabil.</b-link
        >
      </div>
      <div>
        <strong>Brautaskiptabeiðnir nemenda</strong>: Til þess að hægt sé að sækja um brautaskipti í námskerfi Innu þarf að vera merkt við
        „Má sækja um brautaskipti“. Ath. til að hægt sé að sækja um brautaskipti þarf að vera opið fyrir það í undirskólanum.
        <b-link
          v-if="canReadSchool"
          :to="{ name: 'School', query: { page: 'structure', tab: 'division' } }"
          target="_blank"
          style="color: inherit; font-style: italic"
          >Smelltu hér til að skoða undirskóla.</b-link
        >
      </div>
      <div>
        <strong>Brautarskipti hjá innrituðum nemendum</strong>: Til að stjórnendur geti sett nemendur á braut í stjórnendaviðmóti þarf að
        vera merkt við „Má innrita nema á námsbraut“.
      </div>
      <div>
        <strong>Umsóknarvefur</strong>: Til þess að hægt sé að sækja um á braut í umsóknarvef Innu þarf að vera merkt við „Má sækja um á
        umsóknarvef“. Ath. til að hægt sé að sækja um þarf að vera opið fyrir það á kennslutímabilinu. Þær stýringar er að finna í
        stjórnendaviðmóti umsóknarvefs.
      </div>
    </div>
    <table class="table table-striped less-padding table-sticky-header" style="font-size: 12px">
      <thead>
        <tr class="table-search">
          <td colspan="6" :class="{ 'pb-0': detailedSearch }">
            <b-form-input
              v-model="search.search"
              class="search"
              v-on:keyup.enter="loadList"
              placeholder="Leita eftir braut"
              ref="focusInput"
            ></b-form-input>
          </td>
          <td colspan="4" :class="{ 'pb-0': detailedSearch }">
            <b-row>
              <b-col> <b-btn variant="primary" class="w-100" style="margin-top: 25px" @click="loadList()">Leita</b-btn></b-col>
            </b-row>
            <div class="cursor-pointer text-right pt-2" :class="{ visibilityHidden: !showAll }" @click="detailedSearch = !detailedSearch">
              <span v-if="detailedSearch"><i class="fa fa-angle-up"></i> Fela ítarleit</span>
              <span v-else><i class="fa fa-angle-up"></i> Sýna ítarleit</span>
            </div>
          </td>
        </tr>
        <tr class="table-search">
          <td colspan="100%" :class="{ 'pb-0': detailedSearch }">
            <b-row style="margin-top: -20px">
              <b-col class="pl-0" cols="3" v-if="showAll || innrit_menntagatt">
                Menntagátt:
                <i class="fa fa-info-circle" title="Nemendur mega sækja um brautina í Menntagátt." v-b-tooltip> </i>
                <v-select
                  class="dropdownMatchHeight"
                  style="margin-top: 8px"
                  id="enroll"
                  name="enroll"
                  v-model="search.enrollMenntagatt"
                  :options="enrollOptions"
                  :clearable="true"
                >
                </v-select> </b-col
              ><b-col class="pl-0" cols="3" v-if="showAll">
                Innritaðir:
                <i
                  class="fa fa-info-circle"
                  title="Opið fyrir að stjórnendur geti skráð nemanda á brautina í stjórnendaviðmóti."
                  v-b-tooltip
                >
                </i>
                <v-select
                  class="dropdownMatchHeight"
                  style="margin-top: 8px"
                  id="enroll"
                  name="enroll"
                  v-model="search.enroll"
                  :options="enrollOptions"
                  :clearable="true"
                >
                </v-select>
              </b-col>
              <b-col class="pl-0" cols="3" v-if="showAll"
                >Umsóknarvefur
                <i class="fa fa-info-circle" title="Má sækja um brautina í umsóknarvef Innu." v-b-tooltip></i>
                <v-select
                  class="dropdownMatchHeight"
                  style="margin-top: 8px"
                  id="enrollNew"
                  name="enrollNew"
                  v-model="search.enrollNew"
                  :options="enrollOptions"
                  :clearable="true"
                >
                </v-select>
              </b-col>
              <b-col class="pl-0" cols="3" v-if="showAll || innrit_brautaskipti"
                >Brautaskipti
                <i class="fa fa-info-circle" title="Nemandi má sækja um brautina í brautaskiptum." v-b-tooltip></i>
                <v-select
                  class="dropdownMatchHeight"
                  style="margin-top: 8px"
                  id="enrollNew"
                  name="enrollNew"
                  v-model="search.enrollCourseChange"
                  :options="enrollOptions"
                  :clearable="true"
                >
                </v-select>
              </b-col>
              <b-col class="pl-0" cols="3" v-if="!showAll">
                <b-form-group label="Undirskóli" label-for="division">
                  <v-select
                    class="dropdownMatchHeight"
                    style="margin-top: 8px"
                    id="division"
                    name="division"
                    v-model="search.division"
                    :options="divisions"
                    :clearable="false"
                    disabled
                  >
                  </v-select>
                </b-form-group>
              </b-col>
            </b-row>
          </td>
        </tr>
        <tr v-if="detailedSearch" class="table-detail-search">
          <td colspan="100%">
            <b-row>
              <b-col cols="3" class="pl-0">
                <b-form-group label="Deild" label-for="department">
                  <v-select id="department" name="department" v-model="search.department" :options="departments" :clearable="true">
                  </v-select>
                </b-form-group> </b-col
              ><b-col cols="3">
                <b-form-group label="Undirskóli" label-for="division">
                  <v-select id="division" name="division" v-model="search.division" :options="divisions" :clearable="true"> </v-select>
                </b-form-group>
              </b-col>
            </b-row>
          </td>
        </tr>
        <tr class="table-detail-search" v-if="canEdit && list.length > 0">
          <td colspan="100%">
            <b-btn variant="dark" size="sm" class="float-right" v-if="!editing" @click="editAll()" 
              ><i class="fa fa-pencil"></i> Breyta</b-btn 
            >
            <b-btn variant="dark" size="sm" class="float-right" v-if="editing" @click="cancelEdit()"
              ><i class="fa fa-remove"></i> Hætta við</b-btn
            >
            <b-btn variant="success" size="sm" class="float-right mr-2" v-if="editing" @click="saveData()"
              ><i class="fa fa-save"></i> Vista</b-btn
            >
            <i v-if="saving" class="fa fa-spin fa-spinner mr-2 float-right mt-2" style="font-size: 15px"></i>
          </td>
        </tr>
        <tr v-if="firsttime">
          <th><LinkOrder title="Heiti" :filteredList="list" :list="list" column="heiti" /> ({{ list.length }})</th>
          <th><LinkOrder title="Kóði" :filteredList="list" :list="list" column="braut_kodi" /></th>
          <th><LinkOrder title="Undirsk." :filteredList="list" :list="list" column="undirsk_audk" /></th>
          <th v-if="showAll || innrit_menntagatt">
            <LinkOrder title="Fj. nýnema" :filteredList="list" :list="list" column="fj_nynema" />
            <i
              class="fa fa-info-circle"
              title="Ef það er hámark á fjölda nýnema í innritun 10. bekkinga sem mega fara á brautina þarf að fylla út."
              v-b-tooltip
            ></i>
          </th>
          <!--th>
            <LinkOrder title="Fj. nema alls" :filteredList="list" :list="list" column="fj_nema_alls" />
          </th-->
          <th v-if="showAll">
            <LinkOrder title="Athugasemd á rafrænni umsókn" :filteredList="list" :list="list" column="aths_rafr_umsokn" />
          </th>
          <th v-if="showAll">
            <LinkOrder title="Má innrita" :filteredList="list" :list="list" column="innrit_lokad_allar" style="display: block" />
            <LinkOrder title="nema á" :filteredList="list" :list="list" column="innrit_lokad_allar" style="display: block" />
            <LinkOrder title="braut" :filteredList="list" :list="list" column="innrit_lokad_allar" />
            <i
              class="fa fa-info-circle"
              title="Opnar fyrir að stjórnendur geti skráð nemanda á brautina í stjórnendaviðmóti."
              v-b-tooltip
            ></i>
          </th>
          <th v-if="showAll">
            <LinkOrder title="Má sækja" :filteredList="list" :list="list" column="innrit_lokad_nyjar" style="display: block" />
            <LinkOrder title="um á" :filteredList="list" :list="list" column="innrit_lokad_nyjar" style="display: block" />
            <LinkOrder title="umsóknarvef" :filteredList="list" :list="list" column="innrit_lokad_nyjar" />
            <i class="fa fa-info-circle" title="Ef skólinn er með umsóknarvef Innu mega nemendur sækja um brautina." v-b-tooltip></i>
          </th>
          <th v-if="showAll || innrit_brautaskipti">
            <LinkOrder
              title="Má sækja"
              :filteredList="list"
              :lisgetExcelFileNamet="list"
              column="innrit_brautaskipti"
              style="display: block"
            />
            <LinkOrder title="um" :filteredList="list" :list="list" column="innrit_brautaskipti" style="display: block" />
            <LinkOrder title="brautaskipti" :filteredList="list" :list="list" column="innrit_brautaskipti" />
            <i class="fa fa-info-circle" title="Ef skólinn er með opið fyrir brautarskipti geta nemendur sótt um brautina." v-b-tooltip></i>
          </th>
          <th v-if="showAll || innrit_menntagatt">
            <LinkOrder title="Má sækja" :filteredList="list" :list="list" column="innrit_menntagatt" style="display: block" />
            <LinkOrder title="um í" :filteredList="list" :list="list" column="innrit_menntagatt" style="display: block" />
            <LinkOrder title="menntagátt" :filteredList="list" :list="list" column="innrit_menntagatt" />
            <i class="fa fa-info-circle" title="Nemendur mega sækja um brautina í Menntagátt." v-b-tooltip></i>
          </th>
          <th v-if="showAll || innrit_menntagatt">
            <LinkOrder title="Umsóknarfrestur " :filteredList="list" :list="list" column="dags_lokad" style="display: block" />
            <LinkOrder title=" runninn út" :filteredList="list" :list="list" column="dags_lokad"/>
            <i
              class="fa fa-info-circle"
              title="Þennan dag er braut ekki lengur sýnileg í Menntagátt.
              Ef lokað er fyrir umsóknir í Menntagátt á kennslutímabilinu þá er ekki verið að skoða þessa dagssetningu."
              v-b-tooltip
            ></i>
          </th>
        </tr>
      </thead>
      <tbody>
        <TableRowLoader v-if="loading && firsttime" colspan="10" :center="true" />
        <TableRowEmpty v-if="!loading && firsttime && this.list.length === 0" colspan="10" :center="true" />
        <TableRowSearchForResults v-if="!firsttime" colspan="10" :center="true" style="background-color: #fff"></TableRowSearchForResults>
        <CourseApplicationItem style="background-color: #fff"
          v-for="item in getPaginatedList"
          :key="item.braut_id"
          :editing="editing"
          :item="item"
          :innrit_menntagatt="innrit_menntagatt"
          :innrit_brautaskipti="innrit_brautaskipti"
          :ref="'courseApplicationItem' + item.braut_id"
        />
      </tbody>
    </table>

    <Pagination
      :currentPage="currentPage"
      :totalRows="list.length"
      :perPage="perPage"
      v-if="list.length > 20 && !editing"
      @change-page="changePage"
      @change-limit="changeLimit"
    ></Pagination>
  </div>
</template>

<script>
import moment from 'moment';
import curriculum from '@/api/curriculum';
import structure from '@/api/structure';
import { mapGetters, mapActions } from 'vuex';

import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import TableRowSearchForResults from '@/components/common/TableRowSearchForResults.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';
import CourseApplicationItem from '@/components/curriculum/courses/CourseApplicationItem.vue';
import Pagination from '@/components/common/Pagination.vue';

export default {
  name: 'course-application-list',
  components: {
    TableRowLoader,
    TableRowEmpty,
    TableRowSearchForResults,
    LinkOrder,
    CourseApplicationItem,
    Pagination,
  },
  props: ['slidebar', 'divisionId', 'innrit_menntagatt', 'innrit_brautaskipti'],
  computed: {
    getPaginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.list.slice(start, end);
    },
    getExcelData() {
      const newList = [[], ['Brautir - Umsóknir'], [], ['Heiti', 'Kóði'], ...this.list.map(row => [row.heiti, row.braut_kodi])];
      return newList;
    },
    getExcelFileName() {
      return `brautir_umsoknir_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    showAll() {
      // Hvort eigi að takmarka það sem er sýnt því þetta er slidebar til að breyta bara menntagátt eða brautaskiptum
      return !this.innrit_menntagatt && !this.innrit_brautaskipti;
    },
    getDivisions() {
      return this.divisions;
    },
    getDepartments() {
      return this.departments;
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_brautir');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('namskra_brautir');
    },
    canReadSchool() {
      return this.loggedInUserHasReadPermission('skolahald_uppbygging');
    },
    canReadTerms() {
      return this.loggedInUserHasReadPermission('skolahald_annir');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      perPage: 20,
      currentPage: 1,
      perPagePreEdit: 20,
      currentPagePreEdit: 1,
      editing: false,
      firsttime: false,
      detailedSearch: false,
      loading: false,
      saving: false,
      list: [],
      departments: [],
      divisions: [],
      enrollOptions: [
        { id: 1, label: 'Já' },
        { id: 0, label: 'Nei' },
      ],
      search: {
        search: '',
        department: '',
        division: '',
        enrollMenntagatt: '',
        enroll: '',
        enrollNew: '',
        enrollCourseChange: '',
      },
    };
  },
  methods: {
    async saveData() {
      try {
        this.saving = true;
        const list = [];
        Object.keys(this.$refs)
          .filter(el => el.startsWith('courseApplicationItem'))
          .filter(el => !!this.$refs[el][0])
          .filter(el => 'getData' in this.$refs[el][0])
          .forEach(el => {
            const item = this.$refs[el][0].getData();
            if ('braut_id' in item) {
              list.push(item);
            }
          });
        await curriculum.updateCourseApplication(list);
        this.editing = false;
        this.perPage = this.perPagePreEdit;
        this.currentPage = this.currentPagePreEdit;
        this.loadList();
      } catch (e) {
        // Aðgerð mistókst.
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.saving = false;
      }
    },
    async loadList() {
      try {
        this.loading = true;
        this.firsttime = true;
        this.editing = false;
        this.list = [];

        const search = {
          search: this.search.search,
          department: this.search.department ? this.search.department.department_id : '',
          division: this.search.division ? this.search.division.division_id : '',
          enroll: this.search.enroll ? this.search.enroll.id : '',
          enrollNew: this.search.enrollNew ? this.search.enrollNew.id : '',
          enrollMenntagatt: this.search.enrollMenntagatt ? this.search.enrollMenntagatt.id : '',
          enrollCourseChange: this.search.enrollCourseChange ? this.search.enrollCourseChange.id : '',
          active: 1,
          showDepartment: 0,
        };
        const response = await curriculum.getCourseList(search);
        this.list = response.data.items.map(x => ({
          ...x,
          deildir: x.deild ? x.deild.split(',') : [],
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadDepartments() {
      try {
        const response = await structure.getDepartmentList();
        this.departments = response.data.items.map(x => ({
          ...x,
          label: `${x.name}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadDivisions() {
      try {
        const response = await structure.getDivisionList();
        this.divisions = response.data.items.map(x => ({
          ...x,
          label: `${x.code} - ${x.name}`,
        }));
        if (this.divisionId) {
          this.search.division = this.divisions.find(x => x.division_id === this.divisionId);
          this.loadList();
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    // we want to be able to edit everything at once (ignoring pagination)
    editAll() {
      if (this.list.length > 20) {  // if multiple pages
        this.perPagePreEdit = this.perPage; 
        this.currentPagePreEdit = this.currentPage;
        this.changeLimit(100000); // "Sýna allt"
        setTimeout(() => {
          this.editing = true;
        }, 2);
      } else {
        this.editing = true;
      }
    },
    cancelEdit() {
      this.perPage = this.perPagePreEdit; 
      this.currentPage = this.currentPagePreEdit;
      this.editing = false;
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    if (this.innrit_menntagatt) {
      this.search.enrollMenntagatt = { id: 1, label: 'Já' };
    }
    if (this.innrit_brautaskipti) {
      this.search.enrollCourseChange = { id: 1, label: 'Já' };
    }
    this.loadDepartments();
    this.loadDivisions();
  },
};
</script>
