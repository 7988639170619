import Vue from 'vue';

const API_URL = '/api/attendance';

export default {
  getAttendanceDetails(recordId, absenceId, leaveOfAbsenceId) {
    const params = {
      absenceId,
      leaveOfAbsenceId,
    };
    return Vue.http
      .get(`${API_URL}/attendance-detail/${recordId}`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getAttendanceOverview(groupId, params) {
    return Vue.http
      .get(`${API_URL}/attendance-overview/${groupId}`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getAttendanceSummary(groupId, params) {
    return Vue.http
      .get(`${API_URL}/attendance-summary/${groupId}`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  registerAttendance(data) {
    return Vue.http
      .post(`${API_URL}/register-attendance`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getRegisterStudentAttendanceData(params) {
    return Vue.http
    .get(`${API_URL}/register-attendance`, { params })
    .then(response => Promise.resolve(response))
    .catch(error => Promise.reject(error));
  },
  getRegisterGroupAttendanceData(params) {
    return Vue.http
      .get(`${API_URL}/register-attendance-group/`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  saveGroupAttendanceData(data, type) {
    return Vue.http
      .post(`${API_URL}/register-attendance-group/${type}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  clearAbsence(id) {
    return Vue.http
      .post(`${API_URL}/clear-absence/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  // Ástundun og hegðun skráð á skólanum
  getPracticeAndBehaviorCodes(type) {
    return Vue.http
    .get(`${API_URL}/practice-and-behavior-codes/${type}`)
    .then(response => Promise.resolve(response))
    .catch(error => Promise.reject(error));
  },
  
};
