<template>
  <page>
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <b-row class="breadcumb-location">
      <b-col>
        <b-btn variant="primary" @click="openCreateSlidebar" class="float-right" style="height: 45px"> Stofna beiðni </b-btn>
      </b-col>
    </b-row>
    <PageTitle title="Afgreiðsla veikindatilkynninga" />
    <PageMenu route="ReviewAbsence" :selectedPage="page" :items="menu" />
    <AbsencesPending v-if="page === 'pending'" :absenceTypes="'1'"></AbsencesPending>
    <AbsencesAll v-if="page === 'all'" :absenceTypes="'1'" :selectedStudent="selectedStudent"></AbsencesAll>
    <create-absence-slidebar v-if="showCreateSlidebar" :absenceTypes="'1'" @closeSlidebar="closeSlidebar" @closeAndReload="closeSlidebar" />
  </page>
</template>

<script>
import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import PageTitle from '@/components/common/PageTitle.vue';
import PageMenu from '@/components/common/PageMenu.vue';

import AbsencesPending from '@/components/requests/reviewAbsence/AbsencesPending.vue';
import AbsencesAll from '@/components/requests/reviewAbsence/AbsencesAll.vue';
import CreateAbsenceSlidebar from '@/components/requests/reviewAbsence/CreateAbsenceSlidebar.vue';

export default {
  name: 'review-absence',
  components: {
    Breadcrumbs,
    PageTitle,
    AbsencesPending,
    AbsencesAll,
    PageMenu,
    CreateAbsenceSlidebar,
  },
  data() {
    return {
      menu: [
        {
          name: 'Óafgreiddar veikindatilkynningar',
          page: 'pending',
        },
        {
          name: 'Allar veikindatilkynningar',
          page: 'all',
        },
      ],
      page: 'pending',
      breadcrumbs: [
        { name: 'Forsíða', route: 'Dashboard' },
        { name: 'Beiðnir', route: '' },
        { name: 'Afgreiðsla veikindatilkynninga', route: 'ReviewAbsence' },
      ],
      showCreateSlidebar: false,
      selectedStudent: '',
    };
  },
  methods: {
    openCreateSlidebar() {
      this.showCreateSlidebar = true;
    },
    closeSlidebar(data) {
      this.showCreateSlidebar = false;
      if (data && data.ssn) {
        this.page = 'all';
        this.selectedStudent = data.ssn;
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (to && to.query && to.query.page) {
      this.page = to.query.page;
    }
    next();
  },
  created() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
  },
};
</script>
<style lang="scss"></style>
