<template>
  <Slidebar @closeMe="closeSlidebar" :large="large" :extraLarge="extraLarge">
    <h4>Eyða áætlun<loader v-if="loading"></loader></h4>
    <div class="mt-3"><i>Eyða út áföngum í stöðunni Áætlun.</i></div>
    <div class="mt-3">
      <i>Ef engin önn er valin þá er áföngum i stöðunni</i><i><b> Áætlun</b></i
      ><i> eytt af öllum önnum.</i>
    </div>
    <b-row class="pt-4">
      <b-col class="pl-0">
        <b-form-group :state="saving && errors.has('selectedTerm') ? false : ''" :invalid-feedback="errors.first('onn')">
          <label for="selectedTerm"> Önn </label>
          <v-select id="onn" name="onn" v-model="selectedTerm" :options="terms" :clearable="true" label="onn_eink"> </v-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="pt-2">
      <b-col class="pl-1">
        <b-form-checkbox id="deleteCourseChain" name="deleteCourseChain" v-model="deleteCourseChain" class="d-inline-block">
          Eyða áfangakeðju</b-form-checkbox
        >
      </b-col>
    </b-row>
    <b-row class="pt-2">
      <b-col class="pl-0">
        <b-btn variant="primary" size="sm" v-if="canEdit" @click="deleteSchedule()" :disabled="saving">
          <i class="fa fa-spin fa-spinner" v-if="saving"></i>
          Eyða áætlun
        </b-btn>
      </b-col>
    </b-row>
    <i class="fa fa-spin fa-spinner" v-if="loadingData"></i>
  </Slidebar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Slidebar from '@/components/common/Slidebar.vue';
import Loader from '@/components/common/Loader.vue';
import terms from '@/api/terms';
import students from '@/api/students';

export default {
  name: 'delete-schedule-slidebar',
  props: ['studentId', 'large', 'extraLarge'],
  components: {
    Slidebar,
    Loader,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('nemendahald_nemendur');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      item: '',
      selectedTerm: '',
      deleteCourseChain: false,
      term: [],
      terms: [],
      termSelection: [],
      loading: false,
      saving: false,
      loadingData: false,
    };
  },
  methods: {
    async loadTerms() {
      try {
        this.loading = true;
        this.firsttime = true;
        const params = {
          student_deletable_term: this.studentId,
          asc_order: 1, // röðun
        };
        this.selectedTerm = null;
        const response = await terms.getTerms(params);
        this.terms = response.data.items;
        this.terms = response.data.items
          .filter(x => x.onn_id !== parseInt(this.id, 10))
          .map(x => ({
            ...x,
            label: `${x.onn_eink} - ${x.onn_heiti} - ${x.undirsk_heiti}`,
          }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async deleteSchedule() {
      try {
        this.saving = true;
        if (this.selectedTerm === null) {
          this.termId = '';
        } else {
          this.termId = this.selectedTerm.onn_id;
        }
        const data = {
          deleteCourseChain: this.deleteCourseChain ? 1 : 0,
        };
        await students.deleteSchedule(this.studentId, this.termId, data);
        this.displaySuccess(`Áætlun hefur verið eytt.`);
        this.closeAndReloadSlidebar();
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.saving = false;
      }
    },
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    closeAndReloadSlidebar() {
      this.$emit('closeAndReloadSlidebar');
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadTerms();
  },
};
</script>
