<template>
  <div>
    <b-row class="mb-2" style="margin-top: -10px">
      <b-col class="pl-0" v-if="overview">
        <div class="d-inline-block mr-4">
          <label for="dateFrom" class="mb-0">Dags. frá</label>
          <datepicker
            id="dateFrom"
            name="dateFrom"
            v-model="dateFrom"
            :language="lang"
            :monday-first="true"
            format="dd.MM.yyyy"
            :typeable="false"
            :clear-button="false"
            ref="dateFromPickr"
            @input="loadList()"
            :disabled="loading"
          ></datepicker>
        </div>
        <div class="d-inline-block">
          <label for="dateTo" class="mb-0">Dags. til</label>
          <datepicker
            id="dateTo"
            name="dateTo"
            v-model="dateTo"
            :language="lang"
            :monday-first="true"
            format="dd.MM.yyyy"
            :typeable="false"
            :clear-button="false"
            ref="dateToPickr"
            @input="loadList()"
            :disabled="loading"
          ></datepicker>
        </div> </b-col
      ><b-col class="pl-0" v-if="!overview">
        <div class="d-inline-block mr-4">
          <label for="dateFromSummary" class="mb-0">Dags. frá</label>
          <datepicker
            id="dateFromSummary"
            name="dateFromSummary"
            v-model="dateFromSummary"
            :language="lang"
            :monday-first="true"
            format="dd.MM.yyyy"
            :typeable="false"
            :clear-button="true"
            ref="dateFromSummaryPickr"
            @input="loadList()"
            :disabled="loading"
          ></datepicker>
        </div>
        <div class="d-inline-block">
          <label for="dateToSummary" class="mb-0">Dags. til</label>
          <datepicker
            id="dateToSummary"
            name="dateToSummary"
            v-model="dateToSummary"
            :language="lang"
            :monday-first="true"
            format="dd.MM.yyyy"
            :typeable="false"
            :clear-button="true"
            ref="dateToSummaryPickr"
            @input="loadList()"
            :disabled="loading"
          ></datepicker>
        </div>
      </b-col>
      <b-col class="pl-0">
        <b-btn variant="primary" size="sm" @click="overview = !overview">
          <span v-if="overview">Samantekt</span>
          <span v-else>Kladdi</span>
        </b-btn>
      </b-col>
      <b-col>
        <div class="float-right" v-if="canEdit">
          <b-btn variant="primary" size="sm" class="mr-3" @click="openSlidebar(0)">Skrá fjarvist</b-btn>
          <b-btn variant="primary" size="sm" @click="openSlidebar(1)">Skrá forföll</b-btn>
        </div>
      </b-col>
    </b-row>
    <AttendanceOverview
      v-if="overview"
      :groupId="groupId"
      :dateFrom="dateFrom"
      :dateTo="dateTo"
      :reload="loadComponents"
      @loadingStart="loadingStart"
      @loadingEnd="loadingEnd"
    ></AttendanceOverview>
    <AttendanceSummary
      v-if="!overview"
      :groupId="groupId"
      :dateFrom="dateFromSummary"
      :dateTo="dateToSummary"
      @loadingStart="loadingStart"
      @loadingEnd="loadingEnd"
      :reload="loadComponents"
    ></AttendanceSummary>
    <register-attendance-slidebar
      v-if="showSlidebar"
      :groupId="groupId"
      :type="selectedType"
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
    >
    </register-attendance-slidebar>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';
import AttendanceOverview from '@/components/students/groups/attendance/AttendanceOverview.vue';
import AttendanceSummary from '@/components/students/groups/attendance/AttendanceSummary.vue';
import RegisterAttendanceSlidebar from '@/components/students/groups/attendance/RegisterAttendanceSlidebar.vue';

export default {
  name: 'group-attendance',
  props: ['groupId', 'canEdit'],
  components: {
    AttendanceOverview,
    AttendanceSummary,
    RegisterAttendanceSlidebar,
    Datepicker,
  },
  computed: {
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolHasSettings', 'schoolSettings']),
  },
  data() {
    return {
      overview: true,
      lang: is,
      loading: false,
      dateFrom: moment().startOf('week').toDate(),
      dateTo: moment().startOf('week').add(6, 'days').toDate(),
      dateFromSummary: '',
      dateToSummary: '',
      loadComponents: false,
      showSlidebar: false,
      selectedType: '',
    };
  },
  methods: {
    loadingStart() {
      this.loading = true;
    },
    loadingEnd() {
      this.loading = false;
    },
    openSlidebar(type) {
      this.showSlidebar = true;
      this.selectedType = type;
    },
    closeSlidebar() {
      this.showSlidebar = false;
    },
    closeAndReloadSlidebar() {
      this.closeSlidebar();

      this.loadComponents = true;
      setTimeout(() => {
        this.loadComponents = false;
      }, 1000);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
<style lang="scss"></style>
