import { render, staticRenderFns } from "./ChangeModulesSlidebar.vue?vue&type=template&id=774eccf6&scoped=true"
import script from "./ChangeModulesSlidebar.vue?vue&type=script&lang=js"
export * from "./ChangeModulesSlidebar.vue?vue&type=script&lang=js"
import style0 from "./ChangeModulesSlidebar.vue?vue&type=style&index=0&id=774eccf6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "774eccf6",
  null
  
)

export default component.exports