<template>
  <b-row>
    <b-col class="pl-0">
      <strong>{{ titleFrom ? titleFrom : 'Fjöldi' }}: {{ available.length }}</strong>
      <b-form-input v-model="search.available" placeholder="Leita eftir heiti" style="height: 30px"></b-form-input>
      <div v-if="searchDepartment === 1" style="width: 50%" class="d-inline-block mt-2 mb-2">
        <v-select
          id="department"
          name="department"
          v-model="search.availableDepartment"
          :options="getDepartments"
          :clearable="true"
          multiple
          placeholder="Leita eftir deild"
        >
        </v-select>
      </div>
      <div v-if="searchUnits === 1" style="width: 50%" class="d-inline-block mt-2 mb-2">
        <b-form-input v-model="search.availableUnits" placeholder="Leita eftir einingum" style="height: 32px"></b-form-input>
      </div>
      <b-form-select
        size="sm"
        v-model="availableSelected"
        :options="available"
        :multiple="true"
        :select-size="selectedSize"
        @dblclick.native="moveRight"
      >
      </b-form-select>
    </b-col>
    <b-col cols="1" class="text-center pt-5">
      <b-btn
        class="mb-1"
        variant="primary"
        style="width: 60px"
        :disabled="availableSelected.length === 0"
        @click="moveRight"
        :title="`Færa valda ${typeName ? typeName : ''} til hægri`"
      >
        <i class="fa fa-angle-right" aria-hidden="true"></i> </b-btn
      ><br />
      <b-btn
        class="mb-1"
        variant="primary"
        style="width: 60px"
        :disabled="available.length === 0"
        @click="moveAllRight"
        :title="`Færa alla ${typeName ? typeName : ''} til hægri`"
      >
        <i class="fa fa-angle-double-right" aria-hidden="true"></i> </b-btn
      ><br />
      <b-btn
        class="mb-1"
        variant="primary"
        style="width: 60px"
        :disabled="selectedSelected.length === 0"
        @click="moveLeft"
        :title="`Færa valda ${typeName ? typeName : ''} til vinstri`"
      >
        <i class="fa fa-angle-left" aria-hidden="true"></i> </b-btn
      ><br />
      <b-btn
        class="mb-1"
        variant="primary"
        style="width: 60px"
        :disabled="selected.length === 0"
        @click="moveAllLeft"
        :title="`Færa alla ${typeName ? typeName : ''} til vinstri`"
      >
        <i class="fa fa-angle-double-left" aria-hidden="true"></i>
      </b-btn>
    </b-col>
    <b-col>
      <strong>{{ titleTo ? titleTo : 'Fjöldi' }}: {{ selected.length }}</strong>
      <b-form-input v-model="search.selected" placeholder="Leita eftir heiti" style="height: 30px"></b-form-input>
      <div v-if="searchDepartment === 1" style="width: 50%" class="d-inline-block mt-2 mb-2">
        <v-select
          id="department"
          name="department"
          v-model="search.selectedDepartment"
          :options="getDepartments"
          :clearable="true"
          multiple
          placeholder="Leita eftir deild"
        >
        </v-select>
      </div>
      <div v-if="searchUnits === 1" style="width: 50%" class="d-inline-block mt-2 mb-2">
        <b-form-input v-model="search.selectedUnits" placeholder="Leita eftir einingum" style="height: 32px"></b-form-input>
      </div>
      <b-form-select
        size="sm"
        v-model="selectedSelected"
        :options="selected"
        :multiple="true"
        :select-size="selectedSize"
        @dblclick.native="moveLeft"
      ></b-form-select>
    </b-col>
  </b-row>
</template>

<script>
import structure from '@/api/structure';

export default {
  name: 'shuttle',
  props: {
    list: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
    titleFrom: {
      type: String,
      required: false,
    },
    titleTo: {
      type: String,
      required: false,
    },
    typeName: {
      type: String,
      required: false,
    },
    size: {
      type: Number,
      required: false,
    },
    searchDepartment: {
      type: Number,
      required: false,
    },
    searchUnits: {
      type: Number,
      required: false,
    },
    searchAvailable: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      availableSelected: [],
      selectedSelected: [],
      departments: [],
      search: {
        available: '',
        selected: '',
        availableDepartment: [],
        availableUnits: '',
        selectedDepartment: [],
        selectedUnits: '',
      },
      selectedSize: 30,
    };
  },
  computed: {
    available() {
      return (
        this.list
          .filter(g => !this.value.includes(g.id))
          .filter(g => !this.search.available || (g.name || '').toUpperCase().startsWith(this.search.available.toUpperCase()))
          // .filter(g => !this.search.availableDepartment || g.deild.indexOf(this.search.availableDepartment.name) > -1)
          .filter(
            g =>
              this.search.availableDepartment.length === 0 ||
              (g.deild || '')
                .toString()
                .split('/(".*?"|[^",\s]+)(?=\s*,|\s*$)/g')
                .some(r => this.search.availableDepartment.map(d => d.name).includes(r)),
          )
          .filter(g => !this.search.availableUnits || (g.einingar && parseInt(g.einingar, 10) === parseInt(this.search.availableUnits, 10)))
          .map(g => ({
            value: g.id,
            text: g.name,
          }))
      );
    },
    selected() {
      return (
        this.list
          .filter(g => this.value.includes(g.id))
          .filter(g => !this.search.selected || (g.name || '').toUpperCase().startsWith(this.search.selected.toUpperCase()))
          // .filter(g => !this.search.selectedDepartment || g.deild.indexOf(this.search.selectedDepartment.name) > -1)
          .filter(
            g =>
              this.search.selectedDepartment.length === 0 ||
              (g.deild || '')
                .toString()
                .split('/(".*?"|[^",\s]+)(?=\s*,|\s*$)/g')
                .some(r => this.search.selectedDepartment.map(d => d.name).includes(r)),
          )
          .filter(g => !this.search.selectedUnits || (g.einingar && parseInt(g.einingar, 10) === parseInt(this.search.selectedUnits, 10)))
          .map(g => ({
            value: g.id,
            text: g.name,
          }))
      );
    },
    getDepartments() {
      return this.departments.filter(x => !this.search.department || x.department_id !== this.search.department.department_id);
    },
  },
  methods: {
    moveRight() {
      this.value.push(...this.availableSelected); // eslint-disable-line
      this.availableSelected = [];
      this.selectedSelected = [];
    },
    moveLeft() {
      for (let i = this.value.length - 1; i >= 0; i -= 1) {
        // eslint-disable-line no-plusplus
        if (this.selectedSelected.includes(this.value[i])) {
          this.value.splice(i, 1); // eslint-disable-line
        }
      }
      this.availableSelected = [];
      this.selectedSelected = [];
    },
    moveAllRight() {
      this.value.push(...this.available.map(x => x.value)); // eslint-disable-line
      this.moving = false;
      this.availableSelected = [];
      this.selectedSelected = [];
    },
    moveAllLeft() {
      this.value.splice(0, this.value.length); // eslint-disable-line
      this.availableSelected = [];
      this.selectedSelected = [];
    },
    async loadDepartments() {
      try {
        const response = await structure.getDepartmentList();
        this.departments = response.data.items.map(x => ({
          ...x,
          label: `${x.name}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
  },
  created() {
    if (this.size) {
      this.selectedSize = this.size;
    }
    if (this.searchDepartment === 1) {
      this.loadDepartments();
    }
    if (this.searchAvailable) {
      this.search.available = this.searchAvailable;
    }
  },
};
</script>

<style></style>
