<template>
  <Slidebar @closeMe="closeSlidebar" :large="true">
    <h4>Einkunnir nemanda</h4>
    <StudentInfo v-if="item && item.nemandi_id" :id="item.nemandi_id" style="margin-bottom: -10px" />
    <div v-if="item && item.onn_eink"><strong>Önn:</strong> {{ item.onn_eink }}</div>
    <div v-if="item && item.namsgrein_afanganr"><strong>Áfangi:</strong> {{ item.namsgrein_afanganr }} {{ item.afanga_heiti }}</div>
    <div v-if="item && item.stada_afanga_heiti"><strong>Staða áfanga:</strong> {{ item.stada_afanga_heiti }}</div>

    <br />
    <StudyRecordGrades v-if="recordId" :recordId="recordId"></StudyRecordGrades>
  </Slidebar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import students from '@/api/students';

import Slidebar from '@/components/common/Slidebar.vue';
import StudyRecordGrades from '@/components/students/studyRecords/StudyRecordGrades.vue';
import StudentInfo from '@/components/common/StudentInfo.vue';

export default {
  name: 'study-record-grades-slidebar',
  props: ['recordId'],
  components: {
    StudyRecordGrades,
    Slidebar,
    StudentInfo,
  },
  computed: {
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      item: '',
      loading: false,
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async loadDetails() {
      try {
        this.loading = true;
        const response = await students.getStudyRecordDetails(this.recordId);
        this.item = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    recordId() {
      this.loadDetails();
    },
  },
  created() {
    this.loadDetails();
  },
};
</script>

<style lang="scss"></style>
