import Vue from 'vue';

const API_URL = '/api/school';

export default {
  getSchoolInfo() {
    return Vue.http
      .get(`${API_URL}/school-info`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getTermModulesGroups(termModuleId) {
    return Vue.http
      .get(`${API_URL}/module-term/${termModuleId}/groups`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getGroups(params) {
    return Vue.http
      .get(`${API_URL}/groups`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getGroupsByModules(params) {
    return Vue.http
      .get(`${API_URL}/groups-by-modules`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getGroupsByModulesStudents(id, params) {
    return Vue.http
      .get(`${API_URL}/groups-by-modules/${id}/students`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  addStudentsToGroup(id, data) {
    return Vue.http
      .post(`${API_URL}/groups-by-modules/${id}/students`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getGroupTeachers(id) {
    return Vue.http
      .get(`${API_URL}/group/${id}/teachers`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getGroupMastertable(id, params) {
    return Vue.http
      .get(`${API_URL}/group/${id}/mastertable`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getGroupTimetable(id, params) {
    return Vue.http
      .get(`${API_URL}/group/${id}/timetable`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  deleteStaffGroupPremise(group, staff) {
    return Vue.http
      .delete(`${API_URL}/group/${group}/teachers/${staff}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  addStaffGroupPremise(group, staff) {
    return Vue.http
      .post(`${API_URL}/group/${group}/teachers/${staff}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  deleteStaffMastertable(table, staff) {
    return Vue.http
      .delete(`${API_URL}/mastertable/${table}/teacher/${staff}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  addStaffMastertable(table, staff) {
    return Vue.http
      .post(`${API_URL}/mastertable/${table}/teacher/${staff}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  validateTimetable(data) {
    return Vue.http
      .post(`${API_URL}/timetable-validate`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  editTimetableRecord(id, data) {
    return Vue.http
      .post(`${API_URL}/timetable/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  deleteTimetableRecord(id) {
    return Vue.http
      .delete(`${API_URL}/timetable/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  editTimetableRecordTeachers(id, data) {
    return Vue.http
      .post(`${API_URL}/timetable/${id}/teachers`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  checkTimetableAbsenceExists(id) {
    return Vue.http
      .get(`${API_URL}/timetable/${id}/absence-exists`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  examPlacesList() {
    return Vue.http
      .get(`${API_URL}/exam-places/`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createExamPlace(data) {
    return Vue.http
      .post(`${API_URL}/exam-places/`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateExamPlace(id, data) {
    return Vue.http
      .post(`${API_URL}/exam-places/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  examPlaceManagers(id) {
    return Vue.http
      .get(`${API_URL}/exam-places/${id}/manager`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createExamPlaceManager(id, data) {
    return Vue.http
      .post(`${API_URL}/exam-places/${id}/manager`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateSchoolInfo(data) {
    return Vue.http
      .post(`${API_URL}/school-info`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getCovidSchoolCount(params) {
    return Vue.http
      .get(`${API_URL}/covid-school-count`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getMidtermNames() {
    return Vue.http
      .get(`${API_URL}/midterm-names`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getMidtermGradeScale() {
    return Vue.http
    .get(`${API_URL}/midterm-grade-scale`)
    .then(response => Promise.resolve(response))
    .catch(error => Promise.reject(error));
  }
};