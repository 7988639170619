<template>
  <div>
    <div class="alert alert-info p-1" style="font-size: 13px" v-if="edit">
      <i class="fa fa-info-circle"></i>
      Hlutfall einkunnaskala sem skólasóknin fer eftir á að hafa tvo aukastafi. Dæmi: 0 - 79,99, 80 - 81,99 o.s.frv. Ef hlutfallið hefur
      enga aukastafi geta sumir nemendur lent í því að fá enga mætingareinkunn, þ.e. þeir sem lenda á milli tveggja hlutfalla. Ef hlutfallið
      er 0 - 79, 80 - 81 þá fær nemandi með mætingarhlutfall 79,41 enga mætingareinkunn.
    </div>
    <table class="table less-padding" style="font-size: 12px">
      <thead>
        <tr>
          <th v-if="edit && canEdit">
            <b-form-checkbox v-model="allSelected" @input="updateSelected()" class="d-inline-block"></b-form-checkbox>
            <b-btn
              v-if="nrSelected > 0"
              @click="openMassEditSlidebar()"
              variant="primary"
              class="mr-1 btn-xs"
              style="font-size: 13px"
              v-b-tooltip
              title="Breyta"
            >
              <i class="fa fa-pencil" />
            </b-btn>
          </th>
          <th><LinkOrder title="Einkunn" :filteredList="list" :list="list" column="einkunn_sort" /></th>
          <th>
            <LinkOrder title="Hlutfall frá" :filteredList="list" :list="list" column="hlutfall_fra_sort" secondColumn="einkunn_sort" />
          </th>
          <th>
            <LinkOrder title="Hlutfall til" :filteredList="list" :list="list" column="hlutfall_til_sort" secondColumn="einkunn_sort" />
          </th>
          <th><LinkOrder title="Staðið" :filteredList="list" :list="list" column="stadid" secondColumn="einkunn_sort" /></th>
          <th><LinkOrder title="Lýsing" :filteredList="list" :list="list" column="lysing" secondColumn="einkunn_sort" /></th>
          <th>
            <LinkOrder title="0 einingar á lokaprófi" :filteredList="list" :list="list" column="ein_lokaprof" secondColumn="einkunn_sort" />
          </th>
          <th><LinkOrder title="Einingar" :filteredList="list" :list="list" column="einingar" secondColumn="einkunn_sort" /></th>
          <th><LinkOrder title="Fall á önn" :filteredList="list" :list="list" column="fall_a_onn" secondColumn="einkunn_sort" /></th>
          <th><LinkOrder title="Mætir í próf" :filteredList="list" :list="list" column="maetir_i_prof" secondColumn="einkunn_sort" /></th>
          <th v-if="edit && canEdit">
            <b-btn @click="openEditSlidebar()" variant="primary" class="float-right mr-1 btn-xs" v-b-tooltip title="Stofna einkunn">
              <i class="fa fa-plus-circle" />
            </b-btn>
          </th>
        </tr>
      </thead>
      <tr v-for="item in list" :key="item.einkunnaskali_id">
        <td v-if="edit && canEdit">
          <b-form-checkbox v-model="item.selected"></b-form-checkbox>
        </td>
        <td>
          <span v-if="item.einkunn_a">{{ item.einkunn_a }}</span
          ><span v-else>{{ item.einkunn }}</span>
        </td>
        <td>{{ item.hlutfall_fra }}</td>
        <td>{{ item.hlutfall_til }}</td>
        <td><yes-no :item="item.stadid"></yes-no></td>
        <td>{{ item.lysing }}</td>
        <td><yes-no :item="item.ein_lokaprof"></yes-no></td>
        <td>{{ item.einingar }}</td>
        <td><yes-no :item="item.fall_a_onn"></yes-no></td>
        <td><yes-no :item="item.maetir_i_prof"></yes-no></td>
        <td v-if="edit && canEdit">
          <div class="float-right">
            <b-btn @click="openEditSlidebar(item)" variant="primary" class="mr-2 btn-xs" style="font-size: 13px" v-b-tooltip title="Breyta">
              <i class="fa fa-pencil" />
            </b-btn>
          </div>
        </td>
      </tr>
    </table>
    <grade-scale-edit-slidebar
      v-if="showEditSlidebar"
      :item="selectedItem"
      :scaleId="id"
      :large="true"
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
    >
    </grade-scale-edit-slidebar>
    <grade-scale-mass-edit-slidebar
      v-if="showMassEditSlidebar"
      :items="selected"
      :id="id"
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
    ></grade-scale-mass-edit-slidebar>
  </div>
</template>

<script>
import types from '@/api/types';

import { mapActions, mapGetters } from 'vuex';
import YesNo from '@/components/common/YesNo.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';

import GradeScaleEditSlidebar from '@/components/school/school/types/GradeScaleEditSlidebar.vue';
import GradeScaleMassEditSlidebar from '@/components/school/school/types/GradeScaleMassEditSlidebar.vue';

export default {
  name: 'gradeScale',
  components: {
    YesNo,
    LinkOrder,
    GradeScaleEditSlidebar,
    GradeScaleMassEditSlidebar,
  },
  props: ['id', 'reload', 'edit'],
  computed: {
    nrSelected() {
      return this.selected.length;
    },
    selected() {
      return this.list.filter(x => x.selected);
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      loading: false,
      list: [],
      allSelected: false,
      showEditSlidebar: false,
      showMassEditSlidebar: false,
      selectedItem: null,
    };
  },
  methods: {
    async loadList() {
      try {
        this.loading = true;
        const response = await types.getGradeScale(this.id);
        this.list = response.data.items.map(x => ({
          ...x,
          einkunn_sort: x.einkunn_a || x.einkunn,
          hlutfall_fra_sort: !Number.isNaN((x.hlutfall_fra || '').replace('%')) ? parseFloat(x.hlutfall_fra) : x.hlutfall_fra,
          hlutfall_til_sort: !Number.isNaN((x.hlutfall_til || '').replace('%')) ? parseFloat(x.hlutfall_til) : x.hlutfall_til,
          selected: false,
        }));
        this.allSelected = false;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    updateSelected() {
      this.list.forEach(e => {
        this.$set(e, 'selected', this.allSelected);
      });
    },
    openEditSlidebar(item) {
      this.selectedItem = item;
      this.showEditSlidebar = true;
      this.showMassEditSlidebar = false;
    },
    openMassEditSlidebar() {
      this.showMassEditSlidebar = true;
      this.showEditSlidebar = false;
    },
    closeAndReloadSlidebar() {
      this.closeSlidebar();
      this.loadList();
    },
    closeSlidebar() {
      this.selectedItem = null;
      this.showEditSlidebar = false;
      this.showMassEditSlidebar = false;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    id() {
      this.loadList();
    },
    reload() {
      if (this.reload) this.loadList();
    },
  },
  created() {
    this.loadList();
  },
};
</script>
