<template>
  <div>
    <h6>
      Forsendur
      <i
        class="fa fa-info-circle"
        v-b-tooltip
        title="Kennarar skráðir á hópinn, ef notað er stundatöfluforrit þá skrást þessir kennarar sjálfkrafa sem kennarar hópsins í töflu."
      />
    </h6>
    <table class="table mb-0">
      <tr v-for="item in premises" :key="item.notandi_id">
        <td>{{ item.nafn }} ({{ item.skammst }})</td>
        <td>
          <b-btn variant="primary" size="sm" v-if="canEdit" @click="deletePremise(item)" class="float-right"
            ><i class="fa fa-trash"
          /></b-btn>
        </td>
      </tr>
    </table>
    <i v-if="premises.length === 0">Engir kennarar skráðir</i>
    <b-row v-if="canEdit">
      <b-col class="pl-0">
        <v-select id="staff" name="staff" v-model="selectedStaff" :options="staffList" @input="addPremise" ref="selectStaffDropdown">
        </v-select>
      </b-col>
      <!--b-col>
        <b-btn variant="primary" size="sm" style="height: 35px;" @click="addPremise()"><i class="fa fa-plus-circle" /> Bæta við</b-btn>
      </b-col-->
    </b-row>
    <br />
    <h6 v-if="timetable.length + mastertable.length > 0">
      Í stundatöflu
      <i class="fa fa-info-circle mr-3" v-b-tooltip title="Kennslustundir sem kennari er skráður á í stofntöflu" v-if="isMastertable" />
      <i class="fa fa-info-circle mr-3" v-b-tooltip title="Kennslustundir sem kennari er skráður á í stundatöflu" v-else />
      <span v-if="canEdit">
        <b-btn
          size="sm"
          variant="primary"
          v-if="isMastertable"
          title="Breyta kennara í stofntöflu"
          v-b-tooltip
          style="margin-top: -10px"
          @click="mastertableTeacherChangeModal()"
        >
          <i class="fa fa-pencil" />
        </b-btn>
        <b-link
          class="btn btn-primary btn-sm"
          title="Breyta stundatöflu"
          v-b-tooltip
          style="margin-top: -10px"
          :to="{ name: 'Timetable', query: { page: 'changeTimetable', term: item.onn_id, module: item.afangi_id, group: item.hopur_id } }"
          target="_blank"
          v-else
          ><i class="fa fa-pencil"
        /></b-link>
      </span>
    </h6>
    <table class="table">
      <tbody v-for="(item, index) in timetable" :key="index" style="border-width: 1px">
        <tr>
          <td>{{ item.nafn }} {{ item.skammst }}</td>
          <td>
            <b-btn variant="primary" size="sm" class="float-right" v-if="item.showTimetable" @click="item.showTimetable = false"
              ><i class="fa fa-remove"
            /></b-btn>
            <b-btn variant="primary" size="sm" class="float-right" v-else @click="loadTimetable(item)"><i class="fa fa-search" /></b-btn>
          </td>
        </tr>
        <tr v-if="item.showTimetable">
          <td colspan="2">
            <table class="table less-padding no-border" style="background-color: #f3f3f4">
              <tr>
                <th>Dagur</th>
                <th>Tími frá</th>
                <th>Tími til</th>
              </tr>
              <tr v-for="(t, index) in item.timetable" :key="index">
                <td>
                  <span v-if="item.isTimetable">{{ t.dags | moment('DD.MM.YYYY') }}</span>
                  <span v-else>{{ t.heiti_dags }}</span>
                </td>
                <td>{{ t.timi_fra }}</td>
                <td>{{ t.timi_til }}</td>
              </tr>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
    <change-mastertable-teacher-modal ref="mastertableTeacherChangeModal" @successful="loadTeachers"></change-mastertable-teacher-modal>
  </div>
</template>

<script>
import school from '@/api/school';
import staff from '@/api/staff';

import { mapGetters, mapActions } from 'vuex';

import ChangeMastertableTeacherModal from '@/components/students/groups/group/ChangeMastertableTeacherModal.vue';

export default {
  name: 'teacher-group',
  props: ['item', 'canEdit'],
  components: {
    ChangeMastertableTeacherModal,
  },
  computed: {
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      premises: [],
      timetable: [],
      mastertable: [],
      staffList: [],
      isMastertable: false,
      loading: {
        teachers: false,
        timetable: false,
        staff: false,
      },
      selectedStaff: '',
    };
  },
  methods: {
    async loadTeachers() {
      try {
        this.loading.teachers = true;
        this.premises = [];
        this.timetable = [];
        this.mastertable = [];
        const response = await school.getGroupTeachers(this.item.hopur_id);
        this.premises = response.data.hopar_kennarar;
        this.timetable = response.data.stundatafla
          .map(x => ({ ...x, isTimetable: true }))
          .concat(response.data.stofntafla.map(x => ({ ...x, isTimetable: false })));
        if (response.data.stofntafla.length > 0) {
          this.isMastertable = true;
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.teachers = false;
      }
    },
    async loadTimetable(teacher) {
      try {
        this.loading.timetable = true;
        let response = null;
        this.$set(teacher, 'showTimetable', true);
        if (teacher.isTimetable) {
          response = await school.getGroupTimetable(this.item.hopur_id, { staff: teacher.starfsm_id });
        } else {
          response = await school.getGroupMastertable(this.item.hopur_id, { staff: teacher.starfsm_id });
        }
        if (response) {
          this.$set(teacher, 'timetable', response.data.items);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.timetable = false;
      }
    },
    async loadStaff() {
      try {
        this.loading.staff = true;
        const response = await staff.getStaffList(this.search);
        this.staffList = response.data.items;
        this.staffList.forEach(x => {
          const staffName = x.starfsheiti ? ` - ${x.starfsheiti}` : '';
          this.$set(x, 'label', `${x.nafn} (${x.skammst})${staffName}`);
        });
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.staff = false;
      }
    },
    async addPremise() {
      try {
        if (this.selectedStaff) {
          await school.addStaffGroupPremise(this.item.hopur_id, this.selectedStaff.starfsm_id);
          this.selectedStaff = '';
          const input = this.$refs.selectStaffDropdown.$el.querySelector('input');
          if (input) {
            input.focus();
          }
          // this.$refs.selectStaffDropdown.$el.querySelector('input').focus();
          this.loadTeachers();
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async deletePremise(teacher) {
      // eslint-disable-next-line no-restricted-globals
      const conf = confirm(
        `Ertu viss um að þú viljir fjarlægja ${teacher.nafn} sem kennara af hópnum ${this.item.namsgrein_afanganr}(${this.item.hopur}}?`,
      );
      if (conf) {
        try {
          await school.deleteStaffGroupPremise(this.item.hopur_id, teacher.starfsm_id);
          this.loadTeachers();
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    /*
     * Modal gluggar
     */
    mastertableTeacherChangeModal() {
      this.$refs.mastertableTeacherChangeModal.show(this.item.hopur_id);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    item() {
      this.selectedStaff = '';
      this.isMastertable = false;
      this.loadTeachers();
    },
  },
  created() {
    this.selectedStaff = '';
    this.isMastertable = false;
    this.loadTeachers();
    this.loadStaff();
  },
};
</script>

<style lang="scss"></style>
