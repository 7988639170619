<template>
  <div>
    <b-btn variant="primary" size="sm" class="float-right mb-2" v-if="canEdit" @click="openSlidebar()"> Bæta við samhliða</b-btn>

    <table class="table">
      <thead>
        <tr>
          <th>Nr.</th>
          <th>Áfangi</th>
          <th>Heiti áfanga</th>
          <th></th>
        </tr>
      </thead>
      <TableRowLoader v-if="loading.list" colspan="4" :center="true" />
      <TableRowEmpty v-if="!loading.list && this.list.length === 0" colspan="4" :center="true" />
      <tr v-for="(item, idx) in list" :key="idx">
        <td>{{ idx + 1 }}</td>
        <td>
          <b-link v-if="canEdit" @click="openEditSlidebar()">
            {{ item.namsgrein_afanganr }}
          </b-link>
          <span v-else>
            {{ item.namsgrein_afanganr }}
          </span>
        </td>
        <td>{{ item.afanga_heiti }}</td>
        <td>
          <b-btn variant="danger" class="btn-xs float-right" v-if="canEdit" @click="deleteParallel(item)"
            ><i class="fa fa-trash" v-b-tooltip.hover title="Eyða samhliða áfanga"></i
          ></b-btn>
        </td>
      </tr>
    </table>
    <module-parallel-slidebar
      v-if="showSlidebar"
      :id="id"
      :divisionId="divisionId"
      @closeSlidebar="closeSlidebar()"
    ></module-parallel-slidebar>
  </div>
</template>

<script>
import curriculum from '@/api/curriculum';
import { mapGetters, mapActions } from 'vuex';

import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import ModuleParallelSlidebar from '@/components/curriculum/modules/ModuleParallelSlidebar.vue';

export default {
  name: 'module-parallel',
  components: {
    TableRowLoader,
    TableRowEmpty,
    ModuleParallelSlidebar,
  },
  props: ['id', 'divisionId'],
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_afangar');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('namskra_afangar');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      loading: {
        list: false,
      },
      list: [],
      showSlidebar: false,
    };
  },
  methods: {
    closeSlidebar() {
      this.showSlidebar = false;
      this.loadList();
    },
    openSlidebar() {
      this.showSlidebar = true;
    },
    async deleteParallel(item) {
      const conf = confirm(`Ert þú viss um að eyða samhliða áfanganum ${item.namsgrein_afanganr}?`); // eslint-disable-line
      if (conf) {
        try {
          await curriculum.setModuleParallel(this.id, { afangi_id: item.afangi_id, delete: 1 });
          this.displaySuccess('Samhliða áfanga eytt.');

          this.loadList();
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    async loadList() {
      this.loading.list = true;
      try {
        const response = await curriculum.getModuleParallelList(this.id);
        this.list = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.list = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadList();
  },
};
</script>
<style lang="scss"></style>
