<template>
  <div>
    <div v-if="!loading && list.length === 0">Engar færslur fundust.</div>
    <Loader v-if="loading"></Loader>
    <table class="table less-padding" v-if="list.length > 0" style="font-size: 13px">
      <tr>
        <th v-if="isVacation">
          <LinkOrder title="Dags.frá" :filteredList="list" :list="list" column="dags_fra" />
        </th>
        <th v-if="isVacation">
          <LinkOrder title="Dags.til" :filteredList="list" :list="list" column="dags_til" />
        </th>
        <th v-if="!isVacation">
          <LinkOrder title="Dags." :filteredList="list" :list="list" column="dags_fra" />
        </th>
        <th>
          <LinkOrder title="Dags.skráð" :filteredList="list" :list="list" column="time_create" />
        </th>
        <th v-if="isVacation">
          <LinkOrder title="Tegund leyfis" :filteredList="list" :list="list" column="tegund_heiti" />
        </th>
        <th>
          <LinkOrder title="Staða" :filteredList="list" :list="list" column="stada_heiti" />
        </th>
        <th v-if="isVacation">
          <LinkOrder title="Fjöldi daga" :filteredList="list" :list="list" column="fjoldi_daga" />
        </th>
        <th>
          <LinkOrder title="Athugasemd" :filteredList="list" :list="list" column="athugasemd_skraning" />
        </th>
        <th>
          <LinkOrder title="Skráð af" :filteredList="list" :list="list" column="person_create_nafn" />
        </th>
        <th>
          <LinkOrder title="Athugasemd skrifstofu" :filteredList="list" :list="list" column="athugasemd_afgreidsla" />
        </th>
        <th>
          <LinkOrder title="Samþ. af" :filteredList="list" :list="list" column="samt_person_nafn" />
        </th>
      </tr>
      <tr v-for="(item, index) in list" :key="index">
        <td v-if="isVacation">{{ item.dags_fra | moment('DD.MM.YYYY') }}</td>
        <td v-if="isVacation">{{ item.dags_til | moment('DD.MM.YYYY') }}</td>
        <td v-if="!isVacation">{{ item.dags_fra | moment('DD.MM.YYYY') }}</td>
        <td>{{ item.time_create | moment('DD.MM.YYYY') }}</td>
        <td v-if="isVacation">{{ item.tegund_heiti }}</td>
        <td>{{ item.stada_heiti }}</td>
        <td v-if="isVacation">{{ item.fjoldi_daga }}</td>
        <td style="font-size: 12px" :title="item.athugasemd_skraning" v-b-tooltip>
          {{
            item.athugasemd_skraning && item.athugasemd_skraning.length > 40
              ? `${item.athugasemd_skraning.substring(0, 40)}..`
              : item.athugasemd_skraning
          }}
        </td>
        <td style="font-size: 12px" :title="item.person_create_nafn" v-b-tooltip>
          {{
            item.person_create_nafn && item.person_create_nafn.length > 10
              ? `${item.person_create_nafn.substring(0, 10)}..`
              : item.person_create_nafn
          }}
        </td>
        <td style="font-size: 12px" :title="item.athugasemd_afgreidsla" v-b-tooltip>
          {{
            item.athugasemd_afgreidsla && item.athugasemd_afgreidsla.length > 40
              ? `${item.athugasemd_afgreidsla.substring(0, 40)}..`
              : item.athugasemd_afgreidsla
          }}
        </td>
        <td style="font-size: 12px" :title="item.samt_person_nafn" v-b-tooltip>
          {{
            item.samt_person_nafn && item.samt_person_nafn.length > 10
              ? `${item.samt_person_nafn.substring(0, 10)}..`
              : item.samt_person_nafn
          }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import requests from '@/api/requests';

import LinkOrder from '@/components/common/LinkOrder.vue';
import Loader from '@/components/common/Loader.vue';

export default {
  name: 'student-absence-requests',
  props: ['id', 'absenceTypes'],
  components: {
    LinkOrder,
    Loader,
  },
  computed: {
    isVacation() {
      return this.absenceTypes.indexOf('2') > -1 || this.absenceTypes.indexOf('3') > -1;
    },
    isAbsence() {
      return this.absenceTypes.indexOf('1') > -1;
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      list: [],
      loading: false,
    };
  },
  methods: {
    async loadRequests() {
      try {
        this.list = [];
        this.loading = true;
        const response = await requests.getReviewAbsenceList({ student: this.id, absenceTypes: this.absenceTypes, newestFirst: 1 });
        this.list = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    id() {
      this.loadRequests();
    },
  },
  created() {
    this.loadRequests();
  },
};
</script>

<style lang="scss"></style>
