<template>
  <div>
    <table class="table less-padding table-hover" style="font-size: 13px">
      <thead>
        <tr>
          <th><LinkOrder title="Nafn" :filteredList="list" :list="list" column="nafn" /> ({{ list.length }})</th>
          <th>
            <LinkOrder title="Áfangi" :filteredList="list" :list="list" column="namsgrein_afanganr" secondColumn="nafn" />
          </th>
          <th>Í hóp</th>
          <th>
            <download-csv v-if="!loading" :data="getExcelData" :name="getExcelFileName" delimiter=";" class="cursor-pointer float-right">
              <i class="fa fa-file-excel-o excelText" />
            </download-csv>
          </th>
        </tr>
      </thead>
      <TableRowLoader v-if="loading" colspan="3" :center="true" />
      <TableRowEmpty v-if="!loading && this.list.length === 0" colspan="3" :center="true" />
      <tbody>
        <tr v-for="(item, idx) in getPaginatedList" :key="idx">
          <td>{{ item.nafn }}</td>
          <td>{{ item.namsgrein_afanganr }}</td>
          <td>
            <yes-no :item="item.hopar !== null"></yes-no>
            <span v-if="item.hopar && item.hopar.indexOf(',') > -1"> ({{ item.hopar.split(',').length }})</span>
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>

    <Pagination
      :currentPage="currentPage"
      :totalRows="list.length"
      :perPage="perPage"
      v-if="list.length > 25"
      @change-page="changePage"
      @change-limit="changeLimit"
    />
  </div>
</template>

<script>
import moment from 'moment';
import terms from '@/api/terms';
import { mapActions, mapGetters } from 'vuex';

import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import Pagination from '@/components/common/Pagination.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';
import YesNo from '@/components/common/YesNo.vue';

export default {
  name: 'term-student-unfinished-modules',
  props: ['id', 'item'],
  components: {
    TableRowLoader,
    TableRowEmpty,
    Pagination,
    LinkOrder,
    YesNo,
  },
  computed: {
    getExcelData() {
      const newList = [
        [],
        ['Óloknir áfangar'],
        [`${this.item.undirsk_heiti}-${this.item.onn_heiti}`],
        [],
        ['Nafn', 'Áfangi'],
        ...this.list.map(x => [x.nafn, x.namsgrein_afanganr]),
      ];
      return newList;
    },
    getExcelFileName() {
      return `nemendur_oloknir_afangar_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    canRead() {
      return this.loggedInUserHasReadPermission('skolahald_annir');
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_annir');
    },
    getPaginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.list.slice(start, end);
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolHasSettings']),
  },
  data() {
    return {
      perPage: 25,
      currentPage: 1,
      loading: false,
      list: [],
    };
  },
  methods: {
    async loadUnfinishedModules() {
      try {
        this.loading = true;
        const response = await terms.getStudentUnfinishedModules(this.id);
        this.list = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadUnfinishedModules();
  },
};
</script>
