<template>
  <div>
    <b-card v-if="advancedSearch">
      <b-row>
        <b-col>
          <b-form-group label="Nafn" label-for="name">
            <b-form-input id="name" name="name" v-model="selected.name" ref="focusInput" v-on:keyup.enter="loadList()" />
          </b-form-group>
        </b-col>
        <b-col v-if="!afangaskoli">
          <b-form-group label="Bekkur" label-for="studentClass">
            <b-form-input id="studentClass" name="studentClass" v-model="selected.studentClass" v-on:keyup.enter="loadList()" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Kennitala" label-for="ssn">
            <b-form-input id="ssn" name="ssn" v-model="selected.ssn" v-on:keyup.enter="loadList()" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Nemendanr." label-for="studentNr">
            <b-form-input id="studentNr" name="studentNr" v-model="selected.studentNr" v-on:keyup.enter="loadList()" />
          </b-form-group>
        </b-col>
      </b-row>
      <div style="padding-left: 15px" @click="detailedSearch = !detailedSearch" class="text-primary cursor-pointer mb-2">
        <span v-if="detailedSearch">Fela ítarleit </span><span v-else>Sýna ítarleit </span>
        <i class="fa text-primary" :class="{ 'fa-angle-up': detailedSearch, 'fa-angle-down': !detailedSearch }"></i>
      </div>
      <div v-if="detailedSearch">
        <b-row>
          <b-col>
            <b-form-group>
              <label for="statuses">Staða nema <Loader v-if="loading.statuses"></Loader></label>
              <v-select id="statuses" name="statuses" v-model="selected.status" :options="statuses" label="heiti"> </v-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <label for="processingStatuses">Vinnslustaða <Loader v-if="loading.processingStatuses"></Loader></label>
              <v-select
                id="processingStatuses"
                name="processingStatuses"
                v-model="selected.processingStatus"
                :options="processingStatuses"
                label="heiti"
              >
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group>
              <label for="courses">Braut <Loader v-if="loading.courses"></Loader></label>
              <v-select id="courses" name="courses" v-model="selected.course" :options="getCourses" style="font-size: 12px"> </v-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <label for="divisions">Undirskóli <Loader v-if="loading.divisions"></Loader></label>
              <v-select id="divisions" name="divisions" v-model="selected.division" :options="getDivisions" label="name"> </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Virkur" label-for="active">
              <v-select id="active" name="active" v-model="selected.active" :options="active" :clearable="true"> </v-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <label for="departments">Deild <Loader v-if="loading.departments"></Loader></label>
              <v-select id="departments" name="departments" v-model="selected.department" :options="getDepartments" label="name">
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group>
              <label for="userGroups">Notendahópur <Loader v-if="loading.userGroups"></Loader></label>
              <v-select id="userGroups" name="userGroups" v-model="selected.userGroup" :options="userGroups" label="heiti"> </v-select>
            </b-form-group>
          </b-col>
          <b-col v-if="!afangaskoli">
            <b-form-group label="Ár nemanda" label-for="year">
              <b-form-input id="year" name="year" v-model="selected.year" style="height: 32px"> </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Póstnúmer" label-for="postalCodes">
              <v-select
                id="postalCodes"
                name="postalCodes"
                v-model="selected.postalCode"
                :options="postalCodes"
                :clearable="true"
                label="heiti"
              >
              </v-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Sveitafélag" label-for="municipality">
              <b-form-input id="municipality" name="municipality" v-model="selected.municipality" style="height: 32px"> </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Sérstök mætingareinkunn" label-for="specialAttendance">
              <v-select
                id="specialAttendance"
                name="specialAttendance"
                v-model="selected.specialAttendance"
                :options="yesNo"
                :clearable="true"
              >
              </v-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-btn
              variant="dark"
              style="height: 35px; font-size: 12px; margin-top: 21px"
              class="float-right"
              cols="1"
              @click="clearSearch()"
            >
              <i class="fa fa-eraser" aria-hidden="true"></i>
              Hreinsa</b-btn
            >
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col cols="3">
          <b-btn variant="primary" class="w-100" @click="loadList()"> Leita </b-btn>
        </b-col>
      </b-row>
    </b-card>
    <br />
    <table class="table">
      <thead>
        <tr class="table-search" v-if="!advancedSearch">
          <td :colspan="slidebar ? 2 : 2">
            <b-form-input
              v-model="search.search"
              class="search"
              v-on:keyup.enter="loadList"
              placeholder="Leita eftir nafni eða kennitölu"
              ref="focusInput"
            />
          </td>
          <td>
            <b-btn variant="primary" class="w-100" @click="loadList">Leita</b-btn>
          </td>
        </tr>
        <tr v-if="firsttime">
          <th><LinkOrder title="Nafn" :filteredList="list" :list="list" column="nafn" /> ({{ list.length }})</th>
          <th>
            <LinkOrder title="Kennitala" :filteredList="list" :list="list" column="nafn" />
          </th>
          <th>
            <LinkOrder title="Nemandanr" :filteredList="list" :list="list" column="nemandi_id" />
          </th>
          <th>
            <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="cursor-pointer float-right">
              <i class="fa fa-file-excel-o excelText"></i>
            </download-csv>
          </th>
        </tr>
      </thead>
      <tbody>
        <TableRowLoader v-if="loading.students && firsttime" :colspan="slidebar ? 4 : 4" :center="true" />
        <TableRowEmpty v-if="!loading.students && firsttime && this.list.length === 0" :colspan="slidebar ? 4 : 4" :center="true" />
        <TableRowSearchForResults v-if="!firsttime" :colspan="slidebar ? 4 : 4" :center="true" />
        <tr v-for="item in getPaginatedList" :key="item.id">
          <td>
            <b-link v-if="slidebar" class="cursor-pointer" @click="changeStudent(item)" title="Velja nemanda" v-b-tooltip>
              {{ item.nafn }} {{ item.bekkur }}
            </b-link>
            <div v-else>{{ item.nafn }} {{ item.bekkur }}</div>
          </td>
          <td>{{ item.kennitala }}</td>
          <td>{{ item.nemandi_id }}</td>
          <td></td>
        </tr>
      </tbody>
    </table>

    <Pagination
      :currentPage="currentPage"
      :totalRows="list.length"
      :perPage="perPage"
      v-if="list.length > 25"
      @change-page="changePage"
      @change-limit="changeLimit"
    />

    <timetable-slidebar v-if="showTimetableSlidebar" :item="selectedItem" type="student" @closeSlidebar="closeSlidebar">
    </timetable-slidebar>
  </div>
</template>

<script>
import moment from 'moment';
import students from '@/api/students';
import types from '@/api/types';
import users from '@/api/users';
import structure from '@/api/structure';
import curriculum from '@/api/curriculum';
import common from '@/api/common';
import { mapGetters, mapActions } from 'vuex';

import Loader from '@/components/common/Loader.vue';
import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import TableRowSearchForResults from '@/components/common/TableRowSearchForResults.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';
import TimetableSlidebar from '@/components/common/TimetableSlidebar.vue';
import Pagination from '@/components/common/Pagination.vue';

export default {
  name: 'student-search',
  components: {
    Loader,
    TableRowLoader,
    TableRowEmpty,
    TableRowSearchForResults,
    LinkOrder,
    TimetableSlidebar,
    Pagination,
  },
  props: ['slidebar', 'advancedSearch'],
  computed: {
    getExcelData() {
      const newList = [[], ['Nemendur'], [], ['Nafn', 'Kennitala'], ...this.list.map(x => [x.nafn, `="${x.kennitala}"`])];
      return newList;
    },
    getExcelFileName() {
      return `nemendur_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    rows() {
      return this.list.length;
    },
    getPaginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.list.slice(start, end);
    },
    afangaskoli() {
      return this.schoolSettings && this.schoolSettings.afangaskoli ? this.schoolSettings.afangaskoli === 1 : false;
    },
    getDivisions() {
      return this.divisions.filter(x => !this.selected.courses || x.division_id === this.selected.courses.undirskoli_id);
    },
    getCourses() {
      return this.courses
        .filter(x => !this.selected.divisions || x.undirskoli_id === this.selected.divisions.division_id)
        .filter(x => !this.selected.departments || x.deildir.indexOf(this.selected.departments.name) > -1);
    },
    getDepartments() {
      return this.departments.filter(x => !this.selected.courses || this.selected.courses.deildir.indexOf(x.name) > -1);
    },
    heimavist() {
      return this.schoolSettings && this.schoolSettings.vist ? this.schoolSettings.vist === 1 : false;
    },
    /* canRead() {
      return this.loggedInUserHasReadPermission('starfsmannahald_starfsmenn');
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('starfsmannahald_starfsmenn');
    }, */
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {
      perPage: 25,
      currentPage: 1,
      firsttime: false,
      detailedSearch: false,
      loading: {
        students: false,
        statuses: false,
        processingStatuses: false,
        departments: false,
        courses: false,
        divisions: false,
        userGroups: false,
        postalCodes: false,
      },
      list: [],
      search: {
        search: '',
        active: 1,
      },
      selected: {
        name: '',
        ssn: '',
        studentNr: '',
        studentClass: '',
        status: '',
        processingStatus: '',
        active: { id: 1, label: 'Virkur' },
        department: '',
        course: '',
        division: '',
        userGroup: '',
        specialAttendance: '',
        year: '',
        postalCode: '',
        municipality: '',
      },
      statuses: [],
      processingStatuses: [],
      departments: [],
      courses: [],
      divisions: [],
      userGroups: [],
      postalCodes: [],
      active: [
        { id: 1, label: 'Virkur' },
        { id: 0, label: 'Óvirkur' },
      ],
      yesNo: [
        { id: 1, label: 'Já' },
        { id: 0, label: 'Nei' },
      ],
      showTimetableSlidebar: false,
      selectedItem: {},
    };
  },
  methods: {
    updateQuery() {
      const queries = JSON.parse(JSON.stringify(this.$route.query));
      queries.search = this.selected.name ? this.selected.name : '';
      queries.ssn = this.selected.ssn ? this.selected.ssn : '';
      queries.nr = this.selected.studentNr ? this.selected.studentNr : '';
      queries.a = this.selected.status ? this.selected.status.eink_stodu.toString() : '';
      queries.b = this.selected.processingStatus ? this.selected.processingStatus.teg_vinnslust_nema_id.toString() : '';
      queries.c = this.selected.active ? this.selected.active.id.toString() : '';
      queries.d = this.selected.dorm ? this.selected.dorm.id.toString() : '';
      queries.e = this.selected.course ? this.selected.course.braut_id.toString() : '';
      queries.f = this.selected.division ? this.selected.division.division_id.toString() : '';
      queries.g = this.selected.department ? this.selected.department.department_id.toString() : '';
      queries.h = this.selected.userGroup ? this.selected.userGroup.hopur_id : '';
      queries.i = this.selected.specialAttendance ? this.selected.specialAttendance.id : '';
      queries.j = this.selected.year ? this.selected.year : '';
      queries.k = this.selected.postalCode ? this.selected.postalCode.postnumer : '';
      queries.l = this.selected.municipality ? this.selected.municipality : '';
      if (JSON.stringify(this.$route.query) !== JSON.stringify(queries)) {
        this.$router.replace({ query: queries });
      }
    },
    async loadStatuses(id) {
      try {
        this.loading.statuses = true;
        const response = await students.getStudentsStatuses();
        this.statuses = response.data.items;
        if (id) {
          this.selected.status = this.statuses.find(x => x.eink_stodu === parseInt(id, 10));
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.statuses = false;
      }
    },
    async loadProcessingStatuses(id) {
      try {
        this.loading.processingStatuses = true;
        const response = await types.getProcessingStatusList();
        this.processingStatuses = response.data.items;
        if (id) {
          this.selected.processingStatus = this.processingStatuses.find(x => x.teg_vinnslust_nema_id === parseInt(id, 10));
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.processingStatuses = false;
      }
    },
    async loadDepartments(id) {
      try {
        this.loading.departments = true;
        const response = await structure.getDepartmentList();
        this.departments = response.data.items;
        if (id) {
          this.selected.department = this.departments.find(x => x.department_id === parseInt(id, 10));
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.departments = false;
      }
    },
    async loadCourses(id) {
      try {
        this.loading.courses = true;
        const response = await curriculum.getCourseList({ showDepartment: 1, active: 1 });
        this.courses = response.data.items.map(x => ({
          ...x,
          label: `${x.heiti} ${x.braut_kodi}`,
          deildir: (x.deild || '').split(','),
        }));
        if (id) {
          this.selected.course = this.courses.find(x => x.braut_id === parseInt(id, 10));
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.courses = false;
      }
    },
    async loadDivisions(id) {
      try {
        this.loading.divisions = true;
        const response = await structure.getDivisionList();
        this.divisions = response.data.items.map(x => ({
          ...x,
          label: `${x.code} - ${x.name}`,
        }));
        if (id) {
          this.selected.division = this.divisions.find(x => x.division_id === parseInt(id, 10));
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.divisions = false;
      }
    },
    async loadUserGroups(id) {
      try {
        this.loading.userGroups = true;
        const response = await users.getUserGroupsList({ active: 1 });
        this.userGroups = response.data.items;
        if (id) {
          this.selected.userGroup = this.userGroups.find(x => x.hopur_id === parseInt(id, 10));
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.userGroups = false;
      }
    },
    async loadPostalCodes(id) {
      try {
        this.loading.postalCodes = true;
        const response = await common.getPostalCodes();
        this.postalCodes = response.data.items;
        if (id) {
          this.selected.postalCode = this.postalCodes.find(x => x.postnumer === id);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.postalCodes = false;
      }
    },
    async loadList() {
      try {
        this.firsttime = true;
        this.loading.students = true;
        let search = {};
        if (this.advancedSearch) {
          this.updateQuery();
          search = {
            showCourse: 1,
            name: this.selected.name,
            ssn: this.selected.ssn,
            studentNr: this.selected.studentNr,
            studentClass: this.selected.studentClass,
            status: this.selected.status ? this.selected.status.eink_stodu : '',
            processingStatus: this.selected.processingStatus ? this.selected.processingStatus.teg_vinnslust_nema_id : '',
            active: this.selected.active ? this.selected.active.id : '',
            department: this.selected.department ? this.selected.department.department_id : '',
            course: this.selected.course ? this.selected.course.braut_id : '',
            division: this.selected.division ? this.selected.division.division_id : '',
            userGroup: this.selected.userGroup ? this.selected.userGroup.hopur_id : '',
            specialAttendance: this.selected.specialAttendance ? this.selected.specialAttendance.id : '',
            year: this.selected.year,
            postalCode: this.selected.postalCode ? this.selected.postalCode.postnumer : '',
            municipality: this.selected.municipality,
          };
          // ef search bar er útfylltur (ítarleit) þá á að birta ítarleitina
          if (
            this.selected.status ||
            this.selected.processingStatus ||
            this.selected.department ||
            this.selected.course ||
            this.selected.division ||
            this.selected.userGroup ||
            this.selected.specialAttendance ||
            this.selected.year ||
            this.selected.postalCode ||
            this.selected.municipality ||
            this.selected.dorm
          ) {
            this.detailedSearch = true;
          }
        } else {
          search = this.search;
        }
        const response = await students.getStudentList(search);
        this.list = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.students = false;
      }
    },
    openTimetableSlidebar(item) {
      this.selectedItem = item;
      this.showTimetableSlidebar = true;
    },
    closeSlidebar() {
      // this.showSlidebar = false;
      this.showTimetableSlidebar = false;
      this.selectedItem = {};
    },
    checkSlidebar(item) {
      /* if (this.showSlidebar) {
        this.openSlidebar(item);
      } else */
      if (this.showTimetableSlidebar) {
        this.openTimetableSlidebar(item);
      }
    },
    changeStudent(item) {
      this.$emit('changeStudent', item.nemandi_id, this.advancedSearch);
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    async prepareData() {
      const queries = this.$route.query;
      this.selected.name = queries.search || '';
      this.$nextTick(() => {
        if (this.$refs.focusInput) {
          this.$refs.focusInput.focus();
        }
      });
      this.selected.ssn = queries.ssn || '';
      this.selected.studentNr = queries.nr || '';
      if (this.advancedSearch) {
        this.selected.status = queries.status || '';
        this.selected.dorm = queries.d ? this.yesNo.find(x => x.id === parseInt(queries.d, 10)) : '';
        this.selected.specialAttendance = queries.i ? this.yesNo.find(x => x.id === parseInt(queries.i, 10)) : '';
        this.selected.year = queries.j || '';
        this.selected.municipality = queries.l || '';
        await this.loadStatuses(queries.a);
        await this.loadProcessingStatuses(queries.b);
        await this.loadDepartments(queries.g);
        await this.loadCourses(queries.e);
        await this.loadDivisions(queries.f);
        await this.loadUserGroups(queries.h);
        await this.loadPostalCodes(queries.k);
      }
      this.$nextTick(() => {
        if (Object.keys(queries).length > 0) {
          this.loadList();
        }
      });
    },
    clearSearch() {
      this.selected = {
        name: '',
        ssn: '',
        studentNr: '',
        studentClass: '',
        status: '',
        processingStatus: '',
        active: { id: 1, label: 'Virkur' },
        dorm: '',
        department: '',
        course: '',
        division: '',
        userGroup: '',
        specialAttendance: '',
        year: '',
        postalCode: '',
        municipality: '',
      };
      this.loadData();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.prepareData();
  },
};
</script>
