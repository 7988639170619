<template>
  <Slidebar :large="true" @closeMe="closeSlidebar">
    <h4>
      Hópalisti {{ item.namsgrein_afanganr }} <span v-if="item && item.hopur">({{ item.hopur }})</span>
    </h4>
    <table class="table no-border">
      <tr>
        <td>Kynjahlutföll</td>
        <td>KK: {{ males }}</td>
        <td>KVK: {{ females }}</td>
        <td>X: {{ neutral }}</td>
      </tr>
      <tr>
        <th>Nafn</th>
        <th>Kennitala</th>
        <th>Kyn</th>
      </tr>
      <tr v-for="item in list" :key="item.kennitala">
        <td>{{ item.nafn }}</td>
        <td>{{ item.kennitala }}</td>
        <td>
          <span v-if="item.kyn === 1">KK</span>
          <span v-if="item.kyn === 2">KVK</span>
          <span v-if="item.kyn === 7">X</span>
        </td>
      </tr>
    </table>
  </Slidebar>
</template>

<script>
import students from '@/api/students';

import Slidebar from '@/components/common/Slidebar.vue';
import { mapActions } from 'vuex';

export default {
  name: 'timetable-requests-group-slide-bar',
  props: ['item'],
  components: {
    Slidebar,
  },
  computed: {
    females() {
      return this.list.filter(x => x.kyn === 2).length;
    },
    males() {
      return this.list.filter(x => x.kyn === 1).length;
    },
    neutral() {
      return this.list.filter(x => x.kyn === 7).length;
    },
  },
  data() {
    return {
      loading: false,
      list: [],
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async loadGroup() {
      try {
        this.loading = true;
        const response = await students.getStudentsInGroup(this.item.hopur_id);
        this.list = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    item() {
      this.loadGroup();
    },
  },
  created() {
    this.loadGroup();
  },
};
</script>

<style lang="scss"></style>
