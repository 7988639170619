<template>
  <div>
    <b-row>
      <b-col class="pl-0" cols="6">
        <b-form-group label="Tegund vinnslu" label-for="jobType">
          <v-select id="jobType" name="jobType" v-model="selected.jobType" :options="jobTypes" label="name"> </v-select>
        </b-form-group>
        <b-form-group label="Deild" label-for="department">
          <v-select id="department" name="department" v-model="selected.departments" multiple :options="getDepartments" label="name">
          </v-select>
        </b-form-group>
        <b-form-group label="Áfangi" label-for="module">
          <b-form-input id="module" name="module" v-model="selected.module"> </b-form-input>
        </b-form-group>
        <b-form-group>
          <label label-for="dateTo"
            >Endadagsetning hópa
            <i
              class="fa fa-info-circle"
              v-b-tooltip
              title="Ef eingöngu á að reikna einkunn/flytja í feril fyrir hópa sem klárast á ákveðnum degi er þetta
              fyllt út annars skilið eftir autt."
            ></i
          ></label>

          <datepicker
            id="dateTo"
            name="dateTo"
            v-model="selected.dateTo"
            :language="lang"
            :monday-first="true"
            format="dd.MM.yyyy"
            :typeable="false"
            :clear-button="true"
            ref="dateToOpenPickr"
          ></datepicker>
        </b-form-group>
        <b-form-group v-if="selected.jobType && selected.jobType.id > 0">
          <label label-for="moveModules"
            >Færa áfanga vegna falls
            <i
              class="fa fa-info-circle"
              v-b-tooltip
              title="Ekki er mælt með að velja færa áfanga vegna falls fyrr en alveg í lokin.
              Ef ókláruð einkunn er lesin í feril sem fall þá færist áfanginn og áætlun nemanda getur breyst.
              ATH. Ekki er hægt að afturkalla þessa færslu eftir að hún hefur verið keyrð"
            ></i
          ></label>

          <b-form-checkbox id="moveModules" name="moveModules" v-model="selected.moveModules"></b-form-checkbox>
        </b-form-group>

        <b-form-group v-if="selected.jobType && selected.jobType.id > 0 && selected.moveModules">
          <b-form-checkbox
            id="youSure"
            name="youSure"
            class="d-inline-block"
            style="vertical-align: top"
            v-model="selected.youSure"
          ></b-form-checkbox>
          <label label-for="youSure" class="d-inline-block">
            Ertu alveg viss um að þú viljir færa áfanga vegna falls?<br />
            Ekki er hægt að afturkalla þá aðgerð.
          </label>
        </b-form-group>
        <b-btn
          @click="calculateFinalGrades()"
          variant="primary"
          class="mb-3"
          :disabled="!canEdit || !selected.jobType || (selected.jobType.id > 0 && selected.moveModules && !selected.youSure)"
          >Keyra vinnslu</b-btn
        >
      </b-col>
      <b-col>
        <div class="alert alert-info">
          <div><strong>Reikna einkunn</strong></div>
          Reiknar upp samkvæmt einkunnareglum fyrir alla hópa á kennslutímabilinu.
          <br /><br />
          <div><strong>Lokaeinkunn í ferla</strong></div>
          Flytur þá einkunn sem er merkt sem lokaeinkunn í feril allra nemenda í öllum hópum á kennslutímabilinu.
          <br /><br />
          <div><strong>Reikna einkunn og keyra lokaeinkunn í ferla</strong></div>
          Keyrir báðar vinnslurnar hér fyrir ofan.
          <br /><br />
          Hægt er að sía eftir áföngum ákveðinna deilda eða heiti áfanga.
          <br /><br />
          Vinnslan er sett í runuvinnslu. Það getur tekið allt að 5 mínútur fyrir runuvinnsluna til að keyra.<br />
        </div>
      </b-col>
    </b-row>

    <div>
      <b-link @click="showBatchJobs = !showBatchJobs">
        Seinustu runuvinnslur
        <i class="fa ml-2" :class="{ 'fa-angle-up': !showBatchJobs, 'fa-angle-down': showBatchJobs }" />
      </b-link>
    </div>
    <batchjobs v-if="showBatchJobs" :refresh="refreshJobs" :term="this.id" :type="'GradeJobs'"></batchjobs>
  </div>
</template>

<script>
import moment from 'moment';
import terms from '@/api/terms';
import structure from '@/api/structure';
import { mapActions, mapGetters } from 'vuex';

import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';

import Batchjobs from '@/components/common/Batchjobs.vue';

export default {
  name: 'grade-jobs',
  props: ['id', 'termPassed'],
  components: {
    Datepicker,
    Batchjobs,
  },
  computed: {
    canRead() {
      return this.loggedInUserHasReadPermission('skolahald_annir');
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_annir') && !this.termPassed;
    },
    getDepartments() {
      return this.departments.filter(
        x => this.selected.departments.length === 0 || !this.selected.departments.find(y => y.department_id === x.department_id),
      );
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolHasSettings']),
  },
  data() {
    return {
      lang: is,
      loading: {
        departments: false,
      },
      departments: [],
      jobTypes: [
        { id: 0, name: 'Reikna einkunn' },
        { id: 1, name: 'Keyra lokaeinkunn í feril' },
        { id: 2, name: 'Reikna einkunn og keyra lokaeinkunn í feril' },
      ],
      selected: {
        jobType: '',
        departments: [],
        moveModules: false,
        module: '',
        dateTo: '',
        youSure: false,
      },
      showBatchJobs: false,
      refreshJobs: false,
    };
  },
  methods: {
    async loadDepartments() {
      try {
        this.loading.departments = true;
        const response = await structure.getDepartmentList();
        this.departments = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.departments = false;
      }
    },
    async calculateFinalGrades() {
      try {
        await terms.calculateFinalGrades(this.id, this.selected.jobType.id, {
          departments: this.selected.departments.map(x => x.department_id).join(','),
          module: this.selected.module,
          enddate: this.selected.dateTo ? moment(this.selected.dateTo).format('DD.MM.YYYY') : null,
          moveModules: this.selected.moveModules ? 1 : 0,
        });
        this.displaySuccess('Vinnsla hefur verið send í runuvinnslu');
        this.refreshJobs = true;
        this.showBatchJobs = true;

        setTimeout(() => {
          this.refreshJobs = false;
        }, 1000);
      } catch (e) {
        // Aðgerð mistókst.
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadDepartments();
  },
};
</script>
