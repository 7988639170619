<template>
  <div>
    <b-row class="breadcumb-location" v-if="selectedCount > 0">
      <b-col>
        <div class="float-right">
          <Loader v-if="loading.certificates" />
          <CustomButton type="primary" title="Sækja vottorð" @click.native="getCertificates()" :disabled="loading.certificates" />
        </div>
      </b-col>
    </b-row>
    <b-card>
      <b-row>
        <b-col>
          <b-form-group label="Stílsíða" label-for="template">
            <v-select id="template" name="template" v-model="selected.template" :options="templates" label="name" :clearable="false">
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Tegund námskeiðs" label-for="term">
            <v-select
              id="category"
              name="category"
              v-model="selected.category"
              @input="loadClasses()"
              :options="categories"
              label="flokkur_nafn"
              :clearable="false"
            >
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Námskeið" label-for="class">
            <v-select id="class" name="class" v-model="selected.class" :options="classes" :clearable="false" label="namskeid_nafn">
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="selected.class">
        <b-col v-if="selected.class.participants.length === 0"> Engir þátttakendur fundust. </b-col>
        <b-col v-if="selected.class.participants.length > 0">
          <div class="mb-3">Hakaðu við þá nemendur sem á að gera viðurkenningaskjöl fyrir</div>
          <table class="table">
            <thead>
              <tr>
                <th style="padding-left: 12px" width="5%"><b-form-checkbox v-model="selected.all" @input="updateAll()" /></th>
                <th>Nafn</th>
                <th>Kennitala</th>
              </tr>
            </thead>
            <tr v-for="item in selected.class.participants" :key="item.namskeid_skraning_id">
              <td><b-form-checkbox v-model="item.selected" /></td>
              <td>{{ item.nafn }}</td>
              <td>{{ item.kt }}</td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import certificates from '@/api/certificates';
import singleClasses from '@/api/singleClasses';
import { mapGetters, mapActions } from 'vuex';
import he from 'he';

import CustomButton from '@/components/common/CustomButton.vue';

export default {
  name: 'single-classes-certificates',
  components: {
    CustomButton,
  },
  computed: {
    selectedCount() {
      return this.selected.class && this.selected.class.participants ? this.selected.class.participants.filter(x => x.selected).length : 0;
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      selected: {
        template: '',
        type: '',
        class: '',
      },
      loading: {
        category: false,
        classes: false,
        students: false,
        templates: false,
        certificates: false,
      },
      templates: [],
      categories: [],
      classes: [],
      students: [],
    };
  },
  methods: {
    async loadTemplates() {
      try {
        this.loading.templates = true;
        const response = await certificates.getTemplates(4);
        this.templates = response.data.items;
        this.selected.template = this.templates.find(x => x.school_id);
        if (!this.selected.template && this.templates.length > 0) {
          this.selected.template = this.templates[0];
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.templates = false;
      }
    },
    async loadCategory() {
      try {
        this.loading.category = true;
        const response = await singleClasses.getCategories();
        this.categories = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.category = false;
      }
    },
    async loadClasses() {
      try {
        this.classes = [];
        this.loading.classes = true;
        const params = {
          categories: this.selected.category.namskeid_flokkur_id,
        };
        const response = await singleClasses.getList(params);
        this.classes = response.data.items.map(x => ({
          ...x,
          namskeid_nafn: he.decode(x.namskeid_nafn, { allowUnsafeSymbols: true }),
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.classes = false;
      }
    },
    async getCertificates() {
      try {
        this.loading.certificates = true;
        const s = this.selected.class.participants
          .filter(x => x.selected)
          .map(x => x.namskeid_skraning_id)
          .join(',');
        const response = await certificates.createSingleClassCertificate(
          this.selected.template.template_id,
          this.selected.class.namskeid_id,
          s,
        );
        this.item = response.data;
        this.loading.certificates = false;
        if (this.item.id) {
          const url = `/api/certificates/${this.item.id}`;
          window.open(url, '_blank');
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    updateAll() {
      if (this.selected.class && this.selected.class.participants) {
        this.selected.class.participants.forEach(s => {
          this.$set(s, 'selected', this.selected.all);
        });
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadTemplates();
    this.loadCategory();
  },
};
</script>
