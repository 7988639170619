<template>
  <div>
    <div v-if="selectedModule" class="pb-3 noprint">
      <span class="cursor-pointer" @click="openSearchSlidebar()">Áfangi: {{ module.namsgrein_afanganr }} </span>
      <b-btn
        variant="primary"
        size="sm"
        style="padding: 2px 4px; margin-top: -5px"
        title="Breyta um áfanga"
        v-b-tooltip
        @click="openSearchSlidebar()"
        ><i class="fa fa-exchange"></i
      ></b-btn>
    </div>
    <h5 v-if="selectedModule" class="onlyprint" style="position: absolute; left: 0; font-weight: bold">{{ module.namsgrein_afanganr }}</h5>
    <ModuleSearch :slidebar="true" @changeModule="selectModule" v-if="!selectedModule" :searchTerm="true" :selectDefaultTerm="true" />
    <Timetable :id="selectedModule" type="module" v-if="selectedModule" :goToDate="termStart"></Timetable>
    <module-search-slidebar @closeSlidebar="selectModule" v-if="showSearchSlidebar" :large="true"> </module-search-slidebar>
  </div>
</template>

<script>
import curriculum from '@/api/curriculum';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import ModuleSearch from '@/components/curriculum/modules/ModuleSearch.vue';
import Timetable from '@/components/common/Timetable.vue';
import ModuleSearchSlidebar from '@/components/curriculum/modules/ModuleSearchSlidebar.vue';

export default {
  name: 'moduleTimetable',
  components: {
    ModuleSearch,
    Timetable,
    ModuleSearchSlidebar,
  },
  computed: {
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      selectedModule: null,
      module: '',
      showSearchSlidebar: false,
      termStart: '',
    };
  },
  methods: {
    openSearchSlidebar() {
      this.showSearchSlidebar = true;
    },
    selectModule(item) {
      if (item && item.id) {
        this.selectedModule = item.id;
        this.loadDetails();
      }
      this.termStart = '';
      if (item && item.term && item.term.is_active === 0) {
        this.termStart = moment(item.term.dags_fra);
      }
      this.showSearchSlidebar = false;
    },
    async loadDetails() {
      try {
        this.loading = true;
        const response = await curriculum.getModuleFewDetails(this.selectedModule);
        this.module = response.data.items && response.data.items.length > 0 ? response.data.items[0] : '';
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
