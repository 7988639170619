<template>
  <table class="table table-hover less-padding attendanceModulesTable" style="font-size: 13px">
    <thead>
      <tr>
        <td>Áfangi</td>
        <td :colspan="attendance.fjarv_stls.length">Fjarvistir</td>
        <td :colspan="attendance.forf_stls.length">Forföll</td>
        <td>Fjöldi tíma</td>
        <td>Fjarvistarstig</td>
        <td :colspan="attendanceCount">Mætingarhlutfall</td>
      </tr>
      <tr>
        <td></td>
        <td
          v-for="(fjar, idx) in attendance.fjarv_stls"
          :key="fjar.teg_fjarvista"
          :class="{ noBorder: idx !== attendance.fjarv_stls.length - 1 }"
        >
          {{ fjar.kodi }}
        </td>
        <td
          v-for="(forf, idx) in attendance.forf_stls"
          :key="forf.teg_forfalla"
          :class="{ noBorder: idx !== attendance.forf_stls.length - 1 }"
        >
          {{ forf.kodi }}
        </td>
        <td></td>
        <td></td>
        <td class="noBorder" v-if="schoolSettings.maetingarhlutfall_stig === 1">
          Mæting <i class="fa fa-info-circle" :title="`<strong>Mæting</strong><br/> ${$t('maeting')}`" v-b-tooltip.hover.html></i>
        </td>
        <td class="noBorder" v-if="schoolSettings.maetingarhlutfall_raun === 1">
          Raun <i class="fa fa-info-circle" :title="`<strong>Raunmæting</strong><br/> ${$t('raunMaeting')}`" v-b-tooltip.hover.html></i>
        </td>
        <td class="noBorder" v-if="schoolSettings.maetingarhlutfall_onn === 1">
          Önn <i class="fa fa-info-circle" :title="`<strong>Önn</strong><br/> ${$t('maetingOnn')}`" v-b-tooltip.hover.html></i>
        </td>
        <td class="noBorder" v-if="schoolSettings.maetingarhlutfall_einkunn === 1">
          Eink.
          <i
            class="fa fa-info-circle"
            :title="`<strong>Til mætingareinkunnar</strong><br/> ${$t('tilMaetingareinkunnar')}`"
            v-b-tooltip.hover.html
          ></i>
        </td>
      </tr>
    </thead>
    <AttendanceModule v-for="afangi in attendance.afangar" :key="afangi.ferill_id" :afangi="afangi" />
    <tr>
      <td><strong>Samtals</strong></td>
      <td v-for="fjar in attendance.fjarv_stls" :key="`total-${fjar.teg_fjarvista}`">{{ fjar.fj > 0 ? fjar.fj : '' }}</td>
      <td v-for="forf in attendance.forf_stls" :key="`total-${forf.teg_forfalla}`">{{ forf.fj > 0 ? forf.fj : '' }}</td>
      <td>{{ totalClasses }}</td>
      <td>{{ totalAbsencePoints }}</td>
      <td
        v-if="schoolSettings.maetingarhlutfall_stig === 1"
        :class="getAttendanceColor(attendance.maeting_onn_stls.length > 0 && attendance.maeting_onn_stls[0].maeting)"
      >
        {{
          attendance.maeting_onn_stls.length > 0 && attendance.maeting_onn_stls[0].maeting
            ? `${attendance.maeting_onn_stls[0].maeting}%`
            : ''
        }}
      </td>
      <td
        v-if="schoolSettings.maetingarhlutfall_raun === 1"
        :class="getAttendanceColor(attendance.maeting_onn_stls.length > 0 && attendance.maeting_onn_stls[0].maeting_raun)"
      >
        {{
          attendance.maeting_onn_stls.length > 0 && attendance.maeting_onn_stls[0].maeting_raun
            ? `${attendance.maeting_onn_stls[0].maeting_raun}%`
            : ''
        }}
      </td>
      <td
        v-if="schoolSettings.maetingarhlutfall_onn === 1"
        :class="getAttendanceColor(attendance.maeting_onn_stls.length > 0 && attendance.maeting_onn_stls[0].maeting_onn)"
      >
        {{
          attendance.maeting_onn_stls.length > 0 && attendance.maeting_onn_stls[0].maeting_onn
            ? `${attendance.maeting_onn_stls[0].maeting_onn}%`
            : ''
        }}
      </td>
      <td
        v-if="schoolSettings.maetingarhlutfall_einkunn === 1"
        :class="getAttendanceColor(attendance.maeting_onn_stls.length > 0 && attendance.maeting_onn_stls[0].maeting_einkunn)"
      >
        {{
          attendance.maeting_onn_stls.length > 0 && attendance.maeting_onn_stls[0].maeting_einkunn
            ? `${attendance.maeting_onn_stls[0].maeting_einkunn}%`
            : ''
        }}
      </td>
    </tr>
  </table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import AttendanceModule from '@/components/students/attendance/AttendanceModule.vue';

export default {
  name: 'attendance-modules',
  props: ['attendance', 'termName'],
  components: {
    AttendanceModule,
  },
  computed: {
    attendanceCount() {
      let count = 0;
      if (this.schoolSettings && this.schoolSettings.maetingarhlutfall_stig === 1) {
        count += 1;
      }
      if (this.schoolSettings && this.schoolSettings.maetingarhlutfall_raun === 1) {
        count += 1;
      }
      if (this.schoolSettings && this.schoolSettings.maetingarhlutfall_onn === 1) {
        count += 1;
      }
      if (this.schoolSettings && this.schoolSettings.maetingarhlutfall_einkunn === 1) {
        count += 1;
      }
      return count;
    },
    totalClasses() {
      return [].concat
        .apply([], [...this.attendance.afangar.map(x => x.fjarvistarstig_ofl)])
        .map(x => x.fj_fjarv_forfoll_id)
        .reduce((a, b) => {
          return a + b;
        }, 0);
    },
    totalAbsencePoints() {
      return [].concat
        .apply([], [...this.attendance.afangar.map(x => x.fjarvistarstig_ofl)])
        .map(x => x.fjarvistar_stig || 0)
        .reduce((a, b) => {
          return a + b;
        }, 0);
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {};
  },
  methods: {
    getAttendanceColor(item) {
      if (item < 80) {
        return 'attendancePercentageUnder80';
      }
      if (item >= 80 && item < 90) {
        return 'attendancePercentageUnder90';
      }
      return '';
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
<style lang="scss">
table.attendanceModulesTable {
  thead {
    background-color: #ccc;
  }
  tbody:nth-child(even) {
    background-color: #f9f9f9;
  }
  tbody:nth-child(odd) {
    background-color: #ececec;
  }
  thead,
  tbody {
    tr {
      td,
      th {
        border-right: 2px solid #fff;
        &.noBorder {
          border: none;
        }
      }
    }
  }
}
</style>
