
import 'reflect-metadata';
import moment from 'moment';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';

import Slidebar from '@/components/common/Slidebar.vue';
import Loader from '@/components/common/Loader.vue';
import { TimetableGetTeamsEventResponse, TimetableTeamsRoomListResponse } from '@/api/openapi';
import { BTable } from 'bootstrap-vue';

@Component({
  components: { Slidebar, Loader },
})
export default class EditTimetableTeamsSlidebar extends Vue {
  @Action displayError!: (s: string) => void;
  @Action displaySuccess!: (s: string) => void;

  @Prop() id!: number;
  @Prop() term!: any;

  $refs!: {
    attendeeTable: BTable;
  };

  submitted = false;
  submitting = false;
  errorList: Array<string> = [];

  // Data
  form: {
    room: TimetableTeamsRoomListResponse | null;
    lobbyBypass: boolean;
  } = {
    room: null,
    lobbyBypass: false,
  };

  rooms: {
    loading: boolean;
    data: Array<TimetableTeamsRoomListResponse> | null;
  } = {
    loading: true,
    data: null,
  };

  event: {
    loading: boolean;
    data: TimetableGetTeamsEventResponse | null;
  } = {
    loading: true,
    data: null,
  };

  attendeeFields = [
    { key: 'name', label: 'Nafn' },
    { key: 'email', label: 'Netfang' },
    { key: 'isCreated', label: 'Skráð/ur' },
  ];

  // Computed

  get attendeeItems() {
    return this.event.data?.attendees || [];
  }

  get allAttendeesSelected() {
    return (this.event.data?.attendees || []).every(a => a.isCreated);
  }

  get someAttendeesSelected() {
    return (this.event.data?.attendees || []).some(a => a.isCreated);
  }

  get dateFrom() {
    return this.event.data ? `${moment(this.event.data.dags).format('DD.MM.YYYY')} ${this.event.data.timiFra}` : '';
  }

  get dateTo() {
    return this.event.data ? `${moment(this.event.data.dags).format('DD.MM.YYYY')} ${this.event.data.timiTil}` : '';
  }

  // Methods
  selectAllAttendees() {
    if (this.event.data) {
      this.$set(
        this.event.data,
        'attendees',
        this.event.data.attendees.map(a => ({
          ...a,
          isCreated: !this.allAttendeesSelected,
        })),
      );

      // this.event.data.attendees[index].isCreated = value;
    }
  }

  attendeeRowChecked(index: number, value: boolean) {
    if (this.event.data) {
      this.event.data.attendees[index].isCreated = value;
    }
  }

  closeSlidebar() {
    this.$emit('closeSlidebar');
  }

  async loadRoomList() {
    this.rooms.loading = true;
    this.rooms.data = [];
    try {
      const response = await this.$schoolApi.timetableTeamsRoomList({
        id: this.id,
      });

      this.rooms.data = response.data;

      if (this.event.data?.roomId) {
        const currentRoom = response.data.find(r => r.id === this.event.data?.roomId);
        this.form.room = currentRoom || null;
      }
    } catch (e) {
      this.$log.error(e);
      this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
    } finally {
      this.rooms.loading = false;
    }
  }

  async validateBeforeSubmit() {
    try {
      this.submitted = true;
      this.errorList = [];
      await this.$validator.validateAll().then(response => {
        if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
      });

      try {
        this.submitting = true;

        if (this.event.data?.isCreated) {
          await this.$schoolApi.timetableUpdateTeamsEvent({
            id: this.id,
            timetableCreateTeamsEventInput: {
              placeId: this.form.room?.id,
              attendees: this.event.data?.attendees.filter(a => a.isCreated).map(a => a.id) || [],
              lobbyBypass: this.form.lobbyBypass,
            },
          });

          this.displaySuccess('Færsla hefur verið uppfærð');
        } else {
          await this.$schoolApi.timetableCreateTeamsEvent({
            id: this.id,
            timetableCreateTeamsEventInput: {
              placeId: this.form.room?.id,
              attendees: this.event.data?.attendees.filter(a => a.isCreated).map(a => a.id) || [],
              lobbyBypass: this.form.lobbyBypass,
            },
          });

          this.displaySuccess('Færsla hefur verið stofnuð');
        }

        this.loadEvent();
        // this.$emit('closeSlidebarAndReload');
      } catch (e) {
        this.$log.debug(e);
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.submitting = false;
      }
    } catch (e) {
      // Villur í formi.
      this.$log.debug(e);
    } finally {
      this.submitting = false;
    }
  }

  async loadEvent() {
    this.event.loading = true;
    try {
      const response = await this.$schoolApi.timetableGetTeamsEvent({ id: this.id });
      this.event.data = response.data;
      this.form.lobbyBypass = response.data.lobbyBypass;
      this.$set(
        this.event.data,
        'attendees',
        response.data.attendees.map(a => ({
          ...a,
          isCreated: response.data.isCreated ? a.isCreated : true,
        })),
      );
    } catch (e) {
      this.$log.error(e);
      this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
    } finally {
      this.event.loading = false;
    }
  }

  async cancelEvent() {
    const conf = confirm(`Ert þú viss um að þú viljir eyða Teams fundarboði?`); // eslint-disable-line

    if (conf) {
      this.submitting = true;
      try {
        await this.$schoolApi.timetableDeleteTeamsEvent({ id: this.id });

        this.loadEvent();
        this.displaySuccess('Færslu hefur verið eytt');
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.submitting = false;
      }
    }
  }

  async created() {
    await this.loadEvent();
    this.loadRoomList();
  }
}
