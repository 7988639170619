var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('Loader'):_vm._e(),(!_vm.loading)?_c('div',[(_vm.showGroupDetails)?_c('div',[_c('strong',[_vm._v("Hópur: ")]),_vm._v(_vm._s(_vm.item.namsgrein_afanganr)+"("+_vm._s(_vm.item.hopur)+")")]):_vm._e(),(_vm.showGroupDetails)?_c('div',[_c('strong',[_vm._v("Kennari: ")]),_vm._v(_vm._s(_vm.item.kennarar))]):_vm._e(),_c('div',{staticStyle:{"min-height":"100px"}},[_vm._m(0),(_vm.canEdit)?_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"btn-xs mr-2 p-2",staticStyle:{"font-size":"13px"},attrs:{"variant":"primary","title":"Reikna upp skv. einkunnareglu","disabled":_vm.saving.calculate || _vm.saving.final},on:{"click":function($event){return _vm.calculateGrades()}}},[(_vm.saving.calculate)?_c('i',{staticClass:"fa fa-spin fa-spinner"}):_vm._e(),_vm._v(" Reikna einkunn")]):_vm._e(),(_vm.canEdit)?_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"btn-xs mr-2 p-2",staticStyle:{"font-size":"13px"},attrs:{"variant":"primary","title":"Flytja lokaeinkunn í feril","disabled":_vm.saving.calculate || _vm.saving.final},on:{"click":function($event){return _vm.finalGrades()}}},[(_vm.saving.final)?_c('i',{staticClass:"fa fa-spin fa-spinner"}):_vm._e(),_vm._v(" Lokaeinkunn í feril")]):_vm._e(),_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"btn-xs mr-2 p-2",staticStyle:{"font-size":"13px"},attrs:{"variant":"primary","title":"Skoða einkunnareglu hóps"},on:{"click":function($event){return _vm.openGradeRuleSlidebar()}}},[_vm._v(" Einkunnaregla")]),(_vm.canReadCertificate)?_c('b-btn',{staticClass:"btn-xs",attrs:{"variant":"primary","to":{
          name: 'Certificates',
          query: { term: _vm.group && _vm.group.onn_id, moduleTermId: _vm.group && _vm.group.afangar_onn_id, group: _vm.group && _vm.group.hopur_id },
        },"target":"_blank"}},[_vm._v("Viðurkenningaskjal")]):_vm._e(),_vm._v("   ")],1),_c('table',{staticClass:"table table-hover less-padding mt-2",staticStyle:{"font-size":"12px"}},[_c('tr',[_c('th',[_vm._v("Nafn")]),_c('th',[_vm._v("Kennitala")]),_vm._l((_vm.item.einkunnaregla),function(rule){return _c('th',{key:rule.einkunnaregla_id},[_c('div',{staticClass:"gradeRuleRotateBox"},[_c('span',{staticClass:"gradeRuleRotate"},[(_vm.edit && rule.reiknud_skrad === 1)?_c('b-link',{staticStyle:{"color":"inherit"},on:{"click":function($event){return _vm.openGradeModal(rule)}}},[_vm._v(" "+_vm._s(rule.heiti.substring(0, 20))+" "),(rule.heiti.length > 20)?_c('span',[_vm._v("..")]):_vm._e(),_c('i',{staticClass:"fa fa-pencil text-primary"})]):_c('span',[_vm._v(" "+_vm._s(rule.heiti.substring(0, 20))+" "),(rule.heiti.length > 20)?_c('span',[_vm._v("..")]):_vm._e()])],1)])])}),_vm._m(1),_vm._m(2),_vm._m(3),_c('th',[_c('download-csv',{staticClass:"cursor-pointer float-right",attrs:{"data":_vm.getExcelData,"name":_vm.getExcelFileName,"delimiter":";"}},[_c('i',{staticClass:"fa fa-file-excel-o excelText"})])],1)],2),_c('tbody',_vm._l((_vm.item.nemendur),function(student,index){return _c('tr',{key:student.ferill_id},[_c('td',[_vm._v(" "+_vm._s(student.nafn)+" "),(_vm.canViewStudent)?_c('b-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],attrs:{"to":{ name: 'Student', params: { id: student.nemandi_id } },"target":"_blank","title":"Skoða nemanda"}},[_c('i',{staticClass:"fa fa-external-link"})]):_vm._e()],1),_c('td',[_vm._v(_vm._s(student.kennitala))]),_vm._l((student.einkunnir),function(grade,idx){return _c('td',{key:`student${index}grade${idx}`,class:{
              gradeMissing:
                (_vm.showMissing && grade.reiknud_skrad === 1 && !grade.einkunn && grade.heiti !== 'Miðannarmat') ||
                (grade.einkunnaregla_id === _vm.finalGradeId && student.finalMismatch),
            }},[(grade.einkunn)?_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.getGradePopover(grade)),expression:"getGradePopover(grade)",modifiers:{"hover":true,"top":true}}],class:{
                'text-danger':
                  !_vm.checkGradePassed(grade.lagmarks_einkunn, grade.fall_einkunnir_hops_lagm, grade.einkunn) || _vm.isScaleFail(grade),
              }},[_vm._v(_vm._s(grade.einkunn ? _vm.getGrade(grade.einkunn) : '')+" "),(grade.einkunn_laest === 1)?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"fa fa-lock",attrs:{"title":"Einkunn læst"}}):_vm._e()]):_c('span',{staticStyle:{"color":"blue"}},[_vm._v(_vm._s(grade.assignment_grade))])])}),_c('td',[_vm._v(_vm._s(student.reiknud_einkunn ? student.reiknud_einkunn.toFixed(2).toString().replace('.', ',') : ''))]),_c('td',[_vm._v(" "+_vm._s(student.reiknud_einkunn_allar ? student.reiknud_einkunn_allar.toFixed(2).toString().replace('.', ',') : '')+" ")]),_c('td',{class:{ gradeMissing: (_vm.showFinalMissing && !student.lokaeinkunn) || student.finalMismatch }},[_vm._v(" "+_vm._s(student.lokaeinkunn)+" ")]),_c('td')],2)}),0)]),_vm._m(4)]):_vm._e(),(_vm.showGradeSlidebar)?_c('register-grade-slidebar',{attrs:{"groupId":_vm.groupId,"gradeModuleId":_vm.selectedItem && _vm.selectedItem.einkunnir_afanga_id,"gradeGroupId":_vm.selectedItem && _vm.selectedItem.einkunnir_hops_id,"assignmentName":_vm.selectedItem && _vm.selectedItem.assignment_name},on:{"closeAndReloadSlidebar":_vm.closeAndReloadSlidebar,"closeSlidebar":_vm.closeSlidebar}}):_vm._e(),(_vm.showGradeRuleSlidebar)?_c('grade-rule-slidebar',{attrs:{"groupId":_vm.groupId,"edit":_vm.canEditGraderule,"termModuleId":_vm.group && _vm.group.afangar_onn_id},on:{"closeAndReloadSlidebar":_vm.closeAndReloadSlidebar,"closeSlidebar":_vm.closeAndReloadSlidebar}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"font-size":"13px","margin-bottom":"15px"}},[_vm._v(" Hægt er að skrá einkunn með því að smella á blýantinn við viðkomandi einkunnaþátt. "),_c('br'),_vm._v(" Ef það birtist ekki blýantur þar sem á að skrá einkunn þarf að skoða hvort það sé "),_c('br'),_vm._v(" örugglega merkt „skráð“ einkunn í einkunnareglunni. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('div',{staticClass:"gradeRuleRotateBox"},[_c('span',{staticClass:"gradeRuleRotate"},[_vm._v("Reikn. eink.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('div',{staticClass:"gradeRuleRotateBox"},[_c('span',{staticClass:"gradeRuleRotate"},[_vm._v("Reikn. eink. allar")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('div',{staticClass:"gradeRuleRotateBox"},[_c('span',{staticClass:"gradeRuleRotate"},[_vm._v("Lokaeink. í námsf. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"font-size":"13px"}},[_c('div',[_c('strong',[_vm._v("Reiknuð einkunnir allar")]),_vm._v(" inniheldur allar einkunnir bæði birtar og óbirtar sem vega upp í lokaeinkunn.")]),_c('div',[_c('strong',[_vm._v("Reiknuð einkunn")]),_vm._v(" inniheldur eingöngu birtar einkunnir sem vega upp í lokaeinkunn.")]),_c('div',[_c('div',{staticClass:"d-inline-block",staticStyle:{"height":"10px","width":"10px","background":"blue"}}),_vm._v(" = Bláar einkunnir eru óbirtar ")])])
}]

export { render, staticRenderFns }