var render = function render(){var _vm=this,_c=_vm._self._c;return _c('page',[_c('Breadcrumbs',{attrs:{"breadcrumbs":_vm.breadcrumbs}}),_c('PageTitle',{attrs:{"title":"Breyta hlutverki"}}),_c('b-row',[_c('b-col',[_c('h5',[_vm._v(_vm._s(_vm.name))])]),_c('b-col',{attrs:{"cols":"3"}},[_c('div',[_vm._v("Setja sama aðganginn á alla")]),_c('v-select',{attrs:{"id":"sameAccess","name":"sameAccess","options":_vm.accessTypes,"label":"name"},on:{"input":_vm.changeAccessAll},model:{value:(_vm.sameAccess),callback:function ($$v) {_vm.sameAccess=$$v},expression:"sameAccess"}})],1)],1),_c('br'),_c('table',{staticClass:"table less-padding",staticStyle:{"font-size":"13px"}},[_c('tr',[_c('td',{staticStyle:{"border-top":"none"},attrs:{"colspan":"2"}},[_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"fa fa-search pl-1 mr-3",attrs:{"title":"Les réttindi - Má skoða"}}),_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"fa fa-pencil",attrs:{"title":"Skrif réttindi - Má breyta"}})])]),_vm._l((_vm.list),function(item,index){return _c('tbody',{key:item.id,staticStyle:{"border-color":"transparent"}},[(_vm.showCategory(index))?_c('tr',[_c('td',{staticStyle:{"white-space":"nowrap"},attrs:{"width":"5%"}},[_c('div',{staticClass:"d-inline-block"},[_c('b-form-checkbox',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],attrs:{"type":"checkbox","value":"1","title":"Les réttindi","checked":_vm.categories.find(x => x.id === item.adgangur_flokkur_id).access},on:{"change":function($event){_vm.uniqueCheck(
                  _vm.categories.find(x => x.id === item.adgangur_flokkur_id),
                  $event,
                  'category',
                )}}})],1),_c('div',{staticClass:"d-inline-block"},[_c('b-form-checkbox',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],attrs:{"type":"checkbox","value":"2","title":"Skrif réttindi","checked":_vm.categories.find(x => x.id === item.adgangur_flokkur_id).access},on:{"change":function($event){_vm.uniqueCheck(
                  _vm.categories.find(x => x.id === item.adgangur_flokkur_id),
                  $event,
                  'category',
                )}}})],1)]),_c('td',[_c('i',{staticStyle:{"font-size":"18px"}},[_vm._v(_vm._s(item.flokkur_heiti))])])]):_vm._e(),_c('tr',[_c('td',[_c('div',{staticClass:"d-inline-block"},[_c('b-form-checkbox',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],attrs:{"type":"checkbox","value":"1","checked":item.access,"title":"Les réttindi"},on:{"change":function($event){return _vm.uniqueCheck(item, $event)}}})],1),_c('div',{staticClass:"d-inline-block"},[_c('b-form-checkbox',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],attrs:{"type":"checkbox","value":"2","checked":item.access,"title":"Skrif réttindi"},on:{"change":function($event){return _vm.uniqueCheck(item, $event)}}})],1)]),_c('td',[_vm._v(_vm._s(item.styring_heiti))])])])})],2),_c('b-btn',{staticClass:"mr-3",attrs:{"variant":"primary"},on:{"click":_vm.validateBeforeSubmit}},[_vm._v("Vista")]),_c('b-btn',{staticClass:"mr-3",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.deleteJob()}}},[_vm._v("Eyða")]),_c('b-btn',{attrs:{"variant":"dark","to":{ name: 'Access' }}},[_vm._v("Hætta við")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }