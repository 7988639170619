<template>
  <page>
    <b-row class="breadcumb-location" v-if="list.length > 0">
      <b-col>
        <div class="float-right">
          <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="d-inline">
            <CustomButton type="excel" />
          </download-csv>
        </div>
      </b-col>
    </b-row>
    <h2 class="mt-3">Kostnaðargreining brauta</h2>
    <div>Hér er hægt að sækja nauðsynleg gögn til þess að geta reiknað út kostnaðargreiningu hverrar brautar fyrir sig.</div>
    <div>Listinn byggir á nýjasta vinnumati sem finnst fyrir hvern áfanga brautar.</div>
    <b-row>
      <b-col class="pl-0">
        <strong>Braut</strong>
        <b-form-group label-for="searchCourse">
          <v-select
            id="searchCourse"
            name="searchCourse"
            v-model="selected.course"
            :options="getCourses"
            @input="loadData()"
            :clearable="true"
            :disabled="getAll || loading.list"
            style="font-size: 13px"
          >
          </v-select>
        </b-form-group>
      </b-col>
      <b-col class="pl-0">
        <strong>Undirskóli</strong>
        <v-select
          id="division"
          name="division"
          v-model="selected.division"
          :clearable="true"
          @input="loadData()"
          :options="getDivisions"
          :disabled="loading.list"
        >
        </v-select>
        <div class="float-right">
          <b-form-checkbox v-model="getAll" @input="searchAll()" class="d-inline-block" :disabled="loading.list"></b-form-checkbox> Sækja
          allar brautir
        </div>
      </b-col>
    </b-row>
    <Loader v-if="loading.list"></Loader>
    <table class="table less-padding mt-3" v-if="!loading.list && firsttime" style="font-size: 12px">
      <thead>
        <tr>
          <th><LinkOrder title="Heiti" :filteredList="list" :list="list" column="braut_heiti" /> ({{ list.length }})</th>
          <th>
            <LinkOrder title="Kóði " :filteredList="list" :list="list" column="braut_kodi" />
          </th>
          <th class="dont-break">
            <LinkOrder title="Ein." :filteredList="list" :list="list" column="einingar_alls" />
            <i class="fa fa-info-circle" title="Einingar brautar" v-b-tooltip></i>
          </th>
          <th>
            <LinkOrder title="Uppbygging" :filteredList="list" :list="list" column="uppbygging_heiti" />
          </th>
          <th>
            <LinkOrder title="Tegund" :filteredList="list" :list="list" column="uppbygging_tegund" />
          </th>
          <th>
            <LinkOrder title="Áfangi" :filteredList="list" :list="list" column="namsgrein_afanganr" />
          </th>
          <th>
            <LinkOrder title="Heiti" :filteredList="list" :list="list" column="afanga_heiti" />
          </th>
          <th class="dont-break">
            <LinkOrder title="Ein." :filteredList="list" :list="list" column="einingar" />
            <i class="fa fa-info-circle" title="Einingar áfanga" v-b-tooltip></i>
          </th>
          <th>
            <LinkOrder title="Tímabil" :filteredList="list" :list="list" column="onn_nr" />
          </th>
          <th class="dont-break">
            <LinkOrder title="Vinnustundir" :filteredList="list" :list="list" column="samtals_nem" />
            <i class="fa fa-info-circle" title="Vinnustundir samtals ef fjöldaviðmið" v-b-tooltip></i>
          </th>
          <th class="dont-break">
            <LinkOrder title="Nem." :filteredList="list" :list="list" column="fjoldi_hops" />
            <i class="fa fa-info-circle" title="Fjöldaviðmið í námshópi" v-b-tooltip></i>
          </th>
          <th class="dont-break">
            <LinkOrder title="Lágmark" :filteredList="list" :list="list" column="samtals_nem_min" />
            <i class="fa fa-info-circle" title="Vinnustundir samtals ef lágmarksfjöldi í vinnumati" v-b-tooltip></i>
          </th>
          <th class="dont-break">
            <LinkOrder title="Nem." :filteredList="list" :list="list" column="fjoldi_hops_min" />
            <i class="fa fa-info-circle" title="Lágmarksfjöldi í vinnumati kennara" v-b-tooltip></i>
          </th>
          <th class="dont-break">
            <LinkOrder title="Hámark" :filteredList="list" :list="list" column="samtals_nem_max" />
            <i class="fa fa-info-circle" title="Vinnustundir samtals ef hámarksfjöldi án álags" v-b-tooltip></i>
          </th>
          <th class="dont-break">
            <LinkOrder title="Nem." :filteredList="list" :list="list" column="fjoldi_hops_max" />
            <i class="fa fa-info-circle" title="Hámarksfjöldi í námshópi án álags á vinnu nemenda" v-b-tooltip></i>
          </th>
          <th class="dont-break">
            <LinkOrder title="Stundir" :filteredList="list" :list="list" column="afangi_timar_viku" />
            <i class="fa fa-info-circle" title="Fjöldi kennslustunda að jafnaði á viku" v-b-tooltip></i>
          </th>
          <th class="dont-break">
            <LinkOrder title="Lengd" :filteredList="list" :list="list" column="afangi_kennslustund_min" />
            <i class="fa fa-info-circle" title="Lengd kennslustunda í mínútum" v-b-tooltip></i>
          </th>
          <th class="dont-break">
            <LinkOrder title="Tímabil" :filteredList="list" :list="list" column="onn_eink" />
            <i class="fa fa-info-circle" title="Kennslutímabil sem vinnumat var tekið frá" v-b-tooltip></i>
          </th>
        </tr>
      </thead>
      <tbody>
        <TableRowLoader v-if="loading.list" colspan="17" :center="true" />
        <TableRowEmpty v-if="!loading.list && this.list.length === 0" colspan="17" :center="true" />
        <tr v-for="(item, index) in getPaginatedList" :key="index">
          <td :title="item.braut_heiti.length > 20 ? item.braut_heiti : ''" v-b-tooltip>
            {{ item.braut_heiti.substr(0, 20) }}
            <span v-if="item.braut_heiti.length > 20">..</span>
          </td>
          <td>{{ item.braut_kodi }}</td>
          <td>{{ item.einingar_alls }}</td>
          <td :title="item.uppbygging_heiti.length > 20 ? item.uppbygging_heiti : ''" v-b-tooltip>
            {{ item.uppbygging_heiti.substr(0, 20) }}
            <span v-if="item.uppbygging_heiti.length > 20">..</span>
          </td>
          <td>{{ item.uppbygging_tegund }}</td>
          <td>{{ item.namsgrein_afanganr }}</td>
          <td :title="item.afanga_heiti.length > 20 ? item.afanga_heiti : ''" v-b-tooltip>
            {{ item.afanga_heiti.substr(0, 20) }}
            <span v-if="item.afanga_heiti.length > 20">..</span>
          </td>

          <td>{{ item.einingar }}</td>
          <td>{{ item.onn_nr }}</td>
          <td>{{ item.samtals_nem }}</td>
          <td>{{ item.fjoldi_hops }}</td>
          <td>{{ item.samtals_nem_min }}</td>
          <td>{{ item.fjoldi_hops_min }}</td>
          <td>{{ item.samtals_nem_max }}</td>
          <td>{{ item.fjoldi_hops_max }}</td>
          <td>{{ item.afangi_timar_viku }}</td>
          <td>{{ item.afangi_kennslustund_min }}</td>
          <td>{{ item.onn_eink }}</td>
        </tr>
      </tbody>
    </table>

    <Pagination
      :currentPage="currentPage"
      :totalRows="list.length"
      :perPage="perPage"
      v-if="list.length > 25"
      @change-page="changePage"
      @change-limit="changeLimit"
    ></Pagination>
  </page>
</template>

<script>
import structure from '@/api/structure';
import curriculum from '@/api/curriculum';
import reports from '@/api/reports';
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';

import Loader from '@/components/common/Loader.vue';
import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';
import CustomButton from '@/components/common/CustomButton.vue';
import Pagination from '@/components/common/Pagination.vue';

export default {
  name: 'CoursesAnalysis',
  components: {
    Loader,
    TableRowLoader,
    TableRowEmpty,
    LinkOrder,
    CustomButton,
    Pagination,
  },
  computed: {
    getPaginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.list.slice(start, end);
    },
    getCourses() {
      return this.courses.filter(x => !this.selected.division || x.undirskoli_id === this.selected.division.division_id);
    },
    getDivisions() {
      return this.divisions.filter(x => !this.selected.course || x.division_id === this.selected.course.undirskoli_id);
    },
    getExcelData() {
      const newList = [
        [],
        ['Greining brautar'],
        [this.selected.course.label],
        [this.selected.division.label],
        [],
        [
          'Heiti brautar',
          'Kóði brautar',
          'Fjöldi eininga',
          'Uppbygging',
          'Tegund',
          'Áfangi',
          'Heiti áfanga',
          'Einingar áfanga',
          'Tímabil',
          'Vinnustundir samtals ef fjöldaviðmið',
          'Fjöldaviðmið í námshópi',
          'Vinnustundir samtals ef lágmarksfjöldi í vinnumati',
          'Lágmarksfjöldi í vinnumati kennara',
          'Vinnustundir samtals ef hámarksfjöldi án álags',
          'Hámarksfjöldi í námshópi án álags á vinnu nemenda',
          'Fjöldi kennslustunda að jafnaði á viku',
          'Lengd kennslustunda í mínútum',
          'Kennslutímabil sem vinnumat var tekið frá',
        ],
        ...this.list.map(x => [
          x.braut_heiti,
          x.braut_kodi,
          x.einingar_alls,
          x.uppbygging_heiti,
          x.uppbygging_tegund,
          x.namsgrein_afanganr,
          x.fafanga_heiti,
          x.einingar,
          x.onn_nr,
          x.samtals_nem ? x.samtals_nem.toString().replace('.', ',') : '',
          x.fjoldi_hops,
          x.samtals_nem_min ? x.samtals_nem_min.toString().replace('.', ',') : '',
          x.fjoldi_hops_min ? x.fjoldi_hops_min.toString().replace('.', ',') : '',
          x.samtals_nem_max ? x.samtals_nem_max.toString().replace('.', ',') : '',
          x.fjoldi_hops_max ? x.fjoldi_hops_max.toString().replace('.', ',') : '',
          x.afangi_timar_viku ? x.afangi_timar_viku.toString().replace('.', ',') : '',
          x.afangi_kennslustund_min ? x.afangi_kennslustund_min.toString().replace('.', ',') : '',
          x.onn_eink,
        ]),
      ];
      return newList;
    },
    getExcelFileName() {
      return `greining_brautar_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {
      perPage: 25,
      currentPage: 1,
      courses: [],
      loading: {
        courses: false,
        list: false,
      },
      list: [],
      divisions: [],
      selected: {
        course: '',
        division: '',
      },
      getAll: false,
      firsttime: false,
    };
  },
  methods: {
    async loadCourses() {
      try {
        this.loading.courses = true;
        const response = await curriculum.getCourseList({ active: 1 });
        this.courses = response.data.items.map(x => ({
          id: x.braut_id,
          undirskoli_id: x.undirskoli_id,
          label: `${x.heiti} ${x.braut_kodi}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.courses = false;
      }
    },
    async loadData() {
      try {
        this.data = [];
        this.currentPage = 1;
        if (this.getAll || this.selected.course) {
          this.loading.list = true;
          this.firsttime = true;
          const id = !this.getAll ? this.selected.course.id : '';
          const division = this.selected.division ? this.selected.division.division_id : '';
          const response = await reports.getCourseAnalysis(id, { divisionId: division });
          this.list = response.data.items;
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.list = false;
      }
    },
    searchAll() {
      this.list = [];
      if (this.getAll) {
        this.selected.course = '';
        this.loadData();
      } else {
        this.firsttime = false;
      }
    },
    async loadDivisions() {
      try {
        const response = await structure.getDivisionList();
        this.divisions = response.data.items.map(x => ({
          ...x,
          label: `${x.code} - ${x.name}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadCourses();
    this.loadDivisions();
  },
};
</script>
