<template>
  <div>
    <b-row>
      <b-col class="pl-0 mb-3" style="font-size: 13px">
        <div>Áfanginn er tengdur við eftirfarandi kennslutímabil.</div>
        <div>
          Ath! Kennarar áfangans geta sjálfir bætt við og breytt námsáætlun, einnig geta þeir átt við námsgagnalista og lokapróf í
          námskerfinu ef stýringar skólans leyfa. Stýringarnar í þínum skóla eru eftirfarandi:
        </div>
        <div>
          Kennari hefur <span v-if="schoolSettings.starfsmadur_br_bokalista === 0">ekki</span> heimild til að breyta námsgagnalista
          <b-link v-if="canEditSchool" :to="{ name: 'School', query: { page: 'settings', tab: 'staff' } }" target="_blank"
            >, smelltu hér til að breyta því</b-link
          >.
        </div>
        <div>
          Kennari hefur <span v-if="schoolSettings.ma_skra_gomul_lokaprof === 0">ekki</span> heimild til að skrá gömul lokapróf
          <b-link v-if="canEditSchool" :to="{ name: 'School', query: { page: 'settings', tab: 'staff' } }" target="_blank"
            >, smelltu hér til að breyta því</b-link
          >.
          <b-btn variant="primary" size="sm" class="float-right mb-2" v-if="canEditTerms" @click="openConnectTermSlidebar(id, courseName)"> 
            Bæta við kennslutímabili</b-btn>
        </div>    
      </b-col>
    </b-row>
    <table class="table" style="font-size: 14px">
      <thead>
        <tr>
          <th>
            <LinkOrder title="Kennslutímabil" :filteredList="list" :list="list" column="onn_eink" />
            ({{ list.length }})
          </th>
          <th>
            <LinkOrder title="Heiti" :filteredList="list" :list="list" column="onn_heiti" second_column="onn_eink" />
          </th>
          <th>
            <LinkOrder title="Undirskóli" :filteredList="list" :list="list" column="undirsk_audk" second_column="onn_eink" />
          </th>
          <th>
            <LinkOrder title="Dags. frá" :filteredList="list" :list="list" column="dags_fra" second_column="onn_eink" />
          </th>
          <th>
            <LinkOrder title="Dags. til" :filteredList="list" :list="list" column="dags_til" second_column="onn_eink" />
          </th>
          <th>
            <LinkOrder title="Fjöldi námsferli" :filteredList="list" :list="list" column="fj_namsferill" second_column="onn_eink" />
          </th>
          <th>
            <LinkOrder title="Fjöldi hópa" :filteredList="list" :list="list" column="fjoldi_hopa" second_column="onn_eink" />
          </th>
          <th>
            <LinkOrder title="Námsgagnalisti" :filteredList="list" :list="list" column="namsgagnalisti_count" second_column="onn_eink" />
          </th>
          <th>
            <LinkOrder title="Námsáætlun" :filteredList="list" :list="list" column="namsaetlun_count" second_column="onn_eink" />
          </th>
          <th>
            <LinkOrder title="Lokapróf" :filteredList="list" :list="list" column="lokaprof_count" second_column="onn_eink" />
          </th>
          <th>
            <LinkOrder title="Einkunnaregla" :filteredList="list" :list="list" column="fj_einkunnaregla" second_column="onn_eink" />
          </th>
          <th></th>
        </tr>
      </thead>
      <TableRowLoader v-if="loading" colspan="11" :center="true" />
      <TableRowEmpty v-if="!loading && this.list.length === 0" colspan="11" :center="true" />
      <tbody v-for="item in list" :key="item.onn_id">
        <tr>
          <td>{{ item.onn_eink }} 
            <b-link
            :to="{ name: 'Term', params: { id: item.onn_id } }"
            target="_blank"
            v-b-tooltip
            v-if="canReadTerms"
            title="Skoða kennslutímabil"
            ><i class="fa fa-external-link"></i
          ></b-link>
        </td>
          <td>{{ item.onn_heiti }}</td>
          <td>{{ item.undirsk_audk }}</td>
          <td>{{ item.dags_fra | moment('DD.MM.YYYY') }}</td>
          <td>{{ item.dags_til | moment('DD.MM.YYYY') }}</td>
          <td>
            <b-link @click="openStudentSlidebar(item)">
              {{ item.fj_namsferill }}
            </b-link>
          </td>
          <td class="cursor-pointer" @click="toggleShow(item)" title="Skoða hópa">
            {{ item.fjoldi_hopa }}
            <i class="fa text-primary" :class="{ 'fa-angle-up': item.showGroups, 'fa-angle-down': !item.showGroups }"></i>
          </td>
          <td>
            <b-link @click="openBookSlidebar(item)">
              {{ item.namsgagnalisti_count }}
            </b-link>
          </td>
          <td>
            <b-link @click="openAttachmentSlidebar(item, 'teachingplan')">
              {{ item.namsaetlun_count }}
            </b-link>
          </td>
          <td>
            <b-link @click="openAttachmentSlidebar(item, 'finals')">
              {{ item.lokaprof_count }}
            </b-link>
          </td>
          <td>
            {{ item.fj_einkunnaregla }}
            <b-link
              v-if="canReadTerms"
              :to="{ name: 'Term', params: { id: item.onn_id }, query: { page: 'graderules', module: item.annir_afangar_id } }"
            >
              <i class="fa fa-search"></i>
            </b-link>
          </td>
        </tr>
        <tr v-if="item.showGroups">
          <td colspan="100%" style="padding: 20px 30px !important; background-color: #f9f9f9">
            <div style="margin-top: -10px; margin-bottom: 10px">Hópar</div>
            <i class="fa fa-spin fa-spinner" v-if="loading"></i>
            <div v-if="item.hopar.length === 0 && !loading" style="font-size: 12px">Engir hópar fundust</div>
            <table class="table less-padding" v-if="item.hopar.length > 0" style="font-size: 12px">
              <tr style="text-transform: uppercase">
                <th>Hópur</th>
                <th>Fj. nemenda</th>
                <th>Kennari</th>
                <th v-if="!afangaskoli">Bekkur</th>
                <th v-if="!afangaskoli">Bekkjarhópur</th>
                <th>Dags. frá</th>
                <th>Dags. til</th>
                <th>Lýsing</th>
              </tr>
              <tr v-for="g in item.hopar" :key="g.hopur_id">
                <td>
                  <b-link :to="{ name: 'Group', params: { id: g.hopur_id } }"> {{ g.hopur }}</b-link>
                </td>
                <td>{{ g.fj_nema_i_hop }}</td>
                <td>{{ g.kennari }}</td>
                <td v-if="!afangaskoli">{{ g.bekkur_heiti }}</td>
                <td v-if="!afangaskoli">
                  <yes-no :item="g.bekkjarhopur"></yes-no>
                </td>
                <td>{{ g.dags_fra | moment('DD.MM.YYYY') }}</td>
                <td>{{ g.dags_til | moment('DD.MM.YYYY') }}</td>
                <td>{{ g.lysing }}</td>
              </tr>
            </table>
          </td>
        </tr>
      </tbody>
    </table>

    <book-usage-slidebar
      v-if="showUsageSlidebar && id"
      :large="true"
      :termId="selectedItem && selectedItem.onn_id"
      :moduleId="id"
      :edit="true"
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
    >
    </book-usage-slidebar>
    <module-attachment-slidebar
      v-if="showAttachmentSlidebar"
      :large="true"
      :termId="selectedItem && selectedItem.onn_id"
      :moduleId="id"
      :type="selectedItem && selectedItem.type"
      @closeSlidebar="closeAndReloadSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
    >
    </module-attachment-slidebar>
    <module-students-term-slidebar
      v-if="showStudentSlidebar && id"
      :large="true"
      :termCourseId="selectedItem.annir_afangar_id"
      :selectedTerm="selectedItem"
      :courseName="courseName"
      :edit="true"
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
    >
    </module-students-term-slidebar>
    <module-connect-term-slidebar
      v-if="showConnectTermSlidebar"
      :courseId="id"
      :courseName="courseName"
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
    >
    </module-connect-term-slidebar>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import curriculum from '@/api/curriculum';
import terms from '@/api/terms';

import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';

import BookUsageSlidebar from '@/components/school/school/types/BookUsageSlidebar.vue';
import ModuleAttachmentSlidebar from '@/components/curriculum/modules/ModuleAttachmentSlidebar.vue';
import ModuleStudentsTermSlidebar from '@/components/curriculum/modules/ModuleStudentsTermSlidebar.vue';
import ModuleConnectTermSlidebar from '@/components/curriculum/modules/ModuleConnectTermSlidebar.vue';

export default {
  name: 'module-terms',
  components: {
    TableRowLoader,
    TableRowEmpty,
    LinkOrder,
    BookUsageSlidebar,
    ModuleAttachmentSlidebar,
    ModuleStudentsTermSlidebar,
    ModuleConnectTermSlidebar,
  },
  props: ['id', 'courseName'],
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_afangar');
    },
    canEditSchool() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('namskra_afangar');
    },
    canReadTerms() {
      return this.loggedInUserHasReadPermission('skolahald_annir');
    },
    canEditTerms() {
      return this.loggedInUserHasWritePermission('skolahald_annir');
    },
    afangaskoli() {
      return this.schoolSettings && this.schoolSettings.afangaskoli ? this.schoolSettings.afangaskoli === 1 : false;
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {
      list: [],
      loading: false,
      show: {
        booklist: false,
        teachingplan: false,
        finals: false,
      },
      showUsageSlidebar: false,
      showAttachmentSlidebar: false,
      showStudentSlidebar: false,
      showConnectTermSlidebar: false,
      selectedItem: {},
      showGroups: false,
    };
  },
  methods: {
    canDelete(term) {
      const hasPassed = moment(term.dags_til).isBefore(moment());
      return this.canEditTerms && !hasPassed && term.fjoldi_hopa === 0 && term.fj_namsferill === 0 && term.fj_einkunnaregla === 0;
    },
    /* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsForRegex": ["^item"] }] */
    toggleShow(item) {
      item.showGroups = !item.showGroups;
    },
    async loadList() {
      try {
        this.loading = true;
        const response = await curriculum.getModuleTerms(this.id);
        this.list = response.data.items.map(x => ({
          ...x,
          namsgagnalisti_count: x.namsgagnalisti.length,
          namsaetlun_count: x.namsaetlun.length,
          lokaprof_count: x.lokaprof.length,
          showGroups: false,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    openBookSlidebar(term) {
      this.selectedItem.onn_id = term.onn_id;
      this.showUsageSlidebar = true;
      this.showAttachmentSlidebar = false;
      this.showStudentSlidebar = false;
      this.showConnectTermSlidebar = false;
    },
    openAttachmentSlidebar(term, type) {
      this.selectedItem.onn_id = term.onn_id;
      this.selectedItem.type = type;
      this.showAttachmentSlidebar = true;
      this.showUsageSlidebar = false;
      this.showStudentSlidebar = false;
      this.showConnectTermSlidebar = false;
    },
    openStudentSlidebar(item) {
      this.selectedItem = item;
      this.showStudentSlidebar = true;
      this.showAttachmentSlidebar = false;
      this.showUsageSlidebar = false;
      this.showConnectTermSlidebar = false;
    },
    openConnectTermSlidebar() {
      this.showConnectTermSlidebar = true;
      this.showStudentSlidebar = false;
      this.showAttachmentSlidebar = false;
      this.showUsageSlidebar = false;
    },
    closeSlidebar() {
      this.selectedItem = {};
      this.showUsageSlidebar = false;
      this.showAttachmentSlidebar = false;
      this.showStudentSlidebar = false;
      this.showConnectTermSlidebar = false;
    },
    closeAndReloadSlidebar() {
      this.closeSlidebar();
      this.loadList();
    },
    async deleteModuleTerm(item) {
      const conf = confirm(`Ert þú viss um að þú viljir taka áfangann úr boði á kennslutímabilinu ${item.onn_heiti}?`); // eslint-disable-line
      if (conf) {
        try {
          await terms.updateTermModule(item.annir_afangar_id, {
            delete: 1,
          });
          this.displaySuccess('Áfangi í boði eytt.');
          this.loadList();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadList();
  },
};
</script>
