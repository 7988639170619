<template>
  <Slidebar :large="true" @closeMe="closeSlidebar">
    <h4>Breyta mynd</h4>

    <div style="font-size: 16px" class="mb-2" v-if="item">{{ item.nafn }} kt.{{ item.kennitala }}</div>
    <b-row>
      <b-col class="pl-0">
        <div v-if="item.mynd">
          <img :src="`data:image/png;base64,${item.mynd}`" style="height: 200px" />
          <div class="cursor-pointer"><i @click="deletePhoto()">Eyða út mynd</i></div>
        </div>
        <div v-else class="imgBox" style="height: 200px; width: 200px">
          <div class="no-img-filter" style="height: 200px; width: 200px; line-height: 200px">?</div>
        </div>
      </b-col>
      <b-col>
        <b-img v-if="hasPhoto" :src="photoSrc" class="mb-3" style="height: 200px"></b-img>
        <b-form-file
          class="singleCourseFile"
          size="sm"
          accept="image/jpeg, image/png"
          v-model="photo"
          placeholder="Smelltu hér til að velja mynd.."
        ></b-form-file>
        <div style="padding-left: 5px">einungis .jpeg og .png myndir leyfðar</div>
        <b-btn variant="primary" class="mt-3" @click="savePhoto()" v-if="hasPhoto">Vista</b-btn>
      </b-col>
    </b-row>
  </Slidebar>
</template>

<script>
import common from '@/api/common';
import Slidebar from '@/components/common/Slidebar.vue';
import { mapActions, mapGetters } from 'vuex';

const base64Encode = data =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export default {
  name: 'change-photo-slidebar',
  props: ['userId'],
  components: {
    Slidebar,
  },
  computed: {
    hasPhoto() {
      return !!this.photo;
    },
    ...mapGetters([
      'loggedInUserHasReadPermission',
      'loggedInUserHasWritePermission',
      'schoolSettings',
      'schoolHasSettings',
      'loggedInUser',
    ]),
  },
  data() {
    return {
      photo: null,
      photoSrc: null,
      item: '',
    };
  },
  methods: {
    async loadData() {
      try {
        const response = await common.getPhoto(this.userId);
        this.item = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async deletePhoto() {
      try {
      const conf = confirm('Ertu viss um að þú viljir eyða út mynd?'); // eslint-disable-line
        if (conf) {
          await common.deletePhoto(this.userId);
          this.displaySuccess('Mynd eytt');
          this.closeAndReloadSlidebar();
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    closeAndReloadSlidebar() {
      this.$emit('closeAndReloadSlidebar');
    },
    async savePhoto() {
      try {
        await this.$photosApi.changePhoto({ userId: this.userId, photo: this.photo });
        this.displaySuccess('Mynd vistuð');
        this.closeAndReloadSlidebar();
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    photo(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.photoSrc = value;
            })
            .catch(() => {
              this.photoSrc = null;
            });
        } else {
          this.photoSrc = null;
        }
      }
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<style lang="scss"></style>
