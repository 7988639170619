<template>
  <Slidebar @closeMe="closeSlidebar" :extraLarge="true">
    <h4>Tengja áfanga á skírteini brautar {{ course ? ` - ${course.heiti} - ${course.braut_kodi}` : '' }}</h4>
    <b-row>
      <b-col class="pl-0">
        <Loader v-if="loading.studyRecord"></Loader>
        <table class="table no-border less-padding" v-if="studyRecord">
          <tr>
            <th width="30%">Áfangi:</th>
            <td :class="getStudyRecordColor(studyRecord.stada_afanga)">{{ studyRecord.namsgrein_afanganr }}</td>
          </tr>
          <tr>
            <th>Einingar</th>
            <td>{{ studyRecord.einingar }}</td>
          </tr>
          <tr>
            <th>Þrep</th>
            <td>{{ studyRecord.trep }}</td>
          </tr>
          <tr v-if="studyRecord.skoli_audk">
            <th>Skóli</th>
            <td>{{ studyRecord.skoli_audk }}</td>
          </tr>
          <tr>
            <th>Staða áfanga</th>
            <td>{{ studyRecord.stada_afanga_heiti }}</td>
          </tr>
          <tr>
            <th>Einingar á ferli</th>
            <td>{{ studyRecord.einingar_ferill }}</td>
          </tr>
          <tr>
            <th>Einkunn</th>
            <td>{{ studyRecord.einkunn }}</td>
          </tr>
        </table>
      </b-col>
      <b-col>
        <Loader v-if="loading.requirements"></Loader>
        <table class="table no-border less-padding" v-if="!loading.requirements">
          <tr>
            <th width="30%">Brautarkrafa</th>
            <td>
              <div v-if="requirement && requirement.afangi_id_braut">{{ requirement.afangi_brautar }}</div>
              <div v-else>
                <v-select v-model="requirement.moduleCourse" :clearable="true" :options="requirements"> </v-select>
                <div>
                  Nota brautarkröfu sem áfanga á skírteini:
                  <b-form-checkbox v-model="requirement.useRequirement" class="d-inline-block"></b-form-checkbox>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th width="30%">Uppbygging</th>
            <td>
              <v-select
                v-model="requirement.structure"
                :clearable="true"
                :options="structures"
                :disabled="
                  requirement.useRequirement ||
                  (requirement.moduleCourse && requirement.moduleCourse.afangi_id_braut > 0) ||
                  requirement.afangi_id_braut !== undefined
                "
              >
              </v-select>
            </td>
          </tr>
          <tr>
            <th width="30%" style="vertical-align: top">Áfangi á skírteini</th>
            <td>
              <v-select
                v-model="requirement.moduleCertificate"
                :clearable="true"
                :options="evaluation"
                label="namsgrein_afanganr"
                :disabled="requirement.useRequirement"
              >
              </v-select>
              <div>
                Nýr áfangi:
                <b-form-checkbox
                  v-model="requirement.newModule"
                  class="d-inline-block"
                  :disabled="requirement.useRequirement"
                ></b-form-checkbox>

                <v-select v-model="requirement.selectedModule" :clearable="true" :options="modules" v-if="requirement.newModule">
                </v-select>
                <div v-if="requirement.newModule">
                  Vista í möppunartöflu
                  <b-form-checkbox v-model="requirement.map" class="d-inline-block"></b-form-checkbox>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th>Einingar á skírteini</th>
            <td>
              {{ unitsCertificate }}
            </td>
          </tr>
          <tr>
            <th>Þrep á skírteini</th>
            <td>{{ stepCertificate }}</td>
          </tr>
        </table>
      </b-col>
    </b-row>
    <div v-if="!loading.requirements">
      <b-btn variant="primary" class="mt-3" @click="saveRequirements(false)" :disabled="saving">
        <i class="fa fa-spin fa-spinner" v-if="saving"></i> Vista
      </b-btn>
      <b-btn variant="danger" class="mt-3 ml-3" @click="saveRequirements(true)" :disabled="saving">
        <i class="fa fa-spin fa-spinner" v-if="saving"></i> Aftengja
      </b-btn>
    </div>
  </Slidebar>
</template>

<script>
import students from '@/api/students';
import curriculum from '@/api/curriculum';
import { mapActions, mapGetters } from 'vuex';
import Slidebar from '@/components/common/Slidebar.vue';
import Loader from '@/components/common/Loader.vue';

export default {
  name: 'study-record-course-connect-module-slidebar',
  props: ['studentId', 'course', 'moduleCertificateId', 'recordId', 'studentCourseId'],
  components: {
    Slidebar,
    Loader,
  },
  computed: {
    unitsCertificate() {
      if (this.requirement.useRequirement && this.requirement.moduleCourse) {
        return this.requirement.moduleCourse.einingar_skirteini;
      }
      if (this.requirement.newModule && this.requirement.selectedModule) {
        return this.requirement.selectedModule.einingar;
      }
      if (this.requirement.moduleCertificate) {
        return this.requirement.moduleCertificate.einingar;
      }
      return this.requirement.einingar_skirteini;
    },
    stepCertificate() {
      if (this.requirement.useRequirement && this.requirement.moduleCourse) {
        return this.requirement.moduleCourse.trep;
      }
      if (this.requirement.newModule && this.requirement.selectedModule) {
        return this.requirement.selectedModule.trep;
      }
      if (this.requirement.moduleCertificate) {
        return this.requirement.moduleCertificate.trep;
      }
      return this.requirement.trep_skirteini;
    },
    courseId() {
      return this.course ? this.course.braut_id : '';
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {
      saving: false,
      loading: {
        requirements: false,
        structures: false,
        modules: false,
        studyRecord: false,
        evaluation: false,
      },
      requirements: [],
      requirement: '',
      structures: [],
      modules: [],
      studyRecord: '',
      evaluation: [],
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async loadRequirements() {
      try {
        this.loading.requirements = true;
        const response = await students.getStudyRecordCourseModules(this.studentId, this.courseId);
        this.requirements = response.data.items
          .filter(x => x.afangi_id_braut && !x.stadid && !x.ovirk && (!x.stada_afanga || x.stada_afanga !== 1))
          .map(x => ({ ...x, label: `${x.afangi_brautar}${x.afangi_skirteini ? ` (${x.afangi_skirteini})` : ''}` }));

        await this.loadStructure();
        await this.loadDetails();
        if (this.moduleCertificateId) {
          const item = response.data.items.find(x => x.afangar_a_skirteini_id === this.moduleCertificateId);
          this.requirement = {
            ...item,
            moduleCourse: '',
            useRequirement: false,
            structure: this.structures.find(x => x.id === item.braut_uppbygg_id),
            moduleCertificate: '',
            newModule: false,
            selectedModule: '',
            map: false,
          };
          await this.loadEvaluation();
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.requirements = false;
      }
    },
    async loadStructure() {
      try {
        this.loading.structures = true;
        const response = await curriculum.getCourseStructureModules(this.courseId);
        this.structures = response.data.items
          .filter(x => x.tegund === 3)
          .map(x => ({
            id: x.braut_uppbygg_id,
            label: x.uppbygging_heiti,
            uppbygging: x.tegund,
          }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.structures = false;
      }
    },
    async loadEvaluation() {
      const response = await curriculum.getModuleEvaluation({courseId: this.courseId, moduleName: this.studyRecord.namsgrein_afanganr}); // eslint-disable-line

      const list = [];
      list.push({
        afangi_id: this.studyRecord.afangi_id,
        namsgrein_afanganr: `${this.studyRecord.namsgrein_afanganr}${
          this.studyRecord.skoli_audk ? ` - ${this.studyRecord.skoli_audk}` : ''
        }`,
        einingar: this.studyRecord.einingar,
        trep: this.studyRecord.trep,
      });
      if (this.requirement.afangi_id_braut && this.requirement.afangi_id_braut !== this.studyRecord.afangi_id) {
        list.push({
          afangi_id: this.requirement.afangi_id_braut,
          namsgrein_afanganr: this.requirement.afangi_brautar,
          einingar: this.requirement.einingar_skirteini,
          trep: this.requirement.trep_skirteini,
        });
      }

      this.evaluation = list.concat(
        response.data.items
          .filter(x => list.map(y => y.afangi_id).indexOf(x.afangi_id) === -1)
          .map(x => ({ afangi_id: x.afangi_id, namsgrein_afanganr: x.namsgrein_afanganr, einingar: x.einingar, trep: x.trep })),
      );

      let selectedModule = '';
      if (this.evaluation.find(x => x.afangi_id === this.requirement.afangi_id_skirteini)) {
        selectedModule = this.evaluation.find(x => x.afangi_id === this.requirement.afangi_id_skirteini);
      } else if (this.evaluation.length > 0) {
        selectedModule = this.evaluation[0];
      }
      this.$set(this.requirement, 'moduleCertificate', selectedModule);
    },
    async loadModules() {
      try {
        this.loading.modules = true;
        const response = await curriculum.getModuleList({ active: 1 });
        this.modules = response.data.items.map(x => ({
          id: x.afangi_id,
          label: `${x.namsgrein_afanganr} - ${x.undirsk_audk}`,
          einingar: x.einingar,
          trep: x.trep,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.modules = false;
      }
    },
    async loadDetails() {
      try {
        this.loading.studyRecord = true;
        const response = await students.getStudyRecordDetails(this.recordId);
        this.studyRecord = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.studyRecord = false;
      }
    },
    getStudyRecordColor(item) {
      if (item !== undefined) {
        if (item === 40) {
          // Áætlun
          return 'studentStudyRecordAetlun';
        }
        if (item === 41) {
          // Staðfest val
          return 'studentStudyRecordAetlun';
        }
        if (item === 1) {
          // Ólokið
          return 'studentStudyRecordOlokid';
        }
        if (item === 4 || item === 6) {
          // Metið + Lokið
          return 'studentStudyRecordLokid';
        }
        if (item === 2 || item === 3 || item === 8) {
          // Fall + Fall á önn + Mat Fall
          return 'studentStudyRecordFall';
        }
        if (item === 9 || item === 181) {
          // Lokið án eininga
          return 'studentStudyRecordLokidAnEininga';
        }
      }
      return '';
    },
    async saveRequirements(toDelete) {
      try {
        const conf = toDelete ? confirm('Ertu viss um að þú viljir aftengja?') : confirm('Ertu viss um að þú viljir breyta?'); // eslint-disable-line
        let moduleCertificate = '';
        if (this.requirement.useRequirement && this.requirement.moduleCourse) {
          moduleCertificate = this.requirement.moduleCourse.afangi_id_braut;
        } else if (this.requirement.newModule && this.requirement.selectedModule) {
          moduleCertificate = this.requirement.selectedModule.id;
        } else if (this.requirement.moduleCertificate) {
          moduleCertificate = this.requirement.moduleCertificate.afangi_id;
        }
        let structureType = '';
        let structureId = '';
        if (!this.requirement.useRequirement && !this.requirement.moduleCourse && this.requirement.structure) {
          structureType = this.requirement.structure.tegund;
          structureId = this.requirement.structure.id;
        } else if (this.requirement.moduleCourse) {
          structureType = this.requirement.moduleCourse.uppbygging;
          structureId = this.requirement.moduleCourse.braut_uppbygg_id;
        } else {
          structureType = this.requirement.uppbygging;
          structureId = this.requirement.braut_uppbygg_id;
        }

        const item = {
          modules: [
            {
              disconnect: toDelete ? 1 : undefined,
              inactive: 0,
              ferill_id: this.requirement.ferill_id,
              einingar_skirteini: this.unitsCertificate,
              trep_skirteini: this.stepCertificate,
              uppbygging: structureType,
              afangi_id_skirteini: moduleCertificate,
              afangi_id_braut: this.requirement.moduleCourse
                ? this.requirement.moduleCourse.afangi_id_braut
                : this.requirement.afangi_id_braut,
              braut_uppbygg_id: structureId,
              afangar_a_skirteini_id: this.requirement.afangar_a_skirteini_id,
              map: this.requirement.newModule && this.requirement.selectedModule && this.requirement.map ? 1 : 0,
            },
          ],
        };
        if (conf) {
          this.saving = true;
          await this.$studentsApi.changeCourseRequirements({
            changeCourseRequirementsInputItem: {
              studentId: this.studentId,
              courseId: this.courseId,
              studentCourseId: this.studentCourseId,
              ...item,
            },
          });

          this.$emit('closeAndReloadSlidebar');
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.saving = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadRequirements();
    this.loadModules();
  },
};
</script>

<style lang="scss" scoped></style>
