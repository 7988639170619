<template>
  <Slidebar @closeMe="closeSlidebar" :large="large" :extraLarge="extraLarge">
    <h4>Taka staðfest val af<loader v-if="loading"></loader></h4>
    <b-row v-if="terms.length > 0">
      <b-col class="pl-0">
        <b-form-group :state="saving && errors.has('selectedTerm') ? false : ''" :invalid-feedback="errors.first('onn')">
          <label for="selectedTerm"> Önn </label>
          <v-select id="onn" name="onn" v-model="selectedTerm" :options="terms" :clearable="true" label="onn_eink"> </v-select>
        </b-form-group>
      </b-col>
      <b-col class="pl-0">
        <div style="position: absolute; bottom: 9px">
          <b-btn variant="primary" size="sm" v-if="canEdit" @click="unconfirmTermSelection()" :disabled="saving">
            <i class="fa fa-spin fa-spinner" v-if="saving"></i>
            Taka staðfest val af
          </b-btn>
        </div>
      </b-col>
    </b-row>
    <div class="mt-3" v-if="terms.length === 0"><i>Engin önn fannst með staðfestu vali.</i></div>
    <i class="fa fa-spin fa-spinner" v-if="loadingData"></i>
  </Slidebar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Slidebar from '@/components/common/Slidebar.vue';
import Loader from '@/components/common/Loader.vue';
import terms from '@/api/terms';
import students from '@/api/students';

export default {
  name: 'unconfirm-selection-term-slidebar',
  props: ['studentId', 'large', 'extraLarge'],
  components: {
    Slidebar,
    Loader,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('nemendahald_nemendur');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      item: '',
      selectedTerm: '',
      term: [],
      terms: [],
      termSelection: [],
      loading: false,
      saving: false,
      loadingData: false,
    };
  },
  methods: {
    async loadTerms() {
      try {
        this.loading = true;
        this.firsttime = true;
        const params = {
          studentHasSelection: this.studentId,
          eval_terms: 0, // matsannir
        };
        const response = await terms.getTerms(params);
        this.terms = response.data.items;
        this.terms = response.data.items
          .filter(x => x.onn_id !== parseInt(this.id, 10))
          .map(x => ({
            ...x,
            label: `${x.onn_eink} - ${x.onn_heiti} - ${x.undirsk_heiti}`,
          }));
        if (this.terms.length > 0) {
          this.selectedTerm = this.terms[0];
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async unconfirmTermSelection() {
      try {
        this.saving = true;
        await students.unconfirmTermSelection(this.studentId, this.selectedTerm.onn_id);
        this.displaySuccess(`Staðfest val hefur verið tekið af.`);
        this.closeAndReloadSlidebar();
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.saving = false;
      }
    },
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    closeAndReloadSlidebar() {
      this.$emit('closeAndReloadSlidebar');
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadTerms();
  },
};
</script>
