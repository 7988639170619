<template>
  <Slidebar :autoClose="false" @closeMe="closeSlidebar" :large="large" :extraLarge="extraLarge">
    <h4>Lýsing áfanga</h4>
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <b-form-group
        label-for="language"
        label="Tungumál"
        :state="submitted && errors.has('language') ? false : ''"
        :invalid-feedback="errors.first('language')"
      >
        <v-select
          id="language"
          name="language"
          v-model="form.language"
          :options="languages"
          label="heiti_mals"
          :disabled="item.eink_mals !== undefined"
          :clearable="false"
          @input="updateDescription()"
        >
        </v-select>
      </b-form-group>
      <b-form-group
        label-for="description"
        label="Lýsing"
        :state="submitted && errors.has('description') ? false : ''"
        :invalid-feedback="errors.first('description')"
        v-if="form.language"
      >
        <quill-editor
          id="description"
          name="description"
          v-model="form.description"
          :state="submitted && errors.has('description') ? false : ''"
          ref="textQuillEditor"
          :options="editorOption"
          style="min-height: 300px"
        >
        </quill-editor>
      </b-form-group>
      <b-btn variant="primary" @click="validateBeforeSubmit()" v-if="form.language">Vista</b-btn>
      <b-btn variant="danger" @click="deleteDescription()" v-if="item && item.eink_mals" class="float-right">Eyða</b-btn>
    </b-form>
  </Slidebar>
</template>

<script>
import curriculum from '@/api/curriculum';
import common from '@/api/common';
import Slidebar from '@/components/common/Slidebar.vue';
import { mapGetters, mapActions } from 'vuex';

import { quillEditor } from 'vue-quill-editor';

export default {
  name: 'module-equivalent-slidebar',
  components: {
    Slidebar,
    quillEditor,
  },
  props: ['id', 'item', 'large', 'extraLarge'],
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_afangar');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('namskra_afangar');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      form: {
        description: '',
        language: '',
      },
      loading: {
        description: false,
        language: false,
      },
      descriptions: [],
      languages: [],
      submitted: false,
      saving: false,
      editorOption: {
        /* quill options */
      },
    };
  },
  methods: {
    updateDescription() {
      const description = this.descriptions.find(x => x.eink_mals === this.form.language.eink_mals);
      this.form.description = description ? description.lysing : '';
    },
    async loadLanguages() {
      this.loading.language = true;
      try {
        const response = await common.getLanguages();
        this.languages = response.data.items;
        if (this.item && this.item.eink_mals) {
          this.form.language = this.languages.find(x => x.eink_mals === this.item.eink_mals);
          this.loadDescriptions();
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.language = false;
      }
    },
    async loadDescriptions() {
      try {
        this.loading.description = true;
        const response = await curriculum.getModuleDescriptions(this.id);
        this.descriptions = response.data.items;
        this.updateDescription();
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.description = false;
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        try {
          await curriculum.setModuleDescription(this.id, { eink_mals: this.form.language.eink_mals, lysing: this.form.description });
          this.displaySuccess('Lýsingu vistuð.');
          this.closeSlidebar();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.saving = false;
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.saving = false;
      }
    },
    async deleteDescription() {
      const conf = confirm(`Ert þú viss um að eyða lýsingunni fyrir tungumálið: ${this.item.heiti_mals}?`); // eslint-disable-line
      if (conf) {
        try {
          await curriculum.setModuleDescription(this.id, { eink_mals: this.item.eink_mals, delete: 1 });
          this.displaySuccess('Lýsingu eytt.');
          this.closeSlidebar();
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadLanguages();
  },
};
</script>

<style lang="scss">
#description {
  .ql-container {
    height: 300px;
  }
}
</style>
