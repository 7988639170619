<template>
  <page>
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <PageTitle title="Pantanir á stofu" />
    <b-row>
      <b-col class="pl-0">
        <strong>Stofa</strong>
        <v-select
          id="selectClassroom"
          name="selectClassroom"
          v-model="selectedClassroom"
          :options="getClassrooms"
          @input="loadList"
          ref="selectClassroomDropdown"
          placeholder="Smelltu hér til að velja stofu"
        >
        </v-select>

        <div class="d-inline-block float-right">
          <div class="d-inline-block"><b-form-checkbox v-model="showInactive"></b-form-checkbox></div>
          <div class="d-inline-block pr-1" style="vertical-align: middle; margin-top: -7px">Sýna óvirkar stofur</div>
        </div>
      </b-col>
      <b-col class="pl-0">
        <div v-if="!showAsTimetable">
          <strong>Starfsmaður</strong>
          <v-select
            id="selectStaff"
            name="selectStaff"
            v-model="selectedStaff"
            :options="staff"
            @input="loadList"
            ref="selectStaffDropdown"
            placeholder="Smelltu hér til að velja starfsmann"
          >
          </v-select>
        </div>
      </b-col>
      <b-col>
        <b-btn
          variant="outline-dark"
          class="float-right"
          v-if="!showAsTimetable"
          @click="showAsTimetable = true"
          title="Skoða sem stundatöflu"
          ><i class="fa fa-calendar"
        /></b-btn>
        <b-btn
          variant="outline-dark"
          class="float-right"
          v-if="showAsTimetable"
          @click="
            showAsTimetable = false;
            loadList();
          "
          title="Skoða sem lista"
          ><i class="fa fa-list"
        /></b-btn>
        <b-btn variant="primary" class="float-right mr-2" title="Bæta við" @click="openSlidebar()" v-if="canEdit"
          ><i class="fa fa-plus-square"
        /></b-btn>
      </b-col>
    </b-row>
    <OrderList :classroom="selectedClassroom" :staff="selectedStaff" :load="reload" v-if="!showAsTimetable"></OrderList>
    <Timetable
      :id="selectedClassroom && selectedClassroom.stofa_id"
      type="classroom"
      :refetch="reload"
      :clickable="true"
      @dayClick="dayClick"
      @eventClick="eventClick"
      v-else
    ></Timetable>

    <classroom-order-slidebar
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="loadList"
      :item="slidebarItem"
      v-if="showOrderSlidebar"
      :large="true"
    >
    </classroom-order-slidebar>
  </page>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import staff from '@/api/staff';
import structure from '@/api/structure';

import { is } from 'vuejs-datepicker/dist/locale';
import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import PageTitle from '@/components/common/PageTitle.vue';
import Timetable from '@/components/common/Timetable.vue';
import OrderList from '@/components/requests/classrooms/OrderList.vue';
import ClassroomOrderSlidebar from '@/components/requests/classrooms/ClassroomOrderSlidebar.vue';

export default {
  name: 'order-classroom',
  components: {
    Breadcrumbs,
    PageTitle,
    Timetable,
    OrderList,
    ClassroomOrderSlidebar,
  },
  computed: {
    getTimetableData() {
      return {
        classroomId: this.selectedClassroom ? this.selectedClassroom.stofa_id : '',
        staffId: this.selectedStaff ? this.selectedStaff.starfsm_id : '',
      };
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('beidnir_stofa');
    },
    getClassrooms() {
      return this.classrooms.filter(x => this.showInactive || x.virk === 1);
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      reload: false,
      lang: is,
      breadcrumbs: [
        { name: 'Forsíða', route: 'Dashboard' },
        { name: 'Beiðnir', route: '' },
        { name: 'Pantanir á stofu', route: 'Classrooms' },
      ],
      classrooms: [],
      staff: [],
      list: [],
      loading: {
        classrooms: false,
        list: false,
        staff: false,
      },
      selectedClassroom: null,
      selectedStaff: null,
      showStaffSlidebar: false,
      showAsTimetable: false,
      showOrderSlidebar: false,
      slidebarItem: null,
      showInactive: false,
    };
  },
  methods: {
    closeSlidebar() {
      this.showOrderSlidebar = false;
    },
    loadList() {
      this.closeSlidebar();
      this.reload = true;
      setTimeout(() => {
        this.reload = false;
      }, 1000);
    },
    async loadClassrooms(id) {
      try {
        this.loading.classrooms = true;
        this.classrooms = [];
        const response = await structure.getClassroomList();
        this.classrooms = response.data.items.map(x => ({
          ...x,
          label: x.virkt === 0 ? `${x.stofa_eink} - ${x.stofa_heiti} - Óvirkt` : `${x.stofa_eink} - ${x.stofa_heiti}`,
        }));

        if (id) {
          this.selectedClassroom = this.classrooms.find(x => x.stofa_id === parseInt(id, 10));
          this.loadList();
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.classrooms = false;
      }
    },
    async loadStaff() {
      try {
        this.loading.staff = true;
        this.staff = [];
        const response = await staff.getStaffList();
        this.staff = response.data.items.map(x => ({ ...x, label: `${x.nafn} - ${x.skammst}` }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.staff = false;
      }
    },
    openSlidebar(item) {
      if (item) {
        this.slidebarItem = item;
      } else {
        this.slidebarItem = {
          classroomId: this.selectedClassroom ? this.selectedClassroom.stofa_id : '',
          staffId: this.selectedStaff ? this.selectedStaff.starfsm_id : '',
        };
      }
      this.showOrderSlidebar = true;
    },
    eventClick(item) {
      if (this.canEdit) {
        const data = {
          id: item && item.id ? item.id : '',
        };
        this.openSlidebar(data);
      }
    },
    dayClick(item) {
      const data = {
        classroomId: this.selectedClassroom ? this.selectedClassroom.stofa_id : '',
        date: moment(item.date).toDate(),
      };
      this.openSlidebar(data);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    const id = this.$route.query && this.$route.query.id ? this.$route.query.id : null;
    this.loadClassrooms(id);
    this.loadStaff();
    if (!id) {
      this.loadList();
    }
  },
};
</script>
<style lang="scss"></style>
