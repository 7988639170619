import Vue from 'vue';

const API_URL = '/api/staff';

export default {
  /**
   * Vefþjónusta til að sækja lista af starfsmönnum
   */
  getStaffList(params) {
    return Vue.http
      .get(`${API_URL}/list`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til að sækja upplýsingar um starfsmann
   */
  getStaffDetail(id) {
    return Vue.http
      .get(`${API_URL}/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til að sækja upplýsingar um aðgangshlutverk starfsmann
   */
  getStaffAccess(id) {
    return Vue.http
      .get(`${API_URL}/${id}/access`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til að uppfæra aðgang starfsmanns
   */
  updateStaffAccess(id, data) {
    return Vue.http
      .post(`${API_URL}/${id}/access`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til að sækja kennslugreinar starfsmanns
   */
  getStaffSubjects(id, term) {
    return Vue.http
      .get(`${API_URL}/${id}/subjects`, { params: { onn_id: term } })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til að sækja annir kennslugreinar starfsmanns
   */
  getStaffSubjectTerms(id) {
    return Vue.http
      .get(`${API_URL}/${id}/subject-terms`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til að sækja annir forfalla starfsmanns
   */
  getStaffAbsenceTerms(id) {
    return Vue.http
      .get(`${API_URL}/${id}/absences-terms`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til að sækja annir forfalla starfsmanns
   */
  getStaffAbsenceList(params) {
    return Vue.http
      .get(`${API_URL}/absence-list`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
    /**
   * Vefþjónusta til að sækja sundurliðun forfalla starfsmanna - listi
   */
    getStaffAbsenceListBreakdown(params) {
      return Vue.http
        .get(`${API_URL}/absence-list-breakdown`, { params })
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error));
    },  
   /**
   * Vefþjónusta til að sækja samantekt forfalla starfsmanna - listi
   */
    getStaffAbsenceListSummary(params) {
      return Vue.http
        .get(`${API_URL}/absence-list-summary`, { params })
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error));
    },  
  /**
   * Vefþjónusta til að sækja annir séróska starfsmanns
   */
  getStaffSpecialWishesTerms(id) {
    return Vue.http
      .get(`${API_URL}/${id}/special-wishes-terms`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til að sækja séróskir starfsmanns
   */
  getStaffSpecialWishes(id, term) {
    return Vue.http
      .get(`${API_URL}/${id}/special-wishes`, { params: { onn_id: term } })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til að sækja annir umsjónarnema starfsmanns
   */
  getStaffSupervisedStudentsTerms(id) {
    return Vue.http
      .get(`${API_URL}/${id}/supervised-terms`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til að sækja séróskir starfsmanns
   */
  getStaffSupervisedStudents(id, term) {
    return Vue.http
      .get(`${API_URL}/${id}/supervised-students/${term}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til að sækja notendahópa starfsmanns
   */
  getStaffUserGroups(id) {
    return Vue.http
      .get(`${API_URL}/${id}/user-groups`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til að sækja lista af aðgangsstýringum
   */
  getAccessList() {
    return Vue.http
      .get(`${API_URL}/access-list`, { params: { access_type: 0 } })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til að sækja lista af aðgangsstýringum
   */
  getAccessLmsList() {
    return Vue.http
      .get(`${API_URL}/access-list`, { params: { access_type: 1 } })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til að sækja lista af aðgangsstýringum eftir hlutverki
   */
  getAccessListByJob(id) {
    return Vue.http
      .get(`${API_URL}/access-list`, { params: { jobid: id } })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til að sækja lista af aðgangsstýringum eftir aðgangsstýringu
   */
  getAccessListByAccess(id) {
    return Vue.http
      .get(`${API_URL}/access-list`, { params: { accessid: id } })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til að sækja lista starfsmönnum með aðgang að ákveðnu hlutverki
   */
  getStaffAccessListByJob(id) {
    return Vue.http
      .get(`${API_URL}/access-job/${id}/staff`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til að sækja lista af aðgangsstýringum
   */
  updateJobAccess(jobId, accessId, access) {
    return Vue.http
      .post(`${API_URL}/access-list`, {
        styring_id: accessId,
        hlutverk_id: jobId,
        adgangur: access,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til að stofna/breyta hlutverki
   */
  updateJob(jobId, name, active, type) {
    const job = jobId || '';
    return Vue.http
      .post(`${API_URL}/access-job/${job}`, {
        heiti: name,
        virkt: active,
        tegund: type,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til að sækja annir fyrir vinnuskýrslur grunnskóla
   */
  getWorklogTermList() {
    return Vue.http
      .get(`${API_URL}/worklog-terms/`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til að sækja staka önn fyrir vinnuskýrslur grunnskóla
   */
  getWorklogTermDetail(id) {
    return Vue.http
      .get(`${API_URL}/worklog-terms/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til að sækja annir sem ekki er búið að stofna vinnuskýrslur grunnskóla fyrir
   */
  getWorklogTermUnusedList() {
    return Vue.http
      .get(`${API_URL}/worklog-unused-terms`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til að sækja starfsmannalista fyrir vinnuskýrslu annar
   */
  getWorklogTermStaffList(term) {
    return Vue.http
      .get(`${API_URL}/worklog-term/${term}/staff`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til að sækja starfsmann fyrir vinnuskýrslu annar
   */
  getWorklogTermStaffDetail(term, staff) {
    return Vue.http
      .get(`${API_URL}/worklog-term/${term}/staff/${staff}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  /**
   * Vefþjónusta til að stofna önn
   */
  createWorklogTerm(id, data) {
    return Vue.http
      .post(`${API_URL}/worklog-terms/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  /**
   * Vefþjónusta til að uppfæra vinnuskýrslu starfsmanns
   */
  updateWorklogTermStaffDetail(term, staff, data) {
    return Vue.http
      .post(`${API_URL}/worklog-term/${term}/staff/${staff}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createWorklogTermStaffPdf(term, staff) {
    return Vue.http
      .post(`${API_URL}/worklog-term/${term}/staff/${staff}/pdf/`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
};
