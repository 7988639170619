<template>
  <Slidebar @closeMe="closeSlidebar" :large="true">
    <h4>Breyta kennslustundum</h4>
    <Loader v-if="loading.info" />
    <b-form @submit.prevent="validateBeforeSubmit" novalidate v-if="!loading.info">
      <div class="mb-2">
        <strong
          >Kennslustundir sem á að breyta: {{ form.classes.length }}
          <i class="fa fa-search cursor-pointer" title="Skoða/fela færslur" @click="showClasses = !showClasses"> </i>
        </strong>
        <div class="text text-danger" v-if="form.classes.find(x => x.absence_registered)">
          Ath! Fjarvistarskráningar fundust fyrir einhverjar af völdum kennslustundum. <br />
          Sé kennslustundum eytt mun fjarvistunum einnig vera eytt.
        </div>
        <div v-if="showClasses" class="mb-1 mt-1">
          <table class="table less-padding" style="font-size: 13px">
            <tr>
              <th>Áfangi</th>
              <th>Dagsetning</th>
              <th>Tímasetning</th>
              <th>Vikudagur</th>
              <th>Stofa</th>
              <th>Kennari</th>
            </tr>
            <tr v-for="item in form.classes" :key="item.id">
              <td>
                {{ item.namsgrein_afanganr }}({{ item.hopur }})
                <i class="fa fa-exclamation-triangle text-danger" title="Fjarvistaskráningar fundust" v-if="item.absence_registered"></i>
              </td>
              <td>
                {{ item.dags }}
              </td>
              <td>{{ item.timi_fra }}-{{ item.timi_til }}</td>
              <td>
                {{ item.vikudagur }}
              </td>
              <td>{{ item.stofur }}</td>
              <td>
                <span v-for="(t, idx) in item.kennari" :key="t.starfsm_id" v-b-tooltip.hover :title="t.nafn">
                  <span v-if="idx != 0">, </span>{{ t.skammst }}</span
                >
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div class="mb-2">1. Hakaðu við hverju þú vilt breyta.</div>
      <table class="mb-0">
        <tr>
          <td>Kennari/ar</td>
          <td><b-form-checkbox v-model="edit.teacher"></b-form-checkbox></td>
        </tr>
        <tr>
          <td>Stofa</td>
          <td><b-form-checkbox v-model="edit.classroom"></b-form-checkbox></td>
        </tr>
        <tr>
          <td class="pr-3">Tímasetning</td>
          <td><b-form-checkbox v-model="edit.time"></b-form-checkbox></td>
        </tr>
        <tr>
          <td>Vikudagur</td>
          <td><b-form-checkbox v-model="edit.day"></b-form-checkbox></td>
        </tr>
      </table>
      <!--div class="d-inline-block pr-3">
        <div>Kennari/ar</div>
        <b-form-checkbox v-model="edit.teacher"></b-form-checkbox>
      </div>
      <div class="d-inline-block pr-3">
        <div>Stofa</div>
        <b-form-checkbox v-model="edit.classroom"></b-form-checkbox>
      </div>
      <div class="d-inline-block pr-3">
        <div>Tími</div>
        <b-form-checkbox v-model="edit.time"></b-form-checkbox>
      </div>
      <div class="d-inline-block pr-3">
        <div>Dagur</div>
        <b-form-checkbox v-model="edit.day"></b-form-checkbox>
      </div-->
      <br />
      <div class="mb-2 mt-2" v-if="isEditing">2. Veldu rétt gildi</div>
      <div v-if="edit.teacher">
        <strong>Kennari/ar</strong>
        <b-form-group
          label-for="teachers"
          :state="submitted && errors.has('teachers') ? false : ''"
          :invalid-feedback="errors.first('teachers')"
        >
          <v-select
            id="teachers"
            name="teachers"
            v-model="form.teachers"
            multiple
            :state="submitted && errors.has('teachers') ? false : ''"
            :options="teachers"
            data-vv-as="kennari"
            @input="validated = false"
          >
          </v-select>
        </b-form-group>
        <div>
          Fjarlægja núverandi kennara af völdum færslum <b-form-checkbox class="d-inline-block" v-model="form.removeOtherTeachers" />
        </div>
      </div>
      <div v-if="edit.classroom">
        <strong>Stofa</strong>
        <b-form-group
          label-for="classrooms"
          :state="submitted && errors.has('classrooms') ? false : ''"
          :invalid-feedback="errors.first('classrooms')"
        >
          <v-select
            id="classrooms"
            name="classrooms"
            v-model="form.classrooms"
            multiple
            :state="submitted && errors.has('classrooms') ? false : ''"
            :options="classrooms"
            @input="validated = false"
          >
          </v-select>
        </b-form-group>
        <div>
          Fjarlægja núverandi stofur af völdum færslum <b-form-checkbox class="d-inline-block" v-model="form.removeOtherClassrooms" />
        </div>
      </div>
      <div v-if="edit.time">
        <strong class="d-block">Tími</strong>
        <b-form-group
          label="Tími frá"
          label-for="timeFrom"
          :state="submitted && errors.has('timeFrom') ? false : ''"
          :invalid-feedback="errors.first('timeFrom')"
          class="d-inline-block mr-3"
        >
          <masked-input
            id="timeFrom"
            name="timeFrom"
            v-model="form.timeFrom"
            mask="11:11"
            placeholder="hh:mm"
            data-vv-as="tími frá"
            style="padding: 2px 2px 2px 4px; width: 60px; height: 35px; border: 1px solid #ced4da"
            :state="submitted && errors.has('timeFrom') ? false : ''"
            v-validate="{ required: edit.time, regex: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/ }"
          ></masked-input>
        </b-form-group>
        <b-form-group
          label="Tími til"
          label-for="timeTo"
          :state="submitted && errors.has('timeTo') ? false : ''"
          :invalid-feedback="errors.first('timeTo')"
          class="d-inline-block"
        >
          <masked-input
            id="timeTo"
            name="timeTo"
            v-model="form.timeTo"
            mask="11:11"
            placeholder="hh:mm"
            data-vv-as="tími til"
            style="padding: 2px 2px 2px 4px; width: 60px; height: 35px; border: 1px solid #ced4da"
            :state="submitted && errors.has('timeTo') ? false : ''"
            v-validate="{ required: edit.time, regex: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/ }"
          ></masked-input>
        </b-form-group>
      </div>
      <div v-if="edit.day">
        <strong class="d-block">Fjöldi daga sem á að færa kennslustundir fram um</strong>
        <i
          >Leyfilegt að bæta við 1-6 dögum. Ef tími er á þriðjudegi og 2 dögum bætt við mun tíminn vera á sama tíma en bara tveim dögum
          seinna (fimmtudegi)</i
        >
        <i class="d-block">
          Ath. Helgar eru inni í fjöldanum. Þannig ef færa á kennslustund frá föstudegi yfir á mánudag þá þarf að bæta 3 dögum við.
        </i>
        <b-form-group label-for="days" :state="submitted && errors.has('days') ? false : ''" :invalid-feedback="errors.first('days')">
          <b-form-input
            id="days"
            name="days"
            v-model="form.days"
            :state="submitted && errors.has('days') ? false : ''"
            v-validate="{ required: edit.day, regex: /^[0-6]*$/ }"
            data-vv-as="fj. daga"
            style="width: 100px"
            type="number"
            min="0"
            max="7"
          >
          </b-form-input>
        </b-form-group>
      </div>
    </b-form>
    <div class="mt-3"><strong v-if="form.validation.length > 0">Árekstrar</strong></div>
    <table class="table less-padding no-border" v-if="form.validation.length > 0">
      <tbody v-for="(date, index) in form.validation" :key="index">
        <tr>
          <td>
            {{ date.dags }}
          </td>
          <td>
            {{ date.fra }}
          </td>
          <td>
            {{ date.til }}
          </td>
        </tr>
        <tr v-if="!date.validation.can_book">
          <td colspan="5">
            <div class="alert alert-danger mb-0" style="font-size: 13px; padding: 10px">
              <div v-if="!date.validation.hopur.availability.available">Hópaárekstur: Hópurinn er nú þegar bókaður á þessum tíma.</div>
              <div v-if="!date.validation.hopur.onn.date_in_range">
                Dagsetning er ekki innan gildistísma hóps. Vinsamlegast veldu á milli
                <strong> {{ date.validation.hopur.onn.from }} </strong> og <strong>{{ date.validation.hopur.onn.to }}</strong>
              </div>
              <div v-if="date.validation.stofa && date.validation.stofa.filter(x => !x.classroom_size_ok).length > 0">
                <div v-for="(stofa, idx) in date.validation.stofa.filter(x => !x.classroom_size_ok)" :key="idx">
                  <div>Eftirfarandi stofa er of lítil: {{ stofa.short_name }}</div>
                  <div>
                    Fjöldi í hóp er: <strong> {{ date.validation.hopur.number_of_students }}</strong
                    >.
                  </div>
                  <div>
                    Hámarksfjöldi í stofu er: <strong> {{ stofa.max_students }}</strong
                    >.
                  </div>
                </div>
              </div>
              <div v-if="!date.validation.bookings.available">
                Stofupantanir:
                <span v-for="(clash, idx) in date.validation.bookings.clashes" :key="idx">
                  <span v-if="idx != 0">, </span><strong>{{ clash.starfsm_nafn }} - {{ clash.skyring }}</strong> {{ clash.timi_fra }}-{{
                    clash.timi_til
                  }}</span
                >
              </div>
              <div v-if="!date.validation.availability.available">
                Stofuárekstur:
                <span v-for="(clash, idx) in date.validation.availability.clashes" :key="idx">
                  <span v-if="idx != 0">, </span><strong>{{ clash.afanga_nr }}({{ clash.hopur }})</strong> {{ clash.timi_fra }}-{{
                    clash.timi_til
                  }}
                </span>
              </div>
              <div v-for="(t, idx) in date.validation.teachers.filter(x => !x.available)" :key="idx">
                Kennaraárekstur: <strong>{{ t.nafn }} {{ t.skammst }}</strong
                >.
                <span v-for="(clash, idx2) in t.clashes" :key="idx2"
                  ><span v-if="idx2 != 0">, </span> {{ clash.heiti }} {{ clash.timi_fra }}-{{ clash.timi_til }}</span
                >
              </div>
              <div v-for="(t, idx) in date.validation.teachers.filter(x => x.locked)" :key="idx">
                Kennaralæsing: <strong>{{ t.nafn }} {{ t.skammst }}</strong
                >.
                <span v-for="(locks, idx2) in t.locks" :key="idx2"
                  ><span v-if="idx2 != 0">, </span> <strong>{{ locks.lysing }}</strong> {{ locks.timi_fra }}-{{ locks.timi_til }}</span
                >
              </div>
              <div v-if="date.validation.locks.classroom_locked">
                Stofulæsing:
                <span v-for="(clash, idx) in date.validation.locks.classroom" :key="idx"
                  ><span v-if="idx != 0">, </span> <strong>{{ clash.lysing }}</strong> {{ clash.timi_fra }}-{{ clash.timi_til }}</span
                >
              </div>
              <div v-if="date.validation.locks.semester_locked">
                Stofulæsing:
                <span v-for="(clash, idx) in date.validation.locks.semester" :key="idx"
                  ><span v-if="idx != 0">, </span> <strong>{{ clash.lysing }}</strong> {{ clash.timi_fra }}-{{ clash.timi_til }}</span
                >
              </div>
              <div v-if="date.validation.holidays.length > 0">
                Frídagar:
                <span v-for="(clash, idx) in date.validation.holidays" :key="idx"
                  ><span v-if="idx != 0">, </span> <strong>{{ clash.heiti }}</strong> {{ clash.timi_fra
                  }}<span v-if="clash.timi_til">-{{ clash.timi_til }}</span></span
                >
              </div>

              <div v-if="date.validation.student_clashes.length > 0">
                Nemendaárekstur:
                <span v-for="(clash, idx) in date.validation.student_clashes" :key="idx">
                  <span v-if="idx != 0">, </span><strong>{{ clash.nafn }}</strong> {{ clash.heiti }}
                </span>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <b-btn variant="secondary" class="mr-2" @click="validateBeforeSubmit()" :disabled="deleting || saving || !isEditing">
      {{ this.validated ? 'Breyta' : 'Athuga árekstra' }}
    </b-btn>
    <b-btn variant="primary" @click="closeSlidebar" :disabled="deleting || saving">Hætta við</b-btn>
    <b-btn variant="danger" class="float-right" @click="deleteClass()" :disabled="deleting || saving">
      <i class="fa fa-spinner fa-spin" v-if="deleting" />
      Eyða kennslustundum
    </b-btn>
  </Slidebar>
</template>

<script>
import moment from 'moment';
import staff from '@/api/staff';
import school from '@/api/school';
import structure from '@/api/structure';
import { mapActions } from 'vuex';
// import moment from 'moment';

import Slidebar from '@/components/common/Slidebar.vue';
import Loader from '@/components/common/Loader.vue';
import MaskedInput from 'vue-masked-input';

export default {
  name: 'mass-edit-timetable-slidebar',
  props: ['items'],
  components: {
    Slidebar,
    Loader,
    MaskedInput,
  },
  computed: {
    isEditing() {
      return Object.values(this.edit).filter(x => x === true).length > 0;
    },
  },
  data() {
    return {
      submitted: false,
      validated: false,
      deleting: false,
      saving: false,
      showClasses: false,
      classrooms: [],
      teachers: [],
      form: {
        validation: [],
        classes: [],
        teachers: [],
        removeOtherTeachers: false,
        classrooms: [],
        removeOtherClassrooms: false,
        days: 0,
        timeFrom: '',
        timeTo: '',
      },
      edit: {
        teacher: false,
        classroom: false,
        time: false,
        day: false,
      },
      loading: {
        info: false,
        teachers: false,
        classrooms: false,
      },
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async loadData() {
      try {
        this.loadTeachers();
        this.loadClassrooms();
        this.form.classes = JSON.parse(JSON.stringify(this.items));
        for (let i = 0; i < this.form.classes.length; i += 1) {
          const response = await school.checkTimetableAbsenceExists(this.form.classes[i].tafla_id); // eslint-disable-line
          this.$set(this.form.classes[i], 'absence_registered', response.data.absence_registered === 1);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.info = false;
      }
    },
    async loadTeachers() {
      this.loading.teachers = true;
      try {
        const response = await staff.getStaffList();
        this.teachers = response.data.items.map(x => ({
          ...x,
          label: `${x.nafn} - ${x.skammst}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.teachers = false;
      }
    },
    async loadClassrooms() {
      this.loading.classrooms = true;
      try {
        const response = await structure.getClassroomList({ active: 1 });
        this.classrooms = response.data.items.map(x => ({
          ...x,
          label: `${x.stofa_eink} - ${x.stofa_heiti}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.classrooms = false;
      }
    },
    async deleteClass() {
      /* eslint-disable no-alert, no-restricted-globals, max-len */
      const conf1 = confirm(
        `Ertu alveg viss um að þú viljir eyða þessum ${this.form.classes.length} kennslustundum? Þessa aðgerð er ekki hægt að afturkalla.`,
      );
      if (conf1) {
        let conf = true;
        if (this.form.classes.find(x => x.absence_registered)) {
          conf = confirm(
            'Ath! Það er til fjarvistaskráning fyrir einhverja af völdum kennslustundum. Við það að eyða kennslustund verður þeim einnig eytt. Ertu viss um að þú viljir eyða?',
          );
        }
        /* eslint-enable no-alert, no-restricted-globals, max-len */

        if (conf) {
          try {
            this.deleting = true;
            for (let i = 0; i < this.form.classes.length; i += 1) {
            await school.deleteTimetableRecord(this.form.classes[i].tafla_id); // eslint-disable-line
            }
            this.displaySuccess('Færslum hefur verið eytt');
            this.$emit('closeSlidebarAndReload');
          } catch (e) {
            this.$log.error(e);
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          } finally {
            this.deleting = false;
          }
        }
      }
    },
    async validate() {
      try {
        this.validating = true;
        this.form.validation = [];
        for (let i = 0; i < this.form.classes.length; i += 1) {
          const c = this.form.classes[i];
          const classrooms = [];
          const teachers = [];
          // Ef stofa nú þegar skráð og það á ekki að taka allar stofur af setjum við núverandi stofur í listann
          if (c.stofur_id && !(this.edit.classroom && this.form.removeOtherClassrooms)) {
            classrooms.push(c.stofur_id.split(',').map(x => Number(x)));
          }
          // Ef breyta stofum þá bætum við við þeim stofum sem voru valdar
          if (this.edit.classroom && this.form.classrooms.length > 0) {
            classrooms.push(this.form.classrooms.map(x => x.stofa_id));
          }
          // Ef kennari nú þegar skráður og það á ekki að taka allar kennara af setjum við núverandi kennara í listann
          if (c.kennari.length > 0 && !(this.edit.teacher && this.form.removeOtherTeachers)) {
            teachers.push(c.kennari.map(x => x.starfsm_id));
          }
          // Ef breyta kennara þá bætum við við þeim kennara sem voru valdar
          if (this.edit.teacher && this.form.teachers.length > 0) {
            teachers.push(this.form.teachers.map(x => x.starfsm_id));
          }
          const item = {
            id: c.tafla_id,
            stofur: classrooms.join(','),
            teachers: teachers.join(','),
            hopur: c.hopur_id,
            dags: this.edit.day ? moment(c.dags, 'DD.MM.YYYY').add(this.form.days, 'days').format('DD.MM.YYYY') : c.dags,
            fra: this.edit.time ? this.form.timeFrom : c.timi_fra,
            til: this.edit.time ? this.form.timeTo : c.timi_til,
          };
          const response = await school.validateTimetable(item); // eslint-disable-line

          this.form.validation.push({
            ...item,
            validation: response.data,
            copy: response.data.can_book,
          });
        }
        this.validated = true;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.validating = false;
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        let conf = true;
        if (!this.validated) {
          this.validate();
        } else {
          if (this.edit.teacher && this.form.removeOtherTeachers && this.form.teachers.length === 0) {
          conf = confirm( // eslint-disable-line
              'Ath! Þú ert ekki með neinn kennara valinn og ert að fara að hreinsa núverandi kennara af kennslustundunum. Ertu alveg viss um að þú viljir vista?', // eslint-disable-line max-len
            );
          }
          if (this.edit.classroom && this.form.removeOtherClassrooms && this.form.classrooms.length === 0) {
          conf = confirm( // eslint-disable-line
              'Ath! Þú ert ekki með neina stofu valda og ert að fara að hreinsa núverandi stofu af kennslustundunum. Ertu alveg viss um að þú viljir vista?', // eslint-disable-line max-len
            );
          }

          if (conf) {
            try {
              this.saving = true;
              for (let i = 0; i < this.form.classes.length; i += 1) {
                const time = this.form.classes[i];
                // Vista tímann
                const item = {
                  dags: this.edit.day ? moment(time.dags, 'DD.MM.YYYY').add(this.form.days, 'days').format('DD.MM.YYYY') : time.dags,
                  fra: this.edit.time ? this.form.timeFrom : time.timi_fra,
                  til: this.edit.time ? this.form.timeTo : time.timi_til,
                  stofur: this.edit.classroom ? this.form.classrooms.map(x => x.stofa_id).join(',') : '',
                  removeOthers: this.edit.classroom && this.form.removeOtherClassrooms ? 1 : 0,
                };
            await school.editTimetableRecord(time.tafla_id, item); // eslint-disable-line
                // Vista kennara
                if (this.edit.teacher) {
                  const data = {
                    teachers: this.form.teachers.map(x => x.starfsm_id).join(','),
                    removeOthers: this.form.removeOtherTeachers ? 1 : 0,
                  };
              await school.editTimetableRecordTeachers(time.tafla_id, data); // eslint-disable-line
                }
              }
              this.displaySuccess('Færslur hefur verið breytt');
              this.$emit('closeSlidebarAndReload');
            } catch (e) {
              // Aðgerð mistókst.
              this.failed = true;
              this.$log.debug(e);
              this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            } finally {
              this.saving = false;
            }
          }
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.saving = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    id() {
      this.loadData();
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<style lang="scss"></style>
