<template>
  <Slidebar :autoClose="true" @closeMe="closeSlidebar" :large="large" :extraLarge="extraLarge">
    <h4>Áfangaleit</h4>
    <br />
    <ModuleSearch @changeModule="closeSlidebar" :slidebar="true" />
  </Slidebar>
</template>

<script>
import Slidebar from '@/components/common/Slidebar.vue';
import ModuleSearch from '@/components/curriculum/modules/ModuleSearch.vue';

export default {
  name: 'module-search-slidebar',
  components: {
    Slidebar,
    ModuleSearch,
  },
  props: ['large', 'extraLarge'],
  methods: {
    closeSlidebar(item) {
      this.$emit('closeSlidebar', item);
    },
  },
};
</script>

<style lang="scss"></style>
