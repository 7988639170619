<template>
  <div>
    <Loader v-if="loading"></Loader>
    <GChart
      type="OrgChart"
      :data="chartData"
      :options="chartOptions"
      :settings="{ packages: ['orgchart'] }"
      :events="chartEvents"
      @ready="onChartReady"
      v-if="list.length > 0"
      ref="gChart"
    />
    <grade-rule-edit-slidebar
      :item="selectedItem"
      :termModuleId="termModuleId"
      :groupId="groupId"
      :finalGradeId="finalGradeId"
      v-if="showEditSlidebar"
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
    ></grade-rule-edit-slidebar>
  </div>
</template>

<script>
import terms from '@/api/terms';
import groups from '@/api/groups';
import { mapActions, mapGetters } from 'vuex';
import { GChart } from 'vue-google-charts';
import GradeRuleEditSlidebar from '@/components/school/terms/graderules/GradeRuleEditSlidebar.vue';
import Loader from '@/components/common/Loader.vue';

export default {
  name: 'graderule-graph',
  props: ['termModuleId', 'groupId', 'reload', 'edit'],
  components: {
    GChart,
    GradeRuleEditSlidebar,
    Loader,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_annir');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolHasSettings']),
  },
  data() {
    return {
      loading: false,
      list: [],
      selectedItem: null,
      finalGradeId: null,
      showEditSlidebar: false,
      chartData: null,
      chartOptions: {
        allowHtml: true,
        nodeClass: 'GradeRuleNodeClass',
        selectedNodeClass: 'GradeRuleSelectedNodeClass',
      },
      chartEvents: {
        select: () => {
          const table = this.$refs.gChart.chartObject;
          const selection = table.getSelection();
          if (selection.length > 0) {
            const item = selection[0];
            const gradeType = this.chartData.getValue(item.row, 0);
            this.editGraph(gradeType);
          }
        },
      },
    };
  },
  methods: {
    editGraph(gradeType) {
      if (this.edit && this.canEdit) {
        this.selectedItem = this.termModuleId
          ? this.list.find(x => x.tegund_einkunnar_id === parseInt(gradeType, 10))
          : this.list.find(x => x.einkunnir_hops_id === parseInt(gradeType, 10));
        this.showEditSlidebar = true;
      }
    },
    closeSlidebar() {
      this.showEditSlidebar = false;
      this.selectedItem = null;
    },
    async closeAndReloadSlidebar() {
      this.closeSlidebar();
      this.$emit('reloadOthers');
      this.loadRule();
    },
    async loadRule() {
      try {
        let response = null;
        this.list = [];
        this.loading = true;
        if (this.termModuleId) {
          response = await terms.getTermModuleGraderule(this.termModuleId);
          this.finalGradeId = response.data.items.find(x => x.lokaeinkunn);
        } else if (this.groupId) {
          response = await groups.getGroupGradeRule(this.groupId);
        }
        if (response) {
          this.list = response.data.items;
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    getSubgradesWeight(item) {
      let subgrades = this.list.filter(x => x.vegur_upp_i === (this.groupId ? item.einkunnir_hops_id : item.tegund_einkunnar_id));
      if (item.fj_bestu_undirpr) {
        // Útaf einkunnareglu áfanga þarf að raða í báðar áttir eftir stærð til að
        // ganga úr skugga um að það séu örugglega ekki vitlausar prósentur
        subgrades = subgrades
          .sort((a, b) => {
            return b.vaegi - a.vaegi;
          })
          .filter((x, idx) => idx < item.fj_bestu_undirpr);
        // Ef stært í lítið er 100, athugum við hvort það sé líka í öfuga átt
        if (Number(Math.round(subgrades.map(x => x.vaegi).reduce((a, b) => a + b, 0)) === 100)) {
          subgrades = subgrades
            .sort((a, b) => {
              return a.vaegi - b.vaegi;
            })
            .filter((x, idx) => idx < item.fj_bestu_undirpr);
        }
      }
      const sum = subgrades.map(x => x.vaegi).reduce((a, b) => a + b, 0);
      return subgrades.length > 0 ? Number(Math.round(sum)) : 100;
    },
    subgradesInvalid(item) {
      return this.getSubgradesWeight(item) !== 100;
    },
    onChartReady(chart, google) {
      this.createDataTable(google);
    },
    createDataTable(google) {
      const data = new google.visualization.DataTable();
      data.addColumn('string', 'Name');
      data.addColumn('string', 'Manager');
      data.addColumn('string', 'ToolTip');
      data.addRows(this.list.length);
      for (let i = 0; i < this.list.length; i += 1) {
        const item = this.list[i];
        let tooltip = '';
        let weight = '';
        if (item.vaegi) {
          weight = `${item.vaegi}%`;
          tooltip = `Vægi: ${item.vaegi}%`;
          if (item.vaegi !== item.vaegi_uppreiknad) {
            weight = `${weight} (${item.vaegi_uppreiknad}%)`;
            tooltip = `${tooltip}\nUppreiknað vægi: ${item.vaegi_uppreiknad}%`;
          }
          weight = `${weight}<br/>`;
          tooltip = `${tooltip}\n`;
        }
        let type = '';
        if ((this.termModuleId && item.teg_einkunnar === 0) || (this.groupId && item.reiknud_skrad === 0)) {
          type = '<i class="fa fa-calculator"></i>';
          tooltip = `${tooltip}Einkunn: Reiknuð`;
        } else {
          type = '<i class="fa fa-pencil"></i>';
          tooltip = `${tooltip}Einkunn: Skráð`;
        }
        const id = this.termModuleId ? item.tegund_einkunnar_id : item.einkunnir_hops_id;
        let name = item.heiti;
        if (this.termModuleId) {
          name = `${name} <br/> ${item.einkunnaskali}`;
        }
        let color = '';
        if (this.subgradesInvalid(item)) {
          color = 'style="color: #ff0000"';
          tooltip = `${tooltip}\nAth. Vægi undireinkunna ekki 100%`;
        }
        data.setCell(i, 0, `${id}`, `<span ${color} >${name} <br/> ${weight} ${type}</span>`);
        data.setCell(i, 1, item.vegur_upp_i ? `${item.vegur_upp_i}` : '');
        data.setCell(i, 2, tooltip);
      }
      this.chartData = data;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    reload() {
      if (this.reload) {
        this.loadRule();
      }
    },
    termModuleId() {
      this.loadRule();
    },
    groupId() {
      this.loadRule();
    },
  },
  created() {
    this.loadRule();
  },
};
</script>
<style lang="scss">
.GradeRuleNodeClass {
  text-align: center;
  vertical-align: middle;
  font-family: arial, helvetica;
  cursor: default;
  border: none;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.5) 3px 3px 3px;
  -moz-box-shadow: rgba(0, 0, 0, 0.5) 3px 3px 3px;
  background-color: #edf7ff;
  background: -webkit-gradient(linear, left top, left bottom, from(#edf7ff), to(#cde7ee));
}
.GradeRuleSelectedNodeClass {
  border: none;
  background-color: #fff7ae;
  background: -webkit-gradient(linear, left top, left bottom, from(#fff7ae), to(#eee79e));
}
</style>
