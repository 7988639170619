<template>
  <Slidebar @closeMe="closeSlidebar" :large="true">
    <h4>Flokkar</h4>
    <Loader v-if="loading" />
    <table class="table no-border mt-3">
      <tr v-for="(item, index) in list" :key="index">
        <td>
          <inline-edit :doEdit="item.edit" @editField="toggleEdit(item)" @saveField="saveField(item)">
            <span slot="content">
              {{ item.flokkur_nafn }}
            </span>
            <span slot="input">
              <b-form-input v-model="item.newName" :id="`item${index}`" :name="`item${index}`" />
            </span>
          </inline-edit>
        </td>
      </tr>
      <tr>
        <td>
          <b-input-group>
            <b-form-input v-model="newName"></b-form-input>
            <b-input-group-append>
              <b-button variant="primary" @click="saveField()"><i class="fa fa-save" /></b-button>
            </b-input-group-append>
          </b-input-group>
        </td>
      </tr>
    </table>
  </Slidebar>
</template>

<script>
import singleClasses from '@/api/singleClasses';
import { mapActions } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';
import Loader from '@/components/common/Loader.vue';
import InlineEdit from '@/components/common/InlineEdit.vue';

export default {
  name: 'singleclass-category-slidebar',
  components: {
    Slidebar,
    Loader,
    InlineEdit,
  },
  data() {
    return {
      list: [],
      loading: false,
      newName: '',
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async load() {
      try {
        this.list = [];
        this.loading = true;
        const response = await singleClasses.getCategories();
        this.list = response.data.items.map(x => ({
          ...x,
          edit: false,
          newName: x.flokkur_nafn,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    toggleEdit(item) {
      this.$set(item, 'edit', !item.edit);
      this.$set(item, 'newName', item.flokkur_nafn);
    },
    async saveField(item) {
      try {
        const data = {};
        if (item) {
          data.id = item.namskeid_flokkur_id;
          data.name = item.newName;
        } else {
          data.name = this.newName;
        }
        await singleClasses.saveCategory(data);
        this.newName = '';
        this.load();
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    item() {
      this.load();
    },
  },
  created() {
    this.load();
  },
};
</script>

<style lang="scss"></style>
