<template>
  <div>
    <table class="table" style="font-size: 13px">
      <thead>
        <tr class="table-search">
          <td :colspan="slidebar ? 1 : 6" :class="{ 'pb-0': detailedSearch }">
            <b-form-input
              v-model="search.search"
              class="search"
              v-on:keyup.enter="loadList"
              placeholder="Leita eftir braut"
              ref="focusInput"
            ></b-form-input>
          </td>
          <td :colspan="slidebar ? 1 : 4" :class="{ 'pb-0': detailedSearch }">
            <b-btn variant="primary" class="w-100" style="margin-top: 25px" @click="loadList">Leita</b-btn>
            <div class="cursor-pointer text-right pt-2" @click="detailedSearch = !detailedSearch">
              <span v-if="detailedSearch"><i class="fa fa-angle-up"></i> Fela ítarleit</span>
              <span v-else><i class="fa fa-angle-down"></i> Sýna ítarleit</span>
            </div>
          </td>
        </tr>
        <tr v-if="detailedSearch" class="table-detail-search">
          <td colspan="100%">
            <b-row>
              <b-col :cols="slidebar ? 4 : 3" class="pl-0">
                <b-form-group label="Deild" label-for="department">
                  <v-select id="department" name="department" v-model="search.department" :options="departments" :clearable="true">
                  </v-select>
                </b-form-group> </b-col
              ><b-col :cols="slidebar ? 4 : 3">
                <b-form-group label="Undirskóli" label-for="division">
                  <v-select id="division" name="division" v-model="search.division" :options="divisions" :clearable="true"> </v-select>
                </b-form-group>
              </b-col>
              <b-col :cols="slidebar ? 4 : 2" class="pl-0">
                <b-form-group label="Virkar" label-for="active">
                  <v-select id="active" name="active" v-model="search.active" :options="active" :clearable="false"> </v-select>
                </b-form-group>
              </b-col>
              <b-col :cols="slidebar ? 12 : 4" class="pl-0 pr-0" :class="{ 'pt-4': slidebar }">
                <b-form-group class="d-inline-block mr-3" label-for="structureMissing" style="margin-top: -12px">
                  <label>Uppbygging <br />vantar</label>
                  <b-form-checkbox id="structureMissing" name="structureMissing" v-model="search.structureMissing"> </b-form-checkbox>
                </b-form-group>
                <b-form-group class="d-inline-block mr-3" label-for="diploma" style="margin-top: -12px">
                  <label
                    >Vefþjónusta <br />
                    Háskólans</label
                  >
                  <b-form-checkbox id="diploma" name="diploma" v-model="search.diploma"> </b-form-checkbox>
                </b-form-group>
                <b-form-group class="d-inline-block mr-4" label-for="activeStudents" style="margin-top: -12px">
                  <label
                    >Virkar <br />
                    skráningar <i class="fa fa-info-circle" title="Virkir nemendur skráðir á brautina" v-b-tooltip></i
                  ></label>
                  <v-select id="activeStudents" name="activeStudents" v-model="search.activeStudents" :options="types" :clearable="false">
                  </v-select>
                </b-form-group>
                <b-form-group class="d-inline-block" label-for="hasStudents" style="margin-top: -12px">
                  <label
                    >Skráningar <br />
                    <i class="fa fa-info-circle" title="Nemendur skráðir á brautina óháð stöðu" v-b-tooltip></i
                  ></label>
                  <v-select id="hasStudents" name="hasStudents" v-model="search.hasStudents" :options="types" :clearable="false">
                  </v-select>
                </b-form-group>
                <b-btn
                  variant="dark"
                  style="height: 35px; font-size: 12px; margin-top: 14px"
                  class="float-right"
                  cols="1"
                  @click="clearSearch()"
                >
                  <i class="fa fa-eraser" aria-hidden="true"></i>
                  Hreinsa</b-btn
                >
              </b-col>
            </b-row>
          </td>
        </tr>
        <tr v-if="firsttime">
          <th v-if="!slidebar" style="width: 2%; white-space: nowrap">
            <b-form-checkbox v-if="canEdit" v-model="selectAll" @change="updateAll()" class="d-inline-block"></b-form-checkbox>
            <b-btn
              variant="primary"
              class="btn-xs d-inline-block"
              @click="openMassEditSlidebar()"
              v-if="selectedCourses.length > 0"
              style="font-size: 12px"
            >
              <i class="fa fa-pencil"></i>
            </b-btn>
          </th>
          <th><LinkOrder title="Heiti" :filteredList="list" :list="list" column="heiti" /> ({{ list.length }})</th>
          <th>
            <LinkOrder title="Kóði" :filteredList="list" :list="list" column="braut_kodi" />
          </th>
          <th v-if="!slidebar">
            <LinkOrder title="Deild" :filteredList="list" :list="list" column="deild" />
          </th>
          <th v-if="!slidebar">
            <LinkOrder title="Undirsk." :filteredList="list" :list="list" column="undirsk_audk" />
          </th>
          <th v-if="!slidebar">
            <LinkOrder title="Einingar" :filteredList="list" :list="list" column="einingar_alls" />
          </th>
          <th v-if="!slidebar">
            <LinkOrder title="Útgáfudags." :filteredList="list" :list="list" column="dags_gildis" />
          </th>
          <th v-if="!slidebar">
            <LinkOrder title="Virkur" :filteredList="list" :list="list" column="gildi" />
          </th>
          <th v-if="!slidebar">
            <LinkOrder title="Fj. nem" :filteredList="list" :list="list" column="fj_nem" />
            <i class="fa fa-info-circle" title="Fjöldi virkra nemenda á brautinni" v-b-tooltip></i>
          </th>
          <th v-if="!slidebar">
            <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="cursor-pointer float-right">
              <i class="fa fa-file-excel-o excelText"></i>
            </download-csv>
          </th>
        </tr>
      </thead>
      <tbody>
        <TableRowLoader v-if="loading && firsttime" :colspan="slidebar ? 2 : 14" :center="true" />
        <TableRowEmpty v-if="!loading && firsttime && this.list.length === 0" :colspan="slidebar ? 2 : 14" :center="true" />
        <TableRowSearchForResults v-if="!firsttime" :colspan="slidebar ? 2 : 14" :center="true" />
        <tr v-for="item in getPaginatedList" :key="item.braut_id">
          <td v-if="!slidebar" style="width: 2%"><b-form-checkbox v-if="canEdit" v-model="item.selected"></b-form-checkbox></td>
          <td>
            <b-link @click="changeCourse(item)" title="Velja braut" v-b-tooltip v-if="slidebar">
              {{ item.heiti }}
            </b-link>
            <b-link
              v-else
              :to="{
                name: 'Course',
                params: { id: item.braut_id },
                query: {
                  search: search.search,
                  dept: `${search.department ? search.department.department_id : ''}`,
                  div: `${search.division ? search.division.division_id : ''}`,
                  active: `${search.active ? search.active.id : ''}`,
                  structureMissing: `${search.structureMissing ? search.structureMissing : ''}`,
                  diploma: `${search.diploma ? search.diploma : ''}`,
                  activeStudents: `${search.activeStudents ? search.activeStudents.id : ''}`,
                  hasStudents: `${search.hasStudents ? search.hasStudents.id : ''}`,
                },
              }"
            >
              {{ item.heiti }}</b-link
            >
          </td>
          <td>{{ item.braut_kodi }}</td>
          <td v-if="!slidebar">
            <div v-for="(d, idx) in item.deildir" :key="idx">
              {{ d }}
            </div>
          </td>
          <td v-if="!slidebar">
            {{ item.undirsk_audk }}
          </td>
          <td v-if="!slidebar">
            {{ item.einingar_alls }}
          </td>
          <td v-if="!slidebar">
            {{ item.dags_gildis | moment('DD.MM.YYYY') }}
          </td>
          <td v-if="!slidebar">
            <yes-no :item="item.gildi"></yes-no>
          </td>
          <td v-if="!slidebar">
            <b-link @click="openStudentSlidebar(item)">
              {{ item.fj_nem }}
            </b-link>
          </td>
          <td>
            <b-link :to="{ name: 'CreateCourse', query: { copy: item.braut_id } }" v-if="canEdit && !slidebar">
              <i class="fa fa-copy float-right cursor-pointer" title="Afrita braut" v-b-tooltip></i>
            </b-link>
          </td>
        </tr>
      </tbody>
    </table>

    <Pagination
      :currentPage="currentPage"
      :totalRows="list.length"
      :perPage="perPage"
      v-if="list.length > 20"
      @change-page="changePage"
      @change-limit="changeLimit"
    ></Pagination>
    <course-mass-edit-slidebar
      v-if="showSlidebar"
      :items="selectedCourses"
      @closeSlidebar="closeAndReloadSlidebar()"
    ></course-mass-edit-slidebar>
    <course-students-slidebar
      v-if="showStudentSlidebar"
      :courseId="selectedItem && selectedItem.braut_id"
      @closeSlidebar="closeSlidebar()"
    ></course-students-slidebar>
  </div>
</template>

<script>
import moment from 'moment';
import curriculum from '@/api/curriculum';
import structure from '@/api/structure';
import { mapGetters, mapActions } from 'vuex';

import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import TableRowSearchForResults from '@/components/common/TableRowSearchForResults.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';
import Pagination from '@/components/common/Pagination.vue';
import YesNo from '@/components/common/YesNo.vue';

import CourseMassEditSlidebar from '@/components/curriculum/courses/CourseMassEditSlidebar.vue';
import CourseStudentsSlidebar from '@/components/curriculum/courses/CourseStudentsSlidebar.vue';

export default {
  name: 'course-search',
  components: {
    TableRowLoader,
    TableRowEmpty,
    TableRowSearchForResults,
    LinkOrder,
    Pagination,
    YesNo,
    CourseMassEditSlidebar,
    CourseStudentsSlidebar,
  },
  props: ['slidebar'],
  computed: {
    getExcelData() {
      const list = [];
      this.list.forEach(x => {
        const item = {
          Heiti: x.heiti,
          Kóði: x.braut_kodi,
          Deild: x.deild,
          Undirskóli: x.undirsk_heiti,
          Einingar: x.einingar_alls,
          Útgáfudagsetning: moment(x.dags_gildis).format('DD.MM.YYYY'),
          Virkur: x.gildi === 1 ? 'Já' : 'Nei',
          'Fj. virkra nemenda': x.fj_nem,
          'Kjarna áfangar sjálfvirkt í námsferil': x.sjalfvirkt_val === 1 ? 'Já' : 'Nei',
        };
        if (!this.isPrimarySchool) {
          const title = !this.isLearningCenter ? 'Samræmd braut - Eldri námskrá' : 'Samræmd braut';
          item[title] = x.samraemd_braut_heiti;
        }
        if (!this.isPrimarySchool && !this.isLearningCenter) {
          item['Samræmd braut - Ný námskrá'] = x.prog_title;
        }

        if (!this.isPrimarySchool) {
          item['Má sækja um í menntagátt'] = x.innrit_menntagatt === 1 ? 'Já' : 'Nei';
          item['Má innrita nema á námsbraut'] = x.innrit_lokad_allar === 1 ? 'Já' : 'Nei';
          item['Má sækja um á umsóknarvef'] = x.innrit_lokad_nyjar === 1 ? 'Já' : 'Nei';
          item['Má sækja um brautaskipti'] = x.innrit_brautaskipti === 1 ? 'Já' : 'Nei';
          item['Umsóknarfrestur runninn út'] = x.dags_lokad ? moment(x.dags_lokad).format('DD.MM.YYYY') : '';
          item['Fjöldi nýnema'] = x.fj_nynema;
          item['Nám á ensku'] = x.nam_a_ensku === 1 ? 'Já' : 'Nei';
          item['Athugasemd á rafrænni umsókn'] = x.aths_rafr_umsokn;
        }

        item.Athugasemd = x.aths;
        item['Hámark eininga á kennslutímabili'] = x.einingar_max_onn;
        item['Hámarksfjöldi kennslustunda á viku'] = x.timar_max_onn;
        item['Lágmarksfjöldi varavals á kennslutímabili'] = x.min_varaval;
        item['Hámarksfjöldi varavals á kennslutímabili'] = x.max_varaval;

        item['Fj. tíma á kennslutímabili'] = x.klst_alls;
        item['Birta skólasóknaráfanga á skírteini'] = x.skolasoknarafangi_a_skirteini === 1 ? 'Já' : 'Nei';

        item['Íslenska - Texti á undan heiti á skírteini'] = x.texti_skirteini;
        item['Íslenska - Heiti á skírteini'] = x.heiti_skirteini;
        item['Íslenska - Texti á eftir heiti á skírteini'] = x.texti_skirteini2;
        item['Íslenska - Athugasemdir á skírteini'] = x.aths_skirteini;

        item['Enska - Heiti'] = x.heiti_ens;
        item['Enska - Texti á undan heiti á skírteini'] = x.texti_skirteini_enska;
        item['Enska - Heiti á skírteini'] = x.heiti_skirteini_ens;
        item['Enska - Texti á eftir heiti á skírteini'] = x.texti_skirteini2_enska;
        item['Enska - Athugasemdir á skírteini'] = x.aths_skirteini_ens;

        list.push(item);
      });
      return list;
    },
    getExcelFileName() {
      return `brautir_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    getPaginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.list.slice(start, end);
    },
    getDivisions() {
      return this.divisions;
    },
    getDepartments() {
      return this.departments;
    },
    selectedCourses() {
      return this.list.filter(x => x.selected);
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_brautir');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('namskra_brautir');
    },
    isPrimarySchool() {
      return this.schoolSettings && this.schoolSettings.tegund_skola_id === 3;
    },
    isLearningCenter() {
      return this.schoolSettings && this.schoolSettings.tegund_skola_id === 4;
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      perPage: 20,
      currentPage: 1,
      selectAll: false,
      firsttime: false,
      detailedSearch: false,
      loading: false,
      showSlidebar: false,
      showStudentSlidebar: false,
      list: [],
      departments: [],
      divisions: [],
      types: [
        { id: '', label: 'Bæði' },
        { id: '0', label: 'Nei' },
        { id: '1', label: 'Já' },
      ],
      active: [
        { id: '1', label: 'Eingöngu virkar' },
        { id: '0', label: 'Eingöngu óvirkar' },
        { id: '', label: 'Bæði virkar og óvirkar' },
      ],
      search: {
        search: '',
        active: { id: '1', label: 'Eingöngu virkar' },
        department: '',
        division: '',
        structureMissing: false,
        diploma: false,
        activeStudents: { id: '', label: 'Bæði' },
        hasStudents: { id: '', label: 'Bæði' },
      },
      selectedItem: {},
    };
  },
  methods: {
    updateQuery() {
      // if (!this.slidebar) {
      const queries = JSON.parse(JSON.stringify(this.$route.query));
      queries.search = this.search.search;
      queries.dept = this.search.department ? this.search.department.department_id.toString() : '';
      queries.div = this.search.division ? this.search.division.division_id.toString() : '';
      queries.active = this.search.active.id.toString();
      queries.activeStudents = this.search.activeStudents.id.toString();
      queries.hasStudents = this.search.hasStudents.id.toString();
      if (JSON.stringify(this.$route.query) !== JSON.stringify(queries)) {
        this.$router.replace({ query: queries });
      }
      // }
    },
    async loadList() {
      try {
        this.firsttime = true;
        this.loading = true;
        this.updateQuery();
        this.selectAll = false;
        const search = {
          search: this.search.search,
          department: this.search.department ? this.search.department.department_id : '',
          division: this.search.division ? this.search.division.division_id : '',
          active: this.search.active.id,
          showDepartment: 1,
          structureMissing: this.search.structureMissing ? 1 : 0,
          diploma: this.search.diploma ? 1 : 0,
          hasActiveStudents: this.search.activeStudents.id,
          hasStudents: this.search.hasStudents.id,
          showNrStudents: 1,
        };
        // ef search bar er útfylltur (ítarleit) þá á að birta ítarleitina
        if (
          this.search.department ||
          this.search.division ||
          this.search.active.id !== '1' ||
          this.search.activeStudents.id !== '' ||
          this.search.hasStudents.id !== ''
        ) {
          this.detailedSearch = true;
        }
        const response = await curriculum.getCourseList(search);
        this.list = response.data.items.map(x => ({
          ...x,
          deildir: x.deild ? x.deild.split(',') : [],
          selected: false,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadDepartments(id) {
      try {
        const response = await structure.getDepartmentList();
        this.departments = response.data.items.map(x => ({
          ...x,
          label: `${x.name}`,
        }));
        if (id) {
          this.search.department = this.departments.find(x => x.department_id === parseInt(id, 10));
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadDivisions(id) {
      try {
        const response = await structure.getDivisionList();
        this.divisions = response.data.items.map(x => ({
          ...x,
          label: `${x.code} - ${x.name}`,
        }));
        if (id) {
          this.search.division = this.divisions.find(x => x.division_id === parseInt(id, 10));
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    changeCourse(item) {
      this.$emit('changeCourse', {
        id: item.braut_id,
      });
    },
    clearSearch() {
      this.search = {
        search: '',
        active: { id: '1', label: 'Eingöngu virkar' },
        department: '',
        division: '',
        structureMissing: false,
        diploma: false,
        activeStudents: { id: '', label: 'Bæði' },
        hasStudents: { id: '', label: 'Bæði' },
      };
      this.loadList();
    },
    openMassEditSlidebar() {
      this.showSlidebar = true;
      this.showStudentSlidebar = false;
    },
    openStudentSlidebar(item) {
      this.selectedItem = item;
      this.showStudentSlidebar = true;
      this.showSlidebar = false;
    },
    closeSlidebar() {
      this.selectedItem = {};
      this.showSlidebar = false;
      this.showStudentSlidebar = false;
    },
    closeAndReloadSlidebar() {
      this.closeSlidebar();
      this.loadList();
    },
    async loadData() {
      const queries = this.$route.query;
      this.search.search = queries.search || '';
      this.search.structureMissing = queries.structureMissing || false; //  ? true : false;
      this.search.diploma = queries.diploma || false; //  ? true : false;
      this.search.active = queries.active ? this.active.find(x => x.id === queries.active) : { id: '1', label: 'Eingöngu virkir' };
      this.search.activeStudents = queries.activeStudents
        ? this.types.find(x => x.id === queries.activeStudents)
        : { id: '', label: 'Bæði' };
      this.search.hasStudents = queries.hasStudents ? this.types.find(x => x.id === queries.hasStudents) : { id: '', label: 'Bæði' };

      await this.loadDepartments(queries.dept);
      await this.loadDivisions(queries.div);
      this.$nextTick(() => {
        if (this.$refs.focusInput) {
          this.$refs.focusInput.focus();
        }
        if (Object.keys(queries).length > 0) {
          this.loadList();
        }
      });
    },
    updateAll() {
      this.list.forEach(i => {
        this.$set(i, 'selected', this.selectAll);
      });
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    if (this.$route.query) {
      this.searchQuery = this.$route.query;
    }
    this.loadData();
  },
};
</script>
