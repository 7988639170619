<template>
  <Slidebar :autoClose="false" @closeMe="closeSlidebar" :large="large" :extraLarge="extraLarge">
    <h4>{{ item.nafn }} - {{ item.kennitala }} <loader v-if="loading"></loader></h4>
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <table class="table less-padding student-create-table">
        <tr>
          <td>Undirskóli</td>
          <td>
            <b-form-group
              label-for="undirskoli"
              :state="submitted && errors.has('undirskoli') ? false : ''"
              :invalid-feedback="errors.first('undirskoli')"
            >
              <v-select
                id="undirskoli"
                name="undirskoli"
                v-model="form.undirskoli"
                :clearable="false"
                v-validate="'required'"
                :state="submitted && errors.has('undirskoli') ? false : ''"
                :options="divisions"
                data-vv-as="undirskóli"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Aðsetur</td>
          <td>
            <b-form-group
              label-for="adsetur"
              :state="submitted && errors.has('adsetur') ? false : ''"
              :invalid-feedback="errors.first('adsetur')"
            >
              <b-form-input
                id="adsetur"
                name="adsetur"
                type="text"
                v-model="form.adsetur"
                :state="submitted && errors.has('adsetur') ? false : ''"
                maxLength="250"
                data-vv-as="aðsetur"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Póstnúmer aðseturs</td>
          <td>
            <b-form-group
              label-for="postnr_as"
              :state="submitted && errors.has('postnr_as') ? false : ''"
              :invalid-feedback="errors.first('postnr_as')"
            >
              <b-form-input
                id="postnr_as"
                name="postnr_as"
                type="text"
                v-model="form.postnr_as"
                :state="submitted && errors.has('postnr_as') ? false : ''"
                maxLength="3"
                data-vv-as="póstnr. aðseturs"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Sími aðseturs</td>
          <td>
            <b-form-group
              label-for="simi_as"
              :state="submitted && errors.has('simi_as') ? false : ''"
              :invalid-feedback="errors.first('simi_as')"
            >
              <b-form-input
                id="simi_as"
                name="simi_as"
                type="text"
                v-model="form.simi_as"
                :state="submitted && errors.has('simi_as') ? false : ''"
                maxLength="14"
                data-vv-as="sími aðseturs"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Sími lögheimilis</td>
          <td>
            <b-form-group
              label-for="simi_lh"
              :state="submitted && errors.has('simi_lh') ? false : ''"
              :invalid-feedback="errors.first('simi_lh')"
            >
              <b-form-input
                id="simi_lh"
                name="simi_lh"
                type="text"
                v-model="form.simi_lh"
                :state="submitted && errors.has('simi_lh') ? false : ''"
                maxLength="14"
                data-vv-as="sími lögheimilis"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Farsími</td>
          <td>
            <b-form-group
              label-for="farsimi"
              :state="submitted && errors.has('farsimi') ? false : ''"
              :invalid-feedback="errors.first('farsimi')"
            >
              <b-form-input
                id="farsimi"
                name="farsimi"
                type="text"
                v-model="form.farsimi"
                :state="submitted && errors.has('farsimi') ? false : ''"
                maxLength="14"
                data-vv-as="farsími"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Netfang</td>
          <td>
            <b-form-group
              label-for="netfang"
              :state="submitted && errors.has('netfang') ? false : ''"
              :invalid-feedback="errors.first('netfang')"
            >
              <b-form-input
                id="netfang"
                name="netfang"
                type="text"
                v-model="form.netfang"
                :state="submitted && errors.has('netfang') ? false : ''"
                maxLength="100"
                data-vv-as="netfang"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Einkanetfang</td>
          <td>
            <b-form-group
              label-for="einka_netfang"
              :state="submitted && errors.has('einka_netfang') ? false : ''"
              :invalid-feedback="errors.first('einka_netfang')"
            >
              <b-form-input
                id="einka_netfang"
                name="einka_netfang"
                type="text"
                v-model="form.einka_netfang"
                :state="submitted && errors.has('einka_netfang') ? false : ''"
                maxLength="100"
                data-vv-as="einkanetfang"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <!--tr>
          <td>Virkur</td>
          <td>
            <b-form-group
              label-for="virkur"
              :state="submitted && errors.has('virkur') ? false : ''"
              :invalid-feedback="errors.first('virkur')"
            >
              <v-select
                id="virkur"
                name="virkur"
                v-model="form.virkur"
                :clearable="false"
                v-validate="'required'"
                :state="submitted && errors.has('virkur') ? false : ''"
                :options="yesNoOptions"
                data-vv-as="virkur"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr-->
        <tr>
          <td>Staða nema</td>
          <td>
            <b-form-group
              label-for="eink_stodu"
              :state="submitted && errors.has('eink_stodu') ? false : ''"
              :invalid-feedback="errors.first('eink_stodu')"
            >
              <v-select
                id="eink_stodu"
                name="eink_stodu"
                v-model="form.eink_stodu"
                :clearable="false"
                v-validate="'required'"
                :state="submitted && errors.has('eink_stodu') ? false : ''"
                :options="statuses"
                data-vv-as="staða nema"
                label="heiti"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Vinnslustaða</td>
          <td>
            <b-form-group
              label-for="vinnslustada"
              :state="submitted && errors.has('vinnslustada') ? false : ''"
              :invalid-feedback="errors.first('vinnslustada')"
            >
              <v-select
                id="vinnslustada"
                name="vinnslustada"
                v-model="form.vinnslustada"
                :clearable="true"
                :state="submitted && errors.has('vinnslustada') ? false : ''"
                :options="processingStatuses"
                data-vv-as="vinnslustaða"
                label="heiti"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
        <tr v-if="!afangaskoli">
          <td>Ár nemanda</td>
          <td>
            <b-form-group
              label-for="nu_onn"
              :state="submitted && errors.has('nu_onn') ? false : ''"
              :invalid-feedback="errors.first('nu_onn')"
            >
              <b-form-input
                id="nu_onn"
                name="nu_onn"
                type="text"
                v-model="form.nu_onn"
                :state="submitted && errors.has('nu_onn') ? false : ''"
                maxLength="2"
                v-validate="{ regex: /^[0-9]*$/ }"
                data-vv-as="ár"
              ></b-form-input>
            </b-form-group>
            <div v-if="!isValid" class="text-danger">Ath. ár nemanda verður að vera tölustafur</div>
          </td>
        </tr>
        <tr>
          <td>Heimavist</td>
          <td>
            <b-form-group
              label-for="heimavist"
              :state="submitted && errors.has('heimavist') ? false : ''"
              :invalid-feedback="errors.first('heimavist')"
            >
              <v-select
                id="heimavist"
                name="heimavist"
                v-model="form.heimavist"
                :clearable="true"
                :state="submitted && errors.has('heimavist') ? false : ''"
                :options="yesNoOptions"
                data-vv-as="heimavist"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Sérstök mætingareinkunn</td>
          <td>
            <b-form-group
              label-for="frjals_maeting"
              :state="submitted && errors.has('frjals_maeting') ? false : ''"
              :invalid-feedback="errors.first('frjals_maeting')"
            >
              <v-select
                id="frjals_maeting"
                name="frjals_maeting"
                v-model="form.frjals_maeting"
                :clearable="true"
                :state="submitted && errors.has('frjals_maeting') ? false : ''"
                :options="yesNoOptions"
                data-vv-as="sérstök mætingareinkunn"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Skrápanúmer</td>
          <td>
            <b-form-group
              label-for="skapnr"
              :state="submitted && errors.has('skapnr') ? false : ''"
              :invalid-feedback="errors.first('skapnr')"
            >
              <b-form-input
                id="skapnr"
                name="skapnr"
                type="text"
                v-model="form.skapnr"
                :state="submitted && errors.has('skapnr') ? false : ''"
                maxLength="30"
                data-vv-as="skápanúmer"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Fötlun/sérþarfir nemanda</td>
          <td>
            <b-form-group
              label-for="fotlun"
              :state="submitted && errors.has('fotlun') ? false : ''"
              :invalid-feedback="errors.first('fotlun')"
            >
              <b-form-textarea
                id="fotlun"
                name="fotlun"
                type="text"
                v-model="form.fotlun"
                :state="submitted && errors.has('fotlun') ? false : ''"
                maxLength="3000"
                data-vv-as="fötlun"
                style="font-size: 13px"
              ></b-form-textarea>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Móðurmál 1</td>
          <td>
            <b-form-group
              label-for="mmal_1"
              :state="submitted && errors.has('mmal_1') ? false : ''"
              :invalid-feedback="errors.first('mmal_1')"
            >
              <v-select
                id="mmal_1"
                name="mmal_1"
                v-model="form.mmal_1"
                :clearable="true"
                :state="submitted && errors.has('mmal_1') ? false : ''"
                :options="languages"
                data-vv-as="móðurmál"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Móðurmál 2</td>
          <td>
            <b-form-group
              label-for="mmal_2"
              :state="submitted && errors.has('mmal_2') ? false : ''"
              :invalid-feedback="errors.first('mmal_2')"
            >
              <v-select
                id="mmal_2"
                name="mmal_2"
                v-model="form.mmal_2"
                :clearable="true"
                :state="submitted && errors.has('mmal_2') ? false : ''"
                :options="languages"
                data-vv-as="móðurmál"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Norðurlandamál</td>
          <td>
            <b-form-group
              label-for="nl_mal"
              :state="submitted && errors.has('nl_mal') ? false : ''"
              :invalid-feedback="errors.first('nl_mal')"
            >
              <v-select
                id="nl_mal"
                name="nl_mal"
                v-model="form.nl_mal"
                :clearable="true"
                :state="submitted && errors.has('nl_mal') ? false : ''"
                :options="secondLanguages"
                data-vv-as="norðurlandamál"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Þriðja mál</td>
          <td>
            <b-form-group
              label-for="thridja_mal"
              :state="submitted && errors.has('thridja_mal') ? false : ''"
              :invalid-feedback="errors.first('thridja_mal')"
            >
              <v-select
                id="thridja_mal"
                name="thridja_mal"
                v-model="form.thridja_mal"
                :clearable="true"
                :state="submitted && errors.has('thridja_mal') ? false : ''"
                :options="thirdLanguages"
                data-vv-as="þriðja mál"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Fjórða mál</td>
          <td>
            <b-form-group
              label-for="fjorda_mal"
              :state="submitted && errors.has('fjorda_mal') ? false : ''"
              :invalid-feedback="errors.first('fjorda_mal')"
            >
              <v-select
                id="fjorda_mal"
                name="fjorda_mal"
                v-model="form.fjorda_mal"
                :clearable="true"
                :state="submitted && errors.has('fjorda_mal') ? false : ''"
                :options="fourthLanguages"
                data-vv-as="fjórða mál"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
      </table>
    </b-form>
    <b-btn variant="primary" @click="validateBeforeSubmit()" :disabled="loading">Vista</b-btn>
  </Slidebar>
</template>

<script>
import common from '@/api/common';
import students from '@/api/students';
import structure from '@/api/structure';
import curriculum from '@/api/curriculum';
import types from '@/api/types';
import { mapGetters, mapActions } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';
import Loader from '@/components/common/Loader.vue';

export default {
  name: 'student-edit-slidebar',
  components: {
    Slidebar,
    Loader,
  },
  props: ['id', 'large', 'extraLarge'],
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('nemendahald_nemendur');
    },
    heimavist() {
      return this.schoolSettings && this.schoolSettings.vist ? this.schoolSettings.vist === 1 : false;
    },
    isValid() {
      return !this.form.nu_onn || /^[0-9]*$/.test(this.form.nu_onn);
    },
    afangaskoli() {
      return this.schoolSettings && this.schoolSettings.afangaskoli ? this.schoolSettings.afangaskoli === 1 : false;
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      item: '',
      submitted: false,
      loading: false,
      form: {
        undirskoli: '',
        adsetur: '',
        postnr_as: '',
        simi_as: '',
        simi_lh: '',
        farsimi: '',
        netfang: '',
        einka_netfang: '',
        virkur: '',
        eink_stodu: '',
        vinnslustada: '',
        nu_onn: '',
        heimavist: '',
        frjals_maeting: '',
        skapnr: '',
        nl_mal: '',
        thridja_mal: '',
        fjorda_mal: '',
        mmal_1: '',
        mmal_2: '',
      },
      divisions: [],
      statuses: [],
      processingStatuses: [],
      secondLanguages: [],
      thirdLanguages: [],
      fourthLanguages: [],
      languages: [],
      yesNoOptions: [
        { id: 1, label: 'Já' },
        { id: 0, label: 'Nei' },
      ],
    };
  },
  methods: {
    async loadDivisions() {
      try {
        const response = await structure.getDivisionList();
        this.divisions = response.data.items.map(x => ({
          ...x,
          label: `${x.code} - ${x.name}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadStatuses() {
      try {
        const response = await students.getStudentsStatuses();
        this.statuses = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadProcessingStatuses() {
      try {
        const response = await types.getProcessingStatusList();
        this.processingStatuses = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadSecondLanguages() {
      try {
        const response = await curriculum.getSecondLanguages();
        this.secondLanguages = response.data.items.map(x => ({
          ...x,
          label: `${x.heiti} ${x.kodi}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadThirdLanguages() {
      try {
        const response = await curriculum.getThirdLanguages();
        this.thirdLanguages = response.data.items.map(x => ({
          ...x,
          label: `${x.heiti} ${x.kodi}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadFourthLanguages() {
      try {
        const response = await curriculum.getFourthLanguages();
        this.fourthLanguages = response.data.items.map(x => ({
          ...x,
          label: `${x.heiti} ${x.kodi}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadLanguages() {
      try {
        const response = await common.getLanguages();
        this.languages = response.data.items.map(x => ({
          ...x,
          label: `${x.heiti_mals}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadDetails() {
      if (this.canEdit) {
        try {
          this.loading = true;
          const response = await students.getStudentInfo(this.id);
          this.item = response.data;
          this.form.adsetur = this.item.adsetur;
          this.form.postnr_as = this.item.postnr_as;
          this.form.simi_as = this.item.simi_as;
          this.form.simi_lh = this.item.simi_lh;
          this.form.farsimi = this.item.farsimi;
          this.form.netfang = this.item.netfang;
          this.form.einka_netfang = this.item.einka_netfang;
          this.form.virkur = this.yesNoOptions.find(x => x.id === this.item.virkur);
          this.form.nu_onn = this.item.nu_onn;
          this.form.heimavist = this.yesNoOptions.find(x => x.id === this.item.heimavist);
          this.form.frjals_maeting = this.yesNoOptions.find(x => x.id === this.item.frjals_maeting);
          this.form.skapnr = this.item.skapnr;
          this.form.fotlun = this.item.fotlun;

          await this.loadDivisions();
          this.form.undirskoli = this.divisions.find(x => x.division_id === this.item.undirskoli_id);
          await this.loadStatuses();
          this.form.eink_stodu = this.statuses.find(x => x.eink_stodu === this.item.eink_stodu);
          await this.loadProcessingStatuses();
          this.form.vinnslustada = this.processingStatuses.find(x => x.teg_vinnslust_nema_id === this.item.vinnslustada);
          await this.loadLanguages();
          this.form.mmal_1 = this.languages.find(x => x.eink_mals === this.item.mmal_1);
          this.form.mmal_2 = this.languages.find(x => x.eink_mals === this.item.mmal_2);
          await this.loadSecondLanguages();
          this.form.nl_mal = this.secondLanguages.find(x => x.namsgrein_id === this.item.nl_mal);
          await this.loadThirdLanguages();
          this.form.thridja_mal = this.thirdLanguages.find(x => x.namsgrein_id === this.item.thridja_mal);
          await this.loadFourthLanguages();
          this.form.fjorda_mal = this.fourthLanguages.find(x => x.namsgrein_id === this.item.fjorda_mal);
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading = false;
        }
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });
        const item = {
          undirskoli_id: this.form.undirskoli.division_id,
          adsetur: this.form.adsetur,
          postnr_as: this.form.postnr_as,
          simi_as: this.form.simi_as,
          simi_lh: this.form.simi_lh,
          farsimi: this.form.farsimi,
          netfang: this.form.netfang,
          einka_netfang: this.form.einka_netfang,
          virkur: this.form.virkur.id,
          eink_stodu: this.form.eink_stodu.eink_stodu,
          vinnslustada: this.form.vinnslustada ? this.form.vinnslustada.teg_vinnslust_nema_id : '',
          nu_onn: this.form.nu_onn,
          heimavist: this.form.heimavist ? this.form.heimavist.id : '',
          frjals_maeting: this.form.frjals_maeting ? this.form.frjals_maeting.id : '',
          skapnr: this.form.skapnr,
          mmal_1: this.form.mmal_1 ? this.form.mmal_1.eink_mals : '',
          mmal_2: this.form.mmal_2 ? this.form.mmal_2.eink_mals : '',
          nl_mal: this.form.nl_mal ? this.form.nl_mal.namsgrein_id : '',
          thridja_mal: this.form.thridja_mal ? this.form.thridja_mal.namsgrein_id : '',
          fjorda_mal: this.form.fjorda_mal ? this.form.fjorda_mal.namsgrein_id : '',
          fotlun: this.form.fotlun,
        };
        await students.updateStudentInfo(this.id, item);
        this.displaySuccess('Nemanda breytt');
        this.closeSlidebar();
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    closeSlidebar(item) {
      this.$emit('closeSlidebar', item);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.$nextTick(() => {
      if (this.$refs.focusInput) {
        this.$refs.focusInput.focus();
      }
    });
    this.loadDetails();
  },
};
</script>

<style lang="scss">
.student-create-table {
  font-size: 13px;
  input {
    height: 25px !important;
    font-size: 13px;
    &.vs__search {
      margin: 0;
    }
  }
  .form-group {
    margin-bottom: 0px;
  }
}
</style>
