import Vue from 'vue';

const API_URL = '/api/terms';

export default {
  getTerms(params) {
    return Vue.http
      .get(`${API_URL}/`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createTerm(data) {
    return Vue.http
      .post(`${API_URL}/`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getTerm(id) {
    const params = { only_active: 0 };
    return Vue.http
      .get(`${API_URL}/${id}`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateTerm(id, data) {
    return Vue.http
      .post(`${API_URL}/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getTermModules(term, params) {
    return Vue.http
      .get(`${API_URL}/${term}/modules`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  setTermModules(term, data) {
    return Vue.http
      .post(`${API_URL}/${term}/modules`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  flattenTermTimetable(id, params) {
    return Vue.http
      .post(`${API_URL}/${id}/flatten-timetable`, params)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getTermTimetableList(id, params) {
    return Vue.http
      .get(`${API_URL}/${id}/timetable-list`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  checkTermCodeExists(params) {
    return Vue.http
      .get(`${API_URL}/term-exists`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  copyTerm(id, term, data) {
    return Vue.http
      .post(`${API_URL}/${id}/copy/${term}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  copyTermGraderule(id, term, data) {
    return Vue.http
      .post(`${API_URL}/${id}/copy-graderule/${term}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getTermClassrooms(term) {
    return Vue.http
      .get(`${API_URL}/${term}/classrooms`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  setTermClassrooms(term, data) {
    return Vue.http
      .post(`${API_URL}/${term}/classrooms`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateTermModule(id, data) {
    return Vue.http
      .post(`${API_URL}/module/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getTermModule(id) {
    return Vue.http
      .get(`${API_URL}/module/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getTermModuleGraderule(id) {
    return Vue.http
      .get(`${API_URL}/module/${id}/graderule`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  copyGraderule(data) {
    return Vue.http
      .post(`${API_URL}/copy-graderule`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createModuleGraderule(termModuleId, data) {
    return Vue.http
      .post(`${API_URL}/module/${termModuleId}/graderule`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateModuleGraderule(termModuleId, moduleGraderuleId, data) {
    return Vue.http
      .post(`${API_URL}/module/${termModuleId}/graderule?einkunnir_afanga_id=${moduleGraderuleId}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getRestrictedChoice(id) {
    return Vue.http
      .get(`${API_URL}/${id}/restricted-choice/`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createRestrictedChoice(term, data) {
    return Vue.http
      .post(`${API_URL}/${term}/restricted-choice/`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateRestrictedChoice(term, id, data) {
    return Vue.http
      .post(`${API_URL}/${term}/restricted-choice/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getBookByBook(termId, params) {
    return Vue.http
      .get(`${API_URL}/${termId}/booklist-by-books`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getBookByModules(termId) {
    return Vue.http
      .get(`${API_URL}/${termId}/booklist-by-modules`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getBooklist(termId) {
    return Vue.http
      .get(`${API_URL}/${termId}/booklist`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createTermModuleBook(data) {
    return Vue.http
      .post(`${API_URL}/book`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  copyBooklist(termId, data) {
    return Vue.http
      .post(`${API_URL}/${termId}/copy-booklist`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  deleteBooklist(termId) {
    return Vue.http
      .delete(`${API_URL}/${termId}/booklist`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  calculateGrades(term, data) {
    return Vue.http
      .post(`${API_URL}/${term}/calculate-grades`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  finalGrades(term, data) {
    return Vue.http
      .post(`${API_URL}/${term}/final-grades`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  calculateFinalGrades(term, type, data) {
    return Vue.http
      .post(`${API_URL}/${term}/calculate-final-grades/${type}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getGroupsGradesMissing(term) {
    return Vue.http
      .get(`${API_URL}/${term}/groups-grades-missing`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getGroupsFinalMissing(term) {
    return Vue.http
      .get(`${API_URL}/${term}/groups-final-missing`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createAttendanceGrades(term, data) {
    return Vue.http
      .post(`${API_URL}/${term}/attendance-grades`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getGroupsFinalGradesMismatch(term) {
    return Vue.http
      .get(`${API_URL}/${term}/final-grades-mismatch`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentUnits(term) {
    return Vue.http
      .get(`${API_URL}/${term}/student-units`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentUnfinishedModules(term) {
    return Vue.http
      .get(`${API_URL}/${term}/unfinished-modules`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getGraderuleGradesExists(ruleId, type) {
    return Vue.http
      .get(`${API_URL}/graderule/${ruleId}/grade-exists/${type}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getTeachingPlans(id, params) {
    return Vue.http
      .get(`${API_URL}/${id}/teaching-plans`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  setTermToModule(term, data) {
    return Vue.http
      .post(`${API_URL}/${term}/add-module-to-term`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }
};
