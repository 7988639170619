<template>
  <div>
    <b-modal
      id="commentModal"
      title="Athugasemd starfsmanns"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Vista"
      cancel-title="Hætta við"
      @ok.prevent="validateBeforeSubmit"
      ref="commentModal"
    >
      <div>
        <table class="table">
          <tr>
            <td>Áfangi</td>
            <td>{{ item.namsgrein_afanganr }}({{ item.hopur }})</td>
          </tr>
          <tr>
            <td>Aðgerð</td>
            <td>
              <span v-if="item.adgerd === 0">Taka úr töflu <i class="fa fa-fw fa-trash text-danger"></i></span>
              <span v-if="item.adgerd === 1">Bæta í töflu <i class="fa fa-fw fa-plus-circle text-success"></i></span>
            </td>
          </tr>
          <tr>
            <td>Athugasemd nemanda</td>
            <td>{{ item.skilabod_nemandi }}</td>
          </tr>
          <tr>
            <td>Athugasemd starfsmanns</td>
            <td>
              <textarea v-model="description" style="width: 100%" />
            </td>
          </tr>
        </table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import requests from '@/api/requests';
import { mapActions } from 'vuex';

export default {
  name: 'comment-modal',
  data() {
    return {
      item: '',
      description: '',
    };
  },
  methods: {
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          await requests.saveTimetableRequestStaffComment(this.item.nemandi_toflubreytingar_id, {
            comment: this.description,
          });
          this.displaySuccess('Athugasemd breytt.');
          this.$emit('successful', {});
          this.$refs.commentModal.hide();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    show(item) {
      this.item = item;
      this.description = this.item.skilabod_stm;
      this.$refs.commentModal.show();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
<style lang="scss">
.error-modal-description {
  margin-top: 30px;
}
</style>
