<template>
  <Slidebar @closeMe="closeSlidebar" :large="true">
    <h4>
      Þátttakendur -
      <div class="d-inline-block" v-html="instance.namskeid_nafn" />
    </h4>
    <table class="table table-hover no-border">
      <thead>
        <tr v-if="participants.length > 0">
          <th>
            <LinkOrder title="Nafn" :filteredList="participants" :list="participants" column="nafn"  />
          </th>
          <th>
            <LinkOrder title="Kennitala" :filteredList="participants" :list="participants" column="kt" second_column="nafn" />
          </th>
          <th>
            <LinkOrder title="Netfang" :filteredList="participants" :list="participants" column="email" second_column="nafn" />
          </th>
          <th>
            <LinkOrder title="Sími" :filteredList="participants" :list="participants" column="phone" second_column="nafn" />
          </th>
          <th class="float-right">
            <LinkOrder title="Dags. umsóknar" :filteredList="participants" :list="participants" column="time_edit" second_column="nafn" />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="p in participants" :key="p.namskeid_skraning_id">
          <td>{{ p.nafn }}</td>
          <td>{{ p.kt }}</td>
          <td>{{ p.email }}</td>
          <td>{{ p.phone }}</td>
          <td class="float-right">{{ p.time_edit | moment('DD.MM.YYYY HH:mm') }}</td>
        </tr>
      </tbody>
    </table>
    <div v-if="participants.length == 0">Engir þátttakendur skráðir</div>
    <br />
    <span v-if="(instance.participants || []).length > 0">
      <b-btn variant="dark" class="mr-2" @click="messageModal()" v-if="canEdit"><i class="fa fa-envelope" /> Senda skilaboð</b-btn>
      <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="d-inline mr-2">
        <b-btn style="background-color: #1d6f42"> <i class="fa fa-file-excel-o" /> Excel </b-btn>
      </download-csv>
    </span>
    <b-btn variant="primary" :to="{ name: 'SingleClassesClass', params: { id: item.namskeid_id } }">
      <i class="fa fa-search" />
      Skoða námskeið
    </b-btn>
    <message-modal ref="messageModal"></message-modal>
  </Slidebar>
</template>

<script>
import moment from 'moment';
import singleClasses from '@/api/singleClasses';
import { mapActions, mapGetters } from 'vuex';
import he from 'he';
import LinkOrder from '@/components/common/LinkOrder.vue';

import Slidebar from '@/components/common/Slidebar.vue';
import MessageModal from '@/components/curriculum/singleClasses/MessageModal.vue';

export default {
  name: 'singleclass-class-slidebar',
  components: {
    LinkOrder,
    Slidebar,
    MessageModal,
  },
  props: ['item'],
  computed: {
    participants() {
      return this.instance.participants || [];
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_stok_namskeid');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
    getExcelData() {
      const newList = [
        [],
        ['Þátttakendur námskeiðs'],
        [he.decode(this.instance.namskeid_nafn, { allowUnsafeSymbols: true })],
        [],
        ['Nafn', 'Kennitala', 'Símanúmer', 'Netfang'],
        ...(this.instance.participants || []).map(x => [x.nafn, `="${x.kt}"`, x.phone, x.email]),
      ];
      return newList;
    },
    getExcelFileName() {
      return `thatttakendur_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
  },
  data() {
    return {
      instance: '',
      loading: false,
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async load() {
      try {
        this.instance = '';
        this.loading = true;
        const params = { id: this.item.namskeid_id };
        const response = await singleClasses.getList(params);
        this.instance = response.data.items.length > 0 ? response.data.items[0] : '';
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    /*
     * Modal gluggar
     */
    messageModal() {
      this.$refs.messageModal.show(this.item.namskeid_id, this.instance.participants);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    item() {
      this.load();
    },
  },
  created() {
    this.load();
  },
};
</script>

<style lang="scss"></style>
