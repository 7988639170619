<template>
    <Slidebar :large="true" @closeMe="closeSlidebar">
        <h4 v-if="type === 0">Skrá fjarvist á nemanda</h4>
        <h4 v-else>Skrá forföll á nemanda</h4>
        <b-form-group>
            <label class="d-inline-block mr-2">Skrá eftir</label>
            <b-form-radio-group id="period" v-model="period" name="period" class="d-inline-block" @change="checkGetData()">
                <b-form-radio :value="true">Tímabili</b-form-radio>
                <b-form-radio :value="false">Dagsetningu</b-form-radio>
            </b-form-radio-group>
        </b-form-group>
        <div v-if="period">
            <div class="mb-2" style="font-size: 13px">
                <p class="mb-1">
                    Hér eru skráðar fjarvistir/forföll fyrir ákveðið tímabil.
                    <!-- athugið að velja rétt í glugganum „Áfangar“
                    en boðið er upp á að skrá
                    bara á þennan áfanga á tímabilinu eða skrá á alla áfanga sem nemandi/ur eru í á tímabilinu. -->
                </p>
                <p class="mb-2">Ef valið er að skrá á alla áfanga nemandans þarf að hafa í huga að sú aðgerð er ekki
                    afturkræf.</p>
                <p class="mb-1 font-italic">
                    Dæmi þegar skráð er á einn áfanga: Áfanginn er sundáfangi og nemandi fótbrotnar og mætir ekki í tíma
                    næstu 4 vikurnar.
                </p>
                <p class="mb-1 font-italic">
                    Dæmi þegar skráð er á marga áfanga: Nemandi er að fara í aðgerð og mætir ekki í skólann þessa daga.
                </p>
            </div>
            <b-row class="pt-3">
                <b-col class="pl-0" md="6" sm="12" xs="12">
                    <b-form-group label-for="dateFrom" label="Dags. frá*"
                        :state="submitted && errors.has('dateFrom') ? false : ''"
                        :invalid-feedback="errors.first('dateFrom')">
                        <datepicker id="dateFrom" name="dateFrom" v-model="form.dateFrom" :language="lang"
                            :monday-first="true" format="dd.MM.yyyy" :typeable="false" :clear-button="false"
                            ref="registerDateFromPickr" v-validate="'required'"></datepicker>
                    </b-form-group>
                    <b-form-group label="Tími frá" label-for="timeFrom"
                        :state="submitted && errors.has('timeFrom') ? false : ''"
                        :invalid-feedback="errors.first('timeFrom')">
                        <masked-input id="timeFrom" name="timeFrom" v-model="form.timeFrom" mask="11:11" placeholder="hh:mm"
                            style="padding: 2px 2px 2px 2px; width: 60px; height: 30px; border: 1px solid #ced4da"
                            v-validate="{ required: false, regex: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/ }"
                            data-vv-as="tími frá" :state="submitted && errors.has('timeFrom') ? false : ''" />
                    </b-form-group>
                    <b-form-group label-for="dateTo" label="Dags. til*"
                        :state="submitted && errors.has('dateTo') ? false : ''" :invalid-feedback="errors.first('dateTo')">
                        <datepicker id="dateTo" name="dateTo" v-model="form.dateTo" :language="lang" :monday-first="true"
                            format="dd.MM.yyyy" :typeable="false" :clear-button="false" ref="registerDateToPickr"
                            v-validate="'required'"></datepicker>
                    </b-form-group>
                    <b-form-group label="Tími til" label-for="timeTo"
                        :state="submitted && errors.has('timeTo') ? false : ''" :invalid-feedback="errors.first('timeTo')">
                        <masked-input id="timeTo" name="timeTo" v-model="form.timeTo" mask="11:11" placeholder="hh:mm"
                            style="padding: 2px 2px 2px 2px; width: 60px; height: 30px; border: 1px solid #ced4da"
                            v-validate="{ required: false, regex: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/ }"
                            data-vv-as="tími frá" :state="submitted && errors.has('timeTo') ? false : ''" />
                    </b-form-group>
                    <b-form-group label-for="tegund" :label=typeName :state="submitted && errors.has('tegund') ? false : ''"
                        :invalid-feedback="errors.first('tegund')">
                        <v-select id="tegund" name="tegund" v-model="form.type" :clearable="false" v-validate="'required'"
                            :state="submitted && errors.has('tegund') ? false : ''" :options="getTypes" data-vv-as="tegund">
                        </v-select>
                    </b-form-group>
                    <b-form-group label-for="athugasemd" label="Athugasemd"
                        :state="submitted && errors.has('athugasemd') ? false : ''"
                        :invalid-feedback="errors.first('athugasemd')">
                        <b-form-textarea id="athugasemd" name="athugasemd" v-model="form.comment" maxLength="1000"
                            :state="submitted && errors.has('athugasemd') ? false : ''" data-vv-as="athugasemd">
                        </b-form-textarea>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-checkbox v-model="selectAll" @input="updateSelected()" class="d-inline-block grennCheckBox">
                    </b-form-checkbox>
                    <b>Áfangar* ({{ modules.length }})</b>
                    <b-form-group>
                        <b-form-checkbox-group id="module" v-model="selected" :options="modules" value-field="afangi_id"
                            text-field="namsgrein_afanganr" name="module" stacked v-validate="'required'"
                            data-vv-as="module" :state="submitted && errors.has('module') ? false : ''">
                            <b-form-invalid-feedback v-if="submitted" :state="state">
                                Það þarf að haka við amk. einn áfanga</b-form-invalid-feedback>
                        </b-form-checkbox-group>
                    </b-form-group>

                </b-col>
            </b-row>
            <div v-if="form.type" class="mt-3 mb-3">
                Skrá <span v-if="type === 0"> fjarvistategundina </span> <span v-if="type === 1">forfallategundina
                </span>
                <strong>{{ form.type.heiti }} ({{ form.type.kodi }})</strong> fyrir {{ selected.length }}
                áfanga á tímabilinu
                {{ form.dateFrom | moment('DD.MM.YYYY') }} {{ form.timeFrom }} <span v-if="!form.timeFrom">00:00</span>
                -
                {{ form.dateTo | moment('DD.MM.YYYY') }}
                {{ form.timeTo }}
                <span v-if="!form.timeTo">23:59</span>.
                <div>
                    <span v-if="form.onlyThisOne.id === 0">Ath. eingöngu verður skráð fyrir kennslustundir í þessum
                        ákveðna áfanga.</span>
                    <span v-else>Ath. það verður skráð fyrir allar kennslustundir sem nemendurnir eru í á
                        tímabilinu.</span>
                </div>
            </div>
            <div v-if="periodLength >= 14" class="alert alert-info p-2">
                <i class="fa fa-exclemation-triangle"></i> Ath. Tímabilið sem þú ert með valið eru {{ periodLength }}
                dagar!
            </div>
            <b-btn variant="primary" :disabled="saving" @click="validateBeforeSubmit()">
                <i class="fa fa-spin fa-spinner" v-if="saving"></i>
                <span  v-b-tooltip title="Athuga hvort færslur séu til">Athuga</span>
            </b-btn>
            <b-btn variant="primary" class="ml-4" :disabled="savingFinal" v-if="checkingData" @click="saveAll()">
                <i class="fa fa-spin fa-spinner" v-if="savingFinal"></i> Vista
            </b-btn>
            <div v-if="checkingData" class="pt-2 pb-2">
                <span class="font-weight-bold">Ath! </span> Eftirfarandi færslur eru nú þegar skráðar á völdu tímabili með
                <span v-if="type === 0"> fjarvistarkóða. </span>
                <span v-if="type === 1"> forfallakóða. </span>
                Með því að vista ertu að yfirskrifa þær.
            </div>
            <Loader v-if="saving"></Loader>

            <table class="table mt-2" v-if="checkingData" style="font-size: 13px">
                <thead>
                    <tr>
                        <td>Áfangi</td>
                        <td>Dags.</td>
                        <td>Tími</td>
                        <td>Viðverukóði</td>
                        <td>Forfallakóði</td>
                        <td>Athugasemd</td>
                    </tr>
                </thead>
                <tbody v-if="!saving">
                    <tr v-if="alreadyRegistered.length === 0 && !saving">
                        <td colspan="100%">
                            Engin
                            <span v-if="type === 0"> fjarvistarfærsla </span>
                            <span v-if="type === 1"> forfallafærsla </span>
                            fannst miðað við valin skilyrði.
                        </td>
                    </tr>
                    <tr v-for="item in alreadyRegistered" :key="item.fjarv_forfoll_id">
                        <td> {{ item.namsgrein_afanganr }}</td>
                        <td> {{ item.dags }}</td>
                        <td> {{ item.timi_fra }} - {{ item.timi_til }}</td>
                        <td> {{ item.kodi_fjarvist }}</td>
                        <td> {{ item.kodi_forfoll }}</td>
                        <td> {{ item.athugasemd }}</td>
                    </tr>
                </tbody>
            </table>

        </div>
        <div v-else>
            <b-row>
                <b-col class="pl-0">
                    <b-form-group label-for="date" label="Dags." :state="submitted && errors.has('date') ? false : ''"
                        :invalid-feedback="errors.first('date')">
                        <datepicker id="date" name="date" v-model="date" :language="lang" :monday-first="true"
                            format="dd.MM.yyyy" :typeable="false" :clear-button="false" ref="registerDatePickr"
                            @input="getAttendanceData()"></datepicker>
                    </b-form-group>
                </b-col>
            </b-row>
            <Loader v-if="loading.attendanceData"></Loader>
            <div v-if="!loading.attendanceData && attendanceData.length === 0">Ekki er hægt að skrá viðveru þennan dag.
            </div>
            <div v-if="attendanceData.length > 0">
                <table class="table less-padding no-border" style="font-size: 13px">
                    <thead>
                        <tr>
                            <td></td>
                            <td style="font-weight: normal"><i>Sami kóði á alla:</i></td>
                            <td>
                                <input type="text" v-model="commonCode" @input="updateAll(commonCode, attendanceData)"
                                    maxLength="1" style="width: 35px; height: 25px; padding-left: 3px"
                                    :class="{'ml-3': type === 1}"/>
                            </td>
                        </tr>
                        <tr>
                            <th>Áfangi</th>
                            <th>Tími</th>
                            <th></th>

                        </tr>
                    </thead>
                    <tr v-for="(s, idx) in attendanceData" :key="idx">

                        <td>{{ s.namsgrein_afanganr }}</td>
                        <td>{{ s.timifra }} - {{ s.timitil }}</td>
                        <td>
                            <div v-if="type === 0">
                                <input class="d-inline-block" type="text" v-model="s.tegund_fjarvista" maxLength="1"
                                    style="width: 35px; height: 25px; padding-left: 3px" :class="{
                                        attendanceInvalidInput: !attendanceValid(s.tegund_fjarvista),
                                        attendanceValidInput: attendanceValid(s.tegund_fjarvista),
                                    }" />
                                <div class="d-inline-block pl-1" v-if="s.tegund_forfalla" v-b-tooltip
                                    :title="s.heiti_forfoll" style="width: 20px">
                                    {{ s.tegund_forfalla }}
                                </div>
                            </div>
                            <div v-if="type === 1">
                                <div class="d-inline-block pr-1" style="width: 20px">{{ s.tegund_fjarvista }}</div>
                                <input class="d-inline-block" type="text" v-model="s.tegund_forfalla" maxLength="1"
                                    style="width: 35px; height: 25px; padding-left: 3px" :class="{
                                        attendanceInvalidInput: !absenceValid(s.tegund_forfalla),
                                        attendanceValidInput: absenceValid(s.tegund_forfalla),
                                    }" />
                                <span v-if="s.fjarv_forfoll_id && s.teg_forfalla_id" title="Hreinsa út forföll"
                                    v-b-tooltip><i class="fa fa-trash text-primary pl-1 cursor-pointer"
                                        @click="clearStudentAbsence(s)"></i></span>
                            </div>
                        </td>
                    </tr>
                </table>
                <div v-if="type === 1">
                    <b-form-group label-for="athugasemd" label="Athugasemd"
                        :state="submitted && errors.has('athugasemd') ? false : ''"
                        :invalid-feedback="errors.first('athugasemd')">
                        <b-form-textarea id="athugasemd" name="athugasemd" v-model="singleComment" maxLength="1000"
                        style="width:50%"
                            :state="submitted && errors.has('athugasemd') ? false : ''" data-vv-as="athugasemd">
                        </b-form-textarea>
                    </b-form-group>
                </div>
                <b-btn variant="primary" class="mb-3 mt-3" :disabled="invalidCode() || saving" @click="saveDayAttendance()">
                    <i class="fa fa-spin fa-spinner" v-if="saving"></i>
                    Staðfesta <span v-if="type === 0">fjarvistir</span><span v-else>forföll</span>
                </b-btn>
                <div style="font-size: 13px" v-if="type === 0">
                    <b>Fjarvistarkóðar:</b>
                    <div v-for="a in attendanceCodes" :key="a.teg_fjarvista" class="pl-2 d-inline-block">{{ a.kodi }} -
                        {{ a.heiti }}</div>
                </div>
                <div style="font-size: 13px" class="mt-2" v-if="type === 1">
                    <b>Forfallakóðar:</b>
                    <div v-for="a in absenceCodes" :key="a.teg_forfalla" class="pl-2 d-inline-block">{{ a.kodi }} - {{
                        a.heiti
                    }}</div>
                </div>
            </div>
        </div>
    </Slidebar>
</template>

<script>
import moment from 'moment';
import types from '@/api/types';
import attendance from '@/api/attendance';
import Slidebar from '@/components/common/Slidebar.vue';
import Loader from '@/components/common/Loader.vue';
import students from '@/api/students';
import { mapGetters, mapActions } from 'vuex';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';
import MaskedInput from 'vue-masked-input';

export default {
    name: 'group-register-attendance-slidebar',
    props: ['studentId', 'termId', 'type'],
    components: {
        Loader,
        Slidebar,
        Datepicker,
        MaskedInput,
    },
    computed: {
        periodLength() {
            if (this.form.dateFrom && this.form.dateTo) {
                return moment(this.form.dateTo).diff(moment(this.form.dateFrom), 'days');
            }
            return 0;
        },
        getTypes() {
            return this.type === 0 ? this.attendanceCodes : this.absenceCodes;
        },
        selectedModules() {
            return this.modules.filter(x => x.selected);
        },
        state() {
            return this.submitted && this.selected.length > 0;
        },
        ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
    },
    data() {
        return {
            lang: is,
            period: false,
            loading: { attendanceCodes: false, absenceCodes: false, group: false, students: false, attendanceData: false, modules: false },
            submitted: false,
            attendanceCodes: [],
            absenceCodes: [],
            options: [],
            saving: false,
            form: {
                dateFrom: moment().toDate(),
                timeFrom: '',
                dateTo: moment().toDate(),
                timeTo: '',
                type: '',
                comment: '',
                onlyThisOne: '',
            },
            typeName: '',
            date: moment().toDate(),
            attendanceData: [],
            singleComment: '',
            commonCode: '',
            selectAll: false,
            selected: [],
            checkingData: false,
            savingFinal: false,
            alreadyRegistered: [],
        };
    },
    methods: {
        closeSlidebar() {
            this.$emit('closeSlidebar');
        },
        updateSelected() {
            if (this.selectAll) {
                this.selected = this.modules.map(x => x.afangi_id);
            } else {
                this.selected = [];
            }
        },

        async loadAttendance() {
            try {
                this.loading.attendanceCodes = true;
                const response = await types.getAttendanceList({ active: 1 });
                this.attendanceCodes = response.data.items.map(x => ({
                    ...x,
                    label: `${x.kodi} - ${x.heiti}`,
                }));
            } catch (e) {
                this.$log.error(e);
                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            } finally {
                this.loading.attendanceCodes = false;
            }
        },
        async loadAbsence() {
            try {
                this.loading.absenceCodes = true;
                const response = await types.getAbsenceList({ active: 1 });
                this.absenceCodes = response.data.items.map(x => ({
                    ...x,
                    label: `${x.kodi} - ${x.heiti}`,
                }));
            } catch (e) {
                this.$log.error(e);
                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            } finally {
                this.loading.absenceCodes = false;
            }
        },
        async validateBeforeSubmit() {
            try {
                this.submitted = true;
                this.saving = true;
                await this.$validator.validateAll().then(response => {
                    if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal, max-len
                });
                // engar villur í formi
                // Checkum hvort það séu einhverjar færslur skráðar á þetta tímabil.
                const data = {
                    moduleIds: this.selected.map(x => x).join(','),
                    dateFrom: moment(this.form.dateFrom).format('DD.MM.YYYY'),
                    dateTo: moment(this.form.dateTo).format('DD.MM.YYYY'),
                    timeFrom: this.form.timeFrom ? this.form.timeFrom : '00:00',
                    timeTo: this.form.timeTo ? this.form.timeTo : '23:59',
                    isAttendance: this.type === 0 ? 1 : '',
                    isAbsence: this.type === 1 ? 1 : '',
                }
                const response = await students.getStudentsAttendanceList(this.studentId, data);
                this.alreadyRegistered = response.data.items;
                this.checkingData = true; // birtagögn
            } catch (e) {
                // Villur í formi.
                this.$log.debug(e);
            } finally {
                this.saving = false;
            }
        },
        async saveAll() {
            this.savingFinal = true;
            // Engar villur í formi og notandi búin að samþyggja að yfirskrifa ef það voru færslur til að yfirskrifa
            try {
                const item = {
                    afangar: this.selected.map(x => x).join(','),
                    id: this.studentId,
                    dags_fra: moment(this.form.dateFrom).format('DD.MM.YYYY'),
                    timi_fra: this.form.timeFrom ? this.form.timeFrom : '00:00',
                    dags_til: moment(this.form.dateTo).format('DD.MM.YYYY'),
                    timi_til: this.form.timeTo ? this.form.timeTo : '23:59',
                    teg_fjarvista: this.type === 0 ? this.form.type.teg_fjarvista : null,
                    teg_forfalla: this.type === 1 ? this.form.type.teg_forfalla : null,
                    athugasemd: this.form.comment,
                };
                const response = await students.registerStudentAttendance(this.studentId, item);
                let text = '';
                if (this.type === 0) {
                    text = `${response.data.count} fjarvistir skráðar.`;
                } else {
                    text = `${response.data.count} forföll skráð.`;
                }
                this.displaySuccess(text);
                this.$emit('closeAndReloadSlidebar');
            } catch (e) {
                // Aðgerð mistókst.
                this.$log.debug(e);
                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            } finally {
                this.checkingData = false;
                this.savingFinal = false;
            }
        },
        async getAttendanceData() {
            try {
                this.loading.attendanceData = true;
                const response = await attendance.getRegisterStudentAttendanceData({
                    nemandi_id: this.studentId,
                    dags: moment(this.date).format('DD.MM.YYYY'),
                });
                this.attendanceData = response.data.items;
            } catch (e) {
                this.$log.error(e);
                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            } finally {
                this.loading.attendanceData = false;
            }
        },
        checkGetData() {
            if (!this.period && this.attendanceData.length === 0) {
                this.getAttendanceData();
            }
        },
        invalidCode() {
            return (
                this.attendanceData.filter(y => !this.attendanceValid(y.tegund_fjarvista) ||
                    !this.absenceValid(y.tegund_forfalla)).length > 0);
        },
        attendanceValid(code) {
            return !code || this.attendanceCodes.find(x => (x.kodi || '').toUpperCase() === code.toUpperCase());
        },
        absenceValid(code) {
            return !code || this.absenceCodes.find(x => (x.kodi || '').toUpperCase() === code.toUpperCase());
        },
        updateAll(code, nemandi) {
            nemandi.forEach(d => {
                if (this.type === 0) {
                    this.$set(d, 'tegund_fjarvista', code);
                } else if (this.type === 1) {
                    this.$set(d, 'tegund_forfalla', code);
                }
            });
        },
        async saveDayAttendance() {
            try {
                this.saving = true;
                const data = [];
                this.attendanceData.forEach(a => {
                    const item = {
                        ferill_id: a.ferill_id,
                        stofntafla_id: a.stofntafla_id,
                        stundatafla_id: a.stundatafla_id,
                        dags: moment(a.day).format('DD.MM.YYYY'),
                        athugasemd: this.singleComment,
                    };
                    if (this.type === 0) {
                        const exactMatch = this.attendanceCodes.find(x => x.kodi === a.tegund_fjarvista);
                        const caseInsensitiveMatch = this.attendanceCodes.find(
                            x => (x.kodi || '').toUpperCase() === (a.tegund_fjarvista || '').toUpperCase()
                        );

                        item.teg_fjarvista_id = (exactMatch || caseInsensitiveMatch || { teg_fjarvista: '' }).teg_fjarvista;
                    } else {
                        const exactMatch = this.absenceCodes.find(x => x.kodi === a.tegund_forfalla);
                        const caseInsensitiveMatch = this.absenceCodes.find(
                            x => (x.kodi || '').toUpperCase() === (a.tegund_forfalla || '').toUpperCase()
                        );

                        item.teg_forfalla_id = (exactMatch || caseInsensitiveMatch || { teg_forfalla: '' }).teg_forfalla;
                    }
                    data.push(item);
                });
                await attendance.saveGroupAttendanceData(data, 1);
                const text = this.type === 0 ? 'Fjarvistir skráðar' : 'Forföll skráð';

                this.displaySuccess(text);
                this.$emit('closeAndReloadSlidebar');
            } catch (e) {
                this.$log.error(e);
                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            } finally {
                this.saving = false;
            }
        },
        async clearStudentAbsence(student) {
            try {
                this.saving = true;
                const conf = confirm(`Ertu viss um að þú viljir hreinsa út forföll fyrir ${student.nafn}?`); // eslint-disable-line
                if (conf) {
                    await attendance.clearAbsence(student.fjarv_forfoll_id);
                    this.displaySuccess('Forföll hreinsuð út');
                    this.getAttendanceData();
                }
            } catch (e) {
                this.$log.error(e);
                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            } finally {
                this.saving = false;
            }
        },
        async loadModules(id) {
            try {
                this.loading.modules = true;
                const params = {
                    onn_id: this.termId,
                };
                const response = await students.getStudentModuleList(this.studentId, params);
                this.modules = response.data.items
                if (id) {
                    this.form.afangi = this.modules.find(x => x.afangi_id === id);
                }
            } catch (e) {
                this.$log.error(e);
                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            } finally {
                this.loading.modules = false;
            }
        },
        ...mapActions({
            displayError: 'displayError',
            displaySuccess: 'displaySuccess',
        }),
    },
    created() {
        this.getAttendanceData();
        this.loadAttendance();
        this.loadAbsence();
        this.loadModules();
        if (this.type === 0) {
            this.typeName = 'Tegund fjarvista*';
            this.form.onlyThisOne = { id: 0, label: 'Eingöngu skrá fjarvist fyrir þennan áfanga.' };
            this.options = [
                { id: 0, label: 'Eingöngu skrá fjarvist fyrir þennan áfanga.' },
                { id: 1, label: 'Skrá fjarvist fyrir alla áfanga.' },
            ];
        } else {
            this.typeName = 'Tegund forfalla*';
            this.form.onlyThisOne = { id: 0, label: 'Eingöngu skrá forföll fyrir þennan áfanga.' };
            this.options = [
                { id: 0, label: 'Eingöngu skrá forföll fyrir þennan áfanga.' },
                { id: 1, label: 'Skrá forföll fyrir alla áfanga.' },
            ];
        }
    },
};
</script>

<style lang="scss">
.attendanceInvalidInput {
    border: 1px solid red;
    color: red;
}

.attendanceValidInput {
    color: blue;
}
</style>
