<template>
  <page>
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <PageTitle title="Úrvinnsla töflubreytinga" />
    <b-row class="breadcumb-location">
      <b-col>
        <div class="float-right">
          <b-btn variant="primary" @click="openStudentSlidebar()">Velja nemanda </b-btn>
        </div>
      </b-col>
    </b-row>
    <b-card>
      <b-row>
        <b-col>
          <b-form-group label="Kennslutímabil" label-for="term">
            <v-select id="term" name="term" v-model="selected.term" :options="terms" :clearable="false"> </v-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Nafn" label-for="name">
            <b-form-input id="name" name="name" v-model="selected.name" :disabled="selected.type == 1" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Kennitala" label-for="ssn">
            <b-form-input id="ssn" name="ssn" v-model="selected.ssn" :disabled="selected.type == 1" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Nemendanr" label-for="studentNr">
            <b-form-input id="studentNr" name="studentNr" v-model="selected.studentNr" :disabled="selected.type == 1" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Staða nema" label-for="status">
            <v-select id="status" name="status" v-model="selected.status" :options="statuses" label="heiti" :disabled="selected.type == 1">
            </v-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Deild" label-for="department">
            <v-select id="department" name="department" v-model="selected.department" :options="departments" label="name"> </v-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Braut" label-for="course">
            <v-select id="course" name="course" v-model="selected.course" :options="courses" :disabled="selected.type == 1"> </v-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Áfangi" label-for="module">
            <b-form-input id="module" name="module" v-model="selected.module" :disabled="selected.type == 0" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Listi" class="d-inline-block">
            <b-form-radio v-model="selected.type" value="0" @input="reset()" class="d-inline-block pr-4">Nemendur</b-form-radio>
            <b-form-radio v-model="selected.type" value="1" @input="reset()" class="d-inline-block">Áfangar</b-form-radio>
          </b-form-group>
          <b-form-group label="Sýna afgreiddar" class="d-inline-block float-right">
            <b-form-checkbox v-model="selected.showAll" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Byrjunardagsetning hópa" label-for="date_from">
            <datepicker
              id="date_from"
              name="date_from"
              v-model="selected.date_from"
              :language="lang"
              :monday-first="true"
              format="dd.MM.yyyy"
              :typeable="false"
              :clearButton="true"
              ref="dateFromOpenPickr"
            ></datepicker>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Endadagsetning hópa" label-for="date_to">
            <datepicker
              id="date_to"
              name="date_to"
              v-model="selected.date_to"
              :language="lang"
              :monday-first="true"
              format="dd.MM.yyyy"
              :typeable="false"
              :clearButton="true"
              ref="dateToOpenPickr"
            ></datepicker>
          </b-form-group>
        </b-col>
        <b-col>
          <b-btn variant="primary" class="w-100" style="margin-top: 20px" @click="search()" :disabled="!selected.term">Leita</b-btn>
        </b-col>
      </b-row>
    </b-card>
    <br />
    <b-card v-if="firsttime">
      <Loader v-if="loading.list"></Loader>
      <table class="table table-hover" v-if="selected.type === '0' && !loading.list">
        <thead>
          <tr>
            <th v-if="canEdit" style="width: 2%; white-space: nowrap">
              <b-form-checkbox v-model="selectAll" @change="updateSelectAll()" class="d-inline-block"></b-form-checkbox>
              <b-btn
                variant="danger"
                class="btn-xs d-inline-block"
                style="font-size: 13px"
                @click="deleteRequests()"
                v-if="selectedRequests.length > 0"
              >
                <i class="fa fa-trash"></i
              ></b-btn>
            </th>
            <th>Nr.</th>
            <th><LinkOrder title="Nafn" :filteredList="list" :list="list" column="nafn" @notify="updateSort" /></th>
            <th>
              <LinkOrder title="Kennitala" :filteredList="list" :list="list" column="kennitala" secondColumn="nafn" @notify="updateSort" />
            </th>
            <th>
              <LinkOrder title="Nemendanr" :filteredList="list" :list="list" column="nemandi_id" secondColumn="nafn" @notify="updateSort" />
            </th>
            <th>
              <LinkOrder
                title="Staða nema"
                :filteredList="list"
                :list="list"
                column="stada_nema"
                secondColumn="nafn"
                @notify="updateSort"
              />
            </th>
            <th>
              <LinkOrder title="Braut" :filteredList="list" :list="list" column="braut_nema" secondColumn="nafn" @notify="updateSort" />
            </th>
            <th>
              <LinkOrder
                title="Dags. skráð"
                :filteredList="list"
                :list="list"
                column="dags_sort"
                secondColumn="nafn"
                @notify="updateSort"
              />
            </th>
            <th>
              <LinkOrder
                title="Óafgreidd"
                :filteredList="list"
                :list="list"
                column="fjoldi_oafgreitt"
                secondColumn="nafn"
                @notify="updateSort"
              />
            </th>
            <th>
              <LinkOrder
                title="Samtals"
                :filteredList="list"
                :list="list"
                column="fjoldi_beidna"
                secondColumn="nafn"
                @notify="updateSort"
              />
            </th>
            <th>
              <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="cursor-pointer float-right">
                <i class="fa fa-file-excel-o excelText"></i>
              </download-csv>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in list" :key="index">
            <td v-if="canEdit" style="width: 2%"><b-form-checkbox v-model="item.selected"></b-form-checkbox></td>
            <td>{{ index + 1 }}</td>
            <td>
              <b-link
                :to="{
                  name: 'StudentRequests',
                  params: { term: selected.term.onn_id, student: item.nemandi_id },
                  query: { dateFrom: getDateFrom, dateTo: getDateTo, query: getQueryParams },
                }"
              >
                {{ item.nafn }}
              </b-link>

              <b-link
                :to="{ name: 'Student', params: { id: item.nemandi_id } }"
                target="_blank"
                v-if="canViewStudent"
                title="Skoða nemanda"
                v-b-tooltip
                ><i class="fa fa-external-link ml-1"></i
              ></b-link>
            </td>
            <td>{{ item.kennitala }}</td>
            <td>{{ item.nemandi_id }}</td>
            <td>{{ item.stada_nema }}</td>
            <td>{{ item.braut_nema }}</td>
            <td>{{ item.dags | moment('DD.MM.YYYY HH:mm:ss') }}</td>
            <td>{{ item.fjoldi_oafgreitt }}</td>
            <td>{{ item.fjoldi_beidna }}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <table class="table table-hover" v-if="selected.type === '1' && !loading.list">
        <thead>
          <tr>
            <th v-if="canEdit" style="width: 2%; white-space: nowrap">
              <b-form-checkbox v-model="selectAll" @change="updateSelectAll()" class="d-inline-block"></b-form-checkbox>
              <b-btn
                variant="danger"
                class="btn-xs d-inline-block"
                style="font-size: 13px"
                @click="deleteRequests()"
                v-if="selectedRequests.length > 0"
              >
                <i class="fa fa-trash"></i
              ></b-btn>
            </th>
            <th>Nr.</th>
            <th><LinkOrder title="Áfangi" :filteredList="list" :list="list" column="namsgrein_afanganr" /></th>
            <th class="text-center" title="Fjöldi beiðna um að bæta áfanganum við" v-b-tooltip>
              <LinkOrder title="Fjöldi" :filteredList="list" :list="list" column="fjoldi_i_hop" @notify="updateSort" />
              <i class="fa fa-fw fa-plus-circle text-success" />
            </th>
            <th class="text-center" title="Fjöldi beiðna um að fjarlægja áfangann" v-b-tooltip>
              <LinkOrder title="Fjöldi" :filteredList="list" :list="list" column="fjoldi_ur_hop" @notify="updateSort" />
              <i class="fa fa-fw fa-trash text-danger" />
            </th>
            <th class="text-center" title="Heildarfjöldi beiðna" v-b-tooltip>
              <LinkOrder title="Alls" :filteredList="list" :list="list" column="alls" @notify="updateSort" />
            </th>
            <th class="text-center" title="Fjöldi óafgreiddra beiðna um að bæta áfanganum við" v-b-tooltip>
              <LinkOrder title="Óafgreidd" :filteredList="list" :list="list" column="fjoldi_oafgreitt_i_hop" @notify="updateSort" />
              <i class="fa fa-fw fa-plus-circle text-success" />
            </th>
            <th class="text-center" title="Fjöldi óafgreiddra beiðna um að fjarlægja áfangann" v-b-tooltip>
              <LinkOrder title="Óafgreidd" :filteredList="list" :list="list" column="fjoldi_oafgreitt_ur_hop" @notify="updateSort" />
              <i class="fa fa-fw fa-trash text-danger" />
            </th>
            <th class="text-center" title="Heildarfjöldi óafgreiddra beiðna" v-b-tooltip>
              <LinkOrder title="Alls" :filteredList="list" :list="list" column="fjoldi_oafgreitt" @notify="updateSort" />
            </th>
            <th>
              <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="cursor-pointer float-right">
                <i class="fa fa-file-excel-o excelText"></i>
              </download-csv>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in list" :key="index">
            <td v-if="canEdit"><b-form-checkbox v-model="item.selected"></b-form-checkbox></td>
            <td>{{ index + 1 }}</td>
            <td>
              <b-link
                :to="{
                  name: 'ModuleRequests',
                  params: { moduleTermId: item.annir_afangar_id },
                  query: { dateFrom: getDateFrom, dateTo: getDateTo, query: getQueryParams },
                }"
              >
                {{ item.namsgrein_afanganr }}
              </b-link>
            </td>
            <td class="text-center">{{ item.fjoldi_i_hop }}</td>
            <td class="text-center">{{ item.fjoldi_ur_hop }}</td>
            <td class="text-center">{{ item.alls }}</td>
            <td class="text-center">{{ item.fjoldi_oafgreitt_i_hop }}</td>
            <td class="text-center">{{ item.fjoldi_oafgreitt_ur_hop }}</td>
            <td class="text-center">{{ item.fjoldi_oafgreitt }}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </b-card>
    <student-search-slidebar @closeSlidebar="selectStudent" v-if="showStudentSlidebar"> </student-search-slidebar>
  </page>
</template>

<script>
import terms from '@/api/terms';
import structure from '@/api/structure';
import curriculum from '@/api/curriculum';
import students from '@/api/students';
import requests from '@/api/requests';

import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import { sortMixin } from '@/utils';

import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';

import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import PageTitle from '@/components/common/PageTitle.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';
import Loader from '@/components/common/Loader.vue';

import StudentSearchSlidebar from '@/components/students/student/StudentSearchSlidebar.vue';

export default {
  name: 'change-timetable-requests',
  components: {
    Breadcrumbs,
    PageTitle,
    LinkOrder,
    Loader,
    Datepicker,
    StudentSearchSlidebar,
  },
  mixins: [sortMixin],
  computed: {
    getQueryParams() {
      return JSON.stringify(this.$route.query);
    },
    getExcelData() {
      if (this.selected.type === '0') {
        const newList = [
          [],
          ['Nemendur - Töflubreyting'],
          [],
          ['Nafn', 'Kennitala', 'NemendaNr', 'Staða nema', 'Braut', 'Dags.skráð', 'Óafgreidd', 'Samtals'],
          ...this.list.map(x => [
            x.nafn,
            `="${x.kennitala}"`,
            x.nemandi_id,
            x.stada_nema,
            x.braut_nema,
            moment(x.dags).format('DD.MM.YYYY HH:mm'),
            x.fjoldi_oafgreitt,
            x.fjoldi_beidna,
          ]),
        ];
        return newList;
      }
      const newList = [
        [],
        ['Áfangar - Töflubreyting'],
        [],
        [
          'Áfangi',
          'Fjöldi bæta við',
          'Fjöldi fjarlægja',
          'Heildarfjöldi beiðna',
          'Fjöldi óafgreitt bæta við',
          'Fjöldi óafgreittfjarlægja',
          'Heildarfjöldi óafgreitt',
          'Óafgreidd',
        ],
        ...this.list.map(x => [
          x.namsgrein_afanganr,
          x.fjoldi_i_hop,
          x.fjoldi_ur_hop,
          x.alls,
          x.fjoldi_oafgreitt_i_hop,
          x.fjoldi_oafgreitt_ur_hop,
          x.alls,
          x.fjoldi_oafgreitt,
        ]),
      ];
      return newList;
    },
    getExcelFileName() {
      if (this.selected.type === '0') {
        return `toflubreyting_nemendur_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
      }

      return `toflubreyting_afangar_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    getDateFrom() {
      return this.selected.date_from ? moment(this.selected.date_from).format('DD.MM.YYYY') : '';
    },
    getDateTo() {
      return this.selected.date_to ? moment(this.selected.date_to).format('DD.MM.YYYY') : '';
    },
    selectedRequests() {
      return this.list.filter(x => x.selected);
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('beidnir_toflubreytingar');
    },
    canViewStudent() {
      return this.loggedInUserHasWritePermission('nemendahald_nemendur');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      lang: is,
      breadcrumbs: [
        { name: 'Forsíða', route: 'Dashboard' },
        { name: 'Beiðnir', route: '' },
        { name: 'Úrvinnsla töflubreytinga', route: 'ChangeTimetableRequests' },
      ],
      loading: {
        terms: false,
        departments: false,
        courses: false,
        statuses: false,
        list: false,
      },
      firsttime: false,
      showStudentSlidebar: false,
      terms: [],
      departments: [],
      courses: [],
      statuses: [],
      list: [],
      selected: {
        term: '',
        name: '',
        ssn: '',
        studentNr: '',
        department: '',
        course: '',
        module: '',
        status: '',
        type: '0',
        showAll: false,
        date_from: '',
        date_to: '',
      },
      selectAll: false,
      currentSort: '',
      firstTimeSearch: true,
    };
  },
  methods: {
    reset() {
      this.firsttime = false;
      this.list = [];
    },
    updateSort(item) {
      if (this.currentSort === item.column) {
        this.currentSort = `-${this.currentSort}`;
      } else if (this.currentSort === `-${item.column}`) {
        this.currentSort = item.column;
      } else {
        this.currentSort = item.column;
      }
      const queries = JSON.parse(JSON.stringify(this.$route.query));
      queries.sort = this.currentSort;
      this.$router.replace({ query: queries });
    },
    updateQuery() {
      const queries = JSON.parse(JSON.stringify(this.$route.query));
      queries.type = this.selected.type;
      queries.term = this.selected.term ? this.selected.term.onn_id.toString() : '';
      queries.name = this.selected.name;
      queries.ssn = this.selected.ssn;
      queries.nr = this.selected.studentNr;
      queries.dept = this.search.department ? this.search.department.department_id.toString() : '';
      queries.course = this.search.course ? this.search.course.braut_id.toString() : '';
      queries.module = this.selected.module;
      queries.status = this.search.status ? this.search.status.eink_stody.toString() : '';
      queries.all = this.selected.showAll ? '1' : '0';
      queries.from = this.selected.date_from ? moment(this.selected.date_from).format('DD.MM.YYYY') : '';
      queries.to = this.selected.date_to ? moment(this.selected.date_to).format('DD.MM.YYYY') : '';
      if (JSON.stringify(this.$route.query) !== JSON.stringify(queries)) {
        this.$router.replace({ query: queries });
      }
    },
    async loadData() {
      const queries = this.$route.query;
      this.selected.name = queries.name || '';
      this.selected.ssn = queries.ssn || '';
      this.selected.studentNr = queries.nr || '';
      this.selected.module = queries.module || '';
      this.selected.type = queries.type || '0';
      this.selected.showAll = queries.all === '1' || false;
      this.selected.date_from = queries.from ? moment(queries.from, 'DD.MM.YYYY').toDate() : '';
      this.selected.date_to = queries.to ? moment(queries.to, 'DD.MM.YYYY').toDate() : '';
      this.currentSort = queries.sort || '';

      await this.loadTerms(queries.term);
      await this.loadDepartments(queries.dept);
      await this.loadCourses(queries.course);
      await this.loadStatuses(queries.status);
      this.$nextTick(() => {
        if (Object.keys(queries).length > 0) {
          this.search();
        }
      });
    },
    async loadTerms(id) {
      try {
        this.loading.terms = true;
        const response = await terms.getTerms();
        this.terms = response.data.items.map(x => ({
          ...x,
          label: `${x.undirsk_audk} - ${x.onn_heiti}`,
        }));
        if (id && this.terms.find(x => x.onn_id === id)) {
          this.selected.term = this.terms.find(x => x.onn_id === id);
        } else {
          this.selected.term = this.terms.find(x => x.is_default === 1);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.terms = false;
      }
    },
    async loadDepartments(id) {
      try {
        this.loading.departments = true;
        const response = await structure.getDepartmentList();
        this.departments = response.data.items;
        if (id && this.departments.find(x => x.department_id === id)) {
          this.selected.department = this.departments.find(x => x.department_id === id);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.departments = false;
      }
    },
    async loadCourses(id) {
      try {
        this.loading.courses = true;
        const response = await curriculum.getCourseList();
        this.courses = response.data.items.map(x => ({
          ...x,
          label: `${x.heiti} ${x.braut_kodi}`,
        }));
        if (id && this.courses.find(x => x.braut_id === id)) {
          this.selected.course = this.courses.find(x => x.braut_id === id);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.courses = false;
      }
    },
    async loadStatuses(id) {
      try {
        this.loading.statuses = true;
        const response = await students.getStudentsStatuses();
        this.statuses = response.data.items;
        if (id && this.statuses.find(x => x.eink_stodu === id)) {
          this.selected.status = this.statuses.find(x => x.eink_stodu === id);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.statuses = false;
      }
    },
    async search() {
      try {
        this.list = [];
        this.loading.list = true;
        this.firsttime = true;
        this.selectAll = false;
        this.updateQuery();
        if (this.selected.type === '0') {
          // Nemendur
          const item = {
            nafn: this.selected.name,
            kennitala: this.selected.ssn,
            nemandi_id: this.selected.studentNr,
            stada_nema: this.selected.status ? this.selected.status.eink_stodu : '',
            deild_id: this.selected.department ? this.selected.department.department_id : '',
            braut_id: this.selected.course ? this.selected.course.braut_id : '',
            afgreitt: this.selected.showAll ? 1 : '',
            dagsFra: this.selected.date_from ? moment(this.selected.date_from).format('DD.MM.YYYY') : '',
            dagsTil: this.selected.date_to ? moment(this.selected.date_to).format('DD.MM.YYYY') : '',
          };
          const response = await requests.getTimetableRequestsStudentList(this.selected.term.onn_id, item);
          this.list = response.data.items.map(x => ({
            ...x,
            dags_sort: moment(x.dags).format('YYYYMMDDHHmmss'),
            selected: false,
          }));
        } else if (this.selected.type === '1') {
          // Áfangar
          const item = {
            afangi: this.selected.module,
            deild_id: this.selected.department ? this.selected.department.department_id : '',
            afgreitt: this.selected.showAll ? 1 : '',
            dagsFra: this.selected.date_from ? moment(this.selected.date_from).format('DD.MM.YYYY') : '',
            dagsTil: this.selected.date_to ? moment(this.selected.date_to).format('DD.MM.YYYY') : '',
          };
          const response = await requests.getTimetableRequestsModuleList(this.selected.term.onn_id, item);
          this.list = response.data.items.map(x => ({
            ...x,
            alls: x.fjoldi_i_hop + x.fjoldi_ur_hop,
            selected: false,
          }));
        }
        // Ef þú ert að bakka eða koma úr stakri beiðni og order kemur með á viljum við raða
        if (this.currentSort && this.firstTimeSearch) {
          this.firstTimeSearch = false;
          const column = (this.currentSort || '').replace('-', '');
          const secondColumn = (this.currentSort || '').indexOf('nafn') === -1 && this.selected.type === '0' ? 'nafn' : undefined;
          this.sortList(this.list, this.list, column, secondColumn);
          if (this.currentSort.indexOf('-') > -1) {
            this.sortList(this.list, this.list, column, secondColumn);
          }
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.list = false;
      }
    },
    openStudentSlidebar() {
      this.showStudentSlidebar = true;
    },
    selectStudent(item) {
      if (item) {
        this.$router.push({
          name: 'StudentRequests',
          params: { term: this.selected.term.onn_id, student: item },
          query: { dateFrom: this.getDateFrom, dateTo: this.getDateTo },
        });
      }
      this.showStudentSlidebar = false;
    },
    updateSelectAll() {
      this.list.forEach(item => {
        this.$set(item, 'selected', this.selectAll);
      });
    },
    async deleteRequests() {
      try {
        // eslint-disable-next-line no-restricted-globals, no-alert
        const conf = confirm(
          `Ertu viss um að þú viljir hafna eftirfarandi ${this.selectedRequests.length} beiðnum ? Ath. ekki er sendur póstur.`,
        ); // eslint-disable-line
        if (conf) {
          const item = {
            onn_id: this.selected.term.onn_id,
          };
          if (this.selected.type === '0') {
            item.nemendur = this.selectedRequests.map(x => x.nemandi_id).join(',');
          } else {
            item.afangar = this.selectedRequests.map(x => x.annir_afangar_id).join(',');
          }
          await requests.massRejectTimetableRequests(item);
          this.displaySuccess('Beiðnum eytt');
          this.search();
        }
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
      } finally {
        this.addingModule = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadData();
  },
};
</script>
