<template>
  <Slidebar :autoClose="true" @closeMe="closeSlidebar">
    <h4>Nemendaleit</h4>
    <br />
    <StudentSearch @changeStudent="closeSlidebar" :slidebar="true" :advancedSearch="advancedSearch" />
  </Slidebar>
</template>

<script>
import { mapActions } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';
import StudentSearch from '@/components/students/student/StudentSearch.vue';

export default {
  name: 'student-search-slide-bar',
  props: ['advancedSearch'],
  components: {
    Slidebar,
    StudentSearch,
  },
  data() {
    return {
      firsttime: false,
      loading: false,
      list: [],
      search: {
        search: '',
      },
    };
  },
  methods: {
    closeSlidebar(item, advancedSearch) {
      this.$emit('closeSlidebar', item, advancedSearch);
    },
    editItem() {
      this.$emit('editItem');
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.list = [];
    this.search.search = '';
  },
};
</script>

<style lang="scss"></style>
