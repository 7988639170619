<template>
  <Slidebar :autoClose="true" @closeMe="closeSlidebar" large="true">
    <h4>Afrita uppbyggingu</h4>
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <b-form-group label="Braut" label-for="braut">
        <v-select id="braut" name="braut" v-model="form.braut" :clearable="false" :options="getCourses" @input="loadStructure()">
        </v-select>
      </b-form-group>
      <b-form-checkbox v-model="showAll" class="d-inline-block"></b-form-checkbox> Birta brautir í öðrum undirskólum
      <div class="alert alert-danger p-2 mt-2" v-if="getNotSameDivision">
        <i class="fa fa-exclamation-triangle"></i>
        Ath. Þegar þú afritar uppbyggingu af braut í öðrum undirskóla þá verða áfangarnir stofnaðir í undirskóla brautarinnar sem þú ert að
        afrita á.
      </div>

      <div v-if="structure.length > 0" class="mb-2 mt-2" style="font-size: 17px">Uppbygging valinnar brautar</div>
      <div v-for="s in structure" :key="s.braut_uppbygg_id" class="mb-2">
        <strong>
          <!--b-form-checkbox class="d-inline-block" v-model="s.selected" @input="updateSelectAll(s)"></b-form-checkbox-->
          {{ s.uppbygging_heiti }} <span v-if="s.einingar">- {{ s.einingar }} einingar</span></strong
        >
        <div v-if="s.afangar.length === 0"><i>Engir áfangar í uppbyggingu </i></div>
        <div v-for="a in s.afangar" class="ml-3" :key="`${s.braut_uppbygg_id}-${a.afangi_id}`" style="font-size: 13px">
          <b-form-checkbox class="d-inline-block" v-model="a.selected"></b-form-checkbox> {{ a.namsgrein_afanganr }}
        </div>
      </div>
      <b-btn variant="primary" @click="copyStructure()" class="d-block mt-2" :disabled="saving || !form.braut">
        <i class="fa fa-spin fa-spinner" v-if="saving"></i> Afrita</b-btn
      >
    </b-form>
  </Slidebar>
</template>

<script>
import curriculum from '@/api/curriculum';
import { mapGetters, mapActions } from 'vuex';
import Slidebar from '@/components/common/Slidebar.vue';

export default {
  name: 'courses-structure-copy-slidebar',
  components: {
    Slidebar,
  },
  props: ['courseId', 'copyId'],
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_brautir');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('namskra_brautir');
    },
    getCourses() {
      return this.courses.filter(x => this.showAll || x.undirskoli_id === this.divisionId);
    },
    getNotSameDivision() {
      return this.form.braut && this.form.braut.undirskoli_id !== this.divisionId;
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      divisionId: '',
      saving: false,
      loading: false,
      courses: [],
      structure: [],
      showAll: false,
      form: {
        braut: '',
      },
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    updateSelectAll(item) {
      item.afangar.forEach(afangi => {
        this.$set(afangi, 'selected', item.selected);
      });
    },
    async loadCourse() {
      try {
        this.loading = true;
        const response = await curriculum.getCourseById(this.courseId);
        const item = response.data.items[0];
        this.divisionId = item.undirskoli_id;
        this.loadCourses();
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadCourses() {
      try {
        this.loading = true;
        const response = await curriculum.getCourseList({ active: 1, hasStructure: 1 });
        this.courses = response.data.items.map(x => ({
          label: `${x.heiti} - ${x.braut_kodi}`,
          braut_id: x.braut_id,
          undirskoli_id: x.undirskoli_id,
        }));
        if (this.copyId) {
          this.form.braut = this.courses.find(x => x.braut_id === parseInt(this.copyId, 10));
          if (this.form.braut) {
            if (this.form.braut.undirskoli_id !== this.divisionId) {
              this.showAll = true;
            }
            this.loadStructure();
          }
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadStructure() {
      try {
        const response = await curriculum.getCourseStructureModules(this.form.braut.braut_id);
        this.structure = response.data.items.map(x => ({ ...x, afangar: x.afangar.map(y => ({ ...y, selected: true })), selected: true }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async copyStructure() {
      try {
        this.saving = true;
        const skipModules = this.structure
          .map(x => x.afangar.filter(y => !y.selected).map(y => y.braut_afangar_id))
          .filter(x => x.length > 0)
          .join(',');
        await curriculum.copyCourseStructure(this.courseId, this.form.braut.braut_id, { skip_modules: skipModules });
        this.$emit('closeAndReloadSlidebar');
      } catch (e) {
        this.$log.error(e);

        if (e.response && e.response.data && e.response.data.error) {
          this.displayError(e.response.data.error);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } finally {
        this.saving = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadCourse();
  },
};
</script>

<style lang="scss"></style>
