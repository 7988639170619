<template>
    <div class="pt-3">
        <Loader v-if="loading.data"></Loader>
        <div>
            Grunnskóli: <b>{{ studentElementarySchool }}</b>
        </div>
        <div v-if="data.length > 0" class="pb-3">
            Útskriftarár: <b>{{ data[0].grunnskoli_ar }}</b>
        </div>
        
        <table class="table less-padding table-hover table-bordered-header" style="max-width:97%">
            <thead>
                <tr>
                    <th>Fag <span v-if="data.length > 0">({{ data.length }})</span></th>
                    <th>Skólaeinkunn</th>
                    <th>Áætlun</th>
                    <th>Samræmd einkunn </th>
                    <th>Umsögn</th>
                    <th>Matsviðmið</th>
                </tr>
            </thead>
            <tbody v-if="loading.data">
                <td colspan="100%" class="text-center">
                    <Loader></Loader>
                </td>
            </tbody>
            <tbody style="border-width: 1px" v-if="!loading.grades">
                <tr v-for="(d, index) in data" :key="index">
                    <td> {{ d.namsgrein }} </td>
                    <td> <span v-if="d.einkunn_skoli">
                            {{ d.einkunn_skoli }}
                        </span>
                        <span v-else>
                            {{ d.einkunn_skoli_a }}
                        </span>
                    </td>
                    <td>{{ d.aetlun_heiti }}</td>
                    <td> {{ d.einkunn_samraemd }} </td>
                    <td class="w-25"> {{ d.umsogn }} </td>
                    <td>{{ d.matsvidmid }} {{ d.matsvidmid_uri }}</td>

                </tr>
            </tbody>
        </table>
    </div>
</template>
  
<script>
import students from '@/api/students';
// import common from '@/api/common';
import { mapActions, mapGetters } from 'vuex';

import Loader from '@/components/common/Loader.vue';

export default {
    name: 'student-attendance',
    props: ['studentId', "studentSSn", "studentElementarySchool"],
    components: {
        Loader,
    },
    computed: {
        canEdit() {
            return this.loggedInUserHasWritePermission('nemendahald_nemendur');
        },
        ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
    },
    data() {
        return {
            loading: {
                data: false,
            },
            data: [],
        };
    },
    methods: {

        async loadElementaryGrades() {
            try {
                this.loading.data = true;

                const response = await students.getStudentElementaryGrades(this.studentSSn);
                // this.studentSSn
                this.data = response.data.items;
            } catch (e) {
                this.$log.error(e);
                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            } finally {
                this.loading.data = false;
            }
        },
        ...mapActions({
            displayError: 'displayError',
            displaySuccess: 'displaySuccess',
        }),
    },
    created() {
        this.loadElementaryGrades();
    },
};
</script>
<style></style>
  