<template>
  <page> </page>
</template>

<script>
import test from '@/api/test';
import { mapGetters } from 'vuex';
import { is } from 'vuejs-datepicker/dist/locale';

export default {
  name: 'HelloWorld',
  computed: {
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {
      regexTime: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
      lang: is,
      savedShuffle: [],
      file: null,
      parsed: [],
      shuffleList: [
        {
          id: 1,
          name: 'Thing 1',
        },
        {
          id: 2,
          name: 'Thing 2',
        },
        {
          id: 3,
          name: 'Thing 3',
        },
        {
          id: 4,
          name: 'Thing 4',
        },
        {
          id: 5,
          name: 'Thing 5',
        },
        {
          id: 6,
          name: 'Thing 6',
        },
      ],
    };
  },
  methods: {
    async openUrl() {
      try {
        const response = await test.getUrl();
        this.item = response.data;
        if (this.item.id) {
          const url = `/api/school/test?id=${this.item.id}`;
          window.open(url, '_blank');
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
  },
  created() {
    this.$store.commit('closeCategory');
  },
};
</script>
