<template>
  <Slidebar :autoClose="false" @closeMe="closeSlidebar" :extraLarge="true">
    <h4>Afrita einkunnareglu</h4>
    <div class="pb-2">1. Veldu áfanga sem þú vilt afrita reglu frá</div>
    <TermModuleSearch
      :defaultTerm="id"
      :divisionId="divisionId"
      v-show="!hide"
      @selectModule="selectModule"
      :showGradeRule="true"
    ></TermModuleSearch>
    <div v-if="hide" class="mb-2" style="font-size: 17px">
      {{ selectedItem ? `${selectedItem.onn_eink} - ${selectedItem.namsgrein_afanganr}` : '' }}
      <b-btn variant="primary" class="btn-xs" @click="hide = false" v-b-tooltip title="Velja annan áfanga" style="margin-top: -5px">
        <i class="fa fa-pencil"></i>
      </b-btn>
    </div>

    <div v-if="hide && selectedItem">
      <GradeRuleList
        v-if="hide && selectedItem"
        :edit="false"
        :termModuleId="selectedItem.annir_afangar_id"
        :id="`CopyGRList${selectedItem.annir_afangar_id}`"
      ></GradeRuleList>

      <div class="mb-2">2. Finndu áfanga sem þú vilt afrita á</div>
      <Shuttle
        :list="all"
        :value="selected"
        titleFrom="Fjöldi áfanga sem eru ekki valdir"
        titleTo="Fjöldi valda áfanga"
        typeName="áfanga"
        :searchAvailable="searchModule"
        :size="10"
      ></Shuttle>
      <div class="mb-2 mt-3">3. Viltu yfirskrifa ef það er nú þegar einkunnaregla skráð á áfangann?</div>
      <b-form-radio-group stacked id="overwrite" v-model="overwrite" name="searchBy" class="d-inline-block">
        <b-form-radio :value="true">Já. Vil að einkunnaregla sem er nú þegar skráð sé eytt.</b-form-radio>
        <b-form-radio :value="false">Nei. Vil bara bæta við þar sem vantar.</b-form-radio>
      </b-form-radio-group>
      <div class="mt-3">
        <b-btn variant="primary" :disabled="selected.length === 0 || saving" @click="copyGraderule()">Afrita</b-btn>
      </div>
    </div>
  </Slidebar>
</template>

<script>
import terms from '@/api/terms';
import { mapActions } from 'vuex';
import Slidebar from '@/components/common/Slidebar.vue';
import TermModuleSearch from '@/components/school/terms/TermModuleSearch.vue';
import GradeRuleList from '@/components/school/terms/graderules/GradeRuleList.vue';
import Shuttle from '@/components/common/Shuttle.vue';

export default {
  name: 'copy-graderule-slidebar',
  components: {
    Slidebar,
    TermModuleSearch,
    GradeRuleList,
    Shuttle,
  },
  props: ['id', 'divisionId', 'searchModule'],
  data() {
    return {
      hide: false,
      selectedItem: '',
      selected: [],
      all: [],
      loading: false,
      overwrite: false,
      saving: false,
    };
  },
  methods: {
    closeSlidebar(item) {
      this.$emit('closeSlidebar', item);
    },
    async loadModules() {
      try {
        this.loading = true;
        const response = await terms.getTermModules(this.id);
        this.all = response.data.items.map(x => ({
          id: x.annir_afangar_id,
          name: `${x.namsgrein_afanganr}, ${x.afanga_heiti}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    selectModule(item) {
      if (item) {
        this.selectedItem = item;
        this.hide = true;
      } else {
        this.hide = false;
      }
    },
    async copyGraderule() {
      try {
        this.saving = true;
        const item = {
          module_from: this.selectedItem.annir_afangar_id,
          modules_to: this.selected,
          overwrite: this.overwrite ? 1 : 0,
        };
        await terms.copyGraderule(item);
        this.displaySuccess('Einkunnareglur afritaðar');

        this.closeSlidebar();
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.saving = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadModules();
  },
};
</script>

<style lang="scss"></style>
