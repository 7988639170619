<template>
  <div>
    <b-row>
      <b-col class="pl-0" :class="{ overflowX: tabIndex === 'precursor' || tabIndex === 'equal' }">
        <ModuleSettings v-if="tabIndex === 'settings'" :id="id" :item="item" @refresh="loadDetails()"></ModuleSettings>
        <ModulePrecursor v-if="tabIndex === 'precursor'" :id="id" :divisionId="item.undirskoli_id"></ModulePrecursor>
        <ModuleEquivalent v-if="tabIndex === 'equal'" :id="id" :divisionId="item.undirskoli_id"></ModuleEquivalent>
        <ModuleParallel v-if="tabIndex === 'parallel'" :id="id" :divisionId="item.undirskoli_id"></ModuleParallel>
        <ModuleCourse v-if="tabIndex === 'courses'" :id="id" :module="item"></ModuleCourse>
        <ModuleDescription
          :class="{ 'pt-4': tabIndex === 'settings' }"
          v-if="tabIndex === 'description' || (tabIndex === 'settings' && schoolHasSettings('stundatoflukerfi'))"
          :id="id"
        ></ModuleDescription>
        <ModuleHistory v-if="tabIndex === 'history' && item" :id="id" :item="item"></ModuleHistory>
        <div v-if="tabIndex === 'timetable'" class="pb-2" style="margin-top: -5px">
          Hér má sjá stundatöflu áfangans, hægt er að velja að skoða stundatölfuna fyrir mánuð, viku eða dag með því að smella á hnappana
          hægra megin. Til að skoða stundatöflu aftur í tímann eða fram þá er hægt að smella á örvarnar vinstra megin
        </div>
        <Timetable v-if="tabIndex === 'timetable' && item" :id="id" type="module"></Timetable>
      </b-col>
      <b-col cols="2">
        <div class="right-side-nav noprint">
          <b-nav vertical>
            <b-nav-item v-if="canEdit" :to="{ name: 'CreateModule', query: { copy: id } }" style="color: inherit"> Afrita </b-nav-item>
            <b-nav-item @click.prevent="changeTab('courses')" :active="tabIndex === 'courses'">Brautir</b-nav-item>
            <b-nav-item @click.prevent="changeTab('equal')" :active="tabIndex === 'equal'">Jafngildi</b-nav-item
            ><b-nav-item
              v-if="!schoolHasSettings('stundatoflukerfi')"
              @click.prevent="changeTab('description')"
              :active="tabIndex === 'description'"
              >Lýsing</b-nav-item
            >
            <b-nav-item @click.prevent="changeTab('history')" :active="tabIndex === 'history'">Saga</b-nav-item>
            <b-nav-item @click.prevent="changeTab('parallel')" :active="tabIndex === 'parallel'">Samhliða</b-nav-item>
            <b-nav-item @click.prevent="changeTab('timetable')" :active="tabIndex === 'timetable'">Stundatafla</b-nav-item>
            <b-nav-item
              @click.prevent="
                changeTab('settings');
                isApplication = false;
              "
              :active="tabIndex === 'settings'"
              >Stýringar</b-nav-item
            >
            <b-nav-item @click.prevent="changeTab('precursor')" :active="tabIndex === 'precursor'">Undanfarar</b-nav-item>
          </b-nav>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import curriculum from '@/api/curriculum';
import { mapGetters, mapActions } from 'vuex';

import ModuleSettings from '@/components/curriculum/modules/ModuleSettings.vue';
import ModulePrecursor from '@/components/curriculum/modules/ModulePrecursor.vue';
import ModuleEquivalent from '@/components/curriculum/modules/ModuleEquivalent.vue';
import ModuleParallel from '@/components/curriculum/modules/ModuleParallel.vue';
import ModuleDescription from '@/components/curriculum/modules/ModuleDescription.vue';
import ModuleCourse from '@/components/curriculum/modules/ModuleCourse.vue';
import ModuleHistory from '@/components/curriculum/modules/ModuleHistory.vue';
import Timetable from '@/components/common/Timetable.vue';

export default {
  name: 'module-structure',
  props: ['id'],
  components: {
    ModuleSettings, // eslint-disable-line
    ModulePrecursor,
    ModuleEquivalent,
    ModuleParallel,
    ModuleDescription,
    ModuleCourse,
    ModuleHistory,
    Timetable,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_afangar');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('namskra_afangar');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      tabIndex: 'settings',
      page: 'structure',
      item: '',
    };
  },
  methods: {
    async loadDetails() {
      if (this.canRead) {
        try {
          const response = await curriculum.getModuleDetails(this.id);
          this.item = response.data;
          this.reloadModule();
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    reloadModule() {
      this.$emit('reload');
    },
    changeTab(tab) {
      this.tabIndex = tab;
      this.$router.push({ name: 'Module', query: { page: this.page, tab: this.tabIndex } });
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  beforeRouteUpdate(to, from, next) {
    if (to && to.query && to.query.page) {
      this.page = to.query.page;
    }
    if (to && to.query && to.query.tab) {
      this.tabIndex = to.query.tab;
    }
    next();
  },
  created() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
    if (this.$route.query.tab) {
      this.tabIndex = this.$route.query.tab;
    }
    this.loadDetails();
  },
};
</script>
<style lang="scss"></style>
