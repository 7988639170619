<template>
  <div>
    <b-row class="breadcumb-location">
      <b-col>
        <b-btn variant="primary" :to="{ name: 'CounselingCreateInterview' }" v-if="canEdit">
          <i class="fa fa-plus-circle" /> Skrá viðtal
        </b-btn>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="pl-0">
        <b-form-group label="Dags.frá" label-for="dateFrom">
          <datepicker
            id="dateFrom"
            name="dateFrom"
            v-model="search.date_from"
            :language="lang"
            :monday-first="true"
            format="dd.MM.yyyy"
            :typeable="false"
            :clear-button="true"
            ref="dateFromOpenPickr"
          ></datepicker>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Dags.til" label-for="dateTo">
          <datepicker
            id="dateTo"
            name="dateTo"
            v-model="search.date_to"
            :language="lang"
            :monday-first="true"
            format="dd.MM.yyyy"
            :typeable="false"
            :clear-button="true"
            ref="dateToOpenPickr"
          ></datepicker>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Starfsmaður" label-for="name">
          <b-form-input id="name" name="name" v-model="search.staff_name" />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Tegund viðtals" label-for="category">
          <v-select
            id="category"
            name="category"
            v-model="search.categories"
            multiple
            :options="categories"
            label="counseling_category_name"
          >
          </v-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="pl-0">
        <b-form-group label="Einstaklingur kennitala" label-for="name">
          <b-form-input id="name" name="name" v-model="search.counselee_ssn" />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Einstaklingur nafn" label-for="name">
          <b-form-input id="name" name="name" v-model="search.counselee_name" />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Nafn hóps" label-for="groupName">
          <b-form-input id="groupName" name="groupName" v-model="search.counselee_group_name" />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Yfirlit yfir alla" label-for="overview">
          <b-form-checkbox id="overview" name="overview" v-model="search.overview" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col></b-col>
      <b-col></b-col>
      <b-col></b-col>
      <b-col>
        <b-btn variant="primary" @click="loadList()" class="w-100 pull-right" style="margin-top: 20px"> Leita </b-btn>
      </b-col>
    </b-row>
    <br />
    <div v-if="loading.list" class="loader">
      <Loader size="xx-large" />
    </div>
    <div v-if="!loading.list && list.length === 0 && firsttime">Engin viðtöl fundust</div>
    <table class="table" v-if="list.length > 0">
      <thead>
        <tr>
          <th>Dagsetning</th>
          <th>Tegund</th>
          <th>Nafn hóps</th>
          <th>Starfsmaður</th>
          <th>Númer viðtals</th>
          <th>Ástæða viðtals</th>
          <th>Viðmælendur</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in getPaginatedList"
          :key="index"
          class="cursor-pointer"
          :class="{ 'active-table-row': selectedItem && selectedItem.nsr_counseling_id === item.nsr_counseling_id }"
          @click="openSlidebar(item)"
        >
          <td>
            {{ item.counseling_date | moment('DD.MM.YYYY') }}
          </td>
          <td>
            <strong>{{ item.counseling_category_name }}</strong>
          </td>
          <td>
            {{ item.counseling_group_name }}
          </td>
          <td>
            {{ item.user_name }}
          </td>
          <td>
            {{ item.counseling_number }}
          </td>
          <td>
            {{ item.counseling_reason_name }}
          </td>
          <td>
            <div
              v-for="(c, idx) in item.counselees"
              :key="idx"
              :title="item.counselees.length > 1 ? c.nafn : ''"
              v-b-tooltip
              class="gavatar gavatar-sm"
              v-bind:class="{ fullName: item.counselees.length === 1 }"
              :style="`background-color:${colors[idx % colors.length]}`"
            >
              {{ item.counselees.length === 1 ? c.nafn : getInitials(c.nafn) }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <Pagination
      :currentPage="currentPage"
      :totalRows="list.length"
      :perPage="perPage"
      v-if="list.length > 10"
      @change-page="changePage"
      @change-limit="changeLimit"
    />
    <interview-slidebar
      v-if="showSlidebar"
      :item="selectedItem"
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
    >
    </interview-slidebar>
  </div>
</template>

<script>
import counseling from '@/api/counseling';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

import Loader from '@/components/common/Loader.vue';
import Pagination from '@/components/common/Pagination.vue';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';

import InterviewSlidebar from '@/components/students/counseling/InterviewSlidebar.vue';

export default {
  name: 'counseling-interviews',
  components: {
    Loader,
    Pagination,
    Datepicker,
    InterviewSlidebar,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('nemendahald_namsradgjof');
    },
    getPaginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.list.slice(start, end);
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      firsttime: false,
      list: [],
      categories: [],
      loading: {
        categories: false,
        list: false,
      },
      lang: is,
      search: {
        staff_name: '',
        counselee_name: '',
        counselee_ssn: '',
        date_from: '',
        date_to: '',
        categories: [],
        overview: false,
      },
      colors: [
        '#5A8770',
        '#6FA9AB',
        '#F5AF29',
        '#0088B9',
        '#F18636',
        '#D93A37',
        '#A6B12E',
        '#5C9BBC',
        '#F5888D',
        '#9A89B5',
        '#407887',
        '#9A89B5',
        '#5A8770',
        '#D33F33',
        '#A2B01F',
        '#F0B126',
        '#0087BF',
        '#F18636',
        '#0087BF',
        '#B2B7BB',
        '#72ACAE',
        '#9C8AB4',
        '#5A8770',
        '#EEB424',
        '#407887',
      ],
      showSlidebar: false,
      selectedItem: {},
    };
  },
  methods: {
    async loadList() {
      try {
        this.list = [];
        this.loading.list = true;
        const params = { ...this.search };
        params.date_from = params.date_from ? moment(params.date_from).format('DD.MM.YYYY') : '';
        params.date_to = params.date_to ? moment(params.date_to).format('DD.MM.YYYY') : '';
        params.categories = params.categories.map(x => x.nsr_counseling_category_id).join(',');
        params.overview = params.overview ? 1 : 0;
        const response = await counseling.getList(params);
        this.list = response.data.items;
        this.firsttime = true;
        this.changePage(1); // Svo við sjáum pottþétt útkomuna.
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.list = false;
      }
    },
    async loadCategories() {
      try {
        this.categories = [];
        this.loading.categories = true;
        const response = await counseling.getCategories();
        this.categories = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.categories = false;
      }
    },
    getInitials(name) {
      return name
        ? name
            .split(' ')
            .map(n => n[0])
            .join('')
        : '';
    },
    openSlidebar(item) {
      this.selectedItem = item;
      this.showSlidebar = true;
    },
    closeSlidebar() {
      this.showSlidebar = false;
      this.selectedItem = {};
    },
    closeAndReloadSlidebar() {
      this.closeSlidebar();
      this.loadList();
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadCategories();
    this.loadList();
  },
};
</script>
<style lang="scss">
.gavatar {
  color: #fff;
  display: inline-block;
  font-family: Arial, &quot;
  font-size: 20px;
  border-radius: 80px;
  width: 80px;
  height: 80px;
  line-height: 80px;
  margin: 0 5px;
  text-align: center;

  &.gavatar-sm {
    width: 38px;
    height: 38px;
    line-height: 38px;
    font-size: 11px;
    margin: 0 2px;

    &.fullName {
      width: auto;
      padding-left: 1em;
      padding-right: 1em;
    }
  }
}

.loader {
  text-align: center;
  margin: 1em;
}
</style>
