<template>
  <div>
    <Loader v-if="loading" />
    <table class="table" v-else>
      <thead>
        <tr class="table-search">
          <td colspan="9">
            <b-form-input
              v-model="search"
              class="search"
              @input="currentPage = 1"
              style="margin-top: 0px; margin-bottom: 15px"
              placeholder="Leita"
            />
          </td>
        </tr>
        <tr>
          <th class="dont-break">
            <LinkOrder title="Kennitala" :filteredList="getList" :list="list" column="kennitala" secondColumn="nafn" />
            ({{ getList.length }})
          </th>
          <th>
            <LinkOrder title="Nafn" :filteredList="getList" :list="list" column="nafn" />
          </th>
          <th>
            <LinkOrder title="Starfsflokkur" :filteredList="getList" :list="list" column="starfsflokkur" secondColumn="nafn" />
          </th>
          <th>
            <LinkOrder title="Starfsnúmer" :filteredList="getList" :list="list" column="starfsm_id" secondColumn="nafn" />
          </th>
          <th>
            <LinkOrder title="Stöðugildi" :filteredList="getList" :list="list" column="starfsheiti" secondColumn="nafn" />
          </th>
          <th>
            <LinkOrder title="Menntunarflokkur" :filteredList="getList" :list="list" column="menntunarflokkur" secondColumn="nafn" />
          </th>
          <th>
            <LinkOrder title="Menntunarstig" :filteredList="getList" :list="list" column="menntunarstig" secondColumn="nafn" />
          </th>
          <th>
            <LinkOrder title="Kennsluréttindi" :filteredList="getList" :list="list" column="kennslurettindi" secondColumn="nafn" />
          </th>
          <th>
            <LinkOrder title="Meistarapróf" :filteredList="getList" :list="list" column="meistaraprof" secondColumn="nafn" />
          </th>
        </tr>
      </thead>
      <tbody>
        <TableRowLoader v-if="loading" colspan="9" :center="true" />
        <TableRowEmpty v-if="!loading && this.list.length === 0" colspan="9" :center="true" />
        <tr v-for="item in getPaginatedList" :key="item.id">
          <td>{{ item.kennitala }}</td>
          <td>{{ item.nafn }}</td>
          <td>{{ item.starfsflokkur }}</td>
          <td class="text-center">{{ item.starfsm_id }}</td>
          <td>{{ item.starfsheiti }} {{ item.starfs_hlutfall }}<span v-if="item.starfs_hlutfall">%</span></td>
          <td class="text-center">{{ item.menntunarflokkur }}</td>
          <td>{{ item.menntunarstig }}</td>
          <td class="text-center"><yes-no :item="item.kennslurettindi" /></td>
          <td class="text-center"><yes-no :item="item.meistaraprof" /></td>
        </tr>
      </tbody>
    </table>
    <br />
    <Pagination
      :currentPage="currentPage"
      :totalRows="getList.length"
      :perPage="perPage"
      v-if="list.length > 25"
      @change-page="changePage"
      @change-limit="changeLimit"
    />
  </div>
</template>

<script>
import statistics from '@/api/statistics';
import { mapActions } from 'vuex';

import Loader from '@/components/common/Loader.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';
import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import Pagination from '@/components/common/Pagination.vue';
import YesNo from '@/components/common/YesNo.vue';

export default {
  name: 'statistics-elementary-staff',
  props: ['id'],
  components: {
    Loader,
    LinkOrder,
    TableRowLoader,
    TableRowEmpty,
    Pagination,
    YesNo,
  },
  computed: {
    getList() {
      return this.list.filter(
        x =>
          this.search.length === 0 ||
          (x.nafn || '').toUpperCase().indexOf(this.search.toUpperCase()) > -1 ||
          x.kennitala.indexOf(this.search) > -1 ||
          (x.starfsflokkur || '').toUpperCase().indexOf(this.search.toUpperCase()) > -1 ||
          (x.starfsheiti || '').toUpperCase().indexOf(this.search.toUpperCase()) > -1 ||
          (x.menntunarstig || '').toUpperCase().indexOf(this.search.toUpperCase()) > -1,
      );
    },
    getPaginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.getList.slice(start, end);
    },
  },
  data() {
    return {
      perPage: 25,
      currentPage: 1,
      loading: false,
      list: [],
      search: '',
    };
  },
  methods: {
    async loadList() {
      try {
        this.loading = true;
        const response = await statistics.getElementaryStaff();
        this.list = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadList();
  },
};
</script>

<style lang="scss"></style>
