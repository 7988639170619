<template>
  <page>
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <b-row class="breadcumb-location">
      <b-col>
        <div class="float-right">
          <b-btn variant="dark" @click="openSearchSlidebar" class="ml-1"> <i class="fa fa-exchange" /> Skipta um áfanga </b-btn>
        </div>
        <div class="float-right">
          <b-link :to="{ name: 'Modules', query: searchQuery }">
            <b-btn variant="dark" class="ml-1"> <i class="fa fa-arrow-left" /> Til baka í leit </b-btn>
          </b-link>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="pl-0">
        <Loader v-if="loading.module"></Loader>
        <div>
          <i
            class="fa fa-ban mr-2"
            style="font-size: 32px; color: #f26175"
            v-if="!module.gildi && !loading.module"
            v-b-tooltip
            title="Ath. eftirfarandi áfangi er óvirkur"
          ></i>
          <PageTitle :title="title" class="d-inline-block" />
        </div>
        <div v-if="module.afanga_heiti && module.afanga_heiti" style="margin-top: -30px; margin-bottom: 30px">
          <i>{{ module.afanga_heiti }}</i>
        </div>
      </b-col>
      <b-col class="pt-3"> </b-col>
    </b-row>
    <PageMenu route="Module" :selectedPage="page" :items="menu" />
    <ModuleStructure :id="id" v-if="page === 'structure'" @reload="loadModule()"></ModuleStructure>
    <ModuleTerms v-if="page === 'terms'" :id="id" :courseName="module.afanga_heiti"></ModuleTerms>
    <module-search-slidebar @closeSlidebar="closeSlidebar" v-if="showSearchSlidebar" :large="true"> </module-search-slidebar>
  </page>
</template>

<script>
import curriculum from '@/api/curriculum';

import { mapActions, mapGetters } from 'vuex';

import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import PageTitle from '@/components/common/PageTitle.vue';
import PageMenu from '@/components/common/PageMenu.vue';
import Loader from '@/components/common/Loader.vue';

import ModuleSearchSlidebar from '@/components/curriculum/modules/ModuleSearchSlidebar.vue';
import ModuleStructure from '@/components/curriculum/modules/ModuleStructure.vue';
import ModuleTerms from '@/components/curriculum/modules/ModuleTerms.vue';

export default {
  name: 'module',
  components: {
    Breadcrumbs,
    PageTitle,
    PageMenu,
    Loader,
    ModuleSearchSlidebar,
    ModuleStructure,
    ModuleTerms,
  },
  computed: {
    canRead() {
      return this.loggedInUserHasReadPermission('namskra_afangar');
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_afangar');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolHasSettings']),
  },
  data() {
    return {
      id: '',
      breadcrumbs: [
        { name: 'Forsíða', route: 'Dashboard' },
        { name: 'Námskrá', route: '' },
        { name: 'Áfangar', route: 'Modules' },
      ],
      menu: [
        {
          name: 'Uppbygging',
          page: 'structure',
        },
        {
          name: 'Kennslutímabil',
          page: 'terms',
        },
      ],
      page: 'structure',
      loading: {
        module: false,
      },
      module: '',
      title: '',
      showSearchSlidebar: false,
      searchQuery: '',
    };
  },
  methods: {
    async loadModule() {
      // if (this.canRead) {
      try {
        this.loading.module = true;
        const response = await curriculum.getModuleFewDetails(this.id);
        this.module = response.data.items ? response.data.items[0] : '';
        this.breadcrumbs = [
          { name: 'Forsíða', route: 'Dashboard' },
          { name: 'Skólahald', route: '' },
          { name: 'Áfangar', route: 'Modules' },
          { name: this.module.namsgrein_afanganr, route: '' },
        ];
        this.title = `${this.module.undirsk_heiti} - ${this.module.namsgrein_afanganr}`;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.module = false;
      }
      // }
    },
    openSearchSlidebar() {
      this.showSearchSlidebar = true;
    },
    closeSlidebar(item) {
      if (item && item.id) {
        let query = {};
        if (this.$route.query.page) {
          query.page = this.$route.query.page;
        }
        if (this.$route.query) {
          query = this.$route.query;
        }
        this.$router.push({ name: 'Module', params: { id: item.id }, query });
        this.$router.go();
      }
      this.showSearchSlidebar = false;
      this.loadModule();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  beforeRouteUpdate(to, from, next) {
    if (to && to.query && to.query.page) {
      this.page = to.query.page;
    }
    next();
  },
  created() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
    if (this.$route.query) {
      this.searchQuery = this.$route.query;
    }
    this.id = this.$route.params.id;
    this.loadModule();
  },
};
</script>
