<template>
  <Slidebar :autoClose="true" @closeMe="closeSlidebar" :large="large" :extraLarge="extraLarge">
    <h4>Jafngildi</h4>
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <table class="table less-padding no-border">
        <tr>
          <td>
            <b-form-group
              label-for="undirskoli"
              label="Undirskóli"
              :state="submitted && errors.has('undirskoli') ? false : ''"
              :invalid-feedback="errors.first('undirskoli')"
            >
              <v-select
                id="undirskoli"
                name="undirskoli"
                v-model="form.division"
                :clearable="false"
                v-validate="'required'"
                :state="submitted && errors.has('undirskoli') ? false : ''"
                :options="divisions"
                @input="loadModules"
                data-vv-as="undirskóli"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>
            <b-form-group :state="submitted && errors.has('module') ? false : ''" :invalid-feedback="errors.first('module')">
              <label label-for="module"> Áfangi <i class="fa fa-spin fa-spinner" v-if="loading.modules"></i> </label>
              <v-select
                id="module"
                name="module"
                v-model="form.module"
                :clearable="false"
                :state="submitted && errors.has('module') ? false : ''"
                :options="modules"
                data-vv-as="áfangi"
                v-validate="'required'"
              >
              </v-select>
            </b-form-group>

            <b-form-checkbox v-model="inactiveModules" class="d-inline-block" @input="loadModules"></b-form-checkbox> Sækja einnig óvirka
            áfanga
          </td>
        </tr>
        <tr>
          <td>
            <b-form-checkbox v-model="form.twoWay" class="d-inline-block"></b-form-checkbox> Jafngildið á að skrást á báða áfangana.
            <i class="fa fa-info-circle" title="Ef jafngildið á að virka í báðar áttir þá skrást áfangarnir hjá hvorum öðrum."></i>
          </td>
        </tr>
      </table>
      <b-btn variant="primary" @click="validateBeforeSubmit()">Skrá jafngildi</b-btn>
    </b-form>
  </Slidebar>
</template>

<script>
import curriculum from '@/api/curriculum';
import structure from '@/api/structure';
import Slidebar from '@/components/common/Slidebar.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'module-equivalent-slidebar',
  components: {
    Slidebar,
  },
  props: ['id', 'divisionId', 'large', 'extraLarge'],
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_afangar');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('namskra_afangar');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      inactiveModules: false,
      form: {
        division: '',
        module: '',
        twoWay: false,
      },
      loading: {
        modules: false,
        divisions: false,
      },
      modules: [],
      divisions: [],
      submitted: false,
    };
  },
  methods: {
    async loadDivisions() {
      this.loading.divisions = true;
      try {
        const response = await structure.getDivisionList();
        this.divisions = response.data.items.map(x => ({
          ...x,
          label: `${x.code} - ${x.name}`,
        }));

        this.form.division = this.divisions.find(x => x.division_id === this.divisionId);
        this.form.module = '';
        this.loadModules();
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.divisions = false;
      }
    },
    async loadModules() {
      try {
        this.loading.modules = true;
        const response = await curriculum.getModuleList({
          active: !this.inactiveModules ? 1 : '',
          division: this.form.division.division_id,
        });
        this.modules = response.data.items.map(x => ({
          id: x.afangi_id,
          label: `${x.namsgrein_afanganr} ${x.afanga_heiti ? ` - ${x.afanga_heiti} ${x.gildi === 0 ? '- ÓVIRKUR' : ''}` : ''}`,
          name: x.afanga_heiti,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.modules = false;
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        try {
          await curriculum.setModuleEquivalent(this.id, { afangi_id: this.form.module.id });
          if (this.form.twoWay) {
            await curriculum.setModuleEquivalent(this.form.module.id, { afangi_id: this.id });
          }
          this.displaySuccess('Jafngildum áfanga bætt við.');
          this.$emit('successful', {});
          this.closeSlidebar();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.saving = false;
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.saving = false;
      }
    },
    closeSlidebar(item) {
      this.$emit('closeSlidebar', item);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadDivisions();
  },
};
</script>

<style lang="scss"></style>
