<template>
  <page>
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <PageTitle title="Starfsmenn" />
    <StaffSearch :slidebar="false" />
  </page>
</template>

<script>
import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import PageTitle from '@/components/common/PageTitle.vue';

import StaffSearch from '@/components/staff/staff/StaffSearch.vue';

export default {
  name: 'staff',
  components: {
    Breadcrumbs,
    PageTitle,
    StaffSearch,
  },
  data() {
    return {
      breadcrumbs: [
        { name: 'Forsíða', route: 'Dashboard' },
        { name: 'Starfsmannahald', route: '' },
        { name: 'Starfsmenn', route: 'Staff' },
      ],
    };
  },
};
</script>
