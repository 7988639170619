<template>
  <div>
    <div class="mb-2">Notandinn er skráður í eftirfarandi notendahópa:</div>
    <Loader v-if="loading"></Loader>
    <table v-if="!loading" class="table less-padding">
      <thead>
        <tr>
          <th>Notendahópur ({{ list.length }})</th>
          <th>Lýsing</th>
          <th>Önn</th>
          <th>Virkur</th>
          <th v-if="canEdit"></th>
        </tr>
      </thead>
      <TableRowEmpty v-if="!loading && this.list.length === 0" colspan="5" :center="true" />
      <tr v-for="item in list" :key="item.hopur_not_id">
        <td>{{ item.heiti }}</td>
        <td>{{ item.lysing }}</td>
        <td>{{ item.onn_eink }}</td>
        <td><yes-no :item="item.hopur_virkur"></yes-no></td>
        <td v-if="canEdit">
          <b-btn variant="primary" size="sm" class="btn-xs" title="Eyða úr notendahóp" v-b-tooltip @click="removeFromGroup(item)"
            ><i class="fa fa-trash"></i
          ></b-btn>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import users from '@/api/users';
import { mapGetters, mapActions } from 'vuex';
import Loader from '@/components/common/Loader.vue';
import YesNo from '@/components/common/YesNo.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';

export default {
  name: 'user-groups',
  props: ['userId'],
  components: {
    Loader,
    YesNo,
    TableRowEmpty,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('nemendahald_nemendur');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('nemendahald_nemendur');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      loading: false,
      list: [],
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;
        const response = await users.getUserGroups({
          userId: this.userId,
        });
        this.list = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async removeFromGroup(item) {
      const conf = confirm(`Ertu viss um að þú viljir eyða úr hópnum ${item.heiti}?`); // eslint-disable-line
      if (conf) {
        try {
          await users.updateUserGroup({ notandi_id: item.notandi_id, hopur_id: item.hopur_id, delete: 1 });
          this.displaySuccess('Notanda eytt úr hóp.');
          this.load();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.load();
  },
};
</script>
<style lang="scss"></style>
