<template>
  <div class="settings-tabs" :class="{ 'settings-tabs-fixed': tabsFixed }">
    <b-row>
      <b-col sm="9" md="9" lg="9" id="scrollableContent" v-if="editItem">
        <div v-if="tabIndex === 'info'">
          <ItemEdit
            v-for="(i, idx) in data.info"
            :key="`info${idx}`"
            :id="i.param"
            :name="i.param"
            :label="i.label"
            :item="item[i.param]"
            :editStatus="edit.info"
            :editParam="i.param"
            :checkbox="i.checkbox"
            :dropdown="i.dropdown"
            :dropdownList="i.dropdownList"
            :disabled="i.disabled || !canEdit"
            @toEdit="toggleEdit('info')"
            @toChange="toChange"
            @saveData="saveChanges('info')"
          />
          <b-btn variant="primary" class="float-right" v-if="edit.info" @click="saveChanges('info')">Vista breytingar</b-btn>
          <b-btn variant="cancel" class="mr-2 float-right" v-if="edit.info" @click="closeEdit('info')">Hætta við</b-btn>
        </div>
        <div v-if="tabIndex === 'closures'">
          <ItemEdit
            v-for="(i, idx) in data.closures"
            :key="`closures${idx}`"
            :id="i.param"
            :name="i.param"
            :label="i.label"
            :item="item[i.param]"
            :editStatus="edit.closures"
            :editParam="i.param"
            :checkbox="i.checkbox"
            :dropdown="i.dropdown"
            :dropdownList="i.dropdownList"
            :disabled="i.disabled || !canEdit"
            @toEdit="toggleEdit('closures')"
            @toChange="toChange"
            @saveData="saveChanges('closures')"
          />
          <b-btn variant="primary" class="float-right" v-if="edit.closures" @click="saveChanges('closures')">Vista breytingar</b-btn>
          <b-btn variant="cancel" class="mr-2 float-right" v-if="edit.closures" @click="closeEdit('closures')">Hætta við</b-btn>
        </div>
        <div v-if="tabIndex === 'students'">
          <ItemEdit
            v-for="(i, idx) in data.students"
            :key="`students${idx}`"
            :id="i.param"
            :name="i.param"
            :label="i.label"
            :item="item[i.param]"
            :editStatus="edit.students"
            :editParam="i.param"
            :checkbox="i.checkbox"
            :dropdown="i.dropdown"
            :dropdownList="i.dropdownList"
            :disabled="i.disabled || !canEdit"
            @toEdit="toggleEdit('students')"
            @toChange="toChange"
            @saveData="saveChanges('students')"
          />
          <b-btn variant="primary" class="float-right" v-if="edit.students" @click="saveChanges('students')">Vista breytingar</b-btn>
          <b-btn variant="cancel" class="mr-2 float-right" v-if="edit.students" @click="closeEdit('students')">Hætta við</b-btn>
          <br />
          <br />
          <br />
        </div>
        <div v-if="tabIndex === 'studyrecords'">
          <ItemEdit
            v-for="(i, idx) in data.studyrecords"
            :key="`studyrecords${idx}`"
            :id="i.param"
            :name="i.param"
            :label="i.label"
            :item="item[i.param]"
            :editStatus="edit.studyrecords"
            :editParam="i.param"
            :checkbox="i.checkbox"
            :dropdown="i.dropdown"
            :dropdownList="i.dropdownList"
            :disabled="i.disabled || !canEdit"
            @toEdit="toggleEdit('studyrecords')"
            @toChange="toChange"
            @saveData="saveChanges('studyrecords')"
          />
          <b-btn variant="primary" class="float-right" v-if="edit.studyrecords" @click="saveChanges('studyrecords')"
            >Vista breytingar</b-btn
          >
          <b-btn variant="cancel" class="mr-2 float-right" v-if="edit.studyrecords" @click="closeEdit('studyrecords')">Hætta við</b-btn>
        </div>
        <div v-if="tabIndex === 'grades'">
          <ItemEdit
            v-for="(i, idx) in data.grades"
            :key="`grades${idx}`"
            :id="i.param"
            :name="i.param"
            :label="i.label"
            :item="item[i.param]"
            :editStatus="edit.grades"
            :editParam="i.param"
            :checkbox="i.checkbox"
            :dropdown="i.dropdown"
            :dropdownList="i.dropdownList"
            :disabled="i.disabled || !canEdit"
            @toEdit="toggleEdit('grades')"
            @toChange="toChange"
            @saveData="saveChanges('grades')"
          />
          <b-btn variant="primary" class="float-right" v-if="edit.grades" @click="saveChanges('grades')">Vista breytingar</b-btn>
          <b-btn variant="cancel" class="mr-2 float-right" v-if="edit.grades" @click="closeEdit('grades')">Hætta við</b-btn>
          <br /><br />
        </div>
        <div v-if="tabIndex === 'attendance'">
          <ItemEdit
            v-for="(i, idx) in data.attendance.filter(x => x.category === 'default')"
            :key="`attendance${idx}`"
            :id="i.param"
            :name="i.param"
            :label="i.label"
            :item="item[i.param]"
            :editStatus="edit.attendance"
            :editParam="i.param"
            :checkbox="i.checkbox"
            :dropdown="i.dropdown"
            :dropdownList="i.dropdownList"
            :disabled="i.disabled || !canEdit"
            @toEdit="toggleEdit('attendance')"
            @toChange="toChange"
            @saveData="saveChanges('attendance')"
          />
          <strong>Mætingarhlutfall - Birta</strong>
          <ItemEdit
            v-for="(i, idx) in data.attendance.filter(x => x.category === 'percentage')"
            :key="`attendancePercentage${idx}`"
            :id="i.param"
            :name="i.param"
            :label="i.label"
            :item="item[i.param]"
            :editStatus="edit.attendance"
            :editParam="i.param"
            :checkbox="i.checkbox"
            :dropdown="i.dropdown"
            :dropdownList="i.dropdownList"
            :disabled="i.disabled || !canEdit"
            @toEdit="toggleEdit('attendance')"
            @toChange="toChange"
            @saveData="saveChanges('attendance')"
          />
          <strong>Rafræn veikindaskráning nemanda</strong>
          <ItemEdit
            v-for="(i, idx) in data.attendance.filter(x => x.category === 'absence')"
            :key="`attendanceAbsence${idx}`"
            :id="i.param"
            :name="i.param"
            :label="i.label"
            :item="item[i.param]"
            :editStatus="edit.attendance"
            :editParam="i.param"
            :checkbox="i.checkbox"
            :dropdown="i.dropdown"
            :dropdownList="i.dropdownList"
            :disabled="i.disabled || !canEdit"
            @toEdit="toggleEdit('attendance')"
            @toChange="toChange"
            @saveData="saveChanges('attendance')"
          />
          <strong>Rafræn skráning óska um skammtímaleyfi</strong>
          <ItemEdit
            v-for="(i, idx) in data.attendance.filter(x => x.category === 'short')"
            :key="`attendanceShort${idx}`"
            :id="i.param"
            :name="i.param"
            :label="i.label"
            :item="item[i.param]"
            :editStatus="edit.attendance"
            :editParam="i.param"
            :checkbox="i.checkbox"
            :dropdown="i.dropdown"
            :dropdownList="i.dropdownList"
            :disabled="i.disabled || !canEdit"
            @toEdit="toggleEdit('attendance')"
            @toChange="toChange"
            @saveData="saveChanges('attendance')"
          />
          <strong>Rafræn skráning óska um langtímaleyfi</strong>
          <ItemEdit
            v-for="(i, idx) in data.attendance.filter(x => x.category === 'long')"
            :key="`attendanceLong${idx}`"
            :id="i.param"
            :name="i.param"
            :label="i.label"
            :item="item[i.param]"
            :editStatus="edit.attendance"
            :editParam="i.param"
            :checkbox="i.checkbox"
            :dropdown="i.dropdown"
            :dropdownList="i.dropdownList"
            :disabled="i.disabled || !canEdit"
            @toEdit="toggleEdit('attendance')"
            @toChange="toChange"
            @saveData="saveChanges('attendance')"
          />
          <b-btn variant="primary" class="float-right" v-if="edit.attendance" @click="saveChanges('attendance')">Vista breytingar</b-btn>
          <b-btn variant="cancel" class="mr-2 float-right" v-if="edit.attendance" @click="closeEdit('attendance')">Hætta við</b-btn>
          <br /><br /><br />
        </div>
        <div v-if="tabIndex === 'communication'">
          <ItemEdit
            v-for="(i, idx) in data.communication"
            :key="`communication${idx}`"
            :id="i.param"
            :name="i.param"
            :label="i.label"
            :item="item[i.param]"
            :editStatus="edit.communication"
            :editParam="i.param"
            :checkbox="i.checkbox"
            :dropdown="i.dropdown"
            :dropdownList="i.dropdownList"
            :disabled="i.disabled || !canEdit"
            @toEdit="toggleEdit('communication')"
            @toChange="toChange"
            @saveData="saveChanges('communication')"
          />
          <b-btn variant="primary" class="float-right" v-if="edit.communication" @click="saveChanges('communication')"
            >Vista breytingar</b-btn
          >
          <b-btn variant="cancel" class="mr-2 float-right" v-if="edit.communication" @click="closeEdit('communication')">Hætta við</b-btn>
        </div>
        <div v-if="tabIndex === 'specialNeeds'">
          <ItemEdit
            v-for="(i, idx) in data.specialNeeds"
            :key="`specialNeeds${idx}`"
            :id="i.param"
            :name="i.param"
            :label="i.label"
            :item="item[i.param]"
            :editStatus="edit.specialNeeds"
            :editParam="i.param"
            :checkbox="i.checkbox"
            :dropdown="i.dropdown"
            :dropdownList="i.dropdownList"
            :disabled="i.disabled || !canEdit"
            @toEdit="toggleEdit('specialNeeds')"
            @toChange="toChange"
            @saveData="saveChanges('specialNeeds')"
          />
          <b-btn variant="primary" class="float-right" v-if="edit.specialNeeds" @click="saveChanges('specialNeeds')"
            >Vista breytingar</b-btn
          >
          <b-btn variant="cancel" class="mr-2 float-right" v-if="edit.specialNeeds" @click="closeEdit('specialNeeds')">Hætta við</b-btn>
        </div>
        <div v-if="tabIndex === 'staff'">
          <ItemEdit
            v-for="(i, idx) in data.staff"
            :key="`staff${idx}`"
            :id="i.param"
            :name="i.param"
            :label="i.label"
            :item="item[i.param]"
            :editStatus="edit.staff"
            :editParam="i.param"
            :checkbox="i.checkbox"
            :dropdown="i.dropdown"
            :disabled="i.disabled || !canEdit"
            :dropdownList="i.dropdownList"
            @toEdit="toggleEdit('staff')"
            @toChange="toChange"
            @saveData="saveChanges('staff')"
          />
          <b-btn variant="primary" class="float-right" v-if="edit.staff" @click="saveChanges('staff')">Vista breytingar</b-btn>
          <b-btn variant="cancel" class="mr-2 float-right" v-if="edit.staff" @click="closeEdit('staff')">Hætta við</b-btn>
        </div>
        <div v-if="tabIndex === 'premises'">
          <ItemEdit
            v-for="(i, idx) in data.premises"
            :key="`premises${idx}`"
            :id="i.param"
            :name="i.param"
            :label="i.label"
            :item="item[i.param]"
            :editStatus="edit.premises"
            :editParam="i.param"
            :checkbox="i.checkbox"
            :dropdown="i.dropdown"
            :dropdownList="i.dropdownList"
            :disabled="i.disabled || !canEdit"
            @toEdit="toggleEdit('premises')"
            @toChange="toChange"
            @saveData="saveChanges('premises')"
          />
          <b-btn variant="primary" class="float-right" v-if="edit.premises" @click="saveChanges('premises')">Vista breytingar</b-btn>
          <b-btn variant="cancel" class="mr-2 float-right" v-if="edit.premises" @click="closeEdit('premises')">Hætta við</b-btn>
        </div>
        <div v-if="tabIndex === 'classes'">
          <ItemEdit
            v-for="(i, idx) in data.classes"
            :key="`classes${idx}`"
            :id="i.param"
            :name="i.param"
            :label="i.label"
            :item="item[i.param]"
            :editStatus="edit.classes"
            :editParam="i.param"
            :checkbox="i.checkbox"
            :dropdown="i.dropdown"
            :dropdownList="i.dropdownList"
            :disabled="i.disabled || !canEdit"
            @toEdit="toggleEdit('classes')"
            @toChange="toChange"
            @saveData="saveChanges('classes')"
          />
          <b-btn variant="primary" class="float-right" v-if="edit.classes" @click="saveChanges('classes')">Vista breytingar</b-btn>
          <b-btn variant="cancel" class="mr-2 float-right" v-if="edit.classes" @click="closeEdit('classes')">Hætta við</b-btn>
        </div>
        <div v-if="tabIndex === 'otherSystems'">
          <ItemEdit
            v-for="(i, idx) in data.otherSystems"
            :key="`otherSystems${idx}`"
            :id="i.param"
            :name="i.param"
            :label="i.label"
            :item="item[i.param]"
            :editStatus="edit.otherSystems"
            :editParam="i.param"
            :checkbox="i.checkbox"
            :dropdown="i.dropdown"
            :dropdownList="i.dropdownList"
            :disabled="i.disabled || !canEdit"
            @toEdit="toggleEdit('otherSystems')"
            @toChange="toChange"
            @saveData="saveChanges('otherSystems')"
          />
          <b-btn variant="primary" class="float-right" v-if="edit.otherSystems" @click="saveChanges('otherSystems')"
            >Vista breytingar</b-btn
          >
          <b-btn variant="cancel" class="mr-2 float-right" v-if="edit.otherSystems" @click="closeEdit('otherSystems')">Hætta við</b-btn>
        </div>
        <div v-if="tabIndex === 'addOns'">
          <ItemEdit
            v-for="(i, idx) in data.addOns"
            :key="`addOns${idx}`"
            :id="i.param"
            :name="i.param"
            :label="i.label"
            :item="item[i.param]"
            :editStatus="edit.addOns"
            :editParam="i.param"
            :checkbox="i.checkbox"
            :dropdown="i.dropdown"
            :dropdownList="i.dropdownList"
            :disabled="i.disabled || !canEdit"
            @toEdit="toggleEdit('addOns')"
            @toChange="toChange"
            @saveData="saveChanges('addOns')"
          />
          <b-btn variant="primary" class="float-right" v-if="edit.addOns" @click="saveChanges('addOns')">Vista breytingar</b-btn>
          <b-btn variant="cancel" class="mr-2 float-right" v-if="edit.addOns" @click="closeEdit('addOns')">Hætta við</b-btn>
        </div>
      </b-col>
      <b-col cols="3">
        <div class="right-side-nav">
          <b-nav vertical>
            <b-nav-item @click.prevent="changeTab('classes')" :active="tabIndex === 'classes'">Bekkir/Skólaár</b-nav-item>
            <b-nav-item @click.prevent="changeTab('grades')" :active="tabIndex === 'grades'">Einkunnir</b-nav-item>
            <b-nav-item @click.prevent="changeTab('premises')" :active="tabIndex === 'premises'">Forsendur</b-nav-item>
            <b-nav-item @click.prevent="changeTab('addOns')" :active="tabIndex === 'addOns'">Kerfishlutar</b-nav-item>
            <b-nav-item @click.prevent="changeTab('closures')" :active="tabIndex === 'closures'">Lokanir</b-nav-item>
            <b-nav-item @click.prevent="changeTab('studyrecords')" :active="tabIndex === 'studyrecords'">Námsferlar</b-nav-item>
            <b-nav-item @click.prevent="changeTab('students')" :active="tabIndex === 'students'">Nemendur</b-nav-item>
            <b-nav-item @click.prevent="changeTab('communication')" :active="tabIndex === 'communication'">Samskipti</b-nav-item>
            <b-nav-item @click.prevent="changeTab('otherSystems')" :active="tabIndex === 'otherSystems'">
              Samskipti við önnur kerfi
            </b-nav-item>
            <b-nav-item @click.prevent="changeTab('specialNeeds')" :active="tabIndex === 'specialNeeds'">Sérúrræði í námi</b-nav-item>
            <b-nav-item @click.prevent="changeTab('staff')" :active="tabIndex === 'staff'">Starfsmenn</b-nav-item>
            <b-nav-item @click.prevent="changeTab('info')" :active="tabIndex === 'info'">Upplýsingar</b-nav-item>
            <b-nav-item @click.prevent="changeTab('attendance')" :active="tabIndex === 'attendance'">Viðvera</b-nav-item>
          </b-nav>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import school from '@/api/school';
import terms from '@/api/terms';
import schoolTypes from '@/api/schoolTypes';
import users from '@/api/users';

import ItemEdit from '@/components/common/ItemEdit.vue';

export default {
  name: 'settings',
  components: {
    ItemEdit,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  props: ['item'],
  data() {
    return {
      tabsFixed: false,
      tabIndex: 'closures',
      page: 'settings',
      editItem: {},
      edit: {
        info: false,
        closures: false,
        students: false,
        studyrecords: false,
        grades: false,
        attendance: false,
        communication: false,
        specialNeeds: false,
        staff: false,
        premises: false,
        classes: false,
        otherSystems: false,
        addOns: false,
      },
      data: {
        info: [
          { param: 'netfang', label: 'Netfang' },
          { param: 'logo_slod', label: 'Vefslóð á lógó skóla' },
          { param: 'logo_slod_erl', label: 'Vefslóð á erlent lógó skóla' },
        ],
        closures: [
          { param: 'lokun_einkunnir', label: 'Lokað á einkunnir', checkbox: true },
          { param: 'lokun_namsferill', label: 'Lokað á námsferil', checkbox: true },
          { param: 'lokun_nemendur', label: 'Lokað á nemendur', checkbox: true },
          { param: 'lokun_stundatafla', label: 'Lokað á stundatöflu', checkbox: true },
          { param: 'lokun_midannarmat', label: 'Lokað á miðannarmat', checkbox: true },
        ],
        students: [
          { param: 'nemandi_br_personuuppl', label: 'Má nemandi breyta persónuupplýsingum', checkbox: true },
          { param: 'nemandi_br_mynd', label: 'Má nemandi setja inn mynd', checkbox: true },
          { param: 'namsferill_nemar_breyta', label: 'Má nemandi breyta námsferilsáætlun', checkbox: true },
          {
            param: 'val_hopur',
            label: 'Má nemandi breyta námsferilsáætlun - Takmarka aðgang við valinn notendahóp',
            dropdown: true,
            dropdownList: [],
          },
          // { param: 'nemandi_velja_forgang', label: 'Má nemandi setja áfanga í forgang', checkbox: true },
          { param: 'namsferill_nemar_jafngildir', label: 'Má nemandi sjá jafngildisáfanga', checkbox: true },
          // { param: 'ma_skra_p_og_u', label: 'Má nemandi skrá P eða U val', checkbox: true },
          { param: 'namsferill_nemar_stadfesta_val', label: 'Má nemandi staðfesta val', checkbox: true },
          { param: 'nemandi_velja_af_utan_brauta', label: 'Má nemandi velja áfanga utan brautar', checkbox: true },
          { param: 'nemandi_skra_sig_ur_afanga', label: 'Má nemandi skrá sig sjálfur úr áfanga', checkbox: true },
          { param: 'nemandi_ser_proftoflu', label: 'Birta próftöflu á nemendavef', checkbox: true },
          { param: 'proftafla_birta_stofu', label: 'Birta stofu í próftöflu', checkbox: true },
          {
            param: 'nemandi_toflubreytingar',
            label: 'Má nemandi skrá óskir um töflubreytingar',
            dropdown: true,
            dropdownList: [
              { value: 0, text: 'Lokað' },
              { value: 1, text: 'Opið - hægt að sækja um' },
              { value: 2, text: 'Lokað - hægt að skoða afgreiðslu' },
            ],
          },
          {
            param: 'toflubreytingar_hopur',
            label: 'Töflubreytingar - Takmarka aðgang við valinn notendahóp',
            dropdown: true,
            dropdownList: [],
          },
        ],
        studyrecords: [
          { param: 'fj_afanga_onn', label: 'Hámarksfjöldi áfanga á kennslutímabili' },
          { param: 'max_timar_onn', label: 'Hámarksfjöldi kennslustunda á viku' },
          { param: 'min_kennslust_viku', label: 'Lágmarksfjöldi kennslustunda á viku' },
          { param: 'max_varaval', label: 'Hámarksfjöldi varavals á kennslutímabili' },
          { param: 'min_varaval', label: 'Lágmarksfjöldi varavals á kennslutímabili' },
          { param: 'fall_onn_min_einingar', label: 'Lágmarksfjöldi eininga á kennslutímabili án falls' },
          { param: 'fall_onn_min_kennslust', label: 'Lágmarksfjöldi kennslustunda án falls' },
          { param: 'sjalfg_faera_eyda_afangakedju', label: 'Sjálfgefið færa/eyða áfangakeðju', checkbox: true },
          { param: 'max_endurtekt', label: 'Fjöldi endurtekta á áfanga' },
          { param: 'ma_tviskra_afanga', label: 'Má tvískrá áfanga á nema', checkbox: true },
          /* {
            param: 'utlit_namsfaetlun',
            label: 'Útlit námsferilsáætlunar',
            dropdown: true,
            dropdownList: [
              { value: 0, text: 'Bekkjaskóli - Áfangar' },
              { value: 1, text: 'Áfangaskóli' },
              { value: 2, text: 'Bekkjaskóli - Námsgreinar' },
            ],
          }, */
          // { param: 'birta_eftir_a_braut_i_namsf', label: 'Birta áfanga sem eftir eru á braut á námsferli', checkbox: true },
          { param: 'nota_einungis_matsannir', label: 'Mat einungis á matsannir', checkbox: true },
          { param: 'sjalfvirkt_val_a_brautir', label: 'Er sjálfvirkt val á brautum virkt', checkbox: true },
          { param: 'keyra_kjorsv_sjalfv_a_ferla', label: 'Keyra kjörsvið sjálfvirkt á ferla', checkbox: true },
          { param: 'namsferill_fylla_onn', label: 'Námsferilsáætlun - fylla kennslutímabil í sjálfvirku vali', checkbox: true },
          // { param: 'kennari_skoda_namsferill', label: 'Má kennari skoða námsferil nemenda', checkbox: true },
          {
            param: 'max_afangar_styring',
            label: 'Hámark notað í námsferli',
            dropdown: true,
            dropdownList: [
              { value: 1, text: 'Hámark eininga á kennslutímabili' },
              { value: 2, text: 'Hámark kennslustunda á viku' },
              { value: 3, text: 'Það hámark sem fyrst er náð' },
            ],
          },
        ],
        grades: [
          { param: 'sjalfg_falleinkunn', label: 'Sjálfgefin einkunn (ef óskráð)' },
          { param: 'reikna_lokaeink', label: 'Reikna í lokaeinkunn', checkbox: true },
          // { param: 'sjalfg_einkunnablad_heiti', label: 'Sjálfgefið einkunnablað - Vantar dropdown lista' },
          // { param: 'nemandi_ser_medaltal', label: 'Sést meðaltal einkunna', checkbox: true },
          // { param: 'nemandi_ser_vegid_medaltal', label: 'Sést vegið meðaltal einkunna', checkbox: true },
          { param: 'sjalfg_meink_frjals_maeting', label: 'Einkunn v. sérstakrar mætingareinkunnar' },
          { param: 'sjalfg_einingar_skirteini', label: 'Sjálfg. einingar á skírteini', checkbox: true },
          { param: 'skolasokn_a_skirteini', label: 'Skólasóknaráfangi á prófskírteini', checkbox: true },
          { param: 'sjalfgefin_grunnskolaeinkunn', label: 'Sjálfgefin grunnskólaeinkunn' },
          { param: 'aldur_midannarmat', label: 'Miðannarmat - Aldurstakmark' },
          { param: 'kennari_keyra_lokaeinkunn', label: 'Má kennari keyra lokaeinkunn í feril í áföngum sem hann kennir', checkbox: true },
          { param: 'graf_lagmark', label: 'Lágmarksfjöldi einkunna sem þarf til að einkunnadreifing sé birt nemendum' },
        ],
        communication: [
          { param: 'forfoll_kennara_postur', label: 'Senda póst til nemenda þegar kennari forfallast', checkbox: true },
          { param: 'forfoll_senda_til_forr', label: 'Senda póst til forráðamanna/aðstandanda þegar kennari forfallast', checkbox: true },
          { param: 'forfoll_senda_sms', label: 'Senda SMS þegar kennari forfallast', checkbox: true },
        ],
        attendance: [
          {
            param: 'vidveru_kodi',
            label: 'Sjálfgefinn viðverukódi',
            category: 'default',
            dropdown: true,
            dropdownList: [],
          },
          { param: 'skra_vidveru_fram', label: 'Má skrá viðveru fram í tímann', category: 'default', checkbox: true },
          { param: 'maetingarhlutfall_aukastafir', label: 'Fjöldi aukastafa í birtingu mætingarhlutfalls', category: 'default' },
          { param: 'maetingarhlutfall_stig', label: 'Mætingarhlutfall - Mæting', category: 'percentage', checkbox: true },
          { param: 'maetingarhlutfall_raun', label: 'Mætingarhlutfall - Raunmæting', category: 'percentage', checkbox: true },
          { param: 'maetingarhlutfall_onn', label: 'Mætingarhlutfall - Önn', category: 'percentage', checkbox: true },
          { param: 'maetingarhlutfall_einkunn', label: 'Mætingarhlutfall - Til mætingareinkunnar', category: 'percentage', checkbox: true },
          { param: 'forfoll_prosenta', label: 'Frádráttarmörk forfalla (%)', category: 'percentage' },
          { param: 'ma_skra_veikindi_forradamadur', label: 'Forráðamaður má skrá veikindi', category: 'absence', checkbox: true },
          {
            param: 'ma_skra_veikindi_nemandi_y_18',
            label: 'Nemandi yngri en 18 ára má skrá veikindi',
            category: 'absence',
            checkbox: true,
          },
          {
            param: 'ma_skra_veikindi_nemandi_e_18',
            label: 'Nemandi eldri en 18 ára má skrá veikindi',
            category: 'absence',
            checkbox: true,
          },
          {
            param: 'ma_skra_veikindi_a_morgun',
            label: 'Má skrá veikindi rafrænt fyrir daginn á morgun',
            category: 'absence',
            checkbox: true,
          },
          {
            param: 'rafraen_veikindi_kodi',
            label: 'Tegund forfalla sem skráð er við samþykkt',
            category: 'absence',
            dropdown: true,
            dropdownList: [],
          },
          { param: 'rafraen_veikindi_hluti_dags', label: 'Má tilkynna veikindi hluta úr degi', category: 'absence', checkbox: true },
          { param: 'ma_skra_leyfi_skam', label: 'Forráðamaður má skrá ósk um skammtímaleyfi', category: 'short', checkbox: true },
          {
            param: 'ma_skra_leyfi_skam_nem_e_18',
            label: 'Nemandi eldri en 18 ára má skrá ósk um skammtímaleyfi',
            category: 'short',
            checkbox: true,
          },
          {
            param: 'rafraen_leyfi_skam_kodi',
            label: 'Tegund forfalla sem skráð er þegar skammtímaleyfi er samþykkt',
            category: 'short',
            dropdown: true,
            dropdownList: [],
          },
          { param: 'ma_skra_leyfi_lang', label: 'Forráðamaður má skrá ósk um langtímaleyfi', category: 'long', checkbox: true },
          {
            param: 'ma_skra_leyfi_lang_nem_e_18',
            label: 'Nemandi eldri en 18 ára má skrá ósk um langtímaleyfi',
            category: 'long',
            checkbox: true,
          },
          {
            param: 'rafraen_leyfi_lang_kodi',
            label: 'Tegund forfalla sem skráð er þegar langtímaleyfi er samþykkt',
            category: 'long',
            dropdown: true,
            dropdownList: [],
          },
        ],
        specialNeeds: [
          { param: 'nemandi_skra_serurraedi', label: 'Má nemandi skrá áfanga tengd sérúrræði í námi', checkbox: true },
          { param: 'serurraedi_prof', label: 'Eingöngu skrá sérúrræði á áfanga í próftöflu', checkbox: true },
          { param: 'almenn_serurraedi', label: 'Má nemandi skrá almenn sérúrræði í námi', checkbox: true },
        ],
        staff: [
          { param: 'starfsm_br_mynd', label: 'Má starfsmaður setja inn mynd', checkbox: true },
          { param: 'starfsmadur_ma_skra_forfoll', label: 'Má starfsmaður skrá eigin forföll', checkbox: true },
          // { param: 'ma_starfsm_skra_umfr_afanga', label: 'Má starfsmaður skrá áfanga á feril umfram hámark', checkbox: true },
          { param: 'starfsmadur_ma_senda_sms', label: 'Má starfsmaður senda sms', checkbox: true },
          { param: 'starfsmadur_br_bokalista', label: 'Má starfsmaður breyta námsgagnalista áfanga sem hann kennir', checkbox: true },
          { param: 'ma_skra_gomul_lokaprof', label: 'Má starfsmaður skrá gömul lokapróf', checkbox: true },
          { param: 'ma_fletta_upp_einstakling', label: 'Má starfsmaður fletta upp einstaklingum innan skólans', checkbox: true },
        ],
        premises: [
          { param: 'default_onn_id', label: 'Sjálfgefið kennslutímabil', dropdown: true, dropdownList: [] },
          { param: 'aldur_til_ad_sleppa_vid_fornam', label: 'Aldur til að sleppa við fornám' },
          {
            param: 'stilling_athugasemda',
            label: 'Sjálfgefin stilling athugasemda',
            dropdown: true,
            dropdownList: [
              { value: 0, text: 'Almenn athugasemd' },
              { value: 1, text: 'Persónuleg athugasemd' },
            ],
          },
        ],
        classes: [
          { param: 'nota_misserisskipti', label: 'Nota misserisskipti', checkbox: true },
          { param: 'fastar_stofur', label: 'Fastar stofur', checkbox: true },
          { param: 'skra_bekkjastofu_a_hopa', label: 'Skrá bekkjastofu sjálfkrafa á hópa', checkbox: true },
          { param: 'namsferill_bekkur_endurtaka', label: 'Á nemandi að endurtaka lokna áfanga ef hann endurtekur bekk', checkbox: true },
        ],
        otherSystems: [
          { param: 'nota_tbr', label: 'Senda álagningu til innheimtukerfis', checkbox: true },
          { param: 'profskirteini_leyfi', label: 'Stúdentsskírteini í vefþjónustu', checkbox: true },
          { param: 'hagst_audk', label: 'Hagstofuauðkenni', disabled: true },
          { param: 'sms_login', label: 'Notendanafn í SMS þjónustu', disabled: true },
          { param: 'greidslukerfi', label: 'Rafgreiðslur', checkbox: true, disabled: true },
        ],
        addOns: [
          { param: 'ma_skra_vidtalstima', label: 'Viðtalstímar', checkbox: true },
          { param: 'vist', label: 'Heimavist', checkbox: true },
          { param: 'vist_umsokn', label: 'Má nemandi sækja um heimavist', checkbox: true },
        ],
      },
    };
  },
  methods: {
    changeTab(tab) {
      this.tabIndex = tab;
      this.$router.push({ name: 'School', query: { page: this.page, tab: this.tabIndex } });
    },
    async loadTerms() {
      const response = await terms.getTerms();
      this.data.premises[0].dropdownList = response.data.items.map(x => ({
        value: x.onn_id,
        text: `${x.onn_eink} ${x.onn_heiti} ${x.undirsk_heiti}`,
      }));
    },
    async loadAbsenceTypes() {
      const response = await schoolTypes.getAbsenceTypesList();
      this.data.attendance[12].dropdownList = response.data.items.map(x => ({
        value: x.teg_forfalla,
        text: `${x.heiti} (${x.kodi})`,
      }));
      this.data.attendance[15].dropdownList = response.data.items.map(x => ({
        value: x.teg_forfalla,
        text: `${x.heiti} (${x.kodi})`,
      }));
      this.data.attendance[18].dropdownList = response.data.items.map(x => ({
        value: x.teg_forfalla,
        text: `${x.heiti} (${x.kodi})`,
      }));
    },
    async loadAttendanceTypes() {
      const response = await schoolTypes.getAttendanceTypesList();
      this.data.attendance[0].dropdownList = response.data.items
        .map(x => ({
          value: x.teg_fjarvista,
          text: `${x.heiti} (${x.kodi})`,
        }))
        .concat([
          {
            value: null,
            text: '',
          },
        ]);
    },
    async loadUserGroups() {
      const response = await users.getUserGroupsList({ active: 1 });
      this.data.students[3].dropdownList = response.data.items
        .map(x => ({
          value: x.hopur_id,
          text: x.heiti,
        }))
        .concat([
          {
            value: null,
            text: '',
          },
        ]);
      this.data.students[11].dropdownList = response.data.items
        .map(x => ({
          value: x.hopur_id,
          text: x.heiti,
        }))
        .concat([
          {
            value: null,
            text: '',
          },
        ]);
    },
    toggleEdit(item) {
      this.edit[item] = true;
    },
    closeEdit(item) {
      this.edit[item] = false;
    },
    toChange(item) {
      this.editItem[item.column] = item.value;
    },
    async saveChanges(type) {
      const data = {
        ...this.item,
      };
      switch (type) {
        case 'info':
          data.netfang = this.editItem.netfang;
          data.logo_slod = this.editItem.logo_slod;
          data.logo_slod_erl = this.editItem.logo_slod_erl;
          break;
        case 'closures':
          data.lokun_einkunnir = this.editItem.lokun_einkunnir;
          data.lokun_namsferill = this.editItem.lokun_namsferill;
          data.lokun_nemendur = this.editItem.lokun_nemendur;
          data.lokun_stundatafla = this.editItem.lokun_stundatafla;
          data.lokun_midannarmat = this.editItem.lokun_midannarmat;
          break;
        case 'students':
          data.nemandi_br_personuuppl = this.editItem.nemandi_br_personuuppl;
          data.nemandi_br_mynd = this.editItem.nemandi_br_mynd;
          data.namsferill_nemar_breyta = this.editItem.namsferill_nemar_breyta;
          data.val_hopur = this.editItem.namsferill_nemar_breyta === 1 ? this.editItem.val_hopur : null;
          // data.nemandi_velja_forgang = this.editItem.nemandi_velja_forgang;
          data.namsferill_nemar_jafngildir = this.editItem.namsferill_nemar_jafngildir;
          // data.ma_skra_p_og_u = this.editItem.ma_skra_p_og_u;
          data.namsferill_nemar_stadfesta_val = this.editItem.namsferill_nemar_stadfesta_val;
          data.nemandi_velja_af_utan_brauta = this.editItem.nemandi_velja_af_utan_brauta;
          data.nemandi_skra_sig_ur_afanga = this.editItem.nemandi_skra_sig_ur_afanga;
          data.nemandi_ser_proftoflu = this.editItem.nemandi_ser_proftoflu;
          data.proftafla_birta_stofu = this.editItem.proftafla_birta_stofu;
          data.nemandi_toflubreytingar = this.editItem.nemandi_toflubreytingar;
          data.toflubreytingar_hopur = this.editItem.nemandi_toflubreytingar > 0 ? this.editItem.toflubreytingar_hopur : '';
          break;
        case 'studyrecords':
          data.fj_afanga_onn = this.editItem.fj_afanga_onn;
          data.max_timar_onn = this.editItem.max_timar_onn;
          data.min_kennslust_viku = this.editItem.min_kennslust_viku;
          data.max_varaval = this.editItem.max_varaval;
          data.min_varaval = this.editItem.min_varaval;
          data.fall_onn_min_einingar = this.editItem.fall_onn_min_einingar;
          data.fall_onn_min_kennslust = this.editItem.fall_onn_min_kennslust;
          data.sjalfg_faera_eyda_afangakedju = this.editItem.sjalfg_faera_eyda_afangakedju;
          data.max_endurtekt = this.editItem.max_endurtekt;
          data.ma_tviskra_afanga = this.editItem.ma_tviskra_afanga;
          // data.utlit_namsfaetlun = this.editItem.utlit_namsfaetlun;
          // data.birta_eftir_a_braut_i_namsf = this.editItem.birta_eftir_a_braut_i_namsf;
          data.nota_einungis_matsannir = this.editItem.nota_einungis_matsannir;
          data.sjalfvirkt_val_a_brautir = this.editItem.sjalfvirkt_val_a_brautir;
          data.keyra_kjorsv_sjalfv_a_ferla = this.editItem.keyra_kjorsv_sjalfv_a_ferla;
          data.namsferill_fylla_onn = this.editItem.namsferill_fylla_onn;
          // data.kennari_skoda_namsferill = this.editItem.kennari_skoda_namsferill;
          data.max_afangar_styring = this.editItem.max_afangar_styring;
          break;
        case 'grades':
          data.sjalfg_falleinkunn = this.editItem.sjalfg_falleinkunn;
          data.reikna_lokaeink = this.editItem.reikna_lokaeink;
          // data.sjalfg_einkunnablad_heiti = this.editItem.sjalfg_einkunnablad_heiti;
          // data.nemandi_ser_medaltal = this.editItem.nemandi_ser_medaltal;
          // data.nemandi_ser_vegid_medaltal = this.editItem.nemandi_ser_vegid_medaltal;
          data.sjalfg_meink_frjals_maeting = this.editItem.sjalfg_meink_frjals_maeting;
          data.sjalfg_einingar_skirteini = this.editItem.sjalfg_einingar_skirteini;
          data.skolasokn_a_skirteini = this.editItem.skolasokn_a_skirteini;
          data.sjalfgefin_grunnskolaeinkunn = this.editItem.sjalfgefin_grunnskolaeinkunn;
          data.aldur_midannarmat = this.editItem.aldur_midannarmat;
          data.kennari_keyra_lokaeinkunn = this.editItem.kennari_keyra_lokaeinkunn;
          data.graf_lagmark = this.editItem.graf_lagmark;
          break;
        case 'communication':
          data.forfoll_kennara_postur = this.editItem.forfoll_kennara_postur;
          data.forfoll_senda_til_forr = this.editItem.forfoll_senda_til_forr;
          data.forfoll_senda_sms = this.editItem.forfoll_senda_sms;
          break;
        case 'attendance':
          data.vidveru_kodi = this.editItem.vidveru_kodi;
          data.skra_vidveru_fram = this.editItem.skra_vidveru_fram;
          data.maetingarhlutfall_aukastafir = this.editItem.maetingarhlutfall_aukastafir;
          data.maetingarhlutfall_stig = this.editItem.maetingarhlutfall_stig;
          data.maetingarhlutfall_raun = this.editItem.maetingarhlutfall_raun;
          data.maetingarhlutfall_onn = this.editItem.maetingarhlutfall_onn;
          data.maetingarhlutfall_einkunn = this.editItem.maetingarhlutfall_einkunn;
          data.forfoll_prosenta = this.editItem.forfoll_prosenta;
          data.ma_skra_veikindi_forradamadur = this.editItem.ma_skra_veikindi_forradamadur;
          data.ma_skra_veikindi_nemandi_y_18 = this.editItem.ma_skra_veikindi_nemandi_y_18;
          data.ma_skra_veikindi_nemandi_e_18 = this.editItem.ma_skra_veikindi_nemandi_e_18;
          data.ma_skra_veikindi_a_morgun = this.editItem.ma_skra_veikindi_a_morgun;
          data.rafraen_veikindi_kodi = this.editItem.rafraen_veikindi_kodi;
          data.ma_skra_leyfi_skam = this.editItem.ma_skra_leyfi_skam;
          data.ma_skra_leyfi_skam_nem_e_18 = this.editItem.ma_skra_leyfi_skam_nem_e_18;
          data.rafraen_veikindi_hluti_dags = this.editItem.rafraen_veikindi_hluti_dags;
          data.rafraen_leyfi_skam_kodi = this.editItem.rafraen_leyfi_skam_kodi;
          data.ma_skra_leyfi_lang = this.editItem.ma_skra_leyfi_lang;
          data.ma_skra_leyfi_lang_nem_e_18 = this.editItem.ma_skra_leyfi_lang_nem_e_18;
          data.rafraen_leyfi_lang_kodi = this.editItem.rafraen_leyfi_lang_kodi;
          break;
        case 'specialNeeds':
          data.nemandi_skra_serurraedi = this.editItem.nemandi_skra_serurraedi;
          data.serurraedi_prof = this.editItem.serurraedi_prof;
          data.almenn_serurraedi = this.editItem.almenn_serurraedi;
          break;
        case 'staff':
          data.starfsm_br_mynd = this.editItem.starfsm_br_mynd;
          data.starfsmadur_ma_skra_forfoll = this.editItem.starfsmadur_ma_skra_forfoll;
          // data.ma_starfsm_skra_umfr_afanga = this.editItem.ma_starfsm_skra_umfr_afanga;
          data.starfsmadur_br_bokalista = this.editItem.starfsmadur_br_bokalista;
          data.ma_skra_gomul_lokaprof = this.editItem.ma_skra_gomul_lokaprof;
          data.ma_fletta_upp_einstakling = this.editItem.ma_fletta_upp_einstakling;
          break;
        case 'premises':
          data.default_onn_id = this.editItem.default_onn_id;
          data.aldur_til_ad_sleppa_vid_fornam = this.editItem.aldur_til_ad_sleppa_vid_fornam;
          data.stilling_athugasemda = this.editItem.stilling_athugasemda;
          break;
        case 'classes':
          data.nota_misserisskipti = this.editItem.nota_misserisskipti;
          data.fastar_stofur = this.editItem.fastar_stofur;
          data.skra_bekkjastofu_a_hopa = this.editItem.skra_bekkjastofu_a_hopa;
          data.namsferill_bekkur_endurtaka = this.editItem.namsferill_bekkur_endurtaka;
          break;
        case 'otherSystems':
          data.nota_tbr = this.editItem.nota_tbr;
          data.profskirteini_leyfi = this.editItem.profskirteini_leyfi;
          break;
        case 'addOns':
          data.ma_skra_vidtalstima = this.editItem.ma_skra_vidtalstima;
          data.vist = this.editItem.vist;
          data.vist_umsokn = this.editItem.vist_umsokn;
          break;
        default:
          break;
      }
      try {
        await school.updateSchoolInfo(data);
        this.$emit('reload');
        this.edit = {
          info: false,
          closures: false,
          students: false,
          studyrecords: false,
          grades: false,
          attendance: false,
          communication: false,
          specialNeeds: false,
          staff: false,
          premises: false,
          classes: false,
          otherSystems: false,
          addOns: false,
        };
        this.displaySuccess('Stýringar vistaðar');
        await this.getLoggedInUser(); // Endursækja skólaaðgang ef verið er að nota í kerfinu eitthvestaðar
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
        this.displayError('Ekki tókst að vista');
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
      getLoggedInUser: 'getLoggedInUser',
    }),
  },
  watch: {
    item() {
      this.editItem = { ...this.item };
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (to && to.query && to.query.page) {
      this.page = to.query.page;
    }
    if (to && to.query && to.query.tab) {
      this.tabIndex = to.query.tab;
    }
    next();
  },
  created() {
    this.editItem = { ...this.item };
    this.loadTerms();
    this.loadAbsenceTypes();
    this.loadAttendanceTypes();
    this.loadUserGroups();

    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
    if (this.$route.query.tab) {
      this.tabIndex = this.$route.query.tab;
    }
  },
};
</script>

<style lang="scss">
.settings-nav {
  ul.nav {
    li.nav-item {
      a.nav-link {
        padding: 0;
        padding-left: 10px;
        color: inherit;
        font-size: 14px;
        line-height: 26px;
        &.active {
          border-left: 1px solid #98139c;
        }
      }
    }
  }
}
</style>
