<template>
  <div>
    <b-modal
      id="termCreateModal"
      :title="title"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Vista"
      cancel-title="Hætta við"
      @ok.prevent="validateBeforeSubmit"
      :ok-disabled="submitting"
      ref="createModal"
    >
      <div>
        <b-form @submit.prevent="validateBeforeSubmit" novalidate>
          <b-form-group
            label="Kennslutímabil*"
            v-if="!this.termId"
            label-for="term"
            :state="submitted && errors.has('term') ? false : ''"
            :feedback="errors.first('term')"
          >
            <v-select
              id="term"
              name="term"
              v-model="form.term"
              :state="submitted && errors.has('term') ? false : ''"
              v-validate="'required'"
              :options="termsList"
              label="onn_eink"
            >
            </v-select>
          </b-form-group>
          <b-form-group
            label="Gildir frá*"
            label-for="date_from"
            :state="submitted && errors.has('date_from') ? false : ''"
            :feedback="errors.first('date_from')"
          >
            <datepicker
              id="date_from"
              name="date_from"
              :class="{ 'is-invalid': submitted && errors.has('date_from') }"
              v-model="form.date_from"
              :language="lang"
              :monday-first="true"
              format="dd.MM.yyyy"
              :typeable="false"
              ref="dateFromOpenPickr"
              v-validate="'required'"
            ></datepicker>
          </b-form-group>
          <b-form-group
            label="Gildir til*"
            label-for="date_to"
            :state="submitted && errors.has('date_to') ? false : ''"
            :feedback="errors.first('date_to')"
          >
            <datepicker
              id="date_to"
              name="date_to"
              :class="{ 'is-invalid': submitted && errors.has('date_to') }"
              v-model="form.date_to"
              :language="lang"
              :monday-first="true"
              format="dd.MM.yyyy"
              :typeable="false"
              ref="dateToOpenPickr"
              v-validate="'required'"
            ></datepicker>
          </b-form-group>
          <b-form-group
            label="Upphafsmánuður launa*"
            label-for="firstPay"
            :state="submitted && errors.has('firstPay') ? false : ''"
            :feedback="errors.first('firstPay')"
          >
            <v-select
              id="firstPay"
              name="firstPay"
              v-model="form.first_pay"
              :state="submitted && errors.has('firstPay') ? false : ''"
              v-validate="'required'"
              :options="monthList"
              label="name"
            >
            </v-select>
          </b-form-group>
          <b-form-group
            label="Opið starfsmönnum*"
            label-for="open"
            :state="submitted && errors.has('open') ? false : ''"
            :feedback="errors.first('open')"
          >
            <b-form-checkbox id="open" name="open" v-model="form.open"></b-form-checkbox>
          </b-form-group>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import staff from '@/api/staff';

import moment from 'moment';
import { mapActions } from 'vuex';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';

export default {
  name: 'term-create-modal',
  components: {
    Datepicker,
  },
  data() {
    return {
      submitted: false,
      submitting: false,
      lang: is,
      termId: '',
      title: 'Stofna kennslutímabil',
      form: {
        term: '',
        name: '',
        date_from: '',
        date_to: '',
        first_pay: '',
        open: false,
      },
      termsList: [],
      monthList: [
        { id: 1, name: 'janúar' },
        { id: 2, name: 'febrúar' },
        { id: 3, name: 'mars' },
        { id: 4, name: 'apríl' },
        { id: 5, name: 'maí' },
        { id: 6, name: 'júní' },
        { id: 7, name: 'júlí' },
        { id: 8, name: 'ágúst' },
        { id: 9, name: 'september' },
        { id: 10, name: 'október' },
        { id: 11, name: 'nóvember' },
        { id: 12, name: 'desember' },
      ],
      loading: {
        terms: false,
      },
    };
  },
  methods: {
    async loadTerms() {
      this.loading.terms = true;
      try {
        const response = await staff.getWorklogTermUnusedList();
        this.termsList = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.terms = false;
      }
    },
    async load() {
      this.loading.info = true;
      try {
        const response = await staff.getWorklogTermDetail(this.termId);
        if (response.data.items.length > 0) {
          this.form.name = `${response.data.items[0].onn_heiti} ${response.data.items[0].onn_eink}`;
          this.form.date_from = moment(response.data.items[0].vinnuskyrsla_dags_fra).toDate();
          this.form.date_to = moment(response.data.items[0].vinnuskyrsla_dags_til).toDate();
          this.form.open = response.data.items[0].virk === 1;
          this.form.first_pay = response.data.items[0].upphafsmanudur ? this.monthList[response.data.items[0].upphafsmanudur - 1] : null;
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.info = false;
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.loading = true;
          const id = this.termId ? this.termId : this.form.term.onn_id;
          await staff.createWorklogTerm(id, {
            date_from: moment(this.form.date_from).format('DD.MM.YYYY'),
            date_to: moment(this.form.date_to).format('DD.MM.YYYY'),
            open: this.form.open ? 1 : 0,
            month: this.form.first_pay.id,
          });
          if (this.termId) {
            this.displaySuccess('Kennslutímabili breytt');
          } else {
            this.displaySuccess('Kennslutímabil stofnað');
          }
          this.$emit('successful', { id });
          this.$refs.createModal.hide();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    show(termId) {
      this.form.term = '';
      this.form.date_from = '';
      this.form.date_to = '';
      this.form.first_pay = '';
      this.termId = '';
      this.title = 'Stofna kennslutímabil';
      this.submitted = false;
      if (termId) {
        this.title = 'Breyta kennslutímabili';
        this.termId = termId;
        this.load();
      } else {
        this.loadTerms();
      }
      this.$refs.createModal.show();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
<style lang="scss"></style>
