<template>
  <div>
    <b-row>
      <b-col class="pl-0">
        <StudentInfo v-if="tabIndex === 'info'" :id="id" :item="item" @refresh="refresh"></StudentInfo>
        <StudentComments v-if="tabIndex === 'comments'" :studentId="id" :reload="refresh" />
        <UserGroups v-if="tabIndex === 'userGroup'" :userId="item && item.notandi_id" />
        <StudentApplicationList v-if="tabIndex === 'applications'" :studentId="id" />
        <StudentHistory v-if="tabIndex === 'history'" :id="id"></StudentHistory>
        <StudentGraduations v-if="tabIndex === 'graduations'" :id="id"></StudentGraduations>
        <RequestsAll v-if="tabIndex === 'specialNeeds'" :ssn="studentSsn" :studentCardView="1"></RequestsAll>
        <StudentSendMessage
          v-if="tabIndex === 'messages'"
          :nemandi_id="id"
          :kennari_nemanda="1"
          :notandi_id="item.notandi_id"
        ></StudentSendMessage>
      </b-col>
      <b-col cols="2">
        <div class="right-side-nav noprint">
          <b-nav vertical>
            <b-nav-item @click.prevent="changeTab('info')" :active="tabIndex === 'info'">Upplýsingar</b-nav-item>
            <b-nav-item @click.prevent="changeTab('comments')" :active="tabIndex === 'comments'">Athugasemdir</b-nav-item>
            <b-nav-item @click.prevent="changeTab('userGroup')" :active="tabIndex === 'userGroup'">Notendahópar</b-nav-item>
            <b-nav-item @click.prevent="changeTab('applications')" :active="tabIndex === 'applications'">Umsóknir</b-nav-item>
            <b-nav-item @click.prevent="changeTab('history')" :active="tabIndex === 'history'">Saga</b-nav-item>
            <b-nav-item @click.prevent="changeTab('graduations')" :active="tabIndex === 'graduations'">Útskriftir</b-nav-item>
            <b-nav-item @click.prevent="changeTab('specialNeeds')" :active="tabIndex === 'specialNeeds'">Sérúrræði</b-nav-item>
            <b-nav-item @click.prevent="changeTab('messages')" :active="tabIndex === 'messages'">Skilaboð</b-nav-item>
            <!--b-nav-item @click.prevent="changeTab('warning')" :active="tabIndex === 'warning'">Viðvaranir</b-nav-item-->
            <!--b-nav-item @click.prevent="changeTab('payments')" :active="tabIndex === 'payments'">Greiðslur</b-nav-item>
              <b-nav-item @click.prevent="changeTab('documents')" :active="tabIndex === 'documents'">Fylgigögn</b-nav-item-->
          </b-nav>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import StudentInfo from '@/components/students/student/details/StudentInfo.vue';
import StudentHistory from '@/components/students/student/details/StudentHistory.vue';
import StudentComments from '@/components/students/comments/StudentComments.vue';
import UserGroups from '@/components/users/UserGroups.vue';
import StudentApplicationList from '@/components/applications/student/StudentApplicationList.vue';
import StudentGraduations from '@/components/students/student/details/StudentGraduations.vue';
import RequestsAll from '@/components/requests/specialResources/RequestsAll.vue';
import StudentSendMessage from '@/components/students/student/details/StudentSendMessage.vue';

export default {
  name: 'student-details',
  props: ['id', 'item', 'studentSsn'],
  components: {
    StudentInfo,
    StudentHistory,
    StudentComments,
    UserGroups,
    StudentApplicationList,
    StudentGraduations,
    RequestsAll,
    StudentSendMessage,
  },
  computed: {
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      tabIndex: 'info',
      page: 'details',
    };
  },
  methods: {
    refresh() {
      this.$emit('refresh');
    },
    changeTab(tab) {
      this.tabIndex = tab;
      this.$router.push({ name: 'Student', query: { page: this.page, tab: this.tabIndex } });
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  beforeRouteUpdate(to, from, next) {
    if (to && to.query && to.query.page) {
      this.page = to.query.page;
    }
    if (to && to.query && to.query.tab) {
      this.tabIndex = to.query.tab;
    }
    next();
  },
  created() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
    if (this.$route.query.tab) {
      this.tabIndex = this.$route.query.tab;
    }
  },
};
</script>
<style lang="scss"></style>
