<template>
  <Slidebar :autoClose="false" :large="true" @closeMe="closeSlidebar">
    <h4>Staðfesta val<loader v-if="loading"></loader></h4>
    <div v-if="studentActive === 0">Nemandi er óvirkur og því ekki hægt að skrá hann í áfanga.</div>
    <b-row v-if="studentActive === 1">
      <div>Hér fyrir neðan má sjá hvenær hefur verið staðfest val, hver gerði það og fyrir hvaða áfanga.</div>
    </b-row>
    <b-row v-if="studentActive === 1" class="pt-3 pb-2">
      <b-col class="pl-0">
        <b-form-group :state="saving && errors.has('selectedTerm') ? false : ''" :invalid-feedback="errors.first('onn')">
          <label for="selectedTerm"> Tímabil </label>
          <v-select id="onn" name="onn" v-model="selectedTerm" :options="terms" :clearable="false" label="onn_eink"> </v-select>
        </b-form-group>
      </b-col>
      <b-col class="pl-0">
        <div style="position: absolute; bottom: 9px">
          <b-btn variant="primary" size="sm" v-if="canEdit" @click="confirmTermSelection()" :disabled="saving">
            <i class="fa fa-spin fa-spinner" v-if="saving"></i>
            Staðfesta val
          </b-btn>
        </div>
      </b-col>
    </b-row>
    <i class="fa fa-spin fa-spinner" v-if="loadingData"></i>
    <table class="table less-padding pt-1" style="font-size: 13px" v-if="termSelection.length > 0 && !loadingData">
      <thead>
        <tr>
          <th>Dags.</th>
          <th>Tímabil</th>
          <th>Staðfest</th>
          <th>Lýsing</th>
        </tr>
      </thead>
      <tr v-for="(c, idx) in termSelection" :key="`c-${idx}`">
        <td class="dont-break">{{ c.dags | moment('DD.MM.YYYY HH:mm:ss') }}</td>
        <td>{{ c.onn_eink }}</td>
        <td class="dont-break">{{ c.nafn }}</td>
        <td>{{ c.texti }}</td>
      </tr>
    </table>
  </Slidebar>
</template>

<script>
import students from '@/api/students';
import { mapActions, mapGetters } from 'vuex';
import Slidebar from '@/components/common/Slidebar.vue';
import Loader from '@/components/common/Loader.vue';

export default {
  name: 'confirm-selection-term-slidebar',
  props: ['studentId', 'studentActive', 'large', 'extraLarge'],
  components: {
    Slidebar,
    Loader,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('nemendahald_nemendur');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      item: '',
      selectedTerm: '',
      term: [],
      terms: [],
      termSelection: [],
      loading: false,
      saving: false,
      baba: false,
      loadingData: false,
    };
  },
  methods: {
    async getStudentDefaultTerm() {
      try {
        this.noTerm = false;
        const response = await students.getStudentDefaultTerm(this.studentId);
        this.term.id = response.data.onn_id;
        this.term.dateFrom = response.data.dags_fra;
        this.term.dateTo = response.data.dags_til;
        this.term.name = response.data.onn_eink;
        this.loadTerms(this.term.id);
      } catch (e) {
        if (e.status === 404) {
          this.noTerm = true;
        } else {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    async loadTerms(id) {
      try {
        this.loading = true;
        const response = await students.getStudentTerms(this.studentId, { future_terms: 1, past_terms: 1 });
        this.terms = response.data.items;
        if (id) {
          this.selectedTerm = this.terms.find(x => x.onn_id === id);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadTermSelection() {
      try {
        this.loadingData = true;
        const response = await students.getConfirmTermSelection(this.studentId);
        this.termSelection = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loadingData = false;
      }
    },
    async confirmTermSelection() {
      try {
        this.saving = true;
        await students.confirmTermSelection(this.studentId, this.selectedTerm.onn_id);
        this.displaySuccess(`Staðfest val hefur verið skráð.`);
        this.closeAndReloadSlidebar();
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.saving = false;
      }
    },
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    closeAndReloadSlidebar() {
      this.$emit('closeAndReloadSlidebar');
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.getStudentDefaultTerm();
    this.loadTermSelection();
  },
};
</script>
