
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';

import moment from 'moment';
import school from '@/api/school';
import common from '@/api/common';

import { Action, Getter } from 'vuex-class';

import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';
import Loader from '@/components/common/Loader.vue';
import Pagination from '@/components/common/Pagination.vue';

import ModuleStudentListSlidebar from '@/components/students/groups/ModuleStudentListSlidebar.vue';
import GroupsByModuleItem from '@/components/students/groups/GroupsByModuleItem.vue';
import CreateGroupsPlannedSlidebar from '@/components/students/groups/CreateGroupsPlannedSlidebar.vue';
import CreateGroupSlidebar from '@/components/students/groups/CreateGroupSlidebar.vue';

@Component({
  components: {
    TableRowLoader,
    TableRowEmpty,
    LinkOrder,
    ModuleStudentListSlidebar,
    GroupsByModuleItem,
    CreateGroupsPlannedSlidebar,
    CreateGroupSlidebar,
    Loader,
    Pagination,
  },
})
export default class GroupsByModuleList extends Vue {
  @Action displayError!: (s: string) => void;
  @Action displaySuccess!: (s: string) => void;
  // @State loggedInUser: any;
  @Getter loggedInUserHasReadPermission: any;
  @Getter loggedInUserHasWritePermission: any;

  @Prop() load!: boolean;
  @Prop() canEdit!: boolean;
  @Prop() selected!: any;

  loading = {
    modules: false,
    worker: false,
  };
  modules: any[] = [];
  worker = false;
  showStudentSlidebar = false;
  showPlannedSlidebar = false;
  showCreateSlidebar = false;
  selectedItem: any | null = '';
  selectedItems: any = [];
  selectedModules: any = [];
  openGroups: any[] = [];
  perPage = 20;
  currentPage = 1;

  /**
   * Computed
   */
  get getExcelData() {
    const newList = [
      [],
      ['Hópaskipting'],
      [`${this.selected.term.onn_heiti}`],
      [this.selected.department.name],
      [this.selected.module],
      [],
      [
        'Áfangi',
        'Fjöldi hópa',
        'Fjöldi hópa áætlað',
        'Heildarfjöldi nema',
        'Fjöldi nema - Aðalval',
        'Fjöldi nema - Varaval',
        'Fjöldi nema - Ekki í hóp',
        'Fjöldi nema - Í hóp',
      ],
      ...this.modules.map(x => [
        x.namsgrein_afanganr,
        x.fj_hopa_stofnad,
        x.fj_hopa_aetlad,
        x.fj_nemenda,
        x.fj_nema_adalval,
        x.fj_nema_varaval,
        x.fj_nema_ekki_i_hop,
        x.fj_nema_i_hop,
      ]),
    ];
    return newList;
  }

  get getExcelFileName() {
    return `hopaskipting_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
  }
  get canRead() {
    return this.loggedInUserHasReadPermission('nemendahald_hopar');
  }

  get getPaginatedList() {
    const start = (this.currentPage - 1) * this.perPage;
    const end = this.currentPage * this.perPage;
    return this.modules.slice(start, end);
  }

  /**
   * Methods
   */
  async loadModules() {
    try {
      if (this.canRead) {
        this.loading.modules = true;
        this.modules = [];
        this.currentPage = 1;
        const response = await school.getGroupsByModules({
          onn_id: this.selected.term.onn_id,
          namsgrein_afanganr: this.selected.module,
          deild_id: this.selected.department ? this.selected.department.department_id : '',
          noGroups: this.selected.noGroups && this.selected.noGroups.id === 1 ? 1 : '',
        });
        this.modules = response.data.items;
      }
    } catch (e) {
      this.$log.error(e);
      this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
    } finally {
      this.loading.modules = false;
    }
  }

  updateSelectedItems(id: any) {
    if (this.selectedItems.indexOf(id) > -1) {
      this.selectedItems.splice(this.selectedItems.indexOf(id), 1);
    } else {
      this.selectedItems.push(id);
    }
  }
  updateOpenGroups(id: any) {
    if (this.openGroups.indexOf(id) > -1) {
      this.openGroups.splice(this.openGroups.indexOf(id), 1);
    } else {
      this.openGroups.push(id);
    }
  }
  getSelected() {
    return this.modules.filter(x => this.selectedItems.indexOf(x.annir_afangar_id) > -1);
  }
  openStudentSlidebar(item: any) {
    this.selectedItem = {
      ...item,
    };
    this.showStudentSlidebar = true;
    this.showPlannedSlidebar = false;
  }
  openPlannedSlidebar(type: any, item: any | null) {
    this.selectedModules = [];
    // type: 0 allir, 1: hakað við, 2: ákveðinn
    if (type === 1) this.selectedModules = [...this.getSelected()];
    else if (type === 2) this.selectedModules.push(item);
    this.showPlannedSlidebar = true;
    this.showStudentSlidebar = false;
    this.showCreateSlidebar = false;
  }
  openCreateSlidebar(item: any) {
    this.showCreateSlidebar = true;
    this.showStudentSlidebar = false;
    this.showPlannedSlidebar = false;
    this.selectedItem = {
      ...item,
    };
  }
  closeSlidebar() {
    this.showStudentSlidebar = false;
    this.showPlannedSlidebar = false;
    this.showCreateSlidebar = false;
    this.selectedItem = '';
    this.selectedModules = [];
  }
  closeAndReloadSlidebar() {
    this.closeSlidebar();
    this.loadModules();
  }
  closeAndLoadWorkersSlidebar() {
    this.closeSlidebar();
    this.loadWorkerInProgress();
  }

  changePage(page: any) {
    this.currentPage = page;
  }

  changeLimit(limit: any) {
    this.perPage = limit;
  }

  async loadWorkerInProgress() {
    try {
      this.loading.worker = true;
      const response = await common.getWorkersInProgress('stofna_aetlada_hopa');
      this.worker = response.data.items.filter((x: any) => x.reference_id === this.selected.term.onn_id).length > 0;
    } catch (e) {
      this.$log.error(e);
      this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
    } finally {
      this.loading.worker = false;
    }
  }

  onStofnaAetladaHopaComplete(data: { id: number }) {
    this.loadWorkerInProgress();
    if (data.id === this.selected.term.onn_id) {
      this.loadModules();
    }
  }

  onStofnaAetladaHopaFailure(data: { id: number }) {
    this.loadWorkerInProgress();
    if (data.id === this.selected.term.onn_id) {
      this.displayError(`Villa kom upp við að stofna áætlaða hópa. Vinsamlegast reyndu aftur.`);
      this.loadModules();
    }
  }

  onStofnaAetladaHopaStarted() {
    this.loadWorkerInProgress();
  }

  created() {
    if (this.load) {
      this.loadModules();
    }
  }

  @Watch('load')
  onPropertyChanged() {
    if (this.load) {
      this.loadModules();
      this.openGroups = []; // Loka öllum hópum sem eru opnir við "LEITA"
    }
  }

  mounted() {
    this.$socket.emit('joinStofnaAetladaHopa');
    this.sockets.subscribe('stofnaAetladaHopaStarted', this.onStofnaAetladaHopaStarted);
    this.sockets.subscribe('stofnaAetladaHopaComplete', this.onStofnaAetladaHopaComplete);
    this.sockets.subscribe('stofnaAetladaHopaFailure', this.onStofnaAetladaHopaFailure);
  }

  beforeDestroy() {
    this.$socket.emit('leaveStofnaAetladaHopa');
    this.sockets.unsubscribe('stofnaAetladaHopaStarted');
    this.sockets.unsubscribe('stofnaAetladaHopaComplete');
    this.sockets.unsubscribe('stofnaAetladaHopaFailure');
  }
}
