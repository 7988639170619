<template>
  <div>
    <b-row class="pb-4">
      <b-col class="pl-0" cols="10">
        <div class="mb-2" v-show="!studentCardView">
          Hér er að finna allar beiðnir um sérúrræði, ef smellt er á "Sýna ítarleit" er hægt að sía eftir fleiri möguleikum. Fyrir aftan
          nafn nemandans er stækkunargler, ef smellt er á það er hægt að sjá allar umsóknir nemandans óháð stöðu og önn.
        </div>
        <div class="mb-2" v-show="studentCardView">Hér er að finna allar beiðnir um sérúrræði hjá nemandanum</div>
      </b-col>
      <b-col>
        <b-btn variant="primary" v-if="canEdit" @click="openCreateSlidebar()" class="float-right"
          ><i class="fa fa-plus-circle"></i> Stofna beiðni</b-btn
        >
      </b-col>
    </b-row>
    <b-row v-show="!studentCardView">
      <b-col class="pl-0">
        <b-form-group label="Kennslutímabil" label-for="term">
          <v-select id="term" name="term" v-model="selected.term" :options="terms" :multiple="true" :clearable="true"> </v-select>
        </b-form-group>
        <div v-if="selected.term">
          <b-form-checkbox v-model="selected.showCommon" class="d-inline-block"></b-form-checkbox>
          Sýna einnig almenn sérúrræði
        </div>
        <div v-else>
          <b-form-checkbox v-model="selected.onlyShowCommon" class="d-inline-block"></b-form-checkbox>
          Sýna eingöngu almenn sérúrræði
        </div>
      </b-col>
      <b-col>
        <b-form-group label="Tegund" label-for="type">
          <v-select id="type" name="type" v-model="selected.type" :options="types" :clearable="true" label="heiti"> </v-select>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Staða" label-for="status">
          <v-select id="status" name="status" v-model="selected.status" :options="statuses" :clearable="true"> </v-select>
        </b-form-group>
      </b-col>
      <b-col>
        <b-btn variant="primary" class="w-100" style="margin-top: 18px" @click="loadList()">Leita</b-btn>
        <div class="cursor-pointer text-right pt-2" @click="detailedSearch = !detailedSearch">
          <span v-if="detailedSearch"><i class="fa fa-angle-up"></i> Fela ítarleit</span>
          <span v-else><i class="fa fa-angle-up"></i> Sýna ítarleit</span>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="detailedSearch">
      <b-col class="pl-0">
        <b-form-group label="Áfangi" label-for="module">
          <b-form-input id="module" name="module" v-model="selected.module" v-on:keyup.enter="loadList"> </b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Deild" label-for="department">
          <v-select id="department" name="department" v-model="selected.department" :options="departments" :clearable="true"> </v-select>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Nemandi" label-for="student" v-show="!studentCardView">
          <b-form-input id="student" name="student" v-model="selected.name" v-on:keyup.enter="loadList"> </b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Kennitala" label-for="ssn" v-show="!studentCardView">
          <b-form-input id="ssn" name="ssn" v-model="selected.ssn" v-on:keyup.enter="loadList"> </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="pt-2">
      <b-col class="pl-0">
        <Loader v-if="loading.list" />
        <div v-if="firsttime && list.length === 0 && !loading.list">Engar beiðnir fundust.</div>
        <table class="table table-hover table-sticky-header less-padding" v-if="list.length > 0" style="font-size: 13px">
          <thead>
            <tr>
              <th v-if="canEdit">
                <b-btn
                  variant="danger"
                  class="btn-xs"
                  style="font-size: 11px"
                  :disabled="deleting"
                  v-if="getSelected.length > 0"
                  @click="deleteSelectedItems()"
                >
                  <i class="fa fa-trash"></i>
                </b-btn>
              </th>
              <th><LinkOrder title="Nafn" :filteredList="list" :list="list" column="nafn" />({{ list.length }})</th>
              <th>
                <LinkOrder title="Kennitala" :filteredList="list" :list="list" column="kennitala" secondColumn="nafn" />
              </th>
              <th>
                <LinkOrder title="Áfangi" :filteredList="list" :list="list" column="namsgrein_afanganr" secondColumn="nafn" />
              </th>
              <th>
                <LinkOrder title="Kennslutímabil" :filteredList="list" :list="list" column="onn_eink" secondColumn="nafn" />
              </th>
              <th v-for="t in types" :key="t.teg_serurraedi_id">{{ t.heiti }}</th>
              <th>
                <LinkOrder title="Dags" :filteredList="list" :list="list" column="dags" secondColumn="nafn" />
              </th>
              <th>
                <LinkOrder title="Staða" :filteredList="list" :list="list" column="stada" secondColumn="nafn" />
              </th>
              <th>
                <LinkOrder title="Aths." :filteredList="list" :list="list" column="afgreidsla_athugasemd" secondColumn="nafn" />
                <i class="fa fa-info-circle" title="Athugasemd afgreiðslu" v-b-tooltip></i>
              </th>
              <th>
                <LinkOrder title="Próftími" :filteredList="list" :list="list" column="proftimi" secondColumn="nafn" />
              </th>
              <th>
                <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="cursor-pointer float-right">
                  <i class="fa fa-file-excel-o excelText"></i>
                </download-csv>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in getPaginatedAll" :key="index">
              <td v-if="canEdit">
                <b-form-checkbox v-model="item.selected"> </b-form-checkbox>
              </td>
              <td>
                {{ item.nafn }} {{ item.bekkur }}
                <b-link
                  :to="{ name: 'Student', params: { id: item.nemandi_id } }"
                  target="_blank"
                  v-if="canViewStudent && !studentCardView"
                  title="Skoða nemanda"
                  v-b-tooltip
                  ><i class="fa fa-external-link mr-1"></i
                ></b-link>
                <i
                  class="fa fa-search text-primary cursor-pointer"
                  title="Skoða öll sérúrræði"
                  v-b-tooltip
                  @click="openStudentSlidebar(item)"
                ></i>
                <div v-if="item && item.skrad_af_nafn !== item.nafn">
                  <i style="font-size: 12px; padding-left: 5px">Skráð af: {{ item.skrad_af_nafn }}</i>
                </div>
              </td>
              <td>{{ item.kennitala }}</td>
              <td>{{ item.namsgrein_afanganr }}</td>
              <td>{{ item.onn_eink }}</td>
              <td v-for="t in types" :key="`${index}-${t.teg_serurraedi_id}`" style="max-width: 100px">
                {{ getType(item, t) }}
              </td>
              <td>{{ item.dags | moment('DD.MM.YYYY') }}</td>
              <td>{{ item.stada_heiti }}</td>
              <td style="font-size: 13px" :title="item.afgreidsla_athugasemd" v-b-tooltip>
                {{
                  item.afgreidsla_athugasemd && item.afgreidsla_athugasemd.length > 40
                    ? `${item.afgreidsla_athugasemd.substring(0, 40)}..`
                    : item.afgreidsla_athugasemd
                }}
              </td>
              <td>{{ item.proftimi }}</td>
              <td>
                <b-btn variant="primary" v-if="canEdit" @click="openEditSlidebar(item)" class="btn-xs" style="font-size: 12px"
                  ><i class="fa fa-pencil"></i
                ></b-btn>
              </td>
            </tr>
          </tbody>
        </table>
        <Pagination
          :currentPage="currentPage"
          :totalRows="list.length"
          :perPage="perPage"
          v-if="list.length > 15"
          @change-page="changePage"
          @change-limit="changeLimit"
        />
      </b-col>
    </b-row>
    <create-request-slidebar
      v-if="showCreateSlidebar"
      :ssn="ssn"
      :studentCardView="studentCardView"
      @closeSlidebar="closeSlidebar"
      @closeAndReload="closeAndReloadSlidebar"
    />
    <edit-request-slidebar
      v-if="showEditSlidebar"
      :id="selectedItem ? selectedItem.nemandi_serurraedi_id : ''"
      @closeSlidebar="closeSlidebar"
      @closeAndReload="closeAndReloadSlidebar"
    />
    <student-special-resource-slidebar
      v-if="showStudentSlidebar"
      :id="selectedItem ? selectedItem.nemandi_id : ''"
      :showInfo="true"
      @reloadData="loadList"
      @closeSlidebar="closeSlidebar"
      @closeAndReload="closeAndReloadSlidebar"
    />
  </div>
</template>

<script>
import moment from 'moment';
import requests from '@/api/requests';
import terms from '@/api/terms';
import types from '@/api/types';
import structure from '@/api/structure';
import { mapGetters, mapActions } from 'vuex';

import LinkOrder from '@/components/common/LinkOrder.vue';
import Loader from '@/components/common/Loader.vue';
import Pagination from '@/components/common/Pagination.vue';

import CreateRequestSlidebar from '@/components/requests/specialResources/CreateRequestSlidebar.vue';
import EditRequestSlidebar from '@/components/requests/specialResources/EditRequestSlidebar.vue';
import StudentSpecialResourceSlidebar from '@/components/students/student/specialResource/StudentSpecialResourceSlidebar.vue';

export default {
  name: 'special-resources-all',
  props: ['ssn', 'studentCardView'],
  components: {
    Loader,
    LinkOrder,
    Pagination,
    CreateRequestSlidebar,
    EditRequestSlidebar,
    StudentSpecialResourceSlidebar,
  },
  computed: {
    getExcelData() {
      const string = [];

      string.push(['Allar beiðnir vegna sérúrræða']);

      const headers = [
        'Nafn',
        'Kennitala',
        'Undirskóli',
        'Staða nema',
        'Braut nema',
        'Áfangi',
        'Önn',
        'Dags.',
        'Staða',
        'Athugasemd afgreiðslu',
        'Skráð af',
        'Próftími',
      ];

      if (this.types.some(t => t.active)) {
        this.types.forEach(t => {
          if (t.active) {
            headers.push(t.heiti);
          }
        });
      }

      const items = [];

      this.list.forEach(item => {
        const row = [];

        row.push(item.nafn);
        row.push(`="${item.kennitala}"`);
        row.push(item.undirsk_audk);
        row.push(item.stada_nema_heiti);
        row.push(item.braut);
        row.push(item.namsgrein_afanganr);
        row.push(item.onn_eink);
        row.push(moment(item.dags).format('DD.MM.YYYY'));
        row.push(item.stada_heiti);
        row.push(item.afgreidsla_athugasemd);
        row.push(item.skrad_af_nafn);
        row.push(item.proftimi);

        if (this.types.some(t => t.active)) {
          this.types.forEach(t => {
            if (t.active) {
              const type = item.tegundir.find(x => x.teg_serurraedi_id === t.teg_serurraedi_id);
              let value = '';
              if (type) {
                value = (type.text || '').length > 0 ? type.text : 'Já';
              }
              row.push(value);
            }
          });
        }
        items.push(row);
      });

      return [string, headers, ...items];
    },
    getExcelFileName() {
      return `serurraedi_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('beidnir_serurraedi');
    },
    getSelected() {
      return this.list.filter(x => x.selected);
    },
    getPaginatedAll() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.list.slice(start, end);
    },
    canViewStudent() {
      return this.loggedInUserHasWritePermission('nemendahald_nemendur');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      perPage: 15,
      currentPage: 1,
      loading: {
        list: false,
        terms: false,
        types: false,
      },
      deleting: false,
      detailedSearch: false,
      selected: {
        term: [],
        module: '',
        type: '',
        name: '',
        ssn: '',
        status: '',
        showCommon: false,
        onlyShowCommon: false,
        department: '',
      },
      list: [],
      terms: [],
      types: [],
      departments: [],
      statuses: [
        { id: 0, label: 'Óafgreidd' },
        { id: 1, label: 'Samþykkt' },
        { id: 2, label: 'Í bið' },
        { id: 3, label: 'Hafnað' },
      ],
      selectedItem: '',
      firsttime: false,
      showCreateSlidebar: false,
      showEditSlidebar: false,
      showStudentSlidebar: false,
    };
  },
  methods: {
    getType(item, type) {
      const data = item.tegundir.find(x => x.teg_serurraedi_id === type.teg_serurraedi_id);
      let value = '';
      if (data) {
        value = (data.text || '').length > 0 ? data.text : 'Já';
      }
      return value;
    },
    async loadList() {
      try {
        this.loading.list = true;
        this.list = [];
        this.firsttime = true;
        const item = {
          status: this.selected.status ? this.selected.status.id : '',
          term: this.selected.term ? this.selected.term.map(x => x.onn_id).join(',') : '',
          module: this.selected.module,
          type: this.selected.type ? this.selected.type.teg_serurraedi_id : '',
          name: this.selected.name,
          ssn: this.selected.ssn,
          department: this.selected.department ? this.selected.department.department_id : '',
          showCommon: this.selected.term && this.selected.showCommon ? 1 : '',
          onlyShowCommon: !this.selected.term && this.selected.onlyShowCommon ? 1 : '',
          orderBy: 1,
        };

        const response = await requests.getSpecialResources(item);
        this.list = response.data.items.map(x => ({
          ...x,
          tegundir: x.tegundir ? JSON.parse(x.tegundir) : [],
          stada_heiti: this.statuses.find(y => y.id === x.stada) ? this.statuses.find(y => y.id === x.stada).label : '',
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.list = false;
      }
    },
    async loadTerms() {
      try {
        this.loading.terms = true;
        const response = await terms.getTerms();
        this.terms = response.data.items.map(x => ({
          ...x,
          label: `${x.undirsk_audk} - ${x.onn_heiti}`,
        }));
        if (this.terms.filter(x => x.is_active === 1).length > 0) {
          this.selected.term = [...this.terms.filter(x => x.matsonn === 0 && x.is_active === 1)];
        }
        /* if (this.terms.find(x => x.is_default === 1)) {
          this.selected.term = this.terms.find(x => x.is_default === 1);
        } else if (this.terms.find(x => x.is_active === 1)) {
          this.selected.term = this.terms.filter(x => x.is_active === 1)[0];
        } */
        this.selected.showCommon = true;

        if (this.ssn) {
          this.selected.ssn = this.ssn;
          this.selected.term = '';
        }
        this.loadList();
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.terms = false;
      }
    },
    async loadTypes() {
      try {
        this.loading.types = true;
        const response = await types.getSpecialResourceList();
        this.types = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.types = false;
      }
    },
    async loadDepartments() {
      try {
        const response = await structure.getDepartmentList();
        this.departments = response.data.items.map(x => ({
          ...x,
          label: `${x.name}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async deleteSelectedItems() {
      try {
        this.deleting = true;
        const conf = confirm(`Ert þú viss um að þú viljir eyða ${this.getSelected.length} beiðnum? \nEkki verður sendur póstur.`); // eslint-disable-line
        if (conf) {
          const ids = this.getSelected.map(x => x.nemandi_serurraedi_id).join(',');
          await requests.massEditSpecialResources({
            deleted: 1,
            ids,
          });
          this.displaySuccess('Beiðnum eytt');
          this.loadList();
        }
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.deleting = false;
      }
    },
    openCreateSlidebar() {
      this.showCreateSlidebar = true;
      this.showEditSlidebar = false;
      this.showStudentSlidebar = false;
    },
    openEditSlidebar(item) {
      this.selectedItem = item;
      this.showEditSlidebar = true;
      this.showCreateSlidebar = false;
      this.showStudentSlidebar = false;
    },
    openStudentSlidebar(item) {
      this.selectedItem = item;
      this.showStudentSlidebar = true;
      this.showEditSlidebar = false;
      this.showCreateSlidebar = false;
    },
    closeSlidebar() {
      this.selectedItem = null;
      this.showEditSlidebar = false;
      this.showCreateSlidebar = false;
      this.showStudentSlidebar = false;
    },
    closeAndReloadSlidebar(item) {
      if (item && item.ssn) {
        this.selected.ssn = item.ssn;
        this.selected.term = '';
      }
      this.closeSlidebar();
      this.loadList();
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadTerms();
    this.loadTypes();
    this.loadDepartments();
  },
};
</script>
<style lang="scss"></style>
