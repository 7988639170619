<template>
    <div>
        <h4>Senda skilaboð</h4>
        <div>
            Skrifið inn titil og meginmál til að senda skilaboð, hægt er að bæta við að senda SMS skilaboð ef skólinn er
            búin að setja upp sms þjónustu.
            <br>
            Fyrir neðan er svo valið hverjir eiga að fá skilaboðin.
        </div>
        <div v-if="!loggedInUser.netfang" class="pt-3">Ekkert netfang skráð.</div>
        <div v-else class="pt-3">
            <b-form @submit.prevent="validateBeforeSubmit" novalidate>
                <b-form-group label-for="title" label="Titill*" :state="submitted && errors.has('title') ? false : ''"
                    :invalid-feedback="errors.first('title')">
                    <b-form-input id="title" name="title" v-model="mail.title" maxLength="200"
                        :state="submitted && errors.has('title') ? false : ''" v-validate="'required'"
                        style="height: 30px; font-size: 13px" data-vv-as="titill" ref="focusInput"></b-form-input>
                </b-form-group>
                <b-form-group :state="submitted && errors.has('sms') ? false : ''" :invalid-feedback="errors.first('sms')"
                    v-if="canSendSMS">
                    <label for="sms">SMS
                        <i class="fa fa-info-circle" title="Ath. ef verið er að senda sms þá þarf að fylla út 'Titill' og
                        'Meginmál' líka þar sem það eru alltaf send skilaboð samhliða." v-b-tooltip></i></label>
                    <b-form-input id="sms" name="sms" v-model="mail.sms" maxLength="500"
                        :state="submitted && errors.has('sms') ? false : ''"
                        style="height: 30px; font-size: 13px"></b-form-input>
                </b-form-group>

                <b-form-group label-for="body" label="Meginmál*" :state="submitted && errors.has('body') ? false : ''"
                    :invalid-feedback="errors.first('body')">
                    <quill-editor id="body" name="body" v-model="mail.body"
                        :state="submitted && errors.has('body') ? false : ''" ref="textQuillEditor" :options="editorOption"
                        v-validate="'required'" data-vv-as="meginmál" @ready="quillHeight($event)">
                    </quill-editor>
                </b-form-group>
                <b-row class="pb-3">
                    <b-col class="pl-0">
                        <b-form-group :state="submitted && errors.has('file') ? false : ''"
                            :invalid-feedback="errors.first('file')">
                            <label for="file">Viðhengi 1
                                <i class="fa fa-trash cursor-pointer" @click="mail.file = null" v-if="mail.file"></i>
                            </label>
                            <b-form-file class="singleCourseFile" size="sm" v-model="mail.file"
                                placeholder="Smelltu hér til að velja skrá.."></b-form-file>
                        </b-form-group>
                        <div v-if="!fileValid(mail.file)" class="text-danger" style="font-size: 13px">Skrá of stór. Má
                            eingöngu
                            vera 2MB.</div>
                    </b-col>
                    <b-col>
                        <b-form-group :state="submitted && errors.has('file2') ? false : ''"
                            :invalid-feedback="errors.first('file2')">
                            <label for="file2">Viðhengi 2
                                <i class="fa fa-trash cursor-pointer" @click="mail.file2 = null" v-if="mail.file2"></i>
                            </label>
                            <b-form-file class="singleCourseFile" size="sm" v-model="mail.file2"
                                placeholder="Smelltu hér til að velja skrá.."></b-form-file>
                        </b-form-group>
                        <div v-if="!fileValid(mail.file2)" class="text-danger" style="font-size: 13px">Skrá of stór. Má
                            eingöngu
                            vera 2MB.</div>
                    </b-col>
                </b-row>
                <!-- Viðtakendur -->
                <b-form-group :state="submitted && errors.has('student') ? false : ''"
                    :invalid-feedback="errors.first('student')">
                    <b-form-checkbox id="student" name="student" :disabled="!studentEmail" 
                    v-model="mail.student" class="d-inline-block">
                    </b-form-checkbox>
                    <label for="student" class="d-inline-block" style="font-size: 13px">Senda til
                        nemanda</label>
                        <span v-if="!studentEmail" style="font-size: 13px" class="text-danger">
                         - netfang er ekki skráð</span>
                </b-form-group>
                <table class="table less-padding ml-5" v-if="!loading.recipients && mail.student && studentEmail"
                    style="font-size: 12px; overflow-x: auto; width:50%;">
                    <thead>
                        <tr>
                            <th style="width:30px">
                            </th>
                            <th>Nafn ({{ student.length }})</th>
                            <th>Kennitala</th>
                            <th>Netfang</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in student" :key="item.notandi_id">
                            <td>
                            </td>
                            <td style="white-space: nowrap">{{ item.nafn }}</td>
                            <td>{{ item.kennitala }}</td>
                            <td>{{ item.netfang }}</td>
                        </tr>
                    </tbody>
                </table>

                <b-form-group :state="submitted && errors.has('copyGuardian') ? false : ''"
                    :invalid-feedback="errors.first('copyGuardian')">
                    <b-form-checkbox id="copyGuardian" name="copyGuardian" v-model="mail.copyGuardian"
                        class="d-inline-block"></b-form-checkbox>
                    <label for="copyGuardian" class="d-inline-block" style="font-size: 13px">
                        Senda til forráðamanna/aðstandenda 
                    </label>
                </b-form-group>
                <table class="table less-padding ml-5" v-if="!loading.relatives && mail.copyGuardian"
                    style="font-size: 12px; overflow-x: auto; width:50%;">
                    <thead>
                        <tr>
                            <th style="width:30px">
                            </th>
                            <th>Nafn ({{ relatives.length }})</th>
                            <th>Netfang</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="relatives.length === 0">
                            <td colspan="100%" class="text-center">
                                Engin aðstandandi/forráðamaður skráður eða merktur með aðgang
                            </td>
                        </tr>
                        <tr v-for="item in relatives" :key="item.forrad_id">
                            <td>
                            </td>
                            <td style="white-space: nowrap">{{ item.nafn }}</td>
                            <td>{{ item.netfang }}</td>
                        </tr>
                    </tbody>
                </table>
                <b-form-group :state="submitted && errors.has('supervisor') ? false : ''"
                    :invalid-feedback="errors.first('supervisor')">
                    <b-form-checkbox id="superviso" name="supervisor" v-model="mail.supervisor"
                        class="d-inline-block"></b-form-checkbox>
                    <label for="getCopy" class="d-inline-block" style="font-size: 13px">Senda til
                        umsjónarkennara</label>
                </b-form-group>
                <table class="table less-padding ml-5" v-if="!loading.recipients && mail.supervisor"
                    style="font-size: 12px; overflow-x: auto; width:50%;">
                    <thead>
                        <tr>
                            <th style="width:30px">
                            </th>
                            <th>Nafn ({{ supervisors.length }})</th>
                            <th>Netfang</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in supervisors" :key="item.notandi_id">
                            <td>
                            </td>
                            <td style="white-space: nowrap">{{ item.nafn }}</td>
                            <td>{{ item.netfang }}</td>
                        </tr>
                    </tbody>
                </table>
                <b-form-group :state="submitted && errors.has('copyStudentsTeachers') ? false : ''"
                    :invalid-feedback="errors.first('copyStudentsTeachers')">
                    <b-form-checkbox id="copyStudentsTeachers" name="copyStudentsTeachers"
                        v-model="mail.copyStudentsTeachers" @change="updateCheckbox(2)" class="d-inline-block" switch>
                    </b-form-checkbox>
                    <label for="copyStudentsTeachers" class="d-inline-block" style="font-size: 13px">Senda til kennara
                        nemandans</label>
                </b-form-group>
                <table class="table less-padding ml-5" v-if="!loading.recipients && mail.copyStudentsTeachers"
                    style="font-size: 12px; overflow-x: auto; width:50%;">
                    <thead>
                        <tr>
                            <th style="width:10px">
                                <b-form-checkbox v-model="selectAll" @change="updateAllTeachers()"></b-form-checkbox>
                            </th>
                            <th>Nafn ({{ teachers.length }})</th>
                            <th>Netfang</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in teachers" :key="item.notandi_id">
                            <td><b-form-checkbox v-model="item.selected"></b-form-checkbox></td>
                            <td style="white-space: nowrap">{{ item.nafn }}</td>
                            <td>{{ item.netfang }}</td>
                        </tr>
                    </tbody>
                </table>


                <b-form-group :state="submitted && errors.has('otherTeachers') ? false : ''"
                    :invalid-feedback="errors.first('otherTeachers')">
                    <b-form-checkbox id="otherTeachers" name="otherTeachers" v-model="mail.otherTeachers"
                        class="d-inline-block" switch></b-form-checkbox>
                    <label for="otherTeachers" class="d-inline-block" style="font-size: 13px">Senda til
                        annarra starfsmanna</label>
                </b-form-group>
                <div v-if="!loading.recipients && mail.otherTeachers">
                    <v-select id="staff" name="staff" v-model="selectedStaff" :options="staffList" @input="addPremise"
                        class="ml-5 pb-4" style="width: 50%;">
                    </v-select>

                    <table v-if="otherTeachersList.length > 0" class="table less-padding ml-5"
                        style="font-size: 12px; overflow-x: auto; width:50%;">
                        <thead>
                            <tr>
                                <th style="width:30px">
                                </th>
                                <th>Nafn ({{ otherTeachersList.length }})</th>
                                <th>Netfang</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in otherTeachersList" :key="index">
                                <td>
                                </td>
                                <td style="white-space: nowrap">{{ item.nafn }}</td>
                                <td>{{ item.netfang }}</td>
                                <td>
                                    <i class="fa fa-trash cursor-pointer" @click="removeOtherTeacher(index)"></i>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>

                <b-form-group :state="submitted && errors.has('getCopy') ? false : ''"
                    :invalid-feedback="errors.first('getCopy')">
                    <b-form-checkbox id="getCopy" name="getCopy" v-model="mail.getCopy"
                        class="d-inline-block"></b-form-checkbox>
                    <label for="getCopy" class="d-inline-block" style="font-size: 13px">Fá afrit skilaboðum</label>
                </b-form-group>

                <Loader v-if="loading.recipients" />
                <div v-if="errorInForm" style="font-size: 13px" class="text-danger">
                    Titill og meginmál eru skyldireitir 
                </div>
                <b-btn variant="primary" class="mt-2" @click="validateBeforeSubmit()"
                    :disabled="loading.recipients || saving || !validateInput()">
                    <i class="fa fa-spin fa-spinner" v-if="saving"></i>
                    Senda skilaboð</b-btn>
            </b-form>
        </div>
    </div>
</template>
  
<script>
import common from '@/api/common';
import students from '@/api/students';
import staff from '@/api/staff';

import Loader from '@/components/common/Loader.vue';
import { mapActions, mapGetters } from 'vuex';
import { quillEditor } from 'vue-quill-editor';
// import TermRestrictedChoiceSlidebar from '@/components/school/terms/TermRestrictedChoiceSlidebar.vue';

export default {
    name: 'student-send-message',
    props: [
        'undirskoli_id',
        'stada_nema',
        'deild_id',
        'braut_id',
        'upphafsonn',
        'notendahopur_id',
        'vinnslustada_id',
        'nafn',
        'kennitala',
        'aldur',
        'kyn',
        'bekkur_id',
        'nu_onn',
        'hopur_id',
        'umsjonarkennari_id',
        'onn_id',
        'umsjon_nemanda',
        'kennari_nemanda',
        'nemandi_id', // ef á að finna kennara þess nemanda
        'notandi_id',
        'eingonguForradamenn',
    ],
    components: {
        Loader,
        quillEditor,
    },
    computed: {
        canSendSMS() {
            return this.schoolHasSettings('starfsmadur_ma_senda_sms');
        },
        params() {
            return {
                undirskoli_id: this.undirskoli_id,
                stada_nema: this.stada_nema,
                deild_id: this.deild_id,
                braut_id: this.braut_id,
                upphafsonn: this.upphafsonn,
                notendahopur_id: this.notendahopur_id,
                vinnslustada_id: this.vinnslustada_id,
                nafn: this.nafn,
                kennitala: this.kennitala,
                aldur: this.aldur,
                kyn: this.kyn,
                bekkur_id: this.bekkur_id,
                nu_onn: this.nu_onn,
                hopur_id: this.hopur_id,
                umsjonarkennari_id: this.umsjonarkennari_id,
                onn_id: this.onn_id,
                umsjon_nemanda: this.umsjon_nemanda,
                kennari_nemanda: this.kennari_nemanda,
                nemandi_id: this.nemandi_id,
                notandi_id: this.notandi_id,

            };
        },
        ...mapGetters([
            'loggedInUserHasReadPermission',
            'loggedInUserHasWritePermission',
            'schoolSettings',
            'schoolHasSettings',
            'loggedInUser',
        ]),
    },
    data() {
        return {
            submitted: false,
            saving: false,
            msgGroup: '',
            recipients: [],
            supervisors: [],
            student: [],
            teachers: [],
            relatives: [],
            size: 'large',
            selectedStaff: '',
            otherTeachersList: [],
            counter: '',
            loading: {
                msgGroup: false,
                recipients: false,
                relatives: false,
            },
            errorInForm: false,
            selectAll: true,
            mail: {
                title: '',
                body: '',
                sms: '',
                file: null,
                file2: null,
                student: false,
                copyGuardian: false,
                getCopy: false,
                supervisor: false,
                copyStudentsTeachers: false,
                otherTeachers: false,
            },
            form: {
                teachers: '',
            },
            studentEmail: true,
            recipientsList: [],
            editorOption: {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                        ['blockquote', 'code-block'],

                        [{ header: 1 }, { header: 2 }], // custom button values
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
                        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                        [{ direction: 'rtl' }], // text direction

                        [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],

                        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                        [{ font: [] }],
                        [{ align: [] }],
                        ['link'],

                        ['clean'],
                    ],
                },
            },
        };
    },
    methods: {
        fileValid(file) {
            return !file || (file && file.size <= 2 * 1024 * 1024);
        },
        quillHeight(event) {
            event.container.style.height = '200px'; // eslint-disable-line
        },
        updateCheckbox(type) {
            if (type === 2) {
                this.teachers = this.teachers.map(x => ({
                    ...x,
                    selected: true,
                }));
            }
        },
        validateInput() {
          if(!this.mail.student && !this.mail.copyGuardian && !this.mail.supervisor && !this.mail.getCopy && 
          (!this.mail.copyStudentsTeachers || (this.mail.copyStudentsTeachers && this.teachers.filter(x => x.selected).length === 0)) && 
          (!this.mail.otherTeachers || (this.mail.otherTeachers && this.otherTeachersList.length === 0)) 
          // || 
           // !this.mail.title || !this.mail.body
           ) {
           return false; 
          }  
          return true;
        },
        updateAll() {
            this.recipients.forEach(item => {
                this.$set(item, 'selected', this.selectAll);
            });
        },
        updateAllTeachers() {
            this.teachers.forEach(item => {
                this.$set(item, 'selected', this.selectAll);
            });
        },
        closeSlidebar() {
            this.$emit('closeSlidebar');
        },
        closeAndReloadSlidebar() {
            this.$emit('closeAndReload');
        },
        updateRecipients() {
            this.recipientsList = this.form.teachers;
        },
        removeOtherTeacher(index) {
            this.otherTeachersList.splice(index, 1);
        },
        async loadRelativesEmails() {
            try {
                this.loading.relatives = true;
                const response = await students.getStudentRelativesEmails(this.nemandi_id);
                this.relatives = response.data.items;
            } catch (e) {
                this.$log.error(e);
                this.displayError(`Óvænt villa kom upp við að sækja forráðamenn.
                 (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            } finally {
                this.loading.relatives = false;
            }
        },
        async loadStaff() {
            try {
                this.loading.teacher = true;
                const response = await staff.getStaffList();
                this.staffList = response.data.items;
                this.staffList.forEach(x => {
                    const staffName = x.starfsheiti ? ` - ${x.starfsheiti}` : '';
                    this.$set(x, 'label', `${x.nafn} (${x.skammst})${staffName}`);
                });
            } catch (e) {
                this.$log.error(e);
                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            } finally {
                this.loading.teacher = false;
            }
        },
        async addPremise() {
            try {
                if (this.selectedStaff) {
                    this.otherTeachersList.push(this.selectedStaff);
                    this.selectedStaff = '';
                }
            } catch (e) {
                this.$log.error(e);
                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            }
        },
        async loadMessageGroup() {
            try {
                this.loading.msgGroup = true;
                const response = await common.getMessageGroup(this.params);
                this.msgGroup = response.data.msg_group;
                this.mail.title = response.data.title ? `${response.data.title}  ` : '';
            } catch (e) {
                this.$log.error(e);
                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            } finally {
                this.loading.msgGroup = false;
            }
        },
        async loadMessageRecipients(param) {
            let params;
            if (param === 0) { // nemandi
                params = {
                    umsjon_nemanda: '',
                    notandi_id: this.notandi_id,
                }
            } else if (param === 1) { // umsjónarkennari
                params = {
                    nemandi_id: this.nemandi_id,
                    umsjon_nemanda: 1,
                }
            } else { // kennari nemanda
                params = {
                    kennari_nemanda: this.kennari_nemanda,
                    nemandi_id: this.nemandi_id
                }
            }
            try {
                this.loading.recipients = true;
                const response = await common.getMessageRecipients(params);
                if (param === 0) {
                    this.student = response.data.items;
                    // ef netfang er ekki til - ekki leyfa hak en birta villuskilaboð
                    if(this.student.filter(x => x.netfang).length === 0) {
                        this.mail.student = false;
                        this.studentEmail = false;
                    } else {
                        this.studentEmail = true;
                    }
                } else if (param === 1) {
                    this.supervisors = response.data.items;
                } else {
                    this.teachers = response.data.items;
                    this.recipients = response.data.items.map(x => ({
                        ...x,
                        selected: true,
                    }));
                }
            } catch (e) {
                this.$log.error(e);
                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            } finally {
                this.loading.recipients = false;
            }
        },
        async validateBeforeSubmit() {
            try {
                let counter;
                this.submitted = true;
                this.saving = true;
                this.errorInForm = false;
                await this.$validator.validateAll().then(response => {
                    if (!response || !this.fileValid(this.mail.file) || !this.fileValid(this.mail.file2)) throw 'FormValidationError'; // eslint-disable-line no-throw-literal, max-len
                });
                // Engar villur í formi.
                try {
                    let sendTo = 1;
                    // Senda eingöngu á forráðamenn - sendir afrit til forráðamanna 
                    if(this.mail.student && this.mail.copyGuardian) sendTo = 2;

                    // Senda afrita á forráðamenn - eingöngu
                    if (!this.mail.student && this.mail.copyGuardian) sendTo = 4;

                    let allUsers = [];

                    // senda afrit á nemanda 
                    // nemandi þarf að fara í listann þó svo að það er aðeins verið að senda á forráðamenn..
                    // annars sendist ekki pósturinn
                    if (this.mail.student || (this.mail.copyGuardian || this.mail.onlyGuardian)) {
                        allUsers = allUsers.concat(this.student.map(x => x.notandi_id));
                    }
                    // Senda afrit á umsjónarkennara
                    if (this.mail.supervisor && this.supervisors.length > 0) {
                        allUsers = allUsers.concat(this.supervisors.map(x => x.notandi_id));
                    }

                    // kennarar afrit
                    const teachersList = this.teachers.filter(x => x.selected).map(x => x.notandi_id);
                    if (this.mail.copyStudentsTeachers && teachersList.length > 0) {
                        allUsers = allUsers.concat(teachersList);
                    }

                    // aðrir kennarar
                    if (this.mail.otherTeachers && this.otherTeachersList.length > 0) {
                        const extraTeachers = this.otherTeachersList.map(x => x.notandi_id);
                        allUsers = allUsers.concat(extraTeachers);
                    }

                    // fá afrit sjálfur
                    const users = this.recipients.filter(x => x.selected).map(x => x.notandi_id);
                    if (this.mail.getCopy && users.indexOf(this.loggedInUser.notandi_id) === -1) {
                        allUsers.push(this.loggedInUser.notandi_id);
                    }
                    // losa okkur við duplicate users
                    const sendToUsers = [...new Set(allUsers)];

                    const item = {
                        title: this.mail.title,
                        body: this.mail.body,
                        sms: this.mail.sms,
                        user_ids: sendToUsers,
                        send_to: sendTo,
                    };
                    const response = await this.$messagesApi.sendMessage({
                        data: JSON.stringify(item),
                        file: this.mail.file || undefined,
                        file2: this.mail.file2 || undefined,
                    });
                    counter = response.data.count;
                    if (this.mail.copyGuardian && !this.mail.student) {
                        counter += this.relatives.length - 1;  // viljum ekki telja nemanda með
                    } 
                    this.displaySuccess(`Skilaboð fyrir ${counter} viðtakendur hafa verið stofnuð og verða send.`);
                    this.mail.title = '';
                    this.mail.body = '';
                    this.mail.sms = '';
                    this.mail.file = null;
                    this.mail.file2 = null;
                    this.submitted = false;
                    // upphafstilla
                    this.mail.student = false;
                    this.mail.copyGuardian = false;
                    this.mail.getCopy = false;
                    this.mail.supervisor = false;
                    this.mail.copyStudentsTeachers = false;
                    this.mail.otherTeachers = false;
                    this.otherTeachersList = []; // hreinsa lista
                    this.$emit('reload'); // refresh siðu
                    window.scrollTo(0, 0); // síða fer efst upp
                    this.selectAll = true;
                    this.loadList();
                } catch (e) {
                    // Aðgerð mistókst.
                    this.failed = true;
                    this.$log.debug(e);
                    this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
                } finally {
                    this.saving = false;
                }
            } catch (e) {
                // Villur í formi.
                this.saving = false;
                this.errorInForm = true;
                this.$log.debug(e);
            }
        },
        async loadList() {
            this.loadMessageRecipients();
            this.loadMessageRecipients(0); // nemandi
            this.loadMessageRecipients(1); // umsjónarkennari
            this.loadRelativesEmails();
            this.loadStaff();
        },
        ...mapActions({
            displayError: 'displayError',
            displaySuccess: 'displaySuccess',
        }),
    },
    created() {
        this.$nextTick(() => {
            if (this.$refs.focusInput) {
                this.$refs.focusInput.focus();
            }
        });
        this.loadList();
    },
};
</script>
  
<style lang="scss"></style>
  