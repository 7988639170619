<template>
  <div>
    <b-row style="margin-top: -20px">
      <b-col class="pl-0 pr-0">
        <b-form-input v-model="search" class="search" placeholder="Leita" />
      </b-col>
      <b-col cols="2" class="pr-0" v-if="canEdit">
        <div class="float-right" style="margin-top: 20px">
          <CustomButton type="create" title="Nýr prófstaður" @click.native="openExamPlaceSlidebar()" />
        </div>
      </b-col>
    </b-row>
    <div v-if="getList.length === 0">Engir prófstaðir fundust</div>
    <b-card no-body class="building-card" v-for="item in getList" :key="item.id">
      <b-card-header header-tag="header" class="building-card-header" @click="toggleOpened(item)">
        <b-row>
          <b-col> {{ item.profstod_name }} </b-col>
          <b-col> {{ item.profstod_address }} </b-col>
          <b-col> {{ item.profstod_phonenr }} </b-col>
          <b-col>
            <i class="fa float-right" :class="{ 'fa-angle-up': item.showCollapse, 'fa-angle-down': !item.showCollapse }" />
            <span class="float-right pr-4">({{ item.profstjorar.length }})</span>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body v-if="isCollapsed(item)">
        <CustomButton
          type="create"
          v-if="canEdit"
          class="mr-2"
          title="Nýr tengiliður"
          @click.native="openExamPlaceManagerSlidebar(item.profstod_id)"
        />
        <CustomButton type="update" v-if="canEdit" title="Breyta prófstað" @click.native="openExamPlaceSlidebar(item)" />
        <div class="mt-3" v-if="item.profstjorar.length === 0">Engir tengiliðir fundust</div>
        <table class="table no-border mt-3" v-if="item.profstjorar.length > 0">
          <thead>
            <th>
              <LinkOrder title="Nafn" :filteredList="getList" :list="list" column="profstjori_name" />
            </th>
            <th>
              <LinkOrder title="Kennitala" :filteredList="getList" :list="list" column="profstjori_ssn" />
            </th>
            <th>
              <LinkOrder title="Símanúmer" :filteredList="getList" :list="list" column="profstjori_phone" />
            </th>
            <th>
              <LinkOrder title="Netfang" :filteredList="getList" :list="list" column="profstjori_email" />
            </th>
            <th>
              <LinkOrder title="Senda tölvupóst" :filteredList="getList" :list="list" column="senda_post" />
            </th>
            <th />
          </thead>
          <tr v-for="p in item.profstjorar" :key="p.profstjori_id">
            <td>{{ p.profstjori_name }}</td>
            <td>{{ p.profstjori_ssn }}</td>
            <td>{{ p.profstjori_phone }}</td>
            <td>{{ p.profstjori_email }}</td>
            <td>
              <b-form-checkbox v-model="p.senda_post" @input="changeSendMessage(p, item.profstod_id)" />
            </td>
            <td>
              <b-btn variant="primary" size="sm" @click="openExamPlaceManagerSlidebar(item.profstod_id, p)"
                ><i class="fa fa-pencil"
              /></b-btn>
            </td>
          </tr>
        </table>
      </b-card-body>
    </b-card>
    <exam-place-slidebar v-if="showExamPlaceSlidebar" :item="selectedItem" @closeSlidebar="closeSlidebar"> </exam-place-slidebar>
    <exam-place-manager-slidebar v-if="showExamPlaceManagerSlidebar" :item="selectedItem" :id="selectedId" @closeSlidebar="closeSlidebar">
    </exam-place-manager-slidebar>
    <!--equipment-create-modal ref="equipmentCreateModal" @successful="loadList"></equipment-create-modal-->
  </div>
</template>

<script>
import school from '@/api/school';

import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

import CustomButton from '@/components/common/CustomButton.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';

import ExamPlaceSlidebar from '@/components/school/school/types/ExamPlaceSlidebar.vue';
import ExamPlaceManagerSlidebar from '@/components/school/school/types/ExamPlaceManagerSlidebar.vue';

export default {
  name: 'equipments',
  components: {
    LinkOrder,
    CustomButton,
    ExamPlaceSlidebar,
    ExamPlaceManagerSlidebar,
  },
  computed: {
    getList() {
      return this.list.filter(
        x =>
          this.search.length === 0 ||
          (x.profstod_name && x.profstod_name.toUpperCase().startsWith(this.search.toUpperCase())) ||
          x.profstjorar.filter(y => y.profstjori_name.toUpperCase().startsWith(this.search.toUpperCase())).length > 0,
      );
    },
    getExcelData() {
      return this.getList.map(x => ({
        Heiti: x.profstod_name,
        Land: x.profstod_address,
        Símanúmer: x.profstod_phonenr,
      }));
    },
    getExcelFileName() {
      return `profstadir_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      loading: false,
      list: [],
      showExamPlaceSlidebar: false,
      showExamPlaceManagerSlidebar: false,
      selectedItem: {},
      selectedId: '',
      search: '',
      isOpened: [],
    };
  },
  methods: {
    toggleOpened(item) {
      if (this.isOpened.indexOf(item.profstod_id) > -1) this.isOpened.splice(this.isOpened.indexOf(item.profstod_id), 1);
      else if (this.isOpened.indexOf(item.profstod_id) === -1) this.isOpened.push(item.profstod_id);
    },
    isCollapsed(item) {
      return this.isOpened.indexOf(item.profstod_id) > -1;
    },
    async loadList() {
      try {
        this.loading = true;
        const response = await school.examPlacesList();
        this.list = response.data.items.map(x => ({
          ...x,
          showCollapse: false,
          profstjorar: [
            ...x.profstjorar.map(y => ({
              ...y,
              senda_post: y.senda_post === 1,
            })),
          ],
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async changeSendMessage(item, id) {
      const data = {
        name: item.profstjori_name,
        ssn: item.profstjori_ssn,
        phone: item.profstjori_phone,
        email: item.profstjori_email,
        send_email: item.senda_post,
        managerId: item.profstjori_id,
      };
      await school.createExamPlaceManager(id, data);
      this.displaySuccess('Tengilið hefur verið breytt');
      this.loadList();
    },
    openExamPlaceSlidebar(item) {
      this.selectedItem = item || {};
      this.showExamPlaceSlidebar = true;
      this.showExamPlaceManagerSlidebar = false;
    },
    openExamPlaceManagerSlidebar(place, item) {
      this.selectedItem = item || {};
      this.selectedId = place;
      this.showExamPlaceSlidebar = false;
      this.showExamPlaceManagerSlidebar = true;
    },
    closeSlidebar() {
      this.showExamPlaceSlidebar = false;
      this.showExamPlaceManagerSlidebar = false;
      this.selectedItem = {};
      this.selectedId = '';
      this.loadList();
    },
    /*
     * Modal gluggar
     */
    /* equipmentCreateModal() {
      this.$refs.equipmentCreateModal.show();
    },
    equipmentEditModal(id) {
      this.$refs.equipmentCreateModal.show(id);
    }, */
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadList();
  },
};
</script>
