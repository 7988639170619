<template>
  <Slidebar :large="true" @closeMe="closeSlidebar">
    <h4>{{ item.tegund_heiti }} - {{ item.nafn }} kt.{{ item.kennitala }}</h4>
    <!--div v-if="item.stada === 0">
      <h5 v-if="item.nemandi_forf_timar.length > 0" class="mt-4 mb-2">Tímar</h5>
      <table class="table" v-if="item.nemandi_forf_timar.length > 0">
        <thead>
          <tr>
            <th>Dags.</th>
            <th>Áfangi</th>
            <th>Tími frá</th>
            <th>Tími til</th>
          </tr>
        </thead>
        <tr v-for="t in item.nemandi_forf_timar" :key="t.nemandi_forfoll_timi_id">
          <td style="border-top: none">{{ t.dags }}</td>
          <td style="border-top: none">{{ t.namsgrein_afanganr }}</td>
          <td style="border-top: none">{{ t.timi_fra }}</td>
          <td style="border-top: none">{{ t.timi_til }}</td>
        </tr>
      </table>
    </div-->
    <table class="table mt-4">
      <tr>
        <th>Nemandi</th>
        <td>{{ item.nafn }} kt.{{ item.kennitala }}</td>
      </tr>
      <tr v-if="isVacation">
        <th>Tímabil</th>
        <td>
          {{ item.dags_fra | moment('DD.MM.YYYY') }}
          <span v-if="item.dags_fra !== item.dags_til"> - {{ item.dags_til | moment('DD.MM.YYYY') }} </span>
        </td>
      </tr>
      <tr v-if="isAbsence">
        <th>
          Dags.
          <i class="fa fa-info-circle" title="Dagsetning tímans sem viðkomandi er veikur." v-b-tooltip></i>
        </th>
        <td>
          {{ item.dags_fra | moment('DD.MM.YYYY') }}
        </td>
      </tr>
      <tr>
        <th>Dags. skráð</th>
        <td>{{ item.time_create | moment('DD.MM.YYYY HH:mm:ss') }}</td>
      </tr>
      <tr v-if="isVacation">
        <th>Tegund leyfis</th>
        <td>{{ item.tegund_heiti }}</td>
      </tr>
      <tr>
        <th>Staða</th>
        <td v-if="edit">
          <v-select id="status" name="status" v-model="status" :clearable="false" :options="statuses"> </v-select>
        </td>
        <td v-else>{{ item.stada_heiti }}</td>
      </tr>
      <tr>
        <th>Kóði</th>
        <!-- <td v-if="edit">
          <v-select id="code" name="code" v-model="code" :clearable="false" :options="codes"> </v-select>
        </td> -->
        <td>{{ item.forfoll_kodi }}</td>
      </tr>
      <tr>
        <th>Tímar</th>
        <td>
          <table class="table" v-if="item.nemandi_forf_timar.length > 0">
            <thead>
              <tr>
                <th>Dags.</th>
                <th>Áfangi</th>
                <th>Tími frá</th>
                <th>Tími til</th>
              </tr>
            </thead>
            <tr v-for="t in item.nemandi_forf_timar" :key="t.nemandi_forfoll_timi_id">
              <td style="border-top: none">{{ t.dags }}</td>
              <td style="border-top: none">{{ t.namsgrein_afanganr }}</td>
              <td style="border-top: none">{{ t.timi_fra }}</td>
              <td style="border-top: none">{{ t.timi_til }}</td>
            </tr>
          </table>
          <div v-else>Allur dagurinn</div>
        </td>
      </tr>
      <tr v-if="item.persona_is_nemandi === 0">
        <th>Sótt um af</th>
        <td>{{ item.person_create_nafn }}</td>
      </tr>
      <tr>
        <th>
          Skýring
          <i
            v-if="isAbsence"
            class="fa fa-info-circle"
            title="Skýring sem nemandi skráir með veikindatilkynningunni, þessi skýring fer ekki í fjarvistarferilinn
                  og er ekki sýnileg kennurum."
            v-b-tooltip
          ></i>
          <i
            v-else
            class="fa fa-info-circle"
            title="Skýring sem nemandi skráir með beiðni, þessi skýring fer ekki í fjarvistarferilinn og er ekki sýnileg kennurum."
            v-b-tooltip
          ></i>
        </th>
        <td>{{ item.athugasemd_skraning }}</td>
      </tr>
      <tr>
        <th>
          Athugasemd afgreiðslu
          <i class="fa fa-info-circle" title="Athugasemd afgreiðslu er einnig sýnileg nemanda og forráðamanni ef við á." v-b-tooltip></i>
        </th>
        <td v-if="edit"><b-form-input v-model="comment" /></td>
        <td v-else>{{ item.athugasemd_afgreidsla }}</td>
      </tr>
      <tr v-if="item.athugasemd_forfoll">
        <th>
          Athugasemd í fjarvistarferli
          <i class="fa fa-info-circle" title="Athugsemd sem birtist í fjarvistarferli nemandans og er sýnileg kennurum." v-b-tooltip></i>
        </th>
        <td>{{ item.athugasemd_forfoll }}</td>
      </tr>
      <tr v-if="item.samt_person_nafn">
        <th>Afgreitt af</th>
        <td>
          {{ item.samt_person_nafn }} <span class="ml-3">{{ item.time_edit | moment('DD.MM.YYYY HH:mm:ss') }}</span>
        </td>
      </tr>
    </table>

    <b-link @click="showAbsences = !showAbsences" class="d-block">
      Skoða allar beiðnir nemanda
      <i class="fa ml-2" :class="{ 'fa-angle-up': !showAbsences, 'fa-angle-down': showAbsences }" />
    </b-link>
    <StudentAbsenceRequests v-if="showAbsences" :id="item.nemandi_id" :absenceTypes="absenceTypes" />
    <b-link @click="showAttendance = !showAttendance" class="d-block">
      Skoða viðveru nemanda
      <i class="fa ml-2" :class="{ 'fa-angle-up': !showAttendance, 'fa-angle-down': showAttendance }" />
    </b-link>
    <div v-if="showAttendance" style="font-size: 12px">
      <i>Ath. Forföll sjást ekki fyrr en kennari er búin að skrá fjarvist á tímann.</i>
    </div>
    <StudentAttendance v-if="showAttendance" :studentId="item.nemandi_id"></StudentAttendance>
    <b-link @click="showTimetable = !showTimetable" class="d-block">
      Skoða stundatöflu nemanda
      <i class="fa ml-2" :class="{ 'fa-angle-up': !showTimetable, 'fa-angle-down': showTimetable }" />
    </b-link>
    <Timetable :id="item.nemandi_id" type="student" v-if="showTimetable" />

    <div v-if="item.stada !== 0">
      <b-btn variant="primary" @click="toggleEdit(true)" v-if="!edit && item.stada !== 1 && canEdit">Breyta</b-btn>
      <b-btn variant="primary" class="mr-2" v-if="edit" @click="saveChanges()">Vista</b-btn>
      <b-btn variant="dark" @click="toggleEdit(false)" v-if="edit">Hætta við</b-btn>
    </div>
  </Slidebar>
</template>

<script>
import requests from '@/api/requests';

import Slidebar from '@/components/common/Slidebar.vue';
import Timetable from '@/components/common/Timetable.vue';
import StudentAttendance from '@/components/students/attendance/StudentAttendance.vue';
import StudentAbsenceRequests from '@/components/students/student/absenceRequests/StudentAbsenceRequests.vue';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'view-request-slidebar',
  props: ['item', 'defaultEdit'],
  components: {
    Slidebar,
    Timetable,
    StudentAttendance,
    StudentAbsenceRequests,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('beidnir_forfoll');
    },
    isAbsence() {
      return this.absenceTypes.indexOf('1') > -1;
    },
    isVacation() {
      return this.absenceTypes.indexOf('2') > -1 || this.absenceTypes.indexOf('3') > -1;
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      edit: false,
      showTimetable: false,
      showAttendance: false,
      showAbsences: false,
      absenceTypes: '',
      goToDate: '',
      // code: '',
      status: '',
      comment: '',
      statuses: [
        { id: 0, label: 'Óafgreidd' },
        { id: 1, label: 'Samþykkt' },
        { id: 2, label: 'Vantar vottorð' },
        { id: 3, label: 'Hafnað' },
        { id: 4, label: 'Annað' },
      ],
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    closeAndReloadSlidebar() {
      this.$emit('closeAndReload');
    },
    toggleEdit(value) {
      this.edit = value;
      if (this.edit) {
        this.status = this.statuses.find(x => x.id === this.item.stada);
        this.comment = this.item.athugasemd_afgreidsla;
      }
    },
    async saveChanges() {
      try {
        const data = {
          nemandi_forfoll_tegund: this.item.nemandi_forfoll_tegund,
          nemandi_id: this.item.nemandi_id,
          dags_fra: moment(this.item.dags_fra).format('DD.MM.YYYY'),
          dags_til: moment(this.item.dags_til).format('DD.MM.YYYY'),
          heill_dagur: this.item.heill_dagur,
          teg_forfalla_id: this.item.teg_forfalla_id,
          athugasemd_skraning: this.item.athugasemd_skraning,
          stada: this.status ? this.status.id : this.item.stada,
          athugasemd_afgreidsla: this.comment,
          deleted: 0,
        };
        await requests.updateReviewAbsence(this.item.nemandi_forfoll_id, data);
        this.displaySuccess('Leyfisbeiðni breytt');
        this.closeAndReloadSlidebar();
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    if (this.item.nemandi_forfoll_tegund === 1) {
      this.absenceTypes = '1';
    } else {
      this.absenceTypes = '2,3';
    }
    this.$nextTick(() => {
      this.goToDate = this.item.dags_fra;
    });
    if (this.defaultEdit) {
      this.toggleEdit(true);
    } else {
      this.toggleEdit(false);
    }
  },
};
</script>

<style lang="scss"></style>
