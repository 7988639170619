<template>
  <b-form style="padding-bottom: 10px" @submit.prevent="saveFiles" novalidate enctype="multipart/form-data">
    <b-form-group
      class="mb-0"
      :state="submitted && errors.has('file') ? false : null"
      :feedback="errors.first('file')"
      description="Hámarksstærð er 500 MB. Eftirfarandi skráarsnið eru ekki leyfð: .exe."
    >
      <vue-dropzone
        ref="file"
        id="file"
        name="file"
        style="min-height: 50px; padding: 0px"
        @vdropzone-success="fileSuccess"
        @vdropzone-error="fileError"
        @vdropzone-file-added="saveFiles"
        @vdropzone-sending="sendingFile"
        :options="fileDropzoneConfig"
      ></vue-dropzone>
    </b-form-group>
  </b-form>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import { fileDropzoneConfig } from '@/utils';
import { mapActions } from 'vuex';

export default {
  name: 'upload-attachment',
  props: ['typeId', 'uploadId'],
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      submitted: false,
      fileDropzoneConfig: fileDropzoneConfig(),
    };
  },
  methods: {
    /*
      Skjöl
    */
    fileSuccess(file) {
      this.$refs.file.removeFile(file);
      this.$emit('file-success');
    },
    fileError(file, message, xhr) {
      if (xhr) {
        this.$emit('file-error', {
          response: {
            data: message,
            status: xhr.status,
          },
        });
      } else if (message !== undefined) {
        this.displayError(`Ekki tókst að vista skjal. ${message}`);
      }
    },
    sendingFile(file, xhr) {
      xhr.setRequestHeader('filename', file.name);
      xhr.setRequestHeader('contentType', file.type);
    },
    async saveFiles() {
      try {
        const uploadPromise = new Promise((resolve, reject) => { // eslint-disable-line
          this.$once('file-success', () => {
            resolve();
          });

          this.$once('file-error', message => {
            reject(message);
          });
        });
        this.$refs.file.setOption('url', `/api/worker/p/attachments/attachment/upload/${this.typeId}/${this.uploadId}`);
        await uploadPromise;
        this.$emit('reload');
        this.displaySuccess('Skjal vistað.');
      } catch (e) {
        // Aðgerð mistókst.
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp við vistun á skjali. Vinsamlegast reyndu aftur.`);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
