<template>
  <span>
    <span v-if="item === 1 || item === '1' || item === true">Já</span>
    <span v-else>Nei</span>
  </span>
</template>

<script>
export default {
  name: 'yesno',
  props: ['item'],
};
</script>

<style lang="scss"></style>
