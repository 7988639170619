<template>
  <Slidebar :autoClose="false" @closeMe="closeSlidebar" :large="true">
    <h4>Sérúrræði nemanda</h4>
    <br />
    <StudentSpecialResource :id="id" :showInfo="showInfo" @reload="reload" />
  </Slidebar>
</template>

<script>
import { mapActions } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';
import StudentSpecialResource from '@/components/students/student/specialResource/StudentSpecialResource.vue';

export default {
  name: 'student-special-resource-slidebar',
  props: ['id', 'showInfo'],
  components: {
    Slidebar,
    StudentSpecialResource,
  },
  data() {
    return {};
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    reload() {
      this.$emit('reloadData');
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {},
};
</script>

<style lang="scss"></style>
