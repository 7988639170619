<template>
  <Slidebar :large="true" @closeMe="closeSlidebar">
    <h4 v-if="this.item && this.item.profstod_id">Breyta prófstað</h4>
    <h4 v-else>Stofna prófstað</h4>
    <br />
    <br />
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <table class="table no-border less-padding">
        <tr>
          <td>Heiti*</td>
          <td>
            <b-form-group
              label-for="profstod_name"
              :state="submitted && errors.has('profstod_name') ? false : ''"
              :invalid-feedback="errors.first('profstod_name')"
            >
              <b-form-input
                id="profstod_name"
                name="profstod_name"
                type="text"
                v-model="form.profstod_name"
                :state="submitted && errors.has('profstod_name') ? false : ''"
                v-validate="'required'"
                data-vv-as="heiti"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Land*</td>
          <td>
            <b-form-group
              label-for="profstod_address"
              :state="submitted && errors.has('profstod_address') ? false : ''"
              :invalid-feedback="errors.first('profstod_address')"
            >
              <b-form-input
                id="profstod_address"
                name="profstod_address"
                type="text"
                v-model="form.profstod_address"
                :state="submitted && errors.has('profstod_address') ? false : ''"
                v-validate="'required'"
                data-vv-as="land"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Símanúmer*</td>
          <td>
            <b-form-group
              label-for="profstod_phonenr"
              :state="submitted && errors.has('profstod_phonenr') ? false : ''"
              :invalid-feedback="errors.first('profstod_phonenr')"
            >
              <b-form-input
                id="profstod_phonenr"
                name="profstod_phonenr"
                type="text"
                v-model="form.profstod_phonenr"
                :state="submitted && errors.has('profstod_phonenr') ? false : ''"
                v-validate="'required'"
                data-vv-as="símanúmer"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
      </table>

      <b-btn variant="primary" class="mr-2" v-if="canEdit" @click="validateBeforeSubmit">
        <span v-if="this.item && this.item.profstod_id">Breyta</span>
        <span v-else>Stofna</span>
      </b-btn>
      <b-btn variant="dark" @click="closeSlidebar">Hætta við</b-btn>
      <b-btn variant="danger" class="float-right" v-if="canEdit && this.item && this.item.profstod_id" @click="deleteExamPlace">
        Eyða
      </b-btn>
    </b-form>
    <!--b-btn variant="primary" class="mr-2" @click="validateBeforeSubmit">Vista</b-btn-->
  </Slidebar>
</template>

<script>
import school from '@/api/school';
import Slidebar from '@/components/common/Slidebar.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'exam-place-slide-bar',
  props: ['item'],
  components: {
    Slidebar,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      form: {
        profstod_name: '',
        profstod_address: '',
        profstod_phonenr: '',
      },
      submitted: false,
    };
  },
  methods: {
    updateForm() {
      this.form = {
        profstod_name: this.item.profstod_name,
        profstod_address: this.item.profstod_address,
        profstod_phonenr: this.item.profstod_phonenr,
      };
    },
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.saving = true;
          const data = {
            name: this.form.profstod_name,
            address: this.form.profstod_address,
            phone: this.form.profstod_phonenr,
          };
          if (this.item && this.item.profstod_id) {
            await school.updateExamPlace(this.item.profstod_id, data);
            this.displaySuccess(`Prófstaðnum ${this.form.profstod_name} hefur verið breytt.`);
          } else {
            await school.createExamPlace(data);
            this.displaySuccess(`Prófstaðurinn ${this.form.profstod_name} hefur verið stofnaður.`);
          }
          this.closeSlidebar();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.saving = false;
      }
    },
    async deleteExamPlace() {
      try {
        const conf = confirm(`Ert þú viss um að eyða ${this.item.profstod_name}? `); // eslint-disable-line
        if (conf) {
          await school.updateExamPlace(this.item.profstod_id, { delete: 1 });
          this.displaySuccess(`${this.item.profstod_name} hefur verið eytt.`);
          this.closeSlidebar();
        }
      } catch (e) {
        // Aðgerð mistókst.
        this.$log.debug(e);
        if (e.response && e.response.data && e.response.data.error) {
          this.displayError(e.response.data.error);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    item() {
      this.updateForm();
    },
  },
  created() {
    this.updateForm();
  },
};
</script>

<style lang="scss"></style>
