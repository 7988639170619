<template>
  <div>
    <b-row class="mb-4">
      <b-col class="pl-0">
        <v-select
          id="scaleType"
          name="scaleType"
          v-model="selectedItem"
          :options="list"
          ref="selectCourseDropdown"
          label="heiti"
          :clearable="false"
        >
        </v-select>
      </b-col>
      <b-col cols="3">
        <div class="float-right" v-if="canEdit">
          <b-btn variant="primary" size="sm" title="Stofna einkunnaskala" @click="openSlidebar(false)" v-b-tooltip
            ><i class="fa fa-plus-circle"></i
          ></b-btn>
          <b-btn
            variant="dark"
            size="sm"
            class="ml-2"
            title="Breyta einkunnaskala"
            @click="openSlidebar(true)"
            v-b-tooltip
            v-if="selectedItem"
            ><i class="fa fa-pencil"></i
          ></b-btn>
          <b-btn
            v-if="selectedItem && selectedItem.has_scale === 0"
            variant="outline-dark"
            size="sm"
            class="ml-2"
            title="Afrita frá öðrum einkunnaskala"
            @click="openCopySlidebar()"
            v-b-tooltip
            ><i class="fa fa-copy"></i
          ></b-btn>
        </div>
      </b-col>
    </b-row>
    <grade-scale-type-slidebar v-if="showSlidebar" :item="itemToEdit" @closeSlidebar="closeSlidebar"> </grade-scale-type-slidebar>
    <grade-scale-copy-slidebar v-if="showCopySlidebar" :item="selectedItem" @closeSlidebar="closeSlidebar"></grade-scale-copy-slidebar>
    <GradeScale v-if="selectedItem && selectedItem.teg_skala" :reload="toReload" :id="selectedItem.teg_skala" :edit="true"></GradeScale>
  </div>
</template>

<script>
import types from '@/api/types';

import { mapActions, mapGetters } from 'vuex';

import GradeScaleTypeSlidebar from '@/components/school/school/types/GradeScaleTypeSlidebar.vue';
import GradeScaleCopySlidebar from '@/components/school/school/types/GradeScaleCopySlidebar.vue';
import GradeScale from '@/components/school/school/types/GradeScale.vue';

export default {
  name: 'gradeScaleType',
  components: {
    GradeScaleTypeSlidebar,
    GradeScaleCopySlidebar,
    GradeScale,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      loading: false,
      showSlidebar: false,
      showCopySlidebar: false,
      selectedItem: null,
      itemToEdit: null,
      list: [],
      toReload: false,
    };
  },
  methods: {
    async loadList(id) {
      try {
        this.loading = true;
        const response = await types.getGradeScaleTypeList();
        this.list = response.data.items;
        if (this.list.length > 0) {
          if (id && this.list.find(x => x.teg_skala === id)) {
            this.selectedItem = this.list.find(x => x.teg_skala === id);
            this.toReload = true;
            setTimeout(() => {
              this.toReload = false;
            }, 1000);
          } else if (!this.selectedItem || !this.list.find(x => x.teg_skala === this.selectedItem.teg_skala)) {
            this.selectedItem = this.list[0];
          }
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    openSlidebar(edit) {
      this.itemToEdit = null;
      if (edit) {
        this.itemToEdit = this.selectedItem;
      }
      this.showSlidebar = true;
      this.showCopySlidebar = false;
    },
    openCopySlidebar() {
      this.showCopySlidebar = true;
      this.showSlidebar = false;
    },
    closeSlidebar(id) {
      this.showSlidebar = false;
      this.showCopySlidebar = false;
      this.itemToEdit = null;
      this.loadList(id);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadList();
  },
};
</script>
