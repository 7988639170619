<template>
  <page>
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <PageTitle title="Innlestur"></PageTitle>
    <div>Hér er hægt að lesa inn námskránna, ef námsgrein, áfangi eða braut er ekki til fyrir þá er það stofnað við innlestur.</div>

    <b-link class="float-right" :class="{ 'mb-3': !showDetails }" @click="showDetails = !showDetails">
      <span v-if="showDetails">Fela</span><span v-else>Skoða</span>
      leiðbeiningar
      <i class="fa ml-2" :class="{ 'fa-angle-up': !showDetails, 'fa-angle-down': showDetails }" />
    </b-link>
    <div v-if="showDetails" class="mt-3">
      <ul style="margin-left: -20px">
        <li>
          Uppbygging skráar þarf að vera svona: <br />
          <img src="/files/innlestur_namskra.jpg" class="pt-3 pb-3" style="max-width: 100%" />
        </li>
        <li>Ef eitthvað er ekki fyllt út er dálkurinn skilinn eftir tómur, ekki má eyða út dálk.</li>
        <li>Þeir dálkar sem eru litaðir má ekki sleppa að fylla út.</li>
        <li>
          Skráin þarf að vera á .csv formi þegar hún er lesin inn. <br />
          <img src="/files/innlestur_namskra_skra.jpg" class="pt-3 pb-1" style="max-width: 100%" />
        </li>
      </ul>
    </div>

    <div class="mt-3">
      <b-form-file
        v-model="file"
        accept="text/csv"
        class="schoolCurriculumFile"
        placeholder="Smelltu hér til að velja .csv skrá.."
      ></b-form-file>

      <div class="mt-3">
        <b-form-group
          label-for="undirskoli"
          :state="submitted && errors.has('undirskoli') ? false : ''"
          :invalid-feedback="errors.first('undirskoli')"
          class="d-inline-block mr-2"
          style="min-width: 400px"
        >
          <v-select
            id="undirskoli"
            name="undirskoli"
            v-model="form.undirskoli"
            :clearable="false"
            v-validate="'required'"
            :state="submitted && errors.has('undirskoli') ? false : ''"
            :options="divisions"
            data-vv-as="undirskóli"
          >
          </v-select>
        </b-form-group>
        <b-btn variant="primary" @click="loadFile" :disabled="!file" class=""> <i class="fa fa-cloud-upload"></i> Lesa inn</b-btn>
        <b-btn variant="primary" href="/files/innlestur_namskra.csv" target="_blank" class="ml-2">
          <i class="fa fa-cloud-download"></i> Sækja dæmi um .csv skrá</b-btn
        >
        <b-btn variant="dark" @click="openCourseSearchSlidebar" class="float-right"> <i class="fa fa-search" /> Leita eftir braut </b-btn>
        <b-btn variant="dark" @click="openModuleSearchSlidebar" class="float-right mr-2">
          <i class="fa fa-search" /> Leita að áfanga
        </b-btn>
      </div>
    </div>
    <div v-if="firsttime" class="mt-3">
      <h5>Gildar færslur</h5>
      <table class="table table-bordered table-bordered-header no-padding" style="font-size: 12px">
        <thead>
          <tr>
            <th>Námsgr. <i class="fa fa-info-circle" title="Heiti námsgrein" v-b-tooltip></i></th>
            <th>Kóði <i class="fa fa-info-circle" title="Kóði námsgreinar" v-b-tooltip></i></th>
            <th>Númer <i class="fa fa-info-circle" title="Númer áfanga" v-b-tooltip></i></th>
            <th>Áfangi <i class="fa fa-info-circle" title="Kóði áfanga" v-b-tooltip></i></th>
            <th>Heiti <i class="fa fa-info-circle" title="Heiti áfanga" v-b-tooltip></i></th>
            <th>Þrep</th>
            <th>Ein. <i class="fa fa-info-circle" title="Einingar" v-b-tooltip></i></th>
            <th>Fj. viku <i class="fa fa-info-circle" title="Fjöldi tíma á viku" v-b-tooltip></i></th>
            <th>Fj. aukast. <i class="fa fa-info-circle" title="Fjöldi tíma á viku - Aukastofa" v-b-tooltip></i></th>
            <th>Fj. tímab. <i class="fa fa-info-circle" title="Fjöldi tíma á kennslutímabili" v-b-tooltip></i></th>
            <th>Tímabil <i class="fa fa-info-circle" title="Númer kennslutímabils" v-b-tooltip></i></th>
            <th>Braut <i class="fa fa-info-circle" title="Heiti brautar" v-b-tooltip></i></th>
            <th>Kóði <i class="fa fa-info-circle" title="Kóði brautar" v-b-tooltip></i></th>
            <th>Ein. <i class="fa fa-info-circle" title="Einingar brautar" v-b-tooltip></i></th>
            <th>Heiti En <i class="fa fa-info-circle" title="Heiti áfanga á enska" v-b-tooltip></i></th>
            <th>Jafng. <i class="fa fa-info-circle" title="Jafngildi" v-b-tooltip></i></th>
            <th>Undanf. <i class="fa fa-info-circle" title="Undanfari" v-b-tooltip></i></th>
            <th>Uppb. <i class="fa fa-info-circle" title="Heiti uppbyggingar" v-b-tooltip></i></th>
            <th>Tegund <i class="fa fa-info-circle" title="Tegund uppbyggingar" v-b-tooltip></i></th>
            <th>Deild</th>
          </tr>
        </thead>
        <tr v-for="(item, idx) in parsed" :key="idx">
          <td>{{ item.namsgrein }}</td>
          <td>{{ item.namsgrein_kodi }}</td>
          <td>{{ item.afangi }}</td>
          <td>{{ item.afanganumer }}</td>
          <td>{{ item.heiti_afanga }}</td>
          <td>{{ item.trep }}</td>
          <td>{{ item.einingar }}</td>
          <td>{{ item.fj_timar_viku }}</td>
          <td>{{ item.fj_timar_viku_2 }}</td>
          <td>{{ item.timar_onn_alls }}</td>
          <td>{{ item.onn_nr }}</td>
          <td>{{ item.braut }}</td>
          <td>{{ item.braut_kodi }}</td>
          <td>{{ item.einingar_alls }}</td>
          <td>{{ item.heiti_afanga_enska }}</td>
          <td>{{ item.jafngildir }}</td>
          <td>{{ item.undanfarar }}</td>
          <td>{{ item.uppbygging_brautar }}</td>
          <td>{{ item.tegund_uppbyggingar }}</td>
          <td>{{ item.deild }}</td>
        </tr>
        <tr v-if="parsed.length === 0">
          <td colspan="100%">Engar gildar færslur í innlesinni skrá</td>
        </tr>
      </table>
      <div>
        <b-button
          variant="primary"
          size="sm"
          class="d-inline-block"
          style="margin-top: -5px"
          @click="uploadData()"
          :disabled="saving || parsed.length === 0 || workers.length > 0"
          >Hlaða upp</b-button
        >
      </div>
      <div :class="{ visibilityHidden: workers.length === 0 }"><Loader style="margin: 0" /> Verið er að lesa inn námskrá...</div>

      <h5 v-if="notValid.length > 0" class="mt-3">Ógildar færslur</h5>

      <table class="table table-bordered no-padding" style="font-size: 12px" v-if="notValid.length > 0">
        <thead>
          <tr>
            <th>Námsgr. <i class="fa fa-info-circle" title="Heiti námsgrein" v-b-tooltip></i></th>
            <th>Kóði <i class="fa fa-info-circle" title="Kóði námsgreinar" v-b-tooltip></i></th>
            <th>Númer <i class="fa fa-info-circle" title="Númer áfanga" v-b-tooltip></i></th>
            <th>Áfangi <i class="fa fa-info-circle" title="Kóði áfanga" v-b-tooltip></i></th>
            <th>Heiti <i class="fa fa-info-circle" title="Heiti áfanga" v-b-tooltip></i></th>
            <th>Þrep</th>
            <th>Ein. <i class="fa fa-info-circle" title="Einingar" v-b-tooltip></i></th>
            <th>Fj. viku <i class="fa fa-info-circle" title="Fjöldi tíma á viku" v-b-tooltip></i></th>
            <th>Fj. aukast. <i class="fa fa-info-circle" title="Fjöldi tíma á viku - Aukastofa" v-b-tooltip></i></th>
            <th>Fj. tímab. <i class="fa fa-info-circle" title="Fjöldi tíma á kennslutímabili" v-b-tooltip></i></th>
            <th>Tímabil <i class="fa fa-info-circle" title="Númer kennslutímabils" v-b-tooltip></i></th>
            <th>Braut <i class="fa fa-info-circle" title="Heiti brautar" v-b-tooltip></i></th>
            <th>Kóði <i class="fa fa-info-circle" title="Kóði brautar" v-b-tooltip></i></th>
            <th>Ein. <i class="fa fa-info-circle" title="Einingar brautar" v-b-tooltip></i></th>
            <th>Heiti En <i class="fa fa-info-circle" title="Heiti áfanga á enska" v-b-tooltip></i></th>
            <th>Jafng. <i class="fa fa-info-circle" title="Jafngildi" v-b-tooltip></i></th>
            <th>Undanf. <i class="fa fa-info-circle" title="Undanfari" v-b-tooltip></i></th>
            <th>Uppb. <i class="fa fa-info-circle" title="Heiti uppbyggingar" v-b-tooltip></i></th>
            <th>Tegund <i class="fa fa-info-circle" title="Tegund uppbyggingar" v-b-tooltip></i></th>
            <th>Deild</th>
            <th>Villa</th>
          </tr>
        </thead>
        <tr v-for="(item, idx) in notValid" :key="idx">
          <td>{{ item.namsgrein }}</td>
          <td>{{ item.namsgrein_kodi }}</td>
          <td>{{ item.afangi }}</td>
          <td>{{ item.afanganumer }}</td>
          <td>{{ item.heiti_afanga }}</td>
          <td>{{ item.trep }}</td>
          <td>{{ item.einingar }}</td>
          <td>{{ item.fj_timar_viku }}</td>
          <td>{{ item.fj_timar_viku_2 }}</td>
          <td>{{ item.timar_onn_alls }}</td>
          <td>{{ item.onn_nr }}</td>
          <td>{{ item.braut }}</td>
          <td>{{ item.braut_kodi }}</td>
          <td>{{ item.einingar_alls }}</td>
          <td>{{ item.heiti_afanga_enska }}</td>
          <td>{{ item.jafngildir }}</td>
          <td>{{ item.undanfarar }}</td>
          <td>{{ item.uppbygging_brautar }}</td>
          <td>{{ item.tegund_uppbyggingar }}</td>
          <td>{{ item.deild }}</td>
          <td>{{ item.error }}</td>
        </tr>
      </table>
    </div>

    <module-search-slidebar @closeSlidebar="closeModuleSlidebar" v-if="showModuleSearchSlidebar" :large="true"> </module-search-slidebar>
    <course-search-slidebar @closeSlidebar="closeCourseSlidebar" v-if="showCourseSearchSlidebar" :large="true"> </course-search-slidebar>
  </page>
</template>

<script>
import common from '@/api/common';
import curriculum from '@/api/curriculum';
import structure from '@/api/structure';
import upload from '@/api/upload';
import { mapActions, mapGetters } from 'vuex';

import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import PageTitle from '@/components/common/PageTitle.vue';
import Loader from '@/components/common/Loader.vue';

import ModuleSearchSlidebar from '@/components/curriculum/modules/ModuleSearchSlidebar.vue';
import CourseSearchSlidebar from '@/components/curriculum/courses/CourseSearchSlidebar.vue';

export default {
  name: 'curriculum-upload',
  components: {
    Breadcrumbs,
    PageTitle,
    Loader,
    ModuleSearchSlidebar,
    CourseSearchSlidebar,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_innlestur');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      breadcrumbs: [
        { name: 'Forsíða', route: 'Dashboard' },
        { name: 'Námskrá', route: '' },
        { name: 'Innlestur', route: 'CurriculumUpload' },
      ],
      firsttime: false,
      submitted: false,
      saving: false,
      file: null,
      parsed: [],
      notValid: [],
      types: [],
      departments: [],
      divisions: [],
      errorsList: [],
      workers: [],
      form: {
        undirskoli: '',
      },
      showModuleSearchSlidebar: false,
      showCourseSearchSlidebar: false,
      showDetails: true,
    };
  },
  methods: {
    parseFile() {
      this.showDetails = false;
      return new Promise(resolve => {
        const read = new FileReader();
        read.readAsText(this.file);
        read.onloadend = () => {
          const data = read.result;
          if (data.split(';').length > 0) {
            const lines = data.split('\n');
            lines.forEach(line => {
              if (line.length > 0) {
                const columns = line.split(';');
                const item = {
                  namsgrein: '',
                  namsgrein_kodi: '',
                  afangi: '',
                  afanganumer: '',
                  heiti_afanga: '',
                  trep: '',
                  einingar: '',
                  fj_timar_viku: '',
                  fj_timar_viku_2: '',
                  timar_onn_alls: '',
                  onn_nr: '',
                  braut: '',
                  braut_kodi: '',
                  einingar_alls: '',
                  heiti_afanga_enska: '',
                  jafngildir: '',
                  undanfarar: '',
                  uppbygging_brautar: '',
                  tegund_uppbyggingar: '',
                  deild: '',
                };
                let error = '';
                // Námsgrein
                if (columns.length > 0) {
                  const column = (columns[0] || '').trim();
                  if (column === 'NAMSGREIN' || column === 'Heiti námsgreinar') return;
                  if (column.length > 0 && column.length <= 60) {
                    item.namsgrein = column;
                  }
                }
                if (item.namsgrein.length === 0) error += 'Námsgrein ólögleg eða vantar. ';

                // Kóði námsgreinar
                if (columns.length > 1) {
                  const column = (columns[1] || '').trim();
                  if (column.length > 0 && column.length <= 8) {
                    item.namsgrein_kodi = column;
                  }
                }
                if (item.namsgrein_kodi.length === 0) {
                  error += 'Kóði námsgrein ólöglegur eða vantar. ';
                } 

                // Áfanganúmer
                if (columns.length > 2) {
                  const column = (columns[2] || '').trim();
                  if (column.length > 0 && column.length <= 20) {
                    item.afanganumer = column;
                  }
                }
                if (item.afanganumer.length === 0) {
                  error += 'Áfanga númer ólöglegt eða vantar. ';
                }

                // Áfangi
                if (columns.length > 3) {
                  const column = (columns[3] || '').trim();
                  if (column.trim().length > 0 && column.length <= 30) {
                    item.afangi = column;
                  }
                }
                if (item.afangi.length === 0){
                  error += 'Áfangi ólöglegur eða vantar. ';
                } 

                // Heiti áfanga
                if (columns.length > 4) {
                  const column = (columns[4] || '').trim();
                  if (column.length > 0 && column.length <= 100) {
                    item.heiti_afanga = column;
                  }
                }
                if (item.heiti_afanga.length === 0) {
                  error += 'Heiti áfanga ólöglegt eða vantar. ';
                }
                // Þrep
                if (columns.length > 5) {
                  const column = (columns[5] || '').trim();
                  if (column.length > 0 && Number.parseInt(column, 10)) {
                    item.trep = column;
                  } else if (column.length > 0 && !Number.parseInt(column, 10)) {
                    item.trep = column;
                    error += 'Þrep er ekki heiltala. ';
                  }
                }

                // Einingar
                if (columns.length > 6) {
                  const column = (columns[6] || '').trim();
                  if (column.length > 0 && !Number.isNaN(column)) {
                    item.einingar = column.toString().replace('.', ',');
                  } else if (column.length > 0 && Number.isNaN(column)) error += 'Einingar er ekki tala. ';
                }

                // Fj. tíma á viku (fyrirlestur)
                if (columns.length > 7) {
                  const column = (columns[7] || '').trim();
                  if (column.length > 0 && Number.parseInt(column, 10)) {
                    item.fj_timar_viku = column;
                  } else if (column.length > 0 && !Number.parseInt(column, 10)) {
                    item.fj_timar_viku = column;
                    error += 'Tímar á viku er ekki heiltala. ';
                  } 
                }

                // Fj. tíma á viku (verkefnahópur)
                if (columns.length > 8) {
                  const column = (columns[8] || '').trim();
                  if (column.trim().length > 0 && Number.parseInt(column, 10)) {
                    item.fj_timar_viku_2 = column;
                  } else if (column.length > 0 && !Number.parseInt(column, 10)) {
                    item.fj_timar_viku_2 = column;
                    error += 'Tímar á viku er ekki heiltala. ';
                  }
                }

                // Fj. tíma á tímabili
                if (columns.length > 9) {
                  const column = (columns[9] || '').trim();
                  if (column.trim().length > 0 && Number.parseInt(column, 10)) {
                    item.timar_onn_alls = column;
                  } else if (column.length > 0 && !Number.parseInt(column, 10)) {
                    item.timar_onn_alls = column;
                    error += 'Tímar á tímabili er ekki heiltala. ';
                  }
                }

                // Númer annar (Tímabil)
                if (columns.length > 10) {
                  const column = (columns[10] || '').trim();
                  const numberRegex = /^[0-9]*$/;
                  if (column.length > 0 && numberRegex.test(column.trim())) {
                    item.onn_nr = column;
                  } else if (column.length > 0 && !numberRegex.test(column.trim())) {
                    item.onn_nr = column;
                    error += 
                  'Númer annar er ekki heiltala, einungis er hægt að nota tölustafi í tímabil. ';
                  }
                }

                // Braut
                if (columns.length > 11) {
                  const column = (columns[11] || '').trim();
                  if (column.length > 0 && column.length <= 200) {
                    item.braut = column;
                  } else if (column.length > 0 && column.length >= 200) error += 'Brautarheiti er of langt. ';
                }

                // Kóði brautar
                if (columns.length > 12) {
                  const column = (columns[12] || '').trim();
                  if (column.length > 0 && column.length <= 10) {
                    item.braut_kodi = column;
                  } else if (column.length > 0 && column.length >= 10) error += 'Kóði brautar of langur. ';
                }

                // Einingar
                if (columns.length > 13) {
                  const column = (columns[13] || '').trim();
                  if (column.length > 0 && Number.parseInt(column, 10)) {
                    item.einingar_alls = column;
                  } else if (column.length > 0 && !Number.parseInt(column, 10)) {
                    item.einingar_alls = column;
                    error += 'Einingar er ekki heiltala. ';
                  }
                }

                // Heiti áfanga (enska)
                if (columns.length > 14) {
                  const column = (columns[14] || '').trim();
                  if (column.length > 0 && column.length <= 100) {
                    item.heiti_afanga_enska = column;
                  } else if (column.length > 0 && column.length >= 100) error += 'Brautarheiti er of langt. ';
                }

                // Jafngildir
                if (columns.length > 15) {
                  const column = (columns[15] || '').trim();
                  if (column.length > 0 && column.length <= 1000) {
                    item.jafngildir = column;
                  } else if (column.length > 0 && column.length >= 1000) error += 'Jafngildi er of langt. ';
                }

                // Undanfarar
                if (columns.length > 16) {
                  const column = (columns[16] || '').trim();
                  if (column.length > 0 && column.length <= 1000) {
                    item.undanfarar = column;
                  } else if (column.length > 0 && column.length >= 1000) error += 'Undanfari er of langt. ';
                }

                // Uppbygging brautar
                if (columns.length > 17) {
                  const column = (columns[17] || '').trim();
                  if (column.length > 0 && column.length <= 100) {
                    item.uppbygging_brautar = column;
                  } else if (column.length > 0 && column.length >= 100) error += 'Uppbygging brautar er of langt. ';
                }

                // Tegund uppbyggingar
                if (columns.length > 18) {
                  const column = (columns[18] || '').trim();
                  if (column.length > 0 && this.types.find(x => x.heiti.toUpperCase() === column.toUpperCase())) {
                    item.tegund_uppbyggingar = column;
                  } else if (column.length > 0 && !this.types.find(x => x.heiti.toUpperCase() === column.toUpperCase()))
                    error += 'Tegund uppbyggingar finnst ekki. ';
                }

                if (item.braut.length > 0 && item.braut_kodi.length === 0) error += 'Kóða brautar vantar. ';
                if (item.braut.length === 0 && item.braut_kodi.length > 0) error += 'Heiti brautar vantar. ';
                if (item.braut.length > 0 && item.braut_kodi.length > 0 && item.uppbygging_brautar.length === 0)
                  error += 'Uppbygging brautar vantar. ';
                if (item.braut.length > 0 && item.braut_kodi.length > 0 && item.tegund_uppbyggingar.length === 0)
                  error += 'Tegund uppbyggingar vantar. ';

                // Deild
                if (columns.length > 19) {
                  const column = (columns[19] || '').trim();
                  if (column.length > 0 && this.departments.find(x => x.name.toUpperCase() === column.toUpperCase())) {
                    item.deild = column;
                  } else if (column.length > 0 && !this.departments.find(x => x.name.toUpperCase() === column.toUpperCase())) {
                    item.deild = column;
                    error += 'Deild finnst ekki. ';
                  } 
                    
                }

                if (error.length > 0) {
                  this.notValid.push({ ...item, error });
                } else {
                  this.parsed.push(item);
                }
              }
            });
          } else {
            this.displayError('Skrá ekki lögleg. Dálkar verða að vera aðskildir með ";"');
          }
          resolve();
        };
      });
    },
    async loadFile() {
      if (this.file.name && this.file.name.length > 4 && this.file.name.endsWith('.csv')) {
        this.firsttime = true;
        this.parsed = [];
        this.notValid = [];
        // Vinnu úr skjalinu
        await this.parseFile();
        // Athuga hvort allar kennitölur finnist í þjóðskrá
        this.file = null;
      } else {
        this.displayError('Skrá er ekki .csv skrá. Vinsamlegast veljið aðra.');
      }
    },
    async uploadData() {
      try {
        this.saving = true;
        const response = await upload.uploadCurriculum(this.form.undirskoli.division_id, this.parsed);
        if (response.data.length === 0) {
          await this.$uploadApi.uploadCurriculum();
          this.parsed = [];
          this.notValid = [];
        } else {
          this.displayError('Villa kom upp við að vista gögn.');
        }
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.saving = false;
      }
    },
    async loadTypes() {
      try {
        const response = await curriculum.getCourseModuleTypes({ showAll: 1 });
        this.types = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadDepartments() {
      try {
        const response = await structure.getDepartmentList();
        this.departments = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadDivisions() {
      try {
        const response = await structure.getDivisionList();
        this.divisions = response.data.items.map(x => ({
          ...x,
          label: `${x.code} - ${x.name}`,
        }));
        if (this.divisions.length > 0) {
          if (this.divisions.find(x => x.name.indexOf('Dag') > -1)) {
            this.form.undirskoli = this.divisions.find(x => x.name.indexOf('Dag') > -1);
          } else {
            this.form.undirskoli = this.divisions[0];
          }
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadWorkerInProgress() {
      try {
        const response = await common.getWorkersInProgress('innlestur_namskra');
        this.workers = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    onInnlesturNamskraStarted() {
      this.loadWorkerInProgress();
    },
    onInnlesturNamskraComplete() {
      this.loadWorkerInProgress();
      this.displaySuccess('Innlestri lokið');
    },
    onInnlesturNamskraFailure() {
      this.loadWorkerInProgress();
      this.parsed = [];
      this.notValid = [];
      this.displayError('Villa við innlestur. Vinsamlegast reynið aftur.');
    },
    openModuleSearchSlidebar() {
      this.showModuleSearchSlidebar = true;
      this.showCourseSearchSlidebar = false;
    },
    openCourseSearchSlidebar() {
      this.showCourseSearchSlidebar = true;
      this.showModuleSearchSlidebar = false;
    },
    closeModuleSlidebar(item) {
      if (item && item.id) {
        const routeData = this.$router.resolve({ name: 'Module', params: { id: item.id } });
        window.open(routeData.href, '_blank');
      }
      this.showCourseSearchSlidebar = false;
      this.showModuleSearchSlidebar = false;
    },
    closeCourseSlidebar(item) {
      if (item && item.id) {
        const routeData = this.$router.resolve({ name: 'Course', params: { id: item.id } });
        window.open(routeData.href, '_blank');
      }
      this.showCourseSearchSlidebar = false;
      this.showModuleSearchSlidebar = false;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  mounted() {
    this.$socket.emit('joinInnlesturNamskra');
    this.sockets.subscribe('innlesturNamskraStarted', this.onInnlesturNamskraStarted);
    this.sockets.subscribe('innlesturNamskraComplete', this.onInnlesturNamskraComplete);
    this.sockets.subscribe('innlesturNamskraFailure', this.onInnlesturNamskraFailure);
  },
  beforeDestroy() {
    this.$socket.emit('leaveInnlesturNamskra');
    this.sockets.unsubscribe('innlesturNamskraStarted');
    this.sockets.unsubscribe('innlesturNamskraComplete');
    this.sockets.unsubscribe('innlesturNamskraFailure');
  },
  created() {
    this.loadTypes();
    this.loadDepartments();
    this.loadDivisions();
  },
};
</script>

<style lang="scss">
.schoolCurriculumFile {
  .custom-file-label::after {
    content: '' !important;
    padding: 0;
  }
}
</style>
