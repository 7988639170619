<template>
    <Slidebar :large="true" @closeMe="closeSlidebar">
        <h4>Skrá ástundun og hegðun á nemanda</h4>
        <div>
            <b-row>
                <div>
                    Til að skrá ástundun og hegðun þarf fyrst að velja dagssetningu og kennslustundina svo er valið það sem
                    á að skrá.
                    Hægt er að skrá bæði ástundun og hegðun og velja fleiri en eitt atriði úr listanum
                </div>
            </b-row>
            <b-row>
                <b-col class="pl-0">
                    <b-form-group label-for="date" label="Dags." :state="submitted && errors.has('date') ? false : ''"
                        :invalid-feedback="errors.first('date')">
                        <datepicker id="date" name="date" v-model="date" :language="lang" :monday-first="true"
                            format="dd.MM.yyyy" :typeable="false" :clear-button="false" ref="registerDatePickr"
                            :disabled-dates="disabledDates" @input="getAttendanceData()"></datepicker>
                    </b-form-group>
                </b-col>
            </b-row>
            <Loader v-if="loading.attendanceData"></Loader>
            <div v-if="!loading.attendanceData && attendanceData.length === 0">Ekki er hægt að skrá ástundun og hegðun á
                þennan dag.
            </div>
            <div v-if="attendanceData.length > 0">
                <table class="table less-padding no-border" style="font-size: 13px">
                    <thead>
                        <tr>
                            <th>Áfangi</th>
                            <th>Tími</th>
                            <th></th>
                            <th>Ástundun</th>
                            <th>Hegðun</th>
                        </tr>
                    </thead>
                    <tr v-for="(s, idx) in attendanceData" :key="idx">

                        <td>{{ s.namsgrein_afanganr }}</td>
                        <td>{{ s.timifra }} - {{ s.timitil }}</td>
                        <td>
                            <input class="d-inline-block" type="text" v-model="s.tegund_fjarvista" maxLength="1"
                                @input="checkIfAttendance(s)" style="width: 35px; height: 25px; padding-left: 3px" :class="{
                                    attendanceInvalidInput: !attendanceValid(s.tegund_fjarvista) ||
                                        behaviorAndPracticeCheck(s.tegund_fjarvista, s.astundun.length, s.hegdun.length),
                                    attendanceValidInput: attendanceValid(s.tegund_fjarvista),
                                }" />
                        </td>
                        <td>
                            <v-select id="behaviorCodes" name="astundun_heiti" v-model="s.astundun" :clearable="true"
                                @input="checkIfAttendance(s)" :options="practiceCodes" label="heiti_astundun"
                                data-vv-as="tegund_astundun_id" multiple>
                            </v-select>
                        </td>
                        <td>
                            <v-select id="practiceCodes" name="practiceCodes" v-model="s.hegdun" :clearable="true"
                                @input="checkIfAttendance(s)" :options="behaviorCodes" label="heiti_astundun"
                                data-vv-as="tegund_astundun_id" multiple>
                            </v-select>
                        </td>
                    </tr>
                </table>
                <div v-if="invalidCode()" style="font-weight: 90%;" class="redErrorText"  >
                   Vinsamlegast skráðu viðveru.
                </div>
                <b-btn variant="primary" class="mb-3 mt-3" :disabled="invalidCode() || saving" 
                @click="saveDayAttendance()" >
                    <i class="fa fa-spin fa-spinner" v-if="saving"></i>
                    Staðfesta ástundun og hegðun
                </b-btn>
                <div style="font-size: 13px">
                    <b>Fjarvistarkóðar:</b>
                    <div v-for="a in attendanceCodes" :key="a.teg_fjarvista" class="pl-2 d-inline-block">{{ a.kodi }} -
                        {{ a.heiti }}</div>
                </div>
            </div>
        </div>
    </Slidebar>
</template>

<script>
import moment from 'moment';
import types from '@/api/types';
import attendance from '@/api/attendance';
import Slidebar from '@/components/common/Slidebar.vue';
import Loader from '@/components/common/Loader.vue';
import { mapGetters, mapActions } from 'vuex';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';

export default {
    name: 'register-practice-and-behavior-slidebar',
    props: ['studentId', 'termId'],
    components: {
        Loader,
        Slidebar,
        Datepicker,
    },
    computed: {
        ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
    },
    data() {
        return {
            lang: is,
            period: false,
            loading: { attendanceCodes: false, absenceCodes: false, group: false, attendanceData: false, modules: false },
            submitted: false,
            attendanceCodes: [],
            absenceCodes: [],
            options: [],
            saving: false,
            form: {
                dateFrom: moment().toDate(),
                timeFrom: '',
                dateTo: moment().toDate(),
                timeTo: '',
                type: '',
                comment: '',
                onlyThisOne: '',
            },
            typeName: '',
            date: moment().toDate(),
            attendanceData: [],
            singleComment: '',
            commonCode: '',
            selectAll: false,
            selected: [],
            checkingData: false,
            savingFinal: false,
            alreadyRegistered: [],
            behaviorCodes: [],
            practiceCodes: [],
            behaviorS: [],
            disabledDates: {
                from: new Date(Date.now() + 1), // ekki hægt að velja dagsetningu í framtíð
            },
        };
    },
    methods: {
        closeSlidebar() {
            this.$emit('closeSlidebar');
        },
        updateSelected() {
            if (this.selectAll) {
                this.selected = this.modules.map(x => x.afangi_id);
            } else {
                this.selected = [];
            }
        },
        async checkIfAttendance(line) {
            const x = line;
            if (!x.tegund_fjarvista && (x.astundun.length > 0 || x.hegdun.length > 0)) {
                x.errorInForm = true;
            } else {
                x.errorInForm = false;
            }
        },

        async getPracticeCodes() {
            try {
                this.loading.attendanceCodes = true;
                const response = await attendance.getPracticeAndBehaviorCodes(1);
                this.practiceCodes = response.data.items;
            } catch (e) {
                this.$log.error(e);
                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            } finally {
                this.loading.attendanceCodes = false;
            }
        },
        async getBehaviorCodes() {
            try {
                this.loading.attendanceCodes = true;
                const response = await attendance.getPracticeAndBehaviorCodes(2);
                this.behaviorCodes = response.data.items;
            } catch (e) {
                this.$log.error(e);
                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            } finally {
                this.loading.attendanceCodes = false;
            }
        },
        async loadAttendance() {
            try {
                this.loading.attendanceCodes = true;
                const response = await types.getAttendanceList({ active: 1 });
                this.attendanceCodes = response.data.items.map(x => ({
                    ...x,
                    label: `${x.kodi} - ${x.heiti}`,
                }));
            } catch (e) {
                this.$log.error(e);
                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            } finally {
                this.loading.attendanceCodes = false;
            }
        },
        async getAttendanceData() {
            try {
                this.loading.attendanceData = true;
                const response = await attendance.getRegisterStudentAttendanceData({
                    nemandi_id: this.studentId,
                    dags: moment(this.date).format('DD.MM.YYYY'),
                });
                this.attendanceData = response.data.items.map(x => ({
                    ...x,
                    astundun: x.astundun_og_hegdun ? x.astundun_og_hegdun.filter(y => y.type === 1) : [],
                    hegdun: x.astundun_og_hegdun ? x.astundun_og_hegdun.filter(y => y.type === 2) : [],
                    errorInForm: false,
                }));

            } catch (e) {
                this.$log.error(e);
                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            } finally {
                this.loading.attendanceData = false;
            }
        },
        checkGetData() {
            if (!this.period && this.attendanceData.length === 0) {
                this.getAttendanceData();
            }
        },
        invalidCode() {
            return (
                (this.attendanceData.filter(y => !this.attendanceValid(y.tegund_fjarvista) ||
                    y.errorInForm).length > 0));
        },
        attendanceValid(code) {
            return !code || this.attendanceCodes.find(x => (x.kodi || '').toUpperCase() === code.toUpperCase());
        },
        behaviorAndPracticeCheck(code, astundun, hegdun) {
            return (!code && (astundun > 0 || hegdun > 0));
        },
        updateAll(code, nemandi) {
            nemandi.forEach(d => {
                if (this.type === 0) {
                    this.$set(d, 'tegund_fjarvista', code);
                } else if (this.type === 1) {
                    this.$set(d, 'tegund_forfalla', code);
                }
            });
        },
        async saveDayAttendance() {
            try {
                this.saving = true;
                const data = [];
                this.attendanceData.forEach(a => {
                    const item = {
                        ferill_id: a.ferill_id,
                        stofntafla_id: a.stofntafla_id,
                        stundatafla_id: a.stundatafla_id,
                        dags: moment(a.day).format('DD.MM.YYYY'),
                        athugasemd: this.singleComment, // a.athugasemd,
                    };
                    item.teg_fjarvista_id = (
                        this.attendanceCodes.find(x => (x.kodi || '').toUpperCase() ===
                            (a.tegund_fjarvista || '').toUpperCase()) || {
                            tegund_fjarvista: '',
                        }
                    ).teg_fjarvista;
                    const astundunoghegdun = a.astundun.concat(a.hegdun); // sameina aftur
                    item.astundunoghegdunLengd = astundunoghegdun.length;
                    item.astundun_og_hegdun_ids = astundunoghegdun.map(x => x.tegund_astundun_id).toString();
                    data.push(item);
                });
                await attendance.saveGroupAttendanceData(data, 1);
                const text = 'Ástundun og hegðun skráð';
                this.displaySuccess(text);
                this.$emit('closeAndReloadSlidebar');
            } catch (e) {
                this.$log.error(e);
                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            } finally {
                this.saving = false;
            }
        },
        async clearStudentAbsence(student) {
            try {
                this.saving = true;
                const conf = confirm(`Ertu viss um að þú viljir hreinsa út forföll fyrir ${student.nafn}?`); // eslint-disable-line
                if (conf) {
                    await attendance.clearAbsence(student.fjarv_forfoll_id);
                    this.displaySuccess('Forföll hreinsuð út');
                    this.getAttendanceData();
                }
            } catch (e) {
                this.$log.error(e);
                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            } finally {
                this.saving = false;
            }
        },
        ...mapActions({
            displayError: 'displayError',
            displaySuccess: 'displaySuccess',
        }),
    },
    created() {
        this.getPracticeCodes();
        this.getBehaviorCodes();
        this.getAttendanceData();
        this.loadAttendance();
    },
};
</script>

<style lang="scss">
.attendanceInvalidInput {
    border: 1px solid red;
    color: red;
}

.attendanceValidInput {
    color: blue;
}
</style>
