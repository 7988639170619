<template>
  <Slidebar @closeMe="closeSlidebar" :large="large" :extraLarge="extraLarge">
    <h4>Flokkar</h4>
    <table class="table no-border">
      <thead>
        <tr>
          <th>Heiti</th>
          <th></th>
        </tr>
      </thead>
      <tr v-for="item in list" :key="item.flokkur_id">
        <td>
          <inline-edit
            :doEdit="item.edit"
            :showPencil="false"
            @editField="toggleEdit(item)"
            @saveField="saveField(item)"
            :canEdit="canEdit"
          >
            <span slot="content">
              {{ item.heiti }}
            </span>
            <span slot="input">
              <b-form-input
                :id="`flokkur${item.flokkur_id}`"
                :name="`flokkur${item.flokkur_id}`"
                v-model="item.newName"
                v-on:keyup.enter="saveField(item)"
              />
            </span>
          </inline-edit>
        </td>
        <td>
          <b-btn v-if="canEdit" class="float-right" variant="danger" style="margin-top: -8px" @click="deleteCategory(item)"
            ><i class="fa fa-trash"></i
          ></b-btn>
        </td>
      </tr>
      <tr>
        <td>
          <b-form-input v-model="newCategory" v-on:keyup.enter="addCategory"></b-form-input>
        </td>
        <td>
          <b-btn v-if="canEdit" class="float-right" variant="primary" @click="addCategory()" :disabled="newCategory.length === 0"
            ><i class="fa fa-plus-circle"></i
          ></b-btn>
        </td>
      </tr>
    </table>

    <b-btn variant="primary" size="sm" @click="closeSlidebar()">Til baka</b-btn>
  </Slidebar>
</template>

<script>
import types from '@/api/types';
import { mapActions, mapGetters } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';
import InlineEdit from '@/components/common/InlineEdit.vue';

export default {
  name: 'book-category-type-slidebar',
  props: ['item', 'large', 'extraLarge'],
  components: {
    Slidebar,
    InlineEdit,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      loading: false,
      list: [],
      newCategory: '',
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async loadCategories() {
      try {
        this.loading = true;
        const response = await types.getBookCategoryList();
        this.list = response.data.items.map(x => ({
          ...x,
          newName: x.heiti,
          edit: false,
        }));
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async addCategory() {
      try {
        if (this.newCategory.length > 0) {
          await types.createBookCategory({ heiti: this.newCategory });
          this.displaySuccess(`Flokkurinn ${this.newCategory} hefur verið stofnaður.`);
          this.newCategory = '';
          this.loadCategories();
        }
      } catch (e) {
        this.$log.debug(e);
        if (e.response && e.response.data && e.response.data.error) {
          this.displayError(e.response.data.error);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    toggleEdit(item) {
      this.$set(item, 'edit', !item.edit);
      this.$set(item, 'newName', item.heiti);
    },
    async saveField(item) {
      try {
        if (item.newName.length > 0) {
          await types.updateBookCategory(item.flokkur_id, { heiti: item.newName });
          this.displaySuccess(`Flokknum ${item.newName} hefur verið breytt.`);
          this.loadCategories();
        }
      } catch (e) {
        this.$log.debug(e);
        if (e.response && e.response.data && e.response.data.error) {
          this.displayError(e.response.data.error);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    async deleteCategory(item) {
      const conf = confirm(`Ert þú viss um að eyða flokknum ${item.heiti}?`); // eslint-disable-line
      if (conf) {
        try {
          await types.updateBookCategory(item.flokkur_id, { delete: 1 });
          this.displaySuccess(`Flokknum ${item.heiti} hefur verið eytt.`);
          this.loadCategories();
        } catch (e) {
          this.$log.debug(e);
          if (e.response && e.response.data && e.response.data.error) {
            this.displayError(e.response.data.error);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadCategories();
  },
};
</script>

<style lang="scss"></style>
