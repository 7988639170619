<template>
  <Slidebar :autoClose="false" @closeMe="closeSlidebar">
    <h4 v-if="item">Breyta brautarskráningu</h4>
    <h4 v-else>Skrá nemanda á braut</h4>
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <table class="table less-padding">
        <tr>
          <td style="width: 20%; vertical-align: top">Braut*</td>
          <td v-if="item">{{ item.heiti }} {{ item.braut_kodi }}</td>
          <td v-else>
            <b-form-group
              label-for="braut"
              :state="submitted && errors.has('braut') ? false : ''"
              :invalid-feedback="errors.first('braut')"
            >
              <v-select
                id="braut"
                name="braut"
                v-model="form.braut"
                :clearable="false"
                v-validate="'required'"
                :state="submitted && errors.has('braut') ? false : ''"
                :options="getCourses"
                data-vv-as="braut"
                style="font-size: 13px"
              >
              </v-select>
            </b-form-group>
            <div style="font-size: 14px">
              <b-form-checkbox v-model="showAll" class="d-inline-block" @input="loadFilters()"></b-form-checkbox> Sýna brautir óháð
              undirskóla nemanda
            </div>
            <div v-if="showAll">
              <b-row style="font-size: 14px">
                <b-col class="pl-0">
                  <b-form-group label-for="undirskoli" label="Undirskóli">
                    <v-select id="deild" name="deild" v-model="search.division" :clearable="true" :options="divisions"> </v-select>
                  </b-form-group>
                </b-col>
                <b-col class="pl-0">
                  <b-form-group label-for="deild" label="Deild">
                    <v-select id="deild" name="deild" v-model="search.department" :clearable="true" :options="departments"> </v-select>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </td>
        </tr>
        <tr>
          <td style="width: 20%">
            Dags. byrjar <i class="fa fa-info-circle" title="Ef ekki er fyllt út kemur sjálfkrafa dagurinn í dag." v-b-tooltip></i>
          </td>
          <td>
            <b-form-group
              label-for="dags_byrjun"
              :state="submitted && errors.has('dags_byrjun') ? false : ''"
              :invalid-feedback="errors.first('dags_byrjun')"
            >
              <datepicker
                id="dags_byrjun"
                name="dags_byrjun"
                v-model="form.dags_byrjun"
                :language="lang"
                :monday-first="true"
                format="dd.MM.yyyy"
                :typeable="false"
                :clear-button="true"
                ref="dateFromOpenPickr"
              ></datepicker>
            </b-form-group>
          </td>
        </tr>
        <tr v-if="item">
          <td style="width: 20%">Dags. endar</td>
          <td>
            <b-form-group
              label-for="dags_endar"
              :state="submitted && errors.has('dags_endar') ? false : ''"
              :invalid-feedback="errors.first('dags_endar')"
            >
              <datepicker
                id="dags_endar"
                name="dags_endar"
                v-model="form.dags_endar"
                :language="lang"
                :monday-first="true"
                format="dd.MM.yyyy"
                :typeable="false"
                :clear-button="true"
                ref="dateToOpenPickr"
              ></datepicker>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td style="width: 20%">Velja sjálfvirkt á nemanda</td>
          <td>
            <b-form-group
              label-for="sjalfvirkt_val"
              :state="submitted && errors.has('sjalfvirkt_val') ? false : ''"
              :invalid-feedback="errors.first('sjalfvirkt_val')"
            >
              <v-select
                id="sjalfvirkt_val"
                name="sjalfvirkt_val"
                v-model="form.sjalfvirkt_val"
                :clearable="false"
                v-validate="'required'"
                :state="submitted && errors.has('sjalfvirkt_val') ? false : ''"
                :options="yesNo"
                data-vv-as="sjálfvirkt val"
                style="width: 160px"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
        <tr v-if="item">
          <td style="width: 20%">Braut lokið</td>
          <td>
            <b-form-group
              label-for="braut_lokid"
              :state="submitted && errors.has('braut_lokid') ? false : ''"
              :invalid-feedback="errors.first('braut_lokid')"
            >
              <v-select
                id="braut_lokid"
                name="braut_lokid"
                v-model="form.braut_lokid"
                :clearable="true"
                :state="submitted && errors.has('braut_lokid') ? false : ''"
                :options="yesNo"
                data-vv-as="braut_lokid"
                style="width: 160px"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
        <tr v-if="item">
          <td style="width: 20%">Ástæða breytinga</td>
          <td>
            <b-form-group
              label-for="astaeda_breytinga"
              :state="submitted && errors.has('astaeda_breytinga') ? false : ''"
              :invalid-feedback="errors.first('astaeda_breytinga')"
            >
              <b-form-textarea id="astaeda_breytinga" name="astaeda_breytinga" v-model="form.astaeda_breytinga" maxLength="2000">
              </b-form-textarea>
            </b-form-group>
          </td>
        </tr>
        <tr v-if="item">
          <td style="width: 20%">Athugasemd</td>
          <td>
            <b-form-group
              label-for="athugasemd"
              :state="submitted && errors.has('athugasemd') ? false : ''"
              :invalid-feedback="errors.first('athugasemd')"
            >
              <b-form-textarea id="athugasemd" name="athugasemd" v-model="form.athugasemd" maxLength="2000"> </b-form-textarea>
            </b-form-group>
          </td>
        </tr>
      </table>
    </b-form>
    <div v-if="saving && item"><i class="fa fa-spin fa-spinner text-primary"></i> Augnablik.. Verið er að vista breytingar.</div>
    <div v-if="saving && !item"><i class="fa fa-spin fa-spinner text-primary"></i> Augnablik.. Verið er að skrá nemanda á braut.</div>
    <b-btn variant="primary" @click="validateBeforeSubmit()" :disabled="saving">Vista</b-btn>
    <b-btn variant="danger" class="float-right" v-if="item" @click="deleteCourse()" :disabled="saving">Eyða</b-btn>
  </Slidebar>
</template>

<script>
import moment from 'moment';
import curriculum from '@/api/curriculum';
import students from '@/api/students';
import structure from '@/api/structure';
import { mapActions } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';

import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';

export default {
  name: 'student-edit-course-slidebar',
  props: ['studentId', 'divisionId', 'item'],
  components: {
    Slidebar,
    Datepicker,
  },
  computed: {
    getCourses() {
      return this.courses.filter(
        x =>
          (this.showAll &&
            (!this.search.division || x.undirskoli_id === this.search.division.division_id) &&
            (!this.search.department || (x.deild || '').split(',').indexOf(this.search.department.name) > -1)) ||
          (!this.showAll && x.undirskoli_id === parseInt(this.divisionId, 10)),
      );
    },
  },
  data() {
    return {
      lang: is,
      saving: false,
      submitted: false,
      showAll: false,
      loading: {
        courses: false,
        departments: false,
        divisions: false,
      },
      courses: [],
      departments: [],
      divisions: [],
      search: {
        division: '',
        department: '',
      },
      form: {
        braut: '',
        dags_byrjun: '',
        dags_endar: '',
        sjalfvirkt_val: { id: 1, label: 'Já' },
        astaeda_breytinga: '',
        athugasemd: '',
        braut_lokid: '',
      },
      yesNo: [
        { id: 1, label: 'Já' },
        { id: 1, label: 'Nei' },
      ],
    };
  },
  methods: {
    async loadCourses() {
      try {
        this.loading.courses = true;
        const response = await curriculum.getCourseList({ active: 1, enroll: 1, notStudent: this.studentId, showDepartment: 1 });
        this.courses = response.data.items.map(x => ({
          ...x,
          label: `${x.braut_kodi} ${x.heiti}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.courses = false;
      }
    },
    initialize() {
      this.form.dags_byrjun = this.item.dags_byrjun ? moment(this.item.dags_byrjun, 'DD.MM.YYYY').toDate() : '';
      this.form.dags_endar = this.item.dags_endar ? moment(this.item.dags_endar, 'DD.MM.YYYY').toDate() : '';
      this.form.sjalfvirkt_val = this.yesNo.find(x => x.id === this.item.sjalfvirkt_val);
      this.form.braut_lokid = this.yesNo.find(x => x.id === this.item.braut_lokid);
      this.form.astaeda_breytinga = this.item.astaeda_breytinga;
      this.form.athugasemd = this.item.athugasemd;
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.saving = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });
        const item = {
          braut_id: this.item ? this.item.braut_id : this.form.braut.braut_id,
          dags_byrjun: this.form.dags_byrjun ? moment(this.form.dags_byrjun).format('DD.MM.YYYY') : '',
          dags_endar: this.form.dags_endar ? moment(this.form.dags_endar).format('DD.MM.YYYY') : '',
          sjalfvirkt_val: this.form.sjalfvirkt_val ? this.form.sjalfvirkt_val.id : 0,
          astaeda_breytinga: this.form.astaeda_breytinga,
          athugasemd: this.form.athugasemd,
          braut_lokid: this.form.braut_lokid ? this.form.braut_lokid.id : 0,
        };

        if (this.item) {
          await students.updateStudentCourse(this.studentId, this.item.nemandi_brautir_id, item);
          this.displaySuccess('Braut breytt');
        } else {
          await students.createStudentCourse(this.studentId, item);
          this.displaySuccess('Nemandi skráður á braut');
        }
        this.$emit('closeAndReloadSlidebar');
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.saving = false;
      }
    },
    async deleteCourse() {
      const conf = confirm(`Ertu viss um að þú viljir eyða brautinni ${this.item.heiti}?`); // eslint-disable-line
      if (conf) {
        try {
          await students.updateStudentCourse(this.studentId, this.item.nemandi_brautir_id, { delete: 1 });

          this.displaySuccess('Braut eytt.');
          this.$emit('closeAndReloadSlidebar');
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    loadFilters() {
      if (this.divisions.length === 0) {
        this.loadDivisions();
      }
      if (this.departments.length === 0) {
        this.loadDepartments();
      }
    },
    async loadDivisions() {
      try {
        this.loading.divisions = true;
        const response = await structure.getDivisionList();
        this.divisions = response.data.items.map(x => ({
          ...x,
          label: `${x.code} - ${x.name}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.divisions = false;
      }
    },
    async loadDepartments() {
      try {
        this.loading.departments = true;
        const response = await structure.getDepartmentList();
        this.departments = response.data.items.map(x => ({
          ...x,
          label: `${x.name}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.departments = false;
      }
    },
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    if (this.item) {
      this.initialize();
    } else {
      this.loadCourses();
    }
  },
};
</script>

<style lang="scss"></style>
