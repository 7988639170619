<template>
  <div class="page-menu noprint">
    <div v-for="(item, index) in items" :key="index" class="page-menu-items" :class="{ selected: item.page === selectedPage }">
      <div class="page-menu-item">
        <b-link :to="{ name: route, query: { page: item.page } }">{{ item.name }}</b-link>
      </div>
      <div v-if="item.page === selectedPage" class="page-menu-selected" />
    </div>
  </div>
</template>

<script>
// route: name á current route
// items: listi af síðum, inniheldur name (heiti) og page (heitið á query params)
// selectedPage: sú blaðsíða sem er í query params núna
export default {
  name: 'page-menu',
  props: ['route', 'items', 'selectedPage'],
};
</script>

<style lang="scss">
@import '../../style/variables';

.page-menu {
  border-bottom: 1px solid #e5e5e5;
  margin-left: -40px;
  padding-left: 40px;
  margin-bottom: 20px;
  .page-menu-items {
    display: inline-block;
    padding-right: 20px;
    height: 20px;
    font-family: 'Adelle Sans';
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 15px;
    a {
      color: #303030;
      // text-transform: uppercase;
      &:hover {
        text-decoration: none;
      }
    }
    &.selected {
      font-weight: bold;
    }
    .page-menu-selected {
      height: 1px;
      width: auto;
      background-color: $primary;
      margin-top: 15px;
    }
  }
}
</style>
