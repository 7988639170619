<template>
  <div>
    <p class="mb-0">1. Leita eftir nemendum til að færa <i v-if="step > 1" class="fa fa-check text-primary"></i></p>
    <div v-if="step === 1">
      <b-form-group
        label="Skólaár"
        label-for="searchYear"
        :state="submitted && errors.has('searchYear') ? false : ''"
        :invalid-feedback="errors.first('searchYear')"
      >
        <v-select
          id="searchYear"
          name="searchYear"
          v-model="search.year"
          :options="years"
          label="heiti"
          :clearable="false"
          data-vv-as="skólaár"
          v-validate="'required'"
        >
        </v-select>
      </b-form-group>
      <b-form-group
        label-for="searchDivision"
        :state="submitted && errors.has('searchDivision') ? false : ''"
        :invalid-feedback="errors.first('searchDivision')"
        label="Undirskóli"
      >
        <v-select
          id="searchDivision"
          name="searchDivision"
          v-model="search.division"
          :clearable="false"
          @input="loadCourses('search')"
          v-validate="'required'"
          :state="submitted && errors.has('searchDivision') ? false : ''"
          :options="divisions"
          data-vv-as="undirskóli"
        >
        </v-select>
      </b-form-group>
      <b-form-group
        label-for="searchCourse"
        :state="submitted && errors.has('searchCourse') ? false : ''"
        :invalid-feedback="errors.first('searchCourse')"
        label="Braut"
      >
        <v-select
          id="searchCourse"
          name="searchCourse"
          v-model="search.course"
          :clearable="true"
          :state="submitted && errors.has('searchCourse') ? false : ''"
          :options="courses"
          data-vv-as="braut"
        >
        </v-select>
      </b-form-group>
      <b-form-group
        label-for="searchStatus"
        :state="submitted && errors.has('searchStatus') ? false : ''"
        :invalid-feedback="errors.first('searchStatus')"
        label="Staða nema"
      >
        <v-select
          id="searchStatus"
          name="searchStatus"
          v-model="search.status"
          :clearable="true"
          :state="submitted && errors.has('searchStatus') ? false : ''"
          :options="statuses"
          data-vv-as="staða"
          label="heiti"
        >
        </v-select>
      </b-form-group>
      <b-btn variant="primary" @click="searchStudents()">Leita</b-btn>
    </div>
    <div class="d-inline-block">
      <p class="mb-0 mt-2">
        2. Velja nemendur sem þú vilt færa
        <i v-if="step > 2" class="fa fa-check text-primary"></i>
      </p>
    </div>
    <div v-if="step > 1" class="pl-3 d-inline-block">
      Forsendur: <strong>Ár: </strong>{{ search.year.heiti }} <strong class="pl-1">Undirskóli: </strong>{{ search.division.name }}
      <span v-if="search.course"> <strong class="pl-1"> Braut: </strong>{{ search.course.braut_kodi }}</span>
      <span v-if="search.status"> <strong class="pl-1"> Staða nema: </strong>{{ search.status.heiti }}</span>
      <b-btn variant="primary" class="btn-xs ml-2" style="font-size: 13px; margin-top: -5px" v-if="step === 2" @click="step = 1"
        ><i class="fa fa-pencil"></i
      ></b-btn>
    </div>
    <div v-if="step === 2">
      <Shuttle
        :list="students"
        :value="selectedStudents"
        :size="20"
        titleFrom="Nemendur sem uppfylla leita"
        titleTo="Valdir nemendur"
        typeName="nemendur"
      ></Shuttle>
      <b-btn
        variant="primary"
        class="mt-2"
        :disabled="selectedStudents.length === 0"
        @click="
          step = 3;
          loadCourses('form');
        "
        >Halda áfram
      </b-btn>
    </div>
    <div>
      <p class="mb-0 mt-2 d-inline-block">3. Velja hvert á að flytja nema</p>
      <div v-if="step === 3" class="d-inline-block pl-3">
        Valdir nemendur: {{ selectedStudents.length }}
        <b-btn variant="primary" class="btn-xs ml-2" style="font-size: 13px; margin-top: -5px" @click="step = 2"
          ><i class="fa fa-pencil"></i
        ></b-btn>
      </div>
    </div>
    <div v-if="step === 3">
      <b-form-group
        label="Skólaár"
        label-for="year"
        :state="submitted && errors.has('year') ? false : ''"
        :invalid-feedback="errors.first('year')"
      >
        <v-select
          id="year"
          name="year"
          v-model="form.year"
          :options="years"
          label="heiti"
          :clearable="false"
          data-vv-as="skólaár"
          v-validate="'required'"
        >
        </v-select>
      </b-form-group>
      <b-form-group
        label-for="division"
        :state="submitted && errors.has('division') ? false : ''"
        :invalid-feedback="errors.first('division')"
        label="Undirskóli"
      >
        <v-select
          id="division"
          name="division"
          v-model="form.division"
          :clearable="false"
          @input="loadCourses('form')"
          v-validate="'required'"
          :state="submitted && errors.has('division') ? false : ''"
          :options="divisions"
          data-vv-as="undirskóli"
        >
        </v-select>
      </b-form-group>
      <b-form-group
        label-for="course"
        :state="submitted && errors.has('course') ? false : ''"
        :invalid-feedback="errors.first('course')"
        label="Braut"
      >
        <v-select
          id="course"
          name="course"
          v-model="form.course"
          :clearable="true"
          :state="submitted && errors.has('course') ? false : ''"
          :options="courses"
          data-vv-as="braut"
        >
        </v-select>
      </b-form-group>
      <b-btn variant="primary" class="mt-2" @click="moveStudents()">Flytja upp </b-btn>
    </div>
  </div>
</template>

<script>
import classes from '@/api/classes';
import curriculum from '@/api/curriculum';
import structure from '@/api/structure';
import students from '@/api/students';
import { mapActions, mapGetters } from 'vuex';

import Shuttle from '@/components/common/Shuttle.vue';

export default {
  name: 'transfer-students',
  props: ['id'],
  components: {
    Shuttle,
  },
  computed: {
    canRead() {
      return this.loggedInUserHasReadPermission('skolahald_annir');
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_annir');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolHasSettings']),
  },
  data() {
    return {
      submitted: false,
      step: 1,
      loading: {
        years: false,
        divisons: false,
        courses: false,
        statuses: false,
      },
      years: [],
      divisions: [],
      courses: [],
      statuses: [],
      students: [],
      selectedStudents: [],
      search: {
        year: '',
        division: '',
        course: '',
        status: '',
      },
      form: {
        year: '',
        division: '',
        course: '',
      },
    };
  },
  methods: {
    async loadYears() {
      try {
        this.loading.years = true;
        const response = await classes.getSchoolYears();
        this.years = response.data.items.map(x => ({
          ...x,
          label: `${x.heiti}`,
        }));
        this.search.year = this.years.length > 0 ? this.years[0] : '';
        this.form.year = this.years.length > 0 ? this.years[0] : '';
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.years = false;
      }
    },
    async loadDivisions() {
      try {
        this.loading.divisions = true;
        const response = await structure.getDivisionList();
        this.divisions = response.data.items.map(x => ({
          ...x,
          label: `${x.code} - ${x.name}`,
        }));
        this.search.division = this.divisions.length > 0 ? this.divisions[0] : '';
        this.form.division = this.divisions.length > 0 ? this.divisions[0] : '';
        if (this.search.division) this.loadCourses();
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.divisions = false;
      }
    },
    async loadCourses(type) {
      try {
        this.loading.courses = true;
        const params = {
          division: type === 'search' ? this.search.division.division_id : this.form.division.division_id,
          active: 1,
        };
        const response = await curriculum.getCourseList(params);
        this.courses = response.data.items.map(x => ({
          ...x,
          label: `${x.braut_kodi} - ${x.heiti}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.courses = false;
      }
    },
    async loadStatuses() {
      try {
        this.loading.statuses = true;
        const response = await students.getStudentsStatuses();
        this.statuses = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.statuses = false;
      }
    },
    async searchStudents() {
      try {
        const params = {
          active: 1,
          year: this.search.year.skolaar_id,
          division: this.search.division.division_id,
          course: this.search.course ? this.search.course.braut_id : '',
          status: this.search.status ? this.search.status.eink_stodu : '',
          showCourse: 1,
        };
        this.loading.students = true;
        const response = await students.getStudentList(params);
        this.students = response.data.items.map(x => ({
          id: x.nemandi_id,
          name: `${x.nafn}, ${x.kennitala}, ${x.braut}, ${x.nu_onn}`,
        }));
        this.step = 2;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.students = false;
      }
    },
    async moveStudents() {
      try {
        const data = {
          year: this.form.year.skolaar_id,
          division: this.form.division.division_id,
          course: this.form.course ? this.form.course.braut_id : null,
          students: this.selectedStudents,
        };
        await classes.transferStudents(data);
        this.displaySuccess('Nemendur hafa verið færðir');
        this.step = 1;
        this.selectedStudents = [];
      } catch (e) {
        // Aðgerð mistókst.
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadYears();
    this.loadDivisions();
    this.loadStatuses();
  },
};
</script>
