<template>
  <Slidebar :autoClose="false" @closeMe="closeSlidebar" :large="large" :extraLarge="extraLarge">
    <h4>Umsjónakennarar</h4>
    <table class="table table-fixed" v-if="!loading.supervisors">
      <thead>
        <tr>
          <th colspan="3">Nafn</th>
          <th colspan="2">Önn</th>
          <th v-if="canEdit"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in supervisors" :key="item.umsjon_id">
          <td colspan="3">{{ item.nafn }}</td>
          <td colspan="2">{{ item.onn_eink }}</td>
          <td v-if="canEdit">
            <b-btn variant="primary" size="sm" class="btn-xs float-right" @click="deleteSupervisor(item)" :disabled="deleting">
              <i class="fa fa-trash"></i
            ></b-btn>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <v-select id="staff" name="staff" v-model="selected.staff" :clearable="false" :options="staff" label="nafn"> </v-select>
          </td>
          <td colspan="2">
            <v-select id="term" name="term" v-model="selected.term" :clearable="false" :options="terms" label="onn_eink"> </v-select>
          </td>
          <td v-if="canEdit">
            <b-btn
              variant="primary"
              size="sm"
              class="btn-xs float-right"
              @click="addSupervisor()"
              :disabled="!selected.staff || !selected.term || saving"
            >
              <i class="fa fa-save"></i
            ></b-btn>
          </td>
        </tr>
      </tbody>
    </table>
  </Slidebar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import students from '@/api/students';
import staff from '@/api/staff';
import terms from '@/api/terms';

import Slidebar from '@/components/common/Slidebar.vue';

export default {
  name: 'student-supervisor-slidebar',
  components: {
    Slidebar,
  },
  props: ['studentId', 'large', 'extraLarge'],
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('nemendahald_nemendur');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      saving: false,
      deleting: false,
      loading: {
        supervisors: false,
        terms: false,
        staff: false,
      },
      selected: {
        term: '',
        staff: '',
      },
      supervisors: [],
      terms: [],
      staff: [],
    };
  },
  methods: {
    async loadSupervisors() {
      try {
        this.loading.supervisors = true;
        const response = await students.getStudentSupervisors(this.studentId);
        this.supervisors = response.data.items;
        this.loadTerms();
        this.loadStaff();
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.supervisors = false;
      }
    },
    async loadTerms() {
      try {
        this.loading.terms = true;
        const response = await terms.getTerms();
        this.terms = response.data.items.map(x => ({
          ...x,
          label: `${x.undirsk_audk} - ${x.onn_heiti}`,
        }));
        if (this.supervisors.length > 0) {
          this.selected.term = this.terms.find(x => x.onn_id === this.supervisors[0].onn_id);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.terms = false;
      }
    },
    async loadStaff() {
      try {
        this.loading.staff = true;
        const response = await staff.getStaffList({ teaches: 1 });
        this.staff = response.data.items;
        if (this.supervisors.length > 0) {
          this.selected.staff = this.staff.find(x => x.starfsm_id === this.supervisors[0].starfsm_id);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.staff = false;
      }
    },
    async addSupervisor() {
      try {
        this.saving = true;
        const item = {
          starfsm_id: this.selected.staff.starfsm_id,
          onn_id: this.selected.term.onn_id,
        };
        await students.createStudentSupervisor(this.studentId, item);
        this.displaySuccess('Umsjónarkennari skráður');
        this.loadSupervisors();
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        if (e.response.status === 400 && e.response.data.error) {
          this.displayError(e.response.data.error);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } finally {
        this.saving = false;
      }
    },
    async deleteSupervisor(item) {
      const conf = confirm(`Ertu viss um að þú viljir eyða umsjónarkennaranum ${item.nafn} af önninni ${item.onn_eink}?`); // eslint-disable-line
      if (conf) {
        try {
          this.deleting = true;
          await students.createStudentSupervisor(this.studentId, { umsjon_id: item.umsjon_id, delete: 1 });
          this.displaySuccess('Umsjónarkennara eytt.');
          this.loadSupervisors();
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.deleting = false;
        }
      }
    },
    closeSlidebar() {
      this.$emit('closeAndReloadSlidebar');
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadSupervisors();
  },
};
</script>

<style lang="scss"></style>
