<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div
      v-for="(item, idx) in items"
      :key="idx"
      class="d-inline-block"
      style="min-width: 200px"
      v-match-heights="{
        el: ['.equal-height-1'], // Array of selectors to fix
      }"
    >
      <StudyRecordTerm
        v-if="item"
        :item="item"
        :studentId="studentId"
        :studentActive="studentActive"
        @refresh="load()"
        class="equal-height-1"
      >
      </StudyRecordTerm>
    </div>
    <div style="font-size: 13px">
      <b>Staða áfanga getur verið:</b><br />
      <span>Áætlun(Á)</span>, &nbsp; <span>Staðfest val(V)</span>, &nbsp;
      <span class="studentStudyRecordOlokid">Ólokið (Ó)</span>, &nbsp; <span class="studentStudyRecordMetid">Metið (M)</span>, &nbsp;
      <span class="studentStudyRecordMetid">Metið án ein.(ME)</span>, &nbsp; <span class="studentStudyRecordLokid">Lokið (L)</span>, &nbsp;
      <span class="studentStudyRecordLokidAnEininga">Lokið án eininga(LE)</span>, &nbsp;
      <span class="studentStudyRecordUrsogn">Úrsögn(Ú)</span>, &nbsp; 
      <!-- <span class="studentStudyRecordFall">Mat Fall(MF)</span>, &nbsp; -->
      <span class="studentStudyRecordFall">Féll(F)</span>
      <!-- <span class="studentStudyRecordFall">Fall á önn(FÖ)</span> -->
      <br />
    </div>
    <Loader v-if="loadingCourses"></Loader>
    <div v-if="!loadingCourses && courses.length > 0" class="pt-2">
      Áfangar í brautarkröfu sem vantar í námsferil:
      <div style="font-size: 85%" v-for="course in courses" :key="course.nemandi_brautir">
        <strong>{{ course.heiti }} {{ course.braut_kodi }}</strong>
        <strong v-if="course.modulesNotConnected"> ({{ course.modulesNotConnected.length }}) : </strong>
        <span v-for="(i, idx) in course.modulesNotConnected" :key="i.afangi_braut"
          ><span v-if="idx !== 0">, </span> {{ i.afangi_braut }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import students from '@/api/students';

import Loader from '@/components/common/Loader.vue';
import StudyRecordTerm from '@/components/students/studyRecords/terms/StudyRecordTerm.vue';

export default {
  name: 'student-study-record-terms',
  props: ['termId', 'studentId', 'slidebar', 'refreshData', 'studentActive'],
  components: {
    StudyRecordTerm,
    Loader,
  },
  computed: {
    canEdit() {
      return !this.slidebar && this.loggedInUserHasWritePermission('nemendahald_nemendur');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      loading: false,
      loadingCourses: false,
      items: '',
      courses: '',
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;
        this.items = [];
        const params = {};
        if (this.termId) {
          params.onn_id = this.termId;
        }
        const response = await students.getStudentStudyRecord(this.studentId, params);
        this.items = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
    async loadCourses() {
      try {
        this.loadingCourses = true;
        const response = await students.getStudentAllCourses(this.studentId);
        this.courses = response.data.items.filter(x => x.braut_i_gangi === 1);

        /* eslint-disable no-await-in-loop */
        for (let i = 0; i < this.courses.length; i += 1) {
          const response2 = await students.getStudyRecordCourse(this.studentId, this.courses[i].braut_id);
          const structure = response2.data.length > 0 ? response2.data[0] : {};
          const structure2 = (structure.uppbygging || []).map(x => x.namsgreinar.map(y => y.afangar).flat()).flat();
          const structure3 = structure2
            .filter(x => !x.afangi_ferill)
            .filter(x => x.ovirk !== 1)
            .sort((a, b) => {
              return a.afangi_braut.localeCompare(b.afangi_braut);
            });
          // structure3.length === 0 ? this.courses[i].modulesNotConnected
          if (structure3.length === 0) {
            this.courses.splice(i, 1); // 2nd parameter means remove one item only
          } else {
            this.courses[i].modulesNotConnected = structure3;
          }
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loadingCourses = false;
      }
    },
  },
  watch: {
    termId() {
      this.load();
    },
    refreshData() {
      if (this.refreshData) this.load();
    },
  },
  created() {
    this.load();
    this.loadCourses();
  },
};
</script>

<style lang="scss"></style>
