<template>
  <Slidebar :autoClose="true" @closeMe="closeSlidebar">
    <h4>Breyta aðgangi</h4>
    <br />
    <h5>Aðgangur</h5>
    <b-row>
      <b-col cols="2">
        <strong>Admin</strong>
        <b-form-checkbox v-model="form.adgangur_admin" @input="changeAdminSettings()" />
      </b-col>
      <b-col>
        <div>
          <strong>Hlutverk</strong>
          <v-select
            id="admin_hlutverk"
            name="admin_hlutverk"
            v-model="form.admin_hlutverk"
            :options="adminJobList"
            label="name"
            @input="changeAdminAccessSettings()"
          >
          </v-select>
        </div>
        <div class="mt-3">
          <strong>Deild</strong>
          <v-select id="adgangur_deild" name="adgangur_deild" v-model="form.adgangur_deild" :options="departments" label="name"> </v-select>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col cols="2">
        <strong>Gamla</strong>
        <b-form-checkbox v-model="form.adgangur_gamla" />
      </b-col>
      <b-col>
        <strong>Hlutverk</strong>
        <div><i>Skilgreint í gömlu Innu</i></div>
      </b-col>
    </b-row>
    <br />
    <!--strong>Námskerfi</strong>
    <v-select id="lms_hlutverk" name="lms_hlutverk" v-model="form.lms_hlutverk" :options="lmsJobList" label="name"> </v-select>
    <br />
    <br /-->
    <b-btn variant="primary" @click="validateBeforeSubmit()" :disabled="form.adgangur_admin && !form.admin_hlutverk">Vista</b-btn>
  </Slidebar>
</template>

<script>
import staff from '@/api/staff';
import structure from '@/api/structure';
import { mapActions } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';

export default {
  name: 'staff-change-access-slidebar',
  components: {
    Slidebar,
  },
  props: ['id'],
  data() {
    return {
      loading: false,
      submitted: false,
      staffId: '',
      adminJobList: [],
      lmsJobList: [],
      departments: [],
      form: {
        adgangur_admin: false,
        adgangur_gamla: false,
        admin_hlutverk: null,
        lms_hlutverk: null,
        adgangur_deild: null,
      },
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    changeAdminSettings() {
      if (!this.form.adgangur_admin) {
        this.form.admin_hlutverk = null;
      }
    },
    changeAdminAccessSettings() {
      if (!this.form.admin_hlutverk) {
        this.form.adgangur_admin = false;
      }
    },
    async loadAccess() {
      try {
        const response = await staff.getStaffAccess(this.id);
        this.form.adgangur_admin = response.data.adgangur_admin === 1;
        this.form.adgangur_gamla = response.data.adgangur_gamla === 1;
        this.getAdminJobs(response.data.hlutverk_admin);
        this.getLmsJobs(response.data.hlutverk_lms);
        this.getDepartments(response.data.adgangur_deild);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async getAdminJobs(id) {
      try {
        this.adminJobList = [];
        this.form.admin_hlutverk = null;
        const response = await staff.getAccessList();
        response.data.items.forEach(i => {
          if (this.adminJobList.map(x => x.id).indexOf(i.adgangur_hlutverk_id) === -1) {
            this.adminJobList.push({
              id: i.adgangur_hlutverk_id,
              name: i.hlutverk_heiti,
            });
          }
        });
        if (id) {
          this.form.admin_hlutverk = this.adminJobList.find(x => x.id === id);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async getLmsJobs(id) {
      try {
        this.lmsJobList = [];
        this.form.admin_hlutverk = null;
        const response = await staff.getAccessLmsList();
        response.data.items.forEach(i => {
          if (this.lmsJobList.map(x => x.id).indexOf(i.adgangur_hlutverk_id) === -1) {
            this.lmsJobList.push({
              id: i.adgangur_hlutverk_id,
              name: i.hlutverk_heiti,
            });
          }
        });
        if (id) {
          this.form.lms_hlutverk = this.lmsJobList.find(x => x.id === id);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async getDepartments(id) {
      try {
        this.departments = [];
        this.form.adgangur_deild = null;
        const response = await structure.getDepartmentList();
        this.departments = response.data.items;
        if (id) {
          this.form.adgangur_deild = this.departments.find(x => x.department_id === id);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          const item = {
            adgangur_admin: this.form.adgangur_admin ? 1 : 0,
            adgangur_gamla: this.form.adgangur_gamla ? 1 : 0,
            admin_hlutverk: this.form.admin_hlutverk ? this.form.admin_hlutverk.id : null,
            lms_hlutverk: this.form.lms_hlutverk ? this.form.lms_hlutverk.id : null,
            adgangur_deild: this.form.adgangur_deild ? this.form.adgangur_deild.department_id : null,
          };
          await staff.updateStaffAccess(this.id, item);
          this.displaySuccess('Aðgangi hefur verið breytt');
          this.closeSlidebar();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    id() {
      this.loadAccess();
    },
  },
  created() {
    this.loadAccess();
  },
};
</script>
<style lang="scss"></style>
