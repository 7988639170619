<template>
  <i :style="style" class="loader fa fa-fw fa-spin fa-circle-o-notch" />
</template>

<script>
export default {
  name: 'loader',
  props: {
    size: String,
  },
  computed: {
    style() {
      return this.size ? `font-size: ${this.size};` : '';
    },
  },
};
</script>

<style lang="scss">
@import '../../style/variables';
.loader {
  color: $primary;
}
</style>
