<template>
  <page>
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <PageTitle title="Skírteini" />
    <PageMenu route="Certificates" :selectedPage="page" :items="menu" />
    <ModuleCertificate v-if="page === 'module'" />
  </page>
</template>

<script>
import { mapGetters } from 'vuex';

import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import PageTitle from '@/components/common/PageTitle.vue';
import PageMenu from '@/components/common/PageMenu.vue';

import ModuleCertificate from '@/components/students/certificates/ModuleCertificate.vue';

export default {
  name: 'certificates',
  components: {
    Breadcrumbs,
    PageTitle,
    PageMenu,
    ModuleCertificate,
  },
  computed: {
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      page: 'module',
      breadcrumbs: [
        { name: 'Forsíða', route: 'Dashboard' },
        { name: 'Nemendahald', route: '' },
        { name: 'Skírteini', route: 'Certificates' },
      ],
      menu: [
        {
          name: 'Viðurkenningaskjöl áfanga',
          page: 'module',
        },
      ],
    };
  },
  methods: {},
  beforeRouteUpdate(to, from, next) {
    if (to && to.query && to.query.page) {
      this.page = to.query.page;
    }
    next();
  },
  created() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
  },
};
</script>
