import Vue from 'vue';

const API_URL = '/api/requests';

export default {
  getParentTeacherSettings() {
    return Vue.http
      .get(`${API_URL}/parent-teacher/settings`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  saveParentTeacherSettings(data) {
    return Vue.http
      .post(`${API_URL}/parent-teacher/settings`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getParentTeacherList(dateFrom, dateTo) {
    return Vue.http
      .get(`${API_URL}/parent-teacher/list/${dateFrom}/${dateTo}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getTimetableRequestsStudentList(termId, params) {
    return Vue.http
      .get(`${API_URL}/timetable-requests-student-list/${termId}`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getTimetableRequestsModuleList(termId, params) {
    return Vue.http
      .get(`${API_URL}/timetable-requests-module-list/${termId}`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getTimetableRequestsStudent(termId, studentId) {
    return Vue.http
      .get(`${API_URL}/timetable-requests-student/${termId}/${studentId}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getTimetableRequestsModule(moduleTermId) {
    return Vue.http
      .get(`${API_URL}/timetable-requests-module/${moduleTermId}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  saveTimetableRequestStaffComment(id, data) {
    return Vue.http
      .post(`${API_URL}/timetable-requests/${id}/comment`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  processTimetableRequestStatus(id, data) {
    return Vue.http
      .post(`${API_URL}/timetable-requests/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createTimetableRequest(data) {
    return Vue.http
      .post(`${API_URL}/timetable-requests`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getChangeCourseRequests(params) {
    return Vue.http
      .get(`${API_URL}/change-course-requests/`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getChangeCourseRequestById(id) {
    return Vue.http
      .get(`${API_URL}/change-course-requests/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateChangeCourseRequests(id, data) {
    return Vue.http
      .post(`${API_URL}/change-course-requests/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateChangeCourseComment(id, data) {
    return Vue.http
      .post(`${API_URL}/change-course-requests/${id}/comment`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  deleteChangeCourseComment(id) {
    return Vue.http
      .delete(`${API_URL}/change-course-requests/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getReviewAbsenceList(params) {
    return Vue.http
      .get(`${API_URL}/review-absence/`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateReviewAbsence(id, data) {
    return Vue.http
      .post(`${API_URL}/review-absence/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createReviewAbsence(data) {
    return Vue.http
      .post(`${API_URL}/review-absence/`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createMassReviewAbsence(data) {
    return Vue.http
      .post(`${API_URL}/review-absence-create`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getEquipmentRequestsList(params) {
    return Vue.http
      .get(`${API_URL}/equipment`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createEquipmentRequests(data) {
    return Vue.http
      .post(`${API_URL}/equipment`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getEquipmentIsBooked(params) {
    return Vue.http
      .get(`${API_URL}/equipment-booked`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getSpecialResources(params) {
    return Vue.http
      .get(`${API_URL}/special-resources`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  processSpecialResources(data) {
    return Vue.http
      .post(`${API_URL}/special-resources`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  massEditSpecialResources(data) {
    return Vue.http
      .post(`${API_URL}/special-resources-mass`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateStudentSpecialResources(id, data) {
    return Vue.http
      .post(`${API_URL}/special-resource/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentModulesLeftCourseOfStudy(studentId) {
    return Vue.http
      .get(`${API_URL}/modules-left-course-of-study/${studentId}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentModulesCanAdd(studentId, termId) {
    return Vue.http
      .get(`${API_URL}/modules-can-add/${studentId}/${termId}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentModulesCanRemove(studentId, termId) {
    return Vue.http
      .get(`${API_URL}/modules-can-remove/${studentId}/${termId}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  changeStudyRecord(data) {
    return Vue.http
      .post(`${API_URL}/change-study-record`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  massRejectTimetableRequests(data) {
    return Vue.http
      .post(`${API_URL}/timetable-requests-mass-reject`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getClassroomRequestList(params) {
    return Vue.http
      .get(`${API_URL}/classroom`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createClassroomRequests(data) {
    return Vue.http
      .post(`${API_URL}/classroom`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getClassroomFreeDates(params) {
    return Vue.http
      .get(`${API_URL}/classroom-free-dates`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  markEquipment(id, data) {
    return Vue.http
      .post(`${API_URL}/equipment/${id}/mark`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
};
