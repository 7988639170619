import Vue from 'vue';

const API_URL = '/api/groups';

export default {
  getGroupGradeRule(groupId) {
    return Vue.http
      .get(`${API_URL}/${groupId}/graderule/`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  copyGradeRuleFromModule(groupId, moduleTermId) {
    return Vue.http
      .post(`${API_URL}/${groupId}/copy-graderule/${moduleTermId}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  copyGradeRuleFromGroup(groupId, copyGroupId) {
    return Vue.http
      .post(`${API_URL}/${groupId}/copy-graderule-group/${copyGroupId}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createGroupGradeRule(groupId, data) {
    return Vue.http
      .post(`${API_URL}/${groupId}/graderule/`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateGroupGradeRule(groupId, ruleId, data) {
    return Vue.http
      .post(`${API_URL}/${groupId}/graderule/${ruleId}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getGroupAssessmentOverview(groupId) {
    return Vue.http
      .get(`${API_URL}/${groupId}/assessment-overview`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  calculateGrades(id) {
    return Vue.http
      .post(`${API_URL}/${id}/calculate-grades`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  finalGrades(id) {
    return Vue.http
      .post(`${API_URL}/${id}/final-grades`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getGroupDetails(id) {
    return Vue.http
      .get(`${API_URL}/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createGroup(data) {
    return Vue.http
      .post(`${API_URL}/`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateGroup(id, data) {
    return Vue.http
      .post(`${API_URL}/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  cancelGroup(id) {
    return Vue.http
      .post(`${API_URL}/${id}/cancel`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentList(id, params) {
    return Vue.http
      .get(`${API_URL}/${id}/students`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateStartDate(id, data) {
    return Vue.http
      .post(`${API_URL}/${id}/start-dates`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  deleteStudentFromGroup(id, data) {
    return Vue.http
      .post(`${API_URL}/${id}/students`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getGradeStudents(id, params) {
    return Vue.http
      .get(`${API_URL}/${id}/grade-students`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getGradeScaleByRule(id) {
    return Vue.http
      .get(`${API_URL}/grade-scale/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  registerGrades(data) {
    return Vue.http
      .post(`${API_URL}/register-grades`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getGroupStudiesCompleted(groupId) {
    return Vue.http
      .get(`${API_URL}/${groupId}/studies-completed`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getGradeRuleType(groupId) {
    return Vue.http
    .get(`${API_URL}/${groupId}/grade-rule-type`)
    .then(response => Promise.resolve(response))
    .catch(error => Promise.reject(error));
  },
  changeStudentsGroups(data) {
    return Vue.http
      .post(`${API_URL}/change-students-groups`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
};
