<template>
  <div>
    <div v-if="selectedStudent" class="pb-3 noprint">
      <span class="cursor-pointer" @click="openSearchSlidebar()"> Nemandi: {{ student.nafn }} </span>
      <b-btn
        variant="primary"
        size="sm"
        style="padding: 2px 4px; margin-top: -5px"
        title="Breyta um nemanda"
        v-b-tooltip
        @click="openSearchSlidebar()"
        ><i class="fa fa-exchange"></i
      ></b-btn>
    </div>
    <h5 v-if="selectedStudent" class="onlyprint" style="position: absolute; left: 0; font-weight: bold">{{ student.nafn }}</h5>

    <StudentSearch :slidebar="true" @changeStudent="selectStudent" v-if="!selectedStudent" />
    <StudentTimetable :id="selectedStudent" v-if="selectedStudent" />
    <student-search-slidebar @closeSlidebar="selectStudent" v-if="showSearchSlidebar"> </student-search-slidebar>
  </div>
</template>

<script>
import students from '@/api/students';
import { mapActions, mapGetters } from 'vuex';
import StudentSearch from '@/components/students/student/StudentSearch.vue';
import StudentTimetable from '@/components/students/student/StudentTimetable.vue';
import StudentSearchSlidebar from '@/components/students/student/StudentSearchSlidebar.vue';

export default {
  name: 'studentTimetable',
  components: {
    StudentSearch,
    StudentTimetable,
    StudentSearchSlidebar,
  },
  computed: {
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      selectedStudent: null,
      student: '',
      showSearchSlidebar: false,
    };
  },
  methods: {
    openSearchSlidebar() {
      this.showSearchSlidebar = true;
    },
    selectStudent(item) {
      if (item) {
        this.selectedStudent = item;
        this.loadDetails();
      }
      this.showSearchSlidebar = false;
    },
    async loadDetails() {
      try {
        this.loading = true;
        const response = await students.getStudentInfo(this.selectedStudent);
        this.student = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
