<template>
  <page>
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <PageTitle :title="title" />
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <b-card>
        <b-row>
          <b-col>
            <b-form-group
              label="Heiti námskeiðs*"
              label-for="name"
              :state="submitted && errors.has('name') ? false : ''"
              :invalid-feedback="errors.first('name')"
            >
              <b-form-input
                id="name"
                name="name"
                v-model="form.name"
                v-validate="'required'"
                :state="submitted && errors.has('name') ? false : ''"
                data-vv-as="nafn"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :state="submitted && errors.has('category') ? false : ''" :invalid-feedback="errors.first('category')">
              <label for="category">Tegund námskeiðs*</label>
              <i class="fa fa-pencil cursor-pointer" v-if="canEdit" @click="openCategorySlidebar()" />
              <v-select
                id="category"
                name="category"
                v-model="form.category"
                :clearable="false"
                v-validate="'required'"
                :state="submitted && errors.has('category') ? false : ''"
                :options="categories"
                label="flokkur_nafn"
                data-vv-as="tegund"
              >
              </v-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Umsjónarmaður námskeiðs" label-for="teacher">
              <v-select id="teacher" name="teacher" v-model="form.teacher" :clearable="true" :options="teachers" label="nafn"> </v-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Hámarksfjöldi"
              label-for="limit"
              :state="submitted && errors.has('limit') ? false : ''"
              :invalid-feedback="errors.first('limit')"
            >
              <b-form-input
                id="limit"
                name="limit"
                v-model="form.limit"
                type="number"
                min="0"
                v-validate="form.wait_list ? 'required' : ''"
                :state="submitted && (errors.has('category') || (form.wait_list && !form.limit)) ? false : ''"
                data-vv-as="hámark"
              />
            </b-form-group>
            <small class="text-danger" v-if="form.wait_list && !form.limit">
              Ef hakað er við biðlista verður að skilgreina hámarksfjölda.
            </small>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="3">
            <b-form-group
              label="Skráning hefst"
              label-for="registration_open"
              :state="submitted && (errors.has('registration_open') || !registrationDatesValid) ? false : ''"
              :invalid-feedback="errors.first('registration_open')"
            >
              <datepicker
                class="datepicker_100_width"
                id="registration_open"
                name="registration_open"
                v-model="form.registration_open"
                :language="lang"
                :monday-first="true"
                format="dd.MM.yyyy"
                :typeable="false"
                @selected="setRegistrationOpen"
                :state="submitted && !registrationDatesValid ? false : ''"
                data-vv-as="skráning hefst"
                ref="registrationOpenPickr"
              ></datepicker>
            </b-form-group>
            <small v-if="submitted && !registrationDatesValid" class="text-danger">Skráning verður að hefjast áður en henni lýkur.</small>
          </b-col>
          <b-col cols="3">
            <b-form-group
              label="Skráningu lýkur"
              label-for="registration_close"
              :state="
                submitted && (errors.has('registration_close') || !registrationDatesValid || !registrationBeforeClassValid) ? false : ''
              "
              :invalid-feedback="errors.first('registration_close')"
            >
              <datepicker
                class="datepicker_100_width"
                id="registration_close"
                name="registration_close"
                v-model="form.registration_close"
                :language="lang"
                :monday-first="true"
                format="dd.MM.yyyy"
                :typeable="false"
                @selected="setRegistrationClosed"
                :state="submitted && (!registrationDatesValid || !registrationBeforeClassValid) ? false : ''"
                data-vv-as="skráning hefst"
                ref="registrationClosePickr"
              ></datepicker>
            </b-form-group>
            <small v-if="submitted && !registrationDatesValid" class="text-danger">Skráning verður að hefjast áður en henni lýkur.</small>
            <small v-if="submitted && !registrationBeforeClassValid" class="text-danger">
              Skráningu verður að ljúka áður en námskeið hefst.
            </small>
          </b-col>
          <b-col cols="3">
            <b-form-group
              label="Námskeið hefst*"
              label-for="date_from"
              :state="submitted && (errors.has('date_from') || !classDatesValid) ? false : ''"
              :invalid-feedback="errors.first('date_from')"
            >
              <datepicker
                class="datepicker_100_width"
                id="date_from"
                name="date_from"
                v-model="form.date_from"
                :language="lang"
                :monday-first="true"
                format="dd.MM.yyyy"
                :typeable="false"
                @selected="setDateFrom"
                :state="submitted && !classDatesValid ? false : ''"
                v-validate="'required'"
                data-vv-as="skráning hefst"
                ref="dateFromOpenPickr"
              ></datepicker>
            </b-form-group>
            <small v-if="submitted && !classDatesValid" class="text-danger">Námskeið verður að hefjast áður en því lýkur.</small>
          </b-col>
          <b-col cols="3">
            <b-form-group
              label="Námskeiði lýkur*"
              label-for="date_to"
              :state="submitted && (errors.has('date_to') || !classDatesValid) ? false : ''"
              :invalid-feedback="errors.first('date_to')"
            >
              <datepicker
                class="datepicker_100_width"
                id="date_to"
                name="date_to"
                v-model="form.date_to"
                :language="lang"
                :monday-first="true"
                format="dd.MM.yyyy"
                :typeable="false"
                v-validate="'required'"
                :state="submitted && (errors.has('date_to') || !classDatesValid) ? false : ''"
                data-vv-as="skráning hefst"
                ref="dateToOpenPickr"
              ></datepicker>
            </b-form-group>
            <small v-if="submitted && !classDatesValid" class="text-danger">Námskeið verður að hefjast áður en því lýkur.</small>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="3">
            <b-form-group
              label="Staður"
              label-for="location"
              :state="submitted && errors.has('location') ? false : ''"
              :invalid-feedback="errors.first('location')"
            >
              <v-select
                id="location"
                name="location"
                v-model="form.location"
                :clearable="true"
                :state="submitted && errors.has('location') ? false : ''"
                :options="locations"
                label="heiti"
                data-vv-as="staður"
              >
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Staður námskeiðs*" label-for="location_text">
              <b-form-input id="location_text" name="location_text" v-model="form.location_text" />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              label="Verð (kr.)"
              label-for="price"
              :state="submitted && errors.has('price') ? false : ''"
              :invalid-feedback="errors.first('price')"
            >
              <b-form-input
                id="price"
                name="price"
                v-model="form.price"
                type="number"
                min="0"
                :state="submitted && errors.has('price') ? false : ''"
                data-vv-as="verð"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              label="Biðlisti"
              label-for="wait_list"
              :state="submitted && errors.has('wait_list') ? false : ''"
              :invalid-feedback="errors.first('wait_list')"
            >
              <b-form-checkbox id="wait_list" name="wait_list" v-model="form.wait_list" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Lýsing á námskeiði" label-for="description">
              <quill-editor
                id="description"
                name="description"
                v-model="form.description"
                :state="submitted && errors.has('description') ? false : ''"
                ref="textQuillEditor"
                :options="editorOption"
              >
              </quill-editor>
              <div class="text-danger" v-if="form.description.length > 4000">
                Ath. lýsing er of löng. Núverandi lýsing er: {{ form.description.length }} en hámarkið er 4000.
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Lýsing á skírteini námskeiðs" label-for="description_diploma">
              <quill-editor
                id="description_diploma"
                name="description_diploma"
                v-model="form.description_diploma"
                :state="submitted && errors.has('description_diploma') ? false : ''"
                ref="textQuillEditor"
                :options="editorOption"
              >
              </quill-editor>
              <div class="text-danger" v-if="form.description_diploma.length > 4000">
                Ath. lýsing er of löng. Núverandi lýsing er: {{ form.description_diploma.length }} en hámarkið er 4000.
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <br />
      <b-btn
        variant="primary"
        @click="validateBeforeSubmit()"
        v-if="canEdit"
        :disabled="form.description.length > 4000 || form.description_diploma.length > 4000"
      >
        Skrá námskeið
      </b-btn>
    </b-form>
    <category-slidebar v-if="showCategorySlidebar" @closeSlidebar="closeCategorySlidebar"> </category-slidebar>
  </page>
</template>

<script>
import singleClasses from '@/api/singleClasses';
import common from '@/api/common';
import staff from '@/api/staff';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import PageTitle from '@/components/common/PageTitle.vue';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';
import { quillEditor } from 'vue-quill-editor';
import he from 'he';

import CategorySlidebar from '@/components/curriculum/singleClasses/CategorySlidebar.vue';

export default {
  name: 'singleclass-create-class',
  components: {
    Breadcrumbs,
    PageTitle,
    Datepicker,
    quillEditor,
    CategorySlidebar,
  },
  computed: {
    registrationDatesValid() {
      // Annað hvort engin skráningartími eða skráning opnar á undan því sem hún lokar
      return (
        (!this.form.registration_open && !this.form.registration_close) ||
        moment(this.form.registration_open).isSameOrBefore(this.form.registration_close)
      );
    },
    // Skráningu verður að ljúka áður en námskeið hefst
    registrationBeforeClassValid() {
      // Annað hvort er enginn skráningartími eða skráning lokar áður en námskeiðið hefst
      return (
        !this.form.registration_close || (this.form.date_to && moment(this.form.registration_close).isSameOrBefore(this.form.date_from))
      );
    },
    classDatesValid() {
      // Bæði hefst og lýkur útfyllt og hefst á undan lýkur
      return this.form.date_from && this.form.date_to && moment(this.form.date_from).isSameOrBefore(this.form.date_to);
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_stok_namskeid');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      title: 'Stofna námskeið',
      breadcrumbs: [
        { name: 'Forsíða', route: 'Dashboard' },
        { name: 'Námskrá', route: '' },
        { name: 'Stök námskeið', route: 'SingleClasses' },
        { name: 'Stofna námskeið', route: 'SingleClassCreate' },
      ],
      form: {
        name: '',
        category: null,
        teacher: null,
        limit: '',
        registration_open: '',
        registration_close: '',
        date_from: '',
        date_to: '',
        location: null,
        location_text: '',
        price: '',
        description: '',
        description_diploma: '',
        wait_list: false,
      },
      submitted: false,
      lang: is,
      loading: {
        category: false,
        teacher: false,
        location: false,
      },
      editorOption: {
        /* quill options */
      },
      teachers: [],
      categories: [],
      locations: [],
      showCategorySlidebar: false,
    };
  },
  methods: {
    async loadOldClass(id) {
      try {
        const response = await singleClasses.getList({ id });
        await this.loadCategory();
        await this.loadStaff();
        await this.loadLocation();
        const old = response.data.items ? response.data.items[0] : '';
        this.form.name = he.decode(old.namskeid_nafn, { allowUnsafeSymbols: true });
        this.form.limit = old.hamarksfjoldi;
        this.form.location_text = old.starfsstod;
        this.form.price = old.verd;
        this.form.description = old.namskeid_lysing ? he.decode(old.namskeid_lysing, { allowUnsafeSymbols: true }) : '';
        this.form.description_diploma = old.lysing_skirteini ? he.decode(old.lysing_skirteini, { allowUnsafeSymbols: true }) : '';
        this.form.wait_list = old.bidlisti === 1;
        await this.loadCategory();
        this.form.category = this.categories.find(x => x.namskeid_flokkur_id === old.flokkur_id);
        await this.loadLocation();
        this.form.location = this.locations.find(x => x.postnumer === (old.stadsetning_id || 0).toString());
        await this.loadStaff();
        this.form.teacher = this.teachers.find(x => x.starfsm_id === old.starfsm_id);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadCategory() {
      try {
        this.loading.category = true;
        const response = await singleClasses.getCategories();
        this.categories = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.category = false;
      }
    },
    async loadStaff() {
      try {
        this.loading.teacher = true;
        const response = await staff.getStaffList();
        this.teachers = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.teacher = false;
      }
    },
    async loadLocation() {
      try {
        this.loading.location = true;
        const response = await common.getPostalCodes();
        this.locations = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.location = false;
      }
    },
    // registration_open change setDate(1)
    setRegistrationOpen(date) {
      if (!this.form.registration_close) this.form.registration_close = moment(date).toDate();
      if (!this.form.date_from) this.form.date_from = moment(this.form.registration_close).toDate();
      if (!this.form.date_to) this.form.date_to = moment(this.form.date_from).toDate();
    },
    // registration_close change setDate(3)
    setRegistrationClosed(date) {
      if (!this.form.date_from || (this.form.registration_open && this.form.date_from.getTime() === this.form.registration_open.getTime())) this.form.date_from = moment(date).toDate(); // eslint-disable-line
      if (!this.form.date_to || (this.form.registration_open && this.form.date_to.getTime() === this.form.registration_open.getTime())) this.form.date_to = moment(date).toDate(); // eslint-disable-line
    },
    // date_from change setDate(2)
    setDateFrom(date) {
      if (this.form.date_to && this.form.registration_close && this.form.date_to.getTime() === this.form.registration_close.getTime()) this.form.date_to = moment(date).toDate(); // eslint-disable-line
      if (!this.form.date_to) this.form.date_to = moment(date).toDate(); // eslint-disable-line
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response || !this.registrationDatesValid || !this.registrationBeforeClassValid || !this.classDatesValid)
            throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        try {
          const item = {
            date_from: moment(this.form.date_from).format('DD.MM.YYYY'),
            date_to: moment(this.form.date_to).format('DD.MM.YYYY'),
            date_open: this.form.registration_open ? moment(this.form.registration_open).format('DD.MM.YYYY') : '',
            date_close: this.form.registration_close ? moment(this.form.registration_close).format('DD.MM.YYYY') : '',
            location_id: this.form.location ? this.form.location.postnumer : '',
            location_place: this.form.location_text,
            category_id: this.form.category.namskeid_flokkur_id,
            class_name: he.encode(this.form.name, { allowUnsafeSymbols: true }),
            description: he.encode(this.form.description, { allowUnsafeSymbols: true }),
            description_diploma: he.encode(this.form.description_diploma, { allowUnsafeSymbols: true }),
            wait_list: this.form.wait_list ? 1 : 0,
            price: this.form.price ? this.form.price.toString().replace('.', ',') : '',
            max_number: this.form.limit,
            teacher: this.form.teacher ? this.form.teacher.starfsm_id : '',
          };
          const response = await singleClasses.createClass(item);
          this.$router.push({ name: 'SingleClassesClass', params: { id: response.data.id } });
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.saving = false;
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.saving = false;
      }
    },
    openCategorySlidebar() {
      this.showCategorySlidebar = true;
    },
    closeCategorySlidebar() {
      this.showCategorySlidebar = false;
      this.loadCategory();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    if (this.$route.query.old) {
      this.loadOldClass(this.$route.query.old);
    } else {
      this.loadCategory();
      this.loadLocation();
      this.loadStaff();
    }
  },
};
</script>
