<template>
  <page>
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <PageTitle title="Námsráðgjöf" />
    <PageMenu route="Counseling" :selectedPage="page" :items="menu" />
    <Interviews v-if="page === 'interviews'" />
    <Overview v-if="page === 'overview'" />
  </page>
</template>

<script>
import { mapGetters } from 'vuex';

import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import PageTitle from '@/components/common/PageTitle.vue';
import PageMenu from '@/components/common/PageMenu.vue';

import Interviews from '@/components/students/counseling/Interviews.vue';
import Overview from '@/components/students/counseling/Overview.vue';

export default {
  name: 'counseling',
  components: {
    Breadcrumbs,
    PageTitle,
    PageMenu,
    Interviews,
    Overview,
  },
  computed: {
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      page: 'interviews',
      breadcrumbs: [
        { name: 'Forsíða', route: 'Dashboard' },
        { name: 'Nemendahald', route: '' },
        { name: 'Námsráðgjöf', route: 'Counceling' },
      ],
      menu: [
        {
          name: 'Viðtöl',
          page: 'interviews',
        },
        {
          name: 'Yfirlit',
          page: 'overview',
        },
      ],
    };
  },
  methods: {},
  beforeRouteUpdate(to, from, next) {
    if (to && to.query && to.query.page) {
      this.page = to.query.page;
    }
    next();
  },
  created() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
  },
};
</script>
