<template>
  <Slidebar @closeMe="closeSlidebar" :large="true">
    <!--b-btn variant="dark" @click="closeSlidebar">Loka</b-btn-->
    <h4>Skoða viðtal</h4>
    <br />
    <table class="table table-hover no-border">
      <tbody>
        <tr>
          <th>Dagsetning</th>
          <td>{{ instance.counseling_date | moment('DD.MM.YYYY') }}</td>
        </tr>
        <tr>
          <th>Starfsmaður</th>
          <td>{{ instance.user_name }}</td>
        </tr>
        <tr>
          <th>Tegund viðtals</th>
          <td>{{ instance.counseling_category_name }}</td>
        </tr>
        <tr v-if="instance.counseling_group_name">
          <th>Hópur</th>
          <td>{{ instance.counseling_group_name }}</td>
        </tr>
        <tr>
          <th>Ástæða viðtals</th>
          <td>{{ instance.counseling_reason_name }}</td>
        </tr>
        <tr>
          <th>Staður</th>
          <td>{{ instance.counseling_location_name }}</td>
        </tr>
        <tr>
          <th>Staður viðtals</th>
          <td>{{ instance.counseling_location_text }}</td>
        </tr>
        <tr>
          <th>Niðurstaða</th>
          <td>
            <span v-for="(c, idx) in instance.conclusion" :key="idx">
              <span v-if="idx !== 0">,</span>
              {{ c.counseling_conclusion_name }}
            </span>
          </td>
        </tr>
        <tr>
          <th>Greitt af öðrum</th>
          <td v-if="instance.paid_by_others === 0">Nei</td>
          <td v-if="instance.paid_by_others === 1">Já</td>
        </tr>
      </tbody>
    </table>
    <div v-for="(p, idx) in instance.counselees" :key="idx">
      <h4>Einstaklingur #{{ idx + 1 }}</h4>
      <table class="table table-hover no-border">
        <tbody>
          <tr>
            <th>Kennitala</th>
            <td>{{ p.ssn }}</td>
          </tr>
          <tr>
            <th>Nafn</th>
            <td>{{ p.nafn }}</td>
          </tr>
          <tr>
            <th>Netfang</th>
            <td>{{ p.email }}</td>
          </tr>
          <tr>
            <th>Símanúmer</th>
            <td>{{ p.phone_number }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <b-btn variant="primary" class="mr-2" :to="{ name: 'CounselingInterview', params: { id: item.nsr_counseling_id } }" title="Opna viðtal"
      >Opna viðtal</b-btn
    >
    <b-btn
      variant="dark"
      class="mr-2"
      :to="{ name: 'CounselingCreateInterview', query: { old: item.nsr_counseling_id } }"
      title="Smelltu hér til þess að skrá nýtt viðtal með sömu upplýsingum"
      v-if="canEdit"
    >
      Skrá nýtt viðtal
    </b-btn>
    <b-btn variant="danger" title="Smelltu hér til þess að eyða viðtali" v-if="canEdit" @click="deleteInterview()">Eyða viðtali</b-btn>
  </Slidebar>
</template>

<script>
import counseling from '@/api/counseling';
import { mapActions, mapGetters } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';

export default {
  name: 'interview-slidebar',
  props: ['item'],
  components: {
    Slidebar,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('nemendahald_namsradgjof');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      instance: '',
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async loadData() {
      try {
        this.instance = '';
        this.loading = true;
        const response = await counseling.getItem(this.item.nsr_counseling_id);
        this.instance = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async deleteInterview() {
      try {
        const item = {
          id: this.item.nsr_counseling_id,
          deleted: 1,
        };
        const conf = confirm('Ertu viss um að þú viljir eyða viðtalinu ?'); // eslint-disable-line
        if (conf) {
          await counseling.createInterview(item);
          this.$emit('closeAndReloadSlidebar');
        }
      } catch (e) {
        // Aðgerð mistókst.
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    item() {
      this.loadData();
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<style lang="scss"></style>
