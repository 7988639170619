<template>
  <Slidebar :large="true" @closeMe="closeSlidebar">
    <h5>Færslur úr dagbók</h5>
    <i>
      Ath. eftirfarandi færslur fundust í dagbók fyrir töflubreytingar valins nemanda. Þetta eru eingöngu töflubreytingar sem framkvæmdar
      eru af starfsmönnum.
    </i>
    <div v-if="list.length === 0 && !loading" class="mt-4">Engar færslur fundust í dagbók</div>
    <table class="table table-hover less-padding no-border" style="font-size: 13px" v-if="list.length > 0">
      <thead>
        <tr>
          <th>Dags.</th>
          <th>Breytt af</th>
          <th>Aðgerð</th>
          <th>Svæði</th>
          <th>Atriði</th>
          <th>Lykill</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, idx) in list" :key="idx">
          <td style="white-space: nowrap">{{ item.dags | moment('DD.MM.YYYY HH:mm:ss') }}</td>
          <td style="white-space: nowrap">{{ item.nafn }}</td>
          <td style="white-space: nowrap">{{ item.adgerd_heiti }}</td>
          <td style="white-space: nowrap">{{ item.svaedi }}</td>
          <td>{{ item.atridi }}</td>
          <td>{{ item.lykill }}</td>
        </tr>
      </tbody>
    </table>
  </Slidebar>
</template>

<script>
import common from '@/api/common';
import { mapGetters, mapActions } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';

export default {
  name: 'rtudent-request-history',
  components: {
    Slidebar,
  },
  props: ['id'],
  computed: {
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      loading: false,
      list: [],
      showSlidebar: false,
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async loadList() {
      this.loading = true;
      try {
        const response = await common.getDiary('Töflubreytingar', { searchLike: `NemandiId=${this.id}` });

        this.list = response.data.items.map(x => ({
          ...x,
          svaedi: x.svaedi ? x.svaedi.replaceAll(',', ', ') : '',
          atridi: x.atridi ? x.atridi.replaceAll(',', ', ') : '',
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadList();
  },
};
</script>
<style lang="scss"></style>
