<template>
  <div>
    <v-select style="width:50%" v-model="selectedCourse" :clearable="false" :options="courses" class="mb-1"> </v-select>
    <StudyRecordCourse 
      v-if="selectedCourse && selectedCourse.braut_id"
      :studentId="id"
      :courseId="selectedCourse.braut_id"
      :item="selectedCourse"
      @reload="loadCourses(selectedCourse)"
      @reloadStudent="reloadStudent"
    ></StudyRecordCourse>
  </div>
</template>

<script>
import students from '@/api/students';
import StudyRecordCourse from '@/components/students/studyRecords/courses/StudyRecordCourse.vue';

export default {
  name: 'student-study-records-course-list',
  props: ['id'],
  components: {
    StudyRecordCourse,
  },
  data() {
    return {
      courses: [],
      loading: {
        courses: false,
      },
      selectedCourse: '',
    };
  },
  methods: {
    async loadCourses(course) {
      try {
        this.loading.courses = true;
        const response = await students.getStudentAllCourses(this.id);
        this.courses = response.data.items.map(x => ({
          ...x,
          label: `${x.heiti} - ${x.braut_kodi} - Dags: ${x.dags_byrjun ? x.dags_byrjun : ''} - ${x.dags_endar ? x.dags_endar : ''} `,
        }));
        if (course) {
          this.selectedCourse = this.courses.find(x => x.nemandi_brautir_id === course.nemandi_brautir_id);
        }
        if (!this.selectedCourse && this.courses.length > 0) {
          this.selectedCourse = this.courses.find(x => !x.dags_endar);
          if (!this.selectedCourse) {
            this.selectedCourse = this.courses[0];
          }
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.courses = false;
      }
    },
    reloadStudent() {
      this.$emit('reloadStudent');
    },
  },
  created() {
    this.loadCourses();
  },
};
</script>
<style></style>
