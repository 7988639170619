<template>
  <div>
    <div class="imgBox cursor-pointer" @click="openSlidebar()">
      <img v-if="src" :style="style" :src="`data:image/png;base64,${src}`" style="display: block; margin: 0 auto" />
      <div v-else class="no-img-filter">?</div>
    </div>

    <change-photo-slidebar
      v-if="showSlidebar"
      :userId="userId"
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
    >
    </change-photo-slidebar>
  </div>
</template>

<script>
import ChangePhotoSlidebar from '@/components/common/ChangePhotoSlidebar.vue';

export default {
  name: 'photo',
  props: ['userId', 'src', 'height', 'width'],
  components: {
    ChangePhotoSlidebar,
  },
  computed: {
    getSize() {
      if (this.height) {
        return `height: ${this.height}px; width: ${this.height}px;`;
      }
      if (this.width) {
        return `height: ${this.width}px; width: ${this.width}px;`;
      }
      return `height: 120px; width: 120px;`;
    },
    getHeight() {
      return this.height ? `${this.height}px` : '120px';
    },
    getWidth() {
      return this.width ? `${this.width}px` : '120px';
    },
    style() {
      if (this.getHeight) {
        return `height: ${this.getHeight};`;
      }
      return `width: ${this.getWidth};`;
    },
  },
  data() {
    return {
      showSlidebar: false,
    };
  },
  methods: {
    openSlidebar() {
      this.showSlidebar = true;
    },
    closeSlidebar() {
      this.showSlidebar = false;
    },
    closeAndReloadSlidebar() {
      this.closeSlidebar();
      this.$emit('reload');
    },
  },
};
</script>

<style lang="scss"></style>
