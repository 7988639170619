

<template>
    <div>
        <b-row>
            <b-col class="pl-0" :class="{ 'fitPage': tabIndex === 'eachweek' }">
                <StudentGrades v-if="tabIndex === 'grades'" :studentId="studentId"></StudentGrades>
                <StudentElementaryGrades v-if="tabIndex === 'elementaryGrades'" :studentId="studentId"
                    :studentSSn="studentSSn" :studentElementarySchool="studentElementarySchool">
                </StudentElementaryGrades>
                <StudentMidterms v-if="tabIndex === 'midtermGrades'" :studentId="studentId"></StudentMidterms>

            </b-col>
            <b-col cols="2">
                <div class="right-side-nav noprint">
                    <b-nav vertical>
                        <b-nav-item @click.prevent="changeTab('grades')" :active="tabIndex === 'grades'">
                            Einkunnir</b-nav-item>
                        <b-nav-item @click.prevent="changeTab('elementaryGrades')"
                            :active="tabIndex === 'elementaryGrades'"
                            v-if="!isPrimarySchool">
                            Grunnskólaeinkunnir</b-nav-item>
                        <b-nav-item @click.prevent="changeTab('midtermGrades')" :active="tabIndex === 'midtermGrades'">
                            Miðannarmat</b-nav-item>
                    </b-nav>
                </div>
            </b-col>
        </b-row>
    </div>
</template>
  
<script>
import { mapGetters, mapActions } from 'vuex';
import StudentGrades from '@/components/students/student/grades/StudentGrades.vue';
import StudentElementaryGrades from '@/components/students/student/grades/StudentElementaryGrades.vue';
import StudentMidterms from '@/components/students/student/grades/StudentMidtermgrades.vue';

export default {
    name: 'student-grades-details',
    props: ['studentId', 'studentSSn', 'studentElementarySchool'],
    components: {
        StudentGrades,
        StudentElementaryGrades,
        StudentMidterms,
    },
    computed: {
        isPrimarySchool() {
            return this.schoolSettings && this.schoolSettings.tegund_skola_id === 3;
        },
        ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
    },
    data() {
        return {
            tabIndex: 'grades',
            page: 'gradesDetails',
        };
    },
    methods: {
        refresh() {
            this.$emit('refresh');
        },
        changeTab(tab) {
            this.tabIndex = tab;
            this.$router.push({ name: 'Student', query: { page: this.page, tab: this.tabIndex } });
        },
        ...mapActions({
            displayError: 'displayError',
            displaySuccess: 'displaySuccess',
        }),
    },
    beforeRouteUpdate(to, from, next) {
        if (to && to.query && to.query.page) {
            this.page = to.query.page;
        }
        if (to && to.query && to.query.tab) {
            this.tabIndex = to.query.tab;
        }
        next();
    },
    created() {
        if (this.$route.query.page) {
            this.page = this.$route.query.page;
        }
        if (this.$route.query.tab) {
            this.tabIndex = this.$route.query.tab;
        }
    },

};
</script>
<style lang="scss">
.fitPage {
    width: 100%;
    overflow: auto;
}
</style>
  