<template>
  <Slidebar :autoClose="true" @closeMe="closeSlidebar" :large="large" :extraLarge="extraLarge">
    <h4>Samhliða</h4>
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <table class="table less-padding no-border">
        <tr>
          <td>
            <b-form-group :state="submitted && errors.has('module') ? false : ''" :invalid-feedback="errors.first('module')">
              <label label-for="module"> Áfangi <i class="fa fa-spin fa-spinner" v-if="loading"></i> </label>
              <v-select
                id="module"
                name="module"
                v-model="form.module"
                :clearable="false"
                :state="submitted && errors.has('module') ? false : ''"
                :options="list"
                data-vv-as="áfangi"
                v-validate="'required'"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
      </table>
      <b-btn variant="primary" @click="validateBeforeSubmit()">Skrá samhliða áfanga</b-btn>
    </b-form>
  </Slidebar>
</template>

<script>
import curriculum from '@/api/curriculum';
import Slidebar from '@/components/common/Slidebar.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'module-parallel-slidebar',
  components: {
    Slidebar,
  },
  props: ['id', 'divisionId', 'large', 'extraLarge'],
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_afangar');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('namskra_afangar');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      form: {
        module: '',
      },
      loading: false,
      list: [],
      submitted: false,
    };
  },
  methods: {
    async loadList() {
      try {
        this.loading = true;
        const response = await curriculum.getModuleList({ active: 1, division: this.divisionId });
        this.list = response.data.items.map(x => ({
          id: x.afangi_id,
          label: `${x.namsgrein_afanganr}`,
          name: x.afanga_heiti,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        try {
          await curriculum.setModuleParallel(this.id, { afangi_id: this.form.module.id });
          this.displaySuccess('Samhliða áfanga bætt við.');
          this.$emit('successful', {});
          this.closeSlidebar();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.saving = false;
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.saving = false;
      }
    },
    closeSlidebar(item) {
      this.$emit('closeSlidebar', item);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadList();
  },
};
</script>

<style lang="scss"></style>
