import { createModule, ADD_TOAST_MESSAGE } from 'vuex-toast';

import Vue from 'vue';
import Vuex from 'vuex';

import auth from '@/api/auth';

// Types
const AUTH_LOADING = 'AUTH_LOADING';
const ME_SUCCESS = 'ME_SUCCESS';
const ME_FAILED = 'ME_FAILED';

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production';

const INITIAL_STATE = {
  loggedInUser: null,
  collasedSidebar: false,
  showLevel2: false,
  level2Category: {},
};

const getters = {
  loggedInUserHasReadPermission: state => permission => {
    const { loggedInUser } = state;

    if (!loggedInUser || loggedInUser.permissions === null) {
      return false;
    }
    return !permission || loggedInUser.permissions.filter(p => p.key === permission && p.access > 0).length > 0;
  },
  loggedInUserHasWritePermission: state => permission => {
    const { loggedInUser } = state;

    if (!loggedInUser || loggedInUser.permissions === null) {
      return false;
    }
    return loggedInUser.permissions.filter(p => p.key === permission && p.access > 1).length > 0;
  },
  loggedInUserHasCategory: state => category => {
    const { loggedInUser } = state;

    if (!loggedInUser || loggedInUser.permissions === null) {
      return false;
    }
    return loggedInUser.permissions.filter(p => p.category === category && p.access > 0).length > 0;
  },
  schoolSettings: state => {
    const { loggedInUser } = state;

    if (!loggedInUser || !loggedInUser.skoli) {
      return false;
    }
    return loggedInUser.skoli;
  },
  schoolHasSettings: state => setting => {
    const { loggedInUser } = state;

    if (!loggedInUser || !loggedInUser.skoli) {
      return false;
    }
    return loggedInUser.skoli[setting] === 1;
  },
  loggedInUser: state => {
    const { loggedInUser } = state;

    return loggedInUser;
  },
  schoolWithGrades: state => {
    const { loggedInUser } = state;

    if (!loggedInUser || !loggedInUser.skoli) {
      return false;
    }

    return loggedInUser.skoli.afangaskoli === 0;
  },
};

const actions = {
  displaySuccess({ dispatch }, message) {
    dispatch(
      ADD_TOAST_MESSAGE,
      {
        text: message,
        type: 'success',
        dismissAfter: 2000,
      },
      {
        root: true,
      },
    );
  },
  displayError({ dispatch }, message) {
    dispatch(
      ADD_TOAST_MESSAGE,
      {
        text: message,
        type: 'danger',
        dismissAfter: 15000,
      },
      {
        root: true,
      },
    );
  },
  getLoggedInUser({ commit }) {
    return new Promise((resolve, reject) => {
      commit(AUTH_LOADING, true);
      auth
        .me()
        .then(response => {
          resolve(response.data);
          commit(ME_SUCCESS, response.data);
          commit(AUTH_LOADING, false);
        })
        .catch(error => {
          reject(error);
          commit(ME_FAILED);
          commit(AUTH_LOADING, false);
        });
    });
  },
};

const mutations = {
  toggleCollapsedSidebar(state) {
    state.collasedSidebar = !state.collasedSidebar;
  },
  openCategory(state, item) {
    state.showLevel2 = true;
    state.level2Category = item;
  },
  closeCategory(state) {
    state.showLevel2 = false;
    state.level2Category = {};
  },
  [ME_SUCCESS](s, data) {
    Vue.set(s, 'loggedInUser', data);
  },
  [ME_FAILED](s) {
    Vue.set(s, 'loggedInUser', null);
  },
  [AUTH_LOADING](s, authLoading) {
    Vue.set(s, 'authLoading', authLoading);
  },
};

const state = JSON.parse(JSON.stringify(INITIAL_STATE));

export default new Vuex.Store({
  state,
  actions,
  getters,
  mutations,
  modules: {
    toast: createModule({
      dismissInterval: 8000,
    }),
  },
  strict: debug,
});
