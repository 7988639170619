<template>
  <page>
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <b-row class="breadcumb-location">
      <b-col>
        <div class="float-right">
          <b-btn variant="dark" @click="openSearchSlidebar" class="ml-1"> <i class="fa fa-exchange" /> Skipta um nemanda </b-btn>
        </div>
        <div class="float-right">
          <b-link :to="{ name: 'Students', query: searchQuery }">
            <b-btn variant="dark" class="ml-1"> <i class="fa fa-arrow-left" /> Til baka í leit </b-btn>
          </b-link>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="pl-0">
        <div>
          <PageTitle :title="title" class="d-inline-block" style="font-size: 26px" />
          <b-row v-if="student" style="margin-top: -30px; margin-bottom: 20px; font-size: 13px">
            <b-col class="pl-0">
              <div>Nemandanr: {{ student.nemandanr }}</div>
              <div>Staða nema: {{ student.stada_nema_heiti }}</div>
              <div v-if="!student.adgangur"><strong>AÐGANGUR LOKAÐUR</strong></div>
            </b-col>
            <b-col class="pl-0">
              <div>Undirskóli: {{ student.undirsk_heiti }}</div>
              <div>
                <div class="d-inline-block align-top">Braut:</div>
                <div class="d-inline-block pl-1">
                  <div v-for="b in student.braut" :key="b.braut_id">
                    {{ b.braut }} {{ b.braut_kodi }}
                    <span v-if="b.kjorgreinar" style="font-size: 12px" title="Kjörgreinar" v-b-tooltip>({{ b.kjorgreinar }}) </span>
                    <b-link
                      :to="{ name: 'Course', params: { id: b.braut_id } }"
                      target="_blank"
                      title="Skoða braut"
                      v-b-tooltip
                      v-if="canReadCourse"
                    >
                      <i class="fa fa-external-link"></i>
                    </b-link>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col class="pl-0">
              <div>Umsjónarkennari: {{ student.umsjonarkennari }}</div>
              <div v-if="!afangaskoli">Ár/önn: {{ student.nu_onn }}</div>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <PageMenu route="Student" :selectedPage="page" :items="menu" />
    <StudentDetails
      v-if="page === 'details' && student && !loading.student"
      :item="student"
      :id="id"
      :studentSsn="student.kennitala"
      @refresh="loadStudent"
    />
    <Timetable v-if="page === 'timetable' && student" :id="id" type="student"></Timetable>
    <StudentAssessmentsDetails v-if="page === 'assessment'" :studentId="id"></StudentAssessmentsDetails>
    <StudentAttendanceDetails v-if="page === 'attendance' && student" :studentId="id"></StudentAttendanceDetails>
    <StudentStudyRecords v-if="page === 'studyRecords' && student" :id="id" :studentActive="student.virkur" @refresh="loadStudent">
    </StudentStudyRecords>
    <StudentGradesDetails
      v-if="page === 'grades' && student"
      :studentId="id"
      :studentSSn="student.kennitala"
      :studentElementarySchool="student.grunnskoli"
    >
    </StudentGradesDetails>

    <student-search-slidebar
      @closeSlidebar="closeSearchSlidebar"
      :searchQuery="searchQuery"
      :advancedSearch="true"
      v-if="showSearchSlidebar"
      :large="true"
    >
    </student-search-slidebar>
  </page>
</template>

<script>
import students from '@/api/students';

import { mapActions, mapGetters } from 'vuex';

import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import PageTitle from '@/components/common/PageTitle.vue';
import PageMenu from '@/components/common/PageMenu.vue';

import Timetable from '@/components/common/Timetable.vue';
import StudentDetails from '@/components/students/student/details/StudentDetails.vue';
import StudentAttendanceDetails from '@/components/students/attendance/StudentAttendanceDetails.vue';
import StudentSearchSlidebar from '@/components/students/student/StudentSearchSlidebar.vue';
import StudentStudyRecords from '@/components/students/studyRecords/StudentStudyRecords.vue';
import StudentGradesDetails from '@/components/students/student/grades/StudentGradesDetails.vue';

// import StudentAttendance from '@/components/students/attendance/StudentAttendance.vue';

export default {
  name: 'student',
  components: {
    Breadcrumbs,
    PageTitle,
    PageMenu,
    Timetable,
    StudentDetails,
    StudentAttendanceDetails,
    StudentSearchSlidebar,
    StudentStudyRecords,
    StudentGradesDetails,
  },
  computed: {
    canRead() {
      return this.loggedInUserHasReadPermission('nemendahald_nemendur');
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('nemendahald_nemendur');
    },
    afangaskoli() {
      return this.schoolSettings && this.schoolSettings.afangaskoli ? this.schoolSettings.afangaskoli === 1 : false;
    },
    canReadCourse() {
      return this.loggedInUserHasReadPermission('namskra_brautir');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolHasSettings', 'schoolSettings']),
  },
  data() {
    return {
      id: '',
      breadcrumbs: [
        { name: 'Forsíða', route: 'Dashboard' },
        { name: 'Nemendahald', route: '' },
        { name: 'Hópar', route: 'Groups' },
      ],
      menu: [
        {
          name: 'Nemandinn',
          page: 'details',
        },
        {
          name: 'Námsferill',
          page: 'studyRecords',
        },
        {
          name: 'Einkunnir',
          page: 'grades',
        },
        {
          name: 'Viðvera',
          page: 'attendance',
        },
        {
          name: 'Stundaskrá',
          page: 'timetable',
        },
      ],
      page: 'details',
      loading: {
        student: false,
      },
      student: '',
      title: '',
      showSearchSlidebar: false,
      searchQuery: '',
    };
  },
  methods: {
    async loadStudent() {
      try {
        this.loading.student = true;
        const response = await students.getStudentInfo(this.id);
        this.student = { ...response.data, braut: response.data.braut ? JSON.parse(response.data.braut) : [] };
        this.breadcrumbs = [
          { name: 'Forsíða', route: 'Dashboard' },
          { name: 'Nemendahald', route: '' },
          { name: 'Nemendur', route: 'Students' },
          { name: `${this.student.nafn} - ${this.student.kennitala}`, route: '' },
        ];
        const bekkur = !this.afangaskoli && this.student.bekkur ? ` ${this.student.bekkur}` : '';
        this.title = `${this.student.nafn}${bekkur} - ${this.student.kennitala}`;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.student = false;
      }
    },
    openSearchSlidebar() {
      this.showSearchSlidebar = true;
    },
    closeSearchSlidebar(item, advancedSearch) {
      if (item) {
        let query = {};
        if (this.$route.query.page) {
          query.page = this.$route.query.page;
        }
        if (item && item.searchQuery) {
          query.search = item.searchQuery;
        }
        // ef komið frá nemandaspjaldi og verið að skipta um nemanda
        // þá viljum við halda inni leitinni í urlinu
        if (advancedSearch && this.$route.query) {
          query = this.$route.query;
        }
        this.$router.push({ name: 'Student', params: { id: item }, query });
        this.$router.go();
      }
      this.showSearchSlidebar = false;
      this.loadStudent();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  beforeRouteUpdate(to, from, next) {
    if (to && to.query && to.query.page) {
      this.page = to.query.page;
    }
    next();
  },
  created() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
    // var this.$route.query.search
    if (this.$route.query) {
      this.searchQuery = this.$route.query;
    }
    this.id = this.$route.params.id;
    this.loadStudent();
  },
};
</script>
