<template>
  <Slidebar @closeMe="closeSlidebar" :large="true">
    <div class="d-inline-block mt-2">
      <h4>Magnbreyta brautum</h4>
      <b-form-group label="Virkur" label-for="virkur" class="mb-1">
        <v-select id="virkur" name="virkur" v-model="form.gildi" :options="yesNo" :clearable="false"> </v-select>
      </b-form-group>
      <b-btn variant="primary" class="mr-2" v-if="canEdit" @click="validateBeforeSubmit"> Breyta </b-btn>
      <div class="mt-3">
        <strong>Færslur sem verið er að breyta</strong>
      </div>
      <div v-for="item in items" :key="item.braut_id">{{ item.heiti }} {{ item.braut_kodi }}</div>
    </div>
  </Slidebar>
</template>

<script>
import curriculum from '@/api/curriculum';

import { mapActions, mapGetters } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';

export default {
  name: 'course-mass-edit-slidebar',
  props: ['items'],
  components: {
    Slidebar,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_brautir');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {
      saving: false,
      form: {
        gildi: { id: 1, label: 'Já' },
      },
      yesNo: [
        { id: 1, label: 'Já' },
        { id: 0, label: 'Nei' },
      ],
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async validateBeforeSubmit() {
      try {
        this.saving = true;
        const item = {
          courses: this.items.map(x => x.braut_id),
          gildi: this.form.gildi.id,
        };
        await curriculum.updateCourses(item);

        this.displaySuccess('Brautum breytt');
        this.closeSlidebar();
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.saving = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>

<style lang="scss"></style>
