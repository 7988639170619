<template>
  <div>
    <h5 class="student-title mt-3">
      Námsbrautir
      <b-link @click="openEditCourseSlidebar()" v-if="canEdit"
        ><i class="fa fa-plus-circle float-right" v-b-tooltip.hover title="Skrá nemanda á braut"></i
      ></b-link>
    </h5>
    <table class="table less-padding no-border mb-2 table-bordered-header" style="font-size: 13px">
      <thead>
        <tr>
          <th>Braut</th>
          <th>Undirskóli</th>
          <th>Dags. byrjar</th>
          <th>Dags. endar</th>
          <th>Ástæða <i class="fa fa-info-circle" title="Ástæða breytinga" v-b-tooltip></i></th>
          <th>Lokið <i class="fa fa-info-circle" title="Er braut lokið" v-b-tooltip></i></th>
          <th>Sjálfvirkt <i class="fa fa-info-circle" title="Velja sjálfvirkt á nemanda" v-b-tooltip></i></th>
          <th>Ein. braut <i class="fa fa-info-circle" title="Einingar á braut" v-b-tooltip></i></th>
          <th>Ein. skírt. <i class="fa fa-info-circle" title="Einingar á skírteini" v-b-tooltip></i></th>
          <th>- á þrepum <i class="fa fa-info-circle" title="Einingar á skírteini á þrepum" v-b-tooltip></i></th>
          <th>Uppb. <i class="fa fa-info-circle" title="Skoða uppbyggingu" v-b-tooltip></i></th>
          <th></th>
        </tr>
      </thead>
      <TableRowLoader v-if="loading.courses" colspan="11" :center="true" />
      <TableRowEmpty v-if="!loading.courses && this.courses.length === 0" colspan="11" :center="true" />
      <tbody v-for="course in courses" :key="course.nemandi_brautir_id">
        <tr>
          <td>
            <i
              :class="{
                'fa fa-angle-down cursor-pointer': !course.showCourseFields,
                'fa fa-angle-up cursor-pointer': course.showCourseFields,
              }"
              style="color: #98139c"
              @click="toggleShow(course)"
              title="Sjá kjörgreinar"
              v-b-tooltip
              aria-hidden="true"
            ></i>

            {{ course.braut_kodi }} {{ course.heiti }}
            <b-link
              :to="{ name: 'Course', params: { id: course.braut_id } }"
              target="_blank"
              title="Skoða braut"
              v-b-tooltip
              v-if="canReadCourse"
            >
              <i class="fa fa-external-link"></i>
            </b-link>
          </td>
          <td>{{ course.undirsk_heiti }}</td>
          <td>{{ course.dags_byrjun }}</td>
          <td>{{ course.dags_endar }}</td>
          <td>{{ course.astaeda_breytinga }}</td>
          <td><span v-if="course.braut_lokid === 1">Já</span><span v-else>Nei</span></td>
          <td><span v-if="course.sjalfvirkt_val === 1">Já</span><span v-else>Nei</span></td>
          <td>{{ course.einingar_alls }}</td>
          <td>{{ course.ein_skirteini }}</td>
          <td>
            {{ course.ein_skirteini_trep }}<span v-if="course.ein_skirteini_trep && course.ein_skirteini_trep_olokid"> + </span>
            {{ course.ein_skirteini_trep_olokid }}
          </td>
          <td class="text-center">
            <i
              class="fa fa-search text-primary cursor-pointer pr-2"
              title="Skoða uppbyggingu"
              v-b-tooltip
              @click="openStudyRecordSlidebar(course)"
            ></i>
          </td>
          <td>
            <i
              class="fa fa-pencil text-primary cursor-pointer"
              title="Breyta skráningu á braut"
              v-b-tooltip
              @click="openEditCourseSlidebar(course)"
            ></i>
          </td>
        </tr>
        <tr v-if="course.showCourseFields">
          <td colspan="80%" style="padding: 20px 30px !important; background-color: #f9f9f9">
            <h5>Kjörgreinar</h5>

            <table class="table less-padding">
              <thead style="text-transform: uppercase; font-size: 12px">
                <tr>
                  <th>
                    Kóði brautar <span v-if="!course.loadingCourseFields">({{ course.fields.length }})</span>
                  </th>
                  <th>Braut</th>
                  <th>Námsgrein</th>
                  <th>Kóði námsgreinar</th>
                  <th>Röð</th>
                  <th>
                    <i
                      class="fa fa-plus-circle float-right text-primary cursor-pointer"
                      v-b-tooltip.hover
                      title="Skrá nýja kjörgrein"
                      @click="openEditCourseFieldSlidebar(courses)"
                    ></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                <TableRowLoader v-if="course.loadingCourseFields" colspan="11" :center="true" />
                <TableRowEmpty v-if="!course.loadingCourseFields && course.fields.length === 0" colspan="11" :center="true" />
                <tr v-for="field in course.fields" :key="field.nemandi_kjorsvid_id">
                  <td>{{ field.braut_kodi }}</td>
                  <td>{{ field.braut_heiti }}</td>
                  <td>{{ field.namsgrein }}</td>
                  <td>{{ field.namsgrein_kodi }}</td>
                  <td>{{ field.rod }}</td>
                  <td>
                    <i
                      class="fa fa-pencil text-primary cursor-pointer"
                      title="Breyta kjörgrein"
                      v-b-tooltip
                      @click="openEditCourseFieldSlidebar(courses, field)"
                    ></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
    <div style="font-size: 13px; padding-left: 4px" class="mb-3" v-if="courses.length > 0">
      <div class="d-inline-block mr-4">
        Fjöldi eininga á námsferli: <strong>{{ ein }}</strong>
      </div>
      <div class="d-inline-block">
        Fjöldi eininga á þrepum (f-einingar): <strong>{{ einTrep }}</strong>
      </div>
    </div>
    <study-record-course-slidebar
      v-if="showStudyRecordSlidebar"
      @closeSlidebar="closeSlidebar"
      :studentId="id"
      :courseId="selectedItem && selectedItem.braut_id"
      :course="selectedItem"
    ></study-record-course-slidebar>
    <student-edit-course-slidebar
      v-if="showEditCourseSlidebar"
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
      :studentId="id"
      :item="selectedItem"
      :divisionId="item && item.undirskoli_id"
    ></student-edit-course-slidebar>

    <student-edit-course-fields-slidebar
      v-if="showEditFieldSlidebar"
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
      :course="selectedItem"
      :studentId="id"
      :field="selectedField"
    ></student-edit-course-fields-slidebar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import students from '@/api/students';

import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import TableRowLoader from '@/components/common/TableRowLoader.vue';

import StudyRecordCourseSlidebar from '@/components/students/studyRecords/courses/StudyRecordCourseSlidebar.vue';
import StudentEditCourseSlidebar from '@/components/students/student/course/StudentEditCourseSlidebar.vue';
import StudentEditCourseFieldsSlidebar from '@/components/students/student/course/StudentEditCourseFieldsSlidebar.vue';

export default {
  name: 'student-course-list',
  props: ['id', 'item'],
  components: {
    TableRowEmpty,
    TableRowLoader,
    StudyRecordCourseSlidebar,
    StudentEditCourseSlidebar,
    StudentEditCourseFieldsSlidebar,
  },
  computed: {
    ein() {
      return this.courses.length > 0 ? this.courses[0].ein : '';
    },
    einTrep() {
      return this.courses.length > 0 ? this.courses[0].ein_trep : '';
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('nemendahald_nemendur');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('nemendahald_nemendur');
    },
    canReadCourse() {
      return this.loggedInUserHasReadPermission('namskra_brautir');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      showStudyRecordSlidebar: false,
      showEditCourseSlidebar: false,
      showEditFieldSlidebar: false,
      selectedItem: '',
      selectedField: '',
      loading: {
        courses: false,
      },
      courses: [],
    };
  },
  methods: {
    async loadCourses() {
      try {
        this.loading.courses = true;
        const response = await students.getStudentAllCourses(this.id);
        this.courses = response.data.items.map(x => ({
          ...x,
          showCourseFields: false,
          loadingCourseFields: false,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.courses = false;
      }
    },
    /* eslint-disable no-param-reassign */
    async loadCoursesFields(item) {
      try {
        const params = {
          nbId: item.nemandi_brautir_id,
        };
        item.loadingCourseFields = true;
        const response = await students.getStudentCoursesFields(this.id, params);
        item.fields = response.data.items.map(x => ({
          ...x,
          edit: false,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa að sækja kjörsvið. Vinsamlegast reyndu aftur.`);
      } finally {
        item.loadingCourseFields = false;
      }
    },
    toggleShow(item) {
      item.showCourseFields = !item.showCourseFields;
      if (item.showCourseFields) {
        this.loadCoursesFields(item);
      }
    },
    openStudyRecordSlidebar(item) {
      this.showStudyRecordSlidebar = true;
      this.showEditCourseSlidebar = false;
      this.showEditFieldSlidebar = false;
      this.selectedItem = item;
    },
    openEditCourseSlidebar(item) {
      this.showEditCourseSlidebar = true;
      this.showStudyRecordSlidebar = false;
      this.showEditFieldSlidebar = false;
      this.selectedItem = item;
    },
    openEditCourseFieldSlidebar(item, field) {
      this.showEditFieldSlidebar = true;
      this.showEditCourseSlidebar = false;
      this.showStudyRecordSlidebar = false;
      this.selectedItem = item;
      this.selectedField = field;
    },
    closeSlidebar() {
      this.showStudyRecordSlidebar = false;
      this.showEditCourseSlidebar = false;
      this.showEditFieldSlidebar = false;
      this.selectedItem = '';
      this.selectedField = '';
    },
    closeAndReloadSlidebar() {
      this.closeSlidebar();
      this.loadCourses();
      this.$emit('refresh');
    },
  },
  created() {
    this.loadCourses();
  },
};
</script>
<style lang="scss"></style>
