<template>
  <Slidebar @closeMe="closeSlidebar" :large="large" :extraLarge="extraLarge">
    <h4>Magnbreyta</h4>

    <div class="mb-2">1. Hakaðu við hverju þú vilt breyta.</div>
    <div class="d-inline-block pr-3">
      <div>Flokkur</div>
      <b-form-checkbox v-model="edit.flokkur"></b-form-checkbox>
    </div>
    <div class="d-inline-block pr-3">
      <div>Virkur</div>
      <b-form-checkbox v-model="edit.virkur"></b-form-checkbox>
    </div>
    <br />
    <div class="mb-2 mt-2" v-if="isEditing">2. Veldu rétt gildi</div>
    <b-form-group v-if="edit.flokkur" class="mb-1">
      <label for="flokkur">
        Flokkur
        <i @click="openTypeSlidebar()" class="cursor-pointer fa fa-plus-circle text-primary" title="Bæta við flokk" v-if="canEdit"></i>
      </label>
      <v-select
        id="flokkur"
        name="flokkur"
        v-model="form.flokkur"
        :state="submitted && errors.has('flokkur') ? false : ''"
        :options="categories"
        label="heiti"
      >
      </v-select>
    </b-form-group>
    <b-form-group label="Virkur" label-for="virkur" v-if="edit.virkur" class="mb-1">
      <b-form-checkbox id="virkur" name="virkur" v-model="form.virkur"> </b-form-checkbox>
    </b-form-group>
    <br />
    <b-btn variant="primary" class="mr-2" v-if="canEdit" @click="massEdit()" :disabled="!isEditing || saving">
      <i class="fa fa-spin fa-spinner" v-if="saving"></i>
      Breyta
    </b-btn>
    <b-btn variant="dark" @click="closeSlidebar" :disabled="saving">Hætta við</b-btn>
    <b-btn variant="danger" class="float-right" :disabled="saving" v-if="canEdit" @click="deleteBooks()"> Eyða </b-btn>

    <div class="mt-2" style="font-size: 110%">Námsgögn sem verið er að breyta ({{ items.length }})</div>
    <div v-for="item in items" :key="item.bok_id" style="font-size: 13px">
      {{ item.heiti }}
    </div>
    <book-category-type-slidebar v-if="showTypeSlidebar" @closeSlidebar="closeTypeSlidebar"> </book-category-type-slidebar>
  </Slidebar>
</template>

<script>
import types from '@/api/types';
import { mapActions, mapGetters } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';
import BookCategoryTypeSlidebar from '@/components/school/school/types/BookCategoryTypeSlidebar.vue';

export default {
  name: 'book-type-mass-slidebar',
  props: ['items', 'large', 'extraLarge'],
  components: {
    Slidebar,
    BookCategoryTypeSlidebar,
  },
  computed: {
    isEditing() {
      return Object.values(this.edit).filter(x => x === true).length > 0;
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      saving: false,
      loading: false,
      categories: [],
      submitted: false,
      form: {
        flokkur: null,
        virkur: true,
      },
      edit: {
        virkur: false,
        flokkur: false,
      },
      showTypeSlidebar: false,
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    openTypeSlidebar() {
      this.showTypeSlidebar = true;
    },
    closeTypeSlidebar() {
      this.showTypeSlidebar = false;
      this.loadCategories();
    },
    async loadCategories() {
      try {
        this.loading = true;
        const response = await types.getBookCategoryList();
        this.categories = response.data.items;
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async massEdit() {
      try {
        this.saving = true;
        const promises = [];
        this.items.forEach(item => {
          const data = {
            isbn: item.isbn,
            heiti: item.heiti,
            hofundur: item.hofundur,
            utgafa: item.utgafa,
            utgefandi: item.utgefandi,
            lysing: item.lysing,
            flokkur_id: this.edit.flokkur ? (this.form.flokkur ? this.form.flokkur.flokkur_id : '') : item.flokkur_id, // eslint-disable-line
            virkur: this.edit.virkur ? (this.form.virkur ? 1 : 0) : item.virkur, // eslint-disable-line
          };
          promises.push(types.updateBook(item.bok_id, data));
        });
        Promise.all(promises)
          .then(() => {
            this.displaySuccess('Námsgögnum hefur verið breytt.');
            this.saving = false;
            this.$emit('closeAndReloadSlidebar');
          })
          .catch(e => {
            this.saving = false;
            if (e.response && e.response.data && e.response.data.error) {
              this.displayError(e.response.data.error);
            } else {
              this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            }
          });
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        if (e.response && e.response.data && e.response.data.error) {
          this.displayError(e.response.data.error);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    async deleteBooks() {
      const conf = confirm(`Ert þú viss um að eyða eftirfarandi námgögnum ? Ath. eingöngu verður eytt þeim námsgögnum sem hafa ekki verið notuð`); // eslint-disable-line
      if (conf) {
        try {
          this.saving = true;
          const promises = [];
          this.items
            .filter(x => !x.max_onn_eink)
            .forEach(item => {
              promises.push(types.updateBook(item.bok_id, { delete: 1 }));
            });
          Promise.all(promises)
            .then(() => {
              this.displaySuccess('Námsgögnum hefur verið eytt.');
              this.saving = false;
              this.$emit('closeAndReloadSlidebar');
            })
            .catch(e => {
              this.saving = false;
              if (e.response && e.response.data && e.response.data.error) {
                this.displayError(e.response.data.error);
              } else {
                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
              }
            });
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadCategories();
  },
};
</script>

<style lang="scss"></style>
