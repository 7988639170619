<template>
  <tbody style="border-width: 1px">
    <tr>
      <td @click="toggleShowDetails()" class="cursor-pointer">{{ onn.onn_eink }}</td>
      <td v-for="fjar in onn.fjarvistir" :key="`${onn.onn_id}-${fjar.teg_fjarvista}`">
        {{ fjar.fj > 0 ? fjar.fj : '' }}
      </td>
      <td v-for="forf in onn.forfoll" :key="`${onn.onn_id}-${forf.teg_forfalla}`">
        {{ forf.fj > 0 ? forf.fj : '' }}
      </td>
      <td>{{ onn.fjarvistarstig_ofl.length > 0 ? onn.fjarvistarstig_ofl[0].fj_fjarv_forfoll_id : 0 }}</td>
      <td>{{ onn.fjarvistarstig_ofl.length > 0 ? onn.fjarvistarstig_ofl[0].fjarvistar_stig : '' }}</td>
      <td v-if="schoolSettings.maetingarhlutfall_stig === 1" :class="getAttendanceColor(onn.maeting.length > 0 && onn.maeting[0].maeting)">
        {{ onn.maeting.length > 0 && onn.maeting[0].maeting ? `${onn.maeting[0].maeting}%` : '' }}
      </td>
      <td
        v-if="schoolSettings.maetingarhlutfall_raun === 1"
        :class="getAttendanceColor(onn.maeting.length > 0 && onn.maeting[0].maeting_raun)"
      >
        {{ onn.maeting.length > 0 && onn.maeting[0].maeting_raun ? `${onn.maeting[0].maeting_raun}%` : '' }}
      </td>
      <td
        v-if="schoolSettings.maetingarhlutfall_onn === 1"
        :class="getAttendanceColor(onn.maeting.length > 0 && onn.maeting[0].maeting_onn)"
      >
        {{ onn.maeting.length > 0 && onn.maeting[0].maeting_onn ? `${onn.maeting[0].maeting_onn}%` : '' }}
      </td>
      <td
        v-if="schoolSettings.maetingarhlutfall_einkunn === 1"
        :class="getAttendanceColor(onn.maeting.length > 0 && onn.maeting[0].maeting_einkunn)"
      >
        {{ onn.maeting.length > 0 && onn.maeting[0].maeting_einkunn ? `${onn.maeting[0].maeting_einkunn}%` : '' }}
      </td>
    </tr>
    <tr v-if="showDetails" style="background-color: #fff">
      <td colspan="100%" style="font-size: 12px">
        <Loader v-if="loading"></Loader>
        <AttendanceModules :attendance="attendance"></AttendanceModules>
      </td>
    </tr>
  </tbody>
</template>

<script>
import students from '@/api/students';
import { mapActions, mapGetters } from 'vuex';
import Loader from '@/components/common/Loader.vue';
import AttendanceModules from '@/components/students/attendance/AttendanceModules.vue';

export default {
  name: 'attendance-term',
  props: ['studentId', 'onn'],
  components: {
    Loader,
    AttendanceModules,
  },
  computed: {
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {
      showDetails: false,
      attendance: '',
      loading: false,
    };
  },
  methods: {
    async getAttendance() {
      try {
        this.loading = true;
        const response = await students.getStudentAttendanceByTerm(this.studentId, this.onn.onn_id);
        if (response.data.items.length > 0) {
          this.attendance = response.data.items[0];
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    toggleShowDetails() {
      this.showDetails = !this.showDetails;
      if (this.showDetails) {
        this.getAttendance();
      }
    },
    getAttendanceColor(item) {
      if (item < 80) {
        return 'attendancePercentageUnder80';
      }
      if (item >= 80 && item < 90) {
        return 'attendancePercentageUnder90';
      }
      return '';
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
<style></style>
