<template>
  <div>
    <b-row style="margin-top: -10px; margin-bottom: 10px">
      <b-col class="pr-0">
        <div class="float-right">
          <b-btn variant="primary" size="sm" class="mr-3" v-if="item.can_edit_password !== 1 && canEdit" 
            @click="openOpenPasswordAccessSlidebar()">
            Opna lykilorðsaðgang
          </b-btn>
          <b-btn variant="primary" size="sm" class="mr-3" v-if="item.can_edit_password === 1 && canEdit" @click="openPasswordSlidebar()">
            Breyta lykilorði
          </b-btn>

          <!--b-btn variant="primary" size="sm" class="mr-3">Staðfesting á skólavist</b-btn-->
          <b-btn variant="primary" size="sm" v-if="canEdit" @click="openActivationSlidebar()">
            <span v-if="item.virkur === 1">Óvirkja/úrsögn</span>
            <span v-else>Virkja/endurinnrita</span>
          </b-btn>
        </div>
      </b-col>
    </b-row>
    <h5 class="student-title">
      Grunnupplýsingar
      <b-link @click="openEditSlidebar()" v-if="canEdit"><i class="fa fa-pencil" v-b-tooltip.hover title="Gera breytingar"></i></b-link>
    </h5>
    <b-row class="student-settings-border">
      <b-col class="pl-0">
        <div>
          <label>Nemandi</label>
          <div class="text">
            <span
              >{{ item.nafn }}
              <i
                class="fa fa-envelope cursor-pointer text-primary"
                title="Senda skilaboð"
                v-b-tooltip
                @click.prevent="openSendMessageSlidebar('student')"
              ></i
            ></span>
          </div>
        </div>
        <div>
          <label>Kennitala</label>
          <div class="text">
            <span
              >{{ item.kennitala }}
              <i
                class="fa fa-refresh text-primary cursor-pointer pl-1"
                title="Uppfæra kennitölu"
                @click="updateSsnSlidebar()"
                v-b-tooltip
                v-if="canEdit && item.kennitala.startsWith('0000')"
              ></i>
            </span>
          </div>
        </div>
        <div>
          <label>Nemandanr</label>
          <div class="text">
            <span>{{ item.nemandanr }}</span>
          </div>
        </div>
        <div>
          <label>Netfang</label>
          <div class="text">
            <span>{{ item.netfang }}</span>
          </div>
        </div>
        <div>
          <label>Einkanetfang</label>
          <div class="text">
            <span>{{ item.einka_netfang }}</span>
          </div>
        </div>
      </b-col>
      <b-col class="pl-0">
        <div>
          <label>Virkur</label>
          <div class="text">
            <span><yes-no :item="item.virkur"></yes-no></span>
          </div>
        </div>
        <div>
          <label>Aldur</label>
          <div class="text">
            <span>{{ item.aldur }}</span>
          </div>
        </div>
        <div>
          <label>Sími lögheimili</label>
          <div class="text">
            <span>{{ item.simi_lh }}</span>
          </div>
        </div>
        <div>
          <label>Sími aðsetur</label>
          <div class="text">
            <span>{{ item.simi_as }}</span>
          </div>
        </div>
        <div>
          <label>Farsími</label>
          <div class="text">
            <span>{{ item.farsimi }}</span>
          </div>
        </div>
        <div v-if="heimavist">
          <label>Heimavist</label>
          <div class="text">
            <span><yes-no :item="item.heimavist"></yes-no></span>
          </div>
        </div>
      </b-col>
      <b-col class="pl-0">
        <div>
          <label>Staða nema</label>
          <div class="text">
            <span>{{ item.stada_nema_heiti }}</span>
          </div>
        </div>
        <div>
          <label>Vinnslustaða</label>
          <div class="text">
            <span>{{ item.vinnslustada_heiti }}</span>
          </div>
        </div>
        <div v-if="!afangaskoli">
          <label>Bekkur</label>
          <div class="text">
            <span>{{ item.bekkur }}</span>
          </div>
        </div>
        <div v-if="!isLearningCenter && !afangaskoli">
          <label>Ár nemanda</label>
          <div class="text">
            <span>{{ item.nu_onn }}</span>
          </div>
        </div>
        <div v-if="!isLearningCenter">
          <label>Umsjónarkennari</label>
          <div class="text">
            <span
              >{{ item.umsjonarkennari }}
              <i
                class="fa fa-search text-primary cursor-pointer"
                title="Skoða umsjónarkennara"
                v-b-tooltip
                @click="openSupervisorSlidebar()"
              ></i>

              <i
                class="fa fa-envelope cursor-pointer text-primary ml-2"
                title="Senda skilaboð"
                v-b-tooltip
                v-if="item.umsjonarkennari"
                @click.prevent="openSendMessageSlidebar('supervisor')"
              ></i>
            </span>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="viewDetails" class="student-settings-border">
      <b-col class="pl-0">
        <div>
          <label>Lögheimili</label>
          <div class="text">
            <span>{{ item.heimilisfang }} {{ item.postnumer }} {{ item.heiti_poststodvar }}</span>
          </div>
        </div>
        <div>
          <label>Aðsetur</label>
          <div class="text">
            <span>{{ item.adsetur }} {{ item.postnr_as }} {{ item.svfe_as }}</span>
          </div>
        </div>
        <div>
          <label>Ríkisfang</label>
          <div class="text">
            <span>{{ item.rikisfang }}</span>
          </div>
        </div>
        <div>
          <label>Ríkisfang á umsókn</label>
          <div class="text">
            <span>{{ item.rikisfang_old }}</span>
          </div>
        </div>
      </b-col>
      <b-col class="pl-0">
        <div v-if="!isLearningCenter">
          <label>Norðurlandamál</label>
          <div class="text">
            <span>{{ item.nl_mal_heiti }} {{ item.nl_mal_kodi }}</span>
          </div>
        </div>
        <div>
          <label>Móðurmál 1 / Móðurmál 2</label>
          <div class="text">
            <span>{{ item.mmal_1_heiti_mals }} / {{ item.mmal_2_heiti_mals }}</span>
          </div>
        </div>
        <div v-if="!isLearningCenter">
          <label>Þriðja mál</label>
          <div class="text">
            <span>{{ item.thridja_mal_heiti }} {{ item.thridja_mal_kodi }}</span>
          </div>
        </div>
        <div v-if="!isLearningCenter">
          <label>Fjórða mál</label>
          <div class="text">
            <span>{{ item.fjorda_mal_heiti }} {{ item.fjorda_mal_kodi }}</span>
          </div>
        </div>
      </b-col>
      <b-col class="pl-0">
        <div>
          <label>Skápanúmer nemanda</label>
          <div class="text">
            <span>{{ item.skapnr }}</span>
          </div>
        </div>
        <div>
          <label>Sérþarfir nemanda</label>
          <div class="text">
            <span>{{ item.fotlun }}</span>
          </div>
        </div>
        <div v-if="!isLearningCenter">
          <label>Sérstök mætingareinkunn</label>
          <div class="text">
            <span><yes-no :item="item.frjals_maeting"></yes-no></span>
          </div>
        </div>
      </b-col>
    </b-row>
    <div @click="viewDetails = !viewDetails" class="text-primary cursor-pointer mb-2">
      <span v-if="viewDetails">Sjá minna </span><span v-else>Sjá meira </span>
      <i class="fa text-primary" :class="{ 'fa-angle-up': viewDetails, 'fa-angle-down': !viewDetails }"></i>
    </div>
    <CourseList :id="id" :item="item" @refresh="closeAndReloadSlidebar"></CourseList>
    <RelativeList :id="id" :item="item"></RelativeList>
    <student-edit-info-slidebar
      v-if="showEditSlidebar"
      @closeSlidebar="closeAndReloadSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
      :id="id"
      :item="item"
    ></student-edit-info-slidebar>
    <send-message-slidebar
      v-if="showSendMessageSlidebar"
      :notandi_id="selectedItem && selectedItem.notandi_id"
      :nemandi_id="selectedItem && selectedItem.nemandi_id"
      :umsjon_nemanda="selectedItem && selectedItem.umsjon_nemanda === 1 ? 1 : ''"
      @closeSlidebar="closeSlidebar"
    >
    </send-message-slidebar>
    <student-supervisor-slidebar
      v-if="showSupervisorSlidebar"
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
      :studentId="id"
    ></student-supervisor-slidebar>
    <update-ssn-slidebar
      v-if="showUpdateSsnSlidebar"
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
      :userId="item && item.notandi_id"
    ></update-ssn-slidebar>
    <update-password-slidebar
      v-if="showUpdatePasswordSlidebar || showOpenPasswordAccessSlidebar"
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
      :userId="item && item.notandi_id"
      :openAccess="showOpenPasswordAccessSlidebar">
    </update-password-slidebar>
    <student-activation-slidebar
      v-if="showActivationSlidebar"
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
      :studentId="id"
      :active="item && item.virkur"
    ></student-activation-slidebar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import YesNo from '@/components/common/YesNo.vue';

import StudentEditInfoSlidebar from '@/components/students/student/details/StudentEditInfoSlidebar.vue';
import CourseList from '@/components/students/student/course/CourseList.vue';
import RelativeList from '@/components/students/student/relatives/RelativeList.vue';
import SendMessageSlidebar from '@/components/common/SendMessageSlidebar.vue';
import StudentSupervisorSlidebar from '@/components/students/student/details/StudentSupervisorSlidebar.vue';
import UpdateSsnSlidebar from '@/components/users/UpdateSsnSlidebar.vue';
import UpdatePasswordSlidebar from '@/components/users/UpdatePasswordSlidebar.vue';
import StudentActivationSlidebar from '@/components/students/student/StudentActivationSlidebar.vue';

export default {
  name: 'student-info',
  props: ['id', 'item'],
  components: {
    YesNo,
    StudentEditInfoSlidebar,
    CourseList,
    RelativeList,
    SendMessageSlidebar,
    StudentSupervisorSlidebar,
    UpdateSsnSlidebar,
    UpdatePasswordSlidebar,
    StudentActivationSlidebar,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('nemendahald_nemendur');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('nemendahald_nemendur');
    },
    isLearningCenter() {
      return this.schoolSettings && this.schoolSettings.tegund_skola_id === 4 && this.schoolSettings.tegund_skola_id === 5;
    },
    heimavist() {
      return this.schoolSettings && this.schoolSettings.vist ? this.schoolSettings.vist === 1 : false;
    },
    afangaskoli() {
      return this.schoolSettings && this.schoolSettings.afangaskoli ? this.schoolSettings.afangaskoli === 1 : false;
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      viewDetails: false,
      showEditSlidebar: false,
      showSendMessageSlidebar: false,
      showSupervisorSlidebar: false,
      showUpdateSsnSlidebar: false,
      showUpdatePasswordSlidebar: false,
      showOpenPasswordAccessSlidebar: false,
      showActivationSlidebar: false,
      selectedItem: {},
    };
  },
  methods: {
    openEditSlidebar() {
      this.showEditSlidebar = true;
      this.showSendMessageSlidebar = false;
      this.showSupervisorSlidebar = false;
      this.showUpdateSsnSlidebar = false;
      this.showUpdatePasswordSlidebar = false;
      this.showOpenPasswordAccessSlidebar = false;
      this.showActivationSlidebar = false;
    },
    openSendMessageSlidebar(type) {
      if (type === 'supervisor') {
        this.selectedItem = {
          nemandi_id: this.item.nemandi_id,
          umsjon_nemanda: 1,
        };
      } else {
        this.selectedItem = {
          notandi_id: this.item.notandi_id,
        };
      }

      this.showSendMessageSlidebar = true;
      this.showEditSlidebar = false;
      this.showSupervisorSlidebar = false;
      this.showUpdateSsnSlidebar = false;
      this.showUpdatePasswordSlidebar = false;
      this.showOpenPasswordAccessSlidebar = false;
      this.showActivationSlidebar = false;
    },
    openSupervisorSlidebar() {
      this.showSupervisorSlidebar = true;
      this.showSendMessageSlidebar = false;
      this.showEditSlidebar = false;
      this.showUpdateSsnSlidebar = false;
      this.showUpdatePasswordSlidebar = false;
      this.showOpenPasswordAccessSlidebar = false;
      this.showActivationSlidebar = false;
    },
    updateSsnSlidebar() {
      this.showUpdateSsnSlidebar = true;
      this.showEditSlidebar = false;
      this.showSendMessageSlidebar = false;
      this.showSupervisorSlidebar = false;
      this.showUpdatePasswordSlidebar = false;
      this.showOpenPasswordAccessSlidebar = false;
      this.showActivationSlidebar = false;
    },
    openPasswordSlidebar() {
      this.showUpdatePasswordSlidebar = true;
      this.showUpdateSsnSlidebar = false;
      this.showEditSlidebar = false;
      this.showSendMessageSlidebar = false;
      this.showSupervisorSlidebar = false;
      this.showOpenPasswordAccessSlidebar = false;
      this.showActivationSlidebar = false;
    },
    openOpenPasswordAccessSlidebar() {
      this.showOpenPasswordAccessSlidebar = true;
      this.showUpdatePasswordSlidebar = false;
      this.showUpdateSsnSlidebar = false;
      this.showEditSlidebar = false;
      this.showSendMessageSlidebar = false;
      this.showSupervisorSlidebar = false;
      this.showActivationSlidebar = false;
    },
    openActivationSlidebar() {
      this.showActivationSlidebar = true;
      this.showUpdateSsnSlidebar = false;
      this.showEditSlidebar = false;
      this.showSendMessageSlidebar = false;
      this.showSupervisorSlidebar = false;
      this.showUpdatePasswordSlidebar = false;
      this.showOpenPasswordAccessSlidebar = false;
    },
    closeSlidebar() {
      this.selectedItem = {};
      this.showEditSlidebar = false;
      this.showSendMessageSlidebar = false;
      this.showSupervisorSlidebar = false;
      this.showUpdateSsnSlidebar = false;
      this.showUpdatePasswordSlidebar = false;
      this.showOpenPasswordAccessSlidebar = false;
      this.showActivationSlidebar = false;
    },
    closeAndReloadSlidebar() {
      this.closeSlidebar();
      this.$emit('refresh');
    },
  },
};
</script>
<style lang="scss"></style>
;
