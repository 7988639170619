<template>
  <!--div>
    <img src="../assets/landing-loading.jpg" />
    <img src="../assets/landing-error.jpg" />
  </div-->
  <div class="loginLanding">
    <div class="bg" :class="{ 'bg-error': error }">
      <img src="../assets/Inna-purple-logo.svg" />
      <div v-if="error" class="login-error">
        <h1>Villa hefur komið upp!</h1>
        <div>Vinsamlegast farið inn á innskráningarsíðuna og reynið aftur.</div>
        <b-btn :href="url" variant="primary"> Innskráningarsíða </b-btn>
      </div>
      <div class="login-loading" v-else>
        <h1>Vinsamlegast hinkrið</h1>
        <div>Verið er að innskrá þig</div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '@/api/auth';
import { mapActions } from 'vuex';

export default {
  name: 'login',
  data() {
    return {
      error: false,
      url: 'https://inna.is',
    };
  },
  methods: {
    async login(A, kt, token) {
      try {
        const response = await auth.checkToken({ A, kt, token });
        if (response.data.successful) {
          auth.loginSuccess(response.data.token, response.data.name, response.data.ssn, response.data.logo, response.data.timeleft);
          await this.getLoggedInUser();
          this.$socket.emit('authenticate', { token: response.data.token });
          this.$router.push({ name: 'Dashboard' });
        } else {
          this.error = true;
        }
      } catch (e) {
        this.error = true;
        this.$log.error(e);
      }
    },
    ...mapActions({
      getLoggedInUser: 'getLoggedInUser',
    }),
  },
  created() {
    const a = this.$route.query.A;
    const { kt } = this.$route.query;
    const { token } = this.$route.query;
    if (process.env.VUE_APP_LOGIN_REDIRECT_URL) {
      this.url = process.env.VUE_APP_LOGIN_REDIRECT_URL;
    }
    this.login(a, kt, token);
  },
};
</script>

<style lang="scss" scoped>
@import '../style/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

@keyframes loaderAnim {
  to {
    opacity: 1;
    transform: scale3d(0.5, 0.5, 1);
  }
}

.loginLanding {
  height: 100vh;
  .bg {
    background-image: url('../assets/landing-loading.jpg');
    &.bg-error {
      background-image: url('../assets/landing-error.jpg');
    }
    height: 100vh;
    width: 100vw;
    background-size: cover;
    background-position: bottom;
    text-align: center;

    .btn {
      margin-top: 33px;
      font-size: 16px;
      padding: 12px 20px;
      // font-weight: bold;
    }

    img {
      margin-top: 40px;
      margin-bottom: 227px;
    }
  }
}

.login-loading,
.login-error {
  h1 {
    font-size: 36px;
    line-height: 54px;
    font-weight: bold;
    padding-bottom: 14px;
  }
  div {
    font-size: 18px;
    line-height: 28px;
    font-weight: normal;
  }
}
</style>
