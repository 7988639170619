<template>
  <Slidebar :autoClose="true" @closeMe="closeSlidebar" :large="large" :extraLarge="extraLarge">
    <CopyGraderuleTerm :id="id" :isCreating="false" :divisionId="divisionId" @finishCopy="closeSlidebar()"></CopyGraderuleTerm>
  </Slidebar>
</template>

<script>
import Slidebar from '@/components/common/Slidebar.vue';
import CopyGraderuleTerm from '@/components/school/terms/graderules/CopyGraderuleTerm.vue';

export default {
  name: 'copy-graderule-term-slidebar',
  components: {
    Slidebar,
    CopyGraderuleTerm,
  },
  props: ['id', 'divisionId', 'large', 'extraLarge'],
  methods: {
    closeSlidebar(item) {
      this.$emit('closeSlidebar', item);
    },
  },
};
</script>

<style lang="scss"></style>
