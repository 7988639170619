<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <b-form-group label="Kennslutímabil" label-for="term">
            <v-select id="term" name="term" v-model="selected.term" :options="getTerms" :clearable="false"> </v-select>
          </b-form-group>
          <div style="margin-top: -5px">
            <b-form-checkbox v-model="showAll" class="d-inline-block"></b-form-checkbox>
            <div class="d-inline-block">Birta liðnar og læstar annir</div>
          </div>
        </b-col>
        <b-col>
          <b-form-group label="Deild" label-for="department">
            <v-select id="department" name="department" v-model="selected.department" :options="departments" label="name"> </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Áfangi" label-for="module">
            <b-form-input id="module" name="module" v-model="selected.module" v-on:keyup.enter="loadData()" ref="focusInput" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Hópur" label-for="group">
            <b-form-input id="group" name="group" v-model="selected.group" v-on:keyup.enter="loadData()" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <b-btn variant="primary" class="w-100" @click="loadData()"> Leita </b-btn>
        </b-col>
      </b-row>
    </b-card>
    <br />
    <GroupsList
      :load="loadComponents"
      :searchQuery="getQuery()"
      :selected="selected"
      :slidebar="true"
      @changeGroup="changeGroup"
    ></GroupsList>
  </div>
</template>

<script>
import terms from '@/api/terms';
import structure from '@/api/structure';

import { mapGetters, mapActions } from 'vuex';

import GroupsList from '@/components/students/groups/GroupsList.vue';

export default {
  name: 'group-search',
  components: {
    GroupsList,
  },
  props: ['slidebar', 'term', 'module', 'group', 'department'],
  computed: {
    getTerms() {
      return this.terms.filter(x => this.showAll || (x.is_past === 0 && x.tafla_laest === 0));
    },
    canRead() {
      return this.loggedInUserHasReadPermission('nemendahald_hopar');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      loading: {
        terms: false,
        departments: false,
      },
      terms: [],
      departments: [],
      selected: {
        term: '',
        module: '',
        group: '',
        department: '',
        noGroups: { id: 0, label: 'Nei' },
      },
      loadComponents: false,
      yesNo: [
        { id: 1, label: 'Já' },
        { id: 0, label: 'Nei' },
      ],
      showAll: false,
    };
  },
  methods: {
    changeGroup(item) {
      this.$emit('changeGroup', item);
    },
    getQuery() {
      return `${this.selected.term ? this.selected.term.onn_id : ''},${this.selected.module || ''},${this.selected.group || ''},${
        this.selected.department ? this.selected.department.department_id : ''
      }`;
    },
    async loadTerms() {
      try {
        if (this.canRead) {
          this.loading.terms = true;
          const response = await terms.getTerms();
          this.terms = response.data.items.map(x => ({
            ...x,
            label: `${x.undirsk_audk} - ${x.onn_heiti}`,
          }));
          if (this.term) {
            this.selected.term = this.terms.find(x => x.onn_id === parseInt(this.term, 10));
            this.loadData();
          } else {
            this.selected.term = this.terms.find(x => x.is_default === 1);
          }
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.terms = false;
      }
    },
    async loadDepartments() {
      try {
        if (this.canRead) {
          this.loading.departments = true;
          const response = await structure.getDepartmentList();
          this.departments = response.data.items;
        }
        if (this.department) {
          this.selected.department = this.departments.find(x => x.department_id === parseInt(this.department, 10));
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.departments = false;
      }
    },
    loadData() {
      this.loadComponents = true;
      setTimeout(() => {
        this.loadComponents = false;
      }, 1000);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.selected.group = this.group;
    this.selected.module = this.module;
    this.loadTerms();
    this.loadDepartments();

    this.$nextTick(() => {
      if (this.$refs.focusInput) {
        this.$refs.focusInput.focus();
      }
    });
  },
};
</script>
