<template>
  <Slidebar :large="true" @closeMe="closeSlidebar">
    <h4>Eyða úr hóp</h4>
    <div class="alert alert-danger p-2">
      <i class="fa fa-exclamation-triangle"></i> Ertu alveg viss um að þú viljir eyða <span v-if="students.length > 1"> nemendum</span>
      <span v-else>nemandanum </span>
      úr hópnum ?
      <div>
        Þegar nemanda er eytt úr hóp þá er öllum einkunnum og fjarvistum eytt. Þessa aðgerð er <strong>EKKI</strong> hægt að afturkalla!
      </div>
    </div>
    <table class="table less-padding" style="font-size: 14px">
      <thead>
        <tr>
          <th>Nafn ({{ students.length }})</th>
          <th>Einkunnir</th>
          <th>Fjarvistir</th>
        </tr>
      </thead>
      <tr v-for="s in students" :key="s.nemandi_id">
        <td>
          {{ s.nafn }}
        </td>
        <td>
          <yes-no :item="s.einkunn_til"></yes-no>
          <i
            class="cursor-pointer fa fa-search pl-1"
            title="Skoða einkunnir nemanda"
            v-b-tooltip
            @click="openStudyRecordGradesSlidebar(s)"
          ></i>
        </td>
        <td>
          <yes-no :item="s.fjarvist_til"></yes-no>
          <i
            class="cursor-pointer fa fa-search pl-1"
            title="Skoða viðveru nemanda"
            v-b-tooltip
            @click="openStudentAttendanceSlidebar(s)"
          ></i>
        </td>
      </tr>
    </table>
    <div class="mb-3">
      <b-form-checkbox v-model="confirm" class="d-inline-block"></b-form-checkbox> Ég geri mér grein fyrir að þessa aðgerð er ekki hægt að
      afturkalla.
    </div>
    <b-btn variant="primary" @click="deleteStudents()" :disabled="!confirm || saving">
      <i class="fa fa-spin fa-spinner" v-if="saving"></i> Eyða</b-btn
    >
    <student-attendance-slidebar
      v-if="showStudentAttendanceSlidebar"
      :studentId="selectedItem && selectedItem.nemandi_id"
      :termId="item && item.onn_id"
      @closeSlidebar="closeStudentSlidebar"
      @closeAndReload="closeStudentSlidebar"
    />
    <study-record-grades-slidebar
      v-if="showStudyRecordGradesSlidebar"
      :recordId="selectedItem && selectedItem.ferill_id"
      @closeSlidebar="closeStudentSlidebar"
      @closeAndReload="closeStudentSlidebar"
    />
  </Slidebar>
</template>

<script>
import groups from '@/api/groups';
import { mapGetters, mapActions } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';
import YesNo from '@/components/common/YesNo.vue';
import StudentAttendanceSlidebar from '@/components/students/attendance/StudentAttendanceSlidebar.vue';
import StudyRecordGradesSlidebar from '@/components/students/studyRecords/StudyRecordGradesSlidebar.vue';

export default {
  name: 'delete-student-slidebar',
  props: ['item', 'students'],
  components: {
    Slidebar,
    YesNo,
    StudentAttendanceSlidebar,
    StudyRecordGradesSlidebar,
  },
  computed: {
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      showStudentAttendanceSlidebar: false,
      showStudyRecordGradesSlidebar: false,
      selectedItem: null,
      confirm: false,
      saving: false,
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    openStudentAttendanceSlidebar(item) {
      this.selectedItem = item;
      this.showStudentAttendanceSlidebar = true;
      this.showStudyRecordGradesSlidebar = false;
    },
    openStudyRecordGradesSlidebar(item) {
      this.selectedItem = item;
      this.showStudyRecordGradesSlidebar = true;
      this.showStudentAttendanceSlidebar = false;
    },
    closeStudentSlidebar() {
      this.showStudyRecordGradesSlidebar = false;
      this.showStudentAttendanceSlidebar = false;
      this.selectedItem = null;
    },
    async deleteStudents() {
      try {
        this.saving = true;
        const data = {
          students: this.students.map(x => x.ferill_id).join(','),
        };
        await groups.deleteStudentFromGroup(this.item.hopur_id, data);

        this.displaySuccess('Dags. byrjar breytt.');
        this.$emit('closeAndReloadSlidebar');
      } catch (e) {
        // Aðgerð mistókst.
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.saving = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>

<style lang="scss"></style>
