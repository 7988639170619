<template>
  <div>
    <div v-if="selectedClass" class="pb-3 noprint">
      <span class="cursor-pointer" @click="openSearchSlidebar()">Bekkur: {{ classDetail.heiti }} </span>
      <b-btn
        variant="primary"
        size="sm"
        style="padding: 2px 4px; margin-top: -5px"
        title="Breyta um bekk"
        v-b-tooltip
        @click="openSearchSlidebar()"
        ><i class="fa fa-exchange"></i
      ></b-btn>
    </div>
    <h5 v-if="selectedClass" class="onlyprint" style="position: absolute; left: 0; font-weight: bold">{{ classDetail.heiti }}</h5>

    <ClassSearch :slidebar="true" @changeClass="selectClass" v-if="!selectedClass" />
    <Timetable :id="selectedClass" type="class" v-if="selectedClass" :goToDate="termStart"></Timetable>
    <class-search-slidebar @closeSlidebar="selectClass" v-if="showSearchSlidebar"> </class-search-slidebar>
  </div>
</template>

<script>
import classes from '@/api/classes';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import ClassSearch from '@/components/students/classes/ClassSearch.vue';
import Timetable from '@/components/common/Timetable.vue';
import ClassSearchSlidebar from '@/components/students/classes/ClassSearchSlidebar.vue';

export default {
  name: 'classTimetable',
  components: {
    ClassSearch,
    Timetable,
    ClassSearchSlidebar,
  },
  computed: {
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      selectedClass: null,
      classDetail: '',
      showSearchSlidebar: false,
    };
  },
  methods: {
    openSearchSlidebar() {
      this.showSearchSlidebar = true;
    },
    selectClass(item) {
      if (item && item.id) {
        this.selectedClass = item.id;
        this.loadDetails();
      }
      this.termStart = '';
      if (item && item.term && item.term.is_active === 0) {
        this.termStart = moment(item.term.dags_fra);
      }
      this.showSearchSlidebar = false;
    },
    async loadDetails() {
      try {
        this.loading = true;
        const response = await classes.getClassDetail(this.selectedClass);
        this.classDetail = response.data.items && response.data.items.length > 0 ? response.data.items[0] : '';
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
