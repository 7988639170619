import Vue from 'vue';

export default {
  getUrl() {
    return Vue.http
      .post('api/school/test')
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
};
