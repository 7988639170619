<template>
  <Slidebar @closeMe="closeSlidebar" :large="large" :extraLarge="extraLarge">
    <h4 v-if="id">{{ item.heiti }}</h4>
    <h4 v-else>Stofna tegund forfalla</h4>
    <br />
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <table class="table less-padding">
        <tr>
          <td>Heiti*</td>
          <td>
            <b-form-group
              label-for="heiti"
              :state="submitted && errors.has('heiti') ? false : ''"
              :invalid-feedback="errors.first('heiti')"
            >
              <b-form-input
                id="heiti"
                name="heiti"
                type="text"
                v-model="form.name"
                :state="submitted && errors.has('heiti') ? false : ''"
                maxLength="40"
                v-validate="'required'"
                ref="focusInput"
                data-vv-as="heiti"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Kóði*</td>
          <td>
            <b-form-group label-for="kodi" :state="submitted && errors.has('kodi') ? false : ''" :invalid-feedback="errors.first('kodi')">
              <b-form-input
                id="kodi"
                name="kodi"
                type="text"
                v-model="form.code"
                :state="submitted && errors.has('heiti') ? false : ''"
                maxLength="1"
                v-validate="'required'"
                data-vv-as="kóði"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>
            Fjarvistarmargfeldi*<br />
            <i style="font-size: 13px">Tala á milli 0 og 1</i>
          </td>
          <td>
            <b-form-group
              label-for="margfeldi"
              :state="submitted && errors.has('margfeldi') ? false : ''"
              :invalid-feedback="errors.first('margfeldi')"
            >
              <b-form-input
                id="margfeldi"
                name="margfeldi"
                type="text"
                v-model="form.multiply"
                :state="submitted && errors.has('margfeldi') ? false : ''"
                v-validate="{ required: true, regex: /^(0([\,\.]\d+)?|1([\,\.]0+)?)$/ }"
                data-vv-as="fjarvistarmargfeldi"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Virk</td>
          <td>
            <b-form-group label-for="virk">
              <b-form-checkbox id="virk" name="virk" v-model="form.active"></b-form-checkbox>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Nota frádráttarmörk</td>
          <td>
            <b-form-group label-for="fradrattamork">
              <b-form-checkbox id="fradrattamork" name="fradrattamork" v-model="form.limit"></b-form-checkbox>
            </b-form-group>
          </td>
        </tr>
      </table>
      <b-btn variant="primary" class="mr-2" v-if="canEdit" @click="validateBeforeSubmit">
        <span v-if="id">Breyta</span>
        <span v-else>Stofna</span>
      </b-btn>
      <b-btn variant="dark" @click="closeSlidebar">Hætta við</b-btn>
      <b-btn variant="danger" class="float-right" v-if="canEdit && id" @click="deleteAbsence"> Eyða </b-btn>
    </b-form>
  </Slidebar>
</template>

<script>
import types from '@/api/types';
import { mapActions, mapGetters } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';

export default {
  name: 'absence-type-slidebar',
  props: ['item', 'large', 'extraLarge'],
  components: {
    Slidebar,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      loading: false,
      submitted: false,
      id: null,
      attendances: [],
      form: {
        name: '',
        code: '',
        multiply: '',
        active: true,
        limit: false,
      },
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async loadAbsenceDetail() {
      try {
        this.loading = true;
        const response = await types.getAbsenceDetail(this.id);
        if (response.data.items && response.data.items.length > 0) {
          const item = response.data.items[0];
          this.form.name = item.heiti;
          this.form.code = item.kodi;
          this.form.multiply = item.fjarvistar_margf;
          this.form.active = item.virk === 1;
          this.form.limit = item.fradrattarmork === 1;
        }
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.saving = true;
          const data = {
            name: this.form.name,
            code: this.form.code,
            multiply: this.form.multiply.toString().replace('.', ','),
            active: this.form.active ? 1 : 0,
            limit: this.form.limit ? 1 : 0,
          };
          if (this.id) {
            await types.updateAbsence(this.id, data);
            this.displaySuccess(`${this.form.name} hefur verið breytt.`);
          } else {
            await types.createAbsence(data);
            this.displaySuccess(`${this.form.name} hefur verið stofnuð.`);
          }
          this.closeSlidebar();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.data && e.response.data.error) {
            this.displayError(e.response.data.error);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.saving = false;
      }
    },
    async deleteAbsence() {
      try {
        const conf = confirm(`Ert þú viss um að eyða ${this.item.heiti}?`); // eslint-disable-line
        if (conf) {
          await types.updateAbsence(this.id, { delete: 1 });
          this.displaySuccess(`${this.item.heiti} hefur verið eytt.`);
          this.closeSlidebar();
        }
      } catch (e) {
        // Aðgerð mistókst.
        this.$log.debug(e);
        if (e.response && e.response.data && e.response.data.error) {
          this.displayError(e.response.data.error);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    item() {
      this.form = {
        name: '',
        code: '',
        multiply: '',
        active: true,
        limit: false,
      };
      this.id = null;
      if (this.item && this.item.teg_forf_stm_id) {
        this.id = this.item.teg_forf_stm_id;
        this.loadAbsenceDetail();
      }
      this.$nextTick(() => {
        if (this.$refs.focusInput) {
          this.$refs.focusInput.focus();
        }
      });
    },
  },
  created() {
    this.form = {
      name: '',
      code: '',
      multiply: '',
      active: true,
      limit: false,
    };
    this.id = null;
    if (this.item && this.item.teg_forfalla) {
      this.id = this.item.teg_forfalla;
      this.loadAbsenceDetail();
    }
    this.$nextTick(() => {
      if (this.$refs.focusInput) {
        this.$refs.focusInput.focus();
      }
    });
  },
};
</script>

<style lang="scss"></style>
