<template>
  <Slidebar @closeMe="closeSlidebar">
    <h4>Bæta við kennslutímabili á áfanga</h4>
    <b-form>
      <Loader v-if="loading"></Loader>
      <table class="table less-padding no-border">
        <tr>
          <td v-if="terms.length > 0 && !loading ">
            <label for="term">Kennslutímabil</label>
            <v-select id="termId" name="termId" v-model="termId" :clearable="false" :options="terms"> </v-select>
          </td>
          <td v-if="terms.length === 0 && !loading">
            <div>
              Áfanginn er nú þegar skráður á kennslutímabil sem eru til, virk eða í framtíð.
            </div>
          </td>
        </tr>
      </table>
      <b-btn v-if="terms.length > 0" variant="primary" @click="save()">Bæta við kennslutímabili</b-btn>
    </b-form>
  </Slidebar>
</template>
  
<script>
import Slidebar from '@/components/common/Slidebar.vue';
import { mapGetters, mapActions } from 'vuex';
import terms from '@/api/terms'
import Loader from '@/components/common/Loader.vue';

export default {
  name: 'module-connect-term-slidebar',
  components: {
    Slidebar,
    Loader
  },
  props: ['courseId', 'courseName'],
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_afangar');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('namskra_afangar');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      loading: false,
      terms: [],
      termId: '',
    };
  },
  methods: {
    async loadTerms() {
      try {
        this.loading = true;
        const params = {
          only_active: 0,
          hide_past: 1,
          courseIdNoTerm: this.courseId, // svo við fáum bara annir sem áfangi er ekki skráður á
          asc_order: 1
        };
        const response = await terms.getTerms(params);
        this.terms = response.data.items.map(x => ({
          ...x,
          label: `${x.undirsk_audk} - ${x.onn_heiti}`,
        }));
        this.termId = this.terms ? this.terms[0] : '';
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async save() {
      try {
        const data = {
            afangi_id: this.courseId
          };
          await terms.setTermToModule(this.termId.onn_id, data);
          this.displaySuccess('Kennslutímabili var bætt við áfangann.');
          this.closeAndReloadSlidebar();
        } catch (e) {
          this.$log.error(e);
          if (e.response && e.response.data && e.response.data.error) {
            this.displayError(e.response.data.error);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        } 
    },
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    closeAndReloadSlidebar() {
      this.$emit('closeAndReloadSlidebar');
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadTerms();
  },
};
</script>
