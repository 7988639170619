<template>
  <Slidebar @closeMe="closeSlidebar" :extraLarge="true">
    <h4>Nemendur með áfangann {{ item.namsgrein_afanganr }} í námsferli</h4>
    <div v-if="type === 1">Listinn inniheldur alla nemendur með áfangann í námsferli.</div>
    <div v-if="type === 2">Listinn inniheldur alla nemendur með áfangann í aðalvali.</div>
    <div v-if="type === 3">Listinn inniheldur alla nemendur með áfangann í varavali.</div>
    <div v-if="type === 6">Listinn inniheldur alla nemendur með áfangann í stöðunni "Áætlun".</div>
    <div v-if="type === 4">Listinn inniheldur alla nemendur sem eru <strong>ekki</strong> komnir í hóp.</div>
    <div v-if="type === 5">Listinn inniheldur alla nemendur sem eru komnir hóp.</div>
    <div v-if="type === 4">
      <div class="pt-1 pb-2" style="font-size: 14px">
        Hægt er að smella á „Sýna ítarleit“ til að sía nemendur eftir ákveðnum forsendum. Ef engar niðurstöður birtast
        fyrir neðan þá eru
        engir nemendur með áfangann í stöðunni áætlun í námsferlinum hjá sér.
      </div>
      <div>Staða í hópum</div>
      <div v-if="groups.length == 0" class="mb-2"><i>Engir hópar fundust</i></div>
      <div style="width: 50%">
        <table class="table" v-if="groups.length > 0" style="font-size: 12px">
          <thead>
            <tr>
              <th>Hópur</th>
              <th>Fjöldi í hóp</th>
              <th>Hámarksfjöldi í hóp</th>
            </tr>
          </thead>
          <tr v-for="g in groups" :key="g.hopur_id">
            <td>{{ g.hopur }}</td>
            <td>{{ g.fj_nema_i_hop }}</td>
            <td>{{ g.max_fj_nema }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div @click="showSearch = !showSearch" class="text-primary cursor-pointer mb-2">
      <span v-if="showSearch">Fela ítarleit </span><span v-else>Sýna ítarleit </span>
      <i class="fa text-primary" :class="{ 'fa-angle-up': showSearch, 'fa-angle-down': !showSearch }"></i>
    </div>
    <div v-if="showSearch" style="padding: 10px; background-color: #f9f9f9; border: 2px solid #ddd" class="mb-3">
      <b-row>
        <b-col class="pl-0">
          <b-form-group>
            <label for="choiceTypes">Tegund vals <Loader v-if="loading.choiceTypes"></Loader></label>
            <v-select id="choiceTypes" name="choiceTypes" v-model="selected.choiceTypes" :options="choiceTypes"
              label="heiti" class="whiteBackground">
            </v-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <label for="moduleStatuses">Staða áfanga <Loader v-if="loading.moduleStatuses"></Loader></label>
            <v-select id="moduleStatuses" name="moduleStatuses" v-model="selected.moduleStatuses"
              :options="moduleStatuses" label="heiti" class="whiteBackground">
            </v-select>
          </b-form-group>
        </b-col>
        <b-col>
          <div v-if="!afangaskoli">
            <b-form-group>
              <label for="classes">Bekkur <Loader v-if="loading.classes"></Loader></label>
              <v-select id="classes" name="classes" v-model="selected.classes" :options="classes" label="heiti"
                class="whiteBackground">
              </v-select>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="afangaskoli">
        <b-col class="pl-0">
          <b-form-group>
            <label for="statuses">Staða nema <Loader v-if="loading.statuses"></Loader></label>
            <v-select id="statuses" name="statuses" v-model="selected.statuses" :options="statuses" label="heiti"
              class="whiteBackground">
            </v-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <label for="processingStatuses">Vinnslustaða <Loader v-if="loading.processingStatuses"></Loader></label>
            <v-select id="processingStatuses" name="processingStatuses" v-model="selected.processingStatuses"
              :options="processingStatuses" label="heiti" class="whiteBackground">
            </v-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <label for="userGroups">Notendahópur <Loader v-if="loading.userGroups"></Loader></label>
            <v-select id="userGroups" name="userGroups" v-model="selected.userGroups" :options="userGroups" label="heiti"
              class="whiteBackground">
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="afangaskoli">
        <b-col class="pl-0">
          <b-form-group>
            <label for="divisions">Undirskóli <Loader v-if="loading.divisions"></Loader></label>
            <v-select id="divisions" name="divisions" v-model="selected.divisions" :options="getDivisions" label="name"
              class="whiteBackground">
            </v-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <label for="courses">Braut <Loader v-if="loading.courses"></Loader></label>
            <v-select id="courses" name="courses" v-model="selected.courses" :options="getCourses" style="font-size: 12px"
              class="whiteBackground">
            </v-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <label for="departments">Deild <Loader v-if="loading.departments"></Loader></label>
            <v-select id="departments" name="departments" v-model="selected.departments" :options="getDepartments"
              label="name" class="whiteBackground">
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="afangaskoli">
        <b-col class="pl-0">
          <b-form-group label="Áfangi" label-for="module" class="whiteBackground">
            <b-form-input id="module" name="module" v-model="selected.module" style="background-color: inherit" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Hópur" label-for="group" class="whiteBackground">
            <b-form-input id="group" name="group" v-model="selected.group" style="background-color: inherit" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Ár nemenda" label-for="year" class="whiteBackground">
            <b-form-input id="year" name="year" v-model="selected.year" style="background-color: inherit" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-btn variant="primary" size="sm" class="mb-3" @click="loadStudents()">Leita</b-btn>
      <br />
    </div>
    <div v-if="type === 4 && hasPlan">
      <b-form-checkbox v-model="showPlan" class="d-inline-block"></b-form-checkbox> Sýna nemendur með áfanga í stöðunni
      "Áætlun"
    </div>
    <table class="table less-padding" style="font-size: 13px">
      <thead>
        <tr>
          <th>Nafn ({{ getStudents.length }})</th>
          <th>Kennitala</th>
          <th>Staða nema</th>
          <th>Braut</th>
          <th>Staða</th>
          <th>Tegund vals</th>
          <th v-if="type === 5">Hópur 
            <i v-if="canEdit" class="fa fa-info-circle" v-b-tooltip
            title="Hægt er að færa nemendur um hóp ef engar einkunnir eða fjarvistir eru skráðar á nemendum."></i>
          </th>
          <th v-if=" type === 4 && canEdit ">
            Hópur
            <b-form-select v-model=" allGroup " :options=" groups " size="sm" @input="updateGroup()">
              <b-form-select-option :value=" null "></b-form-select-option>
            </b-form-select>
          </th>
        </tr>
      </thead>
      <tbody>
        <TableRowLoader v-if=" loading.students " colspan="10" :center=" true " />
        <TableRowEmpty v-if=" !loading.students && getStudents.length === 0 " colspan="10" :center=" true " />
        <tr v-for=" item  in  getStudents " :key=" item.kennitala ">
          <td>{{ item.nafn }} {{ item.bekkur }}
            <b-link v-if=" item.nemandanr && canEdit " :to=" { name: 'StudentRequests', params: { term: 0, student: item.nemandanr } } "
              target="_blank" title="Skoða töflubreytingar hjá nemanda" v-b-tooltip><i class="fa fa-external-link mr-1"></i></b-link>
          </td>
          <td>{{ item.kennitala }}</td>
          <td>{{ item.stada_nema_heiti }}</td>
          <td>{{ item.brautir }}</td>
          <td>{{ item.stada_afanga_heiti }}</td>
          <td>{{ item.tegund_vals_heiti }}</td>
          <td v-if=" type === 4 && canEdit ">
            <b-form-select v-model=" item.group " :options=" groups " size="sm">
              <b-form-select-option :value=" null "></b-form-select-option>
            </b-form-select>
          </td>
          <td v-if=" type === 5 && !canEdit ">{{ item.hopar }}</td>
          <td v-if=" type === 5 && canEdit ">
            <b-form-select v-model=" item.newGroup " :options=" groups " size="sm"
              :disabled=" item.fjarvist_til === 1 || item.einkunn_til === 1 ">
              <b-form-select-option :value=" null "></b-form-select-option>
            </b-form-select>
          </td>
        </tr>
      </tbody>
    </table>

    <b-btn variant="primary" class="mt-3" v-if="studentGroups.length > 0 " @click="addStudentsToGroup()"
      :disabled=" saving ">
      Bæta {{ studentGroups.length }} í hóp
      <i class="fa fa-spin fa-spinner" v-if=" saving "></i>
    </b-btn>
    <b-btn variant="primary" class="mt-3" v-if="type === 5 && isGroupChanged.length > 0 " @click="changeStudentsGroup()"
      :disabled=" saving ">
      Færa {{isGroupChanged.length }} um hóp
      <i class="fa fa-spin fa-spinner" v-if=" saving "></i>
    </b-btn>
  </Slidebar>
</template>

<script>
import terms from '@/api/terms';
import school from '@/api/school';
import groups from '@/api/groups';
import types from '@/api/types';
import common from '@/api/common';
import users from '@/api/users';
import classes from '@/api/classes';
import students from '@/api/students';
import curriculum from '@/api/curriculum';
import structure from '@/api/structure';
import { mapGetters, mapActions } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';
import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import Loader from '@/components/common/Loader.vue';

export default {
  name: 'module-student-list-slidebar',
  props: ['moduleTermId', 'type', 'canEdit'],
  components: {
    Slidebar,
    TableRowLoader,
    TableRowEmpty,
    Loader,
  },
  computed: {
    getDivisions() {
      return this.divisions.filter(x => !this.selected.courses || x.division_id === this.selected.courses.undirskoli_id);
    },
    canViewStudent() {
      return this.loggedInUserHasWritePermission('nemendahald_nemendur');
    },
    getCourses() {
      return this.courses
        .filter(x => !this.selected.divisions || x.undirskoli_id === this.selected.divisions.division_id)
        .filter(x => !this.selected.departments || x.deildir.indexOf(this.selected.departments.name) > -1);
    },
    getDepartments() {
      return this.departments.filter(x => !this.selected.courses || this.selected.courses.deildir.indexOf(x.name) > -1);
    },
    getStudents() {
      return this.students.filter(x => this.type !== 4 || this.showPlan || x.stada_afanga !== 40);
    },
    studentGroups() {
      return this.students.filter(x => x.group);
    },
    studentChangeGroups() {
      return this.students.filter(x => x.newGroup);
    },
    isGroupChanged() {
      return this.students.filter(x => x.newGroup !== x.hopur_id);
    },
    afangaskoli() {
      return this.schoolSettings && this.schoolSettings.afangaskoli ? this.schoolSettings.afangaskoli === 1 : false;
    },
    canRead() {
      return this.loggedInUserHasReadPermission('nemendahald_hopar');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {
      loading: {
        students: false,
        statuses: false,
        processingStatuses: false,
        moduleStatuses: false,
        choiceTypes: false,
        departments: false,
        courses: false,
        divisions: false,
        userGroups: false,
        classes: false,
      },
      selected: {
        statuses: '',
        processingStatuses: '',
        moduleStatuses: '',
        choiceTypes: '',
        departments: '',
        courses: '',
        divisions: '',
        userGroups: '',
        classes: '',
        module: '',
        group: '',
        year: '',
      },
      students: [],
      groups: [],
      statuses: [],
      processingStatuses: [],
      moduleStatuses: [],
      choiceTypes: [],
      departments: [],
      courses: [],
      divisions: [],
      userGroups: [],
      classes: [],
      item: '',
      allGroup: '',
      saving: false,
      hasPlan: false,
      showPlan: false,
      showGroups: true,
      showSearch: false,
    };
  },
  methods: {
    updateGroup() {
      this.students.forEach(s => {
        this.$set(s, 'group', this.allGroup);
      });
    },
    async loadStudents() {
      try {
        if (this.canRead) {
          const params = {};
          // Type: 1 -> allir nemendur, 2 : aðalval, 3: varaval, 4: nem ekki í hóp, 5: nem í hóp
          if (this.type === 2) {
            params.choice = 1;
          } else if (this.type === 3) {
            params.choice = 2;
          } else if (this.type === 4) {
            params.notInGroup = 1;
            if (this.showSearch) {
              params.studentStatus = this.selected.statuses ? this.selected.statuses.eink_stodu : '';
              params.processingStatus = this.selected.processingStatuses ? this.selected.processingStatuses.teg_vinnslust_nema_id : '';
              params.status = this.selected.moduleStatuses ? this.selected.moduleStatuses.stada_afanga : '';
              params.choice = this.selected.choiceTypes ? this.selected.choiceTypes.teg_val_nemenda_id : '';
              params.department = this.selected.departments ? this.selected.departments.department_id : '';
              params.course = this.selected.courses ? this.selected.courses.braut_id : '';
              params.division = this.selected.divisions ? this.selected.divisions.division_id : '';
              params.userGroup = this.selected.userGroups ? this.selected.userGroups.hopur_id : '';
              params.class = this.selected.classes ? this.selected.classes.bekkir_id : '';
              params.module = this.selected.module;
              params.group = this.selected.group;
              params.year = this.selected.year;
              if (this.selected.moduleStatuses && this.selected.moduleStatuses.stada_afanga === 40) {
                this.showPlan = true;
              }
            }
          } else if (this.type === 5) {
            params.inGroup = 1;
          } else if (this.type === 6) {
            params.status = 40;
          }
          this.students = [];
          const response = await school.getGroupsByModulesStudents(this.moduleTermId, params);
          if (this.type === 5) {
            this.students = response.data.items.map(x => ({ ...x, currentGroup: x.hopur_id, newGroup: x.hopur_id }));
          } else {
            this.students = response.data.items.map(x => ({ ...x, group: null }));
          }
          this.hasPlan = this.students.filter(x => x.stada_afanga === 40).length > 0;
          this.loading.students = true;
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.students = false;
      }
    },
    async loadModule() {
      try {
        if (this.canRead) {
          this.item = '';
          const response = await terms.getTermModule(this.moduleTermId);
          this.item = response.data;
          this.groups = response.data.hopar.map(x => ({ ...x, value: x.hopur_id, text: x.hopur }));
          if (this.type === 4) {
            this.loadClasses();
          }
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async addStudentsToGroup() {
      try {
        this.saving = true;
        const data = this.studentGroups.map(x => ({ ferill_id: x.ferill_id, hopur_id: x.group }));
        await school.addStudentsToGroup(this.moduleTermId, { list: data });
        this.displaySuccess('Nemendum bætt í hópa');
        this.$emit('closeAndReloadSlidebar');
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);

        if (e.response && e.response.data && e.response.data.error) {
          this.displayError(e.response.data.error);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } finally {
        this.saving = false;
      }
    },
    async changeStudentsGroup() {
      try {
        this.saving = true;
        const data = this.isGroupChanged.map(x => ({ ferill_id: x.ferill_id, old_hopur_id: x.currentGroup, hopur_id: x.newGroup }));
        await groups.changeStudentsGroups({ list: data });
        this.displaySuccess('Nemendur færðir um hóp');
         this.loadStudents();
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);

        if (e.response && e.response.data && e.response.data.error) {
          this.displayError(e.response.data.error);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } finally {
        this.saving = false;
      }
    },
    async loadStatuses() {
      try {
        this.loading.statuses = true;
        const response = await students.getStudentsStatuses();
        this.statuses = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.statuses = false;
      }
    },
    async loadProcessingStatuses() {
      try {
        this.loading.processingStatuses = true;
        const response = await types.getProcessingStatusList();
        this.processingStatuses = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.processingStatuses = false;
      }
    },
    async loadModuleStatuses() {
      try {
        this.loading.moduleStatuses = true;
        const response = await common.getModuleStatuses();
        this.moduleStatuses = response.data.items.filter(x => x.stada_afanga === 40 || x.stada_afanga === 41);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.moduleStatuses = false;
      }
    },
    async loadChoiceType() {
      try {
        this.loading.choiceTypes = true;
        const response = await common.getChoiceType();
        this.choiceTypes = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.choiceTypes = false;
      }
    },
    async loadDepartments() {
      try {
        this.loading.departments = true;
        const response = await structure.getDepartmentList();
        this.departments = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.departments = false;
      }
    },
    async loadCourses() {
      try {
        this.loading.courses = true;
        const response = await curriculum.getCourseList({ showDepartment: 1, active: 1 });
        this.courses = response.data.items.map(x => ({
          ...x,
          label: `${x.heiti} ${x.braut_kodi}`,
          deildir: (x.deild || '').split(','),
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.courses = false;
      }
    },
    async loadDivisions() {
      try {
        this.loading.divisions = true;
        const response = await structure.getDivisionList();
        this.divisions = response.data.items.map(x => ({
          ...x,
          label: `${x.code} - ${x.name}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.divisions = false;
      }
    },
    async loadUserGroups() {
      try {
        this.loading.userGroups = true;
        const response = await users.getUserGroupsList({ active: 1 });
        this.userGroups = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.userGroups = false;
      }
    },
    async loadClasses() {
      try {
        this.loading.classes = true;
        const search = {
          term: this.item.onn_id,
        };
        const response = await classes.getClassList(search);
        this.classes = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.classes = false;
      }
    },
    closeSlidebar() {
      let conf = true;
      if (this.type === 4 && this.students.filter(x => x.group).length > 0) {
        conf = confirm('Ertu viss um að þú viljir loka glugganum ? Þú ert ekki búinn að vista skráningu nemenda í hóp!'); // eslint-disable-line
      }
      if (conf) {
        this.$emit('closeSlidebar');
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadModule();
    this.loadStudents();
    if (this.type === 4) {
      if (this.afangaskoli) {
        this.loadStatuses();
        this.loadProcessingStatuses();
        this.loadDepartments();
        this.loadCourses();
        this.loadDivisions();
        this.loadUserGroups();
      }
      this.loadModuleStatuses();
      this.loadChoiceType();
    }
  },
};
</script>

<style lang="scss">
.whiteBackground {
  background: white;
}
</style>
