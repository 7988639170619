<template>
  <Slidebar @closeMe="closeSlidebar" :extraLarge="true">
    <h4>Breyta áföngum</h4>
    <div class="alert alert-danger" v-if="gratuationFinished">
      <i class="fa fa-exclamation-triangle"></i>
      Ath. nemandinn er útskrifaður
    </div>
    <div>
      Þeir áfangar sem birtast á skírteini nemandans eru þeir áfangar sem koma upp þegar skoðaðir eru áfangar tengdir á skírteini brautar,
      ef áfangi birtist ekki á skírteini nemandans þarf að skoða hvort það eigi eftir að tengja hann.
    </div>
    <b-form-radio-group id="type" :value="type" name="type" stacked @change="updateSelection(type, $event)">
      <b-form-radio value="0" class="mt-3"> Áfangar tengdir á skírteini brautar.</b-form-radio>
      <b-form-radio value="1">Áfangar á námsferli sem eru ekki tengdir við skírteini brautar. </b-form-radio>
      <b-form-radio value="2">Áfangar úr öðrum skólum. </b-form-radio>
      <b-form-radio value="3" v-if="courses.length > 0"> Tengja áfanga frá annarri braut inn sem val. </b-form-radio>
    </b-form-radio-group>

    <div v-if="type === '0'" class="mt-2">
      <Loader v-if="loading.requirements"></Loader>
      <div v-if="!loading.requirements">
        <table class="table change-module-table-border table-bordered" v-if="!loading.requirements" style="font-size: 12px">
          <thead>
            <tr>
              <th colspan="3">Brautarkrafa</th>
              <th :colspan="afangaskoli ? 5 : 6">Á námsferli</th>
              <th colspan="5">Á skírteini</th>
            </tr>
            <tr>
              <th>
                <LinkOrder title="Áfangi" :filteredList="requirements" :list="requirements" column="afangi_brautar" />
              </th>
              <th>
                <LinkOrder
                  title="Tegund"
                  :filteredList="requirements"
                  :list="requirements"
                  column="uppbygging_tegund"
                  secondColumn="afangi_brautar"
                />
                <i class="fa fa-info-circle" title="Tegund uppbyggingar á brautinni" v-b-tooltip></i>
              </th>
              <th>Óvirk <i class="fa fa-info-circle" title="Nemandi þarf ekki að klára áfangann í brautarkröfunni" v-b-tooltip></i></th>
              <th>
                <LinkOrder title="Áfangi" :filteredList="requirements" :list="requirements" column="afangi" />
              </th>
              <th>
                <LinkOrder
                  title="Staða"
                  :filteredList="requirements"
                  :list="requirements"
                  column="stada_afanga_heiti"
                  secondColumn="afangi"
                />
              </th>
              <th v-if="!afangaskoli">
                <LinkOrder
                  title="Stúdentspróf"
                  :filteredList="requirements"
                  :list="requirements"
                  column="studentsprof"
                  secondColumn="afangi"
                />
              </th>
              <th>
                <LinkOrder title="Þrep" :filteredList="requirements" :list="requirements" column="trep" secondColumn="afangi" />
              </th>
              <th>
                <LinkOrder title="Ein." :filteredList="requirements" :list="requirements" column="einingar_ferill" secondColumn="afangi" />
              </th>
              <th>
                <LinkOrder title="Einkunn" :filteredList="requirements" :list="requirements" column="einkunn" secondColumn="afangi" />
              </th>
              <th>
                <LinkOrder title="Áfangi" :filteredList="requirements" :list="requirements" column="afangi_skirteini" />
                <i
                  class="fa fa-info-circle"
                  title="Ef áfangi hefur annað heiti en er í uppbyggingunni þá er hægt að velja
                  hvort notast er við heiti áfangans sem er verið að meta eða þess sem er í brautarkröfunni."
                  v-b-tooltip
                ></i>
              </th>
              <th>
                <LinkOrder title="Þrep" :filteredList="requirements" :list="requirements" column="trep_skirteini" />
              </th>
              <th>
                <LinkOrder title="Ein." :filteredList="requirements" :list="requirements" column="einingar" />
              </th>
              <th>Aftengja <i class="fa fa-info-circle" title="Taka áfanga úr brautarkröfunni" v-b-tooltip></i></th>
              <th>Möppun <i class="fa fa-info-circle" v-b-tooltip title="Vista í möppunartöflu"></i></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in requirements" :key="`requirements-${idx}`" :class="{ fellBackground: item.stada_afanga === 2 }">
              <td :class="{ studentStudyRecordOvirk: item.ovirk === 1 }">{{ item.afangi_brautar }}</td>
              <td>{{ item.uppbygging_tegund }}</td>
              <td>
                <b-form-checkbox
                  v-if="!item.afangi || item.disconnect"
                  v-model="item.inactive"
                  @input="
                    item.changed = true;
                    hasMadeChanges = true;
                  "
                ></b-form-checkbox>
              </td>
              <td :class="getStudyRecordColor(item.stada_afanga)">{{ item.afangi }}</td>
              <td>{{ item.stada_afanga_heiti }}</td>
              <td v-if="!afangaskoli"><span v-if="item.studentsprof === 1">Já</span></td>
              <td>{{ item.trep }}</td>
              <td>{{ item.einingar_ferill }}</td>
              <td>{{ item.einkunn }}</td>
              <td>
                <div v-if="item.disconnect">{{ item.afangi_skirteini }}</div>
                <v-select
                  v-model="item.selectedModule"
                  :options="getModules(item)"
                  :clearable="false"
                  label="namsgrein_afanganr"
                  @input="
                    item.changed = true;
                    hasMadeChanges = true;
                  "
                  v-if="getModules(item).length > 0 && !item.disconnect"
                >
                </v-select>
              </td>
              <td>{{ item.selectedModule ? item.selectedModule.trep : '' }}</td>
              <td>{{ item.selectedModule ? item.selectedModule.einingar : '' }}</td>
              <td>
                <b-form-checkbox
                  v-if="item.ferill_id && !item.map"
                  v-model="item.disconnect"
                  @input="hasMadeChanges = true"
                ></b-form-checkbox>
              </td>
              <td>
                <b-form-checkbox
                  v-if="item.afangi && !item.disconnect"
                  @input="
                    item.changed = true;
                    hasMadeChanges = true;
                  "
                  v-model="item.map"
                ></b-form-checkbox>
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <table cellspacing="0" cellpadding="0" border="0" style="font-size: 13px">
          <tr>
            <td><b>Valdar einingar á skírteini:</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
            <td>Einingar:&nbsp;</td>
            <td>
              <b>{{ getTotalSelected }}</b
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </td>
            <td>Einingar á þrepum (f-einingar):&nbsp;</td>
            <td>
              <b>{{ getTotalSelectedTrep }}</b>
            </td>
            <td></td>
            <td></td>
          </tr>
        </table>
        <br />
        <div style="font-size: 13px">
          <b>Staða áfanga getur verið:</b><br />
          <span>Áætlun(Á)</span>, &nbsp; <span>Staðfest val(V)</span>, &nbsp; <span class="studentStudyRecordOlokid">Ólokið (Ó)</span>,
          &nbsp; <span class="studentStudyRecordMetid">Metið (M)</span>, &nbsp;
          <span class="studentStudyRecordMetid">Metið án ein.(ME)</span>, &nbsp; <span class="studentStudyRecordLokid">Lokið (L)</span>,
          &nbsp; <span class="studentStudyRecordLokidAnEininga">Lokið án eininga(LE)</span>, &nbsp;
          <span class="studentStudyRecordUrsogn">Úrsögn(Ú)</span>, &nbsp; 
          <!-- <span class="studentStudyRecordFall">Mat Fall(MF)</span>, &nbsp; -->
          <span class="studentStudyRecordFall">Féll(F)</span>
          <!--
          <span class="studentStudyRecordOlokid">Ólokið (Ó)</span>, &nbsp; <span class="studentStudyRecordMetid">Metið (M)</span>, &nbsp;
          <span class="studentStudyRecordLokid">Lokið (L)</span>, &nbsp;
          <span class="studentStudyRecordLokidAnEininga">Lokið án eininga(LE)</span>, &nbsp;
          <span class="studentStudyRecordFall">Mat Fall(MF)</span>, &nbsp; <span class="studentStudyRecordFall">Fall(F)</span>, &nbsp;
          <span class="studentStudyRecordFall">Fall á önn(FÖ)</span> -->
          <br />
        </div>
      </div>
    </div>
    <div v-if="type === '1'" class="mt-2">
      <Loader v-if="loading.notCertificate"></Loader>
      <div v-if="!loading.notCertificate">
        <table class="table tablle-change-module-table-border table-bordered" style="font-size: 12px">
          <thead>
            <tr>
              <th></th>
              <th :colspan="afangaskoli ? 5 : 6">Á námsferli</th>
              <th colspan="2">Brautarkrafa</th>
              <th colspan="5">Á skírteini</th>
            </tr>
            <tr>
              <th>
                <b-form-checkbox v-model="selectedNotCertificate" @input="updateAllNotCertificate()"></b-form-checkbox>
              </th>
              <th>Áfangi</th>
              <th>Staða</th>
              <th v-if="!afangaskoli">Stúdentspróf</th>
              <th>Þrep</th>
              <th>Ein.</th>
              <th>Einkunn</th>
              <th>Áfangi <i class="fa fa-spin fa-spinner" v-if="loading.requirements"></i></th>
              <th>Nota brautarkröfu <i class="fa fa-info-circle" title="Nota brautarkröfu á skírteini" v-b-tooltip></i></th>
              <th>Áfangi</th>
              <th>Þrep</th>
              <th>Ein.</th>
              <th>Uppb.</th>
              <th>Möppun <i class="fa fa-info-circle" title="Vista í möppunartöflu" v-b-tooltip></i></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in notCertificate" :key="`notCertificate-${idx}`">
              <td>
                <b-form-checkbox v-model="item.selected"></b-form-checkbox>
              </td>
              <td :class="getStudyRecordColor(item.stada_afanga)">{{ item.namsgrein_afanganr }}</td>
              <td>{{ item.stada_afanga_heiti }}</td>
              <td v-if="!afangaskoli"><span v-if="item.studentsprof === 1">Já</span></td>
              <td>{{ item.trep }}</td>
              <td>{{ item.einingar }}</td>
              <td>{{ item.einkunn }}</td>
              <td>
                <v-select
                  v-model="item.selectedCourse"
                  :options="getCourseModules"
                  :clearable="true"
                  :disabled="!item.selected"
                  style="min-width: 150px; font-size: 11px"
                  @input="hasMadeChanges = true"
                >
                </v-select>
              </td>
              <td>
                <b-form-checkbox v-model="item.useRequirements" :disabled="!item.selected" @input="hasMadeChanges = true"></b-form-checkbox>
              </td>
              <td>
                <v-select
                  v-model="item.selectedModule"
                  :options="item.evaluation"
                  label="namsgrein_afanganr"
                  :disabled="item.useRequirements || !item.selected"
                  style="min-width: 150px; font-size: 11px"
                  :clearable="false"
                  @input="hasMadeChanges = true"
                >
                </v-select>
              </td>
              <td>{{ item.selectedModule ? item.selectedModule.trep : '' }}</td>
              <td>{{ item.selectedModule ? item.selectedModule.einingar : '' }}</td>
              <td>
                <v-select
                  v-model="item.structure"
                  :options="getCourseDefinedSelection"
                  :clearable="true"
                  :disabled="!item.selected"
                  @input="hasMadeChanges = true"
                  style="font-size: 11px"
                >
                </v-select>
              </td>
              <td>
                <b-form-checkbox
                  v-model="item.map"
                  :disabled="!item.selectedCourse || !item.selectedModule || !item.selected"
                  @input="hasMadeChanges = true"
                ></b-form-checkbox>
              </td>
            </tr>
          </tbody>
        </table>
        <div style="font-size: 13px">
          <b>Staða áfanga getur verið:</b><br />
          <span>Áætlun(Á)</span>, &nbsp; <span>Staðfest val(V)</span>, &nbsp; <span class="studentStudyRecordOlokid">Ólokið (Ó)</span>,
          &nbsp; <span class="studentStudyRecordMetid">Metið (M)</span>, &nbsp;
          <span class="studentStudyRecordMetid">Metið án ein.(ME)</span>, &nbsp; <span class="studentStudyRecordLokid">Lokið (L)</span>,
          &nbsp; <span class="studentStudyRecordLokidAnEininga">Lokið án eininga(LE)</span>, &nbsp;
          <span class="studentStudyRecordUrsogn">Úrsögn(Ú)</span>, &nbsp; <span class="studentStudyRecordFall">Mat Fall(MF)</span>, &nbsp;
          <span class="studentStudyRecordFall">Féll(F)</span>
          <!--
            <span class="studentStudyRecordOlokid">Ólokið (Ó)</span>, &nbsp; <span class="studentStudyRecordMetid">Metið
            (M)</span>, &nbsp;
          <span class="studentStudyRecordLokid">Lokið (L)</span>, &nbsp;
          <span class="studentStudyRecordLokidAnEininga">Lokið án eininga(LE)</span>, &nbsp;
          <span class="studentStudyRecordFall">Mat Fall(MF)</span>, &nbsp; <span
            class="studentStudyRecordFall">Fall(F)</span>, &nbsp;
          <span class="studentStudyRecordFall">Fall á önn(FÖ)</span> -->
          <br />
        </div>
      </div>
    </div>
    <div v-if="type === '2'" class="mt-2">
      <Loader v-if="loading.otherSchools"></Loader>
      <div v-if="!loading.otherSchools">
        <table class="table change-module-table-border table-bordered" style="font-size: 12px">
          <thead>
            <tr>
              <th></th>
              <th colspan="6">Á námsferli</th>
              <th colspan="2">Brautarkrafa</th>
              <th colspan="5">Á skírteini</th>
            </tr>
            <tr>
              <th>
                <b-form-checkbox v-model="selectedAllOtherSchools" @input="updateAllOtherSchools()"></b-form-checkbox>
              </th>
              <th>Áfangi</th>
              <th>Skóli</th>
              <th>Staða</th>
              <th>Þrep</th>
              <th>Ein.</th>
              <th>Einkunn</th>
              <th>Áfangi <i class="fa fa-spin fa-spinner" v-if="loading.requirements"></i></th>
              <th>Nota braut <i class="fa fa-info-circle" title="Nota brautarkröfu á skírteini" v-b-tooltip></i></th>
              <th>Áfangi</th>
              <th>Þrep</th>
              <th>Ein.</th>
              <th>Uppb.</th>
              <th>Möppun <i class="fa fa-info-circle" title="Vista í möppunartöflu" v-b-tooltip></i></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in otherSchools" :key="`notCertificate-${idx}`">
              <td>
                <b-form-checkbox v-model="item.selected"></b-form-checkbox>
              </td>
              <td :class="getStudyRecordColor(item.stada_afanga)">{{ item.namsgrein_afanganr }}</td>
              <td>{{ item.skoli_audk }}</td>
              <td>{{ item.stada_afanga_heiti }}</td>
              <td>{{ item.trep }}</td>
              <td>{{ item.einingar_ferill }}</td>
              <td>{{ item.einkunn }}</td>
              <td>
                <v-select
                  v-model="item.selectedCourse"
                  :options="getCourseModules"
                  :disabled="!item.selected"
                  :clearable="true"
                  style="font-size: 10px"
                  @input="hasMadeChanges = true"
                >
                </v-select>
              </td>
              <td>
                <b-form-checkbox
                  v-model="item.useRequirements"
                  :disabled="!item.selectedCourse || !item.selected"
                  @input="hasMadeChanges = true"
                ></b-form-checkbox>
              </td>
              <td>
                <v-select
                  v-model="item.selectedModule"
                  :options="item.evaluation"
                  label="namsgrein_afanganr"
                  :disabled="item.useRequirements || !item.selected"
                  style="font-size: 10px"
                  :clearable="false"
                  @input="hasMadeChanges = true"
                >
                </v-select>
              </td>
              <td>{{ item.selectedModule ? item.selectedModule.trep : '' }}</td>
              <td>{{ item.selectedModule ? item.selectedModule.einingar : '' }}</td>
              <td>
                <v-select
                  v-model="item.structure"
                  :options="getCourseDefinedSelection"
                  :clearable="true"
                  :disabled="!item.selected"
                  @input="hasMadeChanges = true"
                >
                </v-select>
              </td>
              <td>
                <b-form-checkbox
                  v-model="item.map"
                  :disabled="!item.selectedCourse || !item.selectedModule || !item.selected"
                  @input="hasMadeChanges = true"
                ></b-form-checkbox>
              </td>
            </tr>
          </tbody>
        </table>
        <div style="font-size: 13px">
          <b>Staða áfanga getur verið:</b><br />
          <span>Áætlun(Á)</span>, &nbsp; <span>Staðfest val(V)</span>, &nbsp; <span class="studentStudyRecordOlokid">Ólokið (Ó)</span>,
          &nbsp; <span class="studentStudyRecordMetid">Metið (M)</span>, &nbsp;
          <span class="studentStudyRecordMetid">Metið án ein.(ME)</span>, &nbsp; <span class="studentStudyRecordLokid">Lokið (L)</span>,
          &nbsp; <span class="studentStudyRecordLokidAnEininga">Lokið án eininga(LE)</span>, &nbsp;
          <span class="studentStudyRecordUrsogn">Úrsögn(Ú)</span>, &nbsp; 
          <!-- <span class="studentStudyRecordFall">Mat Fall(MF)</span>, &nbsp; -->
          <span class="studentStudyRecordFall">Féll(F)</span>
          <!--
          <span class="studentStudyRecordOlokid">Ólokið (Ó)</span>, &nbsp; <span class="studentStudyRecordMetid">Metið
            (M)</span>, &nbsp;
          <span class="studentStudyRecordLokid">Lokið (L)</span>, &nbsp;
          <span class="studentStudyRecordLokidAnEininga">Lokið án eininga(LE)</span>, &nbsp;
          <span class="studentStudyRecordFall">Mat Fall(MF)</span>, &nbsp; <span
            class="studentStudyRecordFall">Fall(F)</span>, &nbsp;
          <span class="studentStudyRecordFall">Fall á önn(FÖ)</span> -->
          <br />
        </div>
      </div>
    </div>
    <div v-if="type === '3'" style="border: 2px solid #ddd" class="p-3 mt-3">
      <strong>Tengja áfanga inn á skírteini brautar frá annarri braut nemanda</strong>
      <b-form-group label="Afrita tengda áfanga frá braut " label-for="course">
        <v-select
          id="course"
          name="course"
          placeholder="Veldu braut"
          v-model="selected.course"
          :options="courses"
          :clearable="false"
          @input="hasMadeChanges = true"
        >
        </v-select>
      </b-form-group>
      <b-form-group label="Uppbygging (áfanga sem ekki eru í brautarkröfu)" label-for="structure">
        <v-select
          id="structure"
          name="structure"
          placeholder="Veldu uppbyggingu"
          v-model="selected.structure"
          :options="structures"
          :clearable="false"
          @input="hasMadeChanges = true"
        >
        </v-select>
      </b-form-group>
    </div>
    <b-btn
      variant="primary"
      class="mt-3"
      @click="saveRequirements()"
      v-if="type && !loading.requirements"
      :disabled="(type === '3' && !selected.course) || saving"
    >
      <i class="fa fa-spin fa-spinner" v-if="saving"></i> Staðfesta
    </b-btn>
  </Slidebar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import students from '@/api/students';
import curriculum from '@/api/curriculum';
import Loader from '@/components/common/Loader.vue';
import Slidebar from '@/components/common/Slidebar.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';

export default {
  name: 'study-record-course-change-modules-slidebar',
  props: ['studentId', 'courseId', 'studentCourseId', 'gratuationFinished'],
  components: {
    Loader,
    Slidebar,
    LinkOrder,
  },
  computed: {
    getCourseDefinedSelection() {
      return this.structures.filter(x => x.id > 0);
    },
    getTotalSelected() {
      // Taka í burtu 9 og 181 sem er lokið/metið án eininga
      return (this.requirements || [])
        .filter(x => x.stada_afanga !== 9 && x.stada_afanga !== 181 && !x.trep_skirteini && x.einingar_skirteini > 0)
        .map(x => x.einingar_skirteini)
        .reduce((a, b) => a + b, 0);
    },
    getTotalSelectedTrep() {
      // Taka í burtu 9 og 181 sem er lokið/metið án eininga
      return (this.requirements || [])
        .filter(x => x.stada_afanga !== 9 && x.stada_afanga !== 181 && x.trep_skirteini > 0 && x.einingar_skirteini > 0)
        .map(x => x.einingar_skirteini)
        .reduce((a, b) => a + b, 0);
    },
    afangaskoli() {
      return this.schoolSettings && this.schoolSettings.afangaskoli ? this.schoolSettings.afangaskoli === 1 : false;
    },
    getCourseModules() {
      return this.requirements
        .filter(x => x.afangi_id_braut && x.stadid < 1 && x.stada_afanga !== 1)
        .map(x => ({
          ...x,
          label: x.afangi_brautar + (x.afangi_skirteini ? `(${x.afangi_skirteini})` : ''),
        }));
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {
      hasMadeChanges: false,
      type: '',
      saving: false,
      courses: [],
      structures: [],
      requirements: [],
      notCertificate: [],
      otherSchools: [],
      selected: {
        course: '',
        structure: '',
      },
      loading: {
        course: false,
        structure: false,
        requirements: false,
        notCertificate: false,
        otherSchools: false,
      },
      selectedAllOtherSchools: false,
      selectedNotCertificate: false,
    };
  },
  methods: {
    getModules(item) {
      const modules = [];
      if (item.afangi_id) {
        modules.push({
          afangi_id: item.afangi_id,
          namsgrein_afanganr: item.afangi,
          trep: item.trep,
          einingar: item.einingar,
        });
      }
      if (item.afangi_id && item.afangi_id_skirteini && item.afangi_id !== item.afangi_id_skirteini) {
        modules.push({
          afangi_id: item.afangi_id_skirteini,
          namsgrein_afanganr: item.afangi_skirteini,
          trep: item.trep_skirteini,
          einingar: item.einingar_skirteini,
        });
      }
      if (
        item.afangi_id &&
        item.afangi_id_braut &&
        item.afangi_id_skirteini &&
        item.afangi_id !== item.afangi_id_braut &&
        item.afangi_id_braut !== item.afangi_id_skirteini
      ) {
        modules.push({
          afangi_id: item.afangi_id_braut,
          namsgrein_afanganr: item.afangi_brautar,
          trep: item.trep,
          einingar: item.einingar,
        });
      }
      return modules.concat(
        item.modules
          .filter(y => modules.map(z => z.afangi_id).indexOf(y.afangi_id) === -1)
          .map(x => ({
            afangi_id: x.afangi_id,
            namsgrein_afanganr: x.namsgrein_afanganr,
            trep: x.trep,
            einingar: x.einingar,
          })),
      );
    },
    updateSelection(oldItem, event) {
      let conf = true;
      if (this.hasMadeChanges) {
        conf = confirm('Ath. þú átt eftir að staðfesta breytingarnar sem þú gerðir. Ertu viss um að þú viljir skipta?'); // eslint-disable-line
      }
      if (conf) {
        this.hasMadeChanges = false;
        this.type = event;
        if (this.type === '0') {
          this.loadRequirements();
        } else if (this.type === '1') {
          this.loadNotCertificate();
          if (this.requirements.length === 0) {
            this.loadRequirements();
          }
        } else if (this.type === '2') {
          this.loadOtherSchools();
        }
      }
    },
    updateAllNotCertificate() {
      this.notCertificate.forEach(x => {
        this.$set(x, 'selected', this.selectedNotCertificate);
      });
    },
    updateAllOtherSchools() {
      this.otherSchools.forEach(x => {
        this.$set(x, 'selected', this.selectedAllOtherSchools);
      });
    },
    async loadCourses() {
      try {
        this.loading.courses = true;
        const response = await students.getStudentCourseList(this.studentId);
        this.courses = response.data.items
          .filter(x => x.braut_id !== this.courseId)
          .map(x => ({
            ...x,
            label: `${x.braut_kodi} ${x.heiti}`,
          }));
        if (this.courses.length > 0) {
          this.selected.course = this.courses[0];
          this.loadStructure();
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.courses = false;
      }
    },
    async loadStructure() {
      try {
        this.loading.structure = true;
        const response = await curriculum.getCourseStructureModules(this.courseId);
        this.structures = response.data.items
          .filter(x => x.tegund === 3)
          .map(x => ({
            id: x.braut_uppbygg_id,
            label: x.uppbygging_heiti,
            uppbygging: x.tegund,
          }));
        this.structures.unshift({ id: null, label: 'Val' });
        this.selected.structure = this.structures[0];
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.structure = false;
      }
    },
    async loadRequirements() {
      try {
        this.loading.requirements = true;
        const response = await students.getStudyRecordCourseModules(this.studentId, this.courseId);
        this.requirements = response.data.items.map(x => ({
          ...x,
          changed: false,
          inactive: x.ovirk === 1,
          map: false,
          modules: [...x.afangar],
          selectedModule: x.afangi_skirteini
            ? {
                afangi_id: x.afangi_id_skirteini,
                namsgrein_afanganr: x.afangi_skirteini,
                trep: x.trep_skirteini,
                einingar: x.einingar_skirteini,
              }
            : {},
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.requirements = false;
      }
    },
    async loadNotCertificate() {
      try {
        this.loading.notCertificate = true;
        const response = await students.getCourseModulesNotInCertificate(this.studentId, this.courseId);
        this.notCertificate = response.data.items.map(x => ({
          ...x,
          selected: false,
          selectedCourse: '',
          structure: '',
          map: false,
          useRequirements: false,
          selectedModule: '',
          evaluation: [],
        }));
        this.loadEvaluation(this.notCertificate);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.notCertificate = false;
      }
    },
    async loadOtherSchools() {
      try {
        this.loading.otherSchools = true;
        const response = await students.getCourseModulesOtherSchools(this.studentId, this.courseId);
        this.otherSchools = response.data.items
          .filter(x => !x.afangi_skirteini && !x.afangi_matskra)
          .map(x => ({
            ...x,
            selected: false,
            selectedCourse: '',
            structure: '',
            map: false,
            useRequirements: false,
            selectedModule: '',
            evaluation: [],
          }));
        if (this.requirements.length === 0) {
          await this.loadRequirements();
        }
        this.loadEvaluation(this.otherSchools, true);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.otherSchools = false;
      }
    },
    async loadEvaluation(list, otherSchools) {
      for (let i = 0; i < list.length; i += 1) {
        const response = await curriculum.getModuleEvaluation({ courseId: this.courseId, moduleName: list[i].namsgrein_afanganr }); // eslint-disable-line
        let name = list[i].namsgrein_afanganr;
        let units = list[i].einingar;
        if (otherSchools) {
          name = `${name} - ${list[i].skoli_audk}`;
          units = list[i].einingar_ferill;
        }
        const modules = [{ afangi_id: list[i].afangi_id, namsgrein_afanganr: name, einingar: units, trep: list[i].trep }].concat(
          response.data.items.filter(x => x.afangi_id !== list[i].afangi_id),
        );

        this.$set(list[i], 'evaluation', modules);
        this.$set(list[i], 'selectedModule', modules[0]);
        if (otherSchools) {
          const selectedCourse = this.getCourseModules.find(x => modules.map(y => y.afangi_id).indexOf(x.afangi_id_braut) > -1);
          if (selectedCourse) {
            this.$set(list[i], 'selectedCourse', selectedCourse);
          }
        }
      }
    },
    async saveRequirements() {
      try {
        let conf = '';
        const item = {};
        let list = [];
        switch (this.type) {
          case '0':
            conf = confirm('Ertu viss um að þú viljir vista breytingu ?'); // eslint-disable-line
            list = this.requirements.filter(x => x.disconnect || x.changed);
            break;
          case '1':
            conf = confirm('Ertu viss um að þú viljir vista breytingu ?'); // eslint-disable-line
            list = this.notCertificate.filter(x => x.selected);
            break;
          case '2':
            conf = confirm('Ertu viss um að þú viljir vista breytingu ?'); // eslint-disable-line
            list = this.otherSchools.filter(x => x.selected);
            break;
          case '3':
            conf = confirm('Ertu viss um að þú viljir tengja áfanga frá annari braut inn sem val?'); // eslint-disable-line
            item.copyCourse = 1;
            item.oldCourseId = this.selected.course.braut_id;
            item.courseStructureId = this.selected.structure ? this.selected.structure.id : null;
            break;
          default:
            conf = confirm(''); // eslint-disable-line
        }
        if (list.length > 0) {
          item.modules = list.map(x => ({
            disconnect: x.disconnect ? 1 : undefined,
            inactive: x.inactive ? 1 : 0,
            ferill_id: x.ferill_id,
            einingar_skirteini: x.selectedModule ? x.selectedModule.einingar : '',
            trep_skirteini: x.selectedModule ? x.selectedModule.trep : '',
            uppbygging: x.structure ? x.structure.uppbygging : x.uppbygging,
            afangi_id_skirteini: x.selectedModule ? x.selectedModule.afangi_id : x.afangi_id_braut,
            afangi_id_braut: x.selectedCourse ? x.selectedCourse.afangi_id_braut : x.afangi_id_braut,
            braut_uppbygg_id: x.structure ? x.structure.id : x.braut_uppbygg_id,
            afangar_a_skirteini_id: x.afangar_a_skirteini_id,
            map: x.map ? 1 : 0,
          }));
        }
        if (conf) {
          this.saving = true;
          this.hasMadeChanges = false;
          await this.$studentsApi.changeCourseRequirements({
            changeCourseRequirementsInputItem: {
              studentId: this.studentId,
              courseId: this.courseId,
              studentCourseId: this.studentCourseId,
              ...item,
            },
          });

          this.$emit('closeAndReloadSlidebar');
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.saving = false;
      }
    },
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    getStudyRecordColor(item) {
      if (item !== undefined) {
        if (item === 40) {
          // Áætlun
          return 'studentStudyRecordAetlun';
        }
        if (item === 41) {
          // Staðfest val
          return 'studentStudyRecordAetlun';
        }
        if (item === 1) {
          // Ólokið
          return 'studentStudyRecordOlokid';
        }
        if (item === 4) {
          // Metið
          return 'studentStudyRecordMetid';
        }
        if (item === 6) {
          // Lokið
          return 'studentStudyRecordLokid';
        }
        if (item === 2 || item === 3 || item === 8) {
          // Fall + Fall á önn + Mat Fall
          return 'studentStudyRecordFall';
        }
        if (item === 9 || item === 181) {
          // Lokið án eininga
          return 'studentStudyRecordLokidAnEininga';
        }
      }
      return '';
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadCourses();
  },
};
</script>

<style lang="scss" scoped>
.change-module-table-border {
  thead {
    border-bottom: 2px solid #dee2e6;

    tr {
      th {
        border-right: 1px solid #dee2e6;
        border-bottom: 1px solid #dee2e6;
      }
    }
  }

  tr {
    td {
      padding-bottom: 0px !important;
      padding-top: 0px !important;
      padding-right: 2px !important;
      padding-left: 2px !important;
    }
  }

  tbody {
    tr:nth-of-type(even) {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
}

.fellBackground {
  background-color: #ffd4d4 !important;
}
</style>
