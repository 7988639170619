<template>
  <Slidebar :large="size === 'large'" :extraLarge="size === 'extra'" @closeMe="closeSlidebar">
    <h4>Senda skilaboð</h4>
    <div class="float-right">
      <b-link @click="size = 'large'" v-if="size === 'extra'">Minnka glugga</b-link>
      <b-link @click="size = 'extra'" v-if="size === 'large'">Stækka glugga</b-link>
    </div>
    <div v-if="!loggedInUser.netfang">Ekkert netfang skráð.</div>
    <div v-else>
      <b-form @submit.prevent="validateBeforeSubmit" novalidate>
        <b-form-group
          label-for="title"
          label="Titill*"
          :state="submitted && errors.has('title') ? false : ''"
          :invalid-feedback="errors.first('title')"
        >
          <b-form-input
            id="title"
            name="title"
            v-model="mail.title"
            maxLength="200"
            :state="submitted && errors.has('title') ? false : ''"
            v-validate="'required'"
            style="height: 30px; font-size: 13px"
            data-vv-as="titill"
            ref="focusInput"
          ></b-form-input>
        </b-form-group>
        <b-form-group :state="submitted && errors.has('sms') ? false : ''" :invalid-feedback="errors.first('sms')" v-if="canSendSMS">
          <label for="sms"
            >SMS
            <i
              class="fa fa-info-circle"
              title="Ath. ef verið er að senda sms þá þarf að fylla út 'Titill' og
              'Meginmál' líka þar sem það eru alltaf send skilaboð samhliða."
              v-b-tooltip
            ></i
          ></label>
          <b-form-input
            id="sms"
            name="sms"
            v-model="mail.sms"
            maxLength="500"
            :state="submitted && errors.has('sms') ? false : ''"
            style="height: 30px; font-size: 13px"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label-for="body"
          label="Meginmál*"
          :state="submitted && errors.has('body') ? false : ''"
          :invalid-feedback="errors.first('body')"
        >
          <quill-editor
            id="body"
            name="body"
            v-model="mail.body"
            :state="submitted && errors.has('body') ? false : ''"
            ref="textQuillEditor"
            :options="editorOption"
            v-validate="'required'"
            data-vv-as="meginmál"
            @ready="quillHeight($event)"
          >
          </quill-editor>
        </b-form-group>
        <b-form-group
          :state="submitted && errors.has('copyGuardian') ? false : ''"
          :invalid-feedback="errors.first('copyGuardian')"
          class="mb-0"
        >
          <b-form-checkbox
            id="copyGuardian"
            name="copyGuardian"
            v-model="mail.copyGuardian"
            @change="updateCheckbox(0)"
            class="d-inline-block"
          ></b-form-checkbox>
          <label for="copyGuardian" class="d-inline-block" style="font-size: 13px">Senda afrit til forráðamanna og aðstandenda</label>
        </b-form-group>
        <b-form-group
          :state="submitted && errors.has('onlyGuardian') ? false : ''"
          :invalid-feedback="errors.first('onlyGuardian')"
          class="mb-0"
        >
          <b-form-checkbox
            id="onlyGuardian"
            name="onlyGuardian"
            v-model="mail.onlyGuardian"
            @change="updateCheckbox(1)"
            class="d-inline-block"
          ></b-form-checkbox>
          <label for="onlyGuardian" class="d-inline-block" style="font-size: 13px">Senda eingöngu til forráðamanna og aðstandenda</label>
        </b-form-group>
        <b-form-group :state="submitted && errors.has('getCopy') ? false : ''" :invalid-feedback="errors.first('getCopy')">
          <b-form-checkbox id="getCopy" name="getCopy" v-model="mail.getCopy" class="d-inline-block"></b-form-checkbox>
          <label for="getCopy" class="d-inline-block" style="font-size: 13px">Fá afrit af skilaboðum</label>
        </b-form-group>
        <b-row>
          <b-col class="pl-0">
            <b-form-group :state="submitted && errors.has('file') ? false : ''" :invalid-feedback="errors.first('file')">
              <label for="file"
                >Viðhengi 1
                <i class="fa fa-trash cursor-pointer" @click="mail.file = null" v-if="mail.file"></i>
              </label>
              <b-form-file
                class="singleCourseFile"
                size="sm"
                v-model="mail.file"
                placeholder="Smelltu hér til að velja skrá.."
              ></b-form-file>
            </b-form-group>
            <div v-if="!fileValid(mail.file)" class="text-danger" style="font-size: 13px">Skrá of stór. Má eingöngu vera 2MB.</div>
          </b-col>
          <b-col>
            <b-form-group :state="submitted && errors.has('file2') ? false : ''" :invalid-feedback="errors.first('file2')">
              <label for="file2"
                >Viðhengi 2
                <i class="fa fa-trash cursor-pointer" @click="mail.file2 = null" v-if="mail.file2"></i>
              </label>
              <b-form-file
                class="singleCourseFile"
                size="sm"
                v-model="mail.file2"
                placeholder="Smelltu hér til að velja skrá.."
              ></b-form-file>
            </b-form-group>
            <div v-if="!fileValid(mail.file2)" class="text-danger" style="font-size: 13px">Skrá of stór. Má eingöngu vera 2MB.</div>
          </b-col>
        </b-row>
        <b-btn variant="primary" class="mt-2" @click="validateBeforeSubmit()" :disabled="loading.recipients || saving">
          <i class="fa fa-spin fa-spinner" v-if="saving"></i>
          Senda skilaboð</b-btn
        >

        <div class="mt-3">
          <strong class="d-inline-block">{{ msgGroup }}</strong>
          <div style="padding-left: 5px; font-size: 13px" class="d-inline-block float-right">
            <b-form-checkbox v-model="mail.under18" class="d-inline-block" @change="updateUnder()"></b-form-checkbox>
            <div class="d-inline-block pr-3">Yngri en 18</div>
            <b-form-checkbox v-model="mail.over18" class="d-inline-block" @change="updateOver()"></b-form-checkbox>
            <div class="d-inline-block">18 ára og eldri</div>
          </div>
        </div>

        <Loader v-if="loading.recipients" />
        <table class="table less-padding" v-if="!loading.recipients" style="font-size: 12px; overflow-x: auto">
          <tr>
            <th><b-form-checkbox v-model="selectAll" @change="updateAll()"></b-form-checkbox></th>
            <th>Nafn ({{ recipients.length }})</th>
            <th>Kennitala</th>
            <th>Netfang</th>
            <th>Farsími</th>
            <th>Aldur</th>
            <th>Aðstandendur</th>
            <th>Netfang</th>
            <th>Farsími</th>
          </tr>
          <tr v-for="item in recipients" :key="item.notandi_id">
            <td><b-form-checkbox v-model="item.selected"></b-form-checkbox></td>
            <td style="white-space: nowrap">{{ item.nafn }}</td>
            <td>{{ item.kennitala }}</td>
            <td>{{ item.netfang }}</td>
            <td>{{ item.farsimi }}</td>
            <td>{{ item.aldur }}</td>
            <td style="white-space: nowrap">{{ item.forradamenn }}</td>
            <td style="white-space: nowrap">{{ item.forradamenn_netfong }}</td>
            <td style="white-space: nowrap">{{ item.forradamenn_farsimi }}</td>
          </tr>
        </table>
      </b-form>
    </div>
  </Slidebar>
</template>

<script>
import common from '@/api/common';

import Slidebar from '@/components/common/Slidebar.vue';
import Loader from '@/components/common/Loader.vue';
import { mapActions, mapGetters } from 'vuex';
import { quillEditor } from 'vue-quill-editor';

export default {
  name: 'send-message-slidebar',
  props: [
    'undirskoli_id',
    'stada_nema',
    'deild_id',
    'braut_id',
    'upphafsonn',
    'notendahopur_id',
    'vinnslustada_id',
    'nafn',
    'kennitala',
    'aldur',
    'kyn',
    'bekkur_id',
    'nu_onn',
    'hopur_id',
    'umsjonarkennari_id',
    'onn_id',
    'umsjon_nemanda',
    'kennari_nemanda',
    'nemandi_id', // ef á að finna kennara þess nemanda
    'notandi_id',
    'eingonguForradamenn',
  ],
  components: {
    Slidebar,
    Loader,
    quillEditor,
  },
  computed: {
    /* canEdit() {
      return this.loggedInUserHasWritePermission('beidnir_forfoll');
    }, */
    canSendSMS() {
      return this.schoolHasSettings('starfsmadur_ma_senda_sms');
    },
     isLearningCenter() {
      return this.schoolSettings && this.schoolSettings.tegund_skola_id === 4;
    },
    params() {
      return {
        undirskoli_id: this.undirskoli_id,
        stada_nema: this.stada_nema,
        deild_id: this.deild_id,
        braut_id: this.braut_id,
        upphafsonn: this.upphafsonn,
        notendahopur_id: this.notendahopur_id,
        vinnslustada_id: this.vinnslustada_id,
        nafn: this.nafn,
        kennitala: this.kennitala,
        aldur: this.aldur,
        kyn: this.kyn,
        bekkur_id: this.bekkur_id,
        nu_onn: this.nu_onn,
        hopur_id: this.hopur_id,
        umsjonarkennari_id: this.umsjonarkennari_id,
        onn_id: this.onn_id,
        umsjon_nemanda: this.umsjon_nemanda,
        kennari_nemanda: this.kennari_nemanda,
        nemandi_id: this.nemandi_id,
        notandi_id: this.notandi_id,
      };
    },
    ...mapGetters([
      'loggedInUserHasReadPermission',
      'loggedInUserHasWritePermission',
      'schoolSettings',
      'schoolHasSettings',
      'loggedInUser',
    ]),
  },
  data() {
    return {
      submitted: false,
      saving: false,
      msgGroup: '',
      recipients: [],
      size: 'large',
      loading: {
        msgGroup: false,
        recipients: false,
      },
      selectAll: true,
      mail: {
        title: '',
        body: '',
        sms: '',
        copyGuardian: true,
        onlyGuardian: false,
        getCopy: true,
        file: null,
        file2: null,
        over18: true,
        under18: true,
      },
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            ['blockquote', 'code-block'],

            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
            [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
            [{ direction: 'rtl' }], // text direction

            [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],
            ['link'],

            ['clean'],
          ],
        },
      },
    };
  },
  methods: {
    fileValid(file) {
      return !file || (file && file.size <= 2 * 1024 * 1024);
    },
    quillHeight(event) {
      event.container.style.height = '200px'; // eslint-disable-line
    },
    updateCheckbox(type) {
      if (type === 0) this.$set(this.mail, 'onlyGuardian', false);
      if (type === 1) this.$set(this.mail, 'copyGuardian', false);
    },
    updateUnder() {
      this.recipients
        .filter(x => x.aldur < 18)
        .forEach(item => {
          this.$set(item, 'selected', this.mail.under18);
        });
    },
    updateOver() {
      this.recipients
        .filter(x => x.aldur >= 18)
        .forEach(item => {
          this.$set(item, 'selected', this.mail.over18);
        });
    },
    updateAll() {
      this.recipients.forEach(item => {
        this.$set(item, 'selected', this.selectAll);
      });
    },
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    closeAndReloadSlidebar() {
      this.$emit('closeAndReload');
    },
    async loadMessageGroup() {
      try {
        this.loading.msgGroup = true;
        const response = await common.getMessageGroup(this.params);
        this.msgGroup = response.data.msg_group;
        this.mail.title = response.data.title ? `${response.data.title}  ` : '';
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.msgGroup = false;
      }
    },
    async loadMessageRecipients() {
      try {
        this.loading.recipients = true;
        const response = await common.getMessageRecipients(this.params);
        this.recipients = response.data.items.map(x => ({
          ...x,
          selected: true,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.recipients = false;
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.saving = true;
        await this.$validator.validateAll().then(response => {
          if (!response || !this.fileValid(this.mail.file) || !this.fileValid(this.mail.file2)) throw 'FormValidationError'; // eslint-disable-line no-throw-literal, max-len
        });
        // Engar villur í formi.
        try {
          let sendTo = 1;
          // Senda eingöngu á forráðamenn
          if (this.mail.copyGuardian) sendTo = 2;
          // Senda afrita á forráðamenn
          if (this.mail.onlyGuardian) sendTo = 4;
          // Sneda skilboð á umsjónarkennara nemanda eða kennara nemanda - eingöngu skólanetfag
          if (this.umsjonarkennari_id || this.kennari_nemanda) sendTo = 5;

          const users = this.recipients.filter(x => x.selected).map(x => x.notandi_id);
          if (this.mail.getCopy && users.indexOf(this.loggedInUser.notandi_id) === -1) {
            users.push(this.loggedInUser.notandi_id);
          }

          const item = {
            title: this.mail.title,
            body: this.mail.body,
            sms: this.mail.sms,
            user_ids: users,
            send_to: sendTo,
          };
          const response = await this.$messagesApi.sendMessage({
            data: JSON.stringify(item),
            file: this.mail.file || undefined,
            file2: this.mail.file2 || undefined,
          });
          this.displaySuccess(`Skilaboð fyrir ${response.data.count} viðtakendur hafa verið stofnuð og verða send.`);
          this.mail.title = '';
          this.mail.body = '';
          this.mail.sms = '';
          this.mail.file = null;
          this.mail.file2 = null;
          this.submitted = false;
          this.closeSlidebar();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.saving = false;
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.$nextTick(() => {
      if (this.$refs.focusInput) {
        this.$refs.focusInput.focus();
      }
    });
    if (this.eingonguForradamenn) {
      this.mail.copyGuardian = false;
      this.mail.onlyGuardian = true;
    }
    if (this.isLearningCenter) {
      this.mail.copyGuardian = false;
    }
    this.loadMessageGroup();
    this.loadMessageRecipients();
  },
};
</script>

<style lang="scss"></style>
