<template>
  <div>
    <b-modal
      id="jobCreateModal"
      :title="title"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Vista"
      cancel-title="Hætta við"
      @ok.prevent="validateBeforeSubmit"
      :ok-disabled="loading"
      ref="createModal"
    >
      <div>
        <b-form @submit.prevent="validateBeforeSubmit" novalidate>
          <b-form-group
            label="Heiti"
            label-for="name"
            :state="submitted && errors.has('name') ? false : ''"
            :feedback="errors.first('name')"
          >
            <b-form-input
              id="name"
              name="name"
              autofocus
              type="text"
              v-model="form.name"
              required
              :state="submitted && errors.has('name') ? false : ''"
              v-validate="'required'"
              data-vv-as="heiti"
            ></b-form-input>
          </b-form-group>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import staff from '@/api/staff';
import { mapActions } from 'vuex';

export default {
  name: 'job-create-modal',
  data() {
    return {
      loading: false,
      submitted: false,
      jobId: '',
      title: 'Stofna hlutverk',
      form: {
        name: '',
        type: 0,
      },
    };
  },
  methods: {
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.loading = true;
          await staff.updateJob(this.jobId, this.form.name, 1, this.form.type);
          if (this.jobId) {
            this.displaySuccess(`Hlutverkinu ${this.form.name} hefur verið breytt.`);
          } else {
            this.displaySuccess(`Hlutverkið ${this.form.name} hefur verið stofnað.`);
          }
          this.$emit('successful', {});
          this.$refs.createModal.hide();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    show(jobId, type, name = '') {
      this.form.name = '';
      this.form.type = type;
      this.jobId = '';
      this.title = 'Stofna hlutverk';
      this.submitted = false;
      this.loading = false;
      if (jobId) {
        this.title = 'Breyta hlutverki';
        this.jobId = jobId;
        this.form.name = name;
      }
      this.$refs.createModal.show();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
<style lang="scss"></style>
