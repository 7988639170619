<template>
  <div class="breadcrumbs-class noprint">
    <div v-for="(item, index) in breadcrumbs" :key="index" :class="{ active: isSelectedRoute(item) }">
      <div v-if="index > 0"><img src="../../assets/breadcrumb-arrow.svg" /></div>
      <div v-if="item.route">
        <b-link :to="{ name: item.route }">{{ item.name }}</b-link>
      </div>
      <div v-else>{{ item.name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'breadcrumbs',
  props: ['breadcrumbs'],
  methods: {
    isSelectedRoute(item) {
      return item.route === this.$route.name;
    },
  },
};
</script>

<style lang="scss">
.breadcrumbs-class {
  //margin-top: -45px;
  padding-bottom: 25px;
  font-size: 14px;
  color: #909090;
  line-height: 20px;
  .active {
    color: #303030;
  }
  a {
    color: inherit;
  }
  div {
    display: inline-block;
    padding-right: 5px;
  }
  img {
    margin-top: -5px;
  }
}
</style>
