<template>
  <div>
    <table class="table less-padding table-hover" style="font-size: 13px">
      <thead>
        <tr>
          <th><LinkOrder title="Áfangi" :filteredList="list" :list="list" column="namsgrein_afanganr" /> ({{ list.length }})</th>
          <th>
            <LinkOrder title="Hópur" :filteredList="list" :list="list" column="hopur" secondColumn="namsgrein_afanganr" />
          </th>
          <th>
            <LinkOrder title="Kennari" :filteredList="list" :list="list" column="kennari" secondColumn="namsgrein_afanganr" />
          </th>
          <th>
            <download-csv v-if="!loading" :data="getExcelData" :name="getExcelFileName" delimiter=";" class="cursor-pointer float-right">
              <i class="fa fa-file-excel-o excelText" />
            </download-csv>
          </th>
        </tr>
      </thead>
      <TableRowLoader v-if="loading" colspan="4" :center="true" />
      <TableRowEmpty v-if="!loading && this.list.length === 0" colspan="4" :center="true" />
      <tbody>
        <tr v-for="item in getPaginatedList" :key="item.hopur_id">
          <td>{{ item.namsgrein_afanganr }}</td>
          <td>{{ item.hopur }}</td>
          <td>{{ item.kennari }}</td>
          <td class="text-right">
            <i class="fa fa-search cursor-pointer" title="Skoða einkunnayfirlit" v-b-tooltip @click="openAssessmentSlidebar(item)"></i>
          </td>
        </tr>
      </tbody>
    </table>

    <Pagination
      :currentPage="currentPage"
      :totalRows="list.length"
      :perPage="perPage"
      v-if="list.length > 25"
      @change-page="changePage"
      @change-limit="changeLimit"
    />
    <assessment-overview-slidebar
      :groupId="selectedItem"
      :showFinalMismatch="true"
      v-if="showAssessmentSlidebar"
      :extraLarge="true"
      @closeSlidebar="closeSlidebar()"
    ></assessment-overview-slidebar>
  </div>
</template>

<script>
import moment from 'moment';
import terms from '@/api/terms';
import { mapActions, mapGetters } from 'vuex';

import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import Pagination from '@/components/common/Pagination.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';

import AssessmentOverviewSlidebar from '@/components/students/groups/grades/AssessmentOverviewSlidebar.vue';

export default {
  name: 'term-final-mismatch',
  props: ['id', 'item'],
  components: {
    AssessmentOverviewSlidebar,
    TableRowLoader,
    TableRowEmpty,
    Pagination,
    LinkOrder,
  },
  computed: {
    getExcelData() {
      const newList = [
        [],
        ['Mismunur í lokaeinkunn og einkunn í námsferli'],
        [`${this.item.undirsk_heiti}-${this.item.onn_heiti}`],
        [],
        ['Áfangi', 'Hópur', 'Kennari', 'Deild'],
        ...this.list.map(x => [x.namsgrein_afanganr, x.hopur, x.kennari, x.deild]),
      ];
      return newList;
    },
    getExcelFileName() {
      return `mismunur_i_lokaeinkunn_og_einkunn_i_namsferli${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    canRead() {
      return this.loggedInUserHasReadPermission('skolahald_annir');
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_annir');
    },
    getPaginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.list.slice(start, end);
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolHasSettings']),
  },
  data() {
    return {
      perPage: 25,
      currentPage: 1,
      loading: false,
      list: [],
      selectedItem: '',
      termModuleId: '',
      showAssessmentSlidebar: false,
    };
  },
  methods: {
    openAssessmentSlidebar(item) {
      this.selectedItem = item.hopur_id;
      this.showAssessmentSlidebar = true;
    },
    closeSlidebar() {
      this.selectedItem = '';
      this.showAssessmentSlidebar = false;
    },
    async loadGroupsFinalMismatch() {
      try {
        this.loading = true;
        const response = await terms.getGroupsFinalGradesMismatch(this.id);
        this.list = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadGroupsFinalMismatch();
  },
};
</script>
