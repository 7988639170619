<template>
  <Slidebar :autoClose="true" @closeMe="closeSlidebar">
    <h4>Virkir nemendur brautar</h4>
    <div>
      {{ course.heiti }}
      {{ course.braut_kodi }}
    </div>
    <div class="mt-3" v-if="students.length === 0">Engir virkir nemendur fundust á valinni braut</div>
    <table class="table less-padding mt-2" style="font-size: 13px" v-if="students.length > 0">
      <tr>
        <th><LinkOrder title="Nafn" :filteredList="students" :list="students" column="nafn" /> ({{ students.length }})</th>
        <th><LinkOrder title="Kennitala" :filteredList="students" :list="students" column="kennitala" secondColumn="nafn" /></th>
        <th><LinkOrder title="Nemendanr" :filteredList="students" :list="students" column="nemandi_id" secondColumn="nafn" /></th>
        <th>
          <LinkOrder title="Dags. byrjun" :filteredList="students" :list="students" column="dags_byrjun" secondColumn="nafn" />

          <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="d-inline float-right">
            <i class="fa fa-file-excel-o excelText cursor-pointer"></i>
          </download-csv>
        </th>
      </tr>
      <tr v-for="student in students" :key="student.kennitala">
        <td>
          {{ student.nafn }}
          <b-link
            :to="{ name: 'Student', params: { id: student.nemandi_id } }"
            target="_blank"
            v-if="canEdit"
            title="Skoða nemanda"
            v-b-tooltip
            ><i class="fa fa-external-link mr-1"></i
          ></b-link>
        </td>
        <td>{{ student.kennitala }}</td>
        <td>{{ student.nemandi_id }}</td>
        <td>
          {{ student.dags_byrjun | moment('DD.MM.YYYY') }}
          <span v-if="student.dags_endar"> - {{ student.dags_endar | moment('DD.MM.YYYY') }} </span>
        </td>
      </tr>
    </table>
  </Slidebar>
</template>

<script>
import moment from 'moment';
import curriculum from '@/api/curriculum';
import { mapGetters, mapActions } from 'vuex';
import Slidebar from '@/components/common/Slidebar.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';

export default {
  name: 'courses-students-slidebar',
  components: {
    Slidebar,
    LinkOrder,
  },
  props: ['courseId'],
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_brautir');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('namskra_brautir');
    },
    getExcelData() {
      const newList = [
        [],
        ['Virkir nemendur brautar'],
        [this.course.heiti],
        [],
        ['Nafn', 'Kennitala', 'Nemandanr.', 'Dags. byrjar'],
        ...this.students.map(x => [x.nafn, JSON.stringify(x.kennitala), x.nemandi_id, moment(x.dags_byrjun).format('DD.MM.YYYY')]),
      ];
      return newList;
    },
    getExcelFileName() {
      return `braut_nemendur_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      loading: {
        course: false,
        students: false,
      },
      students: [],
      course: '',
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async loadCourse() {
      try {
        this.loading.course = true;
        const response = await curriculum.getCourseById(this.courseId);
        this.course = response.data.items ? response.data.items[0] : '';
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.course = false;
      }
    },
    async loadStudents() {
      try {
        this.loading.students = true;
        const response = await curriculum.getCourseStudents(this.courseId);
        this.students = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.students = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadCourse();
    this.loadStudents();
  },
};
</script>

<style lang="scss"></style>
