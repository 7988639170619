<template>
  <div>
    <inline-edit :doEdit="edit" @editField="edit = !edit" :canEdit="canEdit" @saveField="saveField()" :inheritBackground="true">
      <span slot="content">
        <span v-if="access === 0">Enginn</span>
        <span v-if="access === 1">Les</span>
        <span v-if="access === 2">Skrif</span>
      </span>
      <span slot="input">
        <v-select id="newAccess" name="newAccess" v-model="newAccess" :options="accessTypes" label="name" :clearable="false"> </v-select>
      </span>
    </inline-edit>
  </div>
</template>

<script>
import staff from '@/api/staff';
import { mapGetters, mapActions } from 'vuex';
import InlineEdit from '@/components/common/InlineEdit.vue';

export default {
  name: 'access-item',
  props: ['job', 'type', 'access'],
  components: {
    InlineEdit,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('starfsmannahald_adgangur');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      edit: false,
      newAccess: '',
      accessTypes: [
        {
          id: 0,
          name: 'Enginn',
        },
        {
          id: 1,
          name: 'Les',
        },
        {
          id: 2,
          name: 'Skrif',
        },
      ],
    };
  },
  methods: {
    async saveField() {
      try {
        await staff.updateJobAccess(this.job, this.type, this.newAccess.id);
        this.displaySuccess('Aðgangur uppfærður');
        this.edit = false;
        this.$emit('reload');
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.newAccess = this.accessTypes.find(x => x.id === this.access);
  },
};
</script>

<style lang="scss">
.access-none {
  background-color: #ffe8e8;
}
.access-read {
  background-color: #ffffdd;
}
.access-write {
  background-color: #c9f1c9;
}
</style>
