import Vue from 'vue';
import Router from 'vue-router';
import Dashboard from '@/views/Dashboard.vue';
import Login from '@/views/Login.vue';
import Test from '@/views/Test.vue';

// Curriculum
import SingleClasses from '@/views/curriculum/SingleClasses.vue';
import SingleClassCreate from '@/components/curriculum/singleClasses/CreateClass.vue';
import SingleClassesClass from '@/components/curriculum/singleClasses/Class.vue';
import Subjects from '@/views/curriculum/Subjects.vue';
import Modules from '@/views/curriculum/Modules.vue';
import Module from '@/components/curriculum/modules/Module.vue';
import CreateModule from '@/components/curriculum/modules/CreateModule.vue';
import Courses from '@/views/curriculum/Courses.vue';
import Course from '@/components/curriculum/courses/Course.vue';
import CreateCourse from '@/components/curriculum/courses/CreateCourse.vue';
import CurriculumUpload from '@/views/curriculum/Upload.vue';

// School
import School from '@/views/school/School.vue';
import Timetable from '@/views/school/Timetable.vue';
import Terms from '@/views/school/Terms.vue';
import Term from '@/components/school/terms/Term.vue';
import CreateTerm from '@/components/school/terms/CreateTerm.vue';

// Staff
import Staff from '@/views/staff/Staff.vue';
import StaffDetail from '@/components/staff/staff/StaffDetail.vue';
import Access from '@/views/staff/Access.vue';
import AccessEditJob from '@/components/staff/access/EditJob.vue';
import AccessEditAccess from '@/components/staff/access/EditAccess.vue';
import StaffAbsenceReport from '@/views/staff/StaffAbsenceReport.vue';

// Students
import Certificates from '@/views/students/Certificates.vue';
import Groups from '@/views/students/Groups.vue';
import Group from '@/components/students/groups/group/Group.vue';
import Students from '@/views/students/Students.vue';
import Student from '@/components/students/student/Student.vue';
import Counseling from '@/views/students/Counseling.vue';
import CounselingCreateInterview from '@/components/students/counseling/CreateInterview.vue';
import CounselingInterview from '@/components/students/counseling/Interview.vue';

// Reports
import StatisticsElementary from '@/views/reports/Elementary.vue';
import InHouse from '@/views/reports/InHouse.vue';
import Worklog from '@/views/reports/Worklog.vue';
import MainSchool from '@/views/reports/MainSchool.vue';
import CoursesAnalysis from '@/views/reports/CoursesAnalysis.vue';

// Requests
import ParentTeacher from '@/views/requests/ParentTeacher.vue';
import ChangeTimetableRequests from '@/views/requests/ChangeTimetableRequests.vue';
import ReviewAbsence from '@/views/requests/ReviewAbsence.vue';
import ReviewVacation from '@/views/requests/ReviewVacation.vue';
import ChangeCourseRequests from '@/views/requests/ChangeCourseRequests.vue';
import ModuleRequests from '@/components/requests/changeTimetableRequests/ModuleRequests.vue';
import StudentRequests from '@/components/requests/changeTimetableRequests/StudentRequests.vue';
import Equipments from '@/views/requests/Equipments.vue';
import SpecialResources from '@/views/requests/SpecialResources.vue';
import Classrooms from '@/views/requests/Classrooms.vue';

import guards from './guards';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Dashboard',
      component: Dashboard,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/innskraning',
      name: 'Login',
      component: Login,
      beforeEnter: guards.logoutBefore,
    },
    // Curriculum
    {
      path: '/adalskoli',
      name: 'MainSchool',
      component: MainSchool,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/fjoldiHusi',
      name: 'InHouse',
      component: InHouse,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/greiningBrauta',
      name: 'CoursesAnalysis',
      component: CoursesAnalysis,
      beforeEnter: guards.requireAuth,
    },

    // School
    {
      path: '/skoli',
      name: 'School',
      component: School,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/stundatoflur',
      name: 'Timetable',
      component: Timetable,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/kennslutimabil',
      name: 'Terms',
      component: Terms,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/stofna-kennslutimabil',
      name: 'CreateTerm',
      component: CreateTerm,
      beforeEnter: guards.requireAuth,
    },

    {
      path: '/kennslutimabil/:id',
      name: 'Term',
      component: Term,
      beforeEnter: guards.requireAuth,
    },
    // Staff
    {
      path: '/starfsmenn',
      name: 'Staff',
      component: Staff,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/starfsmenn/:id',
      name: 'StaffDetail',
      component: StaffDetail,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/adgangsstyringar',
      name: 'Access',
      component: Access,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/forfoll',
      name: 'StaffAbsenceReport',
      component: StaffAbsenceReport,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/breytaHlutverki/:id',
      name: 'AccessEditJob',
      component: AccessEditJob,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/breytaAdgangi/:id',
      name: 'AccessEditAccess',
      component: AccessEditAccess,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/vinnuskyrslur',
      name: 'Worklog',
      component: Worklog,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/test',
      name: 'Test',
      component: Test,
      beforeEnter: guards.requireAuth,
    },
    // Students
    {
      path: '/samradsfundir',
      name: 'ParentTeacher',
      component: ParentTeacher,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/skirteini',
      name: 'Certificates',
      component: Certificates,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/hopar',
      name: 'Groups',
      component: Groups,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/hopar/:id',
      name: 'Group',
      component: Group,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/nemendur',
      name: 'Students',
      component: Students,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/nemandi/:id',
      name: 'Student',
      component: Student,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/nemandiToflubreytinga',
      name: 'ChangeTimetableRequests',
      component: ChangeTimetableRequests,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/afangiToflubreytinga/:moduleTermId',
      name: 'ModuleRequests',
      component: ModuleRequests,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/urvinnslaToflubreytinga/:term/:student',
      name: 'StudentRequests',
      component: StudentRequests,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/afgreidslaLeyfa',
      name: 'ReviewVacation',
      component: ReviewVacation,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/afgreidslaVeikinda',
      name: 'ReviewAbsence',
      component: ReviewAbsence,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/urvinnslaBrautaskipta',
      name: 'ChangeCourseRequests',
      component: ChangeCourseRequests,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/pantanirBunadar',
      name: 'Equipments',
      component: Equipments,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/pantanirStofu',
      name: 'Classrooms',
      component: Classrooms,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/serurraedi',
      name: 'SpecialResources',
      component: SpecialResources,
      beforeEnter: guards.requireAuth,
    },

    // Statistics
    {
      path: '/hagstofuskyrsla-grunnskolar',
      name: 'StatisticsElementary',
      component: StatisticsElementary,
      beforeEnter: guards.requireAuth,
    },
    // Námsráðgjöf
    {
      path: '/namsradgjof',
      name: 'Counseling',
      component: Counseling,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/stofna-vidtal/:id?',
      name: 'CounselingCreateInterview',
      component: CounselingCreateInterview,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/vidtal/:id',
      name: 'CounselingInterview',
      component: CounselingInterview,
      beforeEnter: guards.requireAuth,
    },
    // Stök námskeið
    {
      path: '/stok-namskeid',
      name: 'SingleClasses',
      component: SingleClasses,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/stofna-namskeid',
      name: 'SingleClassCreate',
      component: SingleClassCreate,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/stok-namskeid/:id',
      name: 'SingleClassesClass',
      component: SingleClassesClass,
      beforeEnter: guards.requireAuth,
    },
    // Námskrá
    {
      path: '/namsgreinar',
      name: 'Subjects',
      component: Subjects,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/afangar',
      name: 'Modules',
      component: Modules,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/afangi/:id',
      name: 'Module',
      component: Module,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/stofna-afanga',
      name: 'CreateModule',
      component: CreateModule,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/brautir',
      name: 'Courses',
      component: Courses,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/braut/:id',
      name: 'Course',
      component: Course,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/stofna-braut',
      name: 'CreateCourse',
      component: CreateCourse,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/namskra-innlestur',
      name: 'CurriculumUpload',
      component: CurriculumUpload,
      beforeEnter: guards.requireAuth,
    },
    {
      path: '/nemandi/:id',
      name: 'StudyRecords',
      component: Student,
      beforeEnter: guards.requireAuth,
    }
  ],
});
