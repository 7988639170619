<template>
  <div>
    <table class="table less-padding table-hover" style="font-size: 13px">
      <thead>
        <tr>
          <th><LinkOrder title="Nafn" :filteredList="list" :list="list" column="nafn" /> ({{ list.length }})</th>
          <th><LinkOrder title="Kennitala" :filteredList="list" :list="list" column="kennitala" /></th>
          <th><LinkOrder title="Staða nema" :filteredList="list" :list="list" column="stada_nema_heiti" /></th>
          <th>
            <LinkOrder title="Einingar - Fall" :filteredList="list" :list="list" column="einingar_fall" secondColumn="nafn" />
            ({{ getUnitsFailSum }})
          </th>
          <th>
            <LinkOrder title="Einingar - Staðið" :filteredList="list" :list="list" column="einingar_stadid" secondColumn="nafn" />
            ({{ getUnitsPassedSum }})
          </th>
          <th>
            <LinkOrder title="Áfangar - Fall" :filteredList="list" :list="list" column="afangar_fall" secondColumn="nafn" />
            ({{ getModulesFailedSum }})
          </th>
          <th>
            <LinkOrder title="Áfangar - Staðið" :filteredList="list" :list="list" column="afangar_fall" secondColumn="nafn" />
            ({{ getModulesPassedSum }})
          </th>
          <th>
            <download-csv v-if="!loading" :data="getExcelData" :name="getExcelFileName" delimiter=";" class="cursor-pointer float-right">
              <i class="fa fa-file-excel-o excelText"></i>
            </download-csv>
          </th>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td>
            <b-form-input
              v-model="search.status"
              title="Staða"
              v-b-tooltip
              style="max-width: 120px; font-size: 13px; height: 25px; padding-left: 2px"
            ></b-form-input>
          </td>
          <td>
            <div class="d-inline-block">
              <b-form-input
                v-model="search.unitsFailedFrom"
                title="Frá"
                v-b-tooltip
                style="width: 40px; font-size: 13px; height: 25px"
              ></b-form-input>
            </div>
            <div class="d-inline-block pl-2 pr-2">-</div>
            <div class="d-inline-block">
              <b-form-input
                v-model="search.unitsFailedTo"
                title="Til"
                v-b-tooltip
                style="width: 40px; font-size: 13px; height: 25px"
              ></b-form-input>
            </div>
          </td>
          <td>
            <div class="d-inline-block">
              <b-form-input
                v-model="search.unitsPassedFrom"
                title="Frá"
                v-b-tooltip
                style="width: 40px; font-size: 13px; height: 25px"
              ></b-form-input>
            </div>
            <div class="d-inline-block pl-2 pr-2">-</div>
            <div class="d-inline-block">
              <b-form-input
                v-model="search.unitsPassedTo"
                title="Til"
                v-b-tooltip
                style="width: 40px; font-size: 13px; height: 25px"
              ></b-form-input>
            </div>
          </td>
          <td>
            <div class="d-inline-block">
              <b-form-input
                v-model="search.modulesFailedFrom"
                title="Frá"
                v-b-tooltip
                style="width: 40px; font-size: 13px; height: 25px"
              ></b-form-input>
            </div>
            <div class="d-inline-block pl-2 pr-2">-</div>
            <div class="d-inline-block">
              <b-form-input
                v-model="search.modulesFailedTo"
                title="Til"
                v-b-tooltip
                style="width: 40px; font-size: 13px; height: 25px"
              ></b-form-input>
            </div>
          </td>
          <td>
            <div class="d-inline-block">
              <b-form-input
                v-model="search.modulesPassedFrom"
                title="Frá"
                v-b-tooltip
                style="width: 40px; font-size: 13px; height: 25px"
              ></b-form-input>
            </div>
            <div class="d-inline-block pl-2 pr-2">-</div>
            <div class="d-inline-block">
              <b-form-input
                v-model="search.modulesPassedTo"
                title="Til"
                v-b-tooltip
                style="width: 40px; font-size: 13px; height: 25px"
              ></b-form-input>
            </div>
          </td>
          <td></td>
        </tr>
      </thead>
      <TableRowLoader v-if="loading" colspan="6" :center="true" />
      <TableRowEmpty v-if="!loading && this.list.length === 0" colspan="4" :center="true" />
      <tbody>
        <tr v-for="(item, idx) in getPaginatedList" :key="idx">
          <td>{{ item.nafn }}</td>
          <td>{{ item.kennitala }}</td>
          <td>{{ item.stada_nema_heiti }}</td>
          <td>{{ item.einingar_fall }}</td>
          <td>{{ item.einingar_stadid }}</td>
          <td>{{ item.afangar_fall }}</td>
          <td>{{ item.afangar_stadid }}</td>
          <td></td>
        </tr>
      </tbody>
    </table>

    <Pagination
      :currentPage="currentPage"
      :totalRows="getList.length"
      :perPage="perPage"
      v-if="getList.length > 25"
      @change-page="changePage"
      @change-limit="changeLimit"
    />
  </div>
</template>

<script>
import moment from 'moment';
import terms from '@/api/terms';
import { mapActions, mapGetters } from 'vuex';

import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import Pagination from '@/components/common/Pagination.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';

export default {
  name: 'term-student-units',
  props: ['id', 'item'],
  components: {
    TableRowLoader,
    TableRowEmpty,
    Pagination,
    LinkOrder,
  },
  computed: {
    getExcelData() {
      const newList = [
        [],
        ['Einingar nemenda'],
        [`${this.item.undirsk_heiti}-${this.item.onn_heiti}`],
        [],
        ['Nafn', 'Kennitala', 'Staða nema', 'Einingar - fall', 'Einingar - staðið', 'Áfangar - fall', 'Áfangar - Staðið'],
        ...this.list.map(x => [
          x.nafn,
          x.kennitala,
          x.stada_nema_heiti,
          x.einingar_fall,
          x.einingar_stadid,
          x.afangar_fall,
          x.afangar_stadid,
        ]),
      ];
      return newList;
    },
    getExcelFileName() {
      return `nemendur_einingar_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    canRead() {
      return this.loggedInUserHasReadPermission('skolahald_annir');
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_annir');
    },
    getUnitsFailSum() {
      return this.list.map(x => x.einingar_fall).reduce((a, b) => a + b, 0);
    },
    getUnitsPassedSum() {
      return this.list.map(x => x.einingar_stadid).reduce((a, b) => a + b, 0);
    },
    getModulesFailedSum() {
      return this.list.map(x => x.afangar_fall).reduce((a, b) => a + b, 0);
    },
    getModulesPassedSum() {
      return this.list.map(x => x.afangar_stadid).reduce((a, b) => a + b, 0);
    },
    getList() {
      return this.list
        .filter(
          x => this.search.status.length === 0 || (x.stada_nema_heiti || '').toUpperCase().startsWith(this.search.status.toUpperCase()),
        )
        .filter(x => !this.search.unitsFailedFrom || x.einingar_fall >= this.search.unitsFailedFrom)
        .filter(x => !this.search.unitsFailedTo || x.einingar_fall <= this.search.unitsFailedTo)
        .filter(x => !this.search.unitsPassedFrom || x.einingar_stadid >= this.search.unitsPassedFrom)
        .filter(x => !this.search.unitsPassedTo || x.einingar_stadid <= this.search.unitsPassedTo)
        .filter(x => !this.search.modulesFailedFrom || x.afangar_fall >= this.search.modulesFailedFrom)
        .filter(x => !this.search.modulesFailedTo || x.afangar_fall <= this.search.modulesFailedTo)
        .filter(x => !this.search.modulesPassedFrom || x.afangar_stadid >= this.search.modulesPassedFrom)
        .filter(x => !this.search.modulesPassedTo || x.afangar_stadid <= this.search.modulesPassedTo);
    },
    getPaginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.getList.slice(start, end);
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolHasSettings']),
  },
  data() {
    return {
      perPage: 25,
      currentPage: 1,
      loading: false,
      list: [],
      search: {
        status: '',
        unitsPassedFrom: '',
        unitsPassedTo: '',
        unitFailedFrom: '',
        unitFailedTo: '',
        modulesPassedFrom: '',
        modulesPassedTo: '',
        modulesFailedFrom: '',
        modulesFailedTo: '',
      },
    };
  },
  methods: {
    async loadStudentUnits() {
      try {
        this.loading = true;
        const response = await terms.getStudentUnits(this.id);
        this.list = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadStudentUnits();
  },
};
</script>
