<template>
  <div>
    <Loader v-if="loading" />
    <div v-if="batchjobs.length === 0 && !loading">Engar runuvinnslur fundust fyrir seinustu 100 daga</div>
    <table class="table less-padding" style="font-size: 13px" v-if="batchjobs.length > 0">
      <thead>
        <tr>
          <th>Heiti</th>
          <th>Dags. skráð</th>
          <th>Dags. keyrt</th>
          <th>Dags. lokið</th>
          <th>Staða</th>
          <th>Keyrt af</th>
          <th><i @click="loadBatchJobs()" class="fa fa-refresh cursor-pointer text-primary" title="Endurhlaða" v-b-tooltip></i></th>
        </tr>
      </thead>
      <tr v-for="batch in batchjobs" :key="batch.runuvinnsla_id">
        <td>{{ batch.heiti }}</td>
        <td>{{ batch.dags_skrad | moment('DD.MM.YYYY HH:mm:ss') }}</td>
        <td>{{ batch.dags_keyrt | moment('DD.MM.YYYY HH:mm:ss') }}</td>
        <td>{{ batch.dags_lokid | moment('DD.MM.YYYY HH:mm:ss') }}</td>
        <td>{{ batch.stada_heiti }}</td>
        <td>{{ batch.keyrt_af_nafn }}</td>
        <td>
          <i @click="removeJob(batch)" class="fa fa-trash cursor-pointer text-primary" v-if="batch.stada === 1 && batch.is_you === 1"></i>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import common from '@/api/common';
import Loader from '@/components/common/Loader.vue';

export default {
  name: 'batchjobs',
  props: ['name', 'term', 'type', 'refresh'],
  components: {
    Loader,
  },
  data() {
    return {
      loading: false,
      batchjobs: [],
    };
  },
  methods: {
    async loadBatchJobs() {
      try {
        this.loading = true;
        const response = await common.getBatchjobs({ batchname: this.name, term: this.term, type: this.type });
        this.batchjobs = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async removeJob(batch) {
      try {
        await common.removeBatchJob(batch.runuvinnsla_id);
        this.loadBatchJobs();
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
  },
  watch: {
    refresh() {
      if (this.refresh) {
        this.loadBatchJobs();
      }
    },
  },
  created() {
    this.loadBatchJobs();
  },
};
</script>

<style lang="scss"></style>
