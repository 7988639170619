<template>
  <div>
    <div v-if="selectedClassroom" class="pb-3 noprint">
      <span class="cursor-pointer" @click="openSearchSlidebar()"> Stofa: {{ classroom.stofa_eink }} - {{ classroom.stofa_heiti }} </span>
      <b-btn
        variant="primary"
        size="sm"
        style="padding: 2px 4px; margin-top: -5px"
        title="Breyta um stofu"
        v-b-tooltip
        @click="openSearchSlidebar()"
        ><i class="fa fa-exchange"></i
      ></b-btn>
    </div>
    <h5 v-if="selectedClassroom" class="onlyprint" style="position: absolute; left: 0; font-weight: bold">{{ classroom.nafn }}</h5>

    <ClassroomSearch :slidebar="true" @changeClassroom="selectClassroom" v-if="!selectedClassroom" />
    <Timetable :id="selectedClassroom" type="classroom" v-if="selectedClassroom"></Timetable>
    <classroom-search-slidebar @closeSlidebar="selectClassroom" v-if="showSearchSlidebar"> </classroom-search-slidebar>
  </div>
</template>

<script>
import structure from '@/api/structure';
import { mapActions, mapGetters } from 'vuex';
import ClassroomSearch from '@/components/school/school/buildingsclassrooms/ClassroomSearch.vue';
import Timetable from '@/components/common/Timetable.vue';
import ClassroomSearchSlidebar from '@/components/school/school/buildingsclassrooms/ClassroomSearchSlidebar.vue';

export default {
  name: 'classroomTimetable',
  components: {
    ClassroomSearch,
    Timetable,
    ClassroomSearchSlidebar,
  },
  computed: {
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      selectedClassroom: null,
      classroom: '',
      showSearchSlidebar: false,
    };
  },
  methods: {
    openSearchSlidebar() {
      this.showSearchSlidebar = true;
    },
    selectClassroom(item) {
      if (item) {
        this.selectedClassroom = item;
        this.loadDetails();
      }
      this.showSearchSlidebar = false;
    },
    async loadDetails() {
      try {
        this.loading = true;
        const response = await structure.getClassroomDetail(this.selectedClassroom);
        this.classroom = response.data.items.length > 0 ? response.data.items[0] : '';
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
