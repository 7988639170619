<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div v-if="!loading && list.length === 0" class="mb-2">Engin einkunnaregla fannst</div>
    <table class="table less-padding gradeRuleListTable" style="font-size: 13px" v-if="list.length > 0">
      <thead style="background-color: transparent">
        <tr>
          <th>Nr.</th>
          <th><span v-if="isModule">Tegund einkunnar</span><span v-else>Heiti</span></th>
          <th>
            Tegund
            <i
              class="fa fa-fw fa-info-circle"
              v-b-tooltip.hover.html
              title="Reiknuð: Þá eru margar undireinkunnir sem reiknast upp í.<br/> Skráð: Þá er hægt að skrá einkunn beint á einkunnaþátt."
            ></i>
          </th>
          <th>Vegur upp í</th>
          <th>Vægi</th>
          <th>Lokavægi</th>
          <th v-if="isModule">Skali</th>
          <th>
            Fj. bestu undirprófa
            <i class="fa fa-fw fa-info-circle" v-b-tooltip.hover title="Dæmi þá gilda 4 hæstu einkunnirnar af 5 mögulegum."></i>
          </th>
          <th v-if="isGroup">Lágmarkseinkunn</th>
          <th v-if="isModule">Fall í lokaeink</th>
          <th>Lokaeinkunn <span v-if="isModule">(flutt í feril)</span></th>
          <th>
            Röð
            <i class="fa fa-fw fa-info-circle" v-b-tooltip.hover title="Þetta stýrir í hvaða röð einkunnir birtast í einkunnayfirliti."></i>
          </th>
          <th v-if="isModule">
            Athugasemd <i class="fa fa-fw fa-info-circle" v-b-tooltip.hover title="Þessi athugasemd birtist ekki nemendum"></i>
          </th>
          <th v-if="isModule">Skráð af</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <grade-rule-list-item
          v-for="(item, index) in list"
          :key="index"
          :termModuleId="termModuleId"
          :groupId="groupId"
          :list="list"
          :item="item"
          :edit="edit"
          @reloadData="reloadData()"
          @editSlidebar="openSlidebar(item, false)"
          @addGradesSlidebar="openSlidebar(item, true)"
        >
        </grade-rule-list-item>
      </tbody>
    </table>
    <grade-rule-edit-slidebar
      :item="selectedItem"
      :termModuleId="termModuleId"
      :groupId="groupId"
      :finalGradeId="finalGradeId"
      :defaultAddGrades="addGradesDefaultSlidebar"
      v-if="showEditSlidebar"
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
    ></grade-rule-edit-slidebar>
  </div>
</template>

<script>
import terms from '@/api/terms';
import groups from '@/api/groups';
import { mapActions, mapGetters } from 'vuex';
import Loader from '@/components/common/Loader.vue';
import GradeRuleEditSlidebar from '@/components/school/terms/graderules/GradeRuleEditSlidebar.vue';
import GradeRuleListItem from '@/components/school/terms/graderules/GradeRuleListItem.vue';

export default {
  name: 'graderule',
  props: ['termModuleId', 'groupId', 'reload', 'edit'],
  components: {
    GradeRuleEditSlidebar,
    Loader,
    GradeRuleListItem,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_annir');
    },
    isGroup() {
      return this.groupId !== undefined;
    },
    isModule() {
      return this.termModuleId !== undefined;
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolHasSettings']),
  },
  data() {
    return {
      list: [],
      loading: false,
      selectedItem: null,
      showEditSlidebar: false,
      finalGradeId: null,
      addGradesDefaultSlidebar: false,
    };
  },

  methods: {
    reloadData() {
      this.$emit('reloadOthers');
      this.loadRule();
    },
    openSlidebar(item, addGradesDefault) {
      this.selectedItem = item;
      this.showEditSlidebar = true;
      this.addGradesDefaultSlidebar = addGradesDefault;
    },
    closeSlidebar() {
      this.showEditSlidebar = false;
      this.selectedItem = null;
    },
    async closeAndReloadSlidebar() {
      this.closeSlidebar();
      this.reloadData();
    },
    async loadRule() {
      try {
        this.loading = true;
        let response = null;
        if (this.termModuleId) {
          response = await terms.getTermModuleGraderule(this.termModuleId);
          this.finalGradeId = response.data.items.find(x => x.lokaeinkunn);
        } else if (this.groupId) {
          response = await groups.getGroupGradeRule(this.groupId);
        }
        if (response) {
          this.list = response.data.items;
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    reload() {
      if (this.reload) {
        this.loadRule();
      }
    },
    termModuleId() {
      this.loadRule();
    },
    groupId() {
      this.loadRule();
    },
  },
  created() {
    this.loadRule();
  },
};
</script>
<style lang="scss">
table.gradeRuleListTable {
  tr {
    td {
      border-right: 2px solid #fff;
    }
  }
}
</style>
