<template>
  <tr>
    <td :colspan="colspan" class="table-empty" :class="{ 'text-center': center }">Leitaðu til þess að fá niðurstöður</td>
  </tr>
</template>

<script>
export default {
  name: 'table-row-search-for-results',
  props: ['colspan', 'center'],
};
</script>

<style lang="scss" scoped>
.table-empty {
  font-style: italic;
}
</style>
