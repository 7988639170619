<template>
  <div>
    <div v-if="this.list.length === 0">
      <p>Hvað er bundið val?</p>
      <p>Bundið val er þegar skólinn skilgreinir sérstaklega hvaða valáfanga nemendur mega velja um.</p>
      <p>Ef nemendur mega ekki breyta hvenær þeir taka áfanga í uppbyggingu brautar en þeir mega velja um valfög þá er það gert hérna.</p>
      <p>
        Um leið og búið er að velja áfanga hérna geta nemendur ekki valið aðra áfanga en þá sem eru skilgreindir hérna og þeir uppfylla
        kröfur fyrir.
      </p>
      <p>Athugið! Til þess að nemendur geti breytt námsferilsáætlun þarf að opna fyrir það sérstaklega undir stýringar</p>

      <b-btn variant="primary" v-if="canEdit" @click="openSlidebar()">
        <i class="fa fa-plus-circle"></i> Smelltu hér til að velja áfanga sem bundið val
      </b-btn>
    </div>
    <table class="table less-padding" style="font-size: 13px" v-if="this.list.length > 0">
      <thead>
        <tr class="table-search">
          <td colspan="6">
            <b-form-input
              v-model="search.search"
              class="search"
              @input="currentPage = 1"
              style="margin-top: 0px; margin-bottom: 15px"
              placeholder="Leita"
            />
          </td>
          <td :class="{ 'pb-0': detailedSearch }">
            <div class="cursor-pointer text-right pt-2" @click="detailedSearch = !detailedSearch">
              <span v-if="detailedSearch"><i class="fa fa-angle-up"></i> Fela ítarleit</span>
              <span v-else><i class="fa fa-angle-up"></i> Sýna ítarleit</span>
            </div>
          </td>
        </tr>
        <tr v-if="detailedSearch" class="table-detail-search">
          <td colspan="2">
            <b-form-group label="Áfangi" label-for="module">
              <b-form-input id="module" name="module" v-model="search.module" style="height: 30px"></b-form-input>
            </b-form-group>
          </td>
          <td>
            <b-form-group label="Ár/kennslutímabil nemanda" label-for="year">
              <v-select id="year" name="year" v-model="search.year" :options="yearList" label="skolaar" :clearable="true"> </v-select>
            </b-form-group>
          </td>
          <td>
            <b-form-group label="Braut" label-for="course">
              <v-select id="course" name="course" v-model="search.course" :options="courseList" label="braut_heiti" :clearable="true">
              </v-select>
            </b-form-group>
          </td>
          <td colspan="2">
            <b-form-group label="Lína" label-for="line">
              <v-select id="line" name="line" v-model="search.line" :options="lineList" label="uppbygging_heiti" :clearable="true">
              </v-select>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <th style="white-space: nowrap">
            <b-form-checkbox v-if="canEdit" v-model="allSelected" @input="updateSelected()" class="d-inline-block"></b-form-checkbox>
            <b-btn
              v-if="nrSelected > 0 && canEdit"
              @click="massEdit()"
              variant="primary"
              class="mr-1 btn-xs"
              style="font-size: 13px"
              v-b-tooltip
              title="Breyta"
            >
              <i class="fa fa-pencil" />
            </b-btn>
            <b-btn
              v-if="nrSelected > 0 && canEdit"
              @click="massDelete()"
              variant="danger"
              class="mr-1 btn-xs"
              style="font-size: 13px"
              v-b-tooltip
              title="Eyða"
            >
              <i class="fa fa-trash" />
            </b-btn>
          </th>
          <th>
            <LinkOrder title="Áfangi" :filteredList="getList" :list="list" column="namsgrein_afanganr" />
            ({{ getList.length }})
          </th>
          <th>
            <LinkOrder
              title="Ár/kennslutímabil nemanda"
              :filteredList="getList"
              :list="list"
              column="skolaar"
              secondColumn="namsgrein_afanganr"
            />
          </th>
          <th>
            <LinkOrder title="Braut" :filteredList="getList" :list="list" column="braut_heiti" secondColumn="namsgrein_afanganr" />
          </th>
          <th>
            <LinkOrder title="Lína" :filteredList="getList" :list="list" column="uppbygging_heiti" secondColumn="namsgrein_afanganr" />
          </th>
          <th>
            <LinkOrder title="Dags.til" :filteredList="getList" :list="list" column="dags_til" secondColumn="namsgrein_afanganr" />
          </th>
          <th>
            <b-btn variant="primary" v-if="canEdit" class="btn-xs float-right" @click="openSlidebar()">
              <i class="fa fa-plus-circle"></i> Bæta við
            </b-btn>
          </th>
        </tr>
      </thead>
      <tbody>
        <TableRowLoader v-if="loading" colspan="7" :center="true" />
        <TableRowEmpty v-if="!loading && this.list.length === 0" colspan="7" :center="true" />
        <tr v-for="item in getPaginatedList" :key="item.id">
          <td style="width: 2%">
            <b-form-checkbox v-model="item.selected" v-if="canEdit"></b-form-checkbox>
          </td>
          <td>
            {{ item.namsgrein_afanganr }}
            <i
              class="fa fa-remove text-danger"
              v-if="item.is_available === 0"
              v-b-tooltip
              title="Áfangi ekki í boði á kennslutímabilinu. Hann mun ekki birtast í vali."
            ></i>
          </td>
          <td>{{ item.skolaar }}</td>
          <td>{{ item.braut_heiti }}</td>
          <td>{{ item.uppbygging_heiti }}</td>
          <td>{{ item.dags_til | moment('DD.MM.YYYY HH:mm:ss') }}</td>
          <td>
            <div class="float-right">
              <b-btn
                variant="primary"
                class="btn-xs mr-2"
                @click="addToTermModules(item)"
                v-if="canEdit && item.is_available === 0"
                title="Setja áfanga í boði"
                v-b-tooltip
                ><i class="fa fa-retweet"></i
              ></b-btn>
              <b-btn variant="primary" class="btn-xs mr-2" v-if="canEdit" @click="openSlidebar(item)"><i class="fa fa-pencil"></i></b-btn>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <Pagination
      :currentPage="currentPage"
      :totalRows="getList.length"
      :perPage="perPage"
      v-if="list.length > 25"
      @change-page="changePage"
      @change-limit="changeLimit"
    ></Pagination>
    <TermRestrictedChoiceSlidebar
      v-if="showSlidebar"
      :termId="id"
      :item="selectedItem"
      :items="selectedItems"
      @closeSlidebar="closeSlidebar"
    ></TermRestrictedChoiceSlidebar>
  </div>
</template>

<script>
import terms from '@/api/terms';
import { mapActions, mapGetters } from 'vuex';

import LinkOrder from '@/components/common/LinkOrder.vue';
import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import Pagination from '@/components/common/Pagination.vue';

import TermRestrictedChoiceSlidebar from '@/components/school/terms/TermRestrictedChoiceSlidebar.vue';

export default {
  name: 'term-restricted-choice',
  props: ['id', 'termPassed'],
  components: {
    LinkOrder,
    TableRowLoader,
    TableRowEmpty,
    Pagination,
    TermRestrictedChoiceSlidebar,
  },
  computed: {
    getList() {
      return this.list
        .filter(
          x =>
            this.search.search.length === 0 ||
            (x.namsgrein_afanganr || '').toUpperCase().indexOf(this.search.search.toUpperCase()) > -1 ||
            (x.braut_heiti || '').toUpperCase().indexOf(this.search.search.toUpperCase()) > -1 ||
            (x.uppbygging_heiti || '').toUpperCase().indexOf(this.search.search.toUpperCase()) > -1 ||
            x.skolaar.toString() === this.search.search,
        )
        .filter(
          x => this.search.module.length === 0 || (x.namsgrein_afanganr || '').toUpperCase().indexOf(this.search.module.toUpperCase()) > -1,
        )
        .filter(x => !this.search.year || x.skolaar === this.search.year.skolaar)
        .filter(x => !this.search.course || x.braut_id === this.search.course.braut_id)
        .filter(x => !this.search.line || x.braut_uppbygg_id === this.search.line.braut_uppbygg_id);
    },
    getPaginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.getList.slice(start, end);
    },
    nrSelected() {
      return this.selected.length;
    },
    selected() {
      return this.list.filter(x => x.selected);
    },
    canRead() {
      return this.loggedInUserHasReadPermission('skolahald_annir');
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_annir') && !this.termPassed;
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolHasSettings']),
  },
  data() {
    return {
      perPage: 25,
      currentPage: 1,
      loading: false,
      showSlidebar: false,
      selectedItem: null,
      allSelected: false,
      list: [],
      search: {
        module: '',
        year: '',
        course: '',
        line: '',
        search: '',
      },
      detailedSearch: false,
      courseList: [],
      lineList: [],
      yearList: [],
    };
  },
  methods: {
    async loadList() {
      try {
        this.loading = true;
        const response = await terms.getRestrictedChoice(this.id);
        this.list = response.data.items;
        this.courseList = [...new Set(this.list.filter(x => x.braut_id).map(x => x.braut_id))].map(id => ({
          braut_id: id,
          braut_heiti: this.list.find(x => x.braut_id === id).braut_heiti,
        }));
        this.lineList = [...new Set(this.list.filter(x => x.braut_uppbygg_id).map(x => x.braut_uppbygg_id))].map(id => ({
          braut_uppbygg_id: id,
          uppbygging_heiti: this.list.find(x => x.braut_uppbygg_id === id).uppbygging_heiti,
        }));
        this.yearList = [...new Set(this.list.filter(x => x.skolaar).map(x => x.skolaar))].map(id => ({
          skolaar: id,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async addToTermModules(item) {
      try {
        const data = {
          onn_id: this.id,
          afangi_id: item.afangi_id,
        };
        await terms.updateTermModule(0, data);
        this.displaySuccess('Áfangi settur í boði');
        this.loadList();
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.saving = false;
      }
    },
    async massDelete() {
      const conf = confirm(`Ert þú viss um að eyða ${this.nrSelected} völdum áföngum sem bundið val?`); // eslint-disable-line
      if (conf) {
        try {
          const promises = [];
          this.selected.forEach(s => {
            promises.push(terms.updateRestrictedChoice(this.id, s.val_id, { delete: 1 }));
          });

          Promise.all(promises).then(
            () => {
              this.displaySuccess('Áföngum hefur verið eytt sem bundið val');
              this.loadList();
            },
            e => {
              this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            },
          );
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    massEdit() {
      this.selectedItems = this.selected;
      this.showSlidebar = true;
    },
    openSlidebar(item) {
      this.showSlidebar = true;
      this.selectedItem = item;
    },
    closeSlidebar() {
      this.showSlidebar = false;
      this.selectedItem = null;
      this.selectedItems = null;
      this.loadList();
    },
    updateSelected() {
      this.list.forEach(e => {
        this.$set(e, 'selected', this.allSelected);
      });
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    id() {
      this.loadList();
    },
  },
  created() {
    if (this.id) {
      this.loadList();
    }
  },
};
</script>
