<template>
  <Slidebar @closeMe="closeSlidebar" :large="large" :extraLarge="extraLarge">
    <div class="d-inline-block mt-2">
      <h4 v-if="id">{{ item.heiti }}</h4>
      <h4 v-else>Stofna tegund einkunna</h4>
    </div>
    <div class="d-inline-block float-right mt-2">
      <b-btn variant="primary" size="sm" v-if="!id" @click="addItem()" title="Bæta við tegund" v-b-tooltip
        ><i class="fa fa-plus-circle"></i
      ></b-btn>
    </div>
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <div class="alert alert-danger p-2" v-if="hasDuplicates">
        <i class="fa fa-exclamation-triangle"></i>
        Ath. Heiti er einkvæmt. Tvær tegundir mega ekki heita það sama.
      </div>
      <div v-for="(g, idx) in items" :key="idx" :class="{ 'border-bottom border-primary mb-3': idx !== items.length - 1 && !afangaskoli }">
        <table class="table less-padding no-border">
          <tr>
            <td>Heiti*</td>
            <td>
              <b-form-group
                :label-for="`heiti${idx}`"
                :state="submitted && errors.has(`heiti${idx}`) ? false : ''"
                :invalid-feedback="errors.first(`heiti${idx}`)"
              >
                <b-form-input
                  :id="`heiti${idx}`"
                  :name="`heiti${idx}`"
                  type="text"
                  v-model="g.heiti"
                  @change="validateCode(g)"
                  :state="(submitted && errors.has(`heiti${idx}`)) || g.codeExists ? false : ''"
                  maxLength="40"
                  v-validate="'required'"
                  ref="focusInput"
                  data-vv-as="heiti"
                  style="height: 30px"
                ></b-form-input>
              </b-form-group>
              <div class="text-danger" style="font-weight: bold; font-size: 13px" v-if="g.codeExists">
                Ath. það er nú þegar til tegund einkunnar með þessu heiti.
              </div>
            </td>
          </tr>
          <tr v-if="!afangaskoli">
            <td>Röð í námsferli</td>
            <td>
              <b-form-group
                :label-for="`rod${idx}`"
                :state="submitted && errors.has(`rod${idx}`) ? false : ''"
                :invalid-feedback="errors.first(`rod${idx}`)"
              >
                <b-form-input
                  :id="`rod${idx}`"
                  :name="`rod${idx}`"
                  type="number"
                  v-model="g.rod"
                  :state="submitted && errors.has(`rod${idx}`) ? false : ''"
                  min="1"
                  data-vv-as="röð"
                  style="height: 30px"
                ></b-form-input>
              </b-form-group>
            </td>
          </tr>
          <tr v-if="!afangaskoli">
            <td>Kóði</td>
            <td>
              <b-form-group
                :label-for="`kodi${idx}`"
                :state="submitted && errors.has(`kodi${idx}`) ? false : ''"
                :invalid-feedback="errors.first(`kodi${idx}`)"
              >
                <b-form-input
                  :id="`kodi${idx}`"
                  :name="`kodi${idx}`"
                  type="text"
                  v-model="g.kodi"
                  :state="submitted && errors.has(`kodi${idx}`) ? false : ''"
                  maxLength="1"
                  data-vv-as="kóði"
                  style="height: 30px"
                ></b-form-input>
              </b-form-group>
            </td>
          </tr>
          <tr v-if="!afangaskoli">
            <td>Kóði í námsferil</td>
            <td>
              <b-form-group
                :label-for="`kodi_ferill${idx}`"
                :state="submitted && errors.has(`kodi_ferill${idx}`) ? false : ''"
                :invalid-feedback="errors.first(`kodi_ferill${idx}`)"
              >
                <b-form-input
                  :id="`kodi_ferill${idx}`"
                  :name="`kodi_ferill${idx}`"
                  type="text"
                  v-model="g.kodi_ferill"
                  :state="submitted && errors.has(`kodi_ferill${idx}`) ? false : ''"
                  maxLength="1"
                  data-vv-as="kóði í feril"
                  style="height: 30px"
                ></b-form-input>
              </b-form-group>
            </td>
          </tr>
          <tr v-if="!afangaskoli">
            <td>Reiknast í árseinkunn</td>
            <td>
              <b-form-group
                :label-for="`i_medal${idx}`"
                :state="submitted && errors.has(`i_medal${idx}`) ? false : ''"
                :invalid-feedback="errors.first(`i_medal${idx}`)"
              >
                <b-form-select :id="`i_medal${idx}`" :name="`i_medal${idx}`" v-model="g.i_medal" :options="options"></b-form-select>
              </b-form-group>
            </td>
          </tr>
          <tr v-if="id">
            <td>Virk</td>
            <td>
              <b-form-group :label-for="`virk${idx}`">
                <b-form-checkbox :id="`virk${idx}`" :name="`virk${idx}`" v-model="g.virk"></b-form-checkbox>
              </b-form-group>
            </td>
          </tr>
        </table>
      </div>
      <b-btn variant="primary" class="mr-2" v-if="canEdit" @click="validateBeforeSubmit" :disabled="hasDuplicates || saving">
        <span v-if="id">Breyta</span>
        <span v-else>Stofna</span>
      </b-btn>
      <b-btn variant="dark" @click="closeSlidebar">Hætta við</b-btn>
      <b-btn variant="danger" class="float-right" v-if="canEdit && id" @click="deleteGrade"> Eyða </b-btn>
    </b-form>
  </Slidebar>
</template>

<script>
import types from '@/api/types';
import { mapActions, mapGetters } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';

export default {
  name: 'grade-type-slidebar',
  props: ['item', 'large', 'extraLarge'],
  components: {
    Slidebar,
  },
  computed: {
    afangaskoli() {
      return this.schoolSettings && this.schoolSettings.afangaskoli ? this.schoolSettings.afangaskoli === 1 : false;
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    hasDuplicates() {
      const array = this.items.filter(x => (x.heiti || '').length > 0).map(x => x.heiti);
      return new Set(array).size !== array.length;
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {
      saving: false,
      loading: false,
      submitted: false,
      id: null,
      attendances: [],
      options: [
        { value: null, text: ' ' },
        { value: 0, text: 'Nei' },
        { value: 1, text: 'Já' },
      ],
      items: [
        {
          codeExists: false,
          heiti: '',
          virk: true,
          rod: '',
          kodi: '',
          kodi_ferill: '',
          i_medal: null,
        },
      ],
    };
  },
  methods: {
    addItem() {
      this.items.push({
        codeExists: false,
        heiti: '',
        virk: true,
        rod: '',
        kodi: '',
        kodi_ferill: '',
        i_medal: null,
      });
    },
    removeItem(idx) {
      this.items.splice(idx, 1);
    },
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async validateCode(g) {
      this.$set(g, 'codeExists', false);
      try {
        const response = await types.getGradeExists({ id: this.id, name: g.heiti });
        this.$set(g, 'codeExists', response.data.already_exists > 0);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadGradeDetail() {
      try {
        this.loading = true;
        const response = await types.getGradeDetail(this.id);
        if (response.data.items && response.data.items.length > 0) {
          const item = response.data.items[0];
          this.items = [
            {
              codeExists: false,
              heiti: item.heiti,
              virk: item.virk === 1,
              rod: item.rod,
              kodi: item.kodi,
              kodi_ferill: item.kodi_ferill,
              i_medal: item.i_medal !== null ? parseInt(item.i_medal, 10) : null,
            },
          ];
        }
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.saving = true;
          const q = [];
          this.items.forEach(i => {
            const data = {
              heiti: i.heiti,
              virk: i.virk ? 1 : 0,
              rod: i.rod,
              kodi: i.kodi,
              kodi_ferill: i.kodi_ferill,
              i_medal: i.i_medal !== null ? i.i_medal : '',
            };
            if (this.id) {
              q.push(types.updateGrade(this.id, data));
            } else {
              q.push(types.createGrade(data));
            }
          });
          Promise.all(q)
            .then(() => {
              if (this.id) {
                this.displaySuccess('Tegund einkunnar hefur verið breytt.');
              } else {
                this.displaySuccess('Tegund einkunnar hefur verið stofnuð');
              }
              this.$emit('closeAndReloadSlidebar');
            })
            .catch(e => {
              if (e.response && e.response.data && e.response.data.error) {
                this.displayError(e.response.data.error);
              } else {
                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
              }
            });
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.data && e.response.data.error) {
            this.displayError(e.response.data.error);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.saving = false;
      }
    },
    async deleteGrade() {
      try {
        const conf = confirm(`Ert þú viss um að eyða ${this.item.heiti}?`); // eslint-disable-line
        if (conf) {
          await types.updateGrade(this.id, { delete: 1 });
          this.displaySuccess(`${this.item.heiti} hefur verið eytt.`);
          this.$emit('closeAndReloadSlidebar');
        }
      } catch (e) {
        // Aðgerð mistókst.
        this.$log.debug(e);
        if (e.response && e.response.data && e.response.data.error) {
          this.displayError(e.response.data.error);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    initialize() {
      this.items = [
        {
          codeExists: false,
          heiti: '',
          virk: true,
          rod: '',
          kodi: '',
          kodi_ferill: '',
          i_medal: null,
        },
      ];
      this.id = null;
      if (this.item && this.item.teg_eink) {
        this.id = this.item.teg_eink;
        this.loadGradeDetail();
      }
      /* this.$nextTick(() => {
        if (this.$refs.focusInput) {
          this.$refs.focusInput.focus();
        }
      }); */
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    item() {
      this.initialize();
    },
  },
  created() {
    this.initialize();
  },
};
</script>

<style lang="scss"></style>
