<template>
  <div>
    <b-row class="breadcumb-location" v-if="selectedCount > 0 && canRead">
      <b-col>
        <div class="float-right">
          <Loader v-if="loading.certificates" />
          <CustomButton type="primary" title="Sækja vottorð" @click.native="getCertificates()" :disabled="loading.certificates" />
        </div>
      </b-col>
    </b-row>
    <b-card>
      <b-row>
        <b-col>
          <b-form-group label="Stílsíða" label-for="template">
            <v-select id="template" name="template" v-model="selected.template" :options="templates" label="name" :clearable="false">
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Lengd" label-for="unit">
            <v-select id="unit" name="unit" v-model="selected.unit" :options="units" :clearable="false"> </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Kennslutímabil" label-for="term">
            <v-select id="term" name="term" v-model="selected.term" @input="loadModules()" :options="terms" :clearable="false"> </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Áfangi" label-for="module">
            <v-select
              id="module"
              name="module"
              v-model="selected.module"
              :options="modules"
              label="namsgrein_afanganr"
              :clearable="false"
              @input="loadGroups()"
            >
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Hópur" label-for="group">
            <v-select
              id="group"
              name="group"
              v-model="selected.group"
              :options="groups"
              label="hopur"
              :clearable="false"
              @input="loadStudents()"
            >
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="students.length > 0">
        <b-col>
          <div class="mb-2">Hakaðu við þá nemendur sem á að gera viðurkenningaskjal fyrir</div>
          <table class="table">
            <thead>
              <tr>
                <th style="padding-left: 12px" width="5%" v-if="canRead">
                  <b-form-checkbox v-model="selected.all" @input="updateAll()" />
                </th>
                <th>Nafn</th>
                <th>Kennitala</th>
              </tr>
            </thead>
            <tr v-for="item in students" :key="item.nemandi_id">
              <td v-if="canRead"><b-form-checkbox v-model="item.selected" /></td>
              <td>{{ item.nafn }}</td>
              <td>{{ item.kennitala }}</td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import certificates from '@/api/certificates';
import terms from '@/api/terms';
import school from '@/api/school';
import { mapGetters, mapActions } from 'vuex';

import Loader from '@/components/common/Loader.vue';
import CustomButton from '@/components/common/CustomButton.vue';

export default {
  name: 'module-certificate',
  components: {
    Loader,
    CustomButton,
  },
  computed: {
    canRead() {
      return this.loggedInUserHasReadPermission('nemendahald_skirteini');
    },
    selectedCount() {
      return this.students.filter(x => x.selected).length;
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      loading: {
        terms: false,
        modules: false,
        groups: false,
        students: false,
        templates: false,
        certificates: false,
      },
      selected: {
        template: '',
        term: '',
        module: '',
        group: '',
        all: false,
        unit: { id: 'stund', label: 'Kennslustundir' },
      },
      terms: [],
      modules: [],
      groups: [],
      students: [],
      templates: [],
      units: [
        { id: 'stund', label: 'Kennslustundir' },
        { id: 'klst', label: 'Klukkustundir' },
      ],
    };
  },
  methods: {
    async loadTerms(term) {
      try {
        this.loading.terms = true;
        this.selected.term = '';
        this.selected.module = '';
        this.selected.group = '';
        this.students = [];
        const response = await terms.getTerms();
        this.terms = response.data.items.map(x => ({
          ...x,
          label: `${x.undirsk_audk} - ${x.onn_heiti}`,
        }));
        if (term) {
          this.selected.term = this.terms.find(x => x.onn_id === parseInt(term, 10));
          if (this.selected.term && this.$route.query.moduleTermId) {
            this.loadModules(this.$route.query.moduleTermId);
          }
        } else {
          this.selected.term = this.terms.find(x => x.is_default === 1);
          if (this.selected.term) {
            this.loadModules();
          }
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.terms = false;
      }
    },
    async loadModules(moduleTermId) {
      try {
        this.loading.modules = true;
        this.selected.module = '';
        this.selected.group = '';
        this.students = [];
        const response = await terms.getTermModules(this.selected.term.onn_id);
        this.modules = response.data.items;

        if (moduleTermId) {
          this.selected.module = this.modules.find(x => x.annir_afangar_id === parseInt(moduleTermId, 10));
          if (this.selected.module && this.$route.query.group) {
            this.loadGroups(this.$route.query.group);
          }
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.modules = false;
      }
    },
    async loadGroups(group) {
      try {
        this.loading.groups = true;
        this.selected.group = '';
        this.students = [];
        const response = await school.getGroups({
          onn_id: this.selected.term.onn_id,
          annir_afangar_id: this.selected.module.annir_afangar_id,
        });
        this.groups = response.data.items;

        if (group) {
          this.selected.group = this.groups.find(x => x.hopur_id === parseInt(group, 10));
          if (this.selected.group) {
            this.loadStudents();
          }
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.groups = false;
      }
    },
    async loadStudents() {
      try {
        this.loading.students = true;
        this.selected.all = false;
        this.students = [];
        const response = await certificates.getModuleCertificateStudentList(this.selected.group.hopur_id);
        this.students = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.students = false;
      }
    },
    async loadTemplates() {
      try {
        this.loading.templates = true;
        const response = await certificates.getTemplates(1);
        this.templates = response.data.items;
        if (this.templates.find(x => x.school_id)) {
          this.$set(
            this.selected,
            'template',
            this.templates.find(x => x.school_id),
          );
        } else if (this.templates.length > 0) {
          this.$set(this.selected, 'template', this.templates[0]);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.templates = false;
      }
    },
    updateAll() {
      this.students.forEach(s => {
        this.$set(s, 'selected', this.selected.all);
      });
    },
    async getCertificates() {
      try {
        this.loading.certificates = true;
        const s = this.students
          .filter(x => x.selected)
          .map(x => x.nemandi_id)
          .join(',');
        const response = await certificates.createModuleCertificate(this.selected.template.template_id, this.selected.group.hopur_id, s, {
          unit: this.selected.unit.id,
        });
        this.item = response.data;
        this.loading.certificates = false;
        if (this.item.id) {
          const url = `/api/certificates/${this.item.id}`;
          window.open(url, '_blank');
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    let term = '';
    if (this.$route.query.term) {
      term = this.$route.query.term;
    }
    this.loadTerms(term);
    this.loadTemplates();
  },
};
</script>
<style lang="scss">
.startDate {
  width: 150px !important;
}
</style>
