<template>
  <div>
    <b-row>
      <b-col class="pl-0 mb-3">
        <div>Ef nemendur hafa lokið áfanga sem er skráður sem jafngildisáfangi þá þurfa þeir ekki að taka áfangann.</div>
        <div>
          Hafa þarf í huga að ef jafngildið á að gilda í báðar áttir þá þarf að fara inn í hinn áfangann og bæta þessum áfanga við þar sem
          jafngildi.
        </div>
      </b-col>
      <b-col cols="3">
        <b-btn variant="primary" size="sm" class="float-right mb-2" v-if="canEdit" @click="openSlidebar()"> Bæta við jafngildi</b-btn>
      </b-col>
    </b-row>

    <table class="table less-padding" style="font-size: 13px">
      <thead>
        <tr>
          <th>Undirskóli</th>
          <th>Áfangi</th>
          <th>Heiti áfanga</th>
          <th></th>
        </tr>
      </thead>
      <TableRowLoader v-if="loading.list" colspan="5" :center="true" />
      <TableRowEmpty v-if="!loading.list && current.length === 0" colspan="5" :center="true" />

      <tr v-for="(item, idx) in current" :key="idx">
        <td>{{ item.undirsk_heiti }}</td>
        <td>
          <b-link :to="{ name: 'Module', params: { id: item.afangi_id } }" target="_blank">{{ item.namsgrein_afanganr }}</b-link>
        </td>
        <td>{{ item.afanga_heiti }}</td>
        <td>
          <b-btn variant="danger" class="btn-xs float-right" v-if="canEdit" @click="deleteEquivalent(item)"
            ><i class="fa fa-trash" v-b-tooltip.hover title="Eyða jafngildi"></i
          ></b-btn>
        </td>
      </tr>
    </table>
    <div class="mt-4 mb-2">Áfangar sem eru með þennan áfanga skráðan sem jafngildisáfanga</div>
    <table class="table less-padding" style="font-size: 13px">
      <thead>
        <tr>
          <th>Undirskóli</th>
          <th>Áfangi ({{ allModules.length }})</th>
          <th>Heiti áfanga</th>
        </tr>
      </thead>
      <TableRowLoader v-if="loading.all" colspan="4" :center="true" />
      <TableRowEmpty v-if="!loading.all && allModules.length === 0" colspan="4" :center="true" />
      <tr v-for="(item, idx) in allModules" :key="idx">
        <td>{{ item.undirsk_heiti }}</td>
        <td>
          <b-link :to="{ name: 'Module', params: { id: item.afangi_id } }" target="_blank">{{ item.namsgrein_afanganr }}</b-link>
        </td>
        <td>{{ item.afanga_heiti }}</td>
      </tr>
    </table>
    <Loader v-if="loading.graph"></Loader>
    <GChart
      type="OrgChart"
      :data="chartData"
      :options="chartOptions"
      :settings="{ packages: ['orgchart'] }"
      @ready="onChartReady"
      v-if="graph.length > 0"
      ref="gChart"
    ></GChart>
    <module-equivalent-slidebar
      v-if="showSlidebar"
      :id="id"
      :divisionId="divisionId"
      @closeSlidebar="closeSlidebar()"
    ></module-equivalent-slidebar>
  </div>
</template>

<script>
import curriculum from '@/api/curriculum';
import { mapGetters, mapActions } from 'vuex';
import { GChart } from 'vue-google-charts';

import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import Loader from '@/components/common/Loader.vue';

import ModuleEquivalentSlidebar from '@/components/curriculum/modules/ModuleEquivalentSlidebar.vue';

export default {
  name: 'module-equivalent',
  components: {
    GChart,
    TableRowLoader,
    TableRowEmpty,
    Loader,
    ModuleEquivalentSlidebar,
  },
  props: ['id', 'divisionId'],
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_afangar');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('namskra_afangar');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      loading: {
        list: false,
        all: false,
        graph: false,
      },
      current: [],
      allModules: [],
      graph: [],
      chartData: null,
      chartOptions: {
        allowHtml: true,
        nodeClass: 'GradeRuleNodeClass',
        selectedNodeClass: 'GradeRuleSelectedNodeClass',
      },
      showSlidebar: false,
    };
  },
  methods: {
    closeSlidebar() {
      this.showSlidebar = false;
      this.loadList();
      this.loadAll();
      this.loadGraph();
    },
    openSlidebar() {
      this.showSlidebar = true;
    },
    async deleteEquivalent(item) {
      const conf = confirm(`Ert þú viss um að eyða jafngildinu ${item.namsgrein_afanganr}?`); // eslint-disable-line
      if (conf) {
        try {
          await curriculum.setModuleEquivalent(this.id, { afangi_id: item.afangi_id, delete: 1 });
          this.displaySuccess('Jafngildum eytt.');

          this.loadList();
          this.loadAll();
          this.loadGraph();
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    async loadList() {
      this.loading.list = true;
      try {
        const response = await curriculum.getModuleEquivalentList(this.id);
        this.current = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.list = false;
      }
    },
    async loadAll() {
      this.loading.all = true;
      try {
        const response = await curriculum.getModuleEquivalentAll(this.id);
        this.allModules = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.all = false;
      }
    },
    async loadGraph() {
      this.loading.graph = true;
      try {
        const response = await curriculum.getModuleEquivalentListGraph(this.id);
        this.graph = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.graph = false;
      }
    },
    onChartReady(chart, google) {
      this.createDataTable(google);
    },
    createDataTable(google) {
      const data = new google.visualization.DataTable();
      data.addColumn('string', 'Name');
      data.addColumn('string', 'Manager');
      data.addRows(this.graph.length);
      for (let i = 0; i < this.graph.length; i += 1) {
        const item = this.graph[i];
        const id = item.id_child ? item.id_child : '';
        const name = `${item.jafng_namsgrein_afanganr}<br/>${item.undirsk_heiti}`;
        const parent = item.id_parent ? item.id_parent : '';

        data.setCell(i, 0, id, name);
        data.setCell(i, 1, parent);
      }
      this.chartData = data;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadList();
    this.loadAll();
    this.loadGraph();
  },
};
</script>
<style lang="scss"></style>
