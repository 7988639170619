<template>
  <Slidebar :autoClose="false" @closeMe="closeSlidebar" :extraLarge="true">
    <h4>Afrita einkunnareglu hóps</h4>
    <div class="pb-2">1. Veldu áfanga sem þú vilt afrita reglu frá</div>
    <TermModuleSearch
      :defaultTerm="id"
      :divisionId="divisionId"
      :searchModule="searchModule"
      v-show="!hide"
      @selectModule="selectModule"
      :showGroupGradeRule="true"
    ></TermModuleSearch>
    <div v-if="hide" class="mb-2" style="font-size: 17px">
      {{ selectedItem ? `${selectedItem.onn_eink} - ${selectedItem.namsgrein_afanganr}` : '' }}
      <b-btn variant="primary" class="btn-xs" @click="hide = false" v-b-tooltip title="Velja annan áfanga" style="margin-top: -5px">
        <i class="fa fa-pencil"></i>
      </b-btn>
    </div>
    <div class="mb-2" v-if="selectedItem">2. Veldu hóp sem þú vilt afrita frá</div>
    <div v-if="selectedItem && !selectedGroup">
      <div v-if="groups.length === 0">Engir hópar fundust</div>
      <div v-for="group in groups" :key="group.hopur_id">
        <b-link v-if="group.has_graderule === 1" @click="selectGroup(group)">{{ group.hopur }}</b-link>
        <span v-if="group.has_graderule === 0"> {{ group.hopur }} - Ath. engin einkunnaregla er skráð á valinn hóp</span>
      </div>
    </div>
    <div v-if="hide && selectedItem && selectedGroup">
      Hópur: {{ selectedGroup.hopur }}
      <b-btn variant="primary" class="btn-xs" @click="selectedGroup = ''" v-b-tooltip title="Velja annan hóp" style="margin-top: -5px">
        <i class="fa fa-pencil"></i>
      </b-btn>

      <GradeRuleList
        v-if="hide && selectedGroup"
        :edit="false"
        :groupId="selectedGroup.hopur_id"
        hopur_id
        :id="`CopyGRList${selectedGroup.annir_afangar_id}`"
      ></GradeRuleList>
      <div class="mt-3">
        <b-btn variant="primary" @click="copyGraderule()">Afrita</b-btn>
      </div>
    </div>
  </Slidebar>
</template>

<script>
import terms from '@/api/terms';
import groups from '@/api/groups';
import { mapActions } from 'vuex';
import Slidebar from '@/components/common/Slidebar.vue';
import TermModuleSearch from '@/components/school/terms/TermModuleSearch.vue';
import GradeRuleList from '@/components/school/terms/graderules/GradeRuleList.vue';

export default {
  name: 'copy-graderule-group-slidebar',
  components: {
    Slidebar,
    TermModuleSearch,
    GradeRuleList,
  },
  props: ['groupId', 'id', 'divisionId', 'searchModule'],
  data() {
    return {
      hide: false,
      selectedItem: '',
      selectedGroup: '',
      loading: false,
      overwrite: false,
      saving: false,
      groups: [],
    };
  },
  methods: {
    closeSlidebar(item) {
      this.$emit('closeSlidebar', item);
    },
    selectModule(item) {
      if (item) {
        this.selectedItem = item;
        this.loadGroups();
        this.hide = true;
      } else {
        this.hide = false;
      }
    },
    selectGroup(item) {
      this.selectedGroup = item;
    },
    async loadGroups() {
      try {
        this.groups = [];
        const response = await terms.getTermModule(this.selectedItem.annir_afangar_id);
        this.groups = response.data.hopar || [];
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async copyGraderule() {
      try {
        await groups.copyGradeRuleFromGroup(this.groupId, this.selectedGroup.hopur_id);
        this.displaySuccess('Einkunnaregla hefur verið afrituð.');
        this.closeSlidebar();
      } catch (e) {
        // Aðgerð mistókst.
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>

<style lang="scss"></style>
