<template>
  <div>
    <Loader v-if="loading"></Loader>
    <table v-if="!loading" class="table less-padding">
      <thead>
        <tr>
          <th>Nafn ({{ list.length }})</th>
          <th>Kennitala</th>
          <th>Undirskóli</th>
          <th>Braut</th>
          <th>Önn</th>
          <th>Staða</th>
          <th>Dags.</th>
          <th></th>
        </tr>
      </thead>
      <TableRowEmpty v-if="!loading && this.list.length === 0" colspan="5" :center="true" />
      <tr v-for="item in list" :key="item.umsokn_skolar_id">
        <td>{{ item.nafn }}</td>
        <td>{{ item.ktala }}</td>
        <td>{{ item.undirsk_audk }}</td>
        <td>{{ item.braut_kodi }}</td>
        <td>{{ item.onn_eink }}</td>
        <td>{{ item.heiti }}</td>
        <td>{{ item.dags_umsoknar | moment('DD.MM.YYYY') }}</td>
        <td>
          <b-btn variant="primary" size="sm" class="btn-xs" @click="openApplicationSlidebar(item)"><i class="fa fa-search"></i></b-btn>
        </td>
      </tr>
    </table>
    <application-slidebar
      @closeSlidebar="closeSlidebar"
      :applicationSchoolId="selectedItem && selectedItem.umsokn_skolar_id"
      v-if="showApplicationSlidebar"
    >
    </application-slidebar>
  </div>
</template>

<script>
import students from '@/api/students';
import { mapGetters, mapActions } from 'vuex';
import Loader from '@/components/common/Loader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import ApplicationSlidebar from '@/components/applications/application/ApplicationSlidebar.vue';

export default {
  name: 'student-application-list',
  props: ['studentId'],
  components: {
    Loader,
    TableRowEmpty,
    ApplicationSlidebar,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('nemendahald_nemendur');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('nemendahald_nemendur');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      loading: false,
      list: [],
      showApplicationSlidebar: false,
      selectedItem: '',
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;
        const response = await students.getApplications(this.studentId);
        this.list = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    openApplicationSlidebar(item) {
      this.selectedItem = item;
      this.showApplicationSlidebar = true;
    },
    closeSlidebar() {
      this.showApplicationSlidebar = false;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.load();
  },
};
</script>
<style lang="scss"></style>
