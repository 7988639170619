<template>
  <Slidebar @closeMe="closeSlidebar">
    <h4>Fletja út kennslutímabil</h4>
    <change-term-type :item="item" @finishCopy="closeSlidebar()"> </change-term-type>
  </Slidebar>
</template>

<script>
import { mapActions } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';
import ChangeTermType from '@/components/school/terms/ChangeTermType.vue';

export default {
  name: 'change-term-type-slide-bar',
  props: ['item'],
  components: {
    Slidebar,
    ChangeTermType,
  },
  data() {
    return {};
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>

<style lang="scss"></style>
