<template>
  <Slidebar :large="true" @closeMe="closeSlidebar">
    <h4 class="mb-3">{{ item.heiti }} {{ item.braut_kodi }}</h4>
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <b-form-group
        label="Samþykkt braut"
        label-for="connected_course"
        :state="submitted && errors.has('connected_course') ? false : ''"
        :feedback="errors.first('connected_course')"
      >
        <v-select
          id="connected_course"
          name="connected_course"
          v-model="form.connected_course"
          :state="submitted && errors.has('connected_course') ? false : ''"
          :options="getCourseList"
        >
        </v-select>
      </b-form-group>
    </b-form>
    <div>
      <div class="d-inline-block"><b-form-checkbox v-model="filter.onlyMine" /></div>
      Birta eingöngu brautir skóla
      <i
        class="fa fa-info-circle"
        v-b-tooltip
        title="Sjá bara þær brautir sem skólinn er þegar tengdur við. Ef það er
      ekki valið þá er hægt að sjá allar brautir sem eru samþykktar hjá MRN"
      >
      </i>
    </div>
    <div>
      <div class="d-inline-block"><b-form-checkbox v-model="filter.onlyMinistrySerial" /></div>
      Birta eingöngu brautir með útgáfunúmer
    </div>
    <br />
    <b-btn variant="primary" @click="validateBeforeSubmit">Vista</b-btn>
  </Slidebar>
</template>

<script>
import reports from '@/api/reports';
import Slidebar from '@/components/common/Slidebar.vue';
import { mapActions } from 'vuex';

export default {
  name: 'connect-course-slide-bar',
  props: ['item'],
  components: {
    Slidebar,
  },
  computed: {
    getCourseList() {
      return this.courses.filter(x => (!this.filter.onlyMine || x.is_mine) && (!this.filter.onlyMinistrySerial || x.ministry_serial));
    },
  },
  data() {
    return {
      form: {
        connected_course: null,
      },
      submitted: false,
      courses: [],
      filter: {
        onlyMine: true,
        onlyMinistrySerial: true,
      },
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async loadCourses() {
      try {
        this.courses = [];
        const response = await reports.getMinistryConfirmedCourses();
        this.courses = response.data.items.map(x => ({
          ...x,
          label: `${x.title} ${x.ministry_serial || ' '}`,
        }));
        this.form.connected_course = null;
        if (this.item.ny_samr_braut_id) {
          this.form.connected_course = this.courses.find(x => x.programme_id === this.item.ny_samr_braut_id);
          if (!this.form.connected_course.is_mine) this.onlyMine = false;
          if (!this.form.connected_course.ministry_serial) this.onlyMinistrySerial = false;
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.saving = true;
          const data = {
            samr_braut: this.form.connected_course ? this.form.connected_course.programme_id : null,
            braut_id: this.item.braut_id,
          };
          await reports.connectToMinistryConfirmedCourses(data);
          this.displaySuccess('Upplýsingar vistaðar');
          this.closeSlidebar();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.saving = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    item() {
      this.form.connected_course = null;
      if (this.item.ny_samr_braut_id) {
        this.form.connected_course = this.courses.find(x => x.programme_id === this.item.ny_samr_braut_id);
        if (!this.form.connected_course.is_mine) this.onlyMine = false;
        if (!this.form.connected_course.ministry_serial) this.onlyMinistrySerial = false;
      }
    },
  },
  created() {
    this.loadCourses();
  },
};
</script>

<style lang="scss"></style>
