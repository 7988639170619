import Vue from 'vue';
import moment from 'moment';

const API_URL = '/api/students';

export default {
  getStudentsInGroup(group, date) {
    const compareDate = date || moment().format('DD.MM.YYYY');
    return Vue.http
      .get(`${API_URL}/group/${group}/students/${compareDate}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentsStatuses() {
    return Vue.http
      .get(`${API_URL}/statuses`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentModuleSubgroups(termId, studentId) {
    return Vue.http
      .get(`${API_URL}/${studentId}/module-subgroups/${termId}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentInfo(studentId) {
    return Vue.http
      .get(`${API_URL}/${studentId}/info`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getGroupsMany(ids, params) {
    return Vue.http
      .get(`${API_URL}/groups-many/${ids}`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentList(params) {
    return Vue.http
      .get(`${API_URL}/list`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentListDetails(params) {
    return Vue.http
      .get(`${API_URL}/list-details`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentStudyRecord(id, params) {
    return Vue.http
      .get(`${API_URL}/${id}/study-records`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentSpecialResourceApplications(id) {
    return Vue.http
      .get(`${API_URL}/${id}/special-resource-applications`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentSpecialResourcesCommon(id) {
    return Vue.http
      .get(`${API_URL}/${id}/special-resources/`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentSpecialResourcesModule(id) {
    return Vue.http
      .get(`${API_URL}/${id}/special-resources-module`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createStudentSpecialResources(id, data) {
    return Vue.http
      .post(`${API_URL}/${id}/special-resources/`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentFewInfo(studentId) {
    return Vue.http
      .get(`${API_URL}/${studentId}/few-info`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentDefaultTerm(studentId) {
    return Vue.http
      .get(`${API_URL}/${studentId}/default-term`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentAttendanceByTerm(studentId, termId, p) {
    const params = {
      type: 1,
      ...p,
    };
    return Vue.http
      .get(`${API_URL}/${studentId}/attendance/${termId}`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentAttendanceTerms(studentId) {
    const params = {
      type: 3,
    };
    return Vue.http
      .get(`${API_URL}/${studentId}/attendance/`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentAttendanceWeeks(studentId, termId) {
    const params = {
      type: 2,
    };
    return Vue.http
      .get(`${API_URL}/${studentId}/attendance/${termId}`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentTerms(studentId, params) {
    return Vue.http
      .get(`${API_URL}/${studentId}/terms`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createComment(data) {
    return Vue.http
      .post(`${API_URL}/comment/`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateComment(id, data) {
    return Vue.http
      .post(`${API_URL}/comment/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentComments(id) {
    return Vue.http
      .get(`${API_URL}/comment/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudyRecordGrades(recordId) {
    return Vue.http
      .get(`${API_URL}/study-record-grades/${recordId}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudyRecordDetails(recordId) {
    return Vue.http
      .get(`${API_URL}/study-record-details/${recordId}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentAbsenceCertificate(studentId) {
    return Vue.http
      .get(`${API_URL}/${studentId}/absence-certificate`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentModuleList(studentId, params) {
    return Vue.http
      .get(`${API_URL}/${studentId}/module-list`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentCourseList(studentId) {
    return Vue.http
      .get(`${API_URL}/${studentId}/course-list`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentAllCourses(studentId) {
    return Vue.http
      .get(`${API_URL}/${studentId}/courses`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentRelatives(studentId) {
    return Vue.http
      .get(`${API_URL}/${studentId}/relatives`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentRelativesEmails(studentId) {
    return Vue.http
      .get(`${API_URL}/${studentId}/relatives-emails`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentGuardians(studentId) {
    return Vue.http
      .get(`${API_URL}/${studentId}/guardians`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateStudentInfo(studentId, data) {
    return Vue.http
      .post(`${API_URL}/${studentId}/info`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudyRecordCourse(studentId, courseId) {
    return Vue.http
      .get(`${API_URL}/${studentId}/study-record-course/${courseId}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudyRecordCourseModules(studentId, courseId) {
    return Vue.http
      .get(`${API_URL}/${studentId}/study-record-course-modules/${courseId}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createStudentRelative(studentId, data) {
    return Vue.http
      .post(`${API_URL}/${studentId}/relative/`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateStudentRelative(studentId, id, data) {
    return Vue.http
      .post(`${API_URL}/${studentId}/relative/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentSupervisors(studentId) {
    return Vue.http
      .get(`${API_URL}/${studentId}/supervisors`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createStudentSupervisor(studentId, data) {
    return Vue.http
      .post(`${API_URL}/${studentId}/supervisors`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createStudentCourse(studentId, data) {
    return Vue.http
      .post(`${API_URL}/${studentId}/course/`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateStudentCourse(studentId, id, data) {
    return Vue.http
      .post(`${API_URL}/${studentId}/course/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentToActivate(studentId) {
    return Vue.http
      .get(`${API_URL}/${studentId}/activation`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateStudentActive(studentId, data) {
    return Vue.http
      .post(`${API_URL}/${studentId}/activation`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentGraduations(studentId, params) {
    return Vue.http
      .get(`${API_URL}/${studentId}/graduations`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getCourseModulesNotInCertificate(studentId, courseId) {
    return Vue.http
      .get(`${API_URL}/${studentId}/modules-not-certificate/${courseId}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getCourseModulesOtherSchools(studentId, courseId) {
    return Vue.http
      .get(`${API_URL}/${studentId}/modules-other-schools/${courseId}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getCourseOtherSchools(studentId) {
    return Vue.http
      .get(`${API_URL}/${studentId}/courses-other-schools`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateCourseRequirements(studentId, courseId, data) {
    return Vue.http
      .post(`${API_URL}/${studentId}/course-requirements/${courseId}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getApplications(studentId) {
    return Vue.http
      .get(`${API_URL}/${studentId}/applications`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getEvaluation(ssn, params) {
    return Vue.http
      .get(`${API_URL}/evaluation/${ssn}`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getConfirmTermSelection(studentId) {
    return Vue.http
      .get(`${API_URL}/${studentId}/confirm-term-selection/`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  confirmTermSelection(studentId, termId) {
    return Vue.http
      .post(`${API_URL}/${studentId}/confirm-term-selection/${termId}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  unconfirmTermSelection(studentId, termId) {
    return Vue.http
      .post(`${API_URL}/${studentId}/unconfirm-term-selection/${termId}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  deleteSchedule(studentId, termId, data) {
    // uppfæra samkv. brautarskráningu
    return Vue.http
      .post(`${API_URL}/${studentId}/remove-schedule/${termId}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  postStudentStudyRecord(studentId, data) {
    // setja áfanga í feril
    return Vue.http
      .post(`${API_URL}/${studentId}/study-records`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudyRecordId(studentId, termModuleId) {
    return Vue.http
      .get(`${API_URL}/${studentId}/study-record-id/${termModuleId}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentCourseText(studentCourseId) {
    return Vue.http
      .get(`${API_URL}/courseText/${studentCourseId}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateStudentCourseText(studentCourseId, data) {
    return Vue.http
      .post(`${API_URL}/courseText/${studentCourseId}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentsCourseResignation(studentId, params) {
    return Vue.http
      .get(`${API_URL}/${studentId}/course-resignation`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  postStudentsCourseResignation(studentId, data) {
    return Vue.http
      .post(`${API_URL}/${studentId}/course-resignation`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  postUpdateStudentsGraduation(studentId, data) {
    return Vue.http
      .post(`${API_URL}/${studentId}/graduations`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  registerStudentAttendance(studentId, data) {
    return Vue.http
      .post(`${API_URL}/${studentId}/register-attendance`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentPracticeAndBehavior(studentId, termId) {
    return Vue.http
      .get(`${API_URL}/${studentId}/practiceandbehavior/${termId}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentsAttendanceList(studentId, params) {
    return Vue.http
      .get(`${API_URL}/${studentId}/attendance-list`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  editSelectedStudentsAttendance(studentId, data) {
    return Vue.http
      .post(`${API_URL}/${studentId}/attendance-list`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentGrades(studentId, termId) {
    return Vue.http
      .get(`${API_URL}/${studentId}/grades/${termId}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  postStudentGrade(studentId, data) {
    return Vue.http
      .post(`${API_URL}/${studentId}/grades`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentGroupId(params) {
    return Vue.http
      .get(`${API_URL}/group`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentStatistics(studentId, params) {
    return Vue.http
      .get(`${API_URL}/${studentId}/statistics`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentElementaryGrades(studentSsn) {
    return Vue.http
      .get(`${API_URL}/elementary-grades/${studentSsn}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentMidtermInfo(studentId, params) {
    return Vue.http
      .get(`${API_URL}/${studentId}/midterms`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  postStudentMidtermGrade(studentId, data) {
    return Vue.http
      .post(`${API_URL}/${studentId}/midterms`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentAttendanceSum(studentId, termId) {
    return Vue.http
      .get(`${API_URL}/${studentId}/attendance-sum/${termId}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentUnits(studentId, termId) {
    return Vue.http
      .get(`${API_URL}/${studentId}/units/${termId}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudyRecordGradesRule(studentId, params) {
    return Vue.http
      .get(`${API_URL}/${studentId}/study-record-grades`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentCoursesFields(studentId, params) {
    return Vue.http
      .get(`${API_URL}/${studentId}/course-fields`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  postStudentCourseField(studentId, data) {
    return Vue.http
      .post(`${API_URL}/${studentId}/course-fields`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStudentCoursesFieldsModules(studentCourseId) {
    return Vue.http
      .get(`${API_URL}/coursefield-modules/${studentCourseId}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
};
