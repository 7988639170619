<template>
  <Slidebar :autoClose="false" :large="true" @closeMe="closeSlidebar">
    <h4 v-if="active === 1">Óvirkja nemanda</h4>
    <h4 v-else>Endurvirkja nemanda</h4>
    <div v-if="active === 1">
      <div>Ertu viss um að þú viljir óvirkja nemanda ?</div>
      <div>Þegar nemandi er óvirkjaður eru:</div>
      <ul style="font-size: 13px">
        <li>Allar virkar brautir endadagsettar.</li>
        <li>Allar fjarvistir á áföngum í stöðunni "Ólokið", "Staðfest val" og "Áætlun" á önnum sem eru ekki liðnar verður eytt.</li>
        <li>
          Allir áfangar í ferli í stöðunni "Staðfest val" og "Áætlun" þar sem engar einkunnir eru skráðar á önnum sem eru ekki liðnar verður
          eytt.
        </li>
        <li>Allir áfangar í ferli í stöðunni "Ólokið" sem var ekki eytt hér fyrir ofan verða skráðir sem "Úrsögn".</li>
      </ul>

      <div class="mb-2"><strong>BRAUTIR</strong></div>
      <div v-if="getCourses.length === 0">Engar virkar brautir fundust.</div>
      <table class="table less-padding" style="font-size: 13px" v-if="getCourses.length > 0">
        <thead>
          <tr>
            <th>Braut</th>
            <th>Undirskóli</th>
            <th>Dags. byrjun</th>
          </tr>
        </thead>
        <tr v-for="(c, idx) in getCourses" :key="`c-${idx}`">
          <td>{{ c.braut_kodi }} {{ c.heiti }}</td>
          <td>{{ c.undirsk_heiti }}</td>
          <td>{{ c.dags_byrjun }}</td>
        </tr>
      </table>

      <div class="mb-2"><strong>ÁFANGAR Í NÁMSFERLI</strong></div>
      <div v-if="studyRecords.length === 0" class="mb-3">
        Engir áfangar fundust í ferli í stöðunni "Áætlun", "Staðfest val" eða "Áætlun".
      </div>
      <table class="table less-padding" style="font-size: 13px" v-if="studyRecords.length > 0">
        <thead>
          <tr>
            <th>Önn</th>
            <th>Áfangi</th>
            <th>Staða</th>
            <th>Önn liðin</th>
            <th>Í hóp</th>
            <th>Einkunn til</th>
            <th>Fjarv. til</th>
            <th>Eytt</th>
          </tr>
        </thead>
        <tr v-for="(r, idx) in studyRecords" :key="idx">
          <td>{{ r.onn_eink }}</td>
          <td>{{ r.namsgrein_afanganr }}</td>
          <td>{{ r.heiti }}</td>
          <td>
            <yes-no :item="r.onn_lidin"></yes-no>
          </td>
          <td><yes-no :item="r.hopur_til"></yes-no></td>
          <td>
            <yes-no :item="r.einkunn_til"></yes-no>
            <i
              class="cursor-pointer fa fa-search pl-1"
              title="Skoða einkunnir nemanda"
              v-if="r.einkunn_til"
              v-b-tooltip
              @click="openStudyRecordGradesSlidebar(r)"
            ></i>
          </td>
          <td>
            <yes-no :item="r.fjarvist_til"></yes-no>
            <i
              class="cursor-pointer fa fa-search pl-1"
              title="Skoða viðveru nemanda"
              v-if="r.fjarvist_til"
              v-b-tooltip
              @click="openStudentAttendanceSlidebar(r)"
            ></i>
          </td>
          <td>
            <span v-if="r.onn_lidin === 0 && r.einkunn_til === 0 && (r.stada_afanga !== 1 || (r.stada_afanga === 1 && !r.onn_i_gangi))">
              Já
            </span>
            <!-- <span v-if="r.onn_i_gangi === 1 && r.stada_afanga === 1">Nei</span> -->
          </td>
        </tr>
      </table>
    </div>
    <div v-else class="mb-3">
      <div>Ertu viss um að þú viljir virkja nemanda aftur ?</div>
      <div>
        Ath. við það að virkja nemanda aftur virkjast aftur síðasta braut sem hann var skráður á sem er ennþá virk í skólanum og ekki merkt
        sem lokið.
      </div>
      <div>
        <b-row class="pt-3">
          <b-col class="pl-0">
            <b-form-group>
              <label for="statuses">Setja nemanda í stöðuna <Loader v-if="loading.statuses"></Loader></label>
              <v-select id="statuses" name="statuses" v-model="status" :options="statuses" :clearable="false" label="heiti"> </v-select>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </div>
    <b-btn variant="primary" @click="updateStudentActive()" :disabled="saving">
      <i class="fa fa-spin fa-spinner" v-if="saving"></i>
      <span v-if="active === 1">Óvirkja</span>
      <span v-else>Endurvirkja</span>
    </b-btn>
    <student-attendance-slidebar
      v-if="showStudentAttendanceSlidebar"
      :studentId="studentId"
      :termId="selectedItem && selectedItem.onn_id"
      @closeSlidebar="closeExtraSlidebar"
      @closeAndReload="closeExtraSlidebar"
    />
    <study-record-grades-slidebar
      v-if="showStudyRecordGradesSlidebar"
      :recordId="selectedItem && selectedItem.ferill_id"
      @closeSlidebar="closeExtraSlidebar"
      @closeAndReload="closeExtraSlidebar"
    />
  </Slidebar>
</template>

<script>
import { mapActions } from 'vuex';
import students from '@/api/students';

import Slidebar from '@/components/common/Slidebar.vue';
import YesNo from '@/components/common/YesNo.vue';
import Loader from '@/components/common/Loader.vue';
import StudentAttendanceSlidebar from '@/components/students/attendance/StudentAttendanceSlidebar.vue';
import StudyRecordGradesSlidebar from '@/components/students/studyRecords/StudyRecordGradesSlidebar.vue';

export default {
  name: 'student-activation-slidebar',
  props: ['studentId', 'active'],
  components: {
    Slidebar,
    YesNo,
    Loader,
    StudentAttendanceSlidebar,
    StudyRecordGradesSlidebar,
  },
  computed: {
    getCourses() {
      return this.courses.filter(x => !x.dags_endar);
    },
  },
  data() {
    return {
      saving: false,
      studyRecords: [],
      courses: [],
      selectedItem: '',
      showStudentAttendanceSlidebar: false,
      showStudyRecordGradesSlidebar: false,
      statuses: [],
      status: '',
      loading: {
        statuses: false,
      },
    };
  },
  methods: {
    async loadStudentToDeactivate() {
      try {
        const response = await students.getStudentToActivate(this.studentId);
        this.studyRecords = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadCourses() {
      try {
        const response = await students.getStudentAllCourses(this.studentId);
        this.courses = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadStatuses() {
      try {
        this.loading.statuses = true;
        const response = await students.getStudentsStatuses();
        this.statuses = response.data.items.filter(x => x.eink_stodu !== 9);
        this.status = this.statuses.filter(x => x.eink_stodu === 5);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.statuses = false;
      }
    },
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async updateStudentActive() {
      try {
        this.saving = true;
        const data = {
          virkur: this.active ? 0 : 1,
          stada: this.status ? this.status.eink_stodu : '',
        };
        await students.updateStudentActive(this.studentId, data);
        this.$emit('closeAndReloadSlidebar');
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.saving = false;
      }
    },
    openStudentAttendanceSlidebar(item) {
      this.selectedItem = item;
      this.showStudentAttendanceSlidebar = true;
      this.showStudyRecordGradesSlidebar = false;
    },
    openStudyRecordGradesSlidebar(item) {
      this.selectedItem = item;
      this.showStudyRecordGradesSlidebar = true;
      this.showStudentAttendanceSlidebar = false;
    },
    closeExtraSlidebar() {
      this.showStudentAttendanceSlidebar = false;
      this.showStudyRecordGradesSlidebar = false;
      this.selectedItem = null;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    if (this.active) {
      this.loadCourses();
      this.loadStudentToDeactivate();
    } else {
      this.loadStatuses();
    }
  },
};
</script>

<style lang="scss"></style>
