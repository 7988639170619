<template>
  <span>
    <b-btn size="sm" class="default-button excel-button" v-if="type === 'excel'">
      <i class="fa fa-file-excel-o" />
      {{ title ? title : 'Excel' }}
    </b-btn>
    <b-btn size="sm" class="default-button" variant="primary" v-if="type === 'create'">
      <i class="fa fa-plus" />
      {{ title }}
    </b-btn>
    <b-btn size="sm" class="default-button" variant="secondary" v-if="type === 'update'">
      <i class="fa fa-pencil" />
      {{ title }}
    </b-btn>
    <b-btn size="sm" class="default-button" variant="dark" v-if="type === 'updateDark'">
      <i class="fa fa-pencil" />
      {{ title }}
    </b-btn>
    <b-btn size="sm" class="default-button" variant="primary" v-if="type === 'primary'">
      {{ title }}
    </b-btn>
    <b-btn size="sm" class="default-button" variant="dark" v-if="type === 'dark'">
      {{ title }}
    </b-btn>
  </span>
</template>

<script>
export default {
  name: 'CustomButton',
  props: ['type', 'title'],
};
</script>

<style lang="scss">
.excel-button {
  border-radius: 3px;
  background-color: #1d6f42;
  text-align: center;
  // font-weight: bold;
  &:hover,
  &:active,
  &:focus {
    background-color: #1d6f42;
    border-color: #1d6f42;
  }
}

.default-button {
  font-size: 16px;
  height: 35px;
  padding: 4px 14px;
}
</style>
