<template>
  <Slidebar :large="true" @closeMe="closeSlidebar">
    <h4>Námi lokið</h4>
    <div class="alert alert-info">
      Í þessari mynd geturu valið að óvirkja þá nemendur í hópnum sem eru ekki í neinu öðru námi í skólanum. Ef nemandi er með áfanga í
      námsferli í stöðunni "Staðfest val", "Áætlun" eða "Ólokið" er ekki hægt að óvirkja hann hér fyrr en búið er að breyta í aðrar stöður
      eða eyða. Nemendur verða gerðir óvirkir og settir í stöðuna "Hættur".
    </div>
    <table class="table less-padding" style="font-size: 14px">
      <thead>
        <tr>
          <th></th>
          <th>Nafn ({{ list.length }})</th>
          <th>Kennitala</th>
          <th>Braut</th>
          <th>Staða nema</th>
          <th>Virkur</th>
          <th>Áfangar</th>
        </tr>
      </thead>
      <tr v-for="s in list" :key="s.nemandi_id">
        <td><b-form-checkbox v-model="s.selected" v-if="!s.afangar && s.virkur"></b-form-checkbox></td>
        <td>{{ s.nafn }}</td>
        <td>{{ s.kennitala }}</td>
        <td>{{ s.braut }}</td>
        <td>{{ s.stada_nema }}</td>
        <td>{{ s.virkur === 1 ? 'Já' : 'Nei' }}</td>
        <td>
          <div v-for="(a, idx) in s.modules" :key="idx">
            {{ a }}
          </div>
        </td>
      </tr>
    </table>
    <b-btn variant="primary" @click="validateBeforeSubmit" :disabled="getSelected.length === 0">Óvrkja valda nemendur</b-btn>
  </Slidebar>
</template>

<script>
import groups from '@/api/groups';
import { mapGetters, mapActions } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';

export default {
  name: 'studies-completed-slidebar',
  props: ['groupId'],
  components: {
    Slidebar,
  },
  computed: {
    getSelected() {
      return this.list.filter(x => x.selected);
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      list: [],
      loading: false,
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async loadList() {
      try {
        this.loading = true;
        const response = await groups.getGroupStudiesCompleted(this.groupId);
        this.list = response.data.items.map(x => ({
          ...x,
          modules: (x.afangar || '').split(','),
          selected: !x.afangar && x.virkur === 1,
        }));
      } catch (e) {
        // Aðgerð mistókst.
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async validateBeforeSubmit() {
      try {
        const students = JSON.stringify({
          students: this.list.filter(x => x.selected).map(x => x.nemandi_id),
        });

        await this.$studentsApi.deactivateStudents({
          deactivateStudentsInputItem: {
            students,
            groupId: this.groupId,
          },
        });
        this.$emit('closeAndReloadSlidebar');
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadList();
  },
};
</script>

<style lang="scss"></style>
