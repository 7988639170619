<template>
    <Slidebar @closeMe="closeSlidebar">
        <h4>Breyta einkunn</h4>
        <b-row style="font-size: 13px">
            <b-col class="pl-0 pb-2">
                <div>
                    <i class="fa fa-info-circle"></i> Ath. þessi einkunnaregla er tengd við verkefnið/prófið <strong>{{
                        itemCopy.heiti }}</strong>. Við það að breyta einkunn hér breytist einkunnin í verkefninu/prófinu líka!
                </div>
            </b-col>
        </b-row>
        <b-row style="font-size: 13px">
            <b-col class="pl-0">
                <div v-if="scale">
                    <div>
                        Skali: {{ scale.heiti }}
                        <i class="fa fa-search cursor-pointer" v-if="!viewScale" @click="viewScale = true"></i>
                        <i class="fa fa-remove cursor-pointer" v-else @click="viewScale = false"></i>
                    </div>
                    <div style="background-color: #eee; width: 50%" class="mb-3 p-4" v-if="viewScale">
                        <div>Leyfðar einkunnir</div>
                        <ul>
                            <li v-for="(g, idx) in scale.skali" :key="idx" style="font-size: 12px">{{ g.grade }}</li>
                        </ul>
                    </div>
                </div>
                <div v-else>Skali: Tölur milli 0 og 10 eða bókstafir.</div>
            </b-col>
        </b-row>
        <b-form @submit.prevent="validateBeforeSubmit" class="pt-2" novalidate>

            <table class="table less-padding pb-3">
                <tr>
                    <td>Áfangi</td>
                    <td>
                        {{ selectedCourse }}
                    </td>
                </tr>
                <tr>
                    <td>Heiti</td>
                    <td>
                        {{ itemCopy.heiti }}
                    </td>
                </tr>
                <tr>
                    <td>Einkunn</td>
                    <td>
                        <b-form-input v-model="itemCopy.einkunn" :class="{ 'is-invalid': !checkGradeValid(itemCopy.einkunn) }"
                            style="width: 40px; height: 25px; padding: 0px 0px 0px 5px; font-size: 13px"></b-form-input>
                    </td>
                </tr>
                <tr>
                    <td>Umsögn</td>
                    <td>
                        <quill-editor v-model="itemCopy.athugasemd" :options="editorOption" @ready="quillHeight($event)">
                        </quill-editor>
                    </td>
                </tr>

            </table>
            <!-- :disabled="notValid"  -->
            <b-btn variant="primary" class="mr-2" v-if="canEdit"
                @click="validateBeforeSubmit(null)">Vista
            </b-btn>
            <b-btn variant="dark" @click="closeSlidebar">Hætta við</b-btn>
            <b-btn variant="danger" class="float-right" v-if="canEdit" @click="deleteGrade(1)">Eyða</b-btn>
        </b-form>
    </Slidebar>
</template>
  
<script>
import students from '@/api/students';
import groups from '@/api/groups';

import { mapActions, mapGetters } from 'vuex';
import { quillEditor } from 'vue-quill-editor';

import Slidebar from '@/components/common/Slidebar.vue';
import { is } from 'vuejs-datepicker/dist/locale';

export default {
    name: 'student-edit-project-grade-slidebar',
    props: ['item', 'studentId', 'selectedCourse'],
    components: {
        Slidebar,
        quillEditor,
    },
    computed: {
        canEdit() {
            return this.loggedInUserHasWritePermission('nemendahald_nemendur');
        },
        notValid() {
            return !this.checkGradeValid(this.itemCopy.einkunn).length > 0;
            // return this.list.filter((x, idx) => !this.checkGradeValid(idx)).length > 0;
        },
        ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
    },
    data() {
        return {
            lang: is,
            loading: false,
            submitted: false,
            id: null,
            moduleStatuses: [],
            scale: '',
            viewScale: false,
            itemCopy: [],
            form: {
                einkunn: '',
                aths: '',
            },
            editorOption: {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline'], // toggled buttons
                        [{ color: [] }], // dropdown with defaults from theme
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    ],
                },
            },
        };
    },
    methods: {
        quillHeight(event) {
            event.container.style.height = '100px'; // eslint-disable-line
        },
        closeSlidebar() {
            this.$emit('closeSlidebar');
        },
        checkGradeValid(val) {
            let value = val;
            if (this.itemCopy.einkunnir_afanga_id && value) {
                value = value.toString().replace('.', ',');
                if(this.scale.skali) {
                    return this.scale.skali.filter(x => (x.grade || '').toUpperCase() === value.toUpperCase()).length > 0;
                }
            }
            //   if (this.gradeGroupId && value) {
            //     value = value.toString().replace(',', '.'); // Verðum að breyta , yfir í . útaf javascript
            //     return !!isGradeValid(value);
            //   }
            return true;
        },
        async deleteGrade(del) {
            const conf = confirm('Ertu viss um að þú viljir eyða færslunni?'); // eslint-disable-line
            if (conf) {
                this.validateBeforeSubmit(del);
            }
        },
        async loadScale() {
            try {
                const response = await groups.getGradeScaleByRule(this.itemCopy.einkunnir_afanga_id);
                this.scale = response.data.items.length > 0 ? response.data.items[0] : '';
            } catch (e) {
                this.$log.error(e);
                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            }
        },
        async validateBeforeSubmit(del) {
            try {
                this.submitted = true;
                this.errorList = [];
                await this.$validator.validateAll().then(response => {
                    if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
                });
                // Engar villur í formi.
                try {
                    this.saving = true;
                    const data = {
                        ferill_id: this.itemCopy.ferill_id,
                        stada_afanga: this.form.stada_afanga.stada_afanga,
                        einkunn: this.form.einkunn ? this.form.einkunn : '',
                        dags_lokid: this.form.dags_lokid,
                        einingar: this.form.einingar ? this.form.einingar : '',
                        laest: this.form.laest ? 1 : 0,
                        aths: this.form.aths,
                        delete: del,
                    };
                    await students.postStudentGrade(this.studentId, data);
                    this.displaySuccess(`${this.form.name} hefur verið stofnuð.`);
                    this.closeSlidebar();
                } catch (e) {
                    // Aðgerð mistókst.
                    this.failed = true;
                    this.$log.debug(e);
                    if (e.response && e.response.data && e.response.data.error) {
                        this.displayError(e.response.data.error);
                    } else {
                        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
                    }
                }
            } catch (e) {
                // Villur í formi.
                this.$log.debug(e);
            } finally {
                this.saving = false;
            }
        },
        ...mapActions({
            displayError: 'displayError',
            displaySuccess: 'displaySuccess',
        }),
    },
    created() {
        this.itemCopy = this.item;
        if (this.itemCopy.einkunnir_afanga_id) {
            this.loadScale();
        }
        this.form = {
            einkunn: this.itemCopy.einkunn, //  ? this.itemCopy.einkunn : this.item.einkunn_a,
        };
    },
};
</script>
  
<style lang="scss"></style>
  