<template>
  <div>
    <b-card class="mb-4 studentInfoCard">
      <b-row>
        <b-col>
          <strong>NAFN</strong>
          <div>
            {{ item.nafn }} {{ item.bekkur }}
            <b-link
              :to="{ name: 'Student', params: { id: item.nemandi_id } }"
              target="_blank"
              v-if="canViewStudent"
              title="Skoða nemanda"
              v-b-tooltip
              ><i class="fa fa-external-link mr-1"></i
            ></b-link>
            <i
              class="fa fa-envelope cursor-pointer text-primary"
              title="Senda skilaboð"
              v-b-tooltip
              @click.prevent="openSendMessageSlidebar()"
            ></i>
          </div>
        </b-col>
        <b-col>
          <strong>KENNITALA</strong>
          <div>{{ item.kennitala }}</div>
        </b-col>
        <b-col>
          <strong>NEMANDANR</strong>
          <div>{{ item.nemandi_id }}</div>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <strong>STAÐA NEMA</strong>
          <div>{{ item.stada_nema_heiti }}</div>
        </b-col>
        <b-col>
          <strong>UNDIRSKÓLI</strong>
          <div>{{ item.undirsk_heiti }}</div>
        </b-col>
        <b-col>
          <strong>BRAUT</strong>
          <div v-for="b in item.braut" :key="b.braut_id">{{ b.braut }} {{ b.braut_kodi }}</div>
        </b-col>
      </b-row>
    </b-card>
    <send-message-slidebar v-if="showSendMessageSlidebar" :notandi_id="item && item.notandi_id" @closeSlidebar="closeMessageSlidebar">
    </send-message-slidebar>
  </div>
</template>

<script>
import students from '@/api/students';
import { mapGetters, mapActions } from 'vuex';

import SendMessageSlidebar from '@/components/common/SendMessageSlidebar.vue';

export default {
  name: 'student-info',
  props: ['id'],
  components: {
    SendMessageSlidebar,
  },
  computed: {
    canViewStudent() {
      return this.loggedInUserHasWritePermission('nemendahald_nemendur');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {
      loading: false,
      item: '',
      showSendMessageSlidebar: false,
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;
        const response = await students.getStudentInfo(this.id);
        this.item = { ...response.data, braut: response.data.braut ? JSON.parse(response.data.braut) : [] };
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    openSendMessageSlidebar() {
      this.showSendMessageSlidebar = true;
    },
    closeMessageSlidebar() {
      this.showSendMessageSlidebar = false;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    id() {
      this.load();
    },
  },
  created() {
    this.load();
  },
};
</script>
<style lang="scss">
.studentInfoCard {
  .card-body {
    padding: 10px 0px;
    font-size: 14px;
    strong {
      font-size: 12px;
    }
  }
}
</style>
