<template>
  <div>
    <b-row>
      <b-col>
        <h5>Kerfi</h5>
        <br />
        <div>
          <strong>Admin: </strong> <yes-no :item="access.adgangur_admin" />
          <i
            class="fa fa-info-circle pl-2"
            v-b-tooltip
            title="Hefur notandi aðgang að Admin vef. Aðgangur notanda stýrist af aðgangshlutverki skilgreint hérna til hægri."
          ></i>
        </div>
        <br />
        <div>
          <strong>Gamla: </strong> <yes-no :item="access.adgangur_gamla" />
          <i
            class="fa fa-info-circle pl-2"
            v-b-tooltip
            title="Hefur notandi aðgang að Gömlu Innu. Aðgangur notanda stýrist af aðgangi skilgreint í gömlu Innu."
          ></i>
        </div>
      </b-col>
      <b-col>
        <h5>Aðgangur</h5>
        <br />
        <div>
          <strong>Admin: </strong> {{ access.admin_heiti }} <i v-if="!access.admin_heiti">Ekki skráð</i>
          <i
            class="fa fa-info-circle pl-2 pr-2"
            v-b-tooltip
            title="Hlutverk sem notandi er í admin vef. Aðgangur stýrist af réttindum hlutverks."
          ></i>
          <b-link :to="{ name: 'Access' }" v-b-tooltip title="Skoða aðgangshlutverk" style="color: inherit" target="_blank"
            ><i class="fa fa-external-link"></i
          ></b-link>
        </div>
        <br />
        <div>
          <strong>Deild: </strong> {{ access.adgangur_deild_heiti }}
          <i v-if="!access.adgangur_deild_heiti">Aðgangur ekki takmarkaður við deild</i>
          <i
            class="fa fa-info-circle pl-2 pr-2"
            v-b-tooltip
            title="Ef útfyllt takmarkast aðgangur notanda að áföngum og brautum sem tilheyra deildinni."
          ></i>
        </div>
        <!--br />
        <div><strong>Námskerfi: </strong> {{ access.lms_heiti }} <i v-if="!access.lms_heiti">Ekki skráð</i></div-->
      </b-col>
    </b-row>
    <b-row class="mt-4" v-if="canEditAccess">
      <b-col>
        <b-btn variant="primary" @click="openSlidebar()"> Breyta aðgangi </b-btn>
      </b-col>
    </b-row>
    <staff-change-access-slidebar :id="id" v-if="showSlidebar" @closeSlidebar="closeSlidebar"> </staff-change-access-slidebar>
  </div>
</template>

<script>
import staff from '@/api/staff';
import { mapActions, mapGetters } from 'vuex';

import YesNo from '@/components/common/YesNo.vue';

import StaffChangeAccessSlidebar from '@/components/staff/staff/StaffChangeAccessSlidebar.vue';

export default {
  name: 'staff-access',
  props: ['id'],
  components: {
    YesNo,
    StaffChangeAccessSlidebar,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('starfsmannahald_starfsmenn');
    },
    canEditAccess() {
      return this.loggedInUserHasWritePermission('starfsmannahald_adgangur');
    },
    canReadAccess() {
      return this.loggedInUserHasWritePermission('starfsmannahald_adgangur');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      loading: {
        access: false,
      },
      access: '',
      showSlidebar: false,
    };
  },
  methods: {
    async loadAccess() {
      try {
        this.loading.access = true;
        const response = await staff.getStaffAccess(this.id);
        this.access = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.access = false;
      }
    },
    /*
     * Modal gluggar
     */
    staffChangeAccessModal() {
      this.$refs.staffChangeAccessModal.show(this.id, this.access);
    },
    openSlidebar() {
      this.showSlidebar = true;
    },
    closeSlidebar() {
      this.showSlidebar = false;
      this.loadAccess();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    id() {
      this.loadAccess();
    },
  },
  created() {
    this.loadAccess();
  },
};
</script>

<style lang="scss"></style>
