<template>
  <div>
    <div class="noprint">
      <b-row>
        <b-col class="pl-0" cols="2" v-if="!selected.showTimetable">
          <b-form-group label="Dags. frá" label-for="dateFrom">
            <datepicker
              id="dateFrom"
              name="dateFrom"
              v-model="selected.dateFrom"
              :language="lang"
              :monday-first="true"
              format="dd.MM.yyyy"
              :typeable="false"
              :clear-button="false"
              ref="dateFromOpenPickr"
              @input="loadList()"
            ></datepicker>
          </b-form-group>
        </b-col>
        <b-col cols="2" v-if="!selected.showTimetable">
          <b-form-group label="Dags. til" label-for="dateTo">
            <datepicker
              id="dateTo"
              name="dateTo"
              v-model="selected.dateTo"
              :language="lang"
              :monday-first="true"
              format="dd.MM.yyyy"
              :typeable="false"
              :clear-button="false"
              ref="dateTopenPickr"
              @input="loadList()"
            ></datepicker>
          </b-form-group>
        </b-col>
        <b-col cols="2" :class="{ 'pl-0': selected.showTimetable }">
          <b-form-group label="Undirskóli" label-for="division">
            <v-select id="division" name="division" label="name" v-model="selected.division" :options="divisions" :clearable="true">
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <div>Atburðir sem birtast:</div>
          <v-select id="searchBy" name="searchBy" v-model="selected.searchBy" :options="getSearchBy" :clearable="true"> </v-select>
        </b-col>
        <b-col v-if="selected.showTimetable">
          <div style="background-color: rgb(152, 19, 156); width: 100px; color: #fff">Ekki kennsla</div>
          <div style="background-color: rgb(152, 19, 156, 0.2); width: 100px">Kennsla</div>
          <div style="background-color: #f26175; width: 100px">Frídagur</div>
        </b-col>
        <b-col v-if="selected.showTimetable">
          <b-form-group>
            <label for="date"
              >Viðmiðunardagsetning
              <i
                class="fa fa-info-circle"
                v-b-tooltip
                title="Eingöngu til þess að flýta fyrir að skipta um mánaðarsýn í dagatalinu.
                Dagatalið verður fært sjálfkrafa í þann mánuð sem valin dagsetningin er í."
              ></i
            ></label>
            <datepicker
              id="date"
              name="date"
              v-model="selected.date"
              :language="lang"
              :monday-first="true"
              format="dd.MM.yyyy"
              :typeable="false"
              :clear-button="true"
              ref="dateOpenPickr"
            ></datepicker>
          </b-form-group>
        </b-col>
        <b-col>
          <div class="pull-right">
            <b-btn variant="primary" class="mr-2" title="Bæta við" @click="openSlidebar()" v-if="canEdit"
              ><i class="fa fa-plus-square"></i>
            </b-btn>
            <b-btn variant="primary" class="mr-2" title="Innlestur" @click="openUploadSlidebar()" v-if="canEdit"
              ><i class="fa fa-upload"></i>
            </b-btn>
            <b-btn
              variant="outline-dark"
              class="mr-2"
              v-if="!selected.showTimetable"
              @click="toggleTimetable()"
              v-b-tooltip
              title="Skoða sem stundatöflu"
              ><i class="fa fa-calendar"></i>
            </b-btn>
            <b-btn
              variant="outline-dark"
              class="mr-2"
              v-if="selected.showTimetable"
              @click="toggleTimetable()"
              v-b-tooltip
              title="Skoða sem lista"
              ><i class="fa fa-list"></i>
            </b-btn>
          </div>
        </b-col>
      </b-row>
    </div>
    <div v-if="!selected.showTimetable">
      <table class="table less-padding">
        <thead>
          <tr>
            <th><LinkOrder title="Atburður" :filteredList="list" :list="list" column="heiti" /> ({{ list.length }})</th>
            <th>
              <LinkOrder title="Dags. byrjun" :filteredList="list" :list="list" column="dags_byrjun" secondColumn="heiti" />
            </th>
            <th>
              <LinkOrder title="Dags. endar" :filteredList="list" :list="list" column="dags_endar" secondColumn="heiti" />
            </th>
            <th><LinkOrder title="Undirskóli" :filteredList="list" :list="list" column="undirsk_audk" secondColumn="heiti" /></th>
            <th>
              <LinkOrder title="Starfsmenn" :filteredList="list" :list="list" column="starfsmenn" secondColumn="heiti" />
              <i class="fa fa-info-circle" title="Atburður birtist í dagatali starfsmanna" v-b-tooltip></i>
            </th>
            <th>
              <LinkOrder title="Nemendur" :filteredList="list" :list="list" column="nemendur" secondColumn="heiti" />
              <i class="fa fa-info-circle" title="Atburður birtist í dagatali nemenda" v-b-tooltip></i>
            </th>
            <th>
              <LinkOrder title="Kennsla" :filteredList="list" :list="list" column="maeting" secondColumn="heiti" />
              <i class="fa fa-info-circle" title="Er kennsla samkvæmt stundatöflu?" v-b-tooltip></i>
            </th>
            <th><LinkOrder title="Heill dagur" :filteredList="list" :list="list" column="heill_dagur" secondColumn="heiti" /></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <TableRowLoader v-if="loading.list" colspan="7" :center="true" />
          <TableRowEmpty v-if="!loading.list && this.list.length === 0" colspan="7" :center="true" />
          <tr v-for="item in getPaginatedList" :key="item.almanak_id">
            <td>
              <b-link @click="openSlidebar(item)" v-if="canEdit">{{ item.heiti }}</b-link>
              <span v-else>{{ item.heiti }}</span>
            </td>
            <td>{{ item.dags_byrjun | moment('DD.MM.YYYY') }}</td>
            <td>{{ item.dags_endar | moment('DD.MM.YYYY') }}</td>
            <td>{{ item.undirsk_audk }}</td>
            <td v-if="!item.notendahopur_heiti"><yes-no :item="item.starfsmenn"></yes-no></td>
            <td v-if="!item.notendahopur_heiti"><yes-no :item="item.nemendur"></yes-no></td>
            <td colspan="2" v-if="item.notendahopur_heiti">
              <i v-b-tooltip title="Birtist eingöngu notendum í notendahópi">{{ item.notendahopur_heiti }}</i>
            </td>
            <td><yes-no :item="item.maeting"></yes-no></td>
            <td><yes-no :item="item.heill_dagur"></yes-no></td>
            <td>
              <b-btn variant="danger" class="btn-xs" v-if="canEdit" @click="deleteEvent(item)"><i class="fa fa-trash"></i></b-btn>
            </td>
          </tr>
        </tbody>
      </table>

      <Pagination
        :currentPage="currentPage"
        :totalRows="getList.length"
        :perPage="perPage"
        v-if="getList.length > 25"
        @change-page="changePage"
        @change-limit="changeLimit"
      ></Pagination>
    </div>
    <Timetable
      v-else
      type="school-events"
      :defaultMonth="true"
      :clickable="true"
      :id="1"
      :goToDate="selected.date"
      @dayClick="dayClick"
      @eventClick="eventClick"
      :extraParams="{
        starfsmenn: selected.searchBy && selected.searchBy.id === 'staff' ? 1 : null,
        nemendur: selected.searchBy && selected.searchBy.id === 'student' ? 1 : null,
        notendahopar: selected.searchBy && selected.searchBy.id === 'groups' ? 1 : null,
        division_id: selected.division ? selected.division.division_id : null,
      }"
    ></Timetable>

    <school-event-slidebar
      v-if="showSlidebar"
      :id="selectedItem"
      :date="selectedDate"
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
    >
    </school-event-slidebar>
    <school-event-upload-slidebar v-if="showUploadSlidebar" @closeSlidebar="closeSlidebar" @closeAndReloadSlidebar="closeAndReloadSlidebar">
    </school-event-upload-slidebar>
  </div>
</template>

<script>
import moment from 'moment';
import common from '@/api/common';
import structure from '@/api/structure';
import { mapActions, mapGetters } from 'vuex';

import Timetable from '@/components/common/Timetable.vue';
import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';
import Pagination from '@/components/common/Pagination.vue';
import YesNo from '@/components/common/YesNo.vue';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';

import SchoolEventSlidebar from '@/components/school/timetable/SchoolEventSlidebar.vue';
import SchoolEventUploadSlidebar from '@/components/school/timetable/SchoolEventUploadSlidebar.vue';

export default {
  name: 'schoolEventsTimetable',
  components: {
    Timetable,
    TableRowLoader,
    TableRowEmpty,
    LinkOrder,
    Pagination,
    YesNo,
    SchoolEventSlidebar,
    Datepicker,
    SchoolEventUploadSlidebar,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_stundatoflur');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('skolahald_stundatoflur');
    },
    getList() {
      return this.list
        .filter(x => !this.selected.division || x.undirskoli_id === this.selected.division.division_id)
        .filter(
          x =>
            !this.selected.searchBy ||
            (this.selected.searchBy.id === 'staff' && x.starfsmenn === 1 && !x.notendahopur) ||
            (this.selected.searchBy.id === 'student' && x.nemendur === 1 && !x.notendahopur) ||
            (this.selected.searchBy.id === 'groups' && x.notendahopur !== null),
        );
    },
    getPaginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.getList.slice(start, end);
    },
    getSearchBy() {
      return [
        {
          id: 'staff',
          label: 'Starfsmönnum',
        },
        {
          id: 'student',
          label: 'Nemendum',
        },
        {
          id: 'groups',
          label: 'Notendahópum',
        },
      ];
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      lang: is,
      perPage: 25,
      currentPage: 1,
      selected: {
        dateFrom: moment().toDate(),
        dateTo: moment().add(90, 'days').toDate(),
        showTimetable: true,
        division: '',
        searchBy: '',
        date: '',
      },
      list: [],
      loading: {
        list: false,
        divisions: false,
      },
      showSlidebar: false,
      showUploadSlidebar: false,
      selectedItem: '',
      selectedDate: '',
      divisions: [],
    };
  },
  methods: {
    toggleTimetable() {
      this.selected.showTimetable = !this.selected.showTimetable;
      if (!this.selected.showTimetable) {
        this.loadList();
      }
    },
    async loadList() {
      try {
        this.loading.list = true;
        const response = await common.getSchoolEvents({
          date_from: moment(this.selected.dateFrom).format('DD.MM.YYYY'),
          date_to: moment(this.selected.dateTo).format('DD.MM.YYYY'),
        });
        this.list = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.list = false;
      }
    },
    async loadDivisions() {
      try {
        this.loading.divisions = true;
        const response = await structure.getDivisionList();
        this.divisions = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.divisions = false;
      }
    },
    async deleteEvent(item) {
      const conf = confirm(`Ertu viss um að þú viljir eyða viðburðinum ${item.heiti}?`); // eslint-disable-line
      if (conf) {
        try {
          await common.updateSchoolEvent(item.almanak_id, { delete: 1 });
          this.displaySuccess('Atburði eytt.');
          this.loadList();
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    dayClick(item) {
      this.selectedItem = '';
      this.selectedDate = item.date;
      this.showSlidebar = true;
    },
    eventClick(item) {
      if (item.id) {
        this.selectedItem = item.id;
        this.selectedDate = '';
        this.showSlidebar = true;
      }
    },
    openSlidebar(item) {
      this.selectedItem = item ? item.almanak_id : '';
      this.showSlidebar = true;
    },
    openUploadSlidebar() {
      this.showUploadSlidebar = true;
    },
    closeSlidebar() {
      this.selectedItem = '';
      this.selectedDate = '';
      this.showSlidebar = false;
      this.showUploadSlidebar = false;
    },
    closeAndReloadSlidebar() {
      this.closeSlidebar();
      this.loadList();
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadList();
    this.loadDivisions();
  },
};
</script>
