<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div v-if="!loading" style="width: 100%; overflow-x: auto">
      <table class="table table-hover table-bordered less-padding mt-2" style="font-size: 12px">
        <thead>
          <tr>
            <th style="border: 1px solid #dee2e6">Nafn</th>
            <th style="border: 1px solid #dee2e6">Kennitala</th>
            <th style="border: 1px solid #dee2e6">Nemandanr</th>
            <th v-for="time in item.timar" :key="time.tafla_id" class="text-center" style="border: 1px solid #dee2e6">
              <div>{{ time.vikudagur }}</div>
              <div>{{ time.man_dag }}</div>
              <div>{{ time.timi_fra }}</div>
            </th>
            <th style="border: 1px solid #dee2e6">
              <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="cursor-pointer float-right">
                <i class="fa fa-file-excel-o excelText"></i>
              </download-csv>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(student, index) in item.kladdi" :key="student.ferill_id">
            <td>
              {{ student.nafn }}
              <b-link
                :to="{ name: 'Student', params: { id: student.nemandi_id } }"
                target="_blank"
                v-if="canViewStudent"
                title="Skoða nemanda"
                v-b-tooltip
                ><i class="fa fa-external-link"></i
              ></b-link>
            </td>
            <td>{{ student.kennitala }}</td>
            <td>{{ student.nemandi_id }}</td>
            <td v-for="(att, idx) in student.vidvera" :key="`student${index}attendance${idx}`">
              <span :class="{ 'text-danger': att.forfoll }">{{ att.fjarvist }}</span>
              <span v-if="att.forfoll"> ({{ att.forfoll }})</span>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>

      <div style="font-size: 12px" v-if="item" class="text-danger">Skráð forföll</div>
      <div style="font-size: 12px" v-if="item">
        <b>Fjarvistarkóðar:</b>
        <span v-for="att in item.fjarvista_kodar" :key="att.teg_fjarvista" class="pr-2">{{ att.kodi }} = {{ att.heiti }}</span>
      </div>
      <div style="font-size: 12px" v-if="item">
        <b>Forfallakóðar:</b> 
        <span v-for="abs in item.forfalla_kodar" :key="abs.teg_forfalla" class="pr-2">{{ abs.kodi }} = {{ abs.heiti }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import attendance from '@/api/attendance';
import { mapActions, mapGetters } from 'vuex';
import Loader from '@/components/common/Loader.vue';

export default {
  name: 'group-attendance-overview',
  props: ['groupId', 'dateFrom', 'dateTo', 'reload'],
  components: {
    Loader,
  },
  computed: {
    getExcelData() {
      const list = [];
      this.item.kladdi.forEach(x => {
        const i = {
          Nafn: x.nafn,
          Kennitala: `="${x.kennitala}"`,
          Nemandanr: x.nemandi_id,
        };
        x.vidvera.forEach(y => {
          const title = `${y.vikudagur} ${y.man_dag} ${y.timi_fra}`;
          let value = y.fjarvist || '';
          if (y.forfoll) {
            value = `${value} (${y.forfoll})`;
          }
          i[title] = value;
        });
        list.push(i);
      });

      return list;
    },
    getExcelFileName() {
      return `hopur_vidvera_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    canRead() {
      return this.loggedInUserHasReadPermission('nemendahald_hopar');
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('nemendahald_hopar');
    },
    canViewStudent() {
      return this.loggedInUserHasWritePermission('nemendahald_nemendur');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolHasSettings', 'schoolSettings']),
  },
  data() {
    return {
      loading: false,
      item: {},
    };
  },
  methods: {
    async loadList() {
      try {
        this.$emit('loadingStart');
        this.loading = true;
        const response = await attendance.getAttendanceOverview(this.groupId, {
          dateFrom: moment(this.dateFrom).format('DD.MM.YYYY'),
          dateTo: moment(this.dateTo).format('DD.MM.YYYY'),
        });
        this.item = response.data.items.length > 0 ? response.data.items[0] : {};
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
        this.$emit('loadingEnd');
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    dateFrom() {
      if (!this.loading) {
        this.loadList();
      }
    },
    dateTo() {
      if (!this.loading) {
        this.loadList();
      }
    },
    reload() {
      if (this.reload) {
        this.loadList();
      }
    },
  },
  created() {
    this.loadList();
  },
};
</script>
<style lang="scss"></style>
