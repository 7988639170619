<template>
  <table class="table table-hover less-padding attendanceModulesTable" style="font-size: 13px;">
    <Loader v-if="loading"></Loader>
    <thead v-if="!loading">
      <tr>
        <td>Áfangi</td>
        <td v-for="(d, idx) in getDates" :key="idx">
          <div>{{ d.artal }} ({{ d.vikunr }})</div>
          <div style="white-space: nowrap">{{ d.dagur_fra }}-{{ d.dagur_til }}</div>
        </td>
      </tr>
    </thead>
    <tbody v-if="!loading">
      <tr v-for="afangi in attendanceWeeks.afangar" :key="afangi.ferill_id" :afangi="afangi">
        <td>{{ afangi.namsgrein_afanganr }}</td>
        <td v-for="(m, idx) in afangi.maeting" :key="idx" :class="getAttendanceColor(m.maeting)"
          :title="`${afangi.namsgrein_afanganr}`">
          {{ m.maeting }} <span v-if="m.maeting">%</span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import students from '@/api/students';
import Loader from '@/components/common/Loader.vue';

export default {
  name: 'attendance-weeks',
  props: ['attendance', 'studentId', 'mainView'],
  components: {Loader},
  computed: {
    getDates() {
      return this.attendanceWeeks && this.attendanceWeeks.afangar && this.attendanceWeeks.afangar.length > 0 ? 
      this.attendanceWeeks.afangar[0].maeting : [];
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {
      loading: false,
      attendanceWeeks: '',
      term: {
        id: '',
        dateFrom: '',
        dateTo: '',
        name: '',
      },
    };
  },
  methods: {
    getAttendanceColor(item) {
      if (item < 80) {
        return 'attendancePercentageUnder80';
      }
      if (item >= 80 && item < 90) {
        return 'attendancePercentageUnder90';
      }
      return '';
    },
    async getStudentDefaultTerm() {
      try {
        this.noTerm = false;
        const response = await students.getStudentDefaultTerm(this.studentId);
        this.term.id = response.data.onn_id;
        this.term.dateFrom = response.data.dags_fra;
        this.term.dateTo = response.data.dags_til;
        this.term.name = response.data.onn_eink;
        this.getAttendanceWeeks();
      } catch (e) {
        if (e.status === 404) {
          this.noTerm = true;
        } else {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    async getAttendanceWeeks() {
      try {
        const response = await students.getStudentAttendanceWeeks(this.studentId, this.term.id);
        if (response.data.items.length > 0) {
          this.attendanceWeeks = response.data.items[0];
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },

    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    if (this.mainView === 1) {
      // sækja uppl.
      this.loading = true;
      this.getStudentDefaultTerm();
    } else {
      this.attendanceWeeks = this.attendance;
      this.loading = false;
    }

  }
};
</script>
<style lang="scss">
table.attendanceModulesTable {
  thead {
    background-color: #ccc;
  }

  tbody:nth-child(even) {
    background-color: #f9f9f9;
  }

  tbody:nth-child(odd) {
    background-color: #ececec;
  }

  tr {

    td,
    th {
      border-right: 2px solid #fff;
    }
  }
}
</style>
