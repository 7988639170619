<template>
  <div>
    <b-row>
      <b-col class="pl-0">
        <GroupHistory v-if="tabIndex === 'history' && item" :id="id" :item="item"></GroupHistory>
        <div v-if="tabIndex === 'applicationSettings'" class="mb-3" style="margin-top: -10px">
          Eftirfarandi upplýsingar eru þær sömu og eru skráðar á umsóknarvef Innu, kerfin tala saman svo ekki er þörf að skrá upplýsingarnar
          á báðum stöðum. Ekki er þörf á að fylla allt út.
        </div>
        <b-row v-if="tabIndex === 'settings' || tabIndex === 'applicationSettings'">
          <b-col class="pl-0">
            <ItemEdit
              v-for="(i, idx) in dataLeft"
              :key="`info${idx}`"
              :id="i.param"
              :name="i.param"
              :label="i.label"
              :item="item[i.param]"
              :maxLength="i.maxLength"
              :editStatus="edit"
              :editParam="i.param"
              :date="i.date"
              :number="i.number"
              :checkbox="i.checkbox"
              :dropdown="i.dropdown"
              :dropdownList="i.dropdownList"
              :required="i.required"
              :disabled="i.disabled || !canEdit"
              :info="i.info"
              @toChange="toChange"
              :small="true"
              @toEdit="toggleEdit"
              @saveData="saveChanges()"
            />
          </b-col>
          <b-col>
            <ItemEdit
              v-for="(i, idx) in dataRight"
              :key="`info${idx}`"
              :id="i.param"
              :name="i.param"
              :label="i.label"
              :item="item[i.param]"
              :maxLength="i.maxLength"
              :editStatus="edit"
              :editParam="i.param"
              :date="i.date"
              :number="i.number"
              :checkbox="i.checkbox"
              :dropdown="i.dropdown"
              :dropdownList="i.dropdownList"
              :required="i.required"
              :disabled="i.disabled || !canEdit"
              :info="i.info"
              @toChange="toChange"
              :small="true"
              @toEdit="toggleEdit"
              @saveData="saveChanges()"
            />
            <b-btn variant="primary" class="float-right" v-if="edit" @click="saveChanges()">Vista breytingar</b-btn>
            <b-btn variant="cancel" class="mr-2 float-right" v-if="edit" @click="closeEdit()">Hætta við</b-btn>
            <b-btn variant="danger" v-if="canEdit && !edit && tabIndex === 'settings'" @click="deleteGroup()"
              ><i class="fa fa-trash"></i> Eyða hóp</b-btn
            >
            <div v-if="canEdit && !edit" class="d-inline-block float-rigth" :class="{ 'pl-3': tabIndex === 'settings' }">
              <i>Smellið í reitina til að gera breytingar</i>
            </div>
          </b-col>
        </b-row>
        <div v-if="tabIndex === 'settings' && item">
          <h5 class="group-detail-title">Kennarar</h5>
          <TeachersGroup :item="item" :canEdit="canEdit"></TeachersGroup>
        </div>

        <div v-if="tabIndex === 'timetable' && item">
          <div class="mb-3">
            Gildistími hóps
            <i class="fa fa-info-circle" title="Hægt er að smella á dagsetningarnar til að sjá þá viku." v-b-tooltip></i>:
            <span class="cursor-pointer text-primary" @click="selectDate(item.dags_fra)" title="Skoða viku" v-b-tooltip>{{
              item.dags_fra | moment('DD.MM.YYYY')
            }}</span>
            -
            <span class="cursor-pointer text-primary" @click="selectDate(item.dags_til)" title="Skoða viku" v-b-tooltip>{{
              item.dags_til | moment('DD.MM.YYYY')
            }}</span>
          </div>
          <Timetable :id="id" type="group" :goToDate="selectedGroupDate"></Timetable>
        </div>

        <div v-if="tabIndex === 'cancel' && item">
          <h5>Fella niður {{ item.namsgrein_afanganr }}({{ item.hopur }})</h5>
          <div>
            Við það að fella niður námskeið þá eru allar umsóknir teknar af hópnum, verð tekið af þeim umsóknum sem voru með þennan hóp og
            hópnum lokað.
          </div>
          <div>Ath. ekki er hægt að afturkalla þessa aðgerð</div>
          <b-btn variant="primary" @click="cancelGroup()" class="mt-2">Fella niður</b-btn>
        </div>
        <b-row v-if="tabIndex === 'studyPlan'">
          <ModuleAttachment :moduleId="item.afangi_id" :termId="item.onn_id" :type="studyPlanType" :groupId="id"></ModuleAttachment>
        </b-row>
      </b-col>
      <b-col cols="2">
        <div class="right-side-nav noprint">
          <b-nav vertical>
            <b-nav-item @click.prevent="changeTab('history')" :active="tabIndex === 'history'">Saga</b-nav-item>
            <b-nav-item
              @click.prevent="
                changeTab('settings');
                isApplication = false;
              "
              :active="tabIndex === 'settings'"
              >Stillingar</b-nav-item
            >
            <b-nav-item
              @click.prevent="
                changeTab('applicationSettings');
                isApplication = true;
              "
              :active="tabIndex === 'applicationSettings'"
              >Umsóknarvefur</b-nav-item
            >
            <b-nav-item @click.prevent="changeTab('timetable')" :active="tabIndex === 'timetable'">Stundatafla</b-nav-item>
            <b-nav-item
              v-if="canEdit && isLearningCenter && item && item.fjoldi_i_hop === 0"
              @click.prevent="changeTab('cancel')"
              :active="tabIndex === 'cancel'"
              >Fella niður námskeið</b-nav-item
            >
            <b-nav-item
              @click.prevent="
                changeTab('studyPlan');
                isApplication = false;
              "
              :active="tabIndex === 'studyPlan'"
              >Námsáætlun</b-nav-item
            >
          </b-nav>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from 'moment';
import classes from '@/api/classes';
import groups from '@/api/groups';
import { mapGetters, mapActions } from 'vuex';
import ItemEdit from '@/components/common/ItemEdit.vue';

import GroupHistory from '@/components/students/groups/group/GroupHistory.vue';
import Timetable from '@/components/common/Timetable.vue';
import TeachersGroup from '@/components/students/groups/group/TeachersGroup.vue';
import ModuleAttachment from '@/components/curriculum/modules/ModuleAttachment.vue';

export default {
  name: 'group-details',
  props: ['id', 'item', 'canEdit'],
  components: {
    ItemEdit,
    GroupHistory,
    Timetable,
    TeachersGroup,
    ModuleAttachment,
  },
  computed: {
    dataLeft() {
      return this.isApplication ? this.applicationData.left : this.data.left;
    },
    dataRight() {
      return this.isApplication ? this.applicationData.right : this.data.right;
    },
    afangaskoli() {
      return this.schoolSettings && this.schoolSettings.afangaskoli ? this.schoolSettings.afangaskoli === 1 : false;
    },
    isLearningCenter() {
      return this.schoolSettings && this.schoolSettings.tegund_skola_id === 4;
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      tabIndex: 'settings',
      page: 'details',
      editItem: {},
      edit: false,
      isApplication: false,
      selectedGroupDate: '',
      studyPlanType: 'teachingplan',
      applicationData: {
        left: [
          {
            param: 'fjoldi_nema',
            label: 'Hámarksfjöldi nema',
            number: false,
            info: 'Hámarksfjöldi nema sem verður tekinn inn í hópinn, ef ekkert hámark er skráð á þessi dálkur að vera tómur.',
          },
          {
            param: 'fj_umsokn_min',
            label: 'Lágmarksfjöldi nema.',
            number: true,
            info: 'Lágmarksfjöldi nema sem þarf til að hópurinn verði kenndur, ef enginn lágmarksfjöldi er skilgreindur kemur sjálfkrafa 0.', //eslint-disable-line
          },
          {
            param: 'bidlisti',
            label: 'Biðlisti',
            number: true,
            info: 'Hversu margir geta verið skráðir á biðlista, ef biðlisti er fullur birtast umsækjanda skilaboð að biðlisti sé fullur og ekki tekið á móti fleiri skráningum.', //eslint-disable-line
          },
          { param: 'timasetning', label: 'Tímasetning', maxLength: '1000', required: false, info: 'Hvenær dags hópur er kenndur' },
          {
            param: 'stadsetning',
            label: 'Staðsetning',
            maxLength: '1000',
            required: false,
            info: 'Hvar verður kennt t.d. ef skóli er í fleiri en einu húsnæði.',
          },
          { param: 'kennsludagar', label: 'Kennsludagar', maxLength: '1000', required: false, info: 'Hvaða dag/a verður kennt.' },
          {
            param: 'tengilidur',
            label: 'Tengiliður',
            maxLength: '1000',
            required: false,
            info: 'Hver heldur utan um námið, þetta þarf ekki endilega að vera kennarinn.',
          },
          { param: 'hopur_opinn', label: 'Hópur opinn til umsóknar', checkbox: true, info: 'Má sækja um í hópinn.' },
        ],
        right: [
          {
            param: 'dags_fra',
            label: 'Dagsetning frá',
            date: true,
            required: false,
            info: 'Ef ekki er sett inn dagsetning þá er hún sótt í kennslutímabilið.',
          },
          {
            param: 'dags_til',
            label: 'Dagsetning til',
            date: true,
            required: false,
            info: 'Ef ekki er sett inn dagsetning þá er hún sótt í kennslutímabilið.',
          },
          {
            param: 'umsokn_dags_fra',
            label: 'Opna fyrir umsóknir',
            date: true,
            required: false,
            info: 'Má sækja um þegar þessi dagssetning hefst.',
          },
          {
            param: 'umsokn_dags_til',
            label: 'Loka fyrir umsóknir',
            date: true,
            required: false,
            info: 'Ekki hægt að sækja um þegar þessi dagssetning hefst.',
          },
          {
            param: 'fyrsti_greidsludags',
            label: 'Fyrsti greiðsludagur hóps',
            date: true,
            required: false,
            info: 'Byrjað verður að gjaldfæra greiðslur þennan dag.',
          },
          {
            param: 'upphaed',
            label: 'Upphæð',
            number: true,
            info: 'Gjald sem þarf að greiða fyrir námsskeiðið/hópinn, ef skóli er með skilgreint einingagjald þá leggst þessi upphæð ofan á það.', //eslint-disable-line
          },
          { param: 'lysing', label: 'Lýsing', maxLength: '4000', required: false, info: 'Lýsing á námsskeiðinu/hópnum.' },
        ],
      },
      data: {
        left: [
          { param: 'hopur', label: 'Hópur', maxLength: '20', required: true },
          { param: 'fjoldi_nema', label: 'Hámarksfjöldi nema', number: false },
        ],
        right: [
          {
            param: 'dags_fra',
            label: 'Dagsetning frá',
            date: true,
            required: false,
            info: 'Ef ekki er sett inn dagsetning þá er hún sótt í kennslutímabilið.',
          },
          {
            param: 'dags_til',
            label: 'Dagsetning til',
            date: true,
            required: false,
            info: 'Ef ekki er sett inn dagsetning þá er hún sótt í kennslutímabilið.',
          },
        ],
      },
    };
  },
  methods: {
    refreshGroup() {
      this.$emit('refresh');
    },
    toChange(item) {
      this.editItem[item.column] = item.value;
    },
    toggleEdit() {
      this.edit = true;
    },
    closeEdit() {
      this.edit = false;
    },
    changeTab(tab) {
      this.tabIndex = tab;
      this.$router.push({ name: 'Group', query: { page: this.page, tab: this.tabIndex } });
    },
    selectDate(date) {
      this.selectedGroupDate = moment(date);
    },
    async loadClasses() {
      try {
        const response = await classes.getClassList({
          term: this.item.onn_id,
        });
        this.data.right[2].dropdownList = response.data.items.map(x => ({
          value: x.bekkur_id,
          text: `${x.heiti}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async saveChanges() {
      try {
        const data = {
          ...this.editItem,
          dags_fra: this.editItem.dags_fra ? moment(this.editItem.dags_fra).format('DD.MM.YYYY') : '',
          dags_til: this.editItem.dags_til ? moment(this.editItem.dags_til).format('DD.MM.YYYY') : '',
          bekkjarhopur: this.afangaskoli ? '' : this.editItem.bekkjarhopur,
          umsokn_dags_fra: this.editItem.umsokn_dags_fra ? moment(this.editItem.umsokn_dags_fra).format('DD.MM.YYYY') : '',
          umsokn_dags_til: this.editItem.umsokn_dags_til ? moment(this.editItem.umsokn_dags_til).format('DD.MM.YYYY') : '',
          fyrsti_greidsludags: this.editItem.fyrsti_greidsludags ? moment(this.editItem.fyrsti_greidsludags).format('DD.MM.YYYY') : '',
        };
        await groups.updateGroup(this.item.hopur_id, data);
        this.displaySuccess('Hóp breytt');
        this.$emit('refresh');
        this.edit = false;
      } catch (e) {
        // Aðgerð mistókst.
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async deleteGroup() {
      const conf = confirm(`Ert þú viss um að eyða hópnum ${this.item.namsgrein_afanganr}(${this.item.hopur})?`); // eslint-disable-line
      if (conf) {
        try {
          await groups.updateGroup(this.item.hopur_id, { delete: 1 });
          this.displaySuccess('Hópi eytt.');
          this.$router.push({ name: 'Groups' });
        } catch (e) {
          this.$log.error(e);
          if (e.response && e.response.data && e.response.data.error) {
            this.displayError(e.response.data.error);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      }
    },
    async cancelGroup() {
      const conf = confirm(`Ert þú viss um að fella niður ${this.item.namsgrein_afanganr}(${this.item.hopur})?`); // eslint-disable-line
      if (conf) {
        try {
          await groups.cancelGroup(this.item.hopur_id);
          this.displaySuccess('Námskeið fellt niður');
          this.tabIndex = 'settings';
          this.$router.push({ name: 'Group', params: { id: this.item.hopur_id }, query: { page: this.page, tab: this.tabIndex } });

          this.$emit('refresh');
        } catch (e) {
          this.$log.error(e);
          if (e.response && e.response.data && e.response.data.error) {
            this.displayError(e.response.data.error);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  beforeRouteUpdate(to, from, next) {
    if (to && to.query && to.query.page) {
      this.page = to.query.page;
    }
    if (to && to.query && to.query.tab) {
      this.tabIndex = to.query.tab;
    }
    next();
  },
  created() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
    if (this.$route.query.tab) {
      this.tabIndex = this.$route.query.tab;
      if (this.tabIndex === 'applicationSettings') {
        this.isApplication = true;
      }
    }
    this.editItem = { ...this.item, dags_fra: this.item.hopur_dags_fra, dags_til: this.item.hopur_dags_til };
    if (!this.afangaskoli && this.data.left.indexOf(x => x.param === 'bekkjarhopur') === -1) {
      this.data.left.push({ param: 'bekkjarhopur', label: 'Bekkjarhópur', checkbox: true, required: false });
      this.data.right.push({ param: 'bekkur_id', label: 'Bekkur', dropdown: true, dropdownList: [], required: false });
      this.loadClasses();
    }
  },
};
</script>
<style lang="scss">
.group-detail-title {
  margin-top: 10px;
  background-color: rgba(152, 19, 156, 0.05);
  padding: 5px;
}
</style>
