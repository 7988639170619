<template>
  <Slidebar :autoClose="true" @closeMe="closeSlidebar" :large="large" :extraLarge="extraLarge">
    <h4>Hópaleit</h4>
    <br />
    <GroupSearch @changeGroup="closeSlidebar" :slidebar="true" :term="term" :module="module" :group="group" :department="department" />
  </Slidebar>
</template>

<script>
import Slidebar from '@/components/common/Slidebar.vue';
import GroupSearch from '@/components/students/groups/GroupSearch.vue';

export default {
  name: 'group-search-slidebar',
  components: {
    Slidebar,
    GroupSearch,
  },
  props: ['large', 'extraLarge', 'searchQuery'],
  data() {
    return {
      term: '',
      module: '',
      group: '',
      department: '',
    };
  },
  methods: {
    closeSlidebar(item) {
      this.$emit('closeSlidebar', item);
    },
  },
  created() {
    this.term = '';
    this.module = '';
    this.group = '';
    this.department = '';

    if (this.searchQuery) {
      const search = this.searchQuery.split(',');
      this.term = search.length > 0 ? search[0] : '';
      this.module = search.length > 1 ? search[1] : '';
      this.group = search.length > 2 ? search[2] : '';
      this.department = search.length > 3 ? search[3] : '';
    }
  },
};
</script>

<style lang="scss"></style>
