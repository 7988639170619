<template>
  <page>
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <PageTitle title="Pantanir á búnaði" />
    <b-row>
      <b-col class="pl-0">
        <strong>Búnaður</strong>
        <v-select
          id="selectEquipment"
          name="selectEquipment"
          v-model="selectedEquipment"
          :options="getEquipments"
          @input="loadList"
          ref="selectCourseDropdown"
          placeholder="Smelltu hér til að velja búnað"
        >
        </v-select>
        <div class="d-inline-block float-right">
          <div class="d-inline-block"><b-form-checkbox v-model="showInactive"></b-form-checkbox></div>
          <div class="d-inline-block pr-1" style="vertical-align: middle; margin-top: -7px">Sýna óvirkan búnað</div>
        </div>
      </b-col>
      <b-col class="pl-0">
        <strong>Starfsmaður</strong>
        <v-select
          id="selectStaff"
          name="selectStaff"
          v-model="selectedStaff"
          :options="staff"
          @input="loadList"
          ref="selectStaffDropdown"
          placeholder="Smelltu hér til að velja starfsmann"
        >
        </v-select>
      </b-col>
      <b-col class="pl-0">
        <strong>Nemandi</strong>
        <v-select
          id="selectedStudent"
          name="selectedStudent"
          v-model="selectedStudent"
          :options="students"
          @input="loadList"
          ref="selectStudentDropdown"
          placeholder="Smelltu hér til að velja nemanda"
        >
        </v-select>
      </b-col>
      <b-col>
        <b-btn
          variant="outline-dark"
          class="float-right"
          v-if="!showAsTimetable"
          @click="showAsTimetable = true"
          title="Skoða sem stundatöflu"
          ><i class="fa fa-calendar"
        /></b-btn>
        <b-btn
          variant="outline-dark"
          class="float-right"
          v-if="showAsTimetable"
          @click="
            showAsTimetable = false;
            loadList();
          "
          title="Skoða sem lista"
          ><i class="fa fa-list"
        /></b-btn>
        <b-btn variant="primary" class="float-right mr-2" title="Bæta við" @click="openSlidebar()" v-if="canEdit"
          ><i class="fa fa-plus-square"
        /></b-btn>
      </b-col>
    </b-row>
    <OrderList
      :equipment="selectedEquipment"
      :student="selectedStudent"
      :staff="selectedStaff"
      :load="reload"
      v-if="!showAsTimetable"
    ></OrderList>
    <Timetable
      id="0"
      type="equipment-orders"
      :extraParams="getTimetableData"
      :refetch="reload"
      :clickable="true"
      @dayClick="dayClick"
      @eventClick="eventClick"
      v-else
    ></Timetable>
    <equipment-order-slidebar @closeSlidebar="loadList" :item="slidebarItem" v-if="showOrderSlidebar" :large="true">
    </equipment-order-slidebar>
  </page>
</template>

<script>
import moment from 'moment';
import structure from '@/api/structure';
import students from '@/api/students';
import staff from '@/api/staff';
import { mapGetters, mapActions } from 'vuex';

import { is } from 'vuejs-datepicker/dist/locale';
import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import PageTitle from '@/components/common/PageTitle.vue';

import Timetable from '@/components/common/Timetable.vue';
import OrderList from '@/components/requests/equipments/OrderList.vue';
import EquipmentOrderSlidebar from '@/components/requests/equipments/EquipmentOrderSlidebar.vue';

export default {
  name: 'order-equipment',
  components: {
    Breadcrumbs,
    PageTitle,
    Timetable,
    OrderList,
    EquipmentOrderSlidebar,
  },
  computed: {
    getTimetableData() {
      return {
        classroomId: this.selectedEquipment ? this.selectedEquipment.bunadur_id : '',
        staffId: this.selectedStaff ? this.selectedStaff.starfsm_id : '',
        studentId: this.selectedStudent ? this.selectedStudent.nemandi_id : '',
      };
    },
    getEquipments() {
      return this.equipments.filter(x => this.showInactive || x.virkt === 1);
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('beidnir_bunadur');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      reload: false,
      lang: is,
      breadcrumbs: [
        { name: 'Forsíða', route: 'Dashboard' },
        { name: 'Beiðnir', route: '' },
        { name: 'Pantanir á búnaði', route: 'Equipments' },
      ],
      equipments: [],
      list: [],
      staff: [],
      students: [],
      loading: {
        equipments: false,
        requests: false,
        staff: false,
        student: false,
        list: false,
      },
      selectedEquipment: null,
      selectedStudent: null,
      selectedStaff: null,
      showAsTimetable: false,
      showOrderSlidebar: false,
      slidebarItem: null,
      showInactive: false,
    };
  },
  methods: {
    closeSlidebar() {
      this.showOrderSlidebar = false;
    },
    loadList() {
      this.closeSlidebar();
      this.reload = true;
      setTimeout(() => {
        this.reload = false;
      }, 1000);
    },
    async loadEquipments(id) {
      try {
        this.loading.equipments = true;
        this.equipments = [];
        const response = await structure.getEquipmentList();
        this.equipments = response.data.items.map(x => ({
          ...x,
          label: x.virkt === 0 ? `${x.heiti} - Óvirkt` : x.heiti,
        }));
        if (id) {
          this.selectedEquipment = this.equipments.find(x => x.bunadur_id === parseInt(id, 10));
          this.loadList();
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.equipments = false;
      }
    },
    async loadStaff() {
      try {
        this.loading.staff = true;
        this.staff = [];
        const response = await staff.getStaffList();
        this.staff = response.data.items.map(x => ({ starfsm_id: x.starfsm_id, label: `${x.nafn} - ${x.skammst}` }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.staff = false;
      }
    },
    async loadStudents() {
      try {
        this.loading.students = true;
        this.students = [];
        const response = await students.getStudentList({ active: 1 });
        this.students = response.data.items.map(x => ({ nemandi_id: x.nemandi_id, label: `${x.nafn} - kt.${x.kennitala}` }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.students = false;
      }
    },
    openSlidebar(item) {
      if (item) {
        this.slidebarItem = item;
      } else {
        this.slidebarItem = {
          equipmentId: this.selectedEquipment ? this.selectedEquipment.bunadur_id : '',
          staffId: this.selectedStaff ? this.selectedStaff.starfsm_id : '',
          studentId: this.selectedStudent ? this.selectedStudent.nemandi_id : '',
        };
      }
      this.showOrderSlidebar = true;
    },
    eventClick(item) {
      if (this.canEdit) {
        const data = {
          id: item && item.id ? item.id : '',
        };
        this.openSlidebar(data);
      }
    },
    dayClick(item) {
      const data = {
        equipmentId: this.selectedEquipment ? this.selectedEquipment.bunadur_id : '',
        date: moment(item.date).toDate(),
      };
      this.openSlidebar(data);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    const id = this.$route.query && this.$route.query.id ? this.$route.query.id : null;
    this.loadEquipments(id);
    this.loadStaff();
    this.loadStudents();
    if (!id) {
      this.loadList();
    }
  },
};
</script>
<style lang="scss"></style>
