<template>
  <div>
    <b-modal
      id="messageModal"
      title="Senda skilaboð á hópinn"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Senda"
      cancel-title="Hætta við"
      @ok.prevent="validateBeforeSubmit"
      ref="messageModal"
    >
      <div>
        <b-form @submit.prevent="validateBeforeSubmit" novalidate>
          <b-form-group
            label="Titill:"
            label-for="subject"
            :state="submitted && errors.has('subject') ? false : ''"
            :feedback="errors.first('subject')"
          >
            <b-form-input
              id="subject"
              name="subject"
              autofocus
              type="text"
              v-model="subject"
              required
              :state="submitted && errors.has('subject') ? false : ''"
              v-validate="'required'"
              data-vv-as="titill"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-for="text">
            <quill-editor
              id="text"
              name="text"
              v-model="text"
              :state="submitted && errors.has('text') ? false : ''"
              ref="textQuillEditor"
              :options="editorOption"
              v-validate="'required'"
              @ready="quillHeight($event)"
            >
            </quill-editor>
            <div v-if="submitted && errors.has('text')" class="text-danger">Ekki má senda tóm skilaboð.</div>
          </b-form-group>
          <h5>Þátttakendur</h5>
          <div v-if="submitted && selectedParticipants.length === 0" class="text-danger">Vinsamlegast veljið amk einn þátttakenda.</div>
          <table class="table less-padding no-border">
            <tr>
              <th></th>
              <th>Nafn</th>
              <th>Kennitala</th>
              <th>Netfang</th>
            </tr>
            <tr v-for="p in participants" :key="p.kt">
              <td><b-form-checkbox v-model="p.selected" /></td>
              <td>{{ p.nafn }}</td>
              <td>{{ p.kt }}</td>
              <td>{{ p.email }}</td>
            </tr>
          </table>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { quillEditor } from 'vue-quill-editor';
import singleClasses from '@/api/singleClasses';

export default {
  name: 'message-modal',
  components: {
    quillEditor,
  },
  computed: {
    selectedParticipants() {
      return this.participants.filter(x => x.selected);
    },
  },
  data() {
    return {
      id: '',
      participants: [],
      subject: '',
      text: '',
      submitted: false,
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            ['blockquote', 'code-block'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ color: [] }], // dropdown with defaults from theme
            ['clean'], // remove formatting button
          ],
        },
      },
    };
  },
  methods: {
    quillHeight(event) {
      event.container.style.height = '250px'; // eslint-disable-line
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response || this.selectedParticipants.length === 0) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        try {
          const item = {
            id: this.id,
            email: this.selectedParticipants
              .filter(x => x.email && x.email.length > 0)
              .map(x => x.email)
              .join(','),
            title: this.subject,
            text: this.text,
          };
          await singleClasses.sendMessage(item);
          this.displaySuccess('Skilaboð voru send á valda þátttakendur');
          this.$emit('successful', {});
          this.$refs.messageModal.hide();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.saving = false;
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.saving = false;
      }
    },
    show(id, participants) {
      this.id = id;
      this.participants = participants.map(x => ({
        ...x,
        selected: true,
      }));
      this.subject = '';
      this.text = '';
      this.$refs.messageModal.show();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
