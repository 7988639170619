<template>
  <Slidebar @closeMe="closeSlidebar" :large="true">
    <div class="d-inline-block mt-2">
      <h4>Magnbreyta áföngum í boði</h4>
      <div class="mb-2">1. Hakaðu við hverju þú vilt breyta.</div>
      <div class="d-inline-block pr-3">
        <div>Í próftöflu</div>
        <b-form-checkbox v-model="edit.i_proftoflu"></b-form-checkbox>
      </div>
      <div class="d-inline-block pr-3">
        <div>Í stokk</div>
        <b-form-checkbox v-model="edit.i_stokk"></b-form-checkbox>
      </div>
      <div class="d-inline-block pr-3">
        <div>Í stundatöflu</div>
        <b-form-checkbox v-model="edit.i_stundatoflu"></b-form-checkbox>
      </div>
      <div class="d-inline-block pr-3">
        <div>Opið val</div>
        <b-form-checkbox v-model="edit.opid_val"></b-form-checkbox>
      </div>
      <br />
      <div class="mb-2 mt-2">2. Veldu rétt gildi</div>
      <b-form-group label="Í próftöflu" label-for="i_proftoflu" v-if="edit.i_proftoflu" class="mb-1">
        <v-select id="i_proftoflu" name="i_proftoflu" v-model="form.i_proftoflu" :options="yesNo" :clearable="false"> </v-select>
      </b-form-group>
      <b-form-group label="Í stokk" label-for="i_stokk" v-if="edit.i_stokk" class="mb-1">
        <v-select id="i_stokk" name="i_stokk" v-model="form.i_stokk" :options="yesNo" :clearable="false"> </v-select>
      </b-form-group>
      <b-form-group label="Í stundatöflu" label-for="i_stundatoflu" v-if="edit.i_stundatoflu" class="mb-1">
        <v-select id="i_stundatoflu" name="i_stundatoflu" v-model="form.i_proftoflu" :options="yesNo" :clearable="false"> </v-select>
      </b-form-group>
      <b-form-group label="Opið val" label-for="opid_val" v-if="edit.opid_val" class="mb-1">
        <v-select id="opid_val" name="opid_val" v-model="form.opid_val" :options="yesNo" :clearable="false"> </v-select>
      </b-form-group>
      <div class="mt-2 mb-2">3. Smelltu á breyta til að vista breytingu eða eyða til að eyða völdum færslum</div>
      <b-btn variant="primary" class="mr-2" :disabled="!isEditing" v-if="canEdit" @click="validateBeforeSubmit"> Breyta </b-btn>
      <b-btn variant="danger" class="float-right" v-if="canEdit" @click="deleteTermModule"> Eyða </b-btn>
      <div class="mt-3">
        <strong>Færslur sem verið er að breyta</strong>
      </div>
      <span v-for="(item, idx) in items" :key="item.annir_afangar_id"> <span v-if="idx != 0">, </span>{{ item.namsgrein_afanganr }} </span>
    </div>
  </Slidebar>
</template>

<script>
import terms from '@/api/terms';

import { mapActions, mapGetters } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';

export default {
  name: 'term-module-mass-edit-slidebar',
  props: ['id', 'items'],
  components: {
    Slidebar,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    isEditing() {
      return Object.values(this.edit).filter(x => x === true).length > 0;
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {
      saving: false,
      loading: false,
      edit: {
        i_proftoflu: false,
        i_stokk: false,
        i_stundatoflu: false,
        opid_val: false,
      },
      form: {
        i_proftoflu: { id: 1, label: 'Já' },
        i_stokk: { id: 1, label: 'Já' },
        i_stundatoflu: { id: 1, label: 'Já' },
        opid_val: { id: 0, label: 'Nei' },
      },
      yesNo: [
        { id: 1, label: 'Já' },
        { id: 0, label: 'Nei' },
      ],
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async validateBeforeSubmit() {
      try {
        this.saving = true;
        const promises = [];
        this.items.forEach(i => {
          const item = {
            i_proftoflu: this.edit.i_proftoflu ? this.form.i_proftoflu.id : i.i_proftoflu,
            i_stokk: this.edit.i_stokk ? this.form.i_stokk.id : i.i_stokk,
            i_stundatoflu: this.edit.i_stundatoflu ? this.form.i_stundatoflu.id : i.i_stundatoflu,
            opid_val: this.edit.opid_val ? this.form.opid_val.id : i.opid_val,
          };
          promises.push(terms.updateTermModule(i.annir_afangar_id, item));
        });

        Promise.all(promises).then(() => {
          this.displaySuccess('Áföngum í boði hefur verið breytt');
          this.$emit('closeAndReloadSlidebar');
        });
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.saving = false;
      }
    },
    async deleteTermModule() {
      try {
        this.saving = true;
        const promises = [];
        this.items.forEach(i => {
          promises.push(terms.updateTermModule(i.annir_afangar_id, { delete: 1 }));
        });

        Promise.all(promises).then(() => {
          this.displaySuccess('Áföngum í boði hefur verið eytt');
          this.$emit('closeAndReloadSlidebar');
        });
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.saving = false;
      }
    },
    initialize() {
      this.edit = {
        i_proftoflu: false,
        i_stokk: false,
        i_stundatoflu: false,
        opid_val: false,
      };
      this.form = {
        i_proftoflu: { id: 1, label: 'Já' },
        i_stokk: { id: 1, label: 'Já' },
        i_stundatoflu: { id: 1, label: 'Já' },
        opid_val: { id: 0, label: 'Nei' },
      };
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.initialize();
  },
};
</script>

<style lang="scss"></style>
