<template>
  <div>
    <b-row>
      <b-col class="pl-0"> Yfirlit yfir áfanga sem tengdir eru við brautaruppbygginguna. </b-col>
      <b-col class="pr-0">
        <div v-if="canEdit" class="float-right mb-2" style="margin-top: -10px">
          <b-btn variant="primary" v-if="!edit" class="mr-2" size="sm" @click="openAddModuleSlidebar()"
            ><i class="fa fa-plus-circle"></i> Bæta við áfanga</b-btn
          >
          <b-btn variant="primary" class="mr-2" size="sm" @click="toggleEdit()">
            <span v-if="edit"><i class="fa fa-remove"></i> Hætta við</span
            ><span v-else> <i class="fa fa-pencil"></i> Breyta uppbyggingu</span></b-btn
          >
          <b-btn variant="primary" size="sm" v-if="edit" @click="saveData()">Vista</b-btn>
        </div>
      </b-col>
    </b-row>
    <table class="table" style="font-size: 14px">
      <thead>
        <tr>
          <th style="width: 2%; white-space: nowrap" v-if="canEdit">
            <b-form-checkbox v-model="selectAll" @change="updateAll()" class="d-inline-block"></b-form-checkbox>
            <b-btn
              variant="danger"
              class="btn-xs d-inline-block"
              style="font-size: 10px"
              v-if="getSelected.length > 0"
              @click="deleteSelected()"
            >
              <i class="fa fa-trash"></i>
            </b-btn>
          </th>
          <th>
            <LinkOrder title="Áfangi" :filteredList="list" :list="list" column="namsgrein_afanganr" />
            ({{ list.length }})
          </th>
          <th>
            <LinkOrder title="Heiti áfanga" :filteredList="list" :list="list" column="afanga_heiti" second_column="namsgrein_afanganr" />
          </th>
          <th>
            <LinkOrder title="Einingar" :filteredList="list" :list="list" column="einingar" second_column="namsgrein_afanganr" />
          </th>
          <th>
            <LinkOrder title="Þrep" :filteredList="list" :list="list" column="trep" second_column="namsgrein_afanganr" />
          </th>
          <th>
            <LinkOrder title="Tímabil" :filteredList="list" :list="list" column="onn_nr" second_column="namsgrein_afanganr" />
            <i
              class="fa fa-info-circle"
              title="Ef taka á áfangann á ákveðnu kennslutímabili þarf að fylla út númer tímabilsins sem á að taka áfangann á hérna.
            Ef nemandi á að taka áfangann á öðru kennslutímabili þá er sett 2."
              v-b-tooltip
            ></i>
          </th>
          <th>
            <LinkOrder title="Forgangsröðun" :filteredList="list" :list="list" column="forgangsrodun" second_column="namsgrein_afanganr" />

            <i
              class="fa fa-info-circle"
              title="Ef áfangi á að vera í forgangi þegar það er sjálfvirkt lesið í feril er hægt að setja inn tölu hér.
            Það er síðan raðað eftir tölunum. Þannig sá sem er í mestum forgang væri með 1, næsti 2 osfrv.
            Ef forgangur skiptir ekki máli þá er þetta skilið eftir tómt."
              v-b-tooltip
            ></i>
          </th>
          <th>
            <LinkOrder title="Uppbygging" :filteredList="list" :list="list" column="uppbygging_heiti" second_column="namsgrein_afanganr" />
          </th>
          <th>
            <LinkOrder title="Tegund" :filteredList="list" :list="list" column="tegund_heiti" second_column="namsgrein_afanganr" />
          </th>
          <th>
            <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="d-inline float-right">
              <i class="fa fa-file-excel-o excelText cursor-pointer"></i>
            </download-csv>
          </th>
        </tr>
      </thead>
      <TableRowLoader v-if="loading" colspan="7" :center="true" />
      <TableRowEmpty v-if="!loading && this.list.length === 0" colspan="7" :center="true" />
      <tr v-for="item in list" :key="item.braut_afangar_id">
        <td v-if="canEdit"><b-form-checkbox v-model="item.selected"></b-form-checkbox></td>

        <td>
          {{ item.namsgrein_afanganr }}

          <i
            class="fa fa-ban mr-2"
            style="color: #f26175"
            v-if="item.gildi === 0"
            v-b-tooltip
            title="Ath. eftirfarandi áfangi er óvirkur"
          ></i>
          <b-link
            v-if="canReadModules"
            :to="{ name: 'Module', params: { id: item.afangi_id } }"
            target="_blank"
            v-b-tooltip
            title="Skoða áfanga"
            ><i class="fa fa-search"></i
          ></b-link>
        </td>
        <td>{{ item.afanga_heiti }}</td>
        <td>{{ item.einingar }}</td>
        <td>{{ item.trep }}</td>
        <td>
          <div v-if="edit">
            <b-form-input
              type="number"
              :id="`${item.braut_afangar_id}-onn_nr`"
              :name="`${item.braut_afangar_id}-onn_nr`"
              v-model="item.editInstance.onn_nr"
              style="width: 60px; height: 30px; font-size: 13px"
            />
          </div>
          <div v-else>
            {{ item.onn_nr }}
          </div>
        </td>
        <td>
          <div v-if="edit">
            <b-form-input
              type="number"
              :id="`${item.braut_afangar_id}-forgangsrodun`"
              :name="`${item.braut_afangar_id}-forgangsrodun`"
              v-model="item.editInstance.forgangsrodun"
              style="width: 60px; height: 30px; font-size: 13px"
            />
          </div>
          <div v-else>
            {{ item.forgangsrodun }}
          </div>
        </td>
        <td>{{ item.uppbygging_heiti }}</td>
        <td>{{ item.tegund_heiti }}</td>
        <td>
          <b-btn
            variant="danger"
            class="btn-xs float-right"
            v-if="canEdit"
            @click="deleteModule(item)"
            title="Fjarlægja af braut"
            v-b-tooltip
            ><i class="fa fa-trash"></i
          ></b-btn>
        </td>
      </tr>
    </table>
    <course-structure-module-slidebar
      :courseId="courseId"
      v-if="showModuleAddSlidebar"
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
    >
    </course-structure-module-slidebar>
  </div>
</template>

<script>
import moment from 'moment';
import curriculum from '@/api/curriculum';
import { mapGetters, mapActions } from 'vuex';

import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';

import CourseStructureModuleSlidebar from '@/components/curriculum/courses/CourseStructureModuleSlidebar.vue';

export default {
  name: 'course-modules',
  props: ['courseId', 'courseName'],
  components: {
    TableRowLoader,
    TableRowEmpty,
    LinkOrder,
    CourseStructureModuleSlidebar,
  },
  computed: {
    getSelected() {
      return this.list.filter(x => x.selected);
    },
    canReadModules() {
      return this.loggedInUserHasReadPermission('namskra_afangar');
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_brautir');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('namskra_brautir');
    },
    getExcelData() {
      const newList = [
        [],
        [`Braut: ${this.courseName} - Áfangar`],
        [],
        ['Áfangi', 'Heiti áfanga', 'Einingar', 'Þrep', 'Tímabil', 'Forgangsröðun', 'Uppbygging', 'Tegund'],
        ...this.list.map(row => [
          row.namsgrein_afanganr,
          row.afanga_heiti,
          row.einingar,
          row.trep,
          row.onn_nr,
          row.forgangsrodun,
          row.uppbygging_heiti,
          row.tegund_heiti,
        ]),
      ];
      return newList;
    },
    getExcelFileName() {
      return `braut_afangar_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      selectAll: false,
      loading: false,
      list: [],
      showModuleAddSlidebar: false,
      selectedItem: '',
      edit: false,
    };
  },
  methods: {
    toggleEdit() {
      this.edit = !this.edit;
      if (this.edit) {
        this.list.forEach(afangi => {
          this.$set(afangi, 'editInstance', { onn_nr: afangi.onn_nr, forgangsrodun: afangi.forgangsrodun });
        });
      }
    },
    async load() {
      try {
        const response = await curriculum.getCourseModules(this.courseId);
        this.list = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async saveData() {
      try {
        const structList = [
          ...new Set(
            this.list
              .filter(x => x.onn_nr !== x.editInstance.onn_nr || x.forgangsrodun !== x.editInstance.forgangsrodun)
              .map(x => x.braut_uppbygg_id),
          ),
        ];
        for (let i = 0; i < structList.length; i += 1) {
          const items = this.list
            .filter(x => x.braut_uppbygg_id === structList[i])
            .filter(x => x.onn_nr !== x.editInstance.onn_nr || x.forgangsrodun !== x.editInstance.forgangsrodun)
            .map(x => ({
              afangi_id: x.afangi_id,
              onn_nr: x.editInstance.onn_nr,
              forgangsrodun: x.editInstance.forgangsrodun,
            }));
          await curriculum.addCourseStructureModules(structList[i], items); // eslint-disable-line
        }
        this.displaySuccess('Áföngum breytt');
        this.edit = false;
        this.load();
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);

        if (e.response && e.response.data && e.response.data.error) {
          this.displayError(e.response.data.error);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    async deleteModule(afangi) {
      // eslint-disable-next-line no-restricted-globals, no-alert
      const conf = confirm(
        `Ert þú viss um að þú viljir eyða áfanganum ${afangi.namsgrein_afanganr} úr uppbyggingunni ${afangi.uppbygging_heiti}?`,
      ); // eslint-disable-line
      if (conf) {
        await curriculum.updateCourseModule(afangi.braut_afangar_id, { delete: 1 });
        this.displaySuccess('Tengingu eytt');
        this.load();
      }
    },
    updateAll() {
      this.list.forEach(i => {
        this.$set(i, 'selected', this.selectAll);
      });
    },

    deleteSelected() {
      const conf = confirm(`Ert þú viss um að þú viljir eyða ${this.getSelected.length} áföngum ?`); // eslint-disable-line
      if (conf) {
        const promises = [];
        this.getSelected.forEach(item => {
          promises.push(curriculum.updateCourseModule(item.braut_afangar_id, { delete: 1 }));
        });

        Promise.all(promises).then(() => {
          this.displaySuccess('Tengingum eytt');
          this.load();
          this.selectAll = false;
        });
      }
    },
    openAddModuleSlidebar() {
      this.showModuleAddSlidebar = true;
    },
    closeSlidebar() {
      this.showModuleAddSlidebar = false;
    },
    closeAndReloadSlidebar() {
      this.closeSlidebar();
      this.load();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.load();
  },
};
</script>
<style lang="scss" scoped>
@import '../../../style/variables';
.termModuleBox {
  padding: 5px;
  margin-right: 10px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  font-size: 13px;
}
</style>
