<template>
  <Slidebar :autoClose="false" @closeMe="closeSlidebar" :large="large" :extraLarge="extraLarge">
    <h4>{{ termName }} - <span v-if="type === 'teachingplan'"> námsáætlanir</span><span v-if="type === 'finals'"> lokapróf</span></h4>
    <ModuleAttachment :moduleId="moduleId" :termId="termId" :type="type"></ModuleAttachment>
  </Slidebar>
</template>

<script>
import curriculum from '@/api/curriculum';

import Slidebar from '@/components/common/Slidebar.vue';
import ModuleAttachment from '@/components/curriculum/modules/ModuleAttachment.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'module-attachment-slidebar',
  components: {
    Slidebar,
    ModuleAttachment,
  },
  props: ['moduleId', 'termId', 'type', 'large', 'extraLarge'],
  computed: {
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      loading: false,
      termName: '',
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async loadItem() {
      try {
        this.loading = true;
        this.attachments = [];
        this.termName = '';
        const response = await curriculum.getModuleTerms(this.moduleId, { termId: this.termId });
        if (response.data.items.length === 1) {
          this.termName = response.data.items[0].onn_heiti;
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadItem();
  },
};
</script>

<style lang="scss"></style>
