<template>
  <Slidebar @closeMe="closeSlidebar" :large="true">
    <h4>Stofna kennslutímabil</h4>
    <b-form @submit.prevent="validateBeforeSubmit" novalidate class="createTermSlidebar">
      <b-form-group
        label-for="division"
        :state="submitted && errors.has('division') ? false : ''"
        :invalid-feedback="errors.first('division')"
        label="Undirskóli*"
      >
        <v-select
          id="division"
          name="division"
          v-model="form.division"
          :clearable="false"
          v-validate="'required'"
          @input="validateCode()"
          :state="submitted && errors.has('division') ? false : ''"
          :options="divisions"
          data-vv-as="undirskóli"
        >
        </v-select>
      </b-form-group>
      <b-form-group
        label-for="code"
        :state="submitted && errors.has('code') ? false : ''"
        :invalid-feedback="errors.first('code')"
        label="Einkenni kennslutímabils *"
      >
        <b-form-input
          id="code"
          name="code"
          type="text"
          @change="validateCode()"
          v-model="form.code"
          v-validate="'required'"
          :state="(submitted && errors.has('code')) || codeExists ? false : ''"
          data-vv-as="einkenni"
          maxLength="6"
        >
        </b-form-input>
      </b-form-group>
      <div class="text-danger" style="font-weight: bold; font-size: 13px" v-if="codeExists">
        Ath. það er nú þegar til kennslutímabil með þessu einkenni. Vinsamlegast veljið annað.
      </div>
      <b-form-group
        label-for="name"
        :state="submitted && errors.has('name') ? false : ''"
        :invalid-feedback="errors.first('name')"
        label="Heiti kennslutímabils *"
      >
        <b-form-input
          id="name"
          name="name"
          type="text"
          v-model="form.name"
          v-validate="'required'"
          :state="submitted && errors.has('name') ? false : ''"
          data-vv-as="heiti"
          maxLength="40"
        >
        </b-form-input>
      </b-form-group>
      <b-form-group
        label-for="nameEns"
        :state="submitted && errors.has('nameEns') ? false : ''"
        :invalid-feedback="errors.first('nameEns')"
        label="Heiti kennslutímabils - Enska"
      >
        <b-form-input
          id="nameEns"
          name="nameEns"
          type="text"
          v-model="form.nameEns"
          :state="submitted && errors.has('nameEns') ? false : ''"
          data-vv-as="heiti enska"
          maxLength="50"
        >
        </b-form-input>
      </b-form-group>
      <b-form-group
        label-for="type"
        :state="submitted && errors.has('type') ? false : ''"
        :invalid-feedback="errors.first('type')"
        label="Tegund kennslutímabils"
      >
        <v-select
          id="type"
          name="type"
          v-model="form.type"
          :clearable="false"
          :state="submitted && errors.has('type') ? false : ''"
          :options="types"
          label="tegund_heiti"
          data-vv-as="tegund"
        >
        </v-select>
      </b-form-group>
      <div>
        <div class="d-inline-block" style="padding-right: 110px">
          <b-form-group
            label-for="dateFrom"
            label="Dags. frá*"
            :state="submitted && errors.has('dateFrom') ? false : ''"
            :invalid-feedback="errors.first('dateFrom')"
          >
            <datepicker
              id="dateFrom"
              name="dateFrom"
              v-model="form.dateFrom"
              :language="lang"
              :monday-first="true"
              format="dd.MM.yyyy"
              :typeable="false"
              :clear-button="true"
              v-validate="'required'"
              data-vv-as="dags. frá"
              :state="submitted && errors.has('dateFrom') ? false : ''"
              ref="dateFromOpenPickr"
            ></datepicker>
          </b-form-group>
        </div>
        <div class="d-inline-block">
          <b-form-group
            label-for="dateTo"
            label="Dags. til*"
            :state="submitted && errors.has('dateTo') ? false : ''"
            :invalid-feedback="errors.first('dateTo')"
          >
            <datepicker
              id="dateTo"
              name="dateTo"
              v-model="form.dateTo"
              :language="lang"
              :monday-first="true"
              format="dd.MM.yyyy"
              :typeable="false"
              :clear-button="true"
              v-validate="'required'"
              data-vv-as="dags. frá"
              :state="submitted && errors.has('dateTo') ? false : ''"
              ref="dateToOpenPickr"
            ></datepicker>
          </b-form-group>
        </div>
      </div>
      <div>
        <div class="d-inline-block" style="padding-right: 65px">
          <b-form-group
            label-for="studentTableOpen"
            label="Stundatafla nema opnar dags."
            :state="submitted && errors.has('studentTableOpen') ? false : ''"
            :invalid-feedback="errors.first('studentTableOpen')"
          >
            <datepicker
              id="studentTableOpen"
              name="studentTableOpen"
              v-model="form.studentTableOpen"
              :language="lang"
              :monday-first="true"
              format="dd.MM.yyyy"
              :typeable="false"
              :clear-button="true"
              data-vv-as="stundatafla nema opnar"
              :state="submitted && errors.has('studentTableOpen') ? false : ''"
              ref="studentTableOpenPickr"
            ></datepicker>
          </b-form-group>
        </div>
        <div class="d-inline-block">
          <b-form-group
            label-for="teacherTableOpen"
            label="Stundatafla kennara opnar dags."
            :state="submitted && errors.has('teacherTableOpen') ? false : ''"
            :invalid-feedback="errors.first('teacherTableOpen')"
          >
            <datepicker
              id="teacherTableOpen"
              name="teacherTableOpen"
              v-model="form.teacherTableOpen"
              :language="lang"
              :monday-first="true"
              format="dd.MM.yyyy"
              :typeable="false"
              :clear-button="true"
              data-vv-as="stundatafla kennara opnar"
              :state="submitted && errors.has('teacherTableOpen') ? false : ''"
              ref="teacherTableOpenPickr"
            ></datepicker>
          </b-form-group>
        </div>
      </div>
      <div>
        <div class="d-inline-block" style="padding-right: 110px">
          <b-form-group
            label-for="teachingBegins"
            label="Kennsla hefst"
            :state="submitted && errors.has('teachingBegins') ? false : ''"
            :invalid-feedback="errors.first('teachingBegins')"
          >
            <datepicker
              id="teachingBegins"
              name="teachingBegins"
              v-model="form.teachingBegins"
              :language="lang"
              :monday-first="true"
              format="dd.MM.yyyy"
              :typeable="false"
              :clear-button="true"
              data-vv-as="kennsla hefst"
              :state="submitted && errors.has('teachingBegins') ? false : ''"
              ref="teachingBeginsOpenPickr"
            ></datepicker>
          </b-form-group>
        </div>
        <div class="d-inline-block">
          <b-form-group
            label-for="teachingEnds"
            label="Kennsla lýkur"
            :state="submitted && errors.has('teachingEnds') ? false : ''"
            :invalid-feedback="errors.first('teachingEnds')"
          >
            <datepicker
              id="teachingEnds"
              name="teachingEnds"
              v-model="form.teachingEnds"
              :language="lang"
              :monday-first="true"
              format="dd.MM.yyyy"
              :typeable="false"
              :clear-button="true"
              data-vv-as="kennsla lýkur"
              :state="submitted && errors.has('teachingEnds') ? false : ''"
              ref="teachingEndsOpenPickr"
            ></datepicker>
          </b-form-group>
        </div>
      </div>
      <div>
        <div class="d-inline-block" style="padding-right: 110px">
          <b-form-group
            label-for="examsBegins"
            label="Dags. byrjun prófa"
            :state="submitted && errors.has('examsBegins') ? false : ''"
            :invalid-feedback="errors.first('examsBegins')"
          >
            <datepicker
              id="examsBegins"
              name="examsBegins"
              v-model="form.examsBegins"
              :language="lang"
              :monday-first="true"
              format="dd.MM.yyyy"
              :typeable="false"
              :clear-button="true"
              data-vv-as="dags. byrjun prófa"
              :state="submitted && errors.has('examsBegins') ? false : ''"
              ref="examsBeginsOpenPickr"
            ></datepicker>
          </b-form-group>
        </div>
        <div class="d-inline-block" style="padding-right: 110px">
          <b-form-group
            label-for="examsEnds"
            label="Dags. prófloka"
            :state="submitted && errors.has('examsEnds') ? false : ''"
            :invalid-feedback="errors.first('examsEnds')"
          >
            <datepicker
              id="examsEnds"
              name="examsEnds"
              v-model="form.examsEnds"
              :language="lang"
              :monday-first="true"
              format="dd.MM.yyyy"
              :typeable="false"
              :clear-button="true"
              data-vv-as="dags. prófloka"
              :state="submitted && errors.has('examsEnds') ? false : ''"
              ref="examsEndsOpenPickr"
            ></datepicker>
          </b-form-group>
        </div>
        <div class="d-inline-block">
          <b-form-group
            label-for="issueDate"
            label="Útgáfudags. prófa"
            :state="submitted && errors.has('issueDate') ? false : ''"
            :invalid-feedback="errors.first('issueDate')"
          >
            <datepicker
              id="issueDate"
              name="issueDate"
              v-model="form.issueDate"
              :language="lang"
              :monday-first="true"
              format="dd.MM.yyyy"
              :typeable="false"
              :clear-button="true"
              data-vv-as="útgáfudags. prófa"
              :state="submitted && errors.has('issueDate') ? false : ''"
              ref="issueDateOpenPickr"
            ></datepicker>
          </b-form-group>
        </div>
      </div>
      <div>
        <div class="d-inline-block" style="padding-right: 17px">
          <b-form-group
            label-for="applicationBegins"
            label="Umsóknartímabil eldri nemenda hefst"
            :state="submitted && errors.has('applicationBegins') ? false : ''"
            :invalid-feedback="errors.first('applicationBegins')"
          >
            <datepicker
              id="applicationBegins"
              name="applicationBegins"
              v-model="form.applicationBegins"
              :language="lang"
              :monday-first="true"
              format="dd.MM.yyyy"
              :typeable="false"
              :clear-button="true"
              data-vv-as="umsóknartímabil eldri nemenda hefst"
              :state="submitted && errors.has('applicationBegins') ? false : ''"
              ref="applicationBeginsOpenPickr"
            ></datepicker>
          </b-form-group>
        </div>
        <div class="d-inline-block">
          <b-form-group
            label-for="applicationEnds"
            label="Umsóknartímabili eldri nemenda lýkur"
            :state="submitted && errors.has('applicationEnds') ? false : ''"
            :invalid-feedback="errors.first('applicationEnds')"
          >
            <datepicker
              id="applicationEnds"
              name="applicationEnds"
              v-model="form.applicationEnds"
              :language="lang"
              :monday-first="true"
              format="dd.MM.yyyy"
              :typeable="false"
              :clear-button="true"
              data-vv-as="umsóknartímabili eldri nemenda lýkur"
              :state="submitted && errors.has('applicationEnds') ? false : ''"
              ref="applicationEndsOpenPickr"
            ></datepicker>
          </b-form-group>
        </div>
      </div>
      <div>
        <div class="d-inline-block pr-5">
          <b-form-group label-for="tableLocked" label="Tafla læst">
            <b-form-checkbox id="tableLocked" name="tableLocked" v-model="form.tableLocked"></b-form-checkbox>
          </b-form-group>
        </div>
        <div class="d-inline-block pr-5">
          <b-form-group label-for="evaluation" label="Matsönn">
            <b-form-checkbox id="evaluation" name="evaluation" v-model="form.evaluation"></b-form-checkbox>
          </b-form-group>
        </div>
        <div class="d-inline-block pr-5">
          <b-form-group label-for="canApply" label="Má sækja um">
            <b-form-checkbox id="canApply" name="canApply" v-model="form.canApply"></b-form-checkbox>
          </b-form-group>
        </div>
        <div class="d-inline-block">
          <b-form-group label-for="studyInEnglish" label="Nám á ensku">
            <b-form-checkbox id="studyInEnglish" name="studyInEnglish" v-model="form.studyInEnglish"></b-form-checkbox>
          </b-form-group>
        </div>
      </div>
      <div>
        <div class="d-inline-block" style="margin-right: 100px">
          <b-form-group
            label-for="tableDays"
            :state="submitted && errors.has('tableDays') ? false : ''"
            :invalid-feedback="errors.first('tableDays')"
            label="Fjöldi töfludaga í viku"
          >
            <b-form-input
              id="tableDays"
              name="tableDays"
              type="number"
              v-model="form.tableDays"
              :state="submitted && errors.has('tableDays') ? false : ''"
              data-vv-as="fj. töfludaga"
              min="0"
              max="7"
            >
            </b-form-input>
          </b-form-group>
        </div>
        <div class="d-inline-block">
          <b-form-group
            label-for="lessonLength"
            :state="submitted && errors.has('lessonLength') ? false : ''"
            :invalid-feedback="errors.first('lessonLength')"
            label="Lengd kennslustundar (mínútúr)"
          >
            <b-form-input
              id="lessonLength"
              name="lessonLength"
              type="number"
              v-model="form.lessonLength"
              :state="submitted && errors.has('lessonLength') ? false : ''"
              data-vv-as="lengd kennslustundar"
            >
            </b-form-input>
          </b-form-group>
        </div>
      </div>
      <div>
        <div class="d-inline-block" style="margin-right: 150px">
          <b-form-group
            label-for="nrNewStudents"
            :state="submitted && errors.has('nrNewStudents') ? false : ''"
            :invalid-feedback="errors.first('nrNewStudents')"
            label="Fjöldi nýnema"
          >
            <b-form-input
              id="nrNewStudents"
              name="nrNewStudents"
              type="number"
              v-model="form.nrNewStudents"
              :state="submitted && errors.has('nrNewStudents') ? false : ''"
              data-vv-as="fj. nýnema"
              min="0"
            >
            </b-form-input>
          </b-form-group>
        </div>
        <div class="d-inline-block">
          <b-form-group
            label-for="nrStudents"
            :state="submitted && errors.has('nrStudents') ? false : ''"
            :invalid-feedback="errors.first('nrStudents')"
            label="Fjöldi nema alls"
          >
            <b-form-input
              id="nrStudents"
              name="nrStudents"
              type="number"
              v-model="form.nrStudents"
              :state="submitted && errors.has('nrStudents') ? false : ''"
              data-vv-as="fj. nema"
            >
            </b-form-input>
          </b-form-group>
        </div>
      </div>

      <b-btn variant="primary" @click="validateBeforeSubmit()"> Stofna </b-btn>
    </b-form>
  </Slidebar>
</template>

<script>
import structure from '@/api/structure';
import types from '@/api/types';
import terms from '@/api/terms';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

import Slidebar from '@/components/common/Slidebar.vue';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';

export default {
  name: 'create-term-slidebar',
  props: ['termToCopy'],
  components: {
    Slidebar,
    Datepicker, // eslint-disable-line
  },
  computed: {
    canRead() {
      return this.loggedInUserHasReadPermission('skolahald_annir');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolHasSettings']),
  },
  data() {
    return {
      lang: is,
      codeExists: false,
      form: {
        division: '',
        code: '',
        name: '',
        nameEns: '',
        tableLocked: false,
        type: '',
        dateFrom: '',
        dateTo: '',
        studentTableOpen: '',
        teacherTableOpen: '',
        teachingBegins: '',
        teachingEnds: '',
        examsBegins: '',
        examsEnds: '',
        issueDate: '',
        applicationBegins: '',
        applicationEnds: '',
        tableDays: '',
        evaluation: false,
        lessonLength: '',
        canApply: false,
        studyInEnglish: false,
        nrNewStudents: '',
        nrStudents: '',
      },
      loading: {
        divisions: false,
        types: false,
      },
      submitted: false,
      divisions: [],
      types: [],
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async loadTerm() {
      if (this.canRead) {
        try {
          const response = await terms.getTerm(this.termToCopy);
          const term = response.data.items ? response.data.items[0] : '';
          this.loadDivisions(term.undirskoli_id);
          this.loadTypes(term.teg_annar);
          this.form.name = term.onn_heiti;
          this.form.tableLocked = term.tafla_laest === 1;
          this.form.tableDays = term.fjoldi_tofludaga;
          this.form.lessonLength = term.lengd_kennslustundar;
          this.form.canApply = term.ma_saekja_um === 1;
          this.form.evaluation = term.matsonn === 1;
          this.form.studyInEnglish = term.nam_a_ensku === 1;
          this.form.nrNewStudents = term.fj_nynema;
          this.form.nrStudents = term.fj_nema_alls;
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading.term = false;
        }
      }
    },
    async loadDivisions(divisionId) {
      if (this.canRead) {
        try {
          this.loading.divisions = true;
          const response = await structure.getDivisionList();
          this.divisions = response.data.items.map(x => ({
            ...x,
            label: `${x.code} - ${x.name}`,
          }));
          this.form.division = this.divisions.find(
            x =>
              (divisionId && x.division_id === divisionId) ||
              (!divisionId && ((x.name || '').toUpperCase().indexOf('DAG') > -1 || (x.code || '').toUpperCase().indexOf('DAG') > -1)),
          );
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading.divisions = false;
        }
      }
    },
    async loadTypes(typeId) {
      if (this.canRead) {
        try {
          this.loading.types = true;
          const response = await types.getTermList();
          this.types = response.data.items;
          this.form.type = '';
          if (this.types.length > 0) {
            this.form.type = typeId ? this.types.find(x => x.tegund_id === typeId) : this.types[0];
          }
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading.types = false;
        }
      }
    },
    async validateCode() {
      this.codeExists = false;
      try {
        const response = await terms.checkTermCodeExists({ id: null, division: this.form.division.division_id, code: this.form.code });
        this.codeExists = response.data.already_exists > 0;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async validateBeforeSubmit() {
      try {
        // this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        const data = {
          undirskoli_id: this.form.division.division_id,
          onn_eink: this.form.code,
          onn_heiti: this.form.name,
          tafla_laest: this.form.tableLocked ? 1 : 0,
          teg_annar: this.form.type ? this.form.type.tegund_id : '',
          dags_fra: this.form.dateFrom ? moment(this.form.dateFrom).format('DD.MM.YYYY') : '',
          dags_til: this.form.dateTo ? moment(this.form.dateTo).format('DD.MM.YYYY') : '',
          prof_byrja: this.form.examsBegins ? moment(this.form.examsBegins).format('DD.MM.YYYY') : '',
          prof_enda: this.form.examsEnds ? moment(this.form.examsEnds).format('DD.MM.YYYY') : '',
          lengd_kennslustundar: this.form.lessonLength,
          fjoldi_tofludaga: this.form.tableDays,
          matsonn: this.form.evaluation ? 1 : 0,
          utgafudags_profa: this.form.issueDate ? moment(this.form.issueDate).format('DD.MM.YYYY') : '',
          onn_heiti_enska: this.form.nameEns,
          ma_saekja_um: this.form.canApply ? 1 : 0,
          stundatafla_nem_opnar: this.form.studentTableOpen ? moment(this.form.studentTableOpen).format('DD.MM.YYYY') : '',
          stundatafla_kenn_opnar: this.form.teacherTableOpen ? moment(this.form.teacherTableOpen).format('DD.MM.YYYY') : '',
          umstimabil_eldri_fra: this.form.applicationBegins ? moment(this.form.applicationBegins).format('DD.MM.YYYY') : '',
          umstimabil_eldri_til: this.form.applicationEnds ? moment(this.form.applicationEnds).format('DD.MM.YYYY') : '',
          nam_a_ensku: this.form.studyInEnglish ? 1 : 0,
          kennsla_hefst: this.form.teachingBegins ? moment(this.form.teachingBegins).format('DD.MM.YYYY') : '',
          kennslu_lykur: this.form.teachingEnds ? moment(this.form.teachingEnds).format('DD.MM.YYYY') : '',
          fj_nema_alls: this.form.nrStudents,
          fj_nynema: this.form.nrNewStudents,
        };

        try {
          const response = await terms.createTerm(data);
          this.displaySuccess('Kennslutímabil stofnað');
          this.$emit('closeSlidebar', { id: response.data.id, termToCopy: this.termToCopy });
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.saving = false;
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.saving = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    if (this.termToCopy) {
      this.loadTerm();
    } else {
      this.loadDivisions();
      this.loadTypes();
    }
  },
};
</script>

<style lang="scss">
.createTermSlidebar {
  .form-group {
    margin-bottom: 8px;
    input {
      height: 30px !important;
    }
    label {
      // font-weight: bold;
      margin-bottom: 0px;
    }
  }
}
</style>
