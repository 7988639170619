<template>
  <div>
    <Loader v-if="loading" />
    <div v-else>
      <div>
        <i>Nemendur á brautum sem eru tengdar við samþykktar brautir MRN.</i>
      </div>
      <table class="table" v-if="list.length > 0">
        <thead>
          <tr class="table-search">
            <td colspan="4">
              <b-form-input v-model="search" class="search" style="margin-top: 0px; margin-bottom: 15px" placeholder="Leita" />
            </td>
          </tr>
          <tr>
            <th>
              <LinkOrder title="Nafn" :filteredList="list" :list="list" column="nafn" second_column="kennitala" /> ({{ getList.length }})
            </th>
            <th><LinkOrder title="Kennitala" :filteredList="list" :list="list" column="kennitala" second_column="nafn" /></th>
            <th><LinkOrder title="Braut" :filteredList="list" :list="list" column="brautir" second_column="nafn" /></th>
            <th>
              <download-csv v-if="!loading" :data="getExcelData" :name="getExcelFileName" delimiter=";" class="cursor-pointer float-right">
                <i class="fa fa-file-excel-o excelText"></i>
              </download-csv>
            </th>
          </tr>
        </thead>
        <tr v-for="(item, index) in getPaginatedList" :key="index">
          <td>
              <b-link @click="redirectoToStudyRecords(item.nemandi_id)" title="Námsferill">{{ item.nafn }}</b-link>
            </td>
          <td>{{ item.kennitala }}</td>
          <td>{{ item.brautir }}</td>
          <td></td>
        </tr>
      </table>
      <br />
      <Pagination
        :currentPage="currentPage"
        :totalRows="getList.length"
        :perPage="perPage"
        v-if="list.length > 25"
        @change-page="changePage"
        @change-limit="changeLimit"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import reports from '@/api/reports';
import { mapActions } from 'vuex';

import Loader from '@/components/common/Loader.vue';
import Pagination from '@/components/common/Pagination.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';

export default {
  name: 'mainschool-paid',
  components: {
    Loader,
    Pagination,
    LinkOrder,
  },
  computed: {
    getExcelData() {
      const newLine = [[], ['Aðalskóli - Nemendur innan líkans'], [], ...this.list.map(x => [x.nafn, `="${x.kennitala}"`, x.brautir])];
      return newLine;
    },
    getExcelFileName() {
      return `adalskoli_nemendur_innan_likans_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    getList() {
      return this.list.filter(
        x =>
          this.search.length === 0 ||
          (x.nafn || '').toUpperCase().indexOf(this.search.toUpperCase()) > -1 ||
          x.kennitala.indexOf(this.search) > -1 ||
          (x.brautir || '').toUpperCase().indexOf(this.search.toUpperCase()) > -1,
      );
    },
    getPaginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.getList.slice(start, end);
    },
  },
  data() {
    return {
      perPage: 25,
      currentPage: 1,
      search: '',
      loading: false,
      list: [],
    };
  },
  methods: {
    async loadList() {
      try {
        this.list = [];
        this.loading = true;
        const response = await reports.getHomeschool({ paid: 1 });
        this.list = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
    redirectoToStudyRecords(notandiId) {
      const url = this.$router.resolve({ 
        path: `/nemandi/${notandiId}`, 
        query: { page: 'studyRecords' } 
      }).href;
      
      window.open(url, '_blank');
    },
  },
  created() {
    this.loadList();
  },
};
</script>

<style lang="scss"></style>
