
import { Component, Vue, Prop } from 'vue-property-decorator';

import moment from 'moment';
import groups from '@/api/groups';
import common from '@/api/common';

import { Action, Getter } from 'vuex-class';

import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';
import Photo from '@/components/common/Photo.vue';
import Loader from '@/components/common/Loader.vue';

import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';

import SendMessageSlidebar from '@/components/common/SendMessageSlidebar.vue';
import ChangeDateSlidebar from '@/components/students/groups/students/ChangeDateSlidebar.vue';
import DeleteStudentSlidebar from '@/components/students/groups/students/DeleteStudentSlidebar.vue';
import ModuleStudentListSlidebar from '@/components/students/groups/ModuleStudentListSlidebar.vue';
import StudentCommentsSlidebar from '@/components/students/comments/StudentCommentsSlidebar.vue';
import StudiesCompletedSlidebar from '@/components/students/groups/students/StudiesCompletedSlidebar.vue';

@Component({
  components: {
    TableRowLoader,
    TableRowEmpty,
    LinkOrder,
    Datepicker,
    SendMessageSlidebar,
    ChangeDateSlidebar,
    DeleteStudentSlidebar,
    ModuleStudentListSlidebar,
    StudentCommentsSlidebar,
    StudiesCompletedSlidebar,
    Photo,
    Loader,
  },
})
export default class GroupStudents extends Vue {
  // eslint-disable-next-line no-undef
  [x: string]: any;
  @Action displayError!: (s: string) => void;
  @Action displaySuccess!: (s: string) => void;
  // @State loggedInUser: any;
  @Getter loggedInUserHasReadPermission: any;
  @Getter loggedInUserHasWritePermission: any;
  @Getter schoolSettings: any;

  @Prop() id!: number;
  @Prop() item!: any;
  @Prop() canEdit!: boolean;

  lang = is;
  page = 'students';
  students: any[] = [];
  worker = false;
  loading = {
    students: false,
    worker: false,
  };
  date = moment().toDate();
  selectAll = false;
  showSendMessageSlidebar = false;
  showDateSlidebar = false;
  showDeleteSlidebar = false;
  showStudentSlidebar = false;
  showCommentsSlidebar = false;
  showStudiesCompletedSlidebar = false;
  showPhotos = false;
  onlyInactive = false;
  selectedItem: any | null = '';
  selectedItems: any[] = [];

  /**
   * Computed
   */
  get getExcelData() {
    const newList = [
      [],
      ['Nemendur - Hópar'],
      [`${this.item.onn_heiti}`],
      [`${this.item.namsgrein_afanganr}-Hópur ${this.item.hopur}`],
      [this.item.afangi_heiti],
      [],
      [
        'Nafn',
        'Kennitala',
        'Nemendanr',
        'Lögheimili',
        'Póstnúmer',
        'Netfang',
        'Einkanetfang',
        'Sími lögheimili',
        'Sími aðsetur',
        'Farsími',
        'Staða nema',
        'Braut',
        'Áfangi',
        'Hópur',
        'Umsjónarkennari',
        'Dags byrjar',
        'Dags úrsagnar',
        'Sérúrræði',
        !this.afangaskoli ? 'Bekkur' : '',
      ],
      ...this.students.map(x => [
        x.nafn,
        `="${x.kennitala}"`,
        x.nemandi_id,
        x.logheimili_info.length > 0 ? x.logheimili_info[0].heimilisfang : '',
        x.logheimili_info.length > 0 ? x.logheimili_info[0].postnumer : '',
        x.netfang,
        x.einkanetfang,
        x.simi_lh,
        x.simi_as,
        x.farsimi,
        x.stada_nema,
        x.brautir,
        this.item.afanga_heiti,
        this.item.hopur,
        x.umsjonarkennari,
        moment(x.dags_byrjun).format('DD.MM.YYYY'),
        x.dags_ursagnar ? moment(x.dags_ursagnar).format('DD.MM.YYYY') : '',
        x.serurraedi,
        !this.afangaskoli ? x.bekkur : '',
      ]),
    ];
    return newList;
  }

  get getExcelFileName() {
    return `hopar_nemendur_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
  }
  get canReadSpecialResources() {
    return this.loggedInUserHasReadPermission('beidnir_serurraedi');
  }
  get afangaskoli() {
    return this.schoolSettings && this.schoolSettings.afangaskoli ? this.schoolSettings.afangaskoli === 1 : false;
  }
  get isLearningCenter() {
    return this.schoolSettings && this.schoolSettings.tegund_skola_id === 4;
  }
  get getSelected() {
    return this.students.filter(x => x.selected);
  }

  get canViewStudent() {
    return this.loggedInUserHasWritePermission('nemendahald_nemendur');
  }

  /**
   * Methods
   */
  refreshGroup() {
    this.$emit('refresh');
  }
  hasStartedGroup(dateStarted: any) {
    const date = moment(dateStarted).set('hours', 0).set('minutes', 0).set('seconds', 0);
    const today = moment().set('hours', 0).set('minutes', 0).set('seconds', 0);
    return today.isAfter(date);
  }
  async loadStudents() {
    try {
      this.selectAll = false;
      const response = await groups.getStudentList(this.id, {
        dags: this.date && !this.onlyInactive ? moment(this.date).format('DD.MM.YYYY') : '',
      });
      this.students = response.data.items.filter(
        (x: any) =>
          !this.onlyInactive ||
          (x.dags_ursagnar && moment(x.dags_ursagnar).isBefore(moment())) ||
          (x.dags_byrjar && moment(x.dags_byrjar).isAfter(moment())),
      );
    } catch (e) {
      this.$log.error(e);
      this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
    }
  }
  updateAll() {
    this.students.forEach(i => {
      this.$set(i, 'selected', this.selectAll);
    });
  }
  openSendMessageSlidebar(id: any) {
    this.showSendMessageSlidebar = true;
    this.showDateSlidebar = false;
    this.showDeleteSlidebar = false;
    this.showCommentsSlidebar = false;
    this.showStudiesCompletedSlidebar = false;
    this.selectedItem = {};
    if (id) {
      this.selectedItem.notandi_id = id;
    } else {
      this.selectedItem.hopur_id = this.id;
    }
  }
  openDateSlidebar(item: any) {
    this.showDateSlidebar = true;
    this.showSendMessageSlidebar = false;
    this.showDeleteSlidebar = false;
    this.showStudentSlidebar = false;
    this.showCommentsSlidebar = false;
    this.showStudiesCompletedSlidebar = false;
    this.selectedItems = [];
    if (item) {
      this.selectedItems.push(item);
    } else {
      this.selectedItems = [...this.students.filter(x => !x.dags_ursagnar)];
    }
  }
  openDeleteSlidebar(item: any) {
    this.showDeleteSlidebar = true;
    this.showDateSlidebar = false;
    this.showSendMessageSlidebar = false;
    this.showStudentSlidebar = false;
    this.showCommentsSlidebar = false;
    this.showStudiesCompletedSlidebar = false;
    this.selectedItems = [];
    if (item) {
      this.selectedItems.push(item);
    } else {
      this.selectedItems = [...this.getSelected];
    }
  }
  openStudentSlidebar() {
    this.showStudentSlidebar = true;
    this.showDeleteSlidebar = false;
    this.showDateSlidebar = false;
    this.showSendMessageSlidebar = false;
    this.showCommentsSlidebar = false;
    this.showStudiesCompletedSlidebar = false;
  }
  openCommentSlidebar(item: any) {
    this.showCommentsSlidebar = true;
    this.showStudentSlidebar = false;
    this.showDeleteSlidebar = false;
    this.showDateSlidebar = false;
    this.showSendMessageSlidebar = false;
    this.showStudiesCompletedSlidebar = false;
    this.selectedItem = item;
  }
  openStudiesCompletedSlidebar() {
    this.showStudiesCompletedSlidebar = true;
    this.showCommentsSlidebar = false;
    this.showStudentSlidebar = false;
    this.showDeleteSlidebar = false;
    this.showDateSlidebar = false;
    this.showSendMessageSlidebar = false;
  }
  closeSlidebar() {
    this.showSendMessageSlidebar = false;
    this.showDateSlidebar = false;
    this.showDeleteSlidebar = false;
    this.showStudentSlidebar = false;
    this.showCommentsSlidebar = false;
    this.showStudiesCompletedSlidebar = false;
  }
  closeAndReloadSlidebar() {
    this.closeSlidebar();
    this.loadStudents();
  }
  closeAndLoadWorkersSlidebar() {
    this.closeSlidebar();
    this.loadWorkerInProgress();
  }

  async loadWorkerInProgress() {
    try {
      this.loading.worker = true;
      const response = await common.getWorkersInProgress('ovirkja_nemendur');
      this.worker = response.data.items.filter((x: any) => x.reference_id === this.id).length > 0;
    } catch (e) {
      this.$log.error(e);
      this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
    } finally {
      this.loading.worker = false;
    }
  }

  onOvirkjaNemendurComplete(data: { id: number }) {
    this.loadWorkerInProgress();
    if (data.id === this.id) {
      this.loadStudents();
    }
  }

  onOvirkjaNemendurFailure(data: { id: number }) {
    this.loadWorkerInProgress();
    if (data.id === this.id) {
      this.displayError(`Villa kom upp við að óvirkja nemendur. Vinsamlegast reyndu aftur.`);
      this.loadStudents();
    }
  }

  onOvirkjaNemendurStarted() {
    this.loadWorkerInProgress();
  }

  beforeRouteUpdate(to: any, from: any, next: any) {
    if (to && to.query && to.query.page) {
      this.page = to.query.page;
    }
    next();
  }

  created() {
    this.loadStudents();
    this.loadWorkerInProgress();
  }

  mounted() {
    this.$socket.emit('joinOvirkjaNemendur');
    this.sockets.subscribe('ovirkjaNemendurStarted', this.onOvirkjaNemendurStarted);
    this.sockets.subscribe('ovirkjaNemendurComplete', this.onOvirkjaNemendurComplete);
    this.sockets.subscribe('ovirkjaNemendurFailure', this.onOvirkjaNemendurFailure);
  }

  beforeDestroy() {
    this.$socket.emit('leaveOvirkjaNemendur');
    this.sockets.unsubscribe('ovirkjaNemendurStarted');
    this.sockets.unsubscribe('ovirkjaNemendurComplete');
    this.sockets.unsubscribe('ovirkjaNemendurFailure');
  }
}
