// @ts-nocheck

import Vue from 'vue';

import axios from 'axios';

import auth from '@/api/auth';

import { RequestsApi, AttachmentsApi, SchoolApi, UploadApi, MessagesApi, GroupsApi, PhotosApi, StudentsApi } from '../api/openapi';

// import router from '../router'

Vue.http = Vue.prototype.$http = axios; // eslint-disable-line no-multi-assign
const redirectUrl = process.env.VUE_APP_LOGIN_REDIRECT_URL || 'https://inna.is/innskraning?c=http://admin.inna.is/innskraning&s=2';

/**
 * Interceptor fyrir öll HTTP köll í kerfinu. Ef 'skipAuthorization' er sett á kallið
 * þá er ekkert gert. Annars er JWT token sóttur úr localStorage. Ef token vantar þá
 * má ekki gera HTTP kallið. Ef token er útrunnið þá má ekki gera HTTP kallið. Ef
 * token er við það að renna út þá er frískað upp á það og síðan kallað á HTTP.
 */
/* eslint-disable */
Vue.http.interceptors.request.use(config => new Promise((resolve, reject) => {
  // Á að sleppa því að auðkenna kallið?
  if ('skipAuthorization' in config && config.skipAuthorization === true) {
    resolve(config);
  } else {
    // Sækja innihald innskráningar.
    const token = auth.getToken();

    // Er notandi innskráður og er innskráning ekki útrunnin?
    if (!token || auth.isTokenExpired()) {
      auth.removeToken();
      // router.push({name: 'Login'})
      window.location.href = redirectUrl;

      reject();
    } else {
      // Þarf að fríska upp á innskráningu?
      const chkDate = new Date(auth.getTokenExpire());
      const sysDate = new Date();

      chkDate.setSeconds(chkDate.getSeconds() - 600);

      if (sysDate.valueOf() > chkDate.valueOf()) {
        // Fríska upp á innskráningu.
      } else {
        // Allt er í lagi.
        config.headers.common['token'] = auth.getToken();
        resolve(config)
      }
    }
  }
}));
/* eslint-enable */

/* eslint-disable */
Vue.$requestsApi = Vue.prototype.$requestsApi = new RequestsApi(
  {
    basePath: '',
  },
  '',
  Vue.http,
);
Vue.$attachmentsApi = Vue.prototype.$attachmentsApi = new AttachmentsApi(
  {
    basePath: '',
  },
  '',
  Vue.http,
);
Vue.$schoolApi = Vue.prototype.$schoolApi = new SchoolApi(
  {
    basePath: '',
  },
  '',
  Vue.http,
);
Vue.$uploadApi = Vue.prototype.$uploadApi = new UploadApi(
  {
    basePath: '',
  },
  '',
  Vue.http,
);

Vue.$messagesApi = Vue.prototype.$messagesApi = new MessagesApi(
  {
    basePath: '',
  },
  '',
  Vue.http,
);

Vue.$groupsApi = Vue.prototype.$groupsApi = new GroupsApi(
  {
    basePath: '',
  },
  '',
  Vue.http,
);

Vue.$photosApi = Vue.prototype.$photosApi = new PhotosApi(
  {
    basePath: '',
  },
  '',
  Vue.http,
);

Vue.$studentsApi = Vue.prototype.$studentsApi = new StudentsApi(
  {
    basePath: '',
  },
  '',
  Vue.http,
);
/* eslint-enable */

declare module 'vue/types/vue' {
  // eslint-disable-next-line no-shadow
  interface Vue {
    $log: any;
    $groupsApi: GroupsApi;
    $requestsApi: RequestsApi;
    $attachmentsApi: AttachmentsApi;
    $schoolApi: SchoolApi;
    $uploadApi: UploadApi;
    $messagesApi: MessagesApi;
    $photosApi: PhotosApi;
    $studentsApi: StudentsApi;
  }
}
