<template>
  <div>
    <b-row>
      <b-col class="pl-0">
        <b-form-group label="Kennslutímabil" label-for="searchTerm">
          <v-select
            id="searchTerm"
            name="searchTerm"
            class="v-select-form-control"
            placeholder="Veldu kennslutímabil"
            v-model="selected.term"
            :options="getTerms"
            :clearable="false"
          >
          </v-select>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Áfangi" label-for="searchModule">
          <b-form-input
            id="searchModule"
            name="searchModule"
            placeholder="Einkenni áfanga"
            v-model="selected.module"
            style="height: 45px"
            v-on:keyup.enter="loadList"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="1">
        <b-btn variant="primary" style="margin-top: 30px; height: 45px" @click="loadList()">Leita</b-btn>
      </b-col>
    </b-row>
    <table class="table mt-3" style="font-size: 14px">
      <thead>
        <tr v-if="firsttime">
          <th>
            <LinkOrder title="Einkenni" :filteredList="list" :list="list" column="onn_eink" />
          </th>
          <th>
            <LinkOrder title="Heiti" :filteredList="list" :list="list" column="onn_heiti" second_column="onn_eink" />
          </th>
          <th v-if="showGradeRule">
            <LinkOrder title="Fj. einkunnareglu" :filteredList="list" :list="list" column="fj_einkunnareglu" second_column="onn_eink" />
          </th>
          <th v-if="showGroupGradeRule">
            <LinkOrder
              title="Fj. hópa m. einkunnareglu"
              :filteredList="list"
              :list="list"
              column="fj_einkunnareglu"
              second_column="onn_eink"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <TableRowLoader v-if="loading.list && firsttime" :colspan="showGradeRule || showGroupGradeRule ? 3 : 2" :center="true" />
        <TableRowEmpty
          v-if="!loading.list && firsttime && this.list.length === 0"
          :colspan="showGradeRule || showGroupGradeRule ? 3 : 2"
          :center="true"
        />
        <tr v-for="item in getPaginatedList" :key="item.annir_afangar_id">
          <td>
            <b-link @click="selectModule(item)" title="Velja áfanga" v-b-tooltip>
              {{ item.namsgrein_afanganr }}
            </b-link>
          </td>
          <td>
            {{ item.afanga_heiti }}
          </td>
          <td v-if="showGradeRule">
            {{ item.fj_einkunnareglu }}
          </td>
          <td v-if="showGroupGradeRule">
            {{ item.fj_einkunnareglu_hopar }}
          </td>
        </tr>
      </tbody>
    </table>

    <Pagination
      :currentPage="currentPage"
      :totalRows="list.length"
      :perPage="perPage"
      v-if="list.length > 15"
      @change-page="changePage"
      @change-limit="changeLimit"
    />
  </div>
</template>

<script>
import terms from '@/api/terms';
import { mapActions, mapGetters } from 'vuex';

import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';
import Pagination from '@/components/common/Pagination.vue';

export default {
  name: 'term-module-search',
  components: {
    TableRowLoader,
    TableRowEmpty,
    LinkOrder,
    Pagination,
  },
  computed: {
    getTerms() {
      return this.terms.filter(x => !this.selected.term || x.onn_id !== this.selected.term.onn_id);
    },
    getPaginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.list.slice(start, end);
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolHasSettings']),
  },
  props: ['defaultTerm', 'divisionId', 'showGradeRule', 'showGroupGradeRule', 'searchModule'],
  data() {
    return {
      perPage: 15,
      currentPage: 1,
      loading: {
        terms: false,
        list: false,
      },
      firsttime: false,
      terms: [],
      list: [],
      selected: {
        term: '',
        module: '',
      },
    };
  },
  methods: {
    async loadTerms() {
      try {
        this.loading.terms = true;
        const params = {};
        if (this.divisionId) {
          params.division_id = this.divisionId;
        }
        const response = await terms.getTerms(params);
        this.terms = response.data.items.map(x => ({
          ...x,
          label: `${x.undirsk_audk} - ${x.onn_eink} - ${x.onn_heiti}`,
        }));
        if (this.defaultTerm) {
          this.selected.term = this.terms.find(x => x.onn_id === this.defaultTerm);
        }
        if (this.searchModule) {
          this.selected.module = this.searchModule;
          if (this.selected.term) this.loadList();
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.terms = false;
      }
    },
    async loadList() {
      try {
        this.list = [];
        if (this.selected.term) {
          this.loading.list = true;
          this.firsttime = true;
          const data = {
            search: this.selected.module,
          };
          if (this.showGradeRule || this.showGroupGradeRule) {
            data.showGraderule = 1;
          }
          const response = await terms.getTermModules(this.selected.term.onn_id, data);
          this.list = response.data.items;
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.list = false;
      }
    },
    selectModule(item) {
      this.$emit('selectModule', { ...item, onn_id: this.selected.term.onn_id, onn_eink: this.selected.term.onn_eink });
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadTerms();
  },
};
</script>
