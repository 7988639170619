<template>
  <div>
    <div class="itemEditBox empty" :class="{ small: small }" v-if="empty"></div>
    <div class="itemEditBox" :class="{ active: edit, disabled: disabled, small: small, error: hasError }" @click="toEdit()" v-else>
      <label class="d-inline-block"
        >{{ this.label }}
        <i class="fa fa-info-circle" v-if="info" :title="info" v-b-tooltip></i>
      </label>
      <div class="d-inline-block ml-3">
        <div v-if="hasError" class="text-danger">Þetta svæði má ekki vera tómt</div>
      </div>

      <b-form-input
        v-if="edit && type === 'input'"
        ref="focusInput"
        v-model="editItem"
        @input="toChange()"
        v-on:keyup.enter="emitSave()"
        :maxLength="maxLength"
      />
      <b-form-checkbox v-if="edit && type === 'checkbox'" v-model="editItem" @input="toChange()" />
      <b-form-select v-if="edit && type === 'dropdown'" :options="options" v-model="editItem" @input="toChange()" />
      <b-form-input type="number" v-if="edit && type === 'number'" ref="focusNumber" v-model="editItem" @input="toChange()" />
      <datepicker
        id="date"
        name="date"
        v-if="edit && type === 'date'"
        v-model="editItem"
        :language="lang"
        :monday-first="true"
        format="dd.MM.yyyy"
        :typeable="false"
        :clear-button="true"
        @input="toChange()"
        ref="dateOpenPickr"
        :class="{ 'datepicker-not-empty': editItem }"
      ></datepicker>

      <div v-if="!edit && (type === 'input' || type === 'number')">{{ item }}</div>
      <div v-if="!edit && type === 'checkbox'"><yes-no :item="item" /></div>
      <div v-if="!edit && type === 'dropdown'">{{ dropdownValue }}</div>
      <div v-if="!edit && type === 'date'">{{ dateValue }}</div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import YesNo from '@/components/common/YesNo.vue';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';

export default {
  name: 'itemEdit',
  props: [
    'label',
    'item', // gildið sem er verið að breyta
    'editStatus', // hvort verið sé að breyta eða ekki, til að loka frá parent ef td skipt á milli taba
    'editParam', // hvaða dálk er verið að breyta
    'checkbox', // er checkbox
    'number', // er tala
    'date', // er dagsetning
    'dropdown', // er dropdown, þá kemur dropdown list með
    'dropdownList', // ef er dropdown
    'disabled', // má breyta eða ekki
    'maxLength', // hámarkslengd á textasvæði
    'required', // svæði skylda
    'small', // css - ákvarðar stærð
    'empty', // tómt
    'info', // ef á að birta info
  ],
  components: {
    YesNo,
    Datepicker,
  },
  computed: {
    hasError() {
      return this.edit && this.type !== 'checkbox' && this.required && !this.editItem;
    },
    dropdownValue() {
      return this.options.find(x => x.value === this.item) ? this.options.find(x => x.value === this.item).text : '';
    },
    dateValue() {
      return this.editItem ? moment(this.editItem).format('DD.MM.YYYY') : '';
    },
  },
  data() {
    return {
      lang: is,
      edit: false,
      editItem: '',
      type: 'input',
      options: [],
    };
  },
  methods: {
    toEdit() {
      if (!this.disabled) {
        this.edit = true;
        this.$emit('toEdit');
        this.$nextTick(() => {
          if (this.number && this.$refs.focusNumber) {
            this.$refs.focusNumber.focus();
          } else if (this.$refs.focusInput) {
            this.$refs.focusInput.focus();
          }
        });
      }
    },
    toChange() {
      if (!this.hasError) {
        let value = this.editItem;
        if (this.checkbox) {
          value = this.editItem ? 1 : 0;
        }
        const item = {
          column: this.editParam,
          value,
        };
        this.$emit('toChange', item);
      }
    },
    initializeType() {
      if (this.checkbox) {
        this.type = 'checkbox';
        this.editItem = this.item === 1;
      } else if (this.dropdown) {
        this.type = 'dropdown';
        this.options = this.dropdownList;
      } else if (this.number) {
        this.type = 'number';
      } else if (this.date) {
        this.type = 'date';
        this.editItem = this.item ? moment(this.item).toDate() : '';
      } else {
        this.type = 'input';
      }
    },
    emitSave() {
      this.$emit('saveData');
    },
  },
  watch: {
    editStatus() {
      if (!this.editStatus) {
        this.edit = false;
        this.editItem = this.item;
        if (this.checkbox) {
          this.editItem = this.item === 1;
        }
        this.toChange();
      }
    },
    item() {
      this.editItem = this.item;
      if (this.checkbox) {
        this.editItem = this.item === 1;
      }
    },
    number() {
      this.initializeType();
    },
    date() {
      this.initializeType();
    },
    checkbox() {
      this.initializeType();
    },
    dropdown() {
      this.initializeType();
    },
    dropdownList() {
      if (this.dropdownList && this.dropdownList.length > 0) {
        this.options = this.dropdownList;
        this.dropdown = true; // eslint-disable-line
      }
    },
  },
  created() {
    this.editItem = this.item;
    this.initializeType();
  },
};
</script>

<style lang="scss">
@import '../../style/variables';
.itemEditBox {
  height: 60px;
  width: 100%;
  border: 1px solid #cbd1e0;
  border-radius: 3px;
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
  &.empty {
    border-color: transparent;
  }
  &.error {
    border: 1px solid $danger !important;
  }
  &.small {
    height: 48px;
    margin-bottom: 8px;
    .form-control {
      font-size: 12px;
      height: 28px;
    }
    select {
      font-size: 12px;
      height: 28px;
      &.custom-select {
        padding-top: 0.2rem;
      }
    }
    .vdp-datepicker {
      &.datepicker-not-empty {
        margin-top: -20px;
      }
    }
  }
  &.disabled {
    cursor: default; // not-allowed;
  }
  label {
    font-size: 12px;
    padding-left: 12px;
    padding-top: 2px;
  }
  div {
    margin-top: -8px;
    padding-left: 10px;
    &.custom-control.custom-checkbox {
      margin-left: 25px;
    }
  }
  select {
    &:focus {
      box-shadow: none;
      border-color: transparent;
    }
    font-size: 14px;
    border-color: transparent;
    margin-top: -16px;
  }
  input {
    &:focus {
      box-shadow: none;
    }
    // height: 60px;
    border: none;
    margin-top: -12px;
  }
  .vdp-datepicker {
    &.datepicker-not-empty {
      margin-top: -15px;
    }
    margin-top: 5px;
    padding-left: 0px;
    input {
      &:focus {
        border-color: transparent !important;
      }
      border-color: transparent !important;
      height: inherit !important;
    }
  }
  &.active {
    border: 1px solid #303030;
    input {
      border-color: #303030;
    }
  }
}
</style>
