<template>
  <div>
    <table class="table" style="font-size: 13px">
      <thead>
        <tr class="table-search table-detail-search">
          <td :colspan="slidebar ? (searchTerm ? 2 : 4) : searchTerm ? 5 : 10" :class="{ 'pb-0': detailedSearch }">
            <b-form-input
              v-model="search.search"
              class="search"
              v-on:keyup.enter="loadList"
              placeholder="Leita eftir áfanga"
              ref="focusInput"
            ></b-form-input>
          </td>
          <td v-if="searchTerm" :colspan="slidebar ? (searchTerm ? 2 : 4) : searchTerm ? 5 : 10" :class="{ 'pb-0': detailedSearch }">
            <b-form-group label-for="term">
              <v-select
                class="v-select-module"
                id="term"
                name="term"
                placeholder="Kennslutímabil"
                v-model="search.term"
                :options="getTerms"
                :clearable="true"
              >
              </v-select>
            </b-form-group>
          </td>
          <td :colspan="slidebar ? 1 : 3" :class="{ 'pb-0': detailedSearch }">
            <b-btn variant="primary" class="w-100" style="margin-top: 25px" @click="loadList">Leita</b-btn>
            <div class="cursor-pointer text-right pt-2" @click="detailedSearch = !detailedSearch">
              <span v-if="detailedSearch"><i class="fa fa-angle-up"></i> Fela ítarleit</span>
              <span v-else><i class="fa fa-angle-down"></i> Sýna ítarleit</span>
            </div>
          </td>
        </tr>
        <tr v-if="detailedSearch" class="table-detail-search">
          <td colspan="100%">
            <b-row>
              <b-col :cols="slidebar ? 6 : 3" class="pl-0">
                <b-form-group label="Deild" label-for="department">
                  <v-select id="department" name="department" v-model="search.department" :options="departments" :clearable="true">
                  </v-select>
                </b-form-group> </b-col
              ><b-col :cols="slidebar ? 6 : 3">
                <b-form-group label="Undirskóli" label-for="division">
                  <v-select id="division" name="division" v-model="search.division" :options="divisions" :clearable="true"> </v-select>
                </b-form-group>
              </b-col>
              <b-col :class="{ 'pl-0': slidebar }" :cols="slidebar ? 6 : 3" v-if="!searchTerm">
                <b-form-group label="Kennslutímabil" label-for="term">
                  <v-select id="term" name="term" v-model="search.term" :options="getTerms" :clearable="true"> </v-select>
                </b-form-group>
              </b-col>
              <b-col :class="{ 'pl-0': slidebar }" :cols="slidebar ? 6 : 3" v-if="searchTerm">
                <b-form-group label="Virkur" label-for="active">
                  <v-select id="active" name="active" v-model="search.active" :options="active" :clearable="false"> </v-select>
                </b-form-group>
              </b-col>
              <b-col :cols="slidebar ? 6 : 3">
                <b-form-group label="Braut" label-for="course">
                  <v-select id="course" name="course" v-model="search.course" :options="getCourses" :clearable="true"> </v-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col :cols="slidebar ? 6 : 3" class="pl-0" v-if="!searchTerm">
                <b-form-group label="Virkur" label-for="active">
                  <v-select id="active" name="active" v-model="search.active" :options="active" :clearable="false"> </v-select>
                </b-form-group>
              </b-col>
              <b-col :class="{ 'pl-0': searchTerm }" :cols="slidebar ? 6 : 3">
                <b-form-group label="Einingar" label-for="units">
                  <b-form-input
                    id="units"
                    name="units"
                    v-model="search.units"
                    v-on:keyup.enter="loadList"
                    style="height: 30px"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col :class="{ 'pl-0': searchTerm }" :cols="slidebar ? 6 : 3">
                <b-form-group label="Þrep" label-for="steps">
                  <b-form-input
                    id="steps"
                    name="steps"
                    v-model="search.steps"
                    v-on:keyup.enter="loadList"
                    style="height: 30px"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col v-if="!slidebar" cols="2">
                <b-form-group>
                  <label for="notActiveAfterDate">
                    Ekki í ferli eftir dags.
                    <i
                      class="fa fa-info-circle"
                      v-b-tooltip
                      title="Áfangi er ekki í námsferli nemenda á önn sem líkur á
                    eftir valinni dagsetningu.
                      Þannig ef þú setur inn 01.01.2020 og færð áfanga upp
                      þá er áfanginn ekki í námsferli hjá nemendum á önn sem lauk eftir 01.01.2022."
                    ></i>
                  </label>
                  <datepicker
                    id="notActiveAfterDate"
                    name="notActiveAfterDate"
                    class="datepicker_90_width"
                    v-model="search.notActiveAfterDate"
                    :language="lang"
                    :monday-first="true"
                    format="dd.MM.yyyy"
                    :typeable="false"
                    :clear-button="true"
                    style="height: 30px"
                    ref="notActiveAfterDateOpenPickr"
                  >
                  </datepicker>
                </b-form-group>
              </b-col>
              <b-col>
                <b-btn
                  variant="dark"
                  style="height: 35px; font-size: 12px; margin-top: 14px"
                  class="float-right"
                  cols="1"
                  @click="clearSearch()"
                >
                  <i class="fa fa-eraser" aria-hidden="true"></i>
                  Hreinsa</b-btn
                >
              </b-col>
            </b-row>
          </td>
        </tr>
        <tr v-if="firsttime">
          <th v-if="!slidebar" style="width: 2%; white-space: nowrap">
            <b-form-checkbox v-if="canEdit" v-model="selectAll" @change="updateAll()" class="d-inline-block"></b-form-checkbox>

            <b-btn
              variant="primary"
              class="btn-xs d-inline-block"
              @click="openMassEditSlidebar()"
              v-if="selectedModules.length > 0"
              style="font-size: 12px"
            >
              <i class="fa fa-pencil"></i>
            </b-btn>
          </th>
          <th><LinkOrder title="Auðkenni" :filteredList="list" :list="list" column="namsgrein_afanganr" /> ({{ list.length }})</th>
          <th>
            <LinkOrder title="Heiti" :filteredList="list" :list="list" column="afanga_heiti" />
          </th>
          <th>
            <LinkOrder title="Einingar" :filteredList="list" :list="list" column="einingar" />
          </th>
          <th v-if="!slidebar">
            <LinkOrder title="Þrep" :filteredList="getList" :list="list" column="trep" secondColumn="namsgrein_afanganr" />
          </th>
          <th v-if="!slidebar && !showHoursPerWeek">
            <LinkOrder
              title="Fj. tíma kennslutímabili"
              :filteredList="getList"
              :list="list"
              column="timar_onn_alls"
              secondColumn="namsgrein_afanganr"
            />
          </th>
          <th v-if="!slidebar && showHoursPerWeek">
            <LinkOrder
              title="Fj. tíma viku"
              :filteredList="getList"
              :list="list"
              column="timar_viku_nemar"
              secondColumn="namsgrein_afanganr"
            />
          </th>
          <th v-if="!slidebar">
            <LinkOrder title="Deild" :filteredList="getList" :list="list" column="deild" secondColumn="namsgrein_afanganr" />
          </th>
          <th>
            <LinkOrder title="Undirskóli" :filteredList="list" :list="list" column="undisk_audk" />
          </th>
          <th v-if="!slidebar">
            <LinkOrder title="Dags." :filteredList="getList" :list="list" column="dags_gildis" secondColumn="namsgrein_afanganr" />
          </th>
          <th v-if="!slidebar">
            <LinkOrder title="Í gildi" :filteredList="getList" :list="list" column="gildi" secondColumn="namsgrein_afanganr" />
          </th>
          <th v-if="!slidebar">
            <LinkOrder title="Jafngildi" :filteredList="getList" :list="list" column="jafngildi" secondColumn="namsgrein_afanganr" />
          </th>
          <th v-if="!slidebar">
            <LinkOrder title="Undanfarar" :filteredList="getList" :list="list" column="undanfarar" secondColumn="namsgrein_afanganr" />
          </th>
          <th>
            <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="cursor-pointer float-right">
              <i class="fa fa-file-excel-o excelText"></i>
            </download-csv>
          </th>
        </tr>
      </thead>
      <tbody>
        <TableRowLoader v-if="loading && firsttime" :colspan="slidebar ? 5 : 14" :center="true" />
        <TableRowEmpty v-if="!loading && firsttime && this.list.length === 0" :colspan="slidebar ? 5 : 14" :center="true" />
        <TableRowSearchForResults v-if="!firsttime" :colspan="slidebar ? 5 : 14" :center="true" />
        <tr v-for="item in getPaginatedList" :key="item.afangi_id">
          <td v-if="!slidebar" style="width: 2%"><b-form-checkbox v-if="canEdit" v-model="item.selected"></b-form-checkbox></td>
          <td>
            <b-link @click="changeModule(item)" title="Velja áfanga" v-b-tooltip v-if="slidebar">
              {{ item.namsgrein_afanganr }}
            </b-link>
            <b-link
              v-else
              :to="{
                name: 'Module',
                params: { id: item.afangi_id },
                query: {
                  search: search.search,
                  term: `${search.term ? search.term.onn_id : ''}`,
                  dept: `${search.department ? search.department.department_id : ''}`,
                  div: `${search.division ? search.division.division_id : ''}`,
                  course: `${search.course ? search.course.braut_id : ''}`,
                  active: `${search.active ? search.active.id : ''}`,
                  steps: search.steps,
                  units: search.units,
                  notActiveAfterDate: search.notActiveAfterDate,
                },
              }"
            >
              {{ item.namsgrein_afanganr }}</b-link
            >
          </td>
          <td>{{ item.afanga_heiti }}</td>
          <td>{{ item.einingar }}</td>
          <td v-if="!slidebar">{{ item.trep }}</td>
          <td v-if="!slidebar && !showHoursPerWeek">{{ item.timar_onn_alls }}</td>
          <td v-if="!slidebar && showHoursPerWeek">{{ item.timar_viku_nemar }}</td>
          <td v-if="!slidebar">
            <div v-for="(d, idx) in item.deildir" :key="idx">
              {{ d }}
            </div>
          </td>
          <td>{{ item.undirsk_audk }}</td>
          <td v-if="!slidebar">{{ item.dags_gildis | moment('DD.MM.YYYY') }}</td>
          <td v-if="!slidebar"><yes-no :item="item.gildi"></yes-no></td>
          <td v-if="!slidebar" style="font-size: 12px">
            <div v-for="(jafngildi, index) in item.jafngildi" :key="index">
              <b-link :to="{ name: 'Module', params: { id: jafngildi.afangi_id } }">
                {{ jafngildi.undirsk_audk }} - {{ jafngildi.namsgrein_afanganr }}
              </b-link>
            </div>
          </td>
          <td v-if="!slidebar" style="font-size: 12px">
            <div v-for="(undanfari, index) in item.undanfarar" :key="index">
              <b-link :to="{ name: 'Module', params: { id: undanfari.afangi_id } }">
                {{ undanfari.undirsk_audk }} - {{ undanfari.namsgrein_afanganr }}
              </b-link>
            </div>
          </td>
          <td>
            <b-link :to="{ name: 'CreateModule', query: { copy: item.afangi_id } }" v-if="canEdit && !slidebar">
              <i class="fa fa-copy float-right cursor-pointer" title="Afrita áfanga" v-b-tooltip></i>
            </b-link>
          </td>
        </tr>
      </tbody>
    </table>

    <Pagination
      :currentPage="currentPage"
      :totalRows="list.length"
      :perPage="perPage"
      v-if="list.length > 20"
      @change-page="changePage"
      @change-limit="changeLimit"
    ></Pagination>

    <module-mass-edit-slidebar v-if="showSlidebar" :items="selectedModules" @closeSlidebar="closeSlidebar()"></module-mass-edit-slidebar>
  </div>
</template>

<script>
import moment from 'moment';
import curriculum from '@/api/curriculum';
// import school from '@/api/school';
import terms from '@/api/terms';
import structure from '@/api/structure';
import { mapGetters, mapActions } from 'vuex';

import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import TableRowSearchForResults from '@/components/common/TableRowSearchForResults.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';
import Pagination from '@/components/common/Pagination.vue';
import YesNo from '@/components/common/YesNo.vue';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';

import ModuleMassEditSlidebar from '@/components/curriculum/modules/ModuleMassEditSlidebar.vue';

export default {
  name: 'module-search',
  components: {
    TableRowLoader,
    TableRowEmpty,
    TableRowSearchForResults,
    LinkOrder,
    Pagination,
    YesNo,
    Datepicker,
    ModuleMassEditSlidebar,
  },
  props: ['slidebar', 'searchTerm', 'selectDefaultTerm'],
  computed: {
    getExcelData() {
      const array = [];
      this.list.forEach(x => {
        const item = {
          Auðkenni: x.namsgrein_afanganr,
          Heiti: x.afanga_heiti,
          Einingar: x.einingar,
          Þrep: x.trep,
          Undirskóli: x.undirsk_audk,
          Deild: x.deild,
          'Fj. tíma kennslutímabili': x.timar_onn_alls,
          'Fj. tíma viku': x.timar_viku_nemar,
          'Dags.': moment(x.dags_gildis).format('DD.MM.YYYY'),
          'Í gildi': x.gildi ? 'Já' : 'Nei',
          Jafngildi: x.jafngildi.map(y => `${y.undirsk_audk}-${y.namsgrein_afanganr}`).join(','),
          Undanfarar: x.undanfarar.map(y => `${y.undirsk_audk}-${y.namsgrein_afanganr}`).join(','),
          'Heiti - enska': x.afanga_heiti_enska,
          'Á námsferil': x.a_namsferil === 1 ? 'Já' : 'Nei',
          'Í fjarvistarferil': x.i_fjarvistarferil === 1 ? 'Já' : 'Nei',
          'Oft í námsferil': x.fjolval === 1 ? 'Já' : 'Nei',
          'Tegund gjalds': x.tegund_gjalds_heiti,
          'Upphæð': x.upphaed,
        };
        if (!this.isLearningCenter) {
          item['Fornámsáfangi'] = x.fornamsafangi === 1 ? 'Já' : 'Nei';
          item['Umsjónaráfangi'] = x.umsj_afangi === 1 ? 'Já' : 'Nei';
          item['Skólasóknaráfangi'] = x.skolasokn === 1 ? 'Já' : 'Nei';
        }
        if (this.showPrimary) {
          item['Tegund áfanga'] = x.tegund_afanga_heiti;
        }
        item['Fjöldi nemenda í hóp'] = x.max_fj_nema;
        if (!this.schoolHasSettings('stundatoflukerfi')) {
          item['Frávik - fjöldi nemenda'] = x.fravik;
          item['Hámarksfjöldi í hóp'] = x.efstu_mork;
          item['Fjöldi samfelldra tíma'] = x.fj_samf_tima;
          item['Tegund stofu'] = x.stofa_heiti;
          item['Tegund stofu - vara'] = x.stofa_vara_heiti;
          item['Fjöldi samfelldra tíma'] = x.fj_samf_tima;
          item['Tegund stofu - aukastofa'] = x.stofa_auka_heiti;
          item['Fjöldi nemenda - aukastofa'] = x.max_fj_nema_2;
          item['Fjöldi samfelldra tíma - aukastofa'] = x.fj_samf_tima_2;
          item['Fjöldi tíma á viku - aukastofa'] = x.timar_viku_nemar_2;
        }

        if (this.showPrimary) {
          item['Grunnskólafag'] = x.fag_heiti;
          item['Grunnskólaeinkunnir (Bókstafir)'] = x.grskeink_bokstafir;
          item['Grunnskólaeinkunn frá (Tölustafir)'] = x.grskeink_fra;
          item['Grunnskólaeinkunn til (Tölustafir)'] = x.grskeink_til;
        }
        array.push(item);
      });
      return array;
    },
    showPrimary() {
      return this.schoolSettings && this.schoolSettings.tegund_skola_id !== 4 && this.schoolSettings.tegund_skola_id !== 5;
    },
    isLearningCenter() {
      return this.schoolSettings && (this.schoolSettings.tegund_skola_id === 4 || this.schoolSettings.tegund_skola_id === 5);
    },
    getExcelFileName() {
      return `afangar_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    showHoursPerWeek() {
      return this.schoolSettings && (this.schoolSettings.tegund_skola_id === 1 || this.schoolSettings.tegund_skola_id === 3);
    },
    getList() {
      return this.list;
    },
    getPaginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.list.slice(start, end);
    },
    getDivisions() {
      return this.divisions.filter(
        x =>
          (!this.search.term || x.division_id === this.search.term.undirskoli_id) &&
          (!this.search.course || x.division_id === this.search.course.undirskoli_id),
      );
    },
    getTerms() {
      return this.terms.filter(x => !this.search.division || x.undirskoli_id === this.search.division.division_id);
    },
    getDepartments() {
      return this.departments.filter(x => !this.search.course || (this.search.course.deild || '').split(',').find(y => y === x.name));
    },
    getCourses() {
      return this.courses.filter(
        x =>
          (!this.search.division || x.undirskoli_id === this.search.division.division_id) &&
          (!this.search.department || (x.deild || '').split(',').find(y => y === this.search.department.name)),
      );
    },
    selectedModules() {
      return this.list.filter(x => x.selected);
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_afangar');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('namskra_afangar');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      lang: is,
      perPage: 20,
      currentPage: 1,
      selectAll: false,
      firsttime: false,
      detailedSearch: false,
      loading: false,
      showSlidebar: false,
      list: [],
      terms: [],
      departments: [],
      divisions: [],
      courses: [],
      active: [
        { id: '1', label: 'Eingöngu virkir' },
        { id: '0', label: 'Eingöngu óvirkir' },
        { id: '', label: 'Bæði virkir og óvirkir' },
      ],
      search: {
        search: '',
        active: { id: '1', label: 'Eingöngu virkir' },
        showDepartment: 1,
        term: '',
        department: '',
        division: '',
        course: '',
        steps: '',
        units: '',
        notActiveAfterDate: '',
      },
      selectedItem: {},
    };
  },
  methods: {
    updateQuery() {
      // if (!this.slidebar) {
      const queries = JSON.parse(JSON.stringify(this.$route.query));
      queries.search = this.search.search;
      queries.term = this.search.term ? this.search.term.onn_id.toString() : '';
      queries.dept = this.search.department ? this.search.department.department_id.toString() : '';
      queries.div = this.search.division ? this.search.division.division_id.toString() : '';
      queries.course = this.search.course ? this.search.course.braut_id.toString() : '';
      queries.active = this.search.active.id.toString();
      queries.steps = this.search.steps;
      queries.units = this.search.units;
      if (JSON.stringify(this.$route.query) !== JSON.stringify(queries)) {
        this.$router.replace({ query: queries });
      }
      // }
    },
    async loadList() {
      try {
        this.firsttime = true;
        this.loading = true;
        this.updateQuery();
        this.selectAll = false;
        const search = {
          search: this.search.search,
          term: this.search.term ? this.search.term.onn_id : '',
          department: this.search.department ? this.search.department.department_id : '',
          division: this.search.division ? this.search.division.division_id : '',
          course: this.search.course ? this.search.course.braut_id : '',
          active: this.search.active.id,
          steps: this.search.steps,
          units: this.search.units,
          notActiveAfterDate: this.search.notActiveAfterDate ? moment(this.search.notActiveAfterDate).format('DD.MM.YYYY') : '',
          showDepartment: 1,
        };
        // ef search bar er útfylltur (ítarleit) þá á að birta ítarleitina
        if (
          this.search.term ||
          this.search.department ||
          this.search.division ||
          this.search.course ||
          this.search.steps ||
          this.search.units
        ) {
          this.detailedSearch = true;
        }
        const response = await curriculum.getModuleList(search);
        this.list = response.data.items.map(x => ({
          ...x,
          deildir: x.deild ? x.deild.split(',') : [],
          jafngildi: x.jafngildi ? JSON.parse(x.jafngildi) : [],
          undanfarar: x.undanfarar ? JSON.parse(x.undanfarar) : [],
          selected: false,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadTerms(id) {
      try {
        const response = await terms.getTerms();
        this.terms = response.data.items.map(x => ({
          ...x,
          label: `${x.undirsk_audk} - ${x.onn_heiti}`,
        }));
        if (id) {
          this.search.term = this.terms.find(x => x.onn_id === parseInt(id, 10));
        } else if (this.selectDefaultTerm) {
          this.search.term = this.terms.find(x => x.is_default === 1);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadDepartments(id) {
      try {
        const response = await structure.getDepartmentList();
        this.departments = response.data.items.map(x => ({
          ...x,
          label: `${x.name}`,
        }));
        if (id) {
          this.search.department = this.departments.find(x => x.department_id === parseInt(id, 10));
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadDivisions(id) {
      try {
        const response = await structure.getDivisionList();
        this.divisions = response.data.items.map(x => ({
          ...x,
          label: `${x.code} - ${x.name}`,
        }));
        if (id) {
          this.search.division = this.divisions.find(x => x.division_id === parseInt(id, 10));
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadCouses(id) {
      try {
        const response = await curriculum.getCourseList({ active: 1, showDepartment: 1 });
        this.courses = response.data.items.map(x => ({
          ...x,
          label: `${x.braut_kodi} - ${x.heiti}`,
        }));
        if (id) {
          this.search.course = this.courses.find(x => x.braut_id === parseInt(id, 10));
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    changeModule(item) {
      this.$emit('changeModule', {
        id: item.afangi_id,
        term: this.search.term,
      });
    },
    clearSearch() {
      this.search = {
        search: '',
        active: { id: '1', label: 'Eingöngu virkir' },
        showDepartment: 1,
        term: '',
        department: '',
        division: '',
        course: '',
        steps: '',
        units: '',
        notActiveAfterDate: '',
      };
      this.loadList();
    },
    openMassEditSlidebar() {
      this.showSlidebar = true;
    },
    closeSlidebar() {
      this.showSlidebar = false;
      this.loadList();
    },
    async loadData() {
      const queries = this.$route.query;
      this.search.search = queries.search || '';
      this.search.active = queries.active ? this.active.find(x => x.id === queries.active) : { id: '1', label: 'Eingöngu virkir' };
      this.search.steps = queries.steps || '';
      this.search.units = queries.units || '';
      this.loadTerms(queries.term);
      this.loadDepartments(queries.dept);
      this.loadDivisions(queries.div);
      await this.loadCouses(queries.course);
      this.$nextTick(() => {
        if (this.$refs.focusInput) {
          this.$refs.focusInput.focus();
        }
        if (Object.keys(queries).length > 0) {
          this.loadList();
        }
      });
    },
    updateAll() {
      this.list.forEach(i => {
        this.$set(i, 'selected', this.selectAll);
      });
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadData();
  },
};
</script>
<style lang="scss">
.v-select-module {
  margin-top: 5px;

  .vs__dropdown-toggle {
    border-radius: 0px;
  }

  input {
    height: calc(1.5em + 0.75rem + 2px);
    border-radius: 0px;
  }
}
</style>
