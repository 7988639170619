import Vue from 'vue';

const API_URL = '/api/certificates';

export default {
  getTemplates(type) {
    return Vue.http
      .get(`${API_URL}/templates/${type}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createModuleCertificate(template, group, students, params) {
    return Vue.http
      .post(`${API_URL}/module-certificate/${template}/${group}/${students}`, params)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createSingleClassCertificate(template, classId, students) {
    return Vue.http
      .post(`${API_URL}/single-class-certificate/${template}/${classId}/${students}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getModuleCertificateStudentList(group) {
    return Vue.http
      .get(`${API_URL}/module-certificate-students/${group}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
};
