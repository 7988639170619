<template>
  <table class="table table-hover less-padding attendanceModulesTable" style="font-size: 13px">
    <Loader v-if="loading"></Loader>
    <thead v-if="!loading">
      <tr>
        <td>Önn</td>
        <td :colspan="getFjarv.length">Fjarvistir</td>
        <td :colspan="getForf.length">Forföll</td>
        <td>Fj.<i class="fa fa-info-circle" title="Fjöldi tíma" v-b-tooltip></i></td>
        <td>Stig <i class="fa fa-info-circle" title="Fjarvistarstig" v-b-tooltip></i></td>
        <td :colspan="attendanceCount">Mætingarhlutfall</td>
      </tr>
      <tr v-if="!loading">
        <td></td>
        <td v-for="fjar in getFjarv" :key="fjar.teg_fjarvista">{{ fjar.kodi }}</td>
        <td v-for="forf in getForf" :key="forf.teg_forfalla">{{ forf.kodi }}</td>
        <td></td>
        <td></td>
        <td v-if="schoolSettings.maetingarhlutfall_stig === 1">
          Mæting <i class="fa fa-info-circle" :title="`<strong>Mæting</strong><br/> ${$t('maeting')}`" v-b-tooltip.hover.html></i>
        </td>
        <td v-if="schoolSettings.maetingarhlutfall_raun === 1">
          Raun <i class="fa fa-info-circle" :title="`<strong>Raunmæting</strong><br/> ${$t('raunMaeting')}`" v-b-tooltip.hover.html></i>
        </td>
        <td v-if="schoolSettings.maetingarhlutfall_onn === 1">
          Önn <i class="fa fa-info-circle" :title="`<strong>Önn</strong><br/> ${$t('maetingOnn')}`" v-b-tooltip.hover.html></i>
        </td>
        <td v-if="schoolSettings.maetingarhlutfall_einkunn === 1">
          Eink.
          <i
            class="fa fa-info-circle"
            :title="`<strong>Til mætingareinkunnar</strong><br/> ${$t('tilMaetingareinkunnar')}`"
            v-b-tooltip.hover.html
          ></i>
        </td>
      </tr>
    </thead>
    <AttendanceTerm v-for="onn in attendanceTerms.annir" :key="onn.onn_id" :onn="onn" :studentId="studentId" />
  </table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import students from '@/api/students';
import Loader from '@/components/common/Loader.vue';
import AttendanceTerm from '@/components/students/attendance/AttendanceTerm.vue';

export default {
  name: 'attendance-terms',
  props: ['attendance', 'studentId', 'mainView'],
  components: {
    Loader,
    AttendanceTerm,
  },
  computed: {
    getFjarv() {
      return this.attendanceTerms && this.attendanceTerms.annir && this.attendanceTerms.annir.length > 0 ? 
      this.attendanceTerms.annir[0].fjarvistir : [];
    },
    getForf() {
      return this.attendanceTerms && this.attendanceTerms.annir && this.attendanceTerms.annir.length > 0 ? 
      this.attendanceTerms.annir[0].forfoll : [];
    },
    attendanceCount() {
      let count = 0;
      if (this.schoolSettings && this.schoolSettings.maetingarhlutfall_stig === 1) {
        count += 1;
      }
      if (this.schoolSettings && this.schoolSettings.maetingarhlutfall_raun === 1) {
        count += 1;
      }
      if (this.schoolSettings && this.schoolSettings.maetingarhlutfall_onn === 1) {
        count += 1;
      }
      if (this.schoolSettings && this.schoolSettings.maetingarhlutfall_einkunn === 1) {
        count += 1;
      }
      return count;
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {
      loading: false,
      attendanceTerms: '',
    };
  },
  methods: {
    getAttendanceColor(item) {
      if (item < 80) {
        return 'attendancePercentageUnder80';
      }
      if (item >= 80 && item < 90) {
        return 'attendancePercentageUnder90';
      }
      return '';
    },
    async getAttendanceTerms() {
      try {
        this.loading = true;
        const response = await students.getStudentAttendanceTerms(this.studentId);
        if (response.data.items.length > 0) {
          this.attendanceTerms = response.data.items[0];
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    if (this.mainView === 1) {
      // sækja uppl.
      this.loading = true;
      this.getAttendanceTerms();
    } else {
      this.attendanceTerms = this.attendance;
      this.loading = false;
    }

  }
};
</script>
<style lang="scss">
table.attendanceModulesTable {
  thead {
    background-color: #ccc;
  }
  tbody:nth-child(even) {
    background-color: #f9f9f9;
  }
  tbody:nth-child(odd) {
    background-color: #ececec;
  }
  tr {
    td,
    th {
      border-right: 2px solid #fff;
    }
  }
}
.attendancePercentageUnder80 {
  color: #cc0000;
}
.attendancePercentageUnder90 {
  color: #006699;
}
</style>
