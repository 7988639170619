<template>
  <div>
    <table class="table table-hover table-fixed less-padding" style="font-size: 13px">
      <thead>
        <tr class="table-detail-search">
          <td colspan="2">
            <b-form-input id="search" name="search" v-model="search.search" :options="departments" placeholder="Leita"> </b-form-input>
          </td>
          <td colspan="2">
            <v-select
              class="v-select-form-control"
              id="department"
              name="department"
              v-model="search.department"
              :options="departments"
              placeholder="Deildir"
              :clearable="true"
              multiple
              @input="loadList()"
            >
            </v-select>
          </td>
          <td>
            <v-select
              class="v-select-form-control"
              id="show"
              name="show"
              v-model="search.show"
              :options="show"
              :clearable="true"
              placeholder="Námsáætlun skráð og vantar"
            >
            </v-select>
          </td>
          <td>
            <b-btn variant="primary" class="float-right" @click="downloadZip()">Sækja sem zip skrá <i class="fa fa-download"></i></b-btn>
          </td>
        </tr>
        <tr>
          <th><LinkOrder title="Áfangi" :filteredList="getList" :list="list" column="namsgrein_afanganr" /> ({{ this.getList.length }})</th>
          <th><LinkOrder title="Hópur" :filteredList="getList" :list="list" column="hopur" secondColumn="namsgrein_afanganr" /></th>
          <th><LinkOrder title="Kennarar" :filteredList="getList" :list="list" column="kennarar" secondColumn="namsgrein_afanganr" /></th>
          <th><LinkOrder title="Deild" :filteredList="getList" :list="list" column="deild" secondColumn="namsgrein_afanganr" /></th>
          <th>
            <LinkOrder title="Stofnað" :filteredList="getList" :list="list" column="time_create" secondColumn="namsgrein_afanganr" />
          </th>
          <th>
            <download-csv v-if="!loading" :data="getExcelData" :name="getExcelFileName" delimiter=";" class="float-right cursor-pointer">
              <i class="fa fa-file-excel-o excelText"></i>
            </download-csv>
          </th>
        </tr>
      </thead>
      <tbody>
        <TableRowLoader v-if="loading" colspan="6" :center="true" />
        <TableRowEmpty v-if="!loading && list.length === 0" colspan="6" :center="true" />
        <tr v-for="(item, idx) in getPaginatedList" :key="idx">
          <td>{{ item.namsgrein_afanganr }}</td>
          <td>
            {{ item.hopur }}
            <b-link
              :to="{ name: 'Group', params: { id: item.hopur_id } }"
              target="_blank"
              v-if="canReadGroup"
              v-b-tooltip
              title="Skoða hóp"
            >
              <i class="fa fa-external-link"></i
            ></b-link>
          </td>
          <td>{{ item.kennarar }}</td>
          <td>{{ item.deild }}</td>
          <td>
            {{ item.time_create | moment('DD.MM.YYYY HH:mm:ss') }}
          </td>
          <td>
            <span v-if="item.content_type === '0'">
              <b-link :href="item.name" target="_blank" v-b-tooltip :title="item.name"
                >{{ item.name ? `${item.name.substr(0, 10)}..` : '' }} <i class="fa fa-external-link"></i
              ></b-link>
            </span>
            <span v-else>
              <b-link v-if="item.attachment_id" v-b-tooltip :title="item.name" @click="loadAttachment(item.attachment_id)"
                >{{ item.name ? `${item.name.substr(0, 10)}..` : '' }} <i class="fa fa-download"></i
              ></b-link>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <Pagination
      :currentPage="currentPage"
      :totalRows="getList.length"
      :perPage="perPage"
      v-if="getList.length > 20"
      @change-page="changePage"
      @change-limit="changeLimit"
    ></Pagination>
  </div>
</template>

<script>
import moment from 'moment';
import terms from '@/api/terms';
import structure from '@/api/structure';
import { mapActions, mapGetters } from 'vuex';

import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';
import Pagination from '@/components/common/Pagination.vue';

export default {
  name: 'term-booklist',
  props: ['id', 'item'],
  components: {
    TableRowLoader,
    TableRowEmpty,
    LinkOrder,
    Pagination,
  },
  computed: {
    getList() {
      const search = this.search.search.toUpperCase();
      return this.list
        .filter(
          x =>
            !this.search.search ||
            (x.namsgrein_afanganr || '').toUpperCase().startsWith(search) ||
            (x.hopur || '').toUpperCase().indexOf(search) > -1 ||
            (x.kennarar || '').toUpperCase().indexOf(search) > -1,
        )
        .filter(
          x => !this.search.show || (this.search.show.id === 0 && x.attachment_id) || (this.search.show.id === 1 && !x.attachment_id),
        );
    },
    getPaginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.getList.slice(start, end);
    },
    getExcelData() {
      const newList = [
        [],
        ['Námsáætlun'],
        [`${this.item.undirsk_heiti}-${this.item.onn_heiti}`],
        [],
        ['Áfangi', 'Hópur', 'Kennari', 'Deild', 'Dags. til', 'Heiti', 'Dags. stofnað'],
        ...this.list.map(x => [x.namsgrein_afanganr, x.hopur, x.kennarar, x.deild, x.name, x.time_create]),
      ];
      return newList;
    },
    getExcelFileName() {
      return `namsaetlun_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    canRead() {
      return this.loggedInUserHasReadPermission('skolahald_annir');
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_annir');
    },
    canReadGroup() {
      return this.loggedInUserHasReadPermission('nemendahald_hopar');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'loggedInUser']),
  },
  data() {
    return {
      perPage: 20,
      currentPage: 1,
      loading: false,
      search: {
        department: [],
        search: '',
        show: null,
      },
      list: [],
      departments: [],
      show: [
        { id: 0, label: 'Námsáætlun skráð' },
        { id: 1, label: 'Námsáætlun vantar' },
      ],
    };
  },
  methods: {
    async loadList() {
      try {
        this.loading = true;
        this.list = [];
        const response = await terms.getTeachingPlans(this.id, { departments: this.search.department.map(x => x.department_id).join(',') });
        this.list = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadDepartments() {
      try {
        const response = await structure.getDepartmentList();
        this.departments = response.data.items.map(x => ({
          ...x,
          label: `${x.name}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadAttachment(attachmentId) {
      try {
        const response = await this.$attachmentsApi.getAttachmentDownloadToken({ attachmentId });
        if (response.data.url) {
          window.open(response.data.url, '_blank');
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async downloadZip() {
      try {
        const response = await this.$attachmentsApi.getTeachingplanDownloadToken({
          termId: this.id,
          userId: this.loggedInUser.notandi_id,
          departments: this.search.department.map(x => x.department_id).join(','),
        });
        if (response.data.url) {
          window.open(response.data.url, '_blank');
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadList();
    this.loadDepartments();
  },
};
</script>
<style lang="scss"></style>
