<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div v-if="!loading">
      <div v-if="showGroupDetails"><strong>Hópur: </strong>{{ item.namsgrein_afanganr }}({{ item.hopur }})</div>
      <div v-if="showGroupDetails"><strong>Kennari: </strong>{{ item.kennarar }}</div>
      <div style="min-height: 100px">
        <div style="font-size: 13px; 
        margin-bottom: 15px">
          Hægt er að skrá einkunn með því að smella á blýantinn við viðkomandi einkunnaþátt. <br />
          Ef það birtist ekki blýantur þar sem á að skrá einkunn þarf að skoða hvort það sé <br />
          örugglega merkt „skráð“ einkunn í einkunnareglunni.
        </div>
        <b-btn
          v-if="canEdit"
          variant="primary"
          class="btn-xs mr-2 p-2"
          style="font-size: 13px"
          title="Reikna upp skv. einkunnareglu"
          v-b-tooltip
          :disabled="saving.calculate || saving.final"
          @click="calculateGrades()"
        >
          <i class="fa fa-spin fa-spinner" v-if="saving.calculate"></i>
          Reikna einkunn</b-btn
        >
        <b-btn
          v-if="canEdit"
          variant="primary"
          class="btn-xs mr-2 p-2"
          style="font-size: 13px"
          v-b-tooltip
          title="Flytja lokaeinkunn í feril"
          :disabled="saving.calculate || saving.final"
          @click="finalGrades()"
        >
          <i class="fa fa-spin fa-spinner" v-if="saving.final"></i>

          Lokaeinkunn í feril</b-btn
        >
        <b-btn
          variant="primary"
          class="btn-xs mr-2 p-2"
          style="font-size: 13px"
          v-b-tooltip
          title="Skoða einkunnareglu hóps"
          @click="openGradeRuleSlidebar()"
        >
          Einkunnaregla</b-btn
        >
        <b-btn
          v-if="canReadCertificate"
          variant="primary"
          class="btn-xs"
          :to="{
            name: 'Certificates',
            query: { term: group && group.onn_id, moduleTermId: group && group.afangar_onn_id, group: group && group.hopur_id },
          }"
          target="_blank"
          >Viðurkenningaskjal</b-btn
        >
        &nbsp;
      </div>
      <table class="table table-hover less-padding mt-2" style="font-size: 12px">
        <tr>
          <th>Nafn</th>
          <th>Kennitala</th>
          <th v-for="rule in item.einkunnaregla" :key="rule.einkunnaregla_id">
            <div class="gradeRuleRotateBox">
              <span class="gradeRuleRotate">
                <b-link v-if="edit && rule.reiknud_skrad === 1" style="color: inherit" @click="openGradeModal(rule)">
                  {{ rule.heiti.substring(0, 20) }} <span v-if="rule.heiti.length > 20">..</span><i class="fa fa-pencil text-primary"></i>
                </b-link>
                <span v-else> {{ rule.heiti.substring(0, 20) }} <span v-if="rule.heiti.length > 20">..</span> </span>
              </span>
            </div>
          </th>
          <th>
            <div class="gradeRuleRotateBox">
              <span class="gradeRuleRotate">Reikn. eink.</span>
            </div>
          </th>
          <th>
            <div class="gradeRuleRotateBox">
              <span class="gradeRuleRotate">Reikn. eink. allar</span>
            </div>
          </th>
          <th>
            <div class="gradeRuleRotateBox">
              <span class="gradeRuleRotate">Lokaeink. í námsf. </span>
            </div>
          </th>
          <th>
            <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="cursor-pointer float-right">
              <i class="fa fa-file-excel-o excelText"></i>
            </download-csv>
          </th>
        </tr>
        <tbody>
          <tr v-for="(student, index) in item.nemendur" :key="student.ferill_id">
            <td>
              {{ student.nafn }}
              <b-link
                :to="{ name: 'Student', params: { id: student.nemandi_id } }"
                target="_blank"
                v-if="canViewStudent"
                title="Skoða nemanda"
                v-b-tooltip
                ><i class="fa fa-external-link"></i
              ></b-link>
            </td>
            <td>{{ student.kennitala }}</td>
            <td
              v-for="(grade, idx) in student.einkunnir"
              :key="`student${index}grade${idx}`"
              :class="{
                gradeMissing:
                  (showMissing && grade.reiknud_skrad === 1 && !grade.einkunn && grade.heiti !== 'Miðannarmat') ||
                  (grade.einkunnaregla_id === finalGradeId && student.finalMismatch),
              }"
            >
              <span
                v-if="grade.einkunn"
                v-b-popover.hover.top="getGradePopover(grade)"
                :class="{
                  'text-danger':
                    !checkGradePassed(grade.lagmarks_einkunn, grade.fall_einkunnir_hops_lagm, grade.einkunn) || isScaleFail(grade),
                }"
                >{{ grade.einkunn ? getGrade(grade.einkunn) : '' }}
                <i class="fa fa-lock" title="Einkunn læst" v-b-tooltip v-if="grade.einkunn_laest === 1"></i
              ></span>
              <span v-else style="color: blue">{{ grade.assignment_grade }}</span>
            </td>
            <td>{{ student.reiknud_einkunn ? student.reiknud_einkunn.toFixed(2).toString().replace('.', ',') : '' }}</td>
            <td>
              {{ student.reiknud_einkunn_allar ? student.reiknud_einkunn_allar.toFixed(2).toString().replace('.', ',') : '' }}
            </td>
            <td :class="{ gradeMissing: (showFinalMissing && !student.lokaeinkunn) || student.finalMismatch }">
              {{ student.lokaeinkunn }}
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <div style="font-size: 13px">
        <div><strong>Reiknuð einkunnir allar</strong> inniheldur allar einkunnir bæði birtar og óbirtar sem vega upp í lokaeinkunn.</div>
        <div><strong>Reiknuð einkunn</strong> inniheldur eingöngu birtar einkunnir sem vega upp í lokaeinkunn.</div>
        <div>
          <div class="d-inline-block" style="height: 10px; width: 10px; background: blue"></div>
          = Bláar einkunnir eru óbirtar
        </div>
      </div>
    </div>
    <register-grade-slidebar
      v-if="showGradeSlidebar"
      :groupId="groupId"
      :gradeModuleId="selectedItem && selectedItem.einkunnir_afanga_id"
      :gradeGroupId="selectedItem && selectedItem.einkunnir_hops_id"
      :assignmentName="selectedItem && selectedItem.assignment_name"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
      @closeSlidebar="closeSlidebar"
    >
    </register-grade-slidebar>
    <grade-rule-slidebar
      v-if="showGradeRuleSlidebar"
      :groupId="groupId"
      :edit="canEditGraderule"
      :termModuleId="group && group.afangar_onn_id"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
      @closeSlidebar="closeAndReloadSlidebar"
    >
    </grade-rule-slidebar>
  </div>
</template>

<script>
import moment from 'moment';
import groups from '@/api/groups';
import { mapActions, mapGetters } from 'vuex';
import Loader from '@/components/common/Loader.vue';

import RegisterGradeSlidebar from '@/components/students/groups/grades/RegisterGradeSlidebar.vue';
import GradeRuleSlidebar from '@/components/school/terms/graderules/GradeRuleSlidebar.vue';

export default {
  name: 'group-assessment-overview',
  props: ['groupId', 'showMissing', 'showFinalMissing', 'showFinalMismatch', 'showGroupDetails', 'edit'],
  components: {
    Loader,
    RegisterGradeSlidebar,
    GradeRuleSlidebar,
  },
  computed: {
    getExcelData() {
      const newList = [
        [],
        ['Hópar - Einkunnir'],
        [],
        [
          'Nafn',
          'Kennitala',
          this.item.nemendur[0].einkunnir.map(y => `${y.heiti}`),
          'Reiknuð einkunn',
          'Reiknuð einkunn allar',
          'Lokaeinkunn í námsferli',
        ],
        ...this.item.nemendur.map(x => [
          x.nafn,
          `="${x.kennitala}"`,
          x.einkunnir.forEach(y => `${y.einkunn},`),
          x.reiknud_einkunn ? x.reiknud_einkunn.toFixed(2).toString().replace('.', ',') : '',
          x.reiknud_einkunn_allar ? x.reiknud_einkunn_allar.toFixed(2).toString().replace('.', ',') : '',
          x.lokaeinkunn ? x.lokaeinkunn.toString().replace('.', ',') : '',
        ]),
      ];
      return newList;

      // const list = [];
      // this.item.nemendur.forEach(x => {
      //   const i = {
      //     Nafn: x.nafn,
      //     Kennitala: `="${x.kennitala}"`,
      //   };
      //   x.einkunnir.forEach(y => {
      //     i[y.heiti] = y.einkunn;
      //   });
      //   i['Reiknuð einkunn'] = x.reiknud_einkunn ? x.reiknud_einkunn.toFixed(2).toString().replace('.', ',') : '';
      //   i['Reiknuð einkunn allar'] = x.reiknud_einkunn_allar ? x.reiknud_einkunn_allar.toFixed(2).toString().replace('.', ',') : '';
      //   i['Lokaeinkunn í námsferli'] = x.lokaeinkunn ? x.lokaeinkunn.toString().replace('.', ',') : '';

      //   list.push(i);
      // });

      // return list;
    },
    getExcelFileName() {
      return `hopar_einkunnir_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    finalGradeId() {
      return this.item && this.item.einkunnaregla && this.item.einkunnaregla.find(x => x.lokaeinkunn === 1)
        ? this.item.einkunnaregla.find(x => x.lokaeinkunn === 1).einkunnaregla_id
        : '';
    },
    canRead() {
      return this.loggedInUserHasReadPermission('skolahald_annir');
    },
    canReadCertificate() {
      return (
        this.loggedInUserHasReadPermission('nemendahald_skirteini') && this.schoolSettings && this.schoolSettings.tegund_skola_id === 4
      );
    },
    canEdit() {
      return (
        (this.loggedInUserHasWritePermission('skolahald_annir') || this.loggedInUserHasWritePermission('nemendahald_hopar')) &&
        this.group &&
        this.group.is_term_active === 1
      );
    },
    canEditGraderule() {
      return this.loggedInUserHasWritePermission('skolahald_annir') && this.group && this.group.is_term_active === 1;
    },
    canViewStudent() {
      return this.loggedInUserHasWritePermission('nemendahald_nemendur');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolHasSettings', 'schoolSettings']),
  },
  data() {
    return {
      loading: false,
      item: {},
      group: {},
      saving: {
        calculate: false,
        final: false,
      },
      showGradeSlidebar: false,
      showGradeRuleSlidebar: false,
      selectedItem: '',
    };
  },
  methods: {
    getGrade(grade) {
      let g = grade.replace('.', ',');
      if (g.startsWith(',')) {
        g = `0${g}`;
      }
      return g;
    },
    async calculateGrades() {
      try {
        this.saving.calculate = true;
        await groups.calculateGrades(this.groupId);
        this.displaySuccess('Búið er að reikna einkunn');
        this.loadList();
      } catch (e) {
        // Aðgerð mistókst.
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.saving.calculate = false;
      }
    },
    async finalGrades() {
      try {
        this.saving.final = true;
        await groups.finalGrades(this.groupId);
        this.displaySuccess('Lokaeinkunn flutt í feril');
        this.loadList();
      } catch (e) {
        // Aðgerð mistókst.
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.saving.final = false;
      }
    },
    async loadInfo() {
      try {
        const response = await groups.getGroupDetails(this.groupId);
        this.group = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadList() {
      try {
        this.loading = true;
        const response = await groups.getGroupAssessmentOverview(this.groupId);
        this.item = response.data.items.length > 0 ? response.data.items[0] : {};
        if (this.showFinalMismatch) {
          const finalGrade = this.item.einkunnaregla.find(x => x.lokaeinkunn === 1)
            ? this.item.einkunnaregla.find(x => x.lokaeinkunn === 1).einkunnaregla_id
            : '';
          this.item.nemendur.forEach(nem => {
            if (
              nem.lokaeinkunn &&
              nem.einkunnir.find(y => y.einkunnaregla_id === finalGrade) &&
              nem.lokaeinkunn !== nem.einkunnir.find(y => y.einkunnaregla_id === finalGrade).einkunn
            ) {
              this.$set(nem, 'finalMismatch', true);
            } else {
              this.$set(nem, 'finalMismatch', false);
            }
          });
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    checkGradePassed(minGrade, failGrade, grade) {
      // fall_einkunnir_hops_lagm === 1 er ekki búið að ná
      if (failGrade && failGrade === 1) {
        return false;
      }
      // ef lágmarkseinkunn og skráð tölueinkunn sem er minni þá ekki búið að ná
      // eslint-disable-next-line no-restricted-globals
      if (grade && minGrade && !isNaN(grade) && minGrade > grade) {
        // eslint-disable-line
        return false;
      }
      return true;
    },
    isScaleFail(grade) {
      return grade.einkunnir_afanga_id && grade.skali_stadid === 0;
    },
    getGradePopover(grade) {
      let text = '';
      if (!this.checkGradePassed(grade.lagmarks_einkunn, grade.fall_einkunnir_hops_lagm, grade.einkunn)) {
        text += 'Fall, lágmarkseinkunn ekki náð. \n';
        if (grade.lagmarks_einkunn) {
          text += `Lágmarkseinkunn er ${grade.lagmarks_einkunn} \n`;
        }
      } else if (grade.einkunnir_afanga_id && grade.skali_stadid === 0) {
        text += `Einkunn merkt í skala sem fall`;
      }

      return text;
    },
    openGradeRuleSlidebar() {
      this.showGradeRuleSlidebar = true;
      this.showGradeSlidebar = false;
    },
    openGradeModal(rule) {
      this.showGradeSlidebar = true;
      this.showGradeRuleSlidebar = false;
      this.selectedItem = rule;
    },
    closeSlidebar() {
      this.showGradeSlidebar = false;
      this.showGradeRuleSlidebar = false;
      this.selectedItem = '';
    },
    closeAndReloadSlidebar() {
      this.closeSlidebar();
      this.loadList();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadList();
    this.loadInfo();
  },
};
</script>
<style lang="scss">
.gradeRuleRotateBox {
  position: relative;
  width: 5px;
  padding-left: 5px;
}
.gradeRuleRotate {
  white-space: nowrap;
  display: block;
  line-height: 20px;
  position: absolute;
  // bottom: 0px;
  -webkit-transform: rotate(-75deg);
  -webkit-transform-origin: 1px;
  -moz-transform: rotate(-75deg);
  -moz-transform-origin: 1px;
  -ms-transform: rotate(-75deg);
  -ms-transform-origin: 1px;
  -o-transform: rotate(-75deg);
  -o-transform-origin: 1px;
  transform: rotate(-75deg);
  transform-origin: 1px;
}
.gradeMissing {
  background-color: #ffe8e8;
}
</style>
