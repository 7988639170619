<template>
  <page>
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <b-row v-if="item && canRead" class="noprint">
      <b-col>
        <PageTitle :title="item.skoli_heiti" />
      </b-col>
      <b-col>
        <div style="position: relative; height: 100%" v-if="item.logo_slod">
          <img :src="item.logo_slod" style="position: absolute; top: 0; bottom: 0; margin: auto; right: 0; height: 50px" />
        </div>
      </b-col>
    </b-row>
    <PageMenu route="School" :selectedPage="page" :items="menu" />
    <Settings v-if="page === 'settings' && canRead" :item="item" @reload="loadSettings()" />
    <Structure v-if="page === 'structure' && canRead" :item="item" />
    <Types v-if="page === 'types' && canRead" />
  </page>
</template>

<script>
import school from '@/api/school';

import { mapGetters } from 'vuex';

import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import PageTitle from '@/components/common/PageTitle.vue';
import PageMenu from '@/components/common/PageMenu.vue';
import Settings from '@/components/school/school/Settings.vue';
import Structure from '@/components/school/school/Structure.vue';
import Types from '@/components/school/school/Types.vue';

export default {
  name: 'school',
  components: {
    Breadcrumbs,
    PageTitle,
    PageMenu,
    Settings,
    Structure,
    Types,
  },
  computed: {
    canRead() {
      return this.loggedInUserHasReadPermission('skolahald_uppbygging');
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      item: '',
      loading: false,
      page: 'settings',
      breadcrumbs: [
        { name: 'Forsíða', route: 'Dashboard' },
        { name: 'Skólahald', route: '' },
        { name: 'Skólinn', route: 'School' },
      ],
      menu: [
        {
          name: 'Stýringar',
          page: 'settings',
        },
        {
          name: 'Uppbygging',
          page: 'structure',
        },
        {
          name: 'Tegundir',
          page: 'types',
        },
      ],
    };
  },
  methods: {
    async loadSettings() {
      if (this.canRead) {
        try {
          this.loading = true;
          const response = await school.getSchoolInfo();
          this.item = response.data;
          this.item.logo_slod = this.item.logo_slod ? this.item.logo_slod.replace('https', 'http') : '';
          this.item.logo_slod_erl = this.item.logo_slod_erl ? this.item.logo_slod_erl.replace('https', 'http') : '';
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading = false;
        }
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (to && to.query && to.query.page) {
      this.page = to.query.page;
    }
    next();
  },
  created() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
    this.loadSettings();
  },
};
</script>
