<template>
  <div class="sidebar">
    <div class="logo">
      <b-link :to="{ name: 'Dashboard' }">
        <img src="../../assets/Inna-logo-big.svg" />
      </b-link>
    </div>
    <div class="nav-collase-icon" @click="collapse">
      <i class="fa fa-angle-left" aria-hidden="true"></i>
    </div>
    <div class="nav-user">
      <div class="nav-user-back" v-if="showAccess" @click="showAccess = false">
        <i class="fa fa-angle-left back-icon" aria-hidden="true"></i>
        <a> Til baka </a>
      </div>
      <div v-else class="nav-user-name" @click="showAccess = true">
        {{ name }} <i class="fa fa-angle-right pl-2" aria-hidden="true"></i>
      </div>
    </div>
    <div v-if="showAccess">
      <div class="nav-item-category" v-for="(access, index) in userAccess" :key="index">
        <div class="nav-category-divider"></div>
        <div class="nav-item" style="padding-top: 5px; padding-bottom: 5px">
          <div class="nav-item-name" style="font-size: 12px">
            <b-link @click.prevent="changeSystem(access)" style="text-decoration: none">
              <div class="d-inline-block">
                <img src="../../assets/system0.png" v-if="access.system === 0" style="margin-top: -10px" />
                <img src="../../assets/system1.png" v-if="access.system === 1 && access.student === 0" style="margin-top: -10px" />
                <img src="../../assets/system2.png" v-if="access.system === 2" style="margin-top: -10px" />
                <img
                  src="../../assets/system3.png"
                  v-if="access.system === 3 || (access.system === 1 && access.student === 1)"
                  style="margin-top: -10px"
                />
                <img src="../../assets/system4.png" v-if="access.system === 4" style="margin-top: -10px" />
              </div>
              <div class="d-inline-block pl-2">
                <div v-if="access.loginType === 0 || access.loginType === 1" :title="access.school_name" v-b-tooltip>
                  {{ access.school_name.length > 35 ? `${access.school_name.substring(0, 35)}...` : access.school_name }}
                </div>
                <span v-if="access.loginType === 2">{{ access.name }}</span>
              </div>
            </b-link>
          </div>
        </div>
        <div class="nav-item-divider"></div>
      </div>
      <div class="nav-item-category" v-if="loggedInUser && loggedInUser.starfsm_id">
        <div class="nav-category-divider"></div>
        <div class="nav-item">
          <div class="nav-item-name">
            <b-link :to="{ name: 'StaffDetail', params: { id: loggedInUser.starfsm_id } }"> Ég </b-link>
          </div>
        </div>
        <div class="nav-item-divider"></div>
      </div>
      <div class="nav-item-category">
        <div class="nav-category-divider"></div>
        <div class="nav-item">
          <div class="nav-item-name">
            <b-link @click="logout"> Útskráning</b-link>
          </div>
        </div>
        <div class="nav-item-divider"></div>
      </div>
    </div>
    <div v-if="!showAccess" class="nav-grid">
      <SidebarLevel2
        class="sidebar-level-2"
        :class="{ 'show-level-2': showLevel2, 'hide-level-2': !showLevel2 }"
        :item="level2Category"
        :accessList="userAccess"
        @clear="clearLevel2"
      >
      </SidebarLevel2>

      <div v-if="!showLevel2" style="margin-top: -10px">
        <div class="nav-category" :class="{ closed: !category.open }" v-for="(category, categoryIndex) in getNavItems" :key="categoryIndex">
          <div class="nav-category-divider" v-if="categoryIndex === 0"></div>
          <div class="nav-category-item" @click="openCategory(category)">
            <img :src="getImgUrl(category)" alt="Category" />
            <div class="nav-category-name">
              {{ category.name }}
            </div>
            <img src="../../assets/right-arrow.svg" alt="Chevron-right" class="nav-category-chevron" />
          </div>
          <div class="nav-category-divider"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '@/api/auth';
import { mapGetters } from 'vuex';
import SidebarLevel2 from '@/components/layout/SidebarLevel2.vue';

export default {
  name: 'sidebar',
  props: ['navItems', 'userAccess'],
  components: {
    SidebarLevel2,
  },
  computed: {
    name() {
      return auth.getName();
    },
    ssn() {
      return auth.getSsn();
    },
    showLevel2() {
      return this.$store.state.showLevel2;
    },
    level2Category() {
      return this.$store.state.level2Category;
    },
    getNavItems() {
      // tímabundið útaf fjöldi í húsi er ekki aðgangsstýrt
      return this.navItems.filter(x => (!x.is_user && this.loggedInUserHasCategory(x.access)) || x.id === 'reports');
    },
    getUser() {
      return this.navItems.find(x => x.is_user);
    },
    ...mapGetters(['loggedInUserHasCategory', 'loggedInUser']),
  },
  data() {
    return {
      showAccess: false,
    };
  },
  methods: {
    openCategory(item) {
      this.$store.commit('openCategory', item);
    },
    clearLevel2() {
      this.$store.commit('closeCategory');
    },
    collapse() {
      this.$emit('collapse');
    },
    logout() {
      auth.logout();
    },
    getImgUrl(category) {
      return category.icon ? require(`../../assets/${category.icon}`) : ''; // eslint-disable-line
    },
    async changeSystem(access) {
      const response = await auth.getUrlToChangeSystem(access.loginType, access.user_id, access.url);
      // auth.removeToken();
      window.location.href = response.data.url;
    },
  },
};
</script>

<style lang="scss">
.sidebar {
  height: 100%;
}

.logo {
  text-align: center;
  img {
    padding-top: 20px;
    padding-bottom: 30px;
  }
}

.nav-grid {
  display: grid;

  .nav-category {
    text-align: left;
    cursor: pointer;
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }

    .nav-category-item {
      display: grid;
      grid-template-columns: auto 1fr auto;
      align-items: center;
      padding: 15px 18px 14px 18px;

      .nav-category-name {
        height: 24px;
        color: #ffffff;
        font-size: 16px;
        line-height: 24px;
        padding-left: 10px;
      }

      .nav-category-chevron {
          width: 0.5rem;
        }
    }

    .nav-category-divider {
      height: 1px;
      width: 300px;
      opacity: 0.15;
      background-color: #ffffff;
    }
  }
}


.sidebar-level-2 {
  opacity: 0;
  &.show-level-2 {
    opacity: 1;
    transition: all 0.75s ease;
  }
  &.hide-level-2 {
    transition: linear 0.5s ease;
  }
}

.nav-collase-icon {
  height: 24px;
  width: 24px;
  border-radius: 15px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.24);
  margin-left: 288px;
  margin-top: -20px;
  cursor: pointer;
  i {
    font-size: 18px;
    color: #303030;
    padding-left: 7px;
    padding-top: 3px;
  }
}

/* .nav-user {
  position: fixed;
  bottom: 0px;
  padding-top: 18px;
  padding-bottom: 5px;
} */

.no-hover {
  &:hover {
    background-color: transparent !important;
  }
}
.nav-user {
  color: #fff;
  padding-left: 0px;
  font-size: 13px;

  .nav-user-name {
    text-align: center;
    cursor: pointer;
  }
  .nav-user-back {
    text-align: left;
    padding-top: 10px;
    height: 39px;
    width: 300px;
    background-color: rgba(0, 0, 0, 0.1);
    a {
      color: #fff;
      height: 18px;
      width: 53px;
      font-size: 12px;
      font-weight: bold;
      line-height: 18px;
      padding-left: 5px;
      cursor: pointer;
      text-transform: uppercase;
    }
    i {
      margin-left: 20px;
    }
  }
}
</style>
