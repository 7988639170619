import Vue from 'vue';

const API_URL = '/api/school-types';

export default {
  getAbsenceTypesList() {
    return Vue.http
      .get(`${API_URL}/absence/`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getAttendanceTypesList() {
    return Vue.http
      .get(`${API_URL}/attendance/`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
};
