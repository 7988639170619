<template>
  <page>
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <PageTitle title="Stök námskeið" />
    <PageMenu route="SingleClasses" :selectedPage="page" :items="menu" />
    <Classes v-if="page === 'classes'" />
    <Certificates v-if="page === 'certificate'" />
  </page>
</template>

<script>
import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import PageTitle from '@/components/common/PageTitle.vue';
import PageMenu from '@/components/common/PageMenu.vue';

import Classes from '@/components/curriculum/singleClasses/Classes.vue';
import Certificates from '@/components/curriculum/singleClasses/Certificates.vue';

export default {
  name: 'single-classes',
  components: {
    Breadcrumbs,
    PageTitle,
    PageMenu,
    Classes,
    Certificates,
  },
  data() {
    return {
      page: 'classes',
      breadcrumbs: [
        { name: 'Forsíða', route: 'Dashboard' },
        { name: 'Námskrá', route: '' },
        { name: 'Stök námskeið', route: 'SingleClasses' },
      ],
      menu: [
        {
          name: 'Námskeið',
          page: 'classes',
        },
        {
          name: 'Viðurkenningarskjöl',
          page: 'certificate',
        },
      ],
    };
  },
  methods: {},
  beforeRouteUpdate(to, from, next) {
    if (to && to.query && to.query.page) {
      this.page = to.query.page;
    }
    next();
  },
  created() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
  },
};
</script>
