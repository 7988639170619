<template>
  <page>
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <b-row class="breadcumb-location">
      <b-col>
        <div class="float-right">
          <b-btn variant="dark" @click="openSearchSlidebar" class="ml-1"> <i class="fa fa-exchange" /> Skipta um braut </b-btn>
        </div>
        <div class="float-right">
          <b-link :to="{ name: 'Courses', query: searchQuery }">
            <b-btn variant="dark" class="ml-1"> <i class="fa fa-arrow-left" /> Til baka í leit </b-btn>
          </b-link>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="pl-0">
        <div>
          <i
            class="fa fa-ban mr-2"
            style="font-size: 32px; color: #f26175"
            v-if="!loading.course && !course.gildi"
            v-b-tooltip
            title="Ath. eftirfarandi braut er óvirk"
          ></i>
          <PageTitle :title="course.heiti" class="d-inline-block" style="font-size: 26px" />
          <PageTitle :title="course.braut_kodi" style="font-size: 16px; margin-top: -45px; margin-bottom: 10px" />
        </div>
      </b-col>
    </b-row>
    <PageMenu route="Course" :selectedPage="page" :items="menu" />

    <CourseDetails v-if="page === 'details'" :item="course" :id="id" @refresh="loadCourse()" />
    <CourseStructureList v-if="page === 'structure' && course" :courseId="id" />
    <CourseModules v-if="page === 'modules' && course" :courseId="id" :courseName="course.heiti" />
    <CourseTerms v-if="page === 'terms' && course" :courseId="id" />
    <course-search-slidebar @closeSlidebar="closeSlidebar" v-if="showSearchSlidebar" :large="true"> </course-search-slidebar>
  </page>
</template>

<script>
import curriculum from '@/api/curriculum';

import { mapActions, mapGetters } from 'vuex';

import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import PageTitle from '@/components/common/PageTitle.vue';
import PageMenu from '@/components/common/PageMenu.vue';

import CourseSearchSlidebar from '@/components/curriculum/courses/CourseSearchSlidebar.vue';
import CourseStructureList from '@/components/curriculum/courses/CourseStructureList.vue';
import CourseModules from '@/components/curriculum/courses/CourseModules.vue';
import CourseDetails from '@/components/curriculum/courses/CourseDetails.vue';
import CourseTerms from '@/components/curriculum/courses/CourseTerms.vue';

export default {
  name: 'module',
  components: {
    Breadcrumbs,
    PageTitle,
    PageMenu,
    CourseSearchSlidebar,
    CourseStructureList,
    CourseModules,
    CourseDetails,
    CourseTerms,
  },
  computed: {
    canRead() {
      return this.loggedInUserHasReadPermission('namskra_brautir');
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_brautir');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolHasSettings']),
  },
  data() {
    return {
      id: '',
      breadcrumbs: [
        { name: 'Forsíða', route: 'Dashboard' },
        { name: 'Námskrá', route: '' },
        { name: 'Brautir', route: 'Courses' },
      ],
      menu: [
        {
          name: 'Stýringar',
          page: 'details',
        },
        {
          name: 'Uppbygging',
          page: 'structure',
        },
        {
          name: 'Áfangar',
          page: 'modules',
        },
        {
          name: 'Kennslutímabil',
          page: 'terms',
        },
      ],
      page: 'details',
      loading: {
        course: false,
      },
      course: '',
      title: '',
      showSearchSlidebar: false,
      searchQuery: '',
    };
  },
  methods: {
    async loadCourse() {
      try {
        this.loading.course = true;
        const response = await curriculum.getCourseById(this.id);
        this.course = response.data.items ? response.data.items[0] : '';
        this.breadcrumbs = [
          { name: 'Forsíða', route: 'Dashboard' },
          { name: 'Skólahald', route: '' },
          { name: 'Brautir', route: 'Courses' },
          { name: this.course.heiti, route: '' },
        ];
        this.title = `${this.course.heiti} - ${this.course.braut_kodi}`;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.course = false;
      }
    },
    openSearchSlidebar() {
      this.showSearchSlidebar = true;
    },
    closeSlidebar(item) {
      if (item && item.id) {
        let query = {};
        if (this.$route.query.page) {
          query.page = this.$route.query.page;
        }
        if (this.$route.query) {
          query = this.$route.query;
        }
        this.$router.push({ name: 'Course', params: { id: item.id }, query });
        this.$router.go();
      }
      this.showSearchSlidebar = false;
      this.loadCourse();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  beforeRouteUpdate(to, from, next) {
    if (to && to.query && to.query.page) {
      this.page = to.query.page;
    }
    next();
  },
  created() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
    if (this.$route.query) {
      this.searchQuery = this.$route.query;
    }
    this.id = this.$route.params.id;
    this.loadCourse();
  },
};
</script>
