<template>
  <div>
    <b-form @submit.prevent="createSubGradeRules" novalidate class="mt-2">
      <b-row>
        <b-col class="pl-0">
          <div class="float-right">
            <b-btn variant="primary" size="sm" @click="addGrade()"><i class="fa fa-plus-circle"></i> Bæta við undireinkunn</b-btn>
            <div>
              <b-form-checkbox v-model="autoAdjustWeight" class="d-inline-block"></b-form-checkbox>
              <div class="d-inline-block">Sjálfvirkt uppfæra vægi</div>
            </div>
          </div>
        </b-col>
      </b-row>
      <div v-for="(grade, idx) in create.grades" :key="idx">
        <b-row>
          <b-col class="pl-0">
            <div v-if="idx === 0 && !isGroup">
              Tegund einkunnar
              <b-btn
                class="btn-xs"
                v-if="canEditStructure"
                variant="primary"
                style="font-size: 13px; margin-top: -10px"
                @click="openGradeTypeSlidebar()"
                ><i class="fa fa-plus-circle"></i>
              </b-btn>
            </div>
            <div v-if="idx === 0 && isGroup">Heiti</div>
            <b-form-group
              :label-for="`tegund_einkunnar${idx}`"
              :state="submitted && errors.has(`tegund_einkunnar${idx}`) ? false : ''"
              :invalid-feedback="errors.first(`tegund_einkunnar${idx}`)"
              v-if="!isGroup"
            >
              <v-select
                :id="`tegund_einkunnar${idx}`"
                :name="`tegund_einkunnar${idx}`"
                v-model="grade.tegund_einkunnar"
                :options="getAvailableGradeTypes(idx)"
                label="heiti"
                :clearable="false"
                style="width: 300px"
                :state="submitted && errors.has(`tegund_einkunnar${idx}`) ? false : ''"
                v-validate="'required'"
                data-vv-as="tegund"
              >
              </v-select>
            </b-form-group>
            <b-form-group
              label-for="heiti"
              :state="submitted && errors.has('heiti') ? false : ''"
              :invalid-feedback="errors.first('heiti')"
              v-if="isGroup"
            >
              <b-form-input
                id="heiti"
                name="heiti"
                v-model="grade.heiti"
                :state="submitted && errors.has(`heiti${idx}`) ? false : ''"
                v-validate="'required'"
                data-vv-as="heiti"
                maxlength="200"
                style="width: 300px"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <div v-if="idx === 0">Vægi</div>
            <div class="d-inline-block">
              <b-form-group
                label-for="vaegi"
                :state="submitted && errors.has(`vaegi${idx}`) ? false : ''"
                :invalid-feedback="errors.first(`vaegi${idx}`)"
              >
                <b-form-input
                  id="vaegi"
                  name="vaegi"
                  v-model="grade.vaegi"
                  :state="submitted && errors.has(`vaegi${idx}`) ? false : ''"
                  data-vv-as="vægi"
                  style="width: 80px"
                >
                </b-form-input>
              </b-form-group>
            </div>
            <div class="d-inline-block ml-4">
              <b-btn variant="primary" @click="removeGrade(idx)" style="margin-top: -10px">
                <i class="fa fa-trash"></i>
              </b-btn>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col class="pl-0">
          <b-form-group
            label="Tegund"
            label-for="reiknud_skrad_stofna"
            :state="submitted && errors.has('reiknud_skrad_stofna') ? false : ''"
            :invalid-feedback="errors.first('reiknud_skrad_stofna')"
          >
            <v-select
              id="reiknud_skrad_stofna"
              name="reiknud_skrad_stofna"
              v-model="create.reiknud_skrad"
              :options="types"
              :clearable="false"
              style="width: 300px"
              :state="submitted && errors.has('reiknud_skrad_stofna') ? false : ''"
              v-validate="'required'"
              data-vv-as="tegund"
            >
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="pl-0">
          <b-form-group
            :state="submitted && errors.has('skali_create') ? false : ''"
            :invalid-feedback="errors.first('skali_create')"
            v-if="!isGroup"
          >
            <label label-for="skali_create">
              Skali
              <b-btn
                class="btn-xs"
                variant="primary"
                style="font-size: 13px; margin-top: -10px"
                v-if="create.skali && canEditStructure"
                @click="openGradeScaleSlidebar()"
                ><i class="fa fa-search"></i>
              </b-btn>
            </label>
            <v-select
              id="skali_create"
              name="skali_create"
              v-model="create.skali"
              :options="gradeScales"
              label="heiti"
              :clearable="false"
              style="width: 300px"
              :state="submitted && errors.has('skali_create') ? false : ''"
              v-validate="'required'"
              data-vv-as="skali"
            >
            </v-select>
          </b-form-group>
          <b-form-group
          :state="submitted && errors.has('athugasemd') ? false : ''"
          :invalid-feedback="errors.first('athugasemd')"
          v-if="!isGroup"
          ><label label-for="athugasemd">
            Athugasemd
            <i class="fa fa-fw fa-info-circle" v-b-tooltip.hover title="Þessi athugasemd birtist ekki nemendum"></i>
          </label>
          <b-form-input
            id="athugasemd"
            name="athugasemd"
            type="text"
            v-model="create.athugasemd"
            :state="submitted && errors.has('athugasemd') ? false : ''"
            data-vv-as="athugasemd"
            maxLength="100"
          >
          </b-form-input>
        </b-form-group>
        </b-col>
      </b-row>
      <br />
      <b-btn variant="primary" class="mr-2" v-if="canEdit" @click="createSubGradeRules"> Stofna </b-btn>
      <b-btn variant="dark" @click="cancel">Hætta við</b-btn>
    </b-form>

    <grade-type-slidebar v-if="showGradeTypeSlidbar" @closeSlidebar="closeSlidebar" @closeAndReloadSlidebar="closeAndReloadSlidebar">
    </grade-type-slidebar>
    <grade-scale-slidebar
      v-if="showGradeScaleSlidebar"
      :id="create.skali ? create.skali.teg_skala : ''"
      @closeSlidebar="closeSlidebar"
    ></grade-scale-slidebar>
  </div>
</template>

<script>
import groups from '@/api/groups';
import types from '@/api/types';
import terms from '@/api/terms';
import { mapActions, mapGetters } from 'vuex';
import GradeTypeSlidebar from '@/components/school/school/types/GradeTypeSlidebar.vue';
import GradeScaleSlidebar from '@/components/school/school/types/GradeScaleSlidebar.vue';

export default {
  name: 'graderule-create-subgrades',
  props: ['termModuleId', 'groupId', 'parentId'],
  components: {
    GradeTypeSlidebar,
    GradeScaleSlidebar,
  },
  computed: {
    canRead() {
      return this.loggedInUserHasReadPermission('skolahald_annir');
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_annir');
    },
    canEditStructure() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolHasSettings']),
  },
  data() {
    return {
      isGroup: false,
      autoAdjustWeight: true,
      create: {
        skali: null,
        reiknud_skrad: { id: 1, label: 'Skráð' },
        grades: [
          {
            tegund_einkunnar: null,
            heiti: '',
            vaegi: 100,
          },
        ],
        athugasemd: '',
      },
      yesNo: [
        { id: 1, label: 'Já' },
        { id: 0, label: 'Nei' },
      ],
      types: [
        { id: 0, label: 'Reiknuð' },
        { id: 1, label: 'Skráð' },
      ],
      submitted: false,
      availableGradeTypes: [],
      gradeScales: [],
      showGradeTypeSlidbar: false,
      showGradeScaleSlidebar: false,
    };
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    getAvailableGradeTypes(idx) {
      const current = this.create.grades[idx].tegund_einkunnar ? this.create.grades[idx].tegund_einkunnar.teg_eink : null;
      const ids = this.create.grades
        .map(x => x.tegund_einkunnar)
        .map(x => x && x.teg_eink)
        .filter(x => x !== current && x !== null);
      return this.availableGradeTypes.filter(x => !ids.includes(x.teg_eink));
    },
    async loadGradeScales() {
      try {
        this.loading = true;
        const response = await types.getGradeScaleTypeList();
        this.gradeScales = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadGradeTypes() {
      try {
        this.loading = true;
        const response = await types.getGradeList({
          tegund: 1,
          annirAfangarId: this.termModuleId,
        });
        this.availableGradeTypes = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    updateGradeWeight() {
      const count = this.create.grades.length;
      this.create.grades.forEach(g => {
        this.$set(g, 'vaegi', Number((1 / count) * 100).toFixed(2));
      });
    },
    addGrade() {
      this.create.grades.push({
        tegund_einkunnar: null,
        heiti: '',
        vaegi: null,
      });
      if (this.autoAdjustWeight) {
        this.updateGradeWeight();
      }
    },
    removeGrade(index) {
      this.create.grades.splice(index, 1);
      if (this.autoAdjustWeight) {
        this.updateGradeWeight();
      }
    },
    async createSubGradeRules() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
          return false;
        });
        try {
          const q = [];
          this.create.grades.forEach(g => {
            if (this.isGroup) {
              const data = {
                heiti: g.heiti,
                teg_einkunnar: this.create.reiknud_skrad.id,
                vegur_upp_i: this.parentId,
                vaegi: g.vaegi ? g.vaegi.toString().replace('.', ',') : null,
                fj_bestu_undirpr: null,
                lagmarkseinkunn: null,
                lokaeinkunn: 0,
                aths: this.create.athugasemd,
              };
              q.push(groups.createGroupGradeRule(this.groupId, data));
            } else {
              const data = {
                tegund_einkunnar: g.tegund_einkunnar.teg_eink,
                reiknud_skrad: this.create.reiknud_skrad.id,
                vegur_upp_i: this.parentId,
                vaegi: g.vaegi ? g.vaegi.toString().replace('.', ',') : null,
                einkunnaskali: this.create.skali.teg_skala,
                fj_bestu_undirpr: null,
                fall_leyft: 0,
                lokaeinkunn: 0,
                rod: null,
                aths: this.create.athugasemd,
              };
              q.push(terms.createModuleGraderule(this.termModuleId, data));
            }
          });
          Promise.all(q)
            .then(() => {
              this.displaySuccess('Einkunnareglur hafa verið stofnaðar');
              this.$emit('done');
            })
            .catch(e => {
              if (e.response && e.response.data && e.response.data.error) {
                this.displayError(e.response.data.error);
              } else {
                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
              }
            });
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.saving = false;
      }
    },
    openGradeTypeSlidebar() {
      this.showGradeTypeSlidbar = true;
      this.showGradeScaleSlidebar = false;
    },
    openGradeScaleSlidebar() {
      this.showGradeTypeSlidbar = false;
      this.showGradeScaleSlidebar = true;
    },
    closeSlidebar() {
      this.showGradeTypeSlidbar = false;
      this.showGradeScaleSlidebar = false;
    },
    closeAndReloadSlidebar() {
      this.closeSlidebar();
      this.loadGradeTypes();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    if (this.groupId) {
      this.isGroup = true;
    } else {
      this.isGroup = false;
      this.loadGradeScales();
      this.loadGradeTypes();
    }
  },
};
</script>
<style lang="scss"></style>
