<template>
  <div>
    <table class="table">
      <thead>
        <tr class="table-search">
          <td :colspan="slidebar ? 3 : 3">
            <b-form-input
              v-model="search.search"
              class="search"
              v-on:keyup.enter="loadList"
              placeholder="Leita eftir númeri, heiti, tegund eða byggingu"
              ref="focusInput"
            />
          </td>
          <td colspan="1">
            <b-btn variant="primary" class="w-100" @click="loadList">Leita</b-btn>
          </td>
        </tr>
        <tr v-if="firsttime">
          <th><LinkOrder title="Númer" :filteredList="list" :list="list" column="stofa_eink" /> ({{ list.length }})</th>
          <th>
            <LinkOrder title="Heiti stofu" :filteredList="list" :list="list" column="stofa_heiti" />
          </th>
          <th>
            <LinkOrder title="Tegund stofu" :filteredList="list" :list="list" column="teg_stofu" />
          </th>
          <th>
            <LinkOrder title="Bygging" :filteredList="list" :list="list" column="bygging" />
          </th>
          <th>
            <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="cursor-pointer float-right">
              <i class="fa fa-file-excel-o excelText"></i>
            </download-csv>
          </th>
        </tr>
      </thead>
      <tbody>
        <TableRowLoader v-if="loading && firsttime" :colspan="slidebar ? 4 : 4" :center="true" />
        <TableRowEmpty v-if="!loading && firsttime && this.list.length === 0" :colspan="slidebar ? 4 : 4" :center="true" />
        <TableRowSearchForResults v-if="!firsttime" :colspan="slidebar ? 4 : 4" :center="true" />
        <tr v-for="item in getPaginatedList" :key="item.stofa_id">
          <td>
            <b-link @click="changeClassroom(item)" title="Velja stofu" v-b-tooltip>
              {{ item.stofa_eink }}
            </b-link>
          </td>
          <td>{{ item.stofa_heiti }}</td>
          <td>{{ item.teg_stofu }}</td>
          <td>{{ item.bygging }}</td>
          <td></td>
        </tr>
      </tbody>
    </table>

    <Pagination
      :currentPage="currentPage"
      :totalRows="list.length"
      :perPage="perPage"
      v-if="list.length > 25"
      @change-page="changePage"
      @change-limit="changeLimit"
    />
  </div>
</template>

<script>
import moment from 'moment';
import structure from '@/api/structure';
import { mapGetters, mapActions } from 'vuex';

import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import TableRowSearchForResults from '@/components/common/TableRowSearchForResults.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';
import Pagination from '@/components/common/Pagination.vue';

export default {
  name: 'classroom-search',
  components: {
    TableRowLoader,
    TableRowEmpty,
    TableRowSearchForResults,
    LinkOrder,
    Pagination,
  },
  props: ['slidebar'],
  computed: {
    getExcelData() {
      const newLine = [
        [],
        ['Stofur'],
        [],
        ['Einkenni', 'Heiti', 'Tegund', 'Bygging'],
        ...this.list.map(x => [x.stofa_eink, x.stofa_heiti, x.teg_stofu, x.bygging]),
      ];
      return newLine;
    },
    getExcelFileName() {
      return `stofur_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    rows() {
      return this.list.length;
    },
    getPaginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.list.slice(start, end);
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      perPage: 25,
      currentPage: 1,
      firsttime: false,
      loading: false,
      list: [],
      search: {
        search: '',
        active: 1,
      },
      selectedItem: {},
    };
  },
  methods: {
    async loadList() {
      try {
        this.firsttime = true;
        this.loading = true;
        const response = await structure.getClassroomList(this.search);
        this.list = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    changeClassroom(item) {
      this.$emit('changeClassroom', item.stofa_id);
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.$nextTick(() => {
      if (this.$refs.focusInput) {
        this.$refs.focusInput.focus();
      }
    });
  },
};
</script>
