<template>
  <Slidebar :autoClose="true" @closeMe="closeSlidebar">
    <h4 v-if="item">Breyta aðstandanda</h4>
    <h4 v-else>Bæta við aðstandanda</h4>
    <div class="alert alert-info p-1" style="font-size: 12px">
      <i class="fa fa-info-circle pr-1"></i>
      <span v-if="aldur < 18"
        >Þegar nemandi er undir 18 ára aldri þarf rofinn "Forráðamaður" að vera "Já" til þess að aðstandandi geti skráð sig inn í Innu og
        fái póst frá kerfinu. </span
      ><span v-else
        >Þegar nemandi er yfir 18 ára aldri þarf rofinn "Aðgangur" að vera "Já" til þess að aðstandandi geti skráð sig inn í Innu og fái
        póst frá kerfinu.
      </span>
    </div>
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <table class="table less-padding">
        <tr v-if="search">
          <td style="width: 20%">Kennitala</td>
          <td>
            <b-form-group
              label-for="kennitala"
              :state="submitted && errors.has('kennitala') ? false : ''"
              :invalid-feedback="errors.first('kennitala')"
            >
              <b-form-input
                id="kennitala"
                name="kennitala"
                v-model="form.kennitala"
                maxLength="10"
                minLength="10"
                v-validate="'required'"
                :state="submitted && errors.has('kennitala') ? false : ''"
                data-vv-as="kennitala"
                @input="searchSsn()"
              >
              </b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tbody v-if="!search">
          <tr>
            <td style="width: 20%">Nafn</td>
            <td>{{ form.kennitala }} {{ form.nafn }}</td>
          </tr>
          <tr>
            <td style="width: 20%">Heimilisfang</td>
            <td>{{ form.postnr_adsetur }}</td>
          </tr>
          <tr>
            <td style="width: 20%">Sími</td>
            <td>
              <b-form-group
                label-for="simi_as"
                :state="submitted && errors.has('simi_as') ? false : ''"
                :invalid-feedback="errors.first('simi_as')"
              >
                <b-form-input
                  id="simi_as"
                  name="simi_as"
                  v-model="form.simi_as"
                  minLength="14"
                  :state="submitted && errors.has('simi_as') ? false : ''"
                  data-vv-as="sími"
                >
                </b-form-input>
              </b-form-group>
            </td>
          </tr>
          <tr>
            <td style="width: 20%">Farsími</td>
            <td>
              <b-form-group
                label-for="simi_gsm"
                :state="submitted && errors.has('simi_gsm') ? false : ''"
                :invalid-feedback="errors.first('simi_gsm')"
              >
                <b-form-input
                  id="simi_gsm"
                  name="simi_gsm"
                  v-model="form.simi_gsm"
                  minLength="14"
                  :state="submitted && errors.has('simi_gsm') ? false : ''"
                  data-vv-as="farsími"
                >
                </b-form-input>
              </b-form-group>
            </td>
          </tr>
          <tr>
            <td style="width: 20%">Netfang</td>
            <td>
              <b-form-group
                label-for="netfang"
                :state="submitted && errors.has('netfang') ? false : ''"
                :invalid-feedback="errors.first('v')"
              >
                <b-form-input
                  id="netfang"
                  name="netfang"
                  v-model="form.netfang"
                  minLength="100"
                  :state="submitted && errors.has('netfang') ? false : ''"
                  data-vv-as="netfang"
                >
                </b-form-input>
              </b-form-group>
            </td>
          </tr>
          <tr>
            <td style="width: 20%">Forráðamaður</td>
            <td>
              <b-form-group
                label-for="forrad_adstand"
                :state="submitted && errors.has('forrad_adstand') ? false : ''"
                :invalid-feedback="errors.first('forrad_adstand')"
              >
                <v-select
                  id="forrad_adstand"
                  name="forrad_adstand"
                  v-model="form.forrad_adstand"
                  :clearable="false"
                  :state="submitted && errors.has('v') ? false : ''"
                  :options="yesNo"
                  data-vv-as="forráðamaður"
                >
                </v-select>
              </b-form-group>
            </td>
          </tr>
          <tr v-if="aldur >= 18">
            <td style="width: 20%">Aðgangur</td>
            <td>
              <b-form-group
                label-for="adgangur"
                :state="submitted && errors.has('adgangur') ? false : ''"
                :invalid-feedback="errors.first('adgangur')"
              >
                <v-select
                  id="adgangur"
                  name="adgangur"
                  v-model="form.adgangur"
                  :clearable="false"
                  :state="submitted && errors.has('v') ? false : ''"
                  :options="yesNo"
                  data-vv-as="aðgangur"
                >
                </v-select>
              </b-form-group>
            </td>
          </tr>
          <tr>
            <td style="width: 20%">Tengsl</td>
            <td>
              <b-form-group
                label-for="tengsl"
                :state="submitted && errors.has('tengsl') ? false : ''"
                :invalid-feedback="errors.first('tengsl')"
              >
                <v-select
                  id="tengsl"
                  name="tengsl"
                  v-model="form.tegund_forr"
                  :clearable="true"
                  :state="submitted && errors.has('tengsl') ? false : ''"
                  :options="relativeTypes"
                  data-vv-as="tengsl"
                  label="heiti"
                >
                </v-select>
              </b-form-group>
            </td>
          </tr>
          <tr>
            <td style="width: 20%">Röðun</td>
            <td>
              <b-form-group
                label-for="rodun"
                :state="submitted && errors.has('rodun') ? false : ''"
                :invalid-feedback="errors.first('rodun')"
              >
                <b-form-input
                  id="rodun"
                  name="rodun"
                  type="number"
                  v-model="form.rodun"
                  :state="submitted && errors.has('rodun') ? false : ''"
                  data-vv-as="rodun"
                >
                </b-form-input>
              </b-form-group>
            </td>
          </tr>
        </tbody>
      </table>
    </b-form>
    <b-btn variant="primary" @click="validateBeforeSubmit()" :disabled="saving">Vista</b-btn>
    <b-btn variant="danger" v-if="item && item.forrad_id" class="float-right" @click="deleteRelative()" :disabled="saving">Eyða</b-btn>
  </Slidebar>
</template>

<script>
import common from '@/api/common';
import students from '@/api/students';
import { mapActions } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';

export default {
  name: 'student-relative-course-slidebar',
  props: ['studentId', 'item', 'aldur'],
  components: {
    Slidebar,
  },
  data() {
    return {
      saving: false,
      submitted: false,
      search: true,
      loading: {
        relativeTypes: false,
      },
      relativeTypes: [],
      form: {
        nafn: '',
        kennitala: '',
        heimili_as: '',
        postnr_as: '',
        postnr_adsetur: '',
        simi_as: '',
        simi_gsm: '',
        netfang: '',
        forrad_adstand: { id: `${this.aldur < '18' ? 1 : 0}`, label: `${this.aldur < '18' ? 'Já' : 'Nei'}` },
        adgangur: { id: 0, label: 'Nei' },
        rodun: '',
        tegund_forr: '',
      },
      yesNo: [
        { id: 1, label: 'Já' },
        { id: 0, label: 'Nei' },
      ],
    };
  },
  methods: {
    async searchSsn() {
      this.form.nafn = '';
      if (this.form.kennitala && this.form.kennitala.length === 10) {
        try {
          const response = await common.searchSsnWithEmail(this.form.kennitala);
          this.form.nafn = response.data.nafn;
          this.form.postnr_adsetur = response.data.heimilisfang_postnumer;
          this.form.heimili_as = response.data.heimilisfang;
          this.form.postnr_as = response.data.postnumer;
          this.form.netfang = response.data.email;
          this.search = false;
        } catch (e) {
          this.$log.error(e);
          if (e.status === 404) {
            this.displayError('Kennitala fannst ekki. Vinsamlegast veljið aðra.');
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      }
    },
    async loadRelativeTypes() {
      try {
        this.loading.relativeTypes = true;
        const response = await common.getRelativeTypes();
        this.relativeTypes = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.relativeTypes = false;
      }
    },
    async initialize() {
      this.search = false;
      this.form.nafn = this.item.nafn;
      this.form.kennitala = this.item.kennitala;
      this.form.postnr_adsetur = this.item.postnr_adsetur;
      this.form.simi_as = this.item.simi_as;
      this.form.simi_gsm = this.item.simi_gsm;
      this.form.netfang = this.item.netfang;
      this.form.forrad_adstand = this.yesNo.find(x => x.id === this.item.forrad_adstand);
      this.form.adgangur = this.yesNo.find(x => x.id === this.item.adgangur);
      this.form.rodun = this.item.rodun;
      await this.loadRelativeTypes();
      this.form.tegund_forr = this.relativeTypes.find(x => x.id === this.item.tegund_forr);
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });
        const item = {
          kennitala: this.form.kennitala,
          nafn: this.form.nafn,
          heimili_as: this.form.heimili_as,
          postnr_as: this.form.postnr_as,
          simi_as: this.form.simi_as,
          simi_gsm: this.form.simi_gsm,
          netfang: this.form.netfang,
          forrad_adstand: this.form.forrad_adstand ? this.form.forrad_adstand.id : 0,
          adgangur: this.form.adgangur ? this.form.adgangur.id : 0,
          rodun: this.form.rodun,
          tegund_forr: this.form.tegund_forr ? this.form.tegund_forr.id : '',
        };

        if (this.item) {
          await students.updateStudentRelative(this.studentId, this.item.forrad_id, item);
          this.displaySuccess('Aðstandanda breytt');
        } else {
          await students.createStudentRelative(this.studentId, item);
          this.displaySuccess('Aðstandandi stofnaður');
        }
        this.$emit('closeAndReloadSlidebar');
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        if (e.response.status === 400 && e.response.data.error) {
          this.displayError(e.response.data.error);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    async deleteRelative() {
      const conf = confirm(`Ertu viss um að þú viljir eyða aðstandanum ${this.item.nafn}?`); // eslint-disable-line
      if (conf) {
        try {
          await students.updateStudentRelative(this.studentId, this.item.forrad_id, { delete: 1 });
          this.displaySuccess('Aðstandanda eytt.');
          this.$emit('closeAndReloadSlidebar');
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    closeSlidebar() {
      this.$emit('closeAndReloadSlidebar');
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.search = true;
    if (this.item) {
      this.initialize();
    } else {
      this.loadRelativeTypes();
    }
  },
};
</script>

<style lang="scss"></style>
