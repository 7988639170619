<template>
  <div>
    <div v-if="showInfo" class="mb-3">
      <p>
        <strong>Nemandi:</strong> {{ info.nafn }} kt. {{ info.kennitala }}
        <span v-if="!afangaskoli && info.bekkur">- {{ info.bekkur }}</span>
      </p>
      <p><strong>Staða nema:</strong> {{ info.stada_nema_heiti }}</p>
      <p><strong>Braut:</strong> {{ info.braut }}</p>
      <p><strong>Umsjónarkennari:</strong> {{ info.umsjonarkennari }}</p>
    </div>
    <div v-if="!loading.applications && applications.length === 0">Engar umsóknir fundust</div>
    <table class="table table-striped less-padding no-border mb-1" style="font-size: 12px" v-if="applications.length > 0">
      <thead>
        <tr>
          <td class="text-center" v-if="canEdit && hasAnyPending">
            <span v-b-tooltip title="Samþykkja" class="fa fa-fw fa-check text-success"></span>
            <b-form-checkbox v-model="selectAll.confirm" @change="changeSelectionAll(0, $event)" />
          </td>
          <td class="text-center" v-if="canEdit && hasAnyPending">
            <span v-b-tooltip title="Hafna" class="fa fa-fw fa-remove text-danger"></span>
            <b-form-checkbox v-model="selectAll.reject" @change="changeSelectionAll(1, $event)" />
          </td>
          <td class="text-center" v-if="canEdit && hasAnyPending">
            <span v-b-tooltip title="Í bið" class="fa fa-user-md text-warning"></span>
            <b-form-checkbox v-model="selectAll.wait" @change="changeSelectionAll(2, $event)" />
          </td>
          <td v-if="canEdit && hasAnyPending">
            Athugasemd <br />
            afgreiðslu
          </td>
          <td>Dags. skráð</td>
          <td>Önn</td>
          <td>Áfangi</td>
          <td v-for="(t, idx) in typeList" :key="`applicationTypes${idx}`" style="max-width: 80px">{{ t.heiti }}</td>
          <td>Staða</td>
          <td>Dags.</td>
          <td v-if="canEdit && hasAnyPending"></td>
        </tr>
      </thead>
      <tr v-for="(item, idx) in applications" :key="`module${idx}`">
        <td class="text-center" v-if="canEdit && hasAnyPending">
          <b-form-checkbox v-if="canEditRecord(item)" v-model="item.confirm" @input="changeSelection(item, 0)" />
        </td>
        <td class="text-center" v-if="canEdit && hasAnyPending">
          <b-form-checkbox v-if="canEditRecord(item)" v-model="item.reject" @input="changeSelection(item, 1)" />
        </td>
        <td class="text-center" v-if="canEdit && hasAnyPending">
          <b-form-checkbox
            v-if="canEditRecord(item)"
            v-model="item.wait"
            :disabled="item.stada_id === 2"
            @input="changeSelection(item, 2)"
          />
        </td>
        <td class="text-center" v-if="canEdit && hasAnyPending">
          <b-form-input
            v-if="canEditRecord(item)"
            v-model="item.afgreidsla_athugasemd"
            style="height: 25px; font-size: 12px; padding: 0px; padding-left: 5px; max-width: 150px"
          ></b-form-input>
        </td>
        <td>{{ item.dags | moment('DD.MM.YYYY') }}</td>
        <td>{{ item.onn_eink }}</td>
        <td style="white-space: nowrap">{{ item.namsgrein_afanganr }}</td>
        <td v-for="(t, idx2) in typeList" :key="`application${idx}-${idx2}`" style="max-width: 200px">
          <yes-no v-if="t.tegund === 0" :item="getResource(t, item)"></yes-no>
          <span v-else>{{ getResource(t, item) }}</span>
        </td>
        <td style="white-space: nowrap">{{ item.stada }}</td>
        <td>{{ item.dags_samt | moment('DD.MM.YYYY') }}</td>
        <td v-if="canEdit && hasAnyPending" style="white-space: nowrap">
          <b-btn variant="primary" v-if="canEditRecord(item)" @click="openEditSlidebar(item)" class="btn-xs" style="font-size: 12px"
            ><i class="fa fa-pencil"></i
          ></b-btn>
          <b-btn variant="danger" v-if="canEditRecord(item)" @click="deleteItem(item)" class="btn-xs ml-2" style="font-size: 12px"
            ><i class="fa fa-trash"></i
          ></b-btn>
        </td>
      </tr>
    </table>
    <b-btn
      variant="primary"
      class="mb-4"
      :disabled="saving || getSelectedItems.length === 0"
      v-if="canEdit && hasAnyPending"
      @click="processRequests()"
    >
      <i class="fa fa-spin fa-spinner" v-if="saving"></i> Staðfesta</b-btn
    >
    <edit-request-slidebar
      v-if="showEditSlidebar"
      :id="selectedItem ? selectedItem.nemandi_serurraedi_id : ''"
      @closeSlidebar="closeSlidebar"
      @closeAndReload="closeAndReloadSlidebar"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import requests from '@/api/requests';
import students from '@/api/students';
import types from '@/api/types';

import YesNo from '@/components/common/YesNo.vue';
import EditRequestSlidebar from '@/components/requests/specialResources/EditRequestSlidebar.vue';

export default {
  name: 'student-special-resource',
  props: ['id', 'showInfo'],
  components: {
    YesNo,
    EditRequestSlidebar,
  },
  computed: {
    getSelectedItems() {
      return this.applications.filter(x => this.canEditRecord(x)).filter(x => x.confirm || x.reject || x.wait);
    },
    hasAnyPending() {
      return this.applications.filter(x => this.canEditRecord(x)).length > 0;
    },
    afangaskoli() {
      return this.schoolSettings && this.schoolSettings.afangaskoli ? this.schoolSettings.afangaskoli === 1 : false;
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('beidnir_serurraedi');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {
      info: '',
      applications: [],
      types: [],
      loading: {
        info: false,
        applications: false,
        types: false,
      },
      selectAll: {
        confirm: false,
        reject: false,
        wait: false,
      },
      saving: false,
      selectedItem: '',
      showEditSlidebar: false,
    };
  },
  methods: {
    canEditRecord(item) {
      return item.stada_id === 0 || item.stada_id === 2;
    },
    changeSelection(item, type) {
      if (type === 0 && item.confirm) {
        this.$set(item, 'confirm', true);
        this.$set(item, 'reject', false);
        this.$set(item, 'wait', false);
      } else if (type === 1 && item.reject) {
        this.$set(item, 'confirm', false);
        this.$set(item, 'reject', true);
        this.$set(item, 'wait', false);
      } else if (type === 2 && item.wait) {
        this.$set(item, 'reject', false);
        this.$set(item, 'confirm', false);
        this.$set(item, 'wait', true);
      }
    },
    changeSelectionAll(type, event) {
      const items = this.applications.filter(x => this.canEditRecord(x));
      if (type === 0) {
        this.selectAll.reject = false;
        this.selectAll.wait = false;
        items.forEach(item => {
          this.$set(item, 'confirm', event);
          this.$set(item, 'reject', false);
          this.$set(item, 'wait', false);
        });
      } else if (type === 1) {
        this.selectAll.confirm = false;
        this.selectAll.wait = false;
        items.forEach(item => {
          this.$set(item, 'confirm', false);
          this.$set(item, 'reject', event);
          this.$set(item, 'wait', false);
        });
      } else if (type === 2) {
        this.selectAll.confirm = false;
        this.selectAll.reject = false;
        items.forEach(item => {
          this.$set(item, 'reject', false);
          this.$set(item, 'confirm', false);
          if (item.stada_id !== 2) {
            this.$set(item, 'wait', event);
          } else {
            this.$set(item, 'wait', false);
          }
        });
      }
    },
    getResource(type, item) {
      const resource = item.tegundir.find(x => x.teg_serurraedi_id === type.teg_serurraedi_id);
      if (type.tegund === 0) {
        if (resource) return 1;
        return 0;
      }
      return resource && resource.text;
    },
    async loadStudentInfo() {
      try {
        this.info = '';
        this.loading.info = true;
        const response = await students.getStudentFewInfo(this.id);
        this.info = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.info = false;
      }
    },
    async loadTypes() {
      try {
        this.loading.types = true;
        const response = await types.getSpecialResourceList({ active: 1 });
        this.typeList = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.types = false;
      }
    },
    async loadStudentApplications() {
      try {
        this.applications = [];
        this.loading.applications = true;
        const response = await students.getStudentSpecialResourceApplications(this.id);
        this.applications = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.applications = false;
      }
    },
    loadData() {
      if (this.showInfo) {
        this.loadStudentInfo();
      }
      this.loadStudentApplications();
    },
    getStatus(item) {
      if (item.confirm) return 1;
      if (item.wait) return 2;
      if (item.reject) return 3;
      return 0;
    },
    async processRequests() {
      try {
        this.saving = true;
        // 0 - nýskráð, 1 - samþykkt, 2 - bið, 3 - hafnað
        const data = this.getSelectedItems.map(x => ({
          id: x.nemandi_serurraedi_id,
          status: this.getStatus(x),
          afgreidsla_athugasemd: x.afgreidsla_athugasemd,
        }));

        await requests.processSpecialResources(data);
        this.displaySuccess('Beiðnir afgreiddar');
        this.loadStudentApplications();
        this.$emit('reload');
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.saving = false;
      }
    },
    async deleteItem(item) {
      try {
        const conf = confirm(`Ert þú viss um að þú viljir eyða beiðninni ? \nEkki verður sendur póstur.`); // eslint-disable-line
        if (conf) {
          await requests.massEditSpecialResources({
            deleted: 1,
            ids: item.nemandi_serurraedi_id.toString(),
          });

          this.displaySuccess('Beiðni eytt');
          this.loadStudentApplications();
          this.$emit('reload');
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    openEditSlidebar(item) {
      this.selectedItem = item;
      this.showEditSlidebar = true;
    },
    closeSlidebar() {
      this.selectedItem = null;
      this.showEditSlidebar = false;
    },
    closeAndReloadSlidebar() {
      this.closeSlidebar();
      this.loadStudentApplications();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    id() {
      this.loadData();
    },
  },
  created() {
    this.loadTypes();
    this.loadData();
  },
};
</script>

<style lang="scss"></style>
