<template>
  <Slidebar :autoClose="false" @closeMe="closeSlidebar" :extraLarge="true">
    <h5 v-if="item">{{ item.namsgrein_afanganr }}</h5>
    <GradeRuleList v-if="termModuleId" :termModuleId="termModuleId" :edit="edit" :id="`GRList${termModuleId}`"></GradeRuleList>
    <h5 v-if="groups.length > 0">Einkunnaregla hóps</h5>
    <div v-for="hopur in groups" :key="hopur.hopur_id">
      <div style="font-size: 17px">Hópur {{ hopur.hopur }}</div>
      <GradeRuleList :groupId="hopur.hopur_id" :edit="false" :id="`GRGroupList${hopur.hopur_id}`"></GradeRuleList>
    </div>
  </Slidebar>
</template>

<script>
import terms from '@/api/terms';
import { mapActions, mapGetters } from 'vuex';
import Slidebar from '@/components/common/Slidebar.vue';

import GradeRuleList from '@/components/school/terms/graderules/GradeRuleList.vue';

export default {
  name: 'graderule-slidebar',
  components: {
    Slidebar,
    GradeRuleList,
  },
  props: {
    termModuleId: {},
    groupId: {},
    edit: {
      default: false,
    },
  },

  computed: {
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolHasSettings']),
  },
  data() {
    return {
      loading: {
        modules: false,
      },
      item: '',
      groups: [],
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async loadModules() {
      try {
        this.loading.module = true;
        const response = await terms.getTermModule(this.termModuleId);
        this.item = response.data;
        if (this.schoolHasSettings('einkunnaregla_hops')) {
          this.groups = this.item.hopar.filter(x => x.hopur_id === this.groupId);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.module = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadModules();
  },
};
</script>

<style lang="scss"></style>
