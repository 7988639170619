<template>
  <Slidebar :autoClose="false" :large="true" @closeMe="closeSlidebar">
    <h4><span v-if="!isEdit">Skrá</span> <span v-if="isEdit">Breyta</span> áfanga</h4>
    <div v-if="!isEdit && studentActive === 0">Nemandi er óvirkur og því ekki hægt að skrá hann í áfanga.</div>
    <!-- <b-row v-if="studentActive === 1">
      <b-col class="pl-0">
        <b-form-group :state="saving && errors.has('selectedTerm') ? false : ''"
          :invalid-feedback="errors.first('onn')">
          <label for="selectedTerm"> Önn </label>
          <v-select id="onn" @input="confirmTerm" name="onn" v-model="selectedTerm" :options="terms" :clearable="false"
            :disabled="moduleStatus.stada_afanga !== 41 || studyRecord.einkunn_til === 0 || studyRecord.fjarvist_til === 0
            || studyRecord.hopar.length > 0" label="label">
          </v-select>
        </b-form-group>
      </b-col>
    </b-row> -->
    <loader v-if="loading.terms"></loader>
    <loader v-if="loading.details"></loader>
    <b-row class="pt-4" v-if="isEdit || (!isEdit && studentActive === 1 && !loading.details)">
      <b-col>
        <!-- @submit.prevent="validateBeforeSubmit" -->
        <b-form novalidate @submit.prevent="postStudentStudyRecord">
          <!-- Vantar loader -->
          <table class="table less-padding">
            <tr>
              <td style="width: 20%">Önn:</td>
              <td>
                <b-form-group
                  :state="saving && errors.has('selectedTerm') ? false : ''"
                  :invalid-feedback="errors.first('onn')"
                  v-b-tooltip
                  :title="`${
                    (isEdit && moduleStatus.cannot_change === 0) ||
                    (isEdit &&
                      moduleStatus.cannot_change === 1 &&
                      moduleStatus.einkunn_til === 0 &&
                      moduleStatus.fjarvist_til === 0 &&
                      !studyRecord.hopar)
                      ? `Gögn eru skráð á áfanga og því ekki hægt að gera breytingar á önn.`
                      : ''
                  }`"
                >
                  <!-- Ekki má færa um önn ef til eru gögn eða áfangi er EKKI í stöðunni staðfestval(41) eða áætlað(40)-->
                  <v-select
                    id="onn"
                    @input="confirmTerm"
                    name="onn"
                    v-model="selectedTerm"
                    :options="terms"
                    :clearable="false"
                    :disabled="
                      (isEdit && moduleStatus.cannot_change === 0) ||
                      (isEdit &&
                        moduleStatus.cannot_change === 1 &&
                        moduleStatus.einkunn_til === 0 &&
                        moduleStatus.fjarvist_til === 0 &&
                        !studyRecord.hopar)
                    "
                    label="label"
                  >
                  </v-select>
                </b-form-group>
              </td>
            </tr>
            <tr>
              <td style="width: 20%">Áfangi:</td>
              <td>
                <div v-if="isEdit">{{ courseDetails.namsgrein_afanganr }}</div>
                <b-form-group
                  :state="saving && errors.has('module') ? false : ''"
                  :invalid-feedback="errors.first('module')"
                  v-if="!loading.modules && !isEdit"
                >
                  <v-select id="module" name="module" v-model="module" :options="modules" :clearable="false" label="name"> </v-select>
                </b-form-group>
                <loader v-if="loading.modules"></loader>
                <b-form-checkbox v-if="module.a_samhlida == 1" v-model="parallelCourse">
                  Bæta samhliða áfanga einnig í feril</b-form-checkbox
                >
              </td>
            </tr>
            <tr>
              <td style="width: 20%">Val:</td>
              <td>
                <b-form-group
                  :state="saving && errors.has('choiceType') ? false : ''"
                  :invalid-feedback="errors.first('type')"
                  v-if="!loading.choiceTypes"
                >
                  <v-select id="choiceType" name="choiceType" v-model="choiceType" :options="choiceTypes" :clearable="false" label="heiti">
                  </v-select>
                </b-form-group>
                <loader v-if="loading.choiceType"></loader>
              </td>
            </tr>
            <tr>
              <td style="width: 20%">
                Forgangur:
                <i
                  class="fa fa-info-circle"
                  v-b-tooltip
                  title="Þegar raðað er sjálfvirkt í áfanga í stundatöfluforriti þá er þessi stilling skoðuð"
                ></i>
              </td>
              <td>
                <b-form-group :state="saving && errors.has('priority') ? false : ''" :invalid-feedback="errors.first('priority')">
                  <v-select id="priority" name="priority" v-model="priority" :clearable="false" :options="yesNo"> </v-select>
                </b-form-group>
              </td>
            </tr>
            <tr>
              <td style="width: 20%">
                Röð vals:
                <i
                  class="fa fa-info-circle"
                  v-b-tooltip
                  title="Stundatöfluforrit skoðar þetta þegar verið er að raða nemendum sjálfvirkt í áfanga/hópa"
                ></i>
              </td>
              <td>
                <b-form-group>
                  <b-form-input
                    id="orderOfSelection"
                    name="orderOfSelection"
                    type="number"
                    v-model="orderOfSelection"
                    maxLength="60"
                    :min="0"
                    data-vv-as="orderOfSelection"
                  ></b-form-input>
                </b-form-group>
              </td>
            </tr>
            <tr v-if="isEdit">
              <td style="width: 20%">Staða</td>
              <td>
                <b-form-group>
                  <label for="moduleStatuses"></label>
                  <b-form-group
                    :state="saving && errors.has('moduleStatus') ? false : ''"
                    :invalid-feedback="errors.first('moduleStatus')"
                    v-if="!loading.courseStructure"
                  >
                    <v-select
                      id="moduleStatus"
                      name="moduleStatus"
                      v-model="moduleStatus"
                      :options="moduleStatuses"
                      :clearable="false"
                      label="heiti"
                    >
                    </v-select>
                  </b-form-group>
                </b-form-group>
              </td>
            </tr>
            <!-- 2-féll / 4-metið / 6-lokið -->
            <tr
              v-if="
                isEdit &&
                moduleStatus &&
                (moduleStatus.stada_afanga === 2 || moduleStatus.stada_afanga === 4 || moduleStatus.stada_afanga === 6)
              "
            >
              <td></td>
              <td style="background-color: #e5e5e5">
                <div class="p-3">
                  <strong class="mb-2">Skrá einkunn og einingar fyrir áfanga.</strong>
                  <b-form-group
                    label-for="einkunn"
                    :state="submitted && errors.has('einkunn') ? false : ''"
                    :invalid-feedback="errors.first('einkunn')"
                    >Einkunn
                    <b-form-input
                      id="einkunn"
                      name="einkunn"
                      v-model="form.einkunn"
                      maxLength="10"
                      v-validate="
                        moduleStatus.stada_afanga === 2 || moduleStatus.stada_afanga === 4 || moduleStatus.stada_afanga === 6
                          ? 'required'
                          : ''
                      "
                      :state="submitted ? false : ''"
                    >
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                    label-for="einingar"
                    :state="submitted && errors.has('einingar') ? false : ''"
                    :invalid-feedback="errors.first('einingar')"
                    >Einingar
                    <b-form-input
                      id="einingar"
                      name="einingar"
                      type="number"
                      min="0"
                      v-model="form.einingar"
                      :state="submitted && errors.has('einingar') ? false : ''"
                      v-validate="
                        moduleStatus.stada_afanga === 2 || moduleStatus.stada_afanga === 4 || moduleStatus.stada_afanga === 6
                          ? 'required'
                          : ''
                      "
                    ></b-form-input>
                  </b-form-group>
                </div>
              </td>
            </tr>
            <tr>
              <td style="width: 20%">
                Nota villutékk:
                <i class="fa fa-info-circle" v-b-tooltip title="Ef það á að hunsa t.d. undanfarareglur þá er sett „Nei“"></i>
              </td>
              <td>
                <b-form-group>
                  <v-select id="errorCheck" name="errorCheck" v-model="errorCheck" :clearable="false" :options="yesNo"> </v-select>
                </b-form-group>
              </td>
            </tr>
            <tr v-if="errorCheck.id === 0">
              <td style="width: 20%">Athugasemd:</td>
              <td>
                <b-form-group>
                  <b-form-input v-model="form.comment" :maxLength="2000" />
                </b-form-group>
              </td>
            </tr>
          </table>
        </b-form>
      </b-col>
    </b-row>
    <div v-if="isEdit && !loading.details" class="p-1" style="font-size: 13px">
      <div
        class="p-1"
        v-if="studyRecord.einkunn_til === 1 || studyRecord.fjarvist_til === 1 || (studyRecord.hopar && studyRecord.hopar.length > 0)"
      >
        <i class="fa fa-exclamation-triangle"></i> Ath. nemandinn er með skráð gögn í þessum áfanga, ef áfanga er eytt þá eyðast þau gögn og
        eru ekki afturkræf s.s. einkunnir, viðvera og fl.
      </div>
      <div v-if="studyRecord.einkunn_til === 1" class="p-1">
        <i
          class="cursor-pointer fa fa-search pl-1"
          @click="openStudyRecordGradesSlidebar()"
          title="Skoða einkunnir nemanda"
          v-b-tooltip
        ></i>
        Einkunnir
      </div>
      <div class="p-1" v-if="studyRecord.fjarvist_til === 1">
        <i
          class="cursor-pointer fa fa-search pl-1"
          title="Skoða fjarvistir nemanda"
          @click="openStudentAttendanceSlidebar()"
          v-b-tooltip
        ></i>
        Fjarvistir
      </div>
      <div class="p-1" v-if="studyRecord.hopar && studyRecord.hopar.length > 0">
        Nemandi er skráður í eftirfarandi hóp/a:
        <div class="p-1" v-for="hopar in studyRecord.hopar" :key="hopar.hopur_id">
          {{ hopar.hopur }}
        </div>
      </div>
      <study-record-grades-slidebar
        v-if="showStudyRecordGradesSlidebar"
        :recordId="studyRecord.ferill_id"
        @closeSlidebar="closeExtraSlidebar"
      />
      <student-attendance-slidebar
        v-if="showStudentAttendanceSlidebar"
        :studentId="studentId"
        :termId="studyRecord.onn_id"
        @closeSlidebar="closeExtraSlidebar"
      />
    </div>
    <div class="pt-2 pb-2" v-if="(!loadingData && !loading.details && isEdit) || (!isEdit && studentActive === 1)">
      <b-btn variant="primary" class="mr-2" @click="postStudentStudyRecord()" v-if="canEdit">
        <span v-if="!isEdit && studentActive === 1">Nýskrá</span>
        <span v-if="isEdit">Breyta</span>
      </b-btn>
      <b-btn variant="dark" @click="closeSlidebar">Hætta</b-btn>
      <b-btn variant="danger" class="float-right" @click="deleteStudentStudyRecord()" v-if="canEdit && isEdit"> Eyða </b-btn>
    </div>
    <div v-if="studentActive === 1" class="pt-1" style="font-size: 14px; padding: 10px 6px">
      Hér eru þeir áfangar sem eru skráðir námsferilinn þessa önn.
    </div>
    <table class="table" style="font-size: 14px" v-if="!isEdit && studentActive === 1 && !loading.details">
      <thead>
        <tr class="table-detail-search">
          <th>Áfangi ({{ items.length }})</th>
          <th>Uppbygging</th>
          <th>Val</th>
          <th>Forgangur</th>
          <th>Röð vals</th>
          <th>Staða</th>
          <th>Einingar</th>
        </tr>
      </thead>
      <div>
        <loader v-if="loading.studentsTermCourses"></loader>
      </div>
      <tbody v-if="!loading.studentsTermCourses">
        <tr v-for="item in items" :key="item.afangi_id">
          <td class="dont-break">{{ item.afangi_audkenni }}</td>
          <td>{{ item.uppbygging_heiti }}</td>
          <td>{{ item.val_teg_heiti }}</td>
          <td>
            <span v-if="item.forgangur === 0">Nei</span>
            <span v-if="item.forgangur === 1">Já</span>
          </td>
          <td>{{ item.val_rod }}</td>
          <td>{{ item.stada }}</td>
          <td>{{ item.afangi_einingar }}</td>
        </tr>
        <tr v-if="items.length === 0">
          <td colspan="100" class="text-center"><i>Engir áfangar skráðir.</i></td>
        </tr>
      </tbody>
    </table>
    <table v-if="!isEdit && studentActive === 1 && !loading.details" class="table" style="font-size: 13px; width: 30%">
      <tbody>
        <tr v-if="summaVal">
          <td>Aðalval</td>
          <td style="text-align: right">{{ summaVal }}</td>
        </tr>
        <tr v-if="summaVaraval">
          <td>Varaval</td>
          <td style="text-align: right">{{ summaVaraval }}</td>
        </tr>
        <tr v-if="summaStada">
          <td style="font-weight: bold">Samtals einingar á önn:</td>
          <td style="text-align: right; font-weight: bold">{{ summaStada }}</td>
        </tr>
      </tbody>
    </table>
  </Slidebar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Slidebar from '@/components/common/Slidebar.vue';
import Loader from '@/components/common/Loader.vue';
import terms from '@/api/terms';
import students from '@/api/students';
import common from '@/api/common';
import StudyRecordGradesSlidebar from '@/components/students/studyRecords/StudyRecordGradesSlidebar.vue';
import StudentAttendanceSlidebar from '@/components/students/attendance/StudentAttendanceSlidebar.vue';

export default {
  name: 'register-course-slidebar',
  props: ['studentId', 'termModuleId', 'studentActive', 'large', 'extraLarge'],
  components: {
    Slidebar,
    Loader,
    StudyRecordGradesSlidebar,
    StudentAttendanceSlidebar,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('nemendahald_nemendur');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      loading: {
        terms: false,
        modules: false,
        courseStructure: false,
        choiceTypes: false,
        studentsTermCourses: false,
        moduleStatuses: false,
      },
      loadingData: false,
      isEdit: false,
      saving: false,
      madeChanges: false,
      showStudyRecordGradesSlidebar: false,
      showStudentAttendanceSlidebar: false,
      showGroupSlidebar: false,
      showGradesSlidebar: false,
      term_items: [],
      items: [],
      term: [],
      terms: [],
      modules: [],
      types: [],
      termSelection: [],
      choiceTypes: [],
      studyRecord: [],
      moduleStatuses: [],
      studentTermCourses: [],
      yesNo: [
        { id: 0, label: 'Nei' },
        { id: 1, label: 'Já' },
      ],
      selectedTerm: '',
      module: '',
      type: '',
      choiceType: '',
      orderOfSelection: '',
      priority: { id: 0, label: 'Nei' },
      errorCheck: { id: 1, label: 'Já' },
      parallelCourse: 0,
      courseDetails: '',
      moduleStatus: '',
      summaStada: '',
      summaVal: '',
      summaVaraval: '',
      form: {
        einkunn: '',
        einingar: '',
        comment: '',
      },
      submitted: false,
    };
  },
  methods: {
    async getStudentDefaultTerm() {
      try {
        this.loading.terms = true;
        this.noTerm = false;
        const response = await students.getStudentDefaultTerm(this.studentId);
        this.term.id = response.data.onn_id;
        this.term.dateFrom = response.data.dags_fra;
        this.term.dateTo = response.data.dags_til;
        this.term.name = response.data.onn_eink;
        this.loadTerms(this.term.id);
      } catch (e) {
        if (e.status === 404) {
          this.noTerm = true;
        } else {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } finally {
        this.loading.terms = false;
      }
    },
    async loadTerms(id) {
      try {
        const params = {};
        if (this.courseDetails.afangi_id) {
          params.courseId = this.courseDetails.afangi_id;
        }
        const response = await terms.getTerms(params);
        this.terms = response.data.items
          .filter(x => (x.onn_id !== parseInt(this.id, 10) && x.is_past === 0 && x.tafla_laest === 0) || x.onn_id === id)
          .map(x => ({
            ...x,
            label: `${x.onn_eink} - ${x.onn_heiti} - ${x.undirsk_heiti}`,
          }));
        if (id) {
          this.selectedTerm = this.terms.find(x => x.onn_id === id);
          this.confirmTerm(); // kalla beint í
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.terms = false;
      }
    },
    async confirmTerm() {
      this.loadingData = true;
      this.loadModules();
      this.loadChoiceType();
      this.loadStudentTerm();
    },
    // sækir áfanga drop down lista
    async loadModules() {
      try {
        this.loading.modules = true;
        const response = await terms.getTermModules(this.selectedTerm.onn_id, { showParallel: 1 });
        this.modules = response.data.items.map(x => ({
          id: x.afangi_id,
          name: `${x.namsgrein_afanganr}, ${x.afanga_heiti}`,
          einingar: x.einingar,
          deild: (x.deild || '').split(','),
          a_samhlida: x.a_samhlida,
          annir_afangar_id: x.annir_afangar_id,
        }));
        // id á áfanga
        // þá getum við notað id á áfanganum
        if (this.modules.length > 0 && !this.isEdit) this.module = this.modules[0];
        if (this.isEdit) {
          this.courseDetails.afangi_onn_id = this.modules.filter(x => x.id === this.courseDetails.afangi_id)[0].annir_afangar_id;
          this.modules = this.modules.filter(x => x.id === this.courseDetails.afangi_id)[0];
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.modules = false;
      }
    },
    async loadChoiceType() {
      // val
      try {
        this.loading.choiceTypes = true;
        const response = await common.getChoiceType();
        this.choiceTypes = response.data.items;
        if (this.choiceTypes.length > 0) this.choiceType = this.choiceTypes[0];
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.choiceTypes = false;
      }
    },
    async loadStudentTerm() {
      try {
        this.loading.studentsTermCourses = true;
        const params = {};
        if (this.selectedTerm) {
          // sendum alltaf inn id - svo verðum að passa að þetta sé ekki mögulegt að sleppa
          params.onn_id = this.selectedTerm.onn_id;
        }
        const response = await students.getStudentStudyRecord(this.studentId, params);
        if (response.data.items.length > 0) {
          this.studentTermCourses = response.data.items;
          this.items = response.data.items[0].afangar.sort((a, b) => a.val_rod - b.val_rod);
          this.orderOfSelection = this.items[this.items.length - 1].val_rod + 1; // röð vals næsta í röð
          if (this.studentTermCourses[0].summa_stada.length > 0) {
            this.summaStada = this.studentTermCourses[0].summa_stada.map(x => x.fj_ein_trep).reduce((a, b) => a + b, 0);
          }
          if (this.studentTermCourses[0].summa_val.length > 0) {
            this.summaVal = this.studentTermCourses[0].summa_val[0].fj_ein_trep;
          }
          if (this.studentTermCourses[0].summa_varaval.length > 0) {
            this.summaVaraval = this.studentTermCourses[0].summa_varaval[0].fj_ein_trep;
          }
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.studentsTermCourses = false;
        this.loadingData = false;
      }
    },
    async loadModuleStatuses() {
      try {
        this.loading.moduleStatuses = true;
        const response = await common.getModuleStatuses();
        this.moduleStatuses = response.data.items.map(x => ({
          ...x,
          cannot_change: x.stada_afanga === 40 || x.stada_afanga === 41 ? 1 : 0,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.moduleStatuses = false;
      }
    },
    async loadDetails() {
      try {
        this.loading.details = true;
        const response = await students.getStudyRecordId(this.studentId, this.termModuleId);
        this.studyRecord = response.data;
        const response2 = await students.getStudyRecordDetails(response.data.ferill_id);
        this.courseDetails = response2.data;
        this.loadTerms(this.courseDetails.onn_id);
        // tengja við drop down lista
        this.selectedTerm = this.terms.filter(x => x.onn_id === response2.data.onn_id)[0];
        this.choiceType = this.choiceTypes.filter(x => x.teg_val_nemenda_id === response2.data.val_teg)[0];
        this.orderOfSelection = response2.data.val_rod;
        this.moduleStatus = this.moduleStatuses.filter(x => x.stada_afanga === response2.data.stada_afanga)[0];
        this.priority = response2.data.forgangur === 0 ? { id: 0, label: 'Nei' } : { id: 1, label: 'Já' };
        if (response2.data.stada_afanga === 1) this.moduleStatuses = this.moduleStatuses.filter(x => x.stada_afanga === 1);
        this.form.einkunn = this.studyRecord.einkunn;
        this.form.einingar = this.studyRecord.einingar_ferill;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`${e.response.data.error ? e.response.data.error : `Óvænt villa kom upp. Vinsamlegast reyndu aftur.`}`);
      } finally {
        this.loading.details = false;
      }
    },
    async postStudentStudyRecord() {
      try {
        this.loading.saving = true;
        this.submitted = true;
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });
        const data = {
          afangi_onn_id: this.isEdit ? this.courseDetails.afangi_onn_id : this.module.annir_afangar_id,
          val_teg: this.choiceType.teg_val_nemenda_id,
          val_rod: this.orderOfSelection,
          forgangur: this.priority.id,
          villucheck: this.errorCheck.id,
          aths: this.form.comment ? this.form.comment : '',
          samhlida_afangi: this.parallelCourse,
          namsferill_id: this.isEdit ? this.studyRecord.ferill_id : null,
          stada_id: this.isEdit ? this.moduleStatus.stada_afanga : null, // skiptir bara máli í update
          einkunn:
            this.isEdit &&
            (this.moduleStatus.stada_afanga === 2 || // féll / metið / lokið
              this.moduleStatus.stada_afanga === 4 ||
              this.moduleStatus.stada_afanga === 6)
              ? this.form.einkunn
              : null,
          einingar:
            this.isEdit &&
            (this.moduleStatus.stada_afanga === 2 || // féll / metið / lokið
              this.moduleStatus.stada_afanga === 4 ||
              this.moduleStatus.stada_afanga === 6)
              ? this.form.einingar
              : null,
        };
        await students.postStudentStudyRecord(this.studentId, data);
        if (!this.isEdit) {
          this.displaySuccess(`Áfangi hefur verið skráður í námsferil.`);
          this.madeChanges = true; // til að listinn reloadist fyrir aftan ef það er breyting og slidebar lokaður manually
          this.loadStudentTerm();
        } else {
          this.displaySuccess(`Áfangi hefur verið uppfærður.`);
          this.$emit('closeAndReloadSlidebar');
        }
      } catch (e) {
        this.$log.error(e);
        if (e.response) {
          this.displayError(`${e.response.data.error ? e.response.data.error : `Óvænt villa kom upp. Vinsamlegast reyndu aftur.`}`);
        }
      } finally {
        this.loading.saving = false;
      }
    },
    async deleteStudentStudyRecord() {
      try {
        // eslint-disable-next-line no-restricted-globals
        const conf = confirm(
          'Ertu viss um að þú viljir eyða áfanga úr námsferli? Öllum tengdum gögnum verður eytt svo sem einkunnum og viðveru.',
        );
        this.loading.saving = true;
        if (conf) {
          const data = {
            namsferill_id: this.studyRecord.ferill_id,
            delete: 1,
          };
          await students.postStudentStudyRecord(this.studentId, data);
          this.displaySuccess(`Áfanga hefur verið eytt úr námsferli.`);
          this.$emit('closeAndReloadSlidebar');
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`${e.response.data.error ? e.response.data.error : `Óvænt villa kom upp. Vinsamlegast reyndu aftur.`}`);
      } finally {
        this.loading.saving = false;
      }
    },
    closeSlidebar() {
      if (this.madeChanges) {
        this.$emit('closeAndReloadSlidebar');
      } else {
        this.$emit('closeSlidebar'); // viljum refresha ef breyting varð.
      }
    },
    closeExtraSlidebar() {
      this.showStudentAttendanceSlidebar = false;
      this.showStudyRecordGradesSlidebar = false;
    },
    closeAndReloadSlidebar() {
      this.$emit('closeAndReloadSlidebar');
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
    openStudyRecordGradesSlidebar() {
      this.showStudyRecordGradesSlidebar = true;
      this.showStudentAttendanceSlidebar = false;
    },
    openStudentAttendanceSlidebar() {
      this.showStudentAttendanceSlidebar = true;
      this.showStudyRecordGradesSlidebar = false;
    },
  },
  created() {
    if (this.termModuleId) {
      this.isEdit = true;
      this.loadDetails();
      this.loadChoiceType(); // val
      this.loadModuleStatuses(); // staða áfanga
    } else {
      this.isEdit = false;
      // aðeins sækja virka önn ef virkur nemandi
      if (this.studentActive === 1) {
        this.getStudentDefaultTerm();
      }
    }
  },
};
</script>
