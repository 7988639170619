import Vue from 'vue';

const API_URL = '/api/applications';

export default {
  getApplicationDetail(applicationSchoolId) {
    return Vue.http
      .get(`${API_URL}/detail/${applicationSchoolId}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getApplicationCommunication(applicationSchoolId) {
    return Vue.http
      .get(`${API_URL}/communication/${applicationSchoolId}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getApplicationAttahments(applicationId) {
    return Vue.http
      .get(`${API_URL}/${applicationId}/attachments`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getApplicantCurrentStudies(applicationId) {
    return Vue.http
      .get(`${API_URL}/${applicationId}/applicant-current-studies`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getApplicantGratuations(applicationId) {
    return Vue.http
      .get(`${API_URL}/${applicationId}/gratuations`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getApplicantRelatives(applicationId) {
    return Vue.http
      .get(`${API_URL}/${applicationId}/relatives`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getApplicantGuardians(applicationId) {
    return Vue.http
      .get(`${API_URL}/${applicationId}/guardians`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
};
