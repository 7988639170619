<template>
  <div>
    <b-card no-body class="building-card">
      <b-card-header header-tag="header" class="building-card-header" @click="showCollapse = !showCollapse">
        <b-row>
          <b-col> {{ item.name }} ({{ stofur.length }}) </b-col>
          <b-col>
            <i class="fa float-right" :class="{ 'fa-angle-up': showCollapse, 'fa-angle-down': !showCollapse }" />
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body v-if="showCollapse" class="pt-0">
        <Classrooms :building="item" :list="getClassrooms" @reload="reloadList" />
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Classrooms from '@/components/school/school/buildingsclassrooms/Classrooms.vue';

export default {
  name: 'building',
  components: {
    Classrooms,
  },
  props: ['item', 'search'],
  computed: {
    stofur() {
      return this.item.stofur.filter(x => x.virk === 1);
    },
    getClassrooms() {
      return this.item.stofur.filter(x => !this.search.canOrder || x.ma_panta === this.search.canOrder.id);
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      showCollapse: false,
    };
  },
  methods: {
    reloadList() {
      this.$emit('reload');
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>

<style lang="scss">
@import '../../../../style/variables';
.building-card {
  box-sizing: border-box;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  margin-bottom: 13px;
}

.building-card-header {
  background-color: #ffffff;
  border-bottom: none;
  font-weight: bold;
  height: 45px;
  cursor: pointer;
  padding-left: 10px;
}

// Toggle show icon
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
</style>
