<template>
  <div>
    <b-row>
      <b-col class="pl-0 pr-0">
        <b-form-input v-model="search.name" class="search" placeholder="Leita" />
      </b-col>
      <b-col cols="4">
        <div class="d-inline-block">
          Má panta stofu
          <v-select id="maPanta" name="maPanta" v-model="search.canOrder" :options="yesNo" :clearable="true"> </v-select>
        </div>
        <div class="float-right mt-3">
          <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="d-inline">
            <CustomButton type="excel" />
          </download-csv>
          <CustomButton type="create" title="Ný bygging" @click.native="openSlidebar()" v-if="canEdit" />
        </div>
      </b-col>
    </b-row>
    <Building v-for="item in getList" :key="item.building_id" :item="item" :search="search" @reload="loadList" />
    <building-slidebar v-if="showSlidebar" @closeSlidebar="closeSlidebar"> </building-slidebar>
  </div>
</template>

<script>
import moment from 'moment';
import structure from '@/api/structure';
import { mapActions, mapGetters } from 'vuex';

import CustomButton from '@/components/common/CustomButton.vue';
import BuildingSlidebar from '@/components/school/school/buildingsclassrooms/BuildingSlidebar.vue';
import Building from '@/components/school/school/buildingsclassrooms/Building.vue';

export default {
  name: 'buildings',
  components: {
    Building,
    CustomButton,
    BuildingSlidebar,
  },
  computed: {
    getList() {
      return this.list
        .filter(
          x =>
            this.search.name.length === 0 ||
            (x.name && x.name.toUpperCase().startsWith(this.search.name.toUpperCase())) ||
            (x.stofur.length > 0 &&
              x.stofur.find(
                y =>
                  (y.stofa_heiti || '').toUpperCase().startsWith(this.search.name.toUpperCase()) ||
                  y.stofa_eink.toUpperCase().startsWith(this.search.name.toUpperCase()),
              )),
        )
        .filter(
          x => !this.search.canOrder || (x.stofur.length > 0 && x.stofur.find(y => y.virk === 1 && y.ma_panta === this.search.canOrder.id)),
        );
    },
    getExcelData() {
      const newList = [[], ['Byggingar og stofur'], [], ['Heiti byggingar'], ...this.getList.map(x => [x.name])];
      return newList;
    },
    getExcelFileName() {
      return `byggingar_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },

    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      loading: false,
      search: {
        name: '',
        canOrder: '',
      },
      list: [],
      showSlidebar: false,
      selectedItem: {},
      yesNo: [
        { id: 1, label: 'Já' },
        { id: 0, label: 'Nei' },
      ],
    };
  },
  methods: {
    async loadList() {
      try {
        this.loading = true;
        const response = await structure.getBuildingList();
        this.list = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    openSlidebar() {
      this.showSlidebar = true;
    },
    closeSlidebar() {
      this.showSlidebar = false;
      this.loadList();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadList();
  },
};
</script>

<style lang="scss"></style>
