<template>
  <div>
    <div class="mb-2" style="margin-top: -10px">
      <b-link @click="details = !details">
        Útskýringar <i class="fa fa-info-circle"></i>
        <i class="fa ml-2" :class="{ 'fa-angle-up': !details, 'fa-angle-down': details }" />
      </b-link>
    </div>
    <div v-if="details" class="mb-3" style="font-size: 13px">
      <p>
        Reikna út mætingareinkunn skv. einkunnaskala fyrir alla virka nemendur skólans.
        <span v-if="schoolWithGrades && !afangaskoli"
          >Hægt er að setja mismunandi áfanga á nemendur eftir ári með því að tiltaka ár nemanda í svæðinu Ár nemanda.</span
        >
      </p>
      <p>
        Mætingareinkunn er gefin skv. hlutfalli frá og til í einkunnaskalanum. Ef nemandi hefur 98% mætingu þá fær hann einkunn úr skalanum
        þar sem hlutfall frá er minna eða jafnt 98% en hlutfall til meira eða jafnt 98%. Einingarnar sem tilgreindar eru í einkunnaskalanum
        eru þær einingar sem nemandinn fær fyrir áfangann. Ef svæðið Fall á önn í einkunnaskalanum hefur gildið Já þá eru allir áfangar sem
        hafa einkunn undir 7 merktir sem Fall á önn. Ef áfanginn er ekki til fyrir á ferli þá er hann settur inn á ferilinn.
      </p>
      <strong
        >Hlutfall einkunnaskala sem skólasóknin fer eftir á að hafa tvo aukastafi. Dæmi: 0 - 79,99, 80 - 81,99 o.s.frv. Ef hlutfallið hefur
        enga aukastafi geta sumir nemendur lent í því að fá enga mætingareinkunn, þ.e. þeir sem lenda á milli tveggja hlutfalla. Ef
        hlutfallið er 0 - 79, 80 - 81 þá fær nemandi með mætingarhlutfall 79,41 enga mætingareinkunn.
      </strong>
    </div>
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <b-row>
        <b-col class="pl-0">
          <b-form-group
            :state="(submitted && errors.has('module')) || (selected.module && !selected.termModuleId) ? false : ''"
            :invalid-feedback="errors.first('module')"
          >
            <label label-for="module">
              Mætingaráfangi
              <i
                class="fa fa-info-circle"
                title="Áfanginn sem verður lesinn í feril nemenda. Hérna birtast bara þeir áfangar sem eru
                skilgreindir sem skólasóknaráfangar."
                v-b-tooltip
              ></i>
            </label>
            <v-select
              id="module"
              name="module"
              v-model="selected.module"
              :options="modules"
              label="namsgrein_afanganr"
              :clearable="false"
              v-validate="'required'"
              data-vv-as="áfangi"
              :state="(submitted && errors.has('module')) || (selected.module && !selected.termModuleId) ? false : ''"
              @input="checkAvailable()"
            >
            </v-select>
            <div v-if="selected.module && !selected.termModuleId && !loading.available">
              <div class="text-danger">Ath. Valinn áfangi er ekki í boði á kennslutímabilinu.</div>
              <b-btn variant="primary" v-if="canEdit" @click="addToTermModules()">Setja áfanga í boði á kennslutímabilinu</b-btn>
            </div>
          </b-form-group>
          <b-form-group :state="submitted && errors.has('scale') ? false : ''" :invalid-feedback="errors.first('scale')">
            <label label-for="scale">
              Einkunnaskali
              <i class="fa fa-info-circle" title="Einkunnaskalinn sem verður notaður til að finna einkunn útfrá mætingu." v-b-tooltip></i>
              <i class="fa fa-search cursor-pointer ml-2" title="Skoða skala" v-b-tooltip @click="openGradeScaleSlidebar()"></i>
            </label>
            <v-select
              id="scale"
              name="scale"
              v-model="selected.scale"
              :options="gradeScale"
              label="heiti"
              :clearable="false"
              v-validate="'required'"
              data-vv-as="skali"
            >
            </v-select>
          </b-form-group>
          <b-form-group
            label="Reikna samkvæmt"
            label-for="type"
            :state="submitted && errors.has('type') ? false : ''"
            :invalid-feedback="errors.first('type')"
          >
            <b-form-radio-group id="type" v-model="selected.type" name="type" stacked>
              <b-form-radio value="1">Mæting % <i class="fa fa-info-circle" :title="$t('maeting')" v-b-tooltip></i></b-form-radio>
              <b-form-radio value="2">Raunmæting % <i class="fa fa-info-circle" :title="$t('raunMaeting')" v-b-tooltip></i></b-form-radio>
              <!--b-form-radio value="3">Kennslutímabil %
              <i class="fa fa-info-circle" :title="$t('maetingOnn')" v-b-tooltip></i></b-form-radio-->
              <b-form-radio
                value="4"
                :disabled="!hasAbsencePercentage"
                v-b-tooltip
                :title="
                  !hasAbsencePercentage ? `Í stýringu skóla þurfa að vera skráð frádráttarmörk forfalla svo að hægt sé að velja þetta.` : ''
                "
                >Til mætingareinkunnar %
                <i class="fa fa-info-circle" :title="hasAbsencePercentage ? $t('tilMaetingareinkunnar') : ''" v-b-tooltip></i
              ></b-form-radio>
              <!--b-form-radio value="5">Fjarvistastig</b-form-radio-->
            </b-form-radio-group>
          </b-form-group>
          <div v-if="schoolWithGrades">
            <div class="d-inline-block mr-3">
              <b-form-group :state="submitted && errors.has('date_from') ? false : ''" :invalid-feedback="errors.first('date_from')">
                <label label-for="date_from">
                  Tímabil frá
                  <i
                    class="fa fa-info-circle"
                    title="Fylla út ef tímabilið er öðruvísi en dagsetningarnar á kennslutímabilinu."
                    v-b-tooltip
                  ></i>
                </label>
                <datepicker
                  class="datepicker_100_width"
                  id="date_from"
                  name="date_from"
                  v-model="selected.date_from"
                  :language="lang"
                  :monday-first="true"
                  format="dd.MM.yyyy"
                  :typeable="false"
                  :class="{ 'is-invalid': submitted && errors.has('date_from') }"
                  data-vv-as="tímabil frá"
                  ref="dateFromOpenPickr"
                ></datepicker>
              </b-form-group>
            </div>
            <div class="d-inline-block">
              <b-form-group :state="submitted && errors.has('date_to') ? false : ''" :invalid-feedback="errors.first('date_to')">
                <label label-for="date_to"> Tímabil til </label>
                <datepicker
                  class="datepicker_100_width"
                  id="date_to"
                  name="date_to"
                  v-model="selected.date_to"
                  :language="lang"
                  :monday-first="true"
                  format="dd.MM.yyyy"
                  :typeable="false"
                  :class="{ 'is-invalid': submitted && errors.has('date_to') }"
                  data-vv-as="tímabil til"
                  ref="dateToOpenPickr"
                ></datepicker>
              </b-form-group>
            </div>
          </div>
          <!--b-form-group
            label="Netfang"
            label-for="email"
            :state="submitted && errors.has('email') ? false : ''"
            :invalid-feedback="errors.first('email')"
          >
            <b-form-input id="email" name="email" v-model="selected.email" data-vv-as="netfang"></b-form-input>
          </b-form-group-->
        </b-col>
        <b-col>
          <b-form-group
            label="Staða nema"
            label-for="status"
            :state="submitted && errors.has('status') ? false : ''"
            :invalid-feedback="errors.first('status')"
          >
            <v-select
              id="status"
              name="status"
              v-model="selected.status"
              :options="statuses"
              label="heiti"
              :clearable="true"
              data-vv-as="staða"
            >
            </v-select>
          </b-form-group>
          <b-form-group
            label="Nemandi virkur"
            label-for="active"
            :state="submitted && errors.has('active') ? false : ''"
            :invalid-feedback="errors.first('active')"
          >
            <v-select id="active" name="active" v-model="selected.active" :options="yesNo" :clearable="false" data-vv-as="virkur">
            </v-select>
          </b-form-group>
          <div v-if="schoolWithGrades && !afangaskoli">
            <b-form-group
              label="Ár nemanda"
              label-for="year"
              :state="submitted && errors.has('year') ? false : ''"
              :invalid-feedback="errors.first('year')"
            >
              <v-select id="year" name="year" v-model="selected.year" :options="years" label="heiti" :clearable="true" data-vv-as="ár">
              </v-select>
            </b-form-group>
          </div>

          <b-form-group
            label="Notendahópur"
            label-for="group"
            :state="submitted && errors.has('group') ? false : ''"
            :invalid-feedback="errors.first('group')"
          >
            <v-select
              id="group"
              name="group"
              v-model="selected.group"
              :options="groups"
              label="heiti"
              :clearable="true"
              data-vv-as="notendahópur"
            >
            </v-select>
          </b-form-group>
          <div>
            <b-form-group
              label="Vinnslustaða"
              label-for="processingStatus"
              :state="submitted && errors.has('processingStatus') ? false : ''"
              :invalid-feedback="errors.first('processingStatus')"
            >
              <v-select
                id="processingStatus"
                name="processingStatus"
                v-model="selected.processingStatus"
                :options="processingStatuses"
                label="heiti"
                :clearable="true"
                data-vv-as="vinnslustaða"
              >
              </v-select>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
      <b-btn variant="primary" class="mt-2" @click="validateBeforeSubmit()" :disabled="!selected.termModuleId || !canEdit"
        >Útbúa mætingareinkunn</b-btn
      >
    </b-form>
    <div class="mt-4">
      <b-link @click="showSpecialAttendance = !showSpecialAttendance">
        Nemendur með sérstaka mætingareinkunn
        <i class="fa ml-2" :class="{ 'fa-angle-up': !showSpecialAttendance, 'fa-angle-down': showSpecialAttendance }" />
      </b-link>
    </div>
    <div v-if="showSpecialAttendance" class="mt-2 mb-3">
      <div>Nemandi þarf að vera með merkt við "sérstök mætingareinkunn" á nemandaspjaldinu.</div>
      <div>Mætingareinkunnin er ekki reiknuð heldur er sérstaklega skilgreind í stýringu skólans undir einkunnir.</div>
      Einkunnin sem nemandi fær er:
      {{ schoolSettings.sjalfg_meink_frjals_maeting }}
      <i v-if="!schoolSettings.sjalfg_meink_frjals_maeting">Ekki skráð</i>
    </div>
    <student-special-attendance :id="id" v-if="showSpecialAttendance && id" :termId="id"></student-special-attendance>

    <div>
      <b-link @click="showBatchJobs = !showBatchJobs">
        Seinustu runuvinnslur
        <i class="fa ml-2" :class="{ 'fa-angle-up': !showBatchJobs, 'fa-angle-down': showBatchJobs }" />
      </b-link>
    </div>
    <batchjobs v-if="showBatchJobs" :refresh="refreshJobs" :term="this.id" :type="'AttendanceGrade'"></batchjobs>

    <grade-scale-slidebar
      v-if="showGradeScaleSlidebar"
      :id="selected.scale ? selected.scale.teg_skala : ''"
      @closeSlidebar="closeSlidebar"
    ></grade-scale-slidebar>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import types from '@/api/types';
import users from '@/api/users';
import terms from '@/api/terms';
import classes from '@/api/classes';
import students from '@/api/students';
import curriculum from '@/api/curriculum';

import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';

import StudentSpecialAttendance from '@/components/students/student/StudentSpecialAttendance.vue';
import GradeScaleSlidebar from '@/components/school/school/types/GradeScaleSlidebar.vue';
import Batchjobs from '@/components/common/Batchjobs.vue';

export default {
  name: 'attendance-grades',
  props: ['id', 'divisionId', 'termPassed'],
  components: {
    Datepicker,
    StudentSpecialAttendance,
    GradeScaleSlidebar,
    Batchjobs,
  },
  computed: {
    canRead() {
      return this.loggedInUserHasReadPermission('skolahald_annir');
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_annir') && !this.termPassed;
    },
    hasAbsencePercentage() {
      return this.schoolSettings && this.schoolSettings.forfoll_prosenta !== null;
    },
    afangaskoli() {
      return this.schoolSettings && this.schoolSettings.afangaskoli ? this.schoolSettings.afangaskoli === 1 : false;
    },
    ...mapGetters([
      'loggedInUserHasReadPermission',
      'loggedInUserHasWritePermission',
      'schoolHasSettings',
      'schoolWithGrades',
      'loggedInUser',
      'schoolSettings',
    ]),
  },
  data() {
    return {
      lang: is,
      details: false,
      submitted: false,
      showSpecialAttendance: false,
      showGradeScaleSlidebar: false,
      loading: {
        modules: false,
        term: false,
        gradeScale: false,
        statuses: false,
        groups: false,
        processingStatuses: false,
        available: false,
        years: false,
      },
      modules: [],
      gradeScale: [],
      statuses: [],
      groups: [],
      processingStatuses: [],
      years: [],
      yesNo: [
        { id: 1, label: 'Já' },
        { id: 0, label: 'Nei' },
      ],
      selected: {
        termModuleId: null,
        module: null,
        scale: null,
        type: 1,
        status: null,
        group: null,
        active: null,
        processingStatus: null,
        email: '',
        year: '',
        dateFrom: '',
        dateTo: '',
      },
      showBatchJobs: false,
      refreshJobs: false,
    };
  },
  methods: {
    async loadModules() {
      try {
        this.loading.modules = true;
        const search = {
          attendance: 1,
          active: 1,
          showAll: 1,
          division: this.divisionId,
        };
        const response = await curriculum.getModuleList(search);
        this.modules = response.data.items;
        if (this.modules.length > 0) {
          this.selected.module = this.modules[0];
          this.checkAvailable();
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.modules = false;
      }
    },
    async loadGradeScale() {
      try {
        this.loading.gradeScale = true;
        const response = await types.getGradeScaleTypeList();
        this.gradeScale = response.data.items;
        if (this.gradeScale.length > 0) {
          this.selected.scale = this.gradeScale[0];
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.gradeScale = false;
      }
    },
    async loadStatuses() {
      try {
        this.loading.statuses = true;
        const response = await students.getStudentsStatuses();
        this.statuses = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.statuses = false;
      }
    },
    async loadProcessingStatuses() {
      try {
        this.loading.processingStatuses = true;
        const response = await types.getProcessingStatusList();
        this.processingStatuses = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.processingStatuses = false;
      }
    },
    async loadUserGroups() {
      try {
        this.loading.groups = true;
        const response = await users.getUserGroupsList({ active: 1 });
        this.groups = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.groups = false;
      }
    },
    async loadYears() {
      try {
        this.loading.years = true;
        const response = await classes.getSchoolYears();
        this.years = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.years = false;
      }
    },
    async checkAvailable() {
      try {
        this.loading.available = true;
        if (this.selected.module) {
          const response = await terms.getTermModules(this.id, {
            moduleId: this.selected.module.afangi_id,
            showAll: 1,
          });

          this.selected.termModuleId = response.data.items.length === 1 ? response.data.items[0].annir_afangar_id : '';
        } else {
          this.selected.termModuleId = '';
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.available = false;
      }
    },
    async addToTermModules() {
      try {
        const data = {
          onn_id: this.id,
          afangi_id: this.selected.module.afangi_id,
        };
        await terms.updateTermModule(0, data);
        this.displaySuccess('Áfangi settur í boði');
        this.checkAvailable();
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.saving = false;
      }
    },
    async validateBeforeSubmit() {
      try {
        const data = {
          termModuleId: this.selected.termModuleId,
          scaleId: this.selected.scale.teg_skala,
          status: this.selected.status ? this.selected.status.eink_stodu : null,
          year: this.selected.year ? this.selected.year.skolaar_id : null,
          dateFrom: this.selected.date_from ? moment(this.selected.date_from).format('DD.MM.YYYY') : '',
          dateTo: this.selected.date_to ? moment(this.selected.date_to).format('DD.MM.YYYY') : '',
          email: this.selected.email,
          active: this.selected.active ? this.selected.active.id : 1,
          userGroup: this.selected.group ? this.selected.group.hopur_id : null,
          processingStatus: this.selected.processingStatus ? this.selected.processingStatus.teg_vinnslust_nema_id : null,
          type: this.selected.type,
        };
        await terms.createAttendanceGrades(this.id, data);
        this.displaySuccess('Vinnsla hefur verið send í runuvinnslu');
        this.refreshJobs = true;
        this.showBatchJobs = true;

        setTimeout(() => {
          this.refreshJobs = false;
        }, 1000);
      } catch (e) {
        // Aðgerð mistókst.
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    openGradeScaleSlidebar() {
      this.showGradeScaleSlidebar = true;
    },
    closeSlidebar() {
      this.showGradeScaleSlidebar = false;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  /* mounted() {
    if (this.loggedInUser && this.loggedInUser.netfang) {
      this.selected.email = this.loggedInUser.netfang;
    }
  }, */
  created() {
    this.selected.active = this.yesNo[0];
    this.loadModules();
    this.loadGradeScale();
    this.loadStatuses();
    this.loadUserGroups();
    this.loadYears();
    this.loadProcessingStatuses();
  },
};
</script>
