<template>
  <div>
    <h5 class="course-title">
      Grunnupplýsingar
      <b-link @click="openEditSlidebar('info')" v-if="canEdit"
        ><i class="fa fa-pencil" v-b-tooltip.hover title="Gera breytingar"></i
      ></b-link>
      <b-link class="float-right" @click="deleteCourse()" v-if="canEdit"
        ><i class="fa fa-trash" v-b-tooltip.hover title="Eyða braut"></i
      ></b-link>
    </h5>
    <b-row class="course-settings-border">
      <b-col class="pl-0">
        <div>
          <label>Heiti brautar</label>
          <div class="text">
            <span>{{ item.heiti }}</span>
          </div>
        </div>
        <div>
          <label
            >Kóði brautar
            <i
              class="fa fa-info-circle"
              v-b-tooltip
              title="Kóði brautar er líkt og kennitala, það geta ekki margar brautir verið með sama kóða.
                      Fjöldi stafa í kóða má mest vera 10."
            ></i
          ></label>
          <div class="text">
            <span>{{ item.braut_kodi }}</span>
          </div>
        </div>
        <div>
          <label>Virk</label>
          <div class="text">
            <span><yes-no :item="item.gildi"></yes-no></span>
          </div>
        </div>
      </b-col>
      <b-col>
        <div>
          <label>Undirskóli</label>
          <div class="text">
            <span>{{ item.undirsk_heiti }}</span>
          </div>
        </div>
        <div>
          <label>Deild</label>
          <div class="text">
            <span>{{ item.deild }}</span>
          </div>
        </div>
        <div>
          <label
            >Kjarna áfangar sjálfvirkt í námsferil
            <i
              class="fa fa-info-circle"
              v-b-tooltip
              title="Ef valið þá fara kjarna áfangar í uppbyggingu brautar
                    sjálfvirkt á þau kennslutímabil sem ekki eru hafin."
            ></i
          ></label>
          <div class="text">
            <span><yes-no :item="item.sjalfvirkt_val"></yes-no></span>
          </div>
        </div>
      </b-col>
      <b-col>
        <div>
          <label>Fjöldi eininga</label>
          <div class="text">
            <span>{{ item.einingar_alls }}</span>
          </div>
        </div>
        <div v-if="!isPrimarySchool">
          <label>Samræmd braut <span v-if="!isLearningCenter">- Eldri námskrá</span></label>
          <div class="text">
            <span>{{ item.samraemd_braut_heiti }} {{ item.samraemd_braut_kodi }}</span>
          </div>
        </div>
        <div v-if="!isPrimarySchool && !isLearningCenter">
          <label
            >Samræmd braut - Ný námskrá
            <i class="fa fa-info-circle" v-b-tooltip title="Braut þarf að vera tengd til að hún birtist rétt í gögnum MMS"></i>
          </label>
          <div class="text">
            <span>{{ item.prog_title }}</span>
          </div>
        </div>
      </b-col>
      <b-col>
        <div>
          <label
            >Athugasemd
            <i
              class="fa fa-info-circle"
              v-b-tooltip
              title="Þessi athugasemd sést bara þegar stjórnandi er að skoða brautina í námsskránni."
            ></i
          ></label>
          <div class="text fullHeight">
            <span>{{ item.aths }}</span>
          </div>
        </div>
      </b-col>
    </b-row>
    <div v-if="!isPrimarySchool">
      <h5 class="course-title">
        Umsóknir
        <b-link @click="openEditSlidebar('application')" v-if="canEdit"
          ><i class="fa fa-pencil" v-b-tooltip.hover title="Gera breytingar"></i
        ></b-link>
      </h5>
      <b-row class="course-settings-border">
        <b-col class="pl-0">
          <div>
            <label>Má sækja um í menntagátt</label>
            <div class="text">
              <span><yes-no :item="item.innrit_menntagatt"></yes-no></span>
            </div>
          </div>
          <div>
            <label>Má innrita nema á námsbraut </label>
            <div class="text">
              <span><yes-no :item="item.innrit_lokad_allar"></yes-no></span>
            </div>
          </div>
        </b-col>
        <b-col>
          <div>
            <label>Má sækja um á umsóknarvef </label>
            <div class="text">
              <span><yes-no :item="item.innrit_lokad_nyjar"></yes-no></span>
            </div>
          </div>
          <div>
            <label>Má sækja um brautaskipti </label>
            <div class="text">
              <span><yes-no :item="item.innrit_brautaskipti"></yes-no></span>
            </div>
          </div>
        </b-col>
        <b-col>
          <div>
            <label
              >Umsóknarfrestur runninn út
              <i class="fa fa-info-circle" v-b-tooltip title="Þennan dag er braut ekki lengur sýnileg í Menntagátt"></i>
            </label>
            <div class="text">
              <span>{{ item.dags_lokad | moment('DD.MM.YYYY') }}</span>
            </div>
          </div>
          <div>
            <label
              >Fjöldi nýnema
              <i class="fa fa-info-circle" v-b-tooltip title="Fjöldi nýnema úr Menntagátt sem má innrita á brautina í miðlægri keyrslu"></i>
            </label>
            <div class="text">
              <span>{{ item.fj_nynema }}</span>
            </div>
          </div>
        </b-col>
        <b-col>
          <div>
            <label
              >Nám á ensku
              <i
                class="fa fa-info-circle"
                v-b-tooltip
                title="Ef þetta er valið þá birtist breski fáninn fyrir aftan brautina á umsóknarvefnum."
              ></i
            ></label>
            <div class="text">
              <span><yes-no :item="item.nam_a_ensku"></yes-no></span>
            </div>
          </div>
          <div>
            <label
              >Athugasemd á rafrænni umsókn
              <i class="fa fa-info-circle" v-b-tooltip title="Athugasemd sést bæði á Menntagátt og umsóknarvef þegar braut er valin"></i
            ></label>
            <div class="text">
              <span>{{ item.aths_rafr_umsokn }}</span>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <h5 class="course-title">
      Val
      <b-link @click="openEditSlidebar('choice')" v-if="canEdit"
        ><i class="fa fa-pencil" v-b-tooltip.hover title="Gera breytingar"></i
      ></b-link>
    </h5>
    <b-row class="course-settings-border">
      <b-col class="pl-0">
        <div>
          <label
            >Hámark eininga á kennslutímabili
            <i class="fa fa-info-circle" v-b-tooltip title="Hámarksfjöldi eininga sem nemendur mega velja á kennslutímabili"></i>
          </label>
          <div class="text">
            <span>{{ item.einingar_max_onn }}</span>
          </div>
        </div>
      </b-col>
      <b-col>
        <div>
          <label
            >Hámarksfjöldi kennslustunda á viku
            <i class="fa fa-info-circle" v-b-tooltip title="Hámark hversu margar kennslustundir nemendur mega skrá sig í á viku."></i
          ></label>
          <div class="text">
            <span>{{ item.timar_max_onn }}</span>
          </div>
        </div>
      </b-col>
      <b-col>
        <div>
          <label
            >Lágmarksfjöldi varavals á kennslutímabili
            <i class="fa fa-info-circle" v-b-tooltip title="Ef fyllt út þá verða nemendur að setja í varaval ákveðinn lágmarksfjölda"></i
          ></label>
          <div class="text">
            <span>{{ item.min_varaval }}</span>
          </div>
        </div>
      </b-col>
      <b-col>
        <div>
          <label
            >Hámarksfjöldi varavals á kennslutímabili
            <i class="fa fa-info-circle" v-b-tooltip title="Hámarksfjöldi varavals sem nemendur mega velja á kennslutímabili"></i>
          </label>
          <div class="text">
            <span>{{ item.max_varaval }}</span>
          </div>
        </div>
      </b-col>
    </b-row>
    <h5 class="course-title">
      Skírteini
      <b-link @click="openEditSlidebar('diploma')" v-if="canEdit"
        ><i class="fa fa-pencil" v-b-tooltip.hover title="Gera breytingar"></i
      ></b-link>
    </h5>
    <b-row class="course-settings-border">
      <b-col class="pl-0">
        <div>
          <label>Fj. tíma á kennslutímabili</label>
          <div class="text">
            <span>{{ item.klst_alls }}</span>
          </div>
        </div>
        <div>
          <label>Birta skólasóknaráfanga á skírteini</label>
          <div class="text">
            <span><yes-no :item="item.skolasoknarafangi_a_skirteini"></yes-no></span>
          </div>
        </div>
      </b-col>
      <b-col>
        <strong>Íslenska</strong>
        <div>
          <label>Texti á undan heiti á skírteini</label>
          <div class="text">
            <span>{{ item.texti_skirteini }}</span>
          </div>
        </div>
        <div>
          <label>Heiti á skírteini</label>
          <div class="text">
            <span>{{ item.heiti_skirteini }}</span>
          </div>
        </div>
        <div>
          <label>Texti á eftir heiti á skírteini</label>
          <div class="text">
            <span>{{ item.texti_skirteini2 }}</span>
          </div>
        </div>
        <div>
          <label>Athugasemdir á skírteini</label>
          <div class="text">
            <span>{{ item.aths_skirteini }}</span>
          </div>
        </div>
      </b-col>
      <b-col>
        <strong>Enska</strong>
        <div>
          <label>Heiti (enska)</label>
          <div class="text">
            <span>{{ item.heiti_ens }}</span>
          </div>
        </div>
        <div>
          <label>Texti á undan heiti á skírteini (enska)</label>
          <div class="text">
            <span>{{ item.texti_skirteini_enska }}</span>
          </div>
        </div>
        <div>
          <label>Heiti á skírteini (enska)</label>
          <div class="text">
            <span>{{ item.heiti_skirteini_ens }}</span>
          </div>
        </div>
        <div>
          <label>Texti á eftir heiti á skírteini (enska)</label>
          <div class="text">
            <span>{{ item.texti_skirteini2_enska }}</span>
          </div>
        </div>
        <div>
          <label>Athugasemdir á skírteini (enska)</label>
          <div class="text">
            <span>{{ item.aths_skirteini_ens }}</span>
          </div>
        </div>
      </b-col>
    </b-row>
    <course-edit-slidebar
      v-if="showEditSlidebar"
      @closeSlidebar="closeSlidebar"
      :id="item.braut_id"
      :type="editType"
    ></course-edit-slidebar>
  </div>
</template>

<script>
import curriculum from '@/api/curriculum';
import { mapGetters, mapActions } from 'vuex';

import YesNo from '@/components/common/YesNo.vue';

import CourseEditSlidebar from '@/components/curriculum/courses/CourseEditSlidebar.vue';

export default {
  name: 'course-settings',
  components: {
    YesNo,
    CourseEditSlidebar,
  },
  props: ['item'],
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_brautir');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('namskra_brautir');
    },
    isPrimarySchool() {
      return this.schoolSettings && this.schoolSettings.tegund_skola_id === 3;
    },
    isLearningCenter() {
      return this.schoolSettings && this.schoolSettings.tegund_skola_id === 4;
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      showEditSlidebar: false,
      editType: 'info',
    };
  },
  methods: {
    openEditSlidebar(type) {
      this.editType = type || 'info';
      this.showEditSlidebar = true;
    },
    closeSlidebar() {
      this.showEditSlidebar = false;
      this.$emit('refresh');
    },
    async deleteCourse() {
      const conf = confirm(`Ert þú viss um að eyða brautinni ${this.item.heiti} ${this.item.braut_kodi}?`); // eslint-disable-line
      if (conf) {
        try {
          await curriculum.updateCourse(this.item.braut_id, { delete: 1 });
          this.displaySuccess('Braut eytt.');
          this.$router.push({ name: 'Courses' });
        } catch (e) {
          this.$log.error(e);
          if (e.response && e.response.data && e.response.data.error) {
            this.displayError(e.response.data.error);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
<style lang="scss">
.course-title {
  font-size: 16px;
  background-color: rgba(152, 19, 156, 0.05);
  padding: 5px;
}
.course-settings-border {
  font-size: 13px;
  label {
    text-decoration: underline;
    text-underline-offset: 3px;
    font-size: 15px;
    margin-bottom: 0px;
    display: block;
  }
  .text {
    // height: 15px;
    margin-bottom: 15px;
    span {
      min-width: 50px;
    }
  }
  .btn {
    font-size: 13px;
  }
}
</style>
