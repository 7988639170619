<template>
  <div>
    <div>
      <strong>Stofna lokaeinkunn</strong>
      <!--i class="fa fa-check text-primary" v-if="subgrades"></i-->
      <i
        class="fa fa-fw fa-info-circle"
        v-b-tooltip
        title="Áfanginn verður að hafa lokaeinkunn sem flyst svo yfir í námsferil nemandans í lok áfangans."
      ></i>
    </div>
    <b-form @submit.prevent="validateBeforeSubmit" novalidate class="mt-2" v-if="!subgrades">
      <b-form-group :state="submitted && errors.has('tegund_einkunnar') ? false : ''" :invalid-feedback="errors.first('tegund_einkunnar')">
        <label label-for="tegund_einkunnar">
          Tegund einkunnar
          <b-btn
            class="btn-xs"
            v-if="canEditStructure"
            variant="primary"
            style="margin-top: -10px; margin-bottom: 5px"
            @click="openGradeTypeSlidebar()"
            ><i class="fa fa-plus-circle"></i>
          </b-btn>
        </label>

        <v-select
          id="tegund_einkunnar"
          name="tegund_einkunnar"
          v-model="form.tegund_einkunnar"
          :options="gradeTypes"
          label="heiti"
          :clearable="false"
          style="width: 300px"
          :state="submitted && errors.has('tegund_einkunnar') ? false : ''"
          v-validate="'required'"
        >
        </v-select>
      </b-form-group>
      <b-form-group :state="submitted && errors.has('reiknud_skrad') ? false : ''" :invalid-feedback="errors.first('reiknud_skrad')">
        <label for="reiknud_skrad">
          Tegund
          <i
            class="fa fa-fw fa-info-circle"
            v-b-tooltip.hover.html
            title="Reiknuð: Þá eru margar undireinkunnir sem reiknast upp í.<br/> Skráð: Þá er hægt að skrá einkunn beint á einkunnaþátt."
          ></i>
        </label>
        <v-select
          id="reiknud_skrad"
          name="reiknud_skrad"
          v-model="form.reiknud_skrad"
          :options="types"
          :clearable="false"
          style="width: 300px"
          :state="submitted && errors.has('reiknud_skrad') ? false : ''"
          v-validate="'required'"
        >
        </v-select>
      </b-form-group>
      <b-form-group :state="submitted && errors.has('skali') ? false : ''" :invalid-feedback="errors.first('skali')">
        <label label-for="skali">
          Skali
          <b-btn
            class="btn-xs"
            variant="primary"
            style="margin-top: -10px; margin-bottom: 5px"
            v-if="form.skali && canEditStructure"
            @click="openGradeScaleSlidebar()"
            ><i class="fa fa-search"></i>
          </b-btn>
        </label>
        <v-select
          id="skali"
          name="skali"
          v-model="form.skali"
          :options="gradeScales"
          label="heiti"
          :clearable="false"
          style="width: 300px"
          :state="submitted && errors.has('skali') ? false : ''"
        >
        </v-select>
      </b-form-group>
      <b-form-group :state="submitted && errors.has('fj_bestu_undirpr') ? false : ''" :invalid-feedback="errors.first('fj_bestu_undirpr')">
        <label label-for="fj_bestu_undirpr">
          Fj. bestu undirprófa
          <i class="fa fa-fw fa-info-circle" v-b-tooltip.hover title="Dæmi þá gilda 4 hæstu einkunnirnar af 5 mögulegum."></i>
        </label>
        <b-form-input
          id="fj_bestu_undirpr"
          name="fj_bestu_undirpr"
          type="number"
          min="1"
          v-model="form.fj_bestu_undirpr"
          :state="submitted && errors.has('fj_bestu_undirpr') ? false : ''"
          data-vv-as="fj. bestu undirprófa"
          style="width: 80px"
        >
        </b-form-input>
      </b-form-group>
      <b-form-group :state="submitted && errors.has('rod') ? false : ''" :invalid-feedback="errors.first('rod')">
        <label label-for="rod">
          Röð
          <i class="fa fa-fw fa-info-circle" v-b-tooltip.hover title="Þetta stýrir í hvaða röð einkunnir birtast í einkunnayfirliti."></i>
        </label>
        <b-form-input
          id="rod"
          name="rod"
          type="number"
          min="1"
          v-model="form.rod"
          :state="submitted && errors.has('rod') ? false : ''"
          data-vv-as="röð"
          style="width: 80px"
        >
        </b-form-input>
      </b-form-group>
      <b-form-group :state="submitted && errors.has('athugasemd') ? false : ''" :invalid-feedback="errors.first('athugasemd')">
        <label label-for="athugasemd">
          Athugasemd
          <i class="fa fa-fw fa-info-circle" v-b-tooltip.hover title="Þessi athugasemd birtist ekki nemendum"></i>
        </label>
        <b-form-input
          id="athugasemd"
          name="athugasemd"
          type="text"
          v-model="form.athugasemd"
          :state="submitted && errors.has('athugasemd') ? false : ''"
          data-vv-as="athugasemd"
          maxLength="100"
          style="width: 400px"
        >
        </b-form-input>
      </b-form-group>
      <br />
      <b-btn variant="primary" class="mr-2" v-if="canEdit" @click="validateBeforeSubmit"> Stofna </b-btn>
      <b-btn variant="dark" @click="cancel">Hætta við</b-btn>
    </b-form>
    <div v-if="subgrades"><strong>Stofna undireinkunnir</strong></div>
    <GradeRuleCreateSubgrades
      v-if="subgrades"
      :termModuleId="termModuleId"
      :parentId="id"
      @done="done"
      @cancel="cancel"
    ></GradeRuleCreateSubgrades>
    <grade-type-slidebar v-if="showGradeTypeSlidbar" @closeSlidebar="closeSlidebar" @closeAndReloadSlidebar="closeAndReloadSlidebar">
    </grade-type-slidebar>
    <grade-scale-slidebar
      v-if="showGradeScaleSlidebar"
      :id="form.skali ? form.skali.teg_skala : ''"
      @closeSlidebar="closeSlidebar"
    ></grade-scale-slidebar>
  </div>
</template>

<script>
import types from '@/api/types';
import terms from '@/api/terms';
import { mapActions, mapGetters } from 'vuex';
import GradeRuleCreateSubgrades from '@/components/school/terms/graderules/GradeRuleCreateSubgrades.vue';
import GradeTypeSlidebar from '@/components/school/school/types/GradeTypeSlidebar.vue';
import GradeScaleSlidebar from '@/components/school/school/types/GradeScaleSlidebar.vue';

export default {
  name: 'graderule-create-final',
  props: ['termModuleId'],
  components: {
    GradeRuleCreateSubgrades,
    GradeTypeSlidebar,
    GradeScaleSlidebar,
  },
  computed: {
    canRead() {
      return this.loggedInUserHasReadPermission('skolahald_annir');
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_annir');
    },
    canEditStructure() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolHasSettings']),
  },
  data() {
    return {
      submitted: false,
      subgrades: false,
      id: null,
      form: {
        tegund_einkunnar: null,
        reiknud_skrad: { id: 0, label: 'Reiknuð' },
        skali: null,
        fj_bestu_undirpr: null,
        rod: null,
        athugasemd: '',
      },
      types: [
        { id: 0, label: 'Reiknuð' },
        { id: 1, label: 'Skráð' },
      ],
      gradeTypes: [],
      gradeScales: [],
      showGradeTypeSlidbar: false,
      showGradeScaleSlidebar: false,
    };
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    done() {
      this.$emit('done');
    },
    async loadGradeScales() {
      try {
        this.loading = true;
        const response = await types.getGradeScaleTypeList();
        this.gradeScales = response.data.items;
        this.form.skali = this.gradeScales.find(
          x =>
            x.heiti.toUpperCase().indexOf('HEILTÖLU') > -1 ||
            x.heiti.toUpperCase().indexOf('LOKAEINK') > -1 ||
            x.heiti.toUpperCase().indexOf('10') > -1,
        );
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    openGradeTypeSlidebar() {
      this.showGradeTypeSlidbar = true;
      this.showGradeScaleSlidebar = false;
    },
    openGradeScaleSlidebar() {
      this.showGradeTypeSlidbar = false;
      this.showGradeScaleSlidebar = true;
    },
    closeSlidebar() {
      this.showGradeTypeSlidbar = false;
      this.showGradeScaleSlidebar = false;
    },
    closeAndReloadSlidebar() {
      this.closeSlidebar();
      this.loadGradeTypes();
    },
    async loadGradeTypes() {
      try {
        this.loading = true;
        const response = await types.getGradeList({
          active: 1,
        });
        this.gradeTypes = response.data.items;
        this.form.tegund_einkunnar = this.gradeTypes.find(x => x.heiti.toUpperCase().indexOf('LOKAEINK') > -1);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
          return false;
        });
        try {
          const data = {
            tegund_einkunnar: this.form.tegund_einkunnar.teg_eink,
            reiknud_skrad: this.form.reiknud_skrad.id,
            vegur_upp_i: null,
            vaegi: 100,
            einkunnaskali: this.form.skali.teg_skala,
            fj_bestu_undirpr: this.form.fj_bestu_undirpr,
            fall_leyft: 1,
            lokaeinkunn: 1,
            rod: this.form.rod,
            aths: this.form.athugasemd,
          };
          await terms.createModuleGraderule(this.termModuleId, data);
          this.displaySuccess('Einkunnaregla hefur verið stofnuð.');
          this.done();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.saving = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadGradeScales();
    this.loadGradeTypes();
  },
};
</script>
<style lang="scss"></style>
