<template>
  <page>
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <PageTitle title="Kennslutímabil" />
    <i>
      Smelltu á einkenni kennslutímabils til þess að vinna með tímabils tengdar upplýsingar s.s. námsgagnalista, einkunnareglur, stýringar
      ofl.
    </i>
    <TermSearch></TermSearch>
  </page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import PageTitle from '@/components/common/PageTitle.vue';

import TermSearch from '@/components/school/terms/TermSearch.vue';

export default {
  name: 'terms',
  components: {
    Breadcrumbs,
    PageTitle,
    TermSearch,
  },
  computed: {
    canRead() {
      return this.loggedInUserHasReadPermission('skolahald_annir');
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_annir');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolHasSettings']),
  },
  data() {
    return {
      breadcrumbs: [
        { name: 'Forsíða', route: 'Dashboard' },
        { name: 'Skólahald', route: '' },
        { name: 'Kennslutímabil', route: 'Terms' },
      ],
    };
  },
  methods: {
    closeSlidebar() {
      this.showChangeTypeSlidebar = false;
      this.selectedItem = {};
      this.loadList();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
