<template>
  <div>
    <h5 class="pb-2">Útskriftir</h5>
    <div v-if="graduations.length === 0 && !loading" class="mt-4">Engar útskriftarfærslur fundust hjá nemanda</div>
    <Loader v-if="loading"></Loader>
    <table class="table table-hover less-padding no-border" style="font-size: 13px" v-if="graduations.length > 0 && !loading">
      <thead>
        <tr>
          <th></th>
          <th>Kóði brautar</th>
          <th>Braut</th>
          <th>Dags. útskriftar</th>
          <th>Önn</th>
          <th>Athugasemdir</th>
          <th>Skráð af</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, idx) in graduations" :key="idx">
          <td></td>
          <!--Setja logo sem er eins og blað og hægt að taka úr prófskírteinið-->
          <td>{{ item.braut_kodi }}</td>
          <td>{{ item.heiti }}</td>
          <td>{{ item.dags | moment('DD.MM.YYYY') }}</td>
          <td>{{ item.onn_heiti }}</td>
          <td>{{ item.athugasemd }}</td>
          <td>{{ item.skrad_af }}</td>
          <td>
            <i class="fa fa-pencil float-right cursor-pointer" title="Breyta/eyða útskrift" @click="editGraduationSlidebar(item)"></i>
          </td>
        </tr>
      </tbody>
    </table>
    <student-graduations-slidebar
      v-if="showEditGraduationSlidebar"
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
      :studentId="id"
      :graduationInfo="graduationInfo"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import students from '@/api/students';
import Loader from '@/components/common/Loader.vue';
import StudentGraduationsSlidebar from '@/components/students/student/details/StudentGraduationsSlidebar.vue';

export default {
  name: 'student-graduations',
  components: { StudentGraduationsSlidebar, Loader },
  props: ['id'],
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('nemendahald_nemendur');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('nemendahald_nemendur');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      loading: false,
      list: [],
      showSlidebar: false,
      graduations: [],
      showEditGraduationSlidebar: false,
      graduationId: '',
      graduationInfo: [],
    };
  },
  methods: {
    async loadGraduations() {
      try {
        this.loading = true;
        const response = await students.getStudentGraduations(this.id);
        this.graduations = response.data.items.length > 0 ? response.data.items : '';
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    editGraduationSlidebar(item) {
      this.graduationId = item.utskriftir_id;
      this.graduationInfo = item;
      this.showEditGraduationSlidebar = true;
    },
    closeSlidebar() {
      this.showEditGraduationSlidebar = false;
    },
    closeAndReloadSlidebar() {
      this.closeSlidebar();
      this.loadGraduations();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadGraduations();
  },
};
</script>
<style lang="scss"></style>
