<template>
  <page>
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <b-row class="breadcumb-location">
      <b-col>
        <div class="float-right">
          <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="d-inline">
            <CustomButton type="excel" />
          </download-csv>
        </div>
      </b-col>
    </b-row>
    <PageTitle title="Samráðsfundir" />
    <b-card>
      <h5>Stýringar</h5>
      <b-row>
        <b-col class="pl-0">
          <div>Starfsmenn skrá samráðsfundi</div>
          <b-form-checkbox size="lg" v-model="settings.teachers" :disabled="!canEdit" />
        </b-col>
        <b-col>
          <div>Forráðamenn sækja um frá og með</div>
          <div class="d-inline-block pr-2">
            <datepicker
              id="settings_date_from"
              name="settings_date_from"
              v-model="settings.dateFrom"
              :language="lang"
              :monday-first="true"
              format="dd.MM.yyyy"
              :typeable="false"
              :disabled="!canEdit"
              ref="dateFromSettingsOpenPickr"
            ></datepicker>
          </div>
          <div class="d-inline-block">
            <b-form-input id="date_time_from" name="date_time_from" v-model="settings.timeFrom" :disabled="!canEdit" style="width: 80px" />
          </div>
        </b-col>
        <b-col>
          <div>Forráðamenn sækja um til og með</div>
          <div class="d-inline-block pr-2">
            <datepicker
              id="settings_date_to"
              name="settings_date_to"
              v-model="settings.dateTo"
              :language="lang"
              :monday-first="true"
              format="dd.MM.yyyy"
              :typeable="false"
              :disabled="!canEdit"
              ref="dateToSettingsOpenPickr"
            ></datepicker>
          </div>
          <div class="d-inline-block">
            <b-form-input id="date_time_from" name="date_time_from" v-model="settings.timeTo" :disabled="!canEdit" style="width: 80px" />
          </div>
        </b-col>
        <b-col>
          <b-btn
            variant="primary"
            @click="saveSettings()"
            class="w-100"
            style="margin-top: 25px"
            v-if="canEdit"
            :disabled="!timeFromValid() || !timeToValid()"
            >Vista</b-btn
          >
        </b-col>
      </b-row>
    </b-card>
    <br />
    <b-card>
      <h5>Samráðsfundir</h5>
      <b-row>
        <b-col cols="2" style="padding-left: 6px">
          <div>Dags. frá</div>
          <datepicker
            id="date_from"
            name="date_from"
            v-model="dateFrom"
            :language="lang"
            :monday-first="true"
            format="dd.MM.yyyy"
            :typeable="false"
            ref="dateFromOpenPickr"
            class="datepicker_100_width"
          ></datepicker>
        </b-col>
        <b-col cols="2">
          <div>Dags. til</div>
          <datepicker
            id="date_to"
            name="date_to"
            v-model="dateTo"
            :language="lang"
            :monday-first="true"
            format="dd.MM.yyyy"
            :typeable="false"
            ref="dateFromOpenPickr"
            class="datepicker_100_width"
          ></datepicker>
        </b-col>
        <b-col cols="2">
          <div style="margin-top: 23px">
            <b-btn variant="primary" class="w-100" @click="loadList()">Leita</b-btn>
          </div>
        </b-col>
      </b-row>
      <br />
      <Loader v-if="loading.list" />
      <table class="table" v-else>
        <thead>
          <tr class="table-search">
            <td colspan="8">
              <b-form-input
                v-model="search"
                class="search"
                @input="currentPage = 1"
                style="margin-top: 0px; margin-bottom: 15px"
                placeholder="Leita"
              />
            </td>
          </tr>
          <tr>
            <th class="dont-break">
              <LinkOrder title="Starfsmaður" :filteredList="getList" :list="list" column="starfsmadur" />
              ({{ getList.length }})
            </th>
            <th>
              <LinkOrder title="Dags." :filteredList="getList" :list="list" column="dags" secondColumn="starfsmadur" />
            </th>
            <th>
              <LinkOrder title="Tími frá" :filteredList="getList" :list="list" column="timi_fra" secondColumn="starfsmadur" />
            </th>
            <th>
              <LinkOrder title="Tími til" :filteredList="getList" :list="list" column="timi_til" secondColumn="starfsmadur" />
            </th>
            <th>
              <LinkOrder title="Staðsetning" :filteredList="getList" :list="list" column="stadsetning" secondColumn="starfsmadur" />
            </th>
            <th>
              <LinkOrder title="Nemandi" :filteredList="getList" :list="list" column="nemandi" secondColumn="starfsmadur" />
            </th>
            <th>
              <LinkOrder title="Pantað" :filteredList="getList" :list="list" column="dags_pantad" secondColumn="starfsmadur" />
            </th>
            <th>
              <LinkOrder title="Pantað af" :filteredList="getList" :list="list" column="pantad_af" secondColumn="starfsmadur" />
            </th>
          </tr>
        </thead>
        <tbody>
          <TableRowLoader v-if="loading.list" colspan="8" :center="true" />
          <TableRowEmpty v-if="!loading.list && this.list.length === 0" colspan="8" :center="true" />
          <tr v-for="item in getPaginatedList" :key="item.id">
            <td>{{ item.starfsmadur }}</td>
            <td>{{ item.dags | moment('DD.MM.YYYY') }}</td>
            <td>{{ item.timi_fra }}</td>
            <td>{{ item.timi_til }}</td>
            <td>{{ item.stadsetning }}</td>
            <td>
              {{ item.nemandi }} <span v-if="item.bekkur">({{ item.bekkur }})</span>
            </td>
            <td>{{ item.dags_pantad | moment('DD.MM.YYYY HH:mm:ss') }}</td>
            <td>{{ item.pantad_af }}</td>
          </tr>
        </tbody>
      </table>
      <br />
      <Pagination
        :currentPage="currentPage"
        :totalRows="getList.length"
        :perPage="perPage"
        v-if="list.length > 25"
        @change-page="changePage"
        @change-limit="changeLimit"
      />
    </b-card>
  </page>
</template>

<script>
import requests from '@/api/requests';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';

import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import PageTitle from '@/components/common/PageTitle.vue';
import Loader from '@/components/common/Loader.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';
import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import Pagination from '@/components/common/Pagination.vue';
import CustomButton from '@/components/common/CustomButton.vue';

export default {
  name: 'parent-teacher',
  components: {
    Datepicker,
    Breadcrumbs,
    PageTitle,
    Loader,
    LinkOrder,
    TableRowLoader,
    TableRowEmpty,
    Pagination,
    CustomButton,
  },
  computed: {
    getList() {
      return this.list.filter(
        x =>
          this.search.length === 0 ||
          (x.starfsmadur || '').toUpperCase().indexOf(this.search.toUpperCase()) > -1 ||
          (x.nemandi || '').toUpperCase().indexOf(this.search.toUpperCase()) > -1 ||
          (x.pantad_af || '').toUpperCase().indexOf(this.search.toUpperCase()) > -1,
      );
    },
    getPaginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.getList.slice(start, end);
    },
    getExcelData() {
      const newList = [
        [],
        ['Beiðnir - Samráðsfundir'],
        [],
        ['Starfsmaður', 'Dags', 'Tími frá', 'Tími til', 'Staðsetning', 'Nemandi', 'Bekkur', 'Pantað', 'Pantað af'],
        ...this.getList.map(x => [
          x.starfsmadur,
          moment(x.dags).format('DD.MM.YYYY'),
          x.timi_fra,
          x.timi_til,
          x.stadsetning,
          x.nemandi,
          x.bekkur,
          x.dags_pantad ? moment(x.dags_pantad).format('DD.MM.YYYY') : '',
          x.pantad_af,
        ]),
      ];
      return newList;
    },
    getExcelFileName() {
      return `samradsfundir_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('beidnir_samradsfundir');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      lang: is,
      perPage: 25,
      currentPage: 1,
      search: '',
      loading: {
        settings: false,
        list: false,
      },
      list: [],
      breadcrumbs: [
        { name: 'Forsíða', route: 'Dashboard' },
        { name: 'Nemendahald', route: '' },
        { name: 'Samráðsfundir', route: 'ParentTeacher' },
      ],
      dateFrom: moment().date(1).subtract(2, 'months').toDate(),
      dateTo: moment().date(1).add(2, 'months').toDate(),
      settings: {
        teachers: true,
        dateFrom: '',
        timeFrom: '',
        dateTo: '',
        timeTo: '',
      },
      hourRegex: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
    };
  },
  methods: {
    async loadSettings() {
      try {
        this.loading.settings = true;
        const response = await requests.getParentTeacherSettings();
        this.settings.teachers = response.data.ma_skra_foreldravidtol === 1;
        this.settings.dateFrom = response.data.foreldravidtol_opid_fra ? moment(response.data.foreldravidtol_opid_fra).toDate() : null;
        this.settings.timeFrom = response.data.foreldravidtol_opid_fra ? moment(response.data.foreldravidtol_opid_fra).format('HH:mm') : '';
        this.settings.dateTo = response.data.foreldravidtol_opid_til ? moment(response.data.foreldravidtol_opid_til).toDate() : null;
        this.settings.timeTo = response.data.foreldravidtol_opid_til ? moment(response.data.foreldravidtol_opid_til).format('HH:mm') : '';
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.settings = false;
      }
    },
    async loadList() {
      try {
        this.loading.list = true;
        const response = await requests.getParentTeacherList(
          moment(this.dateFrom).format('DD.MM.YYYY'),
          moment(this.dateTo).format('DD.MM.YYYY'),
        );
        this.list = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.list = false;
      }
    },
    async saveSettings() {
      try {
        const item = {
          teacher_register: this.settings.teachers ? 1 : 0,
          date_from:
            this.settings.dateFrom && this.settings.timeFrom
              ? `${moment(this.settings.dateFrom).format('DD.MM.YYYY')} ${this.settings.timeFrom}`
              : '',
          date_to:
            this.settings.dateTo && this.settings.timeTo
              ? `${moment(this.settings.dateTo).format('DD.MM.YYYY')} ${this.settings.timeTo}`
              : '',
        };
        await requests.saveParentTeacherSettings(item);
        this.displaySuccess('Stýringar vistaðar');
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    timeFromValid() {
      return !this.settings.timeFrom || this.hourRegex.test(this.settings.timeFrom);
    },
    timeToValid() {
      return !this.settings.timeTo || this.hourRegex.test(this.settings.timeTo);
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadSettings();
    this.loadList();
  },
};
</script>
<style lang="scss">
.startDate {
  width: 150px !important;
}
</style>
