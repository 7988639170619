<template>
  <tr>
    <td style="max-width: 140px">{{ item.heiti }}</td>
    <td>{{ item.braut_kodi }}</td>
    <td>{{ item.undirsk_audk }}</td>
    <td v-if="showAll || innrit_menntagatt">
      <span v-if="editing">
        <b-form-input
          :id="`newStudents${item.braut_id}`"
          :name="`newStudents${item.braut_id}`"
          type="number"
          v-model="editInstance.fj_nynema"
          style="max-width: 70px; height: 30px"
        />
      </span>
      <span v-else>
        {{ item.fj_nynema }}
      </span>
    </td>
    <td v-if="showAll" style="max-width: 280px; font-size: 11px">
      <span v-if="editing">
        <b-form-input
          :id="`comment${item.braut_id}`"
          :name="`comment${item.braut_id}`"
          style="font-size: 12px; height: 30px"
          v-model="editInstance.aths_rafr_umsokn"
        ></b-form-input>
      </span>
      <span v-else>
        {{ item.aths_rafr_umsokn }}
      </span>
    </td>
    <td v-if="showAll">
      <span v-if="editing">
        <b-form-checkbox :id="`enrollAll${item.braut_id}`" :name="`enrollAll${item.braut_id}`" v-model="editInstance.innrit_lokad_allar">
        </b-form-checkbox
      ></span>
      <span v-else>
        <yes-no :item="item.innrit_lokad_allar"></yes-no>
      </span>
    </td>
    <td v-if="showAll">
      <span v-if="editing">
        <b-form-checkbox
          :id="`enrollNewStudents${item.braut_id}`"
          :name="`enrollNewStudents${item.braut_id}`"
          v-model="editInstance.innrit_lokad_nyjar"
        ></b-form-checkbox>
      </span>
      <span v-else>
        <yes-no :item="item.innrit_lokad_nyjar"></yes-no>
      </span>
    </td>
    <td v-if="showAll || innrit_brautaskipti">
      <span v-if="editing">
        <b-form-checkbox
          :id="`enrollBrautaskipti${item.braut_id}`"
          :name="`enrollBrautaskipti${item.braut_id}`"
          v-model="editInstance.innrit_brautaskipti"
        ></b-form-checkbox>
      </span>
      <span v-else>
        <yes-no :item="item.innrit_brautaskipti"></yes-no>
      </span>
    </td>
    <td v-if="showAll || innrit_menntagatt">
      <span v-if="editing">
        <b-form-checkbox
          :id="`enrollMenntagatt${item.braut_id}`"
          :name="`enrollMenntagatt{item.braut_id}`"
          v-model="editInstance.innrit_menntagatt"
        >
        </b-form-checkbox
      ></span>
      <span v-else>
        <yes-no :item="item.innrit_menntagatt"></yes-no>
      </span>
    </td>
    <td v-if="showAll || innrit_menntagatt">
      <span v-if="editing">
        <datepicker
          :id="`dateClosed${item.braut_id}`"
          :name="`dateClosed${item.braut_id}`"
          v-model="editInstance.dags_lokad"
          :language="lang"
          :monday-first="true"
          format="dd.MM.yyyy"
          :typeable="false"
          :clear-button="true"
          ref="dateClosedPickr"
          :disabled="!editInstance.innrit_menntagatt"
        ></datepicker>
      </span>
      <span v-else>{{ item.dags_lokad | moment('DD.MM.YYYY') }} </span>
    </td>
  </tr>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import YesNo from '@/components/common/YesNo.vue';

import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';

export default {
  name: 'course-application-item',
  props: ['item', 'editing', 'innrit_menntagatt', 'innrit_brautaskipti'],
  components: {
    YesNo,
    Datepicker,
  },
  computed: {
    showAll() {
      // Hvort eigi að takmarka það sem er sýnt því þetta er slidebar til að breyta bara menntagátt eða brautaskiptum
      return !this.innrit_menntagatt && !this.innrit_brautaskipti;
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_brautir');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('namskra_brautir');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      lang: is,
      editInstance: '',
      test: false,
    };
  },
  methods: {
    hasChanged() {
      return (
        (this.item.fj_nynema || '') !== (this.editInstance.fj_nynema || '') ||
        (this.item.fj_nema_alls || '') !== (this.editInstance.fj_nema_alls || '') ||
        (this.item.aths_rafr_umsokn || '') !== (this.editInstance.aths_rafr_umsokn || '') ||
        (this.item.innrit_lokad_allar === 1) !== this.editInstance.innrit_lokad_allar ||
        (this.item.innrit_lokad_nyjar === 1) !== this.editInstance.innrit_lokad_nyjar ||
        (this.item.innrit_brautaskipti === 1) !== this.editInstance.innrit_brautaskipti ||
        (this.item.innrit_menntagatt === 1) !== this.editInstance.innrit_menntagatt ||
        moment(this.item.dags_lokad).format('YYYYMMDD') !== moment(this.editInstance.dags_lokad).format('YYYYMMDD')
      );
    },
    getData() {
      const item = {};
      if (this.hasChanged()) {
        item.braut_id = this.item.braut_id;
        item.fj_nynema = this.editInstance.fj_nynema;
        item.fj_nema_alls = this.editInstance.fj_nema_alls;
        item.aths_rafr_umsokn = this.editInstance.aths_rafr_umsokn;
        item.innrit_lokad_allar = this.editInstance.innrit_lokad_allar ? 1 : 0;
        item.innrit_lokad_nyjar = this.editInstance.innrit_lokad_nyjar ? 1 : 0;
        item.innrit_menntagatt = this.editInstance.innrit_menntagatt ? 1 : 0;
        item.innrit_brautaskipti = this.editInstance.innrit_brautaskipti ? 1 : 0;
        item.dags_lokad = this.editInstance.dags_lokad ? moment(this.editInstance.dags_lokad).format('DD.MM.YYYY') : '';
      }
      return item;
    },
  },

  watch: {
    editing() {
      this.editInstance = { ...this.item };
      this.editInstance.innrit_lokad_allar = this.item.innrit_lokad_allar === 1;
      this.editInstance.innrit_lokad_nyjar = this.item.innrit_lokad_nyjar === 1;
      this.editInstance.innrit_menntagatt = this.item.innrit_menntagatt === 1;
      this.editInstance.innrit_brautaskipti = this.item.innrit_brautaskipti === 1;
    },
  },
};
</script>
