import Vue from 'vue';

const API_URL = '/api/upload';

export default {
  uploadCurriculum(division, data) {
    return Vue.http
      .post(`${API_URL}/curriculum/${division}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
};
