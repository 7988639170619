<template>
  <page>
    <b-row class="breadcumb-location" v-if="filteredList.length > 0">
      <b-col>
        <div class="float-right">
          <download-csv
            :data="getExcelDataStudents"
            :name="getExcelFileNameStudents"
            delimiter=";"
            class="d-inline mr-3"
            v-if="!loadingStudents"
          >
            <CustomButton type="excel" title="Nemendalisti" />
          </download-csv>
          <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="d-inline">
            <CustomButton type="excel" title="Mætingalisti" />
          </download-csv>
        </div>
      </b-col>
    </b-row>
    <h2 class="mt-3">Hversu margir eru í húsinu?</h2>
    <div>
      Hér er hægt að fá lista yfir fjölda fólks sem á að vera í skólanum á ákveðnum tíma, unnið er út frá tímum sem eru skráðir í
      stundatöflu.
    </div>
    <div>Hægt er að sía eftir staðsetningum til þess að fá betri mynd á því hversu margir eru í hverri byggingu á hverjum tímapunkti.</div>
    <div>
      Ef skóli er einnig með fjarkennslustundir í töflu þá er ein leiðin til að fá ekki upp þá hópa að færa þá í t.d. stofu sem heitir
      "Fjarkennsla" og sía það síðan í burtu.
    </div>
    <div>Birtar eru eftirfarandi upplýsingar</div>
    <ul>
      <li>Fjöldi starfsmanna skráðir á kennslustundina</li>
      <li>Fjöldi nemenda skráða í hópinn</li>
      <li>Fjöldi viðveruskráninga þar sem viðveran fjarvistarstig er minna en 1 þ.e. allt nema fjarvist</li>
    </ul>
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <div>
        <div class="d-inline-block mr-2">
          <strong>Dagsetning</strong>
          <b-form-group
            label-for="searchDate"
            :state="submitted && errors.has('searchDate') ? false : ''"
            :invalid-feedback="errors.first('searchDate')"
          >
            <datepicker
              id="searchDate"
              name="searchDate"
              v-model="search.date"
              :language="lang"
              :monday-first="true"
              format="dd.MM.yyyy"
              :typeable="false"
              v-validate="'required'"
              data-vv-as="dags"
              @change="validated = false"
              :state="submitted && errors.has('searchDate') ? false : ''"
              ref="dateOpenPickr"
            ></datepicker>
          </b-form-group>
        </div>
        <div class="d-inline-block mr-2">
          <strong>Tími</strong>
          <b-form-group
            label-for="searchTime"
            :state="submitted && errors.has('searchTime') ? false : ''"
            :invalid-feedback="errors.first('searchTime')"
          >
            <!--b-form-input
              id="searchTime"
              name="'searchTime"
              placeholder="hh:mm"
              style="padding: 2px; width: 80px; height: 35px;"
              v-model="search.time"
              v-validate="{ required: true, regex: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/ }"
              data-vv-as="tími"
              :state="submitted && errors.has('searchTime') ? false : ''"
              @change="updateTimeTo()"
            /-->

            <masked-input
              id="searchTime"
              name="'searchTime"
              v-model="search.time"
              mask="11:11"
              placeholder="hh:mm"
              style="padding: 2px 2px 2px 2px; width: 60px; height: 35px; border: 1px solid #ced4da"
              v-validate="{ required: true, regex: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/ }"
              data-vv-as="tími"
              :state="submitted && errors.has('searchTime') ? false : ''"
            />
          </b-form-group>
        </div>
        <div class="d-inline-block">
          <b-btn variant="primary" style="margin-top: -5px; height: 35px" :disabled="loading" @click="validateBeforeSubmit()">Leita</b-btn>
        </div>
        <div class="d-inline-block float-right mr-5" v-if="buildings.length > 0">
          Valdar stofur: {{ classroomCount }}
          <b-btn variant="primary" size="sm" class="ml-2" style="padding: 2px 4px; margin-top: -5px" @click="openSlidebar()"
            ><i class="fa fa-pencil"
          /></b-btn>
        </div>
      </div>
    </b-form>
    <br />
    <div v-if="!loading && firsttime" class="mb-3 mb-3">
      <b-row v-if="getDetailedStatistics.length > 0">
        <b-col style="border: 2px solid #ddd">
          <table class="table table-hover no-border less-padding">
            <tr>
              <th>Bygging</th>
              <th class="text-right">Fj. áætlaða starfsmanna</th>
              <th class="text-right">Fj. áætlaða nemenda</th>
              <th class="text-right">Fj. nemendur mættir</th>
              <th class="text-right">Fj. áætlaða alls</th>
            </tr>
            <tr v-for="(b, idx) in getDetailedStatistics" :key="idx">
              <td>{{ b.name }}</td>
              <td class="text-right">{{ b.total_staff }}</td>
              <td class="text-right">{{ b.total_student }}</td>
              <td class="text-right">{{ b.total_attendance }}</td>
              <td class="text-right">{{ b.total_staff + b.total_student }}</td>
            </tr>
            <tfoot>
              <tr style="border-top: 1px solid #ddd">
                <th style="border-top: 3px solid #ddd !important">Alls</th>
                <th style="border-top: 3px solid #ddd !important" class="text-right">{{ totalStaff }}</th>
                <th style="border-top: 3px solid #ddd !important" class="text-right">{{ totalStudents }}</th>
                <th style="border-top: 3px solid #ddd !important" class="text-right">{{ totalAttendance }}</th>
                <th style="border-top: 3px solid #ddd !important" class="text-right">{{ totalSum }}</th>
              </tr>
            </tfoot>
          </table>
        </b-col>
      </b-row>
    </div>

    <table class="table" v-if="firsttime">
      <thead>
        <tr>
          <th><LinkOrder title="Kennslutímabil" :filteredList="list" :list="list" column="onn_eink" /> ({{ filteredList.length }})</th>
          <th>
            <LinkOrder title="Áfangi" :filteredList="list" :list="list" column="namsgrein_afanganr" />
          </th>
          <th>
            <LinkOrder title="Hópur" :filteredList="list" :list="list" column="hopur" />
          </th>
          <th>
            <LinkOrder title="Bygging" :filteredList="list" :list="list" column="bygging" />
          </th>
          <th>
            <LinkOrder title="Stofa/ur" :filteredList="list" :list="list" column="stofa" />
          </th>
          <th>
            <LinkOrder title="Tími frá" :filteredList="list" :list="list" column="timi_fra" />
          </th>
          <th>
            <LinkOrder title="Tími ti" :filteredList="list" :list="list" column="timi_til" />
          </th>
          <th>
            <LinkOrder title="Fj. kennara" :filteredList="list" :list="list" column="fj_starfsm" />
          </th>
          <th>
            <LinkOrder title="Kennari" :filteredList="list" :list="list" column="starfsm_skammst" />
          </th>
          <th>
            <LinkOrder title="Fj. nemenda" :filteredList="list" :list="list" column="fjoldi_i_hop" />
          </th>
          <th>
            <LinkOrder title="Fj. mætinga" :filteredList="list" :list="list" column="fj_vidvera" />
          </th>
        </tr>
      </thead>
      <tbody>
        <TableRowLoader v-if="loading" colspan="11" :center="true" />
        <TableRowEmpty v-if="!loading && this.list.length === 0" colspan="11" :center="true" />
        <tr v-for="(item, index) in filteredList" :key="index">
          <td>{{ item.onn_eink }}</td>
          <td>{{ item.namsgrein_afanganr }}</td>
          <td>{{ item.hopur }}</td>
          <td>{{ item.byggingar }}</td>
          <td>{{ item.stofa }}</td>
          <td>{{ item.timi_fra }}</td>
          <td>{{ item.timi_til }}</td>
          <td>{{ item.fj_starfsm }}</td>
          <td>{{ item.starfsm_skammst }}</td>
          <td>{{ item.fjoldi_i_hop }}</td>
          <td>{{ item.fj_vidvera }}</td>
        </tr>
      </tbody>
    </table>
    <Slidebar @closeMe="closeSlidebar" :large="true" v-if="showSlidebar">
      <h4>Valdar stofur</h4>
      <div>
        <b-btn variant="primary" size="sm" class="mr-3" @click="updateAll(true)">Velja allt</b-btn>
        <b-btn variant="primary" size="sm" @click="updateAll(false)">Velja ekkert</b-btn>
      </div>
      <div v-for="b in buildings" :key="b.building_id">
        <div>
          <strong class="d-inline-block">{{ b.name }}</strong>
          <div class="d-inline-block">
            <b-form-checkbox v-model="b.selected" @input="toggleBuilding(b)" />
          </div>
        </div>
        <div class="pl-3" v-for="s in b.stofur" :key="s.stofa_id">
          <div class="d-inline-block">
            <b-form-checkbox v-model="s.selected" />
          </div>
          <div class="d-inline-block">{{ s.stofa_eink }} - {{ s.stofa_heiti }}</div>
        </div>
      </div>
    </Slidebar>
  </page>
</template>

<script>
import school from '@/api/school';
import structure from '@/api/structure';
import students from '@/api/students';
import moment from 'moment';
import { mapGetters } from 'vuex';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';
import CustomButton from '@/components/common/CustomButton.vue';
import MaskedInput from 'vue-masked-input';

import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';
import Slidebar from '@/components/common/Slidebar.vue';

export default {
  name: 'InHouse',
  components: {
    Datepicker,
    TableRowLoader,
    TableRowEmpty,
    LinkOrder,
    CustomButton,
    Slidebar,
    MaskedInput,
  },
  computed: {
    totalStaff() {
      return this.filteredList
        .map(x => x.fj_starfsm)
        .reduce((a, b) => {
          return a + b;
        }, 0);
    },
    totalStudents() {
      return this.filteredList
        .map(x => x.fjoldi_i_hop)
        .reduce((a, b) => {
          return a + b;
        }, 0);
    },
    totalAttendance() {
      return this.filteredList
        .map(x => x.fj_vidvera)
        .reduce((a, b) => {
          return a + b;
        }, 0);
    },
    totalSum() {
      return this.totalStaff + this.totalStudents;
    },
    classroomCount() {
      let count = 0;
      this.buildings.forEach(b => {
        count += b.stofur.filter(x => x.selected).length;
      });
      return count;
    },
    selectedClassrooms() {
      const ids = [];
      this.buildings.forEach(b => {
        b.stofur
          .filter(x => x.selected)
          .forEach(s => {
            ids.push(s.stofa_id);
          });
      });
      return ids;
    },
    filteredList() {
      return this.list.filter(x => x.stofur.filter(y => this.selectedClassrooms.indexOf(y.stofa_id) > -1).length > 0);
    },
    getExcelData() {
      const newList = [
        [],
        ['Áætluð mæting'],
        [],
        [
          'Kennslutímabil',
          'Áfangi',
          'Hópur',
          'Bygging/ar',
          'Stofa/ur',
          'Tími frá',
          'Tími til',
          'Fjöldi kennara',
          'Kennari',
          'Fjöldi nemenda',
          'Fjöldi mætinga',
        ],
        ...this.filteredList.map(x => [
          x.onn_eink,
          x.namsgrein_afanganr,
          x.hopur,
          x.byggingar,
          x.stofa,
          x.timi_fra,
          x.timi_til,
          x.fj_starfsm,
          x.starfsmenn,
          x.fjoldi_i_hop,
          x.fj_vidvera,
        ]),
      ];
      return newList;
    },
    getExcelFileName() {
      return `aetlud_maeting_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    getExcelFileNameStudents() {
      return `aetlud_maeting_nemendur_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    getExcelDataStudents() {
      const groups = this.filteredList.map(x => x.hopur_id).join(',');
      return this.students
        ? this.students
            .filter(x => groups.indexOf(x.hopur_id) > -1)
            .map(x => ({
              Kennslutímabil: x.onn_eink,
              Áfangi: x.namsgrein_afanganr,
              Hópur: x.hopur,
              Nafn: x.nafn,
              Kennitala: `="${x.kennitala}"`,
              Farsími: x.farsimi,
              Netfang: x.netfang,
              Einkanetfang: x.einkanetfang,
            }))
        : [];
    },
    getDetailedStatistics() {
      const buildings = [];
      this.filteredList.forEach(l => {
        l.stofur.forEach(s => {
          if (buildings.map(x => x.id).indexOf(s.bygging_id) === -1) {
            buildings.push({
              id: s.bygging_id,
              name: s.heiti,
              total_staff: this.filteredList
                .filter(x => x.stofur.filter(y => y.bygging_id === s.bygging_id).length > 0)
                .map(x => x.fj_starfsm)
                .reduce((a, b) => {
                  return a + b;
                }, 0),
              total_student: this.filteredList
                .filter(x => x.stofur.filter(y => y.bygging_id === s.bygging_id).length > 0)
                .map(x => x.fjoldi_i_hop)
                .reduce((a, b) => {
                  return a + b;
                }, 0),
              total_attendance: this.filteredList
                .filter(x => x.stofur.filter(y => y.bygging_id === s.bygging_id).length > 0)
                .map(x => x.fj_vidvera)
                .reduce((a, b) => {
                  return a + b;
                }, 0),
            });
          }
        });
      });
      return buildings;
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {
      regexTime: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
      lang: is,
      search: {
        date: moment().toDate(),
        time: moment().format('HH:mm'),
      },
      firsttime: false,
      list: [],
      loading: false,
      submitted: false,
      buildings: [],
      showSlidebar: false,
      students: [],
      loadingStudents: false,
    };
  },
  methods: {
    async loadBuildings() {
      try {
        const response = await structure.getBuildingList();
        this.buildings = response.data.items;
        this.buildings.forEach(b => {
          this.$set(b, 'selected', true);
          b.stofur.forEach(s => {
            this.$set(s, 'selected', true);
          });
        });
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    toggleBuilding(building) {
      this.buildings
        .find(x => x.building_id === building.building_id)
        .stofur.forEach(s => {
          this.$set(s, 'selected', building.selected);
        });
    },
    updateAll(value) {
      this.buildings.forEach(b => {
        this.$set(b, 'selected', value);
        b.stofur.forEach(s => {
          this.$set(s, 'selected', value);
        });
      });
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });
        this.loading = true;
        this.firsttime = true;
        this.list = [];
        this.students = [];
        const item = {
          dags: moment(this.search.date).format('DD.MM.YYYY'),
          time: this.search.time,
        };
        const response = await school.getCovidSchoolCount(item);
        this.list = response.data.map(x => ({
          ...x,
          stofa: x.stofur.map(y => y.stofa_eink).join(','),
          byggingar: [...new Set(x.stofur.map(y => y.heiti))].join(','),
        }));
        this.loading = false;
        this.loadingStudents = false;
        const groups = this.list.map(x => x.hopur_id).join(',');
        if (groups.length > 0) {
          const studentResponse = await students.getGroupsMany(groups, { dags: moment(this.search.date).format('DD.MM.YYYY') });
          this.students = studentResponse.data.items;
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.loading = false;
        this.loadingStudents = false;
      }
    },
    openSlidebar() {
      this.showSlidebar = true;
    },
    closeSlidebar() {
      this.showSlidebar = false;
    },
  },
  created() {
    this.loadBuildings();
  },
};
</script>
