<template>
  <div>
    <b-card v-if="groups.length > 0 || loading.groups">
      <table class="table table-sticky-header">
        <thead>
          <tr>
            <th>
              <LinkOrder title="Áfangi" :filteredList="groups" :list="groups" column="namsgrein_afanganr" second_column="hopur" />
              <span v-if="!loading.groups">({{ groups.length }})</span>
            </th>
            <th>
              <LinkOrder title="Hópur" :filteredList="groups" :list="groups" column="hopur" second_column="namsgrein_afanganr" />
            </th>
            <th>
              <LinkOrder
                title="Kennari"
                :filteredList="groups"
                :list="groups"
                column="kennarar_skammst"
                second_column="namsgrein_afanganr"
              />
              <i class="fa fa-info-circle" title="Kennarar skráðir á tíma í stundatöflu" v-b-tooltip></i>
            </th>
            <th>
              <LinkOrder title="Dags. frá" :filteredList="groups" :list="groups" column="dags_fra" second_column="namsgrein_afanganr" />
            </th>
            <th>
              <LinkOrder title="Dags. til" :filteredList="groups" :list="groups" column="dags_til" second_column="namsgrein_afanganr" />
            </th>
            <th v-if="!slidebar">
              <LinkOrder
                title="Hámarks fj. nema"
                :filteredList="groups"
                :list="groups"
                column="max_fj_nema"
                second_column="namsgrein_afanganr"
              />
              <i class="fa fa-info-circle" title="Hámarksfjöldi nema sem skráð er í stillingum áfangans" v-b-tooltip></i>
            </th>
            <th v-if="!slidebar">
              <LinkOrder
                title="Fjöldi nema í hóp"
                :filteredList="groups"
                :list="groups"
                column="fjoldi_hops"
                second_column="namsgrein_afanganr"
              />
              <i
                class="fa fa-info-circle"
                title="Fjöldi nema skráðir í hópinn á núverandi dagsetningu þ.e. ekki með liðna úrsögn"
                v-b-tooltip
              ></i>
            </th>
            <th>
              <LinkOrder title="Deild" :filteredList="groups" :list="groups" column="deild_heiti" second_column="namsgrein_afanganr" />
            </th>
            <th v-if="!slidebar">
              <LinkOrder
                v-if="stofntafla"
                title="Forsendur"
                :filteredList="groups"
                :list="groups"
                column="forsendur_sort"
                second_column="namsgrein_afanganr"
              />
              <i class="fa fa-info-circle" title="Kennarar skráðir á hópinn í forsendum" v-b-tooltip></i>

              <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="cursor-pointer float-right">
                <i class="fa fa-file-excel-o excelText"></i>
              </download-csv>
            </th>
          </tr>
        </thead>
        <tbody>
          <TableRowLoader v-if="loading.groups" colspan="9" :center="true" />
          <TableRowEmpty v-if="!loading.groups && this.groups.length === 0" colspan="9" :center="true" />
          <GroupsItem
            v-for="item in getPaginatedList"
            :key="item.hopur_id"
            :item="item"
            :slidebar="slidebar"
            :searchQuery="searchQuery"
            :canEdit="canEdit"
            @changeGroup="changeGroup(item)"
            @reload="loadGroups"
            @reloadGroup="loadGroup(item)"
          ></GroupsItem>
        </tbody>
      </table>

      <Pagination
        :currentPage="currentPage"
        :totalRows="groups.length"
        :perPage="perPage"
        v-if="groups.length > 20"
        @change-page="changePage"
        @change-limit="changeLimit"
      ></Pagination>
    </b-card>
  </div>
</template>

<script>
import moment from 'moment';
import school from '@/api/school';
import staff from '@/api/staff';

import { mapGetters, mapActions } from 'vuex';

import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';
import Pagination from '@/components/common/Pagination.vue';

import GroupsItem from '@/components/students/groups/GroupsItem.vue';

export default {
  name: 'groups-list',
  props: ['load', 'selected', 'slidebar', 'searchQuery', 'canEdit'],
  components: {
    TableRowLoader,
    TableRowEmpty,
    LinkOrder,
    Pagination,
    GroupsItem,
  },
  computed: {
    getPaginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.groups.slice(start, end);
    },
    getExcelData() {
      const newList = [
        [],
        ['Hópar'],
        [`${this.selected.term.undirsk_heiti}-${this.selected.term.onn_heiti}`],
        [],
        [
          'Áfangi',
          'Hópur',
          'Kennari',
          'Dags. frá',
          'Dags. til',
          'Hámarksfjöldi nema',
          'Fjöldi nema í hóp',
          'Deild',
          this.stofntafla ? 'Forsendur' : '',
        ],
        ...this.groups.map(x => [
          x.namsgrein_afanganr,
          `="${x.hopur}"`,
          x.kennarar_skammst,
          moment(x.dags_fra).format('DD.MM.YYYY'),
          moment(x.dags_til).format('DD.MM.YYYY'),
          x.max_fj_nema,
          x.fjoldi_hops,
          x.departments,
          this.stofntafla ? (x.forsendur || []).map(y => y.skammst).join(',') : '',
        ]),
      ];
      return newList;
    },
    getExcelFileName() {
      return `hopar_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    canRead() {
      return this.loggedInUserHasReadPermission('nemendahald_hopar');
    },
    stofntafla() {
      return !this.schoolHasSettings('stundatoflukerfi');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolHasSettings']),
  },
  data() {
    return {
      perPage: 20,
      currentPage: 1,
      loading: {
        groups: false,
        teacher: false,
      },
      groups: [],
      teachers: [],
    };
  },
  methods: {
    async loadStaff() {
      try {
        if (this.teachers.length === 0) {
          this.loading.teacher = true;
          const response = await staff.getStaffList();
          this.teachers = response.data.items;
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.teacher = false;
      }
      this.groups.forEach(g => {
        const teachers = this.teachers
          .filter(x => (g.kennari_forsendur || '').indexOf(x.starfsm_id) > -1)
          .sort((a, b) => a.skammst - b.skammst);
        this.$set(g, 'forsendur', teachers);
        this.$set(g, 'forsendur_sort', teachers.map(x => x.skammst).join(','));
      });
    },
    async loadGroups() {
      try {
        if (this.canRead) {
          this.loading.groups = true;
          this.groups = [];
          this.currentPage = 1;
          const response = await school.getGroups({
            onn_id: this.selected.term.onn_id,
            namsgrein_afanganr: this.selected.module,
            hopur: this.selected.group,
            deild_id: this.selected.department ? this.selected.department.department_id : '',
            showDepartment: 1,
            showTeacherInitials: 1,
            premise: this.stofntafla ? 1 : '',
          });
          this.groups = response.data.items.map(x => ({
            ...x,
            departments: x.deild_heiti ? x.deild_heiti.split(',') : [],
          }));
          if (this.stofntafla) {
            this.loadStaff();
          }
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.groups = false;
      }
    },
    async loadGroup(item) {
      try {
        const response = await school.getGroups({
          hopur_id: item.hopur_id,
          onn_id: this.selected.term.onn_id,
          namsgrein_afanganr: this.selected.module,
          hopur: this.selected.group,
          deild_id: this.selected.department ? this.selected.department.department_id : '',
          showDepartment: 1,
          showTeacherInitials: 1,
          premise: this.stofntafla ? 1 : '',
        });
        const i = this.groups.find(x => x.hopur_id === item.hopur_id);
        if (response.data.items.length > 0) {
          const teachers = this.teachers
            .filter(x => (response.data.items[0].kennari_forsendur || '').indexOf(x.starfsm_id) > -1)
            .sort((a, b) => a.skammst - b.skammst);
          this.$set(i, 'forsendur', teachers);
          this.$set(i, 'forsendur_sort', teachers.map(x => x.skammst).join(','));
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    changeGroup(item) {
      this.$emit('changeGroup', {
        id: item.hopur_id,
        searchQuery: this.searchQuery,
      });
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    load() {
      if (this.load) {
        this.loadGroups();
      }
    },
  },
  created() {
    if (this.load) {
      this.loadGroups();
    }
  },
};
</script>
