<template>
  <Slidebar :autoClose="true" @closeMe="closeSlidebar">
    <h4 v-if="id">Breyta uppbyggingu</h4>
    <h4 v-else>Stofna uppbyggingu</h4>

    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <table class="table less-padding module-create-table">
        <tr>
          <td>Heiti*</td>
          <td>
            <b-form-group
              label-for="heiti"
              :state="submitted && errors.has('heiti') ? false : ''"
              :invalid-feedback="errors.first('heiti')"
            >
              <b-form-input
                id="heiti"
                name="heiti"
                type="text"
                v-model="form.heiti"
                :state="submitted && errors.has('heiti') ? false : ''"
                maxLength="100"
                v-validate="'required'"
                ref="focusInput"
                data-vv-as="heiti"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Heiti (enska)</td>
          <td>
            <b-form-group
              label-for="heiti_ens"
              :state="submitted && errors.has('heiti_ens') ? false : ''"
              :invalid-feedback="errors.first('heiti_ens')"
            >
              <b-form-input
                id="heiti_ens"
                name="heiti_ens"
                type="text"
                v-model="form.heiti_ens"
                :state="submitted && errors.has('heiti_ens') ? false : ''"
                maxLength="100"
                data-vv-as="heiti"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Einingar</td>
          <td>
            <b-form-group
              label-for="einingar"
              :state="submitted && errors.has('einingar') ? false : ''"
              :invalid-feedback="errors.first('einingar')"
            >
              <b-form-input
                id="einingar"
                name="einingar"
                type="number"
                v-model="form.einingar"
                :state="submitted && errors.has('einingar') ? false : ''"
                data-vv-as="einingar"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>
            Tegund*
            <i
              class="fa fa-info-circle"
              v-b-tooltip.hover.html
              title="Kjarni = Áfangar sem nemendur verða að taka. <br/>
              Val = Nemendur geta valið áfangana.<br/>
              Bundið pakkaval = Þá velja nemendur pakka og þurfa að taka alla áfangana í pakkanum. <br/>
              Bundið áfangaval = Þá velja nemendur pakka og þurfa að velja x marga áfanga úr pakkanum."
            >
            </i>
          </td>
          <td>
            <b-form-group
              label-for="tegund"
              :state="submitted && errors.has('tegund') ? false : ''"
              :invalid-feedback="errors.first('tegund')"
            >
              <v-select
                id="tegund"
                name="tegund"
                v-model="form.tegund"
                :clearable="false"
                v-validate="'required'"
                :state="submitted && errors.has('tegund') ? false : ''"
                :options="types"
                data-vv-as="tegund"
                label="heiti"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Röð</td>
          <td>
            <b-form-group label-for="rod" :state="submitted && errors.has('rod') ? false : ''" :invalid-feedback="errors.first('rod')">
              <b-form-input
                id="rod"
                name="rod"
                type="number"
                v-model="form.rod"
                :state="submitted && errors.has('rod') ? false : ''"
                data-vv-as="rod"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
      </table>
      <b-btn variant="primary" @click="validateBeforeSubmit()" :disabled="loading">Vista</b-btn>
      <b-btn variant="danger" @click="deleteStructure()" class="float-right" v-if="id" :disabled="loading">Eyða</b-btn>
    </b-form>
  </Slidebar>
</template>

<script>
import curriculum from '@/api/curriculum';
import { mapGetters, mapActions } from 'vuex';
import Slidebar from '@/components/common/Slidebar.vue';

export default {
  name: 'courses-structure-slidebar',
  components: {
    Slidebar,
  },
  props: ['courseId', 'item'],
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_brautir');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('namskra_brautir');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      loading: false,
      submitted: false,
      id: '',
      form: {
        heiti: '',
        heiti_ens: '',
        tegund: '',
        einingar: '',
        rod: '',
      },
      types: [],
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async loadTypes() {
      try {
        const response = await curriculum.getCourseModuleTypes();
        this.types = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });
        const item = {
          ...this.form,
          braut_id: this.courseId,
          tegund: this.form.tegund.id,
        };
        if (this.id) {
          await curriculum.editCourseStructure(this.id, item);
          this.displaySuccess('Uppbyggingu breytt');
        } else {
          await curriculum.createCourseStructure(item);
          this.displaySuccess('Uppbygging stofnuð');
        }

        this.$emit('closeAndReloadSlidebar');
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);

        if (e.response && e.response.data && e.response.data.error) {
          this.displayError(e.response.data.error);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    async deleteStructure() {
      try {
        await curriculum.editCourseStructure(this.id, { delete: 1 });
        this.displaySuccess('Uppbyggingu eytt');
        this.$emit('closeAndReloadSlidebar');
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);

        if (e.response && e.response.data && e.response.data.error) {
          this.displayError(e.response.data.error);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    async initialize() {
      if (this.id) {
        this.form.heiti = this.item.uppbygging_heiti;
        this.form.heiti_ens = this.item.heiti_ens;
        this.form.einingar = this.item.einingar;
        this.form.rod = this.item.rod;
        await this.loadTypes();
        this.form.tegund = this.types.find(x => x.id === this.item.tegund);
      } else {
        this.form.heiti = '';
        this.form.heiti_ens = '';
        this.form.tegund = '';
        this.form.rod = '';
        this.loadTypes();
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    if (this.item && this.item.braut_uppbygg_id) {
      this.id = this.item.braut_uppbygg_id;
    }
    this.initialize();
  },
};
</script>

<style lang="scss"></style>
