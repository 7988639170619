<template>
  <div>
    <!--navbar class="nav-header" :userAccess="userAccess" :class="{'collapsed': collapsedSidebar }"></navbar-->
    <div class="sidenav noprint" :class="{ collapsed: collapsedSidebar && !hovering }" @mouseover="hoverOver" @mouseleave="stopHover">
      <collapsed-sidebar v-if="collapsedSidebar && !hovering" :navItems="navItems" @collapse="collapseSidebar"> </collapsed-sidebar>
      <sidebar :navItems="navItems" @collapse="collapseSidebar" :userAccess="userAccess" v-else> </sidebar>
    </div>
    <div class="main" :class="{ collapsed: collapsedSidebar && !hovering }">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import auth from '@/api/auth';

import Sidebar from '@/components/layout/Sidebar.vue';
import CollapsedSidebar from '@/components/layout/CollapsedSidebar.vue';

export default {
  name: 'page',
  components: {
    Sidebar,
    CollapsedSidebar,
  },
  computed: {
    collapsedSidebar() {
      return this.$store.state.collasedSidebar;
    },
  },
  data() {
    return {
      pingTimeout: null,
      hovering: false,
      userAccess: [],
      navItems: [
        {
          name: 'Beiðnir',
          id: 'requests',
          icon: 'nemendahald.svg',
          access: 'beidnir',
          items: [
            {
              id: 'requests_parent_teacher',
              name: 'Samráðsfundir',
              route: 'ParentTeacher',
              access: 'beidnir_samradsfundir',
            },
            {
              id: 'requests_change_timetable_requests',
              name: 'Úrv. töflubreytinga',
              route: 'ChangeTimetableRequests',
              access: 'beidnir_toflubreytingar',
            },
            {
              id: 'requests_change_course_requests',
              name: 'Úrv. brautaskipta',
              route: 'ChangeCourseRequests',
              access: 'beidnir_brautaskipti',
            },
            {
              id: 'requests_review_vacation',
              name: 'Afgreiðsla leyfisbeiðna',
              route: 'ReviewVacation',
              access: 'beidnir_leyfi',
            },
            {
              id: 'requests_review_absence',
              name: 'Veikindatilkynningar',
              route: 'ReviewAbsence',
              access: 'beidnir_veikindi',
            },
            {
              id: 'requests_special_resources',
              name: 'Sérúrræði',
              route: 'SpecialResources',
              access: 'beidnir_serurraedi',
            },
            {
              id: 'requests_equipments',
              name: 'Pantanir á búnaði',
              route: 'Equipments',
              access: 'beidnir_bunadur',
            },
            {
              id: 'requests_classrooms',
              name: 'Pantanir á stofu',
              route: 'Classrooms',
              access: 'beidnir_stofa',
            },
          ],
        },
        {
          id: 'curriculum',
          name: 'Námskrá',
          icon: 'namskra.svg',
          access: 'namskra', // þarft að hafa aðgang að eitthverju í þessum flokk
          items: [
            {
              id: 'curriculum_modules',
              name: 'Áfangar',
              route: 'Modules',
              access: 'namskra_afangar', // þarft að hafa aðgang að þessari stýringu
            },
            {
              id: 'curriculum_courses',
              name: 'Brautir',
              route: 'Courses',
              access: 'namskra_brautir', // þarft að hafa aðgang að þessari stýringu
            },
            {
              id: 'curriculum_subjects',
              name: 'Námsgreinar',
              route: 'Subjects',
              access: 'namskra_namsgreinar', // þarft að hafa aðgang að þessari stýringu
            },
            {
              id: 'curriculum_singleclasses',
              name: 'Stök námskeið',
              route: 'SingleClasses',
              access: 'namskra_stok_namskeid', // þarft að hafa aðgang að þessari stýringu
            },
            {
              id: 'curriculum_upload',
              name: 'Innlestur',
              route: 'CurriculumUpload',
              access: 'namskra_innlestur', // þarft að hafa aðgang að þessari stýringu
            },
          ],
        },
        {
          name: 'Nemendahald',
          id: 'students',
          icon: 'nemendahald.svg',
          access: 'nemendahald',
          items: [
            {
              id: 'students_groups',
              name: 'Hópar',
              route: 'Groups',
              access: 'nemendahald_hopar',
            },
            {
              id: 'students_students',
              name: 'Nemendur',
              route: 'Students',
              access: 'nemendahald_nemendur',
            },
            {
              id: 'students_interviews',
              name: 'Námsráðgjöf',
              route: 'Counseling',
              access: 'nemendahald_namsradgjof',
            },
            {
              id: 'students_module_certificate',
              name: 'Skírteini',
              route: 'Certificates',
              access: 'nemendahald_skirteini',
            },
          ],
        },
        {
          id: 'school',
          name: 'Skólahald',
          icon: 'skolahald.svg',
          access: 'skolahald',
          items: [
            {
              id: 'school_terms',
              name: 'Kennslutímabil',
              route: 'Terms',
              access: 'skolahald_annir',
            },
            {
              id: 'school_school',
              name: 'Uppbygging',
              route: 'School',
              access: 'skolahald_uppbygging',
            },
            {
              id: 'school_timetable',
              name: 'Stundatöflur',
              route: 'Timetable',
              access: 'skolahald_stundatoflur',
            },
          ],
        },
        {
          id: 'reports',
          name: 'Skýrslur',
          icon: 'namskra.svg',
          access: 'skyrslur',
          items: [
            {
              id: 'reports_elementary',
              name: 'Hagstofuskýrsla',
              route: 'StatisticsElementary',
              access: 'skyrslur_hagstofa_grunnskoli',
            },
            {
              id: 'reports_vinnuskyrsla',
              name: 'Vinnuskýrslur',
              route: 'Worklog',
              access: 'skyrslur_vinnuskyrslur',
            },
            {
              id: 'reports_mainschool',
              name: 'Aðalskóli',
              route: 'MainSchool',
              access: 'skyrslur_adalskoli',
            },
            {
              id: 'reports_analysis',
              name: 'Kostnaðargreining brauta',
              route: 'CoursesAnalysis',
              access: 'skyrslur_adalskoli',
            },
            {
              id: 'reports_inhouse',
              name: 'Fjöldi í húsi',
              route: 'InHouse',
              access: '',
            },
          ],
        },
        {
          name: 'Starfsmannahald',
          id: 'staff',
          icon: 'starfsmannahald.svg',
          access: 'starfsmannahald',
          items: [
            {
              id: 'staff_staff',
              name: 'Starfsmenn',
              route: 'Staff',
              access: 'starfsmannahald_starfsmenn',
            },
            {
              id: 'staff_adgangsstyringar',
              name: 'Aðgangsstýringar',
              route: 'Access',
              access: 'starfsmannahald_adgangur',
            },
            {
              id: 'staff_forfoll',
              name: 'Forföll',
              route: 'StaffAbsenceReport',
              access: 'starfsmannahald_forfoll',
            },
          ],
        },
        {
          id: 'user',
          is_user: true,
          items: [
            /* {
              id: 'user_settings',
              name: 'Stillingar',
              route: '',
            },
            {
              id: 'user_help',
              name: 'Hjálp?',
              route: '',
            }, */
          ],
        },
      ],
    };
  },
  methods: {
    collapseSidebar() {
      this.$store.commit('toggleCollapsedSidebar');
    },
    openSidebar() {
      let categoryToOpen;
      this.navItems.forEach(category => {
        category.items.forEach(item => {
          if (item.route === this.$route.name) {
            categoryToOpen = category;
          }
        });
      });
      if (categoryToOpen) {
        this.$store.commit('openCategory', categoryToOpen);
      }
    },
    async loadUserAccess() {
      if (this.userAccess.length === 0) {
        const response = await auth.getUserAccess();
        this.userAccess = response.data.filter(x => !(x.system === 2 && x.is_current_school === 1));
      }
    },
    hoverOver() {
      this.hovering = true;
    },
    stopHover() {
      this.hovering = false;
    },
    async ping() {
      try {
        // Sækja innihald innskráningar.
        const payload = auth.getToken();

        this.$log.debug(`ping ${!!payload}`);

        if (payload) {
          const result = await auth.refreshToken();
          if (result.data.successful && result.data.timeleft) {
            auth.setTokenExpire(result.data.timeleft);
          } else {
            auth.logout();
          }
        } else {
          auth.logout();
        }
      } catch (e) {
        this.$log.debug(e);
        auth.logout();
      } finally {
        this.pingTimeout = setTimeout(() => {
          this.ping();
        }, 120000);
      }
    },
  },
  created() {
    this.ping();
    this.loadUserAccess();
    this.openSidebar();
  },
};
</script>

<style lang="scss">
@import '../../style/variables';

.sidenav {
  height: 100%; /* Full-height: remove this if you want "auto" height */
  width: 300px; //160px; /* Set the width of the sidebar */
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  // z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background: linear-gradient(180deg, #ad0081 0%, #4f0077 100%);
  color: #ffffff;
  // overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 20px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  &.collapsed {
    width: 80px;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
}

.nav-header {
  margin-left: 300px;
  &.collapsed {
    margin-left: 80px;
  }
}

.main {
  margin-left: 300px; //160px; /* Same as the width of the sidebar */
  padding: 10px 10px 10px 40px;
  &.collapsed {
    margin-left: 60px;
  }
}
</style>
