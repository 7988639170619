<template>
  <div>
    <GradeRuleSearch
      :id="id"
      :item="item"
      :divisionId="divisionId"
      @selectModule="selectModule"
      v-show="!hide"
      :allowCopy="true && !termPassed"
      name="gradeRuleSearch"
      :termId="termId"
      :page="page"
    ></GradeRuleSearch>
    <GradeRule :id="selectedItem" :termPassed="termPassed" v-if="hide && selectedItem" @changeModule="changeModule()"></GradeRule>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import GradeRuleSearch from '@/components/school/terms/graderules/GradeRuleSearch.vue';
import GradeRule from '@/components/school/terms/graderules/GradeRule.vue';

export default {
  name: 'term-graderules',
  props: ['id', 'item', 'divisionId', 'termPassed'],
  components: {
    GradeRuleSearch,
    GradeRule,
  },
  computed: {
    canRead() {
      return this.loggedInUserHasReadPermission('skolahald_annir');
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_annir');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolHasSettings']),
  },
  data() {
    return {
      hide: false,
      selectedItem: '',
      page: 'graderules',
      termId: '',
    };
  },
  methods: {
    changeModule() {
      this.hide = false;
    },
    selectModule(item) {
      if (item && item.id) {
        /* if (!item.refresh) {
          this.$router.push({ name: 'Term', params: { id: this.termId }, query: { page: this.page, module: item.id } });
        } */
        this.selectedItem = item.id;
        this.hide = true;
      } else {
        this.hide = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
    if (this.$route.params.id) {
      this.termId = this.$route.params.id;
    }
    if (this.$route.query.module) {
      this.selectModule({ id: this.$route.query.module, refresh: true });
    }
  },
};
</script>
