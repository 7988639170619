<template>
  <page>
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <PageTitle :title="title" />
    <b-card>
      <h5>Upplýsingar um námskeið</h5>
      <b-row>
        <b-col>
          <label class="labelOnclass">Heiti</label>
          <inline-edit :doEdit="edit.name" @editField="toggleEdit('name')" @saveField="saveField('name')" :canEdit="canEdit">
            <span slot="content">
              <span v-html="item.namskeid_nafn" />
            </span>
            <span slot="input">
              <b-form-input id="name" name="name" v-model="editInstance.namskeid_nafn" />
            </span>
          </inline-edit>
        </b-col>
        <b-col>
          <label class="labelOnclass">Tegund námskeiðs</label>
          <inline-edit :doEdit="edit.category" @editField="toggleEdit('category')" @saveField="saveField('category')" :canEdit="canEdit">
            <span slot="content">
              {{ item.flokkur_nafn }}
            </span>
            <span slot="input">
              <v-select
                id="category"
                name="category"
                v-if="categories"
                v-model="editInstance.category"
                :clearable="false"
                :options="categories"
                label="flokkur_nafn"
              >
              </v-select>
            </span>
          </inline-edit>
        </b-col>
        <b-col>
          <label class="labelOnclass">Staður</label>
          <inline-edit :doEdit="edit.location" @editField="toggleEdit('location')" @saveField="saveField('location')" :canEdit="canEdit">
            <span slot="content">
              {{ item.stadsetning_nafn }}
            </span>
            <span slot="input">
              <v-select
                id="location"
                name="location"
                v-model="editInstance.location"
                :clearable="false"
                :options="locations"
                label="heiti"
                data-vv-as="staður"
              >
              </v-select>
            </span>
          </inline-edit>
        </b-col>
        <b-col>
          <label class="labelOnclass">Staður námskeiðs*</label>
          <inline-edit
            :doEdit="edit.locationText"
            @editField="toggleEdit('locationText')"
            @saveField="saveField('locationText')"
            :canEdit="canEdit"
          >
            <span slot="content">
              {{ item.starfsstod }}
            </span>
            <span slot="input">
              <b-form-input id="location_text" name="location_text" v-model="editInstance.starfsstod" />
            </span>
          </inline-edit>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <label class="labelOnclass">Skráningar opnar</label>
          <inline-edit
            :doEdit="edit.registration_open"
            @editField="toggleEdit('registration_open')"
            @saveField="saveField('registration_open')"
            :canEdit="canEdit"
          >
            <span slot="content">
              {{ item.skraning_opnar | moment('DD.MM.YYYY') }}
            </span>
            <span slot="input">
              <datepicker
                class="datepicker_100_width"
                id="registration_open"
                name="registration_open"
                v-model="editInstance.registration_open"
                :language="lang"
                :monday-first="true"
                format="dd.MM.yyyy"
                :typeable="false"
                v-validate="'required'"
                data-vv-as="skráning opnar"
                ref="registrationOpenPickr"
              ></datepicker>
            </span>
          </inline-edit>
        </b-col>
        <b-col>
          <label class="labelOnclass">Skráning lokar</label>
          <inline-edit
            :doEdit="edit.registration_close"
            @editField="toggleEdit('registration_close')"
            @saveField="saveField('registration_close')"
            :canEdit="canEdit"
          >
            <span slot="content">
              {{ item.skraning_lokar | moment('DD.MM.YYYY') }}
            </span>
            <span slot="input">
              <datepicker
                class="datepicker_100_width"
                id="registration_close"
                name="registration_close"
                v-model="editInstance.registration_close"
                :language="lang"
                :monday-first="true"
                format="dd.MM.yyyy"
                :typeable="false"
                v-validate="'required'"
                data-vv-as="skráning lokar"
                ref="registrationClosePickr"
              ></datepicker>
            </span>
          </inline-edit>
        </b-col>
        <b-col>
          <label class="labelOnclass">Námskeið hefst*</label>
          <inline-edit :doEdit="edit.date_from" @editField="toggleEdit('date_from')" @saveField="saveField('date_from')" :canEdit="canEdit">
            <span slot="content">
              {{ item.dags_fra | moment('DD.MM.YYYY') }}
            </span>
            <span slot="input">
              <datepicker
                class="datepicker_100_width"
                id="date_from"
                name="date_from"
                v-model="editInstance.date_from"
                :language="lang"
                :monday-first="true"
                format="dd.MM.yyyy"
                :typeable="false"
                v-validate="'required'"
                data-vv-as="dags"
                ref="dateFromPickr"
              ></datepicker>
            </span>
          </inline-edit>
        </b-col>
        <b-col>
          <label class="labelOnclass">Námskeiði lýkur*</label>
          <inline-edit :doEdit="edit.date_to" @editField="toggleEdit('date_to')" @saveField="saveField('date_to')" :canEdit="canEdit">
            <span slot="content">
              {{ item.dags_til | moment('DD.MM.YYYY') }}
            </span>
            <span slot="input">
              <datepicker
                class="datepicker_100_width"
                id="date_to"
                name="date_to"
                v-model="editInstance.date_to"
                :language="lang"
                :monday-first="true"
                format="dd.MM.yyyy"
                :typeable="false"
                v-validate="'required'"
                data-vv-as="dags"
                ref="dateToPickr"
              ></datepicker>
            </span>
          </inline-edit>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <label class="labelOnclass">Hámarksfjöldi</label>
          <inline-edit :doEdit="edit.limit" @editField="toggleEdit('limit')" @saveField="saveField('limit')" :canEdit="canEdit">
            <span slot="content">
              {{ item.hamarksfjoldi }}
            </span>
            <span slot="input">
              <b-form-input id="limit" name="limit" type="number" min="0" v-model="editInstance.hamarksfjoldi" />
            </span>
          </inline-edit>
        </b-col>
        <b-col>
          <label class="labelOnclass">Verð</label>
          <inline-edit :doEdit="edit.price" @editField="toggleEdit('price')" @saveField="saveField('price')" :canEdit="canEdit">
            <span slot="content">
              {{ item.verd | currency }}
            </span>
            <span slot="input">
              <b-form-input id="price" name="price" type="number" min="0" v-model="editInstance.verd" />
            </span>
          </inline-edit>
        </b-col>
        <b-col>
          <label class="labelOnclass">Umsjónarmaður námskeiðs</label>
          <inline-edit :doEdit="edit.teacher" @editField="toggleEdit('teacher')" @saveField="saveField('teacher')" :canEdit="canEdit">
            <span slot="content">
              {{ item.nafn }}
            </span>
            <span slot="input">
              <v-select
                id="teacher"
                name="teacher"
                v-model="editInstance.teacher"
                :clearable="true"
                :options="teachers"
                label="nafn"
                data-vv-as="umsjónarmaður"
              >
              </v-select>
            </span>
          </inline-edit>
        </b-col>
        <b-col>
          <label class="labelOnclass">Biðlisti</label>
          <inline-edit :doEdit="edit.wait_list" @editField="toggleEdit('wait_list')" @saveField="saveField('wait_list')" :canEdit="canEdit">
            <span slot="content">
              {{ item.bidlisti === 1 ? 'Já' : 'Nei' }}
            </span>
            <span slot="input">
              <b-form-checkbox id="wait_list" name="wait_list" v-model="editInstance.wait_list" />
            </span>
          </inline-edit>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col> </b-col>
        <b-col> </b-col>
        <b-col> </b-col>
        <b-col>
          <label class="labelOnclass">Hlekkur á umsóknarsíðu</label>
          <span slot="content">
            <a :href="`https://umsokn.inna.is/#!/sc/${item.namskeid_id}`" target="_blank" rel="nonreferrer"
              >Opna umsóknarsíðu í nýjum glugga</a
            >
          </span>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <label class="labelOnclass">Lýsing</label>
          <inline-edit
            :doEdit="edit.description"
            @editField="toggleEdit('description')"
            @saveField="saveField('description')"
            :canEdit="canEdit"
          >
            <span slot="content">
              <div v-html="item.namskeid_lysing" />
            </span>
            <span slot="input">
              <quill-editor
                id="description"
                name="description"
                v-model="editInstance.namskeid_lysing"
                :state="submitted && errors.has('description') ? false : ''"
                ref="textQuillEditor"
                :options="editorOption"
              >
              </quill-editor>
            </span>
          </inline-edit>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <label class="labelOnclass">Lýsing á skírteini námskeiðs</label>
          <inline-edit
            :doEdit="edit.description_diploma"
            @editField="toggleEdit('description_diploma')"
            @saveField="saveField('description_diploma')"
            :canEdit="canEdit"
          >
            <span slot="content">
              <div v-html="item.lysing_skirteini" />
            </span>
            <span slot="input">
              <quill-editor
                id="description_diploma"
                name="description_diploma"
                v-model="editInstance.lysing_skirteini"
                :state="submitted && errors.has('description_diploma') ? false : ''"
                ref="textQuillEditor"
                :options="editorOption"
              >
              </quill-editor>
            </span>
          </inline-edit>
        </b-col>
      </b-row>
    </b-card>
    <br />
    <b-card>
      <b-row class="mb-2">
        <b-col>
          <h5>Þátttakendur</h5>
        </b-col>
        <b-col v-if="canEdit">
          <div class="float-right">
            <div class="d-inline-block">
              <b-form-file
                class="singleCourseFile"
                v-model="file"
                accept="text/csv"
                placeholder="Smelltu hér til að velja .csv skrá.."
              ></b-form-file>
            </div>
            <div class="d-inline-block">
              <b-btn variant="primary" @click="loadParticipantFile" :disabled="!file"><i class="fa fa-cloud-upload"></i> Hlaða upp</b-btn>
            </div>
            <div class="mt-3">
              <download-csv
                :data="getNotValidExcelData"
                :name="getNotValidExcelFileName"
                delimiter=";"
                class="d-inline"
                v-if="notValid.length > 0"
              >
                <i class="fa fa-cloud-download text-danger cursor-pointer"></i>
                <span class="text-danger cursor-pointer"> Sækja ólöglegar færslur</span>
              </download-csv>
              <a href="/files/stok_namskeid_innlestur.csv" target="_blank" class="float-right"
                ><i class="fa fa-cloud-download"></i> Sækja dæmi um .csv skrá</a
              >
            </div>
          </div>
        </b-col>
      </b-row>
      <table class="table" style="font-size: 13px">
        <thead>
          <tr>
            <th>
              <LinkOrder title="Nafn" :filteredList="item.participants" :list="item.participants" column="nafn"/>
            </th>
            <th>
              <LinkOrder title="Kennitala" :filteredList="item.participants" :list="item.participants" column="kt" 
               second_column="nafn" />
            </th>
            <th>
              <LinkOrder title="Netfang" :filteredList="item.participants" :list="item.participants" column="email" second_column="nafn" />
            </th>
            <th>
              <LinkOrder title="Sími" :filteredList="item.participants" :list="item.participants" column="phone" second_column="nafn" />
            </th>
            <th>
              <LinkOrder title="Dags. umsóknar" :filteredList="item.participants" :list="item.participants" column="time_edit" 
               second_column="nafn" />
            </th>
            <th v-if="item.verd && item.verd > 0">
              <LinkOrder title="Greitt" :filteredList="item.participants" :list="item.participants" column="greitt" second_column="nafn" />
            </th>
            <th v-if="item.verd && item.verd > 0">
              <LinkOrder title="Greiðslumáti" :filteredList="item.participants" :list="item.participants" column="greidslutegund_heiti" 
               second_column="nafn" />
            </th>
            <th v-if="item.verd && item.verd > 0">
              <LinkOrder title="Kennitala fyrirtækis" :filteredList="item.participants" :list="item.participants" column="payer_ssn" 
               second_column="nafn" />
            </th>
            <th v-if="item.verd && item.verd > 0">
              <LinkOrder title="Netfang tengiliðs" :filteredList="item.participants" :list="item.participants" column="payer_email" 
               second_column="nafn" />
            </th>
            <th colspan="2">
              <div class="d-inline-block float-right">
                <span v-if="(item.participants || []).length > 0">
                  <download-csv :data="getParticipantExcelData" :name="getParticipantExcelFileName" delimiter=";" class="d-inline">
                    <b-btn size="sm" style="background-color: #1d6f42"> <i class="fa fa-file-excel-o" /> Excel </b-btn>
                  </download-csv>
                  <b-btn variant="primary" size="sm" class="ml-2" v-if="canEdit" @click="messageModal()"
                    ><i class="fa fa-envelope" v-b-tooltip title="Senda skilaboð" />
                  </b-btn>
                </span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(p, index) in item.participants" :key="index">
            <td>{{ p.nafn }}</td>
            <td>{{ p.kt }}</td>
            <td>
              <inline-edit
                :doEdit="p.edit.email"
                @editField="toggleEdit('email', p)"
                @saveField="savePersonField('person_email', p)"
                :canEdit="canEdit"
              >
                <span slot="content">
                  {{ p.email }}
                </span>
                <span slot="input">
                  <b-form-input :id="`email${index}`" :name="`email${index}`" v-if="p.editInstance" v-model="p.editInstance.email" />
                </span>
              </inline-edit>
            </td>
            <td>
              <inline-edit
                :doEdit="p.edit.phone"
                @editField="toggleEdit('phone', p)"
                @saveField="savePersonField('person_phone', p)"
                :canEdit="canEdit"
              >
                <span slot="content">
                  {{ p.phone }}
                </span>
                <span slot="input">
                  <b-form-input :id="`phone${index}`" :name="`phone${index}`" v-if="p.editInstance" v-model="p.editInstance.phone" />
                </span>
              </inline-edit>
            </td>
            <td>{{ p.time_edit | moment('DD.MM.YYYY HH:mm') }}</td>
            <td v-if="item.verd && item.verd > 0"><yes-no :item="participant.greitt" /></td>
            <td v-if="item.verd && item.verd > 0">
              <inline-edit
                :doEdit="p.edit.payment"
                @editField="toggleEdit('payment', p)"
                @saveField="savePersonField('person_payment', p)"
                :canEdit="canEdit"
              >
                <span slot="content">
                  {{ p.greidslutegund_heiti }}
                </span>
                <span slot="input">
                  <v-select
                    id="payments"
                    name="payments"
                    v-model="p.editInstance.greidslutegund"
                    :clearable="false"
                    :options="payments"
                    v-if="p.editInstance"
                    label="greidslutegund_heiti"
                    data-vv-as="greiðslumáti"
                  >
                  </v-select>
                </span>
              </inline-edit>
            </td>
            <td v-if="item.verd && item.verd > 0">
              <inline-edit
                :doEdit="p.edit.payer_ssn"
                @editField="toggleEdit('payer_ssn', p)"
                @saveField="savePersonField('person_payer_ssn', p)"
                :canEdit="canEdit"
                v-if="p.greidslutegund_valin === 8"
              >
                <span slot="content">
                  {{ p.greidandi_kt }}
                </span>
                <span slot="input">
                  <b-form-input
                    :id="`payer_ssn${index}`"
                    :name="`payer_ssn${index}`"
                    v-if="p.editInstance"
                    v-model="p.editInstance.greidandi_kt"
                  />
                </span>
              </inline-edit>
            </td>
            <td v-if="item.verd && item.verd > 0">
              <inline-edit
                :doEdit="p.edit.payer_email"
                @editField="toggleEdit('payer_email', p)"
                @saveField="savePersonField('person_payer_email', p)"
                :canEdit="canEdit"
                v-if="p.greidslutegund_valin === 8"
              >
                <span slot="content">
                  {{ p.greidandi_email }}
                </span>
                <span slot="input">
                  <b-form-input
                    :id="`payer_email${index}`"
                    :name="`payer_email${index}`"
                    v-if="p.editInstance"
                    v-model="p.editInstance.greidandi_email"
                  />
                </span>
              </inline-edit>
            </td>
            <td>
              <b-btn variant="primary" size="sm" @click="deleteParticipant(p)" class="float-right" v-if="canEdit">
                <i class="fa fa-trash" v-b-tooltip title="Eyða þátttakanda" />
              </b-btn>
            </td>
          </tr>
          <tr v-if="canEdit">
            <td><b-form-input v-model="participant.name" disabled /></td>
            <td><b-form-input v-model="participant.ssn" placeholder="Sláðu inn kennitölu" @input="searchSsn()" /></td>
            <td><b-form-input v-model="participant.phone" /></td>
            <td><b-form-input v-model="participant.email" /></td>
            <td></td>
            <td colspan="3" v-if="item.verd && item.verd > 0" />
            <td colspan="2">
              <b-btn
                variant="primary"
                size="sm"
                class="float-right"
                :disabled="!participant.name || !participant.ssn"
                @click="saveParticipant(participant.ssn, participant.email, participant.phone, true)"
              >
                Bæta við þátttakanda
                <i class="fa fa-save" />
              </b-btn>
            </td>
          </tr>
      </tbody>
      </table>
    </b-card>
    <message-modal ref="messageModal"></message-modal>
  </page>
</template>

<script>
import singleClasses from '@/api/singleClasses';
import common from '@/api/common';
import staff from '@/api/staff';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import PageTitle from '@/components/common/PageTitle.vue';
import InlineEdit from '@/components/common/InlineEdit.vue';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';
import { quillEditor } from 'vue-quill-editor';
import he from 'he';
import MessageModal from '@/components/curriculum/singleClasses/MessageModal.vue';
import YesNo from '@/components/common/YesNo.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';

export default {
  name: 'single-classes-class',
  components: {
    Breadcrumbs,
    PageTitle,
    InlineEdit,
    Datepicker,
    quillEditor,
    MessageModal,
    YesNo,
    LinkOrder,
  },
  computed: {
    getNotValidExcelData() {
      const newList = [
        [],
        ['Villur í innlestri fyrir námskeið'],
        [he.decode(this.item.namskeid_nafn, { allowUnsafeSymbols: true })],
        [],
        ['Kennitala', 'Netfang', 'Símanúmer', 'Villa'],
        ...this.notValid.map(x => [`="${x.ssn}"`, x.email, x.phone, x.error]),
      ];
      return newList;
    },
    getNotValidExcelFileName() {
      return `innlestur_villur_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    getParticipantExcelData() {
      const newList = [
        [],
        ['Þátttakendur'],
        [he.decode(this.item.namskeid_nafn, { allowUnsafeSymbols: true })],
        [],
        ['Nafn', 'Kennitala', 'Símanúmer', 'Netfang', 'Greiðslumáti', 'Kennitala fyrirtækis', 'Netfang tengiliðs'],
        ...(this.item.participants || []).map(x => [
          x.nafn,
          `="${x.kt}"`,
          x.phone,
          x.email,
          x.greidslutegund_heiti,
          this.item.verd > 0 && x.greidslutegund_valin === 8 && x.greidandi_kt ? `="${x.greidandi_kt}"` : '',
          this.item.verd > 0 && x.greidslutegund_valin === 8 ? x.greidandi_email : '',
        ]),
      ];
      return newList;
    },
    getParticipantExcelFileName() {
      return `thatttakendur_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_stok_namskeid');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      title: 'Skoða námskeið',
      breadcrumbs: [
        { name: 'Forsíða', route: 'Dashboard' },
        { name: 'Stök námskeið', route: 'SingleClasses' },
        { name: 'Skoða námskeið', route: '' },
      ],
      lang: is,
      editorOption: {
        /* quill options */
      },
      file: null,
      notValid: [],
      id: '',
      item: '',
      editInstance: {
        category: null,
        location: null,
        teacher: null,
      },
      submitted: false,
      loading: {
        data: false,
        category: false,
        location: false,
        teacher: false,
        payments: false,
      },
      edit: {
        name: false,
        category: false,
        location: false,
        locationText: false,
        registration_open: false,
        registration_close: false,
        date_from: false,
        date_to: false,
        limit: false,
        price: false,
        teacher: false,
        wait_list: false,
        description: false,
        description_diploma: false,
      },
      participant: {
        name: '',
        ssn: '',
        email: '',
        phone: '',
      },
      categories: [],
      locations: [],
      teachers: [],
      payments: [],
    };
  },
  methods: {
    async loadCategory() {
      try {
        this.loading.category = true;
        const response = await singleClasses.getCategories();
        this.categories = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.category = false;
      }
    },
    async loadPayments() {
      try {
        this.loading.payments = true;
        const response = await singleClasses.getPayments();
        this.payments = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.payments = false;
      }
    },
    async loadStaff() {
      try {
        this.loading.teacher = true;
        const response = await staff.getStaffList();
        this.teachers = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.teacher = false;
      }
    },
    async loadLocation() {
      try {
        this.loading.location = true;
        const response = await common.getPostalCodes();
        this.locations = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.location = false;
      }
    },
    async loadData() {
      try {
        this.item = '';
        this.loading.data = true;
        const params = { id: this.id };
        const response = await singleClasses.getList(params);
        this.item = response.data.items.length > 0 ? response.data.items[0] : '';

        (this.item.participants || []).forEach(p => {
          this.$set(p, 'edit', { phone: false, email: false });
        });
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.data = false;
      }
    },
    initializeForEdit() {
      this.editInstance = {
        ...this.item,
        namskeid_nafn: he.decode(this.item.namskeid_nafn, { allowUnsafeSymbols: true }),
        category: this.categories.find(x => x.namskeid_flokkur_id === this.item.flokkur_id),
        teacher: this.teachers.find(x => x.starfsm_id === this.item.starfsm_id),
        location: this.locations.find(x => parseInt(x.postnumer, 10) === this.item.stadsetning_id),
        registration_open: this.item.skraning_opnar ? moment(this.item.skraning_opnar).toDate() : '',
        registration_close: this.item.skraning_lokar ? moment(this.item.skraning_lokar).toDate() : '',
        date_from: this.item.dags_fra ? moment(this.item.dags_fra).toDate() : '',
        date_to: this.item.dags_til ? moment(this.item.dags_til).toDate() : '',
        wait_list: this.item.bidlisti === 1,
      };
    },
    toggleEdit(item, person) {
      if (person) {
        if (person.edit[item]) {
          this.$set(person, 'edit', {
            phone: false,
            email: false,
            payment: false,
            payer_ssn: false,
            payer_email: false,
          });
        } else {
          this.$set(person, 'edit', {
            phone: false,
            email: false,
            payment: false,
            payer_ssn: false,
            payer_email: false,
          });
          person.edit[item] = true; // eslint-disable-line
        }
        this.$set(person, 'editInstance', {
          ...person,
          greidslutegund: this.payments.find(x => x.greidslutegund_id === person.greidslutegund_id),
        });
      } else {
        if (this.edit[item]) {
          this.edit = {
            name: false,
            category: false,
            location: false,
            locationText: false,
            registration_open: false,
            registration_close: false,
            date_from: false,
            date_to: false,
            limit: false,
            price: false,
            teacher: false,
            wait_list: false,
            description: false,
            description_diploma: false,
          }; // eslint-disable-line
        } else {
          this.edit = {
            name: false,
            category: false,
            location: false,
            locationText: false,
            registration_open: false,
            registration_close: false,
            date_from: false,
            date_to: false,
            limit: false,
            price: false,
            teacher: false,
            wait_list: false,
            description: false,
            description_diploma: false,
          }; // eslint-disable-line
          this.edit[item] = true;
        }
        this.initializeForEdit();
      }
    },
    async savePersonField(type, person) {
      if (type === 'person_email' && !this.checkIsEmail(person.editInstance.email)) {
        this.displayError('Netfang ekki á réttu formi.');
      } else if (type === 'person_phone' && !this.checkIsPhone(person.editInstance.phone)) {
        this.displayError('Símanúmer ekki á réttu formi.');
      } else if (type === 'person_payer_email' && !this.checkIsEmail(person.editInstance.greidandi_email)) {
        this.displayError('Netfang tengiliðs ekki á réttu formi');
      } else if (type === 'person_payer_ssn' && !(await this.checkIsSsn(person.editInstance.greidandi_kt))) {
        this.displayError('Kennitala fannst ekki. Vinsamlegast veljið aðra.');
      } else {
        const item = {
          ssn: person.kt,
        };
        if (type === 'person_email') {
          item.update_email = 1;
          item.email = person.editInstance.email;
        }
        if (type === 'person_phone') {
          item.update_phone = 1;
          item.phone = person.editInstance.phone;
        }
        if (type === 'person_payment') {
          item.update_payment = 1;
          item.payment = person.editInstance.greidslutegund.greidslutegund_id;
          if (person.editInstance.greidslutegund.greidslutegund_id !== 8) {
            item.update_payer_ssn = 1;
            item.payer_ssn = '';
            item.update_payer_email = 1;
            item.payer_email = '';
          }
        }
        if (type === 'person_payer_ssn') {
          item.update_payer_ssn = 1;
          item.payer_ssn = person.editInstance.greidandi_kt;
        }
        if (type === 'person_payer_email') {
          item.update_payer_email = 1;
          item.payer_email = person.editInstance.greidandi_email;
        }
        try {
          await singleClasses.updateParticipant(person.namskeid_skraning_id, item);
          this.displaySuccess('Þátttakanda breytt.');
          this.loadData();
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    async saveField(type) {
      const item = {
        date_from: moment(this.item.dags_fra).format('DD.MM.YYYY'),
        date_to: moment(this.item.dags_til).format('DD.MM.YYYY'),
        date_open: this.item.skraning_opnar ? moment(this.item.skraning_opnar).format('DD.MM.YYYY') : '',
        date_close: this.item.skraning_lokar ? moment(this.item.skraning_lokar).format('DD.MM.YYYY') : '',
        location_id: this.item.stadsetning_id,
        location_place: this.item.starfsstod,
        category_id: this.item.flokkur_id,
        class_name: he.encode(this.item.namskeid_nafn, { allowUnsafeSymbols: true }),
        description: this.item.namskeid_lysing ? he.encode(this.item.namskeid_lysing, { allowUnsafeSymbols: true }) : '',
        description_diploma: this.item.lysing_skirteini ? he.encode(this.item.lysing_skirteini, { allowUnsafeSymbols: true }) : '',
        wait_list: this.item.bidlisti,
        price: this.item.verd,
        max_number: this.item.hamarksfjoldi,
        teacher: this.item.starfsm_id,
        id: this.id,
      };
      switch (type) {
        case 'name': // Nafn
          this.$set(item, 'class_name', he.encode(this.editInstance.namskeid_nafn, { allowUnsafeSymbols: true }));
          break;
        case 'category': // Flokkur
          this.$set(item, 'category_id', this.editInstance.category.namskeid_flokkur_id);
          break;
        case 'location': // Staður
          this.$set(item, 'location_id', this.editInstance.location ? this.editInstance.location.postnumer : '');
          break;
        case 'locationText': // Staður námskeiðs
          this.$set(item, 'location_place', this.editInstance.starfsstod);
          break;
        case 'registration_open': // Skráning opnar
          this.$set(
            item,
            'date_open',
            this.editInstance.registration_open ? moment(this.editInstance.registration_open).format('DD.MM.YYYY') : '',
          );
          break;
        case 'registration_close': // Skráning lokar
          this.$set(
            item,
            'date_close',
            this.editInstance.registration_close ? moment(this.editInstance.registration_close).format('DD.MM.YYYY') : '',
          );
          break;
        case 'date_from': // Hefst
          this.$set(item, 'date_from', this.editInstance.date_from ? moment(this.editInstance.date_from).format('DD.MM.YYYY') : '');
          break;
        case 'date_to': // Lýkur
          this.$set(item, 'date_to', this.editInstance.date_to ? moment(this.editInstance.date_to).format('DD.MM.YYYY') : '');
          break;
        case 'limit': // Hámarksfjöldi
          this.$set(item, 'max_number', this.editInstance.hamarksfjoldi);
          break;
        case 'price': // Verð
          this.$set(item, 'price', this.editInstance.verd ? this.editInstance.verd.replace('.', '') : '');
          break;
        case 'teacher': // Umsjónarmaður
          this.$set(item, 'teacher', this.editInstance.teacher ? this.editInstance.teacher.starfsm_id : '');
          break;
        case 'wait_list': // Biðlisti
          this.$set(item, 'wait_list', this.editInstance.wait_list ? 1 : 0);
          break;
        case 'description': // Athugasemdir
          this.$set(item, 'description', he.encode(this.editInstance.namskeid_lysing, { allowUnsafeSymbols: true }));
          break;
        case 'description_diploma': // Lýsing fyrir skírteini
          this.$set(item, 'description_diploma', he.encode(this.editInstance.lysing_skirteini, { allowUnsafeSymbols: true }));
          break;
        default:
          break;
      }
      await singleClasses.createClass(item);
      this.edit = {
        name: false,
        category: false,
        location: false,
        locationText: false,
        registration_open: false,
        registration_close: false,
        date_from: false,
        date_to: false,
        limit: false,
        price: false,
        teacher: false,
        wait_list: false,
        description: false,
      }; // eslint-disable-line
      this.loadData();
    },
    async searchSsn() {
      this.participant.name = '';
      if (this.participant.ssn && this.participant.ssn.length === 10) {
        try {
          const response = await common.searchSsn(this.participant.ssn);
          this.participant.name = response.data.nafn;
        } catch (e) {
          this.$log.error(e);
          if (e.status === 404) {
            this.displayError('Kennitala fannst ekki. Vinsamlegast veljið aðra.');
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      }
    },
    async checkIsSsn(ssn) {
      let isTrue = false;
      try {
        if (ssn) {
          await common.searchSsn(ssn);
        }
        isTrue = true;
      } catch (e) {
        this.$log.error(e);
        isTrue = false;
        if (e.response.status !== 404) {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
      return isTrue;
    },
    checkIsNumber(number) {
      const numberRegex = /^[0-9]*$/;
      return numberRegex.test(number.trim());
    },
    checkIsEmail(email) {
      const emailRegex =
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/; // eslint-disable-line max-len
      return emailRegex.test(email);
    },
    checkIsPhone(phone) {
      const phoneRegex = /^[0-9]*$/;
      return phoneRegex.test(phone.trim());
    },
    async checkSsn(ssn) {
      try {
        await common.searchSsn(ssn);
        return true;
      } catch (e) {
        return false;
      }
    },
    async saveParticipant(ssn, email, phone, isParticipant) {
      if (this.item.hamarksfjoldi && this.hamarksfjoldi > this.hamarksfjoldi + 1) {
        this.displayError('Ekki hægt að bæta við þátttakanda þar sem hámarksfjölda er náð.');
      } else if (this.item.participants.map(x => x.kt).indexOf(ssn) > -1) {
        this.displayError(`Ekki hægt að bæta við þátttakanda þar sem þátttakandi ${ssn} er nú þegar skráður.`);
      } else {
        try {
          await singleClasses.addParticipant({
            class_id: this.id,
            ssn,
            email,
            phone,
          });
          this.displaySuccess('Þátttakanda bætt við.');
          if (isParticipant) {
            this.participant = {
              name: '',
              ssn: '',
              email: '',
              phone: '',
            };
            this.loadData();
          }
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    parseFile() {
      return new Promise(resolve => {
        const read = new FileReader();
        read.readAsText(this.file);
        read.onloadend = () => {
          const data = read.result;
          if (data.split(';').length > 0) {
            const lines = data.split('\n');
            lines.forEach(line => {
              if (line.length > 0) {
                const item = {};
                const columns = line.split(';');
                let error = '';
                if (this.checkIsNumber(columns[0])) {
                  if (columns[0].length >= 9 && columns[0].length <= 10 && this.checkIsNumber(columns[0])) {
                    item.ssn = columns[0].length === 9 ? `0${columns[0]}` : columns[0];
                    if (this.checkIsEmail(columns[1]) || columns[1] === '') {
                      item.email = columns[1];
                    } else error += 'Ógilt netfang';
                    if (columns[2] && this.checkIsPhone(columns[2])) {
                      item.phone = columns[2];
                    } else error += 'Ógilt símanúmer';
                  } else {
                    error += 'Ógild kennitala';
                  }
                  if (error.length > 0) {
                    this.notValid.push({
                      ssn: columns.length > 0 ? '="' + columns[0] + '"' : '', // eslint-disable-line
                      email: columns.length > 1 ? columns[1] : '',
                      phone: columns.length > 2 ? columns[2] : '',
                      error,
                    });
                  } else {
                    this.parsed.push(item);
                  }
                }
              }
            });
          } else {
            this.displayError('Skrá ekki lögleg. Dálkar verða að vera aðskildir með ";"');
          }
          resolve();
        };
      });
    },
    async loadParticipantFile() {
      if (this.file.name && this.file.name.length > 4 && this.file.name.endsWith('.csv')) {
        this.parsed = [];
        this.notValid = [];
        // Vinnu úr skjalinu
        await this.parseFile();
        // Athuga hvort allar kennitölur finnist í þjóðskrá
        for (let i = 0; i < this.parsed.length; i += 1) {
          const item = this.parsed[i];
          const validSSn = await this.checkSsn(item.ssn); // eslint-disable-line
          if (!validSSn) {
            this.notValid.push({
              ssn: '="' + item.ssn + '"', // eslint-disable-line
              email: item.email,
              phone: item.phone,
              error: 'Kennitala fannst ekki í þjóðskrá',
            });
            this.parsed.splice(i, 1);
          }
        }
        // Athuga hámarksfjölda
        if (
          this.item.hamarksfjoldi &&
          this.parsed.length > 0 &&
          this.item.hamarksfjoldi < this.item.participants.length + this.parsed.length
        ) {
          this.displayError('Ekki hægt að hlaða inn þátttakendum þar sem það færi yfir hámarksfjölda námskeiðsins.');
          return;
        }
        // Vista
        for (let i = 0; i < this.parsed.length; i += 1) {
          const item = this.parsed[i];
          await this.saveParticipant(item.ssn, item.email, item.phone, false); // eslint-disable-line
        }
        if (this.parsed.length > 0) {
          this.displaySuccess('Þátttakendur lesnir inn.');
          this.loadData();
        }
        if (this.notValid.length > 0) {
          this.displayError(
            'Ath. Ólöglegar línur í innlestrarskrá. Hægt er að sækja skrá með þeim sem ekki tókst að hlaða upp hjá dæmi um .csv skrá.',
          );
        }
      } else {
        this.displayError('Skrá er ekki .csv skrá. Vinsamlegast veljið aðra.');
      }
    },
    async deleteParticipant(person) {
      const conf = confirm(`Ert þú viss um að eyða ${person.nafn}?`); // eslint-disable-line
      if (conf) {
        try {
          await singleClasses.deleteParticipant(person.namskeid_skraning_id);
          this.displaySuccess('Þátttakanda eytt.');
          this.loadData();
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    /*
     * Modal gluggar
     */
    messageModal() {
      this.$refs.messageModal.show(this.id, this.item.participants);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.id = this.$route.params.id;
    this.loadData();
    this.loadCategory();
    this.loadLocation();
    this.loadStaff();
    this.loadPayments();
  },
};
</script>
<style lang="scss">
/* #singleCourseFile {
  .custom-file-label::after {
    content: 'Lele';
  }
} */
.singleCourseFile {
  .custom-file-label::after {
    content: '' !important;
    padding: 0;
  }
}

.labelOnclass {
  // border-bottom: 1px solid #98139c;
  // padding-bottom: 5px;
  text-decoration: underline;
  text-underline-offset: 3px;
  // text-decoration-color: #98139c;
  // font-style: italic;
  // font-weight: bold;
  // text-transform: uppercase;
  // color: #000;
  font-size: 15px;
  margin-bottom: 0px;
  display: block;
}
</style>
