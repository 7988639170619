<template>
  <page>
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <PageTitle title="Innlestur" />
    <b-row>
      <b-col> </b-col>
      <b-col> </b-col>
    </b-row>
    <b-card>
      <h4>1. Veldu skrá og smelltu á lesa skrá</h4>
      <div>Ath. skráin þarf að vera byggð upp á eftirfarandi hátt</div>
      <table>
        <tr>
          <th>Svæði</th>
          <th>Námsgrein</th>
          <th>Kóði námsgreinar</th>
          <th>Áfangi</th>
          <th>Áfanganúmer</th>
          <th>Heiti áfanga</th>
          <th>Þrep</th>
          <th>Einingar</th>
          <th>Fj. tíma á viku (fyrirlestur)</th>
          <th>Fj. tíma á viku (verkefnahópur)</th>
          <th>Númer annar</th>
          <th>Braut</th>
          <th>Kóði brautar</th>
          <th>Einingar alls</th>
          <th>Heiti áfanga (enska)</th>
          <th>Jafngildir</th>
          <th>Uppbygging brautar</th>
          <th>Tegund uppbyggingar</th>
        </tr>
        <tr>
          <td>Tegund</td>
          <td>Char</td>
          <td>Char</td>
          <td>Char</td>
          <td>Char</td>
          <td>Char</td>
          <td>Number</td>
          <td>Number</td>
          <td>Number</td>
          <td>Number</td>
          <td>Number</td>
          <td>Char</td>
          <td>Char</td>
          <td>Number</td>
          <td>Char</td>
          <td>Char</td>
          <td>Char</td>
          <td>Char</td>
        </tr>
        <tr>
          <td>100</td>
          <td>6</td>
          <td>100</td>
          <td>20</td>
          <td>100</td>
          <td>1</td>
          <td>5,2</td>
          <td>3</td>
          <td>3</td>
          <td>2</td>
          <td>100</td>
          <td>6</td>
          <td>3</td>
          <td>100</td>
          <td>100</td>
          <td>100</td>
          <td>100</td>
        </tr>
      </table>
      <b-form-file v-model="file" class="mt-3" plain></b-form-file>
      <br />
      <b-btn variant="primary" @click="load" :disabled="!file">Lesa skrá</b-btn>
      <br />
    </b-card>
    <br />
    <b-card v-if="parsed.length > 0">
      <h4>2. Yfirfarðu gögnin og smelltu á "Lesa inn"</h4>
      <div class="mb-3">Ath. Gögn í rauðu eru ekki lögleg</div>
      <table class="table less-padding" style="font-size: 13px">
        <tr>
          <th>Námsgrein</th>
          <th>Kóði námsgreinar</th>
          <th>Áfangi</th>
          <th>Áfanganúmer</th>
          <th>Heiti áfanga</th>
          <th>Þrep</th>
          <th>Einingar</th>
          <th>Fj. tíma á viku (fyrirlestur)</th>
          <th>Fj. tíma á viku (verkefnahópur)</th>
          <th>Númer annar</th>
          <th>Braut</th>
          <th>Kóði brautar</th>
          <th>Einingar alls</th>
          <th>Heiti áfanga (enska)</th>
          <th>Jafngildir</th>
          <th>Uppbygging brautar</th>
          <th>Tegund uppbyggingar</th>
          <th></th>
        </tr>
        <tr v-for="(item, index) in parsed" :key="index">
          <td :class="{ 'text-danger': checkMaxLength(item.namsgrein, 100) }">{{ item.namsgrein }}</td>
          <td :class="{ 'text-danger': checkMaxLength(item.namsgr_kodi, 8) }">{{ item.namsgr_kodi }}</td>
          <td :class="{ 'text-danger': checkMaxLength(item.afangi, 100) }">{{ item.afangi }}</td>
          <td :class="{ 'text-danger': checkMaxLength(item.afanga_nr, 20) }">{{ item.afanga_nr }}</td>
          <td :class="{ 'text-danger': checkMaxLength(item.afanga_heiti, 100) }">{{ item.afanga_heiti }}</td>
          <td :class="{ 'text-danger': checkIsNumber(item.trep) }">{{ item.trep }}</td>
          <td :class="{ 'text-danger': checkIsNumber(item.einingar) }">{{ item.einingar }}</td>
          <td :class="{ 'text-danger': checkIsNumber(item.timar_viku_nemar) }">{{ item.timar_viku_nemar }}</td>
          <td :class="{ 'text-danger': checkIsNumber(item.timar_viku_nemar_2) }">{{ item.timar_viku_nemar_2 }}</td>
          <td :class="{ 'text-danger': checkIsNumber(item.onn_nr) }">{{ item.onn_nr }}</td>
          <td :class="{ 'text-danger': checkMaxLength(item.braut, 200) }">{{ item.braut }}</td>
          <td :class="{ 'text-danger': checkMaxLength(item.braut_kodi, 10) }">{{ item.braut_kodi }}</td>
          <td :class="{ 'text-danger': checkIsNumber(item.einingar_alls) }">{{ item.einingar_alls }}</td>
          <td :class="{ 'text-danger': checkMaxLength(item.afanga_heiti_enska, 100) }">{{ item.afanga_heiti_enska }}</td>
          <td :class="{ 'text-danger': checkMaxLength(item.jafngildir, 1000) }">{{ item.jafngildir }}</td>
          <td :class="{ 'text-danger': checkMaxLength(item.braut_uppbygging, 100) }">{{ item.braut_uppbygging }}</td>
          <td :class="{ 'text-danger': checkMaxLength(item.uppbygging_tegund, 100) }">{{ item.uppbygging_tegund }}</td>
          <td><i class="fa fa-trash" @click="removeItem(index)" /></td>
        </tr>
      </table>
      <b-btn varian="primary" @click="load" :disabled="!file">Lesa inn</b-btn>
    </b-card>
    <br />
    <b-card v-if="parsed.length > 0">
      <h4>3. Skoðaðu innlesin gögn og smelltu á "Staðfesta"</h4>
      <b-btn variant="primary" @click="load" :disabled="!file">Staðfesta</b-btn>
      <b-btn variant="primary" @click="load" :disabled="!file">Eyða</b-btn>
    </b-card>
  </page>
</template>

<script>
import { mapGetters } from 'vuex';

import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import PageTitle from '@/components/common/PageTitle.vue';

export default {
  name: 'Test',
  components: {
    Breadcrumbs,
    PageTitle,
  },
  computed: {
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {
      file: null,
      parsed: [],
      breadcrumbs: [
        { name: 'Forsida', route: 'Dashboard' },
        { name: 'Innlestur', route: '' },
      ],
    };
  },
  methods: {
    removeItem(index) {
      this.parsed.splice(index, 1);
    },
    checkMaxLength(text, length) {
      return text.length > length;
    },
    checkIsNumber(number) {
      return Number.isNaN(number);
    },
    load() {
      this.parsed = [];
      const self = this;
      const read = new FileReader();
      read.readAsText(this.file);
      read.onloadend = () => {
        const data = read.result;
        const lines = data.split('\n');
        lines.forEach(line => {
          if (line.length > 0) {
            const columns = line.split(';');
            const item = {
              namsgrein: columns.length > 0 ? columns[0] : '',
              namsgr_kodi: columns.length > 1 ? columns[1] : '',
              afangi: columns.length > 2 ? columns[2] : '',
              afanga_nr: columns.length > 3 ? columns[3] : '',
              afanga_heiti: columns.length > 4 ? columns[4] : '',
              trep: columns.length > 5 ? columns[5] : '',
              einingar: columns.length > 6 ? columns[6] : '',
              timar_viku_nemar: columns.length > 7 ? columns[7] : '',
              timar_viku_nemar_2: columns.length > 8 ? columns[8] : '',
              onn_nr: columns.length > 9 ? columns[9] : '',
              braut: columns.length > 10 ? columns[10] : '',
              braut_kodi: columns.length > 11 ? columns[11] : '',
              einingar_alls: columns.length > 12 ? columns[12] : '',
              afanga_heiti_enska: columns.length > 13 ? columns[13] : '',
              jafngildir: columns.length > 14 ? columns[14] : '',
              braut_uppbygging: columns.length > 15 ? columns[15] : '',
              uppbygging_tegund: columns.length > 16 ? columns[16] : '',
            };
            self.parsed.push(item);
          }
        });
      };
    },
  },
};
</script>
