<template>
  <page>
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <b-row class="breadcumb-location">
      <b-col>
        <b-btn variant="primary" :to="{ name: 'CreateModule' }" style="height: 35px" v-if="canEdit">
          <i class="fa fa-plus"></i> Stofna áfanga</b-btn
        >
      </b-col>
    </b-row>
    <PageTitle title="Áfangar"></PageTitle>
    <ModuleSearch style="margin-top: -20px"></ModuleSearch>
  </page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import PageTitle from '@/components/common/PageTitle.vue';

import ModuleSearch from '@/components/curriculum/modules/ModuleSearch.vue';

export default {
  name: 'modules',
  components: {
    Breadcrumbs,
    PageTitle,
    ModuleSearch,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_afangar');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('namskra_afangar');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {
      breadcrumbs: [
        { name: 'Forsíða', route: 'Dashboard' },
        { name: 'Námskrá', route: '' },
        { name: 'Áfangar', route: 'Modules' },
      ],
    };
  },
  methods: {
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
