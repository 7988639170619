<template>
  <div>
    <full-calendar :event-sources="eventSources" :config="config" ref="calendar"> </full-calendar>
  </div>
</template>

<script>
import common from '@/api/common';
import moment from 'moment';

import isLocale from 'fullcalendar/dist/locale/en-gb'; // eslint-disable-line
import enLocale from 'fullcalendar/dist/locale/is'; // eslint-disable-line
import { FullCalendar } from 'vue-full-calendar';
import JQuery from 'jquery';

export default {
  name: 'change-timetable-timetable',
  components: {
    FullCalendar,
  },
  props: [
    'defaultGroups',
    'removeGroups',
    'addGroups',
    'extraGroups',
    'teacherAddGroups',
    'teacherRemoveGroups',
    'refetch',
    'showHover',
    'removeHover',
    'goToDate',
  ],
  data() {
    return {
      eventSources: [
        {
          events: (start, end, timezone, callback) => {
            this.loadEvents(start, end, timezone, callback);
          },
        },
      ],
      config: {
        weekends: false,
        editable: false,
        selectable: false,
        defaultView: 'agendaWeek',
        allDaySlot: false,
        minTime: '08:00:00',
        locale: 'is',
        slotLabelFormat: ['HH:mm'],
        height: 650,
        columnHeaderFormat: 'ddd DD.MM',
        eventRender: (event, element) => {
          if (event.group) {
            let title = `Hópur: ${event.name}`;
            if (event.teacher) {
              title += `\nKennari: ${event.teacher}`;
            }
            if (event.room) {
              title += `\nStofa: ${event.room}`;
            }
            element[0].title = title; // eslint-disable-line no-param-reassign
          } else {
            element[0].title = event.title; // eslint-disable-line no-param-reassign
          }
        },
        eventMouseover: event => {
          this.hover(event.name, 1);
        },
        eventMouseout: event => {
          this.hover(event.name, 0);
        },
      },
    };
  },
  methods: {
    loadEvents(start, end, timezone, callback) {
      if (
        this.defaultGroups ||
        this.removeGroups ||
        this.addGroups ||
        this.extraGroups ||
        this.teacherAddGroups ||
        this.teacherRemoveGroups
      ) {
        const groups = (this.defaultGroups || []).concat(
          this.removeGroups || [],
          this.addGroups || [],
          this.extraGroups || [],
          this.teacherAddGroups || [],
          this.teacherRemoveGroups || [],
        );
        const params = {
          date_from: moment(start).format('DD.MM.YYYY'),
          date_to: moment(end).format('DD.MM.YYYY'),
          groups: groups.join(','),
        };
        const p = common.getTimetableGroups(params);
        p.then(response => {
          const list = [];
          response.data.forEach(e => {
            let className = 'timetable-default';
            if (this.addGroups && this.addGroups.indexOf(e.group_id) > -1) {
              className = 'timetable-add';
            } else if (this.removeGroups && this.removeGroups.indexOf(e.group_id) > -1) {
              className = 'timetable-remove';
            } else if (this.extraGroups && this.extraGroups.indexOf(e.group_id) > -1) {
              className = 'timetable-extra';
            } else if (this.teacherAddGroups && this.teacherAddGroups.indexOf(e.group_id) > -1) {
              className = 'timetable-add';
            } else if (this.teacherRemoveGroups && this.teacherRemoveGroups.indexOf(e.group_id) > -1) {
              className = 'timetable-remove';
            }

            let name = e.name;
            if (e.teacher) {
              name += `\n ${e.teacher}`;
            }
            if (e.classroom) {
              name += ` ${e.classroom}`;
            }
            list.push({
              allDay: e.allday === 1,
              start: e.allday === 0 ? e.date_time_from : e.date,
              end: e.allday === 0 ? e.date_time_to : null,
              title: name,
              id: e.table_id,
              group: e.group_id,
              name: e.name,
              teacher: e.teacher,
              room: e.classroom,
              className: `timetableRequestTable ${className}`,
            });
          });
          callback(list);
        });
      } else {
        callback([]);
      }
    },
    hover(name, type) {
      const $ = JQuery;
      if (type === 0) {
        $('#calendar').fullCalendar().find(`div.fc-content:contains(${name})`).parent().removeClass('timetable-hover');
      } else if (type === 1) {
        $('#calendar').fullCalendar().find(`div.fc-content:contains(${name})`).parent().addClass('timetable-hover');
      }
    },
  },
  watch: {
    defaultGroups() {
      this.$refs.calendar.$emit('refetch-events');
    },
    removeGroups() {
      this.$refs.calendar.$emit('refetch-events');
    },
    addGroups() {
      this.$refs.calendar.$emit('refetch-events');
    },
    extraGroups() {
      this.$refs.calendar.$emit('refetch-events');
    },
    teacherAddGroups() {
      this.$refs.calendar.$emit('refetch-events');
    },
    teacherRemoveGroups() {
      this.$refs.calendar.$emit('refetch-events');
    },
    showHover() {
      if (this.showHover && this.showHover.length > 0) {
        this.hover(this.showHover, 1);
      }
    },
    removeHover() {
      if (this.removeHover && this.removeHover.length > 0) {
        this.hover(this.removeHover, 0);
      }
    },
    refetch() {
      if (this.refetch) {
        this.$refs.calendar.$emit('refetch-events');
      }
    },
    goToDate() {
      if (this.goToDate) {
        this.$refs.calendar.fireMethod('gotoDate', this.goToDate);
      }
    },
  },
};
</script>

<style lang="scss">
#calendar {
  .fc-event {
    &.timetableRequestTable {
      font-size: 11px;
      color: #000 !important;
      &.timetable-default {
        background-color: #eceeef;
        border: 1px solid #c2cacb;
      }
      &.timetable-add {
        background-color: #5cb85c;
        border: 1px solid #5cb85c;
      }
      &.timetable-remove {
        background-color: #d9534f;
        border: 1px solid #d9534f;
      }
      &.timetable-extra {
        background-color: #f7f7f9;
        border: 1px solid #464a4c;
      }
      &.timetable-hover {
        background-color: #0275d8 !important;
        border: 1px solid #0275d8 !important;
      }
    }
  }
}
</style>
