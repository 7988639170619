<template>
  <Slidebar @closeMe="closeSlidebar" :large="large" :extraLarge="extraLarge">
    <b-row>
      <b-col class="pl-0">
        <h5>Brautir</h5>
      </b-col>
      <b-col>
        <h5 class="d-inline-block">
          <span v-if="type === 'division'">Undirskóli: </span>
          <span v-if="type === 'department'">Deild: </span>
          {{ item.name }}
        </h5>
      </b-col>
      <b-col class="text-right"> Fjöldi: {{ list.length }} </b-col>
    </b-row>
    <div v-if="type === 'department' && canEditStructure">
      <v-select
        id="newCourse"
        name="newCourse"
        v-model="selectedCourse"
        :options="getAllCourses"
        @input="addCourse"
        ref="selectCourseDropdown"
        placeholder="Smelltu hér til að bæta við braut"
      >
      </v-select>
      <br />
    </div>
    <Loader v-if="loading" />
    <div v-if="list.length === 0" class="mb-4">Engar brautir fundust</div>
    <table class="table" v-if="list.length > 0" style="font-size: 13px">
      <thead>
        <tr>
          <th>Kóði</th>
          <th>Heiti</th>
          <th>Undirsk.</th>
          <th v-if="type === 'department' && canEditStructure"></th>
        </tr>
        <tr class="table-search">
          <td :colspan="type === 'department' && canEditStructure ? 6 : 5" class="p-0">
            <b-form-input v-model="search" class="search mt-0 mb-0" placeholder="Heiti brautar" ref="searchInput" @input="resetPage()" />
          </td>
        </tr>
      </thead>
      <tr v-for="b in getPaginatedList" :key="b.braut_id">
        <td>
          {{ b.braut_kodi }}
          <i class="fa fa-times text-danger" v-if="b.gildi === 0" v-b-tooltip title="Braut ekki í gildi"></i>
        </td>
        <td>{{ b.heiti }}</td>
        <td>{{ b.undirsk_audk }}</td>
        <td v-if="type === 'department' && canEditStructure">
          <i class="fa fa-unlock cursor-pointer" @click="removeFromDepartment(b.braut_id)" title="Aftengja" v-b-tooltip />
        </td>
      </tr>
    </table>
    <Pagination
      :currentPage="currentPage"
      :totalRows="getList.length"
      :perPage="perPage"
      v-if="list.length > 20"
      @change-page="changePage"
      @change-limit="changeLimit"
    />
  </Slidebar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import curriculum from '@/api/curriculum';
import structure from '@/api/structure';
import Slidebar from '@/components/common/Slidebar.vue';
import Loader from '@/components/common/Loader.vue';
import Pagination from '@/components/common/Pagination.vue';

export default {
  name: 'course-slide-bar',
  props: ['item', 'type', 'large', 'extraLarge'], // type: division, department
  components: {
    Slidebar,
    Loader,
    Pagination,
  },
  computed: {
    getAllCourses() {
      return this.all.filter(x => !this.list.find(y => y.braut_id === x.braut_id));
    },
    getList() {
      return this.list.filter(
        x =>
          !this.search ||
          (x.braut_kodi || '').toUpperCase().startsWith(this.search.toUpperCase()) ||
          (x.heiti || '').toUpperCase().startsWith(this.search.toUpperCase()),
      );
    },
    getPaginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.getList.slice(start, end);
    },
    canEditStructure() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      perPage: 20,
      currentPage: 1,
      loading: false,
      loadingAll: false,
      list: [],
      all: [],
      search: '',
      selectedCourse: '',
    };
  },
  methods: {
    resetPage() {
      if (this.currentPage !== 1) {
        this.currentPage = 1;
      }
    },
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    editItem() {
      this.$emit('editItem');
    },
    async loadList(dontFocus) {
      try {
        this.list = [];
        this.loading = true;
        const params = {};
        if (this.type === 'division') {
          params.division = this.item.division_id;
        } else if (this.type === 'department') {
          params.department = this.item.department_id;
        }
        const response = await curriculum.getCourseList(params);
        this.list = response.data.items;
        this.$nextTick(() => {
          if (this.$refs.searchInput && !dontFocus) {
            this.$refs.searchInput.focus();
          }
        });
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadAll() {
      try {
        this.all = [];
        this.loadingAll = true;
        const params = {
          active: 1,
        };
        const response = await curriculum.getCourseList(params);
        this.all = response.data.items;
        this.all.forEach(e => {
          this.$set(e, 'label', `${e.braut_kodi} - ${e.heiti}`);
        });
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loadingAll = false;
      }
    },
    async addCourse() {
      try {
        if (this.selectedCourse) {
          const data = {
            deild_id: this.item.department_id,
            braut_id: this.selectedCourse.braut_id,
            delete: 0,
          };
          await structure.setDepartmentCourse(data);
          this.selectedCourse = '';
          const input = this.$refs.selectCourseDropdown.$el.querySelector('input');
          if (input) {
            input.focus();
          }

          this.loadList(true);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async removeFromDepartment(courseId) {
      try {
        const data = {
          deild_id: this.item.department_id,
          braut_id: courseId,
          delete: 1,
        };
        await structure.setDepartmentCourse(data);

        this.loadList();
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    item() {
      this.loadList();
    },
  },
  created() {
    this.loadList();
    if (this.type === 'department') {
      this.loadAll();
    }
  },
};
</script>

<style lang="scss"></style>
