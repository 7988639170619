<template>
  <Slidebar @closeMe="closeSlidebar">
    <h4>{{ item.namsgrein_afanganr }} - {{ item.afanga_heiti }}</h4>
    <table class="table no-border">
      <tr>
        <th>Í próftöflu</th>
        <td><b-form-checkbox v-model="form.i_proftoflu"></b-form-checkbox></td>
      </tr>
      <tr>
        <th>Í stokk</th>
        <td><b-form-checkbox v-model="form.i_stokk"></b-form-checkbox></td>
      </tr>
      <tr>
        <th>Í stundatöflu</th>
        <td><b-form-checkbox v-model="form.i_stundatoflu"></b-form-checkbox></td>
      </tr>
      <tr>
        <th>Opið val</th>
        <td><b-form-checkbox v-model="form.opid_val"></b-form-checkbox></td>
      </tr>
    </table>
    <b-btn variant="primary" @click="updateItem()">Breyta</b-btn>
    <b-btn variant="danger" class="float-right" @click="deleteItem()">Eyða</b-btn>
  </Slidebar>
</template>

<script>
import terms from '@/api/terms';
import { mapActions } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';

export default {
  name: 'change-term-module-slidebar',
  props: ['item'],
  components: {
    Slidebar,
  },
  data() {
    return {
      loading: false,
      submitting: false,
      form: {
        i_proftoflu: false,
        i_stokk: false,
        i_stundatoflu: false,
        opid_val: false,
      },
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    initialize() {
      this.form.i_proftoflu = this.item.i_proftoflu === 1;
      this.form.i_stokk = this.item.i_stokk === 1;
      this.form.i_stundatoflu = this.item.i_stundatoflu === 1;
      this.form.opid_val = this.item.opid_val === 1;
    },
    async updateItem() {
      try {
        await terms.updateTermModule(this.item.annir_afangar_id, {
          i_proftoflu: this.form.i_proftoflu ? 1 : 0,
          i_stokk: this.form.i_stokk ? 1 : 0,
          i_stundatoflu: this.form.i_stundatoflu ? 1 : 0,
          opid_val: this.form.opid_val ? 1 : 0,
        });
        this.submitting = false;
        this.displaySuccess('Áfangi í boði uppfærður.');
        this.$emit('closeAndReload');
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.submitting = false;
      }
    },
    async deleteItem() {
      try {
        await terms.updateTermModule(this.item.annir_afangar_id, {
          delete: 1,
        });
        this.submitting = false;
        this.displaySuccess('Áfangi í boði eytt.');
        this.$emit('closeAndReload');
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.submitting = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    item() {
      this.initialize();
    },
  },
  created() {
    this.initialize();
  },
};
</script>

<style lang="scss"></style>
