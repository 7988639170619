<template>
  <page>
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <PageTitle title="Sérúrræði" />
    <PageMenu route="SpecialResources" :selectedPage="page" :items="menu" />
    <RequestsPending v-if="page === 'pending'" @goToSsn="searchSsn"></RequestsPending>
    <RequestsAll v-if="page === 'all'" :ssn="ssn"></RequestsAll>
  </page>
</template>

<script>
import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import PageTitle from '@/components/common/PageTitle.vue';
import PageMenu from '@/components/common/PageMenu.vue';

import RequestsPending from '@/components/requests/specialResources/RequestsPending.vue';
import RequestsAll from '@/components/requests/specialResources/RequestsAll.vue';

export default {
  name: 'special-resources',
  components: {
    Breadcrumbs,
    PageTitle,
    RequestsPending,
    RequestsAll,
    PageMenu,
  },
  data() {
    return {
      menu: [
        {
          name: 'Óafgreiddar beiðnir',
          page: 'pending',
        },
        {
          name: 'Allar beiðnir',
          page: 'all',
        },
      ],
      page: 'pending',
      breadcrumbs: [
        { name: 'Forsíða', route: 'Dashboard' },
        { name: 'Beiðnir', route: '' },
        { name: 'Sérúrræði', route: 'SpecialResources' },
      ],
      ssn: '',
    };
  },
  methods: {
    searchSsn(item) {
      if (item) {
        this.ssn = item;
        this.$router.push({ name: 'SpecialResources',  query: { page: 'all' }  });
        // this.page = 'all';
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (to && to.query && to.query.page) {
      this.page = to.query.page;
    }
    next();
  },
  created() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
    if (this.$route.query.ssn) {
      this.searchSsn(this.$route.query.ssn);
    }
  },
};
</script>
<style lang="scss"></style>
