<template>
  <Slidebar @closeMe="closeSlidebar" :large="true">
    <h4>Stofna kennslustund</h4>
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <table class="table less-padding no-border">
        <tr>
          <th>Kennslutímabil</th>
          <td>{{ item.term.undirsk_heiti }} - {{ item.term.onn_heiti }}</td>
        </tr>
        <tr>
          <th>Áfangi*</th>
          <td>
            <b-form-group
              label-for="modules"
              :state="submitted && errors.has('modules') ? false : ''"
              :invalid-feedback="errors.first('modules')"
            >
              <v-select
                id="modules"
                name="modules"
                v-model="form.module"
                :clearable="false"
                v-validate="'required'"
                :state="submitted && errors.has('modules') ? false : ''"
                :options="modules"
                label="namsgrein_afanganr"
                @input="
                  loadGroups();
                  validated = false;
                "
                data-vv-as="áfangi"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <th>Hópur*</th>
          <td>
            <b-form-group
              label-for="groups"
              :state="submitted && errors.has('groups') ? false : ''"
              :invalid-feedback="errors.first('groups')"
            >
              <v-select
                id="groups"
                name="groups"
                v-model="form.group"
                :clearable="false"
                v-validate="'required'"
                :state="submitted && errors.has('groups') ? false : ''"
                :options="groups"
                label="hopur"
                data-vv-as="hópur"
                @input="
                  loadCurrentTeachers();
                  validated = false;
                "
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <th>Kennari/ar*</th>
          <td>
            <b-form-group
              label-for="teachers"
              :state="submitted && errors.has('teachers') ? false : ''"
              :invalid-feedback="errors.first('teachers')"
            >
              <v-select
                id="teachers"
                name="teachers"
                v-model="form.teachers"
                multiple
                v-validate="'required'"
                :state="submitted && errors.has('teachers') ? false : ''"
                :options="teachers"
                data-vv-as="kennari"
                @input="validated = false"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <th>Stofa</th>
          <td>
            <b-form-group
              label-for="classrooms"
              :state="submitted && errors.has('classrooms') ? false : ''"
              :invalid-feedback="errors.first('classrooms')"
            >
              <v-select
                id="classrooms"
                name="classrooms"
                v-model="form.classrooms"
                multiple
                :state="submitted && errors.has('classrooms') ? false : ''"
                :options="classrooms"
                @input="validated = false"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <th>Búnaður</th>
          <td>
            <b-form-checkbox v-model="form.orderEquipment" class="d-inline-block"></b-form-checkbox> Panta búnað
            <b-form-group
              class="mt-2"
              label-for="bunadur"
              :state="submitted && errors.has('bunadur') ? false : ''"
              :invalid-feedback="errors.first('bunadur')"
              v-if="form.orderEquipment"
            >
              <v-select
                id="bunadur"
                name="bunadur"
                v-model="form.equipment"
                :options="equipments"
                label="heiti"
                :state="submitted && errors.has('bunadur') ? false : ''"
                v-validate="form.orderEquipment ? 'required' : ''"
                data-vv-as="búnaður"
                @input="validated = false"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
        <TableRowLoader v-if="validating" colspan="5" :center="true" />
        <tr v-if="!validating">
          <th>Dags.</th>
          <td>
            <div class="d-inline-block"><b-btn variant="primary" size="sm" @click="addDate()">Bæta við</b-btn></div>
            <div class="d-inline-block pl-5">
              <i>Lengd kennslustunda: </i>
              <b-form-input type="number" style="padding: 2px; width: 80px; height: 30px" v-model="classLength" />
            </div>
            <table class="table no-border less-padding">
              <tr>
                <th v-if="validated"></th>
                <th>Dags.</th>
                <th>Tími frá</th>
                <th>Tími til</th>
              </tr>
              <tbody v-for="(date, index) in form.dates" :key="date.id" style="border: 0">
                <tr>
                  <td v-if="validated" style="vertical-align: top">
                    <b-form-checkbox v-model="date.copy" />
                  </td>
                  <td>
                    <b-form-group
                      :label-for="`date${index}`"
                      :state="submitted && errors.has(`date${index}`) ? false : ''"
                      :invalid-feedback="errors.first(`date${index}`)"
                    >
                      <datepicker
                        :id="`date${index}`"
                        :name="`date${index}`"
                        v-model="date.date"
                        :language="lang"
                        :monday-first="true"
                        format="dd.MM.yyyy"
                        :typeable="false"
                        v-validate="'required'"
                        data-vv-as="dags"
                        @input="validated = false"
                        :state="submitted && errors.has(`date${index}`) ? false : ''"
                        :ref="`dateOpenPickr${index}`"
                      ></datepicker>
                    </b-form-group>
                  </td>
                  <td>
                    <b-form-group
                      :label-for="`timeFrom${index}`"
                      :state="submitted && errors.has(`timeFrom${index}`) ? false : ''"
                      :invalid-feedback="errors.first(`timeFrom${index}`)"
                    >
                      <masked-input
                        :id="`timeFrom${index}`"
                        :name="`timeFrom${index}`"
                        v-model="date.time_from"
                        mask="11:11"
                        placeholder="hh:mm"
                        style="padding: 2px 2px 2px 2px; width: 60px; height: 30px; border: 1px solid #ced4da"
                        v-validate="{ required: true, regex: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/ }"
                        data-vv-as="tími frá"
                        :state="submitted && errors.has(`timeFrom${index}`) ? false : ''"
                        @input="
                          updateTimeTo(index);
                          validated = false;
                        "
                      />
                    </b-form-group>
                  </td>
                  <td>
                    <b-form-group
                      :label-for="`timeTo${index}`"
                      :state="submitted && errors.has(`timeTo${index}`) ? false : ''"
                      :invalid-feedback="errors.first(`timeTo${index}`)"
                    >
                      <masked-input
                        :id="`timeTo${index}`"
                        :name="`timeTo${index}`"
                        :ref="`timeTo${index}`"
                        v-model="date.time_to"
                        mask="11:11"
                        placeholder="hh:mm"
                        style="padding: 2px 2px 2px 2px; width: 60px; height: 30px; border: 1px solid #ced4da"
                        v-validate="{ required: true, regex: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/ }"
                        data-vv-as="tími til"
                        :state="submitted && errors.has(`timeTo${index}`) ? false : ''"
                        @input="validated = false"
                      />
                    </b-form-group>
                  </td>
                  <td>
                    <b-btn variant="primary" size="sm" @click="removeDate(index)" style="margin-top: -20px"
                      ><i class="fa fa-trash"
                    /></b-btn>
                  </td>
                </tr>
                <tr v-if="validated && date.equipment === 1" style="font-size: 13px">
                  <td colspan="5">
                    <div class="text-danger">Búnaður er bókaður á valdri dagsetningu. Hann verður ekki pantaður.</div>
                  </td>
                </tr>
                <tr v-if="validated && !date.validation.can_book">
                  <td colspan="5">
                    <div class="alert alert-danger mb-0" style="font-size: 13px; padding: 10px">
                      <div v-if="!date.validation.hopur.availability.available">
                        Hópaárekstur: Hópurinn er nú þegar bókaður á þessum tíma.
                      </div>
                      <div v-if="!date.validation.hopur.onn.date_in_range">
                        Dagsetning er ekki innan gildistíma hóps. Vinsamlegast veldu á milli
                        <strong> {{ date.validation.hopur.onn.from }} </strong> og <strong>{{ date.validation.hopur.onn.to }}</strong>
                      </div>
                      <div v-if="date.validation.stofa && date.validation.stofa.filter(x => !x.classroom_size_ok).length > 0">
                        <div v-for="(stofa, idx) in date.validation.stofa.filter(x => !x.classroom_size_ok)" :key="idx">
                          <div>Eftirfarandi stofa er of lítil: {{ stofa.short_name }}</div>
                          <div>
                            Fjöldi í hóp er: <strong> {{ date.validation.hopur.number_of_students }}</strong
                            >.
                          </div>
                          <div>
                            Hámarksfjöldi í stofu er: <strong> {{ stofa.max_students }}</strong
                            >.
                          </div>
                        </div>
                      </div>
                      <div v-if="!date.validation.bookings.available">
                        Stofupantanir:
                        <span v-for="(clash, idx) in date.validation.bookings.clashes" :key="idx">
                          <span v-if="idx != 0">, </span><strong>{{ clash.starfsm_nafn }} - {{ clash.skyring }}</strong>
                          {{ clash.timi_fra }}-{{ clash.timi_til }}</span
                        >
                      </div>
                      <div v-if="!date.validation.availability.available">
                        Stofuárekstur:
                        <span v-for="(clash, idx) in date.validation.availability.clashes" :key="idx">
                          <span v-if="idx != 0">, </span><strong>{{ clash.afanga_nr }}({{ clash.hopur }})</strong> {{ clash.timi_fra }}-{{
                            clash.timi_til
                          }}
                        </span>
                      </div>
                      <div v-for="(t, idx) in date.validation.teachers.filter(x => !x.available)" :key="idx">
                        Kennaraárekstur: <strong>{{ t.nafn }} {{ t.skammst }}</strong
                        >.
                        <span v-for="(clash, idx2) in t.clashes" :key="idx2"
                          ><span v-if="idx2 != 0">, </span> {{ clash.heiti }} {{ clash.timi_fra }}-{{ clash.timi_til }}</span
                        >
                      </div>
                      <div v-for="(t, idx) in date.validation.teachers.filter(x => x.locked)" :key="idx">
                        Kennaralæsing: <strong>{{ t.nafn }} {{ t.skammst }}</strong
                        >.
                        <span v-for="(locks, idx2) in t.locks" :key="idx2"
                          ><span v-if="idx2 != 0">, </span> <strong>{{ locks.lysing }}</strong> {{ locks.timi_fra }}-{{
                            locks.timi_til
                          }}</span
                        >
                      </div>
                      <div v-if="date.validation.locks.classroom_locked">
                        Stofulæsing:
                        <span v-for="(clash, idx) in date.validation.locks.classroom" :key="idx"
                          ><span v-if="idx != 0">, </span> <strong>{{ clash.lysing }}</strong> {{ clash.timi_fra }}-{{
                            clash.timi_til
                          }}</span
                        >
                      </div>
                      <div v-if="date.validation.locks.semester_locked">
                        Stofulæsing:
                        <span v-for="(clash, idx) in date.validation.locks.semester" :key="idx"
                          ><span v-if="idx != 0">, </span> <strong>{{ clash.lysing }}</strong> {{ clash.timi_fra }}-{{
                            clash.timi_til
                          }}</span
                        >
                      </div>
                      <div v-if="date.validation.holidays.length > 0">
                        Frídagar:
                        <span v-for="(clash, idx) in date.validation.holidays" :key="idx"
                          ><span v-if="idx != 0">, </span> <strong>{{ clash.heiti }}</strong> {{ clash.timi_fra
                          }}<span v-if="clash.timi_til">-{{ clash.timi_til }}</span></span
                        >
                      </div>
                      <div v-if="date.validation.student_clashes.length > 0">
                        Nemendaárekstur:
                        <span v-for="(clash, idx) in date.validation.student_clashes" :key="idx">
                          <span v-if="idx != 0">, </span><strong>{{ clash.nafn }}</strong> {{ clash.heiti }}
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </table>
      <div v-if="allValid" style="margin-top: -10px; margin-bottom: 10px">
        Engir árekstrar fundust, smelltu á "Stofna" til að stofna kennslustund/ir.
      </div>
      <b-btn variant="secondary" @click="validateBeforeSubmit()">
        {{ this.validated ? 'Stofna' : 'Athuga árekstur' }}
      </b-btn>
    </b-form>
  </Slidebar>
</template>

<script>
import school from '@/api/school';
import terms from '@/api/terms';
import staff from '@/api/staff';
import common from '@/api/common';
import requests from '@/api/requests';
import structure from '@/api/structure';
import { mapActions } from 'vuex';
import moment from 'moment';

import Slidebar from '@/components/common/Slidebar.vue';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';
import TableRowLoader from '@/components/common/TableRowLoader.vue';
import MaskedInput from 'vue-masked-input';

export default {
  name: 'create-timetable-slide-bar',
  props: ['item'], // 'editing', 'creating'],
  computed: {
    allValid() {
      return (
        this.validated &&
        this.form.dates.filter(x => x.copy).length === this.form.dates.length &&
        this.form.dates.filter(x => x.equipment === 1).length === 0
      );
    },
  },
  components: {
    Slidebar,
    Datepicker,
    TableRowLoader,
    MaskedInput,
  },
  data() {
    return {
      phone: '',
      regexTime: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
      lang: is,
      classLength: 60,
      submitted: false,
      validated: false,
      validating: false,
      saving: false,
      loading: {
        modules: false,
        groups: false,
        teachers: false,
        classrooms: false,
        equipments: false,
      },
      modules: [],
      groups: [],
      teachers: [],
      classrooms: [],
      form: {
        module: '',
        group: '',
        teachers: [],
        classrooms: [],
        dates: [],
        orderEquipment: false,
        equpment: '',
      },
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async loadEquipments() {
      try {
        this.loading.equipments = true;
        this.equipments = [];
        const response = await structure.getEquipmentList();
        this.equipments = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.equipments = false;
      }
    },
    async loadGroups() {
      this.groups = [];
      this.form.group = '';
      this.form.teachers = [];
      this.validated = false;
      if (this.form.module) {
        this.loading.groups = true;
        try {
          const response = await school.getTermModulesGroups(this.form.module.annir_afangar_id);
          this.groups = response.data.items;
          this.form.group = '';
          if (this.item.group) {
            this.form.group = this.groups.find(x => x.hopur_id === this.item.group.hopur_id);
            this.loadCurrentTeachers();
          }
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading.modules = false;
        }
      }
    },
    async loadModules() {
      if (this.item && this.item.term) {
        this.loading.modules = true;
        try {
          const response = await terms.getTermModules(this.item.term.onn_id);
          this.modules = response.data.items;
          this.form.module = '';
          if (this.item.module) {
            this.form.module = this.modules.find(x => x.annir_afangar_id === this.item.module.annir_afangar_id);
            this.loadGroups();
          }
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading.modules = false;
        }
      }
    },
    async loadTeachers() {
      this.loading.teachers = true;
      try {
        const response = await staff.getStaffList();
        this.teachers = response.data.items.map(x => ({
          ...x,
          label: `${x.nafn} - ${x.skammst}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.teachers = false;
      }
    },
    async loadClassrooms() {
      this.loading.classrooms = true;
      try {
        const response = await structure.getClassroomList({ active: 1 });
        this.classrooms = response.data.items.map(x => ({
          ...x,
          label: `${x.stofa_eink} - ${x.stofa_heiti}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.classrooms = false;
      }
    },
    async loadCurrentTeachers() {
      this.validated = false;
      if (this.form.group) {
        try {
          const response = await school.getGroupTeachers(this.form.group.hopur_id);
          const teachers = response.data.hopar_kennarar || [];
          this.form.teachers = [];
          teachers.forEach(x => {
            if (this.teachers.find(y => y.starfsm_id === x.starfsm_id)) {
              this.form.teachers.push(this.teachers.find(y => y.starfsm_id === x.starfsm_id));
            }
          });
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    async loadData() {
      this.validated = false;
      this.classLength = this.item.term.lengd_kennslustundar ? this.item.term.lengd_kennslustundar : 60;
      if (this.item.date) {
        const date = moment(this.item.date, 'DD.MM.YYYY');
        this.form.dates.push({
          date: date.toDate(),
          time_from: moment(date).format('HH:mm'),
          time_to: moment(date).add('minutes', this.classLength).format('HH:mm'),
        });
      } else {
        this.form.dates.push({ date: '', time_from: '', time_to: '' });
      }
      this.loadModules();
      this.loadTeachers();
      this.loadClassrooms();
      this.loadEquipments();
    },
    addDate() {
      this.validated = false;
      if (this.form.dates.length > 0 && this.form.dates[this.form.dates.length - 1].time_to) {
        const item = this.form.dates[this.form.dates.length - 1];
        const timeTo = moment().set('hour', item.time_to.split(':')[0]).set('minute', item.time_to.split(':')[1]).set('second', 0);
        this.form.dates.push({
          date: moment(item.date).toDate(),
          time_from: item.time_to,
          time_to: moment(timeTo).add('minute', this.classLength).format('HH:mm'),
        });
      } else {
        this.form.dates.push({ date: '', time_from: '', time_to: '' });
      }
    },
    updateTimeTo(index) {
      this.validated = false;
      if (this.regexTime.test(this.form.dates[index].time_from)) {
        const timeTo = moment()
          .set('hour', this.form.dates[index].time_from.split(':')[0])
          .set('minute', this.form.dates[index].time_from.split(':')[1])
          .set('second', 0);
        this.$set(this.form.dates[index], 'time_to', moment(timeTo).add('minute', this.classLength).format('HH:mm'));
        this.$refs[`timeTo${index}`][0].$refs.input.focus();
      }
    },
    removeDate(index) {
      this.form.dates.splice(index, 1);
    },
    async validate() {
      try {
        this.validating = true;
        for (let i = 0; i < this.form.dates.length; i += 1) {
          const item = {
            stofur: this.form.classrooms.map(x => x.stofa_id).join(','),
            teachers: this.form.teachers.map(x => x.starfsm_id).join(','),
            hopur: this.form.group.hopur_id,
            dags: moment(this.form.dates[i].date).format('DD.MM.YYYY'),
            fra: this.form.dates[i].time_from,
            til: this.form.dates[i].time_to,
          };
          const response = await school.validateTimetable(item); // eslint-disable-line
          let equipmentBooked = 0;
          if (this.form.orderEquipment) {
            const equipmentResponse = await requests.getEquipmentIsBooked({// eslint-disable-line
              equipment_id: this.form.equipment.bunadur_id,
              date: moment(this.form.dates[i].date).format('DD.MM.YYYY'),
              time_from: this.form.dates[i].time_from,
              time_to: this.form.dates[i].time_to,
            });
            equipmentBooked = equipmentResponse.data.booked;
          }

          this.$set(this.form.dates[i], 'validation', response.data);
          this.$set(this.form.dates[i], 'equipment', equipmentBooked);
          this.$set(this.form.dates[i], 'copy', response.data.can_book);
        }
        this.validated = true;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.validating = false;
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        if (!this.validated) {
          this.validate();
        } else {
          try {
            this.saving = true;
            const item = {
              onn: this.item.term.onn_id,
              afangi: this.form.module.afangi_id,
              hopur: this.form.group.hopur_id,
              stofur: this.form.classrooms.map(x => x.stofa_id).join(','),
              kennarar: this.form.teachers.map(x => x.starfsm_id).join(','),
              bunadur: this.form.orderEquipment ? this.form.equipment.bunadur_id : '',
              dags: this.form.dates
                .filter(x => x.copy)
                .map(x => ({
                  dags: moment(x.date).format('DD.MM.YYYY'),
                  timi_fra: x.time_from,
                  timi_til: x.time_to,
                })),
            };
            await common.createTimetable(item);
            this.displaySuccess('Færslur stofnaðar');
            this.$emit('closeSlidebarAndReload');
          } catch (e) {
            // Aðgerð mistókst.
            this.failed = true;
            this.$log.debug(e);
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          } finally {
            this.saving = false;
          }
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.saving = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    item() {
      this.loadData();
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<style lang="scss"></style>
