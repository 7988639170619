<template>
  <Slidebar :autoClose="true" @closeMe="closeSlidebar">
    <h4>Starfsmannaleit</h4>
    <br />
    <StaffSearch @changeStaff="closeSlidebar" :slidebar="true" />
    <!--Loader v-if="loading"/>
    <table class="table">
      <thead>
        <tr class="table-search">
          <td colspan="3">
            <b-form-input
              v-model="search.search"
              class="search"
              v-on:keyup.enter="loadList"
              placeholder="Leita"/>
          </td>
          <td style="width: 10px">
            <b-btn variant="primary" class="float-right" @click="loadList">Leita</b-btn>
          </td>
        </tr>
        <tr v-if="firsttime">
          <th>
            <LinkOrder title="Nafn"
            :filteredList="list" :list="list" column="nafn" />
          </th>
          <th>
            <LinkOrder title="Kennitala"
            :filteredList="list" :list="list" column="nafn" />
          </th>
          <th>
            <LinkOrder title="Upphafsstafir"
            :filteredList="list" :list="list" column="skammst" />
          </th>
          <th/>
        </tr>
      </thead>
      <tbody>
        <TableRowLoader v-if="loading && firsttime" colspan="8" :center="true"/>
        <TableRowEmpty v-if="!loading && firsttime && this.list.length === 0" colspan="8" :center="true"/>
        <TableRowSearchForResults v-if="!firsttime" colspan="8" :center="true"/>
        <tr v-for="item in list" :key="item.id">
          <td>{{item.nafn}}</td>
          <td>{{item.kennitala}}</td>
          <td>{{item.skammst}}</td>
          <td><b-btn variant="dark" @click="changeStaff(item)">Velja</b-btn></td>
        </tr>
      </tbody>
    </table-->
  </Slidebar>
</template>

<script>
import staff from '@/api/staff';
import { mapActions } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';
import StaffSearch from '@/components/staff/staff/StaffSearch.vue';

export default {
  name: 'staff-search-slide-bar',
  components: {
    Slidebar,
    StaffSearch,
  },
  data() {
    return {
      firsttime: false,
      loading: false,
      list: [],
      search: {
        search: '',
      },
    };
  },
  methods: {
    closeSlidebar(item) {
      this.$emit('closeSlidebar', item);
    },
    editItem() {
      this.$emit('editItem');
    },
    async loadList() {
      try {
        this.firsttime = true;
        this.loading = true;
        const response = await staff.getStaffList(this.search);
        this.list = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.list = [];
    this.search.search = '';
  },
};
</script>

<style lang="scss"></style>
