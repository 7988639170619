
import { Component, Vue } from 'vue-property-decorator';

import moment from 'moment';
import structure from '@/api/structure';
import curriculum from '@/api/curriculum';
import requests from '@/api/requests';
import common from '@/api/common';

import { Action, Getter } from 'vuex-class';

import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import PageTitle from '@/components/common/PageTitle.vue';
import Loader from '@/components/common/Loader.vue';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';
import Pagination from '@/components/common/Pagination.vue';

import ProcessRequestSlidebar from '@/components/requests/changeCourseRequests/ProcessRequestSlidebar.vue';
import CommentModal from '@/components/requests/changeCourseRequests/CommentModal.vue';

import LinkOrder from '@/components/common/LinkOrder.vue';

@Component({
  components: {
    Breadcrumbs,
    PageTitle,
    Loader,
    ProcessRequestSlidebar,
    CommentModal,
    LinkOrder,
    Datepicker,
    Pagination,
  },
})
export default class ChangeCourseRequests extends Vue {
  // eslint-disable-next-line no-undef
  [x: string]: any;
  @Action displayError!: (s: string) => void;
  @Action displaySuccess!: (s: string) => void;
  // @State loggedInUser: any;
  @Getter loggedInUserHasReadPermission: any;
  @Getter loggedInUserHasWritePermission: any;

  lang = is;
  perPage = 15;
  currentPage = 1;

  breadcrumbs = [
    { name: 'Forsíða', route: 'Dashboard' },
    { name: 'Beiðnir', route: '' },
    { name: 'Úrvinnsla brautaskipta', route: 'ChangeCourseRequests' },
  ];

  selected: any = {
    name: '',
    ssn: '',
    currentDepartment: '',
    currentCourse: '',
    requestDepartment: '',
    requestCourse: '',
    status: { id: 0, name: 'Óafgreidd' },
    dateFrom: '',
    dateTo: '',
    division: '',
  };

  loading = {
    list: false,
    courses: false,
    departments: false,
    worker: false,
  };

  saving = false;
  firsttime = false;
  selectAll = false;
  deleting = false;
  list: any[] = [];
  courses: any[] = [];
  departments: any[] = [];
  divisions: any[] = [];
  showRequestSlidebar = false;
  selectedType: string | null = '';
  selectedItem: string | null = '';
  workers: any[] = [];

  statuses = [
    { id: 0, name: 'Óafgreidd' },
    { id: 1, name: 'Samþykkt' },
    { id: 2, name: 'Hafnað' },
    { id: 3, name: 'Í bið' },
  ];

  $refs!: {
    commentModal: any;
  };

  /**
   * Computed
   */
  get getExcelData() {
    const newList = [
      [],
      ['Úrvinnsla brautaskipta'],
      [],
      [
        'Nafn',
        'Kennitala',
        'Undirskóli',
        'Sótt um',
        'Núverandi braut',
        'Umbeðin braut',
        'Staðfest braut',
        'Athugasemd nema',
        'Athugasemd afgreiðslu',
        'Staða nema',
        'Staða umsóknar',
      ],
      ...this.list.map(x => [
        x.nafn,
        `="${x.kennitala}"`,
        x.undirsk_audk,
        moment(x.date_sent_in).format('DD.MM.YYYY HH:mm'),
        x.current_course,
        x.change_course,
        x.confirmed_course,
        x.commentfromstudent,
        x.commentfromschool,
        x.stada_nema,
        this.getStatusName(x.status),
      ]),
    ];
    return newList;
  }

  get getExcelFileName() {
    return `urvinnsla_brautaskipta_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
  }

  get getCurrentDepartments() {
    return this.departments.filter(x => !this.selected.currentCourse || this.selected.currentCourse.deildir.indexOf(x.name) > -1);
  }

  get getCurrentCourse() {
    return this.courses
      .filter(x => !this.selected.currentDepartment || x.deildir.indexOf(this.selected.currentDepartment.name) > -1)
      .filter(x => !this.selected.division || x.undirskoli_id === this.selected.division.division_id);
  }

  get getRequestDepartments() {
    return this.departments.filter(x => !this.selected.requestCourse || this.selected.requestCourse.deildir.indexOf(x.name) > -1);
  }

  get getRequestCourse() {
    return this.courses
      .filter(x => !this.selected.requestDepartment || x.deildir.indexOf(this.selected.requestDepartment.name) > -1)
      .filter(x => !this.selected.division || x.undirskoli_id === this.selected.division.division_id);
  }

  get getSelected() {
    return this.list.filter(x => x.selected && x.status === 0);
  }

  get getPaginatedList() {
    const start = (this.currentPage - 1) * this.perPage;
    const end = this.currentPage * this.perPage;
    return this.list.slice(start, end);
  }

  get canViewStudent() {
    return this.loggedInUserHasWritePermission('nemendahald_nemendur');
  }

  get canEdit() {
    return this.loggedInUserHasWritePermission('beidnir_brautaskipti');
  }

  /**
   * Methods
   */
  async load() {
    try {
      this.firsttime = true;
      this.loading.list = true;
      const params = {
        name: this.selected.name,
        kennitala: this.selected.ssn,
        change_department_id: this.selected.requestDepartment ? this.selected.requestDepartment.department_id : '',
        current_department_id: this.selected.currentDepartment ? this.selected.currentDepartment.department_id : '',
        change_course_id: this.selected.requestCourse ? this.selected.requestCourse.braut_id : '',
        current_course_id: this.selected.currentCourse ? this.selected.currentCourse.braut_id : '',
        division_id: this.selected.division ? this.selected.division.division_id : '',
        date_from: this.selected.dateFrom ? moment(this.selected.dateFrom).format('DD.MM.YYYY') : '',
        date_to: this.selected.dateTo ? moment(this.selected.dateTo).format('DD.MM.YYYY') : '',
        status: this.selected.status ? this.selected.status.id : null,
      };
      const response = await requests.getChangeCourseRequests(params);
      this.list = response.data.items.map((x: any) => ({
        ...x,
        current: x.current_department ? x.current_department.split(',') : [],
        change: x.change_department ? x.change_department.split(',') : [],
      }));
    } catch (e) {
      this.$log.error(e);
      this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
    } finally {
      this.loading.list = false;
    }
  }

  async loadDepartments() {
    try {
      this.loading.departments = true;
      const response = await structure.getDepartmentList();
      this.departments = response.data.items;
    } catch (e) {
      this.$log.error(e);
      this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
    } finally {
      this.loading.departments = false;
    }
  }

  async loadDivisions() {
    try {
      const response = await structure.getDivisionList();
      this.divisions = response.data.items;
    } catch (e) {
      this.$log.error(e);
      this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
    }
  }

  async loadCourses() {
    try {
      this.loading.courses = true;
      const response = await curriculum.getCourseList({ showDepartment: 1, active: 1 });
      this.courses = response.data.items.map((x: any) => ({
        ...x,
        label: `${x.braut_kodi} ${x.heiti}`,
        deildir: (x.deild || '').split(','),
      }));
    } catch (e) {
      this.$log.error(e);
      this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
    } finally {
      this.loading.courses = false;
    }
  }

  async loadWorkerInProgress() {
    try {
      this.loading.worker = true;
      const response = await common.getWorkersInProgress('uppfaera_brautaskipti');
      this.workers = response.data.items;
    } catch (e) {
      this.$log.error(e);
      this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
    } finally {
      this.loading.worker = false;
    }
  }

  async courseChangeFailure(id: number) {
    try {
      this.loading.courses = true;
      const response = await requests.getChangeCourseRequestById(id);
      if (response.data.items.length === 1) {
        this.displayError(
          `Villa kom upp við að afgreiða brautaskiptabeiðni fyrir ${response.data.items[0].nafn}. Vinsamlegast reyndu aftur.`,
        );
      }
    } catch (e) {
      this.$log.error(e);
      this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
    }
  }

  hasStatus(status: number) {
    return this.list.filter(x => x.status === status).length > 0;
  }

  getStatusName(status: number) {
    return (this.statuses.find(x => x.id === status) || {}).name;
  }

  getSubstring(string: string, length: number) {
    if (string && string.length > length) {
      return `${string.substring(0, length)}..`;
    }

    return string || '';
  }

  async requestOnHold(item: any) {
    try {
      this.saving = true;
      // await requests.updateChangeCourseRequests(item.course_change_id, { status: 3 });
      await this.$requestsApi.updateChangeCourseRequest({
        requestId: item.course_change_id,
        updateChangeCourseRequestInputItem: {
          status: 3,
        },
      });
      this.displaySuccess('Beiðni verður sett í bið.');
      this.load();
    } catch (e) {
      this.$log.error(e);
      this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
    } finally {
      this.saving = false;
    }
  }

  async revokeCancel(item: any) {
    try {
      /* eslint-disable no-alert, no-restricted-globals */
      const conf = confirm(
        `Ert þú viss um að þú viljir afturkalla höfnun fyrir \n${item.nafn} kt. ${item.kennitala} \n${item.change_course}?`,
      );
      /* eslint-enable */
      if (conf) {
        this.saving = true;
        // await requests.updateChangeCourseRequests(item.course_change_id, { status: 0 });
        await this.$requestsApi.updateChangeCourseRequest({
          requestId: item.course_change_id,
          updateChangeCourseRequestInputItem: {
            status: 0,
          },
        });
        this.displaySuccess('Beiðni veriður sett í stöðuna óafgreitt.');
      }
    } catch (e) {
      // Aðgerð mistókst.
      this.$log.debug(e);
      if (e.response && e.response.data && e.response.data.error) {
        this.displayError(e.response.data.error);
      } else {
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    } finally {
      this.saving = false;
    }
  }

  requestProcessing(id: number) {
    return this.workers.find(x => x.reference_id === id) !== undefined;
  }

  async deleteRequest(item: any) {
    const conf = confirm(`Ert þú viss um að þú viljir eyða beiðninni ${item.nafn} - ${item.change_course} ? Ath. ekki er sendur póstur.`); // eslint-disable-line
    if (conf) {
      try {
        await requests.deleteChangeCourseComment(item.course_change_id);
        this.displaySuccess('Beiðni eytt');
        this.load();
      } catch (e) {
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    }
  }

  async deleteSelected() {
    const conf = confirm(`Ert þú viss um að þú viljir eyða ${this.getSelected.length} beiðnum ? Ath. ekki er sendur póstur.`); // eslint-disable-line
    if (conf) {
      try {
        this.deleting = true;
        for (let i = 0; i < this.getSelected.length; i += 1) {
          /* eslint-disable no-await-in-loop */
          await requests.deleteChangeCourseComment(this.getSelected[i].course_change_id);
          /* eslint-enable no-await-in-loop */
        }
        this.selectAll = false;
        this.displaySuccess('Beiðnum eytt');

        this.load();
      } catch (e) {
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.deleting = false;
      }
    }
  }

  updateAll() {
    this.list
      .filter(x => x.status === 0)
      .forEach(item => {
        this.$set(item, 'selected', this.selectAll);
      });
  }

  openRequestSlidebar(item: any, type: any) {
    this.selectedItem = item;
    this.selectedType = type;
    this.showRequestSlidebar = true;
  }

  closeSlidebar() {
    this.showRequestSlidebar = false;
    this.selectedItem = null;
    this.selectedType = null;
  }

  closeAndReloadSlidebar() {
    this.closeSlidebar();
    this.load();
  }

  changePage(page: any) {
    this.currentPage = page;
  }

  changeLimit(limit: any) {
    this.perPage = limit;
  }

  commentModal(item: any) {
    this.$refs.commentModal.show(item);
  }

  onUrvinnslaBrautaskiptaComplete() {
    this.loadWorkerInProgress();
    this.load();
  }

  onUrvinnslaBrautaskiptaFailure(data: { id: number }) {
    this.loadWorkerInProgress();
    this.courseChangeFailure(data.id);
  }

  onUrvinnslaBrautaskiptaStarted() {
    this.loadWorkerInProgress();
  }

  created() {
    this.loadWorkerInProgress();
    this.loadDepartments();
    this.loadDivisions();
    this.loadCourses();
    this.load();
  }

  mounted() {
    this.$socket.emit('joinUrvinnslaBrautaskipta');
    this.sockets.subscribe('urvinnslaBrautaskiptaStarted', this.onUrvinnslaBrautaskiptaStarted);
    this.sockets.subscribe('urvinnslaBrautaskiptaComplete', this.onUrvinnslaBrautaskiptaComplete);
    this.sockets.subscribe('urvinnslaBrautaskiptaFailure', this.onUrvinnslaBrautaskiptaFailure);
  }

  beforeDestroy() {
    this.$socket.emit('leaveUrvinnslaBrautaskipta');
    this.sockets.unsubscribe('urvinnslaBrautaskiptaStarted');
    this.sockets.unsubscribe('urvinnslaBrautaskiptaComplete');
    this.sockets.unsubscribe('urvinnslaBrautaskiptaFailure');
  }
}
