<template>
  <div>
    <b-row>
      <b-col cols="2" class="pl-0">
        <b-form-group label="Dags.frá" label-for="dateFrom">
          <datepicker
            class="datepicker_100_width"
            id="dateFrom"
            name="dateFrom"
            v-model="date_from"
            :language="lang"
            :monday-first="true"
            format="dd.MM.yyyy"
            ref="dateFromOpenPickr"
            @input="loadList()"
          ></datepicker>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label="Dags.til" label-for="dateTo">
          <datepicker
            class="datepicker_100_width"
            id="dateTo"
            name="dateTo"
            v-model="date_to"
            :language="lang"
            :monday-first="true"
            format="dd.MM.yyyy"
            ref="dateToOpenPickr"
            @input="loadList()"
          ></datepicker>
        </b-form-group>
      </b-col>
    </b-row>
    <Loader v-if="loading.list"></Loader>
    <div v-if="hasBeenSearched && list.length === 0">Engar niðurstöður fundust</div>
    <table class="table" v-if="list.length > 0">
      <thead>
        <tr>
          <th style="width: 4%">
            <b-form-checkbox v-model="allSelected" @input="updateAll()" class="d-inline-block"></b-form-checkbox>
            <b-btn
              variant="danger"
              class="btn-xs d-inline-block"
              style="font-size: 13px"
              v-if="selectedItems.length > 0"
              @click="deleteItems()"
              ><i class="fa fa-trash"></i
            ></b-btn>
          </th>
          <th>
            <LinkOrder title="Bygging" :filteredList="list" :list="list" column="bygging" second_column="stofa_eink" />
            ({{ list.length }})
          </th>
          <th>
            <LinkOrder title="Stofa" :filteredList="list" :list="list" column="stofa_eink" />
          </th>
          <th>
            <LinkOrder title="Heiti stofu" :filteredList="list" :list="list" column="stofa_heiti" second_column="stofa_eink" />
          </th>
          <th>
            <LinkOrder title="Tegund stofu" :filteredList="list" :list="list" column="teg_stofu" second_column="stofa_eink" />
          </th>

          <th>
            <LinkOrder title="Dags." :filteredList="list" :list="list" column="dags" second_column="stofa_eink" />
          </th>
          <th>
            <LinkOrder title="Frá" :filteredList="list" :list="list" column="timi_fra" second_column="stofa_eink" />
          </th>
          <th>
            <LinkOrder title="Til" :filteredList="list" :list="list" column="timi_til" second_column="stofa_eink" />
          </th>
          <th>
            <LinkOrder title="Skýring" :filteredList="list" :list="list" column="skyring" second_column="stofa_eink" />
          </th>
          <th>
            <LinkOrder title="Pantað af" :filteredList="list" :list="list" column="nafn" second_column="stofa_eink" />
          </th>
          <th>
            <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="cursor-pointer float-right">
              <i class="fa fa-file-excel-o excelText"></i>
            </download-csv>
          </th>
        </tr>
      </thead>
      <tr v-for="item in getPaginatedList" :key="item.bunadur_pantanir_id">
        <td>
          <b-form-checkbox v-if="canEdit || item.is_mine === 1" v-model="item.selected"></b-form-checkbox>
        </td>
        <td>{{ item.bygging }}</td>
        <td>{{ item.stofa_eink }}</td>
        <td>{{ item.stofa_heiti }}</td>
        <td>{{ item.teg_stofu }}</td>
        <td>{{ item.dags | moment('DD.MM.YYYY') }}</td>
        <td>{{ item.timi_fra }}</td>
        <td>{{ item.timi_til }}</td>
        <td>{{ item.skyring }}</td>
        <td>
          {{ item.nafn }}
          <span v-if="item.auka_stm.length > 0">
            <i class="fa fa-user" v-b-popover.hover.html="getStaffPopover(item)" title="Birtist einnig í stundatöflu hjá"></i>
            {{ item.auka_stm.length }}
          </span>
        </td>
        <td>
          <b-btn
            variant="primary"
            class="btn-xs float-right"
            size="sm"
            @click="openSlidebar(item.pantanir_id)"
            v-if="canEdit || item.is_mine === 1"
          >
            <i class="fa fa-pencil"></i>
          </b-btn>
        </td>
      </tr>
    </table>

    <Pagination
      :currentPage="currentPage"
      :totalRows="list.length"
      :perPage="perPage"
      v-if="list.length > 20"
      @change-page="changePage"
      @change-limit="changeLimit"
    ></Pagination>
    <classroom-order-slidebar
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
      :item="slidebarItem"
      v-if="showOrderSlidebar"
      :large="true"
    >
    </classroom-order-slidebar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import requests from '@/api/requests';
import Loader from '@/components/common/Loader.vue';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';

import LinkOrder from '@/components/common/LinkOrder.vue';
import Pagination from '@/components/common/Pagination.vue';
import ClassroomOrderSlidebar from '@/components/requests/classrooms/ClassroomOrderSlidebar.vue';

export default {
  name: 'classroom-order-list',
  props: ['classroom', 'staff', 'load'],
  components: {
    Loader,
    Datepicker,
    LinkOrder,
    Pagination,
    ClassroomOrderSlidebar,
  },
  computed: {
    getPaginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.list.slice(start, end);
    },
    selectedItems() {
      return this.list.filter(x => x.selected);
    },
    getExcelData() {
      const newList = [
        [],
        ['Stofupantanir'],
        [],
        ['Stofa', 'Dags.', 'Tími frá', 'Tími til', 'Pantað af', 'Skýring'],
        ...this.list.map(x => [x.stofa_eink, moment(x.dags).format('DD.MM.YYYY'), x.timi_fra, x.timi_til, x.nafn, x.skyring]),
      ];
      return newList;
    },
    getExcelFileName() {
      return `stofupantanir_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    hasBeenSearched() {
      return !this.loading && (this.classroom || this.staff);
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('beidnir_stofa');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      perPage: 20,
      currentPage: 1,
      showOrderSlidebar: false,
      slidebarItem: '',
      allSelected: false,
      lang: is,
      list: [],
      loading: false,
      date_from: moment().toDate(),
      date_to: moment().toDate(),
    };
  },
  methods: {
    getStaffPopover(item) {
      return item.auka_stm.map(x => x.nafn).join('<br/>');
    },
    openSlidebar(id) {
      if (this.canEdit) {
        this.slidebarItem = {
          id,
        };
        this.showOrderSlidebar = true;
      }
    },
    closeSlidebar() {
      this.slidebarItem = '';
      this.showOrderSlidebar = false;
    },
    closeAndReloadSlidebar() {
      this.closeSlidebar();
      this.loadList();
    },
    updateAll() {
      this.list
        .filter(x => this.canEdit || x.is_mine === 1)
        .forEach(item => {
          this.$set(item, 'selected', this.allSelected);
        });
    },
    async loadList() {
      try {
        this.loading = true;
        this.list = [];
        const params = {};
        if (this.classroom) {
          params.classroomId = this.classroom.stofa_id;
        }
        if (this.staff) {
          params.staffId = this.staff.starfsm_id;
        }
        params.date_from = moment(this.date_from).format('DD.MM.YYYY');
        params.date_to = moment(this.date_to).format('DD.MM.YYYY');
        const response = await requests.getClassroomRequestList(params);
        this.list = response.data.items.map(x => ({
          ...x,
          auka_stm: x.auka_stm ? JSON.parse(x.auka_stm) : [],
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    deleteItems() {
      /* eslint-disable */
      const conf = confirm(
        `Ertu viss um að þú viljir eyða ${this.selectedItems.length} pöntunum ? Ath. sá sem bókaði stofuna mun vera látinn vita ef pöntun er ekki liðin.`,
      );
      /* eslint-enable */
      if (conf) {
        try {
          const promises = [];
          this.selectedItems.forEach(item => {
            promises.push(requests.createClassroomRequests({ orderId: item.pantanir_id, deleted: 1 }));
          });

          Promise.all(promises).then(() => {
            this.allSelected = false;
            this.displaySuccess('Pöntunum eytt');
            this.loadList();
          });
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    load() {
      if (this.load) {
        this.loadList();
      }
    },
  },
  created() {
    if (this.load) {
      this.loadList();
    }
  },
};
</script>

<style lang="scss"></style>
