<template>
  <page>
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <PageTitle title="Afgreiðsla leyfisbeiðna" />
    <PageMenu route="ReviewVacation" :selectedPage="page" :items="menu" />
    <AbsencesPending v-if="page === 'pending'" :absenceTypes="'2,3'"></AbsencesPending>
    <AbsencesAll v-if="page === 'all'" :absenceTypes="'2,3'"></AbsencesAll>
  </page>
</template>

<script>
import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import PageTitle from '@/components/common/PageTitle.vue';
import PageMenu from '@/components/common/PageMenu.vue';

import AbsencesPending from '@/components/requests/reviewAbsence/AbsencesPending.vue';
import AbsencesAll from '@/components/requests/reviewAbsence/AbsencesAll.vue';

export default {
  name: 'review-vacation',
  components: {
    Breadcrumbs,
    PageTitle,
    AbsencesPending,
    AbsencesAll,
    PageMenu,
  },
  data() {
    return {
      menu: [
        {
          name: 'Óafgreiddar leyfisbeiðnir',
          page: 'pending',
        },
        {
          name: 'Allar leyfisbeiðnir',
          page: 'all',
        },
      ],
      page: 'pending',
      breadcrumbs: [
        { name: 'Forsíða', route: 'Dashboard' },
        { name: 'Beiðnir', route: '' },
        { name: 'Afgreiðsla leyfisbeiðna', route: 'ReviewAbsence' },
      ],
    };
  },
  beforeRouteUpdate(to, from, next) {
    if (to && to.query && to.query.page) {
      this.page = to.query.page;
    }
    next();
  },
  created() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
  },
};
</script>
<style lang="scss"></style>
