<template>
  <Slidebar @closeMe="closeSlidebar" :extraLarge="true">
    <div class="d-inline-block mt-2">
      <h4>Afrita frá öðrum einkunnaskala</h4>
    </div>
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <div class="alert alert-info p2">Ath. Eftir að skali er afritaður þá er hægt að breyta eftir þörfum.</div>
      <div class="mb-3">
        <b-btn variant="primary" size="sm" class="mr-2" v-if="canEdit" @click="validateBeforeSubmit" :disabled="saving"> Afrita </b-btn>
        <b-btn variant="dark" size="sm" @click="closeSlidebar">Hætta við</b-btn>
      </div>
      <v-select
        id="scaleType"
        name="scaleType"
        v-model="selectedItem"
        :options="list"
        ref="selectCourseDropdown"
        label="heiti"
        :clearable="false"
      >
      </v-select>
      <table v-if="selectedItem" class="table less-padding mt-3" style="font-size: 13px">
        <thead>
          <tr>
            <th>Einkunn</th>
            <th>Hlutfall frá</th>
            <th>Hlutfall til</th>
            <th>Staðið</th>
          </tr>
        </thead>
        <tr v-for="item in selectedItem.skali" :key="item.einkunnaskali_id">
          <td>
            <span v-if="item.einkunn_a">{{ item.einkunn_a }}</span
            ><span v-else>{{ item.einkunn }}</span>
          </td>
          <td>{{ item.hlutfall_fra }}</td>
          <td>{{ item.hlutfall_til }}</td>
          <td><yes-no :item="item.stadid"></yes-no></td>
        </tr>
      </table>
    </b-form>
  </Slidebar>
</template>

<script>
import types from '@/api/types';
import { mapActions, mapGetters } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';
import YesNo from '@/components/common/YesNo.vue';

export default {
  name: 'grade-scale-copy-slidebar',
  props: ['item'],
  components: {
    Slidebar,
    YesNo,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {
      saving: false,
      loading: false,
      submitted: false,
      list: [],
      selectedItem: null,
      form: {
        name: '',
      },
    };
  },
  methods: {
    closeSlidebar(id) {
      this.$emit('closeSlidebar', id);
    },
    async loadList() {
      try {
        this.loading = true;
        const response = await types.getGradeScaleCopy();
        this.list = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.saving = true;
          const data = {
            scale_to: this.item.teg_skala,
            scale_from: this.selectedItem.teg_skala,
          };
          await types.copyGradeScaleCopy(data);
          this.displaySuccess(`${this.selectedItem.heiti} hefur verið afritaður.`);
          this.closeSlidebar(this.item.teg_skala);
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.saving = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadList();
  },
};
</script>

<style lang="scss"></style>
