<template>
  <div>
    <b-row class="mt-2">
      <b-col class="pl-0">
        <v-select v-model="selectedCourse" :clearable="false" :options="courses" class="mb-1" @input="loadStructure()"> </v-select>
        <Loader v-if="loading.courses"></Loader>
        <div v-if="!selectedCourse">Vinsamlegast veljið braut</div>
        <div v-if="selectedCourse && selectedCourse.fj_afangar_skirteini === 0">Ekki er búið að vista brautarkröfu fyrir valda braut.</div>
        <div v-if="selectedCourse && selectedCourse.fj_afangar_skirteini > 0">
          <div v-if="displayTypes">
            <div
              class="d-inline-block mr-4"
              v-for="(t, idx) in types"
              :key="`type-${idx}`"
              @mouseenter="hoverName = t.name"
              @mouseleave="hoverName = ''"
            >
              <div class="d-inline-block" style="width: 10px; height: 10px" :style="`background-color:${t.border}`"></div>
              {{ t.name }}
            </div>
          </div>
          <div v-else>
            <div
              class="d-inline-block mr-4"
              v-for="(t, idx) in structureList"
              :key="`type-${idx}`"
              @mouseenter="hoverName = t.name"
              @mouseleave="hoverName = ''"
            >
              <div class="d-inline-block" style="width: 10px; height: 10px" :style="`background-color:${t.border}`"></div>
              {{ t.name }}
            </div>
          </div>
        </div>
      </b-col>
      <b-col>
        <b-row style="margin-top: -10px" v-if="selectedCourse && selectedCourse.fj_afangar_skirteini > 0">
          <b-col class="pl-0">
            <b-form-group>
              <label class="d-inline-block mr-3">Lita eftir</label>
              <b-form-radio v-model="displayTypes" class="d-inline-block mr-2" name="radio-inline" :value="true">Tegund</b-form-radio>
              <b-form-radio v-model="displayTypes" class="d-inline-block" name="radio-inline" :value="false">Uppbyggingu</b-form-radio>
            </b-form-group>
            <div>
              <div>
                <b-form-checkbox v-model="show.notPartOfCourse" class="d-inline-block" />Sýna áfanga sem eru ekki partur af
                brautaruppbyggingu
              </div>
              <div><b-form-checkbox v-model="show.fail" class="d-inline-block" />Sýna falláfanga</div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div v-if="selectedCourse && selectedCourse.fj_afangar_skirteini > 0">
      <Loader v-if="loading.terms"></Loader>
      <b-row class="mt-3" v-if="!loading.terms">
        <b-col cols="2" v-for="(term, idx) in terms.filter(x => getFilteredModules(x.afangar).length > 0)" :key="idx" class="pl-0 mb-3"
          ><span>{{ term.onn_eink }}</span>
          <div
            v-for="(mod, idx2) in getFilteredModules(term.afangar)"
            :key="idx2"
            class="courseTermModuleBox"
            :style="`border-color:${mod.border}; background-color:${mod.background}`"
          >
            <div style="font-size: 10px">{{ mod.afanga_heiti }}</div>
            {{ mod.afangi_audkenni }} (<span v-b-tooltip :title="`${mod.stada}`">{{ mod.stada_kodi }}</span
            >)
            <i class="fa fa-check float-right" title="Áfanga lokið" v-b-tooltip v-if="mod.stadid === 1"></i>
            <i
              class="fa fa-remove float-right"
              title="Áfangi ekki staðinn"
              v-b-tooltip
              v-if="mod.stadid === 0 && mod.stada !== 1 && mod.stada !== 40 && mod.stada !== 41"
            ></i>
          </div>
        </b-col>
      </b-row>
      <div v-if="getStructureModulesNotConnected.length > 0 && !loading.terms" style="font-size: 13px">
        <strong>Áfangar í brautarkröfu sem vantar í námsferil ({{ getStructureModulesNotConnected.length }}) : </strong>
        <span v-for="(i, idx) in getStructureModulesNotConnected" :key="i.afangi_braut"
          ><span v-if="idx !== 0">, </span> {{ i.afangi_braut }}</span
        >
      </div>
      <br />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import students from '@/api/students';

import Loader from '@/components/common/Loader.vue';

export default {
  name: 'student-study-record-course-by-terms',
  props: ['studentId'],
  components: {
    Loader,
  },
  computed: {
    structureList() {
      return [...new Set(this.structure.uppbygging.map(x => x.uppbygging_heiti))]
        .sort((a, b) => {
          return (a || 1000) - (b || 1000);
        })
        .map((x, idx) => ({
          name: x,
          border: this.colors.length > idx ? this.colors[idx].border : '',
          background: this.colors.length > idx ? this.colors[idx].background : '',
        }));
    },
    getStructureModules() {
      return (
        (this.structure.uppbygging || [])
          // .filter(x => x.uppbygging_tegund !== 'Val')
          .map(x => x.namsgreinar.map(y => y.afangar).flat())
          .flat()
      );
    },
    getStructureModulesConnected() {
      return this.getStructureModules.filter(x => x.afangi_ferill);
    },
    getStructureModulesNotConnected() {
      return this.getStructureModules
        .filter(x => !x.afangi_ferill)
        .filter(x => x.ovirk !== 1)
        .sort((a, b) => {
          return a.afangi_braut.localeCompare(b.afangi_braut);
        });
    },
    canEdit() {
      return !this.slidebar && this.loggedInUserHasWritePermission('nemendahald_nemendur');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      loading: {
        terms: false,
        courses: false,
        structure: false,
      },
      show: {
        notPartOfCourse: true,
        fail: true,
      },
      terms: [],
      courses: [],
      structure: [],
      selectedCourse: '',
      displayTypes: true,
      hoverName: '',
      types: [
        { name: 'Kjarni', border: 'rgb(152, 19, 156)', background: 'rgba(152, 19, 156, 0.30)' },
        { name: 'Val', border: 'rgb(104, 221, 149)', background: 'rgba(104, 221, 149, 0.30)' },
        { name: 'Bundið pakkaval', border: 'rgb(245, 199, 92)', background: 'rgba(245, 199, 92, 0.30)' },
        { name: 'Bundið áfangaval', border: 'rgb(42, 186, 217)', background: 'rgba(42, 186, 217, 0.30)' },
      ],
      colors: [
        { border: 'rgb(90, 135, 112)', background: 'rgba(90, 135, 112, 0.30)' },
        { border: 'rgb(154, 137, 181)', background: 'rgba(154, 137, 181, 0.30)' },
        { border: 'rgb(241, 134, 54)', background: 'rgba(241, 134, 54, 0.30)' },
        { border: 'rgb(217, 58, 55)', background: 'rgba(217, 58, 55, 0.30)' },
        { border: 'rgb(111, 169, 171)', background: 'rgba(111, 169, 171, 0.30)' },
        { border: 'rgb(166, 177, 46)', background: 'rgba(166, 177, 46, 0.30)' },
        { border: 'rgb(92, 155, 188)', background: 'rgba(92, 155, 188, 0.30)' },
        { border: 'rgb(245, 136, 141)', background: 'rgba(245, 136, 141, 0.30)' },
        { border: 'rgb(245, 175, 41)', background: 'rgba(245, 175, 41, 0.30)' },
        { border: 'rgb(0, 136, 185)', background: 'rgba(0, 136, 185, 0.30)' },
        { border: 'rgb(64, 120, 135)', background: 'rgba(64, 120, 135, 0.30)' },
        { border: 'rgb(211, 63, 51)', background: 'rgba(211, 63, 51, 0.30)' },
        { border: 'rgb(162, 176, 31)', background: 'rgba(162, 176, 31, 0.30)' },
        { border: 'rgb(240, 177, 38)', background: 'rgba(240, 177, 38, 0.30)' },
        { border: 'rgb(0, 135, 191)', background: 'rgba(0, 135, 191, 0.30)' },
        { border: 'rgb(178, 183, 187)', background: 'rgba(178, 183, 187, 0.30)' },
        { border: 'rgb(114, 172, 174)', background: 'rgba(114, 172, 174, 0.30)' },
        { border: 'rgb(156, 138, 180)', background: 'rgba(156, 138, 180, 0.30)' },
        { border: 'rgb(238, 180, 36)', background: 'rgba(238, 180, 36, 0.30)' },
      ],
    };
  },
  methods: {
    async loadTerms() {
      try {
        this.loading.terms = true;
        const response = await students.getStudentStudyRecord(this.studentId);
        this.terms = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.terms = false;
      }
    },
    async loadCourses() {
      try {
        this.loading.courses = true;
        const response = await students.getStudentAllCourses(this.studentId);
        this.courses = response.data.items.map(x => ({
          ...x,
          label: `${x.heiti} - ${x.braut_kodi} - Dags: ${x.dags_byrjun ? x.dags_byrjun : ''} - ${x.dags_endar ? x.dags_endar : ''} `,
        }));
        if (this.courses.length > 0) {
          this.selectedCourse = this.courses.find(x => !x.dags_endar || moment(x.dags_endar).isAfter(moment()));
        }
        if (this.selectedCourse) {
          this.loadStructure();
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.courses = false;
      }
    },
    async loadStructure() {
      try {
        this.loading.structure = true;
        const response = await students.getStudyRecordCourse(this.studentId, this.selectedCourse.braut_id);
        const result = response.data;
        this.structure = result.length > 0 ? result[0] : {};
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.structure = false;
      }
    },
    getFilteredModules(modules) {
      return modules
        .filter(
          x => this.show.notPartOfCourse || this.getStructureModulesConnected.map(y => y.afangi_ferill).indexOf(x.afangi_audkenni) > -1,
        )
        .filter(x => this.show.fail || x.stadid === 1 || [1, 40, 41].indexOf(x.stada_afanga) > -1)
        .map(x => ({ ...x, border: this.getBorder(x), background: this.getBackground(x) }));
    },
    getBorder(m) {
      const item = (this.structure.uppbygging || []).find(
        x => x.namsgreinar.filter(y => y.afangar.filter(z => z.afangi_ferill === m.afangi_audkenni).length > 0).length > 0,
      );
      if (item) {
        const type = item.uppbygging_tegund;
        const structure = item.uppbygging_heiti;
        if (this.displayTypes && this.types.find(x => x.name === type)) {
          return this.types.find(x => x.name === type).border;
        }
        if (!this.displayTypes && this.structureList.findIndex(x => x.name === structure) > -1) {
          return this.structureList.find(x => x.name === structure).border;
        }
      }
      return '';
    },
    getBackground(m) {
      const item = (this.structure.uppbygging || []).find(
        x => x.namsgreinar.filter(y => y.afangar.filter(z => z.afangi_ferill === m.afangi_audkenni).length > 0).length > 0,
      );
      if (item) {
        const type = item.uppbygging_tegund;
        const structure = item.uppbygging_heiti;
        if ((this.displayTypes && this.hoverName === type) || (!this.displayTypes && this.hoverName === structure)) {
          return 'rgba(255,0,0, 0.5)';
        }
        if (this.displayTypes && this.types.find(x => x.name === type)) {
          return this.types.find(x => x.name === type).background;
        }
        if (!this.displayTypes && this.structureList.findIndex(x => x.name === structure) > -1) {
          return this.structureList.find(x => x.name === structure).background;
        }
      }

      return '';
    },

    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadCourses();
    this.loadTerms();
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../style/variables';
.courseTermModuleBox {
  padding: 5px;
  margin-right: 10px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  font-size: 13px;
  &.borderKjarni {
    border-color: rgb(152, 19, 156); // $primary;
    background-color: rgba(152, 19, 156, 0.1); // $primary;
  }
  &.borderKjorsvid {
  }
  &.borderVal {
    border-color: rgb(104, 221, 149); // $success;
    background-color: rgba(104, 221, 149, 0.1); // $success;
  }
  &.borderFornam {
  }
  &.borderPakkaval {
    border-color: rgb(245, 199, 92); // $warning;
    background-color: rgba(245, 199, 92, 0.1); // $warning;
  }
  &.borderAfangaval {
    border-color: rgb(42, 186, 217); // $secondary;
    background-color: rgba(42, 186, 217, 0.1); // $secondary;
  }
}
</style>
