<template>
  <div>
    <table class="table less-padding no-border">
      <tr>
        <th width="20%">Útgáfudagsetning</th>
        <td>{{ item.dags_gildis | moment('DD.MM.YYYY') }}</td>
      </tr>
      <tr>
        <th>Stofnað</th>
        <td>{{ item.time_create | moment('DD.MM.YYYY HH:mm:ss') }}</td>
      </tr>
      <tr>
        <th>Stofnað af</th>
        <td>{{ item.stofnad_af_nafn }}</td>
      </tr>
      <tr>
        <th>Síðast breytt</th>
        <td>{{ item.time_edit | moment('DD.MM.YYYY HH:mm:ss') }}</td>
      </tr>
      <tr>
        <th>Breytt af</th>
        <td>{{ item.breytt_af_nafn }}</td>
      </tr>
    </table>
    <h5>Færslur úr dagbók</h5>
    <i>
      Ath. eftirfarandi færslur fundust í dagbók fyrir kóða brautar. Ef braut með sama kóða hefur verið stofnaður oft þá eru færslurnar
      fyrir allar brautirnar.
    </i>
    <div v-if="list.length === 0 && !loading" class="mt-4">Engar færslur fundust í dagbók</div>
    <table class="table table-hover less-padding no-border" style="font-size: 13px" v-if="list.length > 0">
      <thead>
        <tr>
          <th>Dags.</th>
          <th>Breytt af</th>
          <th>Aðgerð</th>
          <th>Svæði</th>
          <th>Atriði</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, idx) in list" :key="idx">
          <td style="white-space: nowrap">{{ item.dags | moment('DD.MM.YYYY HH:mm:ss') }}</td>
          <td style="white-space: nowrap">{{ item.nafn }}</td>
          <td>{{ item.adgerd_heiti }}</td>
          <td>{{ item.svaedi }}</td>
          <td>{{ item.atridi }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import common from '@/api/common';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'course-history',
  components: {},
  props: ['id', 'item'],
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_brautir');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('namskra_brautir');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      loading: false,
      list: [],
      showSlidebar: false,
    };
  },
  methods: {
    async loadList() {
      this.loading = true;
      try {
        const response = await common.getDiary('Braut', { search: `BrautKodi=${this.item.braut_kodi}` });

        this.list = response.data.items.map(x => ({
          ...x,
          svaedi: x.svaedi ? x.svaedi.replaceAll(',', ', ') : '',
          atridi: x.atridi ? x.atridi.replaceAll(',', ', ') : '',
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadList();
  },
};
</script>
<style lang="scss"></style>
