<template>
    <Slidebar :autoClose="false" @closeMe="closeSlidebar">
        <h4 v-if="field">Breyta kjörgrein</h4>
        <h4 v-else>Skrá kjörgrein á braut</h4>
        <b-form @submit.prevent="validateBeforeSubmit" novalidate>
            <table class="table less-padding">
                <tr>
                    <td style="width: 20%; vertical-align: top">Braut</td>
                    <td>
                        {{ course[0].heiti }}
                    </td>
                </tr>
                <tr>
                    <td style="width: 20%; vertical-align: top">Námsgrein</td>
                    <td>
                        <Loader v-if="loading.modules"></Loader>
                        <v-select v-if="!loading.modules && !field" id="module" name="module" v-model="form.module"
                            :options="modules" :clearable="false">
                        </v-select>
                        <span v-if="field">{{ form.module }}</span>
                    </td>
                </tr>
                <tr>
                    <td style="width: 20%; vertical-align: top">Röð</td>
                    <td> <b-form-group label-for="rod" :state="submitted && errors.has('rod') ? false : ''"
                            :invalid-feedback="errors.first('rod')">
                            <b-form-input id="rod" name="rod" type="number" v-model="form.rod"
                                :state="submitted && errors.has('rod') ? false : ''" data-vv-as="rod"></b-form-input>
                        </b-form-group></td>
                </tr>
            </table>
        </b-form>
        <b-btn variant="primary" @click="validateBeforeSubmit()" :disabled="saving">Vista</b-btn>
        <b-btn variant="danger" class="float-right" v-if="field" @click="deleteCoursField()" :disabled="saving">Eyða</b-btn>
    </Slidebar>
</template>
  
<script>
import { mapActions } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';
import Loader from '@/components/common/Loader.vue';
import students from '@/api/students';


export default {
    name: 'student-edit-course-fields-slidebar',
    props: ['studentId', 'course', 'field'],
    components: {
        Slidebar,
        Loader,
    },

    data() {
        return {
            saving: false,
            submitted: false,
            loading: {
                modules: false,
            },
            modules: [],
            form: {
                module: '',
                rod: '',
            },
        };
    },
    methods: {
        async loadModules() {
            try {
                this.loading.modules = true;
                const response = await students.getStudentCoursesFieldsModules(this.course[0].nemandi_brautir_id);
                this.modules = response.data.items.map(x => ({
                    namsgrein_id: x.namsgrein_id,
                    label: `${x.kodi} ${x.heiti}`,
                }));
                this.form.module = this.modules[0];
            } catch (e) {
                this.$log.error(e);
                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            } finally {
                this.loading.modules = false;
            }

        },
        async validateBeforeSubmit() {
            try {
                this.submitted = true;
                this.saving = true;
                this.errorList = [];
                await this.$validator.validateAll().then(response => {
                    if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
                });
                const item = {
                    nemandi_kjorsvid_id: this.field ? this.field.nemandi_kjorsvid_id : '', // ef edit
                    nemandi_braut_id: this.course[0].nemandi_brautir_id,
                    namsgrein_id: this.form.module ? this.form.module.namsgrein_id : '',
                    rod: this.form.rod ? this.form.rod : '',
                };
                await students.postStudentCourseField(this.studentId, item);
                if (this.field) {
                    this.displaySuccess('Kjörgrein breytt');
                } else {
                    this.displaySuccess('Kjörgrein skráð');
                }
                this.$emit('closeAndReloadSlidebar');
            } catch (e) {
                // Aðgerð mistókst.
                this.failed = true;
                this.$log.debug(e);
                this.displayError(`Óvænt villa að skrá kjörgrein. Vinsamlegast reyndu aftur.`);
            } finally {
                this.saving = false;
            }
        },
        async deleteCoursField() {
            const conf = confirm(`Ertu viss um að þú viljir eyða kjörgrein?`); // eslint-disable-line
            if (conf) {
                try {
                    const item = {
                        nemandi_kjorsvid_id: this.field ? this.field.nemandi_kjorsvid_id : '',
                        delete: 1,
                    };
                    await students.postStudentCourseField(this.studentId, item);
                    this.displaySuccess('Kjörgrein hefur verið eytt.');
                    this.$emit('closeAndReloadSlidebar');
                } catch (e) {
                    this.$log.error(e);
                    this.displayError(`Óvænt villa að eyða kjörgrein. Vinsamlegast reyndu aftur.`);
                }
            }
        },

        closeSlidebar() {
            this.$emit('closeSlidebar');
        },
        ...mapActions({
            displayError: 'displayError',
            displaySuccess: 'displaySuccess',
        }),
    },
    created() {
        if (!this.field) {
            this.loadModules();
        }
        else {
            this.form.module = `${this.field.namsgrein_kodi} ${this.field.namsgrein}`;
            this.form.rod = this.field.rod;
        }
    },
};
</script>
  
<style lang="scss"></style>
  