<template>
  <Slidebar :extraLarge="true" @closeMe="closeSlidebar">
    <h4>Skráning sérúrræða í námi</h4>
    <div>
      <h5>Nemandi</h5>
      <div v-if="searchStudent">
        <b-form-group label-for="heiti" :state="submitted && errors.has('heiti') ? false : ''"
          :invalid-feedback="errors.first('heiti')">
          <b-form-input v-model="search" v-on:keyup.enter="loadList" placeholder="Leita eftir nafni eða kennitölu"
            :disabled="loading.students" />
        </b-form-group>
        <i class="fa fa-spin fa-spinner" v-if="loading.students"></i>
        <b-btn variant="primary" class="btn-xs float-right" style="margin-top: -5px" @click="loadList()">Leita</b-btn>
        <strong v-if="list.length > 0" class="mt-3 mb-2">Smelltu á nemandann til þess að velja hann</strong>
        <div v-for="student in list" :key="student.kennitala" @click="selectStudent(student)" class="cursor-pointer">
          {{ student.nafn }} {{ student.kennitala }}
        </div>
      </div>
      <div v-else>
        {{ form.studentName }} kt. {{ form.studentSsn }}
        <i class="fa fa-pencil cursor-pointer" @click="searchStudent = true"></i>
      </div>
    </div>

    <div v-if="!searchStudent && form.studentId">
      <h5 class="mb-2 mt-4">Almenn sérúrræði</h5>
      <table class="table less-padding no-border mb-1" style="font-size: 13px">
        <thead>
          <tr>
            <td v-for="(t, idx) in studentCommon" :key="`commonType${idx}`">{{ t.heiti }}</td>
          </tr>
        </thead>
        <tr>
          <td v-for="(item, idx) in studentCommon" :key="`common${idx}`">
            <b-form-checkbox v-model="item.selected" v-if="item.tegund === 0"></b-form-checkbox>
            <b-form-input v-model="item.text" v-if="item.tegund === 1"
              style="height: 30px; font-size: 13px"></b-form-input>
          </td>
        </tr>
      </table>
      <div v-if="studentCurrent.common.length > 0" style="font-size: 13px" class="pb-1">
        <i class="fa fa-exclamation-triangle"></i>
        Ath. nemandinn átti óafgreidda umsókn. Sérúrræðin úr þeirri umsókn birtast hérna. Þeirri umsókn mun vera breytt
        og samþykkt með þeim
        breytingum sem eru gerðar hérna.
      </div>
      <div v-else style="font-size: 13px" class="pb-2">
        <i class="fa fa-exclamation-triangle"></i>
        Þegar sérúrræði eru stofnuð hérna þá verður til umsókn sem er sjálfkrafa samþykkt. Ekki þarf að samþykkja hana
        sérstaklega.
      </div>
      <div class="pt-1 pb-1"><b-form-input v-model="commentGeneral" placeholder="Athugasemd sem fylgir umsókn"
          style="height: 30px; font-size: 13px; width:40%"></b-form-input></div>
      <b-btn variant="primary" size="sm" class="mt-1" @click="saveData(0)">Staðfesta</b-btn>
      <h5 class="mb-2 mt-4">Sérúrræði tengd áfanga</h5>
      <table class="table less-padding no-border" style="font-size: 13px; margin-bottom:0px !important">
        <thead>
          <tr>
            <td>Áfangi</td>
            <td v-for="(t, idx) in typeList" :key="`moduesType${idx}`">{{ t.heiti }}</td>
          </tr>
        </thead>
        <tbody v-for="(item, idx) in studentModule" :key="`module${idx}`" style="border-color: transparent">
          <tr>
            <td>{{ item.namsgrein_afanganr }}</td>
            <td v-for="(resource, idx2) in item.resources" :key="`moduleType${idx}-${idx2}`">
              <b-form-checkbox v-model="resource.selected" v-if="resource.tegund === 0"></b-form-checkbox>
              <b-form-input v-model="resource.text" v-if="resource.tegund === 1"
                style="height: 30px; font-size: 13px"></b-form-input>
            </td>
          </tr>
          <tr v-if="item.serurraedi.length > 0">
            <td colspan="100%">
              <i class="fa fa-exclamation-triangle"></i>
              Ath. nemandinn átti óafgreidda umsókn. Sérúrræðin úr þeirri umsókn birtast hérna. Þeirri umsókn mun vera
              breytt og samþykkt
              með þeim breytingum sem eru gerðar hérna.
            </td>
          </tr>
        </tbody>
      </table>
      <div style="font-size: 13px;" class="pt-2 pb-2">
        <i class="fa fa-exclamation-triangle"></i>
              Þegar sérúrræði eru stofnuð hérna þá verður til umsókn sem er sjálfkrafa samþykkt. Ekki þarf að samþykkja
              hana
              sérstaklega.
      </div>
      <div class="pt-2 pb-2">
        <b-form-input v-model="commentCourse" placeholder="Athugasemd sem fylgir umsókn"
            style="height: 30px; font-size: 13px; width:40%"></b-form-input>
      </div>
      <b-btn variant="primary" size="sm" class="mt-1" @click="saveData(1)">Staðfesta</b-btn>
      <h5 class="mb-2 mt-4">Umsóknir</h5>
      <div v-if="studentApplications.length === 0 && !loading.data">Engar umsóknir fundust</div>
      <Loader v-if="loading.data"></Loader>

      <table class="table table-striped less-padding no-border mb-1" style="font-size: 13px"
        v-if="studentApplications.length > 0 && !loading.data">
        <thead>
          <tr>
            <td>Dags. skráð</td>
            <td>Önn</td>
            <td>Áfangi</td>
            <td v-for="(t, idx) in typeList" :key="`applicationTypes${idx}`" style="max-width: 80px">{{ t.heiti }}</td>
            <td>Staða</td>
            <td>Dags.</td>
          </tr>
        </thead>
        <tr v-for="(item, idx) in studentApplications" :key="`module${idx}`">
          <td>{{ item.dags | moment('DD.MM.YYYY') }}</td>
          <td>{{ item.onn_eink }}</td>
          <td style="white-space: nowrap">{{ item.namsgrein_afanganr }}</td>
          <td v-for="(t, idx2) in typeList" :key="`application${idx}-${idx2}`" style="max-width: 200px">
            <yes-no v-if="t.tegund === 0" :item="getResource(t, item)"></yes-no>
            <span v-else>{{ getResource(t, item) }}</span>
          </td>
          <td style="white-space: nowrap">{{ item.stada }}</td>
          <td>{{ item.dags_samt | moment('DD.MM.YYYY') }}</td>
        </tr>
      </table>
    </div>
  </Slidebar>
</template>

<script>
import students from '@/api/students';
import types from '@/api/types';

import Slidebar from '@/components/common/Slidebar.vue';
import Loader from '@/components/common/Loader.vue';
import { mapActions, mapGetters } from 'vuex';
import YesNo from '@/components/common/YesNo.vue';

export default {
  name: 'create-special-resource-request-slidebar',
  props: ['ssn', 'studentCardView'],
  components: {
    Slidebar,
    Loader,
    YesNo,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('beidnir_serurraedi');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      search: '',
      form: {
        studentId: '',
        studentName: '',
        studentSsn: '',
      },
      list: [],
      common: [],
      studentApplications: [],
      studentCurrent: {
        common: [],
        modules: [],
      },
      studentCommon: [],
      studentModule: [],
      typeList: [],
      loading: {
        students: false,
        types: false,
        data: false,
      },
      firsttime: false,
      submitted: false,
      searchStudent: true,
      commentGeneral: '',
      commentCourse: '',
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    closeAndReloadSlidebar() {
      this.$emit('closeAndReload');
    },
    async loadList() {
      try {
        this.firsttime = true;
        this.loading.students = true;
        const response = await students.getStudentList({ search: this.search, active: 1 });
        this.list = response.data.items;
        if (this.ssn && this.list.length === 1) {
          this.selectStudent(this.list[0]);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.students = false;
      }
    },
    async loadTypes() {
      try {
        this.loading.types = true;
        const response = await types.getSpecialResourceList({ active: 1 });
        this.typeList = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.types = false;
      }
    },
    async loadStudentData() {
      try {
        this.loading.data = true;
        const response = await students.getStudentSpecialResourceApplications(this.form.studentId);
        this.studentApplications = response.data.items;
        const response2 = await students.getStudentSpecialResourcesCommon(this.form.studentId);
        this.studentCurrent.common = response2.data.items;
        this.studentCommon = JSON.parse(JSON.stringify(this.typeList));
        this.studentCommon.forEach(item => {
          this.$set(item, 'selected', false);
          this.$set(item, 'text', '');
          const resource =
            response2.data.items.length > 0
              ? response2.data.items[0].tegundir.find(x => x.teg_serurraedi_id === item.teg_serurraedi_id)
              : undefined;
          if (item.tegund === 0 && resource !== undefined) {
            this.$set(item, 'selected', true);
          }
          if (item.tegund === 1 && resource !== undefined) {
            this.$set(item, 'text', resource.text);
          }
        });

        const response3 = await students.getStudentSpecialResourcesModule(this.form.studentId);
        this.studentCurrent.modules = response3.data.items;
        this.studentModule = response3.data.items;
        this.studentModule.forEach(item => {
          this.$set(item, 'resources', JSON.parse(JSON.stringify(this.typeList)));
          item.resources.forEach(r => {
            this.$set(r, 'selected', false);
            this.$set(r, 'text', '');
            if (item.serurraedi && item.serurraedi.length > 0 && item.serurraedi[0].tegundir && item.serurraedi[0].tegundir.length > 0) {
              const resource = item.serurraedi[0].tegundir.find(x => x.teg_serurraedi_id === r.teg_serurraedi_id);
              if (r.tegund === 0 && resource !== undefined) {
                this.$set(r, 'selected', true);
              }
              if (r.tegund === 1 && resource !== undefined) {
                this.$set(r, 'text', resource.text);
              }
            }
          });
        });
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.data = false;
      }
    },
    async saveData(type) {
      try {
        let item = [];
        if (type === 0) {
          item = {
            comment: this.commentGeneral,
            items: [
              {
                id: this.studentCurrent.common.length > 0 ? this.studentCurrent.common[0].nemandi_serurraedi_id : '',
                types: this.studentCommon
                  .filter(x => (x.tegund === 0 && x.selected) || (x.tegund === 1 && (x.text || '').length > 0))
                  .map(x => ({
                    id: x.teg_serurraedi_id,
                    text: x.text,
                  })),
              },
            ],
          };
        } else {
          item = {
            comment: this.commentCourse,
            items: this.studentModule.map(x => ({
              id: x.nemandi_serurraedi_id,
              moduleId: x.afangi_id,
              termId: x.onn_id,
              types: x.resources
                .filter(y => (y.tegund === 0 && y.selected) || (y.tegund === 1 && (y.text || '').length > 0))
                .map(y => ({
                  id: y.teg_serurraedi_id,
                  text: y.text,
                })),
            })),
          };
        }
        await students.createStudentSpecialResources(this.form.studentId, item);
        this.displaySuccess('Beiðni stofnuð og afgreidd');
        this.$emit('closeAndReload');
        // this.$emit('closeAndReload', { ssn: this.form.studentSsn });
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    getResource(type, item) {
      const resource = item.tegundir.find(x => x.teg_serurraedi_id === type.teg_serurraedi_id);
      if (type.tegund === 0) {
        if (resource) return 1;
        return 0;
      }
      return resource && resource.text;
    },
    selectStudent(student) {
      this.searchStudent = false;
      this.form.studentId = student.nemandi_id;
      this.form.studentName = student.nafn;
      this.form.studentSsn = student.kennitala;
      this.loadStudentData();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadTypes();
    // Aðeins notað ef beiðni er stofnuð á nemendaspjaldi
    if (this.ssn && this.studentCardView) {  
      this.search = this.ssn;
      this.loadList();
    }
  },
};
</script>

<style lang="scss"></style>
