<template>
    <div>
        <b-row>
            <b-col class="pl-0 pt-2 pb-2" sm="4" xs="4" md="4" lg="3">
                <label for="term" class="font-weight-bold">Kennslutímabil</label>
                <v-select id="onn_id" name="onn_id" v-model="selectedTerm" :clearable="false" :options="terms"
                    label="onn_eink" @input="getStudentGrades(); getStudentsStats()"> </v-select>
            </b-col>
            <b-col>
                <b-btn variant="primary" title="Breyta einkunum" v-if="!editAll" v-b-tooltip @click="toggleEditAll()"
                    style="margin-top: 35px"><i class="fa fa-pencil"></i></b-btn>
                <b-btn variant="primary" v-if="editAll" @click="saveGrades()" style="margin-top: 35px">Vista</b-btn>
                <b-btn variant="dark" v-if="editAll" @click="toggleEditAll()" class="ml-3" style="margin-top: 35px">Hætta
                    við</b-btn>
            </b-col>
        </b-row>
        <Loader v-if="loading.terms"></Loader>
        <Loader v-if="loading.grades"></Loader>
        <b-card>
            <table class="table less-padding table-bordered-header" v-if="!loading.grades"
                style="font-size: 13px;max-width:97%">
                <thead>
                    <tr>
                        <th>Tímabil ({{ grades.length }})</th>
                        <th>Áfangi</th>
                        <th>Heiti áfanga</th>
                        <th>Lokaeinkunn</th>
                        <th>Einingar</th>
                        <th>Staða</th>
                        <th>Dags. lokið</th>
                        <th>Athugasemd</th>
                        <th colspan="2" v-if="!editAll">Uppbygging</th>
                        <th colspan="2" v-if="editAll" v-b-tooltip title="Læsa einkunn (ekki reikna upp skv. reglu)">Læsa
                            einkunn</th>
                    </tr>
                </thead>
                <tbody v-for="(g, index) in grades" :key="index" style="border-width: 1px">
                    <tr v-if="saving">
                        <td colspan="100%">
                            <Loader></Loader>
                        </td>
                    </tr>
                    <tr v-if="!saving">
                        <td> {{ g.onn_eink }} </td>
                        <td> {{ g.namsgrein_afanganr }} </td>
                        <td> {{ g.afanga_heiti }} </td>
                        <td>
                            <span v-if="!editAll">
                                {{ g.lokaeinkunn }}
                            </span>
                            <span v-if="editAll">
                                <input type="text" class="form-control" v-model="g.lokaeinkunn" maxLength="2"
                                    style="width: 35px; height: 25px; padding-left: 3px" />
                            </span>
                        </td>
                        <td>
                            <span v-if="!editAll"> {{ g.einingar_ferill }} </span>
                            <span v-if="editAll">
                                <input type="text" class="form-control" v-model="g.einingar_ferill" maxLength="2"
                                    style="width: 35px; height: 25px; padding-left: 3px" />
                            </span>
                        </td>
                        <td>
                            <span v-if="!editAll">{{ g.stada_afanga }} </span>
                            <span v-if="editAll">
                                <v-select id="moduleStatus" name="moduleStatus" v-model="g.stada" :options="moduleStatuses"
                                    :clearable="false" label="heiti">
                                </v-select>
                            </span>
                        </td>
                        <td>
                            <span v-if="!editAll">{{ g.dags_lokid | moment('DD.MM.YYYY') }} </span>
                            <span v-if="editAll">
                                <datepicker id="date" name="date" v-model="g.dags_lokid" :language="lang"
                                    :monday-first="true" format="dd.MM.yyyy" :typeable="false" :clear-button="true"
                                    ref="dateOpenPickr" :class="{ 'datepicker-not-empty': g.dags_lokid }"></datepicker>
                            </span>
                        </td>
                        <td>
                            <span v-if="!editAll">{{ g.aths }} </span>
                            <span v-if="editAll">
                                <input type="text" class="form-control" v-model="g.aths" maxLength="50"
                                    style="height: 28px; padding-left: 3px" />
                            </span>
                        </td>
                        <td v-if="!editAll">
                            {{ g.tegund_braut_afangar_heiti }}
                        </td>
                        <td v-if="editAll">
                            <b-form-checkbox :id="`einkunn_laest${index}`" :name="`einkunn_laest${index}`"
                                v-model="g.einkunn_laest"></b-form-checkbox>
                        </td>
                        <td style="color:#98139c">
                            <i class="fa fa-pencil cursor-pointer pr-2" v-if="!editAll" v-b-tooltip title="Breyta"
                                @click.prevent="openSlidebar(g)"></i>
                            <i class="fa fa-search cursor-pointer pr-2" v-if="!editAll" v-b-tooltip title="Sundurliðun"
                                @click="toggleShow(g, 1)"></i>
                            <i class="fa fa-bar-chart cursor-pointer pr-2" v-if="!editAll" v-b-tooltip
                                @click="toggleShow(g, 2)" title="Einkunnardreifing"></i>
                            <!-- <i class="fa fa-trash cursor-pointer" v-if="!editAll" @click="deleteGrade(g.ferill_id)"
                                v-b-tooltip title="Eyða"></i> -->


                        </td>
                    </tr>
                    <tr v-if="g.showBreakdown && !saving">
                        <td colspan="80%" style="padding: 20px 30px !important; background-color: #f9f9f9;">
                            <h5>Sundurliðun
                                <b-btn variant="primary" class="btn-xs mr-2 mb-2 p-2 float-right" style="font-size: 12px"
                                    v-b-tooltip title="Skoða einkunnareglu" @click="openGradeRuleSlidebar(g)">
                                    Einkunnaregla</b-btn>
                            </h5>
                            <Loader v-if="g.loadingBreakdown"></Loader>

                            <table class="table less-padding">
                                <thead style="text-transform: uppercase; font-size:12px">
                                    <tr>
                                        <th>Heiti</th>
                                        <th>Einkunn</th>
                                        <th>Dags.</th>
                                        <th>Umsögn/Athugasemd</th>
                                        <th>Skráð af</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody v-if="g.breakdown.length === 0">
                                    <tr>
                                        <td colspan="100%" class="font-italic text-center">Engin gögn skráð</td>
                                    </tr>
                                </tbody>

                                <tbody v-for="(b, idx) in g.breakdown" :key="idx">
                                    <tr>
                                        <td :class="{ 'font-weight-bold': b.lokaeinkunn === 1 }">
                                            {{ b.heiti }}
                                            <span v-if="b.lokaeinkunn === 1" v-b-tooltip
                                                title="Einkunn flutt í námsferil nemandans"> (flutt í feril)</span>
                                        </td>
                                        <td>{{ b.einkunn }}</td>
                                        <td>{{ b.dags_einkunnar | moment('DD.MM.YYYY') }}</td>
                                        <td style="max-width: 100px">
                                            <div v-html="b.athugasemd"></div>
                                        </td>
                                        <td>{{ b.skrad_af_nafn }}</td>
                                        <td style="color:#98139c">
                                            <!-- <i class="fa fa-pencil cursor-pointer pr-2" v-if="!editAll" v-b-tooltip
                                                title="Breyta" @click.prevent="openProjectSlidebar(b, g)"></i> -->
                                            <i class="fa fa-bar-chart cursor-pointer pr-2" v-if="!editAll && b.einkunn"
                                                v-b-tooltip title="Einkunnardreifing"
                                                @click="toggleShowMore(g.annir_afangar_id, b)"></i>
                                        </td>
                                    </tr>
                                    <tr v-if="b.showDistribution">
                                        <td colspan="80%" style="padding: 20px 30px !important; background-color: white">
                                            <h5>Einkunnardreifing</h5>
                                            <div v-if="b.loading" class="text-center"> <i class="fa fa-spin fa-spinner"></i>
                                            </div>
                                            <div v-if="!b.loading && b.stats">
                                                <div>Meðaleinkunn: <b> {{ b.stats.medaltal }}</b></div>
                                                <div>Staðalfrávik: <b> {{ b.stats.stadalfravik }}</b></div>
                                                <div>Fjöldi einkunna: <b> {{ b.counter.fjoldi }}</b></div>
                                                <div v-if="b.counter.fjoldi > 5 && !b.loading">
                                                    <GChart type="ColumnChart" :data="b.graph" :options="chartOptions" />
                                                </div>
                                            </div>
                                            <div v-if="(!b.stats || (b.stats && b.stats.length)) && !b.loading">
                                                Fjöldi einkunna er ekki nægur til að hægt sé birta einkunnadreifingu
                                            </div>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr v-if="g.showDistribution && !saving">
                        <td colspan="80%" style="padding: 20px 30px !important; background-color: #f9f9f9">
                            <h5>Einkunnardreifing</h5>
                            <div v-if="g.loading"> <i class="fa fa-spin fa-spinner"></i></div>
                            <div v-if="g.stats.length > 0 && !g.loading">
                                <div>Meðaleinkunn: <b> {{ g.stats[0].medaltal }}</b></div>
                                <div>Staðalfrávik: <b> {{ g.stats[0].stadalfravik }}</b></div>
                                <div>Fjöldi einkunna: <b> {{ g.stats[0].fjoldi }}</b></div>
                                <div>
                                    <GChart type="ColumnChart" :data="g.graph" :options="chartOptions" />
                                </div>
                            </div>
                            <div v-if="g.stats.length === 0 && !g.loading">
                                Fjöldi einkunna er ekki nægur til að hægt sé birta einkunnadreifingu
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </b-card>

        <!-- Meðaltal m.v. önnina -->
        <Loader v-if="loading.stats"></Loader>
        <b-card class="mt-4" v-if="termStats.einingar && !loading.stats">
            <b-row class="pt-3">
                <b-col class="text-center">
                    <label class="statsLabel">Einingar</label>
                    <div class="text statsNr">
                        {{ termStats.einingar }}
                    </div>
                </b-col>
                <b-col class="text-center">
                    <label v-if="termStats.medaltal" class="statsLabel">Meðaltal</label>
                    <div class="text statsNr">
                        {{ termStats.medaltal ? termStats.medaltal.toFixed(2).toString().replace('.', ',') : '' }}
                    </div>
                </b-col>
                <b-col class="text-center">
                    <label v-if="termStats.vegidMedaltal" class="statsLabel">Vegið meðaltal</label>
                    <div class="text statsNr">
                        {{ termStats.vegidMedaltal ? termStats.vegidMedaltal.toFixed(2).toString().replace('.', ',') : '' }}
                    </div>
                </b-col>
                <b-col class="text-center">
                    <label v-if="termStats.vegidMedaltalAnMats" class="statsLabel">Vegið meðaltal án mats</label>
                    <div class="text statsNr">
                        {{ termStats.vegidMedaltalAnMats ? termStats.vegidMedaltalAnMats.toFixed(2).toString().replace('.',
                            ',') :
                            '' }} </div>
                </b-col>
                <b-col class="text-center">
                    <label class="statsLabel" v-if="termStats.vegidMedaltalFall">Vegið meðaltal með falli</label>
                    <div class="text statsNr">
                        {{ termStats.vegidMedaltalFall ? termStats.vegidMedaltalFall.toFixed(2).toString().replace('.', ',')
                            : '' }}
                    </div>
                </b-col>
            </b-row>
            <b-row class="pt-3" style="font-size: 90%;">
                <b-col>
                    <i class="fa fa-info-circle"></i>
                    Til að sjá meðaleinkunn brautar er farið í námsferil, brautaruppbyggingu og skoðað þar neðst undir
                    "Samantekt"
                </b-col>
            </b-row>
        </b-card>

        <student-edit-grade-slidebar v-if="showSlidebar" :item="selectedItem" :studentId="studentId"
            @closeSlidebar="closeAndReloadSlidebar">
        </student-edit-grade-slidebar>
        <grade-rule-slidebar v-if="showGradeRuleSlidebar" :groupId="selectedItem.groupId" :edit="0"
            :termModuleId="selectedItem.annir_afangar_id" @closeAndReloadSlidebar="closeAndReloadSlidebar"
            @closeSlidebar="closeAndReloadSlidebar">
        </grade-rule-slidebar>
        <student-edit-project-grade-slidebar v-if="showProjectGradeSlidebar" :item="selectedItem" :studentId="studentId"
            :selectedCourse="selectedCourse" @closeAndReloadSlidebar="closeAndReloadSlidebar"
            @closeSlidebar="closeSlidebar">
        </student-edit-project-grade-slidebar>
    </div>
</template>
  
<script>
import students from '@/api/students';
import groups from '@/api/groups';
import common from '@/api/common';
import statistics from '@/api/statistics'
import { mapActions, mapGetters } from 'vuex';
import Datepicker from 'vuejs-datepicker';
import StudentEditGradeSlidebar from '@/components/students/student/grades/StudentEditGradeSlidebar.vue';
import GradeRuleSlidebar from '@/components/school/terms/graderules/GradeRuleSlidebar.vue';
import StudentEditProjectGradeSlidebar from '@/components/students/student/grades/StudentEditProjectGradeSlidebar.vue';
import { GChart } from 'vue-google-charts';


import { is } from 'vuejs-datepicker/dist/locale';

import Loader from '@/components/common/Loader.vue';

export default {
    name: 'student-grades',
    props: ['studentId'],
    components: {
        Loader,
        Datepicker,
        StudentEditGradeSlidebar,
        GChart,
        GradeRuleSlidebar,
        StudentEditProjectGradeSlidebar,
    },
    computed: {
        canEdit() {
            return this.loggedInUserHasWritePermission('nemendahald_nemendur');
        },
        canReadSchool() {
            return this.loggedInUserHasReadPermission('skolahald_uppbygging');
        },
        ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
    },
    data() {
        return {
            lang: is,
            loading: {
                terms: false,
                grades: false,
                stats: false,
            },
            term: {
                id: '',
                dateFrom: '',
                dateTo: '',
                name: '',
            },
            terms: [],
            selectedTerm: '',
            selectedCourse: '',
            grades: [],
            editAll: false,
            showSlidebar: false,
            showGradeRuleSlidebar: false,
            showProjectGradeSlidebar: false,
            saving: false,
            type: '',
            statistics: [],
            termStats: [],
            chartOptions: {
                vAxis: {
                    title: 'Fjöldi'
                },
                hAxis: {
                    title: 'Einkunnir',
                },
                legend: 'none',
            },
        };
    },
    methods: {
        toggleEditAll() {
            this.editAll = !this.editAll;
        },
        async getStudentDefaultTerm() {
            try {
                this.noTerm = false;
                const response = await students.getStudentDefaultTerm(this.studentId);
                this.term.id = response.data.onn_id;
                this.term.dateFrom = response.data.dags_fra;
                this.term.dateTo = response.data.dags_til;
                this.term.name = response.data.onn_eink;
                this.loadTerms();
            } catch (e) {
                if (e.response.status === 404) {
                    this.noTerm = true;
                    this.loadTerms(); // sækjum samt annir
                } else {
                    this.$log.error(e);
                    this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
                }
            }
        },
        // sækja allar annir
        async loadTerms() {
            this.loading.terms = true;
            try {
                const response = await students.getStudentTerms(this.studentId, { future_terms: 0, past_terms: 1 });
                this.terms = response.data.items;
                if (this.term.id) {
                    this.selectedTerm = this.terms.find(x => x.onn_id === this.term.id);
                } else {
                    this.selectedTerm = this.terms[0];
                }
                if (this.selectedTerm) {
                    this.getStudentGrades();
                    this.getStudentsStats(this.selectedTerm);
                }

            } catch (e) {
                this.$log.error(e);
                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            } finally {
                this.loading.terms = false;
            }
        },
        async getStudentGrades() {
            try {
                this.editAll = false;
                this.loading.grades = true;
                const response = await students.getStudentGrades(this.studentId, this.selectedTerm.onn_id);
                this.grades = response.data.items.map(x => ({
                    ...x,
                    showBreakdown: false,
                    loadingBreakdown: false,
                    showDistribution: false,
                    lokaeinkunn: x.einkunn || x.einkunn_a,
                    stada: this.moduleStatuses.filter(y => y.stada_afanga === x.stada_afanga_id)[0],
                    einkunn_laest: !!x.laest,
                    stats: [],
                    graph: [],
                    gradeRule: '',
                    breakdown: [],
                    loading: false,
                }))
            } catch (e) {
                this.$log.error(e);
                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            } finally {
                this.loading.grades = false;
            }
        },
        async loadModuleStatuses() {
            try {
                const response = await common.getModuleStatuses();
                this.moduleStatuses = response.data.items;
            } catch (e) {
                this.$log.error(e);
                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            }
        },
        async getStudentsStats() {
            try {
                this.loading.stats = true;
                const params = {
                    termId: this.selectedTerm.onn_id,
                    styring: 4,
                }
                const response = await students.getStudentStatistics(this.studentId, params);
                this.termStats = response.data;
            } catch (e) {
                this.$log.error(e);
                this.displayError(`Óvænt villa að sækja meðaltal. Villa: (${e.response ? e.response.status : -1}) 
                kom upp. Vinsamlegast reyndu aftur.`);
            } finally {
                this.loading.stats = false;
            }

        },
        // Kemur með næstu útgáfu - virkar ekki 
        // async deleteGrade(id) {
        //     const conf = confirm('Ertu viss um að þú viljir eyða færslunni?'); // eslint-disable-line
        //     if (conf) {
        //         const data = {
        //             ferill_id: id,
        //             delete: 1,
        //         };
        //         await students.postStudentGrade(this.studentId, data);
        //         this.displaySuccess(`Færslu hefur verið eytt.`);
        //         this.getStudentGrades();
        //     }
        // },
        async saveGrades() {
            this.saving = true;
            let data;
            try {
                const promises = [];
                for (let i = 0; i < this.grades.length; i += 1) {
                    data = {
                        ferill_id: this.grades[i].ferill_id,
                        stada_afanga: this.grades[i].stada.stada_afanga, // this.grades[i].stada_afanga,
                        einkunn: this.grades[i].lokaeinkunn, // .einkunn ? this.form.einkunn : '',
                        dags_lokid: this.grades[i].dags_lokid,
                        einingar: this.grades[i].einingar_ferill ? this.grades[i].einingar_ferill : '',
                        laest: this.grades[i].laest ? 1 : 0,
                        aths: this.grades[i].aths,
                        delete: null,
                    }
                    promises.push(students.postStudentGrade(this.studentId, data));
                    Promise.all(promises).then(() => {
                        this.getStudentGrades();
                        // gekk
                    },
                        (e) => {
                            if (e.response && e.response.data && e.response.data.error) {
                                this.displayError(e.response.data.error);
                            } else {
                                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. 
                    Vinsamlegast reyndu aftur.`);
                            }
                        },
                    );

                }
                this.displaySuccess('Einkunnir hafa verið uppfærðar');
                this.editAll = !this.editAll;
            } catch (e) {
                // Aðgerð mistókst.
                this.$log.debug(e);
                if (e.error) {
                    this.displayError(`Óvænt villa (${e.error ? e.error : -1}) kom upp. Vinsamlegast reyndu aftur.`);
                } else {
                    this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
                }
            } finally {
                this.saving = false;
            }
        },
        async getGradeRuleType(line) {
            /* eslint-disable no-param-reassign */
            try {
                line.loadingBreakdown = true;
                if (line.hopur_id) {
                    const response = await groups.getGradeRuleType(line.hopur_id);
                    if (response.data.items.length > 0) {
                        line.gradeRule = response.data.items[0].einkunnarregla;
                    } else {
                        line.gradeRule = 0; // einkRegla áfanga 
                    }
                }
                this.loadGradesBreakdown(line)

            } catch (e) {
                this.$log.error(e);
                this.displayError(`Óvænt villa að sækja sundurliðun. (${e.response ? e.response.status : -1}) 
                kom upp. Vinsamlegast reyndu aftur.`);
            } finally {
                line.loading = false;
                line.loadingBreakdown = false;
            }


        },
        async loadGradesBreakdown(line) {
            try {
                /* eslint-disable no-param-reassign */
                line.loading = true;
                const params = {
                    afangaOnnId: line.annir_afangar_id,
                    ferillId: line.ferill_id,
                }
                const params2 = {
                    ferill_id: line.ferill_id,
                    hopur_id: line.hopur_id,
                }
                const res = await students.getStudentGroupId(params);
                const response = await students.getStudyRecordGradesRule(this.studentId, params2);

                // const response = await students.getStudyRecordGrades(line.ferill_id);
                if (res.data.items.length > 0) {
                    line.groupId = res.data.items[0].hopur_id;
                } else { line.groupId = ''; }
                line.breakdown = response.data.items.map(x => ({
                    ...x,
                    showDistribution: false,
                    loading: false,
                    counter: [],
                    stats: [], // meðaleinkunn og staðalfrávik
                    graph: [],
                    groupId: '',
                }))
                // this.grades = response.data.items.map(x => ({
                //     ...x,
                //     showBreakdown: false,
                //     showDistribution: false,
                //     lokaeinkunn: x.einkunn || x.einkunn_a,
                //     stada: this.moduleStatuses.filter(y => y.stada_afanga === x.stada_afanga_id)[0],
                //     einkunn_laest: !!x.laest,
                //     stats: [],
                //     graph: [],
                //     breakdown: [],
                //     loading: false,
                // }))


            } catch (e) {
                this.$log.error(e);
                this.displayError(`Óvænt villa að sækja sundurliðun. (${e.response ? e.response.status : -1}) 
                kom upp. Vinsamlegast reyndu aftur.`);
            } finally {
                line.loadingBreakdown = false;
                line.loading = false;
            }
        },
        async getGradeModuleStatistics(line) {
            try {
                /* eslint-disable no-param-reassign */
                line.loading = true;
                const params = {
                    // einkunnir_afanga_id: null,
                    einkunnirAfangaId: '',
                    einkunnirHopsId: '',
                    // einkunnir_hops_id: null,
                    afangiOnnId: line.annir_afangar_id,
                };

                const response = await statistics.getGradeModuleStatistics(params);
                line.stats = response.data.stats;
                const graph = response.data.graph;
                // teikna upp fyrir graf 
                if (graph.length > 0) {
                    const data = [
                        ["Einkunnir", "Fjöldi", { role: 'annotation' }]
                    ];
                    for (let i = 0; i < graph.length; i += 1) {
                        let text = '';
                        if (graph[i].fjoldi > 0) {
                            text = graph[i].fjoldi
                        }
                        data.push([graph[i].einkunnir,
                        graph[i].fjoldi, text,
                        ]);
                    }
                    line.graph = data;
                }
            } catch (e) {
                this.$log.error(e);
                this.displayError(`Óvænt villa að sækja einkunnardreifingu.
                (${e.response ? e.response.status : -1}). Vinsamlegast reyndu aftur.`);
            } finally {
                line.loading = false;
            }
        },
        async getTaskModuleStats(id, task) {
            task.loading = true;
            const params = {
                afangiOnnId: id,
                tegundEinkunnarId: task.teg_eink
            }
            const response = await statistics.getGradeTaskStatistics(params);
            task.stats = response.data.items[0];
            const response2 = await statistics.getGradeTaskCounter(params);
            task.counter = response2.data.items[0];
            if (task.counter.fjoldi > 5) {
                const response3 = await statistics.getGradeTaskGraph(params);
                const graph = response3.data.items;
                if (graph.length > 0) {
                    const data = [
                        ["Einkunnir", "Fjöldi", { role: 'annotation' }]
                    ];
                    for (let i = 0; i < graph.length; i += 1) {
                        let text = '';
                        if (graph[i].fjoldi > 0) {
                            text = graph[i].fjoldi
                        }
                        data.push([graph[i].einkunnir,
                        graph[i].fjoldi, text,
                        ]);
                    }
                    task.graph = data;
                }

            }
            task.loading = false;


        },
        /* eslint-disable no-param-reassign */
        toggleShow(item, type) {
            // sundurliðun
            if (type === 1) {
                item.showDistribution = false; // loka alltaf
                item.showBreakdown = !item.showBreakdown;
                // loka hinum - bara einn opin í einus
                this.grades.forEach(x => {
                    if (x.ferill_id !== item.ferill_id) {
                        x.showBreakdown = false;
                    }
                });
                if (item.showBreakdown) {
                    this.getGradeRuleType(item);
                }
            } else {
                // einkunardreifing
                item.showBreakdown = false; // loka alltaf
                item.showDistribution = !item.showDistribution;
                if (item.showDistribution) {
                    this.getGradeModuleStatistics(item);
                }
            }
        },
        toggleShowMore(annirAfangarId, task) {
            task.showDistribution = !task.showDistribution;
            if (task.showDistribution) {
                this.getTaskModuleStats(annirAfangarId, task);
            }
        },
        openSlidebar(item) {
            this.selectedItem = item;
            this.showSlidebar = true;
            this.showGradeRuleSlidebar = false;
            this.showProjectGradeSlidebar = false;
        },
        openGradeRuleSlidebar(item) {
            this.selectedItem = item;
            this.showGradeRuleSlidebar = true;
            this.showSlidebar = false;
        },
        openProjectSlidebar(item, courseInfo) {
            this.selectedCourse = courseInfo.afanga_heiti;
            this.selectedItem = item;
            this.showProjectGradeSlidebar = true;
            this.showGradeRuleSlidebar = false;
            this.showSlidebar = false;
        },
        closeSlidebar() {
            this.showSlidebar = false;
            this.showGradeRuleSlidebar = false;
            this.showProjectGradeSlidebar = false;
        },
        closeAndReloadSlidebar() {
            this.closeSlidebar();
            this.getStudentGrades();
            this.getStudentsStats();
        },
        ...mapActions({
            displayError: 'displayError',
            displaySuccess: 'displaySuccess',
        }),
    },
    created() {
        this.loadModuleStatuses();
        this.getStudentDefaultTerm();

    },
};
</script>
<style>
.statsLabel {
    color: grey;
    font-size: 94%;
    text-align: center;
}

.statsNr {
    text-align: center;
    font-size: 115%;
    font-weight: 600;
}
</style>
  