<template>
  <Slidebar :autoClose="true" @closeMe="closeSlidebar" :large="large" :extraLarge="extraLarge">
    <h4>Bekkjaleit</h4>
    <br />
    <ClassSearch @changeClass="closeSlidebar" :slidebar="true" />
  </Slidebar>
</template>

<script>
import Slidebar from '@/components/common/Slidebar.vue';
import ClassSearch from '@/components/students/classes/ClassSearch.vue';

export default {
  name: 'class-search-slidebar',
  components: {
    Slidebar,
    ClassSearch,
  },
  props: ['large', 'extraLarge'],
  methods: {
    closeSlidebar(item) {
      this.$emit('closeSlidebar', item);
    },
    editItem() {
      this.$emit('editItem');
    },
  },
};
</script>

<style lang="scss"></style>
