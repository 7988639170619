<template>
  <page>
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <PageTitle title="Aðgangsstýringar" />
    <PageMenu route="Access" :selectedPage="page" :items="menu" />
    <AccessAdmin v-if="page === 'admin'" />
    <!--AccessLms v-if="page === 'lms'" /-->
  </page>
</template>

<script>
import { mapGetters } from 'vuex';
// import staff from '@/api/staff';

import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import PageTitle from '@/components/common/PageTitle.vue';
import PageMenu from '@/components/common/PageMenu.vue';

import AccessAdmin from '@/components/staff/access/AccessAdmin.vue';
// import AccessLms from '@/components/staff/access/AccessLms.vue';

export default {
  name: 'access',
  components: {
    Breadcrumbs,
    PageTitle,
    PageMenu,
    AccessAdmin,
    // AccessLms,
  },
  computed: {
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      breadcrumbs: [
        { name: 'Forsíða', route: 'Dashboard' },
        { name: 'Starfsmannahald', route: '' },
        { name: 'Aðgangsstýringar', route: 'Access' },
      ],
      page: 'admin',
      menu: [
        {
          name: 'Admin vefur',
          page: 'admin',
        },
        /* {
          name: 'Námskerfi',
          page: 'lms',
        }, */
      ],
      // loading: false,
      // jobList: [],
      // accessList: [],
      // categoryList: [],
      // list: [],
    };
  },
  methods: {},
  beforeRouteUpdate(to, from, next) {
    if (to && to.query && to.query.page) {
      this.page = to.query.page;
    }
    next();
  },
  created() {
    // this.loadList();
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
  },
};
</script>
