import Vue from 'vue';

const API_URL = '/api/types';

export default {
  getAbsenceList(params) {
    return Vue.http
      .get(`${API_URL}/absence/`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getAbsenceDetail(id) {
    return Vue.http
      .get(`${API_URL}/absence/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createAbsence(data) {
    return Vue.http
      .post(`${API_URL}/absence/`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateAbsence(id, data) {
    return Vue.http
      .post(`${API_URL}/absence/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getAttendanceList(params) {
    return Vue.http
      .get(`${API_URL}/attendance/`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getAttendanceDetail(id) {
    return Vue.http
      .get(`${API_URL}/attendance/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createAttendance(data) {
    return Vue.http
      .post(`${API_URL}/attendance/`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateAttendance(id, data) {
    return Vue.http
      .post(`${API_URL}/attendance/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getClassroomList() {
    return Vue.http
      .get(`${API_URL}/classroom/`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getClassroomDetail(id) {
    return Vue.http
      .get(`${API_URL}/classroom/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createClassroom(data) {
    return Vue.http
      .post(`${API_URL}/classroom/`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateClassroom(id, data) {
    return Vue.http
      .post(`${API_URL}/classroom/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getCommentList() {
    return Vue.http
      .get(`${API_URL}/comment/`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getCommentDetail(id) {
    return Vue.http
      .get(`${API_URL}/comment/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createComment(data) {
    return Vue.http
      .post(`${API_URL}/comment/`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateComment(id, data) {
    return Vue.http
      .post(`${API_URL}/comment/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getEventList() {
    return Vue.http
      .get(`${API_URL}/event/`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getEventDetail(id) {
    return Vue.http
      .get(`${API_URL}/event/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createEvent(data) {
    return Vue.http
      .post(`${API_URL}/event/`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateEvent(id, data) {
    return Vue.http
      .post(`${API_URL}/event/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getExamList() {
    return Vue.http
      .get(`${API_URL}/exam/`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getExamDetail(id) {
    return Vue.http
      .get(`${API_URL}/exam/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createExam(data) {
    return Vue.http
      .post(`${API_URL}/exam/`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateExam(id, data) {
    return Vue.http
      .post(`${API_URL}/exam/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getModuleList() {
    return Vue.http
      .get(`${API_URL}/module/`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getModuleDetail(id) {
    return Vue.http
      .get(`${API_URL}/module/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createModule(data) {
    return Vue.http
      .post(`${API_URL}/module/`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateModule(id, data) {
    return Vue.http
      .post(`${API_URL}/module/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getProcessingStatusList() {
    return Vue.http
      .get(`${API_URL}/processingStatus/`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getProcessingStatusDetail(id) {
    return Vue.http
      .get(`${API_URL}/processingStatus/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createProcessingStatus(data) {
    return Vue.http
      .post(`${API_URL}/processingStatus/`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateProcessingStatus(id, data) {
    return Vue.http
      .post(`${API_URL}/processingStatus/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStaffList() {
    return Vue.http
      .get(`${API_URL}/staff/`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStaffDetail(id) {
    return Vue.http
      .get(`${API_URL}/staff/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createStaff(data) {
    return Vue.http
      .post(`${API_URL}/staff/`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateStaff(id, data) {
    return Vue.http
      .post(`${API_URL}/staff/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStaffAbsenceList() {
    return Vue.http
      .get(`${API_URL}/staffAbsence/`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getStaffAbsenceDetail(id) {
    return Vue.http
      .get(`${API_URL}/staffAbsence/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createStaffAbsence(data) {
    return Vue.http
      .post(`${API_URL}/staffAbsence/`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateStaffAbsence(id, data) {
    return Vue.http
      .post(`${API_URL}/staffAbsence/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getTermList() {
    return Vue.http
      .get(`${API_URL}/term/`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getTermDetail(id) {
    return Vue.http
      .get(`${API_URL}/term/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createTerm(data) {
    return Vue.http
      .post(`${API_URL}/term/`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateTerm(id, data) {
    return Vue.http
      .post(`${API_URL}/term/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getCommonAttendanceList() {
    return Vue.http
      .get(`${API_URL}/common-attendance`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getCoordinatedTermsList() {
    return Vue.http
      .get(`${API_URL}/coordinated-terms`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getGradeList(params) {
    return Vue.http
      .get(`${API_URL}/grade/`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getGradeDetail(id) {
    return Vue.http
      .get(`${API_URL}/grade/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createGrade(data) {
    return Vue.http
      .post(`${API_URL}/grade/`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateGrade(id, data) {
    return Vue.http
      .post(`${API_URL}/grade/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getGradeExists(params) {
    return Vue.http
      .get(`${API_URL}/grade-exists`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getGradeScaleTypeList(params) {
    return Vue.http
      .get(`${API_URL}/grade-scale/`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getGradeScaleTypeDetail(id) {
    return Vue.http
      .get(`${API_URL}/grade-scale/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getGradeScale(id) {
    return Vue.http
      .get(`${API_URL}/grade-scale/${id}/grades/`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createGradeScaleType(data) {
    return Vue.http
      .post(`${API_URL}/grade-scale/`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateGradeScaleType(id, data) {
    return Vue.http
      .post(`${API_URL}/grade-scale/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getGradeScaleCopy() {
    return Vue.http
      .get(`${API_URL}/grade-scale-copy`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  copyGradeScaleCopy(data) {
    return Vue.http
      .post(`${API_URL}/grade-scale-copy`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  setMassGradeScale(id, data) {
    return Vue.http
      .post(`${API_URL}/grade-scale/${id}/mass-edit`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getGradeScaleGradeExists(id, params) {
    return Vue.http
      .get(`${API_URL}/grade-scale/${id}/grade-exists`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createGradeScaleGrade(scaleId, data) {
    return Vue.http
      .post(`${API_URL}/grade-scale/${scaleId}/grades/`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateGradeScaleGrade(scaleId, gradeId, data) {
    return Vue.http
      .post(`${API_URL}/grade-scale/${scaleId}/grades/${gradeId}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getBookList(params) {
    return Vue.http
      .get(`${API_URL}/book/`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getBookDetail(id) {
    return Vue.http
      .get(`${API_URL}/book/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createBook(data) {
    return Vue.http
      .post(`${API_URL}/book/`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateBook(id, data) {
    return Vue.http
      .post(`${API_URL}/book/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getBookCategoryList() {
    return Vue.http
      .get(`${API_URL}/book-category/`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getBookCategoryDetail(id) {
    return Vue.http
      .get(`${API_URL}/book-category/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createBookCategory(data) {
    return Vue.http
      .post(`${API_URL}/book-category/`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateBookCategory(id, data) {
    return Vue.http
      .post(`${API_URL}/book-category/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getBookUsage(params) {
    return Vue.http
      .get(`${API_URL}/book-search`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getCommonSubjects() {
    return Vue.http
      .get(`${API_URL}/common-subjects`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getCommonLanguageTypes() {
    return Vue.http
      .get(`${API_URL}/common-language-type`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getCommonCourses() {
    return Vue.http
      .get(`${API_URL}/common-courses`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getSpecialResourceList(params) {
    return Vue.http
      .get(`${API_URL}/special-resources/`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getSpecialResourceDetail(id) {
    return Vue.http
      .get(`${API_URL}/special-resources/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createSpecialResource(data) {
    return Vue.http
      .post(`${API_URL}/special-resources/`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateSpecialResource(id, data) {
    return Vue.http
      .post(`${API_URL}/special-resources/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getPriceList(params) {
    return Vue.http
      .get(`${API_URL}/price/`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getPriceDetail(id) {
    return Vue.http
      .get(`${API_URL}/price/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createPrice(data) {
    return Vue.http
      .post(`${API_URL}/price/`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updatePrice(id, data) {
    return Vue.http
      .post(`${API_URL}/price/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getBehaviorList(params) {
    return Vue.http
      .get(`${API_URL}/behavior/`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getBehaviorDetail(id) {
    return Vue.http
      .get(`${API_URL}/behavior/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createBehavior(data) {
    return Vue.http
      .post(`${API_URL}/behavior/`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateBehavior(id, data) {
    return Vue.http
      .post(`${API_URL}/behavior/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
};
