<template>
  <div>
    <div v-if="allowCopy">
      <b-btn
        variant="primary"
        title="Afrita einkunnareglur frá öðru kennslutímabili"
        v-b-tooltip
        v-if="canEdit"
        class="float-right ml-2"
        @click="slidebar.copyTerm = true"
        style="margin-top: -10px; margin-bottom: -20px"
        ><i class="fa fa-copy"></i
      ></b-btn>
      <b-btn
        variant="primary"
        title="Afrita einkunnareglu frá öðrum áfanga"
        v-b-tooltip
        v-if="canEdit"
        class="float-right"
        @click="slidebar.copyRule = true"
        style="margin-top: -10px; margin-bottom: -20px"
        ><i class="fa fa-sitemap"></i
      ></b-btn>
    </div>
    <table class="table less-padding table-hover table-fixed" style="font-size: 13px; margin-top: -20px">
      <thead>
        <tr class="table-search">
          <td colspan="3" :class="{ 'pb-0': detailedSearch }">
            <b-form-input
              v-model="search.search"
              class="search"
              v-on:keyup.enter="loadModules"
              placeholder="Leita eftir áfanga"
              ref="focusInput"
            />
          </td>
          <td :class="{ 'pb-0': detailedSearch }">
            <b-btn variant="primary" class="w-100" style="margin-top: 25px" @click="loadModules">Leita</b-btn>
            <div class="cursor-pointer text-right pt-2" @click="detailedSearch = !detailedSearch">
              <span v-if="detailedSearch"><i class="fa fa-angle-up"></i> Fela ítarleit</span>
              <span v-else><i class="fa fa-angle-up"></i> Sýna ítarleit</span>
            </div>
          </td>
        </tr>
        <tr v-if="detailedSearch" class="table-detail-search">
          <td>
            <b-form-group label="Deild" label-for="department">
              <v-select
                id="department"
                name="department"
                v-model="search.department"
                :options="getDepartments"
                :clearable="true"
                @input="loadModules()"
              >
              </v-select>
            </b-form-group>
          </td>
          <td>
            <b-form-group label="Tegund einkunnar" label-for="grade">
              <v-select id="grade" name="grade" v-model="search.grade" :options="getGrades" :clearable="true"> </v-select>
            </b-form-group>
          </td>
          <td v-if="search.grade">
            <b-form-radio-group id="containsGrade" v-model="search.contains" name="containsGrade" class="pl-4">
              <b-form-radio :value="true">Inniheldur valda tegund einkunnar</b-form-radio>
              <b-form-radio :value="false">Inniheldur ekki valda tegund einkunnar</b-form-radio>
            </b-form-radio-group>
          </td>
          <td>
            <b-form-group label="Lokaeinkunn skilgreind" label-for="noFinal">
              <div class="d-inline-block">
                <b-form-checkbox v-model="search.noFinal"></b-form-checkbox>
              </div>
              <div class="d-inline-block" style="font-weight: normal; text-transform: none">
                Tómt eða einkunn send í feril ekki skilgreind
              </div>
            </b-form-group>
          </td>
        </tr>
        <tr v-if="firsttime">
          <th>
            <LinkOrder title="Einkenni" :filteredList="modules" :list="modules" column="namsgrein_afanganr"></LinkOrder>({{
              modules.length
            }})
          </th>
          <th>
            <LinkOrder
              title="Heiti"
              :filteredList="modules"
              :list="modules"
              column="afanga_heiti"
              secondColumn="namsgrein_afanganr"
            ></LinkOrder>
          </th>
          <th>
            <LinkOrder
              title="Einkunnaregla"
              :filteredList="modules"
              :list="modules"
              column="fj_einkunnareglu"
              secondColumn="namsgrein_afanganr"
            ></LinkOrder>
          </th>
          <th>
            <LinkOrder
              title="Hópar með einkunnareglu"
              :filteredList="modules"
              :list="modules"
              column="fj_einkunnareglu_hopar"
              secondColumn="namsgrein_afanganr"
            ></LinkOrder>
            <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="cursor-pointer float-right">
              <i class="fa fa-file-excel-o excelText"></i>
            </download-csv>
          </th>
        </tr>
      </thead>
      <TableRowLoader v-if="loading" colspan="4" :center="true" />
      <TableRowEmpty v-if="!loading && firsttime && this.modules.length === 0" colspan="4" :center="true" />
      <TableRowSearchForResults v-if="!firsttime" colspan="4" :center="true" />
      <tbody>
        <tr v-for="m in getPaginatedList" :key="m.annir_afangar_id">
          <td>
            <b-link
              @click="selectModule(m)"
              title="Velja áfanga"
              :to="{ name: 'Term', params: { id: termId }, query: { page: page, module: m.annir_afangar_id } }"
              v-b-tooltip
            >
              {{ m.namsgrein_afanganr }}
            </b-link>
          </td>
          <td>{{ m.afanga_heiti }}</td>
          <td>{{ m.fj_einkunnareglu }}</td>
          <td>{{ m.fj_einkunnareglu_hopar }}</td>
        </tr>
      </tbody>
    </table>

    <Pagination
      :currentPage="currentPage"
      :totalRows="modules.length"
      :perPage="perPage"
      v-if="modules.length > 20"
      @change-page="changePage"
      @change-limit="changeLimit"
    />
    <CopyGraderuleTermSlidebar
      :id="id"
      :divisionId="divisionId"
      v-if="slidebar.copyTerm"
      @closeSlidebar="closeAllSlidebars()"
    ></CopyGraderuleTermSlidebar>
    <CopyGraderuleSlidebar
      :id="id"
      :divisionId="divisionId"
      v-if="slidebar.copyRule"
      @closeSlidebar="closeAllSlidebars()"
    ></CopyGraderuleSlidebar>
  </div>
</template>

<script>
import terms from '@/api/terms';
import structure from '@/api/structure';
import types from '@/api/types';

import { mapActions, mapGetters } from 'vuex';
import Pagination from '@/components/common/Pagination.vue';
import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';
import TableRowSearchForResults from '@/components/common/TableRowSearchForResults.vue';
import CopyGraderuleTermSlidebar from '@/components/school/terms/graderules/CopyGraderuleTermSlidebar.vue';
import CopyGraderuleSlidebar from '@/components/school/terms/graderules/CopyGraderuleSlidebar.vue';
import moment from 'moment';

export default {
  name: 'graderule-list',
  props: ['id', 'item', 'hide', 'divisionId', 'allowCopy', 'termId', 'page'],
  components: {
    Pagination,
    TableRowLoader,
    TableRowEmpty,
    TableRowSearchForResults,
    CopyGraderuleTermSlidebar,
    CopyGraderuleSlidebar,
    LinkOrder,
  },
  computed: {
    getExcelData() {
      const newList = [
        [],
        ['Einkunnareglur'],
        [`${this.item.undirsk_heiti}-${this.item.onn_heiti}`],
        [],
        ['Einkenni', 'Heiti', 'Einkunnaregla', 'Hópar með einkunnareglu'],
        ...this.modules.map(x => [x.namsgrein_afanganr, x.afanga_heiti, x.fj_einkunnareglu, x.fj_einkunnareglu_hopar]),
      ];
      return newList;
    },
    getExcelFileName() {
      return `einkunnareglur_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    getPaginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.modules.slice(start, end);
    },
    getDepartments() {
      return this.departments.filter(x => !this.search.department || x.department_id !== this.search.department.department_id);
    },
    getGrades() {
      return this.grades.filter(x => !this.search.grade || x.teg_eink !== this.search.grade.teg_eink);
    },
    canRead() {
      return this.loggedInUserHasReadPermission('skolahald_annir');
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_annir');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolHasSettings']),
  },
  data() {
    return {
      perPage: 20,
      currentPage: 1,
      detailedSearch: false,
      loading: false,
      modules: [],
      grades: [],
      firsttime: false,
      search: {
        search: '',
        departments: '',
        grade: '',
        contains: true,
        noFinal: false,
      },
      slidebar: {
        copyTerm: false,
        copyRule: false,
      },
    };
  },
  methods: {
    closeAllSlidebars() {
      this.slidebar.copyTerm = false;
      this.slidebar.copyRule = false;
    },
    async loadModules() {
      if (this.canRead) {
        try {
          this.firsttime = true;
          this.loading = true;
          this.modules = [];
          const response = await terms.getTermModules(this.id, {
            showGraderule: 1,
            department: this.search.department ? this.search.department.department_id : '',
            search: this.search.search,
            hasGraderule: this.search.grade && this.search.contains ? this.search.grade.teg_eink : '',
            hasNotGraderule: this.search.grade && !this.search.contains ? this.search.grade.teg_eink : '',
            noFinal: this.search.noFinal ? 1 : '',
          });
          this.modules = response.data.items;
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading = false;
        }
      }
    },
    async loadDepartments() {
      try {
        const response = await structure.getDepartmentList();
        this.departments = response.data.items.map(x => ({
          ...x,
          label: `${x.name}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadGrades() {
      try {
        const response = await types.getGradeList({ active: 1 });
        this.grades = response.data.items.map(x => ({
          ...x,
          label: `${x.heiti}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    selectModule(item) {
      this.$emit('selectModule', {
        id: item.annir_afangar_id,
      });
    },
    initialize() {
      this.modules = [];
      this.loadDepartments();
      this.loadGrades();
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    id() {
      this.initialize();
    },
  },
  created() {
    this.initialize();
  },
};
</script>
