<template>
  <div>
    <b-col class="pl-0 pr-0">
      <b-row>
        <b-col class="pl-0" sm="12" xs="12" md="12" lg="9" v-if="tabView === 0 && !tabType">
          <b-row>
            <span class="font-weight-bold pr-1">Viðvera </span> er skráð af kennurum t.d. mæting, fjarverandi og seint.
          </b-row>
          <b-row>
            <span class="font-weight-bold pr-1">Forföll</span> eru skráð af skrifstofu/stjórnendum t.d. veikindi og
            leyfi sem vegur á móti
            viðveruskráningu.
          </b-row>
          <b-row>
            Ath. ef að nemandi er með skráð forföll en mætir í tíma og fær skráða mætingu í viðveru þá telst
            tíminn alltaf sem mæting.
            Gott er að hafa í huga að forföll koma ekki inn fyrr en búið er að skrá fjarvist á sama tíma á móti.
          </b-row>
          <b-row style="font-size: 90%;">
            Dæmi: Nemandi mætir ekki í tíma og kennari skráir fjarvist, nemandinn tilkynnir sig veikan til
            skrifstofu
            sem skráir veikindi í forföll. Nemandinn fær því ekki "skróp" og fjarvistarstig.
          </b-row>

        </b-col>
        <b-col class="pl-0" sm="12" xs="12" md="12" lg="3" v-if="tabView === 0 && !noTerm && canEdit && !tabType">
          <div class="float-right" @click="openSlidebar(1)">
            <b-btn variant="primary" size="sm">
              Skrá forföll
            </b-btn>
          </div>
          <div class="float-right mr-1">
            <b-btn variant="primary" size="sm" class="mr-3" @click="openSlidebar(0)">
              Skrá fjarvist
            </b-btn>
          </div>
        </b-col>
      </b-row>
    </b-col>
    <b-row>
      <b-col class="pl-0 pt-2 pb-2" sm="4" xs="4" md="4" lg="3"
        v-if="view === 2 || (view === 0 && attendance && attendance.afangar.length > 0) || tabType === 3">
        <div>
          <span v-if="sideBarView === 0">
            <span v-if="!tabType">
              <label for="term" class="font-weight-bold">Kennslutímabil</label>
              <v-select id="onn_id" name="onn_id" v-model="selectedTerm" @input="getAttendance()" :clearable="false"
                :options="terms" label="onn_eink"> </v-select>
            </span>
            <span v-if="tabType === 1" class="font-weight-bold">
              Haustmisseri {{ term.dateFrom | moment('DD.MM.YYYY') }} - 31.12.{{ term.dateFrom | moment('YYYY') }}
            </span>
            <span v-if="tabType === 2" class="font-weight-bold">
              Vormisseri 1.1.{{ term.dateTo | moment('YYYY') }} - {{ term.dateTo | moment('DD.MM.YYYY') }}
            </span>
            <span v-if="tabType === 3" class="font-weight-bold">
              Ástundun og hegðun
            </span>
          </span>
          <span v-if="sideBarView == 1">
            <strong>Kennslutímabil: {{ term.name }}</strong>
          </span>
        </div>
      </b-col>
      <b-col v-show="sideBarView === 1">
        <b-dropdown variant="primary" right class="mb-2 float-right">
          <template v-slot:button-content>
            <span v-if="view === 0 && type === 0">Yfirlit {{ term.onn_eink }}</span>
            <span v-if="view === 0 && type === 1">
              Haustmisseri {{ term.dateFrom | moment('DD.MM.YYYY') }} - 31.12.{{ term.dateFrom | moment('YYYY') }}
            </span>
            <span v-if="view === 0 && type === 2">
              Vormisseri 1.1.{{ term.dateTo | moment('YYYY') }} - {{ term.dateTo | moment('DD.MM.YYYY') }}
            </span>
            <span v-if="view === 1">Allar annir</span>
            <span v-if="view === 2">Viku fyrir viku</span>
          </template>

          <b-dropdown-item @click="getAttendance()">Yfirlit</b-dropdown-item>
          <b-dropdown-item @click="getAttendanceTerms()">Allar annir</b-dropdown-item>
          <b-dropdown-item @click="getAttendanceWeeks()">Viku fyrir viku</b-dropdown-item>
          <b-dropdown-item v-if="schoolSettings.afangaskoli === 0 && schoolSettings.nota_misserisskipti === 1"
            @click="getAttendanceFall()">Haustönn</b-dropdown-item>
          <b-dropdown-item v-if="schoolSettings.afangaskoli === 0 && schoolSettings.nota_misserisskipti === 1"
            @click="getAttendanceSpring()">Vorönn</b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>
    <Loader v-if="loading"></Loader>
    <div v-if="view === 0 && !loading && attendance && attendance.afangar.length > 0 && tabType !== 3">
      <AttendanceModules :attendance="attendance"></AttendanceModules>
    </div>
    <div v-if="view === 0 && !loading && attendance.length === 0 && tabType !== 3">
      Engin viðvera fannst
    </div>
    <div v-if="view === 1 && !loading">
      <AttendanceTerms :attendance="attendanceTerms" :studentId="studentId"></AttendanceTerms>
    </div>
    <div v-if="view === 2 && !loading" style="width: 100%; overflow-y: scroll">
      <AttendanceWeeks :attendance="attendanceWeeks" :studentId="studentId"></AttendanceWeeks>
    </div>
    <div v-if="tabType === 3 && sideBarView === 0">
      <b-row>
        <b-col class="pl-0" sm="12" xs="12" md="12" lg="9">

          <div class="pb-4" style="font-size:90%">
            Kennarar geta skráð ástundun og hegðun hjá nemendum í tímum.
            Ástundun og hegðun eru fyrirfram skilgreindar tegundir sem skólinn setur upp og kennarar velja svo úr lista.
            <b-link v-if="canReadSchool" :to="{ name: 'School', query: { page: 'types', tab: 'behavior' } }"
              target="_blank" v-b-tooltip title="Breyta tegund"><i class="fa fa-external-link"></i></b-link>
            <!--á þett að vera með?
              Hægt er að skrá bæði ástundun og hegðun á sömu kennslustundina auk þess að hægt er að skrá fleiri en eitt atriði.-->
          </div>
        </b-col>
        <b-col class="pl-0" sm="12" xs="12" md="12" lg="3">
          <div class="float-right" @click="openSlidebarExtra()">
            <b-btn variant="primary" size="sm">
              Skrá ástundun og hegðun
            </b-btn>
          </div>
        </b-col>

      </b-row>
      <table class="table table-hover no-border less-padding" style="font-size: 13px">
        <Loader v-if="loading"></Loader>
        <div v-if="!loading && practiceAndBehavior.length === 0">
          Engin ástundunar eða hegðunarskráning skráð!

        </div>
        <thead v-if="!loading && practiceAndBehavior.length > 0">
          <tr>
            <td>Dags. ({{ practiceAndBehavior.length }})</td>
            <td>Tími frá</td>
            <td>Tími til</td>
            <td>Áfangi</td>
            <td>Hópur</td>
            <td>Tegund</td>
            <td>Lýsing</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(c, idx) in practiceAndBehavior" :key="idx">
            <td>{{ c.dags }}</td>
            <td>{{ c.timi_fra }}</td>
            <td>{{ c.timi_til }}</td>
            <td>{{ c.namsgrein_afanganr }}</td>
            <td>{{ c.hopur }}</td>
            <td>
              <span v-if="c.type === 1">Ástundun</span>
              <span v-if="c.type === 2">Hegðun</span>
            </td>
            <td>{{ c.astundun_heiti }}</td>
          </tr>
        </tbody>
      </table>

    </div>
    <div v-if="tabType !== 3">
      <div style="font-size: 12px">
        <b>Fjarvistarkóðar:</b> <span v-for="att in attendanceList" :key="att.teg_fjarvista" class="pr-2">{{ att.kodi }} = {{
          att.heiti
        }}</span>
      </div>
      <div style="font-size: 12px">
        <b>Forfallakóðar:</b> <span v-for="abs in absenceList" :key="abs.teg_forfalla" class="pr-2">{{ abs.kodi }} = {{
          abs.heiti
        }}</span>
      </div>
    </div>
    <register-attendance-slidebar v-if="showSlidebar" :studentId="studentId" :termId="selectedTerm.onn_id" :type="selectedType"
      @closeSlidebar="closeSlidebar" @closeAndReloadSlidebar="closeAndReloadSlidebar">
    </register-attendance-slidebar>
    <register-practice-and-behavior-slidebar v-if="showSlidebarExtra" :studentId="studentId" :termId="term.id"
    @closeSlidebar="closeSlidebar" @closeAndReloadSlidebar="closeAndReloadSlidebar">
    </register-practice-and-behavior-slidebar>
  </div>
</template>

<script>
import moment from 'moment';
import students from '@/api/students';
import types from '@/api/types';
import terms from '@/api/terms';
import { mapActions, mapGetters } from 'vuex';

import AttendanceModules from '@/components/students/attendance/AttendanceModules.vue';
import AttendanceTerms from '@/components/students/attendance/AttendanceTerms.vue';
import AttendanceWeeks from '@/components/students/attendance/AttendanceWeeks.vue';
import RegisterAttendanceSlidebar from '@/components/students/attendance/RegisterAttendanceSlidebar.vue';
import RegisterPracticeAndBehaviorSlidebar from './RegisterPracticeAndBehaviorSlidebar.vue';
import Loader from '@/components/common/Loader.vue';

export default {
  name: 'student-attendance',
  props: ['studentId', 'termId', 'sideBarView', 'tabView', 'tabType'],
  // ef það er verið að skoða viðveru sem hliðarmynd birta takka
  components: {
    AttendanceModules,
    AttendanceTerms,
    AttendanceWeeks,
    RegisterAttendanceSlidebar,
    RegisterPracticeAndBehaviorSlidebar,
    Loader,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('nemendahald_nemendur');
    },
    canReadSchool() {
      return this.loggedInUserHasReadPermission('skolahald_uppbygging');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {
      noTerm: false,
      loading: false,
      view: 0,
      type: 0,
      term: {
        id: '',
        dateFrom: '',
        dateTo: '',
        name: '',
      },
      attendance: '',
      attendanceTerms: '',
      attendanceWeeks: '',
      absenceList: [],
      attendanceList: [],
      showSlidebar: false,
      showSlidebarExtra: false,
      selectedType: '',
      terms: '',
      selectedTerm: '',
      practiceAndBehavior: [],
    };
  },
  methods: {
    async getStudentDefaultTerm() {
      try {
        this.noTerm = false;
        const response = await students.getStudentDefaultTerm(this.studentId);
        this.term.id = response.data.onn_id;
        this.term.dateFrom = response.data.dags_fra;
        this.term.dateTo = response.data.dags_til;
        this.term.name = response.data.onn_eink;
        this.loadTerms();
      } catch (e) {
        if (e.response.status === 404) {
          this.noTerm = true;
          this.loadTerms(); // sækjum samt annir
        } else {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    async getTermDetails() {
      try {
        this.noTerm = false;
        const response = await terms.getTerm(this.termId);
        if (response.data.items.length === 1) {
          this.term.id = response.data.items[0].onn_id;
          this.term.dateFrom = response.data.items[0].dags_fra;
          this.term.dateTo = response.data.items[0].dags_til;
          this.term.name = response.data.items[0].onn_eink;
          this.loadTerms();
        } else {
          this.noTerm = true;
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    // sækja allar annir
    async loadTerms() {
      try {
        const response = await students.getStudentTerms(this.studentId, { future_terms: 0, past_terms: 1 });
        this.terms = response.data.items;
        if (this.term.id) {
          this.selectedTerm = this.terms.find(x => x.onn_id === this.term.id);
        } else {
          this.selectedTerm = this.terms[0];
        }
        if (this.tabType === 1) {
          this.getAttendanceFall();
        } else if (this.tabType === 2) {
          this.getAttendanceSpring();
        } else if (this.tabType === 3) {
          this.getPracticeAndBehavior();
        } else {
          this.getAttendance();
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async getAttendance(params) {
      this.attendance = '';
      if (!params) {
        this.type = 0;
      }
      try {
        this.loading = true;
        this.view = 0;
        if (this.selectedTerm) {
          const response = await students.getStudentAttendanceByTerm(this.studentId, this.selectedTerm.onn_id, params);
          if (response.data.items.length > 0) {
            this.attendance = response.data.items[0];
          }
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async getAttendanceTerms() {
      try {
        this.loading = true;
        this.attendanceTerms = '';
        this.view = 1;
        const response = await students.getStudentAttendanceTerms(this.studentId);
        if (response.data.items.length > 0) {
          this.attendanceTerms = response.data.items[0];
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async getAttendanceWeeks() {
      try {
        this.loading = true;
        this.attendanceWeeks = '';
        this.view = 2;
        const response = await students.getStudentAttendanceWeeks(this.studentId, this.term.id);
        if (response.data.items.length > 0) {
          this.attendanceWeeks = response.data.items[0];
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    getAttendanceFall() {
      const params = {
        dateFrom: moment(this.term.dateFrom).format('DD.MM.YYYY'),
        dateTo: `31.12.${moment(this.term.dateFrom).format('YYYY')}`,
      };
      this.type = 1;
      this.getAttendance(params);
    },
    getAttendanceSpring() {
      const params = {
        dateFrom: `01.01.${moment(this.term.dateTo).format('YYYY')}`,
        dateTo: moment(this.term.dateTo).format('DD.MM.YYYY'),
      };
      this.type = 2;
      this.getAttendance(params);
    },
    async getPracticeAndBehavior() {
      try {
        this.loading = true;
        if (this.term.id) {
          const response = await students.getStudentPracticeAndBehavior(this.studentId, this.term.id);
          this.practiceAndBehavior = response.data.items;
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadAbsenceList() {
      try {
        this.loading = true;
        const response = await types.getAbsenceList({ active: 1 });
        this.absenceList = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadAttendanceList() {
      try {
        this.loading = true;
        const response = await types.getAttendanceList({ active: 1 });
        this.attendanceList = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    openSlidebarExtra() {
      this.showSlidebarExtra = true;
    },
    openSlidebar(type) {
      this.showSlidebar = true;
      this.selectedType = type;
    },
    closeSlidebar() {
      this.showSlidebar = false;
      this.showSlidebarExtra = false;
    },
    closeAndReloadSlidebar() {
      this.closeSlidebar();
      this.getStudentDefaultTerm();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    studentId() {
      this.getStudentDefaultTerm();
    }, tabType() {
      if (this.tabType === 1) {
        this.getAttendanceFall();
      } else if (this.tabType === 2) {
        this.getAttendanceSpring();
      } else if (this.tabType === 3) {
        this.getPracticeAndBehavior();
      } else {
        this.getAttendance();
      }
    }
  },
  created() {
    if (this.termId) {
      this.getTermDetails();
    } else {
      this.getStudentDefaultTerm();
    }
    this.loadAbsenceList();
    this.loadAttendanceList();
  },
};
</script>
<style>
.attendancePercentageUnder80 {
  color: #cc0000;
}

.attendancePercentageUnder90 {
  color: #006699;
}
</style>
