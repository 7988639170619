<template>
  <Slidebar :large="true" @closeMe="closeSlidebar">
    <h4>Breyta um hóp í {{ item.namsgrein_afanganr }}</h4>
    <table class="table no-border">
      <tr>
        <th>Valinn hópur</th>
        <td>{{ item.hopur }}</td>
      </tr>
      <tr>
        <th>Nýr hópur</th>
        <td>
          <v-select id="newGroup" name="newGroup" v-model="group" :options="list" label="hopur"> </v-select>
        </td>
      </tr>
    </table>
    <Timetable
      :defaultGroups="defaultGroups"
      :removeGroups="removeGroups"
      :addGroups="addGroups"
      :showHover="showHoverGroup"
      :removeHover="removeHoverGroup"
    />
    <br />
    <b-btn variant="primary" @click="validateBeforeSubmit()" :disabled="!group">Breyta hóp</b-btn>
  </Slidebar>
</template>

<script>
import school from '@/api/school';
import requests from '@/api/requests';

import Slidebar from '@/components/common/Slidebar.vue';
import { mapActions } from 'vuex';
import Timetable from '@/components/requests/changeTimetableRequests/Timetable.vue';

export default {
  name: 'timetable-requests-change-group-slidebar',
  props: ['item', 'current', 'term', 'student'],
  components: {
    Slidebar,
    Timetable,
  },
  computed: {
    defaultGroups() {
      return this.current.map(x => x.hopur_id).concat(this.current.filter(x => x.yfirhopur && x.yfirhopur > 0).map(x => x.hopur_id));
    },
    removeGroups() {
      return this.item && this.item.hopur_id ? [this.item.hopur_id] : [];
    },
    addGroups() {
      return this.group && this.group.hopur_id ? [this.group.hopur_id] : [];
    },
  },
  data() {
    return {
      loading: false,
      list: [],
      group: null,
      showHoverGroup: '',
      removeHoverGroup: '',
      submitted: false,
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async loadGroups() {
      try {
        this.list = [];
        this.group = null;
        this.loading = true;
        const response = await school.getTermModulesGroups(this.item.annir_afangar_id); // eslint-disable-line
        this.list = response.data.items.filter(x => x.hopur_id !== this.item.hopur_id && x.dags_til_lidin !== 1);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });
        // Engar villur í formi.
        try {
          // Eyða út núverandi hóp
          await requests.processTimetableRequestStatus(this.item.nemandi_toflubreytingar_id, {
            status: 0,
          });

          // Bæta við nýjum hóp
          await requests.createTimetableRequest({
            student: this.student,
            term: this.term,
            group: this.group.hopur_id,
            type: 1,
          });

          this.displaySuccess('Hópi breytt');

          this.closeSlidebar();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    item() {
      this.loadGroups();
    },
  },
  created() {
    this.loadGroups();
  },
};
</script>

<style lang="scss"></style>
