<template>
  <page>
    <div>
      <form-wizard color="#98139c" errorColor="#F26175" title="" subtitle="" step-size="xs" :start-index="startIndex"
        ref="formWizard">
        <template slot="footer" slot-scope="props">
          <div class="wizard-footer-left">
            <b-btn v-if="props.activeTabIndex === 0" :to="{ name: 'Modules' }" :style="props.fillButtonStyle">Hætta
              við</b-btn>
            <b-btn v-if="props.activeTabIndex > 0" @click="props.prevTab()" :style="props.fillButtonStyle">Til
              baka</b-btn>
          </div>
          <div class="wizard-footer-right">
            <b-btn v-if="props.isLastStep || !showStep2" @click="saveModule(props)" class="wizard-footer-right"
              :style="props.fillButtonStyle" :disabled="saving">
              <Loader style="color:white" v-if="saving"></Loader>
              <span v-if="id"> Breyta áfanga </span>
              <span v-else> Stofna áfanga </span>
              
            </b-btn>
            <b-btn v-if="!props.isLastStep && showStep2" @click="props.nextTab()" class="wizard-footer-right"
              :style="props.fillButtonStyle">
              Áfram
            </b-btn>
          </div>
        </template>
        <tab-content title="Grunnupplýsingar" :beforeChange="step1Valid">
          <b-form @submit.prevent="step1Valid" novalidate data-vv-scope="step1">
            <b-row>
              <b-col class="pl-0" sm="12" xs="12" md="12" lg="6">
                <b-form-group :state="submitted && errors.has('step1.subject') ? false : ''"
                  :invalid-feedback="errors.first('step1.subject')">
                  <label label-for="subject">
                    Námsgrein*
                    <i @click="openCreateSlidebar()" class="fa fa-plus-circle text-primary cursor-pointer"
                      title="Stofna námsgrein" v-if="canEditNamsgrein" v-b-tooltip></i>
                  </label>
                  <v-select id="subject" name="subject" v-model="form.namsgrein" :clearable="false"
                    :state="submitted && errors.has('step1.subject') ? false : ''" :options="subjects"
                    data-vv-as="námsgrein" v-validate="'required'">
                  </v-select>
                </b-form-group>
                <b-form-group :state="submitted && errors.has('step1.code') ? false : ''"
                  :invalid-feedback="errors.first('step1.code')">
                  <label label-for="code"> Áfanganúmer* </label>
                  <b-form-input id="code" name="code" v-model="form.afanga_nr"
                    :state="submitted && errors.has('step1.code') ? false : ''" data-vv-as="áfanganúmer" maxLength="20"
                    v-validate="'required'">
                  </b-form-input>
                </b-form-group>
                <b-form-group :state="submitted && errors.has('step1.identity') ? false : ''"
                  :invalid-feedback="errors.first('step1.identity')">
                  <label label-for="identity">
                    Auðkenni áfanga
                    <i class="fa fa-info-circle" v-b-tooltip
                      title="Auðkenni áfanga er búið til úr kóða námsgreinar og áfanganúmeri"></i>
                  </label>
                  <b-form-input id="identity" name="identity" disabled :value="getIdentity"> </b-form-input>
                </b-form-group>
                <b-form-group :state="submitted && errors.has('step1.name') ? false : ''"
                  :invalid-feedback="errors.first('step1.name')">
                  <label label-for="name"> Heiti áfanga* </label>
                  <b-form-input id="name" name="name" v-model="form.afanga_heiti"
                    :state="submitted && errors.has('step1.name') ? false : ''" data-vv-as="heiti" maxLength="100"
                    v-validate="'required'">
                  </b-form-input>
                </b-form-group>
                <b-form-group :state="submitted && errors.has('step1.nameEn') ? false : ''"
                  :invalid-feedback="errors.first('step1.nameEn')">
                  <label label-for="nameEn">
                    Heiti áfanga (enska)
                    <i class="fa fa-info-circle" v-b-tooltip
                      title="Er notað á skírteinum sem eru á ensku, ef notandi velur að hafa Innu á ensku og ef sótt er um á ensku."></i>
                  </label>
                  <b-form-input id="nameEn" name="nameEn" v-model="form.afanga_heiti_enska"
                    :state="submitted && errors.has('step1.nameEn') ? false : ''" data-vv-as="heiti" maxLength="100">
                  </b-form-input>
                </b-form-group>
                <b-form-group v-if="showPrimary" :state="submitted && errors.has('step1.type') ? false : ''"
                  :invalid-feedback="errors.first('step1.type')">
                  <label label-for="type">
                    Tegund áfanga
                    <i class="fa fa-info-circle" v-b-tooltip
                      title="Notað ef skólar vilja flokka saman ákveðna tegundir áfanga."></i>
                  </label>
                  <v-select id="type" name="type" v-model="form.teg_afanga" :clearable="true"
                    :state="submitted && errors.has('step1.type') ? false : ''" :options="moduleTypes" label="heiti"
                    data-vv-as="tegund">
                  </v-select>
                </b-form-group>
                <b-form-group :state="submitted && errors.has('step1.aths') ? false : ''"
                  :invalid-feedback="errors.first('step1.aths')">
                  <label label-for="aths"> Athugasemdir </label>
                  <b-form-textarea id="aths" name="aths" v-model="form.aths"
                    :state="submitted && errors.has('step1.aths') ? false : ''" data-vv-as="aths"
                    style="font-size: 13px"></b-form-textarea>
                </b-form-group>
              </b-col>
              <b-col sm="12" xs="12" md="12" lg="6">
                <b-form-group :state="submitted && errors.has('step1.division') ? false : ''"
                  :invalid-feedback="errors.first('step1.division')">
                  <label label-for="subject"> Undirskóli* </label>
                  <v-select id="division" name="division" v-model="form.undirskoli" :clearable="false"
                    :state="submitted && errors.has('step1.division') ? false : ''" :options="divisions"
                    data-vv-as="undirskóli" v-validate="'required'">
                  </v-select>
                </b-form-group>
                <b-form-group :state="submitted && errors.has('step1.departments') ? false : ''"
                  :invalid-feedback="errors.first('step1.departments')">
                  <label label-for="departments"> Deild/ir </label>
                  <v-select id="departments" name="departments" v-model="form.deildir" :clearable="true"
                    :state="submitted && errors.has('step1.departments') ? false : ''" :options="departments"
                    data-vv-as="deildir" multiple>
                  </v-select>
                </b-form-group>
                <b-form-group :state="submitted && errors.has('step1.units') ? false : ''"
                  :invalid-feedback="errors.first('step1.units')">
                  <label label-for="units"> Einingar </label>
                  <b-form-input id="units" name="units" type="number" v-model="form.einingar"
                    :state="submitted && errors.has('step1.units') ? false : ''" data-vv-as="einingar">
                  </b-form-input>
                </b-form-group>

                <b-form-group :state="submitted && errors.has('step1.steps') ? false : ''"
                  :invalid-feedback="errors.first('step1.steps')">
                  <label label-for="steps"> Þrep </label>
                  <b-form-input id="steps" name="steps" type="number" v-model="form.trep"
                    :state="submitted && errors.has('step1.steps') ? false : ''" data-vv-as="þrep">
                  </b-form-input>
                </b-form-group>

                <b-form-group :state="submitted && errors.has('step1.steps') ? false : ''"
                  :invalid-feedback="errors.first('step1.steps')">
                  <label label-for="hoursTerm"> Fjöldi tíma á kennslutímabili </label>
                  <b-form-input id="hoursTerm" name="hoursTerm" type="number" v-model="form.timar_onn_alls"
                    :state="submitted && errors.has('step1.hoursTerm') ? false : ''"
                    data-vv-as="fjöldi tíma á kennslutímabili">
                  </b-form-input>
                </b-form-group>

                <b-form-group v-if="schoolHasSettings('stundatoflukerfi')"
                  :state="submitted && errors.has('step1.nrStudents') ? false : ''"
                  :invalid-feedback="errors.first('step1.nrStudents')">
                  <label label-for="nrStudents"> Fjöldi nemenda í hóp </label>
                  <b-form-input id="nrStudents" name="nrStudents" type="number" v-model="form.max_fj_nema"
                    :state="submitted && errors.has('step1.nrStudents') ? false : ''" data-vv-as="fjöldi nema í hóp">
                  </b-form-input>
                </b-form-group>

                <b-form-group v-if="!afangaskoli" :state="submitted && errors.has('step1.einStudentsprofs') ? false : ''"
                  :invalid-feedback="errors.first('step1.einStudentsprofs')">
                  <label label-for="einStudentsprofs"> Einingar til stúdentsprófs</label>
                  <b-form-input id="einStudentsprofs" name="einStudentsprofs" type="number" v-model="form.einingar_stprof"
                    :state="submitted && errors.has('step1.einStudentsprofs') ? false : ''"
                    data-vv-as="Einingar stúdentspróf">
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="!afangaskoli">
              <b-col class="pl-0">
                Til stúdentsprófs
                <b-form-checkbox v-model="form.studentsprof" class="display-inline-checkbox"></b-form-checkbox>
              </b-col>
            </b-row>
            <b-row class="pb-4">
              <b-col class="pl-0">
                Á námsferil
                <i class="fa fa-info-circle pr-2" v-b-tooltip
                  title="Á áfangi að koma fram á námsferli og skírteinum nemenda?"></i>
                <b-form-checkbox v-model="form.a_namsferil" class="display-inline-checkbox"></b-form-checkbox>
              </b-col>
              <b-col>
                Í fjarvistarferil
                <i class="fa fa-info-circle pr-2" v-b-tooltip
                  title="Áfangi þarf að vera í fjarvistarferli til að hægt sé að skrá mætingu hjá nemendum"></i>
                <b-form-checkbox v-model="form.i_fjarvistarferil" class="display-inline-checkbox"></b-form-checkbox>
              </b-col>
              <b-col>
                Oft í námsferli
                <i class="fa fa-info-circle pr-2" v-b-tooltip title="Má taka áfanga oft þrátt fyrir að hafa lokið honum, 
                ath. áfangi getur samt bara
                    verið einu sinni á hverju kennslutímabili"></i>
                <b-form-checkbox v-model="form.fjolval" class="display-inline-checkbox"></b-form-checkbox>
              </b-col>
            </b-row>
            <b-row class="pb-4">
              <b-col class="pl-0" v-if="!isLearningCenter && !isPrimarySchool">
                Fornámsáfangi
                <i class="fa fa-info-circle pr-2" v-b-tooltip
                  title="Áfangi sem nemendur þurfa að taka ef þeir uppfylla ekki lágmarks kröfur fyrir framhaldsskólaáfanga"></i>
                <b-form-checkbox v-model="form.fornamsafangi" class="display-inline-checkbox"></b-form-checkbox>
              </b-col>
              <b-col :class="{ 'pl-0': isPrimarySchool }" v-if="!isLearningCenter">
                Skólasóknaráfangi
                <i class="fa fa-info-circle pr-2" v-b-tooltip
                  title="Áfangi sem er notaður til að reikna út einkunn fyrir skólasókn."></i>
                <b-form-checkbox v-model="form.skolasokn" class="display-inline-checkbox"></b-form-checkbox>
              </b-col>
              <b-col v-if="!isLearningCenter">
                Umsjónaráfangi
                <i class="fa fa-info-circle pr-2" v-b-tooltip title="Áfangi sem er notaður fyrir umsjónarkennara."></i>
                <b-form-checkbox v-model="form.umsj_afangi" class="display-inline-checkbox"></b-form-checkbox>
              </b-col>
              <b-col v-if="isPrimarySchool"> </b-col>
            </b-row>

            <b-row v-if="copy">
              <b-col class="pl-0">
                <div style="text-align: center; border-bottom: 2px solid dimgrey; 
                  line-height: 0.1em; margin: 10px 0 20px; font-weight: bold;">
                  <span style="background: #fff; padding: 0 10px; color: dimgrey"> Afrita eftirfarandi frá
                    {{  form.copy_namsgrein }} </span>  
                </div>
              </b-col>

            </b-row>
            <b-row v-if="copy" class="pb-4">
              <b-col class="pl-0">
                Lýsingar
                <i class="fa fa-info-circle pr-3" v-b-tooltip
                  title="Á að afrita lýsingar sem eru skráðar á áfangann sem er verið að afrita"></i>
                <b-form-checkbox v-model="form.lysingar" class="display-inline-checkbox"></b-form-checkbox>
              </b-col>
              <b-col>
                Samhliða áfangar
                <i class="fa fa-info-circle pr-3" v-b-tooltip
                  title="Á að afrita námsgagnalista sem er skráður á áfangann sem er verið að afrita"></i>
                <b-form-checkbox v-model="form.samhlida" class="display-inline-checkbox"></b-form-checkbox>
              </b-col>
              <b-col>
                Námsgagnalisti
                <i class="fa fa-info-circle pr-3" v-b-tooltip
                  title="Á að afrita námsgagnalista sem er skráður á áfangann sem er verið að afrita"></i>
                <b-form-checkbox v-model="form.namsgagnalisti" class="display-inline-checkbox"></b-form-checkbox>
              </b-col>
            </b-row>
            <b-row v-if="copy" class="pb-4">
              <b-col class="pl-0">
                Jafngildis áfangar
                <i class="fa fa-info-circle pr-3" v-b-tooltip
                  title="Á að afrita jafngildis áfanga sem eru skráðir á áfangann sem er verið að afrita"></i>
                <b-form-checkbox v-model="form.jafngildisafangar" class="display-inline-checkbox"></b-form-checkbox>
              </b-col>
              <b-col>
                Setja afritunaráfanga sem jafngildi
                <i class="fa fa-info-circle pr-3" v-b-tooltip
                  title="Á að setja sem er verið að afrita sem jafngildisáfanga og öfugt"></i>
                <b-form-checkbox v-model="form.afangierjafngildisafangi"
                  class="display-inline-checkbox"></b-form-checkbox>
              </b-col>
              <b-col>
                Undanfarar
                <i class="fa fa-info-circle pr-3" v-b-tooltip
                  title="Á að afrita undanfara áfanga sem eru skráðir á áfangann sem er verið að afrita"></i>
                <b-form-checkbox v-model="form.undanfarar" class="display-inline-checkbox"></b-form-checkbox>
              </b-col>
            </b-row>
          </b-form>
        </tab-content>
        <tab-content title="Fyrir stundatöfluforrit" :beforeChange="step2Valid"
          v-if="!schoolHasSettings('stundatoflukerfi')">
          <b-form @submit.prevent="step2Valid" novalidate data-vv-scope="step2">
            <div>Eftirfarandi upplýsingar eru einungis notaðar ef skólinn lætur stundatöfluforritið raða sjálfvirkt í
              töflu.</div>
            <div class="mb-3">
              Ef skóli notar ekki stundatöfluforritið heldur stundatöflugerðina í admin þarf ekki ekki fylla þessar
              upplýsingar út nema
              skólar sem nota vinnumat þeir þurfa að fylla út fjöldatölur.
            </div>
            <b-row>
              <b-col class="pl-0" sm="12" xs="12" md="12" lg="6">
                <b-form-group :state="submitted && errors.has('step2.nrStudents') ? false : ''"
                  :invalid-feedback="errors.first('step2.nrStudents')">
                  <label label-for="nrStudents">
                    Fjöldi nema í hóp

                    <i class="fa fa-info-circle" v-b-tooltip title="Æskilegur fjöldi nemenda í hóp"></i>
                  </label>
                  <b-form-input id="nrStudents" name="nrStudents" type="number" v-model="form.max_fj_nema"
                    :state="submitted && errors.has('step2.nrStudents') ? false : ''" data-vv-as="fjöldi nema í hóp">
                  </b-form-input>
                </b-form-group>
                <b-form-group :state="submitted && errors.has('step2.deviation') ? false : ''"
                  :invalid-feedback="errors.first('step2.deviation')">
                  <label label-for="deviation"> Frávik - fjöldi nema </label>
                  <b-form-input id="deviation" name="deviation" type="number" v-model="form.fravik"
                    :state="submitted && errors.has('step2.deviation') ? false : ''" data-vv-as="frávik">
                  </b-form-input>
                </b-form-group>
                <b-form-group :state="submitted && errors.has('step2.maxStudents') ? false : ''"
                  :invalid-feedback="errors.first('step2.maxStudents')">
                  <label label-for="maxStudents">
                    Hámarksfjöldi í hóp <i class="fa fa-info-circle" v-b-tooltip
                      title="Má ekki fara yfir þennan fjölda í hóp"></i></label>
                  <b-form-input id="maxStudents" name="maxStudents" type="number" v-model="form.efstu_mork"
                    :state="submitted && errors.has('step2.maxStudents') ? false : ''" data-vv-as="hámarksfjöldi">
                  </b-form-input>
                </b-form-group>
                <b-form-group :state="submitted && errors.has('step2.classesWeek') ? false : ''"
                  :invalid-feedback="errors.first('step2.classesWeek')">
                  <label label-for="classesWeek">
                    Fjöldi tíma á viku <i class="fa fa-info-circle" v-b-tooltip
                      title="Hversu oft í viku er áfanginn kenndur?"></i>
                  </label>
                  <b-form-input id="classesWeek" name="classesWeek" type="number" v-model="form.timar_viku_nemar"
                    :state="submitted && errors.has('step2.classesWeek') ? false : ''" data-vv-as="fjöldi tíma á viku">
                  </b-form-input>
                </b-form-group>
                <b-form-group :state="submitted && errors.has('step2.continuous') ? false : ''"
                  :invalid-feedback="errors.first('step2.continuous')">
                  <label label-for="continuous">
                    Fjöldi samfelldra tíma
                    <i class="fa fa-info-circle" v-b-tooltip
                      title="Hversu marga tíma er kennt samfellt, t.d. áfangi er alltaf kenndur tvo tíma í einu þá er sett 2 hér.">
                    </i></label>
                  <b-form-input id="continuous" name="continuous" type="number" v-model="form.fj_samf_tima"
                    :state="submitted && errors.has('step2.continuous') ? false : ''" data-vv-as="samfelldir tímar">
                  </b-form-input>
                </b-form-group>
                <b-form-group :state="submitted && errors.has('step2.classroomType') ? false : ''"
                  :invalid-feedback="errors.first('step2.classroomType')">
                  <label label-for="classroomType">
                    Tegund stofu <i class="fa fa-info-circle" v-b-tooltip title="Hvernig stofu þarf að nota."></i></label>
                  <v-select id="classroomType" name="classroomType" v-model="form.teg_stofu" :clearable="true"
                    :state="submitted && errors.has('step2.classroomType') ? false : ''" :options="classrooms"
                    label="heiti" data-vv-as="tegund">
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col sm="12" xs="12" md="12" lg="6">
                <b-form-group :state="submitted && errors.has('step2.classroomBackup') ? false : ''"
                  :invalid-feedback="errors.first('step2.classroomBackup')"
                  v-if="form.teg_stofu_vara || show.classroomBackup">
                  <label label-for="classroomBackup">
                    Tegund stofu - vara
                    <i class="fa fa-info-circle" v-b-tooltip
                      title="Ef skráð er varastofa þá er sett í hana ef hitt er allt fullt."></i></label>
                  <v-select id="classroomBackup" name="classroomBackup" v-model="form.teg_stofu_vara" :clearable="true"
                    :state="submitted && errors.has('step2.classroomBackup') ? false : ''" :options="classrooms"
                    label="heiti" data-vv-as="tegund">
                  </v-select>
                </b-form-group>
                <div v-if="form.teg_stofu_2 || show.classroomExtra">
                  <b-form-group :state="submitted && errors.has('step2.classroomExtra') ? false : ''"
                    :invalid-feedback="errors.first('step2.classroomExtra')">
                    <label label-for="classroomBackup">
                      Tegund stofu - aukastofa
                      <i class="fa fa-info-circle" v-b-tooltip
                        title="Ef kenna á í tveimur stofum t.d. verklegt nám og bóklegt."></i></label>
                    <v-select id="classroomBackup" name="classroomBackup" v-model="form.teg_stofu_2" :clearable="true"
                      :state="submitted && errors.has('step2.classroomExtra') ? false : ''" :options="classrooms"
                      label="heiti" data-vv-as="tegund">
                    </v-select>
                  </b-form-group>
                  <b-form-group :state="submitted && errors.has('step2.studentsExtra') ? false : ''"
                    :invalid-feedback="errors.first('step2.studentsExtra')">
                    <label label-for="studentsExtra"> Fjöldi nemenda - aukastofa</label>
                    <b-form-input id="studentsExtra" name="studentsExtra" type="number" v-model="form.max_fj_nema_2"
                      :state="submitted && errors.has('step2.studentsExtra') ? false : ''" data-vv-as="fjöldi nema">
                    </b-form-input>
                  </b-form-group>
                  <b-form-group :state="submitted && errors.has('step2.continuousExtra') ? false : ''"
                    :invalid-feedback="errors.first('step2.continuousExtra')">
                    <label label-for="continuousExtra"> Fjöldi samfelldra tíma - aukastofa</label>
                    <b-form-input id="continuousExtra" name="continuousExtra" type="number" v-model="form.fj_samf_tima_2"
                      :state="submitted && errors.has('step2.continuousExtra') ? false : ''"
                      data-vv-as="fjöldi samfelldra tíma">
                    </b-form-input>
                  </b-form-group>
                  <b-form-group :state="submitted && errors.has('step2.classWeekExtra') ? false : ''"
                    :invalid-feedback="errors.first('step2.classWeekExtra')">
                    <label label-for="classWeekExtra"> Fjöldi tíma á viku - aukastofa</label>
                    <b-form-input id="classWeekExtra" name="classWeekExtra" type="number"
                      v-model="form.timar_viku_nemar_2"
                      :state="submitted && errors.has('step2.classWeekExtra') ? false : ''"
                      data-vv-as="fjöldi tíma á viku">
                    </b-form-input>
                  </b-form-group>
                </div>

                <b-btn variant="primary" class="mr-2" style="font-size: 13px"
                  v-if="form.teg_stofu && !form.teg_stofu_vara && !show.classroomBackup"
                  @click="show.classroomBackup = true">Bæta við varastofu
                </b-btn>
                <b-btn variant="primary" style="font-size: 13px"
                  v-if="form.teg_stofu && !form.teg_stofu_2 && !show.classroomExtra"
                  @click="show.classroomExtra = true">Bæta við aukastofu
                </b-btn>
              </b-col>
            </b-row>
          </b-form>
        </tab-content>
        <tab-content :title="isPrimarySchool ? 'Gögn til MMS' : 'Grunnskólaeinkunnir'"
          v-if="showPrimary || isPrimarySchool">
          <b-form @submit.prevent="step2Valid" novalidate data-vv-scope="step2">
            <div class="mb-3" v-if="!isPrimarySchool">
              Ef nemendur þurfa að vera með lágmarkseinkunn í grunnskólaeinkunn til að komast í áfangann þarf að fylla
              eftirfarandi
              upplýsingar út.
            </div>
            <div class="mb-3" v-if="isPrimarySchool">Ef fyllt er út í námsgrein þá fara einkunnir úr þessum áfanga til
              MMS.</div>
            <b-row>
              <b-col class="pl-0" sm="12" xs="12" md="12" lg="4">
                <b-form-group :state="submitted && errors.has('step3.primarySubject') ? false : ''"
                  :invalid-feedback="errors.first('step3.primarySubject')">
                  <label label-for="primarySubject"> <span v-if="isPrimarySchool">Námsgrein</span><span
                      v-else>Grunnskólafag </span></label>
                  <v-select id="primarySubject" name="primarySubject" v-model="form.fag" :clearable="true"
                    :state="submitted && errors.has('step3.primarySubject') ? false : ''" :options="primarySchoolSubjects"
                    label="fagheiti" data-vv-as="grunnskólafag">
                  </v-select>
                </b-form-group>
                <b-form-group v-if="!isPrimarySchool"
                  :state="submitted && errors.has('step3.primaryGradesLetters') ? false : ''"
                  :invalid-feedback="errors.first('step3.primaryGradesLetters')">
                  <label label-for="primaryGradesLetters">
                    Grunnskólaeinkunnir (Bókstafir)
                    <i class="fa fa-info-circle" v-b-tooltip
                      title="Á við ef nemendur luku grunnskóla áður en byrjað var að nota bókstafi."></i></label>
                  <div v-for="(bokstafur, idx) in form.bokstafir" :key="idx">
                    <b-form-checkbox v-model="bokstafur.selected" class="d-inline-block"></b-form-checkbox>
                    {{ bokstafur.id }}
                  </div>
                </b-form-group>
                <b-form-group v-if="!isPrimarySchool"
                  :state="submitted && errors.has('step3.primaryGradeFrom') ? false : ''"
                  :invalid-feedback="errors.first('step3.primaryGradeFrom')">
                  <label label-for="primaryGradeFrom"> Grunnskólaeinkunn frá (Tölustafir) </label>
                  <b-form-input id="primaryGradeFrom" name="primaryGradeFrom" type="text" v-model="form.grskeink_fra"
                    :state="submitted && errors.has('step3.primaryGradeFrom') ? false : ''"
                    v-validate="{ regex: /^[0-9\.\,\/]+$/ }">
                  </b-form-input>
                </b-form-group>
                <b-form-group v-if="!isPrimarySchool"
                  :state="submitted && errors.has('step3.primaryGradeTo') ? false : ''"
                  :invalid-feedback="errors.first('step3.primaryGradeTo')">
                  <label label-for="primaryGradeTo"> Grunnskólaeinkunn til (Tölustafir) </label>
                  <b-form-input id="primaryGradeTo" name="primaryGradeTo" type="text" v-model="form.grskeink_til"
                    :state="submitted && errors.has('step3.primaryGradeTo') ? false : ''"
                    v-validate="{ regex: /^[0-9\.\,\/]+$/ }">
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </tab-content>
      </form-wizard>
    </div>

    <subject-slidebar v-if="showCreateSubjectSlidebar" @closeSlidebar="closeCreateSlidebar"
      @closeAndReloadSlidebar="closeCreateSlidebar">
    </subject-slidebar>
  </page>
</template>

<script>
import common from '@/api/common';
import structure from '@/api/structure';
import curriculum from '@/api/curriculum';
import types from '@/api/types';
import { mapActions, mapGetters } from 'vuex';
import { FormWizard, TabContent } from 'vue-form-wizard';
// import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';
import Loader from '@/components/common/Loader.vue';

import SubjectSlidebar from '@/components/curriculum/subjects/SubjectSlidebar.vue';

export default {
  name: 'create-module',
  components: {
    FormWizard,
    TabContent,
    Loader,
    SubjectSlidebar,
  },
  computed: {
    showStep2() {
      return this.showPrimary || this.isPrimarySchool || !this.schoolHasSettings('stundatoflukerfi');
    },
    showStep3() {
      return (this.showPrimary || this.isPrimarySchool) && !this.schoolHasSettings('stundatoflukerfi');
    },
    showPrimary() {
      return (
        this.schoolSettings &&
        this.schoolSettings.tegund_skola_id !== 3 &&
        this.schoolSettings.tegund_skola_id !== 4 &&
        this.schoolSettings.tegund_skola_id !== 5
      );
    },
    isPrimarySchool() {
      return this.schoolSettings && this.schoolSettings.tegund_skola_id === 3;
    },
    isLearningCenter() {
      return this.schoolSettings && (this.schoolSettings.tegund_skola_id === 4 || this.schoolSettings.tegund_skola_id === 5);
    },
    afangaskoli() {
      return this.schoolSettings && this.schoolSettings.afangaskoli ? this.schoolSettings.afangaskoli === 1 : false;
    },
    canRead() {
      return this.loggedInUserHasReadPermission('namskra_afangar');
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_afangar');
    },
    canEditNamsgrein() {
      return this.loggedInUserHasWritePermission('namskra_namsgreinar');
    },
    getIdentity() {
      return `${this.form.namsgrein && this.form.namsgrein.kodi ? this.form.namsgrein.kodi : ''}${this.form.afanga_nr}`;
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      lang: is,
      startIndex: 0,
      id: '',
      copy: '',
      saving: false,
      form: {
        namsgrein: '',
        afanga_nr: '',
        afanga_heiti: '',
        afanga_heiti_enska: '',
        teg_afanga: '',
        undirskoli: '',
        deildir: [],
        einingar: '',
        trep: '',
        timar_onn_alls: '',
        a_namsferil: true,
        i_fjarvistarferil: true,
        fjolval: false,
        fornamsafangi: false,
        skolasokn: false,
        umsj_afangi: false,
        studentsprof: false,
        einingar_stprof: '',
        aths: '',
        copy_namsgrein: '',

        max_fj_nema: '',
        fravik: '',
        efstu_mork: '',
        timar_viku_nemar: '',
        fj_samf_tima: '',
        teg_stofu: '',

        teg_stofu_2: '',
        teg_stofu_vara: '',
        max_fj_nema_2: '',
        fj_samf_tima_2: '',
        timar_viku_nemar_2: '',

        grskeink_fra: '',
        grskeink_til: '',
        fag: '',
        bokstafir: [
          { id: 'A', selected: false },
          { id: 'B+', selected: false },
          { id: 'B', selected: false },
          { id: 'C+', selected: false },
          { id: 'C', selected: false },
          { id: 'D', selected: false },
          { id: 'Annað', selected: false },
        ],
        lysing: false,
        namsgagnalisti: false,
        samhlida: false,
        jafngildisafangar: false,
        afangierjafngildisafangi: false,
        undanfarar: false,
      },
      subjects: [],
      moduleTypes: [],
      divisions: [],
      departments: [],
      classrooms: [],
      primarySchoolSubjects: [],

      submitted: false,
      show: {
        classroomBackup: false,
        classroomExtra: false,
      },
      loading: false,
      showCreateSubjectSlidebar: false,
    };
  },
  methods: {
    async step1Valid() {
      this.submitted = true;
      this.errorList = [];
      await this.$validator.validateAll('step1').then(response => {
        if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        return false;
      });
      this.submitted = false;
      return true;
    },
    async step2Valid() {
      this.submitted = true;
      this.errorList = [];
      await this.$validator.validateAll('step2').then(response => {
        if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        return false;
      });
      this.submitted = false;
      return true;
    },
    completed() {
      this.$router.push({ name: 'Module', params: { id: this.id } });
    },
    async loadSubjects() {
      try {
        const response = await curriculum.getSubjectsList({ active: 1 });
        this.subjects = response.data.items.map(x => ({
          ...x,
          label: `${x.heiti ? x.heiti : ''} ${x.kodi ? x.kodi : ''}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadTypes() {
      try {
        const response = await types.getModuleList();
        this.moduleTypes = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadDivisions() {
      try {
        const response = await structure.getDivisionList();
        this.divisions = response.data.items.map(x => ({
          ...x,
          label: `${x.code} - ${x.name}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadDepartments() {
      try {
        const response = await structure.getDepartmentList();
        this.departments = response.data.items.map(x => ({
          ...x,
          label: `${x.name}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadClassrooms() {
      try {
        const response = await types.getClassroomList({ active: 1 });
        this.classrooms = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadPrimarySchoolSubjects() {
      try {
        const response = await common.getPrimarySchoolSubjects();
        this.primarySchoolSubjects = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadModule() {
      try {
        this.loading = true;

        const id = this.id ? this.id : this.copy;
        const response = await curriculum.getModuleDetails(id);
        const item = response.data;

        this.form.afanga_nr = item.afanga_nr;
        this.form.afanga_heiti = item.afanga_heiti;
        this.form.afanga_heiti_enska = item.afanga_heiti_enska;
        this.form.einingar = item.einingar;
        this.form.trep = item.trep;
        this.form.timar_onn_alls = item.timar_onn_alls;
        this.form.a_namsferil = item.a_namsferil === 1;
        this.form.i_fjarvistarferil = item.i_fjarvistarferil === 1;
        this.form.fornamsafangi = item.fornamsafangi === 1;
        this.form.skolasokn = item.skolasokn === 1;
        this.form.umsj_afangi = item.umsj_afangi === 1;
        this.form.fjolval = item.fjolval === 1;
        this.form.studentsprof = item.studentsprof === 1;
        this.form.einingar_stprof = item.einingar_stprof;
        this.form.aths = item.aths;
        this.form.copy_namsgrein = item.namsgrein_afanganr;

        this.form.max_fj_nema = item.max_fj_nema;
        this.form.fravik = item.fravik;
        this.form.efstu_mork = item.efstu_mork;
        this.form.timar_viku_nemar = item.timar_viku_nemar;
        this.form.fj_samf_tima = item.fj_samf_tima;
        this.form.max_fj_nema_2 = item.max_fj_nema_2;
        this.form.fj_samf_tima_2 = item.fj_samf_tima_2;
        this.form.timar_viku_nemar_2 = item.timar_viku_nemar_2;

        this.form.grskeink_fra = item.grskeink_fra;
        this.form.grskeink_til = item.grskeink_til;
        if (this.copy) {
          this.form.lysingar = true;
          this.form.namsgagnalisti = true;
          this.form.samhlida = true;
          this.form.jafngildisafangar = true;
          this.form.afangierjafngildisafangi = true;
          this.form.undanfarar = true;
        }
        await this.loadSubjects();
        this.form.namsgrein = this.subjects.find(x => x.namsgrein_id === item.namsgrein_id);

        await this.loadDivisions();
        this.form.undirskoli = this.divisions.find(x => x.division_id === item.undirskoli_id);
        await this.loadDepartments();
        this.form.deildir = this.departments.filter(x => item.deild.find(y => y.deild_id === x.department_id) !== undefined);

        await this.loadTypes();
        this.form.teg_afanga = this.moduleTypes.find(x => x.teg_afanga === item.teg_afanga);

        await this.loadClassrooms();
        this.form.teg_stofu = this.classrooms.find(x => x.teg_stofu === item.teg_stofu);
        this.form.teg_stofu_vara = this.classrooms.find(x => x.teg_stofu === item.teg_stofu_vara);
        this.form.teg_stofu_2 = this.classrooms.find(x => x.teg_stofu === item.teg_stofu_2);

        await this.loadPrimarySchoolSubjects();
        this.form.fag = this.primarySchoolSubjects.find(x => x.fagid === item.fag_id);
        const letters = ((item.grskeink_bokstafir || '').replaceAll(' ', '') || '').split(',');
        this.form.bokstafir.forEach(letter => {
          this.$set(letter, 'selected', letters.indexOf(letter.id) > -1);
        });
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async saveModule() {
      try {
        this.saving = true;
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll('step1', 'step2').then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });
        const item = {
          undirskoli_id: this.form.undirskoli.division_id,
          namsgrein_id: this.form.namsgrein.namsgrein_id,
          deildir: this.form.deildir.map(x => x.department_id).join(','),
          afanga_nr: this.form.afanga_nr,
          afanga_heiti: this.form.afanga_heiti,
          einingar: this.form.einingar,
          timar_viku_nemar: this.form.timar_viku_nemar,
          fj_samf_tima: this.form.fj_samf_tima,
          max_fj_nema: this.form.max_fj_nema,
          fravik: this.form.fravik,
          efstu_mork: this.form.efstu_mork,
          a_namsferil: this.form.a_namsferil ? 1 : 0,
          fjolval: this.form.fjolval ? 1 : 0,
          teg_afanga: this.form.teg_afanga ? this.form.teg_afanga.teg_afanga : '',
          teg_stofu: this.form.teg_stofu ? this.form.teg_stofu.teg_stofu : '',
          i_fjarvistarferil: this.form.i_fjarvistarferil ? 1 : 0,
          grskeink_fra: this.form.grskeink_fra ? this.form.grskeink_fra.toString().replace('.', ',') : '',
          grskeink_til: this.form.grskeink_til ? this.form.grskeink_til.toString().replace('.', ',') : '',
          fag_id: this.form.fag ? this.form.fag.fagid : '',
          skolasokn: this.form.skolasokn ? 1 : 0,
          afanga_heiti_enska: this.form.afanga_heiti_enska,
          timar_onn_alls: this.form.timar_onn_alls,
          fornamsafangi: this.form.fornamsafangi ? 1 : 0,
          studentsprof: this.form.studentsprof ? 1 : (!this.afangaskoli ? 0 : ''), // eslint-disable-line
          einingar_stprof: this.form.einingar_stprof,
          aths: this.form.aths,

          teg_stofu_vara: this.form.teg_stofu_vara ? this.form.teg_stofu_vara.teg_stofu : '',
          teg_stofu_2: this.form.teg_stofu_2 ? this.form.teg_stofu_2.teg_stofu : '',
          max_fj_nema_2: this.form.max_fj_nema_2,
          timar_viku_nemar_2: this.form.timar_viku_nemar_2,
          fj_samf_tima_2: this.form.fj_samf_tima_2,
          umsj_afangi: this.form.umsj_afangi ? 1 : 0,
          trep: this.form.trep,
          grskeink_bokstafir: this.form.bokstafir
            .filter(x => x.selected)
            .map(x => x.id)
            .join(', '),
          afrita_afanga_id: this.copy ? this.copy : '',
          lysingar: this.form.lysingar ? 1 : 0,
          samhlida: this.form.samhlida ? 1 : 0,
          jafngildisafangar: this.form.jafngildisafangar ? 1 : 0,
          afangierjafngildisafangi: this.form.afangierjafngildisafangi ? 1 : 0,
          undanfarar: this.form.undanfarar ? 1 : 0,
          namsgagnalisti: this.form.namsgagnalisti ? 1 : 0,
        };

        const response = await curriculum.createModule(item);
        this.displaySuccess('Áfangi stofnaður');
        this.$router.push({ name: 'Module', params: { id: response.data.id } });
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);

        if (e.response && e.response.data && e.response.data.error) {
          this.displayError(e.response.data.error);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } finally {
        this.saving = false;
      }
    },
    openCreateSlidebar() {
      this.showCreateSubjectSlidebar = true;
    },
    closeCreateSlidebar() {
      this.showCreateSubjectSlidebar = false;
      this.loadSubjects();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    if (this.$route.query && (this.$route.query.id || this.$route.query.copy)) {
      this.id = this.$route.query.id;
      this.copy = this.$route.query.copy;
      this.loadModule();
    } else {
      this.loadSubjects();
      this.loadTypes();
      this.loadDivisions();
      this.loadDepartments();
      this.loadClassrooms();
      this.loadPrimarySchoolSubjects();
    }
  },
};
</script>
<style>
.display-inline-checkbox {
  display: inline;
}
</style>
