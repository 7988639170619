<template>
  <page>
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <PageTitle :title="title" />
    <b-card>
      <h5>Grunnupplýsingar</h5>
      <b-row>
        <b-col>
          <label>Dagsetning</label>
          <inline-edit :doEdit="edit.date" @editField="toggleEdit('date')" @saveField="saveField('date')" :canEdit="canEdit">
            <span slot="content">
              {{ item.counseling_date | moment('DD.MM.YYYY') }}
            </span>
            <span slot="input">
              <datepicker
                class="datepicker_100_width"
                id="date"
                name="date"
                v-model="editInstance.counseling_date"
                :language="lang"
                :monday-first="true"
                format="dd.MM.yyyy"
                :typeable="false"
                v-validate="'required'"
                data-vv-as="dags"
                ref="dateOpenPickr"
              ></datepicker>
            </span>
          </inline-edit>
        </b-col>
        <b-col>
          <label>Tegund viðtals</label>
          <inline-edit :doEdit="edit.category" @editField="toggleEdit('category')" @saveField="saveField('category')" :canEdit="canEdit">
            <span slot="content">
              {{ item.counseling_category_name }}
            </span>
            <span slot="input">
              <!-- @change="updateNumber();updatePricing();" -->
              <v-select
                id="category"
                name="category"
                v-if="categories"
                v-model="editInstance.category"
                :clearable="false"
                :options="categories"
                label="counseling_category_name"
              >
              </v-select>
            </span>
          </inline-edit>
        </b-col>
        <b-col>
          <label>Ástæða viðtals</label>
          <inline-edit :doEdit="edit.reason" @editField="toggleEdit('reason')" @saveField="saveField('reason')" :canEdit="canEdit">
            <span slot="content">
              {{ item.counseling_reason_name }}
            </span>
            <span slot="input">
              <v-select
                id="reason"
                name="reason"
                v-model="editInstance.reason"
                :clearable="false"
                :options="reasons"
                label="counseling_reason_name"
                data-vv-as="ástæða"
              >
              </v-select>
            </span>
          </inline-edit>
        </b-col>
        <b-col>
          <div>
            <label translate>Verðútreikningur</label>
            <br />
            <strong translate>Númer viðtals</strong>: {{ item.counseling_number }}
            <br />
            <strong translate>Verð viðtals</strong>: {{ item.counseling_price | currency }}
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <label>Staður</label>
          <inline-edit :doEdit="edit.location" @editField="toggleEdit('location')" @saveField="saveField('location')" :canEdit="canEdit">
            <span slot="content">
              {{ item.counseling_location_name }}
            </span>
            <span slot="input">
              <v-select
                id="location"
                name="location"
                v-model="editInstance.location"
                :clearable="false"
                :options="locations"
                label="heiti"
                data-vv-as="staður"
              >
              </v-select>
            </span>
          </inline-edit>
        </b-col>
        <b-col>
          <label>Staður viðtals</label>
          <inline-edit
            :doEdit="edit.locationText"
            @editField="toggleEdit('locationText')"
            @saveField="saveField('locationText')"
            :canEdit="canEdit"
          >
            <span slot="content">
              {{ item.counseling_location_text }}
            </span>
            <span slot="input">
              <b-form-input id="location_text" name="location_text" v-model="editInstance.location_text" />
            </span>
          </inline-edit>
        </b-col>
        <b-col>
          <label>Niðurstaða viðtals (hámark 3)</label>
          <inline-edit
            :doEdit="edit.conclusions"
            @editField="toggleEdit('conclusions')"
            @saveField="saveField('conclusions')"
            :canEdit="canEdit"
          >
            <span slot="content">
              <span v-for="(c, idx) in item.conclusion" :key="c.counseling_conclusion_id">
                <span v-if="idx !== 0">, </span>
                {{ c.counseling_conclusion_name }}
              </span>
            </span>
            <span slot="input">
              <v-select
                id="conclusion"
                name="conclusion"
                v-model="editInstance.conclusions"
                multiple
                :options="editInstance.conclusions.length < 3 ? conclusions : []"
                label="counseling_conclusion_name"
              >
              </v-select>
            </span>
          </inline-edit>
        </b-col>
        <b-col />
      </b-row>
      <b-row>
        <b-col>
          <label>Greitt af öðrum</label>
          <inline-edit
            :doEdit="edit.paidByOthers"
            @editField="toggleEdit('paidByOthers')"
            @saveField="saveField('paidByOthers')"
            :canEdit="canEdit"
          >
            <span slot="content">
              {{ item.paid_by_others === 1 ? 'Já' : 'Nei' }}
            </span>
            <span slot="input">
              <b-form-checkbox id="paid_by_others" name="paid_by_others" v-model="editInstance.paid_by_others" />
            </span>
          </inline-edit>
        </b-col>
        <b-col>
          <label>Rafrænt viðtal / símtal</label>
          <inline-edit
            :doEdit="edit.digitalConsult"
            @editField="toggleEdit('digitalConsult')"
            @saveField="saveField('digitalConsult')"
            :canEdit="canEdit"
          >
            <span slot="content">
              {{ item.digital_consult === 1 ? 'Já' : 'Nei' }}
            </span>
            <span slot="input">
              <b-form-checkbox id="digital_consult" name="digital_consult" v-model="editInstance.digital_consult" />
            </span>
          </inline-edit>
        </b-col>
        <b-col /><b-col />
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <label>Athugasemdir</label>
          <inline-edit :doEdit="edit.comments" @editField="toggleEdit('comments')" @saveField="saveField('comments')" :canEdit="canEdit">
            <span slot="content">
              <div v-html="item.comments" />
            </span>
            <span slot="input">
              <quill-editor
                id="comments"
                name="comments"
                v-model="editInstance.comments"
                :state="submitted && errors.has('comments') ? false : ''"
                ref="textQuillEditor"
                :options="editorOption"
              >
              </quill-editor>
            </span>
          </inline-edit>
        </b-col>
      </b-row>
    </b-card>

    <b-card v-for="(p, idx) in item.counselees" :key="idx" class="mt-3">
      <h5>Einstaklingur {{ idx + 1 }}</h5>
      <b-row>
        <b-col>
          <label>Kennitala</label>
          <div>{{ p.ssn }}</div>
        </b-col>
        <b-col>
          <label>Nafn</label>
          <div>{{ p.nafn }}</div>
        </b-col>
        <b-col>
          <label>Kyn og aldur</label>
          <div>{{ p.kyn }} / {{ p.aldur }}</div>
        </b-col>
        <b-col>
          <label>Þjóðerni og búseta</label>
          <div>{{ p.rikisfang }} / {{ p.heimilisfang }}</div>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <label>Símanúmer</label>
          <inline-edit :doEdit="p.edit.tel" @editField="toggleEdit('tel', p)" @saveField="saveField('person_tel', p)" :canEdit="canEdit">
            <span slot="content">
              {{ p.phone_number }}
            </span>
            <span slot="input">
              <b-form-input id="tel" name="tel" v-if="p.editInstance" v-model="p.editInstance.phone_number" />
            </span>
          </inline-edit>
        </b-col>
        <b-col>
          <label>Netfang</label>
          <inline-edit
            :doEdit="p.edit.email"
            @editField="toggleEdit('email', p)"
            @saveField="saveField('person_email', p)"
            :canEdit="canEdit"
          >
            <span slot="content">
              {{ p.email }}
            </span>
            <span slot="input">
              <b-form-input id="email" name="email" v-if="p.editInstance" v-model="p.editInstance.email" />
            </span>
          </inline-edit>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <label>Skólastig</label>
          <inline-edit
            :doEdit="p.edit.level"
            @editField="toggleEdit('level', p)"
            @saveField="saveField('person_level', p)"
            :canEdit="canEdit"
          >
            <span slot="content">
              {{ p.school_level_name }}
            </span>
            <span slot="input">
              <v-select
                :id="`person${idx}schoolLevel`"
                :name="`person${idx}schoolLevel`"
                v-model="p.editInstance.schoolLevel"
                :clearable="false"
                v-if="p.editInstance"
                :options="levels"
                label="school_level_name"
              >
              </v-select>
            </span>
          </inline-edit>
        </b-col>
        <b-col>
          <label>Stéttarfélag</label>
          <inline-edit
            :doEdit="p.edit.union"
            @editField="toggleEdit('union', p)"
            @saveField="saveField('person_union', p)"
            :canEdit="canEdit"
          >
            <span slot="content">
              {{ p.union_name }}
            </span>
            <span slot="input">
              <v-select
                :id="`person${idx}union`"
                :name="`person${idx}union`"
                v-model="p.editInstance.union"
                :clearable="false"
                v-if="p.editInstance"
                :options="unions"
                label="union_name"
              >
              </v-select>
            </span>
          </inline-edit>
        </b-col>
        <b-col>
          <label>Staða á vinnumarkaði</label>
          <inline-edit
            :doEdit="p.edit.status"
            @editField="toggleEdit('status', p)"
            @saveField="saveField('person_status', p)"
            :canEdit="canEdit"
          >
            <span slot="content">
              {{ p.status_name }}
            </span>
            <span slot="input">
              <v-select
                :id="`person${idx}status`"
                :name="`person${idx}status`"
                v-model="p.editInstance.status"
                :clearable="false"
                v-if="p.editInstance"
                :options="status"
                label="status_name"
              >
              </v-select>
            </span>
          </inline-edit>
        </b-col>
        <b-col>
          <label>Vinnustaður</label>
          <inline-edit
            :doEdit="p.edit.workplace"
            @editField="toggleEdit('workplace', p)"
            @saveField="saveField('person_workplace', p)"
            :canEdit="canEdit"
          >
            <span slot="content">
              {{ p.workplace }}
            </span>
            <span slot="input">
              <b-form-input
                :id="`person${idx}workplace`"
                :name="`person${idx}workplace`"
                v-if="p.editInstance"
                v-model="p.editInstance.workplace"
              />
            </span>
          </inline-edit>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <label>Athugasemdir</label>
          <inline-edit
            :doEdit="p.edit.comments"
            @editField="toggleEdit('comments', p)"
            @saveField="saveField('person_comments', p)"
            :canEdit="canEdit"
          >
            <span slot="content">
              <div v-html="p.comments" />
            </span>
            <span slot="input">
              <quill-editor
                :id="`person${idx}comments`"
                :name="`person${idx}comments`"
                v-model="p.editInstance.comments"
                v-if="p.editInstance"
                ref="textQuillEditor"
                :options="editorOption"
              >
              </quill-editor>
            </span>
          </inline-edit>
        </b-col>
      </b-row>
    </b-card>
  </page>
</template>

<script>
import counseling from '@/api/counseling';
import common from '@/api/common';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import PageTitle from '@/components/common/PageTitle.vue';
import InlineEdit from '@/components/common/InlineEdit.vue';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';
import { quillEditor } from 'vue-quill-editor';

export default {
  name: 'counseling-interview',
  components: {
    Breadcrumbs,
    PageTitle,
    InlineEdit,
    Datepicker,
    quillEditor,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('nemendahald_namsradgjof');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      title: 'Skoða viðtal',
      breadcrumbs: [
        { name: 'Forsíða', route: 'Dashboard' },
        { name: 'Nemendahald', route: '' },
        { name: 'Námsráðgjöf', route: 'Counseling' },
        { name: 'Skoða viðtal', route: 'CounselingInterview' },
      ],
      lang: is,
      editorOption: {
        /* quill options */
      },
      id: '',
      item: '',
      editInstance: {
        category: null,
        reason: null,
        location: null,
        conclusions: [],
      },
      submitted: false,
      loading: {
        data: false,
        category: false,
        reason: false,
        location: false,
        conclusion: false,
        schoolLevel: false,
        union: false,
        status: false,
      },
      edit: {
        date: false,
        category: false,
        reason: false,
        location: false,
        locationText: false,
        conclusions: false,
        comments: false,
        paidByOthers: false,
      },
      categories: [],
      reasons: [],
      locations: [],
      conclusions: [],
      schoolLevels: [],
      unions: [],
      status: [],
    };
  },
  methods: {
    async loadCategory() {
      try {
        this.loading.category = true;
        const response = await counseling.getCategories();
        this.categories = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.category = false;
      }
    },
    async loadConclusion() {
      try {
        this.loading.conclusion = true;
        const response = await counseling.getConclusions();
        this.conclusions = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.conclusion = false;
      }
    },
    async loadReason() {
      try {
        this.loading.reason = true;
        const response = await counseling.getReasons();
        this.reasons = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.reason = false;
      }
    },
    async loadSchoolLevel() {
      try {
        this.loading.level = true;
        const response = await counseling.getSchoolLevels();
        this.levels = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.level = false;
      }
    },
    async loadStatus() {
      try {
        this.loading.status = true;
        const response = await counseling.getStatus();
        this.status = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.status = false;
      }
    },
    async loadUnion() {
      try {
        this.loading.union = true;
        const response = await counseling.getUnion();
        this.unions = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.union = false;
      }
    },
    async loadLocation() {
      try {
        this.loading.location = true;
        const response = await common.getPostalCodes();
        this.locations = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.location = false;
      }
    },
    async loadData() {
      try {
        this.item = '';
        this.loading.data = true;
        const response = await counseling.getItem(this.id);
        this.item = response.data;
        this.item.counselees.forEach(c => {
          this.$set(c, 'edit', { tel: false, email: false, level: false, union: false, status: false, workplace: false, comments: false });
        });
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.data = false;
      }
    },
    initializeForEdit() {
      // this.editInstance = JSON.parse(JSON.stringify(this.item));
      this.editInstance = {
        ...this.item,
        digital_consult: this.item.digital_consult === 1,
        category: this.categories.find(x => x.nsr_counseling_category_id === this.item.counseling_category_id),
        reason: this.reasons.find(x => x.nsr_counseling_reason_id === this.item.counseling_reason_id),
        location: this.locations.find(x => parseInt(x.postnumer, 10) === this.item.counseling_location_id),
        conclusions: this.conclusions.filter(
          x => this.item.conclusion.map(y => y.counseling_conclusion_id).indexOf(x.nsr_counseling_conclusion_id) > -1,
        ),
      };
    },
    toggleEdit(item, person) {
      if (person) {
        if (person.edit[item]) {
          this.$set(person, 'edit', {
            tel: false,
            email: false,
            level: false,
            union: false,
            status: false,
            workplace: false,
            comments: false,
          });
        } else {
          this.$set(person, 'edit', {
            tel: false,
            email: false,
            level: false,
            union: false,
            status: false,
            workplace: false,
            comments: false,
          });
          person.edit[item] = true; // eslint-disable-line
        }
        this.$set(person, 'editInstance', {
          ...person,
          schoolLevel: this.levels.find(x => x.nsr_school_level_id === person.school_level_id),
          union: this.unions.find(x => x.nsr_union_id === person.union_id),
          status: this.status.find(x => x.nsr_status_id === person.status_id),
        });
      } else {
        if (this.edit[item]) {
          this.edit = { date: false, category: false, reason: false, location: false, locationText: false, conclusions: false, comments: false, paidByOthers: false }; // eslint-disable-line
        } else {
          this.edit = { date: false, category: false, reason: false, location: false, locationText: false, conclusions: false, comments: false, paidByOthers: false }; // eslint-disable-line
          this.edit[item] = true;
        }
        this.initializeForEdit();
      }
    },
    async saveField(type, person) {
      const item = {
        category_id: this.item.counseling_category_id,
        conclusions: this.item.conclusion.map(x => x.counseling_conclusion_id).join(','),
        comments: this.item.comments,
        date: moment(this.item.counseling_date).format('DD.MM.YYYY'),
        group_id: this.item.counseling_group_id ? this.item.counseling_group_id : '',
        group_name: this.item.counseling_group_name ? this.item.counseling_group_name : '',
        location_id: this.item.counseling_location_id,
        location_text: this.item.counseling_location_text,
        reason_id: this.item.counseling_reason_id,
        paid_by_others: this.item.paid_by_others ? 1 : 0,
        digital_consult: this.item.digital_consult ? 1 : 0,
        id: this.item.nsr_counseling_id,
        persons: this.item.counselees.map(x => ({
          id: x.nsr_counselee_id,
          kennitala: x.ssn,
          email: x.email,
          tel: x.phone_number,
          workplace: x.workplace,
          comments: x.comments,
          schoolLevel: x.school_level_id,
          union: x.union_id,
          status: x.status_id,
        })),
      };
      const personIdx = person ? this.item.counselees.findIndex(x => x.nsr_counselee_id === person.nsr_counselee_id) : 0;
      switch (type) {
        case 'date': // Dagsetning
          this.$set(item, 'date', moment(this.editInstance.counseling_date).format('DD.MM.YYYY'));
          break;
        case 'category': // Tegund viðtals
          this.$set(item, 'category_id', this.editInstance.category.nsr_counseling_category_id);
          break;
        case 'reason': // Ástæða viðtals
          this.$set(item, 'reason_id', this.editInstance.reason.nsr_counseling_reason_id);
          break;
        case 'location': // Staður
          this.$set(item, 'location_id', this.editInstance.location.postnumer);
          break;
        case 'locationText': // Staður viðtals
          this.$set(item, 'location_text', this.editInstance.location_text);
          break;
        case 'conclusions': // Niðurstaða viðtals
          this.$set(item, 'conclusions', this.editInstance.conclusions.map(x => x.nsr_counseling_conclusion_id).join(','));
          break;
        case 'comments': // Athugasemdir
          this.$set(item, 'comments', this.editInstance.comments);
          break;
        case 'paidByOthers': // Greitt af öðrum
          this.$set(item, 'paid_by_others', this.editInstance.paid_by_others ? 1 : 0);
          break;
        case 'digitalConsult': // Rafrænt viðtal
          this.$set(item, 'digital_consult', this.editInstance.digital_consult ? 1 : 0);
          break;
        case 'person_tel': // Einstaklingur - símanúmer
          this.$set(item.persons[personIdx], 'tel', person.editInstance.phone_number);
          break;
        case 'person_email': // Einstaklingur - netfang
          this.$set(item.persons[personIdx], 'email', person.editInstance.email);
          break;
        case 'person_level': // Einstaklingur - skólastig
          this.$set(item.persons[personIdx], 'schoolLevel', person.editInstance.schoolLevel.nsr_school_level_id);
          break;
        case 'person_union': // Einstaklingur - stéttafélag
          this.$set(item.persons[personIdx], 'union', person.editInstance.union.nsr_union_id);
          break;
        case 'person_status': // Einstaklingur - staða á vinnumarkaði
          this.$set(item.persons[personIdx], 'status', person.editInstance.status.nsr_status_id);
          break;
        case 'person_workplace': // Einstaklingur - vinnustaður
          this.$set(item.persons[personIdx], 'workplace', person.editInstance.workplace);
          break;
        case 'person_comments': // Einstaklingur - athugasemd
          this.$set(item.persons[personIdx], 'comments', person.editInstance.comments);
          break;
        default:
          break;
      }
      await counseling.createInterview(item);
      if (person) {
        this.$set(person, 'edit', {
          tel: false,
          email: false,
          level: false,
          union: false,
          status: false,
          workplace: false,
          comments: false,
        });
      } else {
        this.edit = { date: false, category: false, reason: false, location: false, locationText: false, conclusions: false, comments: false, paidByOthers: false, digitalConsult: false }; // eslint-disable-line
      }
      this.loadData();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.id = this.$route.params.id;
    this.loadData();
    this.loadCategory();
    this.loadConclusion();
    this.loadReason();
    this.loadSchoolLevel();
    this.loadStatus();
    this.loadUnion();
    this.loadLocation();
  },
};
</script>
