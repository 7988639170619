<template>
  <Slidebar :autoClose="true" @closeMe="closeSlidebar" :large="large" :extraLarge="extraLarge">
    <copy-booklist :termId="termId" :slidebar="true" @finishCopy="closeAndReloadSlidebar()"></copy-booklist>

    <b-btn variant="danger" class="float-right mt-0" @click="deleteBooklist()">Eyða námsgagnalista</b-btn>
  </Slidebar>
</template>

<script>
import terms from '@/api/terms';
import { mapActions } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';
import CopyBooklist from '@/components/school/terms/booklist/CopyBooklist.vue';

export default {
  name: 'copy-booklist-slidebar',
  props: ['termId', 'large', 'extraLarge'],
  components: {
    Slidebar,
    CopyBooklist,
  },
  data() {
    return {};
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    closeAndReloadSlidebar() {
      this.$emit('closeAndReloadSlidebar');
    },
    async deleteBooklist() {
      const conf = confirm(`Ert þú viss um að eyða námsgagnalista kennslutímabilsins ?`); // eslint-disable-line
      if (conf) {
        try {
          await terms.deleteBooklist(this.termId);
          this.displaySuccess('Námsgagnalista eytt.');
          this.$emit('closeAndReloadSlidebar');
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>

<style lang="scss"></style>
