
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import common from '@/api/common';
import terms from '@/api/terms';

import Loader from '@/components/common/Loader.vue';
import StudyRecordCoursesList from '@/components/students/studyRecords/courses/StudyRecordCoursesList.vue';
import StudyRecordTerms from '@/components/students/studyRecords/terms/StudyRecordTerms.vue';
import CourseByTerm from '@/components/students/studyRecords/courseByTerms/CourseByTerm.vue';
import ConfirmSelectionTermSlidebar from '@/components/students/studyRecords/terms/ConfirmSelectionTermSlidebar.vue';
import UnconfirmSelectionTermSlidebar from '@/components/students/studyRecords/terms/UnconfirmSelectionTermSlidebar.vue';
import DeleteScheduleSlidebar from '@/components/students/studyRecords/terms/DeleteScheduleSlidebar.vue';
import RegisterCourseSlidebar from '@/components/students/studyRecords/terms/RegisterCourseSlidebar.vue';
import ResignationFromCoursesSlidebar from '@/components/students/studyRecords/terms/ResignationFromCoursesSlidebar.vue';

@Component({
  components: {
    Loader,
    StudyRecordCoursesList,
    StudyRecordTerms,
    CourseByTerm,
    ConfirmSelectionTermSlidebar,
    UnconfirmSelectionTermSlidebar,
    DeleteScheduleSlidebar,
    RegisterCourseSlidebar,
    ResignationFromCoursesSlidebar,
  },
})
export default class StudentStudyRecords extends Vue {
  @Action displayError!: (s: string) => void;
  @Action displaySuccess!: (s: string) => void;
  // @State loggedInUser: any;
  @Getter loggedInUserHasReadPermission: any;
  @Getter loggedInUserHasWritePermission: any;
  @Getter schoolSettings: any;

  @Prop() id!: string;
  @Prop() studentActive!: any;

  refreshData = false;
  showBy: string = 'term';
  showConfirmSelectionSlidebar = false;
  showUnconfirmSelectionSlidebar = false;
  showDeleteScheduleSlidebar = false;
  showRegisterCourseSlidebar = false;
  showResignationFromCoursesSlidebar = false;
  saving = false;
  selectedTerm = null;

  loading = {
    worker: false,
  };
  workers: any[] = [];

  /**
   * Computed
   */
  get canEdit() {
    return this.loggedInUserHasWritePermission('nemendahald_nemendur');
  }

  /**
   * Methods
   */
  async reloadStudent() {
    this.$emit('refresh');
  }
  async updateStudyRecordByCourse() {
    try {
      const conf = confirm('Ertu viss um að þú uppfæra samkvæmt brautarlýsingu?'); // eslint-disable-line
      if (conf) {
        this.saving = true;
        await this.$studentsApi.updateStudyRecordByCourse({ studentId: parseInt(this.id, 10) });
        // this.displaySuccess(`Uppfærsla skv. brautarlýsingu hefur verið sett í vinnslu.`);
      }
    } catch (e) {
      this.$log.error(e);
      this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
    } finally {
      this.saving = false;
    }
  }
  // finna virka önn fyrir töflubreytingar'//IEE SKOÐA
  async loadTerms() {
    try {
      const response = await terms.getTerms();
      if (response.data.items.find((x: any) => x.is_default === 1) !== undefined) {
        this.selectedTerm = response.data.items.find((x: any) => x.is_default === 1).onn_id;
      }
    } catch (e) {
      this.$log.error(e);
      this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
    }
  }
  openConfirmSelectionSlidebar() {
    this.showConfirmSelectionSlidebar = true;
  }
  openUnconfirmSelectionSlidebar() {
    this.showUnconfirmSelectionSlidebar = true;
  }
  deleteScheduleSlidebar() {
    this.showDeleteScheduleSlidebar = true;
  }
  registerCourseSlidebar() {
    this.showRegisterCourseSlidebar = true;
  }
  openResignationFromCoursesSlidebar() {
    this.showResignationFromCoursesSlidebar = true;
  }
  closeSlidebar() {
    this.showConfirmSelectionSlidebar = false;
    this.showUnconfirmSelectionSlidebar = false;
    this.showDeleteScheduleSlidebar = false;
    this.showRegisterCourseSlidebar = false;
    this.showResignationFromCoursesSlidebar = false;
    this.$emit('refresh');
  }
  closeAndReloadSlidebar() {
    this.refreshData = true;
    setTimeout(() => {
      this.refreshData = false;
    }, 1000);
    this.closeSlidebar();
  }

  async loadWorkerInProgress() {
    try {
      this.loading.worker = true;
      const response = await common.getWorkersInProgress('uppfaera_skv_brautarlysingu');
      this.workers = response.data.items.filter((x: any) => x.reference_id === parseInt(this.id, 10));
    } catch (e) {
      this.$log.error(e);
      this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
    } finally {
      this.loading.worker = false;
    }
  }

  onUppfaeraSkvBrautarlysinguComplete(data: { id: number }) {
    this.loadWorkerInProgress();

    if (data.id === parseInt(this.id, 10)) {
      this.refreshData = true;
      setTimeout(() => {
        this.refreshData = false;
      }, 1000);
    }
  }

  onUppfaeraSkvBrautarlysinguFailure(data: { id: number }) {
    this.loadWorkerInProgress();
    if (data.id === parseInt(this.id, 10)) {
      this.displayError(`Villa kom upp við að uppfæra skv. brautarkröfu. Vinsamlegast reyndu aftur.`);
    }
  }

  onUppfaeraSkvBrautarlysinguStarted() {
    this.loadWorkerInProgress();
  }

  created() {
    this.loadWorkerInProgress();
    this.loadTerms();
  }

  mounted() {
    this.$socket.emit('joinUppfaeraSkvBrautarlysingu');
    this.sockets.subscribe('uppfaeraSkvBrautarlysinguStarted', this.onUppfaeraSkvBrautarlysinguStarted);
    this.sockets.subscribe('uppfaeraSkvBrautarlysinguComplete', this.onUppfaeraSkvBrautarlysinguComplete);
    this.sockets.subscribe('uppfaeraSkvBrautarlysinguFailure', this.onUppfaeraSkvBrautarlysinguFailure);
  }

  beforeDestroy() {
    this.$socket.emit('leaveUppfaeraSkvBrautarlysingu');
    this.sockets.unsubscribe('uppfaeraSkvBrautarlysinguStarted');
    this.sockets.unsubscribe('uppfaeraSkvBrautarlysinguComplete');
    this.sockets.unsubscribe('uppfaeraSkvBrautarlysinguFailure');
  }
}
