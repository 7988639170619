<template>
  <Slidebar @closeMe="closeSlidebar" :extraLarge="true">
    <h4>{{ name }}</h4>
    <GradeScale v-if="id" :id="id" :edit="false"></GradeScale>
  </Slidebar>
</template>

<script>
import types from '@/api/types';
import GradeScale from '@/components/school/school/types/GradeScale.vue';
import Slidebar from '@/components/common/Slidebar.vue';

export default {
  name: 'grade-scale-slidebar',
  props: ['id'],
  components: {
    GradeScale,
    Slidebar,
  },
  data() {
    return {
      name: '',
      loading: false,
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async loadScale() {
      try {
        this.loading = true;
        const response = await types.getGradeScaleTypeDetail(this.id);
        this.name = response.data.items.length > 0 ? response.data.items[0].heiti : '';
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.loadScale();
  },
};
</script>

<style lang="scss"></style>
