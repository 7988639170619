<template>
  <page>
    <div>
      <form-wizard color="#98139c" errorColor="#F26175" title="" subtitle="" step-size="xs" :start-index="startIndex" ref="formWizard">
        <template slot="footer" slot-scope="props">
          <div class="wizard-footer-left">
            <b-btn v-if="props.activeTabIndex === 0" :to="{ name: 'Terms' }" :style="props.fillButtonStyle">Hætta við</b-btn>
            <b-btn v-if="props.activeTabIndex > 0" @click="props.prevTab()" :style="props.fillButtonStyle">Til baka</b-btn>
          </div>
          <div class="wizard-footer-right">
            <b-btn
              v-if="!props.isLastStep && props.activeTabIndex === 2"
              @click="saveTerm(props)"
              class="wizard-footer-right"
              :style="props.fillButtonStyle"
            >
              <span v-if="id"> Breyta kennslutímabili </span>
              <span v-else> Stofna kennslutímabil </span>
            </b-btn>
            <b-btn
              v-if="!props.isLastStep && props.activeTabIndex !== 2"
              @click="props.nextTab()"
              class="wizard-footer-right"
              :style="props.fillButtonStyle"
            >
              <span
                v-if="props.activeTabIndex !== 3 && props.activeTabIndex !== 4 && props.activeTabIndex !== 5 && props.activeTabIndex !== 6"
              >
                Áfram
              </span>
              <span v-if="props.activeTabIndex === 3">
                <span v-if="!manualCopy">Afrita</span>
                <span v-else>Velja</span>
              </span>
              <span v-if="props.activeTabIndex === 4">Sleppa afritun áfanga</span>
              <span v-if="props.activeTabIndex === 5">Sleppa afritun einkunnaregla</span>
              <span v-if="props.activeTabIndex === 6">Sleppa afritun stofa</span>
            </b-btn>
            <b-btn
              v-if="
                props.activeTabIndex === 3 ||
                props.activeTabIndex === 7 ||
                (props.activeTabIndex === 6 && schoolHasSettings('stundatoflukerfi'))
              "
              @click="completed()"
              class="wizard-footer-right mr-2"
              :style="props.fillButtonStyle"
              >Sleppa afritun
            </b-btn>
          </div>
        </template>
        <tab-content title="Almennar upplýsingar" :beforeChange="step1Valid">
          <Loader v-if="loading.term"></Loader>
          <b-form @submit.prevent="step1Valid" novalidate data-vv-scope="step1">
            <b-form-group :state="submitted && errors.has('step1.type') ? false : ''" :invalid-feedback="errors.first('step1.type')">
              <label label-for="type">
                Tegund kennslutímabils
                <b-link @click="slidebar.termType = true" v-if="canEditStructure" title="Stofna tegund kennslutímabils"
                  ><i class="fa fa-plus-circle"></i
                ></b-link>
              </label>
              <v-select
                id="type"
                name="type"
                v-model="form.type"
                :clearable="false"
                :state="submitted && errors.has('step1.type') ? false : ''"
                :options="types"
                label="tegund_heiti"
                data-vv-as="tegund"
              >
              </v-select>
            </b-form-group>
            <b-form-group
              :state="submitted && errors.has('step1.division') ? false : ''"
              :invalid-feedback="errors.first('step1.division')"
            >
              <label label-for="division">
                Undirskóli*
                <b-link @click="slidebar.division = true" v-if="canEditStructure" title="Stofna undirskóla"
                  ><i class="fa fa-plus-circle"></i
                ></b-link>
              </label>
              <v-select
                id="division"
                name="division"
                v-model="form.division"
                :clearable="false"
                v-validate="'required'"
                @input="validateCode()"
                :state="submitted && errors.has('step1.division') ? false : ''"
                :options="divisions"
                data-vv-as="undirskóli"
              >
              </v-select>
            </b-form-group>
            <b-form-group
              label-for="code"
              :state="submitted && errors.has('step1.code') ? false : ''"
              :invalid-feedback="errors.first('step1.code')"
              label="Einkenni kennslutímabils *"
            >
              <b-form-input
                id="code"
                name="code"
                type="text"
                @change="validateCode()"
                v-model="form.code"
                v-validate="'required'"
                :state="(submitted && errors.has('step1.code')) || codeExists ? false : ''"
                data-vv-as="einkenni"
                maxLength="6"
              >
              </b-form-input>
            </b-form-group>
            <div class="text-danger" style="font-weight: bold; font-size: 13px" v-if="codeExists">
              Ath. það er nú þegar til kennslutímabil með þessu einkenni. Vinsamlegast veljið annað.
            </div>
            <b-form-group
              label-for="name"
              :state="submitted && errors.has('step1.name') ? false : ''"
              :invalid-feedback="errors.first('step1.name')"
              label="Heiti kennslutímabils *"
            >
              <b-form-input
                id="name"
                name="name"
                type="text"
                v-model="form.name"
                v-validate="'required'"
                :state="submitted && errors.has('step1.name') ? false : ''"
                data-vv-as="heiti"
                maxLength="40"
              >
              </b-form-input>
            </b-form-group>
            <b-form-group :state="submitted && errors.has('step1.nameEns') ? false : ''" :invalid-feedback="errors.first('step1.nameEns')">
              <label label-for="nameEns">
                Heiti kennslutímabils - Enska
                <i class="fa fa-info-circle" title="Enska heitið er m.a. notað þegar einkunnablöð eru prentuð á ensku." v-b-tooltip></i>
              </label>
              <b-form-input
                id="nameEns"
                name="nameEns"
                type="text"
                v-model="form.nameEns"
                :state="submitted && errors.has('step1.nameEns') ? false : ''"
                data-vv-as="heiti enska"
                maxLength="50"
              >
              </b-form-input>
            </b-form-group>
          </b-form>
        </tab-content>
        <tab-content title="Dagsetningar" :beforeChange="step2Valid">
          <b-form @submit.prevent="step1Valid" novalidate data-vv-scope="step2">
            <b-row>
              <b-col class="pl-0">
                <div class="alert alert-info p-1" style="font-size: 13px">
                  <i class="fa fa-info-circle"></i> Almennar upplýsingar um dagsetningar
                  <ul class="mb-0" style="margin-left: -10px">
                    <li>
                      Upphafs- og lokadagur kennslutímabils segir til um á hvaða tímabili kennslutímabilið er virkt og hægt er að vinna með
                      það.
                    </li>
                    <li>
                      Stundatafla og áfangar birtast nemendum og kennurum um leið og kennslutímabil er virkt nema að sett sé dagssetning í
                      stundatafla nema/kennara opnar dags. þá birtist hún þann/þá daga.
                    </li>
                    <li>
                      Ef skóli notar stundatöfluforritið til að búa til stundatöflur þá er hægt að nota kennsla hefst og kennsla lýkur ef
                      ekki er kennt frá upphafsdagsetningu til lokadagsetningar kennslutímabils.
                    </li>
                    <li>
                      Ef „Lokað fyrir breytingar hjá kennurum“ er útfyllt þá geta almennir kennarar ekki lengur breytt hópunum sínum á
                      kennslutímabilinu þ.m.t. einkunnum, annars geta þeir það fram að lokadegi kennslutímabils.
                    </li>
                    <li>
                      Umsóknartímabil eldri nemenda er dagsetning sem opið er fyrir umsóknir í gegnum vef Menntagáttar fyrir nemendur sem
                      koma ekki beint úr 10. bekk. Ath. Opið fyrir umsóknir í næsta skrefi þarf einnig að vera "Já" til að hægt sé að sækja
                      um.
                    </li>
                  </ul>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3" md="3" sm="6" class="pl-0">
                <b-form-group
                  :state="submitted && errors.has('step2.dateFrom') ? false : ''"
                  :invalid-feedback="errors.first('step2.dateFrom')"
                >
                  <label label-for="dateFrom"> Upphafsdagur kennslutímabils* </label>
                  <datepicker
                    id="dateFrom"
                    name="dateFrom"
                    v-model="form.dateFrom"
                    :language="lang"
                    :monday-first="true"
                    format="dd.MM.yyyy"
                    :typeable="false"
                    :clear-button="true"
                    v-validate="'required'"
                    data-vv-as="dags. frá"
                    :state="submitted && errors.has('step2.dateFrom') ? false : ''"
                    @input="updateDateFrom"
                    ref="dateFromOpenPickr"
                  ></datepicker>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="3" sm="6">
                <b-form-group :state="submitted && errors.has('step2.dateTo') ? false : ''" :invalid-feedback="errors.first('step2.dateTo')"
                  ><label label-for="dateTo"> Lokadagur kennslutímabils* </label>
                  <datepicker
                    id="dateTo"
                    name="dateTo"
                    v-model="form.dateTo"
                    :language="lang"
                    :monday-first="true"
                    format="dd.MM.yyyy"
                    :typeable="false"
                    :clear-button="true"
                    v-validate="'required'"
                    data-vv-as="dags. frá"
                    :state="submitted && errors.has('step2.dateTo') ? false : ''"
                    ref="dateToOpenPickr"
                  ></datepicker>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3" md="3" sm="6" class="pl-0">
                <b-form-group
                  :state="submitted && errors.has('step2.teacherTableOpen') ? false : ''"
                  :invalid-feedback="errors.first('step2.teacherTableOpen')"
                >
                  <label label-for="teacherTableOpen"> Stundatafla kennara opnar dags. </label>
                  <datepicker
                    id="teacherTableOpen"
                    name="teacherTableOpen"
                    v-model="form.teacherTableOpen"
                    :language="lang"
                    :monday-first="true"
                    format="dd.MM.yyyy"
                    :typeable="false"
                    :clear-button="true"
                    data-vv-as="stundatafla kennara opnar"
                    :state="submitted && errors.has('step2.teacherTableOpen') ? false : ''"
                    ref="teacherTableOpenPickr"
                  ></datepicker>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="3" sm="6">
                <b-form-group
                  :state="submitted && errors.has('step2.studentTableOpen') ? false : ''"
                  :invalid-feedback="errors.first('step2.studentTableOpen')"
                >
                  <label label-for="studentTableOpen"> Stundatafla nema opnar dags. </label>
                  <datepicker
                    id="studentTableOpen"
                    name="studentTableOpen"
                    v-model="form.studentTableOpen"
                    :language="lang"
                    :monday-first="true"
                    format="dd.MM.yyyy"
                    :typeable="false"
                    :clear-button="true"
                    data-vv-as="stundatafla nema opnar"
                    :state="submitted && errors.has('step2.studentTableOpen') ? false : ''"
                    ref="studentTableOpenPickr"
                  ></datepicker>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3" md="3" sm="6" class="pl-0">
                <b-form-group
                  label-for="teachingBegins"
                  label=""
                  :state="submitted && errors.has('step2.teachingBegins') ? false : ''"
                  :invalid-feedback="errors.first('step2.teachingBegins')"
                >
                  <label label-for="teachingBegins"> Kennsla hefst </label>
                  <datepicker
                    id="teachingBegins"
                    name="teachingBegins"
                    v-model="form.teachingBegins"
                    :language="lang"
                    :monday-first="true"
                    format="dd.MM.yyyy"
                    :typeable="false"
                    :clear-button="true"
                    data-vv-as="kennsla hefst"
                    @input="updateTeachingBegins"
                    :state="submitted && errors.has('step2.teachingBegins') ? false : ''"
                    ref="teachingBeginsOpenPickr"
                  ></datepicker>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="3" sm="6">
                <b-form-group
                  label-for="teachingEnds"
                  label="Kennsla lýkur"
                  :state="submitted && errors.has('step2.teachingEnds') ? false : ''"
                  :invalid-feedback="errors.first('step2.teachingEnds')"
                >
                  <datepicker
                    id="teachingEnds"
                    name="teachingEnds"
                    v-model="form.teachingEnds"
                    :language="lang"
                    :monday-first="true"
                    format="dd.MM.yyyy"
                    :typeable="false"
                    :clear-button="true"
                    data-vv-as="kennsla lýkur"
                    :state="submitted && errors.has('step2.teachingEnds') ? false : ''"
                    ref="teachingEndsOpenPickr"
                  ></datepicker>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!--b-col lg="3" md="3" sm="6" class="pl-0">
                <b-form-group
                  label-for="examsBegins"
                  label="Dags. byrjun prófa"
                  :state="submitted && errors.has('step2.examsBegins') ? false : ''"
                  :invalid-feedback="errors.first('step2.examsBegins')"
                >
                  <datepicker
                    id="examsBegins"
                    name="examsBegins"
                    v-model="form.examsBegins"
                    :language="lang"
                    :monday-first="true"
                    format="dd.MM.yyyy"
                    :typeable="false"
                    :clear-button="true"
                    data-vv-as="dags. byrjun prófa"
                    :state="submitted && errors.has('step2.examsBegins') ? false : ''"
                    ref="examsBeginsOpenPickr"
                  ></datepicker>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="3" sm="6">
                <b-form-group
                  label-for="examsEnds"
                  label="Dags. prófloka"
                  :state="submitted && errors.has('step2.examsEnds') ? false : ''"
                  :invalid-feedback="errors.first('step2.examsEnds')"
                >
                  <datepicker
                    id="examsEnds"
                    name="examsEnds"
                    v-model="form.examsEnds"
                    :language="lang"
                    :monday-first="true"
                    format="dd.MM.yyyy"
                    :typeable="false"
                    :clear-button="true"
                    data-vv-as="dags. prófloka"
                    :state="submitted && errors.has('step2.examsEnds') ? false : ''"
                    ref="examsEndsOpenPickr"
                  ></datepicker>
                </b-form-group>
              </b-col-->
              <b-col lg="3" md="3" sm="6" class="pl-0">
                <b-form-group
                  :state="submitted && errors.has('step2.issueDate') ? false : ''"
                  :invalid-feedback="errors.first('step2.issueDate')"
                >
                  <label label-for="issueDate"> Loka fyrir breytingar kennara </label>
                  <datepicker
                    id="issueDate"
                    name="issueDate"
                    v-model="form.issueDate"
                    :language="lang"
                    :monday-first="true"
                    format="dd.MM.yyyy"
                    :typeable="false"
                    :clear-button="true"
                    data-vv-as="útgáfudags. prófa"
                    :state="submitted && errors.has('step2.issueDate') ? false : ''"
                    ref="issueDateOpenPickr"
                  ></datepicker>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3" md="3" sm="6" class="pl-0">
                <b-form-group
                  :state="submitted && errors.has('step2.applicationBegins') ? false : ''"
                  :invalid-feedback="errors.first('step2.applicationBegins')"
                >
                  <label label-for="applicationBegins"> Umsóknartímabil eldri nemenda hefst </label>
                  <datepicker
                    id="applicationBegins"
                    name="applicationBegins"
                    v-model="form.applicationBegins"
                    :language="lang"
                    :monday-first="true"
                    format="dd.MM.yyyy"
                    :typeable="false"
                    :clear-button="true"
                    data-vv-as="umsóknartímabil eldri nemenda hefst"
                    :state="submitted && errors.has('step2.applicationBegins') ? false : ''"
                    ref="applicationBeginsOpenPickr"
                  ></datepicker>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="3" sm="6">
                <b-form-group
                  label-for="applicationEnds"
                  label="Umsóknartímabili eldri nemenda lýkur"
                  :state="submitted && errors.has('step2.applicationEnds') ? false : ''"
                  :invalid-feedback="errors.first('step2.applicationEnds')"
                >
                  <datepicker
                    id="applicationEnds"
                    name="applicationEnds"
                    v-model="form.applicationEnds"
                    :language="lang"
                    :monday-first="true"
                    format="dd.MM.yyyy"
                    :typeable="false"
                    :clear-button="true"
                    data-vv-as="umsóknartímabili eldri nemenda lýkur"
                    :state="submitted && errors.has('step2.applicationEnds') ? false : ''"
                    ref="applicationEndsOpenPickr"
                  ></datepicker>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </tab-content>
        <tab-content title="Aðrar stýringar">
          <b-row>
            <b-col lg="3" md="3" sm="6" class="pl-0">
              <b-form-group label-for="tableLocked">
                <label label-for="evaluation">
                  Kennslutímabil læst
                  <i
                    class="fa fa-info-circle"
                    title="Ef kennslutímabil er læst þá er ekki hægt að vinna með það lengur þar sem það birtist ekki lengur í
                    neinum fellilistum.Þetta er m.a. notað þegar stundatafla er ekki tilbúin eða kennslutímabilð löngu liðið og
                    fellilistinn mjög langur."
                    v-b-tooltip
                  ></i>
                </label>
                <b-form-checkbox id="tableLocked" name="tableLocked" v-model="form.tableLocked"></b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col lg="3" md="3" sm="6">
              <b-form-group>
                <label label-for="evaluation">
                  Matsönn
                  <i class="fa fa-info-circle" title="Kennslutímabil þar sem bara metnir áfangar eru skráðir á." v-b-tooltip></i>
                </label>
                <b-form-checkbox id="evaluation" name="evaluation" v-model="form.evaluation"></b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col lg="3" md="3" sm="6">
              <b-form-group label-for="canApply">
                <label label-for="canApply">
                  <!--Má sækja um-->
                  Opna fyrir umsóknir í Menntagátt
                  <i
                    class="fa fa-info-circle"
                    title="Á einungis við nemendur sem eru ekki að koma beint úr grunnskóla.
                    Ráðuneytið stýrir opnun umsókna fyrir grunnskólanema."
                    v-b-tooltip
                  ></i>
                </label>
                <b-form-checkbox id="canApply" name="canApply" v-model="form.canApply"></b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col lg="3" md="3" sm="6">
              <b-form-group label-for="studyInEnglish" label="Nám á ensku">
                <b-form-checkbox id="studyInEnglish" name="studyInEnglish" v-model="form.studyInEnglish"></b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="3" md="3" sm="6" class="pl-0">
              <b-form-group :state="submitted && errors.has('tableDays') ? false : ''" :invalid-feedback="errors.first('tableDays')">
                <label label-for="tableDays">
                  Fjöldi daga á viku sem kenndir eru skv. stundatöflu
                  <i class="fa fa-info-circle" title="Hefur áhrif á stundatöflugerð." v-b-tooltip></i>
                </label>
                <b-form-input
                  id="tableDays"
                  name="tableDays"
                  type="number"
                  v-model="form.tableDays"
                  :state="submitted && errors.has('tableDays') ? false : ''"
                  data-vv-as="fj. töfludaga"
                  min="0"
                  max="7"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="3" md="3" sm="6" class="pl-0">
              <b-form-group :state="submitted && errors.has('lessonLength') ? false : ''" :invalid-feedback="errors.first('lessonLength')"
                ><label label-for="lessonLength">
                  Lengd kennslustundar (mínútúr)
                  <i class="fa fa-info-circle" title="Hefur áhrif á stundatöflugerð." v-b-tooltip></i>
                </label>
                <b-form-input
                  id="lessonLength"
                  name="lessonLength"
                  type="number"
                  v-model="form.lessonLength"
                  :state="submitted && errors.has('lessonLength') ? false : ''"
                  data-vv-as="lengd kennslustundar"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="3" md="3" sm="6" class="pl-0">
              <b-form-group
                :state="submitted && errors.has('nrNewStudents') ? false : ''"
                :invalid-feedback="errors.first('nrNewStudents')"
              >
                <label label-for="nrNewStudents">
                  Fjöldi nýnema
                  <i
                    class="fa fa-info-circle"
                    title="Fjöldi nýnema sem voru að ljúka grunnskóla, stilling fyrir Menntagátt."
                    v-b-tooltip
                  ></i>
                </label>
                <b-form-input
                  id="nrNewStudents"
                  name="nrNewStudents"
                  type="number"
                  v-model="form.nrNewStudents"
                  :state="submitted && errors.has('nrNewStudents') ? false : ''"
                  data-vv-as="fj. nýnema"
                  min="0"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="3" md="3" sm="6" class="pl-0">
              <b-form-group
                label-for="nrStudents"
                :state="submitted && errors.has('nrStudents') ? false : ''"
                :invalid-feedback="errors.first('nrStudents')"
              >
                <label label-for="nrStudents">
                  Fjöldi nema alls
                  <i class="fa fa-info-circle" title="Heildarfjöldi nemenda sem skólinn getur tekið inn." v-b-tooltip></i>
                </label>
                <b-form-input
                  id="nrStudents"
                  name="nrStudents"
                  type="number"
                  v-model="form.nrStudents"
                  :state="submitted && errors.has('nrStudents') ? false : ''"
                  data-vv-as="fj. nema"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </tab-content>
        <tab-content title="Afritun?">
          <h5 class="mb-3">Má bjóða þér að afrita frá völdu kennslutímabili?</h5>
          <ul style="padding-left: 20px">
            <li>Áfangar í boði</li>
            <li v-if="!schoolHasSettings('stundatoflukerfi')">Stofur í boði</li>
            <li>Einkunnareglur</li>
            <li>Námsgagnalisti</li>
          </ul>

          <b-form-radio-group stacked id="searchBy" v-model="manualCopy" name="searchBy" class="d-inline-block">
            <b-form-radio :value="false"
              >Já. <i>Ath. í næsta skrefi er valið hvað á að afrita, ekki er krafa um að afrita allt</i></b-form-radio
            >
            <b-form-radio :value="true"
              >Nei, vil gera þetta handvirkt.
              <i>Ath. Þegar búið er að velja áfanga, er boðið upp á að afrita einkunnareglur.</i></b-form-radio
            >
          </b-form-radio-group>
        </tab-content>
        <tab-content :title="manualCopy ? 'Áfangar' : 'Afrita frá kennslutímabili'">
          <div v-if="manualCopy">
            <TermModules
              v-if="form.division && id"
              :id="id"
              :division="form.division.division_id"
              :onlyEdit="true"
              @success="
                reloadComponents();
                goToNextTab();
              "
            ></TermModules>
          </div>
          <div v-else>
            <CopyTerm
              v-if="form.division && id"
              :id="id"
              :divisionId="form.division.division_id"
              :copyTerm="copy"
              :creating="true"
              @finishCopy="completed()"
            ></CopyTerm>
          </div>
        </tab-content>
        <tab-content title="Einkunnareglur" v-if="manualCopy" :lazy="true">
          <CopyGraderuleTerm
            v-if="form.division && id"
            :id="id"
            :copyTerm="copy"
            :isCreating="true"
            :reload="reloadComponent"
            :divisionId="form.division.division_id"
            @finishCopy="goToNextTab()"
          ></CopyGraderuleTerm>
        </tab-content>
        <tab-content title="Stofur" v-if="manualCopy && !schoolHasSettings('stundatoflukerfi')">
          <TermClassrooms v-if="id" :id="id" :onlyEdit="true" @success="goToNextTab()"></TermClassrooms>
        </tab-content>
        <tab-content title="Námsgagnalisti" v-if="manualCopy">
          <CopyBooklist v-if="id" :termId="id" @finishCopy="completed()"></CopyBooklist>
        </tab-content>
      </form-wizard>
    </div>
    <term-type-slidebar v-if="slidebar.termType" @closeSlidebar="loadTypes"> </term-type-slidebar>
    <division-slidebar v-if="slidebar.division" @closeSlidebar="loadDivisions"> </division-slidebar>
  </page>
</template>

<script>
import moment from 'moment';
import structure from '@/api/structure';
import types from '@/api/types';
import terms from '@/api/terms';
import { mapActions, mapGetters } from 'vuex';
import { FormWizard, TabContent } from 'vue-form-wizard';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';

import Loader from '@/components/common/Loader.vue';
import CopyTerm from '@/components/school/terms/CopyTerm.vue';
import TermModules from '@/components/school/terms/TermModules.vue';
import CopyGraderuleTerm from '@/components/school/terms/graderules/CopyGraderuleTerm.vue';
import TermClassrooms from '@/components/school/terms/TermClassrooms.vue';
import CopyBooklist from '@/components/school/terms/booklist/CopyBooklist.vue';

import TermTypeSlidebar from '@/components/school/school/types/TermTypeSlidebar.vue';
import DivisionSlidebar from '@/components/school/school/divisions/DivisionSlidebar.vue';

export default {
  name: 'create-term',
  components: {
    FormWizard,
    TabContent,
    Datepicker,
    CopyTerm,
    TermModules,
    CopyGraderuleTerm,
    TermClassrooms,
    TermTypeSlidebar,
    DivisionSlidebar,
    Loader,
    CopyBooklist,
  },
  computed: {
    canRead() {
      return this.loggedInUserHasReadPermission('skolahald_annir');
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_annir');
    },
    canEditStructure() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolHasSettings']),
  },
  data() {
    return {
      lang: is,
      codeExists: false,
      startIndex: 0,
      manualCopy: false,
      id: '',
      copy: '',
      form: {
        division: '',
        code: '',
        name: '',
        nameEns: '',
        tableLocked: false,
        type: '',
        dateFrom: '',
        dateTo: '',
        studentTableOpen: '',
        teacherTableOpen: '',
        teachingBegins: '',
        teachingEnds: '',
        examsBegins: '',
        examsEnds: '',
        issueDate: '',
        applicationBegins: '',
        applicationEnds: '',
        tableDays: '5',
        evaluation: false,
        lessonLength: '60',
        canApply: false,
        studyInEnglish: false,
        nrNewStudents: '',
        nrStudents: '',
      },
      loading: {
        divisions: false,
        types: false,
        term: false,
      },
      submitted: false,
      divisions: [],
      types: [],
      finishedModules: false,
      finishedGradeRule: false,
      slidebar: {
        termType: false,
        division: false,
      },
      reloadComponent: false,
    };
  },
  methods: {
    closeAllSlidebars() {
      this.slidebar.termType = false;
      this.slidebar.division = false;
    },
    updateDateFrom() {
      if (!this.form.dateTo) {
        this.form.dateTo = this.form.dateFrom;
      }
    },
    updateTeachingBegins() {
      if (!this.form.teachingEnds) {
        this.form.teachingEnds = this.form.teachingBegins;
      }
    },
    async step1Valid() {
      this.submitted = true;
      this.errorList = [];
      await this.$validator.validateAll('step1').then(response => {
        if (!response || this.codeExists) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        return false;
      });
      this.submitted = false;
      return true;
    },
    async step2Valid() {
      this.submitted = true;
      this.errorList = [];
      await this.$validator.validateAll('step2').then(response => {
        if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        return false;
      });
      this.submitted = false;
      return true;
    },
    completed() {
      this.$router.push({ name: 'Term', params: { id: this.id } });
    },
    goToNextTab() {
      if (this.$refs.formWizard) {
        this.$refs.formWizard.nextTab();
      }
    },
    reloadComponents() {
      this.reloadComponent = true;
      this.$nextTick(() => {
        this.reloadComponent = false;
      });
    },
    async saveTerm(props) {
      try {
        const data = {
          undirskoli_id: this.form.division.division_id,
          onn_eink: this.form.code,
          onn_heiti: this.form.name,
          tafla_laest: this.form.tableLocked ? 1 : 0,
          teg_annar: this.form.type ? this.form.type.tegund_id : '',
          dags_fra: this.form.dateFrom ? moment(this.form.dateFrom).format('DD.MM.YYYY') : '',
          dags_til: this.form.dateTo ? moment(this.form.dateTo).format('DD.MM.YYYY') : '',
          // prof_byrja: this.form.examsBegins ? moment(this.form.examsBegins).format('DD.MM.YYYY') : '',
          // prof_enda: this.form.examsEnds ? moment(this.form.examsEnds).format('DD.MM.YYYY') : '',
          lengd_kennslustundar: this.form.lessonLength,
          fjoldi_tofludaga: this.form.tableDays,
          matsonn: this.form.evaluation ? 1 : 0,
          utgafudags_profa: this.form.issueDate ? moment(this.form.issueDate).format('DD.MM.YYYY') : '',
          onn_heiti_enska: this.form.nameEns,
          ma_saekja_um: this.form.canApply ? 1 : 0,
          stundatafla_nem_opnar: this.form.studentTableOpen ? moment(this.form.studentTableOpen).format('DD.MM.YYYY') : '',
          stundatafla_kenn_opnar: this.form.teacherTableOpen ? moment(this.form.teacherTableOpen).format('DD.MM.YYYY') : '',
          umstimabil_eldri_fra: this.form.applicationBegins ? moment(this.form.applicationBegins).format('DD.MM.YYYY') : '',
          umstimabil_eldri_til: this.form.applicationEnds ? moment(this.form.applicationEnds).format('DD.MM.YYYY') : '',
          nam_a_ensku: this.form.studyInEnglish ? 1 : 0,
          kennsla_hefst: this.form.teachingBegins ? moment(this.form.teachingBegins).format('DD.MM.YYYY') : '',
          kennslu_lykur: this.form.teachingEnds ? moment(this.form.teachingEnds).format('DD.MM.YYYY') : '',
          fj_nema_alls: this.form.nrStudents,
          fj_nynema: this.form.nrNewStudents,
        };
        let response = null;
        if (this.id) {
          response = await terms.updateTerm(this.id, data);
          this.displaySuccess('Kennslutímabili breytt');
        } else {
          response = await terms.createTerm(data);
          this.id = response.data.id;
          this.displaySuccess('Kennslutímabil stofnað');
        }
        this.$router.push({ name: 'CreateTerm', query: { id: this.id, step: 3, copy: this.copy } });

        props.nextTab();
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadTerm() {
      try {
        this.loading.term = true;
        const id = this.id ? this.id : this.copy;
        const response = await terms.getTerm(id);
        const term = response.data.items[0];
        this.loadDivisions(term.undirskoli_id);
        this.loadTypes(term.teg_annar);
        this.form.name = term.onn_heiti;
        this.form.nameEns = term.onn_heiti_enska;
        this.form.tableLocked = term.tafla_laest === 1;
        this.form.tableDays = term.fjoldi_tofludaga;
        this.form.lessonLength = term.lengd_kennslustundar;
        this.form.canApply = term.ma_saekja_um === 1;
        this.form.evaluation = term.matsonn === 1;
        this.form.studyInEnglish = term.nam_a_ensku === 1;
        this.form.nrNewStudents = term.fj_nynema;
        this.form.nrStudents = term.fj_nema_alls;
        if (this.id) {
          this.form.code = term.onn_eink;
          this.form.dateFrom = moment(term.dags_fra).toDate();
          this.form.dateTo = moment(term.dags_til).toDate();
          this.form.studentTableOpen = term.stundatafla_nem_opnar ? moment(term.stundatafla_nem_opnar).toDate() : '';
          this.form.teacherTableOpen = term.stundatafla_kenn_opnar ? moment(term.stundatafla_kenn_opnar).toDate() : '';
          this.form.teachingBegins = term.kennsla_hefst ? moment(term.kennsla_hefst).toDate() : '';
          this.form.teachingEnds = term.kennslu_lykur ? moment(term.kennslu_lykur).toDate() : '';
          this.form.examsBegins = term.prof_byrja ? moment(term.prof_byrja).toDate() : '';
          this.form.examsEnds = term.prof_enda ? moment(term.prof_enda).toDate() : '';
          this.form.issueDate = term.utgafudags_profa ? moment(term.utgafudags_profa).toDate() : '';
          this.form.applicationBegins = term.umstimabil_eldri_fra ? moment(term.umstimabil_eldri_fra).toDate() : '';
          this.form.applicationEnds = term.umstimabil_eldri_til ? moment(term.umstimabil_eldri_til).toDate() : '';
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.term = false;
      }
    },
    async loadDivisions(divisionId) {
      this.closeAllSlidebars();
      if (this.canRead) {
        try {
          this.loading.divisions = true;
          const response = await structure.getDivisionList();
          this.divisions = response.data.items.map(x => ({
            ...x,
            label: `${x.code} - ${x.name}`,
          }));
          if (!this.form.division) {
            this.form.division = this.divisions.find(
              x =>
                (divisionId && x.division_id === divisionId) ||
                (!divisionId && ((x.name || '').toUpperCase().indexOf('DAG') > -1 || (x.code || '').toUpperCase().indexOf('DAG') > -1)),
            );
          }
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading.divisions = false;
        }
      }
    },
    async loadTypes(typeId) {
      this.closeAllSlidebars();
      if (this.canRead) {
        try {
          this.loading.types = true;
          const response = await types.getTermList();
          this.types = response.data.items;
          if (this.types.length > 0 && !this.form.type) {
            this.form.type = typeId ? this.types.find(x => x.tegund_id === typeId) : this.types[0];
          }
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading.types = false;
        }
      }
    },
    async validateCode() {
      this.codeExists = false;
      try {
        const response = await terms.checkTermCodeExists({ id: null, division: this.form.division.division_id, code: this.form.code });
        this.codeExists = response.data.already_exists > 0;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    if (this.$route.query && this.$route.query.step) {
      this.startIndex = parseInt(this.$route.query.step, 10);
    }
    if (this.$route.query && (this.$route.query.id || this.$route.query.copy)) {
      this.id = this.$route.query.id;
      this.copy = this.$route.query.copy;
      this.loadTerm();
    } else {
      this.loadDivisions();
      this.loadTypes();
    }
  },
};
</script>
