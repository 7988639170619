<template>
  <Slidebar @closeMe="closeSlidebar" :autoClose="false" :large="large" :extraLarge="extraLarge">
    <h4>Námsgögn</h4>
    <Loader v-if="loading.usage"></Loader>
    <table class="table less-padding" style="font-size: 13px" v-if="this.termId">
      <thead>
        <tr>
          <th>Kennslutímabil</th>
          <th>Áfangi</th>
          <th>
            Námsgagn
            <i
              @click="openCreateSlidebar()"
              class="fa fa-plus-circle text-primary cursor-pointer"
              title="Stofna námsgagn"
              v-if="!this.bookId && canEditUppbygging"
              v-b-tooltip
            ></i>
          </th>
          <th></th>
        </tr>
      </thead>
      <tr v-for="(item, idx) in list" :key="idx">
        <td>{{ item.onn_eink }}</td>
        <td>{{ item.namsgrein_afanganr }}</td>
        <td>
          {{ item.heiti }}

          <i @click="openViewSlidebar(item)" class="fa fa-search text-primary cursor-pointer" title="Skoða námsgagn" v-b-tooltip></i>
        </td>
        <td>
          <b-btn variant="danger" class="btn-xs" style="font-size: 13px" @click="deleteBook(item)" v-if="canEdit">
            <i class="fa fa-trash"></i>
          </b-btn>
        </td>
      </tr>
      <tr v-if="canEdit">
        <td>
          <v-select
            id="term"
            name="term"
            v-model="form.term"
            :options="terms"
            @input="loadModules()"
            label="onn_eink"
            :clearable="false"
            :disabled="termId !== undefined && termId !== null"
          >
          </v-select>
        </td>
        <td>
          <v-select
            id="module"
            name="module"
            v-model="form.module"
            :options="modules"
            label="namsgrein_afanganr"
            :clearable="false"
            :disabled="moduleId !== undefined && moduleId !== null"
            @input="addBook()"
          >
          </v-select>
        </td>
        <td>
          <v-select
            id="book"
            name="book"
            v-model="form.book"
            :options="books"
            label="heiti"
            :clearable="false"
            :disabled="bookId !== undefined && bookId !== null"
            @input="addBook()"
          >
          </v-select>
        </td>
        <td>
          <!--b-btn
            variant="primary"
            class="btn-xs"
            style="font-size: 13px;"
            :disabled="!this.form.term || !this.form.module || !this.form.book"
            @click="addBook()"
            title="Bæta við"
            v-b-tooltip
          >
            <i class="fa fa-plus-circle"></i>
          </b-btn-->
        </td>
      </tr>
    </table>
    <div v-if="this.termId" class="mb-2">
      <b-form-checkbox v-model="showAll" class="d-inline-block" @input="loadAll()"></b-form-checkbox> Sjá öll kennslutímabil
    </div>

    <table class="table less-padding" style="font-size: 13px" v-if="showAll">
      <thead>
        <tr>
          <th>Kennslutímabil</th>
          <th>Áfangi</th>
          <th>Námsgagn</th>
          <th>Bætt við</th>
          <th>Bætt við af</th>
        </tr>
      </thead>
      <TableRowLoader v-if="loading.all" colspan="3" :center="true" />
      <TableRowEmpty v-if="!loading.all && this.all.length === 0" colspan="3" :center="true" />
      <tr v-for="(item, idx) in all" :key="`all${idx}`">
        <td>{{ item.onn_eink }}</td>
        <td>{{ item.namsgrein_afanganr }}</td>
        <td style="max-width: 300px">{{ item.heiti }}</td>
        <td>{{ item.time_create | moment('DD.MM.YYYY HH:mm:ss') }}</td>
        <td>{{ item.person_create_name }}</td>
      </tr>
    </table>

    <book-type-slidebar
      v-if="showCreateBookSlidebar"
      :item="selectedBook"
      :defaultNoEdit="selectedBook && selectedBook.bok_id"
      @closeSlidebar="closeCreateSlidebar"
      @closeAndReloadSlidebar="closeAndReloadCreateSlidebar"
    >
    </book-type-slidebar>
  </Slidebar>
</template>

<script>
import types from '@/api/types';
import terms from '@/api/terms';
import { mapActions, mapGetters } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';
import Loader from '@/components/common/Loader.vue';
import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';

import BookTypeSlidebar from '@/components/school/school/types/BookTypeSlidebar.vue';

export default {
  name: 'book-usage-slidebar',
  props: ['bookId', 'termId', 'moduleId', 'edit', 'large', 'extraLarge'],
  components: {
    TableRowLoader,
    TableRowEmpty,
    Slidebar,
    Loader,
    BookTypeSlidebar,
  },
  computed: {
    canEdit() {
      return this.edit && this.loggedInUserHasWritePermission('skolahald_annir');
    },
    canEditUppbygging() {
      return this.edit && this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      changeMade: false,
      showCreateBookSlidebar: false,
      showAll: false,
      selectedBook: '',
      loading: {
        usage: false,
        term: false,
        book: false,
        all: false,
      },
      list: [],
      terms: [],
      modules: [],
      books: [],
      all: [],
      form: {
        term: null,
        module: null,
        book: null,
      },
    };
  },
  methods: {
    closeSlidebar() {
      if (this.changeMade) {
        this.$emit('closeAndReloadSlidebar');
      } else {
        this.$emit('closeSlidebar');
      }
    },
    async loadBookUsage() {
      try {
        this.loading.usage = true;
        const response = await types.getBookUsage({ bok_id: this.bookId, onn_id: this.termId, afangi_id: this.moduleId });
        this.list = response.data.items;
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.usage = false;
      }
    },
    async loadAll() {
      if (this.showAll) {
        try {
          this.loading.all = true;
          const response = await types.getBookUsage({ bok_id: this.bookId, afangi_id: this.moduleId });
          this.all = response.data.items;
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading.all = false;
        }
      }
    },
    async loadTerms() {
      try {
        this.loading.terms = true;
        const response = await terms.getTerms();
        this.terms = response.data.items;
        if (this.termId) {
          this.form.term = this.terms.find(x => x.onn_id === this.termId);
          this.loadModules();
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.terms = false;
      }
    },
    async loadModules() {
      this.modules = [];
      if (this.form.term) {
        try {
          this.loading.modules = true;
          const response = await terms.getTermModules(this.form.term.onn_id);
          this.modules = response.data.items;
          if (this.moduleId) {
            this.form.module = this.modules.find(x => x.afangi_id === parseInt(this.moduleId, 10));
          }
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading.modules = false;
        }
      }
    },
    async loadBooks() {
      try {
        this.loading.books = true;
        const response = await types.getBookList({ active: 1 });
        this.books = response.data.items;
        if (this.bookId) {
          this.form.book = this.books.find(x => x.bok_id === this.bookId);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.books = false;
      }
    },
    async addBook() {
      try {
        const data = {
          bok_id: this.form.book.bok_id,
          afangi_id: this.form.module.afangi_id,
          onn_id: this.form.term.onn_id,
        };
        await terms.createTermModuleBook(data);
        this.displaySuccess('Námsgagni bætt við');
        this.changeMade = true;
        if (!this.bookId) {
          this.form.book = null;
        }
        if (!this.moduleId) {
          this.form.module = null;
        }
        if (!this.termId) {
          this.form.term = null;
        }
        this.loadBookUsage();
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        if (e.response && e.response.data && e.response.data.error) {
          this.displayError(e.response.data.error);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    async deleteBook(item) {
      const conf = confirm(`Ert þú viss um að eyða ${item.onn_eink} - ${item.namsgrein_afanganr} - ${item.heiti}?`); // eslint-disable-line
      if (conf) {
        try {
          await terms.createTermModuleBook({ afangi_baekur_id: item.afangi_baekur_id });
          this.displaySuccess('Námsgagni eytt');
          this.changeMade = true;
          this.loadBookUsage();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.data && e.response.data.error) {
            this.displayError(e.response.data.error);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      }
    },
    openCreateSlidebar() {
      this.showCreateBookSlidebar = true;
      this.selectedBook = '';
    },
    openViewSlidebar(item) {
      this.showCreateBookSlidebar = true;
      this.selectedBook = item;
    },
    closeCreateSlidebar() {
      this.showCreateBookSlidebar = false;
      this.selectedBook = '';
    },
    closeAndReloadCreateSlidebar() {
      this.closeCreateSlidebar();
      this.loadBooks();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    if (this.termId) {
      this.showAll = false;
      this.loadBookUsage();
      this.loadTerms();
      this.loadBooks();
    } else {
      this.showAll = true;
      this.loadAll();
    }
  },
};
</script>

<style lang="scss"></style>
