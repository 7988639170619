<template>
  <div>
    <b-row class="breadcumb-location">
      <b-col>
        <CustomButton type="create" title="Stofna hlutverk" @click.native="jobCreateModal()" v-if="canEdit" />
      </b-col>
    </b-row>
    <div v-if="!loading && accessList.length === 0">Engin hlutverk fundust.</div>
    <table class="table" style="font-size: 13px; table-layout: fixed; width: inherit" v-if="accessList.length > 0">
      <thead class="sticky-header">
        <tr>
          <td style="width: 140px">Aðgangsstýring</td>
          <td style="width: 140px" v-for="item in jobList" :key="item.id">
            <b-link
              :to="{ name: 'AccessEditJob', params: { id: item.id } }"
              style="color: inherit"
              v-if="canEdit"
              :title="item.name + ' - Smelltu hér til að breyta öllum stýringum fyrir hlutverkið'"
              v-b-tooltip
              >{{ item.name.substring(0, 7) }} <span v-if="item.name.length > 7">..</span></b-link
            >
            <span v-else>{{ item.name }} </span>
            <i class="fa fa-user ml-2 cursor-pointer" @click="openStaffSlidebar(item)" title="Skoða starfsmenn" />
          </td>
        </tr>
      </thead>
      <tbody v-for="(access, index) in accessList" :key="access.id" style="border: none">
        <tr v-if="getCategory(index)">
          <td colspan="100%" style="font-size: 16px">
            <i>{{ getCategory(index) }}</i>
          </td>
        </tr>
        <tr>
          <td>
            <b-link
              :to="{ name: 'AccessEditAccess', params: { id: access.id } }"
              style="color: inherit"
              title="Smelltu hér til að breyta stýringunni fyrir öll hlutverk"
              v-b-tooltip
              v-if="canEdit"
              >{{ access.name }}</b-link
            >
            <span v-else>{{ access.name }} </span>
          </td>
          <td
            v-for="item in jobList"
            :key="item.id"
            :class="{
              'access-none': getAccess(item.id, access.id) === 0,
              'access-read': getAccess(item.id, access.id) === 1,
              'access-write': getAccess(item.id, access.id) === 2,
            }"
          >
            <AccessItem :job="item.id" :type="access.id" :access="getAccess(item.id, access.id)" @reload="loadList()" />
          </td>
        </tr>
      </tbody>
    </table>
    <create-job-modal ref="jobCreateModal" @successful="loadList"></create-job-modal>
    <access-staff-slidebar v-if="showStaffSlidebar" :item="selectedItem" @closeSlidebar="closeSlidebar"> </access-staff-slidebar>
  </div>
</template>

<script>
import staff from '@/api/staff';
import { mapGetters } from 'vuex';

import CustomButton from '@/components/common/CustomButton.vue';

import CreateJobModal from '@/components/staff/access/CreateJobModal.vue';
import AccessStaffSlidebar from '@/components/staff/access/AccessStaffSlidebar.vue';
import AccessItem from '@/components/staff/access/AccessItem.vue';

export default {
  name: 'access-admin',
  components: {
    CreateJobModal,
    AccessStaffSlidebar,
    CustomButton,
    AccessItem,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('starfsmannahald_adgangur');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      loading: false,
      jobList: [],
      accessList: [],
      categoryList: [],
      list: [],
      showStaffSlidebar: false,
      selectedItem: false,
    };
  },
  methods: {
    getCategory(index) {
      if (index === 0 || this.accessList[index - 1].category_id !== this.accessList[index].category_id) {
        return this.accessList[index].category_name;
      }
      return '';
    },
    async loadList() {
      try {
        this.loading = true;
        this.jobList = [];
        this.accessList = [];
        this.categoryList = [];
        const response = await staff.getAccessList();
        this.list = response.data.items;
        response.data.items.forEach(i => {
          if (this.jobList.map(x => x.id).indexOf(i.adgangur_hlutverk_id) === -1) {
            this.jobList.push({
              id: i.adgangur_hlutverk_id,
              name: i.hlutverk_heiti,
            });
          }
          if (this.accessList.map(x => x.id).indexOf(i.adgangur_styringar_id) === -1) {
            this.accessList.push({
              id: i.adgangur_styringar_id,
              name: i.styring_heiti,
              category_id: i.adgangur_flokkur_id,
              category_name: i.flokkur_heiti,
            });
            if (this.categoryList.map(x => x.id).indexOf(i.adgangur_flokkur_id) === -1) {
              this.categoryList.push({
                id: i.adgangur_flokkur_id,
                name: i.flokkur_heiti,
                count: 1,
              });
            } else {
              this.categoryList.find(x => x.id === i.adgangur_flokkur_id).count += 1;
            }
          }
        });
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    getAccess(job, access) {
      const item = this.list.find(x => x.adgangur_hlutverk_id === job && x.adgangur_styringar_id === access);
      return item.adgangur;
    },
    openStaffSlidebar(item) {
      this.selectedItem = item;
      this.showStaffSlidebar = true;
    },
    closeSlidebar() {
      this.showStaffSlidebar = false;
      this.selectedItem = {};
    },
    /*
     * Modal gluggar
     */
    jobCreateModal() {
      this.$refs.jobCreateModal.show(null, 0);
    },
  },
  created() {
    this.loadList();
  },
};
</script>

<style lang="scss">
.sticky-header {
  position: sticky !important;
  inset-block-start: 0 !important; /* "top" */
}
.access-none {
  background-color: #ffe8e8;
}
.access-read {
  background-color: #ffffdd;
}
.access-write {
  background-color: #c9f1c9;
}
</style>
