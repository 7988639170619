<template>
  <div>
    <b-row>
      <!-- <b-col class="pl-0 fitPage"> -->
        <b-col class="pl-0" :class="{'fitPage': tabIndex === 'eachweek'}">
        <StudentAttendance v-if="tabIndex === 'term'" :studentId="studentId" :tabView="0" :sideBarView=0
        ></StudentAttendance>
        <AttendanceTerms v-if="tabIndex ==='allterms'" :mainView=1 
        :studentId="studentId"></AttendanceTerms>
        <AttendanceWeeks v-if="tabIndex ==='eachweek'" :mainView=1
         :studentId="studentId"></AttendanceWeeks>
         <StudentAttendance v-if="tabIndex === 'autumn'" :studentId="studentId" :tabView="0" :tabType="1" :sideBarView=0
        ></StudentAttendance>
        <StudentAttendance v-if="tabIndex === 'spring'" :studentId="studentId" :tabView="0" :tabType="2" :sideBarView=0
        ></StudentAttendance>
        <StudentAttendance v-if="tabIndex === 'practiceandbehavior'" :studentId="studentId" :tabView="0" 
        :tabType="3" :sideBarView=0
        ></StudentAttendance>
        <ChangeStudentAttendance v-if="tabIndex === 'changeattendance'" :studentId="studentId" type="student"></ChangeStudentAttendance>
        <StudentAttendanceRecord v-if="tabIndex === 'record'" :studentId="studentId" type="student"></StudentAttendanceRecord>



      </b-col>
      <b-col cols="2">
        <div class="right-side-nav noprint">
          <b-nav vertical>
            <b-nav-item @click.prevent="changeTab('practiceandbehavior')" :active="tabIndex === 'practiceandbehavior'"
            v-if="schoolSettings.afangaskoli === 0 && schoolSettings.nota_misserisskipti === 1">Ástundun og hegðun</b-nav-item>
            <b-nav-item @click.prevent="changeTab('changeattendance')" :active="tabIndex === 'changeattendance'">Breyta viðveru</b-nav-item>
            <b-nav-item @click.prevent="changeTab('autumn')" :active="tabIndex === 'autumn'"
            v-if="schoolSettings.afangaskoli === 0 && schoolSettings.nota_misserisskipti === 1">Haustönn</b-nav-item>
            <b-nav-item @click.prevent="changeTab('eachweek')" :active="tabIndex === 'eachweek'">Hver vika</b-nav-item>

            <b-nav-item @click.prevent="changeTab('term')" :active="tabIndex === 'term'">Kennslutímabil</b-nav-item>
            <b-nav-item @click.prevent="changeTab('record')" :active="tabIndex === 'record'">Kladdi</b-nav-item>

            <b-nav-item @click.prevent="changeTab('spring')" :active="tabIndex === 'spring'"
            v-if="schoolSettings.afangaskoli === 0 && schoolSettings.nota_misserisskipti === 1">Vorönn</b-nav-item>
            <b-nav-item @click.prevent="changeTab('allterms')" :active="tabIndex === 'allterms'">Öll
              tímabil</b-nav-item>
          </b-nav>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import students from '@/api/students';

import StudentAttendance from '@/components/students/attendance/StudentAttendance.vue';
import AttendanceTerms from '@/components/students/attendance/AttendanceTerms.vue';
import AttendanceWeeks from '@/components/students/attendance/AttendanceWeeks.vue';
import StudentAttendanceRecord from '@/components/students/attendance/StudentAttendanceRecords.vue';
import ChangeStudentAttendance from './ChangeStudentAttendance.vue';

export default {
  name: 'student-details',
  props: ['studentId'],
  components: {
    StudentAttendance,
    AttendanceTerms,
    AttendanceWeeks,
    StudentAttendanceRecord,
    ChangeStudentAttendance
},
  computed: {
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      tabIndex: 'term',
      page: 'details',
      attendanceTerms: '',
      attendanceWeeks: '',
    };
  },
  methods: {
    refresh() {
      this.$emit('refresh');
    },
    changeTab(tab) {
      this.tabIndex = tab;
      this.$router.push({ name: 'Student', query: { page: this.page, tab: this.tabIndex } });
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
    async getAttendanceTerms() {
      try {
        this.loading = true;
        this.attendanceTerms = '';
        const response = await students.getStudentAttendanceTerms(this.studentId);
        if (response.data.items.length > 0) {
          this.attendanceTerms = response.data.items[0];
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async getAttendanceWeeks() {
      try {
        this.loading = true;
        this.attendanceWeeks = '';
        const response = await students.getStudentAttendanceWeeks(this.studentId, this.term.id);
        if (response.data.items.length > 0) {
          this.attendanceWeeks = response.data.items[0];
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (to && to.query && to.query.page) {
      this.page = to.query.page;
    }
    if (to && to.query && to.query.tab) {
      this.tabIndex = to.query.tab;
    }
    next();
  },
  created() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
    if (this.$route.query.tab) {
      this.tabIndex = this.$route.query.tab;
    }
    this.getAttendanceTerms();
  },

};
</script>
<style lang="scss">
.fitPage {
  width: 100%;
  overflow: auto;
}

</style>
