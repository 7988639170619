<template>
  <div>
    <div v-if="selectedStaff" class="pb-3 noprint">
      <span class="cursor-pointer" @click="openSearchSlidebar()">Starfsmaður: {{ staff.nafn }} </span>
      <b-btn
        variant="primary"
        size="sm"
        style="padding: 2px 4px; margin-top: -5px"
        title="Breyta um starfsmann"
        v-b-tooltip
        @click="openSearchSlidebar()"
        ><i class="fa fa-exchange"></i
      ></b-btn>
    </div>
    <h5 v-if="selectedStaff" class="onlyprint" style="position: absolute; left: 0; font-weight: bold">{{ staff.nafn }}</h5>

    <StaffSearch :slidebar="true" @changeStaff="selectStaff" v-if="!selectedStaff" />
    <StaffTimetable :id="selectedStaff" v-if="selectedStaff" />
    <staff-search-slidebar @closeSlidebar="selectStaff" v-if="showSearchSlidebar"> </staff-search-slidebar>
  </div>
</template>

<script>
import staff from '@/api/staff';
import { mapActions, mapGetters } from 'vuex';
import StaffSearch from '@/components/staff/staff/StaffSearch.vue';
import StaffTimetable from '@/components/staff/staff/StaffTimetable.vue';
import StaffSearchSlidebar from '@/components/staff/staff/StaffSearchSlidebar.vue';

export default {
  name: 'staffTimetable',
  components: {
    StaffSearch,
    StaffTimetable,
    StaffSearchSlidebar,
  },
  computed: {
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      selectedStaff: null,
      staff: '',
      showSearchSlidebar: false,
    };
  },
  methods: {
    openSearchSlidebar() {
      this.showSearchSlidebar = true;
    },
    selectStaff(item) {
      if (item) {
        this.selectedStaff = item;
        this.loadDetails();
      }
      this.showSearchSlidebar = false;
    },
    async loadDetails() {
      try {
        this.loading = true;
        const response = await staff.getStaffDetail(this.selectedStaff);
        this.staff = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
