<template>
  <div>
    <b-row>
      <b-col class="pl-0">
        <b-form-group label="Nafn" label-for="name">
          <b-form-input id="name" name="name" v-model="search.name" v-on:keyup.enter="searchAll" />
        </b-form-group>
      </b-col>
      <b-col class="pl-0">
        <b-form-group label="Kennitala" label-for="ssn">
          <b-form-input id="ssn" name="ssn" v-model="search.ssn" v-on:keyup.enter="searchAll" />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Staða" label-for="status">
          <v-select id="status" name="status" v-model="search.status" :options="statuses"> </v-select>
        </b-form-group>
      </b-col>
      <b-col v-if="!isVacation">
        <b-form-group label="Heill dagur" label-for="wholeDay">
          <v-select id="wholeDay" name="wholeDay" v-model="search.wholeDay" :options="yesNoList" :clearable="true"> </v-select>
        </b-form-group>
      </b-col>
      <b-col v-if="isVacation">
        <b-form-group label="Tegund leyfis" label-for="type">
          <v-select id="type" name="type" v-model="search.type" :options="types"> </v-select>
        </b-form-group>
      </b-col>
      <b-col></b-col>
    </b-row>
    <b-row>
      <b-col class="pl-0">
        <b-form-group label="Dags. frá" label-for="dateFrom">
          <datepicker
            id="dateFrom"
            name="dateFrom"
            v-model="search.date_from"
            :language="lang"
            :monday-first="true"
            :clear-button="true"
            format="dd.MM.yyyy"
            ref="dateFromOpenPickr"
            style="margin-top: -5px"
          ></datepicker>
        </b-form-group>
      </b-col>
      <b-col class="pl-0">
        <b-form-group label="Dags. til" label-for="dateTo">
          <datepicker
            id="dateTo"
            name="dateTo"
            v-model="search.date_to"
            :language="lang"
            :monday-first="true"
            :clear-button="true"
            format="dd.MM.yyyy"
            ref="dateToOpenPickr"
            style="margin-top: -5px"
          ></datepicker>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Undirskóli" label-for="division">
          <v-select id="division" name="division" v-model="search.division" :options="divisions" :clearable="true"> </v-select>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="Deild" label-for="department">
          <v-select id="department" name="department" v-model="search.department" :options="departments" :clearable="true"> </v-select>
        </b-form-group>
      </b-col>
      <b-col>
        <b-btn variant="primary" class="w-100" style="margin-top: 20px" @click="searchAll()">Leita</b-btn>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="pl-0">
        <i v-if="!firsttime">Smelltu á leita til að sækja <span v-if="isAbsence">veikindatilkynningar</span><span v-else>beiðnir</span></i>
        <div v-if="firsttime && all.length === 0 && !loading.all">
          Engar <span v-if="isAbsence">veikindatilkynningar</span><span v-else>leyfisbeiðnir</span> fundust
        </div>
        <Loader v-if="loading.all" />
        <table class="table less-padding mt-4" v-if="all.length > 0" style="font-size: 13px">
          <thead>
            <tr>
              <th><LinkOrder title="Nafn" :filteredList="all" :list="all" column="nafn" />({{ all.length }})</th>
              <th>
                <LinkOrder title="Kennitala" :filteredList="all" :list="all" column="kennitala" />
              </th>
              <th>
                <LinkOrder title="Braut" :filteredList="all" :list="all" column="braut_kodi" />
                <i class="fa fa-info-circle" title="Braut sem nemandinn var á miðað við dagsetningu beiðnar" v-b-tooltip></i>
              </th>
              <th>
                <LinkOrder title="Fj." :filteredList="all" :list="all" column="fj_skraninga" />
                <i
                  class="fa fa-info-circle"
                  v-if="isAbsence"
                  title="Fjöldi veikindatilkynninga síðustu 90 daga sem tekið hefur verið afstöðu til."
                  v-b-tooltip
                />
                <i class="fa fa-info-circle" v-else title="Fjöldi beiðna síðustu 90 daga sem tekið hefur verið afstöðu til." v-b-tooltip />
              </th>
              <th v-if="isVacation">
                <LinkOrder title="Dags.frá" :filteredList="all" :list="all" column="dags_fra" />
              </th>
              <th v-if="isVacation">
                <LinkOrder title="Dags.til" :filteredList="all" :list="all" column="dags_til" />
              </th>
              <th v-if="!isVacation">
                <LinkOrder title="Dags." :filteredList="all" :list="all" column="dags_fra" />
                <i class="fa fa-info-circle" title="Dagsetning tímans sem viðkomandi er veikur." v-b-tooltip></i>
              </th>
              <th>
                <LinkOrder title="Dags.skráð" :filteredList="all" :list="all" column="time_create" />
              </th>
              <th v-if="isVacation">
                <LinkOrder title="Tegund leyfis" :filteredList="all" :list="all" column="tegund_heiti" />
              </th>
              <th>Staða</th>
              <th>
                <LinkOrder title="Kóði" :filteredList="all" :list="all" column="forfoll_kodi" />
                <i
                  class="fa fa-info-circle"
                  title="Ef kóða vantar eru upplýsingar um hvaða kóði var notaður ekki til staðar."
                  v-b-tooltip
                ></i>
              </th>
              <th v-if="isVacation">
                <LinkOrder title="Fjöldi daga" :filteredList="all" :list="all" column="fjoldi_daga" />
              </th>
              <th v-if="!isVacation">
                <LinkOrder title="Heill dagur" :filteredList="all" :list="all" column="heill_dagur" />
              </th>
              <th>
                <LinkOrder title="Skýring" :filteredList="all" :list="all" column="athugasemd_skraning" />

                <i
                  class="fa fa-info-circle"
                  title="Skýring sem nemandi skráir með beiðni, þessi skýring fer ekki í fjarvistarferilinn og er ekki sýnileg kennurum."
                  v-b-tooltip
                ></i>
              </th>
              <th>
                <LinkOrder title="Ath. afgreiðslu" :filteredList="all" :list="all" column="athugasemd_afgreidsla" />
                <i
                  class="fa fa-info-circle"
                  title="Athugasemd afgreiðslu er einnig sýnileg nemanda og forráðamanni ef við á."
                  v-b-tooltip
                ></i>
              </th>
              <th />
              <th />
              <th v-if="isAbsence">
                Vottorð
                <i
                  class="fa fa-info-circle"
                  title="Skrá kröfu um að nemandi þurfi alltaf að skila vottorði þegar hann skráir veikindi."
                  v-b-tooltip
                ></i>
              </th>
              <th>
                <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="d-inline float-right">
                  <i class="fa fa-file-excel-o excelText cursor-pointer"></i>
                </download-csv>
              </th>
            </tr>
          </thead>
          <tr v-for="(item, index) in getPaginatedAll" :key="index">
            <td>
              {{ item.nafn }} {{ item.bekkur }}
              <b-link
                :to="{ name: 'Student', params: { id: item.nemandi_id } }"
                target="_blank"
                v-if="canViewStudent"
                title="Skoða nemanda"
                v-b-tooltip
                ><i class="fa fa-external-link"></i
              ></b-link>
            </td>
            <td>{{ item.kennitala }}</td>
            <td>
              <span v-b-tooltip :title="item.braut_heiti">{{ item.braut_kodi }}</span>
            </td>
            <td>
              {{ item.fj_skraninga }}
              <i
                class="cursor-pointer fa fa-search text-primary"
                title="Skoða skráningar nemanda"
                v-b-tooltip
                @click="openStudentRequestsSlidebar(item)"
              ></i>
            </td>
            <td v-if="isVacation">{{ item.dags_fra | moment('DD.MM.YYYY') }}</td>
            <td v-if="isVacation">{{ item.dags_til | moment('DD.MM.YYYY') }}</td>
            <td v-if="!isVacation">{{ item.dags_fra | moment('DD.MM.YYYY') }}</td>
            <td>{{ item.time_create | moment('DD.MM.YYYY HH:mm:ss') }}</td>
            <td v-if="isVacation">{{ item.tegund_heiti }}</td>
            <td>{{ item.stada_heiti }}</td>
            <td>{{ item.forfoll_kodi }}</td>
            <td v-if="isVacation">{{ item.fjoldi_daga }}</td>
            <td v-if="!isVacation">
              <yes-no :item="item.heill_dagur"></yes-no>
              <span v-if="item.heill_dagur === 0">
                - {{ item.nemandi_forf_timar.length }} <span v-if="item.nemandi_forf_timar.length === 1">tími </span
                ><span v-else>tímar </span>
              </span>
            </td>
            <td style="font-size: 13px">
              {{
                item.athugasemd_skraning && item.athugasemd_skraning.length > 40
                  ? `${item.athugasemd_skraning.substring(0, 40)}`
                  : item.athugasemd_skraning
              }}
              <span
                v-if="item.athugasemd_skraning && item.athugasemd_skraning.length > 40"
                v-b-tooltip
                :title="`${item.athugasemd_skraning}`"
                >...</span
              >
            </td>
            <td style="font-size: 13px">
              {{
                item.athugasemd_afgreidsla && item.athugasemd_afgreidsla.length > 40
                  ? `${item.athugasemd_afgreidsla.substring(0, 40)}`
                  : item.athugasemd_afgreidsla
              }}
              <span
                v-if="item.athugasemd_afgreidsla && item.athugasemd_afgreidsla.length > 40"
                v-b-tooltip
                :title="`${item.athugasemd_afgreidsla}`"
                >...</span
              >
            </td>
            <td>
              <span
                v-if="item.persona_is_nemandi === 0"
                class="fa fa-info-circle"
                v-b-tooltip
                :title="`Sótt um af: ${item.person_create_nafn}`"
              ></span>
            </td>
            <td>
              <span v-if="item.samt_person_nafn" class="fa fa-user" v-b-tooltip :title="`Afgreitt af: ${item.samt_person_nafn}`"></span>
            </td>
            <td v-if="isAbsence" style="font-size: 12px">
              <b-link @click="openStudentCommentSlidebar(item)" v-if="!item.vottord">Skrá</b-link>{{ item.vottord }}
            </td>
            <td>
              <div class="float-right">
                <b-btn variant="primary" size="xs" class="mr-2" v-if="canEdit && item.stada !== 1 && item.stada !== 0">
                  <i v-b-tooltip title="Breyta leyfisskráningu" class="fa fa-pencil" @click="openRequestSlidebar(item, true)"
                /></b-btn>
                <b-btn variant="primary" size="xs" @click="openRequestSlidebar(item, false)"
                  ><i v-b-tooltip title="Skoða beiðni" class="fa fa-search"></i>
                </b-btn>
              </div>
            </td>
          </tr>
        </table>
        <Pagination
          :currentPage="currentPage"
          :totalRows="all.length"
          :perPage="perPage"
          v-if="all.length > 15"
          @change-page="changePage"
          @change-limit="changeLimit"
        />
      </b-col>
    </b-row>
    <view-request-slidebar
      v-if="showRequestSlidebar"
      :item="selectedItem"
      :defaultEdit="defaultEdit"
      @closeSlidebar="closeSlidebar"
      @closeAndReload="closeAndReloadSlidebar"
    />
    <student-add-comment-slidebar
      v-if="showStudentCommentSlidebar"
      :studentId="selectedItem && selectedItem.nemandi_id"
      :showDetails="true"
      :certificate="true"
      @closeSlidebar="closeSlidebar"
      @closeAndReload="closeAndReloadSlidebar"
    />
    <student-absence-requests-slidebar
      v-if="showStudentRequestsSlidebar"
      :studentId="selectedItem && selectedItem.nemandi_id"
      :absenceTypes="absenceTypes"
      @closeSlidebar="closeSlidebar"
      @closeAndReload="closeAndReloadSlidebar"
    />
  </div>
</template>

<script>
import requests from '@/api/requests';
import structure from '@/api/structure';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';
import LinkOrder from '@/components/common/LinkOrder.vue';
import Pagination from '@/components/common/Pagination.vue';
import Loader from '@/components/common/Loader.vue';
import YesNo from '@/components/common/YesNo.vue';

import ViewRequestSlidebar from '@/components/requests/reviewAbsence/ViewRequestSlidebar.vue';
import StudentAddCommentSlidebar from '@/components/students/comments/StudentAddCommentSlidebar.vue';
import StudentAbsenceRequestsSlidebar from '@/components/students/student/absenceRequests/StudentAbsenceRequestsSlidebar.vue';

export default {
  name: 'review-absence-all',
  props: ['absenceTypes', 'selectedStudent'],
  components: {
    LinkOrder,
    Pagination,
    Loader,
    ViewRequestSlidebar,
    Datepicker,
    YesNo,
    StudentAddCommentSlidebar,
    StudentAbsenceRequestsSlidebar,
  },
  computed: {
    getPaginatedAll() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.all.slice(start, end);
    },
    isVacation() {
      return this.absenceTypes.indexOf('2') > -1 || this.absenceTypes.indexOf('3') > -1;
    },
    isAbsence() {
      return this.absenceTypes.indexOf('1') > -1;
    },
    canEdit() {
      if (this.isVacation) return this.loggedInUserHasWritePermission('beidnir_leyfi');
      if (this.isAbsence) return this.loggedInUserHasWritePermission('beidnir_veikindi');
      return false;
    },
    getExcelData() {
      const list = [];
      list.push([]);
      list.push(['Veikindatilkynningar']);
      list.push([]);
      const headers = ['Nafn', 'Kennitala', 'Braut kóði', 'Braut heiti'];

      if (!this.afangaskoli) {
        headers.push('Bekkur');
      }

      headers.push('Skráningar seinustu 90 daga');

      if (this.isVacation) {
        headers.push('Dags. frá', 'Dags. til');
      } else {
        headers.push('Dags.');
      }

      headers.push('Dags.skráð');

      if (this.isVacation) {
        headers.push('Tegund leyfis');
      }

      headers.push('Staða', 'Kóði');

      if (this.isVacation) {
        headers.push('Fjöldi daga');
      }
      headers.push(
        'Heill dagur',
        'Fjöldi tíma',
        'Skýring',
        'Ath. afgreiðslu',
        'Ath. fjarv',
        'Sótt um af',
        'Afgreitt af',
        'Netfang',
        'Einkanetfang',
      );

      list.push(headers);

      this.all.forEach(a => {
        const item = {
          Nafn: a.nafn,
          Kennitala: `="${a.kennitala}"`,
          'Braut kóði': a.braut_kodi,
          'Braut heiti': a.braut_heiti,
        };
        if (!this.afangaskoli) {
          item.Bekkur = a.bekkur;
        }
        item['Skráningar seinustu 90 daga'] = a.fj_skraninga;
        if (this.isVacation) {
          item['Dags. frá'] = moment(a.dags_fra).format('DD.MM.YYYY');
          item['Dags. til'] = moment(a.dags_til).format('DD.MM.YYYY');
        } else {
          item['Dags.'] = moment(a.dags_fra).format('DD.MM.YYYY');
        }
        item['Dags.skráð'] = moment(a.time_create).format('DD.MM.YYYY');
        if (this.isVacation) {
          item['Tegund leyfis'] = a.tegund_heiti;
        }
        item.Staða = a.stada_heiti;
        item.Kóði = a.forfoll_kodi;
        if (this.isVacation) {
          item['Fjöldi daga'] = a.fjoldi_daga;
        }
        item['Heill dagur'] = a.heill_dagur === 1 ? 'Já' : 'Nei';
        item['Fjöldi tíma'] = a.nemandi_forf_timar.length;
        item.Skýring = a.athugasemd_skraning;
        item['Ath. afgreiðslu'] = a.athugasemd_afgreidsla;
        item['Ath. fjarv'] = a.athugasemd_forfoll;
        item['Sótt um af'] = a.person_create_nafn;
        item['Afgreitt af'] = a.samt_person_nafn;
        item.Netfang = a.netfang;
        item.Einkanetfang = a.einkanetfang;
        list.push(Object.values(item));
      });
      return list;
    },
    getExcelFileName() {
      if (this.isAbsence) return `veikindaskraningar_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
      return `leyfisbeidnir_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    afangaskoli() {
      return this.schoolSettings && this.schoolSettings.afangaskoli ? this.schoolSettings.afangaskoli === 1 : false;
    },
    canViewStudent() {
      return this.loggedInUserHasWritePermission('nemendahald_nemendur');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {
      lang: is,
      perPage: 15,
      currentPage: 1,
      selectedItem: '',
      showRequestSlidebar: false,
      showStudentCommentSlidebar: false,
      showStudentRequestsSlidebar: false,
      defaultEdit: false,
      breadcrumbs: [
        { name: 'Forsíða', route: 'Dashboard' },
        { name: 'Beiðnir', route: '' },
        { name: 'Afgreiðsla leyfisbeiðna', route: 'ReviewAbsence' },
      ],
      loading: {
        list: false,
        all: false,
      },
      firsttime: false,
      search: {
        name: '',
        ssn: '',
        status: { id: 1, label: 'Samþykkt' },
        type: '',
        date_from: moment().add(-7, 'days').toDate(),
        date_to: moment().toDate(),
        division: '',
        wholeDay: '',
        department: '',
      },
      list: [],
      all: [],
      divisions: [],
      departments: [],
      statuses: [
        { id: 0, label: 'Óafgreidd' },
        { id: 1, label: 'Samþykkt' },
        { id: 2, label: 'Vantar vottorð' },
        { id: 3, label: 'Hafnað' },
        { id: 4, label: 'Annað' },
      ],
      types: [
        //  { id: 1, label: 'Veikindi' },
        { id: 2, label: 'Skammtímaleyfi' },
        { id: 3, label: 'Langtímaleyfi' },
      ],
      yesNoList: [
        { id: 1, label: 'Já - heill dagur' },
        { id: 0, label: 'Nei - hluti úr degi' },
      ],
    };
  },
  methods: {
    async searchAll() {
      try {
        this.firsttime = true;
        this.loading.all = true;
        this.currentPage = 1;
        const response = await requests.getReviewAbsenceList({
          status: this.search.status ? this.search.status.id : '',
          absence_type: this.search.type ? this.search.type.id : '',
          name: this.search.name,
          ssn: this.search.ssn,
          date_from: this.search.date_from ? moment(this.search.date_from).format('DD.MM.YYYY') : '',
          date_to: this.search.date_to ? moment(this.search.date_to).format('DD.MM.YYYY') : '',
          newestFirst: 1,
          absenceTypes: this.absenceTypes,
          divisionId: this.search.division ? this.search.division.division_id : '',
          wholeDay: this.search.wholeDay ? this.search.wholeDay.id : '',
          departmentId: this.search.department ? this.search.department.department_id : '',
        });
        this.all = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.all = false;
      }
    },
    async loadDivisions() {
      try {
        const response = await structure.getDivisionList();
        this.divisions = response.data.items.map(x => ({
          ...x,
          label: `${x.code} - ${x.name}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadDepartments() {
      try {
        const response = await structure.getDepartmentList();
        this.departments = response.data.items.map(x => ({
          ...x,
          label: `${x.name}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    openRequestSlidebar(item, defaultEdit) {
      this.selectedItem = item;
      this.showRequestSlidebar = true;
      this.showStudentRequestsSlidebar = false;
      this.showStudentCommentSlidebar = false;
      this.defaultEdit = defaultEdit;
    },
    openStudentCommentSlidebar(item) {
      this.selectedItem = item;
      this.showStudentCommentSlidebar = true;
      this.showStudentRequestsSlidebar = false;
      this.showRequestSlidebar = false;
    },
    openStudentRequestsSlidebar(item) {
      this.selectedItem = item;
      this.showStudentRequestsSlidebar = true;
      this.showStudentCommentSlidebar = false;
      this.showRequestSlidebar = false;
    },
    closeSlidebar() {
      this.showRequestSlidebar = false;
      this.selectedItem = null;
      this.defaultEdit = false;
      this.showStudentRequestsSlidebar = false;
      this.showStudentCommentSlidebar = false;
    },
    closeAndReloadSlidebar() {
      this.closeSlidebar();
      this.searchAll();
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    selectedStudent() {
      if (this.selectedStudent) {
        this.search.ssn = this.selectedStudent;
        this.searchAll();
      }
    },
  },
  created() {
    this.loadDivisions();
    this.loadDepartments();
    if (this.selectedStudent) {
      this.search.ssn = this.selectedStudent;
    }
    this.searchAll();
  },
};
</script>
<style lang="scss"></style>
