<template>
  <div>
    <Loader v-if="loading"></Loader>
    <div v-if="!loading" style="width: 100%; overflow-x: auto">
      <table class="table table-hover table-bordered group-attendance-table less-padding mt-2" style="font-size: 12px">
        <thead>
          <tr>
            <th colspan="2">Nemandi</th>
            <th :colspan="item.vidverukodar.length" class="text-center summaryThickerBorder">Viðvera</th>
            <th :colspan="item.forfallakodar.length" class="text-center">Forföll</th>
            <th>Fj.<i class="fa fa-info-circle" title="Fjöldi tíma" v-b-tooltip></i></th>
            <th>Stig <i class="fa fa-info-circle" title="Fjarvistarstig" v-b-tooltip></i></th>
            <th :colspan="attendanceCount" class="text-center">Mætingarhlutfall</th>
            <th></th>
          </tr>
          <tr>
            <th>Nafn</th>
            <th>Nemandanr</th>
            <th
              v-for="(att, aIdx) in item.vidverukodar"
              :key="att.teg_fjarvista"
              :class="{ summaryThickerBorder: aIdx === item.vidverukodar.length - 1 }"
              :title="att.heiti"
              v-b-tooltip
            >
              {{ att.kodi }}
            </th>
            <th v-for="abs in item.forfallakodar" :key="abs.teg_forfalla" :title="abs.heiti" v-b-tooltip>
              {{ abs.kodi }}
            </th>
            <th></th>
            <th></th>
            <th v-if="schoolSettings.maetingarhlutfall_stig === 1">
              Mæting <i class="fa fa-info-circle" :title="`<strong>Mæting</strong><br/> ${$t('maeting')}`" v-b-tooltip.hover.html></i>
            </th>
            <th v-if="schoolSettings.maetingarhlutfall_raun === 1">
              Raun <i class="fa fa-info-circle" :title="`<strong>Raunmæting</strong><br/> ${$t('raunMaeting')}`" v-b-tooltip.hover.html></i>
            </th>
            <th v-if="schoolSettings.maetingarhlutfall_onn === 1">
              Önn <i class="fa fa-info-circle" :title="`<strong>Önn</strong><br/> ${$t('maetingOnn')}`" v-b-tooltip.hover.html></i>
            </th>
            <th v-if="schoolSettings.maetingarhlutfall_einkunn === 1">
              Eink.
              <i
                class="fa fa-info-circle"
                :title="`<strong>Til mætingareinkunnar</strong><br/> ${$t('tilMaetingareinkunnar')}`"
                v-b-tooltip.hover.html
              ></i>
            </th>
            <th>
              Athugasemdir
              <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="cursor-pointer float-right">
                <i class="fa fa-file-excel-o excelText"></i>
              </download-csv>
            </th>
          </tr>
        </thead>
        <AttendanceSummaryItem v-for="(student, index) in item.nemendur" :key="student.ferill_id" :student="student" :index="index" />
      </table>

      <div style="font-size: 12px" v-if="item">
        <b>Fjarvistarkóðar:</b>
        <span v-for="att in item.vidverukodar" :key="att.teg_fjarvista" class="pr-2">{{ att.kodi }} = {{ att.heiti }}</span>
      </div>
      <div style="font-size: 12px" v-if="item">
        <b>Forfallakóðar:</b> 
        <span v-for="abs in item.forfallakodar" :key="abs.teg_forfalla" class="pr-2">{{ abs.kodi }} = {{ abs.heiti }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import attendance from '@/api/attendance';
import { mapActions, mapGetters } from 'vuex';
import Loader from '@/components/common/Loader.vue';
import AttendanceSummaryItem from '@/components/students/groups/attendance/AttendanceSummaryItem.vue';

export default {
  name: 'group-attendance-summary',
  props: ['groupId', 'dateFrom', 'dateTo', 'reload'],
  components: {
    Loader,
    AttendanceSummaryItem,
  },
  computed: {
    attendanceCount() {
      let count = 0;
      if (this.schoolSettings && this.schoolSettings.maetingarhlutfall_stig === 1) {
        count += 1;
      }
      if (this.schoolSettings && this.schoolSettings.maetingarhlutfall_raun === 1) {
        count += 1;
      }
      if (this.schoolSettings && this.schoolSettings.maetingarhlutfall_onn === 1) {
        count += 1;
      }
      if (this.schoolSettings && this.schoolSettings.maetingarhlutfall_einkunn === 1) {
        count += 1;
      }
      return count;
    },
    getExcelData() {
      const list = [];
      this.item.nemendur.forEach(x => {
        const i = {
          Nafn: x.nafn,
          Nemandanr: x.nemandi_id,
        };
        x.fjarv_stls.forEach(y => {
          i[`${y.heiti} ${y.kodi}`] = y.fj > 0 ? y.fj : '';
        });
        x.forf_stls.forEach(y => {
          i[`${y.heiti} ${y.kodi}`] = y.fj > 0 ? y.fj : '';
        });

        i['Fjöldi tíma'] = x.fjarvistarstig_ofl.length > 0 ? x.fjarvistarstig_ofl[0].fj_fjarv_forfoll_id : '';
        i.Fjarvistarstig =
          x.fjarvistarstig_ofl.length > 0 && x.fjarvistarstig_ofl[0].fjarvistar_stig
            ? x.fjarvistarstig_ofl[0].fjarvistar_stig.toString().replace('.', ',')
            : '';

        if (this.schoolSettings.maetingarhlutfall_stig === 1) {
          i.Mæting = x.maeting_onn_stls.length > 0 && x.maeting_onn_stls[0].maeting ? `${x.maeting_onn_stls[0].maeting}%` : '';
        }
        if (this.schoolSettings.maetingarhlutfall_raun === 1) {
          i.Raunmæting =
            x.maeting_onn_stls.length > 0 && x.maeting_onn_stls[0].maeting_raun ? `${x.maeting_onn_stls[0].maeting_raun}%` : '';
        }
        if (this.schoolSettings.maetingarhlutfall_onn === 1) {
          i.Önn = x.maeting_onn_stls.length > 0 && x.maeting_onn_stls[0].maeting_onn ? `${x.maeting_onn_stls[0].maeting_onn}%` : '';
        }
        if (this.schoolSettings.maetingarhlutfall_einkunn === 1) {
          i['Til mætingareinkunnar'] =
            x.maeting_onn_stls.length > 0 && x.maeting_onn_stls[0].maeting_einkunn ? `${x.maeting_onn_stls[0].maeting_einkunn}%` : '';
        }
        i.Athugasemdir = x.athugasemdir;

        list.push(i);
      });

      return list;
    },
    getExcelFileName() {
      return `hopur_fjarvistayfirlit_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    canRead() {
      return this.loggedInUserHasReadPermission('nemendahald_hopar');
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('nemendahald_hopar');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolHasSettings', 'schoolSettings']),
  },
  data() {
    return {
      loading: false,
      item: {},
    };
  },
  methods: {
    async loadList() {
      try {
        this.$emit('loadingStart');
        this.loading = true;
        const response = await attendance.getAttendanceSummary(this.groupId, {
          dateFrom: this.dateFrom ? moment(this.dateFrom).format('DD.MM.YYYY') : '',
          dateTo: this.dateTo ? moment(this.dateTo).format('DD.MM.YYYY') : '',
        });
        this.item = response.data.items.length > 0 ? response.data.items[0] : {};
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
        this.$emit('loadingEnd');
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    reload() {
      if (this.reload) {
        this.loadList();
      }
    },
    dateFrom() {
      this.loadList();
    },
    dateTo() {
      this.loadList();
    },
  },
  created() {
    this.loadList();
  },
};
</script>
<style lang="scss">
.group-attendance-table {
  thead {
    tr {
      th {
        border: 1px solid #dee2e6 !important;
        &.summaryThickerBorder {
          border-right-width: 3px !important;
        }
      }
    }
  }
}
.summaryThickerBorder {
  border-right-width: 3px !important;
}
</style>
