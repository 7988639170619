<template>
  <page>
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <PageTitle title="Breyta aðgangi" />
    <b-row>
      <b-col>
        <h5>{{ name }}</h5>
      </b-col>
      <b-col cols="3">
        <div>Setja sama aðganginn á alla</div>
        <v-select id="sameAccess" name="sameAccess" v-model="sameAccess" :options="accessTypes" label="name" @input="changeAccessAll">
        </v-select>
      </b-col>
    </b-row>
    <br />
    <table class="table less-padding" style="font-size: 13px">
      <tr>
        <td style="border-top: none" colspan="2">
          <i class="fa fa-search pl-1 mr-3" title="Les réttindi - Má skoða" v-b-tooltip />
          <i class="fa fa-pencil" title="Skrif réttindi - Má breyta" v-b-tooltip />
        </td>
      </tr>
      <tbody v-for="item in list" :key="item.id" style="border-color: transparent">
        <tr>
          <td width="5%" style="white-space: nowrap">
            <div class="d-inline-block">
              <b-form-checkbox
                type="checkbox"
                value="1"
                :checked="item.access"
                @change="uniqueCheck(item, $event)"
                title="Les réttindi"
                v-b-tooltip
              />
            </div>
            <div class="d-inline-block">
              <b-form-checkbox
                type="checkbox"
                value="2"
                :checked="item.access"
                @change="uniqueCheck(item, $event)"
                title="Skrif réttindi"
                v-b-tooltip
              />
            </div>
          </td>
          <td>{{ item.hlutverk_heiti }}</td>
          <!--td>
            <v-select
              :id="`access${index}`"
              :name="`access${index}`"
              v-model="item.access"
              :state="submitted && errors.has(`access${index}`) ? false : ''"
              v-validate="'required'"
              :clearable="false"
              :options="accessTypes"
              label="name"
            >
            </v-select>
          </td-->
        </tr>
      </tbody>
    </table>
    <b-btn variant="primary" class="mr-3" @click="validateBeforeSubmit">Vista</b-btn>
    <b-btn variant="secondary" :to="{ name: 'Access' }">Hætta við</b-btn>
  </page>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import staff from '@/api/staff';

import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import PageTitle from '@/components/common/PageTitle.vue';

export default {
  name: 'edit-access',
  components: {
    Breadcrumbs,
    PageTitle,
  },
  computed: {
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      breadcrumbs: [
        { name: 'Forsíða', route: 'Dashboard' },
        { name: 'Starfsmannahald', route: '' },
        { name: 'Aðgangsstýringar', route: 'Access' },
        { name: 'Breyta aðgangi', route: 'AccessEditAccess' },
      ],
      loading: false,
      submitted: false,
      list: [],
      name: '',
      sameAccess: '',
      accessTypes: [
        {
          id: 0,
          name: 'Enginn',
        },
        {
          id: 1,
          name: 'Les',
        },
        {
          id: 2,
          name: 'Skrif',
        },
      ],
    };
  },
  methods: {
    changeAccessAll() {
      if (this.sameAccess && this.sameAccess.id !== null) {
        let access = '';
        if (this.sameAccess.id === 1) access = '1';
        else if (this.sameAccess.id === 2) access = '2';
        this.list.forEach(l => {
          this.$set(l, 'access', access);
        });
      }
    },
    uniqueCheck(item, e) {
      this.$set(item, 'access', '');
      if (e === '1' || e === '2') {
        this.$set(item, 'access', e);
      }
    },
    async loadList() {
      try {
        this.loading = true;
        const response = await staff.getAccessListByAccess(this.id);
        this.list = response.data.items;
        this.list.forEach(i => {
          // this.$set(i, 'access', this.accessTypes[i.adgangur]);

          let access = '';
          if (i.adgangur === 1) access = '1';
          else if (i.adgangur === 2) access = '2';
          this.$set(i, 'access', access);
        });
        this.name = this.list.length > 0 ? this.list[0].styring_heiti : '';
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          const q = [];
          this.list.forEach(s => {
            let access = 0;
            if (s.access === '1') {
              access = 1;
            } else if (s.access === '2') {
              access = 2;
            }
            q.push(staff.updateJobAccess(s.adgangur_hlutverk_id, s.adgangur_styringar_id, access));
          });

          Promise.all(q)
            .then(() => {
              this.displaySuccess('Aðgangur vistaður');
              this.$router.go(-1);
              // this.$router.push({ name: 'Access' });
            })
            .catch(() => {
              this.loadList();
              this.displayError('Mistókst að vista aðgang');
            });
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.id = this.$route.params.id;
    this.loadList();
  },
};
</script>
