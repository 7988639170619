<template>
  <Slidebar :large="true" @closeMe="closeSlidebar">
    <h4>Breyta upphafsdagsetningu</h4>
    <b-row class="mb-2">
      <b-col class="pl-0">
        <label for="date" class="mb-2">Velja nýja upphafsdagssetningu fyrir alla.</label>
        <datepicker
          id="date"
          name="date"
          v-model="date"
          :language="lang"
          :monday-first="true"
          format="dd.MM.yyyy"
          :typeable="false"
          :clear-button="false"
          ref="datePickr"
        ></datepicker>
      </b-col>
    </b-row>
    <table class="table less-padding" style="font-size: 14px">
      <thead>
        <tr>
          <th>Nafn ({{ students.length }})</th>
          <th>Dags. byrjar</th>
        </tr>
      </thead>
      <tr v-for="s in students" :key="s.nemandi_id">
        <td>
          {{ s.nafn }}
        </td>
        <td>{{ s.dags_byrjar | moment('DD.MM.YYYY') }}</td>
      </tr>
    </table>
    <b-btn variant="primary" :disabled="!date" @click="updateStartDate()">Breyta upphafsdagsetningum</b-btn>
  </Slidebar>
</template>

<script>
import moment from 'moment';
import groups from '@/api/groups';
import { mapGetters, mapActions } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';

export default {
  name: 'change-date-slidebar',
  props: ['item', 'students'],
  components: {
    Slidebar,
    Datepicker,
  },
  computed: {
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      lang: is,
      date: '',
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async updateStartDate() {
      try {
        const data = {
          students: this.students.map(x => x.ferill_id).join(','),
          dags_byrjar: moment(this.date).format('DD.MM.YYYY'),
        };
        await groups.updateStartDate(this.item.hopur_id, data);

        this.displaySuccess('Dags. byrjar breytt.');
        this.$emit('closeAndReloadSlidebar');
      } catch (e) {
        // Aðgerð mistókst.
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    if (this.item && this.item.dags_fra) {
      this.date = moment(this.item.dags_fra).toDate();
    }
  },
};
</script>

<style lang="scss"></style>
