import Vue from 'vue';

const API_URL = '/api/users';

export default {
  getUserGroupsList(params) {
    return Vue.http
      .get(`${API_URL}/user-groups/`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getUserInfo(id) {
    return Vue.http
      .get(`${API_URL}/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateUserSsn(id, data) {
    return Vue.http
      .post(`${API_URL}/${id}/update-ssn`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateUserPassword(id, data) {
    return Vue.http
      .post(`${API_URL}/${id}/password`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  openUserPasswordAccess(id, data) {
    return Vue.http
      .post(`${API_URL}/${id}/open-password-access`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getUserGroups(params) {
    return Vue.http
      .get(`${API_URL}/usergroups`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateUserGroup(data) {
    return Vue.http
      .post(`${API_URL}/usergroups`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
};
