<template>
  <Slidebar :autoClose="true" @closeMe="closeSlidebar" :large="large" :extraLarge="extraLarge">
    <h4>Undanfari</h4>
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <table class="table less-padding no-border">
        <tr>
          <td>
            <b-form-group
              label-for="rule"
              label="Regla"
              :state="submitted && errors.has('rule') ? false : ''"
              :invalid-feedback="errors.first('rule')"
            >
              <v-select
                id="rule"
                name="rule"
                v-model="form.rule"
                :clearable="false"
                :state="submitted && errors.has('rule') ? false : ''"
                :options="rules"
                data-vv-as="regla"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>
            <b-form-group :state="submitted && errors.has('module') ? false : ''" :invalid-feedback="errors.first('module')">
              <label label-for="module"> Áfangi <i class="fa fa-spin fa-spinner" v-if="loading"></i> </label>
              <v-select
                id="module"
                name="module"
                v-model="form.module"
                :clearable="false"
                :state="submitted && errors.has('module') ? false : ''"
                :options="list"
                :disabled="item && item.afangi_id !== undefined"
                data-vv-as="áfangi"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>
            <b-form-group :state="submitted && errors.has('sameTerm') ? false : ''" :invalid-feedback="errors.first('sameTerm')">
              <label label-for="sameTerm">
                Á sömu önn
                <i class="fa fa-info-circle" title="Má nemandi vera í undanfara áfanga á sömu önn og hann tekur áfangann" v-b-tooltip></i>
              </label>
              <v-select
                id="sameTerm"
                name="sameTerm"
                v-model="form.sameTerm"
                :clearable="false"
                :state="submitted && errors.has('sameTerm') ? false : ''"
                :options="sameTerm"
                data-vv-as="á sömu önn"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
      </table>
      <b-btn variant="primary" @click="validateBeforeSubmit()">Skrá undanfara</b-btn>
    </b-form>
  </Slidebar>
</template>

<script>
import curriculum from '@/api/curriculum';
import Slidebar from '@/components/common/Slidebar.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'module-precursor-slidebar',
  components: {
    Slidebar,
  },
  props: ['id', 'item', 'divisionId', 'large', 'extraLarge'],
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_afangar');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('namskra_afangar');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      form: {
        rule: 1,
        module: '',
        sameTerm: { id: 0, label: 'Nei' },
      },
      loading: false,
      list: [],
      rules: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      sameTerm: [
        { id: 0, label: 'Nei' },
        { id: 1, label: 'Já' },
      ],
      submitted: false,
    };
  },
  methods: {
    async loadList() {
      try {
        this.loading = true;
        const response = await curriculum.getModuleList({ active: 1, division: this.divisionId });
        this.list = response.data.items.map(x => ({
          id: x.afangi_id,
          label: `${x.namsgrein_afanganr}`,
          name: x.afanga_heiti,
        }));
        if (this.item) {
          this.form.module = this.list.find(x => x.id === this.item.afangi_id);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        try {
          const data = {
            afangi_id: this.form.module.id,
            sama_onn: this.form.sameTerm.id,
            kedjunr: `${this.id}.${this.form.rule}`,
          };
          await curriculum.setModulePrecursor(this.id, data);
          this.displaySuccess(this.item ? 'Undanfara áfanga breytt.' : 'Undanfara áfanga bætt við.');
          this.$emit('successful', {});
          this.closeSlidebar();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.saving = false;
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.saving = false;
      }
    },
    closeSlidebar(item) {
      this.$emit('closeSlidebar', item);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    if (this.item && this.item.afanga_id) {
      this.form.rule = this.item.kedja_stutt;
      this.form.sameTerm = this.sameTerm.find(x => x.id === this.item.sama_onn);
    }
    this.loadList();
  },
};
</script>

<style lang="scss"></style>
