<template>
  <Slidebar @closeMe="closeSlidebar" :large="true">
    <h4>Breyta kennslustund</h4>
    <Loader v-if="loading.info" />
    <b-form @submit.prevent="validateBeforeSubmit" novalidate v-if="!loading.info">
      <table class="table less-padding no-border">
        <tr>
          <th>Áfangi</th>
          <td colspan="3">{{ form.module_name }}({{ form.group_name }})</td>
        </tr>
        <tr>
          <th>Kennari/ar*</th>
          <td colspan="3">
            <b-form-group
              label-for="teachers"
              :state="submitted && errors.has('teachers') ? false : ''"
              :invalid-feedback="errors.first('teachers')"
            >
              <v-select
                id="teachers"
                name="teachers"
                v-model="form.teachers"
                multiple
                v-validate="'required'"
                :state="submitted && errors.has('teachers') ? false : ''"
                :options="teachers"
                data-vv-as="kennari"
                @input="
                  validated = false;
                  updateFocus();
                "
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <th>Stofa</th>
          <td colspan="3">
            <b-form-group
              label-for="classrooms"
              :state="submitted && errors.has('classrooms') ? false : ''"
              :invalid-feedback="errors.first('classrooms')"
            >
              <v-select
                id="classrooms"
                name="classrooms"
                v-model="form.classrooms"
                multiple
                :state="submitted && errors.has('classrooms') ? false : ''"
                :options="classrooms"
                @input="
                  validated = false;
                  updateFocus();
                "
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
        <TableRowLoader v-if="validating" colspan="5" :center="true" />
        <tr v-if="!validating">
          <th>Dags.</th>
          <td>
            <b-form-group label-for="date" :state="submitted && errors.has('date') ? false : ''" :invalid-feedback="errors.first('date')">
              <datepicker
                id="date"
                name="date"
                v-model="form.date"
                :language="lang"
                :monday-first="true"
                format="dd.MM.yyyy"
                :typeable="false"
                v-validate="'required'"
                data-vv-as="dags"
                @change="validated = false"
                :state="submitted && errors.has('date') ? false : ''"
                ref="dateOpenPickr"
              ></datepicker>
            </b-form-group>
          </td>
          <td>
            <b-form-group
              label-for="timeFrom"
              :state="submitted && errors.has('timeFrom') ? false : ''"
              :invalid-feedback="errors.first('timeFrom')"
            >
              <masked-input
                id="timeFrom"
                name="timeFrom"
                v-model="form.time_from"
                mask="11:11"
                placeholder="hh:mm"
                style="padding: 2px 2px 2px 2px; width: 60px; height: 30px; border: 1px solid #ced4da"
                v-validate="{ required: true, regex: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/ }"
                data-vv-as="tími frá"
                :state="submitted && errors.has('timeFrom') ? false : ''"
                @input="updateTimeTo()"
              />
            </b-form-group>
          </td>
          <td>
            <b-form-group
              label-for="timeTo"
              :state="submitted && errors.has('timeTo') ? false : ''"
              :invalid-feedback="errors.first('timeTo')"
            >
              <masked-input
                id="timeTo"
                name="timeTo"
                ref="timeTo"
                v-model="form.time_to"
                mask="11:11"
                placeholder="hh:mm"
                style="padding: 2px 2px 2px 2px; width: 60px; height: 30px; border: 1px solid #ced4da"
                v-validate="{ required: true, regex: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/ }"
                data-vv-as="tími til"
                :state="submitted && errors.has('timeTo') ? false : ''"
              />
            </b-form-group>
          </td>
        </tr>
        <tr v-if="validated && !form.validation.can_book">
          <td colspan="4">
            <div class="alert alert-danger" style="font-size: 13px; padding: 10px">
              <div v-if="!form.validation.hopur.availability.available">Hópaárekstur: Hópurinn er nú þegar bókaður á þessum tíma.</div>
              <div v-if="!form.validation.hopur.onn.date_in_range">
                Dagsetning er ekki innan gildistíma hóps. Vinsamlegast veldu á milli
                <strong> {{ form.validation.hopur.onn.from }} </strong> og <strong>{{ form.validation.hopur.onn.to }}</strong>
              </div>
              <div v-if="form.validation.stofa && form.validation.stofa.filter(x => !x.classroom_size_ok) > 0">
                <div v-for="(stofa, idx) in form.validation.stofa.filter(x => !x.classroom_size_ok)" :key="idx">
                  <div>Eftirfarandi stofa er of lítil: {{ stofa.short_name }}</div>
                  <div>
                    Fjöldi í hóp er: <strong> {{ form.validation.hopur.number_of_students }}</strong
                    >.
                  </div>
                  <div>
                    Hámarksfjöldi í stofu er: <strong> {{ stofa.max_students }}</strong
                    >.
                  </div>
                </div>
              </div>
              <div v-if="!form.validation.bookings.available">
                Stofupantanir:
                <span v-for="(clash, idx) in form.validation.bookings.clashes" :key="idx">
                  <span v-if="idx != 0">, </span><strong>{{ clash.starfsm_nafn }} - {{ clash.skyring }}</strong> {{ clash.timi_fra }}-{{
                    clash.timi_til
                  }}</span
                >
              </div>
              <div v-if="!form.validation.availability.available">
                Stofuárekstur:
                <span v-for="(clash, idx) in form.validation.availability.clashes" :key="idx">
                  <span v-if="idx != 0">, </span><strong>{{ clash.afanga_nr }}({{ clash.hopur }})</strong> {{ clash.timi_fra }}-{{
                    clash.timi_til
                  }}
                </span>
              </div>
              <div v-for="(t, idx) in form.validation.teachers.filter(x => !x.available)" :key="idx">
                Kennaraárekstur: <strong>{{ t.nafn }} {{ t.skammst }}</strong
                >.
                <span v-for="(clash, idx2) in t.clashes" :key="idx2"
                  ><span v-if="idx2 != 0">, </span> {{ clash.heiti }} {{ clash.timi_fra }}-{{ clash.timi_til }}</span
                >
              </div>
              <div v-for="(t, idx) in form.validation.teachers.filter(x => x.locked)" :key="idx">
                Kennaralæsing: <strong>{{ t.nafn }} {{ t.skammst }}</strong
                >.
                <span v-for="(locks, idx2) in t.locks" :key="idx2"
                  ><span v-if="idx2 != 0">, </span> <strong>{{ locks.lysing }}</strong> {{ locks.timi_fra }}-{{ locks.timi_til }}</span
                >
              </div>
              <div v-if="form.validation.locks.classroom_locked">
                Stofulæsing:
                <span v-for="(clash, idx) in form.validation.locks.classroom" :key="idx"
                  ><span v-if="idx != 0">, </span> <strong>{{ clash.lysing }}</strong> {{ clash.timi_fra }}-{{ clash.timi_til }}</span
                >
              </div>
              <div v-if="form.validation.locks.semester_locked">
                Stofulæsing:
                <span v-for="(clash, idx) in form.validation.locks.semester" :key="idx"
                  ><span v-if="idx != 0">, </span> <strong>{{ clash.lysing }}</strong> {{ clash.timi_fra }}-{{ clash.timi_til }}</span
                >
              </div>
              <div v-if="form.validation.holidays.length > 0">
                Frídagar:
                <span v-for="(clash, idx) in form.validation.holidays" :key="idx"
                  ><span v-if="idx != 0">, </span> <strong>{{ clash.heiti }}</strong> {{ clash.timi_fra
                  }}<span v-if="clash.timi_til">-{{ clash.timi_til }}</span></span
                >
              </div>
              <div v-if="form.validation.student_clashes.length > 0">
                Nemendaárekstur:
                <span v-for="(clash, idx) in form.validation.student_clashes" :key="idx">
                  <span v-if="idx != 0">, </span><strong>{{ clash.nafn }}</strong> {{ clash.heiti }}
                </span>
              </div>
            </div>
          </td>
        </tr>
      </table>
      <div class="alert alert-info" v-if="form.absence_registered">Ath! Fjarvistarskráning fannst fyrir valda kennslustund.</div>
    </b-form>
    <b-btn variant="primary" @click="validateBeforeSubmit()" ref="submitButton">
      {{ this.validated ? 'Vista' : 'Athuga árekstur' }}
    </b-btn>
    <b-btn variant="danger" class="float-right" @click="deleteClass()">Eyða</b-btn>
  </Slidebar>
</template>

<script>
import staff from '@/api/staff';
import terms from '@/api/terms';
import school from '@/api/school';
import structure from '@/api/structure';
import { mapActions } from 'vuex';
import moment from 'moment';

import Slidebar from '@/components/common/Slidebar.vue';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';
import TableRowLoader from '@/components/common/TableRowLoader.vue';
import Loader from '@/components/common/Loader.vue';
import MaskedInput from 'vue-masked-input';

export default {
  name: 'edit-timetable-slidebar',
  props: ['id', 'term'],
  components: {
    Slidebar,
    Datepicker,
    TableRowLoader,
    Loader,
    MaskedInput,
  },
  data() {
    return {
      regexTime: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
      lang: is,
      classLength: 60,
      submitted: false,
      validating: false,
      validated: false,
      classrooms: [],
      teachers: [],
      form: {
        absence_registered: false,
        date: '',
        time_from: '',
        time_to: '',
        module_name: '',
        group_name: '',
        default_teachers: '',
        default_classroom: '',
        teachers: [],
        classrooms: [],
      },
      loading: {
        info: false,
        teachers: false,
        classrooms: false,
      },
    };
  },
  methods: {
    updateFocus() {
      if (this.$refs.submitButton) {
        setTimeout(() => {
          this.$refs.submitButton.focus();
        }, 10);
      }
    },
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async loadData() {
      try {
        this.loading.info = true;
        this.classLength = this.term.lengd_kennslustundar ? this.term.lengd_kennslustundar : 60;
        const response = await terms.getTermTimetableList(this.term.onn_id, { tableId: this.id });
        const absenceResponse = await school.checkTimetableAbsenceExists(this.id);
        if (response.data.items.length > 0) {
          const item = response.data.items[0];
          this.form.date = moment(item.dags, 'DD.MM.YYYY').toDate();
          this.form.time_from = item.timi_fra;
          this.form.time_to = item.timi_til;
          this.form.module_name = item.namsgrein_afanganr;
          this.form.group_name = item.hopur;
          this.form.group_id = item.hopur_id;
          this.form.default_teachers = item.kennari.map(x => x.starfsm_id);
          this.form.default_classroom = item.stofur_id ? item.stofur_id.split(',').map(x => Number(x)) : '';
          this.form.absence_registered = absenceResponse.data.absence_registered === 1;
          this.loadTeachers();
          this.loadClassrooms();
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.info = false;
      }
    },
    async loadTeachers() {
      this.loading.teachers = true;
      try {
        const response = await staff.getStaffList();
        this.teachers = response.data.items.map(x => ({
          ...x,
          label: `${x.nafn} - ${x.skammst}`,
        }));
        this.form.teachers = JSON.parse(JSON.stringify(this.teachers.filter(x => this.form.default_teachers.indexOf(x.starfsm_id) > -1)));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.teachers = false;
      }
    },
    async loadClassrooms() {
      this.loading.classrooms = true;
      try {
        const response = await structure.getClassroomList({ active: 1 });
        this.classrooms = response.data.items.map(x => ({
          ...x,
          label: `${x.stofa_eink} - ${x.stofa_heiti}`,
        }));
        this.form.classrooms = JSON.parse(
          JSON.stringify(this.classrooms.filter(x => this.form.default_classroom.indexOf(x.stofa_id) > -1)),
        );
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.classrooms = false;
      }
    },
    updateTimeTo() {
      this.validated = false;

      if (this.regexTime.test(this.form.time_from)) {
        const timeTo = moment()
          .set('hour', this.form.time_from.split(':')[0])
          .set('minute', this.form.time_from.split(':')[1])
          .set('second', 0);
        this.$set(this.form, 'time_to', moment(timeTo).add('minute', this.classLength).format('HH:mm'));
        this.$refs.timeTo.$refs.input.focus();
      }
    },
    async deleteClass() {
      let conf = true;
      if (this.form.absence_registered) {
        /* eslint-disable no-alert, no-restricted-globals, max-len */
        conf = confirm(
          'Ath! Það er til fjarvistaskráning fyrir valda kennslustund. Við það að eyða kennslustund verður þeim einnig eytt. Ertu viss um að þú viljir eyða?',
        );
        /* eslint-enable no-alert, no-restricted-globals, max-len */
      }
      if (conf) {
        try {
          await school.deleteTimetableRecord(this.id);
          this.displaySuccess('Færslu hefur verið eytt');
          this.$emit('closeSlidebarAndReload');
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    async validate() {
      try {
        this.validating = true;
        const item = {
          stofur: this.form.classrooms.map(x => x.stofa_id).join(','),
          teachers: this.form.teachers.map(x => x.starfsm_id).join(','),
          hopur: this.form.group_id,
          dags: moment(this.form.date).format('DD.MM.YYYY'),
          fra: this.form.time_from,
          til: this.form.time_to,
          id: this.id,
        };
        const response = await school.validateTimetable(item); // eslint-disable-line

        this.$set(this.form, 'validation', response.data);
        this.$set(this.form, 'copy', response.data.can_book);
        this.validated = true;
        if (response.data.can_book) {
          this.validateBeforeSubmit();
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.validating = false;
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        if (!this.validated) {
          this.validate();
        } else {
          try {
            this.saving = true;
            // Vista tímann
            const item = {
              dags: moment(this.form.date).format('DD.MM.YYYY'),
              fra: this.form.time_from,
              til: this.form.time_to,
              stofur: this.form.classrooms.map(x => x.stofa_id).join(','),
              removeOthers: 1,
            };
            await school.editTimetableRecord(this.id, item);
            // Vista kennara
            if (this.form.teachers.length > 0) {
              const data = {
                teachers: this.form.teachers.map(x => x.starfsm_id).join(','),
                removeOthers: 1,
              };
              await school.editTimetableRecordTeachers(this.id, data);
            }
            this.displaySuccess('Færslur hefur verið breytt');
            this.$emit('closeSlidebarAndReload');
          } catch (e) {
            // Aðgerð mistókst.
            this.failed = true;
            this.$log.debug(e);
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          } finally {
            this.saving = false;
          }
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.saving = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    id() {
      this.loadData();
    },
  },
  /* mounted() {
    window.addEventListener('keyup', ev => {
      this.test(ev); // declared in your component methods
    });
  }, */
  created() {
    this.loadData();
  },
};
</script>

<style lang="scss"></style>
