<template>
  <div>
    <b-row>
      <b-col class="pl-0 mb-3">
        <div>Áfanginn er tengdur við eftirfarandi brautir.</div>
      </b-col>
    </b-row>
    <table class="table" style="font-size: 14px">
      <thead>
        <tr>
          <th>
            <LinkOrder title="Braut" :filteredList="list" :list="list" column="heiti" />
          </th>
          <th>
            <LinkOrder title="Auðkenni" :filteredList="list" :list="list" column="braut_kodi" second_column="heiti" />
          </th>
          <th>
            <LinkOrder title="Undirskóli" :filteredList="list" :list="list" column="undirsk_audk" second_column="heiti" />
          </th>
          <th>
            <LinkOrder title="Uppbygging" :filteredList="list" :list="list" column="uppbygging_heiti" second_column="heiti" />
          </th>
          <th>
            <LinkOrder title="Tegund" :filteredList="list" :list="list" column="tegund_heiti" second_column="heiti" />
          </th>
          <th>
            <LinkOrder title="Tímabil" :filteredList="list" :list="list" column="onn_nr" second_column="heiti" />
          </th>
          <th>
            <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="cursor-pointer float-right">
              <i class="fa fa-file-excel-o excelText"></i>
            </download-csv>
          </th>
        </tr>
      </thead>
      <tbody>
        <TableRowLoader v-if="loading" colspan="100%" :center="true" />
        <TableRowEmpty v-if="!loading && this.list.length === 0" colspan="100%" :center="true" />
        <tr v-for="item in list" :key="item.braut_id">
          <td>
            {{ item.heiti }}
            <i
              class="fa fa-ban mr-2"
              style="color: #f26175"
              v-if="item.gildi === 0"
              v-b-tooltip
              title="Ath. eftirfarandi braut er óvirk"
            ></i>
            <b-link
              v-if="canReadCourses"
              :to="{ name: 'Course', params: { id: item.braut_id } }"
              target="_blank"
              v-b-tooltip
              title="Skoða braut"
              ><i class="fa fa-search"></i
            ></b-link>
          </td>
          <td>
            {{ item.braut_kodi }}
          </td>
          <td>{{ item.undirsk_audk }}</td>
          <td>{{ item.uppbygging_heiti }}</td>
          <td>{{ item.tegund_heiti }}</td>
          <td>{{ item.onn_nr }}</td>
          <td>
            <b-btn variant="primary" v-if="canEdit" class="btn-xs float-right" style="font-size: 12px" @click="openSlidebar(item)"
              ><i class="fa fa-pencil"></i
            ></b-btn>
          </td>
        </tr>
      </tbody>
    </table>
    <course-module-slidebar
      v-if="showSlidebar"
      :id="selectedItem ? selectedItem.braut_afangar_id : ''"
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
    ></course-module-slidebar>
  </div>
</template>

<script>
import moment from 'moment';
import curriculum from '@/api/curriculum';
import { mapGetters, mapActions } from 'vuex';

import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';

import CourseModuleSlidebar from '@/components/curriculum/courses/CourseModuleSlidebar.vue';

export default {
  name: 'module-course',
  components: {
    TableRowLoader,
    TableRowEmpty,
    LinkOrder,
    CourseModuleSlidebar,
  },
  props: ['id', 'module'],
  computed: {
    getExcelData() {
      const newList = [
        [],
        ['Brautir tengdar áfanga'],
        [this.module.afanga_heiti],
        [this.module.namsgrein_afanganr],
        [],
        ['Braut', 'Auðkenni', 'Undirskóli', 'Tímabil'],
        ...this.list.map(x => [x.heiti, x.braut_kodi, x.undirsk_audk, x.onn_nr]),
      ];
      return newList;
    },
    getExcelFileName() {
      return `afangi_brautir_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    canReadCourses() {
      return this.loggedInUserHasReadPermission('namskra_brautir');
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_afangar');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('namskra_afangar');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      list: [],
      loading: false,
      showSlidebar: false,
      selectedItem: '',
    };
  },
  methods: {
    async loadList() {
      try {
        this.loading = true;
        const response = await curriculum.getModuleCourses(this.id);
        this.list = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    openSlidebar(item) {
      this.selectedItem = item;
      this.showSlidebar = true;
    },
    closeSlidebar() {
      this.showSlidebar = false;
      this.selectedItem = '';
    },
    closeAndReloadSlidebar() {
      this.loadList();
      this.closeSlidebar();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadList();
  },
};
</script>
