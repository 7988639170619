<template>
  <div>
    <b-modal
      id="staffCreateModal"
      :title="title"
      size="xl"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Vista"
      cancel-title="Hætta við"
      @ok.prevent="validateBeforeSubmit"
      :ok-disabled="submitting"
      ref="createModal"
    >
      <div>
        <b-form @submit.prevent="validateBeforeSubmit" novalidate v-if="staff">
          <h5 class="mb-3">Grunnupplýsingar</h5>
          <table class="table less-padding no-border" style="font-size: 13px">
            <tr>
              <th>Nafn</th>
              <td>{{ staff.nafn }}</td>
              <th></th>
              <td></td>
            </tr>
            <tr>
              <th>Kennitala</th>
              <td>{{ staff.kennitala }}</td>
              <th :class="{ 'text-danger': submitted && errors.has('radningarhlutfall') }">Ráðningarhlutfall</th>
              <td>
                <b-form-input
                  id="radningarhlutfall"
                  name="radningarhlutfall"
                  v-model="staff.radningarhlutfall"
                  :state="submitted && errors.has('radningarhlutfall') ? false : ''"
                  class="d-inline-block worklog-small-input"
                  v-validate="{ regex: /^[0-9\.\,\/]+$/ }"
                >
                </b-form-input>
                <div class="d-inline-block">%</div>
              </td>
            </tr>
            <tr>
              <th>Heimilisfang</th>
              <td>{{ staff.heimilisfang }} {{ staff.postnumer }} {{ staff.heiti_poststodvar }}</td>
              <th :class="{ 'text-danger': submitted && errors.has('kennsluferill') }">Kennsluferill</th>
              <td>
                <b-form-input
                  id="kennsluferill"
                  name="kennsluferill"
                  v-model="staff.kennsluferill"
                  :state="submitted && errors.has('kennsluferill') ? false : ''"
                  class="worklog-small-input"
                  v-validate="{ regex: /^[0-9\.\,\/]+$/ }"
                >
                </b-form-input>
              </td>
            </tr>
            <tr>
              <th>Farsími</th>
              <td>{{ staff.farsimi }}</td>
              <th>Starfsheiti</th>
              <td>{{ staff.tegund_starfsmanns }}</td>
            </tr>
            <tr>
              <th>Netfang</th>
              <td>{{ staff.netfang }}</td>
              <th :class="{ 'text-danger': submitted && errors.has('upphafsmanudur') }">Upphafsmánuður launa</th>
              <td>
                <div style="width: 150px">
                  <v-select
                    id="upphafsmanudur"
                    name="upphafsmanudur"
                    v-model="staff.upphafsmanudur"
                    :state="submitted && errors.has('upphafsmanudur') ? false : ''"
                    v-validate="'required'"
                    :clearable="false"
                    :options="monthList"
                    label="name"
                  >
                  </v-select>
                </div>
              </td>
            </tr>
            <tr>
              <th>Gildistími kennslutímabils</th>
              <td>{{ staff.vinnuskyrsla_dags_fra | moment('DD.MM.YYYY') }} - {{ staff.vinnuskyrsla_dags_til | moment('DD.MM.YYYY') }}</td>
              <th>Gildistími</th>
              <td>
                <div class="d-inline-block worklogStaffDate">
                  <datepicker
                    id="date_from"
                    name="date_from"
                    :class="{ 'is-invalid': submitted && errors.has('date_from') }"
                    v-model="staff.dags_fra"
                    :language="lang"
                    :monday-first="true"
                    format="dd.MM.yyyy"
                    :typeable="false"
                    ref="dateFromOpenPickr"
                    v-validate="'required'"
                  ></datepicker>
                </div>
                <div class="d-inline-block">-</div>
                <div class="d-inline-block worklogStaffDate">
                  <datepicker
                    id="date_to"
                    name="date_to"
                    :class="{ 'is-invalid': submitted && errors.has('date_to') }"
                    v-model="staff.dags_til"
                    :language="lang"
                    :monday-first="true"
                    format="dd.MM.yyyy"
                    :typeable="false"
                    ref="dateToOpenPickr"
                    v-validate="'required'"
                  ></datepicker>
                </div>
              </td>
            </tr>
          </table>
          <h5 class="mb-3 mt-3">Launaliðir</h5>
          <table class="table less-padding no-border" style="font-size: 13px">
            <tr>
              <th>Kennslustundir á viku</th>
              <td>{{ staff.kennslustundir_viku }}</td>
              <th :class="{ 'text-danger': submitted && errors.has('nybuakennsla') }">Nýbúakennsla</th>
              <td>
                <b-form-input
                  id="nybuakennsla"
                  name="nybuakennsla"
                  v-model="staff.nybuakennsla"
                  :state="submitted && errors.has('nybuakennsla') ? false : ''"
                  class="worklog-small-input"
                  @change="updateSum"
                  v-validate="{ regex: /^[0-9\.\,\/]+$/ }"
                >
                </b-form-input>
              </td>
            </tr>
            <tr>
              <th :class="{ 'text-danger': submitted && errors.has('kennsluskylda') }">Kennsluskylda</th>
              <td>
                <b-form-input
                  id="kennsluskylda"
                  name="kennsluskylda"
                  v-model="staff.kennsluskylda"
                  :state="submitted && errors.has('kennsluskylda') ? false : ''"
                  class="worklog-small-input"
                  v-validate="{ regex: /^[0-9\.\,\/]+$/ }"
                >
                </b-form-input>
              </td>
              <th :class="{ 'text-danger': submitted && errors.has('serkennsla') }">Sérkennsla</th>
              <td>
                <b-form-input
                  id="serkennsla"
                  name="serkennsla"
                  v-model="staff.serkennsla"
                  :state="submitted && errors.has('serkennsla') ? false : ''"
                  class="worklog-small-input"
                  @change="updateSum"
                  v-validate="{ regex: /^[0-9\.\,\/]+$/ }"
                >
                </b-form-input>
              </td>
            </tr>
            <tr></tr>
            <tr>
              <th :class="{ 'text-danger': submitted && errors.has('almenn_kennsla') }">Almenn kennsla</th>
              <td>
                <b-form-input
                  id="almenn_kennsla"
                  name="almenn_kennsla"
                  v-model="staff.almenn_kennsla"
                  :state="submitted && errors.has('almenn_kennsla') ? false : ''"
                  class="worklog-small-input"
                  @change="updateSum"
                  v-validate="{ regex: /^[0-9\.\,\/]+$/ }"
                >
                </b-form-input>
              </td>
              <th :class="{ 'text-danger': submitted && errors.has('gaesla') }">Gæsla</th>
              <td>
                <b-form-input
                  id="gaesla"
                  name="gaesla"
                  v-model="staff.gaesla"
                  :state="submitted && errors.has('gaesla') ? false : ''"
                  class="worklog-small-input"
                  @change="updateSum"
                  v-validate="{ regex: /^[0-9\.\,\/]+$/ }"
                >
                </b-form-input>
              </td>
            </tr>
          </table>
          <h5 class="mb-3 mt-3">Launaupplýsingar</h5>
          <table class="table less-padding no-border" style="font-size: 13px">
            <tr>
              <th>Afsal kennsluafsláttar</th>
              <td><yes-no :item="staff.kennsluafslattur_afsal" /></td>
              <th :class="{ 'text-danger': submitted && errors.has('orlofsprosenta') }">Orlofsprósenta</th>
              <td>
                <b-form-input
                  id="orlofsprosenta"
                  name="orlofsprosenta"
                  v-model="staff.orlofsprosenta"
                  :state="submitted && errors.has('orlofsprosenta') ? false : ''"
                  class="worklog-small-input"
                  v-validate="{ regex: /^[0-9\.\,\/]+$/ }"
                >
                </b-form-input>
              </td>
            </tr>
            <tr>
              <th>Launatafla</th>
              <td>
                <div style="width: 75px">
                  <v-select
                    id="launatafla"
                    name="launatafla"
                    v-model="staff.launatafla"
                    :state="submitted && errors.has('launatafla') ? false : ''"
                    v-validate="'required'"
                    :clearable="false"
                    :options="['1', '2']"
                  >
                  </v-select>
                </div>
              </td>
              <th :class="{ 'text-danger': submitted && errors.has('kennsluafslattur') }">Kennsluafsláttur alls</th>
              <td>
                <b-form-input
                  id="kennsluafslattur"
                  name="kennsluafslattur"
                  v-model="staff.kennsluafslattur"
                  :state="submitted && errors.has('kennsluafslattur') ? false : ''"
                  class="worklog-small-input"
                  v-validate="{ regex: /^[0-9\.\,\/]+$/ }"
                >
                </b-form-input>
              </td>
            </tr>
            <tr>
              <th>Launaflokkur</th>
              <td>{{ staff.launaflokkur }}</td>
              <td colspan="2" v-if="staff.launatafla === '2'" />
              <th v-if="staff.launatafla === '1'">Persónuálag</th>
              <td v-if="staff.launatafla === '1'">{{ default0(staff.personualag) }} %</td>
            </tr>
            <tr>
              <th :class="{ 'text-danger': submitted && errors.has('grunnrodun') }">Grunnröðun</th>
              <td>
                <b-form-input
                  id="grunnrodun"
                  name="grunnrodun"
                  v-model="staff.grunnrodun"
                  :state="submitted && errors.has('grunnrodun') ? false : ''"
                  class="worklog-small-input"
                  @change="updateSum"
                  v-validate="{ regex: /^[0-9\.\,\/]+$/ }"
                >
                </b-form-input>
              </td>
              <th></th>
              <td></td>
            </tr>
            <tr>
              <td><i>Viðbótarlaunaflokkar</i></td>
              <td></td>
              <th></th>
              <td></td>
            </tr>
            <tr>
              <th v-if="staff.launatafla === '2'" :class="{ 'text-danger': submitted && errors.has('prof') }">Próf/Leyfisbréf</th>
              <td v-if="staff.launatafla === '2'">
                <b-form-input
                  id="prof"
                  name="prof"
                  v-model="staff.prof"
                  :state="submitted && errors.has('prof') ? false : ''"
                  class="worklog-small-input"
                  @change="updateSum"
                  v-validate="{ regex: /^[0-9\.\,\/]+$/ }"
                >
                </b-form-input>
              </td>
              <th v-if="staff.launatafla === '1'" :class="{ 'text-danger': submitted && errors.has('personualag') }">
                Persónuálag v. viðbótarmenntunar
              </th>
              <td v-if="staff.launatafla === '1'">
                <b-form-input
                  id="personualag"
                  name="personualag"
                  v-model="staff.personualag"
                  :state="submitted && errors.has('personualag') ? false : ''"
                  class="worklog-small-input"
                  v-validate="{ regex: /^[0-9\.\,\/]+$/ }"
                >
                </b-form-input>
              </td>
              <th></th>
              <td></td>
            </tr>
            <tr>
              <th :class="{ 'text-danger': submitted && errors.has('stadgengilslaun') }">Staðgengilslaun</th>
              <td>
                <b-form-input
                  id="stadgengilslaun"
                  name="stadgengilslaun"
                  v-model="staff.stadgengilslaun"
                  :state="submitted && errors.has('stadgengilslaun') ? false : ''"
                  class="worklog-small-input"
                  @change="updateSum"
                  v-validate="{ regex: /^[0-9\.\,\/]+$/ }"
                >
                </b-form-input>
              </td>
              <th>Samtals til launa á viku</th>
              <td></td>
            </tr>
            <tr>
              <th :class="{ 'text-danger': submitted && errors.has('ferill_aldur_throun') }">Kennsluferill/lífaldur/starfsþróun</th>
              <td>
                <b-form-input
                  id="ferill_aldur_throun"
                  name="ferill_aldur_throun"
                  v-model="staff.ferill_aldur_throun"
                  :state="submitted && errors.has('ferill_aldur_throun') ? false : ''"
                  class="worklog-small-input"
                  @change="updateSum"
                  v-validate="{ regex: /^[0-9\.\,\/]+$/ }"
                >
                </b-form-input>
              </td>
              <th :class="{ 'text-danger': submitted && errors.has('launahlutfall') }">Launahlutfall</th>
              <td>
                <b-form-input
                  id="launahlutfall"
                  name="launahlutfall"
                  v-model="staff.launahlutfall"
                  :state="submitted && errors.has('launahlutfall') ? false : ''"
                  class="worklog-small-input d-inline-block"
                  v-validate="{ regex: /^[0-9\.\,\/]+$/ }"
                >
                </b-form-input>
                <div class="d-inline-block">%</div>
              </td>
            </tr>
            <tr>
              <th :class="{ 'text-danger': submitted && errors.has('laun_annad') }">Laun annað</th>
              <td>
                <b-form-input
                  id="laun_annad"
                  name="laun_annad"
                  v-model="staff.laun_annad"
                  :state="submitted && errors.has('laun_annad') ? false : ''"
                  class="worklog-small-input"
                  @change="updateSum"
                  v-validate="{ regex: /^[0-9\.\,\/]+$/ }"
                >
                </b-form-input>
              </td>
              <th>Yfirvinna</th>
              <td>{{ staff.yfirvinna }}</td>
            </tr>
          </table>
          <h5 class="mb-3 mt-3">Athugasemd</h5>
          <b-textarea v-model="staff.athugasemdir" />
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import staff from '@/api/staff';

import moment from 'moment';
import { mapActions } from 'vuex';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';
import YesNo from '@/components/common/YesNo.vue';

export default {
  name: 'term-create-modal',
  components: {
    Datepicker,
    YesNo,
  },
  data() {
    return {
      submitted: false,
      submitting: false,
      lang: is,
      termId: '',
      staffId: '',
      staff: {},
      title: 'Stofna kennslutímabil',
      form: {
        term: '',
        name: '',
        date_from: '',
        date_to: '',
        first_pay: '',
        open: false,
      },
      termsList: [],
      monthList: [
        { id: 1, name: 'janúar' },
        { id: 2, name: 'febrúar' },
        { id: 3, name: 'mars' },
        { id: 4, name: 'apríl' },
        { id: 5, name: 'maí' },
        { id: 6, name: 'júní' },
        { id: 7, name: 'júlí' },
        { id: 8, name: 'ágúst' },
        { id: 9, name: 'september' },
        { id: 10, name: 'október' },
        { id: 11, name: 'nóvember' },
        { id: 12, name: 'desember' },
      ],
      loading: {
        info: false,
      },
    };
  },
  methods: {
    updateSum() {
      let lessonsPerWeek = 0;
      let payGrade = 0;
      let overtime = 0;

      if (this.staff.almenn_kennsla && !Number.isNaN(parseFloat(this.staff.almenn_kennsla.toString().replace(',', '.')))) {
        lessonsPerWeek += parseFloat(this.staff.almenn_kennsla.toString().replace(',', '.'));
      }
      if (this.staff.nybuakennsla && !Number.isNaN(parseFloat(this.staff.nybuakennsla.toString().replace(',', '.')))) {
        lessonsPerWeek += parseFloat(this.staff.nybuakennsla.toString().replace(',', '.'));
      }
      if (this.staff.serkennsla && !Number.isNaN(parseFloat(this.staff.serkennsla.toString().replace(',', '.')))) {
        lessonsPerWeek += parseFloat(this.staff.serkennsla.toString().replace(',', '.'));
      }
      this.$set(this.staff, 'kennslustundir_viku', lessonsPerWeek.toString().replace('.', ','));

      if (this.staff.grunnrodun && !Number.isNaN(this.staff.grunnrodun)) {
        payGrade += Number(this.staff.grunnrodun);
      }
      if (this.staff.prof && this.staff.launatafla === '2' && !Number.isNaN(this.staff.prof)) {
        payGrade += Number(this.staff.prof);
      }
      if (this.staff.stadgengilslaun && !Number.isNaN(this.staff.stadgengilslaun)) {
        payGrade += Number(this.staff.stadgengilslaun);
      }
      if (this.staff.ferill_aldur_throun && !Number.isNaN(this.staff.ferill_aldur_throun)) {
        payGrade += Number(this.staff.ferill_aldur_throun);
      }
      if (this.staff.laun_annad && !Number.isNaN(this.staff.laun_annad)) {
        payGrade += Number(this.staff.laun_annad);
      }
      this.$set(this.staff, 'launaflokkur', payGrade);

      // overtime = lessonsPerWeek + gaesla - kennsluskylda
      overtime = lessonsPerWeek;
      if (this.staff.gaesla && !Number.isNaN(parseFloat(this.staff.gaesla.toString().replace(',', '.')))) {
        overtime += parseFloat(this.staff.gaesla.toString().replace(',', '.'));
      }
      if (this.staff.kennsluskylda && !Number.isNaN(parseFloat(this.staff.kennsluskylda.toString().replace(',', '.')))) {
        overtime -= parseFloat(this.staff.kennsluskylda.toString().replace(',', '.'));
      }

      this.$set(this.staff, 'yfirvinna', overtime);
    },
    async load() {
      this.loading.info = true;
      try {
        const response = await staff.getWorklogTermStaffDetail(this.termId, this.staffId);
        if (response.data.items.length > 0) {
          this.staff = response.data.items[0];
          this.staff.upphafsmanudur = this.staff.upphafsmanudur ? this.monthList[this.staff.upphafsmanudur - 1] : null;
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.info = false;
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.submitting = true;
          const item = {
            radningarhlutfall: this.staff.radningarhlutfall,
            kennsluferill: this.staff.kennsluferill,
            kennsluskylda: this.staff.kennsluskylda || 0,
            almenn_kennsla: this.staff.almenn_kennsla || 0,
            nybuakennsla: this.staff.nybuakennsla || 0,
            serkennsla: this.staff.serkennsla || 0,
            gaesla: this.staff.gaesla || 0,
            grunnrodun: this.staff.grunnrodun || 0,
            prof: this.staff.launatafla === '2' ? this.staff.prof : null,
            stadgengilslaun: this.staff.stadgengilslaun || 0,
            ferill_aldur_throun: this.staff.ferill_aldur_throun || 0,
            laun_annad: this.staff.laun_annad || 0,
            orlofsprosenta: this.staff.orlofsprosenta || 0,
            kennsluafslattur: this.staff.kennsluafslattur || 0,
            launahlutfall: this.staff.launahlutfall || 0,
            dags_fra: moment(this.staff.dags_fra).format('DD.MM.YYYY'),
            dags_til: moment(this.staff.dags_til).format('DD.MM.YYYY'),
            athugasemd: this.staff.athugasemd,
            upphafsmanudur: this.staff.upphafsmanudur ? this.staff.upphafsmanudur.id : 8,
            launatafla: this.staff.launatafla,
            personualag: this.staff.launatafla === '1' ? this.staff.personualag : null,
          };
          await staff.updateWorklogTermStaffDetail(this.termId, this.staffId, item);
          this.displaySuccess('Kennslutímabili breytt');
          this.$emit('successful', {});
          this.$refs.createModal.hide();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.submitting = false;
      }
    },
    default0(value) {
      if (!value) return 0;
      return value;
    },
    show(staffId, termId) {
      this.staffId = staffId;
      this.termId = termId;
      this.staff = '';
      this.load();
      this.$refs.createModal.show();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
<style lang="scss">
.worklogStaffDate {
  .vdp-datepicker {
    input {
      height: 30px;
      padding-left: 5px;
      width: 75px;
    }
  }
}

.worklog-small-input {
  width: 60px;
  height: 30px;
  font-size: 13px;
}
</style>
