<template>
  <Slidebar :autoClose="false" @closeMe="closeSlidebar" :large="large" :extraLarge="extraLarge">
    <h4>{{ item.namsgrein_afanganr }} <loader v-if="loading"></loader></h4>
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <div v-if="type === 'info'">
        <i>Grunnupplýsingar</i>
        <table class="table less-padding course-create-table">
          <tr>
            <td>Heiti brautar*</td>
            <td>
              <b-form-group
                label-for="heiti"
                :state="submitted && errors.has('heiti') ? false : ''"
                :invalid-feedback="errors.first('heiti')"
              >
                <b-form-input
                  id="heiti"
                  name="heiti"
                  type="text"
                  v-model="form.heiti"
                  :state="submitted && errors.has('heiti') ? false : ''"
                  maxLength="200"
                  v-validate="'required'"
                  data-vv-as="heiti"
                ></b-form-input>
              </b-form-group>
            </td>
          </tr>
          <tr>
            <td>Kóði brautar*</td>
            <td>
              <b-form-group
                label-for="braut_kodi"
                :state="submitted && errors.has('braut_kodi') ? false : ''"
                :invalid-feedback="errors.first('braut_kodi')"
              >
                <b-form-input
                  id="braut_kodi"
                  name="braut_kodi"
                  type="text"
                  v-model="form.braut_kodi"
                  :state="submitted && errors.has('braut_kodi') ? false : ''"
                  maxLength="10"
                  v-validate="'required'"
                  data-vv-as="kóði"
                  @change="validateCode()"
                ></b-form-input>
              </b-form-group>
              <div class="text-danger" style="font-weight: bold; font-size: 13px" v-if="codeExists">
                Ath. í þessum undirskóla er þegar til virk braut með þessum kóða. Vinsamlega veljið annan kóða.
              </div>
            </td>
          </tr>
          <tr>
            <td>Undirskóli*</td>
            <td>
              <b-form-group
                label-for="undirskoli"
                :state="submitted && errors.has('undirskoli') ? false : ''"
                :invalid-feedback="errors.first('undirskoli')"
              >
                <v-select
                  id="undirskoli"
                  name="undirskoli"
                  v-model="form.undirskoli"
                  :clearable="false"
                  v-validate="'required'"
                  :state="submitted && errors.has('undirskoli') ? false : ''"
                  :options="divisions"
                  @input="validateCode()"
                  data-vv-as="undirskóli"
                >
                </v-select>
              </b-form-group>
            </td>
          </tr>
          <tr>
            <td>Deild/ir</td>
            <td>
              <b-form-group
                label-for="deild"
                :state="submitted && errors.has('deild') ? false : ''"
                :invalid-feedback="errors.first('deild')"
              >
                <v-select
                  id="deild"
                  name="deild"
                  v-model="form.deildir"
                  :clearable="true"
                  :state="submitted && errors.has('deild') ? false : ''"
                  :options="departments"
                  data-vv-as="deild"
                  multiple
                >
                </v-select>
              </b-form-group>
            </td>
          </tr>
          <tr>
            <td>Kjarna áfangar sjálfvirkt í námsferil</td>
            <td>
              <b-form-group
                label-for="sjalfvirkt_val"
                :state="submitted && errors.has('sjalfvirkt_val') ? false : ''"
                :invalid-feedback="errors.first('sjalfvirkt_val')"
              >
                <b-form-checkbox id="sjalfvirkt_val" name="sjalfvirkt_val" v-model="form.sjalfvirkt_val"></b-form-checkbox>
              </b-form-group>
            </td>
          </tr>
          <tr>
            <td>Fjöldi eininga</td>
            <td>
              <b-form-group
                label-for="einingar_alls"
                :state="submitted && errors.has('einingar_alls') ? false : ''"
                :invalid-feedback="errors.first('einingar_alls')"
              >
                <b-form-input
                  id="einingar_alls"
                  name="einingar_alls"
                  type="number"
                  v-model="form.einingar_alls"
                  :state="submitted && errors.has('einingar_alls') ? false : ''"
                ></b-form-input>
              </b-form-group>
            </td>
          </tr>
          <tr v-if="!isPrimarySchool">
            <td>Samræmd braut <span v-if="!isLearningCenter">- eldri námskrá</span></td>
            <td>
              <b-form-group
                label-for="samr_braut"
                :state="submitted && errors.has('samr_braut') ? false : ''"
                :invalid-feedback="errors.first('samr_braut')"
              >
                <v-select
                  id="samr_braut"
                  name="samr_braut"
                  v-model="form.samr_braut"
                  :clearable="true"
                  :state="submitted && errors.has('samr_braut') ? false : ''"
                  :options="getCommonCourses"
                  data-vv-as="samr. braut"
                >
                </v-select>
              </b-form-group>
              <div v-if="isSecondarySchool">
                <div class="d-inline-block pt-2"><b-form-checkbox v-model="filter.onlyMatricular" /></div>
                Birta eingöngu brautir sem sjást í vefþjónustu Háskólans
              </div>
            </td>
          </tr>
          <tr v-if="!isPrimarySchool && !isLearningCenter">
            <td>Samræmd braut - ný námskrá</td>
            <td>
              <b-form-group
                :state="submitted && errors.has('samr_braut_ny_namskra') ? false : ''"
                :invalid-feedback="errors.first('samr_braut_ny_namskra')"
              >
                <label label-for="subject" class="mt-2">
                  Samræmd braut - Ný námskrá

                  <i class="fa fa-info-circle" v-b-tooltip title="Braut þarf að vera tengd til að hún birtist rétt í gögnum MMS"></i>
                </label>
                <v-select
                  id="samr_braut_ny_namskra"
                  name="samr_braut_ny_namskra"
                  v-model="form.samr_braut_ny_namskra"
                  :clearable="true"
                  :state="submitted && errors.has('samr_braut_ny_namskra') ? false : ''"
                  :options="getCommonCoursesNew"
                  data-vv-as="samr. braut"
                >
                </v-select>
              </b-form-group>
              <div>
                <div class="d-inline-block pt-2"><b-form-checkbox v-model="filter.onlyMine" /></div>
                Birta eingöngu brautir skóla
                <i
                  class="fa fa-info-circle"
                  v-b-tooltip
                  title="Sjá bara þær brautir sem skólinn er þegar tengdur við. Ef það er
                    ekki valið þá er hægt að sjá allar brautir sem eru samþykktar hjá MRN"
                >
                </i>
              </div>
              <div>
                <div class="d-inline-block"><b-form-checkbox v-model="filter.onlyMinistrySerial" /></div>
                Birta eingöngu brautir með útgáfunúmer
              </div>
            </td>
          </tr>
          <tr>
            <td>Athugasemd</td>
            <td>
              <b-form-group label-for="aths" :state="submitted && errors.has('aths') ? false : ''" :invalid-feedback="errors.first('aths')">
                <b-form-textarea
                  id="aths"
                  name="aths"
                  v-model="form.aths"
                  maxLength="4000"
                  style="font-size: 13px"
                  :state="submitted && errors.has('aths') ? false : ''"
                ></b-form-textarea>
              </b-form-group>
            </td>
          </tr>
          <tr>
            <td>Virk</td>
            <td>
              <b-form-group
                label-for="gildi"
                :state="submitted && errors.has('gildi') ? false : ''"
                :invalid-feedback="errors.first('gildi')"
              >
                <b-form-checkbox id="gildi" name="gildi" v-model="form.gildi" @input="loadModules()"></b-form-checkbox>
              </b-form-group>
            </td>
          </tr>
        </table>
        <div v-if="item.gildi === 1 && !form.gildi && getModules.length > 0" style="font-size: 13px">
          <div class="mb-1">
            <i class="fa fa-exclamation-triangle"></i> Ath. eftirfarandi áfangar í brautaruppbyggingu eru ekki tengdir við aðra virka braut
            og eru ekki í námsferli nemenda á virkum kennslutímabilum.
            <div class="mt-2">
              <span v-for="(m, idx) in getModules" :key="idx"><span v-if="idx !== 0">, </span>{{ m.namsgrein_afanganr }}</span>
            </div>
            <div class="mb-1 mt-2">Má bjóða þér að óvirkja þá í leiðinni ?</div>
            <v-select
              id="ogilda_afanga"
              name="ogilda_afanga"
              v-model="form.ogilda_afanga"
              :clearable="false"
              :state="submitted && errors.has('ogilda_afanga') ? false : ''"
              :options="yesNo"
              class="mb-3"
            >
            </v-select>
          </div>
        </div>
      </div>
      <div v-if="type === 'application'">
        <i>Umsóknir</i>
        <table class="table less-padding course-create-table">
          <tr>
            <td>Má sækja um í menntagátt</td>
            <td>
              <b-form-group
                label-for="innrit_menntagatt"
                :state="submitted && errors.has('innrit_menntagatt') ? false : ''"
                :invalid-feedback="errors.first('innrit_menntagatt')"
              >
                <b-form-checkbox id="innrit_menntagatt" name="innrit_menntagatt" v-model="form.innrit_menntagatt"></b-form-checkbox>
              </b-form-group>
            </td>
          </tr>
          <tr>
            <td>Má innrita nema á námsbraut</td>
            <td>
              <b-form-group
                label-for="innrit_lokad_allar"
                :state="submitted && errors.has('innrit_lokad_allar') ? false : ''"
                :invalid-feedback="errors.first('innrit_lokad_allar')"
              >
                <b-form-checkbox id="innrit_lokad_allar" name="innrit_lokad_allar" v-model="form.innrit_lokad_allar"></b-form-checkbox>
              </b-form-group>
            </td>
          </tr>
          <tr>
            <td>Má sækja um á umsóknarvef</td>
            <td>
              <b-form-group
                label-for="innrit_lokad_nyjar"
                :state="submitted && errors.has('innrit_lokad_nyjar') ? false : ''"
                :invalid-feedback="errors.first('innrit_lokad_nyjar')"
              >
                <b-form-checkbox id="innrit_lokad_nyjar" name="innrit_lokad_nyjar" v-model="form.innrit_lokad_nyjar"></b-form-checkbox>
              </b-form-group>
            </td>
          </tr>
          <tr>
            <td>Má sækja um brautaskipti</td>
            <td>
              <b-form-group
                label-for="innrit_brautaskipti"
                :state="submitted && errors.has('innrit_brautaskipti') ? false : ''"
                :invalid-feedback="errors.first('innrit_brautaskipti')"
              >
                <b-form-checkbox id="innrit_brautaskipti" name="innrit_brautaskipti" v-model="form.innrit_brautaskipti"></b-form-checkbox>
              </b-form-group>
            </td>
          </tr>
          <tr>
            <td>
              Umsóknarfrestur runninn út
              <i
                class="fa fa-info-circle"
                v-b-tooltip
                :title="`Þennan dag er braut ekki lengur sýnileg í Menntagátt.
                      ${
                        !form.innrit_lokad_allar
                          ? `Ath! til að hægt sé að skrá dagsetningu þarf að vera opið fyrir umsóknir í Menntagátt.`
                          : ''
                      }`"
              ></i>
            </td>
            <td>
              <datepicker
                id="dags_lokad"
                name="dags_lokad"
                v-model="form.dags_lokad"
                :language="lang"
                :monday-first="true"
                format="dd.MM.yyyy"
                :typeable="false"
                :clear-button="true"
                :disabled="!form.innrit_lokad_allar"
                ref="dateEnrolOpenPickr"
              ></datepicker>
            </td>
          </tr>
          <tr>
            <td>Fjöldi nýnema</td>
            <td>
              <b-form-group
                label-for="fj_nynema"
                :state="submitted && errors.has('fj_nynema') ? false : ''"
                :invalid-feedback="errors.first('fj_nynema')"
              >
                <b-form-input
                  id="fj_nynema"
                  name="fj_nynema"
                  type="number"
                  v-model="form.fj_nynema"
                  :state="submitted && errors.has('fj_nynema') ? false : ''"
                ></b-form-input>
              </b-form-group>
            </td>
          </tr>
          <tr>
            <td>Nám á ensku</td>
            <td>
              <b-form-group
                label-for="nam_a_ensku"
                :state="submitted && errors.has('nam_a_ensku') ? false : ''"
                :invalid-feedback="errors.first('nam_a_ensku')"
              >
                <b-form-checkbox id="nam_a_ensku" name="nam_a_ensku" v-model="form.nam_a_ensku"></b-form-checkbox>
              </b-form-group>
            </td>
          </tr>
          <tr>
            <td>Athugasemd á rafrænni umsókn</td>
            <td>
              <b-form-group
                label-for="aths_rafr_umsokn"
                :state="submitted && errors.has('aths_rafr_umsokn') ? false : ''"
                :invalid-feedback="errors.first('aths_rafr_umsokn')"
              >
                <b-form-textarea
                  id="aths_rafr_umsokn"
                  name="aths_rafr_umsokn"
                  v-model="form.aths_rafr_umsokn"
                  maxLength="4000"
                  style="font-size: 13px"
                  :state="submitted && errors.has('aths_rafr_umsokn') ? false : ''"
                ></b-form-textarea>
              </b-form-group>
            </td>
          </tr>
        </table>
      </div>
      <div v-if="type === 'choice'">
        <i>Val</i>
        <table class="table less-padding course-create-table">
          <tr>
            <td>Hámark eininga á kennslutímabili</td>
            <td>
              <b-form-group
                label-for="einingar_max_onn"
                :state="submitted && errors.has('einingar_max_onn') ? false : ''"
                :invalid-feedback="errors.first('einingar_max_onn')"
              >
                <b-form-input
                  id="einingar_max_onn"
                  name="einingar_max_onn"
                  type="number"
                  v-model="form.einingar_max_onn"
                  :state="submitted && errors.has('einingar_max_onn') ? false : ''"
                ></b-form-input>
              </b-form-group>
            </td>
          </tr>
          <tr>
            <td>Hámarksfjöldi kennslustunda á viku</td>
            <td>
              <b-form-group
                label-for="timar_max_onn"
                :state="submitted && errors.has('timar_max_onn') ? false : ''"
                :invalid-feedback="errors.first('timar_max_onn')"
              >
                <b-form-input
                  id="timar_max_onn"
                  name="timar_max_onn"
                  type="number"
                  v-model="form.timar_max_onn"
                  :state="submitted && errors.has('timar_max_onn') ? false : ''"
                ></b-form-input>
              </b-form-group>
            </td>
          </tr>
          <tr>
            <td>Lágmarksfjöldi varavals á kennslutímabili</td>
            <td>
              <b-form-group
                label-for="min_varaval"
                :state="submitted && errors.has('min_varaval') ? false : ''"
                :invalid-feedback="errors.first('min_varaval')"
              >
                <b-form-input
                  id="min_varaval"
                  name="min_varaval"
                  type="number"
                  v-model="form.min_varaval"
                  :state="submitted && errors.has('min_varaval') ? false : ''"
                ></b-form-input>
              </b-form-group>
            </td>
          </tr>
          <tr>
            <td>Hámarksfjöldi varavals á kennslutímabili</td>
            <td>
              <b-form-group
                label-for="max_varaval"
                :state="submitted && errors.has('max_varaval') ? false : ''"
                :invalid-feedback="errors.first('max_varaval')"
              >
                <b-form-input
                  id="max_varaval"
                  name="max_varaval"
                  type="number"
                  v-model="form.max_varaval"
                  :state="submitted && errors.has('max_varaval') ? false : ''"
                ></b-form-input>
              </b-form-group>
            </td>
          </tr>
        </table>
      </div>
      <div v-if="type === 'diploma'">
        <i>Skírteini</i>
        <table class="table less-padding course-create-table">
          <tr>
            <td>Fj. tíma á kennslutímabili</td>
            <td>
              <b-form-group
                label-for="klst_alls"
                :state="submitted && errors.has('klst_alls') ? false : ''"
                :invalid-feedback="errors.first('klst_alls')"
              >
                <b-form-input
                  id="klst_alls"
                  name="klst_alls"
                  type="number"
                  v-model="form.klst_alls"
                  :state="submitted && errors.has('klst_alls') ? false : ''"
                ></b-form-input>
              </b-form-group>
            </td>
          </tr>
          <tr>
            <td>Birta skólasóknaráfanga á skírteini</td>
            <td>
              <b-form-group
                label-for="skolasoknarafangi_a_skirteini"
                :state="submitted && errors.has('skolasoknarafangi_a_skirteini') ? false : ''"
                :invalid-feedback="errors.first('skolasoknarafangi_a_skirteini')"
              >
                <b-form-checkbox
                  id="skolasoknarafangi_a_skirteini"
                  name="skolasoknarafangi_a_skirteini"
                  v-model="form.skolasoknarafangi_a_skirteini"
                ></b-form-checkbox>
              </b-form-group>
            </td>
          </tr>
          <tr>
            <td>Texti á undan heiti á skírteini</td>
            <td>
              <b-form-group
                label-for="texti_skirteini"
                :state="submitted && errors.has('texti_skirteini') ? false : ''"
                :invalid-feedback="errors.first('texti_skirteini')"
              >
                <b-form-input
                  id="texti_skirteini"
                  name="texti_skirteini"
                  type="text"
                  v-model="form.texti_skirteini"
                  :state="submitted && errors.has('texti_skirteini') ? false : ''"
                  maxLength="100"
                  data-vv-as="texti"
                ></b-form-input>
              </b-form-group>
            </td>
          </tr>
          <tr>
            <td>Heiti á skírteini</td>
            <td>
              <b-form-group
                label-for="heiti_skirteini"
                :state="submitted && errors.has('heiti_skirteini') ? false : ''"
                :invalid-feedback="errors.first('heiti_skirteini')"
              >
                <b-form-input
                  id="heiti_skirteini"
                  name="heiti_skirteini"
                  type="text"
                  v-model="form.heiti_skirteini"
                  :state="submitted && errors.has('heiti_skirteini') ? false : ''"
                  maxLength="200"
                  data-vv-as="heiti"
                ></b-form-input>
              </b-form-group>
            </td>
          </tr>
          <tr>
            <td>Texti á eftir heiti á skírteini</td>
            <td>
              <b-form-group
                label-for="texti_skirteini2"
                :state="submitted && errors.has('texti_skirteini2') ? false : ''"
                :invalid-feedback="errors.first('texti_skirteini2')"
              >
                <b-form-input
                  id="texti_skirteini2"
                  name="texti_skirteini2"
                  type="text"
                  v-model="form.texti_skirteini2"
                  :state="submitted && errors.has('texti_skirteini2') ? false : ''"
                  maxLength="300"
                  data-vv-as="texti"
                ></b-form-input>
              </b-form-group>
            </td>
          </tr>
          <tr>
            <td>Athugasemdir á skírteini</td>
            <td>
              <b-form-group
                label-for="aths_skirteini"
                :state="submitted && errors.has('aths_skirteini') ? false : ''"
                :invalid-feedback="errors.first('aths_skirteini')"
              >
                <b-form-input
                  id="aths_skirteini"
                  name="aths_skirteini"
                  type="text"
                  v-model="form.aths_skirteini"
                  :state="submitted && errors.has('aths_skirteini') ? false : ''"
                  maxLength="4000"
                  data-vv-as="athugasemd"
                ></b-form-input>
              </b-form-group>
            </td>
          </tr>
          <tr>
            <td>Heiti (enska)</td>
            <td>
              <b-form-group
                label-for="heiti_ens"
                :state="submitted && errors.has('heiti_ens') ? false : ''"
                :invalid-feedback="errors.first('heiti_ens')"
              >
                <b-form-input
                  id="heiti_ens"
                  name="heiti_ens"
                  type="text"
                  v-model="form.heiti_ens"
                  :state="submitted && errors.has('heiti_ens') ? false : ''"
                  maxLength="200"
                  data-vv-as="heiti"
                ></b-form-input>
              </b-form-group>
            </td>
          </tr>
          <tr>
            <td>Texti á undan heiti á skírteini (enska)</td>
            <td>
              <b-form-group
                label-for="texti_skirteini_enska"
                :state="submitted && errors.has('texti_skirteini_enska') ? false : ''"
                :invalid-feedback="errors.first('texti_skirteini_enska')"
              >
                <b-form-input
                  id="texti_skirteini_enska"
                  name="texti_skirteini_enska"
                  type="text"
                  v-model="form.texti_skirteini_enska"
                  :state="submitted && errors.has('texti_skirteini_enska') ? false : ''"
                  maxLength="100"
                  data-vv-as="texti"
                ></b-form-input>
              </b-form-group>
            </td>
          </tr>
          <tr>
            <td>Heiti á skírteini (enska)</td>
            <td>
              <b-form-group
                label-for="heiti_skirteini_ens"
                :state="submitted && errors.has('heiti_skirteini_ens') ? false : ''"
                :invalid-feedback="errors.first('heiti_skirteini_ens')"
              >
                <b-form-input
                  id="heiti_skirteini_ens"
                  name="heiti_skirteini_ens"
                  type="text"
                  v-model="form.heiti_skirteini_ens"
                  :state="submitted && errors.has('heiti_skirteini_ens') ? false : ''"
                  maxLength="200"
                  data-vv-as="heiti"
                ></b-form-input>
              </b-form-group>
            </td>
          </tr>
          <tr>
            <td>Texti á eftir heiti á skírteini (enska)</td>
            <td>
              <b-form-group
                label-for="texti_skirteini2_enska"
                :state="submitted && errors.has('texti_skirteini2_enska') ? false : ''"
                :invalid-feedback="errors.first('texti_skirteini2_enska')"
              >
                <b-form-input
                  id="texti_skirteini2_enska"
                  name="texti_skirteini2_enska"
                  type="text"
                  v-model="form.texti_skirteini2_enska"
                  :state="submitted && errors.has('texti_skirteini2_enska') ? false : ''"
                  maxLength="300"
                  data-vv-as="texti"
                ></b-form-input>
              </b-form-group>
            </td>
          </tr>
          <tr>
            <td>Athugasemdir á skírteini (enska)</td>
            <td>
              <b-form-group
                label-for="aths_skirteini_ens"
                :state="submitted && errors.has('aths_skirteini_ens') ? false : ''"
                :invalid-feedback="errors.first('aths_skirteini_ens')"
              >
                <b-form-input
                  id="aths_skirteini_ens"
                  name="aths_skirteini_ens"
                  type="text"
                  v-model="form.aths_skirteini_ens"
                  :state="submitted && errors.has('aths_skirteini_ens') ? false : ''"
                  maxLength="4000"
                  data-vv-as="athugasemd"
                ></b-form-input>
              </b-form-group>
            </td>
          </tr>
        </table>
      </div>
    </b-form>
    <b-btn variant="primary" @click="validateBeforeSubmit()" :disabled="loading">Vista</b-btn>
  </Slidebar>
</template>

<script>
import moment from 'moment';
import types from '@/api/types';
import curriculum from '@/api/curriculum';
import structure from '@/api/structure';
import reports from '@/api/reports';
import { mapGetters, mapActions } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';
import Loader from '@/components/common/Loader.vue';

import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';

export default {
  name: 'course-edit-slidebar',
  components: {
    Slidebar,
    Loader,
    Datepicker,
  },
  props: ['id', 'type', 'large', 'extraLarge'],
  computed: {
    getModules() {
      return this.modules.filter(x => x.tengt_namsferli === 0 && x.tengt_virkri_braut === 0);
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_brautir');
    },
    isSecondarySchool() {
      return this.schoolSettings && this.schoolSettings.tegund_skola_id === 1;
    },
    isPrimarySchool() {
      return this.schoolSettings && this.schoolSettings.tegund_skola_id === 3;
    },
    isLearningCenter() {
      return this.schoolSettings && this.schoolSettings.tegund_skola_id === 4;
    },
    getCommonCourses() {
      if (this.isLearningCenter) {
        return this.commonCourses.filter(x => x.kodi.startsWith('FRÆ'));
      }
      return this.commonCourses.filter(x => !this.filter.onlyMatricular || x.student === 1);
    },
    getCommonCoursesNew() {
      return this.commonCoursesNew.filter(
        x => (!this.filter.onlyMine || x.is_mine) && (!this.filter.onlyMinistrySerial || x.ministry_serial),
      );
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      lang: is,
      item: '',
      submitted: false,
      loading: false,
      codeExists: false,

      modules: [],
      yesNo: [
        { id: 0, label: 'Nei' },
        { id: 1, label: 'Já' },
      ],
      form: {
        heiti: '',
        braut_kodi: '',
        gildi: true,
        undirskoli: '',
        deildir: [],
        sjalfvirkt_val: false,
        einingar_alls: '',
        samr_braut: '',
        samr_braut_ny_namskra: '',
        aths: '',
        ogilda_afanga: { id: 0, label: 'Nei' },

        innrit_menntagatt: false,
        innrit_lokad_allar: false,
        innrit_lokad_nyjar: false,
        innrit_brautaskipti: false,
        dags_lokad: '',
        fj_nynema: '',
        nam_a_ensku: false,
        aths_rafr_umsokn: '',

        einingar_max_onn: '',
        timar_max_onn: '',
        min_varaval: '',
        max_varaval: '',

        klst_alls: '',
        skolasoknarafangi_a_skirteini: false,
        texti_skirteini: '',
        heiti_skirteini: '',
        texti_skirteini2: '',
        aths_skirteini: '',
        heiti_ens: '',
        texti_skirteini_enska: '',
        heiti_skirteini_ens: '',
        texti_skirteini2_enska: '',
        aths_skirteini_ens: '',
      },
      filter: {
        onlyMine: true,
        onlyMinistrySerial: true,
        onlyMatricular: false,
      },
      divisions: [],
      departments: [],
      commonCourses: [],
      commonCoursesNew: [],
    };
  },
  methods: {
    async validateCode() {
      this.codeExists = false;
      if (this.form.braut_kodi.length > 0 && this.form.undirskoli && this.form.undirskoli.division_id) {
        try {
          const response = await curriculum.checkCourseCodeExists({
            id: this.id,
            division: this.form.undirskoli.division_id,
            code: this.form.braut_kodi,
          });
          this.codeExists = response.data.already_exists > 0;
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    async loadDivisions() {
      try {
        const response = await structure.getDivisionList();
        this.divisions = response.data.items.map(x => ({
          ...x,
          label: `${x.code} - ${x.name}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadDepartments() {
      try {
        const response = await structure.getDepartmentList();
        this.departments = response.data.items.map(x => ({
          ...x,
          label: `${x.name}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadCommonCourses() {
      try {
        const response = await types.getCommonCourses();
        this.commonCourses = response.data.items.map(x => ({
          ...x,
          label: `${x.kodi} - ${x.heiti}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadCommonCoursesNew() {
      try {
        this.commonCoursesNew = [];
        const response = await reports.getMinistryConfirmedCourses();
        this.commonCoursesNew = response.data.items.map(x => ({
          ...x,
          label: `${x.title} ${x.ministry_serial || ' '}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadModules() {
      if (this.item.gildi === 1 && !this.form.gildi && this.modules.length === 0) {
        try {
          const response = await curriculum.getCourseModules(this.id);
          this.modules = response.data.items;
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    async loadDetails() {
      if (this.canEdit) {
        try {
          this.loading = true;
          const response = await curriculum.getCourseById(this.id);
          this.item = response.data.items[0];
          this.form.heiti = this.item.heiti;
          this.form.braut_kodi = this.item.braut_kodi;
          this.form.einingar_alls = this.item.einingar_alls;
          this.form.sjalfvirkt_val = this.item.sjalfvirkt_val === 1;
          this.form.gildi = this.item.gildi === 1;
          this.form.aths = this.item.aths;
          this.form.innrit_menntagatt = this.item.innrit_menntagatt === 1;
          this.form.innrit_lokad_allar = this.item.innrit_lokad_allar === 1;
          this.form.innrit_lokad_nyjar = this.item.innrit_lokad_nyjar === 1;
          this.form.innrit_brautaskipti = this.item.innrit_brautaskipti === 1;
          this.form.dags_lokad = this.item.dags_lokad ? moment(this.item.dags_lokad) : '';
          this.form.fj_nynema = this.item.fj_nynema;
          this.form.aths_rafr_umsokn = this.item.aths_rafr_umsokn;
          this.form.nam_a_ensku = this.item.nam_a_ensku === 1;
          this.form.einingar_max_onn = this.item.einingar_max_onn;
          this.form.timar_max_onn = this.item.timar_max_onn;
          this.form.min_varaval = this.item.min_varaval;
          this.form.max_varaval = this.item.min_varaval;
          this.form.skolasoknarafangi_a_skirteini = this.item.skolasoknarafangi_a_skirteini === 1;
          this.form.heiti_ens = this.item.heiti_ens;
          this.form.texti_skirteini = this.item.texti_skirteini;
          this.form.heiti_skirteini = this.item.heiti_skirteini;
          this.form.texti_skirteini2 = this.item.texti_skirteini2;
          this.form.heiti_skirteini_ens = this.item.heiti_skirteini_ens;
          this.form.texti_skirteini_enska = this.item.texti_skirteini_enska;
          this.form.texti_skirteini2_enska = this.item.texti_skirteini2_enska;
          this.form.aths_skirteini = this.item.aths_skirteini;
          this.form.aths_skirteini_ens = this.item.aths_skirteini_ens;
          this.form.klst_alls = this.item.klst_alls;

          await this.loadDivisions();
          this.form.undirskoli = this.divisions.find(x => x.division_id === this.item.undirskoli_id);

          await this.loadDepartments();
          this.form.deildir = this.departments.filter(x => (this.item.deild || '').split(',').find(y => y === x.name) !== undefined);

          await this.loadCommonCourses();
          this.form.samr_braut = this.commonCourses.find(x => x.samr_braut === this.item.samr_braut);

          await this.loadCommonCoursesNew();
          this.form.samr_braut_ny_namskra = this.commonCoursesNew.find(x => x.programme_id === this.item.samr_braut_ny_namskra);
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading = false;
        }
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });
        const item = {
          undirskoli_id: this.form.undirskoli.division_id,
          heiti: this.form.heiti,
          deildir: this.form.deildir.map(x => x.department_id).join(','),
          einingar_alls: this.form.einingar_alls,
          einingar_max_onn: this.form.einingar_max_onn,
          gildi: this.form.gildi ? 1 : 0,
          aths: this.form.aths,
          innrit_lokad_allar: this.form.innrit_lokad_allar ? 1 : 0,
          innrit_lokad_nyjar: this.form.innrit_lokad_nyjar ? 1 : 0,
          innrit_menntagatt: this.form.innrit_menntagatt ? 1 : 0,
          innrit_brautaskipti: this.form.innrit_brautaskipti ? 1 : 0,
          dags_lokad: this.form.dags_lokad ? moment(this.form.dags_lokad).format('DD.MM.YYYY') : '',
          samr_braut: this.form.samr_braut ? this.form.samr_braut.samr_braut : '',
          braut_kodi: this.form.braut_kodi,
          heiti_ens: this.form.heiti_ens,
          heiti_skirteini: this.form.heiti_skirteini,
          sjalfvirkt_val: this.form.sjalfvirkt_val ? 1 : 0,
          texti_skirteini: this.form.texti_skirteini,
          timar_max_onn: this.form.timar_max_onn,
          aths_rafr_umsokn: this.form.aths_rafr_umsokn,
          min_varaval: this.form.min_varaval,
          max_varaval: this.form.max_varaval,
          skolasoknarafangi_a_skirteini: this.form.skolasoknarafangi_a_skirteini ? 1 : 0,
          nam_a_ensku: this.form.nam_a_ensku ? 1 : 0,
          heiti_skirteini_ens: this.form.heiti_skirteini_ens,
          texti_skirteini2: this.form.texti_skirteini2,
          texti_skirteini_enska: this.form.texti_skirteini_enska,
          texti_skirteini2_enska: this.form.texti_skirteini2_enska,
          fj_nema_alls: this.form.fj_nema_alls,
          fj_nynema: this.form.fj_nynema,
          aths_skirteini: this.form.aths_skirteini,
          aths_skirteini_ens: this.form.aths_skirteini_ens,
          samr_braut_ny_namskra: this.form.samr_braut_ny_namskra ? this.form.samr_braut_ny_namskra.programme_id : '',
          klst_alls: this.form.klst_alls,
          ogilda_afanga: this.getModules.map(x => x.afangi_id).join(','),
        };

        await curriculum.updateCourse(this.id, item);
        this.displaySuccess('Braut breytt');
        this.closeSlidebar();
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        if (e.response && e.response.data && e.response.data.error) {
          this.displayError(e.response.data.error);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    closeSlidebar(item) {
      this.$emit('closeSlidebar', item);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.$nextTick(() => {
      if (this.$refs.focusInput) {
        this.$refs.focusInput.focus();
      }
    });
    this.loadDetails();
  },
};
</script>

<style lang="scss">
.course-create-table {
  font-size: 13px;
  input {
    height: 25px !important;
    font-size: 13px;
    &.vs__search {
      margin: 0;
    }
  }
  .form-group {
    margin-bottom: 0px;
  }
}
</style>
