<template>
  <div>
    <b-btn variant="primary" v-if="canEdit" @click="openEditSlidebar()" size="sm" class="btn-xs float-right mb-2">
      <i class="fa fa-plus-circle"></i> Bæta við athugasemd</b-btn
    >
    <div v-if="!loading && list.length === 0">Engar athugasemdir fundust</div>
    <table class="table less-padding" style="font-size: 13px" v-if="!loading && list.length > 0">
      <thead>
        <tr>
          <th>Athugasemd</th>
          <th>Dags.</th>
          <th>Opin aths.</th>
          <th>Áfangi</th>
          <th>Braut</th>
          <th>Önn</th>
          <th>Flokkur</th>
          <th>Skráð af</th>
          <th>Breytt af</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tr v-for="item in list" :key="item.nemandi_athugasemdir_id">
        <td>{{ item.athugasemd }}</td>
        <td>{{ item.dags }}</td>
        <td><yes-no :item="item.opin"></yes-no></td>
        <td>{{ item.namsgrein_afanganr }}</td>
        <td>{{ item.braut_kodi }}</td>
        <td>{{ item.onn_eink }}</td>
        <td>{{ item.heiti }}</td>
        <td>{{ item.skammst_create }}</td>
        <td>{{ item.skammst_edit }}</td>
        <td>
          <i
            class="fa fa-pencil cursor-pointer text-primary"
            title="Breyta athugasemd"
            v-b-tooltip
            v-if="canEdit"
            @click="openEditSlidebar(item)"
          ></i>
        </td>
        <td>
          <i
            class="fa fa-trash cursor-pointer text-primary"
            title="Eyða athugasemd"
            v-b-tooltip
            v-if="canEdit"
            @click="deleteComment(item)"
          ></i>
        </td>
      </tr>
    </table>
    <StudentAddCommentSlidebar
      :item="selectedItem"
      :studentId="selectedItem.nemandi_id"
      :showDetails="true"
      v-if="showEditSlidebar"
      @closeSlidebar="closeEditSlidebar"
      @closeAndReload="closeAndReloadEditSlidebar"
    ></StudentAddCommentSlidebar>
  </div>
</template>

<script>
import students from '@/api/students';
import { mapActions, mapGetters } from 'vuex';
import YesNo from '@/components/common/YesNo.vue';

import StudentAddCommentSlidebar from '@/components/students/comments/StudentAddCommentSlidebar.vue';

export default {
  name: 'student-comments',
  props: ['studentId', 'reload'],
  components: {
    YesNo,
    StudentAddCommentSlidebar,
  },
  computed: {
    canRead() {
      return this.loggedInUserHasReadPermission('nemendahald_hopar') || this.loggedInUserHasReadPermission('nemendahald_nemendur');
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('nemendahald_hopar') || this.loggedInUserHasWritePermission('nemendahald_nemendur');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolHasSettings', 'schoolSettings']),
  },
  data() {
    return {
      loading: false,
      list: [],
      selectedItem: '',
      showEditSlidebar: false,
    };
  },
  methods: {
    async loadList() {
      try {
        this.loading = true;
        const response = await students.getStudentComments(this.studentId);
        this.list = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async deleteComment(item) {
      try {
        const conf = confirm(`Ertu viss um að þú viljir eyða út athguasemdinni ${item.athugasemd}?`); // eslint-disable-line
        if (conf) {
          await students.updateComment(item.nemandi_athugasemdir_id, { delete: 1 });
          this.displaySuccess('Athugsemd eytt');
          this.loadList();
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    openEditSlidebar(item) {
      if (item) {
        this.selectedItem = item;
      } else {
        this.selectedItem = {
          nemandi_id: this.studentId,
        };
      }
      this.showEditSlidebar = true;
    },
    closeEditSlidebar() {
      this.selectedItem = '';
      this.showEditSlidebar = false;
    },
    closeAndReloadEditSlidebar() {
      this.closeEditSlidebar();
      this.loadList();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    reload() {
      if (this.reload) {
        this.loadList();
      }
    },
  },
  created() {
    this.loadList();
  },
};
</script>
<style lang="scss"></style>
