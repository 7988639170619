<template>
  <tr>
    <td>
      <b-link @click="changeGroup()" title="Velja hóp" v-b-tooltip v-if="slidebar">
        {{ item.namsgrein_afanganr }}
      </b-link>
      <b-link :to="{ name: 'Group', params: { id: item.hopur_id }, query: { search: searchQuery } }" v-else>
        {{ item.namsgrein_afanganr }}</b-link
      >
    </td>
    <td>
      <b-link @click="changeGroup()" title="Velja hóp" v-b-tooltip v-if="slidebar"> {{ item.hopur }} </b-link>
      <b-link :to="{ name: 'Group', params: { id: item.hopur_id }, query: { search: searchQuery } }" v-else> {{ item.hopur }}</b-link>
    </td>
    <td>{{ item.kennarar_skammst }}</td>
    <td>{{ item.dags_fra | moment('DD.MM.YYYY') }}</td>
    <td>{{ item.dags_til | moment('DD.MM.YYYY') }}</td>
    <td v-if="!slidebar">{{ item.max_fj_nema }}</td>
    <td v-if="!slidebar">{{ item.fjoldi_hops }}</td>
    <td style="font-size: 13px">
      <div v-for="(d, dIdx) in item.departments" :key="dIdx">
        {{ d }}
      </div>
    </td>
    <td v-if="!slidebar">
      <span v-if="stofntafla">
        <div v-if="editTeachers">
          <Loader v-if="loading.teacher"></Loader>
          <div v-for="t in currentTeachers" :key="t.starfsm_id">
            {{ t.nafn }} {{ t.skammst }}
            <div class="float-right"><i @click="deletePremise(t)" class="fa fa-trash text-primary cursor-pointer"></i></div>
          </div>
          <v-select
            id="staff"
            name="staff"
            v-model="selectedStaff"
            :options="getTeachers"
            @input="addPremise"
            ref="selectStaffDropdown"
            style="font-size: 12px; width: 200px"
          ></v-select>
          <b-btn variant="primary" size="sm" class="btn-xs" style="font-size: 10px" @click="doEditTeachers()">Hætta að breyta</b-btn>
        </div>
        <div v-else>
          <span v-for="(f, idx) in item.forsendur" :key="`group${item.hopur_id}teacher${f.starfsm_id}`">
            <span v-if="idx > 0">, </span>
            {{ f.skammst }}
          </span>
          <i class="fa fa-pencil text-primary cursor-pointer" @click="doEditTeachers()"></i>
        </div>
      </span>
    </td>
  </tr>
</template>

<script>
import staff from '@/api/staff';
import school from '@/api/school';

import { mapGetters, mapActions } from 'vuex';
import Loader from '@/components/common/Loader.vue';

export default {
  name: 'groups-item',
  props: ['item', 'slidebar', 'canEdit', 'searchQuery'],
  components: {
    Loader,
  },
  computed: {
    stofntafla() {
      return !this.schoolHasSettings('stundatoflukerfi');
    },
    getTeachers() {
      return this.teachers.filter(x => this.currentTeachers.map(y => y.starfsm_id).indexOf(x.starfsm_id) === -1);
    },
    forsendur_sort() {
      return this.item && this.item.forsendur_sort;
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolHasSettings']),
  },
  data() {
    return {
      loading: {
        teacher: false,
      },
      editTeachers: false,
      currentTeachers: [],
      teachers: [],
      selectedStaff: '',
    };
  },
  methods: {
    doEditTeachers() {
      this.editTeachers = !this.editTeachers;
      if (this.editTeachers) {
        this.loadStaff();
      }
    },
    async loadStaff() {
      try {
        if (this.teachers.length === 0) {
          this.loading.teacher = true;
          const response = await staff.getStaffList();
          this.teachers = response.data.items.map(x => ({
            nafn: x.nafn,
            starfsm_id: x.starfsm_id,
            skammst: x.skammst,
            label: `${x.nafn} ${x.skammst}`,
          }));
          this.currentTeachers = [...this.teachers.filter(x => this.item.forsendur.map(y => y.starfsm_id).indexOf(x.starfsm_id) > -1)];
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.teacher = false;
      }
    },
    async deletePremise(teacher) {
      // eslint-disable-next-line no-restricted-globals
      const conf = confirm(
        `Ertu viss um að þú viljir fjarlægja ${teacher.nafn} sem kennara af hópnum ${this.item.namsgrein_afanganr}(${this.item.hopur}}?`,
      );
      if (conf) {
        try {
          await school.deleteStaffGroupPremise(this.item.hopur_id, teacher.starfsm_id);
          this.$emit('reloadGroup');
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    async addPremise() {
      try {
        if (this.selectedStaff) {
          await school.addStaffGroupPremise(this.item.hopur_id, this.selectedStaff.starfsm_id);
          this.selectedStaff = '';
          this.$emit('reloadGroup');
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    changeGroup() {
      this.$emit('changeGroup');
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    forsendur_sort() {
      this.currentTeachers = [...this.teachers.filter(x => this.item.forsendur.map(y => y.starfsm_id).indexOf(x.starfsm_id) > -1)];
    },
  },
};
</script>
