<template>
  <page>
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <PageTitle title="Hagsstofuskýrsla grunnskóla" />
    <PageMenu route="StatisticsElementary" :selectedPage="page" :items="menu" />
    <b-row class="breadcumb-location">
      <b-col>
        <div class="float-right">
          <CustomButton type="excel" title="Sækja skýrslu" @click.native="getReport" />
        </div>
      </b-col>
    </b-row>
    <Students v-if="page === 'students'" />
    <Staff v-if="page === 'staff'" />
  </page>
</template>

<script>
import auth from '@/api/auth';
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
// import store from 'store';

import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import PageTitle from '@/components/common/PageTitle.vue';
import PageMenu from '@/components/common/PageMenu.vue';
import CustomButton from '@/components/common/CustomButton.vue';

import Staff from '@/components/reports/elementary/Staff.vue';
import Students from '@/components/reports/elementary/Students.vue';

export default {
  name: 'statistics-elementary',
  components: {
    Breadcrumbs,
    PageTitle,
    PageMenu,
    CustomButton,
    Staff,
    Students,
  },
  computed: {
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      item: '',
      loading: false,
      page: 'students',
      breadcrumbs: [
        { name: 'Forsíða', route: 'Dashboard' },
        { name: 'Tölfræði', route: '' },
        { name: 'Hagstofuskýrsla', route: 'StatisticsElementary' },
      ],
      menu: [
        {
          name: 'Nemendur',
          page: 'students',
        },
        {
          name: 'Starfsfólk',
          page: 'staff',
        },
      ],
    };
  },
  methods: {
    async getReport() {
      try {
        axios({
          url: '/api/statistics/elementary-report',
          method: 'GET',
          headers: {
            token: auth.getToken(),
          },
          responseType: 'arraybuffer',
        }).then(response => {
          let filename = response.headers['content-disposition'];
          filename = filename.split(';')[0];
          filename = filename.replace('filename=', '');
          filename = filename.replace('"', '');
          filename = filename.replace('"', '');
          const file = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          const fileURL = URL.createObjectURL(file);
          const a = document.createElement('a');
          a.href = fileURL;
          a.target = '_blank';
          a.download = filename;
          document.body.appendChild(a);
          a.click();
        });
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  beforeRouteUpdate(to, from, next) {
    if (to && to.query && to.query.page) {
      this.page = to.query.page;
    }
    next();
  },
  created() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
  },
};
</script>
