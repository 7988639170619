
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';

import students from '@/api/students';
import common from '@/api/common';

import { Action, Getter } from 'vuex-class';
import Loader from '@/components/common/Loader.vue';
import GratuationSlidebar from '@/components/students/studyRecords/courses/GratuationSlidebar.vue';
import ChangeModulesSlidebar from '@/components/students/studyRecords/courses/ChangeModulesSlidebar.vue';
import ChangeRequirementsSlidebar from '@/components/students/studyRecords/courses/ChangeRequirementsSlidebar.vue';
import ConnectModuleCourseSlidebar from '@/components/students/studyRecords/courses/ConnectModuleCourseSlidebar.vue';
import ChangeCertificateTextSlidebar from '@/components/students/studyRecords/courses/ChangeCertificateTextSlidebar.vue';

@Component({
  components: {
    Loader,
    GratuationSlidebar,
    ChangeModulesSlidebar,
    ChangeRequirementsSlidebar,
    ConnectModuleCourseSlidebar,
    ChangeCertificateTextSlidebar,
  },
})
export default class StudyRecordCourse extends Vue {
  @Action displayError!: (s: string) => void;
  @Action displaySuccess!: (s: string) => void;

  @Getter loggedInUserHasReadPermission: any;
  @Getter loggedInUserHasWritePermission: any;

  @Prop() studentId!: number;
  @Prop() courseId!: number;
  @Prop() item!: any;
  @Prop() slidebar!: boolean;

  saving = false;
  columns = [0, 1, 2];
  items: any[] = [];
  notCertificate: any[] = [];
  otherSchools: any[] = [];
  coursesOtherSchools: any[] = [];
  courseStats: any[][]  = [];
  worker = false;
  loading = {
    data: false,
    gratuations: false,
    student: false,
    notCertificate: false,
    otherSchools: false,
    coursesOtherSchools: false,
    worker: false,
  };
  selectedModule: any | null = '';
  gratuation: any | null = '';
  student: any | null = '';
  showGratuationSlidebar = false;
  showModuleSlidebar = false;
  showRequirementsSlidebar = false;
  showConnectSlidebar = false;
  showTextSlidebar = false;

  /**
   * Computed
   */
  get gratuationMarked() {
    return this.gratuation && this.gratuation.utskriftir_id !== undefined;
  }
  get gratuationFinished() {
    return (
      this.gratuation && this.gratuation.utskriftir_id !== undefined && this.gratuation.dags !== undefined && this.gratuation.dags !== null
    );
  }
  get courseFinished() {
    return this.item && this.item.braut_lokid === 1;
  }
  get nrModulesCertificate() {
    return this.item && this.item.fj_afangar_skirteini;
  }
  get canEdit() {
    return !this.slidebar && this.loggedInUserHasWritePermission('nemendahald_nemendur');
  }
  get canReadCourse() {
    return this.loggedInUserHasReadPermission('namskra_brautir');
  }
  /**
   * Methods
   */
  async loadData() {
    try {
      this.loading.data = true;
      const response = await students.getStudyRecordCourse(this.studentId, this.courseId);
      this.items = response.data;
    } catch (e) {
      this.$log.error(e);
      this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
    } finally {
      this.loading.data = false;
    }
  }
  async getStudentsStats() {
    try {
      this.loading.data = true;
      const params = {
        brautId: this.courseId
      }
      const response = await students.getStudentStatistics(this.studentId, params);
      this.courseStats = response.data;
    } catch (e) {
      this.$log.error(e);
      this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
    } finally {
      this.loading.data = false;
    }
  }

  async loadGraduations() {
    try {
      this.loading.gratuations = true;
      const response = await students.getStudentGraduations(this.studentId, { courseId: this.courseId });
      this.gratuation = response.data.items.length > 0 ? response.data.items[0] : '';
    } catch (e) {
      this.$log.error(e);
      this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
    } finally {
      this.loading.gratuations = false;
    }
  }

  async loadStudent() {
    try {
      this.loading.student = true;
      const response = await students.getStudentFewInfo(this.studentId);
      this.student = response.data;
    } catch (e) {
      this.$log.error(e);
      this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
    } finally {
      this.loading.student = false;
    }
  }
  async loadNotCertificate() {
    try {
      this.loading.notCertificate = true;
      const response = await students.getCourseModulesNotInCertificate(this.studentId, this.courseId);
      this.notCertificate = response.data.items;
    } catch (e) {
      this.$log.error(e);
      this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
    } finally {
      this.loading.notCertificate = false;
    }
  }

  async loadOtherSchools() {
    try {
      this.loading.otherSchools = true;
      const response = await students.getCourseModulesOtherSchools(this.studentId, this.courseId);
      this.otherSchools = response.data.items;
    } catch (e) {
      this.$log.error(e);
      this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
    } finally {
      this.loading.otherSchools = false;
    }
  }

  async loadCoursesOtherSchools() {
    try {
      this.loading.coursesOtherSchools = true;
      const response = await students.getCourseOtherSchools(this.studentId);
      this.coursesOtherSchools = response.data.items;
    } catch (e) {
      this.$log.error(e);
      this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
    } finally {
      this.loading.coursesOtherSchools = false;
    }
  }

  async saveRequirements() {
    try {
        const conf = confirm('Ertu viss um að þú viljir vista brautaruppbyggingu og áfanga á skírteini nemanda?'); // eslint-disable-line
      if (conf) {
        this.saving = true;
        await this.$studentsApi.changeCourseRequirements({
          changeCourseRequirementsInputItem: {
            studentId: this.studentId,
            courseId: this.courseId,
            studentCourseId: this.item.nemandi_brautir_id,
            update: 1,
          },
        });
        this.loadWorkerInProgress();
      }
    } catch (e) {
      this.$log.error(e);
      this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
    } finally {
      this.saving = false;
    }
  }

  getListForColumn(list: any, column: number) {
    const lines = Math.ceil(list.length / this.columns.length);
    const start = column * lines;
    const end = column * lines + lines - 1;
    return list.filter((x: any, idx: number) => idx >= start && idx <= end);
  }

  getNumber(num: number) {
    return [...Array(num && num > 0 ? num : 3).keys()];
  }

  // Ef þetta er þrepabraut en það er áfangi í vali sem er ekki á þrepi þá viljum við birta dálkinn
  showWithoutStep(course: any) {
    const count = course.uppbygging
      .map((x: any) => x.namsgreinar.map((y: any) => y.afangar.filter((z: any) => !z.trep).length).reduce((a: any, b: any) => a + b))
      .reduce((a: any, b: any) => a + b);

    return count > 0;
  }

  getSubjectSum(subject: any) {
    const sum = (subject.afangar || [])
      .filter((x: any) => x.stadid === 1 && x.einingar_skirteini)
      .map((x: any) => x.einingar_skirteini)
      .reduce((a: any, b: any) => a + b, 0);
    return sum;
  }

  getStepSum(step: any, structure: any) {
    const sum = structure
      .filter((x: any) => (x.afangar || []).length > 0)
      .map((x: any) =>
        x.afangar
          .filter((y: any) => y.stadid === 1 && y.einingar_skirteini)
          .filter(
            (y: any) =>
              (step === 0 && !(y.trep_skirteini !== undefined || (y.trep !== undefined && y.afangi_skirteini === undefined))) ||
              (step > 0 && (y.trep_skirteini === step || (y.trep === step && y.afangi_skirteini === undefined))),
          )
          .map((z: any) => z.einingar_skirteini)
          // .map((z: any) => ((step > 0 && z.trep === step) || (step === 0 && !z.trep) ? z.einingar_skirteini : 0))
          .reduce((a: any, b: any) => a + b, 0),
      )
      .reduce((a: any, b: any) => a + b, 0);
    return sum > 0 ? sum : '';
  }

  getStepSumCurrent(step: any, structure: any) {
    const sum = structure
      .filter((x: any) => (x.afangar || []).length > 0)
      .map((x: any) =>
        x.afangar
          .filter((y: any) => y.stada_afanga && y.stada_afanga === 1 && y.einingar_afangi)
          .map((z: any) => ((step > 0 && z.trep === step) || (step === 0 && !z.trep) ? z.einingar_afangi : 0))
          .reduce((a: any, b: any) => a + b, 0),
      )
      .reduce((a: any, b: any) => a + b, 0);
    return sum > 0 ? sum : '';
  }

  getStructureSum(type: any, structure: any) {
    let sum = 0;
    if (type === 0) {
      // Lokið
      sum = structure.namsgreinar
        .map((x: any) =>
          (x.afangar || [])
            .filter((y: any) => y.stadid === 1 && y.einingar_skirteini)
            .map((z: any) => z.einingar_skirteini)
            .reduce((a: any, b: any) => a + b, 0),
        )
        .reduce((a: any, b: any) => a + b, 0);
    } else if (type === 1) {
      // Heild
      sum =
        structure.uppbygging_tegund !== 'Kjarni'
          ? structure.uppbygging_einingar
          : structure.namsgreinar.map((x: any) => x.namsgrein_einingar).reduce((a: any, b: any) => a + b, 0);
    }

    return sum;
  }

  getStudyRecordColor(item: any) {
    if (item !== undefined) {
      if (item.ovirk === 1) {
        return 'studentStudyRecordOvirk';
      }
      if (item === 40) {
        // Áætlun
        return 'studentStudyRecordAetlun';
      }
      if (item === 41) {
        // Staðfest val
        return 'studentStudyRecordAetlun';
      }
      if (item === 1) {
        // Ólokið
        return 'studentStudyRecordOlokid';
      }
      if (item === 2 || item === 3 || item === 8) {
        // Fall + Fall á önn + Mat Fall
        return 'studentStudyRecordFall';
      }
      if (item === 4 || item === 6) {
        // Metið + Lokið
        return 'studentStudyRecordLokid';
      }
      if (item === 9 || item === 181) {
        // Lokið án eininga
        return 'studentStudyRecordLokidAnEininga';
      }
    }
    return '';
  }

  getPopover(module: any) {
    let text = '<div style="color:#676867">';

    if (module.afangi_braut) {
      text += `<div><b>Brautarkrafa: </b>${module.afangi_braut}</div>`;
    }
    if (module.trep_skirteini) {
      text += `<div><b>Þrep á skírteini: </b>${module.trep_skirteini}</div>`;
    }
    if (module.einingar_skirteini) {
      text += `<div><b>Einingar á skírteini: </b>${module.einingar_skirteini}</div>`;
    }
    if (module.stada_afanga_heiti && module.ovirk !== 1) {
      text += `<div><b>Staða: </b>${module.stada_afanga_heiti}</div>`;
    } else if (module.ovirk === 1) {
      text += '<div><b>Staða: </b> Brautarkrafa óvirk </div>';
    } else {
      text += '<div><b>Staða: </b>Ekki í ferli</div>';
    }
    if (module.onn_eink) {
      text += `<div><b>Önn: </b>${module.onn_eink}</div>`;
    }
    if (module.afangi_ferill && module.afangi_skriteini !== module.afangi_ferill) {
      text += `<div><b>Áfangi á ferli: </b>${module.afangi_ferill}</div>`;
    }
    if (module.afangi_ferill && module.trep !== module.trep_skirteini) {
      text += `<div><b>Þrep áfanga á ferli: </b>${module.trep}</div>`;
    }
    if (module.einingar_ferill && module.einingar_ferill !== module.einingar_afangi) {
      text += `<div><b>Einingar áfanga á ferli: </b>${module.einingar_ferill}</div>`;
    }
    if (module.einkunn) {
      text += `<div><b>Einkunn: </b>${module.einkunn}</div>`;
    }

    if (module.skoli_audk) {
      text += `<div><b>Áfangi frá: </b>${module.skoli_audk}</div>`;
    }

    text += '</div>';

    return text;
  }

  openGratuationSlidebar() {
    this.showGratuationSlidebar = true;
    this.showModuleSlidebar = false;
    this.showRequirementsSlidebar = false;
    this.showConnectSlidebar = false;
    this.showTextSlidebar = false;
  }

  openModuleSlidebar() {
    this.showModuleSlidebar = true;
    this.showGratuationSlidebar = false;
    this.showRequirementsSlidebar = false;
    this.showConnectSlidebar = false;
    this.showTextSlidebar = false;
  }

  openRequirementsSlidebar() {
    this.showRequirementsSlidebar = true;
    this.showGratuationSlidebar = false;
    this.showModuleSlidebar = false;
    this.showConnectSlidebar = false;
    this.showTextSlidebar = false;
  }

  openConnectSlidbar(module: any) {
    this.selectedModule = module;
    this.showConnectSlidebar = true;
    this.showModuleSlidebar = false;
    this.showGratuationSlidebar = false;
    this.showRequirementsSlidebar = false;
    this.showTextSlidebar = false;
  }

  openTextSlidebar() {
    this.showTextSlidebar = true;
    this.showConnectSlidebar = false;
    this.showModuleSlidebar = false;
    this.showGratuationSlidebar = false;
    this.showRequirementsSlidebar = false;
  }

  closeSlidebar() {
    this.selectedModule = '';
    this.showGratuationSlidebar = false;
    this.showModuleSlidebar = false;
    this.showRequirementsSlidebar = false;
    this.showConnectSlidebar = false;
    this.showTextSlidebar = false;
  }

  closeAndLoadWorkersSlidebar(item: any) {
    this.closeSlidebar();
    this.loadWorkerInProgress();
    if (item && item.disableStudent) {
      this.$emit('reloadStudent');
    }
  }

  closeAndReloadSlidebar() {
    this.closeSlidebar();
    this.$emit('reload');
  }

  async loadWorkerInProgress() {
    try {
      this.loading.worker = true;
      const response = await common.getWorkersInProgress('breyta_brautakrofu');
      this.worker = response.data.items.filter((x: any) => x.reference_id === this.item.nemandi_brautir_id).length > 0;
    } catch (e) {
      this.$log.error(e);
      this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
    } finally {
      this.loading.worker = false;
    }
  }

  reloadData() {
    this.loadData();
    this.getStudentsStats();
    this.loadNotCertificate();
    this.loadOtherSchools();
    this.loadGraduations();
    this.$emit('reload');
  }

  onBreytaBrautarkrofuComplete(data: { id: number }) {
    this.loadWorkerInProgress();
    if (data.id === this.item.nemandi_brautir_id) {
      this.reloadData();
    }
  }

  onBreytaBrautarkrofuFailure(data: { id: number }) {
    this.loadWorkerInProgress();
    if (data.id === this.item.nemandi_brautir_id) {
      this.displayError(`Villa kom upp við að breyta brautarkröfu. Vinsamlegast reyndu aftur.`);
      this.reloadData();
    }
  }

  onBreytaBrautarkrofuStarted() {
    this.loadWorkerInProgress();
  }

  @Watch('courseId')
  onPropertyChanged() {
    this.loadData();
    this.getStudentsStats();
    this.loadGraduations();
    this.loadNotCertificate();
    this.loadOtherSchools();
    this.loadCoursesOtherSchools();
    this.loadWorkerInProgress();
  }

  created() {
    this.loadStudent();
    this.loadData();
    this.getStudentsStats();
    this.loadGraduations();
    this.loadNotCertificate();
    this.loadOtherSchools();
    this.loadCoursesOtherSchools();
    this.loadWorkerInProgress();
  }

  mounted() {
    this.$socket.emit('joinBreytaBrautarkrofu');
    this.sockets.subscribe('breytaBrautarkrofuStarted', this.onBreytaBrautarkrofuStarted);
    this.sockets.subscribe('breytaBrautarkrofuComplete', this.onBreytaBrautarkrofuComplete);
    this.sockets.subscribe('breytaBrautarkrofuFailure', this.onBreytaBrautarkrofuFailure);
  }

  beforeDestroy() {
    this.$socket.emit('leaveBreytaBrautarkrofur');
    this.sockets.unsubscribe('breytaBrautarkrofuStarted');
    this.sockets.unsubscribe('breytaBrautarkrofuComplete');
    this.sockets.unsubscribe('breytaBrautarkrofuFailure');
  }
}
