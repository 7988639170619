<template>
  <Slidebar :large="true" @closeMe="closeSlidebar">
    <h4 v-if="type === 1">Samþykkja brautaskiptabeiðni</h4>
    <h4 v-else>Hafna brautarskiptabeiðni</h4>
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <h5 class="mt-4 mb-3">{{ item.nafn }} - {{ item.kennitala }}</h5>
      <div><strong>Núverandi braut:</strong> {{ item.current_course }}</div>
      <div><strong>Umbeðin braut:</strong> {{ item.change_course }}</div>
      <br />
      <div v-if="type === 1">
        <b-form-group
          label="Staðfest braut"
          label-for="course"
          :state="submitted && errors.has('course') ? false : ''"
          :invalid-feedback="errors.first('course')"
        >
          <v-select
            id="course"
            name="course"
            v-model="form.course"
            :state="submitted && errors.has('course') ? false : ''"
            v-validate="'required'"
            :clearable="false"
            :options="courses"
          >
          </v-select>
        </b-form-group>
        <b-row>
          <b-col class="pl-0">
            <b-form-group
              label="Endadagsetning núverandi brautar"
              label-for="dateEnd"
              :state="submitted && errors.has('dateEnd') ? false : ''"
              :invalid-feedback="errors.first('dateEnd')"
            >
              <datepicker
                id="dateEnd"
                name="dateEnd"
                v-model="form.date_end"
                :language="lang"
                :monday-first="true"
                format="dd.MM.yyyy"
                :typeable="false"
                ref="dateEndOpenPickr"
              ></datepicker>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Byrjunardagsetning umbeðinnar brautar"
              label-for="dateStart"
              :state="submitted && errors.has('dateStart') ? false : ''"
              :invalid-feedback="errors.first('dateStart')"
            >
              <datepicker
                id="dateStart"
                name="dateStart"
                v-model="form.date_start"
                :language="lang"
                :monday-first="true"
                format="dd.MM.yyyy"
                :typeable="false"
                ref="dateStartOpenPickr"
              ></datepicker>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group label="Sjálfvirkt val" label-for="automaticSelection">
          <b-form-checkbox v-model="form.automaticSelection" />
        </b-form-group>
      </div>
      <div v-else>
        <h4>Ertu viss um að þú viljir hafna þessari brautaskiptabeiðni?</h4>
      </div>
      <b-form-group label="Athugasemd" label-for="comment">
        <b-form-textarea v-model="form.comment" class="w-100" />
      </b-form-group>
      <br />
    </b-form>
    <div class="mb-3" v-if="saving"><Loader /> Augnablik.. Verið er að vista.</div>
    <b-btn variant="primary" @click="validateBeforeSubmit()" :disabled="saving">
      <span v-if="type === 1">Samþykkja</span>
      <span v-else>Hafna</span>
    </b-btn>
  </Slidebar>
</template>

<script>
import curriculum from '@/api/curriculum';
// import requests from '@/api/requests';

import Slidebar from '@/components/common/Slidebar.vue';
import Loader from '@/components/common/Loader.vue';
import { mapActions } from 'vuex';
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';

export default {
  name: 'process-request-slidebar',
  props: ['item', 'type'],
  components: {
    Slidebar,
    Datepicker,
    Loader,
  },
  data() {
    return {
      loading: false,
      submitted: false,
      courses: [],
      form: {
        course: '',
        comment: '',
        date_start: moment().toDate(),
        date_end: moment().add(-1, 'days').toDate(),
        automaticSelection: true,
      },
      lang: is,
      saving: false,
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });
        // Engar villur í formi.
        try {
          const data = {
            schoolComment: this.form.comment,
            status: this.type,
          };
          if (this.type === 1) {
            data.startDate = moment(this.form.date_start).format('YYYY-MM-DD');
            data.closeDate = moment(this.form.date_end).format('YYYY-MM-DD');
            data.confirmedCourseId = this.form.course.braut_id;
            data.sjalfvirktVal = this.form.automaticSelection ? 1 : 0;
          }
          this.saving = true;
          await this.$requestsApi.updateChangeCourseRequest({
            requestId: this.item.course_change_id,
            updateChangeCourseRequestInputItem: {
              ...data,
            },
          });
          if (this.type === 1) {
            this.displaySuccess('Samþykkt brautaskiptabeiðni sett í vinnslu.');
          } else {
            this.displaySuccess('Höfnun brautaskiptabeiðni sett í vinnslu.');
          }
          this.$emit('closeSlidebar');
          // this.$emit('closeAndReload');
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.saving = false;
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      }
    },
    async loadCourses() {
      try {
        this.loading = true;
        const response = await curriculum.getCourseList();
        this.courses = response.data.items.map(x => ({
          ...x,
          label: `${x.braut_kodi} ${x.heiti}`,
        }));
        if (this.item && this.item.change_course_id && this.courses.find(x => x.braut_id === this.item.change_course_id)) {
          this.form.course = this.courses.find(x => x.braut_id === this.item.change_course_id);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.form.comment = this.item.commentfromschool;
    this.loadCourses();
  },
};
</script>

<style lang="scss"></style>
