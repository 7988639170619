<template>
  <div class="pt-3">
    <div class="back-to-category" @click="goBack">
      <i class="fa fa-angle-left back-icon" aria-hidden="true"></i>
      <a class="back-text">
        {{ item.name }}
      </a>
    </div>
    <div v-for="(item, itemIndex) in getItems" class="nav-item-category" :key="itemIndex">
      <div class="nav-category-divider" v-if="itemIndex === 0"></div>
      <b-link :to="{ name: item.route }" style="text-decoration: none">
        <div class="nav-item" :class="{ 'nav-selected': isSelectedRoute(item) }">
          <div class="nav-item-name">
            {{ item.name }}
            <div class="nav-item-arrow" v-if="isSelectedRoute(item)" />
          </div>
        </div>
      </b-link>
      <div class="nav-item-divider"></div>
    </div>
  </div>
</template>

<script>
import auth from '@/api/auth';
import { mapGetters } from 'vuex';

export default {
  name: 'sidebar-level2',
  props: ['item', 'c'],
  computed: {
    getItems() {
      return this.item && this.item.items
        ? this.item.items.filter(x => this.item.is_user || this.loggedInUserHasReadPermission(x.access))
        : [];
    },
    ...mapGetters(['loggedInUserHasReadPermission']),
  },
  methods: {
    logout() {
      auth.logout();
    },
    goBack() {
      this.$emit('clear');
    },
    isSelectedRoute(item) {
      return item.route === this.$route.name;
    },
  },
};
</script>

<style lang="scss">
.back-to-category {
  height: 39px;
  width: 300px;
  background-color: rgba(0, 0, 0, 0.1);
  padding-top: 5px;
  .back-icon {
    margin-left: 20px;
  }
  .back-text {
    color: #fff;
    height: 18px;
    width: 53px;
    font-size: 12px;
    font-weight: bold;
    line-height: 18px;
    padding-left: 5px;
    cursor: pointer;
    text-transform: uppercase;
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
}

.nav-item-category {
  text-align: left;
  cursor: pointer;
  .nav-item {
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
    width: 300px;
    padding-left: 20px;
    padding-top: 15px; // 18px;
    padding-bottom: 14px; // 17px;
    .nav-item-name {
      height: 24px;
      font-size: 16px;
      line-height: 24px;
      display: inline-block;
      padding-left: 10px;
      color: #fff;
      a {
        color: #fff;
      }
    }

    &.nav-selected {
      background-color: rgba(0, 0, 0, 0.2);
      .nav-item-arrow {
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 6px solid #fff;
        position: absolute;
        right: 0;
        margin-top: -20px;
      }
    }
  }
  .nav-item-divider {
    height: 1px;
    width: 300px;
    opacity: 0.15;
    background-color: #ffffff;
  }
}
</style>
