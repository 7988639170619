<template>
  <div>
    <b-row>
      <b-col class="pl-0 mb-3">
        <div>
          Undanfari er áfangi sem nemandi þarf að hafa lokið til að geta tekið áfangann, ef fleiri en einn áfangi er skilgreindur sem
          undanfari þá þarf nemandinn
        </div>
        <div>
          að hafa lokið einum af þeim sem eru skráðir. Ef tveir áfangar eru skráðir á sömu reglu þá þarf að ljúka þeim báðum til að geta
          tekið áfangann.
        </div>
      </b-col>
      <b-col cols="2">
        <b-btn variant="primary" size="sm" class="float-right mb-2" v-if="canEdit" @click="openEditSlidebar()">Bæta við undanfara</b-btn>
      </b-col>
    </b-row>

    <table class="table less-padding" style="font-size: 13px">
      <thead>
        <tr>
          <th>Regla</th>
          <th>Undirskóli</th>
          <th>Áfangi</th>
          <th>Heiti áfanga</th>
          <th>
            Á sömu önn
            <i class="fa fa-info-circle" title="Má nemandi vera í undanfara áfanga á sömu önn og hann tekur áfangann" v-b-tooltip></i>
          </th>
          <th></th>
        </tr>
      </thead>
      <TableRowLoader v-if="loading.list" colspan="7" :center="true" />
      <TableRowEmpty v-if="!loading.list && this.list.length === 0" colspan="7" :center="true" />

      <tr v-for="(item, idx) in list" :key="idx">
        <td>{{ item.kedja_stutt }}</td>
        <td>{{ item.undirsk_heiti }}</td>
        <td>
          <b-link v-if="canEdit" @click="openEditSlidebar(item)">
            {{ item.namsgrein_afanganr }}
          </b-link>
          <span v-else>
            {{ item.namsgrein_afanganr }}
          </span>
        </td>
        <td>{{ item.afanga_heiti }}</td>
        <td><yes-no :item="item.sama_onn"></yes-no></td>
        <td>
          <b-btn variant="danger" class="btn-xs float-right" v-if="canEdit" @click="deletePrecursor(item)"
            ><i class="fa fa-trash" v-b-tooltip.hover title="Eyða undanfara"></i
          ></b-btn>
        </td>
      </tr>
    </table>
    <Loader v-if="loading.graph"></Loader>
    <GChart
      type="OrgChart"
      :data="chartData"
      :options="chartOptions"
      :settings="{ packages: ['orgchart'] }"
      @ready="onChartReady"
      v-if="graph.length > 0"
      ref="gChart"
    ></GChart>
    <div class="mt-4 mb-2">Áfangar sem eru með þennan áfanga skráðan sem undanfara.</div>
    <table class="table less-padding" style="font-size: 13px">
      <thead>
        <tr>
          <th>Undirskóli</th>
          <th>Áfangi</th>
          <th>Heiti áfanga</th>
        </tr>
      </thead>
      <TableRowLoader v-if="loading.all" colspan="4" :center="true" />
      <TableRowEmpty v-if="!loading.all && all.length === 0" colspan="4" :center="true" />
      <tr v-for="(item, idx) in all" :key="idx">
        <td>{{ item.undirsk_heiti }}</td>
        <td>
          <b-link :to="{ name: 'Module', params: { id: item.afangi_id } }" target="_blank">{{ item.namsgrein_afanganr }}</b-link>
        </td>
        <td>{{ item.afanga_heiti }}</td>
      </tr>
    </table>
    <module-precursor-slidebar
      v-if="showEditSlidebar"
      :id="id"
      :item="selectedItem"
      @closeSlidebar="closeSlidebar()"
    ></module-precursor-slidebar>
  </div>
</template>

<script>
import curriculum from '@/api/curriculum';
import { mapGetters, mapActions } from 'vuex';
import { GChart } from 'vue-google-charts';

import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import Loader from '@/components/common/Loader.vue';
import YesNo from '@/components/common/YesNo.vue';

import ModulePrecursorSlidebar from '@/components/curriculum/modules/ModulePrecursorSlidebar.vue';

export default {
  name: 'module-precursor',
  components: {
    GChart,
    TableRowLoader,
    TableRowEmpty,
    Loader,
    YesNo,
    ModulePrecursorSlidebar,
  },
  props: ['id'],
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_afangar');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('namskra_afangar');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      loading: {
        list: false,
        all: false,
        graph: false,
      },
      list: [],
      all: [],
      graph: [],
      chartData: null,
      chartOptions: {
        allowHtml: true,
        nodeClass: 'GradeRuleNodeClass',
        selectedNodeClass: 'GradeRuleSelectedNodeClass',
      },
      selectedItem: {},
      showEditSlidebar: false,
    };
  },
  methods: {
    closeSlidebar() {
      this.selectedItem = {};
      this.showEditSlidebar = false;
      this.loadList();
      this.loadGraph();
    },
    openEditSlidebar(item) {
      this.selectedItem = item || {};
      this.showEditSlidebar = true;
    },
    async deletePrecursor(item) {
      const conf = confirm(`Ert þú viss um að eyða undanfaranum ${item.namsgrein_afanganr}?`); // eslint-disable-line
      if (conf) {
        try {
          await curriculum.setModulePrecursor(this.id, { afangi_id: item.afangi_id, delete: 1 });
          this.displaySuccess('Undanfara eytt.');

          this.loadList();
          this.loadGraph();
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    async loadList() {
      this.loading.list = true;
      try {
        const response = await curriculum.getModulePrecursorList(this.id);
        this.list = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.list = false;
      }
    },
    async loadAll() {
      this.loading.all = true;
      try {
        const response = await curriculum.getModulePrecursorAllList(this.id);
        this.all = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.all = false;
      }
    },
    async loadGraph() {
      this.loading.graph = true;
      this.graph = [];
      try {
        const response = await curriculum.getModulePrecursorListGraph(this.id);
        this.graph = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.graph = false;
      }
    },
    onChartReady(chart, google) {
      this.createDataTable(google);
    },
    createDataTable(google) {
      const data = new google.visualization.DataTable();
      data.addColumn('string', 'Name');
      data.addColumn('string', 'Manager');
      data.addRows(this.graph.length);
      for (let i = 0; i < this.graph.length; i += 1) {
        const item = this.graph[i];
        const id = item.id_child ? item.id_child : '';
        const name = item.regla ? `<span style="color:#aaa; font-style: italic">${item.regla}</span>` : item.namsgrein_afanganr;
        const parent = item.id_parent ? item.id_parent : '';

        data.setCell(i, 0, id, name);
        data.setCell(i, 1, parent);
      }
      this.chartData = data;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadList();
    this.loadGraph();
    this.loadAll();
  },
};
</script>
<style lang="scss"></style>
