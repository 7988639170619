<template>
  <Slidebar @closeMe="closeSlidebar" :large="true">
    <div class="d-inline-block mt-2">
      <h4>Magnbreyta völdum námsgreinum</h4>

      <b-form-group label="Virk" label-for="virkur">
        <b-form-checkbox v-model="form.virkur"></b-form-checkbox>
      </b-form-group>
      <b-btn variant="primary" class="mr-2" v-if="canEdit" @click="validateBeforeSubmit"> Breyta </b-btn>
      <b-btn variant="danger" class="float-right" v-if="canEdit" @click="deleteSubjects"> Eyða </b-btn>
      <div class="mt-3">
        <strong>Færslur sem verið er að breyta</strong>
      </div>

      <div class="text text-danger" v-if="items.find(x => x.has_modules === 1)">
        Ath! Búið er að tengja einhverjar af völdum námsgreinum við áfanga. Ekki er hægt að eyða þeim..
      </div>
      <div v-for="item in items" :key="item.namsgrein_id">
        {{ item.kodi }} - {{ item.heiti }}

        <i class="fa fa-exclamation-triangle text-danger" v-b-tooltip title="Áfangar fundust" v-if="item.has_modules === 1"></i>
      </div>
    </div>
  </Slidebar>
</template>

<script>
import curriculum from '@/api/curriculum';

import { mapActions, mapGetters } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';

export default {
  name: 'subject-mass-edit-slidebar',
  props: ['id', 'items'],
  components: {
    Slidebar,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_namsgreinar');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {
      saving: false,
      loading: false,
      form: {
        virkur: true,
      },
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async validateBeforeSubmit() {
      try {
        this.saving = true;
        const promises = [];
        this.items.forEach(i => {
          const item = {
            ...i,
            virkur: this.form.virkur ? 1 : 0,
          };
          promises.push(curriculum.updateSubject(i.namsgrein_id, item));
        });

        Promise.all(promises).then(() => {
          this.displaySuccess('Námsgreinum hefur verið breytt');
          this.$emit('closeAndReloadSlidebar');
        });
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.saving = false;
      }
    },
    async deleteSubjects() {
      try {
        const conf = confirm(`Ert þú viss um að eyða ${this.items.length} námsgreinum ? Ath. eingöngu verður eytt námsgreinum sem ekki er búið að tengja við áfanga.`); // eslint-disable-line
        if (conf) {
          this.saving = true;
          const promises = [];
          this.items
            .filter(x => x.has_modules === 0)
            .forEach(i => {
              promises.push(curriculum.updateSubject(i.namsgrein_id, { delete: 1 }));
            });

          Promise.all(promises).then(() => {
            this.displaySuccess('Námsgreinum hefur verið eytt');
            this.$emit('closeAndReloadSlidebar');
          });
        }
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.saving = false;
      }
    },
    initialize() {
      this.form = {
        virkur: true,
      };
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.initialize();
  },
};
</script>

<style lang="scss"></style>
