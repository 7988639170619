import Vue from 'vue';

const API_URL = '/api/structure';

export default {
  getDepartmentList() {
    return Vue.http
      .get(`${API_URL}/department/`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getDepartmentDetail(id) {
    return Vue.http
      .get(`${API_URL}/department/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createDepartment(data) {
    return Vue.http
      .post(`${API_URL}/department/`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateDepartment(id, data) {
    return Vue.http
      .post(`${API_URL}/department/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  setDepartmentModule(data) {
    return Vue.http
      .post(`${API_URL}/department-module`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  setDepartmentCourse(data) {
    return Vue.http
      .post(`${API_URL}/department-course`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getDivisionList() {
    return Vue.http
      .get(`${API_URL}/division/`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getDivisionDetail(id) {
    return Vue.http
      .get(`${API_URL}/division/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createDivision(data) {
    return Vue.http
      .post(`${API_URL}/division/`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateDivision(id, data) {
    return Vue.http
      .post(`${API_URL}/division/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getBuildingList() {
    return Vue.http
      .get(`${API_URL}/building/`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getBuildingDetail(id) {
    return Vue.http
      .get(`${API_URL}/building/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createBuilding(data) {
    return Vue.http
      .post(`${API_URL}/building/`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateBuilding(id, data) {
    return Vue.http
      .post(`${API_URL}/building/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getClassroomList(params) {
    return Vue.http
      .get(`${API_URL}/classroom/`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getClassroomDetail(id) {
    return Vue.http
      .get(`${API_URL}/classroom/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createClassroom(data) {
    return Vue.http
      .post(`${API_URL}/classroom/`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateClassroom(id, data) {
    return Vue.http
      .post(`${API_URL}/classroom/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  checkClassroomCodeExists(params) {
    return Vue.http
      .get(`${API_URL}/classroom-exists`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getEquipmentList() {
    return Vue.http
      .get(`${API_URL}/equipment/`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getEquipmentDetail(id) {
    return Vue.http
      .get(`${API_URL}/equipment/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createEquipment(data) {
    return Vue.http
      .post(`${API_URL}/equipment/`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateEquipment(id, data) {
    return Vue.http
      .post(`${API_URL}/equipment/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
};
