<template>
  <Slidebar :autoClose="false" @closeMe="closeSlidebar" :large="type === 'addGrades'">
    <h4 v-if="creating">Stofna einkunnareglu</h4>
    <h4 v-else>{{ item.heiti }}</h4>
    <b-form-radio-group id="editType" v-model="type" name="orderBy" class="d-inline-block" v-if="!creating">
      <b-form-radio value="edit">Breyta</b-form-radio>
      <b-form-radio value="addGrades">Stofna undireinkunnir</b-form-radio>
    </b-form-radio-group>
    <div v-if="type === 'edit'">
      <b-form @submit.prevent="editItem" novalidate class="mt-2">
        <b-form-group
          :state="submitted && errors.has('tegund_einkunnar') ? false : ''"
          :invalid-feedback="errors.first('tegund_einkunnar')"
          v-if="!isGroup"
        >
          <label label-for="tegund_einkunnar">
            Tegund einkunnar
            <b-btn
              class="btn-xs"
              v-if="canEditStructure"
              variant="primary"
              style="font-size: 13px; margin-top: -10px"
              @click="openGradeTypeSlidebar()"
              ><i class="fa fa-plus-circle"></i>
            </b-btn>
          </label>
          <v-select
            id="tegund_einkunnar"
            name="tegund_einkunnar"
            v-model="form.tegund_einkunnar"
            :options="gradeTypes"
            label="heiti"
            :clearable="false"
            style="width: 300px"
            :state="submitted && errors.has('tegund_einkunnar') ? false : ''"
            v-validate="'required'"
          >
          </v-select>
        </b-form-group>
        <b-form-group
          label="Heiti"
          label-for="heiti"
          :state="submitted && errors.has('heiti') ? false : ''"
          :invalid-feedback="errors.first('heiti')"
          v-if="isGroup && form.lokaeinkunn === 0"
        >
          <b-form-input
            id="heiti"
            name="heiti"
            v-model="form.heiti"
            :state="submitted && errors.has('heiti') ? false : ''"
            data-vv-as="heiti"
            maxlength="200"
            style="width: 300px"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group :state="submitted && errors.has('reiknud_skrad') ? false : ''" :invalid-feedback="errors.first('reiknud_skrad')"
          ><label for="reiknud_skrad">
            Tegund
            <i
              class="fa fa-fw fa-info-circle"
              v-b-tooltip.hover.html
              title="Reiknuð: Þá eru margar undireinkunnir sem reiknast upp í.<br/> Skráð: Þá er hægt að skrá einkunn beint á einkunnaþátt."
            ></i>
          </label>
          <v-select
            id="reiknud_skrad"
            name="reiknud_skrad"
            v-model="form.reiknud_skrad"
            :options="types"
            :clearable="false"
            style="width: 300px"
            :state="submitted && errors.has('reiknud_skrad') ? false : ''"
            v-validate="'required'"
          >
          </v-select>
        </b-form-group>
        <b-form-group
          label="Vegur upp í"
          label-for="vegur_upp_i"
          :state="submitted && errors.has('vegur_upp_i') ? false : ''"
          :invalid-feedback="errors.first('vegur_upp_i')"
          v-if="!creating && ((!isGroup && (form.lokaeinkunn.id === 0 || finalGradeAlreadyUsed)) || form.lokaeinkunn === 0)"
        >
          <v-select
            id="vegur_upp_i"
            name="vegur_upp_i"
            v-model="form.vegur_upp_i"
            :options="gradeTypeParents"
            label="heiti"
            :clearable="isGroup ? false : true"
            style="width: 300px"
            :state="submitted && errors.has('vegur_upp_i') ? false : ''"
            v-validate="isGroup ? 'required' : ''"
          >
          </v-select>
        </b-form-group>
        <b-form-group
          label-for="vaegi"
          :state="submitted && errors.has('vaegi') ? false : ''"
          :invalid-feedback="errors.first('vaegi')"
          label="Vægi"
          v-if="(!isGroup && form.lokaeinkunn.id === 0) || form.lokaeinkunn === 0"
        >
          <b-form-input
            id="vaegi"
            name="vaegi"
            v-model="form.vaegi"
            :state="submitted && errors.has('vaegi') ? false : ''"
            data-vv-as="vægi"
            style="width: 80px"
            v-validate="{ regex: /^[0-9.,]*$/ }"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group :state="submitted && errors.has('skali') ? false : ''" :invalid-feedback="errors.first('skali')" v-if="!isGroup">
          <label label-for="skali">
            Skali
            <b-btn
              class="btn-xs"
              variant="primary"
              style="font-size: 13px; margin-top: -10px"
              v-if="form.skali && canEditStructure"
              @click="openGradeScaleSlidebar()"
              ><i class="fa fa-search"></i>
            </b-btn>
          </label>
          <v-select
            id="skali"
            name="skali"
            v-model="form.skali"
            :options="gradeScales"
            label="heiti"
            :clearable="false"
            style="width: 300px"
            :state="submitted && errors.has('skali') ? false : ''"
          >
          </v-select>
        </b-form-group>
        <b-form-group
          :state="submitted && errors.has('fj_bestu_undirpr') ? false : ''"
          :invalid-feedback="errors.first('fj_bestu_undirpr')"
        >
          <label label-for="fj_bestu_undirpr">
            Fj. bestu undirprófa
            <i class="fa fa-fw fa-info-circle" v-b-tooltip.hover title="Dæmi þá gilda 4 hæstu einkunnirnar af 5 mögulegum."></i>
          </label>
          <b-form-input
            id="fj_bestu_undirpr"
            name="fj_bestu_undirpr"
            type="number"
            min="1"
            v-model="form.fj_bestu_undirpr"
            :state="submitted && errors.has('fj_bestu_undirpr') ? false : ''"
            data-vv-as="fj. bestu undirprófa"
            style="width: 80px"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          label="Fall í lokaeinkunn"
          label-for="fall_leyft"
          :state="submitted && errors.has('fall_leyft') ? false : ''"
          :invalid-feedback="errors.first('fall_leyft')"
          v-if="!isGroup"
        >
          <v-select
            id="fall_leyft"
            name="fall_leyft"
            v-model="form.fall_leyft"
            :options="yesNo"
            :clearable="false"
            style="width: 100px"
            :state="submitted && errors.has('fall_leyft') ? false : ''"
          >
          </v-select>
        </b-form-group>
        <b-form-group
          label="Lokaeinkunn (flutt í feril)"
          label-for="lokaeinkunn"
          :state="(submitted && errors.has('lokaeinkunn')) || finalGradeAlreadyUsed ? false : ''"
          :invalid-feedback="errors.first('lokaeinkunn')"
          v-if="!isGroup"
        >
          <v-select
            id="lokaeinkunn"
            name="lokaeinkunn"
            v-model="form.lokaeinkunn"
            :options="yesNo"
            :clearable="false"
            style="width: 100px"
            :state="(submitted && errors.has('lokaeinkunn')) || finalGradeAlreadyUsed ? false : ''"
          >
          </v-select>
        </b-form-group>
        <div class="text-danger" style="font-weight: bold; font-size: 13px" v-if="finalGradeAlreadyUsed">
          Ath. það er nú þegar skráð lokaeinkunn. Ekki má hafa tvær tegundir merktar sem lokaeinkunn.
        </div>
        <b-form-group
          label-for="lagmarks_einkunn"
          :state="submitted && errors.has('lagmarks_einkunn') ? false : ''"
          :invalid-feedback="errors.first('lagmarks_einkunn')"
          label="Lágmarkseinkunn"
          v-if="isGroup"
        >
          <b-form-input
            id="lagmarks_einkunn"
            name="lagmarks_einkunn"
            v-model="form.lagmarks_einkunn"
            :state="submitted && errors.has('lagmarks_einkunn') ? false : ''"
            data-vv-as="lágmarkseinkunn"
            style="width: 80px"
            v-validate="{ regex: /^[0-9.,]*$/ }"
          >
          </b-form-input>
        </b-form-group>

        <b-form-group :state="submitted && errors.has('rod') ? false : ''" :invalid-feedback="errors.first('rod')" v-if="!isGroup">
          <label label-for="rod">
            Röð
            <i class="fa fa-fw fa-info-circle" v-b-tooltip.hover title="Þetta stýrir í hvaða röð einkunnir birtast í einkunnayfirliti."></i>
          </label>
          <b-form-input
            id="rod"
            name="rod"
            type="number"
            min="1"
            v-model="form.rod"
            :state="submitted && errors.has('rod') ? false : ''"
            data-vv-as="röð"
            style="width: 80px"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          :state="submitted && errors.has('athugasemd') ? false : ''"
          :invalid-feedback="errors.first('athugasemd')"
          v-if="!isGroup"
          ><label label-for="athugasemd">
            Athugasemd
            <i class="fa fa-fw fa-info-circle" v-b-tooltip.hover title="Þessi athugasemd birtist ekki nemendum"></i>
          </label>
          <b-form-input
            id="athugasemd"
            name="athugasemd"
            type="text"
            v-model="form.athugasemd"
            :state="submitted && errors.has('athugasemd') ? false : ''"
            data-vv-as="athugasemd"
            maxLength="100"
          >
          </b-form-input>
        </b-form-group>
        <br />
        <b-btn variant="primary" class="mr-2" v-if="canEdit" @click="editGraderule">
          <span v-if="creating">Stofna</span>
          <span v-else>Breyta</span>
        </b-btn>
        <b-btn variant="dark" @click="closeSlidebar">Hætta við</b-btn>
        <b-btn variant="danger" class="float-right" v-if="canEdit && !creating" @click="deleteGradeRule"> Eyða </b-btn>
      </b-form>
    </div>
    <GradeRuleCreateSubgrades
      v-if="type === 'addGrades'"
      :termModuleId="termModuleId"
      :groupId="groupId"
      :parentId="parentId"
      @done="closeAndReloadSlidebar"
      @cancel="closeSlidebar"
    ></GradeRuleCreateSubgrades>
    <grade-type-slidebar
      v-if="showGradeTypeSlidbar"
      @closeSlidebar="closeExtraSlidebar"
      @closeAndReloadSlidebar="closeAndReloadExtraSlidebar"
    >
    </grade-type-slidebar>
    <grade-scale-slidebar
      v-if="showGradeScaleSlidebar"
      :id="form.skali ? form.skali.teg_skala : ''"
      @closeSlidebar="closeExtraSlidebar"
    ></grade-scale-slidebar>
  </Slidebar>
</template>

<script>
import groups from '@/api/groups';
import types from '@/api/types';
import terms from '@/api/terms';
import { mapActions, mapGetters } from 'vuex';
import Slidebar from '@/components/common/Slidebar.vue';
import GradeRuleCreateSubgrades from '@/components/school/terms/graderules/GradeRuleCreateSubgrades.vue';
import GradeTypeSlidebar from '@/components/school/school/types/GradeTypeSlidebar.vue';
import GradeScaleSlidebar from '@/components/school/school/types/GradeScaleSlidebar.vue';

export default {
  name: 'graderule-edit-slidebar',
  components: {
    Slidebar,
    GradeRuleCreateSubgrades,
    GradeTypeSlidebar,
    GradeScaleSlidebar,
  },
  props: ['item', 'termModuleId', 'groupId', 'finalGradeId', 'defaultAddGrades'],
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_annir');
    },
    canEditStructure() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    finalGradeAlreadyUsed() {
      return (
        !this.isGroup &&
        this.form.lokaeinkunn &&
        this.form.lokaeinkunn.id === 1 &&
        this.finalGradeId &&
        this.item &&
        this.finalGradeId.einkunnir_afanga_id !== this.item.einkunnir_afanga_id
      );
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      creating: false,
      isGroup: false,
      type: 'edit',
      parentId: null,
      form: {
        tegund_einkunnar: null,
        heiti: null,
        reiknud_skrad: null,
        vegur_upp_i: null,
        vaegi: null,
        skali: null,
        fj_bestu_undirpr: null,
        fall_leyft: null,
        lokaeinkunn: null,
        rod: null,
        athugasemd: '',
        lagmarks_einkunn: null,
      },
      yesNo: [
        { id: 1, label: 'Já' },
        { id: 0, label: 'Nei' },
      ],
      types: [
        { id: 0, label: 'Reiknuð' },
        { id: 1, label: 'Skráð' },
      ],
      gradeScales: [],
      gradeTypeParents: [],
      gradeTypes: [],
      submitted: false,
      showGradeTypeSlidbar: false,
      showGradeScaleSlidebar: false,
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    closeAndReloadSlidebar() {
      this.$emit('closeAndReloadSlidebar');
    },
    initialize() {
      if (this.defaultAddGrades) {
        this.type = 'addGrades';
      } else {
        this.type = 'edit';
      }
      this.creating = !this.item;
      if (this.isGroup) {
        this.parentId = this.item ? this.item.einkunnir_hops_id : null;
        this.form.heiti = this.item ? this.item.heiti : '';
        this.form.lagmarks_einkunn = this.item ? this.item.lagmarks_einkunn : null;
        this.form.lokaeinkunn = this.item ? this.item.lokaeinkunn : 0;
        this.form.reiknud_skrad = this.item ? this.types.find(x => x.id === this.item.reiknud_skrad) : this.types[0];
        this.form.vaegi = this.item ? this.item.vaegi : null;
        this.form.fj_bestu_undirpr = this.item ? this.item.fj_bestu_undirpr : null;
      } else {
        this.parentId = this.item ? this.item.tegund_einkunnar_id : null;
        this.form.reiknud_skrad = this.item ? this.types.find(x => x.id === this.item.reiknud_skrad) : this.types[0];
        this.form.vaegi = this.item ? this.item.vaegi : null;
        this.form.fj_bestu_undirpr = this.item ? this.item.fj_bestu_undirpr : null;
        this.form.fall_leyft = this.item ? this.yesNo.find(x => x.id === this.item.fall_leyft) : this.yesNo[1];
        this.form.lokaeinkunn = this.item ? this.yesNo.find(x => x.id === this.item.lokaeinkunn) : this.yesNo[1];
        this.form.rod = this.item ? this.item.rod : null;
        this.form.athugasemd = this.item ? this.item.aths : null;
      }
    },
    async loadGradeScales() {
      try {
        this.loading = true;
        const response = await types.getGradeScaleTypeList();
        this.gradeScales = response.data.items;
        this.form.skali = this.item ? this.gradeScales.find(x => x.teg_skala === this.item.tegund_skala_id) : null;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadGradeTypesParents() {
      try {
        this.loading = true;
        const response = await types.getGradeList({
          vegurUppI: 1,
          annirAfangarId: this.termModuleId,
          einkunnirAfangaId: this.item ? this.item.einkunnir_afanga_id : null,
        });
        this.gradeTypeParents = response.data.items;
        this.form.vegur_upp_i = this.item ? this.gradeTypeParents.find(x => x.teg_eink === this.item.vegur_upp_i) : null;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadGradeTypes() {
      try {
        this.loading = true;
        const response = await types.getGradeList({
          tegund: 1,
          annirAfangarId: this.termModuleId,
          tegEink: this.item ? this.item.tegund_einkunnar_id : null,
        });
        this.gradeTypes = response.data.items;
        this.form.tegund_einkunnar = this.item ? this.gradeTypes.find(x => x.teg_eink === this.item.tegund_einkunnar_id) : null;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadGradeParents() {
      try {
        this.loading = true;
        const response = await groups.getGroupGradeRule(this.groupId);
        this.gradeTypeParents = response.data.items.filter(x => !this.item || x.einkunnir_hops_id !== this.item.einkunnir_hops_id);
        this.form.vegur_upp_i = this.item ? this.gradeTypeParents.find(x => x.einkunnir_hops_id === this.item.vegur_upp_i) : null;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async editGraderule() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response || this.finalGradeAlreadyUsed) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
          return false;
        });
        try {
          if (this.isGroup) {
            const data = {
              heiti: this.form.heiti,
              teg_einkunnar: this.form.reiknud_skrad.id,
              vegur_upp_i: this.form.vegur_upp_i ? this.form.vegur_upp_i.einkunnir_hops_id : null,
              vaegi: this.form.vaegi ? this.form.vaegi.toString().replace('.', ',') : null,
              fj_bestu_undirpr: this.form.fj_bestu_undirpr,
              lagmarkseinkunn: this.form.lagmarks_einkunn ? this.form.lagmarks_einkunn.toString().replace('.', ',') : null,
              lokaeinkunn: this.form.lokaeinkunn,
            };
            if (this.creating) {
              await groups.createGroupGradeRule(this.groupId, data);
              this.displaySuccess('Einkunnaregla hefur verið stofnuð.');
            } else {
              await groups.updateGroupGradeRule(this.groupId, this.item.einkunnir_hops_id, data);
              this.displaySuccess('Einkunnareglu hefur verið breytt.');
            }
          } else {
            const data = {
              tegund_einkunnar: this.form.tegund_einkunnar.teg_eink,
              reiknud_skrad: this.form.reiknud_skrad.id,
              vegur_upp_i: this.form.vegur_upp_i && this.form.lokaeinkunn.id === 0 ? this.form.vegur_upp_i.teg_eink : null,
              vaegi: this.form.vaegi ? this.form.vaegi.toString().replace('.', ',') : null,
              einkunnaskali: this.form.skali.teg_skala,
              fj_bestu_undirpr: this.form.fj_bestu_undirpr,
              fall_leyft: this.form.fall_leyft.id,
              lokaeinkunn: this.form.lokaeinkunn.id,
              rod: this.form.rod ? this.form.rod.toString().replace('.', ',') : '',
              aths: this.form.athugasemd,
            };
            if (this.creating) {
              await terms.createModuleGraderule(this.termModuleId, data);
              this.displaySuccess('Einkunnaregla hefur verið stofnuð.');
            } else {
              await terms.updateModuleGraderule(this.termModuleId, this.item.einkunnir_afanga_id, data);
              this.displaySuccess('Einkunnareglu hefur verið breytt.');
            }
          }

          this.closeAndReloadSlidebar();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.saving = false;
      }
    },
    async deleteGradeRule() {
      const id = this.isGroup ? this.item.einkunnir_hops_id : this.item.einkunnir_afanga_id;
      const type = this.isGroup ? 'group' : 'module';
      const exists = await terms.getGraderuleGradesExists(id, type);
      let message = `Ert þú viss um að eyða ${this.item.heiti}?`;
      if (exists && exists.data.grade_exists === 1) {
        message = `${message} \nATH! Búið er að skrá einkunnir á eftirfarandi reglu.\nÞær einkunnir munu eyðast og ekki hægt að endurheimta!!`; // eslint-disable-line
      }

      const conf = confirm(message); // eslint-disable-line
      if (conf) {
        try {
          if (this.isGroup) {
            await groups.updateGroupGradeRule(this.groupId, this.item.einkunnir_hops_id, { delete: 1 });
          } else {
            await terms.updateModuleGraderule(this.termModuleId, this.item.einkunnir_afanga_id, { delete: 1 });
          }
          this.displaySuccess('Einkunnareglu hefur verið eytt.');

          this.$emit('closeAndReloadSlidebar');
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    openGradeTypeSlidebar() {
      this.showGradeTypeSlidbar = true;
      this.showGradeScaleSlidebar = false;
    },
    openGradeScaleSlidebar() {
      this.showGradeTypeSlidbar = false;
      this.showGradeScaleSlidebar = true;
    },
    closeExtraSlidebar() {
      this.showGradeTypeSlidbar = false;
      this.showGradeScaleSlidebar = false;
    },
    closeAndReloadExtraSlidebar() {
      this.closeExtraSlidebar();
      this.loadGradeTypesParents();
      this.loadGradeTypes();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    item() {
      this.initialize();
    },
  },
  created() {
    if (this.groupId) {
      this.isGroup = true;
      this.initialize();
      this.loadGradeParents();
    } else {
      this.isGroup = false;
      this.initialize();
      this.loadGradeScales();
      this.loadGradeTypesParents();
      this.loadGradeTypes();
    }
  },
};
</script>

<style lang="scss"></style>
