<template>
  <Slidebar @closeMe="closeSlidebar" :large="true">
    <h4>Brautarkrafa</h4>
    <div class="alert alert-danger" v-if="gratuationFinished">
      <i class="fa fa-exclamation-triangle"></i>
      Ath. nemandinn er útskrifaður
    </div>
    <div class="mb-2">
      Ef búið er að breyta uppbyggingu brautar þ.e.a.s. bæta áföngum við eða fjarlægja þá þarf að uppfæra brautarkröfuna hjá nemendum ef
      breytingarnar eiga við hann.
    </div>
    <div>
      Það eru til fjórar leiðir til að uppfæra námsferilsáætlun, áfanga, brautarkröfu eða eyða. Farðu vel yfir hvaða leið á best við.
    </div>

    <b-form-radio-group id="type" v-model="type" name="type" stacked>
      <b-form-radio value="0" class="mb-3 mt-3">
        Uppfæra námsferilsáætlun samkvæmt brautarkröfu
        <div class="ml-3"><strong>Virkni:</strong> Áföngum sem eru í brautarkröfunni raðað niður á næsta/u kennslutímabil.</div>
        <div class="ml-3">
          <strong>Afleyðingar:</strong> Allir áfangar sem eru í óstaðfestu vali verður eytt og áfangar í brautarkröfu settir inn á næsta/u
          kennslutímabil. Ef nemandi er með ótengda áfanga í stöðunni "Lokið" eða "Metið" þá tengjast þeir áfangar við brautarkröfuna, ef
          þessir áfangar tilheyra ekki brautarkröfunni þá fara þeir sjálfkrafa undir "Val".
        </div>
      </b-form-radio>
      <b-form-radio value="1" class="mb-3"
        >Uppfæra áfanga á skírteini nemanda.
        <div class="ml-3">
          <strong>Virkni:</strong> Áfangar sem eru ekki tengdir við skírteinið og nemandi hefur lokið færast inn á skírteinið.
        </div>
      </b-form-radio>
      <b-form-radio value="2" class="mb-3"
        >Uppfæra brautarkröfu og áfanga á skírteini.
        <div class="ml-3"><strong>Virkni:</strong> Brautarkrafa uppfærð og áfangar sem við á færast inn á skírteinið.</div>
        <div class="ml-3">
          <strong>Afleyðingar:</strong> Ef búið er að meta/tengja áfanga úr öðrum skóla við áfanga sem var í gömlu brautaruppbyggingunni en
          er ekki í nýju þá dettur það út.
        </div>
      </b-form-radio>
      <b-form-radio value="3" class="mb-3"
        >Eyða brautarkröfu
        <div class="ml-3"><strong>Virkni:</strong> Eyðir brautarkröfu og aftengi áfanga af skírteini.</div>
      </b-form-radio>
    </b-form-radio-group>
    <b-btn variant="primary" class="mt-3" @click="saveRequirements()" :disabled="saving">
      <i class="fa fa-spin fa-spinner" v-if="saving"></i> Staðfesta
    </b-btn>
  </Slidebar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Slidebar from '@/components/common/Slidebar.vue';

export default {
  name: 'study-record-course-change-requiremens-slidebar',
  props: ['studentId', 'courseId', 'studentCourseId', 'gratuationFinished'],
  components: {
    Slidebar,
  },
  computed: {
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      type: '0',
      saving: false,
    };
  },
  methods: {
    async saveRequirements() {
      try {
        let conf = '';
        const item = {};
        switch (this.type) {
          case '0':
            conf = confirm('Ertu viss um að þú viljir uppfæra námsferilsáætlun skv. brautarlýsingu? \n\nATH. öllu óstaðfestu vali verður eytt og áfangar í brautarkröfu settir inn á feril!'); // eslint-disable-line
            item.update = 2;
            break;
          case '1':
            conf = confirm('Ertu viss um að þú viljir uppfæra áfanga á skírteini nemanda?'); // eslint-disable-line
            item.update = 1;
            break;
          case '2':
            conf = confirm('Ertu viss um að þú viljir uppfæra brautarkröfu og áfanga á skírteini nemanda?'); // eslint-disable-line
            item.update = 1;
            item.updateReq = 1;
            break;
          case '3':
            conf = confirm('Ertu viss um að þú viljir eyða brautarkröfu og aftengja áfanga af skírteini?'); // eslint-disable-line
            item.delete = 1;
            break;
          default:
            conf = confirm('Ertu viss um að þú viljir uppfæra áfanga á skírteini nemanda?'); // eslint-disable-line
            item.update = 1;
        }
        if (conf) {
          this.saving = true;
          await this.$studentsApi.changeCourseRequirements({
            changeCourseRequirementsInputItem: {
              studentId: this.studentId,
              courseId: this.courseId,
              studentCourseId: this.studentCourseId,
              ...item,
            },
          });

          this.$emit('closeAndReloadSlidebar');
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.saving = false;
      }
    },
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>

<style lang="scss"></style>
