<template>
  <Slidebar :large="true" @closeMe="closeSlidebar">
    <h4>{{ item.name }}</h4>
    <h5>Pantanir</h5>
    <br />
    <Timetable />
    <br />
    <b-btn variant="primary" @click="closeSlidebar">Loka</b-btn>
  </Slidebar>
</template>

<script>
import Slidebar from '@/components/common/Slidebar.vue';
import Timetable from '@/components/common/Timetable.vue';

export default {
  name: 'order-slide-bar',
  props: ['item'],
  components: {
    Slidebar,
    Timetable,
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    editItem() {
      this.$emit('editItem');
    },
  },
};
</script>

<style lang="scss"></style>
