<template>
  <Slidebar @closeMe="closeSlidebar" :extraLarge="true">
    <h4>Innlestur atburða</h4>

    <b-link class="float-right" @click="showDetails = !showDetails">
      <span v-if="showDetails">Fela</span><span v-else>Skoða</span>
      leiðbeiningar
      <i class="fa ml-2" :class="{ 'fa-angle-up': !showDetails, 'fa-angle-down': showDetails }" />
    </b-link>
    <div v-if="showDetails">
      <div class="mb-2">Hægt er að lesa inn atburði á tvo vegu:</div>
      <div class="mb-2">
        <strong>1.</strong> Fylla út .csv skjal með atburðum og lesa þá inn. <br />
        <div>
          <a href="/files/skoladagatal_innlestur.csv" target="_blank"><i class="fa fa-cloud-download"></i> Sækja dæmi um .csv skrá</a>
        </div>
      </div>
      <div>
        <strong>2.</strong> Sækja tímabil í .csv skrá, fylla það út og lesa inn. <br />
        Skráin inniheldur alla daga tímabilsins, rauðir dagar koma með þeim dögum sem þar á við.
        <div>
          <div class="d-inline-block mr-3">
            <b-form-group
              label="Dags. frá"
              label-for="dateFrom"
              :state="errors.has('dateFrom') ? false : ''"
              :invalid-feedback="errors.first('dateFrom')"
            >
              <datepicker
                id="dateFrom"
                name="dateFrom"
                v-model="form.dateFrom"
                :language="lang"
                :monday-first="true"
                format="dd.MM.yyyy"
                :typeable="false"
                v-validate="'required'"
                data-vv-as="dags"
                :state="errors.has('dateFrom') ? false : ''"
                @input="updateDates()"
                ref="dateFromPickr"
                :disabled="searching"
              ></datepicker>
            </b-form-group>
          </div>
          <div class="d-inline-block mr-3">
            <b-form-group
              label="Dags. til"
              label-for="dateTo"
              :state="errors.has('dateTo') ? false : ''"
              :invalid-feedback="errors.first('dateTo')"
            >
              <datepicker
                id="dateTo"
                name="dateTo"
                v-model="form.dateTo"
                :language="lang"
                :monday-first="true"
                format="dd.MM.yyyy"
                :typeable="false"
                v-validate="'required'"
                data-vv-as="dags"
                @input="updateDates()"
                :state="errors.has('dateTo') ? false : ''"
                ref="dateToPickr"
                :disabled="searching"
              ></datepicker>
            </b-form-group>
          </div>
          <div class="d-inline-block">
            <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" v-if="!searching">
              <b-btn variant="primary" style="margin-top: -5px"> Útbúa skjal</b-btn>
            </download-csv>
          </div>
        </div>
      </div>

      <h5 class="mt-2">Útskýringar á dálkum</h5>
      <table class="table no-border">
        <tr>
          <th>Dagsetning</th>
          <td>Dagsetning atburðar. Eingöngu er hægt að lesa inn atburð fyrir heilan dag.</td>
        </tr>
        <tr>
          <th>Undirskóli</th>
          <td>Undirskóli sem atburður tilheyrir. Verður að fylla út. Hægt að setja inn bæði heiti eða kóða.</td>
        </tr>
        <tr>
          <th>Atburður starfsfólk</th>
          <td>Ef stofna á atburð fyrir starfsfólk er heiti hans sett í þennan dálk.</td>
        </tr>
        <tr>
          <th>Atburður nemendur</th>
          <td>Ef stofna á atburð fyrir nemendur er heiti hans sett í þennan dálk.</td>
        </tr>
        <tr>
          <th>Mætingarskylda</th>
          <td>
            Er kennt sé samkvæmt stundatöflu?
            <div>1 - Nemendur mæta skv. stundatöflu</div>
            <div>0 - Það er frí þann dag og nemendur þurfa ekki að mæta skv. stundatöflu.</div>
            <div>
              Ath. ef búið er að fletja út stundatöflu og tímar skráðir á daginn þá eyðast þeir ekki sjálfkrafa heldur þarf handvirkt að
              eyða þeim.
            </div>
          </td>
        </tr>
      </table>
      <div>Ath. Gæta þarf að ef atburður er fyrir bæði starfsmenn og nemendur þarf heitið að vera það sama í báðum reitum.</div>
    </div>

    <div class="mt-3">
      <b-form-file
        v-model="file"
        accept="text/csv"
        class="schoolEventFile"
        placeholder="Smelltu hér til að velja .csv skrá.."
      ></b-form-file>
      <b-btn variant="primary" @click="loadFile" :disabled="!file" class="mt-2"><i class="fa fa-cloud-upload"></i> Lesa inn</b-btn>
    </div>
    <div v-if="firsttime" class="mt-3">
      <h5>Gildar færslur</h5>
      <table class="table">
        <tr>
          <th>Undirskóli</th>
          <th>Heiti atburðar</th>
          <th>Dags. byrjar</th>
          <th>Dags. endar</th>
          <th>Birtist starfsfólki</th>
          <th>Birtist nemendum</th>
          <th>
            Nemendur eiga að mæta <br />
            í tíma skv. stundatöflu
          </th>
          <th></th>
        </tr>
        <tr v-for="(item, idx) in parsed" :key="idx">
          <td>{{ item.undirskoli.name }}</td>
          <td>{{ item.heiti }}</td>
          <td>{{ item.dags_byrjar | moment('DD.MM.YYYY') }}</td>
          <td>{{ item.dags_endar | moment('DD.MM.YYYY') }}</td>
          <td><b-form-checkbox v-model="item.starfsmenn"></b-form-checkbox></td>
          <td><b-form-checkbox v-model="item.nemendur"></b-form-checkbox></td>
          <td><b-form-checkbox v-model="item.maeting"></b-form-checkbox></td>
          <td>
            <i
              v-if="!item.maeting && (!item.starfsmenn || !item.nemendur)"
              class="fa fa-exclamation-triangle"
              style="color: #7e323d"
              v-b-tooltip.hover
              title="Viðburður þarf að vera með mætingaskyldu eða vera fyrir bæði nemendur og starfsmenn"
            ></i>
          </td>
        </tr>
        <tr v-if="parsed.length === 0">
          <td colspan="100%">Engar gildar færslur í innlesinni skrá</td>
        </tr>
      </table>

      <div class="alert alert-danger p-2" v-if="noAttendanceError">
        <i class="fa fa-exclamation-triangle"></i> Ath. ekki er hægt að stofna viðburð án mætingaskyldu nema hann sé fyrir bæði nemendur og
        starfsmenn.
      </div>
      <b-btn variant="primary" @click="createEvents()" v-if="parsed.length > 0" :disabled="saving || noAttendanceError">
        <i class="fa fa-spin fa-spinner" v-if="saving"></i> Stofna gilda atburði</b-btn
      >
      <b-btn variant="dark" @click="closeSlidebar()" v-if="parsed.length > 0" class="float-right"> Hætta við</b-btn>
      <br /><br />
      <h5 v-if="notValid.length > 0">Ógildar færslur</h5>
      <table class="table" v-if="notValid.length > 0">
        <tr>
          <th>Dagsetning</th>
          <th>Undirskóli</th>
          <th>Atburður starfsmenn</th>
          <th>Atburður nemendur</th>
          <th style="width: 40%">Villa</th>
        </tr>
        <tr v-for="(item, idx) in notValid" :key="idx">
          <td>{{ item.dagsetning | moment('DD.MM.YYYY') }}</td>
          <td>{{ item.undirskoli }}</td>
          <td>{{ item.atburdur_stm }}</td>
          <td>{{ item.atburdur_nem }}</td>
          <td>{{ item.error }}</td>
        </tr>
      </table>
    </div>
  </Slidebar>
</template>

<script>
import moment from 'moment';
import common from '@/api/common';
import structure from '@/api/structure';
import { mapActions, mapGetters } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';

import Datepicker from 'vuejs-datepicker';

import { is } from 'vuejs-datepicker/dist/locale';

export default {
  name: 'school-event-upload-slidebar',
  components: {
    Slidebar,
    Datepicker,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_stundatoflur');
    },
    canEditTypes() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    noAttendanceError() {
      return this.parsed.filter(x => !x.maeting && (!x.starfsmenn || !x.nemendur)).length > 0;
    },
    getExcelData() {
      const newList = [
        [],
        ['Skóladagatal - Innlestur'],
        [],
        ['Dagsetning', 'Undirskóli', 'Atburður starfsfólk', 'Atburður nemendur', 'Mætingarskylda'],
        ...this.form.dates.map(x => [moment(x.date).format('DD.MM.YYYY'), x.event ? 'Rauður dagur' : '', x.event, x.event, 1]),
      ];
      return newList;
    },
    getExcelFileName() {
      return `innlestur_skoladagatal_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      type: 'upload',
      saving: false,
      file: null,
      events: [],
      divisions: [],
      parsed: [],
      notValid: [],
      firsttime: false,
      loading: {
        events: false,
        divisions: false,
      },
      lang: is,
      searching: false,
      form: {
        dateFrom: new Date(),
        dateTo: new Date(),
        dates: [],
      },
      showDetails: true,
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async loadDivisions() {
      try {
        this.loading.divisions = true;
        const response = await structure.getDivisionList();
        this.divisions = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.divisions = false;
      }
    },
    parseFile() {
      return new Promise(resolve => {
        const read = new FileReader();
        read.readAsText(this.file);
        read.onloadend = () => {
          const data = read.result;
          if (data.split(';').length > 0) {
            const lines = data.split('\n');
            lines.forEach(line => {
              if (line.length > 0) {
                const item = {
                  dags_byrjar: '',
                  dags_endar: '',
                  heitiStm: '',
                  heitiNem: '',
                };
                const columns = line.split(';');
                let error = '';
                let someInfo = false;
                // Dálkur 0 - Dagsetning
                if (columns.length > 0) {
                  if (columns[0] === 'Dagsetning') return;
                  const date = moment(columns[0], 'DD.MM.YYYY');
                  if (date.isValid()) {
                    item.dags_byrjar = moment(columns[0], 'DD.MM.YYYY');
                    item.dags_endar = moment(columns[0], 'DD.MM.YYYY');
                  } else error += 'Dagsetning ekki gild. ';
                }
                // Dálkur 1 - Undirskóli
                if (columns.length > 1) {
                  if (columns[1] === 'Rauður dagur') return;
                  if (!someInfo && columns[1] !== '') someInfo = true;
                  const division = this.divisions.find(
                    x =>
                      (x.code || '').toUpperCase().trim() === (columns[1] || '').toUpperCase().trim() ||
                      (x.name || '').toUpperCase().trim() === (columns[1] || '').toUpperCase().trim(),
                  );
                  if (division) {
                    item.undirskoli = division;
                  } else error += 'Undirskóli fannst ekki. ';
                }
                // Dálkur 2 - Atburður starfsfólk
                if (columns.length > 2) {
                  if (columns[2].length <= 40) {
                    if (!someInfo && columns[2] !== '') someInfo = true;
                    item.heitiStm = columns[2];
                    item.heiti = columns[2].length > 0 ? columns[2] : item.heiti;
                    item.starfsmenn = columns[2].length > 0;
                  } else error += 'Heiti viðburðar of langt. ';
                } else item.starfsmenn = false;
                // Dálkur 3 - Atburður nemendur
                if (columns.length > 3) {
                  if (columns[3].length <= 40) {
                    if (!someInfo && columns[3] !== '') someInfo = true;
                    item.heitiNem = columns[3];
                    item.heiti = columns[3].length > 0 ? columns[3] : item.heiti;
                    item.nemendur = columns[3].length > 0;
                  } else error += 'Heiti viðburðar of langt. ';
                } else item.nemendur = false;
                // Dálkur 4 - Mætingarskylda
                if (columns.length > 4) {
                  item.maeting = parseInt(columns[4], 10) === 1;
                } else item.maeting = true;

                const multiEvents = item.heitiStm.length > 0 && item.heitiNem.length > 0 && item.heitiStm !== item.heitiNem;

                if (error.length > 0) {
                  if (someInfo) {
                    this.notValid.push({
                      dagsetning: columns.length > 0 ? columns[0] : '',
                      undirskoli: columns.length > 1 ? columns[1] : '',
                      atburdur_stm: columns.length > 2 ? columns[2] : '',
                      atburdur_nem: columns.length > 3 ? columns[3] : '',
                      error,
                    });
                  }
                } else if (item.starfsmenn === false && item.nemendur === false) {
                  // do nothing - sleppa tómum línum
                } else if (multiEvents) {
                  this.parsed.push({ ...item, heiti: item.heitiStm, starfsmenn: true, nemendur: false });
                  this.parsed.push({ ...item, heiti: item.heitiNem, starfsmenn: false, nemendur: true });
                } else {
                  this.parsed.push(item);
                }
              }
            });
          } else {
            this.displayError('Skrá ekki lögleg. Dálkar verða að vera aðskildir með ";"');
          }
          resolve();
        };
      });
    },
    async loadFile() {
      if (this.file.name && this.file.name.length > 4 && this.file.name.endsWith('.csv')) {
        this.showDetails = false;
        this.firsttime = true;
        this.parsed = [];
        this.notValid = [];
        // Vinnu úr skjalinu
        await this.parseFile();
        // Athuga hvort allar kennitölur finnist í þjóðskrá
        this.file = null;
      } else {
        this.displayError('Skrá er ekki .csv skrá. Vinsamlegast veljið aðra.');
      }
    },
    createEvents() {
      try {
        this.saving = true;
        const promises = [];
        this.parsed.forEach(x => {
          const data = {
            heill_dagur: 1,
            heiti: x.heiti,
            undirskoli: x.undirskoli.division_id,
            dags_byrjun: moment(x.dags_byrjar).format('DD.MM.YYYY'),
            dags_endar: moment(x.dags_endar).format('DD.MM.YYYY'),
            nemendur: x.nemendur ? 1 : 0,
            starfsmenn: x.starfsmenn ? 1 : 0,
            maeting: x.maeting ? 1 : 0,
            notendahopur: '',
            timar: '',
          };
          promises.push(common.createSchoolEvent(data));
        });
        Promise.all(promises)
          .then(() => {
            this.displaySuccess('Atburðir stofnaðir í skóladagatali');
            this.$emit('closeAndReloadSlidebar');
            this.saving = false;
          })
          .catch(e => {
            this.saving = false;
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          });
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    updateDates() {
      this.form.dates = [];
      const startDate = moment(this.form.dateFrom).startOf('day').toDate();
      const endDate = moment(this.form.dateTo).startOf('day').toDate();
      const currentDate = startDate;
      while (currentDate <= endDate) {
        this.form.dates.push({
          date: new Date(currentDate),
          event: '',
        });
        currentDate.setDate(currentDate.getDate() + 1);
      }
      this.loadBankHolidays();
    },
    async loadBankHolidays() {
      try {
        this.searching = true;
        const response = await common.getBankHolidays(
          moment(this.form.dateFrom).format('DD.MM.YYYY'),
          moment(this.form.dateTo).format('DD.MM.YYYY'),
        );
        this.form.dates.forEach(d => {
          const holiday = response.data.items.find(x => moment(x.dags).format('DD.MM.YYYY') === moment(d.date).format('DD.MM.YYYY'));
          if (holiday) {
            this.$set(d, 'event', holiday.heiti);
          }
        });
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.searching = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadDivisions();
    this.updateDates();
  },
};
</script>

<style lang="scss">
.schoolEventFile {
  .custom-file-label::after {
    content: '' !important;
    padding: 0;
  }
}
</style>
