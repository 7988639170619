<template>
  <Slidebar :large="true" @closeMe="closeSlidebar">
    <h4 v-if="this.item && this.item.profstjori_id">Breyta tengilið</h4>
    <h4 v-else>Stofna tengilið</h4>
    <br />
    <br />
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <table class="table no-border less-padding">
        <tr>
          <td>Kennitala*</td>
          <td>
            <b-form-group label-for="ssn" :state="submitted && errors.has('ssn') ? false : ''" :invalid-feedback="errors.first('ssn')">
              <b-form-input
                id="ssn"
                name="ssn"
                type="text"
                v-model="form.ssn"
                @input="searchSsn()"
                :state="submitted && errors.has('ssn') ? false : ''"
                v-validate="'required'"
                data-vv-as="kennitala"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Nafn*</td>
          <td>
            <b-form-group label-for="name" :state="submitted && errors.has('name') ? false : ''" :invalid-feedback="errors.first('name')">
              <b-form-input
                id="name"
                name="name"
                type="text"
                v-model="form.name"
                disabled
                :state="submitted && errors.has('name') ? false : ''"
                v-validate="'required'"
                data-vv-as="nafn"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Símanúmer*</td>
          <td>
            <b-form-group
              label-for="phone"
              :state="submitted && errors.has('phone') ? false : ''"
              :invalid-feedback="errors.first('phone')"
            >
              <b-form-input
                id="phone"
                name="phone"
                type="text"
                v-model="form.phone"
                :state="submitted && errors.has('phone') ? false : ''"
                v-validate="'required'"
                data-vv-as="símanúmer"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Netfang*</td>
          <td>
            <b-form-group
              label-for="email"
              :state="submitted && errors.has('email') ? false : ''"
              :invalid-feedback="errors.first('email')"
            >
              <b-form-input
                id="email"
                name="email"
                type="text"
                v-model="form.email"
                :state="submitted && errors.has('email') ? false : ''"
                v-validate="'required'"
                data-vv-as="netfang"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Fá sendan tilkynningarpóst</td>
          <td>
            <b-form-group
              label-for="send_email"
              :state="submitted && errors.has('send_email') ? false : ''"
              :feedback="errors.first('send_email')"
            >
              <b-form-checkbox
                id="send_email"
                name="send_email"
                v-model="form.send_email"
                :state="submitted && errors.has('send_email') ? false : ''"
                v-validate="'required'"
              ></b-form-checkbox>
            </b-form-group>
          </td>
        </tr>
      </table>
      <div class="mb-3"><i class="fa fa-info pr-2" /> Símanúmer verður að vera skráð rétt og tengt við rafræn skilríki.</div>
    </b-form>
    <b-btn variant="primary" class="mr-2" v-if="canEdit" @click="validateBeforeSubmit">
      <span v-if="this.item && this.item.profstjori_id">Breyta</span>
      <span v-else>Stofna</span>
    </b-btn>
    <b-btn variant="dark" @click="closeSlidebar">Hætta við</b-btn>
    <b-btn variant="danger" class="float-right" v-if="canEdit && this.item && this.item.profstjori_id" @click="deleteManager"> Eyða </b-btn>
  </Slidebar>
</template>

<script>
import school from '@/api/school';
import common from '@/api/common';
import Slidebar from '@/components/common/Slidebar.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'exam-place-manager-slide-bar',
  props: ['item', 'id'],
  components: {
    Slidebar,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      form: {
        ssn: '',
        name: '',
        phone: '',
        email: '',
        send_email: true,
      },
      submitted: false,
    };
  },
  methods: {
    updateForm() {
      this.form = {
        ssn: this.item.profstjori_ssn,
        name: this.item.profstjori_name,
        phone: this.item.profstjori_phone,
        email: this.item.profstjori_email,
        send_email: this.item.senda_post,
      };
    },
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async searchSsn() {
      this.form.name = '';
      if (this.form.ssn && this.form.ssn.length === 10) {
        try {
          const response = await common.searchSsn(this.form.ssn);
          this.form.name = response.data.nafn;
        } catch (e) {
          this.$log.error(e);
          if (e.status === 404) {
            this.displayError('Kennitala fannst ekki. Vinsamlegast veljið aðra.');
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      }
    },
    async deleteManager() {
      const data = {
        name: this.form.name,
        ssn: this.form.ssn,
        phone: this.form.phone,
        email: this.form.email,
        send_email: this.form.send_email,
        managerId: this.item.profstjori_id,
        deleted: 1,
      };
      await school.createExamPlaceManager(this.id, data);
      this.displaySuccess('Tengilið hefur verið eytt');
      this.closeSlidebar();
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.saving = true;
          const data = {
            name: this.form.name,
            ssn: this.form.ssn,
            phone: this.form.phone,
            email: this.form.email,
            send_email: this.form.send_email,
          };
          if (this.item && this.item.profstjori_id) {
            data.managerId = this.item.profstjori_id;
          }
          await school.createExamPlaceManager(this.id, data);
          this.displaySuccess('Tengiliður vistaður');
          this.closeSlidebar();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.saving = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    item() {
      this.updateForm();
    },
  },
  created() {
    this.updateForm();
  },
};
</script>

<style lang="scss"></style>
