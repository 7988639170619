<template>
  <page>
    <Breadcrumbs :breadcrumbs="breadcrumbs" />

    <PageTitle title="Forföll" />
    <b-card>
      <form @submit.prevent="loadList(showSummary)">
        <b-row>
          <b-col>
            <b-form-group>
              Skoða eftir:
              <b-form-radio-group id="showBy" v-model="showSummary" @input="updateSettings()" name="showBy" class="pl-2 d-inline-block">
                <b-form-radio :value="false">Sundurliðun</b-form-radio>
                <b-form-radio :value="true">Samantekt</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Dags. frá" label-for="dateFrom">
              <datepicker
                id="dateFrom"
                name="dateFrom"
                v-model="search.dateFrom"
                :language="lang"
                :monday-first="true"
                format="dd.MM.yyyy"
                :typeable="false"
                :clear-button="showSummary"
                ref="dateOpenPickr"
              ></datepicker>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Dags. til" label-for="dateTo">
              <datepicker
                id="dateTo"
                name="dateTo"
                v-model="search.dateTo"
                :language="lang"
                :monday-first="true"
                format="dd.MM.yyyy"
                :typeable="false"
                :clear-button="showSummary"
                ref="dateOpenPickr"
              ></datepicker>
            </b-form-group>
          </b-col>
          <b-col v-if="!showSummary"></b-col>

          <b-col v-if="showSummary">
            <b-form-group label="Kennslutímabil" label-for="term">
              <v-select id="term" name="term" v-model="search.term" :options="getTerms" :clearable="false"> </v-select>
            </b-form-group>
            <div style="margin-top: -5px">
              <b-form-checkbox v-model="showAllTerms" class="d-inline-block"></b-form-checkbox>
              <div class="d-inline-block small-text-size">Birta liðnar og læstar annir</div>
            </div>
          </b-col>
          <b-col>
            <b-btn type="submit" variant="primary" class="w-100" style="margin-top: 20px">Leita</b-btn>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Nafn/Kennitala" label-for="name">
              <b-form-input
                id="search"
                placeholder="Leita eftir nafni, skammstöfun eða kennitölu"
                name="search"
                v-model="search.search"
                ref="focusInput"
                v-on:keyup.enter="loadList()"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Ástæða forfalla" label-for="absenceType">
              <v-select
                id="absenceType"
                name="absenceType"
                v-model="search.absenceType"
                :options="absenceList"
                label="heiti"
                :clearable="true"
                value="teg_for_stm_id"
              ></v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="showSummary">
          <b-col>
            <b-form-group label="Áfangi" label-for="module">
              <b-form-input id="module" name="module" v-model="search.module" v-on:keyup.enter="loadList()" ref="focusInput" />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Hópur" label-for="group">
              <b-form-input id="group" name="group" v-model="search.group" v-on:keyup.enter="loadList()" />
            </b-form-group>
          </b-col>
        </b-row>
      </form>
    </b-card>
    <br />
    <b-card v-if="firsttime">
      <table class="table" v-if="!showSummary">
        <thead>
          <tr>
            <th>Nafn ({{ list.length }})</th>
            <th>Kennitala</th>
            <th>Upphasstafir</th>
            <th>Dags.</th>
            <th>Vikudagur</th>
            <th>Frá kl.</th>
            <th>Til kl.</th>
            <th>Áfangi</th>
            <th>Hópur</th>
            <th>Önn</th>
            <th>Ástæða</th>
            <th>Aths.</th>
            <th>
              Forfallakennsla
              <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="cursor-pointer float-right">
                <i class="fa fa-file-excel-o excelText"></i>
              </download-csv>
            </th>
          </tr>
        </thead>
        <tbody>
          <TableRowLoader v-if="loading.info && firsttime" :colspan="13" :center="true" />
          <TableRowEmpty v-if="!loading.info && firsttime && this.list.length === 0" :colspan="13" :center="true" />

          <tr v-for="item in list" :key="item.tafla_id">
            <td>{{ item.nafn }}</td>
            <td>{{ item.kennitala }}</td>
            <td>{{ item.skammst }}</td>
            <td>{{ item.dags | moment('DD.MM.YYYY') }}</td>
            <td>{{ item.vikudagur }}</td>
            <td>{{ item.timi_fra }}</td>
            <td>{{ item.timi_til }}</td>
            <td>{{ item.namsgrein_afanganr }}</td>
            <td>{{ item.hopur }}</td>
            <td>{{ item.onn_eink }}</td>
            <td>{{ item.teg_forfalla }}</td>
            <td>{{ item.athugasemd }}</td>
            <td>{{ item.forfallakennari }}</td>
          </tr>
        </tbody>
        <!-- <tr v-if="list.length === 0 && !loading.info">Engin forföll fundust</tr> -->
      </table>
      <table class="table" v-if="showSummary">
        <thead>
          <tr>
            <th>Nafn ({{ list.length }})</th>
            <th>Kennitala</th>
            <th>Upphasstafir</th>
            <th>
              Fj. tíma
              <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="cursor-pointer float-right">
                <i class="fa fa-file-excel-o excelText"></i>
              </download-csv>
            </th>
          </tr>
        </thead>
        <tbody>
          <TableRowLoader v-if="loading.info && firsttime" :colspan="13" :center="true" />
          <TableRowEmpty v-if="!loading.info && firsttime && this.list.length === 0" :colspan="13" :center="true" />
          <template v-for="(item, index) in list">
            <tr :key="item.starfsm_id">
              <td>{{ item.nafn }}</td>
              <td>{{ item.kennitala }}</td>
              <td>{{ item.skammst }}</td>
              <td class="cursor-pointer" @click="toggleExpand(index, item.starfsm_id)">
                {{ item.fj }}
                <i class="fa text-primary" :class="{ 'fa-angle-up': expandedRows[index], 'fa-angle-down': !expandedRows[index] }"></i>
              </td>
            </tr>
            <tr v-if="expandedRows[index]" :key="`details-${item.starfsm_id}`">
              <td colspan="4" style="padding: 20px 30px !important; background-color: #f9f9f9">
                <div style="margin-top: -10px; margin-bottom: 10px">Sundurliðun</div>
                <table class="table less-padding" style="font-size: 13px">
                  <thead>
                    <tr>
                      <th>Nafn</th>
                      <th>Kennitala</th>
                      <th>Upphasstafir</th>
                      <th>Dags.</th>
                      <th>Vikudagur</th>
                      <th>Frá kl.</th>
                      <th>Til kl.</th>
                      <th>Áfangi</th>
                      <th>Hópur</th>
                      <th>Önn</th>
                      <th>Ástæða</th>
                      <th>Aths.</th>
                      <th>Forfallakennsla</th>
                    </tr>
                  </thead>
                  <tbody>
                    <TableRowLoader v-if="loading.detail && firsttime" :colspan="13" :center="true" />
                    <tr v-for="detail in detailsList[item.starfsm_id]" :key="detail.tafla_id">
                      <td>{{ detail.nafn }}</td>
                      <td>{{ detail.kennitala }}</td>
                      <td>{{ detail.skammst }}</td>
                      <td>{{ detail.dags | moment('DD.MM.YYYY') }}</td>
                      <td>{{ detail.vikudagur }}</td>
                      <td>{{ detail.timi_fra }}</td>
                      <td>{{ detail.timi_til }}</td>
                      <td>{{ detail.namsgrein_afanganr }}</td>
                      <td>{{ detail.hopur }}</td>
                      <td>{{ detail.onn_eink }}</td>
                      <td>{{ detail.teg_forfalla }}</td>
                      <td>{{ detail.athugasemd }}</td>
                      <td>{{ detail.forfallakennari }}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </b-card>
  </page>
</template>

<script>
import { mapGetters } from 'vuex';
import staff from '@/api/staff';
import types from '@/api/types';

import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import PageTitle from '@/components/common/PageTitle.vue';

import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import terms from '@/api/terms';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';
import moment from 'moment';

export default {
  name: 'staff-absence-report',
  components: {
    Breadcrumbs,
    PageTitle,
    TableRowLoader,
    TableRowEmpty,
    Datepicker,
  },
  computed: {
    getTerms() {
      return this.terms.filter(x => this.showAllTerms || (x.is_past === 0 && x.tafla_laest === 0));
    },
    canRead() {
      return this.loggedInUserHasReadPermission('starfsmannahald_forfoll');
    },
    getExcelData() {
      const newList = [
        ['Starfsmenn forföll samantekt'],
        [],
        [
          'Nafn',
          'Kennitala',
          'Upphafsstafir',
          this.showSummary ? 'Fj. tíma' : 'Dags.',
          this.showSummary ? null : 'Vikudagur',
          this.showSummary ? null : 'Frá kl.',
          this.showSummary ? null : 'Til kl.',
          this.showSummary ? null : 'Áfangi',
          this.showSummary ? null : 'Hópur',
          this.showSummary ? null : 'Önn',
          this.showSummary ? null : 'Ástæða',
          this.showSummary ? null : 'Aths',
          this.showSummary ? null : 'Forfallakennsla',
        ],
        ...this.list.map(x => [
          x.nafn,
          x.kennitala,
          x.skammst,
          this.showSummary ? x.fj : moment(x.dags).format('DD.MM.YYYY'),
          this.showSummary ? '' : x.vikudagur,
          this.showSummary ? '' : x.vikudagur,
          this.showSummary ? '' : x.timi_fra,
          this.showSummary ? '' : x.timi_til,
          this.showSummary ? '' : x.namsgrein_afanganr,
          this.showSummary ? '' : x.hopur,
          this.showSummary ? '' : x.onn_eink,
          this.showSummary ? '' : x.teg_forfalla,
          this.showSummary ? '' : x.athugasemd,
          this.showSummary ? '' : x.athugasemd,
        ]),
      ];
      return newList;
    },
    getExcelFileName() {
      if (this.showSummary) {
        return `starfsmenn_forfoll_samantekt_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
      }
      return `starfsmenn_forfoll_sundurlidunt_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      breadcrumbs: [
        { name: 'Forsíða', route: 'Dashboard' },
        { name: 'Starfsmannahald', route: '' },
        { name: 'Forföll', route: 'StaffAbsenceReport' },
      ],
      lang: is,
      loading: {
        terms: false,
        absenceList: false,
        info: false,
        detail: false,
      },
      terms: [],
      absenceList: [],
      list: [],
      detailsList: {},
      showSummary: false,
      showAllTerms: false,
      firsttime: false,
      search: {
        search: '',
        term: '',
        absenceType: '',
        dateFrom: moment().toDate(),
        dateTo: moment().toDate(),
        module: '',
        group: '',
      },
      expandedRows: {},
    };
  },
  methods: {
    toggleExpand(index, staffId) {
      this.loadListBreakdown(staffId);
      this.$set(this.expandedRows, index, !this.expandedRows[index]);
    },
    // sækir forfalla tegundir
    async loadAbsenceList() {
      try {
        this.loading.absenceList = true;
        const response = await types.getStaffAbsenceList();
        this.absenceList = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.absenceList = false;
      }
    },
    // sækir annir
    async loadTerms() {
      try {
        if (this.canRead) {
          this.loading.terms = true;
          const response = await terms.getTerms();
          this.terms = response.data.items.map(x => ({
            ...x,
            label: `${x.undirsk_audk} - ${x.onn_heiti}`,
          }));
          if (this.term) {
            this.search.term = this.terms.find(x => x.onn_id === parseInt(this.term, 10));
            this.loadList();
          } else {
            this.search.term = this.terms.find(x => x.is_default === 1);
          }
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.terms = false;
      }
    },
    async updateSettings() {
      this.list = []; // hreinsa lista
      this.firsttime = false;
      if (this.showSummary === false) {
        // find dateFrom input and set text to current date - workaround due to bug in datepicker
        // with typeable="true": https://github.com/sumcumo/vue-datepicker/issues/142
        // document.getElementById('dateFrom').innerText = moment().format('DD.MM.YYYY');
        // document.getElementById('dateTo').innerText = moment().format('DD.MM.YYYY');

        // dagsetningar verða að hafa gildi (mega ekki vera tómt) ef Sundurliðun
        this.search.dateFrom = moment().toDate();
        this.search.dateTo = moment().toDate();
      } else {
        // dagsetningar byrja tómar
        this.search.dateFrom = '';
        this.search.dateTo = '';
      }
    },
    async loadList(isSummary) {
      this.detailsList = {};
      this.expandedRows = {};
      if (isSummary) {
        this.loadListSummary();
      } else {
        this.loadListBreakdown();
      }
    },
    // sækir sundurliðun
    async loadListBreakdown(staffId) {
      this.firsttime = true;
      if (staffId) {
        this.loading.detail = true;
      } else {
        this.loading.info = true;
      }
      try {
        const item = {
          search: this.search.search,
          date_from: this.search.dateFrom ? moment(this.search.dateFrom).format('DD.MM.YYYY') : null,
          date_to: this.search.dateTo ? moment(this.search.dateTo).format('DD.MM.YYYY') : null,
          term_id: this.search.term ? this.search.term.onn_id : '', // notað ef verið að sækja niður á starsmannaId
          absence_type: this.search.absenceType ? this.search.absenceType.teg_forf_stm_id : '',
          staff_id: staffId ?? null,
        };
        const response = await staff.getStaffAbsenceListBreakdown(item);

        if (staffId) {
          this.detailsList[staffId] = response.data.items;
        } else {
          this.list = response.data.items;
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.info = false;
        this.loading.detail = false;
      }
    },
    // sækir samantekt
    async loadListSummary() {
      this.firsttime = true;
      this.loading.info = true;
      try {
        const item = {
          search: this.search.search,
          date_from: this.search.dateFrom ? moment(this.search.dateFrom).format('DD.MM.YYYY') : null,
          date_to: this.search.dateTo ? moment(this.search.dateTo).format('DD.MM.YYYY') : null,
          absence_type: this.search.absenceType ? this.search.absenceType.teg_forf_stm_id : '',
          term_id: this.search.term ? this.search.term.onn_id : '',
          module: this.search.module,
          group: this.search.group,
        };
        const response = await staff.getStaffAbsenceListSummary(item);
        this.list = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.info = false;
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (to && to.query && to.query.page) {
      this.page = to.query.page;
    }
    next();
  },
  created() {
    this.loadAbsenceList();
    this.loadTerms();
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
  },
};
</script>
