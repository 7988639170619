<template>
  <Slidebar @closeMe="closeSlidebar" :extraLarge="true">
    <h4 v-if="creating">Bæta við bundnu vali</h4>
    <h4 v-else>Breyta bundnu vali</h4>
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <div v-if="creating">
        <div style="font-size: 17px">1. Veldu áfanga sem þú vilt bæta við sem bundið val</div>

        <Shuttle
          v-if="creating"
          :list="all"
          :value="selected"
          :size="15"
          titleFrom="Allir áfangar"
          titleTo="Valdir áfangar"
          typeName="áfanga"
        ></Shuttle>
      </div>
      <div v-else>
        <div style="font-size: 17px">1. <span v-if="items">Áfangar</span><span v-else>Áfangi</span> sem verið er að breyta</div>
        <div v-if="item">{{ item.namsgrein_afanganr }}</div>
        <div v-if="items">
          <div v-for="i in items" :key="i.val_id">{{ i.namsgrein_afanganr }}</div>
        </div>
      </div>
      <div class="mt-2" style="font-size: 17px">2. Veldu skólaár nemanda sem mega velja áfangana</div>

      <b-form-group
        v-if="creating"
        label-for="years"
        :state="submitted && errors.has('years') ? false : ''"
        :invalid-feedback="errors.first('years')"
      >
        <v-select
          id="years"
          name="years"
          v-model="form.years"
          multiple
          :options="getYears"
          label="heiti"
          data-vv-as="skólaár"
          v-validate="'required'"
        >
        </v-select>
      </b-form-group>

      <b-form-group v-else label-for="year" :state="submitted && errors.has('year') ? false : ''" :invalid-feedback="errors.first('year')">
        <v-select
          id="year"
          name="year"
          v-model="form.year"
          :options="getYears"
          label="heiti"
          :clearable="false"
          data-vv-as="skólaár"
          v-validate="'required'"
        >
        </v-select>
      </b-form-group>

      <div class="mt-2" style="font-size: 17px">3. Viltu að áfanginn birtist bara þangað til á ákveðinni dagsetningu?</div>
      <div style="font-size: 13px">Ath. þarf ekki að fylla út, ef óútfyllt er hægt að velja áfangann á meðan opið er fyrir val</div>
      <div>
        <div class="d-inline-block mr-2">
          <b-form-group label-for="date" :state="submitted && errors.has('date') ? false : ''" :invalid-feedback="errors.first('date')">
            <datepicker
              id="date"
              name="date"
              v-model="form.date"
              :language="lang"
              :monday-first="true"
              format="dd.MM.yyyy"
              :typeable="false"
              data-vv-as="dags"
              :state="submitted && errors.has('date') ? false : ''"
              ref="dateOpenPickr"
              v-validate="{ required: this.form.time !== '' }"
            ></datepicker>
          </b-form-group>
        </div>
        <div class="d-inline-block">
          <b-form-group label-for="time" :state="submitted && errors.has('time') ? false : ''" :invalid-feedback="errors.first('time')">
            <masked-input
              id="time"
              name="time"
              v-model="form.time"
              mask="11:11:11"
              placeholder="hh:mm:ss"
              style="padding: 2px 2px 2px 2px; width: 80px; height: 37px; border-radius: 0.25rem; border: 1px solid #ced4da"
              v-validate="{ required: this.form.date !== '', regex: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/ }"
              data-vv-as="tími"
              :state="submitted && errors.has('time') ? false : ''"
            />
          </b-form-group>
        </div>
        <div class="d-inline-block ml-2" v-if="form.date || form.time">
          <i class="fa fa-remove text-primary cursor-pointer" @click="clearDate()" title="Hreinsa dagsetnignu" v-b-tooltip></i>
        </div>
      </div>

      <div class="mt-2" style="font-size: 17px">4. Viltu takmarka niður að eingöngu nemendur á ákveðni braut megi velja ?</div>

      <b-form-group label-for="course" :state="submitted && errors.has('course') ? false : ''" :invalid-feedback="errors.first('course')">
        <v-select id="course" name="course" v-model="form.course" :options="courses" @input="loadLines"> </v-select>
      </b-form-group>

      <div class="mt-2" style="font-size: 17px">5. Viltu takmarka niður að eingöngu nemendur á ákveðinni línu megi velja ?</div>
      <b-form-group label-for="line" :state="submitted && errors.has('line') ? false : ''" :invalid-feedback="errors.first('line')">
        <v-select id="line" name="line" v-model="form.line" :options="lines" label="heiti"> </v-select>
      </b-form-group>
      <br />
      <b-btn variant="primary" @click="validateBeforeSubmit()" v-if="canEdit" :disabled="creating && selected.length === 0">
        <span v-if="creating">Bæta við</span>
        <span v-else>Breyta</span>
      </b-btn>
      <b-btn variant="danger" class="float-right" @click="deleteModule()" v-if="canEdit && !creating && item"> Eyða </b-btn>
    </b-form>
  </Slidebar>
</template>

<script>
import curriculum from '@/api/curriculum';
import classes from '@/api/classes';
import terms from '@/api/terms';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

import Slidebar from '@/components/common/Slidebar.vue';
import Shuttle from '@/components/common/Shuttle.vue';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';
import MaskedInput from 'vue-masked-input';

export default {
  name: 'term-restricted-choice-slidebar',
  props: ['termId', 'item', 'items'],
  components: {
    Slidebar,
    Shuttle,
    Datepicker,
    MaskedInput,
  },
  computed: {
    getYears() {
      return this.years.filter(x => !this.form.years.find(y => x.skolaar_skola_id === y.skolaar_skola_id));
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_annir');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {
      lang: is,
      creating: true,
      loading: false,
      loadingAll: false,
      submitted: false,
      courses: [],
      lines: [],
      selected: [],
      all: [],
      years: [],
      form: {
        year: '',
        years: [],
        course: '',
        line: '',
        date: '',
        time: '',
      },
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    clearDate() {
      this.form.date = '';
      this.form.time = '';
    },
    async loadList() {
      try {
        this.loadingAll = true;
        const response = await curriculum.getModuleList({ active: 1 });
        this.all = response.data.items.map(x => ({
          id: x.afangi_id,
          name: `${x.namsgrein_afanganr}, ${x.afanga_heiti}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loadingAll = false;
      }
    },
    async loadYears() {
      try {
        const response = await classes.getSchoolYears();
        this.years = response.data.items.map(x => ({
          ...x,
          label: `${x.heiti}`,
        }));
        this.form.years = [];
        this.form.year = '';
        if (this.item && this.item.skolaar && this.years.find(x => x.skolaar_id === this.item.skolaar)) {
          this.form.year = this.years.find(x => x.skolaar_id === this.item.skolaar);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadCourses() {
      try {
        const response = await curriculum.getCourseList({ active: 1 });
        this.courses = response.data.items.map(x => ({
          ...x,
          label: `${x.braut_kodi} - ${x.heiti}`,
        }));

        this.form.course = this.item && this.item.braut_id ? this.courses.find(x => x.braut_id === this.item.braut_id) : '';
        if (this.form.course) {
          this.loadLines();
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadLines() {
      try {
        this.lines = [];
        this.form.line = '';
        if (this.form.course && this.form.course.braut_id) {
          const response = await curriculum.getCourseLinesList(this.form.course.braut_id);
          this.lines = response.data.items;

          this.form.line =
            this.item && this.item.braut_uppbygg_id ? this.lines.find(x => x.braut_uppbygg_id === this.item.braut_uppbygg_id) : '';
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        try {
          if (this.creating) {
            const promises = [];
            this.selected.forEach(s => {
              this.form.years.forEach(year => {
                const data = {
                  afangi_id: s,
                  skolaar: year.skolaar_id,
                  braut_id: this.form.course ? this.form.course.braut_id : null,
                  braut_uppbygg_id: this.form.line ? this.form.line.braut_uppbygg_id : null,
                  dags_til: this.form.date && this.form.time ? `${moment(this.form.date).format('DD.MM.YYYY')} ${this.form.time}` : null,
                };
                promises.push(terms.createRestrictedChoice(this.termId, data));
              });
            });
            Promise.all(promises).then(
              () => {
                this.displaySuccess('Áföngum bætt við sem bundið val');
                this.closeSlidebar();
              },
              e => {
                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
              },
            );
          } else if (this.items) {
            const promises = [];
            this.items.forEach(i => {
              const data = {
                afangi_id: i.afangi_id,
                skolaar: this.form.year.skolaar_id,
                braut_id: this.form.course ? this.form.course.braut_id : null,
                braut_uppbygg_id: this.form.line ? this.form.line.braut_uppbygg_id : null,
                dags_til: this.form.date && this.form.time ? `${moment(this.form.date).format('DD.MM.YYYY')} ${this.form.time}` : null,
              };
              promises.push(terms.updateRestrictedChoice(this.termId, i.val_id, data));
            });
            Promise.all(promises).then(
              () => {
                this.displaySuccess('Áföngum breytt');
                this.closeSlidebar();
              },
              e => {
                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
              },
            );
          } else {
            const data = {
              afangi_id: this.item.afangi_id,
              skolaar: this.form.year.skolaar_id,
              braut_id: this.form.course ? this.form.course.braut_id : null,
              braut_uppbygg_id: this.form.line ? this.form.line.braut_uppbygg_id : null,
              dags_til: this.form.date && this.form.time ? `${moment(this.form.date).format('DD.MM.YYYY')} ${this.form.time}` : null,
            };
            await terms.updateRestrictedChoice(this.termId, this.item.val_id, data);
            this.displaySuccess('Áfanga breytt.');
            this.closeSlidebar();
          }
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.saving = false;
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.saving = false;
      }
    },
    async deleteModule() {
      const conf = confirm(`Ert þú viss um að eyða ${this.item.namsgrein_afanganr} sem bundið val?`); // eslint-disable-line
      if (conf) {
        try {
          terms.updateRestrictedChoice(this.termId, this.item.val_id, { delete: 1 });
          this.displaySuccess('Áfangi hefur verið eytt sem bundið val.');
          this.displaySuccess('Áfanga eytt.');
          this.closeSlidebar();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.creating = !this.item && !this.items;
    if (this.creating) {
      this.loadList();
      this.form.date = '';
      this.form.time = '';
    } else if (this.item && this.item.dags_til) {
      const date = moment(this.item.dags_til);
      this.form.date = date.toDate();
      this.form.time = moment(date).format('HH:mm:ss');
    }
    this.loadYears();
    this.loadCourses();
  },
};
</script>

<style lang="scss"></style>
