<template>
  <Slidebar @closeMe="closeSlidebar" :large="large" :extraLarge="extraLarge">
    <h4 v-if="id">{{ item.heiti }}</h4>
    <h4 v-else>Stofna búnað</h4>
    <br />
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <table class="table less-padding">
        <tr>
          <td>
            Heiti*
            <b-btn
              size="sm"
              style="padding: 0px 4px; margin-top: -5px"
              v-b-tooltip
              title="Bæta við öðrum eins búnaði"
              variant="primary"
              @click="addNew()"
              v-if="!id"
              ><i class="fa fa-plus-circle"></i
            ></b-btn>
          </td>
          <td>
            <div v-for="(n, idx) in form.name" :key="idx">
              <b-form-group
                :label-for="`heiti${idx}`"
                :state="submitted && errors.has(`heiti${idx}`) ? false : ''"
                :invalid-feedback="errors.first(`heiti${idx}`)"
              >
                <b-form-input
                  :id="`heiti${idx}`"
                  :name="`heiti${idx}`"
                  type="text"
                  v-model="n.name"
                  :state="submitted && errors.has(`heiti${idx}`) ? false : ''"
                  maxLength="455"
                  v-validate="'required'"
                  ref="focusInput"
                  data-vv-as="heiti"
                  style="width: 80%"
                  class="d-inline-block"
                ></b-form-input>
                <b-btn
                  v-if="form.name.length > 1"
                  @click="deleteItem(idx)"
                  variant="danger"
                  size="sm"
                  style="padding: 0px 4px; margin-left: 5px; height: calc(1.5em + 0.75rem + 2px); margin-top: -7px"
                  ><i class="fa fa-trash"></i
                ></b-btn>
              </b-form-group>
            </div>
          </td>
        </tr>
        <tr>
          <td>Lýsing</td>
          <td>
            <b-form-group
              label-for="lysing"
              :state="submitted && errors.has('lysing') ? false : ''"
              :invalid-feedback="errors.first('lysing')"
            >
              <b-form-textarea
                id="lysing"
                name="lysing"
                type="text"
                v-model="form.description"
                :state="submitted && errors.has('lysing') ? false : ''"
                maxLength="1000"
                data-vv-as="lýsing"
              ></b-form-textarea>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Staðsetning</td>
          <td>
            <b-form-group
              label-for="stadsetning"
              :state="submitted && errors.has('stadsetning') ? false : ''"
              :invalid-feedback="errors.first('stadsetning')"
            >
              <b-form-input
                id="stadsetning"
                name="stadsetning"
                type="text"
                v-model="form.location"
                :state="submitted && errors.has('stadsetning') ? false : ''"
                maxLength="1000"
                data-vv-as="staðsetning"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Má starfsmaður panta</td>
          <td>
            <b-form-group label-for="starfsmPanta">
              <b-form-checkbox id="starfsmPanta" name="starfsmPanta" type="text" v-model="form.staffOrder"></b-form-checkbox>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Má nemandi panta</td>
          <td>
            <b-form-group label-for="nemPanta">
              <b-form-checkbox id="nemPanta" name="nemPanta" v-model="form.studentOrder"></b-form-checkbox>
            </b-form-group>
          </td>
        </tr>
        <tr v-if="id">
          <td>Virkt</td>
          <td>
            <b-form-group label-for="virkt">
              <b-form-checkbox id="virkt" name="virkt" v-model="form.active"></b-form-checkbox>
            </b-form-group>
          </td>
        </tr>
      </table>

      <div class="alert alert-info p-1" style="font-size: 13px; margin-top: -20px" v-if="id">
        <i class="fa fa-info-circle"></i>
        Ef óvirkja eða eyða á búnaði munu þeir sem eiga pantanir í framtíð fá póst þar sem þeir eru látnir vita að búnaði hafi verið
        eytt/óvirkjaður.
      </div>
      <b-btn variant="primary" class="mr-2" v-if="canEdit" @click="validateBeforeSubmit">
        <span v-if="id">Breyta</span>
        <span v-else>Stofna</span>
      </b-btn>
      <b-btn variant="dark" @click="closeSlidebar">Hætta við</b-btn>
      <b-btn variant="danger" class="float-right" v-if="canEdit && id" @click="deleteEquipment"> Eyða </b-btn>
    </b-form>
  </Slidebar>
</template>

<script>
import structure from '@/api/structure';
import { mapActions, mapGetters } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';

export default {
  name: 'equipment-slidebar',
  props: ['item', 'large', 'extraLarge'],
  components: {
    Slidebar,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      loading: false,
      submitted: false,
      id: null,
      staff: [],
      form: {
        name: [{ name: '' }],
        description: '',
        location: '',
        staffOrder: true,
        studentOrder: false,
        active: true,
      },
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    addNew() {
      this.form.name.push({ name: '' });
    },
    deleteItem(idx) {
      this.form.name.splice(idx, 1);
    },
    async loadEquipmentDetail() {
      try {
        this.loading = true;
        const response = await structure.getEquipmentDetail(this.id);
        if (response.data.items && response.data.items.length > 0) {
          const item = response.data.items[0];
          this.form.name = [{ name: item.heiti }];
          this.form.description = item.lysing;
          this.form.location = item.stadsetning;
          this.form.staffOrder = item.ma_starfsmadur_panta === 1;
          this.form.studentOrder = item.ma_nemandi_panta === 1;
          this.form.active = item.virkt === 1;
        }
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.saving = true;
          const names = this.form.name.map(x => x.name).join(',');
          const promises = [];
          this.form.name.forEach(e => {
            const data = {
              name: e.name,
              description: this.form.description,
              location: this.form.location,
              staffOrder: this.form.staffOrder ? 1 : 0,
              studentOrder: this.form.studentOrder ? 1 : 0,
              active: this.form.active ? 1 : 0,
            };
            if (this.id) {
              promises.push(structure.updateEquipment(this.id, data));
            } else {
              promises.push(structure.createEquipment(data));
            }
          });
          Promise.all(promises).then(() => {
            if (this.id) {
              this.displaySuccess(`${names} hefur verið breytt.`);
            } else {
              this.displaySuccess(`${names} hefur verið stofnuð.`);
            }
            this.closeSlidebar();
          });
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.data && e.response.data.error) {
            this.displayError(e.response.data.error);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.saving = false;
      }
    },
    async deleteEquipment() {
      try {
        const conf = confirm(`Ert þú viss um að eyða ${this.item.heiti}?`); // eslint-disable-line
        if (conf) {
          await structure.updateEquipment(this.id, { delete: 1 });
          this.displaySuccess(`${this.item.heiti} hefur verið eytt.`);
          this.closeSlidebar();
        }
      } catch (e) {
        // Aðgerð mistókst.
        this.$log.debug(e);
        if (e.response && e.response.data && e.response.data.error) {
          this.displayError(e.response.data.error);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    item() {
      this.form = {
        name: [{ name: '' }],
        description: '',
        location: '',
        staffOrder: true,
        studentOrder: false,
        active: true,
      };
      this.id = null;
      if (this.item && this.item.bunadur_id) {
        this.id = this.item.bunadur_id;
        this.loadEquipmentDetail();
      }
      /* this.$nextTick(() => {
        if (this.$refs.focusInput) {
          this.$refs.focusInput.focus();
        }
      }); */
    },
  },
  created() {
    this.form = {
      name: [{ name: '' }],
      description: '',
      location: '',
      staffOrder: true,
      studentOrder: false,
      active: true,
    };
    this.id = null;
    if (this.item && this.item.bunadur_id) {
      this.id = this.item.bunadur_id;
      this.loadEquipmentDetail();
    }
    /* this.$nextTick(() => {
      if (this.$refs.focusInput) {
        this.$refs.focusInput.focus();
      }
    }); */
  },
};
</script>

<style lang="scss"></style>
