<template>
  <tbody style="border-width: 1px">
    <tr>
      <td v-if="canEdit" style="width: 2%">
        <b-form-checkbox
          v-model="selected"
          @change="updateSelected"
          :disabled="item.fj_hopa_aetlad <= item.fj_hopa_stofnad || worker"
        ></b-form-checkbox>
      </td>
      <td>{{ item.namsgrein_afanganr }}</td>
      <td class="text-right cursor-pointer" @click="toggleShow()" title="Skoða hópa">
        {{ item.fj_hopa_stofnad }}
        <i class="fa text-primary" :class="{ 'fa-angle-up': showGroups, 'fa-angle-down': !showGroups }"></i>
      </td>
      <td class="text-right">{{ item.fj_hopa_aetlad }}</td>
      <td class="text-right">
        {{ item.fj_nemenda }}
        <i class="fa fa-search text-primary cursor-pointer" @click="openStudentSlidebar(1)"></i>
      </td>
      <td class="text-right">
        {{ item.fj_nema_adalval }}
        <i class="fa fa-search text-primary cursor-pointer" @click="openStudentSlidebar(2)"></i>
      </td>
      <td class="text-right">
        {{ item.fj_nema_varaval }}
        <i class="fa fa-search text-primary cursor-pointer" @click="openStudentSlidebar(3)"></i>
      </td>
      <td class="text-right">
        {{ item.fj_nema_aetlun }}
        <i class="fa fa-search text-primary cursor-pointer" @click="openStudentSlidebar(6)"></i>
      </td>
      <td class="text-right">
        {{ item.fj_nema_ekki_i_hop }}
        <i class="fa fa-search text-primary cursor-pointer" @click="openStudentSlidebar(4)"></i>
      </td>
      <td class="text-right">
        {{ item.fj_nema_i_hop }}
        <i class="fa fa-search text-primary cursor-pointer" @click="openStudentSlidebar(5)"></i>
      </td>
      <td class="text-center" v-if="canEdit">
        <b-btn variant="primary" class="btn-xs" :disabled="worker" @click="openCreateSlidebar()"><i class="fa fa-plus-square"></i></b-btn>
      </td>
      <td class="text-center" v-if="canEdit">
        <b-btn
          variant="primary"
          class="btn-xs"
          @click="openPlannedSlidebar()"
          :disabled="item.fj_hopa_aetlad <= item.fj_hopa_stofnad || worker"
          :title="`${item.fj_hopa_aetlad <= item.fj_hopa_stofnad ? 'Það eru nú þegar stofnaðir allir áætlaðir hópar' : ''}`"
          ><i class="fa fa-plus-square"></i
        ></b-btn>
      </td>
      <td></td>
    </tr>
    <tr v-if="showGroups">
      <td colspan="100%" style="padding: 20px 30px !important; background-color: #f9f9f9">
        <div style="margin-top: -10px; margin-bottom: 10px">Hópar</div>
        <i class="fa fa-spin fa-spinner" v-if="loading"></i>
        <div v-if="groups.length === 0 && !loading" style="font-size: 12px">Engir hópar fundust</div>
        <table class="table less-padding" v-if="groups.length > 0" style="font-size: 12px">
          <tr style="text-transform: uppercase">
            <th>Hópur</th>
            <th>Fj. nemenda</th>
            <th>Kennari</th>
            <th v-if="!afangaskoli">Bekkur</th>
            <th v-if="!afangaskoli">Bekkjarhópur</th>
            <th>Dags. frá</th>
            <th>Dags. til</th>
            <th>Lýsing</th>
            <th v-if="canEdit"></th>
          </tr>
          <tr v-for="g in groups" :key="g.hopur_id">
            <td>
              <b-link :to="{ name: 'Group', params: { id: g.hopur_id } }"> {{ g.hopur }}</b-link>
            </td>
            <td>{{ g.fj_nema_i_hop }}</td>
            <td>{{ g.kennari }}</td>
            <td v-if="!afangaskoli">{{ g.bekkur_heiti }}</td>
            <td v-if="!afangaskoli"><yes-no :item="g.bekkjarhopur"></yes-no></td>
            <td>{{ g.dags_fra | moment('DD.MM.YYYY') }}</td>
            <td>{{ g.dags_til | moment('DD.MM.YYYY') }}</td>
            <td>{{ g.lysing }}</td>
            <td v-if="canEdit">
              <b-btn variant="primary" class="btn-xs" @click="openCreateSlidebar(g.hopur_id)" style="font-size: 12px"
                ><i class="fa fa-pencil cursor-pointer"></i
              ></b-btn>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </tbody>
</template>

<script>
import terms from '@/api/terms';
import { mapGetters, mapActions } from 'vuex';

import YesNo from '@/components/common/YesNo.vue';

export default {
  name: 'groups-by-module-item',
  props: ['item', 'worker', 'defaultOpenGroup', 'canEdit'],
  components: {
    YesNo,
  },
  computed: {
    canRead() {
      return this.loggedInUserHasReadPermission('nemendahald_hopar');
    },
    afangaskoli() {
      return this.schoolSettings && this.schoolSettings.afangaskoli ? this.schoolSettings.afangaskoli === 1 : false;
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {
      showStudentSlidebar: false,
      selectedType: '',
      selected: false,
      showGroups: false,
      groups: [],
      loading: false,
    };
  },
  methods: {
    toggleShow() {
      this.showGroups = !this.showGroups;
      if (this.showGroups) {
        this.loadModule();
        this.$emit('updateGroups');
      }
    },
    async loadModule() {
      try {
        this.loading = true;
        const response = await terms.getTermModule(this.item.annir_afangar_id);
        this.groups = response.data.hopar;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    updateSelected() {
      this.$emit('updateSelected');
    },
    openStudentSlidebar(type) {
      this.$emit('openStudentSlidebar', { id: this.item.annir_afangar_id, type });
    },
    openPlannedSlidebar() {
      this.$emit('openPlannedSlidebar');
    },
    openCreateSlidebar(group) {
      this.$emit('openCreateSlidebar', { id: this.item.annir_afangar_id, group });
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    if (this.defaultOpenGroup) {
      this.showGroups = true;
      this.loadModule();
    }
  },
};
</script>
