<template>
  <div>
    <Loader v-if="loading" />
    <div v-if="!loading && grades.length === 0">Engar einkunnir fundust</div>
    <table class="table less-padding" v-if="grades.length > 0" style="font-size: 13px">
      <thead>
        <tr>
          <th>Heiti</th>
          <th>Einkunn</th>
          <th>Dagsetning</th>
          <th>Lokaeinkunn</th>
          <th>Umsögn/Athugasemd</th>
          <th>Skráð af</th>
        </tr>
      </thead>
      <tr v-for="grade in grades" :key="grade.einkunn_id">
        <td>{{ grade.heiti }}</td>
        <td>{{ grade.einkunn }}</td>
        <td>{{ grade.dags_einkunnar | moment('DD.MM.YYYY') }}</td>
        <td><span v-if="grade.lokaeinkunn === 1">Já</span></td>
        <td style="max-width: 100px"><div v-html="grade.athugasemd"></div></td>
        <td>{{ grade.skrad_af_nafn }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import students from '@/api/students';
import { mapActions, mapGetters } from 'vuex';
import Loader from '@/components/common/Loader.vue';

export default {
  name: 'study-record-grades',
  props: ['recordId'],
  components: {
    Loader,
  },
  computed: {
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {
      loading: false,
      grades: [],
    };
  },
  methods: {
    async loadGrades() {
      try {
        this.loading = false;
        const response = await students.getStudyRecordGrades(this.recordId);
        this.grades = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    recordId() {
      this.loadGrades();
    },
  },
  created() {
    this.loadGrades();
  },
};
</script>
<style></style>
