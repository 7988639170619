<template>
  <Slidebar @closeMe="closeSlidebar" :large="large" :extraLarge="extraLarge">
    <h4>Magnbreyta stofum</h4>
    <ul>
      <li v-for="item in items" :key="item.bunadur_id">{{ item.stofa_eink }} {{ item.stofa_heiti }}</li>
    </ul>
    <div>Veldu hverju þú vilt breyta</div>
    <v-select id="selectedType" name="selectedType" class="mb-3" v-model="selectedType" :options="types"> </v-select>
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <div v-if="selectedType && selectedType.id === 'active'">
        <b-form-group label-for="virkt" label="Virkt">
          <v-select id="virkt" name="virkt" v-model="active" :options="yesNo" :clearable="false"> </v-select>
        </b-form-group>
      </div>
      <div v-if="selectedType && selectedType.id === 'staffOrder'">
        <b-form-group label-for="starfsmPanta" label="Má starfsmaður panta">
          <v-select id="starfsmPanta" name="starfsmPanta" v-model="staffOrder" :options="yesNo" :clearable="false"> </v-select>
        </b-form-group>
      </div>
      <br />
      <b-btn variant="primary" class="mr-2" v-if="selectedType" @click="validateBeforeSubmit" :disabled="saving"> Breyta </b-btn>
      <b-btn variant="dark" @click="closeSlidebar" :disabled="saving">Hætta við</b-btn>
    </b-form>
  </Slidebar>
</template>

<script>
import structure from '@/api/structure';
import { mapActions, mapGetters } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';

export default {
  name: 'classroom-mass-editslidebar',
  props: ['items', 'large', 'extraLarge'],
  components: {
    Slidebar,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      loading: false,
      types: [
        { id: 'active', label: 'Virk/Óvirk' },
        { id: 'staffOrder', label: 'Má starfsmaður panta' },
      ],
      selectedType: '',
      active: { id: 0, label: 'Nei' },
      staffOrder: { id: 0, label: 'Nei' },
      submitted: false,
      saving: false,
      yesNo: [
        { id: 1, label: 'Já' },
        { id: 0, label: 'Nei' },
      ],
    };
  },
  methods: {
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.saving = true;
          const promises = [];
          this.items.forEach(i => {
            const data = {
              name: i.stofa_heiti,
              code: i.stofa_eink,
              building_id: i.bygging_id,
              type_id: i.teg_stofu_id,
              characteristics: i.serkenni,
              count: i.fjoldi,
              count_deviations: i.fravik,
              count_exam: i.fjoldi_i_profi,
              key: i.lykill_stofu,
              tel: i.simi_innanh,
              accessible: i.fatladir,
              active: i.virk,
              can_order: i.ma_panta,
            };

            const type = this.selectedType ? this.selectedType.id : '';
            switch (type) {
              case 'staffOrder':
                this.$set(data, 'can_order', this.staffOrder.id);
                break;
              case 'active':
                this.$set(data, 'active', this.active.id);
                break;
              default:
                break;
            }
            promises.push(structure.updateClassroom(i.stofa_id, data));
          });

          Promise.all(promises).then(() => {
            this.displaySuccess('Búnaði breytt');
            this.closeSlidebar();
          });
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.data && e.response.data.error) {
            this.displayError(e.response.data.error);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.saving = false;
      }
    },
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {},
  created() {},
};
</script>

<style lang="scss"></style>
