<template>
  <div>
    <div v-if="edit">
      <h5 style="margin-bottom: 0px">Setja stofu í boði</h5>
      <div class="pb-2">
        Hér er valið hvaða stofur verða í boði á kennslutímabilinu. Ath. ef stofa er ekki í boði á kennslutímabilinu er ekki hægt að velja
        hana í gerð stundatöflu.
      </div>
      <Shuttle :list="all" :value="selected" titleFrom="Fjöldi stofa sem eru ekki í boði" titleTo="Fjöldi stofa í boði"></Shuttle>
      <div style="margin-top: 10px" :class="{ 'float-right': this.onlyEdit }">
        <b-btn variant="primary" class="ml-2" @click="saveTermClassrooms()" :disabled="saving">
          <i class="fa fa-spin fa-spinner" v-if="saving"></i>Uppfæra</b-btn
        >
        <b-btn variant="dark" class="ml-2" @click="edit = false" :disabled="saving" v-if="!onlyEdit">
          <i class="fa fa-spin fa-spinner" v-if="saving"></i>Hætta við</b-btn
        >
      </div>
    </div>
    <div v-else>
      <div class="float-right" v-if="canEdit">
        <b-btn variant="primary" size="sm" @click="edit = true" style="margin-top: -25px; width: 150px"
          ><i class="fa fa-pencil"></i>Breyta</b-btn
        >
      </div>
      <div v-if="classrooms.length === 0">Engar stofur fundust</div>
      <table class="table less-padding" style="font-size: 13px" v-if="classrooms.length > 0">
        <thead>
          <tr class="table-search">
            <td colspan="2">
              <b-form-input v-model="search" style="height: 30px"></b-form-input>
            </td>
          </tr>
          <tr>
            <th>
              <LinkOrder title="Stofa" :filteredList="getList" :list="classrooms" column="stofa_eink" second_column="stofa_heiti" />
              ({{ classrooms.length }})
            </th>
            <th>
              <LinkOrder title="Heiti" :filteredList="getList" :list="classrooms" column="stofa_heiti" second_column="stofa_eink" />
              <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="cursor-pointer float-right">
                <i class="fa fa-file-excel-o excelText"></i>
              </download-csv>
            </th>
          </tr>
        </thead>
        <tr v-for="m in getPaginatedList" :key="m.stofa_id">
          <td>{{ m.stofa_eink }}</td>
          <td>{{ m.stofa_heiti }}</td>
        </tr>
      </table>

      <Pagination
        :currentPage="currentPage"
        :totalRows="classrooms.length"
        :perPage="perPage"
        v-if="classrooms.length > 20"
        @change-page="changePage"
        @change-limit="changeLimit"
      />
    </div>
  </div>
</template>

<script>
import terms from '@/api/terms';
import structure from '@/api/structure';
import { mapActions, mapGetters } from 'vuex';
import Shuttle from '@/components/common/Shuttle.vue';
import Pagination from '@/components/common/Pagination.vue';
import moment from 'moment';
import LinkOrder from '@/components/common/LinkOrder.vue';

export default {
  name: 'term-classrooms',
  props: ['id', 'item', 'onlyEdit', 'termPassed'],
  components: {
    Shuttle,
    Pagination,
    LinkOrder,
  },
  computed: {
    getExcelData() {
      const newList = [
        [],
        ['Stofur í boði'],
        [`${this.item.undirsk_heiti}-${this.item.onn_heiti}`],
        [],
        ['Stofa', 'Heiti'],
        ...this.getList.map(x => [x.stofa_eink, x.stofa_heiti]),
      ];
      return newList;
    },
    getExcelFileName() {
      return `stofur_i_bodi_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    getList() {
      return this.classrooms.filter(
        x =>
          this.search.length === 0 ||
          (x.stofa_eink || '').toUpperCase().startsWith(this.search.toUpperCase()) ||
          (x.stofa_heiti || '').toUpperCase().startsWith(this.search.toUpperCase()),
      );
    },
    getPaginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.getList.slice(start, end);
    },
    canRead() {
      return this.loggedInUserHasReadPermission('skolahald_annir');
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_annir') && !this.termPassed;
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      perPage: 20,
      currentPage: 1,
      loading: false,
      classrooms: [],
      selected: [],
      all: [],
      saving: false,
      edit: false,
      search: '',
    };
  },
  methods: {
    async loadClassrooms() {
      if (this.canRead) {
        try {
          this.loading = true;
          const response = await terms.getTermClassrooms(this.id);
          this.selected = response.data.items.map(x => x.stofa_id);
          this.classrooms = response.data.items;
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading = false;
        }
      }
    },
    async loadAllClassrooms() {
      if (this.canRead) {
        try {
          this.loading = true;
          const response = await structure.getClassroomList({ active: 1 });
          this.all = response.data.items.map(x => ({
            id: x.stofa_id,
            name: `${x.stofa_eink}, ${x.stofa_heiti}`,
          }));
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading = false;
        }
      }
    },
    async saveTermClassrooms() {
      try {
        this.saving = true;
        const data = {
          classrooms: this.selected.join(','),
        };
        await terms.setTermClassrooms(this.id, data);
        this.displaySuccess('Stofur vistaðir');
        this.loadClassrooms();
        this.$emit('success');
      } catch (e) {
        if (e.response && e.response.data && e.response.data.error) {
          this.displayError(e.response.data.error);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } finally {
        this.saving = false;
      }
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    id() {
      this.loadClassrooms();
    },
  },
  created() {
    if (this.onlyEdit) {
      this.edit = true;
    }
    if (this.id) {
      this.loadClassrooms();
    }
    this.loadAllClassrooms();
  },
};
</script>
