<template>
  <Slidebar @closeMe="closeSlidebar" :large="large" :extraLarge="extraLarge">
    <h4>Úrsögn úr áfanga<loader v-if="loading.terms"></loader>
    </h4>
    <span v-if="!showInfo">
      <div class="pb-2" v-if="terms.length > 0">Smelltu á þann áfanga sem á að skrá viðkomandi nemanda úr.</div>
      <b-row v-if="terms.length > 0" class="pb-3">
        <b-col class="pl-0">
          <b-form-group :state="saving && errors.has('selectedTerm') ? false : ''"
            :invalid-feedback="errors.first('onn')">
            <label for="selectedTerm"> Önn </label>
            <v-select id="onn" name="onn" v-model="selectedTerm" :options="terms" :clearable="false" label="label"
              @input="loadResignationList()">
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <loader v-if="loading.items"></loader>
        <table class="table no-border" v-if="terms.length > 0 && !loading.items">
          <tr v-for="(i, idx) in items" :key="`c-${idx}`">
            <td style="width: 7%;">
              <b-form-checkbox @change="updateSelected()" v-model="i.selected" />
            </td>
            <td class="studentStudyRecordOlokid">{{ i.namsgrein_afanganr }} {{ i.kodi }}</td>
          </tr>
          <tr>
            <td>Dags</td>
            <td>
              <datepicker id="date" name="date" v-model="form.date" :language="lang" :monday-first="true"
                format="dd.MM.yyyy" :typeable="false" :clear-button="false" ref="dateOpenPickr"></datepicker>
            </td>
          </tr>
          <tr>
            <td>Staða áfanga</td>
            <td>
              <v-select id="moduleStatuses" name="moduleStatuses" v-model="form.moduleStatus" :clearable="false"
                :options="moduleStatuses" label="heiti">
              </v-select>
            </td>
          </tr>
          <tr>
            <td>Einkunn</td>
            <td>
              <b-form-input id="grade" name="grade" v-model="form.grade" maxLength="10"></b-form-input>
            </td>
          </tr>
          <tr>
            <td>Athugasemd:</td>
            <td>
              <b-form-group>
                <b-form-input v-model="form.comment" :maxLength="2000" />
              </b-form-group>
            </td>
          </tr>
          <tr>
            <td style="width:20%">Nota villutékk:
              <i class="fa fa-info-circle" v-b-tooltip
                title="Ef það á að hunsa t.d. undanfarareglur þá er sett „Nei“"></i>
            </td>
            <td>
              <b-form-group>
                <v-select id="errorCheck" name="errorCheck" v-model="form.errorCheck" :clearable="false" :options="yesNo">
                </v-select>
              </b-form-group>
            </td>
          </tr>
          <tr>
            <td>Stillingar:
            </td>
            <td>
              <b-form-group>
                <b-form-checkbox id="deleteAbsences" name="deleteAbsences" v-model="form.deleteAbsences"> Eyða
                  fjarvistum</b-form-checkbox>
                <b-form-checkbox id="deleteCourseStructure" name="deleteCourseStructure"
                  v-model="form.deleteCourseStructure"> Eyða áfangakeðju?</b-form-checkbox>
              </b-form-group>
            </td>
          </tr>
        </table>
        <b-col class="pl-0">
          <div>
            <Loader v-if="saving"></Loader>
            <b-btn variant="primary" size="sm" v-if="!loading.items && canEdit && terms.length > 0"
              @click="resignateSelected()" :disabled="saving">
              Segja úr
            </b-btn>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="noCourseSelected" class="pt-2">
        <div class="alert alert-danger mb-0" alert>
          <i class="fa fa-exclamation-triangle"></i> Velja þarf áfanga.
        </div>
      </b-row>
      <div class="mt-3" v-if="terms.length === 0"><i>Engin önn fannst með áfanga í stöðunni ólokið.</i></div>
      <!-- <div style="font-size: 13px">
          <b>Staða áfanga getur verið:</b><br />
          <span class="studentStudyRecordOlokid">Ólokið (Ó)</span>, &nbsp; <span class="studentStudyRecordMetid">Metið (M)</span>, &nbsp;
          <span class="studentStudyRecordLokid">Lokið (L)</span>, &nbsp;
          <span class="studentStudyRecordLokidAnEininga">Lokið án eininga(LE)</span>, &nbsp;
          <span class="studentStudyRecordFall">Mat Fall(MF)</span>, &nbsp; <span class="studentStudyRecordFall">Fall(F)</span>, &nbsp;
          <span class="studentStudyRecordFall">Fall á önn(FÖ)</span><br />
        </div> -->
    </span>
    <span v-if="showInfo">
      <div class="pb-2 pt-2" v-for="(i, idx) in info" :key="`c-${idx}`">
        <div><b>{{ i.data.items[0].afangi }}</b>: Úrsögn úr áfanga frá og með <b>{{ i.data.items[0].dags }} </b></div>
        <div class="pt-1"><b>Athugasemd:</b></div>
        <div class="pb-1">Póstur hefur veirð sendur á eftirfarandi netföng:</div>
        <div class="pb-1" v-if="i.data.items[0].umsjonarkennarar.length === 0">Umsjónarkennari: <i>Ekki skráð</i></div>
        <div class="pb-1" v-if="i.data.items[0].umsjonarkennarar.length > 0">Umsjónarkennari:
          <span v-for="t in i.data.items[0].umsjonarkennarar" :key="t.nafn">
            {{ t.nafn }} ({{ t.netfang }})
          </span>
        </div>
        <div class="pb-1" v-if="i.data.items[0].askrifendur.length === 0">Starfsmenn: ><i>Ekki skráð</i></div>
        <div class="pb-1" v-if="i.data.items[0].askrifendur.length > 0">Starfsmenn:
          <span v-for="t in i.data.items[0].askrifendur" :key="t.nafn">
            {{ t.nafn }} ({{ t.netfang }})
          </span>
        </div>
        <div class="pb-1" v-if="i.data.items[0].forradamenn.length > 0">Forráðamenn:
          <span v-for="t in i.data.items[0].forradamenn" :key="t.nafn">
            {{ t.nafn }} {{ t.netfang }}
          </span>
        </div>
        <div>Nemandi: {{ i.data.items[0].nemandi }} ({{ i.data.items[0].nemandi_netfang }}) </div>

      </div>

    </span>
  </Slidebar>
</template>
  
<script>
import { mapActions, mapGetters } from 'vuex';
import Datepicker from 'vuejs-datepicker';
import moment from 'moment';
import { is } from 'vuejs-datepicker/dist/locale';
import Slidebar from '@/components/common/Slidebar.vue';
import Loader from '@/components/common/Loader.vue';
import terms from '@/api/terms';
import students from '@/api/students';
import common from '@/api/common';


export default {
  name: 'resignation-from-courses-slidebar',
  props: ['studentId', 'large', 'extraLarge'],
  components: {
    Slidebar,
    Loader,
    Datepicker,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('nemendahald_nemendur');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      selectedTerm: '',
      terms: [],
      items: [],
      selectedItems: [],
      loading: {
        terms: false,
        items: false,
      },
      saving: false,
      yesNo: [
        { id: 0, label: 'Nei' },
        { id: 1, label: 'Já' },
      ],
      lang: is,
      form: {
        date: moment().toDate(),
        moduleStatus: '',
        grade: '',
        comment: '',
        errorCheck: { id: 1, label: 'Já' },
        deleteAbsences: false,
        deleteCourseStructure: true,
      },
      noCourseSelected: false,
      showInfo: false,
      info: [],
      madeChanges: false,
    };
  },
  methods: {
    async loadTerms() {
      try {
        this.loading.terms = true;
        this.firsttime = true;
        const params = {
          studentHasUnconfirmedSelection: this.studentId,
          eval_terms: 0, // matsannir
        };
        const response = await terms.getTerms(params);
        this.terms = response.data.items;
        this.terms = response.data.items
          .filter(x => x.onn_id !== parseInt(this.id, 10))
          .map(x => ({
            ...x,
            label: `${x.onn_eink} - ${x.onn_heiti}`,
          }));
        // label: `${x.onn_eink} - ${x.onn_heiti} - ${x.undirsk_heiti}`,
        if (this.terms.length > 0) {
          this.selectedTerm = this.terms[0];
          this.loadResignationList();
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.terms = false;
      }
    },
    async loadResignationList() {
      try {
        this.loading.items = true;
        const response = await students.getStudentsCourseResignation(this.studentId, { termId: this.selectedTerm.onn_id });
        this.items = response.data.items.map(x => ({
          ...x,
          selected: false,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.items = false;
      }
    },
    async loadModuleStatuses() {
      try {
        const response = await common.getModuleStatuses();
        this.moduleStatuses = response.data.items.filter(x => x.stada_afanga === 2 || x.stada_afanga === 5);
        this.form.moduleStatus = this.moduleStatuses[1]; // úrsögn default valið
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async updateSelected() {
      if ((this.items.filter(x => x.selected === true)).length > 0) {
        this.noCourseSelected = false;
      }
    },
    async resignateSelected() {
      this.saving = true;
      let data;
      let itemsList = [];
      if (this.items.filter(x => x.selected === true).length === 0) {
        this.noCourseSelected = true;
      } else {
        try {
          this.noCourseSelected = false;
          itemsList = this.items.filter(x => x.selected === true);
          const promises = [];
          for (let i = 0; i < itemsList.length; i += 1) {
            data = {
              ferill_id: itemsList[i].ferill_id,
              dags: moment(this.form.date).format('DD.MM.YYYY'),
              stada_afanga: this.form.moduleStatus.stada_afanga,
              einkunn: this.form.grade,
              athugasemd: this.form.comment,
              villucheck: this.form.errorCheck.id,
              eyda_fjarvistum: this.form.deleteAbsences ? 1 : 0,
              eyda_afangakedju: this.form.deleteCourseStructure ? 1 : 0,
            }
            promises.push(students.postStudentsCourseResignation(this.studentId, data));
          }
          Promise.all(promises).then((response2) => {
            this.info = response2;
            this.showInfo = true;
            this.displaySuccess('Úrsögn úr áföngum tókst');
            // this.$emit('closeAndReloadSlidebar'); // Viljum birta hverjir fengu póst
          },
            (e) => {
              if (e.response && e.response.data && e.response.data.error) {
                this.displayError(e.response.data.error);
              } else {
                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
              }
            },
          );
        } catch (e) {
          // Aðgerð mistókst.
          this.$log.debug(e);
          if (e.error) {
            this.displayError(`Óvænt villa (${e.error ? e.error : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        } finally {
          this.saving = false;
        }
      }
    },

    closeSlidebar() {
      if (this.showInfo) {
        this.$emit('closeAndReloadSlidebar');
      } else {
        this.$emit('closeSlidebar'); // viljum refresha ef breyting varð.
      }
    },
    closeAndReloadSlidebar() {
      this.$emit('closeAndReloadSlidebar');
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadTerms();
    this.loadModuleStatuses();
  },
};
</script>
  