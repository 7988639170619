<template>
  <div>
    <b-row class="mb-0">
      <b-col>
        <h5 style="font-size: 16px">Lýsingar</h5>
        <div v-if="!loading && list.length === 0"><i>Engar lýsingar skráðar</i></div>
      </b-col>
      <b-col class="pl-0 pr-0" v-if="canEdit">
        <b-btn variant="primary" size="sm" class="float-right" style="margin-bottom: -10px" @click="openEditSlidebar()"
          >Bæta við lýsingu</b-btn
        >
        <div v-if="!loading && list.length > 1" class="float-right mr-3">
          <b-form-checkbox v-model="showAll" class="d-inline-block"></b-form-checkbox> Sjá allt
        </div>
      </b-col>
    </b-row>

    <div class="title_box" v-for="item in list" :key="item.eink_mals">
      <div class="title">
        {{ item.heiti_mals }}
        <b-btn class="btn-xs" variant="primary" @click="openEditSlidebar(item)"><i class="fa fa-pencil"></i></b-btn>
      </div>
      <div class="content" :class="{ limitHeight: !showAll }">
        <div v-html="item.lysing"></div>
      </div>
    </div>

    <module-description-slidebar
      v-if="showEditSlidebar"
      @closeSlidebar="closeSlidebar"
      :large="true"
      :id="id"
      :item="selectedItem"
    ></module-description-slidebar>
  </div>
</template>

<script>
import curriculum from '@/api/curriculum';

import { mapGetters, mapActions } from 'vuex';

import ModuleDescriptionSlidebar from '@/components/curriculum/modules/ModuleDescriptionSlidebar.vue';

export default {
  name: 'module-description',
  components: {
    ModuleDescriptionSlidebar,
  },
  props: ['id'],
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_afangar');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('namskra_afangar');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      loading: false,
      list: [],
      showEditSlidebar: false,
      selectedItem: {},
      showAll: false,
    };
  },
  methods: {
    openEditSlidebar(item) {
      this.selectedItem = item || {};
      this.showEditSlidebar = true;
    },
    closeSlidebar() {
      this.selectedItem = {};
      this.showEditSlidebar = false;
      this.loadList();
    },
    async loadList() {
      try {
        this.loading = true;
        const response = await curriculum.getModuleDescriptions(this.id);
        this.list = response.data.items;
        if (this.list.length <= 1) {
          this.showAll = true;
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadList();
  },
};
</script>
<style lang="scss">
.title_box {
  margin-top: 20px;
  border: 1px solid #ddd;
  .title {
    text-transform: uppercase;
    overflow: visible;
    position: relative;
    top: -10px;
    margin-left: 1em;
    display: inline;
    background-color: white;
    .btn {
      font-size: 13px;
      margin-top: -10px;
    }
  }
  .content {
    padding: 15px;
    padding-top: 0px;
    margin-top: -10px;
    &.limitHeight {
      max-height: 80px;
      overflow-y: hidden;
      &:hover {
        max-height: 100%;
      }
    }
  }
}
</style>
