<template>
  <page>
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <PageTitle title="Töflubreytingar nemanda" />
    <b-row style="margin-top: -10px">
      <b-col xs="12" sm="12" md="8">
        <StudentInfo :id="student" />
      </b-col>
      <b-col xs="12" sm="12" md="4">
        <h5>Núverandi staða nemanda</h5>
        <div>
          Fjöldi eininga á tímabili: {{ currentCredits }}
          <b-btn
            variant="primary"
            class="btn-xs"
            style="font-size: 10px; margin-top: -5px"
            title="Skoða námsferil nemanda"
            v-b-tooltip.hover
            @click="openStudyRecordSlidebar()"
          >
            <i class="fa fa-list"> </i
          ></b-btn>
        </div>
        <div v-if="dateFrom || dateTo">
          <div v-if="filterByDate" class="alert alert-info p-2 mb-0">
            <i class="fa fa-exclamation-triangle"></i> Verið er að sía beiðnir eftir
            <span v-if="dateFrom">dags. frá: {{ dateFrom | moment('DD.MM.YYYY') }} <span v-if="dateTo"> og </span></span>
            <span v-if="dateTo">dags. til: {{ dateTo | moment('DD.MM.YYYY') }}</span>
            <div>
              <b-link @click="filterByDate = false">Smelltu hér til að sjá beiðnir óháð dagsetningum</b-link>
            </div>
          </div>
          <div v-else class="alert alert-info p-2 mb-0">
            <div>
              <b-link @click="filterByDate = true">Smelltu hér til að sía beiðnir aftur eftir dagsetningum</b-link>
            </div>
          </div>
        </div>
        <div class="mt-2">
          <b-btn variant="primary" size="sm" @click="openHistorySlidebar()">Skoða dagbók</b-btn>
          <b-btn variant="primary" size="sm" class="float-right" @click="getBack()">Snúa aftur í leit</b-btn>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col xs="6" sm="6" md="3">
        <b-form-group label="Kennslutímabil" label-for="term">
          <v-select id="term" name="term" v-model="selectedTerm" :options="getTerms" :clearable="false"> </v-select>
        </b-form-group>
      </b-col>
      <b-col xs="6" sm="6" md="3" class="pt-4">
        <button type="button" class="btn btn-primary btn-sm" @click="changeTerm()">Velja</button>
      </b-col>
    </b-row>
    <Loader v-if="loading.data" />

    <b-row class="pt-2" v-if="!fromStudentView">
      <b-col xs="12" sm="12" md="6">
        <h5 v-if="canEdit">Breytingar á námsferli</h5>
        <b-row v-if="canEdit">
          <b-col class="pl-0">
            <div><strong>Fjarlægja áfanga: </strong><i class="fa fa-spin fa-spinner" v-if="loading.modulesRemove" /></div>
            <v-select id="moduleRemove" name="moduleRemove" v-model="selectedModulesRemove" :options="getModulesToRemove"> </v-select>
            <div v-if="selectedModulesRemove" style="font-size: 13px">
              <div>
                <b-form-checkbox class="d-inline-block" v-model="selectedModulesRemoveSettings.history" /> Geyma sögu

                <i
                  class="fa fa-info-circle"
                  title="Geyma sögu þýðir að ef nemandi er skráður í hóp þá er
                  skráð úrsögn á þann hóp og áfanginn helst í námsferli í stöðunni 'Staðfest val'.
                  Ef það á ekki að geyma sögu þá er nemandanum eytt úr hópnum og áfanginn tekinn úr námsferli."
                  v-b-tooltip
                ></i>
              </div>
              <div>
                <b-form-checkbox class="d-inline-block" v-model="selectedModulesRemoveSettings.deleteChain" /> Eyða áfangakeðju?
                <i class="fa fa-info-circle" title="Á að eyða eftirförum áfangans ef þeir eru í námsferli nemanda." v-b-tooltip></i>
              </div>
              <b-btn variant="primary" class="btn-xs mt-2 mb-2" :disabled="removingModule" @click="removeModule()">
                <i class="fa fa-spin fa-spinner" v-if="removingModule"></i> Fjarlægja áfanga
              </b-btn>
            </div>
          </b-col>

          <b-col class="pl-0">
            <div><strong>Bæta við áfanga: </strong><i class="fa fa-spin fa-spinner" v-if="loading.modulesAdd" /></div>
            <v-select id="moduleAdd" name="moduleAdd" v-model="selectedModulesAdd" :options="getModulesToAdd"> </v-select>
            <div v-if="selectedModulesAdd" style="font-size: 13px; padding-top: 5px">
              <div>
                <b-form-checkbox class="d-inline-block" v-model="selectedModulesAddSettings.history" /> Geyma sögu
                <i
                  class="fa fa-info-circle"
                  title="Geyma sögu þýðir að nemandi er skráður með úrsögn á gamla
                  hópinn ef hann er að skipta um hóp. Ef verið er að bæta við nýjum áfanga í námsferil hefur
                  geyma sögu engin áhrif."
                  v-b-tooltip
                ></i>
              </div>
              <div>
                <b-form-checkbox class="d-inline-block" v-model="selectedModulesAddSettings.precursor" /> Virða undanfarareglur
                <i
                  class="fa fa-info-circle"
                  title="Á að hlusta á undanfara og hámarksfjölda eininga/kennslustunda
                  þegar áfanga er bætt við? Ef það á að skrá nemandann í áfangann óháð öllu þá er afhakað hérna.
                  Ef ekki á að virða undanfara er hægt að setja ástæðu fyrir því í athugasemd.
                  Hún er síðan vistuð á námsferilsfærsluna."
                  v-b-tooltip
                ></i>
                <b-form-input
                  v-model="selectedModulesAddSettings.comment"
                  :disabled="selectedModulesAddSettings.precursor"
                  maxLength="200"
                  class="d-inline-block"
                  placeholder="athugasemd"
                  style="font-size: 10px; heigth: 20px; padding: 0px; width: auto; margin-left: 5px"
                ></b-form-input>
              </div>
              <div>
                <b-form-checkbox class="d-inline-block" v-model="selectedModulesAddSettings.addGroup" /> Bæta við hóp
                <i
                  class="fa fa-info-circle"
                  title="Ef hakað er í þetta þá getur nemandi verið í fleiri en einum hóp
                  í sama áfanga á sama tíma. Ef verið er að skrá nemandann í annan hóp en hann er skráður í og hann
                  á að haldast í gamla þá er þetta 'Já' en ef hann á að fara úr gamla hópnum þá er þetta 'Nei'"
                  v-b-tooltip
                ></i>
              </div>
              <b-btn variant="primary" class="btn-xs mt-2 mb-2" :disabled="addingModule" @click="addModule()">
                <i class="fa fa-spin fa-spinner" v-if="addingModule"></i> Bæta áfanga við
              </b-btn>
            </div>
          </b-col>
        </b-row>
        <b-row
          v-if="
            selectedModulesAdd &&
            selectedModulesAdd.max_fj_nema &&
            selectedModulesAdd.max_fj_nema > 0 &&
            selectedModulesAdd.fjoldi >= selectedModulesAdd.max_fj_nema
          "
        >
          <b-col class="pl-0">
            <div class="alert alert-danger p-1" style="font-size: 13px">
              <i class="fa fa-exclamation-triangle"></i> Ath. Valinn hópur er með skilgreinan hámarksfjölda nemanda
              {{ selectedModulesAdd.max_fj_nema }} og núverandi fjöldi nemenda er {{ selectedModulesAdd.fjoldi }}.
            </div>
          </b-col>
        </b-row>
        <b-row v-if="selectedModulesRemove && (selectedModulesRemove.fjarvist_til === 1 || selectedModulesRemove.einkunn_til === 1)">
          <b-col class="pl-0">
            <div class="alert alert-danger p-1" style="font-size: 13px">
              <div>
                <i class="fa fa-exclamation-triangle"></i> Ath. áfangi sem þú ert að velja að fjarlægja úr námsferli er með skráð gögn!
              </div>
              <div class="mb-2">
                Við það að fjarlægja áfangann úr námsferli eyðir þú þessum gögnum og það er ekki hægt að afturkalla þá aðgerð!
              </div>
              <table class="table no-border less-padding">
                <tr>
                  <th>Áfangi</th>
                  <th>Einkunnir</th>
                  <th>Fjarvistir</th>
                </tr>
                <tr>
                  <td>{{ selectedModulesRemove.namsgrein_afanganr }}</td>
                  <td>
                    <yes-no :item="selectedModulesRemove.einkunn_til"></yes-no>
                    <i
                      class="cursor-pointer fa fa-search pl-1"
                      title="Skoða einkunnir nemanda"
                      v-b-tooltip
                      @click="openStudyRecordGradesSlidebar(selectedModulesRemove)"
                    ></i>
                  </td>
                  <td>
                    <yes-no :item="selectedModulesRemove.fjarvist_til"></yes-no>
                    <i
                      class="cursor-pointer fa fa-search pl-1"
                      title="Skoða viðveru nemanda"
                      v-b-tooltip
                      @click="openStudentAttendanceSlidebar()"
                    ></i>
                  </td>
                </tr>
              </table>
            </div>
          </b-col>
        </b-row>

        <h5 class="pt-2">Óafgreiddar töflubreytingar nemanda</h5>
        <div v-if="getList.length === 0 && !loading.data">Engar óafgreiddar töflubreytingar</div>
        <table class="table table-hover no-border less-padding" v-if="getList.length > 0" style="font-size: 14px">
          <thead>
            <tr>
              <th class="text-center pl-0"><i class="fa fa-fw fa-check text-success" v-b-tooltip title="Samþykkja"></i></th>
              <th class="text-center"><i class="fa fa-fw fa-remove text-danger" v-b-tooltip title="Hafna"></i></th>
              <th>
                <LinkOrder title="Aðgerð" :filteredList="getList" :list="list" column="adgerd" />
              </th>
              <th>
                <LinkOrder title="Áfangi" :filteredList="getList" :list="list" column="namsgrein_afanganr" />
              </th>
              <th class="text-center">
                <LinkOrder title="Hópur" :filteredList="getList" :list="list" column="hopur" />
              </th>
              <th>
                <LinkOrder title="Dags.skráð" :filteredList="getList" :list="list" column="dags_sort" />
              </th>
              <th class="text-center">
                <i class="fa fa-fw fa-users" aria-hidden="true" v-b-tooltip title="Fjöldi nemenda í hópnum"></i>
              </th>
              <th class="text-center">
                <i
                  class="fa fa-fw fa-plus-circle text-success"
                  aria-hidden="true"
                  v-b-tooltip
                  title="Fjöldi óafgreiddra beiðna um að bæta áfanganum við"
                ></i>
              </th>
              <th class="text-center">
                <i
                  class="fa fa-fw fa-trash text-danger"
                  aria-hidden="true"
                  v-b-tooltip
                  title="Fjöldi óafgreiddra beiðna um að fjarlægja áfangann"
                ></i>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in getList" :key="index">
              <td class="text-center">
                <b-form-checkbox v-model="item.confirm" @input="changeSelection(item, 0)" :disabled="!canEdit" />
              </td>
              <td class="text-center">
                <b-form-checkbox v-model="item.reject" @input="changeSelection(item, 1)" :disabled="!canEdit" />
              </td>
              <td class="text-center">
                <i
                  class="fa fa-fw"
                  :class="{ 'fa-trash text-danger': item.adgerd === 0, 'fa-plus-circle text-success': item.adgerd === 1 }"
                ></i>
                <i
                  class="fa fa-fw fa-info-circle"
                  :class="{ invisible: !item.skilabod_nemandi }"
                  v-b-popover.hover.top="item.skilabod_nemandi"
                  title="Athugasemd nemanda"
                ></i>
              </td>
              <td>
                <b-link
                  :to="{ name: 'ModuleRequests', params: { moduleTermId: item.annir_afangar_id } }"
                  title="Skoða áfangabeiðnir"
                  v-b-tooltip
                >
                  <div @mouseenter="onHover(item, 1)" @mouseleave="onHover(item, 0)">
                    {{ item.namsgrein_afanganr }}
                  </div>
                </b-link>
              </td>
              <td class="text-center">
                <div class="d-inline-block cursor-pointer" v-b-tooltip title="Skoða hóp" @click="openGroupSlidebar(item)">
                  {{ item.hopur }}
                </div>
                <a class="cursor-pointer" :class="{ invisible: item.adgerd === 0 }" v-if="canEdit" @click="openEditGroupSlidebar(item)">
                  <i class="fa fa-fw fa-pencil"></i>
                </a>
              </td>
              <td>{{ item.dags | moment('DD.MM.YYYY HH:mm') }}</td>
              <td class="text-center" :class="{ 'text-danger': item.max_fj_nema < item.fjoldi_hop }">
                {{ item.fjoldi_hop }}
                <i
                  class="fa fa-exclamation-triangle text-danger"
                  :title="`Hámarksfjölda í hóp er náð! Skilgreint hámark er ${item.max_fj_nema}`"
                  v-b-tooltip
                  v-if="item.max_fj_nema < item.fjoldi_hop"
                ></i>
              </td>
              <td class="text-center">{{ item.fjoldi_i_hop }}</td>
              <td class="text-center">{{ item.fjoldi_ur_hop }}</td>
            </tr>
          </tbody>
        </table>
        <b-btn variant="primary" class="mb-4" v-if="getList.length > 0 && !loading.data && canEdit" @click="processRequests()"
          >Staðfesta</b-btn
        >
        <br />
        <h5 v-if="!loading.data">Afgreiddar töflubreytingar</h5>
        <div v-if="getOld.length == 0 && !loading.data"><i translate>Engar afgreiddar töflubreytingar</i></div>
        <table class="table table-hover no-border less-padding" v-if="getOld.length > 0" style="font-size: 12px">
          <thead>
            <tr>
              <th class="pl-0">Aðgerð</th>
              <th>Áfangi</th>
              <th class="text-center">Hópur</th>
              <th class="text-center"><i class="fa fa-fw fa-users" aria-hidden="true" v-b-tooltip title="Fjöldi nemenda í hópnum"></i></th>
              <th class="text-center">
                <i
                  class="fa fa-fw fa-plus-circle text-success"
                  aria-hidden="true"
                  v-b-tooltip
                  title="Fjöldi beiðna um að bæta áfanganum við"
                ></i>
              </th>
              <th class="text-center">
                <i class="fa fa-fw fa-trash text-danger" aria-hidden="true" v-b-tooltip title="Fjöldi beiðna um að fjarlægja áfangann"></i>
              </th>
              <th class="text-center">Staða</th>
              <th>Aths.</th>
            </tr>
          </thead>
          <tbody v-for="(item, index) in getOld" :key="index" style="border: 0">
            <tr>
              <td>
                <i
                  class="fa fa-fw"
                  :class="{ 'fa-trash text-danger': item.adgerd === 0, 'fa-plus-circle text-success': item.adgerd === 1 }"
                ></i>
                <i
                  class="fa fa-fw fa-info-circle"
                  :class="{ invisible: !item.skilabod_nemandi }"
                  v-b-popover.hover.top="item.skilabod_nemandi"
                  title="Athugasemd nemanda"
                ></i>
              </td>
              <td>
                <b-link
                  :to="{ name: 'ModuleRequests', params: { moduleTermId: item.annir_afangar_id } }"
                  title="Skoða áfangabeiðnir"
                  v-b-tooltip
                >
                  <div @mouseenter="onHover(item, 1)" @mouseleave="onHover(item, 0)">
                    {{ item.namsgrein_afanganr }}
                  </div>
                </b-link>
              </td>
              <td class="text-center">
                <div class="d-inline-block cursor-pointer" v-b-tooltip title="Skoða hóp" @click="openGroupSlidebar(item)">
                  {{ item.hopur }}
                </div>
              </td>
              <td class="text-center">{{ item.fjoldi_hop }}</td>
              <td class="text-center">{{ item.fjoldi_i_hop }}</td>
              <td class="text-center">{{ item.fjoldi_ur_hop }}</td>
              <td class="text-center">
                <i class="fa fa-fw" :class="{ 'fa-remove text-danger': item.stada === 0, 'fa-check text-success': item.stada === 1 }"></i>
              </td>
              <td>
                <div v-b-popover.hover.top="item.skilabod_stm">
                  {{ item.skilabod_stm ? item.skilabod_stm.substring(0, 5) : '' }}
                  <span v-if="item.skilabod_stm && item.skilabod_stm.length > 5">...</span>
                  <i class="fa fa-fw fa-pencil cursor-pointer" v-if="canEdit" @click="commentModal(item)"></i>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2">Afgreitt: {{ item.afgreitt_dags | moment('DD.MM.YYYY HH:mm') }}</td>
              <td colspan="4">Afgreitt af: {{ item.afgreitt_nafn | moment('DD.MM.YYYY HH:mm') }}</td>
              <td colspan="2">Skráð: {{ item.dags | moment('DD.MM.YYYY HH:mm') }}</td>
            </tr>
          </tbody>
        </table>
      </b-col>
      <b-col xs="12" sm="12" md="6">
        <b-row style="padding-top: 2rem !important">
          <b-col class="pl-0">
            <div>
              <strong>Birta alla hópa í áfanga: </strong>
              <Loader v-if="loading.allGroups" />
            </div>
            <v-select
              id="allModules"
              name="allModules"
              v-model="seeAllGroups"
              multiple
              :options="allModules"
              label="namsgrein_afanganr"
              @input="updateGroupsAll"
              class="mb-2"
            >
            </v-select>
          </b-col>
        </b-row>
        <div v-if="currentDates.length > 1">
          Flýtileið á dagsetningar
          <i
            class="fa fa-info-circle"
            title="Upphafsdagsetning virkra hópa er ekki alltaf sú sama. Ef smellt er á dagsetninguna þá birtist stundataflan fyrir þá viku"
            v-b-tooltip
          ></i>
          :
          <a v-for="d in currentDates" :key="d" @click="selectDate(d)" class="cursor-pointer pr-3">{{ d }}</a>
        </div>
        <Timetable
          :defaultGroups="defaultGroups"
          :removeGroups="removeGroups"
          :addGroups="addGroups"
          :extraGroups="extraGroups"
          :showHover="showHoverGroup"
          :removeHover="removeHoverGroup"
          :teacherAddGroups="teacherAddGroups"
          :teacherRemoveGroups="teacherRemoveGroups"
          :goToDate="selectedGroupDate"
        />
        <div v-if="getFornamsAfangarEftir.length > 0" style="font-size: 12px" class="mt-2">
          <div>Fornámsáfangar á braut sem eru ekki í námsferli:</div>
          <span v-for="(item, idx) in getFornamsAfangarEftir" :key="`fornam-${idx}`">{{ item.namsgrein_afanganr }} </span>
        </div>
        <div v-if="getKjarniAfangarEftir.length > 0" style="font-size: 12px" class="mt-2">
          <div>Kjarnaáfangar á braut sem eru ekki í námsferli:</div>
          <span v-for="(item, idx) in getKjarniAfangarEftir" :key="`kjarni-${idx}`">{{ item.namsgrein_afanganr }} </span>
        </div>
        <div v-if="getKjorsvidAfangarEftir.length > 0" style="font-size: 12px" class="mt-2">
          <div>Kjörsviðsáfangar á braut sem eru ekki í námsferli:</div>
          <span v-for="(item, idx) in getKjorsvidAfangarEftir" :key="`kjorsvid-${idx}`">{{ item.namsgrein_afanganr }} </span>
        </div>
        <div v-if="getValAfangarEftir.length > 0" style="font-size: 12px" class="mt-2">
          <div>Valáfangar á braut sem eru ekki í námsferli:</div>
          <span v-for="(item, idx) in getValAfangarEftir" :key="`val-${idx}`">{{ item.namsgrein_afanganr }} </span>
        </div>
      </b-col>
    </b-row>
    <view-group-slidebar v-if="showGroupSlidebar" :item="selectedItem" @closeSlidebar="closeSlidebar"> </view-group-slidebar>
    <change-group-slidebar
      v-if="showEditGroupSlidebar"
      :item="selectedItem"
      :term="term"
      :student="student"
      :current="current"
      @closeSlidebar="closeAndReload"
    >
    </change-group-slidebar>
    <comment-modal ref="commentModal" @successful="loadData"></comment-modal>
    <term-slidebar :termId="term" :studentId="student" v-if="showStudyRecordSlidebar" @closeSlidebar="closeSlidebar"> </term-slidebar>

    <student-attendance-slidebar
      v-if="showStudentAttendanceSlidebar"
      :studentId="student"
      :termId="term"
      @closeSlidebar="closeSlidebar"
      @closeAndReload="closeAndReload"
    />
    <study-record-grades-slidebar
      v-if="showStudyRecordGradesSlidebar"
      :recordId="selectedItem && selectedItem.ferill_id"
      @closeSlidebar="closeSlidebar"
      @closeAndReload="closeAndReload"
    />
    <student-request-history-slidebar
      v-if="showHistorySlidebar"
      :id="student"
      @closeSlidebar="closeSlidebar"
      @closeAndReload="closeSlidebar"
    />
  </page>
</template>

<script>
import moment from 'moment';
import students from '@/api/students';
import school from '@/api/school';
import requests from '@/api/requests';
import terms from '@/api/terms';
import { mapGetters, mapActions } from 'vuex';

import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import PageTitle from '@/components/common/PageTitle.vue';
import Loader from '@/components/common/Loader.vue';
import Timetable from '@/components/requests/changeTimetableRequests/Timetable.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';
import YesNo from '@/components/common/YesNo.vue';

import ViewGroupSlidebar from '@/components/requests/changeTimetableRequests/ViewGroupSlidebar.vue';
import ChangeGroupSlidebar from '@/components/requests/changeTimetableRequests/ChangeGroupSlidebar.vue';
import CommentModal from '@/components/requests/changeTimetableRequests/CommentModal.vue';
import StudentRequestHistorySlidebar from '@/components/requests/changeTimetableRequests/StudentRequestHistorySlidebar.vue';
import StudentInfo from '@/components/common/StudentInfo.vue';
import TermSlidebar from '@/components/students/studyRecords/terms/TermSlidebar.vue';
import StudentAttendanceSlidebar from '@/components/students/attendance/StudentAttendanceSlidebar.vue';
import StudyRecordGradesSlidebar from '@/components/students/studyRecords/StudyRecordGradesSlidebar.vue';
// import TermHistory from '@/components/school/terms/TermHistory.vue';

export default {
  name: 'student-requests',
  components: {
    Breadcrumbs,
    PageTitle,
    Loader,
    YesNo,
    Timetable,
    ViewGroupSlidebar,
    ChangeGroupSlidebar,
    CommentModal,
    StudentInfo,
    LinkOrder,
    TermSlidebar,
    StudentAttendanceSlidebar,
    StudyRecordGradesSlidebar,
    StudentRequestHistorySlidebar,
  },
  computed: {
    defaultGroups() {
      return this.current.map(x => x.hopur_id).concat(this.current.filter(x => x.yfirhopur && x.yfirhopur > 0).map(x => x.hopur_id));
    },
    removeGroups() {
      return this.toRemove.map(x => x.hopur_id).concat(this.toRemove.filter(x => x.yfirhopur && x.yfirhopur > 0).map(x => x.hopur_id));
    },
    addGroups() {
      return this.toAdd.map(x => x.hopur_id).concat(this.toAdd.filter(x => x.yfirhopur && x.yfirhopur > 0).map(x => x.hopur_id));
    },
    teacherAddGroups() {
      return this.selectedModulesAdd ? [this.selectedModulesAdd.hopur_id] : []; // this.selectedModulesAdd.map(x => x.hopur_id);
    },
    getModulesToAdd() {
      return this.modulesCanAdd.filter(x => !this.selectedModulesAdd || x.hopur_id !== this.selectedModulesAdd.hopur_id);
      // .filter(x => this.selectedModulesAdd.map(y => y.hopur_id).indexOf(x.hopur_id) === -1);
    },
    getTerms() {
      return this.terms.filter(x => x.is_past === 0 && x.tafla_laest === 0);
    },
    teacherRemoveGroups() {
      return this.selectedModulesRemove && this.selectedModulesRemove.groupId ? this.selectedModulesRemove.groupId : [];
      /* return Array.prototype.concat.apply(
        [],
        this.selectedModulesRemove.map(x => x.groupId),
      ); */
    },
    getModulesToRemove() {
      return this.modulesCanRemove; /* .filter(
        x => this.selectedModulesRemove.map(y => y.namsgrein_afanganr).indexOf(x.namsgrein_afanganr) === -1,
      ); */
    },
    currentCredits() {
      const groups = []; // Undir - yfirhópar, nota bara einu sinni hvert
      let credits = 0;
      this.current.forEach(c => {
        if (groups.indexOf(c.annir_afangar_id) === -1) {
          groups.push(c.annir_afangar_id);
          credits += c.einingar || 0;
        }
      });
      return credits;
    },
    getList() {
      return this.list
        .filter(
          x => !this.filterByDate || !this.dateFrom || moment(x.dags_fra).format('YYYYMMDD') === moment(this.dateFrom).format('YYYYMMDD'),
        )
        .filter(
          x => !this.filterByDate || !this.dateTo || moment(x.dags_til).format('YYYYMMDD') === moment(this.dateTo).format('YYYYMMDD'),
        );
    },
    getOld() {
      return this.old
        .filter(
          x => !this.filterByDate || !this.dateFrom || moment(x.dags_fra).format('YYYYMMDD') === moment(this.dateFrom).format('YYYYMMDD'),
        )
        .filter(
          x => !this.filterByDate || !this.dateTo || moment(x.dags_til).format('YYYYMMDD') === moment(this.dateTo).format('YYYYMMDD'),
        );
    },
    getFornamsAfangarEftir() {
      return this.modulesLeft.filter(x => x.tegund === 4);
    },
    getValAfangarEftir() {
      return this.modulesLeft.filter(x => x.tegund === 3);
    },
    getKjorsvidAfangarEftir() {
      return this.modulesLeft.filter(x => x.tegund === 2);
    },
    getKjarniAfangarEftir() {
      return this.modulesLeft.filter(x => x.tegund === 1);
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('beidnir_toflubreytingar');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      breadcrumbs: [
        { name: 'Forsíða', route: 'Dashboard' },
        { name: 'Beiðnir', route: '' },
        { name: 'Úrvinnsla töflubreytinga', route: 'ChangeTimetableRequests' },
        { name: 'Töflubreytingar nemanda', route: 'StudentRequests' },
      ],
      term: null,
      selectedTerm: null,
      terms: [],
      student: null,
      loading: {
        data: false,
        allGroups: false,
        modulesLeft: false,
        modulesAdd: false,
        modulesRemove: false,
      },
      currentDates: [],
      list: [],
      old: [],
      toRemove: [],
      toAdd: [],
      current: [],
      allModules: [],
      extraGroups: [],
      showHoverGroup: '',
      removeHoverGroup: '',
      showGroupSlidebar: false,
      showEditGroupSlidebar: false,
      showStudyRecordSlidebar: false,
      showStudentAttendanceSlidebar: false,
      showStudyRecordGradesSlidebar: false,
      showHistorySlidebar: false,
      selectedItem: null,
      seeAllGroups: [],
      dateFrom: '',
      dateTo: '',
      filterByDate: true,
      modulesLeft: [],
      modulesCanAdd: [],
      modulesCanRemove: [],
      selectedGroupDate: '',
      selectedModulesAdd: '',
      selectedModulesRemove: '',
      fromStudentView: false, // ef komið er inn frá nemendasýn
      selectedModulesAddSettings: {
        history: false,
        precursor: true,
        comment: '',
        addGroup: false,
      },
      selectedModulesRemoveSettings: {
        history: false,
        deleteChain: true,
      },
      addingModule: false,
      removingModule: false,
    };
  },
  methods: {
    getBack() {
      if (this.$route.query.query) {
        this.$router.push({ name: 'ChangeTimetableRequests', query: JSON.parse(this.$route.query.query) });
      } else {
        this.$router.push({ name: 'ChangeTimetableRequests' });
      }
    },
    updateQuery() {
      const query = {};
      if (this.$route.query.query) {
        query.query = this.$route.query.query;
      }
      if (this.$route.query.dateFrom) {
        query.dateFrom = this.$route.query.dateFrom;
      }
      if (this.$route.query.dateTo) {
        query.dateTo = this.$route.query.dateTo;
      }
      this.$router.push({
        name: 'StudentRequests',
        params: { term: this.term, student: this.student },
        query,
      });
    },
    async loadData() {
      try {
        this.loading.data = true;
        const response = await requests.getTimetableRequestsStudent(this.term, this.student);
        this.list = response.data.items
          .filter(x => x.stada === null)
          .map(x => ({
            ...x,
            dags_sort: moment(x.dags).format('YYYYMMDDHHmmss'),
          }));

        this.allModules = response.data.items.map(x => ({
          annir_afangar_id: x.annir_afangar_id,
          namsgrein_afanganr: x.namsgrein_afanganr,
        }));
        this.toRemove = response.data.items.filter(x => x.stada === null && x.adgerd === 0);
        this.toAdd = response.data.items.filter(x => x.stada === null && x.adgerd === 1);
        this.old = response.data.items.filter(x => x.stada !== null);

        const response2 = await students.getStudentModuleSubgroups(this.term, this.student);
        this.current = response2.data.items;
        this.allModules = this.allModules.concat(
          response2.data.items.map(x => ({ annir_afangar_id: x.annir_afangar_id, namsgrein_afanganr: x.namsgrein_afanganr })),
        );
        this.allModules = [...new Set(this.allModules.map(JSON.stringify))].map(JSON.parse); // Remove duplicates

        this.currentDates = Array.from(new Set(this.current.map(x => x.dags_fra))).sort((a, b) => {
          return moment(a, 'DD.MM.YYYY').valueOf() - moment(b, 'DD.MM.YYYY').valueOf();
        });
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.data = false;
      }
    },
    async loadTerms(id) {
      try {
        // if (this.canRead) {
        this.loading.terms = true;
        const response = await terms.getTerms();
        this.terms = response.data.items.map(x => ({
          ...x,
          label: `${x.undirsk_audk} - ${x.onn_heiti}`,
        }));
        if (id && id !== '0') {
          // kemur inn frá úrv. töflubreytingar - loada gögnum beint.
          this.selectedTerm = this.terms.find(x => x.onn_id === parseInt(id, 10));
          this.loadData();
          this.loadModulesLeft();
          this.loadModulesCanAdd();
          this.loadModulesCanRemove();
        } else {
          // kemur inn frá nemanda sýn
          this.selectedTerm = this.terms.find(x => x.is_default === 1);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.terms = false;
      }
    },
    async changeTerm() {
      if (this.term === '0') {
        this.fromStudentView = false;
      }
      this.term = this.selectedTerm.onn_id;
      // TODO- færa í sér fall ef það skildi vera queries með
      this.updateQuery();
      // this.$router.push({ name: 'StudentRequests', params: { term: this.term, student: this.student  } });
      // ef komið inn frá nemandasýn viljum við uppfæra önnina í urli og birta gögn
      this.loadData();
      this.loadModulesLeft();
      this.loadModulesCanAdd();
      this.loadModulesCanRemove();
    },
    async loadModulesLeft() {
      try {
        this.loading.modulesLeft = true;
        const response = await requests.getStudentModulesLeftCourseOfStudy(this.student);
        this.modulesLeft = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.modulesLeft = false;
      }
    },
    async loadModulesCanAdd() {
      try {
        this.modulesCanAdd = [];
        this.loading.modulesAdd = true;
        const response = await requests.getStudentModulesCanAdd(this.student, this.term);
        this.modulesCanAdd = response.data.items.map(x => ({ ...x, label: `${x.namsgrein_afanganr}(${x.hopur}) - Fj.${x.fjoldi}` }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.modulesAdd = false;
      }
    },
    async loadModulesCanRemove() {
      try {
        this.loading.modulesRemove = true;
        this.modulesCanRemove = [];
        const response = await requests.getStudentModulesCanRemove(this.student, this.term);
        response.data.items.forEach(item => {
          let group = '';
          const groupId = [];
          if (item.hopur.length > 1) {
            item.hopur.forEach(hopur => {
              groupId.push(hopur.hopur_id);
              group += `(${hopur.hopur}) - Fj.${hopur.fj_hop} `;
            });
          } else if (item.hopur.length === 1) {
            groupId.push(item.hopur[0].hopur_id);
            group = `(${item.hopur[0].hopur}) - Fj.${item.hopur[0].fj_hop}`;
          }

          const data = {
            ...item,
            label: `${item.namsgrein_afanganr} ${group}`,
            groupId,
          };
          this.modulesCanRemove.push(data);
        });
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.modulesRemove = false;
      }
    },
    async updateGroupsAll() {
      try {
        this.extraGroups = [];
        this.loading.allGroups = true;
        for (let i = 0; i < this.seeAllGroups.length; i += 1) {
          const response = await school.getTermModulesGroups(this.seeAllGroups[i].annir_afangar_id); // eslint-disable-line
          this.extraGroups = this.extraGroups.concat(response.data.items.map(x => x.hopur_id));
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.allGroups = false;
      }
    },
    async processRequests() {
      try {
        for (let i = 0; i < this.getList.length; i += 1) {
          if (this.getList[i].confirm) {
            // eslint-disable-next-line no-await-in-loop
            await requests.processTimetableRequestStatus(this.getList[i].nemandi_toflubreytingar_id, {
              // eslint-disable-line
              status: 1,
            });
          }
          if (this.getList[i].reject) {
            // eslint-disable-next-line no-await-in-loop
            await requests.processTimetableRequestStatus(this.getList[i].nemandi_toflubreytingar_id, {
              // eslint-disable-line
              status: 0,
            });
          }
        }
        this.displaySuccess('Töflubreytingar afgreiddar');
        this.loadData();
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async addModule() {
      try {
        let conf = true;
        if (!this.selectedModulesAddSettings.precursor) {
          conf = confirm('Ertu alveg viss um að þú viljir skrá nemandann í áfangann óháð því hvort hann uppfyllir undanfara eða ekki ?'); // eslint-disable-line
        }
        if (conf) {
          this.addingModule = true;
          const item = {
            nemandi_id: this.student,
            hopur_id: this.selectedModulesAdd.hopur_id,
            geyma_sogu: this.selectedModulesAddSettings.history ? 1 : 0,
            undanfari: this.selectedModulesAddSettings.precursor ? 1 : 0,
            athugasemd: !this.selectedModulesAddSettings.precursor ? this.selectedModulesAddSettings.comment : '',
            fjolhopar: this.selectedModulesAddSettings.addGroup ? 1 : 0,
          };
          await requests.changeStudyRecord(item);
          this.selectedModulesAdd = '';
          this.displaySuccess('Áfanga bætt við');
          this.addingModule = false;
          this.loadData();
          this.loadModulesLeft();
          this.loadModulesCanAdd();
          this.loadModulesCanRemove();
        }
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        if (e.response.status === 400 && e.response.data.error) {
          this.displayError(e.response.data.error);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } finally {
        this.addingModule = false;
      }
    },
    async removeModule() {
      try {
        this.removingModule = true;
        const item = {
          nemandi_id: this.student,
          annir_afangar_id: this.selectedModulesRemove.annir_afangar_id,
          delete: 1,
          ferill_id: this.selectedModulesRemove.ferill_id,
          geyma_sogu: this.selectedModulesRemoveSettings.history ? 1 : 0,
          undanfari: 0,
          eyda_afangakedju: this.selectedModulesRemoveSettings.deleteChain ? 1 : 0,
        };
        await requests.changeStudyRecord(item);
        this.selectedModulesRemove = '';

        this.displaySuccess('Áfanga eytt');
        this.removingModule = false;

        this.loadData();
        this.loadModulesLeft();
        this.loadModulesCanAdd();
        this.loadModulesCanRemove();
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        if (e.response.status === 400 && e.response.data.error) {
          this.displayError(e.response.data.error);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } finally {
        this.removingModule = false;
      }
    },
    changeSelection(item, type) {
      if (type === 0 && item.confirm) {
        this.$set(item, 'reject', false);
      } else if (type === 1 && item.reject) {
        this.$set(item, 'confirm', false);
      }
    },
    getInitials(name) {
      return name
        ? name
            .split(' ')
            .map(n => n[0])
            .join('')
        : '';
    },
    openGroupSlidebar(item) {
      this.selectedItem = item;
      this.showGroupSlidebar = true;
      this.showEditGroupSlidebar = false;
      this.showStudyRecordSlidebar = false;
      this.showStudentAttendanceSlidebar = false;
      this.showStudyRecordGradesSlidebar = false;
      this.showHistorySlidebar = false;
    },
    openEditGroupSlidebar(item) {
      this.selectedItem = item;
      this.showEditGroupSlidebar = true;
      this.showGroupSlidebar = false;
      this.showStudyRecordSlidebar = false;
      this.showStudentAttendanceSlidebar = false;
      this.showStudyRecordGradesSlidebar = false;
      this.showHistorySlidebar = false;
    },
    openStudyRecordSlidebar() {
      this.showStudyRecordSlidebar = true;
      this.showGroupSlidebar = false;
      this.showEditGroupSlidebar = false;
      this.showStudentAttendanceSlidebar = false;
      this.showStudyRecordGradesSlidebar = false;
      this.showHistorySlidebar = false;
    },
    openStudentAttendanceSlidebar() {
      this.showStudentAttendanceSlidebar = true;
      this.showGroupSlidebar = false;
      this.showEditGroupSlidebar = false;
      this.showStudyRecordSlidebar = false;
      this.showStudyRecordGradesSlidebar = false;
      this.showHistorySlidebar = false;
    },
    openStudyRecordGradesSlidebar(item) {
      this.selectedItem = item;
      this.showStudyRecordGradesSlidebar = true;
      this.showStudentAttendanceSlidebar = false;
      this.showGroupSlidebar = false;
      this.showEditGroupSlidebar = false;
      this.showStudyRecordSlidebar = false;
      this.showHistorySlidebar = false;
    },
    openHistorySlidebar() {
      this.showHistorySlidebar = true;
      this.showStudyRecordGradesSlidebar = false;
      this.showStudentAttendanceSlidebar = false;
      this.showGroupSlidebar = false;
      this.showEditGroupSlidebar = false;
      this.showStudyRecordSlidebar = false;
    },
    closeSlidebar() {
      this.showGroupSlidebar = false;
      this.showEditGroupSlidebar = false;
      this.showStudyRecordSlidebar = false;
      this.showStudentAttendanceSlidebar = false;
      this.showStudyRecordGradesSlidebar = false;
      this.showHistorySlidebar = false;
      this.selectedItem = null;
    },
    closeAndReload() {
      this.closeSlidebar();
      this.loadData();
    },
    onHover(item, type) {
      const name = `${item.namsgrein_afanganr} (${item.hopur})`;
      if (type === 1) {
        this.showHoverGroup = name;
        this.$nextTick(() => {
          this.showHoverGroup = '';
        });
      } else if (type === 0) {
        this.removeHoverGroup = name;
        this.$nextTick(() => {
          this.removeHoverGroup = '';
        });
      }
    },
    selectDate(date) {
      let selectedDate;
      if (moment(date, 'DD.MM.YYYY').isoWeekday() === 1) {
        selectedDate = moment(date, 'DD.MM.YYYY').format('DD.MM.YYYY');
      } else {
        selectedDate = moment(date, 'DD.MM.YYYY').startOf('isoWeek').add(1, 'week').format('DD.MM.YYYY');
      }
      this.selectedGroupDate = moment(selectedDate, 'DD.MM.YYYY');
    },
    /*
     * Modal gluggar
     */
    commentModal(item) {
      this.$refs.commentModal.show(item);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.term = this.$route.params.term ? this.$route.params.term : null;
    this.student = this.$route.params.student ? this.$route.params.student : null;
    if (this.$route.query.dateFrom && this.$route.query.dateFrom !== '') {
      this.dateFrom = moment(this.$route.query.dateFrom, 'DD.MM.YYYY');
    }
    if (this.$route.query.dateTo && this.$route.query.dateTo !== '') {
      this.dateTo = moment(this.$route.query.dateTo, 'DD.MM.YYYY');
    }
    // ef term kemur ekki inn þá erum við ekki að loada gögnum
    if (this.term === '0') {
      this.fromStudentView = true;
    } else {
      this.fromStudentView = false;
    }
    this.loadTerms(this.term);
  },
};
</script>
