<template>
  <Slidebar :autoClose="false" @closeMe="closeSlidebar">
    <h4>Aðgangur - {{ item.name }}</h4>
    <br />
    <br />
    <div v-if="!loading.access && list.length == 0">Engir starfsmenn með valið hlutverk.</div>
    <table class="table">
      <tr v-if="list.length > 0">
        <th>Nafn</th>
        <th>Kennitala</th>
        <th>Skammstöfun</th>
      </tr>
      <tr v-for="l in list" :key="l.kennitala">
        <td>
          <b-link :to="{ name: 'StaffDetail', params: { id: l.starfsm_id } }" target="_blank">{{ l.nafn }}</b-link>
        </td>
        <td>{{ l.kennitala }}</td>
        <td>{{ l.skammst }}</td>
      </tr>
      <tr>
        <td colspan="2">
          <v-select id="staff" name="staff" v-model="teacherToAdd" :options="staff" label="nafn"> </v-select>
        </td>
        <td>
          <b-btn variant="primary" size="sm" style="height: 35px" @click="addTeacher()" :disabled="!teacherToAdd"
            ><i class="fa fa-plus-circle" /> Bæta við</b-btn
          >
        </td>
      </tr>
      <tr>
        <td colspan="3">
          <i>Ath. starfsmaður fær aðgang að Admin vef við að vera settur í hlutverk.</i>
        </td>
      </tr>
    </table>
    <br />
  </Slidebar>
</template>

<script>
import staff from '@/api/staff';
import { mapActions } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';

export default {
  name: 'staff-search-slide-bar',
  components: {
    Slidebar,
  },
  props: ['item'],
  data() {
    return {
      loading: {
        access: false,
        staff: false,
      },
      list: [],
      staff: [],
      teacherToAdd: null,
    };
  },
  methods: {
    async loadList() {
      try {
        this.list = [];
        this.loading.access = true;
        const response = await staff.getStaffAccessListByJob(this.item.id);
        this.list = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.access = false;
      }
    },
    async loadStaff() {
      try {
        this.loading.staff = true;
        const response = await staff.getStaffList({ noAccess: 1 });
        this.staff = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.staff = false;
      }
    },
    async addTeacher() {
      const item = {
        adgangur_admin: 1,
        adgangur_gamla: this.teacherToAdd.adgangur_gamla,
        admin_hlutverk: this.item.id,
        lms_hlutverk: this.teacherToAdd.hlutverk_lms,
      };
      await staff.updateStaffAccess(this.teacherToAdd.starfsm_id, item);
      this.displaySuccess('Starfsmanni bætt við.');
      this.teacherToAdd = null;
      this.loadList();
      this.loadStaff();
    },
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    item() {
      this.list = [];
      this.loadList();
      this.loadStaff();
    },
  },
  created() {
    this.list = [];
    this.loadList();
    this.loadStaff();
  },
};
</script>

<style lang="scss"></style>
