<template>
  <div>
    <h5>Afrita námsgagnalista</h5>

    <b-form @submit.prevent="copy" novalidate class="mt-2">
      <b-form-group>
        Afrita frá:
        <b-form-radio-group id="latest" v-model="form.latest" name="latest" stacked>
          <b-form-radio :value="true"
            >Nýjasti námsgagnalistinn fyrir hvern áfanga
            <i
              class="fa fa-info-circle"
              v-b-tooltip
              title="Síðasta námsgagnafærslan sem skráð var á áfangann. Ath. eingöngu eru skoðaðar eldri kennslutímabil."
            ></i>
          </b-form-radio>
          <b-form-radio :value="false">Ákveðnu kennslutímabili</b-form-radio>
        </b-form-radio-group>
      </b-form-group>

      <b-form-group v-if="!form.latest">
        <label for="term">Kennslutímabil</label>
        <v-select id="term" name="term" v-model="form.term" :clearable="false" :options="terms"> </v-select>
      </b-form-group>

      <b-form-group>
        Hvernig viltu afrita?
        <b-form-radio-group id="type" v-model="form.type" name="type" stacked>
          <b-form-radio :value="0"
            >Bæta við námsgagnalista á áfanga ef námsgagnalisti er ekki til fyrir
            <i class="fa fa-info-circle" title="Ef eitthvað námsgagn er skráð á áfanga verður ekkert afritað." v-b-tooltip></i>
          </b-form-radio>
          <b-form-radio :value="1"
            >Yfirskrifa núverandi námsgagnalista <span v-if="term"> - {{ term.onn_heiti }} ({{ term.onn_eink }})</span>

            <i class="fa fa-info-circle" title="Ef það eru námsgögn skráð fyrir er þeim eytt og svo afritað nýju." v-b-tooltip></i>
          </b-form-radio>
        </b-form-radio-group>
      </b-form-group>

      <b-form-group>
        <label label-for="department">Eingöngu afrita áfanga eftirfarandi deildar: </label>
        <v-select id="department" name="department" v-model="form.department" :options="departments" :clearable="true"> </v-select>
      </b-form-group>
    </b-form>
    <b-btn variant="primary" style="margin-top: 10px" :class="{ 'float-right ml-2': !this.slidebar }" @click="copy()" :disabled="!canEdit">
      <i class="fa fa-spin fa-spinner" v-if="submitting"></i>
      Afrita
    </b-btn>
  </div>
</template>

<script>
import terms from '@/api/terms';
import structure from '@/api/structure';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'term-copy-booklist',
  props: ['termId', 'slidebar'],
  computed: {
    ...mapGetters(['loggedInUser']),
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_annir');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolHasSettings']),
  },
  data() {
    return {
      form: {
        latest: true,
        type: 0,
        term: '',
        department: '',
      },
      terms: [],
      term: '',
      departments: [],
      loading: false,
      submitting: false,
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async loadTerms() {
      try {
        this.loading = true;
        this.firsttime = true;
        const params = {
          only_active: 0,
        };
        const response = await terms.getTerms(params);
        this.term = response.data.items.find(x => x.onn_id === parseInt(this.termId, 10));
        this.terms = response.data.items
          .filter(x => x.onn_id !== parseInt(this.termId, 10))
          .map(x => ({
            ...x,
            label: `${x.undirsk_audk} - ${x.onn_heiti}`,
          }));
        const current = response.data.items.find(x => x.onn_id === parseInt(this.termId, 10));
        this.form.term = this.terms.find(x => (!current || x.undirskoli_id === current.undirskoli_id) && x.is_active === 1);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadDepartments() {
      try {
        const response = await structure.getDepartmentList();
        this.departments = response.data.items.map(x => ({
          ...x,
          label: `${x.name}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async copy() {
      try {
        await terms.copyBooklist(this.termId, {
          latest: this.form.latest ? 1 : 0,
          onn_fra: !this.form.latest ? this.form.term.onn_id : '',
          yfirskrifa: this.form.type,
          deild: this.form.department ? this.form.department.department_id : '',
        });
        this.submitting = false;
        this.displaySuccess('Námsgagnalisti hefur verið afritaður');
        this.$emit('finishCopy');
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.submitting = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadTerms();
    this.loadDepartments();
  },
};
</script>

<style lang="scss"></style>
