<template>
  <div>
    <b-modal
      id="mastertableTeacherChangeModal"
      title="Breyta kennara"
      size="lg"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Loka"
      @ok="closeModal"
      ok-only
      ref="createModal"
    >
      <div>
        <b-form>
          <div v-for="(item, index) in mastertable" :key="index">
            <h5>{{ item.heiti_dags }} {{ item.timi_fra }} - {{ item.timi_til }}</h5>
            <table class="table no-border">
              <tr v-if="item.noTeachers">
                <td colspan="3" class="pt-0 pb-0">
                  <div class="alert alert-danger mb-0">
                    <strong
                      ><i class="fa fa-exclamation-triangle"></i> Ekki er hægt að eyða kennara af tíma nema bæta öðrum kennara við áður.
                      Tími má ekki vera kennaralaus.</strong
                    >
                  </div>
                </td>
              </tr>
              <tr v-for="(teacher, tidx) in item.kennarar" :key="tidx">
                <td>{{ teacher.nafn }}</td>
                <td>{{ teacher.skammst }}</td>
                <td>
                  <b-btn variant="danger" size="sm" @click="removeTeacher(item, teacher)">
                    <i class="fa fa-trash" />
                  </b-btn>
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <b-row>
                    <b-col class="pl-0">
                      <v-select id="staff" name="staff" v-model="item.teacherToAdd" :options="staff" label="nafn"> </v-select>
                    </b-col>
                    <b-col>
                      <b-btn variant="primary" size="sm" style="height: 35px" @click="addTeacher(item)" :disabled="!item.teacherToAdd"
                        ><i class="fa fa-plus-circle" /> Bæta við</b-btn
                      >
                    </b-col>
                  </b-row>
                </td>
              </tr>
            </table>
          </div>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import school from '@/api/school';
import staff from '@/api/staff';

import { mapActions } from 'vuex';

export default {
  name: 'change-mastertable-teacher-modal',
  data() {
    return {
      id: '',
      mastertable: [],
      staff: [],
      loading: {
        timetable: false,
        staff: false,
      },
    };
  },
  methods: {
    async loadTimetable() {
      try {
        this.loading.timetable = true;
        const response = await school.getGroupMastertable(this.id);
        this.mastertable = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.timetable = false;
      }
    },
    async loadStaff() {
      try {
        this.loading.staff = true;
        const response = await staff.getStaffList(this.search);
        this.staff = response.data.items.map(x => ({
          ...x,
          label: `${x.nafn} ${x.skammst}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.staff = false;
      }
    },
    async addTeacher(day) {
      this.$set(day, 'noTeachers', false);
      try {
        await school.addStaffMastertable(day.tafla_id, day.teacherToAdd.starfsm_id);
        this.$set(day, 'teacherToAdd', '');
        this.loadTimetable();
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async removeTeacher(day, teacher) {
      this.$set(day, 'noTeachers', false);
      if (day.kennarar.length > 1) {
        try {
          await school.deleteStaffMastertable(day.tafla_id, teacher.starfsm_id);
          this.loadTimetable();
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } else {
        this.$set(day, 'noTeachers', true);
      }
    },
    closeModal() {
      this.$emit('successful', {});
      this.$refs.createModal.hide();
    },
    show(id) {
      this.id = id;
      this.loadTimetable();
      this.loadStaff();
      this.$refs.createModal.show();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
<style lang="scss"></style>
