<template>
  <div>
    <table class="table noprint">
      <thead>
        <tr class="table-search">
          <td :colspan="slidebar ? 2 : 7">
            <b-form-input
              v-model="search.search"
              class="search"
              v-on:keyup.enter="loadList"
              placeholder="Leita eftir nafni, skammstöfun eða kennitölu"
              ref="searchInput"
            />
          </td>
          <td>
            <b-btn variant="primary" class="w-100" @click="loadList">Leita</b-btn>
          </td>
        </tr>
        <tr v-if="firsttime">
          <th><LinkOrder title="Nafn" :filteredList="list" :list="list" column="nafn" /> ({{ list.length }})</th>
          <th>
            <LinkOrder title="Kennitala" :filteredList="list" :list="list" column="nafn" />
          </th>
          <th>
            <LinkOrder title="Upphafsstafir" :filteredList="list" :list="list" column="skammst" />
          </th>
          <th v-if="!slidebar">
            <LinkOrder title="Starfsheiti" :filteredList="list" :list="list" column="starfsheiti" />
          </th>
          <th v-if="!slidebar">
            <LinkOrder title="Starfshlutfall" :filteredList="list" :list="list" column="starfs_hlutfall" />
          </th>
          <th v-if="!slidebar">
            <LinkOrder title="Aðgangshlutverk" :filteredList="list" :list="list" column="admin_hlutverk" />
          </th>
          <th v-if="!slidebar">
            <LinkOrder title="Virkur" :filteredList="list" :list="list" column="virkur" />
          </th>
          <th v-if="!slidebar">
            <LinkOrder title="Netfang" :filteredList="list" :list="list" column="netfang" />
          </th>
          <th v-if="!slidebar"></th>
          <th>
            <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="cursor-pointer float-right">
              <i class="fa fa-file-excel-o excelText"></i>
            </download-csv>
          </th>
        </tr>
      </thead>
      <tbody>
        <TableRowLoader v-if="loading && firsttime" :colspan="slidebar ? 4 : 10" :center="true" />
        <TableRowEmpty v-if="!loading && firsttime && this.list.length === 0" :colspan="slidebar ? 4 : 10" :center="true" />
        <TableRowSearchForResults v-if="!firsttime" :colspan="slidebar ? 4 : 10" :center="true" />
        <tr v-for="item in getPaginatedList" :key="item.id">
          <td>
            <b-link v-if="!slidebar" :to="{ name: 'StaffDetail', params: { id: item.starfsm_id } }" title="Velja starfsmann" v-b-tooltip
              >{{ item.nafn }}
            </b-link>
            <b-link v-else @click="changeStaff(item)" title="Velja starfsmann" v-b-tooltip>{{ item.nafn }}</b-link>
          </td>
          <td>{{ item.kennitala }}</td>
          <td>{{ item.skammst }}</td>
          <td v-if="!slidebar">{{ item.starfsheiti }}</td>
          <td v-if="!slidebar">{{ item.starfs_hlutfall }}<span v-if="item.starfs_hlutfall">%</span></td>
          <td v-if="!slidebar">{{ item.admin_hlutverk }}</td>
          <td v-if="!slidebar"><yes-no :item="item.virkur" /></td>
          <td v-if="!slidebar">{{ item.netfang }}</td>
          <td v-if="!slidebar">
            <b-btn size="sm" variant="dark" @click.prevent="openTimetableSlidebar(item)" title="Skoða stundatöflu" v-b-tooltip>
              <i class="fa fa-calendar" />
            </b-btn>
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>

    <Pagination
      :currentPage="currentPage"
      :totalRows="list.length"
      :perPage="perPage"
      v-if="list.length > 25"
      @change-page="changePage"
      @change-limit="changeLimit"
    />

    <timetable-slidebar v-if="showTimetableSlidebar" :item="selectedItem" type="staff" @closeSlidebar="closeSlidebar"> </timetable-slidebar>
  </div>
</template>

<script>
import moment from 'moment';
import staff from '@/api/staff';
import { mapGetters, mapActions } from 'vuex';

import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import TableRowSearchForResults from '@/components/common/TableRowSearchForResults.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';
import TimetableSlidebar from '@/components/common/TimetableSlidebar.vue';
import Pagination from '@/components/common/Pagination.vue';
import YesNo from '@/components/common/YesNo.vue';

export default {
  name: 'staff-search',
  components: {
    TableRowLoader,
    TableRowEmpty,
    TableRowSearchForResults,
    LinkOrder,
    TimetableSlidebar,
    Pagination,
    YesNo,
  },
  props: ['slidebar'],
  computed: {
    getExcelData() {
      const newList = [
        [],
        ['Starfsmenn'],
        [],
        ['Nafn', 'Kennitala', 'Upphafsstaðir', 'Starfsheiti', 'Starfshlutfall', 'Aðgangshlutverk', 'Virkur', 'Netfang'],
        ...this.list.map(x => [
          x.nafn,
          `="${x.kennitala}"`,
          x.skammst,
          x.starfsheiti,
          x.starfs_hlutfall,
          x.admin_hlutverk,
          x.virkur === 1 ? 'Já' : 'Nei',
          x.netfang,
        ]),
      ];
      return newList;
    },
    getExcelFileName() {
      return `starfsmenn_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    rows() {
      return this.list.length;
    },
    getPaginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.list.slice(start, end);
    },
    canRead() {
      return this.loggedInUserHasReadPermission('starfsmannahald_starfsmenn');
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('starfsmannahald_starfsmenn');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      perPage: 25,
      currentPage: 1,
      firsttime: false,
      loading: false,
      list: [],
      search: {
        search: '',
      },
      showTimetableSlidebar: false,
      selectedItem: {},
    };
  },
  methods: {
    async loadList() {
      if (this.canRead) {
        try {
          this.firsttime = true;
          this.loading = true;
          const response = await staff.getStaffList(this.search);
          this.list = response.data.items;
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading = false;
        }
      }
    },
    openTimetableSlidebar(item) {
      this.selectedItem = item;
      this.showTimetableSlidebar = true;
    },
    closeSlidebar() {
      // this.showSlidebar = false;
      this.showTimetableSlidebar = false;
      this.selectedItem = {};
    },
    checkSlidebar(item) {
      /* if (this.showSlidebar) {
        this.openSlidebar(item);
      } else */
      if (this.showTimetableSlidebar) {
        this.openTimetableSlidebar(item);
      }
    },
    changeStaff(item) {
      this.$emit('changeStaff', item.starfsm_id);
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.$nextTick(() => {
      if (this.$refs.searchInput) {
        this.$refs.searchInput.focus();
      }
    });
  },
};
</script>
