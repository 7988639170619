<template>
  <div>
    <b-row class="breadcumb-location" v-if="canEdit">
      <b-col>
        <b-btn variant="primary" :to="{ name: 'SingleClassCreate' }"> <i class="fa fa-plus-circle" /> Skrá námskeið </b-btn>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="pl-0" cols="3">
        <b-form-group label="Heiti námskeiðs" label-for="name">
          <b-form-input id="name" name="name" v-model="search.name" />
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group label="Umsjónarmaður námskeiðs" label-for="name">
          <b-form-input id="name" name="name" v-model="search.teacher" />
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group>
          <label for="category">Tegund námskeiðs</label>
          <i class="fa fa-pencil cursor-pointer ml-2" v-if="canEdit" @click="openCategorySlidebar()" />
          <v-select id="category" name="category" v-model="search.categories" multiple :options="categories" label="flokkur_nafn">
          </v-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="3" class="pl-0">
        <b-form-group label="Dags.frá" label-for="dateFrom">
          <datepicker
            class="datepicker_100_width"
            id="dateFrom"
            name="dateFrom"
            v-model="search.date_from"
            :language="lang"
            :monday-first="true"
            format="dd.MM.yyyy"
            :typeable="true"
            ref="dateFromOpenPickr"
          ></datepicker>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group label="Dags.til" label-for="dateTo">
          <datepicker
            class="datepicker_100_width"
            id="dateTo"
            name="dateTo"
            v-model="search.date_to"
            :language="lang"
            :monday-first="true"
            format="dd.MM.yyyy"
            :typeable="true"
            ref="dateToOpenPickr"
          ></datepicker>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-btn variant="primary" @click="loadList()" class="w-100" style="margin-top: 33px">Leita</b-btn>
      </b-col>
    </b-row>
    <br />
    <Loader v-if="loading.list" />
    <div v-if="!loading.list && list.length === 0 && firsttime">Engar niðurstöður fundust.</div>
    <table class="table" v-if="list.length > 0">
      <tr
        v-for="(item, index) in getPaginatedList"
        :key="index"
        :class="{ 'active-table-row': selectedItem && selectedItem.namskeid_id === item.namskeid_id }"
      >
        <td>{{ item.dags_fra | moment('DD.MM.YYYY') }} - {{ item.dags_til | moment('DD.MM.YYYY') }}</td>
        <td>
          <div><strong v-html="item.namskeid_nafn"></strong> - {{ item.flokkur_nafn }}</div>
          <div><strong>Umsjónarmaður:</strong> - {{ item.nafn }}</div>
        </td>
        <td>
          <a @click="openClassSlidebar(item)" title="Skoða þátttakendur" v-b-tooltip class="cursor-pointer">
            <i class="fa fa-user" />
            {{ item.participants.length }}
          </a>
        </td>
        <td>
          <div class="float-right">
            <b-btn
              variant="primary"
              :to="{ name: 'SingleClassesClass', params: { id: item.namskeid_id } }"
              size="sm"
              title="Skoða námskeið"
              v-b-tooltip
            >
              <i class="fa fa-search" />
            </b-btn>
            <b-btn
              variant="dark"
              size="sm"
              class="ml-2 mr-2"
              :to="{ name: 'SingleClassCreate', query: { old: item.namskeid_id } }"
              title="Smelltu hér til þess að skrá nýtt námskeið með sömu upplýsingum"
              v-b-tooltip
              v-if="canEdit"
            >
              <i class="fa fa-copy" />
            </b-btn>
            <b-btn
              variant="danger"
              size="sm"
              title="Smelltu hér til þess að eyða námskeiði"
              v-b-tooltip
              @click="deleteClass(item)"
              v-if="canEdit"
              ><i class="fa fa-trash"
            /></b-btn>
          </div>
        </td>
      </tr>
    </table>
    <br />
    <Pagination
      :currentPage="currentPage"
      :totalRows="list.length"
      :perPage="perPage"
      v-if="list.length > 25"
      @change-page="changePage"
      @change-limit="changeLimit"
    />
    <class-slidebar
      v-if="showClassSlidebar"
      :item="selectedItem"
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
    >
    </class-slidebar>
    <category-slidebar v-if="showCategorySlidebar" @closeSlidebar="closeCategorySlidebar"> </category-slidebar>
  </div>
</template>

<script>
import singleClasses from '@/api/singleClasses';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

import Loader from '@/components/common/Loader.vue';
import Pagination from '@/components/common/Pagination.vue';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';

import ClassSlidebar from '@/components/curriculum/singleClasses/ClassSlidebar.vue';
import CategorySlidebar from '@/components/curriculum/singleClasses/CategorySlidebar.vue';

export default {
  name: 'single-classes-classes',
  components: {
    Loader,
    Pagination,
    Datepicker,
    ClassSlidebar,
    CategorySlidebar,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_stok_namskeid');
    },
    getPaginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.list.slice(start, end);
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      lang: is,
      perPage: 25,
      currentPage: 1,
      firsttime: false,
      list: [],
      categories: [],
      search: {
        name: '',
        teacher: '',
        categories: [],
        date_from: '',
        date_to: '',
      },
      loading: {
        list: false,
        categories: false,
      },
      showClassSlidebar: false,
      showCategorySlidebar: false,
      selectedItem: {},
    };
  },
  methods: {
    async loadList() {
      try {
        this.list = [];
        this.loading.list = true;
        const params = { ...this.search };
        params.date_from = params.date_from ? moment(params.date_from).format('DD.MM.YYYY') : '';
        params.date_to = params.date_to ? moment(params.date_to).format('DD.MM.YYYY') : '';
        params.categories = params.categories.map(x => x.namskeid_flokkur_id).join(',');
        const response = await singleClasses.getList(params);
        this.list = response.data.items;
        this.firsttime = true;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.list = false;
      }
    },
    async loadCategories() {
      try {
        this.categories = [];
        this.loading.categories = true;
        const response = await singleClasses.getCategories();
        this.categories = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.categories = false;
      }
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    openClassSlidebar(item) {
      this.selectedItem = item;
      this.showClassSlidebar = true;
      this.showCategorySlidebar = false;
    },
    openCategorySlidebar() {
      this.showCategorySlidebar = true;
      this.showClassSlidebar = false;
    },
    closeSlidebar() {
      this.showClassSlidebar = false;
      this.showCategorySlidebar = false;
      this.selectedItem = {};
    },
    closeAndReloadSlidebar() {
      this.closeSlidebar();
      this.loadList();
    },
    closeCategorySlidebar() {
      this.showClassSlidebar = false;
      this.showCategorySlidebar = false;
      this.loadCategories();
    },
    async deleteClass(item) {
      try {
        const data = {
          id: item.namskeid_id,
          deleted: 1,
        };
        const conf = confirm('Ertu viss um að þú viljir eyða námskeiðinu ?'); // eslint-disable-line
        if (conf) {
          await singleClasses.createClass(data);
          this.loadList();
        }
      } catch (e) {
        // Aðgerð mistókst.
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadCategories();
    this.loadList();
  },
};
</script>
