// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import VeeValidate, { Validator } from 'vee-validate';
import VueMoment from 'vue-moment';
import VueLogger from 'vuejs-logger';
import VueScrollTo from 'vue-scrollto';
import Affix from 'vue-affix';
import vSelect from 'vue-select';
import { sync } from 'vuex-router-sync';
import FullCalendar from 'vue-full-calendar';
import JsonCSV from 'vue-json-csv';
import VueTimepicker from 'vue2-timepicker';
import VueCurrencyFilter from 'vue-currency-filter';
import VueMatchHeights from 'vue-match-heights';

import socketio from 'socket.io-client';
import VueSocketIO from 'vue-socket.io';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserFriends, faSpinner, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import moment from 'moment';
import 'moment/locale/is';

import App from './App.vue';
import Page from './components/layout/Page.vue';
import router from './router';
import i18n from './i18n';
import store from './store';
import is from './locale/validation/is';

import './http';

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(VeeValidate, {
  fieldsBagName: 'vvFields',
});
Validator.localize('is', is);

Vue.component('v-select', vSelect);
Vue.use(VueMoment, {
  moment,
});

Vue.use(VueMatchHeights, {
  disabled: [768], // Optional: default viewports widths to disabled resizing on. Can be overridden per usage
});
Vue.use(FullCalendar);
Vue.component('downloadCsv', JsonCSV);

Vue.use(VueMatchHeights, {
  disabled: [768], // Optional: default viewports widths to disabled resizing on. Can be overridden per usage
});

Vue.use(VueLogger as any, {
  // required ['debug', 'info', 'warn', 'error', 'fatal']
  logLevel: 'debug',
  // optional : defaults to false if not specified
  stringifyArguments: false,
  // optional : defaults to false if not specified
  showLogLevel: true,
  // optional : defaults to false if not specified
  showMethodName: false,
  // optional : defaults to '|' if not specified
  separator: '|',
  // optional : defaults to false if not specified
  showConsoleColors: true,
});

Vue.filter('limitTo', (value: string, length: number) => {
  if (!value) return '';
  if (value && value.length <= length) return value;
  return `${value.substring(0, length)}..`;
});

Vue.use(VueCurrencyFilter, {
  symbol: 'kr.',
  thousandsSeparator: '.',
  fractionCount: 0,
  fractionSeparator: ',',
  symbolPosition: 'end',
  symbolSpacing: true,
});

Vue.use(VueScrollTo);
Vue.use(Affix);
Vue.use(VueTimepicker);

Vue.use(
  new VueSocketIO({
    debug: true,
    connection: socketio('/', {
      path: '/ws/socket.io',
      transports: ['websocket'],
    }),
    // vuex: {
    //   store,
    //   actionPrefix: "SOCKET_",
    //   mutationPrefix: "SOCKET_"
    // }
  }),
);

// FontAwesome
library.add(faUserFriends, faSpinner, faInfoCircle);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.component('page', Page);

sync(store, router);

/* eslint-disable no-new */
new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app');
