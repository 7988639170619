<template>
  <Slidebar @closeMe="closeSlidebar" :extraLarge="true">
    <h4>Breyta textum</h4>
    <Loader v-if="loading.course"></Loader>
    <b-form-group
      label="Athugasemd"
      label-for="athugasemd"
      :state="submitted && errors.has('athugasemd') ? false : ''"
      :invalid-feedback="errors.first('athugasemd')"
    >
      <b-form-textarea id="athugasemd" name="athugasemd" v-model="form.athugasemd" maxLength="2000"> </b-form-textarea>
    </b-form-group>
    <strong class="mt-3 mb-2">Íslenska</strong>
    <b-form-group
      label="Texti á skírteini í stað heiti brautar"
      label-for="texti_braut_skirteini"
      :state="submitted && errors.has('texti_braut_skirteini') ? false : ''"
      :invalid-feedback="errors.first('texti_braut_skirteini')"
    >
      <b-form-textarea id="texti_braut_skirteini" name="texti_braut_skirteini" v-model="form.texti_braut_skirteini" maxLength="2000">
      </b-form-textarea>
    </b-form-group>
    <b-form-group
      label="Athugasemd á skírteini nemanda"
      label-for="athugasemd_skirteini"
      :state="submitted && errors.has('athugasemd_skirteini') ? false : ''"
      :invalid-feedback="errors.first('athugasemd_skirteini')"
    >
      <b-form-textarea id="athugasemd_skirteini" name="athugasemd_skirteini" v-model="form.athugasemd_skirteini" maxLength="2000">
      </b-form-textarea>
    </b-form-group>
    <strong class="mt-3 mb-2">Enska</strong>
    <b-form-group
      label="Texti á skírteini í stað heiti brautar (enska)"
      label-for="texti_braut_skirteini_ens"
      :state="submitted && errors.has('texti_braut_skirteini_ens') ? false : ''"
      :invalid-feedback="errors.first('texti_braut_skirteini_ens')"
    >
      <b-form-textarea
        id="texti_braut_skirteini_ens"
        name="texti_braut_skirteini_ens"
        v-model="form.texti_braut_skirteini_ens"
        maxLength="2000"
      >
      </b-form-textarea>
    </b-form-group>
    <b-form-group
      label="Athugasemd á skírteini nemanda (enska)"
      label-for="athugasemd_skirteini_ens"
      :state="submitted && errors.has('athugasemd_skirteini_ens') ? false : ''"
      :invalid-feedback="errors.first('athugasemd_skirteini_ens')"
    >
      <b-form-textarea
        id="athugasemd_skirteini_ens"
        name="athugasemd_skirteini_ens"
        v-model="form.athugasemd_skirteini_ens"
        maxLength="2000"
      >
      </b-form-textarea>
    </b-form-group>
    <b-btn variant="primary" class="mt-3" @click="saveTexts()" :disabled="saving">
      <i class="fa fa-spin fa-spinner" v-if="saving"></i> Vista
    </b-btn>
  </Slidebar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import students from '@/api/students';
import Loader from '@/components/common/Loader.vue';
import Slidebar from '@/components/common/Slidebar.vue';

export default {
  name: 'study-record-course-change-text-slidebar',
  props: ['studentCourseId'],
  components: {
    Loader,
    Slidebar,
  },
  computed: {
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {
      submitted: false,
      saving: false,
      form: {
        athugasemd: '',
        texti_braut_skirteini: '',
        texti_braut_skirteini_ens: '',
        athugasemd_skirteini: '',
        athugasemd_skirteini_ens: '',
      },
      loading: {
        course: false,
      },
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async loadCourses() {
      try {
        this.loading.courses = true;
        const response = await students.getStudentCourseText(this.studentCourseId);

        this.form.athugasemd = response.data.athugasemd;
        this.form.texti_braut_skirteini = response.data.texti_braut_skirteini;
        this.form.texti_braut_skirteini_ens = response.data.texti_braut_skirteini_ens;
        this.form.athugasemd_skirteini = response.data.athugasemd_skirteini;
        this.form.athugasemd_skirteini_ens = response.data.athugasemd_skirteini_ens;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.courses = false;
      }
    },
    async saveTexts() {
      try {
        this.saving = true;
        await students.updateStudentCourseText(this.studentCourseId, this.form);
        this.displaySuccess('Textum breytt');

        this.$emit('closeAndReloadSlidebar');
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.saving = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadCourses();
  },
};
</script>

<style lang="scss" scoped></style>
