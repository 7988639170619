<template>
  <div>
    <div v-if="attachments.length === 0" class="mb-2">Engin skjöl fundust.</div>
    <table class="table less-padding no-border mb-4" v-if="attachments.length > 0">
      <thead>
        <tr>
          <th v-if="type === 'teachingplan'">Hópur</th>
          <th>Heiti</th>
          <th>Stofnað</th>
          <th>Stofnað af</th>
          <th></th>
        </tr>
      </thead>
      <tr v-for="item in attachments" :key="item.attachment_id">
        <td v-if="type === 'teachingplan'">{{ item.hopur }}</td>
        <td>
          <b-link @click="mixinDownloadAttachment(item.attachment_id)">{{ item.name }} <i class="fa fa-download"></i> </b-link>
        </td>
        <td>
          {{ item.time_create | moment('DD.MM.YYYY HH:mm:ss') }}
        </td>
        <td>{{ item.created_by }}</td>
        <td>
          <b-btn variant="primary" v-if="canEdit" class="btn-xs float-right" @click="deleteAttachment(item)"
            ><i class="fa fa-trash"></i
          ></b-btn>
        </td>
      </tr>
    </table>
    <div v-if="type === 'teachingplan' && canEdit">
      <div v-if="groups.length === 0">Engir hópar fundust. Ekki er hægt að hlaða upp námsáætlun nema að hópur sé til</div>
      <div v-if="groups.length > 0 && getGroups.length === 0 && !groupId">
        Búið er að skrá námsáætlun fyrir alla hópa. Til að skrá verður að eyða út þeirri sem er fyrir.
      </div>
      <div v-if="groups.length > 0 && getGroups.length === 0 && groupId">
        Búið er að skrá námsáætlun fyrir hópinn. Til að skrá verður að eyða út þeirri sem er fyrir.
      </div>
      <b-form-group label-for="group" label="Veldu hóp til að hlaða upp á" v-if="getGroups.length > 0">
        <v-select
          id="group"
          name="group"
          v-model="uploadId"
          :reduce="item => item.hopur_id"
          :clearable="false"
          :options="getGroups"
          :disabled="groupId && groupId > 0"
          label="hopur"
        >
        </v-select>
      </b-form-group>
    </div>

    <UploadAttachment v-if="canEdit && uploadId" :typeId="typeId" :uploadId="uploadId" @reload="reload()"> </UploadAttachment>
  </div>
</template>

<script>
import { attachmentMixin } from '@/utils';

import terms from '@/api/terms';
import curriculum from '@/api/curriculum';

import UploadAttachment from '@/components/common/UploadAttachment.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'module-attachment',
  components: {
    UploadAttachment,
  },
  props: ['moduleId', 'termId', 'type', 'groupId'],
  mixins: [attachmentMixin],
  computed: {
    getGroups() {
      return this.groups.filter(x => !this.attachments.find(y => y.reference_id === x.hopur_id));
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_afangar');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('namskra_afangar');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      loading: false,
      attachments: [],
      groups: [],
      termName: '',
      termModuleId: '',
      typeId: null,
      uploadId: null,
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async loadGroups() {
      try {
        const response = await terms.getTermModule(this.termModuleId);
        this.groups = (response.data.hopar || []).filter(x => !this.groupId || x.hopur_id === parseInt(this.groupId, 10));
        if (this.groupId && this.groups.length === 1 && this.attachments.length === 0) {
          this.uploadId = this.groups[0].hopur_id;
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadItem() {
      try {
        this.loading = true;
        this.attachments = [];
        this.termName = '';
        const response = await curriculum.getModuleTerms(this.moduleId, { termId: this.termId });
        if (response.data.items.length === 1) {
          this.termName = response.data.items[0].onn_heiti;
          this.termModuleId = response.data.items[0].annir_afangar_id;
          if (this.type === 'teachingplan') {
            this.typeId = 5;
            this.attachments = response.data.items[0].namsaetlun.filter(
              x => !this.groupId || x.reference_id === parseInt(this.groupId, 10),
            );
            this.loadGroups();
          } else if (this.type === 'finals') {
            this.typeId = 7;
            this.uploadId = this.termModuleId;
            this.attachments = response.data.items[0].lokaprof;
          }
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    reload() {
      if (this.type === 'teachingplan') {
        this.uploadId = null;
      }
      this.loadItem();
    },
    async deleteAttachment(item) {
      await this.mixinDeleteAttachment(item);
      this.loadItem();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadItem();
  },
};
</script>

<style lang="scss"></style>
