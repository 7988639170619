<template>
  <Slidebar @closeMe="closeSlidebar" :extraLarge="true">
    <div class="d-inline-block mt-2">
      <h4>Magnbreyta einkunnaskala</h4>
      <div class="mb-2">1. Hakaðu við hverju þú vilt breyta.</div>
      <div class="d-inline-block pr-3">
        <div>Staðið</div>
        <b-form-checkbox v-model="edit.stadid"></b-form-checkbox>
      </div>
      <div class="d-inline-block pr-3">
        <div>Lýsing</div>
        <b-form-checkbox v-model="edit.lysing"></b-form-checkbox>
      </div>
      <div class="d-inline-block pr-3">
        <div>0 einingar á lokaprófi</div>
        <b-form-checkbox v-model="edit.ein_lokaprof"></b-form-checkbox>
      </div>
      <div class="d-inline-block pr-3">
        <div>Einingar</div>
        <b-form-checkbox v-model="edit.einingar"></b-form-checkbox>
      </div>
      <div class="d-inline-block pr-3">
        <div>Fall á önn</div>
        <b-form-checkbox v-model="edit.fall_a_onn"></b-form-checkbox>
      </div>
      <div class="d-inline-block">
        <div>Mætir í próf</div>
        <b-form-checkbox v-model="edit.maetir_i_prof"></b-form-checkbox>
      </div>
      <br />
      <div class="mb-2 mt-2">2. Veldu rétt gildi</div>
      <b-form-group label="Staðið" label-for="stadid" v-if="edit.stadid" class="mb-1">
        <v-select id="stadid" name="stadid" v-model="form.stadid" :options="yesNo" :clearable="false"> </v-select>
      </b-form-group>

      <b-form-group label="Lýsing" label-for="lysing" v-if="edit.lysing" class="mb-1">
        <b-form-input id="lysing" name="lysing" v-model="form.lysing" max-length="100" />
      </b-form-group>

      <b-form-group label="0 einingar á lokaprófi" label-for="ein_lokaprof" v-if="edit.ein_lokaprof" class="mb-1">
        <v-select id="ein_lokaprof" name="ein_lokaprof" v-model="form.ein_lokaprof" :options="yesNo" :clearable="false"> </v-select>
      </b-form-group>

      <b-form-group label="Einingar" label-for="einingar" v-if="edit.einingar" class="mb-1">
        <b-form-input id="einingar" name="einingar" v-model="form.einingar" />
      </b-form-group>

      <b-form-group label="Fall á önn" label-for="fall_a_onn" v-if="edit.fall_a_onn" class="mb-1">
        <v-select id="fall_a_onn" name="fall_a_onn" v-model="form.fall_a_onn" :options="yesNo" :clearable="false"> </v-select>
      </b-form-group>

      <b-form-group label="Mætir í próf" label-for="maetir_i_prof" v-if="edit.maetir_i_prof" class="mb-1">
        <v-select id="maetir_i_prof" name="maetir_i_prof" v-model="form.maetir_i_prof" :options="yesNo" :clearable="false"> </v-select>
      </b-form-group>

      <div class="mt-2 mb-2">3. Smelltu á breyta til að vista breytingu eða eyða til að eyða völdum færslum</div>
      <b-btn variant="primary" class="mr-2" :disabled="!isEditing" v-if="canEdit" @click="validateBeforeSubmit"> Breyta </b-btn>
      <b-btn variant="danger" class="float-right" v-if="canEdit" @click="deleteGradeScale"> Eyða </b-btn>
      <div class="mt-3">
        <strong>Færslur sem verið er að breyta</strong>
      </div>
      <span v-for="(item, idx) in items" :key="item.einkunnaskali_id"> <span v-if="idx != 0">, </span>{{ item.einkunn }} </span>
    </div>
  </Slidebar>
</template>

<script>
import types from '@/api/types';

import { mapActions, mapGetters } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';

export default {
  name: 'grade-scale-mass-edit-slidebar',
  props: ['id', 'items'],
  components: {
    Slidebar,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    isEditing() {
      return Object.values(this.edit).filter(x => x === true).length > 0;
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {
      saving: false,
      loading: false,
      edit: {
        stadid: false,
        lysing: false,
        ein_lokaprof: false,
        einingar: false,
        fall_a_onn: false,
        maetir_i_prof: false,
      },
      form: {
        stadid: { id: 0, label: 'Nei' },
        lysing: '',
        ein_lokaprof: { id: 0, label: 'Nei' },
        einingar: '',
        fall_a_onn: { id: 0, label: 'Nei' },
        maetir_i_prof: { id: 1, label: 'Já' },
      },
      yesNo: [
        { id: 1, label: 'Já' },
        { id: 0, label: 'Nei' },
      ],
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async validateBeforeSubmit() {
      try {
        this.saving = true;
        const item = {
          edit_stadid: this.edit.stadid ? 1 : 0,
          stadid: this.form.stadid ? 1 : 0,
          edit_lysing: this.edit.lysing ? 1 : 0,
          lysing: this.form.lysing,
          edit_ein_lokaprof: this.edit.ein_lokaprof ? 1 : 0,
          ein_lokaprof: this.form.ein_lokaprof ? 1 : 0,
          edit_einingar: this.edit.einingar ? 1 : 0,
          einingar: this.form.einingar,
          edit_fall_a_onn: this.edit.fall_a_onn ? 1 : 0,
          fall_a_onn: this.form.fall_a_onn ? 1 : 0,
          edit_maetir_i_prof: this.edit.maetir_i_prof ? 1 : 0,
          maetir_i_prof: this.form.maetir_i_prof ? 1 : 0,
          items: this.items.map(x => x.einkunnaskali_id),
        };
        await types.setMassGradeScale(this.id, item);
        this.displaySuccess('Einkunnaskala breytt');
        this.$emit('closeAndReloadSlidebar');
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.saving = false;
      }
    },
    async deleteGradeScale() {
      try {
        this.saving = true;
        const item = {
          delete: 1,
          items: this.items.map(x => x.einkunnaskali_id),
        };
        await types.setMassGradeScale(this.id, item);
        this.displaySuccess('Færslum í einkunnaskala hefur verið eytt');
        this.$emit('closeAndReloadSlidebar');
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.saving = false;
      }
    },
    initialize() {
      this.edit = {
        stadid: false,
        lysing: false,
        ein_lokaprof: false,
        einingar: false,
        fall_a_onn: false,
        maetir_i_prof: false,
      };
      this.form = {
        stadid: { id: 0, label: 'Nei' },
        lysing: '',
        ein_lokaprof: { id: 0, label: 'Nei' },
        einingar: '',
        fall_a_onn: { id: 0, label: 'Nei' },
        maetir_i_prof: { id: 1, label: 'Já' },
      };
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.initialize();
  },
};
</script>

<style lang="scss"></style>
