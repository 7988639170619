<template>
  <div>
    <div v-if="showCopyAlert" class="alert alert-info p-2" style="font-size: 13px">
      Hér þarf að smella á takkann <strong>Afrita uppbyggingu</strong> ef þú vilt afrita uppbygginguna frá brautinni sem þú ert að afrita
      frá. Gott er að hafa í huga ef verið er að afrita á milli undirskóla og áfangarnir eru ekki til í undirskólanum sem nýja brautin er á,
      þá stofnast áfangarnir einnig í þeim undirskóla.
    </div>
    <b-btn variant="primary" size="sm" class="mr-2" @click="openAll()">Opna allt</b-btn>
    <b-btn variant="primary" size="sm" @click="closeAll()">Loka öllu</b-btn>
    <b-btn variant="primary" size="sm" class="float-right mb-3" v-if="canEdit" @click="openSlidebar()"
      ><i class="fa fa-plus"></i> Stofna uppbyggingu</b-btn
    >
    <b-btn variant="primary" size="sm" class="float-right mr-3 mb-3" v-if="canEdit && this.list.length === 0" @click="openCopySlidebar()"
      ><i class="fa fa-copy"></i> Afrita uppbyggingu</b-btn
    >
    <div class="mt-2 mb-2" style="font-size: 13px">
      Til að tengja áfanga við brautina þarf að vera til brautaruppbygging hún er búin til með því að smella á takkann "Stofna uppbyggingu",
      áfangar eru svo tengdir við uppbygginguna með því að smella á (<i class="fa fa-plus-circle text-primary" />
      ) undir "Fjöldi áfanga".
    </div>
    <table class="table" style="font-size: 14px">
      <thead>
        <tr>
          <th>
            <LinkOrder title="Heiti" :filteredList="list" :list="list" column="uppbygging_heiti" />
            <i class="fa fa-info-circle" v-b-tooltip title="Heiti kemur m.a. fram þegar skoðuð er brautarkrafa nemenda."></i>
          </th>
          <th>
            <LinkOrder title="Heiti (enska)" :filteredList="list" :list="list" column="heiti_ens" second_column="heiti" />
            <i
              class="fa fa-info-circle"
              v-b-tooltip
              title="Þetta er notað ef skóli er með skírteini á ensku þar sem brautarkrafan kemur fram."
            ></i>
          </th>
          <th>
            <LinkOrder title="Einingar" :filteredList="list" :list="list" column="einingar" second_column="heiti" />
            <i
              class="fa fa-info-circle"
              v-b-tooltip
              title="Fjöldi eininga sem nemandi á að taka í þessum flokki, er sýnilegt í brautarkröfu hjá nemendum."
            ></i>
          </th>
          <th>
            <LinkOrder title="Tegund" :filteredList="list" :list="list" column="uppbygging_tegund" second_column="heiti" />

            <i
              class="fa fa-info-circle"
              v-b-tooltip.hover.html
              title="Kjarni = Áfangar sem nemendur verða að taka. <br/>
              Val = Nemendur geta valið áfangana.<br/>
              Bundið pakkaval = Þá velja nemendur pakka og þurfa að taka alla áfangana í pakkanum. <br/>
              Bundið áfangaval = Þá velja nemendur pakka og þurfa að velja x marga áfanga úr pakkanum."
            >
            </i>
          </th>
          <th>
            <LinkOrder title="Röð" :filteredList="list" :list="list" column="rod" second_column="heiti" />
            <i class="fa fa-info-circle" v-b-tooltip title="Í hvaða röð flokkarnir birtast í brautarkröfunni sem birtist hjá nemendum."></i>
          </th>
          <th>
            <LinkOrder title="Fjöldi áfanga" :filteredList="list" :list="list" column="fj_afanga_uppbygging" second_column="heiti" />
            <i class="fa fa-info-circle" v-b-tooltip title="Hversu margir áfangar eru í flokknum."></i>
          </th>
          <th></th>
        </tr>
      </thead>
      <TableRowLoader v-if="loading" colspan="7" :center="true" />
      <TableRowEmpty v-if="!loading && this.list.length === 0" colspan="7" :center="true" />
      <CourseStructureItem
        v-for="item in list"
        :key="item.braut_uppbygg_id"
        :courseId="courseId"
        :item="item"
        :open="open"
        :close="close"
        @openSlidebar="openSlidebar"
        @refresh="load"
      />
    </table>

    <course-structure-slidebar
      :courseId="courseId"
      :item="selectedItem"
      v-if="showSlidebar"
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
    >
    </course-structure-slidebar>

    <course-structure-copy-slidebar
      :courseId="courseId"
      :copyId="copyId"
      v-if="showCopySlidebar"
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
    >
    </course-structure-copy-slidebar>
  </div>
</template>

<script>
import curriculum from '@/api/curriculum';
import { mapGetters, mapActions } from 'vuex';

import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';

import CourseStructureSlidebar from '@/components/curriculum/courses/CourseStructureSlidebar.vue';
import CourseStructureCopySlidebar from '@/components/curriculum/courses/CourseStructureCopySlidebar.vue';
import CourseStructureItem from '@/components/curriculum/courses/CourseStructureItem.vue';

export default {
  name: 'course-structure',
  props: ['courseId', 'copyId'],
  components: {
    TableRowLoader,
    TableRowEmpty,
    LinkOrder,
    CourseStructureSlidebar,
    CourseStructureCopySlidebar,
    CourseStructureItem,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_brautir');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('namskra_brautir');
    },
    showCopyAlert() {
      return this.$route.query.copy;
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      loading: false,
      showSlidebar: false,
      showCopySlidebar: false,
      list: [],
      selectedItem: '',
      open: false,
      close: false,
    };
  },
  methods: {
    openAll() {
      this.open = true;
      setTimeout(() => {
        this.open = false;
      }, 1000);
    },
    closeAll() {
      this.close = true;
      setTimeout(() => {
        this.close = false;
      }, 1000);
    },
    openCopySlidebar() {
      this.showCopySlidebar = true;
      this.showSlidebar = false;
    },
    openSlidebar(item) {
      this.selectedItem = item;
      this.showSlidebar = true;
      this.showCopySlidebar = false;
    },
    closeSlidebar() {
      this.showSlidebar = false;
      this.showCopySlidebar = false;
    },
    closeAndReloadSlidebar() {
      this.closeSlidebar();
      this.load();
    },
    async load() {
      try {
        const response = await curriculum.getCourseStructureModules(this.courseId);
        this.list = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.load();
  },
};
</script>
