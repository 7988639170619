<template>
  <div>
    <div v-if="edit">
      <h5 style="margin-bottom: 0px">Setja áfanga í boði</h5>
      <div class="pb-2">Til að setja áfanga í boði þarf að færa þá úr vinstri dálk yfir í þann hægri.</div>
      <Shuttle
        :list="all"
        :value="selected"
        titleFrom="Fjöldi áfanga sem eru ekki í boði"
        titleTo="Fjöldi áfanga í boði"
        typeName="áfanga"
        :searchDepartment="1"
        :searchUnits="1"
      ></Shuttle>
      <div style="margin-top: 10px" :class="{ 'float-right': this.onlyEdit }">
        <b-btn variant="primary" class="ml-2" @click="saveTermModules()" :disabled="saving">
          <i class="fa fa-spin fa-spinner" v-if="saving"></i>Uppfæra</b-btn
        >
        <b-btn variant="dark" class="ml-2" @click="edit = false" :disabled="saving" v-if="!onlyEdit">
          <i class="fa fa-spin fa-spinner" v-if="saving"></i>Hætta við</b-btn
        >
      </div>
    </div>
    <div v-else>
      <b-row>
        <b-col class="pl-0" cols="8">
          Listi yfir þá áfanga sem eru í boði á önninni, ef listinn hér að neðan er tómur þá á eftir að tengja áfanga. Til að
          tengja/aftengja áfanga þá er smellt á "Breyta" takkann hægra megin á skjánum.
        </b-col>
        <b-col>
          <div class="float-right" v-if="canEdit">
            <b-btn variant="primary" size="sm" @click="edit = true" style="margin-top: -25px; width: 150px"
              ><i class="fa fa-pencil"></i> Breyta</b-btn
            >
          </div>
        </b-col>
      </b-row>

      <table class="table less-padding" style="font-size: 13px">
        <thead>
          <tr class="table-search">
            <td colspan="6" :class="{ 'pb-0': detailedSearch }">
              <b-form-input
                v-model="search.search"
                class="search"
                v-on:keyup.enter="loadList"
                placeholder="Leita eftir áfanga"
                ref="focusInput"
              ></b-form-input>
            </td>
            <td :class="{ 'pb-0': detailedSearch }">
              <div class="cursor-pointer text-right pt-2" @click="detailedSearch = !detailedSearch">
                <span v-if="detailedSearch"><i class="fa fa-angle-up"></i> Fela ítarleit</span>
                <span v-else><i class="fa fa-angle-up"></i> Sýna ítarleit</span>
              </div>
            </td>
          </tr>
          <tr v-if="detailedSearch" class="table-detail-search">
            <td colspan="2">
              <b-form-group label="Opið val" label-for="openChoice">
                <v-select
                  id="openChoice"
                  name="openChoice"
                  v-model="search.openChoice"
                  :options="options"
                  :clearable="false"
                  @input="loadModules"
                >
                </v-select>
              </b-form-group>
            </td>
            <td>
              <b-form-group label="Deild" label-for="department">
                <v-select
                  id="department"
                  name="department"
                  v-model="search.department"
                  :options="getDepartments"
                  :clearable="true"
                  @input="loadModules()"
                >
                </v-select>
              </b-form-group>
            </td>
            <td>
              <b-form-group label="Einingar" label-for="units">
                <b-form-input
                  title="Einingar frá"
                  v-b-tooltip
                  v-model="search.unitsFrom"
                  style="width: 25px; height: 25px; font-size: 12px; padding: 0px; padding-left: 5px"
                  class="d-inline-block"
                ></b-form-input>
                -
                <b-form-input
                  title="Einingar til"
                  v-b-tooltip
                  v-model="search.unitsTo"
                  style="width: 25px; height: 25px; font-size: 12px; padding: 0px; padding-left: 5px"
                  class="d-inline-block"
                ></b-form-input>
              </b-form-group>
            </td>
            <td>
              <b-form-group label="Leita í miðju orði" label-for="units">
                <b-form-checkbox v-model="search.searchMiddle"></b-form-checkbox>
              </b-form-group>
            </td>
          </tr>
          <tr>
            <th v-if="canEdit">
              <b-form-checkbox v-model="allSelected" @input="updateSelected()" class="d-inline-block"></b-form-checkbox>
              <b-btn
                v-if="nrSelectedToEdit > 0"
                @click="openMassEditSlidebar()"
                variant="primary"
                class="mr-1 btn-xs"
                style="font-size: 13px"
                v-b-tooltip
                title="Breyta"
              >
                <i class="fa fa-pencil" />
              </b-btn>
            </th>
            <th>
              <LinkOrder title="Einkenni" :filteredList="getList" :list="modules" column="namsgrein_afanganr"></LinkOrder>
              ({{ modules.length }})
            </th>
            <th>
              <LinkOrder
                title="Heiti"
                :filteredList="getList"
                :list="modules"
                column="afanga_heiti"
                secondColumn="namsgrein_afanganr"
              ></LinkOrder>
            </th>
            <th>
              <LinkOrder
                title="Einingar"
                :filteredList="getList"
                :list="modules"
                column="einingar"
                secondColumn="namsgrein_afanganr"
              ></LinkOrder>
            </th>
            <th>
              <LinkOrder
                title="Í próftöflu"
                :filteredList="getList"
                :list="modules"
                column="i_proftoflu"
                secondColumn="namsgrein_afanganr"
              ></LinkOrder>
            </th>
            <th>
              <LinkOrder
                title="Í stokk"
                :filteredList="getList"
                :list="modules"
                column="i_stokk"
                secondColumn="namsgrein_afanganr"
              ></LinkOrder>
            </th>
            <th>
              <LinkOrder
                title="Í stundatöflu"
                :filteredList="getList"
                :list="modules"
                column="i_stundatoflu"
                secondColumn="namsgrein_afanganr"
              ></LinkOrder>
            </th>
            <th>
              <LinkOrder
                title="Opið val"
                :filteredList="getList"
                :list="modules"
                column="opid_val"
                secondColumn="namsgrein_afanganr"
              ></LinkOrder>
              <i class="fa fa-info-circle" title="Má velja áfanga óháð brautum" v-b-tooltip></i>
            </th>
            <th>
              <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="cursor-pointer float-right">
                <i class="fa fa-file-excel-o excelText"></i>
              </download-csv>
            </th>
            <th></th>
          </tr>
        </thead>
        <TableRowLoader v-if="loading" colspan="8" :center="true" />
        <TableRowEmpty v-if="!loading && this.modules.length === 0" colspan="8" :center="true" />
        <tr v-for="m in getPaginatedList" :key="m.annir_afangar_id">
          <td v-if="canEdit"><b-form-checkbox v-model="m.selected"></b-form-checkbox></td>
          <td>
            {{ m.namsgrein_afanganr }}

            <b-link v-if="canReadModules" :to="{ name: 'Module', params: { id: m.afangi_id } }" target="_blank">
              <i class="fa fa-search"></i>
            </b-link>
          </td>
          <td>{{ m.afanga_heiti }}</td>
          <td>{{ m.einingar }}</td>
          <td><yes-no :item="m.i_proftoflu"></yes-no></td>
          <td><yes-no :item="m.i_stokk"></yes-no></td>
          <td><yes-no :item="m.i_stundatoflu"></yes-no></td>
          <td><yes-no :item="m.opid_val"></yes-no></td>
          <td>
            <b-btn variant="primary" class="btn-xs float-right" v-if="canEdit" @click="openSlidebar(m)"><i class="fa fa-pencil"></i></b-btn>
          </td>
        </tr>
      </table>

      <Pagination
        :currentPage="currentPage"
        :totalRows="getList.length"
        :perPage="perPage"
        v-if="modules.length > 20"
        @change-page="changePage"
        @change-limit="changeLimit"
      />
    </div>
    <change-term-module-slidebar
      v-if="showEditSlidebar"
      @closeAndReload="closeAndReloadSlidebar"
      @closeSlidebar="closeSlidebar"
      :item="selectedItem"
    >
    </change-term-module-slidebar>
    <change-mass-term-module-slidebar
      v-if="showMassEditSlidebar"
      :items="selectedToEdit"
      :id="id"
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
    >
    </change-mass-term-module-slidebar>
  </div>
</template>

<script>
import terms from '@/api/terms';
import curriculum from '@/api/curriculum';
import structure from '@/api/structure';
import { mapActions, mapGetters } from 'vuex';
import Shuttle from '@/components/common/Shuttle.vue';
import Pagination from '@/components/common/Pagination.vue';
import YesNo from '@/components/common/YesNo.vue';
import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';
import ChangeTermModuleSlidebar from '@/components/school/terms/ChangeTermModuleSlidebar.vue';
import ChangeMassTermModuleSlidebar from '@/components/school/terms/ChangeMassTermModuleSlidebar.vue';
import moment from 'moment';

export default {
  name: 'term-modules',
  props: ['id', 'item', 'division', 'onlyEdit', 'termPassed'],
  components: {
    Shuttle,
    Pagination,
    YesNo,
    TableRowLoader,
    TableRowEmpty,
    ChangeTermModuleSlidebar,
    ChangeMassTermModuleSlidebar,
    LinkOrder,
  },
  computed: {
    getExcelData() {
      const newList = [
        [],
        ['Áfangar í boði'],
        [this.item ? `${this.item.undirsk_heiti} - ${this.item.onn_heiti}` : ''],
        [],
        ['Einkenni', 'Heiti', 'Einingar', 'Í próftöflu', 'Í stokk', 'Í stundatöflu', 'Opið val'],
        ...this.getList.map(x => [
          x.namsgrein_afanganr,
          x.afanga_heiti,
          x.einingar,
          x.i_proftoflu === 1 ? 'Já' : 'Nei',
          x.i_stokk === 1 ? 'Já' : 'Nei',
          x.i_stundatoflu === 1 ? 'Já' : 'Nei',
          x.opid_val === 1 ? 'Já' : 'Nei',
        ]),
      ];
      return newList;
    },
    getExcelFileName() {
      return `afangar_i_bodi_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    nrSelectedToEdit() {
      return this.selectedToEdit.length;
    },
    selectedToEdit() {
      return this.modules.filter(x => x.selected);
    },
    getList() {
      return this.modules
        .filter(
          x =>
            this.search.search.length === 0 ||
            (!this.search.searchMiddle &&
              ((x.namsgrein_afanganr || '').toUpperCase().startsWith(this.search.search.toUpperCase()) ||
                (x.afanga_heiti || '').toUpperCase().startsWith(this.search.search.toUpperCase()))) ||
            (this.search.searchMiddle &&
              ((x.namsgrein_afanganr || '').toUpperCase().indexOf(this.search.search.toUpperCase()) > -1 ||
                (x.afanga_heiti || '').toUpperCase().indexOf(this.search.search.toUpperCase()) > -1)),
        )
        .filter(x => !this.search.unitsFrom || x.einingar >= this.search.unitsFrom)
        .filter(x => !this.search.unitsTo || x.einingar <= this.search.unitsTo);
    },
    getPaginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.getList.slice(start, end);
    },
    getDepartments() {
      return this.departments.filter(x => !this.search.department || x.department_id !== this.search.department.department_id);
    },
    canRead() {
      return this.loggedInUserHasReadPermission('skolahald_annir');
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_annir'); // && !this.termPassed;
    },
    canReadModules() {
      return this.loggedInUserHasReadPermission('namskra_afangar');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolHasSettings']),
  },
  data() {
    return {
      perPage: 20,
      currentPage: 1,
      detailedSearch: false,
      loading: false,
      loadingAll: false,
      modules: [],
      selected: [],
      departments: [],
      all: [],
      saving: false,
      edit: false,
      showEditSlidebar: false,
      selectedItem: '',
      search: {
        search: '',
        departments: '',
        openChoice: { id: '', label: 'Bæði' },
        unitsFrom: '',
        unitsTo: '',
        searchMiddle: false,
      },
      options: [
        { id: '', label: 'Bæði' },
        { id: '1', label: 'Opið val' },
        { id: '0', label: 'Ekki opið val' },
      ],
      allSelected: false,
      showMassEditSlidebar: false,
    };
  },
  methods: {
    async loadModules() {
      if (this.canRead) {
        try {
          this.loading = true;
          const response = await terms.getTermModules(this.id, {
            department: this.search.department ? this.search.department.department_id : '',
            openChoice: this.search.openChoice.id,
          });
          this.modules = response.data.items.map(x => ({
            ...x,
            selected: false,
          }));
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading = false;
        }
      }
    },
    async loadAllModules() {
      if (this.canEdit) {
        try {
          this.loadingAll = true;

          const response = await terms.getTermModules(this.id, { showAll: 1 });
          this.selected = response.data.items.map(x => x.afangi_id);

          const response2 = await curriculum.getModuleList({ division: this.division, active: 1, showAll: 1, showDepartment: 1 });
          this.all = response2.data.items.map(x => ({
            id: x.afangi_id,
            name: `${x.namsgrein_afanganr}, ${x.afanga_heiti}`,
            einingar: x.einingar,
            deild: (x.deild || '').split(','),
          }));
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loadingAll = false;
        }
      }
    },
    async loadDepartments() {
      try {
        const response = await structure.getDepartmentList();
        this.departments = response.data.items.map(x => ({
          ...x,
          label: `${x.name}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async saveTermModules() {
      try {
        this.saving = true;
        const data = {
          modules: this.selected,
        };
        await terms.setTermModules(this.id, data);
        this.displaySuccess('Áfangar vistaðir');
        this.loadModules();
        this.$emit('success');
      } catch (e) {
        if (e.response && e.response.data && e.response.data.error) {
          this.displayError(e.response.data.error);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } finally {
        this.saving = false;
      }
    },
    updateSelected() {
      this.getList.forEach(e => {
        this.$set(e, 'selected', this.allSelected);
      });
    },
    openMassEditSlidebar() {
      this.showMassEditSlidebar = true;
      this.showEditSlidebar = false;
    },
    openSlidebar(item) {
      this.selectedItem = item;
      this.showEditSlidebar = true;
    },
    closeSlidebar() {
      this.selectedItem = '';
      this.showEditSlidebar = false;
      this.showMassEditSlidebar = false;
    },
    closeAndReloadSlidebar() {
      this.closeSlidebar();
      this.loadModules();
      this.loadAllModules();
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    id() {
      this.loadModules();
      this.loadAllModules();
    },
  },
  created() {
    if (this.onlyEdit) {
      this.edit = true;
    }
    if (this.id) {
      this.loadModules();
      this.loadAllModules();
    }
    this.loadDepartments();
  },
};
</script>
