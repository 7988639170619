import auth from '@/api/auth';
/* eslint-disable no-param-reassign,import/prefer-default-export */
export const sortMixin = {
  data() {
    return {};
  },
  methods: {
    sortList(filteredList, list, column, secondColumn) {
      function localeSort(v1, v2, isSecond = false) {
        let col2v1;
        let col2v2;
        if (!false && secondColumn !== undefined) {
          col2v1 = v1[secondColumn];
          col2v2 = v2[secondColumn];
        }

        v1 = v1[column];
        v2 = v2[column];

        const t1 = typeof v1;
        const t2 = typeof v2;
        if (t1 === t2) {
          if (t1 === 'string') {
            v1 = v1.toUpperCase();
            v2 = v2.toUpperCase();
            const order = ' 0123456789AÁBCDÐEÉĘFGHIÍJKLMNOÓPQRSTUÚVWXYÝZÞÆÖ.,:;';
            let counter = 0;
            let s1;
            let s2;
            while (counter < v1.length && counter < v2.length) {
              s1 = v1.substring(counter, counter + 1);
              s2 = v2.substring(counter, counter + 1);
              if (s1 === s2) {
                counter += 1;
              } else {
                break;
              }
            }
            let returnValue = order.indexOf(s1) - order.indexOf(s2);
            if (!isSecond && returnValue === 0 && secondColumn !== undefined) {
              returnValue = localeSort(col2v1, col2v2, true);
            }

            return returnValue;
          }
          if (t1 === 'number') {
            let returnValue = v1 - v2;
            if (!isSecond && returnValue === 0 && secondColumn !== undefined) {
              returnValue = localeSort(col2v1, col2v2, true);
            }
            return returnValue;
          }

          const a = v1 == null ? '' : v1.toLocaleString();
          const b = v2 == null ? '' : v2.toLocaleString();
          let returnValue = a.localeCompare(b);
          if (!isSecond && returnValue === 0 && secondColumn !== undefined) {
            returnValue = localeSort(col2v1, col2v2, true);
          }
          return returnValue;
        }

        return t1.localeCompare(t2);
      }

      let same = true;
      if (filteredList !== undefined && filteredList.length > 0) {
        const checker = filteredList[0][column];
        for (let i = 0; i < filteredList.length; i += 1) {
          if (filteredList[i][column] !== checker) {
            same = false;
            break;
          }
        }
        if (same === false) {
          if (!list.column) {
            list.column = column;
            list.sort(localeSort);
          } else if (list.column === column) {
            list.reverse();
          } else {
            list.column = column;
            list.sort(localeSort);
          }
        }
      }
    },
  },
};
/* eslint-enable no-param-reassign */

export const attachmentMixin = {
  data() {
    return {};
  },
  methods: {
    async mixinDownloadAttachment(attachmentId) {
      try {
        const response = await this.$attachmentsApi.getAttachmentDownloadToken({ attachmentId });
        if (response.data.url) {
          window.open(response.data.url, '_blank');
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async mixinDeleteAttachment(attachment) {
      const conf = confirm(`Ert þú viss um að þú viljir eyða ${attachment.name}?`); // eslint-disable-line
      if (conf) {
        try {
          await this.$attachmentsApi.deleteAttachment({ attachmentId: attachment.attachment_id });
          this.displaySuccess('Skjali eytt.');
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
  },
};

export const fileDropzoneConfig = () => ({
  url: '/',
  maxFilesize: 500,
  maxFiles: 10,
  paramName: 'file',
  acceptedFiles: '.docx,.doc,.pdf,.xlsx,.xls',
  autoProcessQueue: true,
  addRemoveLinks: true,
  dictDefaultMessage: '<i class="fa fa-cloud-upload"></i> Smelltu hér eða dragðu skrá inn á svæðið.',
  dictFileTooBig: 'Skrá er of stór ({{filesize}} MB). Hámarksstærð er {{maxFilesize}} MB.',
  dictInvalidFileType: 'Skrá er ekki á leyfilegu skráarsniði.',
  dictRemoveFile: 'Fjarlægja skrá.',
  dictMaxFilesExceeded: 'Hámarksfjölda skráa náð.',
  headers: {
    token: auth.getToken(),
  },
});

export const GRADE_LETTERS = /^[A-Za-záðéíóúýþæöÁÐÉÍÓÚÝÞÆÖ][A-Za-záðéíóúýþæöÁÐÉÍÓÚÝÞÆÖ+\-\*]*$/;

export const isGradeValid = value => !!((isNaN(value) && value.match(GRADE_LETTERS)) || (Number(value) <= 10 && Number(value) >= 0)); // eslint-disable-line
