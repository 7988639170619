<template>
  <div>
    <b-row class="mb-2">
      <b-col class="pr-0">
        <div class="float-right">
          <CustomButton type="create" title="Nýr undirskóli" @click.native="openSlidebar()" v-if="canEdit"/>
        </div>
      </b-col>
    </b-row>
    <table class="table">
      <thead>
        <tr>
          <th>
            <LinkOrder title="Auðkenni" :filteredList="getList" :list="list" column="code" />
          </th>
          <th>
            <LinkOrder title="Heiti" :filteredList="getList" :list="list" column="name" />
          </th>
          <th>
            <LinkOrder title="Heiti (enska)" :filteredList="getList" :list="list" column="name_en" />
          </th>
          <th>
            <LinkOrder title="Opið f. brautaskipti" :filteredList="getList" :list="list" column="brautarskiptabeidni" />
          </th>
          <th>
            <LinkOrder title="Virk/ur" :filteredList="getList" :list="list" column="active" />
          </th>
          <th>
            <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="cursor-pointer float-right">
              <i class="fa fa-file-excel-o excelText"></i>
            </download-csv>
          </th>
        </tr>
      </thead>
      <tbody>
        <TableRowLoader v-if="loading" colspan="6" :center="true" />
        <TableRowEmpty v-if="!loading && this.list.length === 0" colspan="6" :center="true" />
        <tr
          v-for="item in getList"
          :key="item.division_id"
          :class="{
            'active-table-row': selectedItem && selectedItem.division_id === item.division_id,
            'cursor-pointer': showModuleSlidebar || showCourseSlidebar || showSlidebar,
          }"
          @click="checkSlidebar(item)"
        >
          <td>{{ item.code }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.name_en }}</td>
          <td>
            <span v-if="item.brautarskiptabeidni === 0">Lokað</span>
            <span v-if="item.brautarskiptabeidni === 1">Opið</span>
            <span v-if="item.brautarskiptabeidni === 2">Lokið - afgreiðsla opin</span>
            <b-btn
              class="btn-xs ml-2"
              style="margin-top: -5px"
              variant="primary"
              v-if="canReadCourses"
              @click="openCourseApplicationSlidebar(item)"
            >
              <i class="fa fa-search"></i>
            </b-btn>
          </td>
          <td><yes-no :item="item.active"></yes-no></td>
          <td>
            <div class="float-right" style="white-space: nowrap">
              <b-btn
                size="sm"
                variant="primary"
                class="btn-xs mr-2"
                title="Skoða áfanga"
                v-b-tooltip
                @click.prevent="openModuleSlidebar(item)"
              >
                <i class="fa fa-book" />
              </b-btn>
              <b-btn
                size="sm"
                variant="primary"
                class="btn-xs mr-2"
                title="Skoða brautir"
                v-b-tooltip
                @click.prevent="openCourseSlidebar(item)"
              >
                <i class="fa fa-sitemap" />
              </b-btn>
              <b-btn size="sm" variant="dark" class="btn-xs" @click.prevent="openSlidebar(item)" title="Breyta" v-b-tooltip v-if="canEdit">
                <i class="fa fa-pencil" />
              </b-btn>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <module-slidebar v-if="showModuleSlidebar" :item="selectedItem" type="division" @closeSlidebar="closeSlidebar"> </module-slidebar>
    <course-slidebar v-if="showCourseSlidebar" :item="selectedItem" type="division" @closeSlidebar="closeSlidebar"> </course-slidebar>
    <division-slidebar v-if="showSlidebar" :item="selectedItem" @closeSlidebar="closeAndReloadSlidebar"> </division-slidebar>
    <courses-application-slidebar
      v-if="showCourseApplicationSlidebar"
      :divisionId="selectedItem && selectedItem.division_id"
      :innrit_brautaskipti="true"
      @closeSlidebar="closeSlidebar"
    >
    </courses-application-slidebar>
  </div>
</template>

<script>
import moment from 'moment';
import structure from '@/api/structure';
import { mapActions, mapGetters } from 'vuex';
import ClickOutside from 'vue-click-outside';

import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';
import CustomButton from '@/components/common/CustomButton.vue';
import YesNo from '@/components/common/YesNo.vue';

import DivisionSlidebar from '@/components/school/school/divisions/DivisionSlidebar.vue';
import ModuleSlidebar from '@/components/curriculum/modules/ModuleSlidebar.vue';
import CourseSlidebar from '@/components/curriculum/courses/CourseSlidebar.vue';
import CoursesApplicationSlidebar from '@/components/curriculum/courses/CoursesApplicationSlidebar.vue';

export default {
  name: 'divisions',
  components: {
    TableRowLoader,
    TableRowEmpty,
    LinkOrder,
    ModuleSlidebar,
    CourseSlidebar,
    DivisionSlidebar,
    CustomButton,
    YesNo,
    CoursesApplicationSlidebar,
  },
  directives: {
    ClickOutside,
  },
  computed: {
    getList() {
      return this.list.filter(
        x => this.search.name.length === 0 || (x.name && x.name.toUpperCase().indexOf(this.search.name.toUpperCase()) > -1),
      );
    },
    getExcelData() {
      const newLine = [
        [],
        ['Undirskólar'],
        [],
        ['Auðkenni', 'Heiti', 'Heiti (enska)', 'Opið f. brautarskipti', 'Virk/ur'],
        ...this.getList.map(x => [x.code, x.name, x.name_en, x.brautarskiptabeidni === 1 ? 'Já' : 'Nei', x.active === 1 ? 'Já' : 'Nei']),
      ];
      return newLine;
    },
    getExcelFileName() {
      return `undirskoli_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    canReadCourses() {
      return this.loggedInUserHasWritePermission('namskra_brautir');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      loading: false,
      search: {
        name: '',
      },
      list: [],
      showModuleSlidebar: false,
      showCourseSlidebar: false,
      showSlidebar: false,
      showCourseApplicationSlidebar: false,
      selectedItem: {},
    };
  },
  methods: {
    async loadList() {
      try {
        this.loading = true;
        const response = await structure.getDivisionList();
        this.list = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    openModuleSlidebar(item) {
      this.selectedItem = item;
      this.showModuleSlidebar = true;
    },
    openCourseSlidebar(item) {
      this.selectedItem = item;
      this.showCourseSlidebar = true;
    },
    openSlidebar(item) {
      this.selectedItem = item;
      this.showSlidebar = true;
    },
    openCourseApplicationSlidebar(item) {
      this.selectedItem = item;
      this.showCourseApplicationSlidebar = true;
    },
    closeSlidebar() {
      this.showModuleSlidebar = false;
      this.showCourseSlidebar = false;
      this.showSlidebar = false;
      this.showCourseApplicationSlidebar = false;
      this.selectedItem = {};
    },
    closeAndReloadSlidebar() {
      this.closeSlidebar();
      this.loadList();
    },
    checkSlidebar(item) {
      if (this.showModuleSlidebar) {
        this.openModuleSlidebar(item);
      } else if (this.showCourseSlidebar) {
        this.openCourseSlidebar(item);
      } else if (this.showSlidebar) {
        this.openSlidebar(item);
      } else if (this.showCourseApplicationSlidebar) {
        this.openCourseApplicationSlidebar(item);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadList();
  },
};
</script>

<style lang="scss"></style>
