<template>
  <page>
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <b-row class="breadcumb-location">
      <b-col>
        <b-btn variant="dark" @click="openSearchSlidebar" class="float-right" style="height: 45px">
          <i class="fa fa-exchange" /> Breyta um starfsmann
        </b-btn>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col class="pl-0">
        <div class="d-inline-block pr-4" v-if="staff.mynd_clob">
          <img :src="`data:image/png;base64,${staff.mynd_clob}`" style="height: 100px; margin-top: -20px" />
          <!--div v-else style="height: 100px; width: 100px; background-color: #e5e5e5;"></div-->
        </div>
        <div class="d-inline-block">
          <PageTitle :title="title" style="margin-bottom: 0px" class="pt-3" />
          <div class="pt-2">{{ staff.kennitala }}</div>
        </div>
      </b-col>
    </b-row>
    <PageMenu route="StaffDetail" :selectedPage="page" :items="menu" />
    <StaffAccess v-if="page === 'access'" :id="id" />
    <!--StaffInfo v-if="page === 'details'" :staff="staff" /-->
    <!--StaffSubjects v-if="page === 'subjects'" :id="id" />
    <StaffTimetable v-if="page === 'timetable'" :id="id" />
    <StaffAbsences v-if="page === 'absences'" :id="id" />
    <StaffSpecialWishes v-if="page === 'wishes'" :id="id" />
    <StaffSupervisedStudents v-if="page === 'supervised'" :id="id" />
    <StaffUserGroups v-if="page === 'usergroups'" :id="id" />
    <StaffWorklog v-if="page === 'worklog'" :id="id" /-->

    <staff-search-slidebar @closeSlidebar="closeSlidebar" v-if="showSearchSlidebar"> </staff-search-slidebar>
  </page>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import staff from '@/api/staff';

import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import PageTitle from '@/components/common/PageTitle.vue';
import PageMenu from '@/components/common/PageMenu.vue';

import StaffSearchSlidebar from '@/components/staff/staff/StaffSearchSlidebar.vue';
/* import StaffSubjects from '@/components/staff/staff/StaffSubjects.vue';
import StaffTimetable from '@/components/staff/staff/StaffTimetable.vue';
import StaffAbsences from '@/components/staff/staff/StaffAbsences.vue';
import StaffSpecialWishes from '@/components/staff/staff/StaffSpecialWishes.vue';
import StaffSupervisedStudents from '@/components/staff/staff/StaffSupervisedStudents.vue';
import StaffUserGroups from '@/components/staff/staff/StaffUserGroups.vue';
import StaffWorklog from '@/components/staff/staff/StaffWorklog.vue'; */
import StaffAccess from '@/components/staff/staff/StaffAccess.vue';
// import StaffInfo from '@/components/staff/staff/StaffInfo.vue';

export default {
  name: 'staff-detail',
  components: {
    Breadcrumbs,
    PageTitle,
    PageMenu,
    StaffSearchSlidebar,
    /* StaffSubjects,
    StaffTimetable,
    StaffAbsences,
    StaffSpecialWishes,
    StaffSupervisedStudents,
    StaffUserGroups,
    StaffWorklog, */
    StaffAccess,
    // StaffInfo,
  },
  computed: {
    canRead() {
      return this.loggedInUserHasReadPermission('starfsmannahald_starfsmenn');
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('starfsmannahald_starfsmenn');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      page: 'access',
      breadcrumbs: [
        { name: 'Forsíða', route: 'Dashboard' },
        { name: 'Starfsmannahald', route: '' },
        { name: 'Starfsmenn', route: 'Staff' },
        { name: 'Starfsmaður', route: 'StaffDetail' },
      ],
      menu: [
        /* {
          name: 'Nánar',
          page: 'details',
        }, */
        {
          name: 'Aðgangur',
          page: 'access',
        } /*
        {
          name: 'Kennslugreinar',
          page: 'subjects',
        },
        {
          name: 'Stundatafla',
          page: 'timetable',
        },
        {
          name: 'Forföll',
          page: 'absences',
        },
        {
          name: 'Séróskir',
          page: 'wishes',
        },
        {
          name: 'Umsjónarnemendur',
          page: 'supervised',
        },
        {
          name: 'Notendahópar',
          page: 'usergroups',
        },
        {
          name: 'Vinnumat',
          page: 'worklog',
        }, */,
      ],
      loading: false,
      submitted: false,
      id: '',
      staff: '',
      showSearchSlidebar: false,
      title: '',
    };
  },
  methods: {
    async loadDetails() {
      if (this.canRead) {
        try {
          this.loading = true;
          const response = await staff.getStaffDetail(this.id);
          this.staff = response.data;
          this.title = `${this.staff.nafn}`;
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading = false;
        }
      }
    },
    openSearchSlidebar() {
      this.showSearchSlidebar = true;
    },
    closeSlidebar(item) {
      if (item) {
        this.$router.push({ name: 'StaffDetail', params: { id: item } });
        this.id = item;
        this.loadDetails();
      }
      this.showSearchSlidebar = false;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  beforeRouteUpdate(to, from, next) {
    if (to && to.query && to.query.page) {
      this.page = to.query.page;
    }
    next();
  },
  created() {
    this.id = this.$route.params.id;
    this.loadDetails();
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
  },
};
</script>
