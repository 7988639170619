<template>
  <Slidebar :autoClose="true" @closeMe="closeSlidebar" :large="large">
    <h4>Nemendur með áfanga í ferli</h4>
    <div style="font-size: 90%">
      Áfangi: <b>{{ courseName }}</b>
    </div>
    <div style="font-size: 90%">
      Önn: <b>{{ selectedTerm.onn_heiti }}</b>
    </div>
    <Loader v-if="loading.students"></Loader>
    <div class="mt-3" v-if="students.length === 0 && !loading.students">Engir nemendur fundust með áfanga í feril á þessari önn</div>
    <table class="table less-padding mt-2" style="font-size: 13px" v-if="students.length > 0 && !loading.students">
      <tr>
        <th><LinkOrder title="Nafn" :filteredList="students" :list="students" column="nafn" /> ({{ students.length }})</th>
        <th>
          <LinkOrder title="Kennitala" :filteredList="students" :list="students" column="kennitala" secondColumn="nafn" />
        </th>
        <th>
          <LinkOrder title="Nemendanr" :filteredList="students" :list="students" column="nemandi_id" secondColumn="nafn" />
        </th>
        <th>
          <LinkOrder title="Staða áfanga" :filteredList="students" :list="students" column="stada_afanga_heiti" secondColumn="nafn" />
          <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="d-inline float-right">
            <i class="fa fa-file-excel-o excelText cursor-pointer"></i>
          </download-csv>
        </th>
        <!-- <th>
                    <LinkOrder title="Dags. lokið" :filteredList="students" :list="students" column="dags_lokid"
                        secondColumn="nafn" />
                </th> -->
      </tr>
      <tr v-for="student in students" :key="student.kennitala">
        <td>
          {{ student.nafn }}
          <b-link
            :to="{ name: 'Student', params: { id: student.nemandi_id } }"
            target="_blank"
            v-if="canEdit"
            title="Skoða nemanda"
            v-b-tooltip
            ><i class="fa fa-external-link mr-1"></i
          ></b-link>
        </td>
        <td>{{ student.kennitala }}</td>
        <td>{{ student.nemandi_id }}</td>
        <td>{{ student.stada_afanga_heiti }}</td>
        <!-- <td>
                    <span v-if="student.dags_lokid">{{ student.dags_lokid | moment('DD.MM.YYYY') }}</span>
                </td> -->
      </tr>
    </table>
  </Slidebar>
</template>

<script>
import moment from 'moment';
import curriculum from '@/api/curriculum';
import { mapGetters, mapActions } from 'vuex';
import Slidebar from '@/components/common/Slidebar.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';
import Loader from '@/components/common/Loader.vue';

export default {
  name: 'module-students-term-slidebar',
  components: {
    Slidebar,
    LinkOrder,
    Loader,
  },
  props: ['termCourseId', 'selectedTerm', 'courseName', 'large'],
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('nemendahald_nemendur');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('nemendahald_nemendur');
    },
    getExcelData() {
      const newList = [
        [],
        ['Nemendur með áfanga í ferli'],
        [this.courseName],
        [this.selectedTerm.onn_heiti],
        [],
        ['Nafn', 'Kennitala', 'Nemandanr.', 'Staða áfanga'],
        ...this.students.map(x => [x.nafn, `="${x.kennitala}"`, x.nemandi_id, x.stada_afanga_heiti]),
      ];
      return newList;
    },
    getExcelFileName() {
      return `nemendur_med_afanga_i_ferli${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      loading: {
        students: false,
      },
      students: [],
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async loadStudents() {
      try {
        this.loading.students = true;
        const response = await curriculum.getModuleTermStudents(this.termCourseId);
        this.students = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.students = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadStudents();
  },
};
</script>

<style lang="scss"></style>
