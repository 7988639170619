<template>
  <div>
    <Loader v-if="loading"></Loader>
    <table class="table" style="font-size: 14px">
      <thead>
        <tr class="table-search">
          <td :colspan="afangaskoli ? 3 : 5" style="padding-top: 0; padding-left: 0px">
            <b-form-input v-model="search.name" class="search" placeholder="Tegund einkunnar" ref="focusInput" />
          </td>
          <td :colspan="afangaskoli ? 1 : 3" style="max-width: 80px">
            <div class="float-right" style="margin-top: -10px">
              <CustomButton type="create" title="Ný tegund" v-if="canEdit" @click.native="openSlidebar()" />
              <div class="mt-2">
                <b-form-checkbox class="d-inline-block" style="margin-top: -5px" v-model="search.onlyActive"></b-form-checkbox>
                <div class="d-inline-block" style="vertical-align: middle">Eingöngu virkar tegundir</div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <th v-if="canEdit" style="width: 2%; white-space: nowrap">
            <b-form-checkbox v-if="canEdit" v-model="selectAll" @change="updateAll()" class="d-inline-block"></b-form-checkbox>
            <b-btn
              variant="primary"
              class="btn-xs d-inline-block"
              @click="openMassEditSlidebar()"
              v-if="selectedGrades.length > 0"
              style="font-size: 12px"
            >
              <i class="fa fa-pencil"></i>
            </b-btn>
          </th>
          <th><LinkOrder title="Tegund einkunnar" :filteredList="getList" :list="list" column="heiti" />({{ getList.length }})</th>
          <th v-if="!afangaskoli">
            <LinkOrder title="Röð í námsferli" :filteredList="getList" :list="list" column="rod" />
            <i class="fa fa-info-circle" v-b-tooltip title="Röðun á birtingu í námsferli fyrir bekkjaskóla"></i>
          </th>
          <th v-if="!afangaskoli">
            <LinkOrder title="Kóði" :filteredList="getList" :list="list" column="kodi" />
            <i class="fa fa-info-circle" v-b-tooltip title="Kóði einkunnategundar notað fyrir bekkjaskóla"></i>
          </th>
          <th v-if="!afangaskoli">
            <LinkOrder title="Kóði í námsferil" :filteredList="getList" :list="list" column="kodi_ferill" />
            <i class="fa fa-info-circle" v-b-tooltip title="Kóði einkunnategundar í feril notað fyrir bekkjaskóla"></i>
          </th>
          <th v-if="!afangaskoli">
            <LinkOrder title="Reiknast í árseinkunn" :filteredList="getList" :list="list" column="i_medal" />
            <i class="fa fa-info-circle" v-b-tooltip title="Reiknast einkunn í meðtaltal fyrir bekkjaskóla"></i>
          </th>
          <th>
            <LinkOrder title="Virk" :filteredList="getList" :list="list" column="virk" />
          </th>
          <th>
            <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="cursor-pointer float-right">
              <i class="fa fa-file-excel-o excelText"></i>
            </download-csv>
          </th>
        </tr>
      </thead>
      <tbody>
        <TableRowLoader v-if="loading" :colspan="afangaskoli ? 4 : 6" :center="true" />
        <TableRowEmpty v-if="!loading && this.list.length === 0" :colspan="afangaskoli ? 4 : 6" :center="true" />
        <tr
          v-for="item in getList"
          :key="item.teg_eink"
          :class="{
            'active-table-row': selectedItem && selectedItem.teg_eink === item.teg_eink,
            'cursor-pointer': showSlidebar,
          }"
          @click="checkSlidebar(item)"
        >
          <td v-if="canEdit" style="width: 2%"><b-form-checkbox v-if="canEdit" v-model="item.selected"></b-form-checkbox></td>
          <td>{{ item.heiti }}</td>
          <td v-if="!afangaskoli">{{ item.rod }}</td>
          <td v-if="!afangaskoli">{{ item.kodi }}</td>
          <td v-if="!afangaskoli">{{ item.kodi_ferill }}</td>
          <td v-if="!afangaskoli"><yes-no :item="item.i_medal" v-if="item.i_medal !== null"></yes-no></td>
          <td><yes-no :item="item.virk"></yes-no></td>
          <td>
            <div class="float-right" v-if="canEdit">
              <b-btn variant="primary" class="mr-2 btn-xs" v-b-tooltip title="Breyta" @click.prevent="openSlidebar(item)">
                <i class="fa fa-pencil" />
              </b-btn>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <grade-type-slidebar
      v-if="showSlidebar"
      :item="selectedItem"
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
    >
    </grade-type-slidebar>
    <grade-type-mass-edit-slidebar
      v-if="showMassEditSlidebar"
      :items="selectedGrades"
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
    >
    </grade-type-mass-edit-slidebar>
  </div>
</template>

<script>
import types from '@/api/types';

import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

import CustomButton from '@/components/common/CustomButton.vue';
import YesNo from '@/components/common/YesNo.vue';

import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';
import Loader from '@/components/common/Loader.vue';

import GradeTypeSlidebar from '@/components/school/school/types/GradeTypeSlidebar.vue';
import GradeTypeMassEditSlidebar from '@/components/school/school/types/GradeTypeMassEditSlidebar.vue';

export default {
  name: 'gradeType',
  components: {
    TableRowLoader,
    TableRowEmpty,
    LinkOrder,
    GradeTypeSlidebar,
    GradeTypeMassEditSlidebar,
    CustomButton,
    YesNo,
    Loader,
  },
  computed: {
    afangaskoli() {
      return this.schoolSettings && this.schoolSettings.afangaskoli ? this.schoolSettings.afangaskoli === 1 : false;
    },
    getList() {
      return this.list
        .filter(x => !this.search.onlyActive || x.virk === 1)
        .filter(x => this.search.name.length === 0 || (x.heiti && x.heiti.toUpperCase().startsWith(this.search.name.toUpperCase())));
    },
    getExcelData() {
      if (!this.afangaskoli) {
        const newList = [
          [],
          ['Tegundir einkunna'],
          [],
          ['Heiti', 'Röð í námsferli', 'Kóði', 'Kóði í námsferil', 'Reiknast í árseinkunn', 'Virk'],
          ...this.getList.map(x => [x.heiti, x.rod, x.kodi, x.kodi_ferill, x.i_medal === 1 ? 'Já' : 'Nei', x.virk === 1 ? 'Já' : 'Nei']),
        ];
        return newList;
      }
      const newList = [[], ['Tegund einkunnar'], [], ['Heiti', 'Virk'], ...this.getList.map(x => [x.heiti, x.virk === 1 ? 'Já' : 'Nei'])];
      return newList;
    },
    getExcelFileName() {
      return `tegund_einkunna_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    selectedGrades() {
      return this.getList.filter(x => x.selected);
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {
      selectAll: false,
      loading: false,
      showSlidebar: false,
      showMassEditSlidebar: false,
      selectedItem: {},
      search: {
        name: '',
        onlyActive: true,
      },
      list: [],
    };
  },
  methods: {
    async loadList() {
      try {
        this.loading = true;
        this.selectAll = false;
        const response = await types.getGradeList();
        this.list = response.data.items;
        this.$nextTick(() => {
          if (this.$refs.focusInput) {
            this.$refs.focusInput.focus();
          }
        });
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    openSlidebar(item) {
      this.selectedItem = item;
      this.showSlidebar = true;
      this.showMassEditSlidebar = false;
    },
    openMassEditSlidebar() {
      this.showMassEditSlidebar = true;
      this.showSlidebar = false;
    },
    closeSlidebar() {
      this.showSlidebar = false;
      this.showMassEditSlidebar = false;
      this.selectedItem = {};
    },
    closeAndReloadSlidebar() {
      this.closeSlidebar();
      this.loadList();
    },
    checkSlidebar(item) {
      if (this.showSlidebar) {
        this.openSlidebar(item);
      }
    },
    updateAll() {
      this.getList.forEach(i => {
        this.$set(i, 'selected', this.selectAll);
      });
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadList();
  },
};
</script>
