<template>
  <Slidebar @closeMe="closeSlidebar" :large="large" :extraLarge="extraLarge">
    <h4 v-if="divisionId">{{ item.name }} - {{ item.code }}</h4>
    <h4 v-else>Stofna undirskóla</h4>
    <br />
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <table class="table less-padding table-fixed">
        <tr>
          <td>Auðkenni*</td>
          <td>
            <b-form-group label-for="code" :state="submitted && errors.has('code') ? false : ''" :invalid-feedback="errors.first('code')">
              <b-form-input
                id="code"
                name="code"
                type="text"
                v-model="form.code"
                :state="submitted && errors.has('heiti') ? false : ''"
                maxLength="10"
                v-validate="'required'"
                ref="focusInput"
                data-vv-as="auðkenni"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Heiti*</td>
          <td>
            <b-form-group
              label-for="heiti"
              :state="submitted && errors.has('heiti') ? false : ''"
              :invalid-feedback="errors.first('heiti')"
            >
              <b-form-input
                id="heiti"
                name="heiti"
                type="text"
                v-model="form.name"
                :state="submitted && errors.has('heiti') ? false : ''"
                maxLength="50"
                v-validate="'required'"
                data-vv-as="heiti"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Heiti (enska)</td>
          <td>
            <b-form-group
              label-for="heitiEn"
              :state="submitted && errors.has('heitiEn') ? false : ''"
              :invalid-feedback="errors.first('heitiEn')"
            >
              <b-form-input
                id="heitiEn"
                name="heitiEn"
                type="text"
                v-model="form.nameEn"
                :state="submitted && errors.has('heitiEn') ? false : ''"
                maxLength="100"
                data-vv-as="heiti (enska)"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Opið fyrir brautaskipti</td>
          <td>
            <b-form-group
              label-for="brautarskiptabeidni"
              :state="submitted && errors.has('brautarskiptabeidni') ? false : ''"
              :invalid-feedback="errors.first('brautarskiptabeidni')"
            >
              <v-select
                id="brautarskiptabeidni"
                name="brautarskiptabeidni"
                v-model="form.brautarskiptabeidni"
                :options="courseChange"
                :clearable="false"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
      </table>
      <b-btn variant="primary" class="mr-2" v-if="canEdit" @click="validateBeforeSubmit">
        <span v-if="divisionId">Breyta</span>
        <span v-else>Stofna</span>
      </b-btn>
      <b-btn variant="dark" @click="closeSlidebar">Hætta við</b-btn>
    </b-form>
  </Slidebar>
</template>

<script>
import structure from '@/api/structure';
import { mapActions, mapGetters } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';

export default {
  name: 'division-slidebar',
  props: ['item', 'large', 'extraLarge'],
  components: {
    Slidebar,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      loading: false,
      submitted: false,
      divisionId: null,
      form: {
        code: '',
        name: '',
        nameEn: '',
        brautarskiptabeidni: { id: 0, label: 'Lokað' },
        active: 1,
      },
      courseChange: [
        { id: 0, label: 'Lokað' },
        { id: 1, label: 'Opið - hægt að sækja um' },
        { id: 2, label: 'Lokað - hægt að skoða afgreiðslu' },
      ],
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async loadDivisionDetail() {
      try {
        const response = await structure.getDivisionDetail(this.divisionId);
        if (response.data.items.length > 0) {
          this.form.code = response.data.items[0].code;
          this.form.name = response.data.items[0].name;
          this.form.nameEn = response.data.items[0].name_en;
          this.form.brautarskiptabeidni = this.courseChange.find(x => x.id === response.data.items[0].brautarskiptabeidni);
          this.form.active = response.data.items[0].active === 1;
        }
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.saving = true;
          if (this.divisionId) {
            await structure.updateDivision(this.divisionId, {
              name: this.form.name,
              code: this.form.code,
              active: this.form.active ? 1 : 0,
              nameEn: this.form.nameEn,
              course_change: this.form.brautarskiptabeidni.id,
            });
            this.displaySuccess(`Undirskólanum ${this.form.name} hefur verið breytt.`);
          } else {
            await structure.createDivision({
              name: this.form.name,
              code: this.form.code,
              nameEn: this.form.nameEn,
              active: 1,
              course_change: this.form.brautarskiptabeidni.id,
            });
            this.displaySuccess(`Undirskólanum ${this.form.name} hefur verið stofnaður.`);
          }
          this.closeSlidebar();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.saving = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    item() {
      this.form.code = '';
      this.form.name = '';
      this.form.nameEn = '';
      this.form.active = true;
      if (this.item && this.item.division_id) {
        this.divisionId = this.item.division_id;
        this.loadDivisionDetail();
      }
      this.$nextTick(() => {
        if (this.$refs.focusInput) {
          this.$refs.focusInput.focus();
        }
      });
    },
  },
  created() {
    this.form.code = '';
    this.form.name = '';
    this.form.nameEn = '';
    this.form.active = true;
    this.divisionId = null;
    if (this.item && this.item.division_id) {
      this.divisionId = this.item.division_id;
      this.loadDivisionDetail();
    }
    this.$nextTick(() => {
      if (this.$refs.focusInput) {
        this.$refs.focusInput.focus();
      }
    });
  },
};
</script>

<style lang="scss"></style>
