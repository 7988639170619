<template>
  <Slidebar :autoClose="false" @closeMe="closeSlidebar" :large="large" :extraLarge="true">
    <h4>Athugasemdir nemanda</h4>
    <StudentInfo v-if="studentId" :id="studentId" style="margin-bottom: -10px" />
    <StudentComments :studentId="studentId" />
  </Slidebar>
</template>

<script>
import Slidebar from '@/components/common/Slidebar.vue';
import StudentComments from '@/components/students/comments/StudentComments.vue';
import StudentInfo from '@/components/common/StudentInfo.vue';

export default {
  name: 'group-assessment-overview-slidebar',
  components: {
    Slidebar,
    StudentComments,
    StudentInfo,
  },
  props: ['studentId', 'large', 'extraLarge'],
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
  },
};
</script>

<style lang="scss"></style>
