<template>
  <div>
    <b-row>
      <b-col class="pl-0 mb-4">
        <div v-if="isVacation">
          Í stýringum skólans er hægt að velja hver er sjálfgefinn forfallakóði þegar tilkynning er samþykkt. Í þínum skóla er stillt að
          sjálfgefinn forfallakóði fyrir skammtímaleyfi: {{ getCode(schoolSettings.rafraen_leyfi_skam_kodi) }} og langtímaleyfi:
          {{ getCode(schoolSettings.rafraen_leyfi_lang_kodi) }}
          en hægt er að breyta þeim í stýringum með því að smella
          <span v-if="canEditUppbygging">
            en hægt er að breyta honum í stýringum með því að smella
            <b-link :to="{ name: 'School', query: { page: 'settings', tab: 'attendance' } }" target="_blank">hér</b-link>.</span
          >
        </div>
        <div v-else>
          Í stýringum skólans er hægt að velja hver er sjálfgefinn forfallakóði þegar tilkynning er samþykkt. Í þínum skóla er stillt að
          sjálfgefinn forfallakóði er {{ getCode(schoolSettings.rafraen_veikindi_kodi) }}
          <span v-if="canEditUppbygging">
            en hægt er að breyta honum í stýringum með því að smella
            <b-link :to="{ name: 'School', query: { page: 'settings', tab: 'attendance' } }" target="_blank">hér</b-link>.</span
          >
        </div>
        <div>
          Ef forráðamaður hefur skráð tilkynninguna kemur (i táknið) aftast í skráningunni, hægt er að fara með músina yfir til að sjá hver
          sótti um.
        </div>

        <div class="mt-2" style="font-size: 12px">
          <i
            >Forfallakóðar: <span v-for="abs in absenceList" :key="abs.teg_forfalla" class="pr-2">{{ abs.kodi }} = {{ abs.heiti }}</span></i
          >
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="pl-0" cols="3" style="margin-top: -5px">
        <b-form-group label="Undirskóli" label-for="division">
          <v-select id="division" name="division" v-model="search.division" :options="divisions" :clearable="true"> </v-select>
        </b-form-group>
      </b-col>
      <b-col v-if="isVacation" cols="3" style="margin-top: -5px">
        <b-form-group label="Tegund" label-for="types">
          <v-select id="types" name="types" v-model="search.type" :options="typeList" :clearable="true"> </v-select>
        </b-form-group>
      </b-col>
      <b-col v-if="!isVacation" cols="3" style="margin-top: -5px">
        <b-form-group label="Heill dagur" label-for="wholeDay">
          <v-select id="wholeDay" name="wholeDay" v-model="search.wholeDay" :options="yesNoList" :clearable="true"> </v-select>
        </b-form-group>
      </b-col>
      <b-col cols="3" style="margin-top: -5px">
        <b-form-group label="Nafn" label-for="name">
          <b-form-input id="name" name="name" v-model="search.name" />
        </b-form-group>
      </b-col>
      <b-col cols="3" style="margin-top: -5px">
        <b-form-group label="Kennitala" label-for="ssn">
          <b-form-input id="ssn" name="ssn" v-model="search.ssn" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="pl-0">
        <Loader v-if="loading.list" />
        <div v-if="list.length === 0 && !loading.list">
          Engar óafgreiddar <span v-if="isAbsence">veikindatilkynningar</span><span v-else>beiðnir</span>
        </div>
        <table class="table table-hover no-border less-padding" v-if="list.length > 0" style="font-size: 13px">
          <thead>
            <tr>
              <th v-if="canEdit">
                <b-form-checkbox class="d-inline-block" v-if="list.length > 0" @input="updateSelectAll()" v-model="selectAll.all">
                </b-form-checkbox>
                <b-btn
                  variant="danger"
                  class="btn-xs"
                  style="font-size: 11px"
                  :disabled="deleting"
                  v-if="getSelected.length > 0"
                  @click="deleteSelectedItems()"
                >
                  <i class="fa fa-trash"></i>
                  <i class="fa fa-spin fa-spinner" v-if="deleting"></i>
                </b-btn>
              </th>
              <th><LinkOrder title="Nafn" :filteredList="getList" :list="list" column="nafn" />({{ getList.length }})</th>
              <th>
                <LinkOrder title="Kennitala" :filteredList="getList" :list="list" column="kennitala" />
              </th>
              <th>
                <LinkOrder title="Braut" :filteredList="getList" :list="list" column="braut_kodi" />
                <i class="fa fa-info-circle" title="Braut sem nemandinn var á miðað við dagsetningu beiðnar" v-b-tooltip></i>
              </th>
              <th>
                <LinkOrder title="Fj." :filteredList="getList" :list="list" column="fj_skraninga" />
                <i
                  class="fa fa-info-circle"
                  v-if="isAbsence"
                  title="Fjöldi veikindatilkynninga síðustu 90 daga sem tekið hefur verið afstöðu til."
                  v-b-tooltip
                />
                <i class="fa fa-info-circle" v-else title="Fjöldi beiðna síðustu 90 daga sem tekið hefur verið afstöðu til." v-b-tooltip />
              </th>
              <th class="text-center" v-if="canEdit">
                <span v-b-tooltip title="Samþykkja" class="fa fa-fw fa-check text-success"></span>
                <b-form-checkbox v-model="selectAll.confirm" @input="changeAllSelection(0)" />
              </th>
              <th class="text-center" v-if="canEdit">
                <span v-b-tooltip title="Hafnað" class="fa fa-fw fa-remove text-danger"></span>
                <b-form-checkbox v-model="selectAll.reject" @input="changeAllSelection(1)" />
              </th>
              <th class="text-center" v-if="canEdit">
                <span v-b-tooltip title="Vantar vottorð" class="fa fa-user-md text-warning"></span>
                <b-form-checkbox v-model="selectAll.certificate" @input="changeAllSelection(2)" />
              </th>
              <th class="text-center" v-if="canEdit">
                <span v-b-tooltip title="Annað" class="fa fa-fw fa-commenting text-primary"></span>
                <b-form-checkbox v-model="selectAll.other" @input="changeAllSelection(3)" />
              </th>
              <th v-if="canEdit">
                <span v-if="anyConfirmed">Kóði <i class="fa fa-info-circle" title="Forfallakóði sem verður notaður" v-b-tooltip></i></span>
              </th>
              <th>
                <LinkOrder title="Ath. afgreiðslu" :filteredList="getList" :list="list" column="athugasemd_afgreidsla" />
                <i
                  class="fa fa-info-circle"
                  title="Athugasemd afgreiðslu er einnig sýnileg nemanda og forráðamanni ef við á."
                  v-b-tooltip
                ></i>
              </th>
              <th>
                <LinkOrder title="Ath. fjarv." :filteredList="getList" :list="list" column="athugasemd_forfoll" />
                <i
                  v-if="isAbsence"
                  class="fa fa-info-circle"
                  title="Athugsemd sem birtist í fjarvistarferli nemandans og er sýnileg kennurum.
                  Einungis hægt að skrá ef veikindatilkynning er samþykkt."
                  v-b-tooltip
                ></i
                ><i
                  v-else
                  class="fa fa-info-circle"
                  title="Athugsemd sem birtist í fjarvistarferli nemandans og er sýnileg kennurum.
                  Einungis hægt að skrá ef beiðni er samþykkt."
                  v-b-tooltip
                ></i>
              </th>
              <th v-if="isVacation">
                <LinkOrder title="Dags.frá" :filteredList="getList" :list="list" column="dags_fra" />
              </th>
              <th v-if="isVacation">
                <LinkOrder title="Dags.til" :filteredList="getList" :list="list" column="dags_til" />
              </th>
              <th v-if="!isVacation">
                <LinkOrder title="Dags" :filteredList="getList" :list="list" column="dags_fra" />
                <i class="fa fa-info-circle" title="Dagsetning tímans sem viðkomandi er veikur." v-b-tooltip></i>
              </th>
              <th v-if="!isVacation">
                <LinkOrder title="Heill dagur" :filteredList="getList" :list="list" column="heill_dagur" />
              </th>
              <th>
                <LinkOrder title="Dags.skráð" :filteredList="getList" :list="list" column="time_create" />
              </th>
              <th v-if="isVacation">
                <LinkOrder title="Tegund leyfis" :filteredList="getList" :list="list" column="tegund_heiti" />
              </th>
              <th>
                <LinkOrder title="Skýring" :filteredList="getList" :list="list" column="athugasemd_skraning" />
                <i
                  v-if="isAbsence"
                  class="fa fa-info-circle"
                  title="Skýring sem nemandi skráir með veikindatilkynningunni, þessi skýring fer ekki í fjarvistarferilinn
                  og er ekki sýnileg kennurum."
                  v-b-tooltip
                ></i>
                <i
                  v-else
                  class="fa fa-info-circle"
                  title="Skýring sem nemandi skráir með beiðni, þessi skýring fer ekki í fjarvistarferilinn og er ekki sýnileg kennurum."
                  v-b-tooltip
                ></i>
              </th>
              <th v-if="isAbsence">
                Vottorð
                <i
                  class="fa fa-info-circle"
                  title="Skrá kröfu um að nemandi þurfi alltaf að skila vottorði þegar hann skráir veikindi."
                  v-b-tooltip
                ></i>
              </th>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in getPaginatedList" :key="index">
              <td v-if="canEdit">
                <b-form-checkbox v-model="item.selected"> </b-form-checkbox>
              </td>
              <td style="white-space: nowrap">
                <i
                  v-if="item.virkur === 0"
                  class="fa fa-exclamation-triangle"
                  style="color: #f26175"
                  title="Ath. Nemandi er óvirkur"
                  v-b-tooltip
                ></i>
                {{ item.nafn }} {{ item.bekkur }}
                <i
                  class="cursor-pointer fa fa-search text-primary"
                  title="Skoða viðveru nemanda"
                  v-b-tooltip
                  @click="openStudentAttendanceSlidebar(item)"
                ></i>
                <b-link
                  :to="{ name: 'Student', params: { id: item.nemandi_id } }"
                  target="_blank"
                  v-if="canViewStudent"
                  title="Skoða nemanda"
                  v-b-tooltip
                  ><i class="fa fa-external-link ml-1"></i
                ></b-link>
              </td>
              <td>{{ item.kennitala }}</td>
              <td>
                <span v-b-tooltip :title="item.braut_heiti">{{ item.braut_kodi }}</span>
              </td>
              <td>
                {{ item.fj_skraninga }}
                <i
                  class="cursor-pointer fa fa-search text-primary"
                  title="Skoða skráningar nemanda"
                  v-b-tooltip
                  @click="openStudentRequestsSlidebar(item)"
                ></i>
              </td>
              <td class="text-center" v-if="canEdit"><b-form-checkbox v-model="item.confirm" @input="changeSelection(item, 0)" /></td>
              <td class="text-center" v-if="canEdit"><b-form-checkbox v-model="item.reject" @input="changeSelection(item, 1)" /></td>
              <td class="text-center" v-if="canEdit"><b-form-checkbox v-model="item.certificate" @input="changeSelection(item, 2)" /></td>
              <td class="text-center" v-if="canEdit"><b-form-checkbox v-model="item.other" @input="changeSelection(item, 3)" /></td>
              <td v-if="canEdit">
                <v-select
                  class="dropdownAbsenceList"
                  :id="`absence${index}`"
                  :name="`absence${index}`"
                  v-model="item.absenceCode"
                  :clearable="false"
                  :options="absenceList"
                  label="kodi"
                  v-if="item.confirm"
                >
                </v-select>
              </td>
              <td v-if="canEdit">
                <b-form-input
                  v-model="item.athugasemd_afgreidsla"
                  style="height: 25px; font-size: 13px; padding-left: 2px"
                  max-length="2000"
                />
              </td>
              <td v-if="canEdit">
                <b-form-input
                  v-model="item.athugasemd_forfoll"
                  style="height: 25px; font-size: 13px; padding-left: 2px"
                  max-length="1000"
                  :disabled="!item.confirm"
                />
              </td>
              <td v-if="isVacation">{{ item.dags_fra | moment('DD.MM.YYYY') }}</td>
              <td v-if="isVacation">{{ item.dags_til | moment('DD.MM.YYYY') }}</td>
              <td v-if="!isVacation">{{ item.dags_fra | moment('DD.MM.YYYY') }}</td>
              <td v-if="!isVacation">
                <yes-no :item="item.heill_dagur"></yes-no>
                <span v-if="item.heill_dagur === 0">
                  - {{ item.nemandi_forf_timar.length }} <span v-if="item.nemandi_forf_timar.length === 1">tími </span
                  ><span v-else>tímar </span>
                </span>
              </td>
              <td>{{ item.time_create | moment('DD.MM.YYYY HH:mm:ss') }}</td>
              <td v-if="isVacation">{{ item.tegund_heiti }}</td>
              <td style="font-size: 12px">
                {{
                  item.athugasemd_skraning && item.athugasemd_skraning.length > 50
                    ? `${item.athugasemd_skraning.substring(0, 50)}`
                    : item.athugasemd_skraning
                }}
                <span
                  v-if="item.athugasemd_skraning && item.athugasemd_skraning.length > 50"
                  v-b-tooltip
                  :title="`${item.athugasemd_skraning}`"
                  >...</span
                >
              </td>
              <td v-if="isAbsence" style="font-size: 12px">
                <b-link @click="openStudentCommentSlidebar(item)" v-if="!item.vottord">Skrá</b-link>{{ item.vottord }}
              </td>
              <td>
                <span
                  v-if="item.persona_is_nemandi === 0"
                  class="fa fa-info-circle"
                  v-b-tooltip
                  :title="`Sótt um af: ${item.person_create_nafn}`"
                ></span>
              </td>
              <td>
                <b-btn variant="primary" size="xs" @click="openRequestSlidebar(item, false)">
                  <i v-b-tooltip title="Skoða" class="fa fa-search"></i>
                </b-btn>
              </td>
            </tr>
          </tbody>
        </table>
        <Pagination
          :currentPage="currentPage"
          :totalRows="getList.length"
          :perPage="perPage"
          v-if="list.length > 15"
          @change-page="changePage"
          @change-limit="changeLimit"
        />
        <b-btn
          variant="primary"
          class="mb-4"
          v-if="list.length > 0 && !loading.data && canEdit"
          :disabled="saving || getSelectedItems.length === 0"
          @click="processRequests()"
          >Staðfesta</b-btn
        >
      </b-col>
    </b-row>
    <view-request-slidebar
      v-if="showRequestSlidebar"
      :item="selectedItem"
      :defaultEdit="defaultEdit"
      @closeSlidebar="closeSlidebar"
      @closeAndReload="closeAndReloadSlidebar"
    />
    <student-attendance-slidebar
      v-if="showStudentAttendanceSlidebar"
      :studentId="selectedItem && selectedItem.nemandi_id"
      @closeSlidebar="closeSlidebar"
      @closeAndReload="closeAndReloadSlidebar"
    />
    <student-add-comment-slidebar
      v-if="showStudentCommentSlidebar"
      :studentId="selectedItem && selectedItem.nemandi_id"
      :showDetails="true"
      :certificate="true"
      @closeSlidebar="closeSlidebar"
      @closeAndReload="closeAndReloadSlidebar"
    />
    <student-absence-requests-slidebar
      v-if="showStudentRequestsSlidebar"
      :studentId="selectedItem && selectedItem.nemandi_id"
      :absenceTypes="absenceTypes"
      @closeSlidebar="closeSlidebar"
      @closeAndReload="closeAndReloadSlidebar"
    />
  </div>
</template>

<script>
import requests from '@/api/requests';
import types from '@/api/types';
import structure from '@/api/structure';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

import LinkOrder from '@/components/common/LinkOrder.vue';
import Loader from '@/components/common/Loader.vue';
import Pagination from '@/components/common/Pagination.vue';
import YesNo from '@/components/common/YesNo.vue';

import ViewRequestSlidebar from '@/components/requests/reviewAbsence/ViewRequestSlidebar.vue';
import StudentAttendanceSlidebar from '@/components/students/attendance/StudentAttendanceSlidebar.vue';
import StudentAddCommentSlidebar from '@/components/students/comments/StudentAddCommentSlidebar.vue';
import StudentAbsenceRequestsSlidebar from '@/components/students/student/absenceRequests/StudentAbsenceRequestsSlidebar.vue';

export default {
  name: 'review-absence-pending',
  props: ['absenceTypes'],
  components: {
    LinkOrder,
    ViewRequestSlidebar,
    Loader,
    Pagination,
    StudentAttendanceSlidebar,
    StudentAddCommentSlidebar,
    StudentAbsenceRequestsSlidebar,
    YesNo,
  },
  computed: {
    isVacation() {
      return this.absenceTypes.indexOf('2') > -1 || this.absenceTypes.indexOf('3') > -1;
    },
    isAbsence() {
      return this.absenceTypes.indexOf('1') > -1;
    },
    anyConfirmed() {
      return this.list.filter(x => x.confirm).length > 0;
    },
    getSelectedItems() {
      return this.list.filter(x => x.confirm || x.reject || x.certificate || x.other);
    },
    getList() {
      return this.list
        .filter(x => !this.search.division || x.undirskoli_id === this.search.division.division_id)
        .filter(x => !this.search.type || x.nemandi_forfoll_tegund === this.search.type.id)
        .filter(x => !this.search.wholeDay || x.heill_dagur === this.search.wholeDay.id)
        .filter(x => this.search.name.length === 0 || (x.nafn || '').toUpperCase().startsWith(this.search.name.toUpperCase()))
        .filter(x => this.search.ssn.length === 0 || x.kennitala.indexOf(this.search.ssn) > -1);
    },
    getPaginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.getList.slice(start, end);
    },
    getSelected() {
      return this.getList.filter(x => x.selected);
    },
    canEdit() {
      if (this.isVacation) return this.loggedInUserHasWritePermission('beidnir_leyfi');
      if (this.isAbsence) return this.loggedInUserHasWritePermission('beidnir_veikindi');
      return false;
    },
    canEditUppbygging() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    canViewStudent() {
      return this.loggedInUserHasWritePermission('nemendahald_nemendur');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {
      perPage: 15,
      currentPage: 1,
      saving: false,
      deleting: false,
      selectedItem: '',
      showRequestSlidebar: false,
      showStudentAttendanceSlidebar: false,
      showStudentCommentSlidebar: false,
      showStudentRequestsSlidebar: false,
      defaultEdit: false,
      loading: {
        list: false,
        absences: false,
      },
      list: [],
      absenceList: [],
      divisions: [],
      statuses: [
        { id: 0, label: 'Óafgreidd' },
        { id: 1, label: 'Samþykkt' },
        { id: 2, label: 'Vantar vottorð' },
        { id: 3, label: 'Hafnað' },
        { id: 4, label: 'Annað' },
      ],
      typeList: [
        // { id: 1, label: 'Veikindi' },
        { id: 2, label: 'Skammtímaleyfi' },
        { id: 3, label: 'Langtímaleyfi' },
      ],
      yesNoList: [
        { id: 1, label: 'Já - heill dagur' },
        { id: 0, label: 'Nei - hluti úr degi' },
      ],
      search: {
        division: '',
        type: '',
        wholeDay: '',
        name: '',
        ssn: '',
      },
      selectAll: {
        all: false,
        confirm: false,
        reject: false,
        certificate: false,
        other: false,
      },
    };
  },
  methods: {
    updateSelectAll() {
      this.getList.forEach(item => {
        this.$set(item, 'selected', this.selectAll.all);
      });
    },
    async loadData() {
      await this.loadAbsenceList();
      this.loadList();
    },
    async loadList() {
      try {
        this.list = [];
        this.loading.list = true;
        const response = await requests.getReviewAbsenceList({ status: 0, absenceTypes: this.absenceTypes });
        this.list = response.data.items.map(x => ({
          ...x,
          absenceCode: this.absenceList.find(
            y =>
              (x.nemandi_forfoll_tegund === 1 && y.teg_forfalla === this.schoolSettings.rafraen_veikindi_kodi) ||
              (x.nemandi_forfoll_tegund === 2 && y.teg_forfalla === this.schoolSettings.rafraen_leyfi_skam_kodi) ||
              (x.nemandi_forfoll_tegund === 3 && y.teg_forfalla === this.schoolSettings.rafraen_leyfi_lang_kodi),
          ),
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.list = false;
      }
    },
    async loadAbsenceList() {
      try {
        this.loading.absences = true;
        const response = await types.getAbsenceList({ active: 1 });
        this.absenceList = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.absences = false;
      }
    },
    getCode(id) {
      const exists = this.absenceList.find(x => x.teg_forfalla === id);
      return exists ? `${exists.heiti}(${exists.kodi})` : '';
    },
    async loadDivisions() {
      try {
        const response = await structure.getDivisionList();
        this.divisions = response.data.items.map(x => ({
          ...x,
          label: `${x.code} - ${x.name}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    changeSelection(item, type) {
      if (type === 0 && item.confirm) {
        this.$set(item, 'reject', false);
        this.$set(item, 'certificate', false);
        this.$set(item, 'other', false);
      } else if (type === 1 && item.reject) {
        this.$set(item, 'confirm', false);
        this.$set(item, 'certificate', false);
        this.$set(item, 'other', false);
      } else if (type === 2 && item.certificate) {
        this.$set(item, 'reject', false);
        this.$set(item, 'confirm', false);
        this.$set(item, 'other', false);
      } else if (type === 3 && item.other) {
        this.$set(item, 'confirm', false);
        this.$set(item, 'reject', false);
        this.$set(item, 'certificate', false);
      }
    },
    changeAllSelection(type) {
      this.getList.forEach(item => {
        if (type === 0) {
          this.$set(item, 'confirm', this.selectAll.confirm);
          this.$set(item, 'reject', false);
          this.$set(item, 'certificate', false);
          this.$set(item, 'other', false);
        } else if (type === 1) {
          this.$set(item, 'confirm', false);
          this.$set(item, 'reject', this.selectAll.reject);
          this.$set(item, 'certificate', false);
          this.$set(item, 'other', false);
        } else if (type === 2) {
          this.$set(item, 'reject', false);
          this.$set(item, 'confirm', false);
          this.$set(item, 'certificate', this.selectAll.certificate);
          this.$set(item, 'other', false);
        } else if (type === 3) {
          this.$set(item, 'confirm', false);
          this.$set(item, 'reject', false);
          this.$set(item, 'certificate', false);
          this.$set(item, 'other', this.selectAll.other);
        }
      });
    },
    async deleteSelectedItems() {
      try {
        this.deleting = true;
        const type = this.isAbsence ? 'veikindatilkynningum' : 'beiðnum';
        const conf = confirm(`Ert þú viss um að þú viljir eyða ${this.getSelected.length} ${type}? \nEkki verður sendur póstur.`); // eslint-disable-line
        if (conf) {
          for (let i = 0; i < this.getSelected.length; i += 1) {
            /* eslint-disable no-await-in-loop */
            await requests.updateReviewAbsence(this.getSelected[i].nemandi_forfoll_id, {
              deleted: 1,
              nemandi_forfoll_tegund: this.getSelected[i].nemandi_forfoll_tegund,
            });
            /* eslint-enable no-await-in-loop */
          }
          this.selectAll.all = false;
          this.displaySuccess(`${type} eytt`);
          this.loadList();
        }
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.deleting = false;
      }
    },
    openRequestSlidebar(item, defaultEdit) {
      this.selectedItem = item;
      this.showRequestSlidebar = true;
      this.showStudentAttendanceSlidebar = false;
      this.showStudentCommentSlidebar = false;
      this.showStudentRequestsSlidebar = false;
      this.defaultEdit = defaultEdit;
    },
    openStudentAttendanceSlidebar(item) {
      this.selectedItem = item;
      this.showStudentAttendanceSlidebar = true;
      this.showRequestSlidebar = false;
      this.showStudentCommentSlidebar = false;
      this.showStudentRequestsSlidebar = false;
    },
    openStudentCommentSlidebar(item) {
      this.selectedItem = item;
      this.showStudentCommentSlidebar = true;
      this.showStudentAttendanceSlidebar = false;
      this.showStudentRequestsSlidebar = false;
      this.showRequestSlidebar = false;
    },
    openStudentRequestsSlidebar(item) {
      this.selectedItem = item;
      this.showStudentRequestsSlidebar = true;
      this.showStudentCommentSlidebar = false;
      this.showStudentAttendanceSlidebar = false;
      this.showRequestSlidebar = false;
    },
    closeSlidebar() {
      this.showRequestSlidebar = false;
      this.showStudentAttendanceSlidebar = false;
      this.showStudentCommentSlidebar = false;
      this.showStudentRequestsSlidebar = false;
      this.selectedItem = null;
      this.defaultEdit = false;
    },
    closeAndReloadSlidebar() {
      this.closeSlidebar();
      this.loadList();
    },
    async processRequests() {
      try {
        this.saving = true;
        for (let i = 0; i < this.getSelectedItems.length; i += 1) {
          let status = null;
          const item = this.getSelectedItems[i];
          if (item.confirm) status = 1; // Stendur fyrir samþykkt leyfi í grunni
          if (item.reject) status = 3; // Stendur fyrir hafnað leyfi í grunni
          if (item.certificate) status = 2; // Stendur fyrir "vantar vottorð" í grunni
          if (item.other) status = 4; // Stendur fyrir "Annað" í grunni
          if (status !== null) {
            const data = {
              nemandi_forfoll_tegund: item.nemandi_forfoll_tegund,
              nemandi_id: item.nemandi_id,
              dags_fra: moment(item.dags_fra).format('DD.MM.YYYY'),
              dags_til: moment(item.dags_til).format('DD.MM.YYYY'),
              heill_dagur: item.heill_dagur,
              // teg_forfalla_id: item.teg_forfalla_id,
              athugasemd_skraning: item.athugasemd_skraning,
              stada: status,
              athugasemd_afgreidsla: item.athugasemd_afgreidsla,
              athugasemd_forfoll: status === 1 ? item.athugasemd_forfoll : '',
              teg_forfalla_id: item.confirm && item.absenceCode ? item.absenceCode.teg_forfalla : '',
              deleted: 0,
            };
            await requests.updateReviewAbsence(item.nemandi_forfoll_id, data); // eslint-disable-line
          }
        }
        const type = this.isAbsence ? 'Veikindatilkynningar' : 'Beiðnir';
        this.displaySuccess(`${type} afgreiddar, póstur sendur á umsækjanda.`);

        this.selectAll.confirm = false;
        this.selectAll.reject = false;
        this.selectAll.certificate = false;
        this.selectAll.other = false;
        this.loadList();
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.saving = false;
      }
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadData();
    this.loadDivisions();
  },
};
</script>
<style lang="scss">
.dropdownAbsenceList {
  width: 50px;
  font-size: 12px;
  padding: 0px;
  background-color: #fff;
  .vs__selected {
    padding: 0px;
    font-size: 12px;
    color: #000;
  }
  .vs__selected-options,
  .vs__dropdown-toggle {
    padding: 0px;
  }
  .vs__dropdown-menu {
    font-size: 12px;
    padding: 0px;
    min-width: 50px;
  }
  .vs__open-indicator {
    display: none;
  }
}
</style>
