<template>
  <div>
    <Loader v-if="loading"></Loader>
    <table class="table" style="font-size: 14px">
      <thead>
        <tr class="table-search">
          <td colspan="3" style="padding-top: 0; padding-left: 0px">
            <b-form-input v-model="search.name" class="search" placeholder="Tegund sérúrræða" ref="focusInput" />
          </td>
          <td colspan="1" style="max-width: 80px">
            <div class="float-right" style="margin-top: -10px">
              <CustomButton type="create" title="Ný tegund" v-if="canEdit" @click.native="openSlidebar()" />
            </div>
          </td>
        </tr>
        <tr>
          <th>
            <LinkOrder title="Heiti" :filteredList="getList" :list="list" column="heiti" />
          </th>
          <th>
            <LinkOrder title="Tegund" :filteredList="getList" :list="list" column="tegund" />
            <i class="fa fa-info-circle" title="Hvort nemandi velji já eða nei við sérúrræðinu eða þurfi að fylla út texta" v-b-tooltip />
          </th>
          <th>
            <LinkOrder title="Röð" :filteredList="getList" :list="list" column="rod" />
            <i class="fa fa-info-circle" title="Í hvaða röð úrræðin birtast hjá nemendum og í listum" v-b-tooltip />
          </th>
          <th>
            <LinkOrder title="Virk" :filteredList="getList" :list="list" column="virkt" />
          </th>
          <th>
            <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="cursor-pointer float-right">
              <i class="fa fa-file-excel-o excelText"></i>
            </download-csv>
          </th>
        </tr>
      </thead>
      <tbody>
        <TableRowLoader v-if="loading" colspan="4" :center="true" />
        <TableRowEmpty v-if="!loading && this.list.length === 0" colspan="4" :center="true" />
        <tr
          v-for="item in getList"
          :key="item.teg_serurraedi_id"
          :class="{
            'active-table-row': selectedItem && selectedItem.teg_serurraedi_id === item.teg_serurraedi_id,
            'cursor-pointer': showSlidebar,
          }"
          @click="checkSlidebar(item)"
        >
          <td>{{ item.heiti }}</td>
          <td>
            <span v-if="item.tegund === 0">Já/Nei</span>
            <span v-if="item.tegund === 1">Texti</span>
          </td>
          <td>{{ item.rod }}</td>
          <td><yes-no :item="item.virkt"></yes-no></td>
          <td>
            <div class="float-right" v-if="canEdit">
              <b-btn variant="primary" class="mr-2 btn-xs" v-b-tooltip title="Breyta" @click.prevent="openSlidebar(item)">
                <i class="fa fa-pencil" />
              </b-btn>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <special-resource-type-slidebar
      v-if="showSlidebar"
      :item="selectedItem"
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
    >
    </special-resource-type-slidebar>
  </div>
</template>

<script>
import types from '@/api/types';

import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

import CustomButton from '@/components/common/CustomButton.vue';
import YesNo from '@/components/common/YesNo.vue';

import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';
import Loader from '@/components/common/Loader.vue';

import SpecialResourceTypeSlidebar from '@/components/school/school/types/SpecialResourceTypeSlidebar.vue';

export default {
  name: 'specialResource',
  components: {
    TableRowLoader,
    TableRowEmpty,
    LinkOrder,
    SpecialResourceTypeSlidebar,
    CustomButton,
    YesNo,
    Loader,
  },
  computed: {
    getList() {
      return this.list.filter(
        x => this.search.name.length === 0 || (x.heiti && x.heiti.toUpperCase().startsWith(this.search.name.toUpperCase())),
      );
    },
    getExcelData() {
      const newList = [
        [],
        ['Tegundir sérúrræða'],
        [],
        ['Heiti', 'Tegund', 'Virk'],
        ...this.getList.map(x => [x.heiti, x.tegund === 0 ? 'Já/Nei' : 'Texti', x.virkt === 1 ? 'Já' : 'Nei']),
      ];
      return newList;
    },
    getExcelFileName() {
      return `tegund_serurraeda_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {
      loading: false,
      showSlidebar: false,
      selectedItem: {},
      search: {
        name: '',
      },
      list: [],
    };
  },
  methods: {
    async loadList() {
      try {
        this.loading = true;
        const response = await types.getSpecialResourceList({ showAll: 1 });
        this.list = response.data.items;
        this.$nextTick(() => {
          if (this.$refs.focusInput) {
            this.$refs.focusInput.focus();
          }
        });
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    openSlidebar(item) {
      this.selectedItem = item;
      this.showSlidebar = true;
    },
    closeSlidebar() {
      this.showSlidebar = false;
      this.selectedItem = {};
    },
    closeAndReloadSlidebar() {
      this.closeSlidebar();
      this.loadList();
    },
    checkSlidebar(item) {
      if (this.showSlidebar) {
        this.openSlidebar(item);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadList();
  },
};
</script>
