<template>
  <Slidebar @closeMe="closeSlidebar" :large="large" :extraLarge="extraLarge">
    <h4 v-if="id">{{ item.name }}</h4>
    <h4 v-else>Stofna deild</h4>
    <br />
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <table class="table less-padding">
        <tr>
          <td>Heiti*</td>
          <td>
            <b-form-group
              label-for="heiti"
              :state="submitted && errors.has('heiti') ? false : ''"
              :invalid-feedback="errors.first('heiti')"
            >
              <b-form-input
                id="heiti"
                name="heiti"
                type="text"
                v-model="form.name"
                :state="submitted && errors.has('heiti') ? false : ''"
                maxLength="60"
                v-validate="'required'"
                ref="focusInput"
                data-vv-as="heiti"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Heiti (ens)</td>
          <td>
            <b-form-group
              label-for="heitiEn"
              :state="submitted && errors.has('heitiEn') ? false : ''"
              :invalid-feedback="errors.first('heitiEn')"
            >
              <b-form-input
                id="heitiEn"
                name="heitiEn"
                type="text"
                v-model="form.nameEn"
                :state="submitted && errors.has('heitiEn') ? false : ''"
                maxLength="60"
                data-vv-as="heiti (ens)"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Lýsing</td>
          <td>
            <b-form-group
              label-for="lysing"
              :state="submitted && errors.has('lysing') ? false : ''"
              :invalid-feedback="errors.first('lysing')"
            >
              <b-form-textarea
                id="lysing"
                name="lysing"
                type="text"
                v-model="form.description"
                :state="submitted && errors.has('lysing') ? false : ''"
                maxLength="700"
                data-vv-as="lýsing"
              ></b-form-textarea>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Deildarstjóri</td>
          <td>
            <b-form-group label-for="manager">
              <v-select id="manager" name="manager" v-model="form.manager" :options="staff" :clearable="true"> </v-select>
            </b-form-group>
          </td>
        </tr>
      </table>
      <b-btn variant="primary" class="mr-2" v-if="canEdit" @click="validateBeforeSubmit">
        <span v-if="id">Breyta</span>
        <span v-else>Stofna</span>
      </b-btn>
      <b-btn variant="dark" @click="closeSlidebar">Hætta við</b-btn>
      <b-btn variant="danger" class="float-right" v-if="canEdit && id" @click="deleteDepartment"> Eyða </b-btn>
    </b-form>
  </Slidebar>
</template>

<script>
import structure from '@/api/structure';
import staff from '@/api/staff';
import { mapActions, mapGetters } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';

export default {
  name: 'department-slidebar',
  props: ['item', 'large', 'extraLarge'],
  components: {
    Slidebar,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      loading: false,
      submitted: false,
      id: null,
      staff: [],
      form: {
        name: '',
        nameEn: '',
        description: '',
        manager: '',
      },
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async loadDepartmentDetail() {
      try {
        this.loading = true;
        const response = await structure.getDepartmentDetail(this.id);
        if (response.data.items && response.data.items.length > 0) {
          const item = response.data.items[0];
          this.form.name = item.name;
          this.form.nameEn = item.name_en;
          this.form.description = item.description;
          this.loadStaff(item.manager_id);
        }
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadStaff(id) {
      try {
        this.loading = true;
        const response = await staff.getStaffList();
        this.staff = response.data.items.map(x => ({
          id: x.starfsm_id,
          label: x.nafn,
        }));
        if (id) {
          this.form.manager = this.staff.find(x => x.id === id);
        }
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.saving = true;
          const data = {
            name: this.form.name,
            nameEn: this.form.nameEn,
            description: this.form.description,
            manager_id: this.form.manager ? this.form.manager.id : '',
          };
          if (this.id) {
            await structure.updateDepartment(this.id, data);
            this.displaySuccess(`${this.form.name} hefur verið breytt.`);
          } else {
            await structure.createDepartment(data);
            this.displaySuccess(`${this.form.name} hefur verið stofnuð.`);
          }
          this.closeSlidebar();
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.data && e.response.data.error) {
            this.displayError(e.response.data.error);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.saving = false;
      }
    },
    async deleteDepartment() {
      try {
        const conf = confirm(`Ert þú viss um að eyða ${this.item.name}?`); // eslint-disable-line
        if (conf) {
          await structure.updateDepartment(this.id, { delete: 1 });
          this.displaySuccess(`${this.item.name} hefur verið eytt.`);
          this.closeSlidebar();
        }
      } catch (e) {
        // Aðgerð mistókst.
        this.$log.debug(e);
        if (e.response && e.response.data && e.response.data.error) {
          this.displayError(e.response.data.error);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    item() {
      this.form = {
        name: '',
        nameEn: '',
        description: '',
        manager: '',
      };
      this.id = null;
      if (this.item && this.item.department_id) {
        this.id = this.item.department_id;
        this.loadDepartmentDetail();
      }
      this.$nextTick(() => {
        if (this.$refs.focusInput) {
          this.$refs.focusInput.focus();
        }
      });
    },
  },
  created() {
    this.form = {
      name: '',
      nameEn: '',
      description: '',
      manager: '',
    };
    this.id = null;
    if (this.item && this.item.department_id) {
      this.id = this.item.department_id;
      this.loadDepartmentDetail();
    } else {
      this.loadStaff();
    }
    this.$nextTick(() => {
      if (this.$refs.focusInput) {
        this.$refs.focusInput.focus();
      }
    });
  },
};
</script>

<style lang="scss"></style>
