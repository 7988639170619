<template>
  <Slidebar @closeMe="closeSlidebar" :large="true">
    <h4>Útskriftartengt</h4>
    <div class="alert alert-info" v-if="!gratuationMarked">
      <i class="fa fa-info-circle"></i>
      Fyrst þarf að vista nemanda til útskriftar, nemandi er vistaður til útskriftar á því kennslutímabili sem hann útskrifast á. Þegar búið
      er að staðfesta þá er hægt að merkja við að skírteini hafi verið yfirfarið og svo útskrifa.
    </div>
    <div class="alert alert-info" v-if="gratuationMarked && !gratuationDate && gratuationInfo && gratuationInfo.onn_eink">
      <i class="fa fa-info-circle"></i>
      Nemandi hefur verið vistaður til útskriftar kennslutímabilið <strong>{{ gratuationInfo.onn_eink }}</strong> smelltu á „Útskrifa“ til
      að útskrifa nemandann af brautinni. Fylla þarf út hvaða dagssetningu nemandinn útskrifast af brautinni.
    </div>
    <div class="alert alert-danger" v-if="gratuationMarked && gratuationDate">
      <i class="fa fa-exclamation-triangle"></i>
      Búið er að útskrifa nemandann. Ekki er hægt að útskrifa hann aftur.
    </div>
    <b-form-radio-group id="type" v-model="type" name="type" stacked>
      <b-form-radio value="0" :disabled="!gratuationMarked || gratuationDate">
        Útskrifa
        <div v-if="!gratuationMarked || gratuationDate">
          <i>Ekki er hægt að útskrifa nemanda nema búið sé að vista hann til útskriftar</i>
        </div>
      </b-form-radio>
      <b-form-radio value="1" :disabled="!!gratuationMarked">
        Vista nemanda til útskriftar
        <div v-if="gratuationMarked">
          <i>Það er nú þegar búið að merkja nemanda til útskriftar</i>
        </div>
      </b-form-radio>
      <b-form-radio value="2" :disabled="studentStatus !== 6 && studentStatus !== 24">
        Merkja skírteini yfirfarið
        <div v-if="reviewed"><i>Ath. skírteinið er nú þegar merkt yfirfarið.</i></div>
        <div v-if="studentStatus !== 6 && studentStatus !== 24">
          <i>Ekki er hægt að merkja skírteini nema nemandi sé í stöðunni "Útskriftarefni" eða "Útsk.efni - verður á næstu önn". </i>
        </div>
      </b-form-radio>
    </b-form-radio-group>
    <div v-if="type === '0'" style="border: 2px solid #ddd" class="p-3 mt-3">
      <strong>Útskrifa nemanda</strong>
      <b-form-group label="Dagsetning útskriftar" label-for="date">
        <datepicker
          id="date"
          name="date"
          v-model="selected.date"
          :language="lang"
          :monday-first="true"
          format="dd.MM.yyyy"
          :typeable="false"
          ref="dateOpenPickr"
        ></datepicker>
      </b-form-group>

      <div v-if="getUnfinishedCourses.length === 1">
        <b-form-checkbox v-model="selected.disableStudent" class="d-inline-block"></b-form-checkbox>
        <div class="d-inline-block">Óvirkja nemanda</div>
      </div>
    </div>
    <div v-if="type === '1'" style="border: 2px solid #ddd" class="p-3 mt-3">
      <strong>Merkja nemanda til útskriftar</strong>
      <b-form-group label="Kennslutímabil til útskriftar " label-for="term">
        <v-select id="term" name="term" placeholder="Veldu önn" v-model="selected.term" :options="terms" :clearable="false"> </v-select>
      </b-form-group>
    </div>
    <!-- (type === '1' && !selected.term) || (type === '2' && !selected.date) || -->
    <b-btn variant="primary" class="mt-3" @click="saveRequirements()" v-if="type" :disabled="saving">
      <i class="fa fa-spin fa-spinner" v-if="saving"></i> Staðfesta
    </b-btn>
  </Slidebar>
</template>

<script>
import moment from 'moment';
import terms from '@/api/terms';
import students from '@/api/students';
import { mapActions, mapGetters } from 'vuex';
import Slidebar from '@/components/common/Slidebar.vue';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';

export default {
  name: 'study-record-course-gratuation-slidebar',
  props: [
    'studentId',
    'courseId',
    'studentCourseId',
    'divisionId',
    'studentStatus',
    'gratuationMarked',
    'gratuationDate',
    'gratuationInfo',
    'reviewed',
  ],
  components: {
    Slidebar,
    Datepicker,
  },
  computed: {
    getUnfinishedCourses() {
      return this.courses.filter(x => !x.dags_endar);
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      lang: is,
      type: '',
      saving: false,
      terms: [],
      loading: {
        terms: false,
        courses: false,
      },
      selected: {
        term: '',
        date: '',
        disableStudent: false,
      },
      courses: [],
    };
  },
  methods: {
    async loadTerms() {
      try {
        this.loading.terms = true;
        const params = {
          eval_terms: 0,
        };
        if (this.divisionId) {
          params.division_id = this.divisionId;
        }
        const response = await terms.getTerms(params);
        this.terms = response.data.items.map(x => ({
          ...x,
          label: `${x.undirsk_audk} - ${x.onn_eink} - ${x.onn_heiti}`,
        }));

        this.selected.term = this.terms.find(x => x.is_default === 1);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.terms = false;
      }
    },
    async loadCourses() {
      try {
        this.loading.courses = true;
        const response = await students.getStudentAllCourses(this.studentId);
        this.courses = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.courses = false;
      }
    },
    async saveRequirements() {
      try {
        let conf = '';
        const item = {};
        switch (this.type) {
          case '0':
            conf = confirm('Ertu viss um að þú viljir útskrifa nemanda?'); // eslint-disable-line
            item.gratuate = 1;
            item.disableStudent = this.selected.disableStudent ? 1 : 0;
            item.gratuationDate = moment(this.selected.date).format('DD.MM.YYYY');
            break;
          case '1':
            conf = confirm('Ertu viss um að þú viljir merkja nemanda til útskriftar?'); // eslint-disable-line
            item.mark = 1;
            item.termId = this.selected.term.onn_id;
            break;
          case '2':
            conf = confirm('Ertu viss um að þú viljir merkja skírteinið yfirfarið?'); // eslint-disable-line
            item.confirm = 1;
            break;
          default:
            conf = confirm('Ertu viss um að þú viljir merkja skírteinið yfirfarið?'); // eslint-disable-line
            item.confirm = 1;
        }
        if (conf) {
          this.saving = true;
          await this.$studentsApi.changeCourseRequirements({
            changeCourseRequirementsInputItem: {
              studentId: this.studentId,
              courseId: this.courseId,
              studentCourseId: this.studentCourseId,
              ...item,
            },
          });

          this.$emit('closeAndReloadSlidebar', { disableStudent: this.selected.disableStudent });
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.saving = false;
      }
    },
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadTerms();
    this.loadCourses();
  },
};
</script>

<style lang="scss"></style>
