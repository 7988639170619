import Vue from 'vue';

const API_URL = '/api/reports';

export default {
  /*
    Aðalskóli þjónustur
  */
  getHomeschoolSummary(date) {
    return Vue.http
      .get(`${API_URL}/homeschool-summary/${date}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getNotHomeschool() {
    return Vue.http
      .get(`${API_URL}/homeschool-not`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getHomeschool(params) {
    return Vue.http
      .get(`${API_URL}/homeschool`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getHomeschoolStudentCourses(ssn, school) {
    return Vue.http
      .get(`${API_URL}/homeschool-student-courses/${ssn}/${school}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getHomeschoolCourselist(params) {
    return Vue.http
      .get(`${API_URL}/homeschool-courses`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getMinistryConfirmedCourses() {
    return Vue.http
      .get(`${API_URL}/ministry-confirmed-courses`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  connectToMinistryConfirmedCourses(data) {
    return Vue.http
      .post(`${API_URL}/ministry-confirmed-courses`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getCourseAnalysis(id, params) {
    return Vue.http
      .get(`${API_URL}/course-analysis/${id}`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
};
