<template>
  <div>
    <div class="d-inline-block">
      <h5>{{ item.namsgrein_afanganr }}</h5>
    </div>
    <div class="d-inline-block ml-2">
      <b-btn @click="changeModule()" variant="primary" class="btn-xs" title="Breyta um áfanga" style="margin-top: -10px" v-b-tooltip
        ><i class="fa fa-exchange"></i
      ></b-btn>
    </div>
    <div class="d-inline-block float-right">
      <b-btn
        variant="primary"
        title="Afrita einkunnareglu frá öðrum áfanga"
        v-b-tooltip
        v-if="canEdit"
        class="float-right"
        @click="openCopyRuleSlidebar()"
        style="margin-top: -10px; margin-bottom: -20px"
        ><i class="fa fa-sitemap"></i
      ></b-btn>
    </div>
    <GradeRuleCreateFinal
      v-if="createFinal"
      :termModuleId="item.annir_afangar_id"
      @cancel="createFinal = false"
      @done="creating()"
    ></GradeRuleCreateFinal>

    <div v-else>
      <div class="alert alert-danger p-2" v-if="item.has_graderule_final === 0 && (item.has_graderule === 1 || hasGroupRules)">
        <i class="fa fa-exclamation-triangle mr-1"></i>
        <span v-if="item.has_graderule === 1">
          Ath. Engin einkunnaregla er merkt sem lokaeinkunn. Einkunnir munu því ekki flytjast í feril.</span
        >
        <span v-if="hasGroupRules && item.has_graderule === 0">
          Ath. Til þess að einkunn sé lesin yfir í feril þá þarf að vera skilgreind lokaeinkunn í einkunnareglu áfanga.
        </span>
      </div>
      <GradeRuleList
        v-if="!createFinal"
        :edit="true && !termPassed"
        :termModuleId="item.annir_afangar_id"
        :id="`GRList${item.annir_afangar_id}`"
        :reload="reload.list"
        @reloadOthers="reloadGraph"
      ></GradeRuleList>
      <!--b-btn
        variant="primary"
        class="btn-xs"
        @click="openSlidebar()"
        v-if="item.has_graderule !== 0 && canEdit"
        style="margin-top: -10px;"
        title="Bæta við einkunnareglu sem vegur ekki uppí aðra"
        v-b-tooltip
      >
        <i class="fa fa-plus-circle"></i> Bæta við einkunnareglu
      </b-btn-->
      <GradeRuleGraph
        v-if="!createFinal"
        :edit="true && !termPassed"
        :termModuleId="item.annir_afangar_id"
        :id="`GRGraph${item.annir_afangar_id}`"
        :reload="reload.graph"
        @reloadOthers="reloadList"
      ></GradeRuleGraph>

      <b-btn variant="primary" size="sm" v-if="item.has_graderule === 0 && canEdit" @click="createFinal = true" class="mb-3"
        ><i class="fa fa-plus-circle"></i> Stofna lokaeinkunn</b-btn
      >
      <h5 v-if="groups.length > 0">Einkunnaregla hóps</h5>
      <div v-for="hopur in groups" :key="hopur.hopur_id" class="mb-2">
        <div style="font-size: 17px">
          Hópur {{ hopur.hopur }}
          <span v-if="hopur.kennari" :title="`Kennari: ${hopur.kennari}`" v-b-tooltip> - {{ hopur.kennari }} </span>
          <i
            class="fa fa-search cursor-pointer"
            title="Skoða einkunnayfirlit"
            v-b-tooltip
            @click="openAssessmentSlidebar(hopur.hopur_id)"
          ></i>
          <!--b-btn
            variant="primary"
            @click="openSlidebar(hopur)"
            v-if="hopur.has_graderule !== 0"
            class="btn-xs ml-2"
            style="margin-top: -10px;"
            ><i class="fa fa-plus-circle"></i
          ></b-btn-->
        </div>
        <b-btn variant="primary" size="sm" @click="createGroupFinal(hopur)" v-if="hopur.has_graderule === 0 && canEdit" class="mb-2 mt-2"
          ><i class="fa fa-plus-circle"></i> Stofna lokaeinkunn</b-btn
        >
        <b-btn variant="primary" size="sm" class="ml-3 mb-2 mt-2" v-if="hopur.has_graderule === 0 && canEdit" @click="copyGraderule(hopur)"
          ><i class="fa fa-copy"></i> Afrita einkunnareglu frá áfanganum</b-btn
        >
        <b-btn
          variant="primary"
          size="sm"
          class="ml-3 mb-2 mt-2"
          v-if="hopur.has_graderule === 0 && canEdit"
          @click="openCopyRuleGroupSlidebar(hopur)"
          ><i class="fa fa-copy"></i> Afrita einkunnareglu frá öðrum hóp</b-btn
        >
        <div v-if="hopur.has_graderule === 1 && !hopur.show"><b-link @click="hopur.show = true">Skoða einkunnareglu</b-link></div>
        <GradeRuleList
          v-if="hopur.show"
          :groupId="hopur.hopur_id"
          :edit="true && !termPassed"
          :id="`GRGroupList${hopur.hopur_id}`"
          :reload="reload.groupList"
          @reloadOthers="reloadGraph(true)"
        ></GradeRuleList>
        <GradeRuleGraph
          v-if="hopur.show"
          :groupId="hopur.hopur_id"
          :edit="true && !termPassed"
          :id="`GRGroupGraph${hopur.hopur_id}`"
          :reload="reload.groupGraph"
          @reloadOthers="reloadList(true)"
        ></GradeRuleGraph>
      </div>
    </div>

    <grade-rule-edit-slidebar
      :termModuleId="selectedTermModuleId"
      :groupId="selectedGroupId"
      v-if="showCreateSlidebar"
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
    ></grade-rule-edit-slidebar>
    <assessment-overview-slidebar
      :groupId="selectedGroupId"
      v-if="showAssessmentSlidebar"
      :extraLarge="true"
      @closeSlidebar="closeSlidebar()"
    ></assessment-overview-slidebar>
    <CopyGraderuleSlidebar
      :id="termId"
      :divisionId="divisionId"
      :searchModule="item ? item.namsgrein_afanganr : ''"
      v-if="showCopyRuleSlidebar"
      @closeSlidebar="closeAndReloadSlidebar()"
    ></CopyGraderuleSlidebar>
    <CopyGraderuleGroupSlidebar
      :id="termId"
      :divisionId="divisionId"
      :searchModule="item ? item.namsgrein_afanganr : ''"
      :groupId="selectedItem ? selectedItem.hopur_id : ''"
      v-if="showCopyGroupRuleSlidebar"
      @closeSlidebar="closeAndReloadSlidebar()"
    ></CopyGraderuleGroupSlidebar>
  </div>
</template>

<script>
import groups from '@/api/groups';
import terms from '@/api/terms';
import { mapActions, mapGetters } from 'vuex';
import GradeRuleGraph from '@/components/school/terms/graderules/GradeRuleGraph.vue';
import GradeRuleList from '@/components/school/terms/graderules/GradeRuleList.vue';
import GradeRuleCreateFinal from '@/components/school/terms/graderules/GradeRuleCreateFinal.vue';
import GradeRuleEditSlidebar from '@/components/school/terms/graderules/GradeRuleEditSlidebar.vue';
import AssessmentOverviewSlidebar from '@/components/students/groups/grades/AssessmentOverviewSlidebar.vue';
import CopyGraderuleSlidebar from '@/components/school/terms/graderules/CopyGraderuleSlidebar.vue';
import CopyGraderuleGroupSlidebar from '@/components/school/terms/graderules/CopyGraderuleGroupSlidebar.vue';

export default {
  name: 'graderule',
  props: ['id', 'termPassed'],
  components: {
    GradeRuleGraph,
    GradeRuleList,
    GradeRuleCreateFinal,
    GradeRuleEditSlidebar,
    AssessmentOverviewSlidebar,
    CopyGraderuleSlidebar,
    CopyGraderuleGroupSlidebar,
  },
  computed: {
    hasGroupRules() {
      return this.groups.filter(x => x.has_graderule === 1).length > 0;
    },
    canRead() {
      return this.loggedInUserHasReadPermission('skolahald_annir');
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_annir') && !this.termPassed;
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolHasSettings']),
  },
  data() {
    return {
      createFinal: false,
      selectedTermModuleId: null,
      selectedGroupId: null,
      reload: {
        list: false,
        graph: false,
        groupList: false,
        groupGraph: false,
      },
      termId: '',
      divisionId: '',
      item: '',
      selectedItem: '',
      rule: [],
      groups: [],
      loading: {
        rule: false,
        module: false,
      },
      showCreateSlidebar: false,
      showAssessmentSlidebar: false,
      showCopyRuleSlidebar: false,
      showCopyGroupRuleSlidebar: false,
    };
  },
  methods: {
    changeModule() {
      this.$emit('changeModule');
    },
    openSlidebar(group) {
      this.selectedTermModuleId = !group ? this.id : null;
      this.selectedGroupId = null;
      this.selectedId = group ? group.hopur_id : null;
      this.showCreateSlidebar = true;
      this.showCopyGroupRuleSlidebar = false;
    },
    openCopyRuleSlidebar() {
      this.showCopyRuleSlidebar = true;
      this.showCreateSlidebar = false;
      this.showAssessmentSlidebar = false;
      this.showCopyGroupRuleSlidebar = false;
    },
    openCopyRuleGroupSlidebar(item) {
      this.selectedItem = item;
      this.showCopyGroupRuleSlidebar = true;
      this.showCopyRuleSlidebar = false;
      this.showCreateSlidebar = false;
      this.showAssessmentSlidebar = false;
    },
    closeSlidebar() {
      this.selectedId = null;
      this.selectedGroupId = null;
      this.showCreateSlidebar = false;
      this.showAssessmentSlidebar = false;
      this.showCopyRuleSlidebar = false;
      this.showCopyGroupRuleSlidebar = false;
    },
    closeAndReloadSlidebar() {
      this.closeSlidebar();
      this.reloadGraph(false);
      this.reloadList(false);
    },
    openAssessmentSlidebar(id) {
      this.selectedGroupId = id;
      this.showAssessmentSlidebar = true;
    },
    async copyGraderule(item) {
      try {
        await groups.copyGradeRuleFromModule(item.hopur_id, this.id);
        this.$set(item, 'show', true);
        this.displaySuccess('Einkunnaregla hefur verið afrituð.');
        this.reloadGraph(true);
        this.reloadList(true);
        this.loadModules();
      } catch (e) {
        // Aðgerð mistókst.
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async createGroupFinal(item) {
      try {
        await groups.createGroupGradeRule(item.hopur_id, {});
        this.$set(item, 'show', true);
        this.displaySuccess('Einkunnaregla hefur verið stofnuð.');
        this.loadModules();
        this.reloadGraph(true);
        this.reloadList(true);
      } catch (e) {
        // Aðgerð mistókst.
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadModules() {
      if (this.canRead) {
        try {
          this.loading.module = true;
          const response = await terms.getTermModule(this.id);
          this.item = response.data;
          this.termId = response.data.onn_id;
          this.divisionId = response.data.undirskoli_id;
          if (this.schoolHasSettings('einkunnaregla_hops')) {
            this.groups = this.item.hopar.map(x => ({
              ...x,
              show: x.has_graderule === 1,
            }));
          }
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading.module = false;
        }
      }
    },
    reloadGraph(isGroup) {
      if (isGroup) {
        this.reload.groupGraph = true;
      } else {
        this.reload.graph = true;
      }
      this.loadModules();

      setTimeout(() => {
        this.reload.graph = false;
        this.reload.groupGraph = false;
      }, 1000);
    },
    reloadList(isGroup) {
      if (isGroup) {
        this.reload.groupList = true;
      } else {
        this.reload.list = true;
      }
      this.loadModules();

      setTimeout(() => {
        this.reload.list = false;
        this.reload.groupList = false;
      }, 1000);
    },
    creating() {
      this.createFinal = false;
      this.initialize();
      this.reloadGraph(false);
      this.reloadList(false);
    },
    initialize() {
      this.loadModules();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    id() {
      this.initialize();
    },
  },
  created() {
    this.initialize();
  },
};
</script>
<style lang="scss"></style>
