<template>
  <Slidebar @closeMe="closeSlidebar" :large="large" :extraLarge="extraLarge">
    <h4 v-if="!defaultEdit">Skoða námsgagn</h4>
    <h4 v-if="id && defaultEdit">Breyta námsgagni</h4>
    <h4 v-if="!id && defaultEdit">Stofna námsgagn</h4>
    <br />
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <table class="table less-padding">
        <tr>
          <td>ISBN-númer</td>
          <td>
            <b-form-group label-for="isbn" :state="submitted && errors.has('isbn') ? false : ''" :invalid-feedback="errors.first('isbn')">
              <div v-if="!defaultEdit">{{ form.isbn }}</div>
              <b-form-input
                id="isbn"
                name="isbn"
                type="text"
                v-model="form.isbn"
                :state="submitted && errors.has('isbn') ? false : ''"
                maxLength="20"
                data-vv-as="isbn"
                v-else
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Heiti*</td>
          <td>
            <b-form-group
              label-for="heiti"
              :state="submitted && errors.has('heiti') ? false : ''"
              :invalid-feedback="errors.first('heiti')"
            >
              <div v-if="!defaultEdit">{{ form.heiti }}</div>
              <b-form-input
                id="heiti"
                name="heiti"
                type="text"
                v-model="form.heiti"
                :state="submitted && errors.has('heiti') ? false : ''"
                maxLength="200"
                v-validate="'required'"
                ref="focusInput"
                data-vv-as="heiti"
                v-else
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Höfundur</td>
          <td>
            <b-form-group
              label-for="hofundur"
              :state="submitted && errors.has('hofundur') ? false : ''"
              :invalid-feedback="errors.first('hofundur')"
            >
              <div v-if="!defaultEdit">{{ form.hofundur }}</div>
              <b-form-input
                id="hofundur"
                name="hofundur"
                type="text"
                v-model="form.hofundur"
                :state="submitted && errors.has('hofundur') ? false : ''"
                maxLength="300"
                data-vv-as="höfundur"
                v-else
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Útgáfuár</td>
          <td>
            <b-form-group
              label-for="utgafa"
              :state="submitted && errors.has('utgafa') ? false : ''"
              :invalid-feedback="errors.first('utgafa')"
            >
              <div v-if="!defaultEdit">{{ form.utgafa }}</div>
              <b-form-input
                id="utgafa"
                name="utgafa"
                type="text"
                v-model="form.utgafa"
                :state="submitted && errors.has('utgafa') ? false : ''"
                maxLength="20"
                data-vv-as="útgáfa"
                v-else
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Útgefandi</td>
          <td>
            <b-form-group
              label-for="utgefandi"
              :state="submitted && errors.has('utgefandi') ? false : ''"
              :invalid-feedback="errors.first('utgefandi')"
            >
              <div v-if="!defaultEdit">{{ form.utgefandi }}</div>
              <b-form-input
                id="utgefandi"
                name="utgefandi"
                type="text"
                v-model="form.utgefandi"
                :state="submitted && errors.has('utgefandi') ? false : ''"
                maxLength="300"
                data-vv-as="útgefandi"
                v-else
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Lýsing</td>
          <td>
            <b-form-group
              label-for="lysing"
              :state="submitted && errors.has('lysing') ? false : ''"
              :invalid-feedback="errors.first('lysing')"
            >
              <div v-if="!defaultEdit">{{ form.lysing }}</div>
              <b-form-input
                id="lysing"
                name="lysing"
                type="text"
                v-model="form.lysing"
                :state="submitted && errors.has('lysing') ? false : ''"
                maxLength="3000"
                data-vv-as="lýsing"
                v-else
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>
            Flokkur
            <i
              @click="openTypeSlidebar()"
              class="cursor-pointer fa fa-plus-circle text-primary"
              title="Bæta við flokk"
              v-if="canEdit && defaultEdit"
            ></i>
          </td>
          <td>
            <b-form-group
              label-for="flokkur"
              :state="submitted && errors.has('flokkur') ? false : ''"
              :invalid-feedback="errors.first('flokkur')"
            >
              <div v-if="!defaultEdit">{{ form.flokkur ? form.flokkur.heiti : '' }}</div>
              <v-select
                id="flokkur"
                name="flokkur"
                v-model="form.flokkur"
                :state="submitted && errors.has('flokkur') ? false : ''"
                :options="categories"
                label="heiti"
                v-else
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Virkur</td>
          <td>
            <b-form-group
              label-for="virkur"
              :state="submitted && errors.has('virkur') ? false : ''"
              :invalid-feedback="errors.first('virkur')"
            >
              <div v-if="!defaultEdit">{{ form.virkur ? 'Já' : 'Nei' }}</div>
              <b-form-checkbox id="virkur" name="virkur" v-model="form.virkur" v-else> </b-form-checkbox>
            </b-form-group>
          </td>
        </tr>
      </table>
      <b-btn variant="primary" class="mr-2" v-if="canEdit && defaultEdit" @click="validateBeforeSubmit">
        <span v-if="id">Breyta</span>
        <span v-else>Stofna</span>
      </b-btn>
      <b-btn variant="primary" class="mr-2" v-if="canEdit && !defaultEdit" @click="defaultEdit = !defaultEdit"> Breyta </b-btn>
      <b-btn variant="dark" @click="closeSlidebar">Hætta við</b-btn>
      <b-btn variant="danger" class="float-right" v-if="canEdit && id && defaultEdit" @click="deleteBook"> Eyða </b-btn>
    </b-form>

    <book-category-type-slidebar v-if="showTypeSlidebar" @closeSlidebar="closeTypeSlidebar"> </book-category-type-slidebar>
  </Slidebar>
</template>

<script>
import types from '@/api/types';
import { mapActions, mapGetters } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';
import BookCategoryTypeSlidebar from '@/components/school/school/types/BookCategoryTypeSlidebar.vue';

export default {
  name: 'book-type-slidebar',
  props: ['item', 'defaultNoEdit', 'large', 'extraLarge'],
  components: {
    Slidebar,
    BookCategoryTypeSlidebar,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      loading: {
        detail: false,
        categories: false,
      },
      showTypeSlidebar: false,
      submitted: false,
      id: null,
      attendances: [],
      categories: [],
      defaultEdit: true,
      form: {
        isbn: '',
        heiti: '',
        hofundur: '',
        utgafa: '',
        utgefandi: '',
        lysing: '',
        flokkur: '',
        flokkur_id: '',
        virkur: true,
      },
    };
  },
  methods: {
    openTypeSlidebar() {
      this.showTypeSlidebar = true;
    },
    closeTypeSlidebar() {
      this.showTypeSlidebar = false;
      this.loadCategories();
    },
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async initialize() {
      this.form = {
        isbn: '',
        heiti: '',
        hofundur: '',
        utgafa: '',
        utgefandi: '',
        lysing: '',
        flokkur: '',
        flokkur_id: '',
        virkur: true,
      };
      this.id = null;
      await this.loadCategories();
      if (this.item && this.item.bok_id) {
        this.id = this.item.bok_id;
        this.loadBookDetail();
      }
      this.$nextTick(() => {
        if (this.$refs.focusInput) {
          this.$refs.focusInput.focus();
        }
      });
    },
    async loadCategories() {
      try {
        this.loading.categories = true;
        const response = await types.getBookCategoryList();
        this.categories = response.data.items;
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.categories = false;
      }
    },
    async loadBookDetail() {
      try {
        this.loading.detail = true;
        const response = await types.getBookDetail(this.id);
        if (response.data.items && response.data.items.length > 0) {
          const item = response.data.items[0];
          this.form.isbn = item.isbn;
          this.form.heiti = item.heiti;
          this.form.hofundur = item.hofundur;
          this.form.utgafa = item.utgafa;
          this.form.utgefandi = item.utgefandi;
          this.form.lysing = item.lysing;
          this.form.flokkur_id = item.flokkur_id;
          this.form.flokkur = this.categories.find(x => x.flokkur_id === this.form.flokkur_id);
          this.form.virkur = item.virkur === 1;
        }
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.detail = false;
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          const data = {
            ...this.form,
            flokkur_id: this.form.flokkur ? this.form.flokkur.flokkur_id : '',
            virkur: this.form.virkur ? 1 : 0,
          };
          this.saving = true;
          if (this.id) {
            await types.updateBook(this.id, data);
            this.displaySuccess(`${this.form.heiti} hefur verið breytt.`);
          } else {
            await types.createBook(data);
            this.displaySuccess(`${this.form.heiti} hefur verið stofnuð.`);
          }
          if (this.defaultNoEdit && this.id) {
            this.defaultEdit = false;
            this.initialize();
          } else {
            this.$emit('closeAndReloadSlidebar');
          }
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.data && e.response.data.error) {
            this.displayError(e.response.data.error);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.saving = false;
      }
    },
    async deleteBook() {
      try {
        const conf = confirm(`Ert þú viss um að eyða ${this.item.heiti}?`); // eslint-disable-line
        if (conf) {
          await types.updateBook(this.id, { delete: 1 });
          this.displaySuccess(`${this.item.heiti} hefur verið eytt.`);
          this.$emit('closeAndReloadSlidebar');
        }
      } catch (e) {
        // Aðgerð mistókst.
        this.$log.debug(e);
        if (e.response && e.response.data && e.response.data.error) {
          this.displayError(e.response.data.error);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    item() {
      this.initialize();
    },
    defaultNoEdit() {
      if (this.defaultNoEdit) {
        this.defaultEdit = false;
      } else {
        this.defaultEdit = true;
      }
    },
  },
  created() {
    if (this.defaultNoEdit) {
      this.defaultEdit = false;
    } else {
      this.defaultEdit = true;
    }
    this.initialize();
  },
};
</script>

<style lang="scss"></style>
