<template>
  <div>
    <b-btn v-if="!attendanceColorCode" variant="dark" class="float-right timetablePrintButton noprint" @click="print()">
      <i class="fa fa-print"></i></b-btn>
    <full-calendar :event-sources="eventSources" :config="config" ref="calendar" class="printCalendar"> </full-calendar>
    <div v-if="type !== 'school-events' && showColorInfo">
      <div class="d-inline-block mr-2 mt-2" style="background-color: rgb(152, 19, 156); width: 100px; color: #fff; font-size: 13px">
        Skv. stundatöflu
      </div>
      <div class="d-inline-block" style="background-color: rgb(152, 19, 156, 0.2); width: 60px; font-size: 13px">
        Annað
        <i class="fa fa-info-circle" v-b-tooltip title="T.d. atburðir í skóladagatali, læsingar, pantanir, viðtalstímar ofl."></i>
      </div>
    </div>
  </div>
</template>

<script>
import common from '@/api/common';
import requests from '@/api/requests';
import moment from 'moment';

// import isLocale from 'fullcalendar/dist/locale/en-gb'; // eslint-disable-line
// import enLocale from 'fullcalendar/dist/locale/is'; // eslint-disable-line
import { FullCalendar } from 'vue-full-calendar';
// import TermSearchSlidebar from '../school/terms/TermSearchSlidebar.vue';

export default {
  name: 'timetable',
  components: {
    FullCalendar,
  },
  props: ['id', 'type', 'holidays', 'clickable', 'refetch', 'goToDate', 'defaultMonth', 'defaultDay', 'extraParams', 'attendanceColorCode'],
  data() {
    return {
      loading: false,
      showColorInfo: false,
      locale: 'en',
      eventSources: [
        {
          events: (start, end, timezone, callback) => {
            this.loadEvents(start, end, timezone, callback);
          },
          backgroundColor: '#98139C',
          borderColor: '#98139C',
          textColor: '#ffffff',
        },
      ],
      config: {
        editable: false,
        selectable: false,
        defaultView: 'agendaWeek',
        allDaySlot: true,
        minTime: '08:00:00',
        locale: 'is',
        slotLabelFormat: ['HH:mm'],
        height: 650,
        // columnHeaderFormat: 'ddd DD.MM',
        eventClick: event => {
          if (this.clickable && event.id) {
            this.$emit('eventClick', { id: event.id });
          }
        },
        dayClick: date => {
          if (this.clickable) {
            this.$emit('dayClick', { date });
          }
        },
        eventRender: (event, element) => {
          let classicTitle = true;
          let title = '';
          if (event.group) {
            classicTitle = false;
            title = `Hópur: ${event.name}`;
          }
          if (event.teacher && !this.attendanceColorCode) {
            classicTitle = false;
            title += `\nKennari: ${event.teacher}`;
          }
          if (event.room) {
            classicTitle = false;
            title += `\nStofa: ${event.room}`;
          }
          if (this.attendanceColorCode && event.attendance_name) {
            classicTitle = false;
            title += `\Fjarvist: ${event.attendance_name}`;
          }
          if (this.attendanceColorCode && event.absence_name) {
            classicTitle = false;
            title += `\nForfall: ${event.absence_name}`;
          }

          if (classicTitle) {
            element[0].title = event.title; // eslint-disable-line no-param-reassign
          } else {
            element[0].title = title; // eslint-disable-line no-param-reassign
          }
          element.find('span.fc-title, div.fc-title').replaceWith(event.title);
        },
      },
    };
  },
  methods: {
    weekendExists() {
      const events = this.$refs.calendar.fireMethod('clientEvents');
      if (events) {
        return events.filter(x => moment(x.start).day() === 0 || moment(x.start).day() === 6).length > 0;
      }
      return false;
    },
    hasLongDays() {
      const events = this.$refs.calendar.fireMethod('clientEvents');
      if (events) {
        return events.filter(x => moment(x.end).format('HHmm') > 1700).length > 0;
      }
      return false;
    },
    loadEvents(start, end, timezone, callback) {
      this.showColorInfo = false;
      if (this.type && this.id) {
        const params = {
          ...this.extraParams,
          date_from: moment(start).format('DD.MM.YYYY'),
          date_to: moment(end).format('DD.MM.YYYY'),
        };
        let p = null;
        if (this.type === 'classroom') {
          params.classroom_id = this.id;
          p = common.getTimetable(params);
        } else if (this.type === 'staff') {
          params.staff_id = this.id;
          p = common.getTimetable(params);
        } else if (this.type === 'student') {
          params.student_id = this.id;
          p = common.getTimetable(params);
        } else if (this.type === 'module') {
          params.module_id = this.id;
          p = common.getTimetable(params);
        } else if (this.type === 'class') {
          params.class_id = this.id;
          p = common.getTimetable(params);
        } else if (this.type === 'groups') {
          params.groups = this.id;
          p = common.getTimetableGroups(params);
        } else if (this.type === 'equipment-orders') {
          p = requests.getEquipmentRequestsList(params);
        } else if (this.type === 'school-events') {
          // params.division_id = this.id !== 1 ? this.id : '';
          p = common.getCalendarSchoolEvents(params);
        } else if (this.type === 'classroom-orders') {
          p = requests.getClassroomRequestList(params);
        } else if (this.type === 'group') {
          params.groups = this.id;
          p = common.getTimetableGroups(params);
        }
        p.then(response => {
          const list = [];
          let resp = [];
          if (response.data.items) resp = response.data.items;
          else resp = response.data;
          resp.forEach(e => {
            let { name } = e;
            let backgroundColor = '';
            let textColor = '';
            let borderColor = '';
            if (e.teacher && !this.attendanceColorCode) {
              name += ` <i class="fa fa-user"></i>${e.teacher}`;
            }
            if (e.classroom && !this.attendanceColorCode) {
              name += ` <i class="fa fa-home"></i>${e.classroom}`;
            }
            if (this.type === 'school-events' && e.notendahopur_heiti) {
              name += ` - ${e.notendahopur_heiti}`;
            }
            // Viðvera
            if(this.attendanceColorCode && e.attendance_code) {
              name += `  <i class="fa fa-clock-o"></i> ${e.attendance_code}`;
            }
            // Fjarvist
            if(this.attendanceColorCode && e.absence_code) {
              name += `  <i class="fa fa-sign-out"></i> ${e.absence_code}`;
            }
            // Mismunandi litir á viðburðum
            // Ef skóladagatal þá öðruvísi litur á þeim sem eru ekki með mætingarskyldu
            if ((this.type === 'school-events' && e.maeting === 1) || (this.type !== 'school-events' && !e.group_id)) {
              backgroundColor = 'rgb(	152, 19, 156, 0.2)';
              textColor = '#000';
              borderColor = 'rgb(	152, 19, 156, 0.2)';
              if (this.type !== 'school-events') this.showColorInfo = true;
            }
            // ef kladdi þá viljum við lita kladdann með lit í takt við kóða
            if (this.attendanceColorCode === 1 && e.color_code) {
              backgroundColor = e.color_code;
              borderColor =  e.color_code;
            }

            list.push({
              allDay: e.allday === 1,
              start: e.allday === 0 ? e.date_time_from : e.date,
              end: e.allday === 0 ? e.date_time_to : null,
              title: name,
              popup: e.name,
              id: e.table_id,
              group: e.group_id,
              name: e.name,
              teacher: e.teacher,
              room: e.classroom,
              backgroundColor,
              textColor,
              borderColor,
              attendance_name: e.attendance_name,
              absence_name: e.absence_name,
            });
          });
          if (this.holidays) {
            common.getHolidays(params).then(response2 => {
              if (response2.data.length > 0) this.showColorInfo = true;
              response2.data.forEach(r => {
                list.push({
                  allDay: 0,
                  start: r.date_time_from,
                  end: r.date_time_to,
                  title: r.name,
                  backgroundColor: 'rgb(	152, 19, 156, 0.2)',
                  textColor: '#000',
                  borderColor: 'rgb(	152, 19, 156, 0.2)',
                });
              });
              callback(list);
            });
          } else if (this.type === 'school-events') {
            common.getBankHolidays(moment(start).format('DD.MM.YYYY'), moment(end).format('DD.MM.YYYY')).then(response2 => {
              response2.data.items.forEach(r => {
                list.push({
                  allDay: true,
                  start: r.dags,
                  end: null,
                  title: r.heiti,
                  backgroundColor: '#F26175',
                  textColor: '#000',
                  borderColor: '#F26175',
                });
              });
              callback(list);
            });
          } else {
            callback(list);
          }
        });
      } else {
        callback([]);
      }
    },
    print() {
      this.$refs.calendar.fireMethod('option', 'allDaySlot', false);
      if (this.hasLongDays()) {
        this.$refs.calendar.fireMethod('option', 'height', 850);
        this.$refs.calendar.fireMethod('option', 'maxTime', '24:00:00');
      } else {
        this.$refs.calendar.fireMethod('option', 'height', 500);
        this.$refs.calendar.fireMethod('option', 'maxTime', '17:00:00');
      }
      if (!this.weekendExists()) {
        this.$refs.calendar.fireMethod('option', 'weekends', false);
      }

      window.print();
      this.$refs.calendar.fireMethod('option', 'allDaySlot', true);
      this.$refs.calendar.fireMethod('option', 'height', 650);
      this.$refs.calendar.fireMethod('option', 'weekends', true);
      this.$refs.calendar.fireMethod('option', 'maxTime', '24:00:00');
    },
  },
  watch: {
    id() {
      /* if (!this.goToDate) {
        this.$refs.calendar.fireMethod('gotoDate', moment());
      } */
      this.$refs.calendar.$emit('refetch-events');
    },
    extraParams() {
      /* if (!this.goToDate) {
        this.$refs.calendar.fireMethod('gotoDate', moment());
      } */
      this.$refs.calendar.$emit('refetch-events');
    },
    refetch() {
      if (this.refetch) {
        this.$refs.calendar.$emit('refetch-events');
      }
    },
    goToDate() {
      if (this.goToDate) {
        this.$refs.calendar.fireMethod('gotoDate', this.goToDate);
      }
    },
  },
  created() {
    this.config.locale = this.$i18n.locale;
    if (this.defaultMonth) {
      this.config.defaultView = 'month';
    }
    if (this.defaultDay) {
      this.config.defaultView = 'agendaDay';
    }
    this.$nextTick(() => {
      if (this.goToDate && this.$refs.calendar) {
        this.$refs.calendar.fireMethod('gotoDate', this.goToDate);
      }
    });
  },
};
</script>

<style lang="scss">
.timetablePrintButton {
  background-color: #f5f5f5;
  border-color: #ccc;
  height: 2.1em;
  &:hover,
  &:focus,
  &.active {
    background-color: #f5f5f5;
    border-color: #f5f5f5;
  }
  i {
    color: #303030;
    margin-top: -10px;
  }
}
</style>
