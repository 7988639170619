<template>
  <Slidebar :large="true" @closeMe="closeSlidebar">
    <h4>Stofna veikindabeiðni</h4>
    <div>
      <h5>Nemandi</h5>
      <div v-if="searchStudent">
        <b-form-group label-for="heiti" :state="submitted && errors.has('heiti') ? false : ''" :invalid-feedback="errors.first('heiti')">
          <b-form-input
            v-model="search"
            v-on:keyup.enter="loadList"
            placeholder="Leita eftir nafni eða kennitölu"
            :disabled="loading.students"
          />
        </b-form-group>
        <i class="fa fa-spin fa-spinner" v-if="loading.students"></i>
        <b-btn variant="primary" class="btn-xs float-right" style="margin-top: -5px" @click="loadList()">Leita</b-btn>
        <strong v-if="list.length > 0" class="mt-3 mb-2">Smelltu á nemandann til þess að velja hann</strong>
        <div v-for="student in list" :key="student.kennitala" @click="selectStudent(student)" class="cursor-pointer">
          {{ student.nafn }} {{ student.kennitala }}
        </div>
      </div>
      <div v-else class="mb-2">
        {{ form.studentName }} kt. {{ form.studentSsn }}
        <i class="fa fa-pencil cursor-pointer" @click="searchStudent = true"></i>
        <div v-if="form.certificate">
          <i class="fa fa-exclamation-triangle"></i> Ath. þessi nemandi er skráður að þurfi að skila vottorði "{{ form.certificate }}".
        </div>
      </div>
    </div>

    <div v-if="!searchStudent && form.studentId">
      <b-row>
        <b-col class="pl-0">
          <b-form-checkbox v-model="form.today" class="d-inline-block"></b-form-checkbox>
          <div class="d-inline-block">Í dag ({{ today | moment('DD.MM.YYYY') }})</div>
          <div v-if="form.today">
            <b-form-checkbox v-model="form.wholeToday" class="d-inline-block"></b-form-checkbox>
            <div class="d-inline-block">Skrá veikindi allan daginn</div>
          </div>
          <div v-if="form.today && !form.wholeToday && eventsToday.length === 0">Engir tímar</div>
          <i class="fa fa-spin fa-spinner" v-if="form.today && !form.wholeToday && loading.today"></i>
          <table class="table less-padding mt-2" v-if="form.today && !form.wholeToday && !loading.today && eventsToday.length > 0">
            <tr>
              <th></th>
              <th>Frá</th>
              <th>Til</th>
              <th>Hópur</th>
            </tr>
            <tr v-for="(item, idx) in eventsToday" :key="`today-${idx}`">
              <td><b-form-checkbox v-model="item.absence"></b-form-checkbox></td>
              <!-- ng-checked ng-disabled"-->
              <td>{{ item.time_from }}</td>
              <td>{{ item.time_to }}</td>
              <td>{{ item.name }}</td>
            </tr>
          </table>
        </b-col>
        <b-col>
          <b-form-checkbox v-model="form.tomorrow" class="d-inline-block"></b-form-checkbox>
          <div class="d-inline-block">Á morgun ({{ tomorrow | moment('DD.MM.YYYY') }})</div>
          <div v-if="form.tomorrow">
            <b-form-checkbox v-model="form.wholeTomorrow" class="d-inline-block"></b-form-checkbox>
            <div class="d-inline-block">Skrá veikindi allan daginn</div>
          </div>
          <div v-if="form.tomorrow && !form.wholeTomorrow && eventsTomorrow.length === 0">Engir tímar</div>
          <i class="fa fa-spin fa-spinner" v-if="form.tomorrow && !form.wholeTomorrow && loading.tomorrow"></i>
          <table
            class="table less-padding mt-2"
            v-if="form.tomorrow && !form.wholeTomorrow && !loading.tomorrow && eventsTomorrow.length > 0"
          >
            <tr>
              <th></th>
              <th>Frá</th>
              <th>Til</th>
              <th>Hópur</th>
            </tr>
            <tr v-for="(item, idx) in eventsTomorrow" :key="`tomorrow-${idx}`">
              <td><b-form-checkbox v-model="item.absence"></b-form-checkbox></td>
              <!-- ng-checked ng-disabled"-->
              <td>{{ item.time_from }}</td>
              <td>{{ item.time_to }}</td>
              <td>{{ item.name }}</td>
            </tr>
          </table>
        </b-col>
      </b-row>
      <div v-if="form.today || form.tomorrow" style="margin-top: 30px">
        <div>
          <strong>Skýring</strong>
          <i
            class="pl-1 fa fa-info-circle"
            v-b-tooltip
            title="Skýring nemanda á veikindum. Þessi skýring fer ekki í fjarvistarferil og er ekki sýnileg kennurum."
          ></i>
        </div>
        <b-form-input v-model="form.reason" style="width: 100%" maxLength="2000"> </b-form-input>
        <div class="mt-2">
          <strong>Aths. afgreiðslu</strong>
          <i
            class="pl-1 fa fa-info-circle"
            v-b-tooltip
            title="Athugasemd starfsmanns við afgreiðslu beiðnar. Þessi athugasemd er sýnileg nemanda og forráðamönnum."
          ></i>
        </div>
        <b-form-input v-model="form.comment" style="width: 100%" maxLength="2000"> </b-form-input>
        <div class="mt-2">
          <strong
            >Aths. fjarvista
            <i
              class="pl-1 fa fa-info-circle"
              v-b-tooltip
              title="Athugasemd sem birtist í fjarvistaferli nemanda og er sýnileg kennurum."
            ></i
          ></strong>
        </div>
        <b-form-input v-model="form.commentAbsence" style="width: 100%" maxLength="1000"> </b-form-input>
        <div class="mt-2"><strong>Kóði</strong></div>
        <v-select class="dropdownAbsenceList" v-model="form.code" :clearable="false" :options="absenceList" label="kodi"> </v-select>

        <div class="mt-2">
          <strong>Senda tölvupóst</strong>
          <i
            class="pl-1 fa fa-info-circle"
            v-b-tooltip
            title="Sjálfvirkur póstur sem segir að veikindaskráning hafi verið samþykkt.
             Pósturinn er sendur á nemandann sjálfan, forráðamenn ef nemandi er yngri en 18 ára og
             aðstandendur nemenda eldri en 18 ára ef þeir hafa aðgang."
          ></i>
        </div>
        <b-form-checkbox v-model="form.sendMail"></b-form-checkbox>
        <b-btn variant="primary" class="mt-3" :disabled="!canSubmit" @click="saveRequest()">Senda inn</b-btn>
        <div class="pt-2">
          <i class="fa fa-exclamation-triangle"></i> Ath. þegar veikindatilkynning er send inn þá er hún sjálfkrafa samþykkt.
        </div>
      </div>
    </div>
  </Slidebar>
</template>

<script>
import moment from 'moment';
import students from '@/api/students';
import requests from '@/api/requests';
import common from '@/api/common';
import types from '@/api/types';

import Slidebar from '@/components/common/Slidebar.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'create-absence-slidebar',
  props: [],
  components: {
    Slidebar,
  },
  computed: {
    canSubmit() {
      return (
        (this.form.today && (this.form.wholeToday || this.eventsToday.filter(x => x.absence).length > 0)) ||
        (this.form.tomorrow && (this.form.wholeTomorrow || this.eventsTomorrow.filter(x => x.absence).length > 0))
      );
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('beidnir_forfoll');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {
      search: '',
      halfDayAllowed: true,
      form: {
        studentId: '',
        studentName: '',
        studentSsn: '',
        today: false,
        wholeToday: true,
        tomorrow: false,
        wholeTomorrow: true,
        reason: '',
        comment: '',
        commentAbsence: '',
        code: '',
        sendMail: false,
        certificate: '',
      },
      today: moment(),
      tomorrow: moment().add(1, 'days'),
      eventsToday: [],
      eventsTomorrow: [],
      list: [],
      absenceList: [],
      loading: {
        certificate: false,
        students: false,
        today: false,
        tomorrow: false,
        absences: false,
      },
      firsttime: false,
      submitted: false,
      searchStudent: true,
      current: [],
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    closeAndReloadSlidebar() {
      this.$emit('closeAndReload');
    },
    async loadList() {
      try {
        this.firsttime = true;
        this.loading.students = true;
        const response = await students.getStudentList({ search: this.search, active: 1 });
        this.list = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.students = false;
      }
    },
    selectStudent(student) {
      this.searchStudent = false;
      this.form.studentId = student.nemandi_id;
      this.form.studentName = student.nafn;
      this.form.studentSsn = student.kennitala;
      this.loadStudentData();
    },
    async loadAbsenceList() {
      try {
        this.loading.absences = true;
        const response = await types.getAbsenceList({ active: 1 });
        this.absenceList = response.data.items;
        this.form.code = this.absenceList.find(y => y.teg_forfalla === this.schoolSettings.rafraen_veikindi_kodi);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.absences = false;
      }
    },
    async loadStudentData() {
      try {
        this.loading.certificate = true;
        const resp = await students.getStudentAbsenceCertificate(this.form.studentId);
        this.form.certificate = resp.data.vottord;
        this.loading.certificate = false;

        this.loading.today = true;
        // Sækja stundatöflu dagsins í dag
        const response2 = await common.getTimetable({
          date_from: moment().format('DD.MM.YYYY'),
          date_to: moment().format('DD.MM.YYYY'),
          student_id: this.form.studentId,
        });
        this.eventsToday = response2.data
          .filter(x => x.group_id)
          .map(x => ({
            absence: false,
            ...x,
          }));
        this.loading.today = false;

        // Sækja stundatöflu morgundagins
        const response3 = await common.getTimetable({
          date_from: moment().add(1, 'days').format('DD.MM.YYYY'),
          date_to: moment().add(1, 'days').format('DD.MM.YYYY'),
          student_id: this.form.studentId,
        });
        // Vantar að tengja við heitið
        this.eventsTomorrow = response3.data
          .filter(x => x.group_id)
          .map(x => ({
            absence: false,
            ...x,
          }));
        this.loading.tomorrow = false;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.today = false;
        this.loading.tomorrow = false;
        this.loading.certificate = false;
      }
    },
    getDayData(today, tomorrow) {
      const events = this.today ? this.eventsToday.filter(x => x.absence) : this.eventsTomorrow.filter(x => x.absence);
      return {
        nemandi_forfoll_tegund: 1,
        nemandi_id: this.form.studentId,
        dags_fra: today ? moment().format('DD.MM.YYYY') : moment().add(1, 'days').format('DD.MM.YYYY'),
        dags_til: today ? moment().format('DD.MM.YYYY') : moment().add(1, 'days').format('DD.MM.YYYY'),
        heill_dagur: (today && this.form.wholeToday) || (tomorrow && this.form.wholeTomorrow) ? 1 : 0,
        teg_forfalla_id: this.form.code.teg_forfalla,
        athugasemd_skraning: this.form.reason,
        athugasemd_afgreidsla: this.form.comment,
        athugasemd_forfoll: this.form.commentAbsence,
        senda_post: this.form.sendMail ? 1 : 0,
        timar: events.map(event => ({
          dags: moment(event.date).format('DD.MM.YYYY'),
          timi_fra: event.time_from,
          timi_til: event.time_to,
          ferill_id: event.ferill_id,
          namsgrein_afanganr: event.moduleName,
          stofntafla_id: event.stofntafla_id,
          stundatafla_id: event.stundatafla_id,
        })),
      };
    },
    async saveRequest() {
      try {
        const items = [];

        if (this.form.today) {
          items.push(this.getDayData(true, false));
        }
        if (this.form.tomorrow) {
          items.push(this.getDayData(false, true));
        }
        await requests.createMassReviewAbsence({ items });

        this.displaySuccess('Beiðni stofnuð og afgreidd');

        this.closeSlidebar();

        this.$emit('closeSlidebar', { ssn: this.form.studentSsn });
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadAbsenceList();
  },
};
</script>

<style lang="scss"></style>
