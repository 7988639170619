/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CalculateFinalGradesInputItem
 */
export interface CalculateFinalGradesInputItem {
    /**
     * 
     * @type {string}
     * @memberof CalculateFinalGradesInputItem
     */
    departments: string;
    /**
     * 
     * @type {string}
     * @memberof CalculateFinalGradesInputItem
     */
    enddate: string;
    /**
     * 
     * @type {string}
     * @memberof CalculateFinalGradesInputItem
     */
    module?: string;
    /**
     * 
     * @type {number}
     * @memberof CalculateFinalGradesInputItem
     */
    moveModules?: number;
}
/**
 * 
 * @export
 * @interface ChangeCourseRequirementsInputItem
 */
export interface ChangeCourseRequirementsInputItem {
    /**
     * 
     * @type {number}
     * @memberof ChangeCourseRequirementsInputItem
     */
    studentId?: number;
    /**
     * 
     * @type {number}
     * @memberof ChangeCourseRequirementsInputItem
     */
    courseId?: number;
    /**
     * 
     * @type {number}
     * @memberof ChangeCourseRequirementsInputItem
     */
    studentCourseId?: number;
    /**
     * 
     * @type {number}
     * @memberof ChangeCourseRequirementsInputItem
     */
    update?: number;
    /**
     * 
     * @type {number}
     * @memberof ChangeCourseRequirementsInputItem
     */
    updateReq?: number;
    /**
     * 
     * @type {number}
     * @memberof ChangeCourseRequirementsInputItem
     */
    _delete?: number;
    /**
     * 
     * @type {number}
     * @memberof ChangeCourseRequirementsInputItem
     */
    confirm?: number;
    /**
     * 
     * @type {number}
     * @memberof ChangeCourseRequirementsInputItem
     */
    mark?: number;
    /**
     * 
     * @type {number}
     * @memberof ChangeCourseRequirementsInputItem
     */
    termId?: number;
    /**
     * 
     * @type {number}
     * @memberof ChangeCourseRequirementsInputItem
     */
    gratuate?: number;
    /**
     * 
     * @type {number}
     * @memberof ChangeCourseRequirementsInputItem
     */
    disableStudent?: number;
    /**
     * 
     * @type {string}
     * @memberof ChangeCourseRequirementsInputItem
     */
    gratuationDate?: string;
    /**
     * 
     * @type {number}
     * @memberof ChangeCourseRequirementsInputItem
     */
    copyCourse?: number;
    /**
     * 
     * @type {number}
     * @memberof ChangeCourseRequirementsInputItem
     */
    oldCourseId?: number;
    /**
     * 
     * @type {number}
     * @memberof ChangeCourseRequirementsInputItem
     */
    courseStructureId?: number;
    /**
     * 
     * @type {Array<ChangeCourseRequirementsModuleInputItem>}
     * @memberof ChangeCourseRequirementsInputItem
     */
    modules?: Array<ChangeCourseRequirementsModuleInputItem>;
}
/**
 * 
 * @export
 * @interface ChangeCourseRequirementsModuleInputItem
 */
export interface ChangeCourseRequirementsModuleInputItem {
    /**
     * 
     * @type {number}
     * @memberof ChangeCourseRequirementsModuleInputItem
     */
    disconnect?: number;
    /**
     * 
     * @type {number}
     * @memberof ChangeCourseRequirementsModuleInputItem
     */
    inactive?: number;
    /**
     * 
     * @type {number}
     * @memberof ChangeCourseRequirementsModuleInputItem
     */
    ferill_id?: number;
    /**
     * 
     * @type {number}
     * @memberof ChangeCourseRequirementsModuleInputItem
     */
    einingar_skirteini?: number;
    /**
     * 
     * @type {number}
     * @memberof ChangeCourseRequirementsModuleInputItem
     */
    trep_skirteini?: number;
    /**
     * 
     * @type {number}
     * @memberof ChangeCourseRequirementsModuleInputItem
     */
    uppbygging?: number;
    /**
     * 
     * @type {number}
     * @memberof ChangeCourseRequirementsModuleInputItem
     */
    afangi_id_skirteini?: number;
    /**
     * 
     * @type {number}
     * @memberof ChangeCourseRequirementsModuleInputItem
     */
    afangi_id_braut?: number;
    /**
     * 
     * @type {number}
     * @memberof ChangeCourseRequirementsModuleInputItem
     */
    braut_uppbygg_id?: number;
    /**
     * 
     * @type {number}
     * @memberof ChangeCourseRequirementsModuleInputItem
     */
    afangar_a_skirteini_id?: number;
    /**
     * 
     * @type {number}
     * @memberof ChangeCourseRequirementsModuleInputItem
     */
    map?: number;
}
/**
 * 
 * @export
 * @interface CreatePlannedGroupsInputItem
 */
export interface CreatePlannedGroupsInputItem {
    /**
     * 
     * @type {string}
     * @memberof CreatePlannedGroupsInputItem
     */
    modules?: string;
}
/**
 * 
 * @export
 * @interface DeactivateStudentsInputItem
 */
export interface DeactivateStudentsInputItem {
    /**
     * 
     * @type {string}
     * @memberof DeactivateStudentsInputItem
     */
    students?: string;
    /**
     * 
     * @type {number}
     * @memberof DeactivateStudentsInputItem
     */
    groupId?: number;
}
/**
 * 
 * @export
 * @interface GenerateAttachmentDownloadTokenItem
 */
export interface GenerateAttachmentDownloadTokenItem {
    /**
     * 
     * @type {string}
     * @memberof GenerateAttachmentDownloadTokenItem
     */
    url: string;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    detail?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface SendMessageResponse
 */
export interface SendMessageResponse {
    /**
     * 
     * @type {Array<number>}
     * @memberof SendMessageResponse
     */
    ids: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof SendMessageResponse
     */
    count: number;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum StatusEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3
}

/**
 * 
 * @export
 * @interface TimetableCreateTeamsEventInput
 */
export interface TimetableCreateTeamsEventInput {
    /**
     * 
     * @type {string}
     * @memberof TimetableCreateTeamsEventInput
     */
    placeId?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof TimetableCreateTeamsEventInput
     */
    attendees: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof TimetableCreateTeamsEventInput
     */
    lobbyBypass?: boolean;
}
/**
 * 
 * @export
 * @interface TimetableGetTeamsEventAttendee
 */
export interface TimetableGetTeamsEventAttendee {
    /**
     * 
     * @type {number}
     * @memberof TimetableGetTeamsEventAttendee
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof TimetableGetTeamsEventAttendee
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TimetableGetTeamsEventAttendee
     */
    email: string;
    /**
     * 
     * @type {boolean}
     * @memberof TimetableGetTeamsEventAttendee
     */
    teacher: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TimetableGetTeamsEventAttendee
     */
    isCreated?: boolean;
}
/**
 * 
 * @export
 * @interface TimetableGetTeamsEventResponse
 */
export interface TimetableGetTeamsEventResponse {
    /**
     * 
     * @type {boolean}
     * @memberof TimetableGetTeamsEventResponse
     */
    isCreated: boolean;
    /**
     * 
     * @type {string}
     * @memberof TimetableGetTeamsEventResponse
     */
    dags: string;
    /**
     * 
     * @type {string}
     * @memberof TimetableGetTeamsEventResponse
     */
    timiFra: string;
    /**
     * 
     * @type {string}
     * @memberof TimetableGetTeamsEventResponse
     */
    timiTil: string;
    /**
     * 
     * @type {string}
     * @memberof TimetableGetTeamsEventResponse
     */
    heiti: string;
    /**
     * 
     * @type {boolean}
     * @memberof TimetableGetTeamsEventResponse
     */
    lobbyBypass: boolean;
    /**
     * 
     * @type {Array<TimetableGetTeamsEventAttendee>}
     * @memberof TimetableGetTeamsEventResponse
     */
    attendees: Array<TimetableGetTeamsEventAttendee>;
    /**
     * 
     * @type {string}
     * @memberof TimetableGetTeamsEventResponse
     */
    roomId?: string;
    /**
     * 
     * @type {string}
     * @memberof TimetableGetTeamsEventResponse
     */
    link?: string;
}
/**
 * 
 * @export
 * @interface TimetableTeamsEnabledResponse
 */
export interface TimetableTeamsEnabledResponse {
    /**
     * 
     * @type {boolean}
     * @memberof TimetableTeamsEnabledResponse
     */
    enabled: boolean;
}
/**
 * 
 * @export
 * @interface TimetableTeamsRoomListResponse
 */
export interface TimetableTeamsRoomListResponse {
    /**
     * 
     * @type {string}
     * @memberof TimetableTeamsRoomListResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TimetableTeamsRoomListResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TimetableTeamsRoomListResponse
     */
    email: string;
}
/**
 * 
 * @export
 * @interface UpdateChangeCourseRequestInputItem
 */
export interface UpdateChangeCourseRequestInputItem {
    /**
     * 
     * @type {StatusEnum}
     * @memberof UpdateChangeCourseRequestInputItem
     */
    status?: StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateChangeCourseRequestInputItem
     */
    schoolComment?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateChangeCourseRequestInputItem
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateChangeCourseRequestInputItem
     */
    closeDate?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateChangeCourseRequestInputItem
     */
    confirmedCourseId?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateChangeCourseRequestInputItem
     */
    sjalfvirktVal?: number;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<string | number>}
     * @memberof ValidationError
     */
    loc: Array<string | number>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    msg: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    type: string;
}

/**
 * AttachmentsApi - axios parameter creator
 * @export
 */
export const AttachmentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete Attachment
         * @param {number} attachmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAttachment: async (attachmentId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'attachmentId' is not null or undefined
            assertParamExists('deleteAttachment', 'attachmentId', attachmentId)
            const localVarPath = `/api/worker/p/attachments/attachment/{attachmentId}`
                .replace(`{${"attachmentId"}}`, encodeURIComponent(String(attachmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAPIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate Attachment Download
         * @param {number} attachmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttachmentDownload: async (attachmentId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'attachmentId' is not null or undefined
            assertParamExists('getAttachmentDownload', 'attachmentId', attachmentId)
            const localVarPath = `/api/worker/p/attachments/attachment/{attachmentId}`
                .replace(`{${"attachmentId"}}`, encodeURIComponent(String(attachmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate Attachment Download Token
         * @param {number} attachmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttachmentDownloadToken: async (attachmentId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'attachmentId' is not null or undefined
            assertParamExists('getAttachmentDownloadToken', 'attachmentId', attachmentId)
            const localVarPath = `/api/worker/p/attachments/attachment/{attachmentId}/token`
                .replace(`{${"attachmentId"}}`, encodeURIComponent(String(attachmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAPIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate Teachingplan Download
         * @param {number} termId 
         * @param {number} userId 
         * @param {string} departments 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeachingplanDownload: async (termId: number, userId: number, departments: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'termId' is not null or undefined
            assertParamExists('getTeachingplanDownload', 'termId', termId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getTeachingplanDownload', 'userId', userId)
            // verify required parameter 'departments' is not null or undefined
            assertParamExists('getTeachingplanDownload', 'departments', departments)
            const localVarPath = `/api/worker/p/attachments/attachment/teachingplan/{termId}`
                .replace(`{${"termId"}}`, encodeURIComponent(String(termId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (departments !== undefined) {
                localVarQueryParameter['departments'] = departments;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate Teachingplan Download Token
         * @param {number} termId 
         * @param {number} userId 
         * @param {string} departments 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeachingplanDownloadToken: async (termId: number, userId: number, departments: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'termId' is not null or undefined
            assertParamExists('getTeachingplanDownloadToken', 'termId', termId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getTeachingplanDownloadToken', 'userId', userId)
            // verify required parameter 'departments' is not null or undefined
            assertParamExists('getTeachingplanDownloadToken', 'departments', departments)
            const localVarPath = `/api/worker/p/attachments/attachment/teachingplan/{termId}/token`
                .replace(`{${"termId"}}`, encodeURIComponent(String(termId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAPIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (departments !== undefined) {
                localVarQueryParameter['departments'] = departments;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload Attachment
         * @param {number} type 
         * @param {number} id 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAttachment: async (type: number, id: number, file: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('uploadAttachment', 'type', type)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadAttachment', 'id', id)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadAttachment', 'file', file)
            const localVarPath = `/api/worker/p/attachments/attachment/upload/{type}/{id}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication CustomAPIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AttachmentsApi - functional programming interface
 * @export
 */
export const AttachmentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AttachmentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete Attachment
         * @param {number} attachmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAttachment(attachmentId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAttachment(attachmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generate Attachment Download
         * @param {number} attachmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAttachmentDownload(attachmentId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAttachmentDownload(attachmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generate Attachment Download Token
         * @param {number} attachmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAttachmentDownloadToken(attachmentId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateAttachmentDownloadTokenItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAttachmentDownloadToken(attachmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generate Teachingplan Download
         * @param {number} termId 
         * @param {number} userId 
         * @param {string} departments 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeachingplanDownload(termId: number, userId: number, departments: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeachingplanDownload(termId, userId, departments, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generate Teachingplan Download Token
         * @param {number} termId 
         * @param {number} userId 
         * @param {string} departments 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeachingplanDownloadToken(termId: number, userId: number, departments: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateAttachmentDownloadTokenItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeachingplanDownloadToken(termId, userId, departments, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload Attachment
         * @param {number} type 
         * @param {number} id 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadAttachment(type: number, id: number, file: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadAttachment(type, id, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AttachmentsApi - factory interface
 * @export
 */
export const AttachmentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AttachmentsApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete Attachment
         * @param {number} attachmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAttachment(attachmentId: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteAttachment(attachmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate Attachment Download
         * @param {number} attachmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttachmentDownload(attachmentId: number, options?: any): AxiosPromise<object> {
            return localVarFp.getAttachmentDownload(attachmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate Attachment Download Token
         * @param {number} attachmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttachmentDownloadToken(attachmentId: number, options?: any): AxiosPromise<GenerateAttachmentDownloadTokenItem> {
            return localVarFp.getAttachmentDownloadToken(attachmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate Teachingplan Download
         * @param {number} termId 
         * @param {number} userId 
         * @param {string} departments 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeachingplanDownload(termId: number, userId: number, departments: string, options?: any): AxiosPromise<object> {
            return localVarFp.getTeachingplanDownload(termId, userId, departments, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate Teachingplan Download Token
         * @param {number} termId 
         * @param {number} userId 
         * @param {string} departments 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeachingplanDownloadToken(termId: number, userId: number, departments: string, options?: any): AxiosPromise<GenerateAttachmentDownloadTokenItem> {
            return localVarFp.getTeachingplanDownloadToken(termId, userId, departments, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload Attachment
         * @param {number} type 
         * @param {number} id 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAttachment(type: number, id: number, file: any, options?: any): AxiosPromise<object> {
            return localVarFp.uploadAttachment(type, id, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteAttachment operation in AttachmentsApi.
 * @export
 * @interface AttachmentsApiDeleteAttachmentRequest
 */
export interface AttachmentsApiDeleteAttachmentRequest {
    /**
     * 
     * @type {number}
     * @memberof AttachmentsApiDeleteAttachment
     */
    readonly attachmentId: number
}

/**
 * Request parameters for getAttachmentDownload operation in AttachmentsApi.
 * @export
 * @interface AttachmentsApiGetAttachmentDownloadRequest
 */
export interface AttachmentsApiGetAttachmentDownloadRequest {
    /**
     * 
     * @type {number}
     * @memberof AttachmentsApiGetAttachmentDownload
     */
    readonly attachmentId: number
}

/**
 * Request parameters for getAttachmentDownloadToken operation in AttachmentsApi.
 * @export
 * @interface AttachmentsApiGetAttachmentDownloadTokenRequest
 */
export interface AttachmentsApiGetAttachmentDownloadTokenRequest {
    /**
     * 
     * @type {number}
     * @memberof AttachmentsApiGetAttachmentDownloadToken
     */
    readonly attachmentId: number
}

/**
 * Request parameters for getTeachingplanDownload operation in AttachmentsApi.
 * @export
 * @interface AttachmentsApiGetTeachingplanDownloadRequest
 */
export interface AttachmentsApiGetTeachingplanDownloadRequest {
    /**
     * 
     * @type {number}
     * @memberof AttachmentsApiGetTeachingplanDownload
     */
    readonly termId: number

    /**
     * 
     * @type {number}
     * @memberof AttachmentsApiGetTeachingplanDownload
     */
    readonly userId: number

    /**
     * 
     * @type {string}
     * @memberof AttachmentsApiGetTeachingplanDownload
     */
    readonly departments: string
}

/**
 * Request parameters for getTeachingplanDownloadToken operation in AttachmentsApi.
 * @export
 * @interface AttachmentsApiGetTeachingplanDownloadTokenRequest
 */
export interface AttachmentsApiGetTeachingplanDownloadTokenRequest {
    /**
     * 
     * @type {number}
     * @memberof AttachmentsApiGetTeachingplanDownloadToken
     */
    readonly termId: number

    /**
     * 
     * @type {number}
     * @memberof AttachmentsApiGetTeachingplanDownloadToken
     */
    readonly userId: number

    /**
     * 
     * @type {string}
     * @memberof AttachmentsApiGetTeachingplanDownloadToken
     */
    readonly departments: string
}

/**
 * Request parameters for uploadAttachment operation in AttachmentsApi.
 * @export
 * @interface AttachmentsApiUploadAttachmentRequest
 */
export interface AttachmentsApiUploadAttachmentRequest {
    /**
     * 
     * @type {number}
     * @memberof AttachmentsApiUploadAttachment
     */
    readonly type: number

    /**
     * 
     * @type {number}
     * @memberof AttachmentsApiUploadAttachment
     */
    readonly id: number

    /**
     * 
     * @type {any}
     * @memberof AttachmentsApiUploadAttachment
     */
    readonly file: any
}

/**
 * AttachmentsApi - object-oriented interface
 * @export
 * @class AttachmentsApi
 * @extends {BaseAPI}
 */
export class AttachmentsApi extends BaseAPI {
    /**
     * 
     * @summary Delete Attachment
     * @param {AttachmentsApiDeleteAttachmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentsApi
     */
    public deleteAttachment(requestParameters: AttachmentsApiDeleteAttachmentRequest, options?: any) {
        return AttachmentsApiFp(this.configuration).deleteAttachment(requestParameters.attachmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate Attachment Download
     * @param {AttachmentsApiGetAttachmentDownloadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentsApi
     */
    public getAttachmentDownload(requestParameters: AttachmentsApiGetAttachmentDownloadRequest, options?: any) {
        return AttachmentsApiFp(this.configuration).getAttachmentDownload(requestParameters.attachmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate Attachment Download Token
     * @param {AttachmentsApiGetAttachmentDownloadTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentsApi
     */
    public getAttachmentDownloadToken(requestParameters: AttachmentsApiGetAttachmentDownloadTokenRequest, options?: any) {
        return AttachmentsApiFp(this.configuration).getAttachmentDownloadToken(requestParameters.attachmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate Teachingplan Download
     * @param {AttachmentsApiGetTeachingplanDownloadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentsApi
     */
    public getTeachingplanDownload(requestParameters: AttachmentsApiGetTeachingplanDownloadRequest, options?: any) {
        return AttachmentsApiFp(this.configuration).getTeachingplanDownload(requestParameters.termId, requestParameters.userId, requestParameters.departments, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate Teachingplan Download Token
     * @param {AttachmentsApiGetTeachingplanDownloadTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentsApi
     */
    public getTeachingplanDownloadToken(requestParameters: AttachmentsApiGetTeachingplanDownloadTokenRequest, options?: any) {
        return AttachmentsApiFp(this.configuration).getTeachingplanDownloadToken(requestParameters.termId, requestParameters.userId, requestParameters.departments, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload Attachment
     * @param {AttachmentsApiUploadAttachmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentsApi
     */
    public uploadAttachment(requestParameters: AttachmentsApiUploadAttachmentRequest, options?: any) {
        return AttachmentsApiFp(this.configuration).uploadAttachment(requestParameters.type, requestParameters.id, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GroupsApi - axios parameter creator
 * @export
 */
export const GroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Planned Groups
         * @param {number} termId 
         * @param {CreatePlannedGroupsInputItem} createPlannedGroupsInputItem 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlannedGroups: async (termId: number, createPlannedGroupsInputItem: CreatePlannedGroupsInputItem, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'termId' is not null or undefined
            assertParamExists('createPlannedGroups', 'termId', termId)
            // verify required parameter 'createPlannedGroupsInputItem' is not null or undefined
            assertParamExists('createPlannedGroups', 'createPlannedGroupsInputItem', createPlannedGroupsInputItem)
            const localVarPath = `/api/worker/groups/create-planned-groups/{termId}`
                .replace(`{${"termId"}}`, encodeURIComponent(String(termId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAPIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPlannedGroupsInputItem, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupsApi - functional programming interface
 * @export
 */
export const GroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Planned Groups
         * @param {number} termId 
         * @param {CreatePlannedGroupsInputItem} createPlannedGroupsInputItem 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPlannedGroups(termId: number, createPlannedGroupsInputItem: CreatePlannedGroupsInputItem, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPlannedGroups(termId, createPlannedGroupsInputItem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GroupsApi - factory interface
 * @export
 */
export const GroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GroupsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Planned Groups
         * @param {number} termId 
         * @param {CreatePlannedGroupsInputItem} createPlannedGroupsInputItem 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlannedGroups(termId: number, createPlannedGroupsInputItem: CreatePlannedGroupsInputItem, options?: any): AxiosPromise<object> {
            return localVarFp.createPlannedGroups(termId, createPlannedGroupsInputItem, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createPlannedGroups operation in GroupsApi.
 * @export
 * @interface GroupsApiCreatePlannedGroupsRequest
 */
export interface GroupsApiCreatePlannedGroupsRequest {
    /**
     * 
     * @type {number}
     * @memberof GroupsApiCreatePlannedGroups
     */
    readonly termId: number

    /**
     * 
     * @type {CreatePlannedGroupsInputItem}
     * @memberof GroupsApiCreatePlannedGroups
     */
    readonly createPlannedGroupsInputItem: CreatePlannedGroupsInputItem
}

/**
 * GroupsApi - object-oriented interface
 * @export
 * @class GroupsApi
 * @extends {BaseAPI}
 */
export class GroupsApi extends BaseAPI {
    /**
     * 
     * @summary Create Planned Groups
     * @param {GroupsApiCreatePlannedGroupsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public createPlannedGroups(requestParameters: GroupsApiCreatePlannedGroupsRequest, options?: any) {
        return GroupsApiFp(this.configuration).createPlannedGroups(requestParameters.termId, requestParameters.createPlannedGroupsInputItem, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MessagesApi - axios parameter creator
 * @export
 */
export const MessagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Senda Póst
         * @param {string} data 
         * @param {any} [file] 
         * @param {any} [file2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessage: async (data: string, file?: any, file2?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('sendMessage', 'data', data)
            const localVarPath = `/api/worker/messages/send-message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication CustomAPIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


            if (data !== undefined) { 
                localVarFormParams.append('data', data as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (file2 !== undefined) { 
                localVarFormParams.append('file2', file2 as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessagesApi - functional programming interface
 * @export
 */
export const MessagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MessagesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Senda Póst
         * @param {string} data 
         * @param {any} [file] 
         * @param {any} [file2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendMessage(data: string, file?: any, file2?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendMessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendMessage(data, file, file2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MessagesApi - factory interface
 * @export
 */
export const MessagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MessagesApiFp(configuration)
    return {
        /**
         * 
         * @summary Senda Póst
         * @param {string} data 
         * @param {any} [file] 
         * @param {any} [file2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessage(data: string, file?: any, file2?: any, options?: any): AxiosPromise<SendMessageResponse> {
            return localVarFp.sendMessage(data, file, file2, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for sendMessage operation in MessagesApi.
 * @export
 * @interface MessagesApiSendMessageRequest
 */
export interface MessagesApiSendMessageRequest {
    /**
     * 
     * @type {string}
     * @memberof MessagesApiSendMessage
     */
    readonly data: string

    /**
     * 
     * @type {any}
     * @memberof MessagesApiSendMessage
     */
    readonly file?: any

    /**
     * 
     * @type {any}
     * @memberof MessagesApiSendMessage
     */
    readonly file2?: any
}

/**
 * MessagesApi - object-oriented interface
 * @export
 * @class MessagesApi
 * @extends {BaseAPI}
 */
export class MessagesApi extends BaseAPI {
    /**
     * 
     * @summary Senda Póst
     * @param {MessagesApiSendMessageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public sendMessage(requestParameters: MessagesApiSendMessageRequest, options?: any) {
        return MessagesApiFp(this.configuration).sendMessage(requestParameters.data, requestParameters.file, requestParameters.file2, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PhotosApi - axios parameter creator
 * @export
 */
export const PhotosApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Breyta Mynd Notanda
         * @param {number} userId 
         * @param {any} [photo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePhoto: async (userId: number, photo?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('changePhoto', 'userId', userId)
            const localVarPath = `/api/worker/photos/change-photo/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication CustomAPIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


            if (photo !== undefined) { 
                localVarFormParams.append('photo', photo as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PhotosApi - functional programming interface
 * @export
 */
export const PhotosApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PhotosApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Breyta Mynd Notanda
         * @param {number} userId 
         * @param {any} [photo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePhoto(userId: number, photo?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePhoto(userId, photo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PhotosApi - factory interface
 * @export
 */
export const PhotosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PhotosApiFp(configuration)
    return {
        /**
         * 
         * @summary Breyta Mynd Notanda
         * @param {number} userId 
         * @param {any} [photo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePhoto(userId: number, photo?: any, options?: any): AxiosPromise<object> {
            return localVarFp.changePhoto(userId, photo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for changePhoto operation in PhotosApi.
 * @export
 * @interface PhotosApiChangePhotoRequest
 */
export interface PhotosApiChangePhotoRequest {
    /**
     * 
     * @type {number}
     * @memberof PhotosApiChangePhoto
     */
    readonly userId: number

    /**
     * 
     * @type {any}
     * @memberof PhotosApiChangePhoto
     */
    readonly photo?: any
}

/**
 * PhotosApi - object-oriented interface
 * @export
 * @class PhotosApi
 * @extends {BaseAPI}
 */
export class PhotosApi extends BaseAPI {
    /**
     * 
     * @summary Breyta Mynd Notanda
     * @param {PhotosApiChangePhotoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PhotosApi
     */
    public changePhoto(requestParameters: PhotosApiChangePhotoRequest, options?: any) {
        return PhotosApiFp(this.configuration).changePhoto(requestParameters.userId, requestParameters.photo, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PrototypeApi - axios parameter creator
 * @export
 */
export const PrototypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Test Pdf
         * @param {string} [type] html / pdf
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profskirteini: async (type?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/worker/prototype/profskirteini`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAPIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PrototypeApi - functional programming interface
 * @export
 */
export const PrototypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PrototypeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Test Pdf
         * @param {string} [type] html / pdf
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profskirteini(type?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profskirteini(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PrototypeApi - factory interface
 * @export
 */
export const PrototypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PrototypeApiFp(configuration)
    return {
        /**
         * 
         * @summary Test Pdf
         * @param {string} [type] html / pdf
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profskirteini(type?: string, options?: any): AxiosPromise<string> {
            return localVarFp.profskirteini(type, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for profskirteini operation in PrototypeApi.
 * @export
 * @interface PrototypeApiProfskirteiniRequest
 */
export interface PrototypeApiProfskirteiniRequest {
    /**
     * html / pdf
     * @type {string}
     * @memberof PrototypeApiProfskirteini
     */
    readonly type?: string
}

/**
 * PrototypeApi - object-oriented interface
 * @export
 * @class PrototypeApi
 * @extends {BaseAPI}
 */
export class PrototypeApi extends BaseAPI {
    /**
     * 
     * @summary Test Pdf
     * @param {PrototypeApiProfskirteiniRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrototypeApi
     */
    public profskirteini(requestParameters: PrototypeApiProfskirteiniRequest = {}, options?: any) {
        return PrototypeApiFp(this.configuration).profskirteini(requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RequestsApi - axios parameter creator
 * @export
 */
export const RequestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update Change Course Request
         * @param {number} requestId 
         * @param {UpdateChangeCourseRequestInputItem} updateChangeCourseRequestInputItem 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChangeCourseRequest: async (requestId: number, updateChangeCourseRequestInputItem: UpdateChangeCourseRequestInputItem, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            assertParamExists('updateChangeCourseRequest', 'requestId', requestId)
            // verify required parameter 'updateChangeCourseRequestInputItem' is not null or undefined
            assertParamExists('updateChangeCourseRequest', 'updateChangeCourseRequestInputItem', updateChangeCourseRequestInputItem)
            const localVarPath = `/api/worker/requests/change-course-requests/{requestId}`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAPIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateChangeCourseRequestInputItem, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RequestsApi - functional programming interface
 * @export
 */
export const RequestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RequestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Update Change Course Request
         * @param {number} requestId 
         * @param {UpdateChangeCourseRequestInputItem} updateChangeCourseRequestInputItem 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateChangeCourseRequest(requestId: number, updateChangeCourseRequestInputItem: UpdateChangeCourseRequestInputItem, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateChangeCourseRequest(requestId, updateChangeCourseRequestInputItem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RequestsApi - factory interface
 * @export
 */
export const RequestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RequestsApiFp(configuration)
    return {
        /**
         * 
         * @summary Update Change Course Request
         * @param {number} requestId 
         * @param {UpdateChangeCourseRequestInputItem} updateChangeCourseRequestInputItem 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChangeCourseRequest(requestId: number, updateChangeCourseRequestInputItem: UpdateChangeCourseRequestInputItem, options?: any): AxiosPromise<object> {
            return localVarFp.updateChangeCourseRequest(requestId, updateChangeCourseRequestInputItem, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for updateChangeCourseRequest operation in RequestsApi.
 * @export
 * @interface RequestsApiUpdateChangeCourseRequestRequest
 */
export interface RequestsApiUpdateChangeCourseRequestRequest {
    /**
     * 
     * @type {number}
     * @memberof RequestsApiUpdateChangeCourseRequest
     */
    readonly requestId: number

    /**
     * 
     * @type {UpdateChangeCourseRequestInputItem}
     * @memberof RequestsApiUpdateChangeCourseRequest
     */
    readonly updateChangeCourseRequestInputItem: UpdateChangeCourseRequestInputItem
}

/**
 * RequestsApi - object-oriented interface
 * @export
 * @class RequestsApi
 * @extends {BaseAPI}
 */
export class RequestsApi extends BaseAPI {
    /**
     * 
     * @summary Update Change Course Request
     * @param {RequestsApiUpdateChangeCourseRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestsApi
     */
    public updateChangeCourseRequest(requestParameters: RequestsApiUpdateChangeCourseRequestRequest, options?: any) {
        return RequestsApiFp(this.configuration).updateChangeCourseRequest(requestParameters.requestId, requestParameters.updateChangeCourseRequestInputItem, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SchoolApi - axios parameter creator
 * @export
 */
export const SchoolApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Stofna Teams fund fyrir skóla hjá innskráðum notanda út frá stundatöflu.
         * @summary Timetable Create Teams Event
         * @param {number} id 
         * @param {TimetableCreateTeamsEventInput} timetableCreateTeamsEventInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timetableCreateTeamsEvent: async (id: number, timetableCreateTeamsEventInput: TimetableCreateTeamsEventInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('timetableCreateTeamsEvent', 'id', id)
            // verify required parameter 'timetableCreateTeamsEventInput' is not null or undefined
            assertParamExists('timetableCreateTeamsEvent', 'timetableCreateTeamsEventInput', timetableCreateTeamsEventInput)
            const localVarPath = `/api/worker/school/timetable/{id}/teams-event`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAPIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(timetableCreateTeamsEventInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Hætta við Teams fund fyrir skóla hjá innskráðum notanda út frá stundatöflu.
         * @summary Timetable Delete Teams Event
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timetableDeleteTeamsEvent: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('timetableDeleteTeamsEvent', 'id', id)
            const localVarPath = `/api/worker/school/timetable/{id}/teams-event`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAPIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sækja Teams fund fyrir skóla hjá innskráðum notanda út frá stundatöflu.
         * @summary Timetable Get Teams Event
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timetableGetTeamsEvent: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('timetableGetTeamsEvent', 'id', id)
            const localVarPath = `/api/worker/school/timetable/{id}/teams-event`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAPIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Athuga hvort Teams sé virkjað hjá skóla.
         * @summary Timetable Teams Enabled Get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timetableTeamsEnabled: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/worker/school/timetable/teams-enabled`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAPIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sækja lista yfir fundarherbergi úr Teams fyrir skóla hjá innskráðum notanda.
         * @summary Timetable Teams Room List
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timetableTeamsRoomList: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('timetableTeamsRoomList', 'id', id)
            const localVarPath = `/api/worker/school/timetable/{id}/teams-room-list`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAPIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Uppfæra Teams fund fyrir skóla hjá innskráðum notanda út frá stundatöflu.
         * @summary Timetable Update Teams Event
         * @param {number} id 
         * @param {TimetableCreateTeamsEventInput} timetableCreateTeamsEventInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timetableUpdateTeamsEvent: async (id: number, timetableCreateTeamsEventInput: TimetableCreateTeamsEventInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('timetableUpdateTeamsEvent', 'id', id)
            // verify required parameter 'timetableCreateTeamsEventInput' is not null or undefined
            assertParamExists('timetableUpdateTeamsEvent', 'timetableCreateTeamsEventInput', timetableCreateTeamsEventInput)
            const localVarPath = `/api/worker/school/timetable/{id}/teams-event`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAPIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(timetableCreateTeamsEventInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SchoolApi - functional programming interface
 * @export
 */
export const SchoolApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SchoolApiAxiosParamCreator(configuration)
    return {
        /**
         * Stofna Teams fund fyrir skóla hjá innskráðum notanda út frá stundatöflu.
         * @summary Timetable Create Teams Event
         * @param {number} id 
         * @param {TimetableCreateTeamsEventInput} timetableCreateTeamsEventInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timetableCreateTeamsEvent(id: number, timetableCreateTeamsEventInput: TimetableCreateTeamsEventInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timetableCreateTeamsEvent(id, timetableCreateTeamsEventInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Hætta við Teams fund fyrir skóla hjá innskráðum notanda út frá stundatöflu.
         * @summary Timetable Delete Teams Event
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timetableDeleteTeamsEvent(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timetableDeleteTeamsEvent(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sækja Teams fund fyrir skóla hjá innskráðum notanda út frá stundatöflu.
         * @summary Timetable Get Teams Event
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timetableGetTeamsEvent(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimetableGetTeamsEventResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timetableGetTeamsEvent(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Athuga hvort Teams sé virkjað hjá skóla.
         * @summary Timetable Teams Enabled Get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timetableTeamsEnabled(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimetableTeamsEnabledResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timetableTeamsEnabled(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sækja lista yfir fundarherbergi úr Teams fyrir skóla hjá innskráðum notanda.
         * @summary Timetable Teams Room List
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timetableTeamsRoomList(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TimetableTeamsRoomListResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timetableTeamsRoomList(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Uppfæra Teams fund fyrir skóla hjá innskráðum notanda út frá stundatöflu.
         * @summary Timetable Update Teams Event
         * @param {number} id 
         * @param {TimetableCreateTeamsEventInput} timetableCreateTeamsEventInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timetableUpdateTeamsEvent(id: number, timetableCreateTeamsEventInput: TimetableCreateTeamsEventInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timetableUpdateTeamsEvent(id, timetableCreateTeamsEventInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SchoolApi - factory interface
 * @export
 */
export const SchoolApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SchoolApiFp(configuration)
    return {
        /**
         * Stofna Teams fund fyrir skóla hjá innskráðum notanda út frá stundatöflu.
         * @summary Timetable Create Teams Event
         * @param {number} id 
         * @param {TimetableCreateTeamsEventInput} timetableCreateTeamsEventInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timetableCreateTeamsEvent(id: number, timetableCreateTeamsEventInput: TimetableCreateTeamsEventInput, options?: any): AxiosPromise<object> {
            return localVarFp.timetableCreateTeamsEvent(id, timetableCreateTeamsEventInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Hætta við Teams fund fyrir skóla hjá innskráðum notanda út frá stundatöflu.
         * @summary Timetable Delete Teams Event
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timetableDeleteTeamsEvent(id: number, options?: any): AxiosPromise<object> {
            return localVarFp.timetableDeleteTeamsEvent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Sækja Teams fund fyrir skóla hjá innskráðum notanda út frá stundatöflu.
         * @summary Timetable Get Teams Event
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timetableGetTeamsEvent(id: number, options?: any): AxiosPromise<TimetableGetTeamsEventResponse> {
            return localVarFp.timetableGetTeamsEvent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Athuga hvort Teams sé virkjað hjá skóla.
         * @summary Timetable Teams Enabled Get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timetableTeamsEnabled(options?: any): AxiosPromise<TimetableTeamsEnabledResponse> {
            return localVarFp.timetableTeamsEnabled(options).then((request) => request(axios, basePath));
        },
        /**
         * Sækja lista yfir fundarherbergi úr Teams fyrir skóla hjá innskráðum notanda.
         * @summary Timetable Teams Room List
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timetableTeamsRoomList(id: number, options?: any): AxiosPromise<Array<TimetableTeamsRoomListResponse>> {
            return localVarFp.timetableTeamsRoomList(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Uppfæra Teams fund fyrir skóla hjá innskráðum notanda út frá stundatöflu.
         * @summary Timetable Update Teams Event
         * @param {number} id 
         * @param {TimetableCreateTeamsEventInput} timetableCreateTeamsEventInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timetableUpdateTeamsEvent(id: number, timetableCreateTeamsEventInput: TimetableCreateTeamsEventInput, options?: any): AxiosPromise<object> {
            return localVarFp.timetableUpdateTeamsEvent(id, timetableCreateTeamsEventInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for timetableCreateTeamsEvent operation in SchoolApi.
 * @export
 * @interface SchoolApiTimetableCreateTeamsEventRequest
 */
export interface SchoolApiTimetableCreateTeamsEventRequest {
    /**
     * 
     * @type {number}
     * @memberof SchoolApiTimetableCreateTeamsEvent
     */
    readonly id: number

    /**
     * 
     * @type {TimetableCreateTeamsEventInput}
     * @memberof SchoolApiTimetableCreateTeamsEvent
     */
    readonly timetableCreateTeamsEventInput: TimetableCreateTeamsEventInput
}

/**
 * Request parameters for timetableDeleteTeamsEvent operation in SchoolApi.
 * @export
 * @interface SchoolApiTimetableDeleteTeamsEventRequest
 */
export interface SchoolApiTimetableDeleteTeamsEventRequest {
    /**
     * 
     * @type {number}
     * @memberof SchoolApiTimetableDeleteTeamsEvent
     */
    readonly id: number
}

/**
 * Request parameters for timetableGetTeamsEvent operation in SchoolApi.
 * @export
 * @interface SchoolApiTimetableGetTeamsEventRequest
 */
export interface SchoolApiTimetableGetTeamsEventRequest {
    /**
     * 
     * @type {number}
     * @memberof SchoolApiTimetableGetTeamsEvent
     */
    readonly id: number
}

/**
 * Request parameters for timetableTeamsRoomList operation in SchoolApi.
 * @export
 * @interface SchoolApiTimetableTeamsRoomListRequest
 */
export interface SchoolApiTimetableTeamsRoomListRequest {
    /**
     * 
     * @type {number}
     * @memberof SchoolApiTimetableTeamsRoomList
     */
    readonly id: number
}

/**
 * Request parameters for timetableUpdateTeamsEvent operation in SchoolApi.
 * @export
 * @interface SchoolApiTimetableUpdateTeamsEventRequest
 */
export interface SchoolApiTimetableUpdateTeamsEventRequest {
    /**
     * 
     * @type {number}
     * @memberof SchoolApiTimetableUpdateTeamsEvent
     */
    readonly id: number

    /**
     * 
     * @type {TimetableCreateTeamsEventInput}
     * @memberof SchoolApiTimetableUpdateTeamsEvent
     */
    readonly timetableCreateTeamsEventInput: TimetableCreateTeamsEventInput
}

/**
 * SchoolApi - object-oriented interface
 * @export
 * @class SchoolApi
 * @extends {BaseAPI}
 */
export class SchoolApi extends BaseAPI {
    /**
     * Stofna Teams fund fyrir skóla hjá innskráðum notanda út frá stundatöflu.
     * @summary Timetable Create Teams Event
     * @param {SchoolApiTimetableCreateTeamsEventRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchoolApi
     */
    public timetableCreateTeamsEvent(requestParameters: SchoolApiTimetableCreateTeamsEventRequest, options?: any) {
        return SchoolApiFp(this.configuration).timetableCreateTeamsEvent(requestParameters.id, requestParameters.timetableCreateTeamsEventInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Hætta við Teams fund fyrir skóla hjá innskráðum notanda út frá stundatöflu.
     * @summary Timetable Delete Teams Event
     * @param {SchoolApiTimetableDeleteTeamsEventRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchoolApi
     */
    public timetableDeleteTeamsEvent(requestParameters: SchoolApiTimetableDeleteTeamsEventRequest, options?: any) {
        return SchoolApiFp(this.configuration).timetableDeleteTeamsEvent(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sækja Teams fund fyrir skóla hjá innskráðum notanda út frá stundatöflu.
     * @summary Timetable Get Teams Event
     * @param {SchoolApiTimetableGetTeamsEventRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchoolApi
     */
    public timetableGetTeamsEvent(requestParameters: SchoolApiTimetableGetTeamsEventRequest, options?: any) {
        return SchoolApiFp(this.configuration).timetableGetTeamsEvent(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Athuga hvort Teams sé virkjað hjá skóla.
     * @summary Timetable Teams Enabled Get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchoolApi
     */
    public timetableTeamsEnabled(options?: any) {
        return SchoolApiFp(this.configuration).timetableTeamsEnabled(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sækja lista yfir fundarherbergi úr Teams fyrir skóla hjá innskráðum notanda.
     * @summary Timetable Teams Room List
     * @param {SchoolApiTimetableTeamsRoomListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchoolApi
     */
    public timetableTeamsRoomList(requestParameters: SchoolApiTimetableTeamsRoomListRequest, options?: any) {
        return SchoolApiFp(this.configuration).timetableTeamsRoomList(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Uppfæra Teams fund fyrir skóla hjá innskráðum notanda út frá stundatöflu.
     * @summary Timetable Update Teams Event
     * @param {SchoolApiTimetableUpdateTeamsEventRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchoolApi
     */
    public timetableUpdateTeamsEvent(requestParameters: SchoolApiTimetableUpdateTeamsEventRequest, options?: any) {
        return SchoolApiFp(this.configuration).timetableUpdateTeamsEvent(requestParameters.id, requestParameters.timetableCreateTeamsEventInput, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StudentsApi - axios parameter creator
 * @export
 */
export const StudentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Change Course Requirements
         * @param {ChangeCourseRequirementsInputItem} changeCourseRequirementsInputItem 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeCourseRequirements: async (changeCourseRequirementsInputItem: ChangeCourseRequirementsInputItem, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'changeCourseRequirementsInputItem' is not null or undefined
            assertParamExists('changeCourseRequirements', 'changeCourseRequirementsInputItem', changeCourseRequirementsInputItem)
            const localVarPath = `/api/worker/students/change-course-requirements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAPIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeCourseRequirementsInputItem, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deactivate Students
         * @param {DeactivateStudentsInputItem} deactivateStudentsInputItem 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateStudents: async (deactivateStudentsInputItem: DeactivateStudentsInputItem, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deactivateStudentsInputItem' is not null or undefined
            assertParamExists('deactivateStudents', 'deactivateStudentsInputItem', deactivateStudentsInputItem)
            const localVarPath = `/api/worker/students/deactivate-students`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAPIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deactivateStudentsInputItem, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Study Record By Course
         * @param {number} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStudyRecordByCourse: async (studentId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentId' is not null or undefined
            assertParamExists('updateStudyRecordByCourse', 'studentId', studentId)
            const localVarPath = `/api/worker/students/update-study-record-by-course/{studentId}`
                .replace(`{${"studentId"}}`, encodeURIComponent(String(studentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAPIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StudentsApi - functional programming interface
 * @export
 */
export const StudentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StudentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Change Course Requirements
         * @param {ChangeCourseRequirementsInputItem} changeCourseRequirementsInputItem 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeCourseRequirements(changeCourseRequirementsInputItem: ChangeCourseRequirementsInputItem, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeCourseRequirements(changeCourseRequirementsInputItem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deactivate Students
         * @param {DeactivateStudentsInputItem} deactivateStudentsInputItem 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateStudents(deactivateStudentsInputItem: DeactivateStudentsInputItem, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateStudents(deactivateStudentsInputItem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Study Record By Course
         * @param {number} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStudyRecordByCourse(studentId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStudyRecordByCourse(studentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StudentsApi - factory interface
 * @export
 */
export const StudentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StudentsApiFp(configuration)
    return {
        /**
         * 
         * @summary Change Course Requirements
         * @param {ChangeCourseRequirementsInputItem} changeCourseRequirementsInputItem 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeCourseRequirements(changeCourseRequirementsInputItem: ChangeCourseRequirementsInputItem, options?: any): AxiosPromise<object> {
            return localVarFp.changeCourseRequirements(changeCourseRequirementsInputItem, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deactivate Students
         * @param {DeactivateStudentsInputItem} deactivateStudentsInputItem 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateStudents(deactivateStudentsInputItem: DeactivateStudentsInputItem, options?: any): AxiosPromise<object> {
            return localVarFp.deactivateStudents(deactivateStudentsInputItem, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Study Record By Course
         * @param {number} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStudyRecordByCourse(studentId: number, options?: any): AxiosPromise<object> {
            return localVarFp.updateStudyRecordByCourse(studentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for changeCourseRequirements operation in StudentsApi.
 * @export
 * @interface StudentsApiChangeCourseRequirementsRequest
 */
export interface StudentsApiChangeCourseRequirementsRequest {
    /**
     * 
     * @type {ChangeCourseRequirementsInputItem}
     * @memberof StudentsApiChangeCourseRequirements
     */
    readonly changeCourseRequirementsInputItem: ChangeCourseRequirementsInputItem
}

/**
 * Request parameters for deactivateStudents operation in StudentsApi.
 * @export
 * @interface StudentsApiDeactivateStudentsRequest
 */
export interface StudentsApiDeactivateStudentsRequest {
    /**
     * 
     * @type {DeactivateStudentsInputItem}
     * @memberof StudentsApiDeactivateStudents
     */
    readonly deactivateStudentsInputItem: DeactivateStudentsInputItem
}

/**
 * Request parameters for updateStudyRecordByCourse operation in StudentsApi.
 * @export
 * @interface StudentsApiUpdateStudyRecordByCourseRequest
 */
export interface StudentsApiUpdateStudyRecordByCourseRequest {
    /**
     * 
     * @type {number}
     * @memberof StudentsApiUpdateStudyRecordByCourse
     */
    readonly studentId: number
}

/**
 * StudentsApi - object-oriented interface
 * @export
 * @class StudentsApi
 * @extends {BaseAPI}
 */
export class StudentsApi extends BaseAPI {
    /**
     * 
     * @summary Change Course Requirements
     * @param {StudentsApiChangeCourseRequirementsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public changeCourseRequirements(requestParameters: StudentsApiChangeCourseRequirementsRequest, options?: any) {
        return StudentsApiFp(this.configuration).changeCourseRequirements(requestParameters.changeCourseRequirementsInputItem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deactivate Students
     * @param {StudentsApiDeactivateStudentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public deactivateStudents(requestParameters: StudentsApiDeactivateStudentsRequest, options?: any) {
        return StudentsApiFp(this.configuration).deactivateStudents(requestParameters.deactivateStudentsInputItem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Study Record By Course
     * @param {StudentsApiUpdateStudyRecordByCourseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentsApi
     */
    public updateStudyRecordByCourse(requestParameters: StudentsApiUpdateStudyRecordByCourseRequest, options?: any) {
        return StudentsApiFp(this.configuration).updateStudyRecordByCourse(requestParameters.studentId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TermsApi - axios parameter creator
 * @export
 */
export const TermsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Setja í gang runuvinnslu til að reikna lokaeinkunnir.
         * @summary Calculate Final Grades
         * @param {number} termId 
         * @param {number} inputType 
         * @param {CalculateFinalGradesInputItem} calculateFinalGradesInputItem 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateFinalGrades: async (termId: number, inputType: number, calculateFinalGradesInputItem: CalculateFinalGradesInputItem, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'termId' is not null or undefined
            assertParamExists('calculateFinalGrades', 'termId', termId)
            // verify required parameter 'inputType' is not null or undefined
            assertParamExists('calculateFinalGrades', 'inputType', inputType)
            // verify required parameter 'calculateFinalGradesInputItem' is not null or undefined
            assertParamExists('calculateFinalGrades', 'calculateFinalGradesInputItem', calculateFinalGradesInputItem)
            const localVarPath = `/api/worker/terms/{termId}/calculate-final-grades/{inputType}`
                .replace(`{${"termId"}}`, encodeURIComponent(String(termId)))
                .replace(`{${"inputType"}}`, encodeURIComponent(String(inputType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAPIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calculateFinalGradesInputItem, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TermsApi - functional programming interface
 * @export
 */
export const TermsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TermsApiAxiosParamCreator(configuration)
    return {
        /**
         * Setja í gang runuvinnslu til að reikna lokaeinkunnir.
         * @summary Calculate Final Grades
         * @param {number} termId 
         * @param {number} inputType 
         * @param {CalculateFinalGradesInputItem} calculateFinalGradesInputItem 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateFinalGrades(termId: number, inputType: number, calculateFinalGradesInputItem: CalculateFinalGradesInputItem, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculateFinalGrades(termId, inputType, calculateFinalGradesInputItem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TermsApi - factory interface
 * @export
 */
export const TermsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TermsApiFp(configuration)
    return {
        /**
         * Setja í gang runuvinnslu til að reikna lokaeinkunnir.
         * @summary Calculate Final Grades
         * @param {number} termId 
         * @param {number} inputType 
         * @param {CalculateFinalGradesInputItem} calculateFinalGradesInputItem 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateFinalGrades(termId: number, inputType: number, calculateFinalGradesInputItem: CalculateFinalGradesInputItem, options?: any): AxiosPromise<object> {
            return localVarFp.calculateFinalGrades(termId, inputType, calculateFinalGradesInputItem, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for calculateFinalGrades operation in TermsApi.
 * @export
 * @interface TermsApiCalculateFinalGradesRequest
 */
export interface TermsApiCalculateFinalGradesRequest {
    /**
     * 
     * @type {number}
     * @memberof TermsApiCalculateFinalGrades
     */
    readonly termId: number

    /**
     * 
     * @type {number}
     * @memberof TermsApiCalculateFinalGrades
     */
    readonly inputType: number

    /**
     * 
     * @type {CalculateFinalGradesInputItem}
     * @memberof TermsApiCalculateFinalGrades
     */
    readonly calculateFinalGradesInputItem: CalculateFinalGradesInputItem
}

/**
 * TermsApi - object-oriented interface
 * @export
 * @class TermsApi
 * @extends {BaseAPI}
 */
export class TermsApi extends BaseAPI {
    /**
     * Setja í gang runuvinnslu til að reikna lokaeinkunnir.
     * @summary Calculate Final Grades
     * @param {TermsApiCalculateFinalGradesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TermsApi
     */
    public calculateFinalGrades(requestParameters: TermsApiCalculateFinalGradesRequest, options?: any) {
        return TermsApiFp(this.configuration).calculateFinalGrades(requestParameters.termId, requestParameters.inputType, requestParameters.calculateFinalGradesInputItem, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UploadApi - axios parameter creator
 * @export
 */
export const UploadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Upload Curriculum
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadCurriculum: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/worker/upload/upload-curriculum`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAPIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "token", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UploadApi - functional programming interface
 * @export
 */
export const UploadApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UploadApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Upload Curriculum
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadCurriculum(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadCurriculum(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UploadApi - factory interface
 * @export
 */
export const UploadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UploadApiFp(configuration)
    return {
        /**
         * 
         * @summary Upload Curriculum
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadCurriculum(options?: any): AxiosPromise<object> {
            return localVarFp.uploadCurriculum(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UploadApi - object-oriented interface
 * @export
 * @class UploadApi
 * @extends {BaseAPI}
 */
export class UploadApi extends BaseAPI {
    /**
     * 
     * @summary Upload Curriculum
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public uploadCurriculum(options?: any) {
        return UploadApiFp(this.configuration).uploadCurriculum(options).then((request) => request(this.axios, this.basePath));
    }
}


