<template>
  <page>
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <PageTitle :title="`Töflubreytingar áfanga - ${moduleInfo ? moduleInfo.namsgrein_afanganr : ''}`" />
    <Loader v-if="loading" />
    <b-row>
      <b-col xs="12" sm="12" md="6">
        <div v-if="dateFrom || dateTo">
          <div v-if="filterByDate" class="alert alert-info p-2 mb-0">
            <i class="fa fa-exclamation-triangle"></i> Verið er að sía beiðnir eftir
            <span v-if="dateFrom">dags. frá: {{ dateFrom | moment('DD.MM.YYYY') }} <span v-if="dateTo"> og </span></span>
            <span v-if="dateTo">dags. til: {{ dateTo | moment('DD.MM.YYYY') }}</span>
            <div><b-link @click="filterByDate = false">Smelltu hér til að sjá beiðnir óháð dagsetningum</b-link></div>
          </div>
          <div v-else class="alert alert-info p-2 mb-0">
            <div><b-link @click="filterByDate = true">Smelltu hér til að sía beiðnir aftur eftir dagsetningum</b-link></div>
          </div>
        </div>
        <h5 v-if="!loading">Óafgreiddar töflubreytingar</h5>
        <div v-if="getList.length === 0 && !loading">Engar óafgreiddar töflubreytingar</div>
        <table class="table table-hover no-border less-padding" v-if="getList.length > 0" style="font-size: 11px">
          <thead>
            <tr>
              <th class="text-center pl-0"><i class="fa fa-fw fa-check text-success" v-b-tooltip title="Samþykkja"></i></th>
              <th class="text-center"><i class="fa fa-fw fa-remove text-danger" v-b-tooltip title="Hafna"></i></th>
              <th><LinkOrder title="Aðgerð" :filteredList="getList" :list="list" column="adgerd" /></th>
              <th class="text-center"><LinkOrder title="Hópur" :filteredList="getList" :list="list" column="hopur" /></th>
              <th class="text-center"><i class="fa fa-fw fa-users" aria-hidden="true" v-b-tooltip title="Fjöldi nemenda í hópnum"></i></th>
              <th class="text-center">
                <i
                  class="fa fa-fw fa-plus-circle text-success"
                  aria-hidden="true"
                  v-b-tooltip
                  title="Fjöldi óafgreiddra beiðna um að bæta áfanganum við"
                ></i>
              </th>
              <th class="text-center">
                <i
                  class="fa fa-fw fa-trash text-danger"
                  aria-hidden="true"
                  v-b-tooltip
                  title="Fjöldi óafgreiddra beiðna um að fjarlægja áfangann"
                ></i>
              </th>
              <th><LinkOrder title="Nafn" :filteredList="getList" :list="list" column="nafn" /></th>
              <th><LinkOrder title="Kennitala" :filteredList="getList" :list="list" column="kennitala" /></th>
              <th><LinkOrder title="Staða" :filteredList="getList" :list="list" column="stada_nema" /></th>
              <th><LinkOrder title="Braut" :filteredList="list" :getList="list" column="braut_nema" /></th>
              <th><LinkOrder title="Dags.skráð" :filteredList="getList" :list="list" column="dags_sort" /></th>
            </tr>
          </thead>
          <tbody v-for="(item, index) in getList" :key="index" style="border: 0">
            <tr>
              <td class="text-center"><b-form-checkbox v-model="item.confirm" @input="changeSelection(item, 0)" :disabled="!canEdit" /></td>
              <td class="text-center"><b-form-checkbox v-model="item.reject" @input="changeSelection(item, 1)" :disabled="!canEdit" /></td>
              <td class="text-center">
                <i
                  class="fa fa-fw"
                  :class="{ 'fa-trash text-danger': item.adgerd === 0, 'fa-plus-circle text-success': item.adgerd === 1 }"
                ></i>
                <i
                  class="fa fa-fw fa-info-circle"
                  :class="{ invisible: !item.skilabod_nemandi }"
                  v-b-popover.hover.top="item.skilabod_nemandi"
                  title="Athugasemd nemanda"
                ></i>
              </td>
              <td class="text-center">
                <div
                  class="d-inline-block cursor-pointer"
                  v-b-tooltip
                  title="Skoða hóp"
                  @click="openGroupSlidebar(item)"
                  @mouseenter="onHover(item, 1)"
                  @mouseleave="onHover(item, 0)"
                >
                  {{ item.hopur }}
                </div>
              </td>
              <td class="text-center" :class="{ 'text-danger': item.max_fj_nema < item.fjoldi_hop }">
                {{ item.fjoldi_hop }}
                <i
                  class="fa fa-exclamation-triangle text-danger"
                  :title="`Hámarksfjölda í hóp er náð! Skilgreint hámark er ${item.max_fj_nema}`"
                  v-b-tooltip
                  v-if="item.max_fj_nema < item.fjoldi_hop"
                ></i>
              </td>
              <td class="text-center">{{ item.fjoldi_i_hop }}</td>
              <td class="text-center">{{ item.fjoldi_ur_hop }}</td>
              <td>
                <!-- ng-mouseenter="vm.getStudentTimetable(l)" ng-mouseleave="vm.clearStudentTimetable()" -->
                <div @mouseenter="loadStudent(item)" @mouseleave="clearStudent(item)">
                  <b-link
                    :to="{ name: 'StudentRequests', params: { student: item.nemandi_id, term: item.onn_id } }"
                    title="Skoða nemendabeiðnir"
                    v-b-tooltip
                  >
                    {{ item.nafn }}
                  </b-link>
                  <b-link
                    :to="{ name: 'Student', params: { id: item.nemandi_id } }"
                    target="_blank"
                    v-if="canViewStudent"
                    title="Skoða nemanda"
                    v-b-tooltip
                    ><i class="fa fa-external-link"></i
                  ></b-link>
                </div>
              </td>
              <td>{{ item.kennitala }}</td>
              <td :title="item.stada_nema" v-b-tooltip>
                {{ item.stada_nema.length > 6 ? `${item.stada_nema.substring(0, 6)}..` : item.stada_nema }}
              </td>
              <td>{{ item.braut_nema }}</td>
              <td>
                {{ item.dags | moment('DD.MM.YYYY HH:mm') }}
                <b-btn
                  variant="primary"
                  class="float-right btn-xs"
                  title="Skoða námsferil"
                  @click="openStudyRecordSlidebar(item)"
                  v-b-tooltip.hover
                  style="font-size: 10px"
                  ><i class="fa fa-list"></i
                ></b-btn>
              </td>
            </tr>
          </tbody>
        </table>
        <b-btn variant="primary" class="mb-4" v-if="getList.length > 0 && !loading && canEdit" @click="processRequests()">Staðfesta</b-btn>
        <br />
        <h5 v-if="!loading">Afgreiddar töflubreytingar</h5>
        <div v-if="getOld.length == 0 && !loading"><i translate>Engar afgreiddar töflubreytingar</i></div>
        <table class="table table-hover no-border less-padding" v-if="getOld.length > 0" style="font-size: 11px">
          <thead>
            <tr>
              <th class="pl-0">Aðgerð</th>
              <th class="text-center">Hópur</th>
              <th class="text-center"><i class="fa fa-fw fa-users" aria-hidden="true" v-b-tooltip title="Fjöldi nemenda í hópnum"></i></th>
              <th class="text-center">
                <i
                  class="fa fa-fw fa-plus-circle text-success"
                  aria-hidden="true"
                  v-b-tooltip
                  title="Fjöldi beiðna um að bæta áfanganum við"
                ></i>
              </th>
              <th class="text-center">
                <i class="fa fa-fw fa-trash text-danger" aria-hidden="true" v-b-tooltip title="Fjöldi beiðna um að fjarlægja áfangann"></i>
              </th>
              <th>Nafn</th>
              <th>Kennitala</th>
              <th>Staða nema</th>
              <th>Braut</th>
              <th class="text-center">Staða</th>
              <th class="text-center">Aths.</th>
            </tr>
          </thead>
          <tbody v-for="(item, index) in getOld" :key="index" style="border: 0">
            <tr>
              <td>
                <i
                  class="fa fa-fw"
                  :class="{ 'fa-trash text-danger': item.adgerd === 0, 'fa-plus-circle text-success': item.adgerd === 1 }"
                ></i>
                <i
                  class="fa fa-fw fa-info-circle"
                  :class="{ invisible: !item.skilabod_nemandi }"
                  v-b-popover.hover.top="item.skilabod_nemandi"
                  title="Athugasemd nemanda"
                ></i>
              </td>
              <td>
                <div class="d-inline-block cursor-pointer" v-b-tooltip title="Skoða hóp" @click="openGroupSlidebar(item)">
                  {{ item.hopur }}
                </div>
              </td>
              <td class="text-center">{{ item.fjoldi_hop }}</td>
              <td class="text-center">{{ item.fjoldi_i_hop }}</td>
              <td class="text-center">{{ item.fjoldi_ur_hop }}</td>
              <td>
                <b-link
                  :to="{ name: 'StudentRequests', params: { student: item.nemandi_id, term: item.onn_id } }"
                  title="Skoða nemendabeiðnir"
                  v-b-tooltip
                >
                  {{ item.nafn }}
                </b-link>
              </td>
              <td>{{ item.kennitala }}</td>
              <td>{{ item.stada_nema }}</td>
              <td>{{ item.braut_nema }}</td>
              <td class="text-center">
                <i class="fa fa-fw" :class="{ 'fa-remove text-danger': item.stada === 0, 'fa-check text-success': item.stada === 1 }"></i>
              </td>
              <td>
                <div v-b-popover.hover.top="item.skilabod_stm">
                  {{ item.skilabod_stm ? item.skilabod_stm.substring(0, 5) : '' }}
                  <span v-if="item.skilabod_stm && item.skilabod_stm.length > 5">...</span>
                  <i class="fa fa-fw fa-pencil cursor-pointer" @click="commentModal(item)" v-if="canEdit"></i>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="4">Afgreitt: {{ item.afgreitt_dags | moment('DD.MM.YYYY HH:mm') }}</td>
              <td colspan="4">Afgreitt af: {{ item.afgreitt_nafn | moment('DD.MM.YYYY HH:mm') }}</td>
              <td colspan="3">Skráð: {{ item.dags | moment('DD.MM.YYYY HH:mm') }}</td>
            </tr>
          </tbody>
        </table>
      </b-col>
      <b-col xs="12" sm="12" md="6">
        <div class="float-left w-100">
          <b-btn variant="primary" class="float-right mb-2" size="sm" @click="getBack()">Snúa aftur í leit</b-btn>
        </div>
        <div v-if="currentDates.length > 1">
          Flýtileið á dagsetningar
          <i
            class="fa fa-info-circle"
            title="Upphafsdagsetning virkra hópa er ekki alltaf sú sama. Ef smellt er á dagsetninguna þá birtist stundataflan fyrir þá viku"
            v-b-tooltip
          ></i>
          :
          <a v-for="d in currentDates" :key="d" @click="selectDate(d)" class="cursor-pointer pr-3">{{ d | moment('DD.MM.YYYY') }}</a>
        </div>
        <Timetable
          :defaultGroups="defaultGroups"
          :showHover="showHoverGroup"
          :addGroups="addGroups"
          :removeHover="removeHoverGroup"
          :goToDate="selectedGroupDate"
        />
      </b-col>
    </b-row>
    <view-group-slidebar v-if="showGroupSlidebar" :item="selectedItem" @closeSlidebar="closeSlidebar"> </view-group-slidebar>
    <comment-modal ref="commentModal" @successful="loadData"></comment-modal>
    <term-slidebar
      :termId="selectedItem ? selectedItem.onn_id : ''"
      :studentId="selectedItem ? selectedItem.nemandi_id : ''"
      v-if="showStudyRecordSlidebar"
      @closeSlidebar="closeSlidebar"
    ></term-slidebar>
  </page>
</template>

<script>
import moment from 'moment';
import students from '@/api/students';
import school from '@/api/school';
import terms from '@/api/terms';
import requests from '@/api/requests';
import { mapGetters, mapActions } from 'vuex';

import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import PageTitle from '@/components/common/PageTitle.vue';
import Loader from '@/components/common/Loader.vue';
import Timetable from '@/components/requests/changeTimetableRequests/Timetable.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';

import ViewGroupSlidebar from '@/components/requests/changeTimetableRequests/ViewGroupSlidebar.vue';
import CommentModal from '@/components/requests/changeTimetableRequests/CommentModal.vue';
import TermSlidebar from '@/components/students/studyRecords/terms/TermSlidebar.vue';

export default {
  name: 'module-requests',
  components: {
    Breadcrumbs,
    PageTitle,
    Loader,
    Timetable,
    ViewGroupSlidebar,
    CommentModal,
    LinkOrder,
    TermSlidebar,
  },
  computed: {
    defaultGroups() {
      return this.moduleGroups.map(x => x.hopur_id);
    },
    addGroups() {
      return this.selectedStudent
        ? this.toAdd.map(x => x.hopur_id).concat(this.toAdd.filter(x => x.yfirhopur && x.yfirhopur > 0).map(x => x.hopur_id))
        : [];
    },
    getList() {
      return this.list
        .filter(
          x => !this.filterByDate || !this.dateFrom || moment(x.dags_fra).format('YYYYMMDD') === moment(this.dateFrom).format('YYYYMMDD'),
        )
        .filter(
          x => !this.filterByDate || !this.dateTo || moment(x.dags_til).format('YYYYMMDD') === moment(this.dateTo).format('YYYYMMDD'),
        );
    },
    getOld() {
      return this.old
        .filter(
          x => !this.filterByDate || !this.dateFrom || moment(x.dags_fra).format('YYYYMMDD') === moment(this.dateFrom).format('YYYYMMDD'),
        )
        .filter(
          x => !this.filterByDate || !this.dateTo || moment(x.dags_til).format('YYYYMMDD') === moment(this.dateTo).format('YYYYMMDD'),
        );
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('beidnir_toflubreytingar');
    },
    canViewStudent() {
      return this.loggedInUserHasWritePermission('nemendahald_nemendur');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      breadcrumbs: [
        { name: 'Forsíða', route: 'Dashboard' },
        { name: 'Beiðnir', route: '' },
        { name: 'Úrvinnsla töflubreytinga', route: 'ChangeTimetableRequests' },
        { name: 'Töflubreytingar áfanga', route: 'ModuleRequests' },
      ],
      moduleInfo: '',
      moduleTermId: null,
      loading: false,
      loadingStudent: false,
      list: [],
      old: [],
      moduleGroups: [],
      toAdd: [],
      selectedStudent: '',
      showGroupSlidebar: false,
      showStudyRecordSlidebar: false,
      selectedItem: null,
      showHoverGroup: '',
      removeHoverGroup: '',
      dateFrom: '',
      dateTo: '',
      filterByDate: true,
      currentDates: [],
      selectedGroupDate: '',
    };
  },
  methods: {
    getBack() {
      this.$router.push({ name: 'ChangeTimetableRequests', query: JSON.parse(this.$route.query.query) });
    },
    async loadModuleInfo() {
      try {
        const response = await terms.getTermModule(this.moduleTermId);
        this.moduleInfo = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadData() {
      try {
        this.loading = true;
        const response = await requests.getTimetableRequestsModule(this.moduleTermId);
        this.list = response.data.items
          .filter(x => x.stada === null)
          .map(x => ({
            ...x,
            dags_sort: moment(x.dags).format('YYYYMMDDHHmmss'),
          }));
        this.old = response.data.items.filter(x => x.stada !== null);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadGroups() {
      try {
        this.moduleGroups = [];
        this.loading = true;
        const response = await school.getTermModulesGroups(this.moduleTermId); // eslint-disable-line
        this.moduleGroups = response.data.items;

        this.currentDates = Array.from(new Set(this.moduleGroups.map(x => x.dags_fra))).sort((a, b) => {
          return moment(a, 'DD.MM.YYYY').valueOf() - moment(b, 'DD.MM.YYYY').valueOf();
        });
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadStudent(student) {
      try {
        this.toAdd = [];
        this.loadingStudent = true;
        this.selectedStudent = student.nemandi_id;
        const response = await students.getStudentModuleSubgroups(student.onn_id, student.nemandi_id);
        this.toAdd = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loadingStudent = false;
      }
    },
    clearStudent() {
      this.selectedStudent = '';
      this.toAdd = [];
    },
    async processRequests() {
      try {
        for (let i = 0; i < this.getList.length; i += 1) {
          if (this.getList[i].confirm) {
            await requests.processTimetableRequestStatus(this.getList[i].nemandi_toflubreytingar_id, { // eslint-disable-line
              status: 1,
            });
          }
          if (this.getList[i].reject) {
            await requests.processTimetableRequestStatus(this.getList[i].nemandi_toflubreytingar_id, { // eslint-disable-line
              status: 0,
            });
          }
        }
        this.displaySuccess('Töflubreytingar afgreiddar');
        this.loadData();
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    onHover(item, type) {
      const name = `${item.namsgrein_afanganr} (${item.hopur})`;
      if (type === 1) {
        this.showHoverGroup = name;
        this.$nextTick(() => {
          this.showHoverGroup = '';
        });
      } else if (type === 0) {
        this.removeHoverGroup = name;
        this.$nextTick(() => {
          this.removeHoverGroup = '';
        });
      }
    },
    changeSelection(item, type) {
      if (type === 0 && item.confirm) {
        this.$set(item, 'reject', false);
      } else if (type === 1 && item.reject) {
        this.$set(item, 'confirm', false);
      }
    },
    openGroupSlidebar(item) {
      this.selectedItem = item;
      this.showGroupSlidebar = true;
      this.showStudyRecordSlidebar = false;
    },
    openStudyRecordSlidebar(item) {
      this.selectedItem = item;
      this.showStudyRecordSlidebar = true;
      this.showGroupSlidebar = false;
    },
    closeSlidebar() {
      this.showGroupSlidebar = false;
      this.showStudyRecordSlidebar = false;
      this.selectedItem = null;
    },
    selectDate(date) {
      let selectedDate;
      if (moment(date).isoWeekday() === 1) {
        selectedDate = moment(date).format('DD.MM.YYYY');
      } else {
        selectedDate = moment(date).startOf('isoWeek').add(1, 'week').format('DD.MM.YYYY');
      }
      this.selectedGroupDate = moment(selectedDate, 'DD.MM.YYYY');
    },
    /*
     * Modal gluggar
     */
    commentModal(item) {
      this.$refs.commentModal.show(item);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.moduleTermId = this.$route.params.moduleTermId ? this.$route.params.moduleTermId : null;
    if (this.$route.query.dateFrom && this.$route.query.dateFrom !== '') {
      this.dateFrom = moment(this.$route.query.dateFrom, 'DD.MM.YYYY');
    }
    if (this.$route.query.dateTo && this.$route.query.dateTo !== '') {
      this.dateTo = moment(this.$route.query.dateTo, 'DD.MM.YYYY');
    }
    this.loadModuleInfo();
    this.loadData();
    this.loadGroups();
  },
};
</script>
