<template>
  <div>
    <b-row>
      <b-col class="pl-0">
        <b-form-group>
          Skoða eftir:
          <b-form-radio-group id="showBy" v-model="showBooks" @input="loadList()" name="showBy" class="pl-2 d-inline-block">
            <b-form-radio :value="false">Áföngum</b-form-radio>
            <b-form-radio :value="true">Námsgögnum</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
      <b-col>
        <download-csv
          v-if="!loader"
          :data="getExcelDataAll"
          :name="getExcelFileNameAll"
          delimiter=";"
          class="float-right"
          style="margin-top: -10px"
          title="Sækja námsgögn allra áfanga"
        >
          <CustomButton type="excel"/>
        </download-csv>
        <b-btn
          variant="primary"
          title="Afrita/eyða námsgagnalista"
          v-b-tooltip
          v-if="canEdit"
          @click="openCopySlidebar()"
          class="float-right mr-2"
          style="margin-top: -10px; margin-bottom: -20px; height: 35px"
          ><i class="fa fa-copy"></i
        ></b-btn>
      </b-col>
    </b-row>
    <table class="table table-hover table-sticky-header less-padding" style="font-size: 13px" v-if="showBooks">
      <thead>
        <tr class="table-detail-search">
          <td style="padding-top: 0; padding-left: 0px">
            <b-form-input v-model="search.name" class="search" placeholder="Heiti" />
          </td>
          <td style="padding-top: 0; padding-left: 0px">
            <b-form-input v-model="search.isbn" class="search" placeholder="ISBN" />
          </td>
          <td colspan="2" style="padding-top: 0; padding-left: 0px">
            <v-select class="v-select-form-control" v-model="search.category" placeholder="Flokkur" :options="categories" label="heiti">
            </v-select>
          </td>
          <td colspan="3">
            <v-select
              id="bookDepartmentSearch"
              name="department"
              v-model="search.department"
              :options="getDepartments"
              :clearable="true"
              placeholder="Deild"
              @input="loadList()"
            >
            </v-select>
          </td>
        </tr>
        <tr>
          <th><LinkOrder title="Heiti" :filteredList="getList" :list="list" column="heiti" /> ({{ this.getList.length }})</th>
          <th>
            <LinkOrder title="Höfundur" :filteredList="getList" :list="list" column="hofundur" secondColumn="heiti" />
          </th>
          <th>
            <LinkOrder title="Útgáfuár" :filteredList="getList" :list="list" column="utgafa" secondColumn="heiti" />
          </th>
          <th>
            <LinkOrder title="Útgefandi" :filteredList="getList" :list="list" column="utgefandi" secondColumn="heiti" />
          </th>
          <th>
            <LinkOrder title="Flokkur" :filteredList="getList" :list="list" column="flokkur_heiti" secondColumn="heiti" />
          </th>
          <th>
            <LinkOrder title="Fj. áfanga" :filteredList="getList" :list="list" column="fj_afanga" secondColumn="heiti" />
          </th>
          <th>
            <LinkOrder title="Fj. nemenda" :filteredList="getList" :list="list" column="fj_nema" secondColumn="heiti" />
            <i class="fa fa-info-circle" title="Fjöldi nemenda með áfanga í ferli óháð stöðu" v-b-tooltip></i>
          </th>
          <th>
            <LinkOrder title="ISBN - númer" :filteredList="getList" :list="list" column="isbn" secondColumn="heiti" />
            <download-csv v-if="!loader" :data="getExcelData" :name="getExcelFileName" delimiter=";" class="float-right cursor-pointer">
              <i class="fa fa-file-excel-o excelText"></i>
            </download-csv>
          </th>
        </tr>
      </thead>
      <tbody>
        <TableRowLoader v-if="loader" colspan="8" :center="true" />
        <TableRowEmpty v-if="!loader && this.list.length === 0" colspan="8" :center="true" />
        <tr v-for="(item, idx) in getPaginatedList" :key="idx">
          <td width="35%">
            <span :title="item.heiti" v-b-tooltip>
              {{ item.heiti && item.heiti.length > 65 ? `${item.heiti.substring(0, 65)}..` : item.heiti }}</span
            >
            <b-link
              v-if="canEditStructure"
              style="font-size: 13px"
              title="Breyta námsgagni"
              v-b-tooltip
              class="pl-2"
              @click="openEditBookSlidebar(item)"
              ><i class="fa fa-pencil"></i
            ></b-link>
          </td>
          <td :title="item.hofundur" v-b-tooltip>
            {{ item.hofundur && item.hofundur.length > 30 ? `${item.hofundur.substring(0, 30)}..` : item.hofundur }}
          </td>
          <td>{{ item.utgafa }}</td>
          <td>{{ item.utgefandi }}</td>
          <td>{{ item.flokkur_heiti }}</td>
          <td>
            {{ item.fj_afanga }}
            <i class="fa fa-search text-primary cursor-pointer" @click.prevent="openBookUsageSlidebar(id, item.bok_id, null)"> </i>
          </td>
          <td>{{ item.fj_nem }}</td>
          <td>{{ item.isbn }}</td>
        </tr>
      </tbody>
    </table>
    <table class="table table-fixed table-sticky-header table-hover less-padding" style="font-size: 13px" v-if="!showBooks">
      <thead>
        <tr class="table-detail-search">
          <td style="padding-top: 0; padding-left: 0px">
            <b-form-input v-model="search.module" class="search" placeholder="Áfangi" />
          </td>
          <td style="padding-top: 0; padding-left: 0px">
            <v-select
              id="bookDepartmentSearch"
              name="department"
              v-model="search.department"
              :options="getDepartments"
              :clearable="true"
              placeholder="Deild"
            >
            </v-select>
          </td>
          <td colspan="2">
            Eingöngu án námsgagna
            <b-form-checkbox v-model="search.onlyNone"></b-form-checkbox>
          </td>
        </tr>
        <tr>
          <th><LinkOrder title="Áfangi" :filteredList="getList" :list="list" column="namsgrein_afanganr" /> ({{ this.getList.length }})</th>
          <th><LinkOrder title="Deild" :filteredList="getList" :list="list" column="deild" /></th>
          <th>
            <LinkOrder title="Fj. námsgagna" :filteredList="getList" :list="list" column="fj_boka" secondColumn="namsgrein_afanganr" />
          </th>
          <th>
            <LinkOrder title="Fj. nemenda" :filteredList="getList" :list="list" column="fj_nemenda" secondColumn="namsgrein_afanganr" />
            <i class="fa fa-info-circle" title="Fjöldi nemenda með áfanga í ferli óháð stöðu" v-b-tooltip></i>
          </th>
          <th>
            <LinkOrder
              title="Seinast bætt við"
              :filteredList="getList"
              :list="list"
              column="max_time_create"
              secondColumn="namsgrein_afanganr"
            />
            <i class="fa fa-info-circle" title="Hvenær nýjasta námsgagnið sem er skráð á áfangann var skráð." v-b-tooltip></i>
          </th>
          <th>
            <LinkOrder
              title="Bætt við af"
              :filteredList="getList"
              :list="list"
              column="max_person_create"
              secondColumn="namsgrein_afanganr"
            />
            <i class="fa fa-info-circle" title="Af hverjum nýjasta námsgagnið sem er skráð á áfangann var skráð." v-b-tooltip></i>
            <download-csv v-if="!loader" :data="getExcelData" :name="getExcelFileName" delimiter=";" class="float-right cursor-pointer">
              <i class="fa fa-file-excel-o excelText"></i>
            </download-csv>
          </th>
        </tr>
      </thead>
      <tbody>
        <TableRowLoader v-if="loader" colspan="3" :center="true" />
        <TableRowEmpty v-if="!loader && this.list.length === 0" colspan="3" :center="true" />
        <tr v-for="(item, idx) in getPaginatedList" :key="idx">
          <td>
            {{ item.namsgrein_afanganr }}
          </td>
          <td>
            {{ item.deild }}
          </td>
          <td>
            {{ item.fj_boka }}
            <i class="fa fa-search text-primary cursor-pointer" @click.prevent="openBookUsageSlidebar(id, null, item.afangi_id)"> </i>
          </td>
          <td>{{ item.fj_nemenda }}</td>
          <td>{{ item.max_time_create | moment('DD.MM.YYYY HH:mm:ss') }}</td>
          <td>{{ item.max_person_create }}</td>
        </tr>
      </tbody>
    </table>
    <Pagination
      :currentPage="currentPage"
      :totalRows="getList.length"
      :perPage="perPage"
      v-if="getList.length > 20"
      @change-page="changePage"
      @change-limit="changeLimit"
    ></Pagination>
    <book-usage-slidebar
      v-if="showUsageSlidebar"
      :large="true"
      :termId="search.termId"
      :moduleId="search.moduleId"
      :bookId="search.bookId"
      :edit="search.edit"
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
    >
    </book-usage-slidebar>
    <copy-booklist-slidebar
      v-if="showCopySlidebar"
      :large="true"
      :termId="id"
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
    >
    </copy-booklist-slidebar>
    <book-type-slidebar
      v-if="showEditBookSlidebar"
      :item="selectedItem"
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
    >
    </book-type-slidebar>
  </div>
</template>

<script>
import moment from 'moment';
import terms from '@/api/terms';
import types from '@/api/types';
import structure from '@/api/structure';
import { mapActions, mapGetters } from 'vuex';

import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';
import Pagination from '@/components/common/Pagination.vue';
import CustomButton from '@/components/common/CustomButton.vue';

import BookUsageSlidebar from '@/components/school/school/types/BookUsageSlidebar.vue';
import CopyBooklistSlidebar from '@/components/school/terms/booklist/CopyBooklistSlidebar.vue';
import BookTypeSlidebar from '@/components/school/school/types/BookTypeSlidebar.vue';

export default {
  name: 'term-booklist',
  props: ['id', 'item'],
  components: {
    TableRowLoader,
    TableRowEmpty,
    LinkOrder,
    Pagination,
    BookUsageSlidebar,
    CopyBooklistSlidebar,
    BookTypeSlidebar,
    CustomButton,
  },
  computed: {
    loader() {
      return this.loading.books || this.loading.modules;
    },
    list() {
      return this.showBooks ? this.listByBooks : this.listByModules;
    },
    getDepartments() {
      return this.departments.filter(x => !this.search.department || x.department_id !== this.search.department.department_id);
    },
    getList() {
      if (this.showBooks) {
        return this.list
          .filter(x => !this.search.name || (x.heiti && x.heiti.toUpperCase().startsWith(this.search.name.toUpperCase())))
          .filter(x => this.search.isbn.length === 0 || (x.isbn && x.isbn.toUpperCase().startsWith(this.search.isbn.toUpperCase())))
          .filter(x => !this.search.category || x.flokkur_id === this.search.category.flokkur_id);
      }
      return this.list
        .filter(
          x =>
            !this.search.module ||
            (x.namsgrein_afanganr && x.namsgrein_afanganr.toUpperCase().startsWith(this.search.module.toUpperCase())),
        )
        .filter(x => !this.search.onlyNone || x.fj_boka === 0)
        .filter(x => !this.search.department || (x.deild || '').split(',').indexOf(this.search.department.name) > -1);
    },
    getPaginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.getList.slice(start, end);
    },
    getExcelData() {
      if (this.showBooks) {
        const newList = [
          [],
          ['Námsgagnalisti eftir námsgögnum'],
          [`${this.item.undirsk_heiti}-${this.item.onn_heiti}`],
          [],
          ['Heiti', 'Höfundur', 'Útgáfuár', 'Útgefandi', 'Lýsing', 'Flokkur', 'ISBN-númer', 'Fjöldi áfanga', 'Fjöldi nemenda'],
          ...this.getList.map(x => [x.heiti, x.hofundur, x.utgafa, x.utgefandi, x.lysing, x.flokkur_heiti, x.isbn, x.fj_afanga, x.fj_nem]),
        ];
        return newList;
      }
      const newList = [
        [],
        ['Námsgagnalistar eftir áföngum'],
        [`${this.item.undirsk_heiti}-${this.item.onn_heiti}`],
        [],
        ['Áfangi', 'Deild', 'Fjöldi námsgagna', 'Fjöldi nemenda'],
        ...this.getList.map(x => [x.namsgrein_afanganr, x.deild, x.fj_boka, x.fj_nemenda]),
      ];
      return newList;
    },
    getExcelFileName() {
      return `namsgagnalisti_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    getExcelDataAll() {
      const newList = [
        [],
        ['Námsgagnalisti - Allt'],
        [`${this.item.undirsk_heiti}-${this.item.onn_heiti}`],
        [],
        ['Áfangi', 'Deild', 'Heiti', 'Höfundur', 'Útgáfuár', 'Útgefandi', 'Lýsing', 'Flokkur', 'ISBN-númer'],
        ...this.all.map(x => [
          x.namsgrein_afanganr,
          x.deild,
          x.heiti,
          x.hofundur,
          x.utgafa,
          x.utgefandi,
          x.lysing,
          x.flokkur_heiti,
          x.isbn,
        ]),
      ];
      return newList;
    },
    getExcelFileNameAll() {
      return `namsgagnalisti_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    canRead() {
      return this.loggedInUserHasReadPermission('skolahald_annir');
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_annir');
    },
    canEditStructure() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolHasSettings']),
  },
  data() {
    return {
      perPage: 20,
      currentPage: 1,
      selectedItem: {},
      showBooks: false,
      showUsageSlidebar: false,
      showCopySlidebar: false,
      showEditBookSlidebar: false,
      loading: {
        books: false,
        modules: false,
        categories: false,
        all: false,
      },
      search: {
        name: '',
        isbn: '',
        bookName: '',
        category: '',
        module: '',
        termId: '',
        moduleId: '',
        bookId: '',
        onlyNone: false,
        edit: false,
        department: '',
      },
      all: [],
      listByBooks: [],
      listByModules: [],
      categories: [],
      departments: [],
    };
  },
  methods: {
    loadList() {
      if (this.showBooks) {
        this.getByBooks();
      } else {
        this.getByModules();
      }
      this.getAll();
    },
    async getByBooks() {
      try {
        this.loading.books = true;
        const params = {};
        if (this.search.department) {
          params.department = this.search.department.department_id;
        }
        const response = await terms.getBookByBook(this.id, params);
        this.listByBooks = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.books = false;
      }
    },
    async getByModules() {
      try {
        this.loading.modules = true;
        const response = await terms.getBookByModules(this.id);
        this.listByModules = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.modules = false;
      }
    },
    async getAll() {
      try {
        this.loading.all = true;
        const response = await terms.getBooklist(this.id);
        this.all = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.all = false;
      }
    },
    async loadCategories() {
      try {
        this.loading.categories = true;
        const response = await types.getBookCategoryList();
        this.categories = response.data.items;
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.categories = false;
      }
    },
    async loadDepartments() {
      try {
        const response = await structure.getDepartmentList();
        this.departments = response.data.items.map(x => ({
          ...x,
          label: `${x.name}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    openCopySlidebar() {
      this.showCopySlidebar = true;
      this.showUsageSlidebar = false;
      this.showEditBookSlidebar = false;
    },
    openBookUsageSlidebar(termId, bookId, moduleId) {
      this.search.edit = !!termId;
      this.search.termId = termId;
      this.search.bookId = bookId;
      this.search.moduleId = moduleId;
      this.showUsageSlidebar = true;
      this.showCopySlidebar = false;
      this.showEditBookSlidebar = false;
    },
    openEditBookSlidebar(item) {
      this.selectedItem = item;
      this.showEditBookSlidebar = true;
      this.showUsageSlidebar = false;
      this.showCopySlidebar = false;
    },
    closeSlidebar() {
      this.showUsageSlidebar = false;
      this.showCopySlidebar = false;
      this.showEditBookSlidebar = false;
      this.selectedItem = {};
      this.search.termId = '';
      this.search.bookId = '';
      this.search.moduleId = '';
    },
    closeAndReloadSlidebar() {
      this.closeSlidebar();
      this.loadList();
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    // this.getByBooks();
    this.loadList();
    this.loadCategories();
    this.loadDepartments();
  },
};
</script>
<style lang="scss">
#bookDepartmentSearch {
  input {
    height: 28px;
  }
}
</style>
