<template>
  <div>
    <h5>Ertu viss um að þú viljir fletja út stofntöflu á eftirfarandi kennslutímabili?</h5>
    <div>{{ item.onn_eink }} {{ item.onn_heiti }}</div>
    <br />
    <div class="alert alert-info" v-if="item.stundatafla_tegund === 0">
      <i class="fa fa-info-circle"></i>
      <span>
        Ath! Með því að velja að fletja út verður stofntaflan lesin og breytt í fljótandi stundatöflu þannig fyrir hvern vikudag sem tíminn
        á að vera kenndur á kennslutímabilinu verður stofnaður tími fyrir hvern dag.<br />
        Framvegis munu allar minniháttar breytingar fara fram undir "Breyta stundatöflu".
      </span>
    </div>
    <div class="alert alert-info" v-if="item.stundatafla_tegund === 1">
      <span>
        <div>
          <i class="fa fa-info-circle"></i> Ath! Þar sem verið er að endurfletja stofntöfluna þá verður að slá inn dagsetningu frá hvaða
          tíma þær breytingar eiga að taka gildi.
        </div>
        <div>Allir tímar í stundatöflu frá þeirri dagsetningu verður eytt og nýtt lesið inn út frá stofntöflu.</div>
        <div>Framvegis munu allar minniháttar breytingar fara fram undir "Breyta stundatöflu".</div>
      </span>
    </div>
    <div v-if="item.stundatafla_tegund === 1">
      <b-form-group label="Dags. tekur gildi" label-for="dateFrom" :feedback="errors.first('dateFrom')">
        <datepicker
          id="dateFrom"
          name="dateFrom"
          v-model="dateFrom"
          :language="lang"
          :monday-first="true"
          format="dd.MM.yyyy"
          :typeable="false"
          :disabled-dates="disabledDates"
          ref="dateFromOpenPickr"
        ></datepicker>
      </b-form-group>
    </div>
    <br />
    <b-btn variant="primary" @click="flatten" class="mb-3">Fletja út / senda í runuvinnslu</b-btn>
    <div>
      <b-link @click="showBatchJobs = !showBatchJobs">
        Seinustu runuvinnslur
        <i class="fa ml-2" :class="{ 'fa-angle-up': !showBatchJobs, 'fa-angle-down': showBatchJobs }" />
      </b-link>
    </div>
    <batchjobs v-if="showBatchJobs" :refresh="refreshJobs" :term="this.id" type="FlattenTimetable"></batchjobs>
  </div>
</template>

<script>
import terms from '@/api/terms';
import moment from 'moment';
import { mapActions } from 'vuex';

import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';

import Batchjobs from '@/components/common/Batchjobs.vue';

export default {
  name: 'change-term-type-slide-bar',
  props: ['item'],
  components: {
    Datepicker,
    Batchjobs,
  },
  data() {
    return {
      dateFrom: '',
      flattening: false,
      lang: is,
      disabledDates: {
        to: new Date(Date.now() - 8640000),
      },
      showBatchJobs: false,
      refreshJobs: false,
    };
  },
  methods: {
    async flatten() {
      this.flattening = true;
      try {
        const params = {};
        if (this.item.stundatafla_tegund === 1) {
          params.dags = moment(this.dateFrom).format('DD.MM.YYYY');
        }
        await terms.flattenTermTimetable(this.item.onn_id, params);
        this.displaySuccess('Vinnsla hefur verið send í runuvinnslu');
        this.refreshJobs = true;
        this.showBatchJobs = true;

        setTimeout(() => {
          this.refreshJobs = false;
        }, 1000);
        this.$emit('finishCopy');
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.flattening = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    item() {
      this.dateFrom = '';
      if (this.item.can_change_type === 1) {
        this.dateFrom = moment().add(1, 'days').toDate();
      }
    },
  },
  created() {
    this.dateFrom = '';
    if (this.item.can_change_type === 1) {
      this.dateFrom = moment().add(1, 'days').toDate();
    }
  },
};
</script>

<style lang="scss"></style>
