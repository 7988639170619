<template>
  <div>
    <table class="table less-padding table-hover" style="font-size: 13px">
      <thead>
        <tr>
          <th>Nafn</th>
          <th>Kennitala</th>
          <th>Virkur</th>
        </tr>
      </thead>
      <TableRowLoader v-if="loading" colspan="3" :center="true" />
      <TableRowEmpty v-if="!loading && this.list.length === 0" colspan="3" :center="true" />
      <tbody>
        <tr v-for="item in getPaginatedList" :key="item.hopur_id">
          <td>{{ item.nafn }}</td>
          <td>{{ item.kennitala }}</td>
          <td>
            <yes-no :item="item.virkur"></yes-no>
          </td>
        </tr>
      </tbody>
    </table>

    <Pagination
      :currentPage="currentPage"
      :totalRows="list.length"
      :perPage="perPage"
      v-if="list.length > 25"
      @change-page="changePage"
      @change-limit="changeLimit"
    />
  </div>
</template>

<script>
import students from '@/api/students';
import { mapGetters, mapActions } from 'vuex';

import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import Pagination from '@/components/common/Pagination.vue';

import YesNo from '@/components/common/YesNo.vue';

export default {
  name: 'student-special-attendance',
  components: {
    TableRowLoader,
    TableRowEmpty,
    Pagination,
    YesNo,
  },
  props: ['termId'],
  computed: {
    getPaginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.list.slice(start, end);
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      perPage: 25,
      currentPage: 1,
      loading: false,
      list: [],
    };
  },
  methods: {
    async loadList() {
      try {
        this.loading = true;
        const params = {
          specialAttendance: 1,
        };
        if (this.termId) {
          params.termId = this.termId;
        }
        const response = await students.getStudentList(params);
        this.list = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadList();
  },
};
</script>
