<template>
  <div class="slidebar isOpen slidebarPrintPosition" :class="{ large: large, extraLarge: extraLarge }" v-click-outside="closeMe">
    <div class="slidebar-content">
      <i class="fa fa-remove slidebar-close-icon" @click="closeIcon" />
      <slot></slot>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  name: 'slidebar',
  directives: {
    ClickOutside,
  },
  props: ['large', 'autoClose', 'extraLarge'],
  data() {
    return {
      count: 0,
    };
  },
  methods: {
    closeMe() {
      // Calles close when you open, so we only close if its more than that time
      if (this.count > 0 && this.autoClose) {
        this.$emit('closeMe');
      }
      this.count += 1;
    },
    closeIcon() {
      this.$emit('closeMe');
    },
  },
  created() {
    this.count = 0;
  },
};
</script>

<style lang="scss">
.slidebar {
  position: fixed;
  right: 0;
  top: 0;
  // top: 54px;
  width: 650px;
  height: 100vh;
  max-height: 100vh;
  padding-top: 20px;
  // overflow-x: auto;
  background-color: #fff;
  transform: translate3d(100%, 0px, 0px);
  transition: transform 0.6s ease(out-cubic);

  -webkit-box-shadow: -10px 0 20px 0 rgba(66, 66, 66, 0.2);
  -moz-box-shadow: -10px 0 20px 0 rgba(66, 66, 66, 0.2);
  box-shadow: -10px 0 20px 0 rgba(66, 66, 66, 0.2);
  z-index: 100;

  &.large {
    width: 900px;
  }

  &.extraLarge {
    width: 1200px;
  }

  .slidebar-content {
    height: 100%;
    max-height: 100%;
    overflow-x: auto;
    padding: 20px;
  }

  &.isOpen {
    transform: translate3d(0%, 0px, 0px);
  }
  .slidebar-close-icon {
    color: #636c72;
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 20px;
    cursor: pointer;
  }
}
</style>
