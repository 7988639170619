<template>
  <Slidebar :large="true" @closeMe="closeSlidebar">
    <h4>
      <div v-if="type === 'classroom'">Stofa: {{ item.stofa_eink }} {{ item.stofa_heiti }}</div>
    </h4>
    <br />
    <Timetable :id="id" :type="type" />
  </Slidebar>
</template>

<script>
import Timetable from '@/components/common/Timetable.vue';
import Slidebar from '@/components/common/Slidebar.vue';

export default {
  name: 'classroom-slide-bar',
  props: ['item', 'type'],
  computed: {
    id() {
      if (this.type === 'classroom') {
        return this.item.stofa_id;
      }
      if (this.type === 'staff') {
        return this.item.starfsm_id;
      }
      return null;
    },
  },
  components: {
    Slidebar,
    Timetable,
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    editItem() {
      this.$emit('editItem');
    },
  },
};
</script>

<style lang="scss"></style>
