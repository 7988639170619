<template>
  <page>
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <b-row class="breadcumb-location">
      <b-col>
        <b-btn variant="primary" :to="{ name: 'CreateCourse' }" style="height: 35px" v-if="canEdit">
          <i class="fa fa-plus"></i> Stofna braut</b-btn
        >
      </b-col>
    </b-row>
    <PageTitle title="Brautir"></PageTitle>
    <div>
      <b-tabs pills align="center" style="margin-top: -35px">
        <b-tab title="Yfirlit" active>
          <CourseSearch></CourseSearch>
        </b-tab>
        <b-tab title="Umsóknir">
          <CoursesApplicationList style="margin-top: 20px"></CoursesApplicationList>
        </b-tab>
      </b-tabs>
    </div>
  </page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import PageTitle from '@/components/common/PageTitle.vue';

import CourseSearch from '@/components/curriculum/courses/CourseSearch.vue';
import CoursesApplicationList from '@/components/curriculum/courses/CoursesApplicationList.vue';

export default {
  name: 'courses',
  components: {
    Breadcrumbs,
    PageTitle,
    CourseSearch,
    CoursesApplicationList,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_brautir');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('namskra_brautir');
    },
    isSecondarySchool() {
      return this.schoolSettings && this.schoolSettings.tegund_skola_id === 1;
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {
      breadcrumbs: [
        { name: 'Forsíða', route: 'Dashboard' },
        { name: 'Námskrá', route: '' },
        { name: 'Brautir', route: 'Courses' },
      ],
    };
  },
  methods: {
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
