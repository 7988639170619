<template>
  <div>
    <h5 class="module-title">
      Grunnupplýsingar
      <b-link @click="openEditSlidebar('info')" v-if="canEdit"
        ><i class="fa fa-pencil" v-b-tooltip.hover title="Gera breytingar"></i
      ></b-link>
      <b-link class="float-right" @click="deleteModule()" v-if="canEdit"
        ><i class="fa fa-trash" v-b-tooltip.hover title="Eyða áfanga"></i
      ></b-link>
    </h5>
    <b-row class="module-settings-border">
      <b-col class="pl-0">
        <div>
          <label>Heiti áfanga</label>
          <div class="text">
            <span>{{ item.afanga_heiti }}</span>
          </div>
        </div>
        <div>
          <label
            >Heiti áfanga á ensku
            <i
              class="fa fa-info-circle"
              v-b-tooltip
              title="Er notað á skírteinum sem eru á ensku, ef notandi velur að hafa Innu á ensku og ef sótt er um á ensku."
            ></i
          ></label>
          <div class="text">{{ item.afanga_heiti_enska }}</div>
        </div>
        <div>
          <label>Undirskóli</label>
          <div class="text">{{ item.undirsk_heiti }}</div>
        </div>
        <div>
          <label>Einingar</label>
          <div class="text">{{ item.einingar }}</div>
        </div>
        <div v-if="!afangaskoli">
          <label>Til stúdentsprófs</label>
          <div class="text"><yes-no :item="item.studentsprof"></yes-no></div>
        </div>
      </b-col>
      <b-col class="pl-0">
        <div>
          <label
            >Auðkenni áfanga
            <i class="fa fa-info-circle" v-b-tooltip title="Auðkenni áfanga er búið til úr kóða námsgreinar og áfanganúmeri"></i>
          </label>
          <div class="text">{{ item.namsgrein_afanganr }}</div>
        </div>
        <div>
          <label>Virkur</label>
          <div class="text"><yes-no :item="item.gildi"></yes-no></div>
        </div>
        <div>
          <label>Deild</label>
          <div class="text">
            <span v-for="(d, idx) in item.deild" :key="d.deild_id"> <span v-if="idx !== 0">,</span>{{ d.heiti }}</span>
          </div>
        </div>
        <div>
          <label>Þrep</label>
          <div class="text">{{ item.trep }}</div>
        </div>
        <div v-if="!afangaskoli">
          <label>Einingar til stúdentsprófs</label>
          <div class="text">{{ item.einingar_stprof }}</div>
        </div>
      </b-col>
      <b-col class="pl-0">
        <div>
          <label
            >Á námsferli <i class="fa fa-info-circle" v-b-tooltip title="Á áfangi að koma fram á námsferli og skírteinum nemenda?"></i>
          </label>
          <div class="text"><yes-no :item="item.a_namsferil"></yes-no></div>
        </div>
        <div v-if="!isLearningCenter && !isPrimarySchool">
          <label
            >Fornámsáfangi
            <i
              class="fa fa-info-circle"
              v-b-tooltip
              title="Áfangi sem nemendur þurfa að taka ef þeir uppfylla ekki lágmarks kröfur fyrir framhaldsskólaáfanga"
            ></i>
          </label>
          <div class="text"><yes-no :item="item.fornamsafangi"></yes-no></div>
        </div>
        <div v-if="!isLearningCenter">
          <label>Umsjónaráfangi <i class="fa fa-info-circle" v-b-tooltip title="Áfangi sem er notaður fyrir umsjónarkennara."></i></label>
          <div class="text"><yes-no :item="item.umsj_afangi"></yes-no></div>
        </div>
        <div>
          <label>Fjöldi tíma á kennslutímabili</label>
          <div class="text">{{ item.timar_onn_alls }}</div>
        </div>
      </b-col>
      <b-col class="pl-0">
        <div>
          <label
            >Í fjarvistarferil
            <i
              class="fa fa-info-circle"
              v-b-tooltip
              title="Áfangi þarf að vera í fjarvistarferli til að hægt sé að skrá mætingu hjá nemendum"
            ></i>
          </label>
          <div class="text"><yes-no :item="item.i_fjarvistarferil"></yes-no></div>
        </div>
        <div v-if="!isLearningCenter">
          <label
            >Skólasóknaráfangi
            <i class="fa fa-info-circle" v-b-tooltip title="Áfangi sem er notaður til að reikna út einkunn fyrir skólasókn."></i
          ></label>
          <div class="text"><yes-no :item="item.skolasokn"></yes-no></div>
        </div>
        <div>
          <label
            >Oft í námsferli
            <i
              class="fa fa-info-circle"
              v-b-tooltip
              title="Má taka áfanga oft þrátt fyrir að hafa lokið honum,
              ath. áfangi getur samt bara verið einu sinni á hverju kennslutímabili"
            ></i>
          </label>
          <div class="text"><yes-no :item="item.fjolval"></yes-no></div>
        </div>
        <div v-if="schoolHasSettings('stundatoflukerfi')">
          <label>Fjöldi nemenda í hóp <i class="fa fa-info-circle" v-b-tooltip title="Æskilegur fjöldi nemenda í hóp"></i></label>
          <div class="text">{{ item.max_fj_nema }}</div>
        </div>
        <div v-if="showPrimary">
          <label
            >Tegund áfanga <i class="fa fa-info-circle" v-b-tooltip title="Notað ef skólar vilja flokka saman ákveðna tegundir áfanga."></i
          ></label>
          <div class="text">{{ item.tegund_afanga_heiti }}</div>
        </div>
      </b-col>
    </b-row>
    <b-row class="module-settings-border">
      <b-col class="pl-0 mb-3">
        <div>
          <label>Athugasemdir</label>
          <div class="text" v-html="item.aths"></div>
        </div>
      </b-col>
    </b-row>

    <h5 class="pt-2 pb-2 module-title" v-if="!schoolHasSettings('stundatoflukerfi')">
      Upplýsingar fyrir stundatöfluforrit
      <b-link @click="openEditSlidebar('timetable')" v-if="canEdit"
        ><i class="fa fa-pencil" v-b-tooltip.hover title="Gera breytingar"></i
      ></b-link>
    </h5>
    <b-row v-if="!schoolHasSettings('stundatoflukerfi')" class="module-settings-border">
      <b-col class="pl-0">
        <div>
          <label>Fjöldi nemenda í hóp <i class="fa fa-info-circle" v-b-tooltip title="Æskilegur fjöldi nemenda í hóp"></i></label>
          <div class="text">{{ item.max_fj_nema }}</div>
        </div>
        <div>
          <label>Hámarksfjöldi í hóp <i class="fa fa-info-circle" v-b-tooltip title="Má ekki fara yfir þennan fjölda í hóp"></i> </label>
          <div class="text">{{ item.efstu_mork }}</div>
        </div>
        <div>
          <label>Fjöldi tíma á viku <i class="fa fa-info-circle" v-b-tooltip title="Hversu oft í viku er áfanginn kenndur?"></i> </label>
          <div class="text">{{ item.timar_viku_nemar }}</div>
        </div>
      </b-col>
      <b-col class="pl-0">
        <div>
          <label>Frávik - fjöldi nemenda</label>
          <div class="text">{{ item.fravik }}</div>
        </div>
        <div>
          <label
            >Fjöldi samfelldra tíma
            <i
              class="fa fa-info-circle"
              v-b-tooltip
              title="Hversu marga tíma er kennt samfellt, t.d. áfangi er alltaf kenndur tvo tíma í einu þá er sett 2 hér."
            ></i>
          </label>
          <div class="text">{{ item.fj_samf_tima }}</div>
        </div>
        <div>
          <label>Tegund stofu <i class="fa fa-info-circle" v-b-tooltip title="Hvernig stofu þarf að nota."></i></label>
          <div class="text">{{ item.stofa_heiti }}</div>
        </div>
      </b-col>
      <b-col class="pl-0">
        <div v-if="item.stofa_vara_heiti">
          <label
            >Tegund stofu - vara
            <i class="fa fa-info-circle" v-b-tooltip title="Ef skráð er varastofa þá er sett í hana ef hitt er allt fullt."></i>
          </label>
          <div class="text">{{ item.stofa_vara_heiti }}</div>
        </div>
        <div v-if="item.stofa_auka_heiti">
          <label
            >Tegund stofu - aukastofa
            <i class="fa fa-info-circle" v-b-tooltip title="Ef kenna á í tveimur stofum t.d. verklegt nám og bóklegt."></i
          ></label>
          <div class="text">{{ item.stofa_auka_heiti }}</div>
        </div>
        <div v-if="item.stofa_auka_heiti">
          <label>Fjöldi nemenda - aukastofa</label>
          <div class="text">{{ item.max_fj_nema_2 }}</div>
        </div>
      </b-col>
      <b-col class="pl-0" v-if="item.stofa_auka_heiti">
        <div>
          <label>Fjöldi samfelldra tíma - aukastofa</label>
          <div class="text">{{ item.fj_samf_tima_2 }}</div>
        </div>
        <div>
          <label>Fjöldi tíma á viku - aukastofa</label>
          <div class="text">{{ item.timar_viku_nemar_2 }}</div>
        </div>
      </b-col>
    </b-row>

    <h5 class="pt-2 pb-2 module-title" v-if="showPrimary">
      Upplýsingar vegna grunnskólaeinkunna
      <b-link @click="openEditSlidebar('primary')" v-if="canEdit"
        ><i class="fa fa-pencil" v-b-tooltip.hover title="Gera breytingar"></i
      ></b-link>
    </h5>
    <b-row>
      <b-col class="pl-0" v-if="showPrimary">
        <b-row class="module-settings-border">
          <b-col class="pl-0">
            <div>
              <label>Grunnskólafag</label>
              <div class="text">{{ item.fag_heiti }}</div>
            </div>

            <div>
              <label>Grunnskólaeinkunnir (Bókstafir)</label>
              <div class="text">{{ item.grskeink_bokstafir }}</div>
            </div>
          </b-col>
          <b-col class="pl-0">
            <div>
              <label>Grunnskólaeinkunn frá (Tölustafir)</label>
              <div class="text">{{ item.grskeink_fra }}</div>
            </div>

            <div>
              <label>Grunnskólaeinkunn til (Tölustafir)</label>
              <div class="text">{{ item.grskeink_til }}</div>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="pr-0">
        <!--b-btn variant="primary" class="float-right" @click="openEditSlidebar()">
        <i class="fa fa-pencil"></i> Breyta upplýsingum </b-btn-->
      </b-col>
    </b-row>

    <h5 class="pt-2 pb-2 module-title" v-if="isPrimarySchool">
      Gögn til MMS
      <b-link @click="openEditSlidebar('primary')" v-if="canEdit"
        ><i class="fa fa-pencil" v-b-tooltip.hover title="Gera breytingar"></i
      ></b-link>
    </h5>
    <b-row v-if="isPrimarySchool">
      <b-col class="pl-0">
        <b-row class="module-settings-border">
          <b-col class="pl-0">
            <div>
              <label
                >Námsgrein
                <i class="fa fa-info-circle" title="Ef fyllt er út í námsgrein þá fara einkunnir úr þessum áfanga til MMS."></i>
              </label>
              <div class="text">{{ item.fag_heiti }}</div>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="pr-0"> </b-col>
    </b-row>

    <h5 class="pt-2 pb-2 module-title">
      Gjöld
      <b-link @click="openEditSlidebar('price')" v-if="canEdit"
        ><i class="fa fa-pencil" v-b-tooltip.hover title="Gera breytingar"></i
      ></b-link>
    </h5>
    <b-row class="module-settings-border">
      <b-col class="pl-0">
        <div>
          <label>Tegund gjalds</label>
          <div class="text">{{ item.tegund_gjalds_heiti }}</div>
        </div>
      </b-col>
      <b-col class="pl-0">
        <div>
          <label>Upphæð</label>
          <div class="text">{{ item.upphaed }}</div>
        </div>
      </b-col>
    </b-row>

    <module-edit-slidebar v-if="showEditSlidebar" @closeSlidebar="closeSlidebar" :id="id" :type="editType"></module-edit-slidebar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import curriculum from '@/api/curriculum';

import YesNo from '@/components/common/YesNo.vue';

import ModuleEditSlidebar from '@/components/curriculum/modules/ModuleEditSlidebar.vue';

export default {
  name: 'module-settings',
  components: {
    YesNo,
    ModuleEditSlidebar,
  },
  props: ['id', 'item'],
  computed: {
    showPrimary() {
      return (
        this.schoolSettings &&
        this.schoolSettings.tegund_skola_id !== 3 &&
        this.schoolSettings.tegund_skola_id !== 4 &&
        this.schoolSettings.tegund_skola_id !== 5
      );
    },
    afangaskoli() {
      return this.schoolSettings && this.schoolSettings.afangaskoli ? this.schoolSettings.afangaskoli === 1 : false;
    },
    isPrimarySchool() {
      return this.schoolSettings && this.schoolSettings.tegund_skola_id === 3;
    },
    isLearningCenter() {
      return this.schoolSettings && (this.schoolSettings.tegund_skola_id === 4 || this.schoolSettings.tegund_skola_id === 5);
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_afangar');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('namskra_afangar');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      showEditSlidebar: false,
      editType: 'info',
    };
  },
  methods: {
    openEditSlidebar(type) {
      this.editType = type || 'info';
      this.showEditSlidebar = true;
    },
    closeSlidebar() {
      this.showEditSlidebar = false;
      this.$emit('refresh');
    },
    async deleteModule() {
      const conf = confirm(`Ert þú viss um að eyða áfanganum ${this.item.namsgrein_afanganr}?`); // eslint-disable-line
      if (conf) {
        try {
          await curriculum.updateModule(this.item.afangi_id, { delete: 1 });
          this.displaySuccess('Áfanga eytt.');
          this.$router.push({ name: 'Modules' });
        } catch (e) {
          this.$log.error(e);
          if (e.response && e.response.data && e.response.data.error) {
            this.displayError(e.response.data.error);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    item() {},
  },
  created() {},
};
</script>
<style lang="scss">
.module-title {
  font-size: 16px;
  background-color: rgba(152, 19, 156, 0.05);
  padding: 5px;
}
.module-settings-border {
  // border: 2px solid #e5e5e5;
  // padding: 10px;
  font-size: 13px;
  label {
    // border-bottom: 1px solid #98139c;
    // padding-bottom: 5px;
    text-decoration: underline;
    text-underline-offset: 3px;
    // text-decoration-color: #98139c;
    // font-style: italic;
    // font-weight: bold;
    // text-transform: uppercase;
    // color: #000;
    font-size: 15px;
    margin-bottom: 0px;
    display: block;
  }
  .text {
    // font-style: italic;
    height: 15px;
    // margin-bottom: 9px;
    margin-bottom: 15px;
    span {
      min-width: 50px;
      // background-color: RGBA(152, 19, 156, 0.1);
    }
  }
  .btn {
    font-size: 13px;
  }
}
</style>
