<template>
  <page>
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <b-row class="breadcumb-location">
      <b-col>
        <div class="float-right">
          <b-btn variant="dark" @click="openSearchSlidebar" class="ml-1"> <i class="fa fa-exchange" /> Skipta um hóp </b-btn>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="pl-0">
        <div>
          <PageTitle :title="getTitle" class="d-inline-block" style="font-size: 26px" />
          <PageTitle
            :title="getSubTitle1"
            style="font-size: 16px; font-weight: normal; font-style: italic; margin-top: -45px; margin-bottom: 10px"
          />
          <div style="margin-top: -45px">
            <PageTitle
              class="d-inline-block"
              :title="getSubTitle2"
              style="font-size: 16px; font-weight: normal; font-style: italic; margin-bottom: 0px"
            />
            <PageTitle
              class="d-inline-block pl-1"
              :title="getSubTitle3"
              style="font-size: 13px; font-weight: normal; font-style: italic; margin-bottom: 0px"
            />
          </div>
        </div>
      </b-col>
    </b-row>
    <PageMenu route="Group" :selectedPage="page" :items="menu" />

    <GroupDetails v-if="page === 'details' && group && !loading.group" :item="group" :id="id" @refresh="loadGroup()" :canEdit="canEdit" />
    <StudentList
      v-if="page === 'students' && group && !loading.group"
      :item="group"
      :id="Number(id)"
      @refresh="loadGroup()"
      :canEdit="canEdit"
    />
    <AssessmentOverview
      style="margin-top: 50px"
      v-if="page === 'grades' && group && !loading.group"
      :groupId="id"
      :edit="canEdit"
      @refresh="loadGroup()"
    />
    <Attendance v-if="page === 'attendance' && group && !loading.group" :groupId="id" :canEdit="canEdit" />
    <group-search-slidebar @closeSlidebar="closeSearchSlidebar" :searchQuery="searchQuery" v-if="showSearchSlidebar" :large="true">
    </group-search-slidebar>
  </page>
</template>

<script>
import moment from 'moment';
import groups from '@/api/groups';

import { mapActions, mapGetters } from 'vuex';

import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import PageTitle from '@/components/common/PageTitle.vue';
import PageMenu from '@/components/common/PageMenu.vue';

import GroupDetails from '@/components/students/groups/group/GroupDetails.vue';
import StudentList from '@/components/students/groups/students/StudentList.vue';
import AssessmentOverview from '@/components/students/groups/grades/AssessmentOverview.vue';
import GroupSearchSlidebar from '@/components/students/groups/GroupSearchSlidebar.vue';
import Attendance from '@/components/students/groups/attendance/Attendance.vue';

export default {
  name: 'group',
  components: {
    Breadcrumbs,
    PageTitle,
    PageMenu,
    GroupDetails,
    StudentList,
    AssessmentOverview,
    GroupSearchSlidebar,
    Attendance,
  },
  computed: {
    canRead() {
      return this.loggedInUserHasReadPermission('nemendahald_hopar');
    },
    canEdit() {
      return (
        this.group &&
        (this.group.is_term_active === 1 || moment(this.group.onn_dags_til).isAfter(new Date())) &&
        this.group.tafla_laest === 0 &&
        this.loggedInUserHasWritePermission('nemendahald_hopar')
      );
    },
    getTitle() {
      return this.group ? `${this.group.namsgrein_afanganr} - Hópur ${this.group.hopur}` : '';
    },
    getSubTitle1() {
      return this.group ? this.group.afanga_heiti : '';
    },
    getSubTitle2() {
      return this.group ? `${this.group.onn_heiti} ` : '';
    },
    getSubTitle3() {
      return this.group
        ? `(${moment(this.group.dags_fra).format('DD.MM.YYYY')} - ${moment(this.group.dags_til).format('DD.MM.YYYY')}) `
        : '';
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolHasSettings']),
  },
  data() {
    return {
      id: '',
      breadcrumbs: [
        { name: 'Forsíða', route: 'Dashboard' },
        { name: 'Nemendahald', route: '' },
        { name: 'Hópar', route: 'Groups' },
      ],
      menu: [
        {
          name: 'Stýringar',
          page: 'details',
        },
        {
          name: 'Nemendur',
          page: 'students',
        },
        {
          name: 'Einkunnir',
          page: 'grades',
        },
      ],
      page: 'details',
      loading: {
        group: false,
      },
      group: '',
      title: '',
      showSearchSlidebar: false,
      searchQuery: '',
    };
  },
  methods: {
    async loadGroup() {
      try {
        this.loading.group = true;
        const response = await groups.getGroupDetails(this.id);
        this.group = response.data;
        this.breadcrumbs = [
          { name: 'Forsíða', route: 'Dashboard' },
          { name: 'Nemendahald', route: '' },
          { name: 'Hópar', route: 'Groups' },
          { name: `${this.group.namsgrein_afanganr}(${this.group.hopur})`, route: '' },
        ];
        this.title = `${this.group.namsgrein_afanganr}(${this.group.hopur})`;
        if (this.group.i_fjarvistarferil === 1 && this.menu.filter(x => x.page === 'attendance').length === 0) {
          this.menu.push({
            name: 'Viðvera',
            page: 'attendance',
          });
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.group = false;
      }
    },
    openSearchSlidebar() {
      this.showSearchSlidebar = true;
    },
    closeSearchSlidebar(item) {
      if (item && item.id) {
        const query = {};
        if (this.$route.query.page) {
          query.page = this.$route.query.page;
        }
        if (item && item.searchQuery) {
          query.search = item.searchQuery;
        }
        this.$router.push({ name: 'Group', params: { id: item.id }, query });
        this.$router.go();
      }
      this.showSearchSlidebar = false;
      this.loadGroup();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  beforeRouteUpdate(to, from, next) {
    if (to && to.query && to.query.page) {
      this.page = to.query.page;
    }
    next();
  },
  created() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
    if (this.$route.query.search) {
      this.searchQuery = this.$route.query.search;
    }
    this.id = this.$route.params.id;
    this.loadGroup();
  },
};
</script>
