<template>
  <div>
    <table class="table less-padding" style="font-size: 13px">
      <thead>
        <tr class="table-detail-search">
          <td colspan="2" style="padding-top: 0; padding-left: 0px">
            <b-form-input v-model="search.isbn" class="search" placeholder="ISBN" />
          </td>
          <td style="padding-top: 0; padding-left: 0px">
            <b-form-input v-model="search.name" class="search" placeholder="Heiti" ref="focusInput" />
          </td>
          <td colspan="2" style="padding-top: 0; padding-left: 0px">
            <v-select class="v-select-form-control" v-model="search.category" placeholder="Flokkur" :options="categories" label="heiti">
            </v-select>
          </td>
          <td>
            Eingöngu virkt
            <b-form-checkbox v-model="search.onlyActive"></b-form-checkbox>
          </td>
          <td colspan="4" style="max-width: 80px">
            <div class="float-right" style="margin-top: -10px">
              <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="d-inline" v-if="!loading">
                <CustomButton type="excel" />
              </download-csv>
              <CustomButton type="create" title="Nýtt námsgagn" v-if="canEdit" @click.native="openSlidebar()" />
            </div>
          </td>
        </tr>
        <tr>
          <th>
            <b-form-checkbox class="d-inline-block" v-model="allSelected" @input="updateAll()"></b-form-checkbox>
            <b-btn
              variant="primary"
              class="btn-xs d-inline-block"
              style="font-size: 12px"
              @click="openMassEditSlidebar()"
              v-if="getSelected.length > 0"
              ><i class="fa fa-pencil"></i
            ></b-btn>
          </th>
          <th>
            <LinkOrder title="ISBN - númer" :filteredList="getList" :list="list" column="isbn" secondColumn="heiti" />
          </th>
          <th><LinkOrder title="Heiti" :filteredList="getList" :list="list" column="heiti" /> ({{ this.getList.length }})</th>
          <th>
            <LinkOrder title="Höfundur" :filteredList="getList" :list="list" column="hofundur" secondColumn="heiti" />
          </th>
          <th>
            <LinkOrder title="Útgáfuár" :filteredList="getList" :list="list" column="utgafa" secondColumn="heiti" />
          </th>
          <th>
            <LinkOrder title="Útgefandi" :filteredList="getList" :list="list" column="utgefandi" secondColumn="heiti" />
          </th>
          <th>
            <LinkOrder title="Flokkur" :filteredList="getList" :list="list" column="flokkur_heiti" secondColumn="heiti" />
            <i class="fa fa-plus-circle cursor-pointer text-primary" @click="openBookCategorySlidebar()"></i>
          </th>
          <th v-b-tooltip title="Kennslutímabilið þar sem námsgagnið var seinast notað">
            <LinkOrder title="Kennslutímabil" :filteredList="getList" :list="list" column="max_onn_eink" secondColumn="heiti" />
          </th>
          <th>
            <LinkOrder title="Virkur" :filteredList="getList" :list="list" column="virkur" secondColumn="heiti" />
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <TableRowLoader v-if="loading" colspan="10" :center="true" />
        <TableRowEmpty v-if="!loading && this.list.length === 0" colspan="10" :center="true" />
        <tr
          v-for="(item, idx) in getPaginatedList"
          :key="idx"
          :class="{
            'active-table-row': selectedItem && selectedItem.bok_id === item.bok_id,
            'cursor-pointer': showSlidebar,
          }"
          @click="checkSlidebar(item)"
        >
          <td width="5%">
            <b-form-checkbox v-model="item.selected"></b-form-checkbox>
          </td>
          <td width="10%">{{ item.isbn }}</td>
          <td width="30%" :title="item.heiti" v-b-tooltip>
            {{ item.heiti && item.heiti.length > 45 ? `${item.heiti.substring(0, 45)}..` : item.heiti }}
          </td>
          <td width="15%" :title="item.hofundur" v-b-tooltip>
            {{ item.hofundur && item.hofundur.length > 30 ? `${item.hofundur.substring(0, 30)}..` : item.hofundur }}
          </td>
          <td width="8%">{{ item.utgafa }}</td>
          <td width="9%">{{ item.utgefandi }}</td>
          <td width="13%">{{ item.flokkur_heiti }}</td>
          <td width="5%">{{ item.max_onn_eink }}</td>
          <td width="3%"><yes-no :item="item.virkur"></yes-no></td>
          <td width="3%">
            <div class="float-right" style="white-space: nowrap">
              <b-btn
                v-if="canEdit"
                variant="primary"
                class="mr-2 btn-xs"
                v-b-tooltip
                title="Breyta"
                @click.prevent="openSlidebar(item)"
                style="font-size: 13px"
              >
                <i class="fa fa-pencil" />
              </b-btn>
              <b-btn
                variant="primary"
                class="mr-2 btn-xs"
                v-b-tooltip
                title="Skoða notkun"
                @click.prevent="openBookUsageSlidebar(item)"
                style="font-size: 13px"
              >
                <i class="fa fa-book" />
              </b-btn>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <Pagination
      :currentPage="currentPage"
      :totalRows="getList.length"
      :perPage="perPage"
      v-if="getList.length > 20"
      @change-page="changePage"
      @change-limit="changeLimit"
    ></Pagination>
    <book-type-slidebar
      v-if="showSlidebar"
      :item="selectedItem"
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
    >
    </book-type-slidebar>
    <book-type-mass-slidebar
      v-if="showMassEditSlidebar"
      :items="getSelected"
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
    >
    </book-type-mass-slidebar>
    <book-usage-slidebar
      v-if="showBookUsageSlidebar"
      :bookId="selectedItem && selectedItem.bok_id"
      :large="true"
      @closeSlidebar="closeSlidebar"
    >
    </book-usage-slidebar>
    <book-category-type-slidebar v-if="showBookCategorySlidebar" @closeSlidebar="closeCategorySlidebar"> </book-category-type-slidebar>
  </div>
</template>

<script>
import types from '@/api/types';

import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

import CustomButton from '@/components/common/CustomButton.vue';

import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';
import Pagination from '@/components/common/Pagination.vue';
import YesNo from '@/components/common/YesNo.vue';

import BookTypeSlidebar from '@/components/school/school/types/BookTypeSlidebar.vue';
import BookTypeMassSlidebar from '@/components/school/school/types/BookTypeMassSlidebar.vue';
import BookUsageSlidebar from '@/components/school/school/types/BookUsageSlidebar.vue';
import BookCategoryTypeSlidebar from '@/components/school/school/types/BookCategoryTypeSlidebar.vue';

export default {
  name: 'bookType',
  components: {
    TableRowLoader,
    TableRowEmpty,
    LinkOrder,
    BookTypeSlidebar,
    CustomButton,
    Pagination,
    BookTypeMassSlidebar,
    BookUsageSlidebar,
    YesNo,
    BookCategoryTypeSlidebar,
  },
  computed: {
    getList() {
      return this.list
        .filter(x => this.search.name.length === 0 || (x.heiti && x.heiti.toUpperCase().startsWith(this.search.name.toUpperCase())))
        .filter(x => this.search.isbn.length === 0 || (x.isbn && x.isbn.toUpperCase().startsWith(this.search.isbn.toUpperCase())))
        .filter(x => !this.search.category || x.flokkur_id === this.search.category.flokkur_id)
        .filter(x => !this.search.onlyActive || x.virkur === 1);
    },
    getExcelData() {
      const newList = [
        [],
        ['Námsgögn'],
        [],
        ['ISBN-númer', 'Heiti', 'Höfundur', 'Útgáfuár', 'Útgefandi', 'Lýsing', 'Flokkur'],
        ...this.getList.map(x => [x.isbn, x.heiti, x.hofundur, x.utgafa, x.utgefandi, x.lysing, x.flokkur_heiti]),
      ];
      return newList;
    },
    getExcelFileName() {
      return `tegund_namsgogn_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    getPaginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.getList.slice(start, end);
    },
    getSelected() {
      return this.getList.filter(x => x.selected);
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      perPage: 20,
      currentPage: 1,
      loading: false,
      loadingCategory: false,
      showSlidebar: false,
      showMassEditSlidebar: false,
      showBookUsageSlidebar: false,
      showBookCategorySlidebar: false,
      selectedItem: {},
      search: {
        name: '',
        isbn: '',
        category: '',
        onlyActive: true,
      },
      allSelected: false,
      list: [],
      categories: [],
    };
  },
  methods: {
    async loadList() {
      try {
        this.loading = true;
        this.allSelected = false;
        const response = await types.getBookList();
        this.list = response.data.items.map(x => ({
          ...x,
          selected: false,
        }));
        this.$nextTick(() => {
          if (this.$refs.focusInput) {
            this.$refs.focusInput.focus();
          }
        });
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadCategories() {
      try {
        this.loadingCategory = true;
        const response = await types.getBookCategoryList();
        this.categories = response.data.items;
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loadingCategory = false;
      }
    },
    updateAll() {
      this.getList.forEach(i => {
        this.$set(i, 'selected', this.allSelected);
      });
    },
    openMassEditSlidebar() {
      this.showMassEditSlidebar = true;
      this.showSlidebar = false;
    },
    openSlidebar(item) {
      this.selectedItem = item;
      this.showSlidebar = true;
    },
    openBookUsageSlidebar(item) {
      this.selectedItem = item;
      this.showBookUsageSlidebar = true;
    },
    openBookCategorySlidebar() {
      this.showBookCategorySlidebar = true;
    },
    closeSlidebar() {
      this.showSlidebar = false;
      this.showMassEditSlidebar = false;
      this.showBookUsageSlidebar = false;
      this.showBookCategorySlidebar = false;
      this.selectedItem = {};
    },
    closeAndReloadSlidebar() {
      this.closeSlidebar();
      this.loadList();
    },
    closeCategorySlidebar() {
      this.closeSlidebar();
      this.loadCategories();
    },
    checkSlidebar(item) {
      if (this.showSlidebar && this.selectedItem) {
        this.openSlidebar(item);
      }
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadCategories();
    this.loadList();
  },
};
</script>
