<template>
  <tbody style="border-width: 1px">
    <tr @click="showModules = !edit ? !showModules : true" :class="{ courseStructureOpened: showModules }">
      <td>
        <div v-if="edit">
          <b-form-group label-for="heiti" :state="submitted && errors.has('heiti') ? false : ''" :invalid-feedback="errors.first('heiti')">
            <b-form-input
              id="heiti"
              name="heiti"
              type="text"
              v-model="editInstance.uppbygging_heiti"
              :state="submitted && errors.has('heiti') ? false : ''"
              maxLength="100"
              v-validate="'required'"
              ref="focusInput"
              data-vv-as="heiti"
            ></b-form-input>
          </b-form-group>
        </div>
        <div v-else>
          <a class="cursor-pointer">
            <i class="fa" :class="{ 'fa-angle-up': showModules, 'fa-angle-down': !showModules }"></i>
            {{ item.uppbygging_heiti }}
          </a>
        </div>
      </td>
      <td>
        <div v-if="edit">
          <b-form-group
            label-for="heitiEn"
            :state="submitted && errors.has('heitiEn') ? false : ''"
            :invalid-feedback="errors.first('heitiEn')"
          >
            <b-form-input
              id="heitiEn"
              name="heitiEn"
              type="text"
              v-model="editInstance.heiti_ens"
              :state="submitted && errors.has('heitiEn') ? false : ''"
              maxLength="100"
              data-vv-as="heiti"
            ></b-form-input>
          </b-form-group>
        </div>
        <div v-else>
          {{ item.heiti_ens }}
        </div>
      </td>
      <td>
        <div v-if="edit">
          <b-form-group
            label-for="einingar"
            :state="submitted && errors.has('einingar') ? false : ''"
            :invalid-feedback="errors.first('einingar')"
          >
            <b-form-input
              id="einingar"
              name="einingar"
              type="number"
              v-model="editInstance.einingar"
              :state="submitted && errors.has('einingar') ? false : ''"
              data-vv-as="einingar"
            ></b-form-input>
          </b-form-group>
        </div>
        <div v-else>
          {{ item.einingar }}
        </div>
      </td>
      <td>
        <div v-if="edit">
          <b-form-group
            label-for="tegund"
            :state="submitted && errors.has('tegund') ? false : ''"
            :invalid-feedback="errors.first('tegund')"
          >
            <v-select
              id="tegund"
              name="tegund"
              v-model="editInstance.uppbygging_tegund"
              :clearable="false"
              v-validate="'required'"
              :state="submitted && errors.has('tegund') ? false : ''"
              :options="types"
              data-vv-as="tegund"
              label="heiti"
            >
            </v-select>
          </b-form-group>
        </div>
        <div v-else>
          {{ item.uppbygging_tegund }}
        </div>
      </td>
      <td>
        <div v-if="edit">
          <b-form-group label-for="rod" :state="submitted && errors.has('rod') ? false : ''" :invalid-feedback="errors.first('rod')">
            <b-form-input
              id="rod"
              name="rod"
              type="number"
              v-model="editInstance.rod"
              :state="submitted && errors.has('rod') ? false : ''"
              data-vv-as="rod"
            ></b-form-input>
          </b-form-group>
        </div>
        <div v-else>
          {{ item.rod }}
        </div>
      </td>
      <td>
        <div class="d-inline-block" style="width: 25px">{{ item.fj_afanga_uppbygging }}</div>
        <i
          class="fa fa-plus-circle text-primary cursor-pointer"
          v-if="canEdit"
          @click="openMassSlidebar($event)"
          title="Bæta við áföngum"
          v-b-tooltip
        ></i>
      </td>
      <td>
        <!--b-btn variant="primary" class="btn-xs float-right" v-if="canEdit" @click="openSlidebar($event)"
          ><i class="fa fa-pencil"></i
        ></b-btn-->
        <b-btn
          variant="primary"
          class="float-right btn-xs"
          :disabled="!editInstance.uppbygging_tegund || !editInstance.uppbygging_heiti"
          v-if="canEdit && edit"
          @click="saveData($event)"
          ><i class="fa fa-save" title="Vista" v-b-tooltip></i
        ></b-btn>
        <b-btn variant="primary" class="float-right btn-xs mr-2" v-if="canEdit" @click="toggleEdit($event)">
          <span v-if="edit"><i class="fa fa-remove" title="Hætta við" v-b-tooltip></i></span
          ><span v-else><i class="fa fa-pencil" title="Breyta" v-b-tooltip></i></span
        ></b-btn>
        <b-btn variant="danger" class="float-right btn-xs mr-2" v-if="!edit && canEdit" @click="deleteStructure()">
          <i class="fa fa-trash"></i>
        </b-btn>
      </td>
    </tr>
    <tr v-if="showModules">
      <td colspan="100%" style="padding: 10px; padding-left: 50px; border-color: transparent">
        <div v-if="item.afangar.length === 0"><i>Engir áfangar fundust</i></div>
        <table class="table table-hover less-padding no-border mb-0" v-if="item.afangar.length > 0" style="font-size: 13px">
          <thead style="background-color: transparent">
            <tr>
              <th style="width: 2%; white-space: nowrap" v-if="canEdit">
                <b-form-checkbox v-model="selectAll" @change="updateAll()" class="d-inline-block"></b-form-checkbox>
                <b-btn
                  variant="danger"
                  class="btn-xs d-inline-block"
                  style="font-size: 10px"
                  v-if="getSelected.length > 0"
                  @click="deleteSelected()"
                >
                  <i class="fa fa-trash"></i>
                </b-btn>
              </th>
              <th>
                <LinkOrder title="Áfangi" :filteredList="item.afangar" :list="item.afangar" column="namsgrein_afanganr" />
              </th>
              <th>
                <LinkOrder
                  title="Heiti áfanga"
                  :filteredList="item.afangar"
                  :list="item.afangar"
                  column="afanga_heiti"
                  second_column="namsgrein_afanganr"
                />
              </th>
              <th>
                <LinkOrder
                  title="Einingar"
                  :filteredList="item.afangar"
                  :list="item.afangar"
                  column="einingar"
                  second_column="namsgrein_afanganr"
                />
              </th>
              <th>
                <LinkOrder
                  title="Þrep"
                  :filteredList="item.afangar"
                  :list="item.afangar"
                  column="trep"
                  second_column="namsgrein_afanganr"
                />
              </th>
              <th>
                <LinkOrder
                  title="Tímabil"
                  :filteredList="item.afangar"
                  :list="item.afangar"
                  column="onn_nr"
                  second_column="namsgrein_afanganr"
                />
                <i
                  class="fa fa-info-circle"
                  title="Ef taka á áfangann á ákveðnu kennslutímabili þarf að fylla út númer tímabilsins sem á að taka áfangann á hérna.
            Ef nemandi á að taka áfangann á öðru kennslutímabili þá er sett 2."
                  v-b-tooltip
                ></i>
              </th>
              <th>
                <LinkOrder
                  title="Forgangsröðun"
                  :filteredList="item.afangar"
                  :list="item.afangar"
                  column="forgangsrodun"
                  second_column="namsgrein_afanganr"
                />
                <i
                  class="fa fa-info-circle"
                  title="Ef áfangi á að vera í forgangi þegar það er sjálfvirkt lesið í feril er hægt að setja inn tölu hér.
            Það er síðan raðað eftir tölunum. Þannig sá sem er í mestum forgang væri með 1, næsti 2 osfrv.
            Ef forgangur skiptir ekki máli þá er þetta skilið eftir tómt."
                  v-b-tooltip
                ></i>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(afangi, index) in item.afangar" :key="`${item.braut_uppbygg_id}-${afangi.afangi_id}-${index}`">
              <td v-if="canEdit"><b-form-checkbox v-model="afangi.selected"></b-form-checkbox></td>
              <td>
                {{ afangi.namsgrein_afanganr }}
                <i
                  class="fa fa-ban mr-2"
                  style="color: #f26175"
                  v-if="afangi.gildi === 0"
                  v-b-tooltip
                  title="Ath. eftirfarandi áfangi er óvirkur"
                ></i>
                <b-link
                  v-if="canReadModules"
                  :to="{ name: 'Module', params: { id: afangi.afangi_id } }"
                  target="_blank"
                  v-b-tooltip
                  title="Skoða áfanga"
                  ><i class="fa fa-search"></i
                ></b-link>
              </td>
              <td>{{ afangi.afanga_heiti }}</td>
              <td>{{ afangi.einingar }}</td>
              <td>{{ afangi.trep }}</td>
              <td>
                <div v-if="edit && afangi && afangi.editInstance">
                  <b-form-input
                    type="number"
                    :id="`${item.braut_uppbygg_id}-${afangi.afangi_id}-onn_nr`"
                    :name="`${item.braut_uppbygg_id}-${afangi.afangi_id}-onn_nr`"
                    v-model="afangi.editInstance.onn_nr"
                    style="width: 60px; height: 30px; font-size: 13px"
                  />
                </div>
                <div v-else>
                  {{ afangi.onn_nr }}
                </div>
              </td>
              <td>
                <div v-if="edit && afangi && afangi.editInstance">
                  <b-form-input
                    type="number"
                    :id="`${item.braut_uppbygg_id}-${afangi.afangi_id}-forgangsrodun`"
                    :name="`${item.braut_uppbygg_id}-${afangi.afangi_id}-forgangsrodun`"
                    v-model="afangi.editInstance.forgangsrodun"
                    style="width: 60px; height: 30px; font-size: 13px"
                  />
                </div>
                <div v-else>
                  {{ afangi.forgangsrodun }}
                </div>
              </td>
              <td>
                <b-btn variant="danger" class="btn-xs float-right" style="font-size: 12px" v-if="canEdit" @click="deleteModule(afangi)"
                  ><i class="fa fa-trash"></i
                ></b-btn>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>

    <course-structure-module-slidebar
      :courseId="courseId"
      :structureId="item.braut_uppbygg_id"
      v-if="showMassSlidebar"
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
    >
    </course-structure-module-slidebar>
    <course-module-slidebar
      v-if="showModuleSlidebar"
      :id="selectedItem ? selectedItem.braut_afangar_id : ''"
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
    ></course-module-slidebar>
  </tbody>
</template>

<script>
import curriculum from '@/api/curriculum';
import { mapGetters, mapActions } from 'vuex';

import LinkOrder from '@/components/common/LinkOrder.vue';
import InlineEdit from '@/components/common/InlineEdit.vue';
import CourseStructureModuleSlidebar from '@/components/curriculum/courses/CourseStructureModuleSlidebar.vue';
import CourseModuleSlidebar from '@/components/curriculum/courses/CourseModuleSlidebar.vue';

export default {
  name: 'course-structure-item',
  props: ['courseId', 'item', 'open', 'close'],
  components: {
    CourseStructureModuleSlidebar,
    CourseModuleSlidebar,
    LinkOrder,
    InlineEdit, // eslint-disable-line
  },
  computed: {
    getSelected() {
      return this.item && this.item.afangar ? this.item.afangar.filter(x => x.selected) : [];
    },
    canReadModules() {
      return this.loggedInUserHasReadPermission('namskra_afangar');
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_brautir');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('namskra_brautir');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      selectAll: false,
      showModules: false,
      showMassSlidebar: false,
      showModuleSlidebar: false,
      selectedItem: '',
      edit: false,
      submitted: false,
      editInstance: {},
      types: [],
    };
  },
  methods: {
    openMassSlidebar(event) {
      this.showModules = true;
      event.stopPropagation();
      this.showMassSlidebar = true;
      this.showModuleSlidebar = false;
    },
    openModuleSlidebar(item) {
      this.selectedItem = item;
      this.showModuleSlidebar = true;
      this.showMassSlidebar = false;
    },
    closeSlidebar() {
      this.showMassSlidebar = false;
      this.showModuleSlidebar = false;
      this.selectedItem = '';
    },
    closeAndReloadSlidebar() {
      this.closeSlidebar();
      this.$emit('refresh');
    },
    openSlidebar(event) {
      event.stopPropagation();
      this.$emit('openSlidebar', this.item);
    },
    async loadTypes() {
      if (this.types.length === 0) {
        try {
          const response = await curriculum.getCourseModuleTypes();
          this.types = response.data.items;
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    async toggleEdit(event) {
      event.stopPropagation();
      this.edit = !this.edit;
      if (this.edit) {
        this.item.afangar.forEach(afangi => {
          this.$set(afangi, 'editInstance', { onn_nr: afangi.onn_nr, forgangsrodun: afangi.forgangsrodun });
        });
        await this.loadTypes();
        this.editInstance = { ...this.item, uppbygging_tegund: this.types.find(x => x.id === this.item.tegund) };
        this.showModules = true;
      }
    },
    async saveStructure() {
      try {
        const item = {
          heiti: this.editInstance.uppbygging_heiti,
          heiti_ens: this.editInstance.heiti_ens,
          einingar: this.editInstance.einingar,
          rod: this.editInstance.rod,
          braut_id: this.courseId,
          tegund: this.editInstance.uppbygging_tegund.id,
        };
        await curriculum.editCourseStructure(this.item.braut_uppbygg_id, item);
        this.displaySuccess('Uppbyggingu breytt');
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);

        if (e.response && e.response.data && e.response.data.error) {
          this.displayError(e.response.data.error);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },

    async deleteStructure() {
      let conf = true;
      if (this.item.afangar.length > 0) {
        conf = confirm(`Ertu alveg viss um að þú viljir eyða uppbyggingunni ${this.item.uppbygging_heiti}? Það eru skráðir ${this.item.afangar.length} áfangar. Þeim verður eytt af brautinni líka.`); // eslint-disable-line
      }

      if (conf) {
        try {
          const item = {
            delete: 1,
          };
          await curriculum.editCourseStructure(this.item.braut_uppbygg_id, item);
          this.displaySuccess('Uppbyggingu eytt');
          this.$emit('refresh');
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);

          if (e.response && e.response.data && e.response.data.error) {
            this.displayError(e.response.data.error);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      }
    },
    async saveModules() {
      try {
        const items = this.item.afangar
          .filter(x => x.onn_nr !== x.editInstance.onn_nr || x.forgangsrodun !== x.editInstance.forgangsrodun)
          .map(x => ({
            afangi_id: x.afangi_id,
            onn_nr: x.editInstance.onn_nr,
            forgangsrodun: x.editInstance.forgangsrodun,
          }));

        await curriculum.addCourseStructureModules(this.item.braut_uppbygg_id, items);
        this.displaySuccess('Áföngum breytt');
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);

        if (e.response && e.response.data && e.response.data.error) {
          this.displayError(e.response.data.error);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    async saveData(event) {
      event.stopPropagation();

      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        await this.saveStructure();
        await this.saveModules();

        this.edit = false;
        this.$emit('refresh');
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
      }
    },
    async deleteModule(afangi) {
      const conf = confirm(`Ert þú viss um að þú viljir eyða áfanganum ${afangi.namsgrein_afanganr} úr uppbyggingunni ${this.item.uppbygging_heiti}?`); // eslint-disable-line
      if (conf) {
        await curriculum.updateCourseModule(afangi.braut_afangar_id, { delete: 1 });
        this.displaySuccess('Tengingu eytt');
        this.$emit('refresh');
      }
    },
    updateAll() {
      this.item.afangar.forEach(i => {
        this.$set(i, 'selected', this.selectAll);
      });
    },
    deleteSelected() {
      const conf = confirm(`Ert þú viss um að þú viljir eyða ${this.getSelected.length} áföngum úr uppbyggingunni ${this.item.uppbygging_heiti}?`); // eslint-disable-line
      if (conf) {
        const promises = [];
        this.getSelected.forEach(item => {
          promises.push(curriculum.updateCourseModule(item.braut_afangar_id, { delete: 1 }));
        });

        Promise.all(promises).then(() => {
          this.displaySuccess('Tengingum eytt');
          this.$emit('refresh');
          this.selectAll = false;
        });
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    open() {
      if (this.open) {
        this.showModules = true;
      }
    },
    close() {
      if (this.close) {
        this.showModules = false;
      }
    },
  },
};
</script>
<style lang="scss">
.courseStructureOpened {
  // background-color: #e5e5e5;
  // border: 5px solid #e5e5e5;
  td {
    border-bottom: 1px solid #98139c !important;
  }
}
</style>
