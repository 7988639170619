<template>
  <page>
    <div>
      <form-wizard color="#98139c" errorColor="#F26175" title="" subtitle="" step-size="xs" :start-index="startIndex" ref="formWizard">
        <template slot="footer" slot-scope="props">
          <div class="wizard-footer-left">
            <b-btn v-if="props.activeTabIndex === 0" :to="{ name: 'Courses' }" :style="props.fillButtonStyle">Hætta við</b-btn>
            <b-btn v-if="props.activeTabIndex > 0" @click="props.prevTab()" :style="props.fillButtonStyle">Til baka</b-btn>
          </div>
          <div class="wizard-footer-right">
            <b-btn
              v-if="
                !props.isLastStep && ((isPrimarySchool && props.activeTabIndex === 2) || (!isPrimarySchool && props.activeTabIndex === 3))
              "
              @click="saveCourse(props)"
              class="wizard-footer-right"
              :style="props.fillButtonStyle"
            >
              <span v-if="id"> Breyta braut </span>
              <span v-else> Stofna braut </span>
            </b-btn>
            <b-btn
              v-if="
                !props.isLastStep && ((isPrimarySchool && props.activeTabIndex !== 2) || (!isPrimarySchool && props.activeTabIndex !== 3))
              "
              @click="props.nextTab()"
              class="wizard-footer-right"
              :style="props.fillButtonStyle"
            >
              Áfram
            </b-btn>
            <b-btn v-if="props.isLastStep" @click="completed()" class="wizard-footer-right" :style="props.fillButtonStyle">
              Skoða braut
            </b-btn>
          </div>
        </template>
        <tab-content title="Grunnupplýsingar" :beforeChange="step1Valid">
          <b-form @submit.prevent="step1Valid" novalidate data-vv-scope="step1">
            <b-row>
              <b-col class="pl-0" sm="12" xs="12" md="12" lg="6">
                <b-form-group :state="submitted && errors.has('step1.name') ? false : ''" :invalid-feedback="errors.first('step1.name')">
                  <label label-for="name"> Heiti brautar* </label>
                  <b-form-input
                    id="name"
                    name="name"
                    v-model="form.heiti"
                    :state="submitted && errors.has('step1.name') ? false : ''"
                    data-vv-as="heiti"
                    maxLength="200"
                    v-validate="'required'"
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group :state="submitted && errors.has('step1.code') ? false : ''" :invalid-feedback="errors.first('step1.code')">
                  <label label-for="code">
                    Kóði brautar*
                    <i
                      class="fa fa-info-circle"
                      v-b-tooltip
                      title="Kóði brautar er líkt og kennitala, það geta ekki margar brautir verið með sama kóða.
                      Fjöldi stafa í kóða má mest vera 10."
                    ></i
                  ></label>
                  <b-form-input
                    id="code"
                    name="code"
                    v-model="form.braut_kodi"
                    :state="submitted && errors.has('step1.code') ? false : ''"
                    data-vv-as="kóði"
                    maxLength="10"
                    v-validate="'required'"
                    @change="validateCode()"
                  >
                  </b-form-input>
                </b-form-group>
                <div class="text-danger" style="font-weight: bold; font-size: 13px" v-if="codeExists">
                  Ath. í þessum undirskóla er þegar til virk braut með þessum kóða. Vinsamlega veljið annan kóða.
                </div>
                <b-form-group :state="submitted && errors.has('step1.units') ? false : ''" :invalid-feedback="errors.first('step1.units')">
                  <label label-for="units"> Fjöldi eininga</label>
                  <b-form-input
                    id="units"
                    name="units"
                    type="number"
                    v-model="form.einingar_alls"
                    :state="submitted && errors.has('step1.units') ? false : ''"
                    data-vv-as="einingar"
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group
                  :state="submitted && errors.has('step1.autoSelection') ? false : ''"
                  :invalid-feedback="errors.first('step1.autoSelection')"
                >
                  <label label-for="units"
                    >Kjarna áfangar sjálfvirkt í námsferil
                    <i
                      class="fa fa-info-circle"
                      v-b-tooltip
                      title="Ef valið þá fara kjarna áfangar í uppbyggingu brautar
                    sjálfvirkt á þau kennslutímabil sem ekki eru hafin."
                    ></i>
                  </label>
                  <b-form-checkbox id="autoSelection" name="autoSelection" v-model="form.sjalfvirkt_val"> </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col class="pl-0" sm="12" xs="12" md="12" lg="6">
                <b-form-group
                  :state="submitted && errors.has('step1.division') ? false : ''"
                  :invalid-feedback="errors.first('step1.division')"
                >
                  <label label-for="subject"> Undirskóli* </label>
                  <v-select
                    id="division"
                    name="division"
                    class="dropdownMatchHeight"
                    v-model="form.undirskoli"
                    :clearable="false"
                    :state="submitted && errors.has('step1.division') ? false : ''"
                    :options="divisions"
                    @input="validateCode()"
                    data-vv-as="undirskóli"
                    style="height: 28px"
                    v-validate="'required'"
                  >
                  </v-select>
                </b-form-group>
                <b-form-group
                  :state="submitted && errors.has('step1.departments') ? false : ''"
                  :invalid-feedback="errors.first('step1.departments')"
                >
                  <label label-for="departments" style="margin-top: 10px">
                    Deild/ir
                    <i class="fa fa-info-circle" v-b-tooltip title="Hægt er að hafa fleiri en eina deild tengda"></i>
                  </label>
                  <v-select
                    id="departments"
                    name="departments"
                    class="dropdownMatchHeight"
                    v-model="form.deildir"
                    :clearable="true"
                    :state="submitted && errors.has('step1.departments') ? false : ''"
                    :options="departments"
                    data-vv-as="deildir"
                    multiple
                  >
                  </v-select>
                </b-form-group>
                <div v-if="!isPrimarySchool">
                  <b-form-group
                    :state="submitted && errors.has('step1.commonCourse') ? false : ''"
                    :invalid-feedback="errors.first('step1.commonCourse')"
                  >
                    <label label-for="subject"> Samræmd braut <span v-if="!isLearningCenter">- Eldri námskrá</span></label>
                    <v-select
                      id="commonCourse"
                      name="commonCourse"
                      class="dropdownMatchHeight"
                      v-model="form.samr_braut"
                      :clearable="true"
                      :state="submitted && errors.has('step1.commonCourse') ? false : ''"
                      :options="getCommonCourses"
                      data-vv-as="samr. braut"
                    >
                    </v-select>
                  </b-form-group>
                  <div style="margin-top: -2px" v-if="!isLearningCenter && !isSecondarySchool">
                    <div class="d-inline-block"><b-form-checkbox v-model="filter.onlyMatricular" /></div>
                    Birta eingöngu brautir sem sjást í vefþjónustu Háskólans
                  </div>
                  <div v-if="!isPrimarySchool && !isLearningCenter">
                    <b-form-group
                      :state="submitted && errors.has('step1.commonCourse') ? false : ''"
                      :invalid-feedback="errors.first('step1.commonCourse')"
                    >
                      <label label-for="subject" class="mt-2">
                        Samræmd braut - Ný námskrá

                        <i class="fa fa-info-circle" v-b-tooltip title="Braut þarf að vera tengd til að hún birtist rétt í gögnum MMS"></i>
                      </label>
                      <v-select
                        id="commonCourseNew"
                        name="commonCourseNew"
                        class="dropdownMatchHeight"
                        v-model="form.samr_braut_ny_namskra"
                        :clearable="true"
                        :state="submitted && errors.has('step1.commonCourseNew') ? false : ''"
                        :options="getCommonCoursesNew"
                        data-vv-as="samr. braut"
                      >
                      </v-select>
                    </b-form-group>
                    <div style="margin-top: -2px">
                      <div class="d-inline-block"><b-form-checkbox v-model="filter.onlyMine" /></div>
                      Birta eingöngu brautir skóla
                      <i
                        class="fa fa-info-circle"
                        v-b-tooltip
                        title="Sjá bara þær brautir sem skólinn er þegar tengdur við. Ef það er
                    ekki valið þá er hægt að sjá allar brautir sem eru samþykktar hjá MRN"
                      >
                      </i>
                    </div>
                    <div>
                      <div class="d-inline-block"><b-form-checkbox v-model="filter.onlyMinistrySerial" /></div>
                      Birta eingöngu brautir með útgáfunúmer
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="pl-0">
                <b-form-group
                  :state="submitted && errors.has('step1.comments') ? false : ''"
                  :invalid-feedback="errors.first('step1.comments')"
                >
                  <label label-for="comments"
                    >Athugasemd
                    <i
                      class="fa fa-info-circle"
                      v-b-tooltip
                      title="Þessi athugasemd sést bara þegar stjórnandi er að skoða brautina í námsskránni."
                    ></i>
                  </label>
                  <b-form-textarea
                    id="comments"
                    name="comments"
                    type="number"
                    maxLength="4000"
                    v-model="form.aths"
                    :state="submitted && errors.has('step1.comments') ? false : ''"
                    data-vv-as="athugasemd"
                  >
                  </b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </tab-content>
        <tab-content title="Umsóknir" :beforeChange="step2Valid" v-if="!isPrimarySchool">
          <div class="alert alert-info p-2" style="font-size: 13px">
            <div>
              <strong>Menntagátt</strong> er innritunarvefur Menntamálastofnunnar sem framhaldsskólar nota. Til þess að hægt sé að sækja um
              braut í menntagátt þarf að vera merkt við „Má sækja um í menntagátt“. Ath. til að hægt sé að sækja um á menntagátt þarf að
              vera opið fyrir það á kennslutímabilinu.
            </div>
            <div>
              <strong>Brautaskiptabeiðnir nemenda</strong>: Til þess að hægt sé að sækja um brautaskipti í námskerfi Innu þarf að vera merkt
              við „Má sækja um brautaskipti“. Ath. til að hægt sé að sækja um brautaskipti þarf að vera opið fyrir það í undirskólanum.
            </div>
            <div>
              <strong>Brautarskipti hjá innrituðum nemendum</strong>: Til að stjórnendur geti sett nemendur á braut þarf að vera merkt við
              „Má innrita nema á námsbraut“.
            </div>
            <div>
              <strong>Umsóknarvefur</strong>: Til þess að hægt sé að sækja um á braut í umsóknarvef Innu þarf að vera merkt við „Má sækja um
              á umsóknarvef“. Ath. til að hægt sé að sækja um þar að vera opið fyrir það á kennslutímabilinu. Þær stýringar er að finna í
              stjórnendaviðmóti umsóknarvefs.
            </div>
          </div>
          <b-form @submit.prevent="step2Valid" novalidate data-vv-scope="step2">
            <b-row>
              <b-col class="pl-0" sm="12" xs="12" md="12" lg="6">
                <b-form-group
                  :state="submitted && errors.has('step2.enrolMenntagatt') ? false : ''"
                  :invalid-feedback="errors.first('step2.enrolMenntagatt')"
                >
                  <label label-for="enrolMenntagatt">Má sækja um í menntagátt</label>
                  <b-form-checkbox id="enrolMenntagatt" name="enrolMenntagatt" v-model="form.innrit_menntagatt"> </b-form-checkbox>
                </b-form-group>
                <b-form-group :state="submitted && errors.has('step2.enrol') ? false : ''" :invalid-feedback="errors.first('step2.enrol')">
                  <label label-for="enrol">Má innrita nema á námsbraut</label>
                  <b-form-checkbox id="enrol" name="enrol" v-model="form.innrit_lokad_allar"> </b-form-checkbox>
                </b-form-group>
                <b-form-group
                  :state="submitted && errors.has('step2.enrolNew') ? false : ''"
                  :invalid-feedback="errors.first('step2.enrolNew')"
                >
                  <label label-for="enrolNew">Má sækja um á umsóknarvef </label>
                  <b-form-checkbox id="enrolNew" name="enrolNew" v-model="form.innrit_lokad_nyjar"> </b-form-checkbox>
                </b-form-group>
                <b-form-group
                  :state="submitted && errors.has('step2.enrollCourseChange') ? false : ''"
                  :invalid-feedback="errors.first('step2.enrollCourseChange')"
                >
                  <label label-for="enrollCourseChange">Má sækja um brautaskipti </label>
                  <b-form-checkbox id="enrolCourseChange" name="enrollCourseChange" v-model="form.innrit_brautaskipti"> </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col class="pl-0" sm="12" xs="12" md="12" lg="6">
                <!--b-form-group
                  :state="submitted && errors.has('step2.nrStudents') ? false : ''"
                  :invalid-feedback="errors.first('step2.nrStudents')"
                >
                  <label label-for="nrStudents">Fjöldi nema alls</label>
                  <b-form-input
                    id="nrStudents"
                    name="nrStudents"
                    type="number"
                    v-model="form.fj_nema_alls"
                    :state="submitted && errors.has('step2.nrStudents') ? false : ''"
                    data-vv-as="fj. nema"
                  >
                  </b-form-input>
                </b-form-group-->
                <b-form-group
                  :state="submitted && errors.has('step2.enrolDate') ? false : ''"
                  :invalid-feedback="errors.first('step2.enrolDate')"
                >
                  <label label-for="enrolDate">
                    Umsóknarfrestur runninn út
                    <i
                      class="fa fa-info-circle"
                      v-b-tooltip
                      :title="`Þennan dag er braut ekki lengur sýnileg í Menntagátt.
                      ${
                        !form.innrit_lokad_allar
                          ? `Ath! til að hægt sé að skrá dagsetningu þarf að vera opið fyrir umsóknir í Menntagátt.`
                          : ''
                      }`"
                    ></i>
                  </label>
                  <datepicker
                    id="enrolDate"
                    name="enrolDate"
                    v-model="form.dags_lokad"
                    :language="lang"
                    :monday-first="true"
                    format="dd.MM.yyyy"
                    :typeable="false"
                    :clear-button="true"
                    :disabled="!form.innrit_lokad_allar"
                    ref="dateEnrolOpenPickr"
                  ></datepicker>
                </b-form-group>
                <b-form-group
                  :state="submitted && errors.has('step2.nrNewStudents') ? false : ''"
                  :invalid-feedback="errors.first('step2.nrNewStudents')"
                >
                  <label label-for="nrNewStudents"
                    >Fjöldi nýnema
                    <i
                      class="fa fa-info-circle"
                      v-b-tooltip
                      title="Fjöldi nýnema úr Menntagátt sem má innrita á brautina í miðlægri keyrslu"
                    ></i>
                  </label>
                  <b-form-input
                    id="nrNewStudents"
                    name="nrNewStudents"
                    type="number"
                    v-model="form.fj_nynema"
                    :state="submitted && errors.has('step2.nrNewStudents') ? false : ''"
                    data-vv-as="fj. nýnema"
                  >
                  </b-form-input>
                </b-form-group>

                <b-form-group
                  :state="submitted && errors.has('step2.inEnglish') ? false : ''"
                  :invalid-feedback="errors.first('step2.inEnglish')"
                >
                  <label label-for="inEnglish">
                    Nám á ensku

                    <i
                      class="fa fa-info-circle"
                      v-b-tooltip
                      title="Ef þetta er valið þá birtist breski fáninn fyrir aftan brautina á umsóknarvefnum."
                    ></i>
                  </label>
                  <b-form-checkbox id="inEnglish" name="inEnglish" v-model="form.nam_a_ensku"> </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="pl-0">
                <b-form-group
                  :state="submitted && errors.has('step2.commentsApplication') ? false : ''"
                  :invalid-feedback="errors.first('step2.commentsApplication')"
                >
                  <label label-for="commentsApplication"
                    >Athugasemd á rafrænni umsókn

                    <i
                      class="fa fa-info-circle"
                      v-b-tooltip
                      title="Athugasemd sést bæði á Menntagátt og umsóknarvef þegar braut er valin"
                    ></i>
                  </label>
                  <b-form-textarea
                    id="commentsApplication"
                    name="commentsApplication"
                    type="number"
                    maxLength="2000"
                    v-model="form.aths_rafr_umsokn"
                    :state="submitted && errors.has('step2.commentsApplication') ? false : ''"
                    data-vv-as="athugasemd á rafrænni umsókn"
                  >
                  </b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </tab-content>
        <tab-content title="Val" :beforeChange="step3Valid">
          <div class="alert alert-info p-2" style="font-size: 13px">
            <b-row>
              <b-col class="pl-0">
                <div>Þessar stýringar eru notaðar þegar nemendur eru að velja áfanga fyrir komandi kennslutímabil.</div>
                <div>
                  Í stýringum skólans er valið hvort eigi að nota hámarksfjölda áfanga eða kennslustunda, auk þess er er sett þar inn
                  hámarksfjöldi.
                </div>
                <div>Ath. ef fjöldatölur eiga að vera öðruvísi á brautinni en í stýringum skólans er þetta fyllt út annars ekki.</div>
              </b-col>
              <b-col>
                <div>
                  Í stýringum skólans er eftirfarandi hámark notað:
                  <span v-if="schoolSettings.max_afangar_styring === 1">Einingar á kennslutímabili </span>
                  <span v-if="schoolSettings.max_afangar_styring === 2">Kennslustundir á viku </span>
                  <span v-if="schoolSettings.max_afangar_styring === 3">Það hámark sem fyrst er náð </span>
                </div>
                <div>Hámarksfjöldi áfanga á kennslutímabili: {{ schoolSettings.fj_afanga_onn }}</div>
                <div>Hámarksfjöldi kennslustunda á viku: {{ schoolSettings.max_timar_onn }}</div>

                <div>Lágmarksfjöldi áfanga í varavali: {{ schoolSettings.min_varaval }}</div>
                <div>Hámarksfjöldi áfanga í varavali: {{ schoolSettings.max_varaval }}</div></b-col
              >
            </b-row>
          </div>

          <b-form @submit.prevent="step3Valid" novalidate data-vv-scope="step3">
            <b-row>
              <b-col class="pl-0" sm="12" xs="12" md="12" lg="6">
                <b-form-group
                  :state="submitted && errors.has('step3.maxUnits') ? false : ''"
                  :invalid-feedback="errors.first('step3.maxUnits')"
                >
                  <label label-for="maxUnits"
                    >Hámark eininga á kennslutímabili
                    <i class="fa fa-info-circle" v-b-tooltip title="Hámarksfjöldi eininga sem nemendur mega velja á kennslutímabili"></i>
                  </label>
                  <b-form-input
                    id="maxUnits"
                    name="maxUnits"
                    type="number"
                    v-model="form.einingar_max_onn"
                    :state="submitted && errors.has('step3.maxUnits') ? false : ''"
                    data-vv-as="hámark eininga"
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group
                  :state="submitted && errors.has('step3.maxClasses') ? false : ''"
                  :invalid-feedback="errors.first('step3.maxClasses')"
                >
                  <label label-for="maxClasses"
                    >Hámarksfjöldi kennslustunda á viku
                    <i
                      class="fa fa-info-circle"
                      v-b-tooltip
                      title="Hámark hversu margar kennslustundir nemendur mega skrá sig í á viku."
                    ></i
                  ></label>
                  <b-form-input
                    id="maxClasses"
                    name="maxClasses"
                    type="number"
                    v-model="form.timar_max_onn"
                    :state="submitted && errors.has('step3.maxClasses') ? false : ''"
                    data-vv-as="hámark kennslustundir"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col class="pl-0" sm="12" xs="12" md="12" lg="6">
                <b-form-group
                  :state="submitted && errors.has('step3.minBackup') ? false : ''"
                  :invalid-feedback="errors.first('step3.minBackup')"
                >
                  <label label-for="minBackup"
                    >Lágmarksfjöldi varavals á kennslutímabili
                    <i
                      class="fa fa-info-circle"
                      v-b-tooltip
                      title="Ef fyllt út þá verða nemendur að setja í varaval ákveðinn lágmarksfjölda"
                    ></i>
                  </label>
                  <b-form-input
                    id="minBackup"
                    name="minBackup"
                    type="number"
                    v-model="form.min_varaval"
                    :state="submitted && errors.has('step3.minBackup') ? false : ''"
                    data-vv-as="lágmark varaval"
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group
                  :state="submitted && errors.has('step3.maxBackup') ? false : ''"
                  :invalid-feedback="errors.first('step3.maxBackup')"
                >
                  <label label-for="maxBackup"
                    >Hámarksfjöldi varavals á kennslutímabili
                    <i class="fa fa-info-circle" v-b-tooltip title="Hámarksfjöldi varavals sem nemendur mega velja á kennslutímabili"></i>
                  </label>
                  <b-form-input
                    id="maxBackup"
                    name="maxBackup"
                    type="number"
                    v-model="form.max_varaval"
                    :state="submitted && errors.has('step3.maxBackup') ? false : ''"
                    data-vv-as="hámark varaval"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </tab-content>

        <tab-content title="Skírteini" :beforeChange="step4Valid">
          <div class="alert alert-info p-2" style="font-size: 13px">
            Eftirfarandi stillingar eru einungis fyrir skírteini nemenda, það fer eftir skírteinum hvers skóla hvað þarf að fylla út.
          </div>
          <b-form @submit.prevent="step4Valid" novalidate data-vv-scope="step4">
            <b-row>
              <b-col class="pl-0" sm="12" xs="12" md="12" lg="6">
                <b-form-group
                  :state="submitted && errors.has('step4.hourTerm') ? false : ''"
                  :invalid-feedback="errors.first('step4.hourTerm')"
                >
                  <label label-for="hourTerm">Fj. tíma á kennslutímabili</label>
                  <b-form-input
                    id="hourTerm"
                    name="hourTerm"
                    type="number"
                    v-model="form.klst_alls"
                    :state="submitted && errors.has('step4.hourTerm') ? false : ''"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col class="pl-0" sm="12" xs="12" md="12" lg="6">
                <b-form-group
                  :state="submitted && errors.has('step4.attendanceDiploma') ? false : ''"
                  :invalid-feedback="errors.first('step4.attendanceDiploma')"
                >
                  <label label-for="attendanceDiploma">Birta skólasóknaráfanga á skírteini</label>
                  <b-form-checkbox
                    id="attendanceDiploma"
                    name="attendanceDiploma"
                    v-model="form.skolasoknarafangi_a_skirteini"
                    :state="submitted && errors.has('step4.attendanceDiploma') ? false : ''"
                  >
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="pl-0" sm="12" xs="12" md="12" lg="6">
                <strong>Íslenska</strong>
                <b-form-group>
                  <label> Heiti* </label>
                  <b-form-input id="name" name="name" v-model="form.heiti" disabled> </b-form-input>
                </b-form-group>
                <b-form-group
                  :state="submitted && errors.has('step4.textBefore') ? false : ''"
                  :invalid-feedback="errors.first('step4.textBefore')"
                >
                  <label label-for="textBefore"> Texti á undan heiti á skírteini</label>
                  <b-form-input
                    id="textBefore"
                    name="textBefore"
                    v-model="form.texti_skirteini"
                    :state="submitted && errors.has('step4.textBefore') ? false : ''"
                    data-vv-as="texti á undan"
                    maxLength="100"
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group
                  :state="submitted && errors.has('step4.diplomaName') ? false : ''"
                  :invalid-feedback="errors.first('step4.diplomaName')"
                >
                  <label label-for="diplomaName"> Heiti á skírteini</label>
                  <b-form-input
                    id="diplomaName"
                    name="diplomaName"
                    v-model="form.heiti_skirteini"
                    :state="submitted && errors.has('step4.diplomaName') ? false : ''"
                    data-vv-as="heiti"
                    maxLength="200"
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group
                  :state="submitted && errors.has('step4.textAfter') ? false : ''"
                  :invalid-feedback="errors.first('step4.textAfter')"
                >
                  <label label-for="textAfter"> Texti á eftir heiti á skírteini</label>
                  <b-form-input
                    id="textAfter"
                    name="textAfter"
                    v-model="form.texti_skirteini2"
                    :state="submitted && errors.has('step4.textAfter') ? false : ''"
                    data-vv-as="texti á eftir"
                    maxLength="300"
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group
                  :state="submitted && errors.has('step4.diplomaComment') ? false : ''"
                  :invalid-feedback="errors.first('step4.diplomaComment')"
                >
                  <label label-for="diplomaComment"> Athugasemdir á skírteini</label>
                  <b-form-input
                    id="diplomaComment"
                    name="diplomaComment"
                    v-model="form.aths_skirteini"
                    :state="submitted && errors.has('step4.diplomaComment') ? false : ''"
                    data-vv-as="athugasemd"
                    maxLength="4000"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col class="pl-0" sm="12" xs="12" md="12" lg="6">
                <strong>Enska</strong>
                <b-form-group :state="submitted && errors.has('step4.name') ? false : ''" :invalid-feedback="errors.first('step4.nameEn')">
                  <label label-for="nameEn"> Heiti </label>
                  <b-form-input
                    id="nameEn"
                    name="nameEn"
                    v-model="form.heiti_ens"
                    :state="submitted && errors.has('step4.nameEn') ? false : ''"
                    data-vv-as="heiti"
                    maxLength="200"
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group
                  :state="submitted && errors.has('step4.textBeforeEn') ? false : ''"
                  :invalid-feedback="errors.first('step4.textBeforeEn')"
                >
                  <label label-for="textBeforeEn"> Texti á undan heiti á skírteini</label>
                  <b-form-input
                    id="textBeforeEn"
                    name="textBeforeEn"
                    v-model="form.texti_skirteini_enska"
                    :state="submitted && errors.has('step4.textBeforeEn') ? false : ''"
                    data-vv-as="texti á undan"
                    maxLength="100"
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group
                  :state="submitted && errors.has('step4.diplomaNameEn') ? false : ''"
                  :invalid-feedback="errors.first('step4.diplomaNameEn')"
                >
                  <label label-for="diplomaNameEn"> Heiti á skírteini</label>
                  <b-form-input
                    id="diplomaNameEn"
                    name="diplomaNameEn"
                    v-model="form.heiti_skirteini_ens"
                    :state="submitted && errors.has('step4.diplomaNameEn') ? false : ''"
                    data-vv-as="heiti"
                    maxLength="200"
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group
                  :state="submitted && errors.has('step4.textAfterEn') ? false : ''"
                  :invalid-feedback="errors.first('step4.textAfterEn')"
                >
                  <label label-for="textAfterEn"> Texti á eftir heiti á skírteini</label>
                  <b-form-input
                    id="textAfterEn"
                    name="textAfterEn"
                    v-model="form.texti_skirteini2_enska"
                    :state="submitted && errors.has('step4.textAfterEn') ? false : ''"
                    data-vv-as="texti á eftir"
                    maxLength="300"
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group
                  :state="submitted && errors.has('step4.diplomaCommentEn') ? false : ''"
                  :invalid-feedback="errors.first('step4.diplomaCommentEn')"
                >
                  <label label-for="diplomaCommentEn"> Athugasemdir á skírteini</label>
                  <b-form-input
                    id="diplomaCommentEn"
                    name="diplomaCommentEn"
                    v-model="form.aths_skirteini_ens"
                    :state="submitted && errors.has('step4.diplomaCommentEn') ? false : ''"
                    data-vv-as="athugasemd"
                    maxLength="4000"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </tab-content>

        <tab-content title="Uppbygging">
          <CourseStructureList :courseId="id" :copyId="copy" v-if="id" />
        </tab-content>
      </form-wizard>
    </div>
  </page>
</template>

<script>
import moment from 'moment';
import structure from '@/api/structure';
import types from '@/api/types';
import curriculum from '@/api/curriculum';
import reports from '@/api/reports';
import { mapActions, mapGetters } from 'vuex';
import { FormWizard, TabContent } from 'vue-form-wizard';

import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';

import CourseStructureList from '@/components/curriculum/courses/CourseStructureList.vue';

export default {
  name: 'create-course',
  components: {
    FormWizard,
    TabContent,
    Datepicker,
    CourseStructureList,
  },
  computed: {
    canRead() {
      return this.loggedInUserHasReadPermission('namskra_brautir');
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_brautir');
    },
    isSecondarySchool() {
      return this.schoolSettings && this.schoolSettings.tegund_skola_id === 1;
    },
    isPrimarySchool() {
      return this.schoolSettings && this.schoolSettings.tegund_skola_id === 3;
    },
    isLearningCenter() {
      return this.schoolSettings && this.schoolSettings.tegund_skola_id === 4;
    },
    getCommonCourses() {
      if (this.isLearningCenter) {
        return this.commonCourses.filter(x => x.kodi.startsWith('FRÆ'));
      }
      return this.commonCourses.filter(x => !this.filter.onlyMatricular || x.student === 1);
    },
    getCommonCoursesNew() {
      return this.commonCoursesNew.filter(
        x => (!this.filter.onlyMine || x.is_mine) && (!this.filter.onlyMinistrySerial || x.ministry_serial),
      );
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      lang: is,
      startIndex: 0,
      codeExists: false,
      id: '',
      copy: '',
      form: {
        heiti: '',
        braut_kodi: '',
        einingar_alls: '',
        sjalfvirkt_val: false,
        undirskoli: '',
        deildir: [],
        samr_braut: '',
        samr_braut_ny_namskra: '',
        aths: '',

        innrit_lokad_allar: false,
        innrit_lokad_nyjar: false,
        innrit_menntagatt: false,
        innrit_brautaskipti: false,
        dags_lokad: '',
        fj_nema_alls: '',
        fj_nynema: '',
        aths_rafr_umsokn: '',
        nam_a_ensku: false,

        einingar_max_onn: '',
        timar_max_onn: '',
        min_varaval: '',
        max_varaval: '',

        skolasoknarafangi_a_skirteini: false,
        heiti_ens: '',
        texti_skirteini: '',
        heiti_skirteini: '',
        texti_skirteini2: '',
        heiti_skirteini_ens: '',
        texti_skirteini_enska: '',
        texti_skirteini2_enska: '',
        aths_skirteini: '',
        aths_skirteini_ens: '',
        klst_alls: '',
      },
      filter: {
        onlyMine: true,
        onlyMinistrySerial: true,
        onlyMatricular: false,
      },
      submitted: false,
      loading: false,
      divisions: [],
      departments: [],
      commonCourses: [],
      commonCoursesNew: [],
    };
  },
  methods: {
    async validateCode() {
      this.codeExists = false;
      if (this.form.braut_kodi.length > 0 && this.form.undirskoli && this.form.undirskoli.division_id) {
        try {
          const response = await curriculum.checkCourseCodeExists({
            id: this.id,
            division: this.form.undirskoli.division_id,
            code: this.form.braut_kodi,
          });
          this.codeExists = response.data.already_exists > 0;
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    async step1Valid() {
      this.submitted = true;
      this.errorList = [];
      await this.$validator.validateAll('step1').then(response => {
        if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        return false;
      });
      this.submitted = false;
      return true;
    },
    async step2Valid() {
      this.submitted = true;
      this.errorList = [];
      await this.$validator.validateAll('step2').then(response => {
        if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        return false;
      });
      this.submitted = false;
      return true;
    },
    async step3Valid() {
      this.submitted = true;
      this.errorList = [];
      await this.$validator.validateAll('step3').then(response => {
        if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        return false;
      });
      this.submitted = false;
      return true;
    },
    async step4Valid() {
      this.submitted = true;
      this.errorList = [];
      await this.$validator.validateAll('step4').then(response => {
        if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        return false;
      });
      this.submitted = false;
      return true;
    },
    completed() {
      this.$router.push({ name: 'Course', params: { id: this.id } });
    },
    async loadDivisions() {
      try {
        const response = await structure.getDivisionList();
        this.divisions = response.data.items.map(x => ({
          ...x,
          label: `${x.code} - ${x.name}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadDepartments() {
      try {
        const response = await structure.getDepartmentList();
        this.departments = response.data.items.map(x => ({
          ...x,
          label: `${x.name}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadCommonCourses() {
      try {
        const response = await types.getCommonCourses();
        this.commonCourses = response.data.items.map(x => ({
          ...x,
          label: `${x.kodi} - ${x.heiti}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadCommonCoursesNew() {
      try {
        this.commonCoursesNew = [];
        const response = await reports.getMinistryConfirmedCourses();
        this.commonCoursesNew = response.data.items.map(x => ({
          ...x,
          label: `${x.title} ${x.ministry_serial || ' '}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadCourse() {
      try {
        this.loading = true;

        const id = this.id ? this.id : this.copy;
        const response = await curriculum.getCourseById(id);
        const item = response.data.items[0];

        this.form.heiti = item.heiti;
        this.form.braut_kodi = item.braut_kodi;
        this.form.einingar_alls = item.einingar_alls;
        this.form.sjalfvirkt_val = item.sjalfvirkt_val === 1;
        this.form.aths = item.aths;
        this.form.innrit_lokad_allar = item.innrit_lokad_allar === 1;
        this.form.innrit_lokad_nyjar = item.innrit_lokad_nyjar === 1;
        this.form.innrit_menntagatt = item.innrit_menntagatt === 1;
        this.form.innrit_brautaskipti = item.innrit_brautaskipti === 1;
        this.form.dags_lokad = item.dags_lokad ? moment(item.dags_lokad, 'DD.MM.YYYY') : '';
        this.form.fj_nema_alls = item.fj_nema_alls;
        this.form.fj_nynema = item.fj_nynema;
        this.form.aths_rafr_umsokn = item.fj_nynema;
        this.form.nam_a_ensku = item.nam_a_ensku === 1;
        this.form.einingar_max_onn = item.einingar_max_onn;
        this.form.timar_max_onn = item.timar_max_onn;
        this.form.min_varaval = item.min_varaval;
        this.form.max_varaval = item.min_varaval;
        this.form.skolasoknarafangi_a_skirteini = item.skolasoknarafangi_a_skirteini === 1;
        this.form.heiti_ens = item.heiti_ens;
        this.form.texti_skirteini = item.texti_skirteini;
        this.form.heiti_skirteini = item.heiti_skirteini;
        this.form.texti_skirteini2 = item.texti_skirteini2;
        this.form.heiti_skirteini_ens = item.heiti_skirteini_ens;
        this.form.texti_skirteini_enska = item.texti_skirteini_enska;
        this.form.texti_skirteini2_enska = item.texti_skirteini2_enska;
        this.form.aths_skirteini = item.aths_skirteini;
        this.form.aths_skirteini_ens = item.aths_skirteini_ens;
        this.form.klst_alls = item.klst_alls;

        await this.loadDivisions();
        this.form.undirskoli = this.divisions.find(x => x.division_id === item.undirskoli_id);

        await this.loadDepartments();
        this.form.deildir = this.departments.filter(x => item.deildir_ids.find(y => y.deild_id === x.department_id) !== undefined);

        await this.loadCommonCourses();
        this.form.samr_braut = this.commonCourses.find(x => x.samr_braut === item.samr_braut);

        await this.loadCommonCoursesNew();
        this.form.samr_braut_ny_namskra = this.commonCoursesNew.find(x => x.programme_id === item.samr_braut_ny_namskra);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async saveCourse(props) {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll('step1', 'step2', 'step3', 'step4').then(resp => {
          if (!resp) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });
        const item = {
          undirskoli_id: this.form.undirskoli.division_id,
          heiti: this.form.heiti,
          deildir: this.form.deildir.map(x => x.department_id).join(','),
          einingar_alls: this.form.einingar_alls,
          einingar_max_onn: this.form.einingar_max_onn,
          aths: this.form.aths,
          innrit_lokad_allar: this.form.innrit_lokad_allar ? 1 : 0,
          innrit_lokad_nyjar: this.form.innrit_lokad_nyjar ? 1 : 0,
          innrit_menntagatt: this.form.innrit_menntagatt ? 1 : 0,
          innrit_brautaskipti: this.form.innrit_brautaskipti ? 1 : 0,
          dags_lokad: this.form.dags_lokad ? moment(this.form.dags_lokad).format('DD.MM.YYYY') : '',
          samr_braut: this.form.samr_braut ? this.form.samr_braut.samr_braut : '',
          braut_kodi: this.form.braut_kodi,
          heiti_ens: this.form.heiti_ens,
          heiti_skirteini: this.form.heiti_skirteini,
          sjalfvirkt_val: this.form.sjalfvirkt_val ? 1 : 0,
          texti_skirteini: this.form.texti_skirteini,
          timar_max_onn: this.form.timar_max_onn,
          aths_rafr_umsokn: this.form.aths_rafr_umsokn,
          min_varaval: this.form.min_varaval,
          max_varaval: this.form.max_varaval,
          skolasoknarafangi_a_skirteini: this.form.skolasoknarafangi_a_skirteini ? 1 : 0,
          nam_a_ensku: this.form.nam_a_ensku ? 1 : 0,
          heiti_skirteini_ens: this.form.heiti_skirteini_ens,
          texti_skirteini2: this.form.texti_skirteini2,
          texti_skirteini_enska: this.form.texti_skirteini_enska,
          texti_skirteini2_enska: this.form.texti_skirteini2_enska,
          fj_nema_alls: this.form.fj_nema_alls,
          fj_nynema: this.form.fj_nynema,
          aths_skirteini: this.form.aths_skirteini,
          aths_skirteini_ens: this.form.aths_skirteini_ens,
          samr_braut_ny_namskra: this.form.samr_braut_ny_namskra ? this.form.samr_braut_ny_namskra.programme_id : '',
          klst_alls: this.form.klst_alls,
        };

        let response = null;
        if (this.id) {
          response = await curriculum.updateCourse(this.id, item);
          this.displaySuccess('Braut breytt');
        } else {
          response = await curriculum.createCourse(item);
          this.id = response.data.id;
          this.displaySuccess('Braut stofnuð');
        }
        this.$router.push({ name: 'CreateCourse', query: { id: this.id, step: 4, copy: this.copy } });

        props.nextTab();
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);

        if (e.response && e.response.data && e.response.data.error) {
          this.displayError(e.response.data.error);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    if (this.$route.query && this.$route.query.step) {
      this.startIndex = parseInt(this.$route.query.step, 10);
    }
    if (this.$route.query && (this.$route.query.id || this.$route.query.copy)) {
      this.id = this.$route.query.id;
      this.copy = this.$route.query.copy;
      this.loadCourse();
    } else {
      this.loadDivisions();
      this.loadDepartments();
      this.loadCommonCourses();
      this.loadCommonCoursesNew();
    }
  },
};
</script>
