<template>
  <div>
    <table class="table">
      <thead>
        <tr class="table-search">
          <td colspan="6" style="padding-top: 0">
            <b-form-input v-model="search.name" class="search" placeholder="Heiti búnaðs" />
          </td>
          <td colspan="2" style="max-width: 80px">
            <div class="float-right" style="margin-top: -10px">
              <CustomButton type="updateDark" title="Magnbreyta" v-if="canEdit && selectedCount > 0" 
                @click.native="openMassEditSlidebar()" />
              <CustomButton type="create" title="Nýr búnaður" v-if="canEdit" @click.native="openSlidebar()" />
            </div>
          </td>
        </tr>
        <tr>
          <th v-if="canEdit">
            <b-form-checkbox v-model="selectAll" @input="updateSelected()"></b-form-checkbox>
          </th>
          <th>
            <LinkOrder title="Heiti" :filteredList="getList" :list="list" column="heiti" />
          </th>
          <th>
            <LinkOrder title="Lýsing" :filteredList="getList" :list="list" column="lysing" />
          </th>
          <th>
            <LinkOrder title="Staðsetning" :filteredList="getList" :list="list" column="stadsetning" />
          </th>
          <th>
            <LinkOrder title="Virkt" :filteredList="getList" :list="list" column="virkt" />
          </th>
          <th>
            <LinkOrder title="Má starfsmaður panta" :filteredList="getList" :list="list" column="ma_starfsmadur_panta" />
          </th>
          <th>
            <LinkOrder title="Má nemandi panta" :filteredList="getList" :list="list" column="ma_nemandi_panta" />
          </th>
          <th>
            <div class="d-inline-block pr-1" style="vertical-align: middle">Sýna óvirk</div>
            <div class="d-inline-block"><b-form-checkbox v-model="showInactive"></b-form-checkbox></div>
            <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="cursor-pointer float-right">
              <i class="fa fa-file-excel-o excelText"></i>
            </download-csv>
          </th>
        </tr>
      </thead>
      <tbody>
        <TableRowLoader v-if="loading" :colspan="canEdit ? 8 : 7" :center="true" />
        <TableRowEmpty v-if="!loading && this.list.length === 0" :colspan="canEdit ? 8 : 7" :center="true" />
        <tr
          v-for="item in getList"
          :key="item.bunadur_id"
          :class="{
            'active-table-row': selectedItem && selectedItem.bunadur_id === item.bunadur_id,
            'cursor-pointer': showSlidebar || showOrderSlidebar,
          }"
          @click="checkSlidebar(item)"
        >
          <td v-if="canEdit">
            <b-form-checkbox v-model="item.selected"></b-form-checkbox>
          </td>
          <td>{{ item.heiti }}</td>
          <td>{{ item.lysing }}</td>
          <td>{{ item.stadsetning }}</td>
          <td><yes-no :item="item.virkt" /></td>
          <td><yes-no :item="item.ma_starfsmadur_panta" /></td>
          <td><yes-no :item="item.ma_nemandi_panta" /></td>
          <td>
            <div class="float-right" style="white-space: nowrap">
              <b-btn
                :to="{ name: 'Equipments', query: { id: item.bunadur_id } }"
                target="_blank"
                size="sm"
                variant="primary"
                v-b-tooltip
                title="Skoða pantanir"
                v-if="canViewOrders"
              >
                <i class="fa fa fa-list" />
              </b-btn>
              <b-btn size="sm" variant="dark" class="ml-2" v-b-tooltip title="Breyta" @click.prevent="openSlidebar(item)" v-if="canEdit">
                <i class="fa fa-pencil" />
              </b-btn>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <order-slidebar v-if="showOrderSlidebar" :item="selectedItem" @closeSlidebar="closeSlidebar"> </order-slidebar>
    <equipment-slidebar v-if="showSlidebar" :item="selectedItem" @closeSlidebar="closeAndReloadSlidebar"> </equipment-slidebar>
    <equipment-mass-edit-slidebar v-if="showMassEditSlidebar" :items="massEditItems" @closeSlidebar="closeAndReloadSlidebar">
    </equipment-mass-edit-slidebar>
  </div>
</template>

<script>
import structure from '@/api/structure';

import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

import CustomButton from '@/components/common/CustomButton.vue';

import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';
import YesNo from '@/components/common/YesNo.vue';

import OrderSlidebar from '@/components/school/equipments/OrderSlidebar.vue';
import EquipmentSlidebar from '@/components/school/school/equipments/EquipmentSlidebar.vue';
import EquipmentMassEditSlidebar from '@/components/school/school/equipments/EquipmentMassEditSlidebar.vue';

export default {
  name: 'equipments',
  components: {
    TableRowLoader,
    TableRowEmpty,
    LinkOrder,
    OrderSlidebar,
    EquipmentSlidebar,
    YesNo,
    CustomButton,
    EquipmentMassEditSlidebar,
  },
  computed: {
    selectedCount() {
      return this.list.filter(x => x.selected).length;
    },
    getList() {
      return this.list.filter(
        x =>
          (this.search.name.length === 0 || (x.heiti || '').toUpperCase().startsWith(this.search.name.toUpperCase())) &&
          (this.showInactive || x.virkt === 1),
      );
    },
    getExcelData() {
      const newLine = [
        [],
        ['Búnaður'],
        [],
        ['Heiti búnaðar', 'Lýsing', 'Staðsetning'],
        ...this.getList.map(x => [x.heiti, x.lysing, x.stadsetning]),
      ];
      return newLine;
    },
    getExcelFileName() {
      return `bunadur_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    canViewOrders() {
      return this.loggedInUserHasReadPermission('beidnir_bunadur');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      loading: false,
      list: [],
      showOrderSlidebar: false,
      showSlidebar: false,
      selectedItem: {},
      search: {
        name: '',
      },
      showInactive: false,
      selectAll: false,
      massEditItems: [],
      showMassEditSlidebar: false,
    };
  },
  methods: {
    async loadList() {
      try {
        this.loading = true;
        this.selectAll = false;
        const response = await structure.getEquipmentList();
        this.list = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    updateSelected() {
      this.getList.forEach(x => {
        this.$set(x, 'selected', this.selectAll);
      });
    },
    openSlidebar(item) {
      this.selectedItem = item;
      this.showSlidebar = true;
    },
    openOrderSlidebar(item) {
      this.selectedItem = item;
      this.showOrderSlidebar = true;
    },
    openMassEditSlidebar() {
      this.massEditItems = this.list.filter(x => x.selected);
      this.showMassEditSlidebar = true;
    },
    closeSlidebar() {
      this.showOrderSlidebar = false;
      this.showSlidebar = false;
      this.showMassEditSlidebar = false;
      this.selectedItem = {};
      this.massEditItems = [];
    },
    closeAndReloadSlidebar() {
      this.closeSlidebar();
      this.loadList();
    },
    checkSlidebar(item) {
      if (this.showOrderSlidebar) {
        this.openOrderSlidebar(item);
      } else if (this.showSlidebar) {
        this.openSlidebar(item);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadList();
  },
};
</script>
