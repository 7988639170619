<template>
  <Slidebar :large="true" @closeMe="closeSlidebar">
    <h4>{{ item.nafn }} - Áfangar</h4>
    <br />
    <h5 class="mb-3"><strong>Áfangar í aðalskóla</strong></h5>
    <Loader v-if="loading.main" />
    <div v-if="main.length === 0 && !loading.main">Engir áfangar fundust</div>
    <table class="table less-padding" style="font-size: 13px" v-if="main.length > 0">
      <tr>
        <th>Aðalskóli</th>
        <th>Brautir</th>
        <th>Kennslutímabil</th>
        <th>Áfangi</th>
        <th>Einingar</th>
      </tr>
      <tr v-for="(item, index) in main" :key="index">
        <td>{{ item.skoli_heiti }}</td>
        <td>{{ item.brautir }}</td>
        <td>{{ item.onn_heiti }}</td>
        <td>{{ item.afanga_heiti }}<br />{{ item.namsgrein_afanganr }}</td>
        <td>{{ item.einingar }}</td>
      </tr>
    </table>
    <br />
    <h5 class="mb-3"><strong>Áfangar í núverandi skóla</strong></h5>
    <Loader v-if="loading.current" />
    <div v-if="current.length === 0 && !loading.current">Engir áfangar fundust</div>
    <table class="table less-padding" style="font-size: 13px" v-if="current.length > 0">
      <tr>
        <th>Aðalskóli</th>
        <th>Brautir</th>
        <th>Kennslutímabil</th>
        <th>Áfangi</th>
        <th>Einingar</th>
      </tr>
      <tr v-for="(item, index) in current" :key="index">
        <td>{{ item.skoli_heiti }}</td>
        <td>{{ item.brautir }}</td>
        <td>{{ item.onn_heiti }}</td>
        <td>{{ item.afanga_heiti }}<br />{{ item.namsgrein_afanganr }}</td>
        <td>{{ item.einingar }}</td>
      </tr>
    </table>
  </Slidebar>
</template>

<script>
import reports from '@/api/reports';
import Slidebar from '@/components/common/Slidebar.vue';
import Loader from '@/components/common/Loader.vue';
import { mapActions } from 'vuex';

export default {
  name: 'student-course-slide-bar',
  props: ['item'],
  components: {
    Slidebar,
    Loader,
  },
  data() {
    return {
      loading: {
        current: false,
        main: false,
      },
      current: [],
      main: [],
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async loadMain() {
      try {
        this.main = [];
        if (this.item.skoli_id) {
          this.loading.main = true;
          const response = await reports.getHomeschoolStudentCourses(this.item.kennitala, this.item.skoli_id);
          this.main = response.data.items;
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.main = false;
      }
    },
    async loadCurrent() {
      try {
        this.current = [];
        this.loading.current = true;
        const response = await reports.getHomeschoolStudentCourses(this.item.kennitala, '');
        this.current = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.current = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    item() {
      this.current = [];
      this.main = [];
      this.loadCurrent();
      this.loadMain();
    },
  },
  created() {
    this.current = [];
    this.main = [];
    this.loadCurrent();
    this.loadMain();
  },
};
</script>

<style lang="scss"></style>
