<template>
  <Slidebar @closeMe="closeSlidebar" :large="true">
    <div class="d-inline-block mt-2">
      <h4>Magnbreyta tegund einkunnar</h4>
      <b-form-group label="Virkur" label-for="virkur" class="mb-1">
        <v-select id="virkur" name="virkur" v-model="form.gildi" :options="yesNo" :clearable="false"> </v-select>
      </b-form-group>
      <b-btn variant="primary" class="mr-2" v-if="canEdit" @click="validateBeforeSubmit"> Breyta </b-btn>
      <div class="mt-3">
        <strong>Færslur sem verið er að breyta</strong>
      </div>
      <span v-for="(item, idx) in items" :key="item.teg_eink"> <span v-if="idx != 0">, </span>{{ item.heiti }} </span>
    </div>
  </Slidebar>
</template>

<script>
import types from '@/api/types';
import { mapActions, mapGetters } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';

export default {
  name: 'module-mass-edit-slidebar',
  props: ['items'],
  components: {
    Slidebar,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_afangar');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {
      saving: false,
      form: {
        gildi: { id: 1, label: 'Já' },
      },
      yesNo: [
        { id: 1, label: 'Já' },
        { id: 0, label: 'Nei' },
      ],
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async validateBeforeSubmit() {
      try {
        this.saving = true;
        const q = [];
        this.items.forEach(i => {
          const data = {
            heiti: i.heiti,
            virk: this.form.gildi.id,
            rod: i.rod,
            kodi: i.kodi,
            kodi_ferill: i.kodi_ferill,
            i_medal: i.i_medal,
          };
          q.push(types.updateGrade(i.teg_eink, data));
        });
        Promise.all(q)
          .then(() => {
            this.displaySuccess('Tegundum einkunnar hefur verið breytt.');
            this.$emit('closeAndReloadSlidebar');
          })
          .catch(e => {
            if (e.response && e.response.data && e.response.data.error) {
              this.displayError(e.response.data.error);
            } else {
              this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            }
          });
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.saving = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>

<style lang="scss"></style>
