<template>
  <div>
    <div class="mb-3 noprint">
      <CustomButton type="create" title="Ný stofa" @click.native="createItem()" v-if="canEdit"/>
      <CustomButton type="updateDark" class="float-right" title="Breyta byggingu" @click.native="openBuildingSlidebar()" v-if="canEdit"/>
    </div>
    <table class="table noprint">
      <thead>
        <tr>
          <th v-if="canEdit">
            <b-btn variant="warning" class="btn-xs" style="font-size: 12px" @click="openMassEditSlidebar()" v-if="selectedItems.length > 0">
              <i class="fa fa-pencil"></i>
            </b-btn>
          </th>
          <th>
            <LinkOrder title="Númer stofu" :filteredList="getList" :list="list" column="stofa_eink" />
          </th>
          <th>
            <LinkOrder title="Heiti stofu" :filteredList="getList" :list="list" column="stofa_heiti" secondColumn="stofa_eink" />
          </th>
          <th>
            <LinkOrder title="Tegund stofu" :filteredList="getList" :list="list" column="teg_stofu" secondColumn="stofa_eink" />
          </th>
          <th>
            <LinkOrder title="Bygging" :filteredList="getList" :list="list" column="bygging_heiti" secondColumn="stofa_eink" />
          </th>
          <th>
            <LinkOrder title="Fjöldi" :filteredList="getList" :list="list" column="fjoldi" secondColumn="stofa_eink" />
          </th>
          <th>
            <LinkOrder title="Sérkenni" :filteredList="getList" :list="list" column="serkenni" secondColumn="stofa_eink" />
          </th>
          <th>
            <LinkOrder title="Má panta" :filteredList="getList" :list="list" column="ma_panta" secondColumn="stofa_eink" />
          </th>
          <th v-if="showInactive">
            <LinkOrder title="Virk" :filteredList="getList" :list="list" column="virk" secondColumn="stofa_eink" />
          </th>
          <th>
            <div class="d-inline-block">
              <b-form-checkbox v-model="showInactive"></b-form-checkbox>
            </div>
            <span class="d-inline-block" style="vertical-align: middle">Sýna óvirkar</span>
            <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="cursor-pointer float-right d-inline-block">
              <i class="fa fa-file-excel-o excelText"></i>
            </download-csv>
          </th>
        </tr>
      </thead>
      <tbody>
        <TableRowLoader v-if="loading" :colspan="showInactive ? 10 : 9" :center="true" />
        <TableRowEmpty v-if="!loading && this.list.length === 0" :colspan="showInactive ? 10 : 9" :center="true" />
        <tr
          v-for="item in getList"
          :key="item.stofa_id"
          :class="{
            'active-table-row': selectedItem && selectedItem.stofa_id === item.stofa_id,
            'cursor-pointer': showSlidebar || showTimetableSlidebar,
          }"
          @click="checkSlidebar(item)"
        >
          <td><b-form-checkbox v-model="item.selected"></b-form-checkbox></td>
          <td>{{ item.stofa_eink }}</td>
          <td>{{ item.stofa_heiti }}</td>
          <td>{{ item.teg_stofu }}</td>
          <td>{{ item.bygging_heiti }}</td>
          <td>{{ item.fjoldi }}</td>
          <td>{{ item.serkenni }}</td>
          <td><yes-no :item="item.ma_panta"></yes-no></td>
          <td v-if="showInactive"><yes-no :item="item.virk"></yes-no></td>
          <td>
            <div class="float-right">
              <b-btn
                size="sm"
                variant="primary"
                class="mr-2 btn-xs"
                title="Skoða stundatöflu"
                v-b-tooltip
                @click.prevent="openTimetableSlidebar(item)"
              >
                <i class="fa fa-calendar" />
              </b-btn>
              <b-btn
                size="sm"
                variant="primary"
                class="btn-xs mr-2"
                title="Skoða ítarupplýsingar"
                v-b-tooltip
                @click.prevent="openSlidebar(item)"
              >
                <i class="fa fa-search" />
              </b-btn>
              <b-btn size="sm" variant="dark" class="btn-xs" @click.prevent="editItem(item)" title="Breyta" v-b-tooltip v-if="canEdit">
                <i class="fa fa-pencil" />
              </b-btn>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <classroom-slidebar v-if="showSlidebar" :item="selectedItem" :editing="editing" :creating="creating" @closeSlidebar="closeSlidebar">
    </classroom-slidebar>
    <timetable-slidebar v-if="showTimetableSlidebar" :item="selectedItem" type="classroom" @closeSlidebar="closeSlidebar">
    </timetable-slidebar>
    <building-slidebar v-if="showBuildingSlidebar" :item="building" @closeSlidebar="closeSlidebar"> </building-slidebar>
    <classroom-mass-edit-slidebar v-if="showMassEditSlidebar" :items="selectedItems" @closeSlidebar="closeSlidebar">
    </classroom-mass-edit-slidebar>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';
import CustomButton from '@/components/common/CustomButton.vue';
import ClassroomSlidebar from '@/components/school/school/buildingsclassrooms/ClassroomSlidebar.vue';
import ClassroomMassEditSlidebar from '@/components/school/school/buildingsclassrooms/ClassroomMassEditSlidebar.vue';
import BuildingSlidebar from '@/components/school/school/buildingsclassrooms/BuildingSlidebar.vue';
import TimetableSlidebar from '@/components/common/TimetableSlidebar.vue';
import YesNo from '@/components/common/YesNo.vue';

export default {
  name: 'classrooms',
  components: {
    TableRowLoader,
    TableRowEmpty,
    LinkOrder,
    ClassroomSlidebar,
    TimetableSlidebar,
    CustomButton,
    BuildingSlidebar,
    YesNo,
    ClassroomMassEditSlidebar,
  },
  props: ['building', 'list'],
  computed: {
    selectedItems() {
      return this.list.filter(x => x.selected);
    },
    getList() {
      return this.list.filter(x => this.showInactive || x.virk === 1);
    },
    getExcelData() {
      const newLine = [
        [],
        ['Stofur'],
        [],
        ['Númer stofu', 'Heiti stofu', 'Tegund stofu', 'Bygging', 'Fjöldi', 'Sérkenni', 'Virk'],
        ...this.getList.map(x => [
          x.stofa_eink,
          x.stofa_heiti,
          x.teg_stofu,
          x.bygging_heiti,
          x.fjoldi,
          x.serkenni,
          x.virk === 1 ? 'Já' : 'Nei',
        ]),
      ];
      return newLine;
    },
    getExcelFileName() {
      return `stofur_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      loading: false,
      showSlidebar: false,
      showTimetableSlidebar: false,
      showBuildingSlidebar: false,
      showMassEditSlidebar: false,
      selectedItem: {},
      editing: false,
      creating: null,
      showInactive: false,
      buildingItem: {
        building_id: '',
      },
    };
  },
  methods: {
    openBuildingSlidebar() {
      this.showBuildingSlidebar = true;
    },
    openSlidebar(item) {
      this.selectedItem = item;
      this.showSlidebar = true;
    },
    openTimetableSlidebar(item) {
      this.selectedItem = item;
      this.showTimetableSlidebar = true;
    },
    openMassEditSlidebar() {
      this.showMassEditSlidebar = true;
    },
    closeSlidebar() {
      this.showSlidebar = false;
      this.showTimetableSlidebar = false;
      this.showBuildingSlidebar = false;
      this.showMassEditSlidebar = false;
      this.selectedItem = {};
      this.editing = false;
      this.creating = null;
      this.reloadList();
    },
    checkSlidebar(item) {
      this.creating = null;
      if (this.showSlidebar) {
        this.openSlidebar(item);
      } else if (this.showTimetableSlidebar) {
        this.openTimetableSlidebar(item);
      }
    },
    createItem() {
      this.selectedItem = '';
      this.editing = false;
      this.creating = this.building.building_id;
      this.showSlidebar = true;
    },
    editItem(item) {
      this.selectedItem = item;
      this.editing = true;
      this.creating = null;
      this.showSlidebar = true;
    },
    reloadList() {
      this.$emit('reload');
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>

<style lang="scss"></style>
