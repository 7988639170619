<template>
  <div>
    <div class="alert alert-info p-2">
      <i class="fa fa-info-circle"></i> Ath. Hægt er að takmarka aðgang starfsmanna við deildir, til að auka sveigjanleika er búið að breyta
      í Admin vefnum að hægt er að tengja áfanga og brautir við fleiri en eina deild en í gömlu Innu var bara hægt að hafa það tengt við
      eina deild. Það þýðir að á meðan gamla Inna er ennþá í notkun getur verið að tenging áfanga og brautar við deildir sé ekki eins. Þegar
      deild er breytt á áfanga eða braut í gömlu Innu þá sést sú breyting á Admin vefnum. Það gildir hins vegar ekki í hina áttina þ.e.a.s.
      ef breytingar eru gerðar á tengingum við deildir í Admin-Innu þá sjást þær ekki í gömlu-Innu.
    </div>
    <table class="table table-fixed" style="font-size: 14px">
      <thead>
        <tr class="table-search">
          <td colspan="3" style="padding-top: 0">
            <b-form-input v-model="search.name" class="search" placeholder="Heiti deildar" />
          </td>
          <td colspan="1" style="max-width: 80px">
            <div class="float-right" style="margin-top: -10px">
              <CustomButton type="create" title="Ný deild" v-if="canEdit" @click.native="openSlidebar()" />
            </div>
          </td>
        </tr>
        <tr>
          <th>
            <LinkOrder title="Heiti" :filteredList="getList" :list="list" column="name" />
          </th>
          <th>
            <LinkOrder title="Lýsing" :filteredList="getList" :list="list" column="description" />
          </th>
          <th>
            <LinkOrder title="Deildarstjóri" :filteredList="getList" :list="list" column="head" />
          </th>
          <th>
            <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="cursor-pointer float-right">
              <i class="fa fa-file-excel-o excelText"></i>
            </download-csv>
          </th>
        </tr>
      </thead>
      <tbody>
        <TableRowLoader v-if="loading" colspan="4" :center="true" />
        <TableRowEmpty v-if="!loading && this.list.length === 0" colspan="4" :center="true" />
        <tr
          v-for="item in getList"
          :key="item.department_id"
          :class="{
            'active-table-row': selectedItem && selectedItem.department_id === item.department_id,
            'cursor-pointer': showModuleSlidebar || showCourseSlidebar,
          }"
          @click="checkSlidebar(item)"
        >
          <td>{{ item.name }}</td>
          <td>{{ item.description }}</td>
          <td>{{ item.manager_name }}</td>
          <td>
            <div class="float-right" style="white-space: nowrap">
              <b-btn size="sm" variant="primary" class="mr-2" v-b-tooltip title="Skoða áfanga" @click.prevent="openModuleSlidebar(item)">
                <i class="fa fa-book" />
              </b-btn>
              <b-btn size="sm" variant="primary" class="mr-2" v-b-tooltip title="Skoða brautir " @click.prevent="openCourseSlidebar(item)">
                <i class="fa fa-sitemap" />
              </b-btn>
              <b-btn size="sm" variant="dark" @click.prevent="openSlidebar(item)" v-if="canEdit">
                <i class="fa fa-pencil" />
              </b-btn>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <module-slidebar v-if="showModuleSlidebar" :item="selectedItem" type="department" @closeSlidebar="closeSlidebar" :extraLarge="true">
    </module-slidebar>
    <course-slidebar v-if="showCourseSlidebar" :item="selectedItem" type="department" @closeSlidebar="closeSlidebar" :extraLarge="true">
    </course-slidebar>
    <department-slidebar v-if="showSlidebar" :item="selectedItem" @closeSlidebar="closeAndReloadSlidebar"> </department-slidebar>
  </div>
</template>

<script>
import structure from '@/api/structure';

import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

import CustomButton from '@/components/common/CustomButton.vue';

import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';

import DepartmentSlidebar from '@/components/school/school/departments/DepartmentSlidebar.vue';
import ModuleSlidebar from '@/components/curriculum/modules/ModuleSlidebar.vue';
import CourseSlidebar from '@/components/curriculum/courses/CourseSlidebar.vue';

export default {
  name: 'departments',
  components: {
    TableRowLoader,
    TableRowEmpty,
    LinkOrder,
    ModuleSlidebar,
    CourseSlidebar,
    DepartmentSlidebar,
    CustomButton,
  },
  computed: {
    getList() {
      return this.list.filter(
        x => this.search.name.length === 0 || (x.name && x.name.toUpperCase().startsWith(this.search.name.toUpperCase())),
      );
    },
    getExcelData() {
      const newList = [
        [],
        ['Deildir'],
        [],
        ['Heiti deildar', 'Lýsing', 'Deildarstjóri'],
        ...this.getList.map(x => [x.name, x.description, x.manager_name]),
      ];
      return newList;
    },
    getExcelFileName() {
      return `deildir_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      loading: false,
      showModuleSlidebar: false,
      showCourseSlidebar: false,
      showSlidebar: false,
      selectedItem: {},
      search: {
        name: '',
      },
      list: [],
    };
  },
  methods: {
    async loadList() {
      try {
        this.loading = true;
        const response = await structure.getDepartmentList();
        this.list = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    openModuleSlidebar(item) {
      this.selectedItem = item;
      this.showModuleSlidebar = true;
    },
    openCourseSlidebar(item) {
      this.selectedItem = item;
      this.showCourseSlidebar = true;
    },
    openSlidebar(item) {
      this.selectedItem = item;
      this.showSlidebar = true;
    },
    closeSlidebar() {
      this.showModuleSlidebar = false;
      this.showCourseSlidebar = false;
      this.showSlidebar = false;
      this.selectedItem = {};
    },
    closeAndReloadSlidebar() {
      this.closeSlidebar();
      this.loadList();
    },
    checkSlidebar(item) {
      if (this.showModuleSlidebar) {
        this.openModuleSlidebar(item);
      } else if (this.showCourseSlidebar) {
        this.openCourseSlidebar(item);
      } else if (this.showSlidebar) {
        this.openSlidebar(item);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadList();
  },
};
</script>
