<template>
  <div id="app">
    <router-view />
    <toast position="ne"></toast>
  </div>
</template>

<script>
import { Toast } from 'vuex-toast';
import auth from '@/api/auth';

export default {
  name: 'App',
  components: {
    Toast,
  },
  created() {
    if (auth.getToken()) {
      this.$socket.emit('authenticate', { token: auth.getToken() });
    }
  },
};
</script>

<style lang="scss">
@import '~vuex-toast/dist/vuex-toast';
@import './style/bootstrap';
@import './style/main';

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 0px;
}

/**
  * Types
  */
.toast-type-success {
  background-color: $success;
}

.toast-type-danger {
  background-color: $danger;
}
</style>
