<template>
  <div>
    <b-card class="noprint">
      <b-row>
        <b-col>
          <b-form-group label="Kennslutímabil" label-for="term">
            <v-select id="term" name="term" v-model="selected.term" :options="terms" :clearable="false" @input="loadModules()"> </v-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Áfangar" label-for="modules">
            <v-select
              id="modules"
              name="modules"
              v-model="selected.modules"
              multiple
              :options="modules"
              label="namsgrein_afanganr"
              @input="
                loadGroups();
                loadResults();
              "
              :disabled="!validTerm || selected.allModules"
            >
            </v-select>
          </b-form-group>
          <div v-if="validTerm && !selected.showTimetable">
            <div class="d-inline-block">
              Skoða alla áfanga
              <i class="fa fa-info-circle" title="Velja þarf dags. frá til að virkja hak" v-b-tooltip></i>
            </div>
            <div class="d-inline-block ml-2">
              <b-form-checkbox v-model="selected.allModules" :disabled="!selected.dateFrom" @input="loadResults()" />
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="selected.modules.length > 0 && !selected.allModules">
        <b-col v-for="item in selected.modules" :key="item.annir_afangar_id" cols="2">
          <b-form-group :label-for="`groups${item.annir_afangar_id}`">
            <label
              ><strong>{{ item.namsgrein_afanganr }} - Hópar</strong></label
            >
            <v-select
              :id="`groups${item.annir_afangar_id}`"
              :name="`groups${item.annir_afangar_id}`"
              v-model="item.selectedGroups"
              multiple
              :options="item.groups"
              label="hopur"
            >
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="!validTerm && !this.loading.terms">
        <b-col>
          <div class="alert alert-danger mb-2">
            Ekki er hægt að skrá tíma í stundatöflu þar sem til er stofntafla á völdu kennslutímabili og ekki búið að fletja hana út
          </div>
          <b-btn
            variant="primary"
            @click.prevent="openChangeTypeSlidebar()"
            v-if="selected.term && selected.term.stundatafla_tegund === 0 && selected.term.can_change_type === 1"
          >
            Fletja út
          </b-btn>
        </b-col>
      </b-row>
    </b-card>
    <br />
    <b-card class="printPosition">
      <h5 class="noprint">Yfirlit yfir kennslustundir</h5>
      <div class="noprint">
        <b-row>
          <b-col v-if="!selected.showTimetable">
            <b-form-group label="Dags. frá" label-for="dateFrom">
              <datepicker
                id="dateFrom"
                name="dateFrom"
                v-model="selected.dateFrom"
                :language="lang"
                :monday-first="true"
                format="dd.MM.yyyy"
                :typeable="false"
                :clear-button="true"
                ref="dateFromOpenPickr"
                @input="loadResults()"
              ></datepicker>
            </b-form-group>
          </b-col>
          <b-col v-if="!selected.showTimetable">
            <b-form-group label="Dags. til" label-for="dateTo">
              <datepicker
                id="dateTo"
                name="dateTo"
                v-model="selected.dateTo"
                :language="lang"
                :monday-first="true"
                format="dd.MM.yyyy"
                :typeable="false"
                :clear-button="true"
                ref="dateTopenPickr"
                @input="loadResults()"
              ></datepicker>
            </b-form-group>
          </b-col>
          <b-col v-if="!selected.showTimetable">
            <b-form-group label="Tími frá" label-for="timeFrom" class="d-inline-block mr-3">
              <masked-input
                id="timeFrom"
                name="timeFrom"
                v-model="selected.timeFrom"
                mask="11:11"
                placeholder="hh:mm"
                style="padding: 2px 2px 2px 4px; width: 60px; height: 35px; border: 1px solid #ced4da"
                v-validate="{ regex: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/ }"
              ></masked-input>
            </b-form-group>
            <b-form-group label="Tími til" label-for="timeTo" class="d-inline-block">
              <masked-input
                id="timeTo"
                name="timeTo"
                v-model="selected.timeTo"
                mask="11:11"
                placeholder="hh:mm"
                style="padding: 2px 2px 2px 4px; width: 60px; height: 35px; border: 1px solid #ced4da"
                v-validate="{ regex: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/ }"
              ></masked-input>
            </b-form-group>
          </b-col>
          <b-col v-if="!selected.showTimetable">
            <b-form-group label="Vikudagar" label-for="weedays">
              <v-select id="weedays" name="weedays" v-model="selected.weekdays" multiple :options="weedays"> </v-select>
            </b-form-group>
          </b-col>
          <b-col v-if="!selected.showTimetable">
            <div class="d-inline-block mr-3">
              <b-form-group label="Fela liðna tíma " label-for="hide" v-if="!selected.dateFrom && !selected.dateTo">
                <b-form-checkbox id="hide" name="hide" v-model="selected.hide" @input="loadResults()" />
              </b-form-group>
            </div>
            <div class="d-inline-block">
              <b-form-group label="Sýna samantekt" label-for="hide" v-if="selected.modules.length > 0 && !selected.allModules">
                <b-form-checkbox id="totals" name="totals" v-model="selected.totals" />
              </b-form-group>
            </div>
          </b-col>
          <b-col>
            <div class="pull-right">
              <b-btn variant="primary" class="mr-2" title="Bæta við" @click="openCreateTimetableSlidebar()" v-if="canEdit"
                ><i class="fa fa-plus-square"
              /></b-btn>
              <b-btn variant="primary" class="mr-2" title="Bæta við" @click="openMassCreateTimetableSlidebar()" v-if="canEdit"
                ><i class="fa fa-clone"
              /></b-btn>
              <b-btn
                variant="outline-dark"
                class="mr-2"
                v-if="!selected.showTimetable"
                @click="selected.showTimetable = true"
                title="Skoða sem stundatöflu"
                :disabled="selected.allModules"
                ><i class="fa fa-calendar"
              /></b-btn>
              <b-btn
                variant="outline-dark"
                class="mr-2"
                v-if="selected.showTimetable"
                @click="selected.showTimetable = false"
                title="Skoða sem lista"
                :disabled="selected.allModules"
                ><i class="fa fa-list"
              /></b-btn>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-if="!selected.showTimetable" class="noprint">
        <table class="table less-padding no-border" v-if="selected.modules.length > 0 && selected.totals" style="background-color: #f6f6f6">
          <tr>
            <th>Áfangi</th>
            <th>Hópur</th>
            <th>Kennslustundir</th>
            <th>Mínútur</th>
          </tr>
          <tbody v-for="m in selected.modules" :key="m.afangi_id">
            <tr v-for="g in getModuleGroups(m)" :key="g.hopur_id">
              <td>{{ m.namsgrein_afanganr }}</td>
              <td>{{ g.hopur }}</td>
              <td>{{ g.totalClasses }}</td>
              <td>{{ g.totalMinutes }}</td>
            </tr>
          </tbody>
        </table>
        <i v-if="!firstTime">Veldu áfanga til þess að fá lista af kennslustundum</i>
        <table class="table less-padding" v-else>
          <thead>
            <tr>
              <th style="padding-left: 6px; width: 5%">
                <b-form-checkbox v-model="selectedAll" class="d-inline-block" @input="changeSelectAll()" v-if="canEdit"></b-form-checkbox>

                <b-btn
                  variant="warning"
                  title="Breyta"
                  class="btn-xs"
                  v-if="!selected.showTimetable && numberSelected > 0 && canEdit"
                  @click="openMassEditTimetableSlidebar()"
                >
                  <i class="fa fa-pencil-square"></i
                ></b-btn>
              </th>
              <th>
                <LinkOrder title="Áfangi" :filteredList="results" :list="results" column="namsgrein_afanganr" second_column="hopur" />
                ({{ getList.length }})
              </th>
              <th>
                <LinkOrder title="Hópur" :filteredList="results" :list="results" column="hopur" second_column="vikudagur" />
              </th>
              <th>
                <LinkOrder
                  title="Vikudagur"
                  :filteredList="results"
                  :list="results"
                  column="vikudagur"
                  second_column="namsgrein_afanganr"
                />
              </th>
              <th>
                <LinkOrder title="Dags" :filteredList="results" :list="results" column="dags" second_column="namsgrein_afanganr" />
              </th>
              <th>
                <LinkOrder title="Tími frá" :filteredList="results" :list="results" column="timi_fra" second_column="namsgrein_afanganr" />
              </th>
              <th>
                <LinkOrder title="Tími til" :filteredList="results" :list="results" column="timi_til" second_column="namsgrein_afanganr" />
              </th>
              <th>
                <LinkOrder title="Stofa" :filteredList="results" :list="results" column="stofur" second_column="namsgrein_afanganr" />
              </th>
              <th>
                <LinkOrder title="Kennari/ar" :filteredList="results" :list="results" column="kennari" second_column="namsgrein_afanganr" />
              </th>
              <th>
                <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="cursor-pointer float-right">
                  <i class="fa fa-file-excel-o excelText"></i>
                </download-csv>
              </th>
            </tr>
          </thead>
          <tbody>
            <TableRowLoader v-if="loading.results" colspan="9" :center="true" />
            <TableRowEmpty v-if="!loading.results && this.results.length === 0" colspan="9" :center="true" />
            <tr v-for="item in getList" :key="item.tafla_id">
              <td><b-form-checkbox v-model="item.selected" v-if="canEdit" /></td>
              <td>{{ item.namsgrein_afanganr }}</td>
              <td>{{ item.hopur }}</td>
              <td>{{ item.vikudagur }}</td>
              <td>{{ item.dags }}</td>
              <td>{{ item.timi_fra }}</td>
              <td>{{ item.timi_til }}</td>
              <td>{{ item.stofur }}</td>
              <td>
                <span v-for="(t, idx) in item.kennari" :key="t.starfsm_id" v-b-tooltip.hover :title="t.nafn">
                  <span v-if="idx != 0">, </span>{{ t.skammst }}</span
                >
              </td>
              <td>
                <b-btn
                  class="float-right"
                  variant="primary"
                  size="sm"
                  v-if="item.can_edit === 1 && canEdit"
                  @click="openEditTimetableSlidebar(item.tafla_id)"
                >
                  <i class="fa fa-pencil" />
                </b-btn>
                <b-btn
                  class="float-right"
                  variant="link"
                  size="sm"
                  v-if="teamsEnabled && item.can_edit === 1 && canEdit"
                  @click="openEditTeamsSlidebar(item.tafla_id)"
                >
                  <font-awesome-icon icon="user-friends" style="color: #464eb8" />
                </b-btn>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Timetable
        :id="groupList"
        :refetch="refetchTimetable"
        type="groups"
        :holidays="true"
        :clickable="true"
        @eventClick="eventClick"
        @dayClick="dayClick"
        v-if="selected.showTimetable"
        style="margin-top: 20px"
      />
    </b-card>
    <change-term-type-slidebar
      v-if="showChangeTypeSlidebar"
      :item="selected.term"
      @closeSlidebar="closeSlidebar"
      @closeSlidebarAndReload="closeSlidebarAndReload"
    >
    </change-term-type-slidebar>
    <create-timetable-slidebar
      v-if="showCreateSlidebar"
      :item="selectedItem"
      @closeSlidebar="closeSlidebar"
      @closeSlidebarAndReload="closeSlidebarAndReload"
    >
    </create-timetable-slidebar>
    <mass-create-timetable-slidebar
      v-if="showMassCreateSlidebar"
      :item="selectedItem"
      @closeSlidebar="closeSlidebar"
      @closeSlidebarAndReload="closeSlidebarAndReload"
    >
    </mass-create-timetable-slidebar>
    <edit-timetable-slidebar
      v-if="showEditSlidebar"
      :id="selectedItem"
      :term="selected.term"
      @closeSlidebar="closeSlidebar"
      @closeSlidebarAndReload="closeSlidebarAndReload"
    >
    </edit-timetable-slidebar>
    <mass-edit-timetable-slidebar
      v-if="showMassEditSlidebar"
      :items="selectedItem"
      @closeSlidebar="closeSlidebar"
      @closeSlidebarAndReload="closeSlidebarAndReload"
    >
    </mass-edit-timetable-slidebar>
    <edit-timetable-teams-slidebar
      v-if="showTeamsSlidebar"
      :id="selectedItem"
      :term="selected.term"
      @closeSlidebar="closeSlidebar"
      @closeSlidebarAndReload="closeSlidebarAndReload"
    >
    </edit-timetable-teams-slidebar>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';
import MaskedInput from 'vue-masked-input';
import school from '@/api/school';
import terms from '@/api/terms';

import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';

import Timetable from '@/components/common/Timetable.vue';

import ChangeTermTypeSlidebar from '@/components/school/terms/ChangeTermTypeSlidebar.vue';
import CreateTimetableSlidebar from '@/components/school/timetable/CreateTimetableSlidebar.vue';
import MassCreateTimetableSlidebar from '@/components/school/timetable/MassCreateTimetableSlidebar.vue';
import EditTimetableSlidebar from '@/components/school/timetable/EditTimetableSlidebar.vue';
import MassEditTimetableSlidebar from '@/components/school/timetable/MassEditTimetableSlidebar.vue';
import EditTimetableTeamsSlidebar from '@/components/school/timetable/EditTimetableTeamsSlidebar.vue';

import LinkOrder from '@/components/common/LinkOrder.vue';

export default {
  name: 'changeTimetable',
  components: {
    ChangeTermTypeSlidebar,
    Datepicker,
    TableRowLoader,
    TableRowEmpty,
    Timetable,
    CreateTimetableSlidebar,
    MassCreateTimetableSlidebar,
    EditTimetableSlidebar,
    MassEditTimetableSlidebar,
    MaskedInput,
    LinkOrder,
    EditTimetableTeamsSlidebar,
  },
  computed: {
    getExcelData() {
      const newList = [
        [],
        ['Stundatöflur'],
        [`${this.selected.term.onn_heiti}`],
        [this.selected.modules.map(m => m.afanga_heiti).join('; ')],
        [],
        ['Áfangi', 'Hópur', 'Vikudagur', 'Dags', 'Tími til', 'Stofa', 'Kennari/ar'],
        ...this.results.map(x => [x.namsgrein_afanganr, x.afanga_heiti, x.fj_einkunnareglu, x.fj_einkunnareglu_hopar]),
      ];
      return newList;
    },
    getExcelFileName() {
      return `stundatoflur${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    validTerm() {
      return (
        this.selected.term &&
        (this.selected.term.stundatafla_tegund === 1 ||
          (this.selected.term.stundatafla_tegund === 0 && this.selected.term.can_change_type === 0))
      );
    },
    getList() {
      return this.results
        .filter(
          x =>
            this.selected.weekdays.length === 0 ||
            this.selected.weekdays
              .map(y => y.label)
              .join(',')
              .indexOf(x.vikudagur) > -1,
        )
        .filter(
          x =>
            !this.selected.timeFrom ||
            !this.selected.timeFrom.match(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/) ||
            moment(x.timi_fra, 'HH:mm').isSameOrAfter(moment(this.selected.timeFrom, 'HH:mm')),
        )
        .filter(
          x =>
            !this.selected.timeTo ||
            !this.selected.timeTo.match(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/) ||
            moment(x.timi_til, 'HH:mm').isSameOrBefore(moment(this.selected.timeTo, 'HH:mm')),
        )
        .filter(x => this.groupList.length === 0 || this.groupList.indexOf(x.hopur_id) > -1);
    },
    groupList() {
      let list = [];
      if (!this.selected.allModules) {
        this.selected.modules.forEach(m => {
          const groups =
            m.selectedGroups && m.selectedGroups.length > 0
              ? (m.selectedGroups || []).map(x => x.hopur_id)
              : (m.groups || []).map(x => x.hopur_id);
          list = list.concat(groups);
        });
      }
      return list.join(',');
    },
    numberSelected() {
      return this.results.filter(x => x.selected).length;
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_stundatoflur');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('skolahald_stundatoflur');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      lang: is,
      weedays: [
        { id: 0, label: 'mánudagur' },
        { id: 1, label: 'þriðjudagur' },
        { id: 2, label: 'miðvikudagur' },
        { id: 3, label: 'fimmtudagur' },
        { id: 4, label: 'föstudagur' },
        { id: 5, label: 'laugardagur' },
        { id: 6, label: 'sunnudagur' },
      ],
      loading: {
        terms: false,
        modules: false,
        results: false,
      },
      refetchTimetable: false,
      terms: [],
      modules: [],
      results: [],
      selected: {
        term: '',
        modules: [],
        allModules: false,
        showTimetable: false,
        dateFrom: '',
        dateTo: '',
        hide: true,
        totals: false,
        weekdays: [],
        timeFrom: '',
        timeTo: '',
      },
      selectedItem: '',
      firstTime: false,
      showChangeTypeSlidebar: false,
      showCreateSlidebar: false,
      showMassCreateSlidebar: false,
      showEditSlidebar: false,
      showMassEditSlidebar: false,
      showTeamsSlidebar: false,
      selectedAll: false,
      teamsEnabled: false,
      modulesLength: 0,
    };
  },
  methods: {
    getModuleGroups(item) {
      return item.selectedGroups.length > 0 ? item.selectedGroups : item.groups;
    },
    async loadList(termId, moduleId, groupId) {
      if (this.canRead) {
        try {
          this.loading.terms = true;
          this.results = [];
          this.modules = [];
          this.selected.modules = [];
          this.terms = [];
          const response = await terms.getTerms();

          this.terms = response.data.items.map(x => ({
            ...x,
            label: `${x.undirsk_audk} - ${x.onn_heiti}`,
          }));
          if (termId) {
            this.selected.term = this.terms.find(x => x.onn_id === parseInt(termId, 10));
          }
          this.selected.term = this.selected.term || this.terms.find(x => x.is_default === 1);
          if (this.selected.term) {
            this.loadModules(moduleId, groupId);
          }
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading.terms = false;
        }
      }
    },
    async loadModules(moduleId, groupId) {
      try {
        this.loading.modules = true;
        this.selected.modules = [];
        const response = await terms.getTermModules(this.selected.term.onn_id);

        this.modules = response.data.items;
        this.modulesLength = this.modules.length;
        if (moduleId) {
          const item = this.modules.find(x => x.afangi_id === parseInt(moduleId, 10));
          if (item) {
            this.selected.modules.push(item);
            this.loadGroups(groupId);
          }
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.modules = false;
      }
    },
    async loadGroups(groupId) {
      if (this.selected.modules.length > 0) {
        try {
          this.loading.groups = true;
          for (let i = 0; i < this.selected.modules.length; i += 1) {
            const response = await school.getTermModulesGroups(this.selected.modules[i].annir_afangar_id); // eslint-disable-line
            this.$set(this.selected.modules[i], 'groups', response.data.items);
            const selectedGroups = [];
            if (groupId && response.data.items.find(x => x.hopur_id === parseInt(groupId, 10))) {
              selectedGroups.push(response.data.items.find(x => x.hopur_id === parseInt(groupId, 10)));
            }
            this.$set(this.selected.modules[i], 'selectedGroups', selectedGroups);
            if (selectedGroups.length > 0) {
              this.loadResults();
            }
          }
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading.groups = false;
        }
      }
    },
    getGroupCount(group) {
      let totalClasses = 0;
      let totalMinutes = 0;
      this.results
        .filter(r => r.hopur_id === group.hopur_id)
        .forEach(r => {
          totalClasses += 1;
          if (r.timi_fra && r.timi_til) {
            const dateFrom = moment().set('hour', r.timi_fra.split(':')[0]).set('minute', r.timi_fra.split(':')[1]);
            const dateTo = moment().set('hour', r.timi_til.split(':')[0]).set('minute', r.timi_til.split(':')[1]);
            const duration = moment.duration(dateTo.diff(dateFrom), 'milliseconds').asMinutes();
            totalMinutes += Math.floor(duration);
          }
        });
      this.$set(group, 'totalClasses', totalClasses);
      this.$set(group, 'totalMinutes', totalMinutes);
    },
    async loadResults() {
      this.results = [];
      if (this.selected.term && (this.selected.allModules || this.selected.modules.length > 0)) {
        try {
          this.firstTime = true;
          this.loading.results = true;
          const params = {
            modules: this.selected.modules.length > 0 ? this.selected.modules.map(x => x.afangi_id).join(',') : null,
            hide: this.selected.hide && !this.selected.dateFrom && !this.selected.dateTo ? 1 : null,
            dateFrom: this.selected.dateFrom ? moment(this.selected.dateFrom).format('DD.MM.YYYY') : null,
            dateTo: this.selected.dateTo ? moment(this.selected.dateTo).format('DD.MM.YYYY') : null,
            timeFrom: this.selected.timeFrom,
            timeTo: this.selected.timeTo,
          };
          const response = await terms.getTermTimetableList(this.selected.term.onn_id, params);
          this.results = response.data.items;
          if (!this.selected.allModules) {
            this.selected.modules.forEach(m => {
              this.groups = m.selectedGroups && m.selectedGroups.length > 0 ? m.selectedGroups : m.groups;
              (this.groups || []).forEach(g => {
                this.getGroupCount(g);
              });
            });
          }
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading.results = false;
        }
      }
    },
    openChangeTypeSlidebar() {
      this.showChangeTypeSlidebar = true;
    },
    openCreateTimetableSlidebar(date) {
      this.selectedItem = {
        term: this.selected.term,
        module: this.selected.modules.length === 1 ? this.selected.modules[0] : '',
        group:
          this.selected.modules.length === 1 && this.selected.modules[0].selectedGroups.length === 1
            ? this.selected.modules[0].selectedGroups[0]
            : '',
        date: date || '',
      };
      this.showCreateSlidebar = true;
    },
    openMassCreateTimetableSlidebar() {
      this.selectedItem = {
        term: this.selected.term,
        module: this.selected.modules.length === 1 ? this.selected.modules[0] : '',
        group:
          this.selected.modules.length === 1 && this.selected.modules[0].selectedGroups.length === 1
            ? this.selected.modules[0].selectedGroups[0]
            : '',
      };
      this.showMassCreateSlidebar = true;
    },
    openEditTimetableSlidebar(id) {
      this.selectedItem = id;
      this.showEditSlidebar = true;
    },
    openEditTeamsSlidebar(id) {
      this.selectedItem = id;
      this.showTeamsSlidebar = true;
    },
    openMassEditTimetableSlidebar() {
      this.selectedItem = this.results.filter(x => x.selected);
      this.showMassEditSlidebar = true;
    },
    changeSelectAll() {
      this.getList.forEach(i => {
        this.$set(i, 'selected', this.selectedAll);
      });
    },
    closeSlidebar() {
      this.selectedItem = '';
      this.showChangeTypeSlidebar = false;
      this.showCreateSlidebar = false;
      this.showMassCreateSlidebar = false;
      this.showEditSlidebar = false;
      this.showMassEditSlidebar = false;
      this.showTeamsSlidebar = false;
    },
    closeSlidebarAndReload() {
      this.closeSlidebar();
      this.loadResults();
      this.refetchTimetableData();
      this.selectedAll = false;
    },
    dayClick(data) {
      if (this.canEdit) {
        this.openCreateTimetableSlidebar(data.date);
      }
    },
    eventClick(data) {
      if (this.canEdit) {
        this.openEditTimetableSlidebar(data.id);
      }
    },
    refetchTimetableData() {
      this.refetchTimetable = true;
      setTimeout(() => {
        this.refetchTimetable = false;
      }, 1000);
    },
    async checkTeamsEnabled() {
      try {
        const response = await this.$schoolApi.timetableTeamsEnabled();
        this.teamsEnabled = response.data.enabled;
      } catch (e) {
        this.$log.error(e);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadList(this.$route.query.term, this.$route.query.module, this.$route.query.group);
    this.checkTeamsEnabled();
    // query params: term, module, group
  },
};
</script>
