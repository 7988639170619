<template>
  <div>
    <table class="table" style="font-size: 14px">
      <thead>
        <tr class="table-search">
          <td colspan="3" style="padding-top: 0; padding-left: 0px">
            <b-form-input v-model="search.name" class="search" placeholder="Tegund forfalla starfsmanna" ref="focusInput" />
          </td>
          <td colspan="1" style="max-width: 80px">
            <div class="float-right" style="margin-top: -10px">
              <CustomButton type="create" title="Ný tegund" v-if="canEdit" @click.native="openSlidebar()" />
            </div>
          </td>
        </tr>
        <tr>
          <th>
            <LinkOrder title="Tegund forfalla starfsmanna" :filteredList="getList" :list="list" column="heiti" />
          </th>
          <th>
            <LinkOrder title="Kóði" :filteredList="getList" :list="list" column="kodi" />
          </th>
          <th>
            <LinkOrder title="Kóði - Nemandi" :filteredList="getList" :list="list" column="fjarvist_kodi" />
          </th>
          <th>
            <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="cursor-pointer float-right">
              <i class="fa fa-file-excel-o excelText"></i>
            </download-csv>
          </th>
        </tr>
      </thead>
      <tbody>
        <TableRowLoader v-if="loading" colspan="4" :center="true" />
        <TableRowEmpty v-if="!loading && this.list.length === 0" colspan="4" :center="true" />
        <tr
          v-for="item in getList"
          :key="item.teg_forf_stm_id"
          :class="{
            'active-table-row': selectedItem && selectedItem.teg_forf_stm_id === item.teg_forf_stm_id,
            'cursor-pointer': showSlidebar,
          }"
          @click="checkSlidebar(item)"
        >
          <td>{{ item.heiti }}</td>
          <td>{{ item.kodi }}</td>
          <td>{{ item.fjarvist_kodi }}</td>
          <td>
            <div class="float-right" v-if="canEdit">
              <b-btn variant="primary" class="mr-2 btn-xs" v-b-tooltip title="Breyta" @click.prevent="openSlidebar(item)">
                <i class="fa fa-pencil" />
              </b-btn>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <staff-absence-type-slidebar v-if="showSlidebar" :item="selectedItem" @closeSlidebar="closeSlidebar"> </staff-absence-type-slidebar>
  </div>
</template>

<script>
import types from '@/api/types';

import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

import CustomButton from '@/components/common/CustomButton.vue';

import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';

import StaffAbsenceTypeSlidebar from '@/components/school/school/types/StaffAbsenceTypeSlidebar.vue';

export default {
  name: 'staffAbsenceType',
  components: {
    TableRowLoader,
    TableRowEmpty,
    LinkOrder,
    StaffAbsenceTypeSlidebar,
    CustomButton,
  },
  computed: {
    getList() {
      return this.list.filter(
        x => this.search.name.length === 0 || (x.heiti && x.heiti.toUpperCase().startsWith(this.search.name.toUpperCase())),
      );
    },
    getExcelData() {
      const newList = [
        [],
        ['Tegundir forfalla starfsmanna'],
        [],
        ['Tegund forfalla', 'Kóði', 'Kóði - Nemandi'],
        ...this.getList.map(x => [x.heiti, x.kodi, x.fjarvist_kodi]),
      ];
      return newList;
    },
    getExcelFileName() {
      return `tegund_forfalla_starfsmanns_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      loading: false,
      showSlidebar: false,
      selectedItem: {},
      search: {
        name: '',
      },
      list: [],
    };
  },
  methods: {
    async loadList() {
      try {
        this.loading = true;
        const response = await types.getStaffAbsenceList();
        this.list = response.data.items;
        this.$nextTick(() => {
          if (this.$refs.focusInput) {
            this.$refs.focusInput.focus();
          }
        });
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    openSlidebar(item) {
      this.selectedItem = item;
      this.showSlidebar = true;
    },
    closeSlidebar() {
      this.showSlidebar = false;
      this.selectedItem = {};
      this.loadList();
    },
    checkSlidebar(item) {
      if (this.showSlidebar) {
        this.openSlidebar(item);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadList();
  },
};
</script>
