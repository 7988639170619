<template>
  <page>
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <PageTitle title="Nemendur" />
    <b-card>
      <b-row>
        <b-col>
          <b-form-group label="Nafn" label-for="name">
            <b-form-input id="name" name="name" v-model="selected.name" ref="focusInput" v-on:keyup.enter="loadData()" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Kennitala" label-for="ssn">
            <b-form-input id="ssn" name="ssn" v-model="selected.ssn" v-on:keyup.enter="loadData()" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Nemendanr." label-for="studentNr">
            <b-form-input id="studentNr" name="studentNr" v-model="selected.studentNr" v-on:keyup.enter="loadData()" />
          </b-form-group>
        </b-col>
        <b-col v-if="!afangaskoli">
          <b-form-group label="Bekkur" label-for="studentClass">
            <b-form-input id="studentClass" name="studentClass" v-model="selected.studentClass" v-on:keyup.enter="loadData()" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group>
            <!--a: fyrir leit -->
            <label for="statuses">Staða nema <Loader v-if="loading.statuses"></Loader></label>
            <v-select id="statuses" name="statuses" v-model="selected.status" :options="statuses" label="heiti"> </v-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <label for="courses">Braut <Loader v-if="loading.courses"></Loader></label>
              <v-select id="courses" name="courses" v-model="selected.course" :options="getCourses"> </v-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-row>
              <b-col class="pl-0">
                <!--c: fyrir leit -->
                <b-form-group label="Virkur" label-for="active">
                  <v-select id="active" name="active" v-model="selected.active" :options="active" :clearable="true"> </v-select>
                </b-form-group>
              </b-col>
              <b-col class="pl-0 pr-0" v-if="heimavist">
                <!--d: fyrir leit -->
                <b-form-group label="Heimavist" label-for="dorm">
                  <v-select id="dorm" name="dorm" v-model="selected.dorm" :options="yesNo" :clearable="true"> </v-select>
                </b-form-group>
              </b-col>
            </b-row>
        </b-col>
      </b-row>
      <div style="padding-left: 15px" @click="detailedSearch = !detailedSearch" class="text-primary cursor-pointer mb-2">
        <span v-if="detailedSearch">Fela ítarleit </span><span v-else>Sýna ítarleit </span>
        <i class="fa text-primary" :class="{ 'fa-angle-up': detailedSearch, 'fa-angle-down': !detailedSearch }"></i>
      </div>
      <div v-if="detailedSearch">
        <b-row>
          <b-col>
            <b-form-group>
              <!--b: fyrir leit -->
              <label for="processingStatuses">Vinnslustaða <Loader v-if="loading.processingStatuses"></Loader></label>
              <v-select
                id="processingStatuses"
                name="processingStatuses"
                v-model="selected.processingStatus"
                :options="processingStatuses"
                label="heiti"
              >
              </v-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <label for="divisions">Undirskóli <Loader v-if="loading.divisions"></Loader></label>
              <v-select id="divisions" name="divisions" v-model="selected.division" :options="getDivisions" label="name"> </v-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <label for="departments">Deild <Loader v-if="loading.departments"></Loader></label>
              <v-select id="departments" name="departments" v-model="selected.department" :options="getDepartments" label="name">
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group>
              <label for="userGroups">Notendahópur <Loader v-if="loading.userGroups"></Loader></label>
              <v-select id="userGroups" name="userGroups" v-model="selected.userGroup" :options="userGroups" label="heiti"> </v-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-row>
              <b-col class="pl-0">
                <b-form-group label="Sérstök mætingareinkunn" label-for="specialAttendance">
                  <v-select
                    id="specialAttendance"
                    name="specialAttendance"
                    v-model="selected.specialAttendance"
                    :options="yesNo"
                    :clearable="true"
                  >
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col class="pl-0 pr-0" v-if="!afangaskoli">
                <b-form-group label="Ár nemanda" label-for="year">
                  <b-form-input id="year" name="year" v-model="selected.year" style="height: 32px"> </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <b-row>
              <b-col class="pl-0">
                <b-form-group label="Póstnúmer" label-for="postalCodes">
                  <v-select
                    id="postalCodes"
                    name="postalCodes"
                    v-model="selected.postalCode"
                    :options="postalCodes"
                    :clearable="true"
                    label="heiti"
                  >
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col class="pl-0 pr-0">
                <b-form-group label="Sveitafélag" label-for="municipality">
                  <b-form-input id="municipality" name="municipality" v-model="selected.municipality" style="height: 32px"> </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col cols="3">
          <b-btn variant="primary" class="w-100" @click="loadData()"> Leita </b-btn>
        </b-col>
        <b-col>
          <b-btn variant="dark" style="height: 35px; font-size: 12px; margin-top: 14px" class="float-right" cols="1" @click="clearSearch()">
            <i class="fa fa-eraser" aria-hidden="true"></i>
            Hreinsa</b-btn
          >
        </b-col>
      </b-row>
    </b-card>
    <br />

    <b-card v-if="students.length > 0 || loading.students">
      <table class="table table-sticky-header">
        <thead>
          <tr>
            <th><LinkOrder title="Nafn" :filteredList="students" :list="students" column="nafn" /> ({{ students.length }})</th>
            <th>
              <LinkOrder title="Kennitala" :filteredList="students" :list="students" column="kennitala" second_column="nafn" />
            </th>
            <th>
              <LinkOrder title="Braut" :filteredList="students" :list="students" column="braut" second_column="nafn" />
            </th>
            <th>
              <LinkOrder title="Undirskóli" :filteredList="students" :list="students" column="undirsk_heiti" second_column="nafn" />
            </th>
            <th>
              <LinkOrder title="Staða" :filteredList="students" :list="students" column="stada_nema_heiti" second_column="nafn" />
            </th>
            <th>
              <LinkOrder title="Virkur" :filteredList="students" :list="students" column="virkur" second_column="nafn" />
            </th>
            <th>
              <download-csv :data="getExcelData" :name="getExcelFileName" ref="excelList" delimiter=";" style="display: none">
              </download-csv>
              <i class="fa fa-spin fa-spinner text-primary float-right" v-if="loading.excel"></i>
              <div class="cursor-pointer float-right" @click="loadExcel" v-if="!loading.excel">
                <i class="fa fa-file-excel-o excelText"></i>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <TableRowLoader v-if="loading.students" colspan="7" :center="true" />
          <TableRowEmpty v-if="!loading.students && this.students.length === 0" colspan="7" :center="true" />
          <tr v-for="item in getPaginatedList" :key="item.nemandi_id">
            <td>
              <b-link
                :to="{
                  name: 'Student',
                  params: { id: item.nemandi_id },
                  query: {
                    search: selected.name,
                    ssn: selected.ssn,
                    nr: selected.studentNr,
                    a: `${selected.status ? selected.status.eink_stodu : ''}`,
                    b: `${selected.processingStatus ? selected.processingStatus.teg_vinnslust_nema_id : ''}`,
                    c: `${selected.active ? selected.active.id : ''}`,
                    d: `${selected.dorm ? selected.dorm.id : ''}`,
                    e: `${selected.course ? selected.course.braut_id : ''}`,
                    f: `${selected.division ? selected.division.division_id : ''}`,
                    g: `${selected.department ? selected.department.department_id : ''}`,
                    h: `${selected.userGroup ? selected.userGroup.hopur_id : ''}`,
                    i: `${selected.specialAttendance ? selected.specialAttendance.id : ''}`,
                    j: selected.year,
                    k: `${selected.postalCode ? selected.postalCode.postnumer : ''}`,
                    l: selected.municipality,
                  },
                }"
              >
                {{ item.nafn }} {{ item.bekkur }}</b-link
              >
            </td>
            <td>{{ item.kennitala }}</td>
            <td>{{ item.braut }}</td>
            <td>{{ item.undirsk_heiti }}</td>
            <td>{{ item.stada_nema_heiti }}</td>
            <td><yes-no :item="item.virkur"></yes-no></td>
            <td></td>
          </tr>
        </tbody>
      </table>

      <Pagination
        :currentPage="currentPage"
        :totalRows="students.length"
        :perPage="perPage"
        v-if="students.length > 20"
        @change-page="changePage"
        @change-limit="changeLimit"
      ></Pagination>
    </b-card>
  </page>
</template>

<script>
import moment from 'moment';
import students from '@/api/students';
import types from '@/api/types';
import curriculum from '@/api/curriculum';
import structure from '@/api/structure';
import users from '@/api/users';
import common from '@/api/common';
import { mapGetters, mapActions } from 'vuex';

import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import PageTitle from '@/components/common/PageTitle.vue';
import Loader from '@/components/common/Loader.vue';
import YesNo from '@/components/common/YesNo.vue';

import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';
import Pagination from '@/components/common/Pagination.vue';

export default {
  name: 'students',
  components: {
    Breadcrumbs,
    PageTitle,
    Loader,
    TableRowLoader,
    TableRowEmpty,
    LinkOrder,
    Pagination,
    YesNo,
  },
  computed: {
    // getQuery() {
    //   // this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);

    //   return `name:${this.selected.name}`;
    //   // return `${this.selected.term ? this.selected.term.onn_id : ''},${this.selected.module || ''},${this.selected.group || ''},${
    //   //   this.selected.department ? this.selected.department.department_id : ''
    //   // }`;
    //   // `${search.term ? search.term.onn_id : ''}
    //   // return '';
    // },

    getPaginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.students.slice(start, end);
    },
    getDivisions() {
      return this.divisions.filter(x => !this.selected.courses || x.division_id === this.selected.courses.undirskoli_id);
    },
    getCourses() {
      return this.courses
        .filter(x => !this.selected.divisions || x.undirskoli_id === this.selected.divisions.division_id)
        .filter(x => !this.selected.departments || x.deildir.indexOf(this.selected.departments.name) > -1);
    },
    getDepartments() {
      return this.departments.filter(x => !this.selected.courses || this.selected.courses.deildir.indexOf(x.name) > -1);
    },
    afangaskoli() {
      return this.schoolSettings && this.schoolSettings.afangaskoli ? this.schoolSettings.afangaskoli === 1 : false;
    },
    heimavist() {
      return this.schoolSettings && this.schoolSettings.vist ? this.schoolSettings.vist === 1 : false;
    },
    getExcelData() {
      const list = [];
      this.excelData.forEach(x => {
        const item = {};
        item.Nafn = x.nafn;
        item.Kennitala = `="${x.kennitala}"`;
        item.Fæðingarár = x.faed_ar;
        item['Nemanda nr.'] = x.nemandanr;
        item.Heimilisfang = x.heimilisfang;
        item.Póstnúmer = x.postnumer;
        item.Póststöð = x.heiti_poststodvar;
        item.Sveitafélag = x.sveitarfelag_nr;
        item.Aðsetur = x.adsetur;
        item['Póstnr.aðseturs'] = x.postnr_as;
        item.Póststöð = x.heiti_poststodvar;  
        item['Sími lh'] = x.simi_lh;
        item['Sími as'] = x.simi_as;
        item.Farsími = x.farsimi;
        item.Skólanetfang = x.netfang;
        item.Staða = x.stada;
        item.Vinnslustaða = x.vinnslustada;
        item.Aðgangur = x.adgangur === 1 ? 'Já' : 'Nei';
        item['Aðgangi lokað af'] = x.notandi_sem_lokar;
        item.Skápanr = x.skapnr;
        item.Mötuneyti = x.motuneyti === 1 ? 'Já' : 'Nei';
        item['Námsstyrkur greiddur beint til skóla'] = x.namsstyrkur_greiddur_skola === 1 ? 'Já' : 'Nei';
        item['Sérst.mætingareink'] = x.frjals_maeting === 1 ? 'Já' : 'Nei';

        const forradamenn = x.forradamenn
          ? JSON.parse(x.forradamenn).sort((a, b) => {
              if (a.rodun === b.rodun) {
                // Price is only important when cities are the same
                return b.forrad_id - a.forrad_id;
              }
              return a.rodun > b.rodun ? 1 : -1;
            })
          : [];

        const forrad1 = forradamenn.length > 0 ? forradamenn[0] : {};
        const forrad2 = forradamenn.length > 1 ? forradamenn[1] : {};

        item['Forráðamaður/Aðstandandi1'] = forrad1.nafn;
        item.Kennitala1 = forrad1.ktala;
        item.Heimili1 = forrad1.heimili;
        item.Póstnúmer1 = `${forrad1.postnumer ? forrad1.postnumer : ''} ${forrad1.poststod ? forrad1.poststod : ''}`;
        item['Netfang forrm1'] = forrad1.netfang;
        item.Farsími1 = forrad1.simi_gsm;
        item['Forráðamaður/Aðstandandi2'] = forrad2.nafn;
        item.Kennitala2 = forrad2.ktala;
        item.Heimili2 = forrad2.heimili;
        item.Póstnúmer2 = `${forrad2.postnumer ? forrad2.postnumer : ''} ${forrad2.poststod ? forrad2.poststod : ''}`;
        item['Netfang forrm2'] = forrad2.netfang;
        item.Farsími2 = forrad2.simi_gsm;

        item['Undir 18 ára'] = x.aldur < 18 ? 'Já' : 'Nei';

        const umsjonarkennari = x.umsjonarkennari
          ? JSON.parse(x.umsjonarkennari).sort((a, b) => {
              return a.umsjon_id - b.umsjon_id;
            })
          : [];
        item.Umsjónarkennari = umsjonarkennari.map(y => `${y.nafn} - ${y.onn_eink}`).join(' /');
        item.Skammst = umsjonarkennari.map(y => y.skammst).join(' /');
        item.Undirskóli = x.undirsk_audk;
        item.Brautir = x.brautir;
        item.Upphafsönn = x.upphafsonn;
        item['Áætluð útskriftarönn'] = x.utskriftaronn;
        if (!this.afangaskoli) {
          item.Bekkur = x.bekkur;
        }
        item['Athugasemdir (almennar)'] = x.athugasemdir;
        item.Kyn = x.kyn_heiti;
        item.Ríkisfang = x.rikisfang;

        if (this.heimavist) {
          item.Heimavist = x.heimavist;
        }
        item.Norðurlandamál = x.nl_mal;
        item['Þriðja mál'] = x.thridja_mal;
        item['Fjórða mál'] = x.fjorda_mal;
        item['Móðurmál 1'] = x.mmal_1;
        item['Móðurmál 2'] = x.mmal_2;
        item['Efnisgj.áf(ólokið)'] = x.afangar_efnisgjald;
        item.Einkanetfang = x.einkanetfang;
        item.Aldur = x.aldur;

        list.push(item);
      });
      return list;
    },
    getExcelFileName() {
      return `nemendur_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    canRead() {
      return this.loggedInUserHasReadPermission('nemendahald_nemendur');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {
      perPage: 20,
      currentPage: 1,
      breadcrumbs: [
        { name: 'Forsíða', route: 'Dashboard' },
        { name: 'Nemendahald', route: '' },
        { name: 'Nemendur', route: 'Students' },
      ],
      detailedSearch: false,
      firsttime: false,
      selected: {
        name: '',
        ssn: '',
        studentNr: '',
        studentClass: '',
        status: '',
        processingStatus: '',
        active: { id: 1, label: 'Virkur' },
        dorm: '',
        department: '',
        course: '',
        division: '',
        userGroup: '',
        specialAttendance: '',
        year: '',
        postalCode: '',
        municipality: '',
      },
      loading: {
        students: false,
        statuses: false,
        processingStatuses: false,
        departments: false,
        courses: false,
        divisions: false,
        userGroups: false,
        postalCodes: false,
        excel: false,
      },
      students: [],
      excelData: [],
      statuses: [],
      processingStatuses: [],
      departments: [],
      courses: [],
      divisions: [],
      userGroups: [],
      postalCodes: [],
      active: [
        { id: 1, label: 'Virkur' },
        { id: 0, label: 'Óvirkur' },
      ],
      yesNo: [
        { id: 1, label: 'Já' },
        { id: 0, label: 'Nei' },
      ],
    };
  },
  methods: {
    updateQuery() {
      const queries = JSON.parse(JSON.stringify(this.$route.query));
      queries.search = this.selected.name ? this.selected.name : '';
      queries.ssn = this.selected.ssn ? this.selected.ssn : '';
      queries.nr = this.selected.studentNr ? this.selected.studentNr : '';
      queries.a = this.selected.status ? this.selected.status.eink_stodu.toString() : '';
      queries.b = this.selected.processingStatus ? this.selected.processingStatus.teg_vinnslust_nema_id.toString() : '';
      queries.c = this.selected.active ? this.selected.active.id.toString() : '';
      queries.d = this.selected.dorm ? this.selected.dorm.id.toString() : '';
      queries.e = this.selected.course ? this.selected.course.braut_id.toString() : '';
      queries.f = this.selected.division ? this.selected.division.division_id.toString() : '';
      queries.g = this.selected.department ? this.selected.department.department_id.toString() : '';
      queries.h = this.selected.userGroup ? this.selected.userGroup.hopur_id : '';
      queries.i = this.selected.specialAttendance ? this.selected.specialAttendance.id : '';
      queries.j = this.selected.year ? this.selected.year : '';
      queries.k = this.selected.postalCode ? this.selected.postalCode.postnumer : '';
      queries.l = this.selected.municipality ? this.selected.municipality : '';
      if (JSON.stringify(this.$route.query) !== JSON.stringify(queries)) {
        this.$router.replace({ query: queries });
      }
    },
    async loadStatuses(id) {
      try {
        this.loading.statuses = true;
        const response = await students.getStudentsStatuses();
        this.statuses = response.data.items;
        if (id) {
          this.selected.status = this.statuses.find(x => x.eink_stodu === parseInt(id, 10));
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.statuses = false;
      }
    },
    async loadProcessingStatuses(id) {
      try {
        this.loading.processingStatuses = true;
        const response = await types.getProcessingStatusList();
        this.processingStatuses = response.data.items;
        if (id) {
          this.selected.processingStatus = this.processingStatuses.find(x => x.teg_vinnslust_nema_id === parseInt(id, 10));
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.processingStatuses = false;
      }
    },
    async loadDepartments(id) {
      try {
        this.loading.departments = true;
        const response = await structure.getDepartmentList();
        this.departments = response.data.items;
        if (id) {
          this.selected.department = this.departments.find(x => x.department_id === parseInt(id, 10));
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.departments = false;
      }
    },
    async loadCourses(id) {
      try {
        this.loading.courses = true;
        const response = await curriculum.getCourseList({ showDepartment: 1, active: 1 });
        this.courses = response.data.items.map(x => ({
          ...x,
          label: `${x.heiti} ${x.braut_kodi}`,
          deildir: (x.deild || '').split(','),
        }));
        if (id) {
          this.selected.course = this.courses.find(x => x.braut_id === parseInt(id, 10));
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.courses = false;
      }
    },
    async loadDivisions(id) {
      try {
        this.loading.divisions = true;
        const response = await structure.getDivisionList();
        this.divisions = response.data.items.map(x => ({
          ...x,
          label: `${x.code} - ${x.name}`,
        }));
        if (id) {
          this.selected.division = this.divisions.find(x => x.division_id === parseInt(id, 10));
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.divisions = false;
      }
    },
    async loadUserGroups(id) {
      try {
        this.loading.userGroups = true;
        const response = await users.getUserGroupsList({ active: 1 });
        this.userGroups = response.data.items;
        if (id) {
          this.selected.userGroup = this.userGroups.find(x => x.hopur_id === parseInt(id, 10));
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.userGroups = false;
      }
    },
    async loadPostalCodes(id) {
      try {
        this.loading.postalCodes = true;
        const response = await common.getPostalCodes();
        this.postalCodes = response.data.items;
        if (id) {
          this.selected.postalCode = this.postalCodes.find(x => x.postnumer === id);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.postalCodes = false;
      }
    },
    async loadData() {
      try {
        this.firsttime = true;
        this.loading.students = true;
        this.updateQuery();
        const params = {
          showCourse: 1,
          name: this.selected.name,
          ssn: this.selected.ssn,
          studentNr: this.selected.studentNr,
          studentClass: this.selected.studentClass,
          status: this.selected.status ? this.selected.status.eink_stodu : '',
          processingStatus: this.selected.processingStatus ? this.selected.processingStatus.teg_vinnslust_nema_id : '',
          active: this.selected.active ? this.selected.active.id : '',
          department: this.selected.department ? this.selected.department.department_id : '',
          course: this.selected.course ? this.selected.course.braut_id : '',
          division: this.selected.division ? this.selected.division.division_id : '',
          userGroup: this.selected.userGroup ? this.selected.userGroup.hopur_id : '',
          specialAttendance: this.selected.specialAttendance ? this.selected.specialAttendance.id : '',
          year: this.selected.year,
          postalCode: this.selected.postalCode ? this.selected.postalCode.postnumer : '',
          municipality: this.selected.municipality,
          dorm: this.selected.dorm ? this.selected.dorm.id : '',
        };
        // ef search bar er útfylltur (ítarleit) þá á að birta ítarleitina
        if (
          this.selected.status ||
          this.selected.processingStatus ||
          this.selected.department ||
          this.selected.course ||
          this.selected.division ||
          this.selected.userGroup ||
          this.selected.specialAttendance ||
          this.selected.year ||
          this.selected.postalCode ||
          this.selected.municipality ||
          this.selected.dorm
        ) {
          this.detailedSearch = true;
        }
        const response = await students.getStudentList(params);
        this.students = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.students = false;
      }
    },
    async loadExcel() {
      try {
        const params = {
          showCourse: 1,
          name: this.selected.name,
          ssn: this.selected.ssn,
          studentNr: this.selected.studentNr,
          studentClass: this.selected.studentClass,
          status: this.selected.status ? this.selected.status.eink_stodu : '',
          processingStatus: this.selected.processingStatus ? this.selected.processingStatus.teg_vinnslust_nema_id : '',
          active: this.selected.active ? this.selected.active.id : '',
          department: this.selected.department ? this.selected.department.department_id : '',
          course: this.selected.course ? this.selected.course.braut_id : '',
          division: this.selected.division ? this.selected.division.division_id : '',
          userGroup: this.selected.userGroup ? this.selected.userGroup.hopur_id : '',
          specialAttendance: this.selected.specialAttendance ? this.selected.specialAttendance.id : '',
          year: this.selected.year,
          postalCode: this.selected.postalCode ? this.selected.postalCode.postnumer : '',
          municipality: this.selected.municipality,
          dorm: this.selected.dorm ? this.selected.dorm.id : '',
        };
        this.loading.excel = true;
        const response = await students.getStudentListDetails(params);
        this.excelData = response.data.items;
        setTimeout(() => this.$refs.excelList.generate(), 0);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.excel = false;
      }
    },
    async prepareData() {
      const queries = this.$route.query;
      this.selected.name = queries.search || '';
      this.$nextTick(() => {
        if (this.$refs.focusInput) {
          this.$refs.focusInput.focus();
        }
      });
      this.selected.ssn = queries.ssn || '';
      this.selected.studentNr = queries.nr || '';
      this.selected.status = queries.status || '';
      this.selected.dorm = queries.d ? this.yesNo.find(x => x.id === parseInt(queries.d, 10)) : '';
      this.selected.specialAttendance = queries.i ? this.yesNo.find(x => x.id === parseInt(queries.i, 10)) : '';
      this.selected.year = queries.j || '';
      this.selected.municipality = queries.l || '';
      await this.loadStatuses(queries.a);
      await this.loadProcessingStatuses(queries.b);
      await this.loadDepartments(queries.g);
      await this.loadCourses(queries.e);
      await this.loadDivisions(queries.f);
      await this.loadUserGroups(queries.h);
      await this.loadPostalCodes(queries.k);
      this.$nextTick(() => {
        if (Object.keys(queries).length > 0) {
          this.loadData();
        }
      });
    },
    clearSearch() {
      this.selected = {
        name: '',
        ssn: '',
        studentNr: '',
        studentClass: '',
        status: '',
        processingStatus: '',
        active: { id: 1, label: 'Virkur' },
        dorm: '',
        department: '',
        course: '',
        division: '',
        userGroup: '',
        specialAttendance: '',
        year: '',
        postalCode: '',
        municipality: '',
      };
      this.loadData();
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.prepareData();
  },
};
</script>
