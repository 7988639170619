import Vue from 'vue';

const API_URL = '/api/common';

export default {
  getTimetable(params) {
    return Vue.http
      .get(`${API_URL}/timetable`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createTimetable(data) {
    return Vue.http
      .post(`${API_URL}/timetable`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getHolidays(params) {
    // dateFrom, dateTo
    return Vue.http
      .get(`${API_URL}/holidays`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getBankHolidays(dateFrom, dateTo) {
    return Vue.http
      .get(`${API_URL}/bank-holidays/${dateFrom}/${dateTo}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getTimetableGroups(params) {
    // dateFrom, dateTo, groups (commaseparated list)
    return Vue.http
      .get(`${API_URL}/timetable-groups`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getPostalCodes() {
    return Vue.http
      .get(`${API_URL}/postal-codes`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  searchSsn(ssn, params) {
    return Vue.http
      .get(`${API_URL}/search-natreg/${ssn}`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  // Sækir upplýsingar úr þjóðskrá og finnur email í adgangur töflunni
  searchSsnWithEmail(ssn, params) {
    return Vue.http
      .get(`${API_URL}/search-natreg-and-email/${ssn}`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getBatchjobs(params) {
    return Vue.http
      .get(`${API_URL}/batchjobs/`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  removeBatchJob(id) {
    return Vue.http
      .post(`${API_URL}/batchjobs/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getCalendarSchoolEvents(params) {
    return Vue.http
      .get(`${API_URL}/timetable-school-events`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getSchoolEvents(params) {
    return Vue.http
      .get(`${API_URL}/school-events/`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getSchoolEvent(id) {
    return Vue.http
      .get(`${API_URL}/school-events/${id}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getSchoolHours(params) {
    return Vue.http
      .get(`${API_URL}/school-hours`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  createSchoolEvent(data) {
    return Vue.http
      .post(`${API_URL}/school-events/`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  updateSchoolEvent(id, data) {
    return Vue.http
      .post(`${API_URL}/school-events/${id}`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  checkSchoolEventsCollision(data) {
    return Vue.http
      .post(`${API_URL}/school-events-collision`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  massDeleteTimetable(data) {
    return Vue.http
      .post(`${API_URL}/mass-delete-timetable`, data)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getPrimarySchoolSubjects() {
    return Vue.http
      .get(`${API_URL}/primary-school-subjects`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getLanguages() {
    return Vue.http.get(`${API_URL}/languages`);
  },
  getWorkersInProgress(key) {
    return Vue.http
      .get(`${API_URL}/worker-in-progress/${key}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getDiary(type, params) {
    return Vue.http
      .get(`${API_URL}/diary/${type}`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getMessageGroup(params) {
    return Vue.http
      .get(`${API_URL}/message-group`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getMessageRecipients(params) {
    return Vue.http
      .get(`${API_URL}/message-recipients`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getPhoto(userId) {
    return Vue.http
      .get(`${API_URL}/photo/${userId}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  deletePhoto(userId) {
    return Vue.http
      .delete(`${API_URL}/photo/${userId}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getModuleStatuses() {
    return Vue.http
      .get(`${API_URL}/module-status`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getChoiceType() {
    return Vue.http
      .get(`${API_URL}/choice-type`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getRelativeTypes() {
    return Vue.http
      .get(`${API_URL}/relative-types`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getDiaryFilterType(params) {
    return Vue.http
      .get(`${API_URL}/diary-filter-type-list`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getTimetableColours() {
    return Vue.http
      .get(`${API_URL}/timetable-colours`,)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

};
