<template>
  <Slidebar :autoClose="false" @closeMe="closeSlidebar" :large="large" :extraLarge="extraLarge">
    <h4>{{ item.namsgrein_afanganr }} <loader v-if="loading"></loader></h4>
    <b-form @submit.prevent="validateBeforeSubmit" novalidate> </b-form>

    <div v-if="type == 'info'">
      <i>Grunnupplýsingar</i>
      <table class="table less-padding module-create-table">
        <tr>
          <td>Námsgrein</td>
          <td>
            <div class="alert alert-danger p-1 mb-1" v-if="editSubject" style="font-size: 12px">
              <div><strong>Ertu alveg viss um að þú viljir breyta námsgrein?</strong></div>
              <div>
                Við það að breyta námsgrein breytist auðkenni áfangans. Hægt að sjá hvert nýtt auðkenni verður í "Auðkenni áfanga". Þessar
                breytingar hafa áhrif á <strong>alla</strong> námsferla.
              </div>
              <div>Ef það má ekki gerast, vinsamlegast búið til nýjan áfanga í staðin fyrir að breyta námsgrein.</div>
            </div>
            <b-form-group
              v-if="editSubject"
              label-for="namsgrein"
              :state="submitted && errors.has('namsgrein') ? false : ''"
              :invalid-feedback="errors.first('namsgrein')"
            >
              <v-select
                id="namsgrein"
                name="namsgrein"
                v-model="form.namsgrein"
                :clearable="false"
                v-validate="'required'"
                :state="submitted && errors.has('namsgrein') ? false : ''"
                :options="subjects"
                data-vv-as="námsgrein"
              >
              </v-select>
            </b-form-group>
            <span v-else
              >{{ item.namsgrein_heiti }}
              <i class="fa fa-pencil cursor-pointer" @click="editSubject = true"></i>
            </span>
          </td>
        </tr>
        <tr>
          <td>Áfanganúmer</td>
          <td>
            <b-form-group
              label-for="afanga_nr"
              :state="submitted && errors.has('afanga_nr') ? false : ''"
              :invalid-feedback="errors.first('afanga_nr')"
            >
              <b-form-input
                id="afanga_nr"
                name="afanga_nr"
                type="text"
                v-model="form.afanga_nr"
                :state="submitted && errors.has('afanga_nr') ? false : ''"
                maxLength="20"
                v-validate="'required'"
                ref="focusInput"
                data-vv-as="númer"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Auðkenni áfanga</td>
          <td>{{ getIdentity }}</td>
        </tr>
        <tr>
          <td>Heiti áfanga*</td>
          <td>
            <b-form-group
              label-for="afanga_heiti"
              :state="submitted && errors.has('afanga_heiti') ? false : ''"
              :invalid-feedback="errors.first('afanga_heiti')"
            >
              <b-form-input
                id="afanga_heiti"
                name="afanga_heiti"
                type="text"
                v-model="form.afanga_heiti"
                :state="submitted && errors.has('afanga_heiti') ? false : ''"
                maxLength="100"
                v-validate="'required'"
                data-vv-as="heiti"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>
            Heiti (enska)
            <i
              class="fa fa-info-circle"
              v-b-tooltip
              title="Er notað á skírteinum sem eru á ensku, ef notandi velur að hafa Innu á ensku og ef sótt er um á ensku."
            ></i>
          </td>
          <td>
            <b-form-group
              label-for="afanga_heiti_enska"
              :state="submitted && errors.has('afanga_heiti_enska') ? false : ''"
              :invalid-feedback="errors.first('afanga_heiti_enska')"
            >
              <b-form-input
                id="afanga_heiti_enska"
                name="afanga_heiti_enska"
                type="text"
                v-model="form.afanga_heiti_enska"
                :state="submitted && errors.has('afanga_heiti_enska') ? false : ''"
                maxLength="100"
                data-vv-as="heiti"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Undirskóli</td>
          <td>
            <b-form-group
              label-for="undirskoli"
              :state="submitted && errors.has('undirskoli') ? false : ''"
              :invalid-feedback="errors.first('undirskoli')"
            >
              <v-select
                id="undirskoli"
                name="undirskoli"
                v-model="form.undirskoli"
                :clearable="false"
                v-validate="'required'"
                :state="submitted && errors.has('undirskoli') ? false : ''"
                :options="divisions"
                data-vv-as="undirskóli"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Deild/ir</td>
          <td>
            <b-form-group
              label-for="deild"
              :state="submitted && errors.has('deild') ? false : ''"
              :invalid-feedback="errors.first('deild')"
            >
              <v-select
                id="deild"
                name="deild"
                v-model="form.deildir"
                :clearable="true"
                :state="submitted && errors.has('deild') ? false : ''"
                :options="departments"
                data-vv-as="deild"
                multiple
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Einingar</td>
          <td>
            <b-form-group
              label-for="einingar"
              :state="submitted && errors.has('einingar') ? false : ''"
              :invalid-feedback="errors.first('einingar')"
            >
              <b-form-input
                id="einingar"
                name="einingar"
                type="number"
                v-model="form.einingar"
                :state="submitted && errors.has('einingar') ? false : ''"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr v-if="originalUnits !== form.einingar">
          <td colspan="2">
            <div class="alert alert-info p-2">
              <i class="fa fa-info-circle"></i>
              Ath. einingar breytast ekki hjá nemendum sem eru með áfangann lokinn í námsferli. Breytingin á eingöngu við hjá þeim nemendum
              sem ljúka áfanganum eftir breytinguna.
            </div>
          </td>
        </tr>
        <tr>
          <td>Þrep</td>
          <td>
            <b-form-group label-for="trep" :state="submitted && errors.has('trep') ? false : ''" :invalid-feedback="errors.first('trep')">
              <b-form-input
                id="trep"
                name="trep"
                type="number"
                v-model="form.trep"
                :state="submitted && errors.has('trep') ? false : ''"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Fj. tíma á kennslutímabili</td>
          <td>
            <b-form-group
              label-for="timar_onn_alls"
              :state="submitted && errors.has('timar_onn_alls') ? false : ''"
              :invalid-feedback="errors.first('timar_onn_alls')"
            >
              <b-form-input
                id="timar_onn_alls"
                name="timar_onn_alls"
                type="number"
                v-model="form.timar_onn_alls"
                :state="submitted && errors.has('timar_onn_alls') ? false : ''"
                data-vv-as="fj. tíma"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr v-if="schoolHasSettings('stundatoflukerfi')">
          <td>
            Fjöldi nemenda í hóp

            <i class="fa fa-info-circle" v-b-tooltip title="Æskilegur fjöldi nemenda í hóp"></i>
          </td>
          <td>
            <b-form-group
              label-for="max_fj_nema"
              :state="submitted && errors.has('max_fj_nema') ? false : ''"
              :invalid-feedback="errors.first('max_fj_nema')"
            >
              <b-form-input
                id="max_fj_nema"
                name="max_fj_nema"
                type="number"
                v-model="form.max_fj_nema"
                :state="submitted && errors.has('max_fj_nema') ? false : ''"
                data-vv-as="fj. nema"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr v-if="showPrimary">
          <td>
            Tegund áfanga
            <i class="fa fa-info-circle" v-b-tooltip title="Notað ef skólar vilja flokka saman ákveðna tegundir áfanga."></i>
          </td>
          <td>
            <b-form-group
              label-for="tegund_afanga"
              :state="submitted && errors.has('tegund_afanga') ? false : ''"
              :invalid-feedback="errors.first('tegund_afanga')"
            >
              <v-select
                id="tegund_afanga"
                name="tegund_afanga"
                v-model="form.teg_afanga"
                :clearable="true"
                :state="submitted && errors.has('tegund_afanga') ? false : ''"
                :options="moduleTypes"
                label="heiti"
                data-vv-as="tegund"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Athugasemdir</td>
          <td>
            <b-form-group label-for="aths" :state="submitted && errors.has('aths') ? false : ''" :invalid-feedback="errors.first('aths')">
              <b-form-textarea
                id="aths"
                name="aths"
                v-model="form.aths"
                :state="submitted && errors.has('tegund_afanga') ? false : ''"
                data-vv-as="aths"
                style="font-size: 13px"
              >
              </b-form-textarea>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <b-row>
              <b-col class="pl-0" cols="4">
                Virkur
                <i
                  class="fa fa-info-circle"
                  v-b-tooltip
                  title="Áfangi birtist alltaf á námsferli og skírteinum nemenda sem hafa lokið honum hvort sem hann er virkur eða ekki"
                ></i>
                <b-form-checkbox v-model="form.gildi"></b-form-checkbox>
              </b-col>
            </b-row>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <b-row>
              <b-col class="pl-0">
                Á námsferil <i class="fa fa-info-circle" v-b-tooltip title="Á áfangi að koma fram á námsferli og skírteinum nemenda?"></i>
                <b-form-checkbox v-model="form.a_namsferil"></b-form-checkbox>
              </b-col>
              <b-col>
                Í fjarvistarferil
                <i
                  class="fa fa-info-circle"
                  v-b-tooltip
                  title="Áfangi þarf að vera í fjarvistarferli til að hægt sé að skrá mætingu hjá nemendum"
                ></i>
                <b-form-checkbox v-model="form.i_fjarvistarferil"></b-form-checkbox>
              </b-col>
              <b-col>
                Oft í námsferli
                <i
                  class="fa fa-info-circle"
                  v-b-tooltip
                  title="Má taka áfanga oft þrátt fyrir að hafa lokið honum,
                  ath. áfangi getur samt bara verið einu sinni á hverju kennslutímabili"
                ></i>
                <b-form-checkbox v-model="form.fjolval"></b-form-checkbox>
              </b-col>
            </b-row>
          </td>
        </tr>
        <tr v-if="!isLearningCenter || isPrimarySchool">
          <td colspan="2">
            <b-row>
              <b-col class="pl-0" v-if="!isPrimarySchool">
                Fornámsáfangi
                <i
                  class="fa fa-info-circle"
                  v-b-tooltip
                  title="Áfangi sem nemendur þurfa að taka ef þeir uppfylla ekki lágmarks kröfur fyrir framhaldsskólaáfanga"
                ></i>
                <b-form-checkbox v-model="form.fornamsafangi"></b-form-checkbox>
              </b-col>
              <b-col :class="{ 'pl-0': isPrimarySchool }" v-if="!isLearningCenter">
                Skólasóknaráfangi
                <i class="fa fa-info-circle" v-b-tooltip title="Áfangi sem er notaður til að reikna út einkunn fyrir skólasókn."></i>
                <b-form-checkbox v-model="form.skolasokn"></b-form-checkbox>
              </b-col>
              <b-col v-if="!isLearningCenter">
                Umsjónaráfangi
                <i
                  class="fa fa-info-circle"
                  v-b-tooltip
                  title="Áfangi sem er notaður til að reikna út einkunn fyrir skólasókn.Áfangi sem er notaður fyrir umsjónarkennara."
                ></i>
                <b-form-checkbox v-model="form.umsj_afangi"></b-form-checkbox>
              </b-col>
            </b-row>
          </td>
        </tr>
        <tr v-if="!afangaskoli">
          <td>Til stúdentsprófs</td>
          <td>
            <b-form-group
              label-for="tilStudentsprofs"
              :state="submitted && errors.has('tilStudentsprofs') ? false : ''"
              :invalid-feedback="errors.first('tilStudentsprofs')"
            >
              <b-form-checkbox id="tilStudentsprofs" v-model="form.studentsprof"></b-form-checkbox>
            </b-form-group>
          </td>
        </tr>
        <tr v-if="!afangaskoli">
          <td>Einingar til stúdentsprófs</td>
          <td>
            <b-form-group
              label-for="einStudentsprofs"
              :state="submitted && errors.has('einStudentsprofs') ? false : ''"
              :invalid-feedback="errors.first('einStudentsprofs')"
            >
              <b-form-input
                id="einStudentsprofs"
                name="einStudentsprofs"
                type="number"
                v-model="form.einingar_stprof"
                :state="submitted && errors.has('einStudentsprofs') ? false : ''"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
      </table>
    </div>
    <div v-if="type == 'timetable' && !schoolHasSettings('stundatoflukerfi')">
      <i>Upplýsingar fyrir stundatöfluforrit</i>
      <div class="mb-2 mt-2" style="font-size: 13px">
        Eftirfarandi upplýsingar eru einungis notaðar ef skólinn lætur stundatöfluforritið raða sjálfvirkt í töflu. Ef skóli notar ekki
        stundatöfluforritið heldur stundatöflugerðina í admin þarf ekki ekki fylla þessar upplýsingar út nema skólar sem nota vinnumat þeir
        þurfa að fylla út fjöldatölur.
      </div>
      <table class="table less-padding module-create-table">
        <tr>
          <td>
            Fjöldi nemenda í hóp
            <i class="fa fa-info-circle" v-b-tooltip title="Æskilegur fjöldi nemenda í hóp"></i>
          </td>
          <td>
            <b-form-group
              label-for="max_fj_nema"
              :state="submitted && errors.has('max_fj_nema') ? false : ''"
              :invalid-feedback="errors.first('max_fj_nema')"
            >
              <b-form-input
                id="max_fj_nema"
                name="max_fj_nema"
                type="number"
                v-model="form.max_fj_nema"
                :state="submitted && errors.has('max_fj_nema') ? false : ''"
                data-vv-as="fjöldi"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Frávik - fj. nemenda</td>
          <td>
            <b-form-group
              label-for="fravik"
              :state="submitted && errors.has('fravik') ? false : ''"
              :invalid-feedback="errors.first('fravik')"
            >
              <b-form-input
                id="fravik"
                name="fravik"
                type="number"
                v-model="form.fravik"
                :state="submitted && errors.has('fravik') ? false : ''"
                data-vv-as="frávik"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Hámarksfjöldi í hóp <i class="fa fa-info-circle" v-b-tooltip title="Má ekki fara yfir þennan fjölda í hóp"></i></td>
          <td>
            <b-form-group
              label-for="efstu_mork"
              :state="submitted && errors.has('efstu_mork') ? false : ''"
              :invalid-feedback="errors.first('efstu_mork')"
            >
              <b-form-input
                id="efstu_mork"
                name="efstu_mork"
                type="number"
                v-model="form.efstu_mork"
                :state="submitted && errors.has('efstu_mork') ? false : ''"
                data-vv-as="hámarksfjöldi"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Fjöldi tíma á viku <i class="fa fa-info-circle" v-b-tooltip title="Hversu oft í viku er áfanginn kenndur?"></i></td>
          <td>
            <b-form-group
              label-for="timar_viku_nemar"
              :state="submitted && errors.has('timar_viku_nemar') ? false : ''"
              :invalid-feedback="errors.first('timar_viku_nemar')"
            >
              <b-form-input
                id="timar_viku_nemar"
                name="timar_viku_nemar"
                type="number"
                v-model="form.timar_viku_nemar"
                :state="submitted && errors.has('timar_viku_nemar') ? false : ''"
                data-vv-as="fj. tíma á viku"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>
            Fjöldi samfelldra tíma
            <i
              class="fa fa-info-circle"
              v-b-tooltip
              title="Hversu marga tíma er kennt samfellt, t.d. áfangi er alltaf kenndur tvo tíma í einu þá er sett 2 hér."
            ></i>
          </td>
          <td>
            <b-form-group
              label-for="fj_samf_tima"
              :state="submitted && errors.has('fj_samf_tima') ? false : ''"
              :invalid-feedback="errors.first('fj_samf_tima')"
            >
              <b-form-input
                id="fj_samf_tima"
                name="fj_samf_tima"
                type="number"
                v-model="form.fj_samf_tima"
                :state="submitted && errors.has('fj_samf_tima') ? false : ''"
                data-vv-as="fj. tíma á viku"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Tegund stofu <i class="fa fa-info-circle" v-b-tooltip title="Hvernig stofu þarf að nota."></i></td>
          <td>
            <b-form-group
              label-for="teg_stofu"
              :state="submitted && errors.has('teg_stofu') ? false : ''"
              :invalid-feedback="errors.first('teg_stofu')"
            >
              <v-select
                id="teg_stofu"
                name="teg_stofu"
                v-model="form.teg_stofu"
                :clearable="true"
                :state="submitted && errors.has('teg_stofu') ? false : ''"
                :options="classrooms"
                label="heiti"
                data-vv-as="stofa"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>

        <tr v-if="form.teg_stofu_vara || show.classroomBackup">
          <td>
            Tegund stofu - Vara
            <i class="fa fa-info-circle" v-b-tooltip title="Ef skráð er varastofa þá er sett í hana ef hitt er allt fullt."></i>
          </td>
          <td>
            <b-form-group
              label-for="teg_stofu_vara"
              :state="submitted && errors.has('teg_stofu_vara') ? false : ''"
              :invalid-feedback="errors.first('teg_stofu_vara')"
            >
              <v-select
                id="teg_stofu_vara"
                name="teg_stofu_vara"
                v-model="form.teg_stofu_vara"
                :clearable="true"
                :state="submitted && errors.has('teg_stofu_vara') ? false : ''"
                :options="classrooms"
                label="heiti"
                data-vv-as="stofa"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>

        <tr v-if="form.teg_stofu_2 || show.classroomExtra">
          <td>
            Tegund stofu - aukastofa
            <i class="fa fa-info-circle" v-b-tooltip title="Ef kenna á í tveimur stofum t.d. verklegt nám og bóklegt."></i>
          </td>
          <td>
            <b-form-group
              label-for="teg_stofu_2"
              :state="submitted && errors.has('teg_stofu_2') ? false : ''"
              :invalid-feedback="errors.first('teg_stofu_2')"
            >
              <v-select
                id="teg_stofu_2"
                name="teg_stofu_2"
                v-model="form.teg_stofu_2"
                :clearable="true"
                :state="submitted && errors.has('teg_stofu_2') ? false : ''"
                :options="classrooms"
                label="heiti"
                data-vv-as="stofa"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
        <tr v-if="form.teg_stofu_2 || show.classroomExtra">
          <td>Fjöldi nemenda - aukastofa</td>
          <td>
            <b-form-group
              label-for="max_fj_nema_2"
              :state="submitted && errors.has('max_fj_nema_2') ? false : ''"
              :invalid-feedback="errors.first('max_fj_nema_2')"
            >
              <b-form-input
                id="max_fj_nema_2"
                name="max_fj_nema_2"
                type="number"
                v-model="form.max_fj_nema_2"
                :state="submitted && errors.has('max_fj_nema_2') ? false : ''"
                data-vv-as="fj. nema"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr v-if="form.teg_stofu_2 || show.classroomExtra">
          <td>Fjöldi samfelldra tíma - aukastofa</td>
          <td>
            <b-form-group
              label-for="fj_samf_tima_2"
              :state="submitted && errors.has('fj_samf_tima_2') ? false : ''"
              :invalid-feedback="errors.first('fj_samf_tima_2')"
            >
              <b-form-input
                id="fj_samf_tima_2"
                name="fj_samf_tima_2"
                type="number"
                v-model="form.fj_samf_tima_2"
                :state="submitted && errors.has('fj_samf_tima_2') ? false : ''"
                data-vv-as="fj. samf. tíma"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr v-if="form.teg_stofu_2 || show.classroomExtra">
          <td>Fjöldi tíma á viku - aukastofa</td>
          <td>
            <b-form-group
              label-for="timar_viku_nemar_2"
              :state="submitted && errors.has('timar_viku_nemar_2') ? false : ''"
              :invalid-feedback="errors.first('timar_viku_nemar_2')"
            >
              <b-form-input
                id="timar_viku_nemar_2"
                name="timar_viku_nemar_2"
                type="number"
                v-model="form.timar_viku_nemar_2"
                :state="submitted && errors.has('timar_viku_nemar_2') ? false : ''"
                data-vv-as="fj. tíma á viku"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr v-if="form.teg_stofu && ((!form.teg_stofu_vara && !show.classroomBackup) || (!form.teg_stofu_2 && !show.classroomExtra))">
          <td></td>
          <td>
            <b-btn
              variant="primary"
              class="btn-xs mr-2"
              style="font-size: 13px"
              v-if="form.teg_stofu && !form.teg_stofu_vara && !show.classroomBackup"
              @click="show.classroomBackup = true"
              >Bæta við varastofu
            </b-btn>
            <b-btn
              variant="primary"
              class="btn-xs"
              style="font-size: 13px"
              v-if="form.teg_stofu && !form.teg_stofu_2 && !show.classroomExtra"
              @click="show.classroomExtra = true"
              >Bæta við aukastofu
            </b-btn>
          </td>
        </tr>
      </table>
    </div>
    <div v-if="type == 'primary' && showPrimary">
      <i>Upplýsingar vegna grunnskólaeinkunna</i>

      <div class="mb-2 mt-2" style="font-size: 13px">
        Ef nemendur þurfa að vera með lágmarkseinkunn í grunnskólaeinkunn til að komast í áfangann þarf að fylla eftirfarandi upplýsingar
        út.
      </div>
      <table class="table less-padding module-create-table">
        <tr>
          <td>Grunnskólafag</td>
          <td>
            <b-form-group label-for="fag" :state="submitted && errors.has('fag') ? false : ''" :invalid-feedback="errors.first('fag')">
              <v-select
                id="fag"
                name="fag"
                v-model="form.fag"
                :clearable="true"
                :state="submitted && errors.has('fag') ? false : ''"
                :options="primarySchoolSubjects"
                label="fagheiti"
                data-vv-as="fag"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Grunnskólaeinkunnir (Bókstafir)</td>
          <td>
            <b-form-group
              label-for="bokstafir"
              :state="submitted && errors.has('bokstafir') ? false : ''"
              :invalid-feedback="errors.first('bokstafir')"
            >
              <div v-for="(bokstafur, idx) in form.bokstafir" :key="idx">
                <b-form-checkbox v-model="bokstafur.selected" class="d-inline-block"></b-form-checkbox>
                {{ bokstafur.id }}
              </div>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Grunnskólaeinkunn frá (Tölustafir)</td>
          <td>
            <b-form-group
              label-for="grskeink_fra"
              :state="submitted && errors.has('grskeink_fra') ? false : ''"
              :invalid-feedback="errors.first('grskeink_fra')"
            >
              <b-form-input
                id="grskeink_fra"
                name="grskeink_fra"
                type="text"
                v-model="form.grskeink_fra"
                :state="submitted && errors.has('grskeink_fra') ? false : ''"
                data-vv-as="grunnskólaeinkunn"
                v-validate="{ regex: /^[0-9\.\,\/]+$/ }"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Grunnskólaeinkunn til (Tölustafir)</td>
          <td>
            <b-form-group
              label-for="grskeink_til"
              :state="submitted && errors.has('grskeink_til') ? false : ''"
              :invalid-feedback="errors.first('grskeink_til')"
            >
              <b-form-input
                id="grskeink_til"
                name="grskeink_til"
                type="text"
                v-model="form.grskeink_til"
                :state="submitted && errors.has('grskeink_til') ? false : ''"
                data-vv-as="grunnskólaeinkunn"
                v-validate="{ regex: /^[0-9\.\,\/]+$/ }"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
      </table>
    </div>
    <div v-if="type === 'primary' && isPrimarySchool">
      <i>Gögn til MMS</i>

      <div class="mb-2 mt-2" style="font-size: 13px">Ef fyllt er út í námsgrein þá fara einkunnir úr þessum áfanga til MMS.</div>
      <table class="table less-padding module-create-table">
        <tr>
          <td>Námsgrein</td>
          <td>
            <b-form-group label-for="fag" :state="submitted && errors.has('fag') ? false : ''" :invalid-feedback="errors.first('fag')">
              <v-select
                id="fag"
                name="fag"
                v-model="form.fag"
                :clearable="true"
                :state="submitted && errors.has('fag') ? false : ''"
                :options="primarySchoolSubjects"
                label="fagheiti"
                data-vv-as="fag"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
      </table>
    </div>
    <div v-if="type === 'price'">
      <i>Gjöld</i>
      <table class="table less-padding module-create-table">
        <tr>
          <td>Tegund gjalds</td>
          <td>
            <b-form-group
              label-for="tegund_gjalds"
              :state="submitted && errors.has('tegund_gjalds') ? false : ''"
              :invalid-feedback="errors.first('tegund_gjalds')"
            >
              <v-select
                id="tegund_gjalds"
                name="tegund_gjalds"
                v-model="form.tegund_gjalds"
                :clearable="true"
                :state="submitted && errors.has('tegund_gjalds') ? false : ''"
                :options="priceTypes"
                label="heiti"
                data-vv-as="tegund gjalds"
              >
              </v-select>
            </b-form-group>
            <div v-if="form.tegund_gjalds && form.tegund_gjalds.hamarksupph" class="pt-2">
              <i class="fa fa-info-circle"></i>
              Ath. skilgreind hámarksupphæð fyrir valið gjald er: {{ form.tegund_gjalds.hamarksupph | currency }}
            </div>
          </td>
        </tr>
        <tr>
          <td>Upphæð</td>
          <td>
            <b-form-group
              label-for="upphaed"
              :state="submitted && errors.has('upphaed') ? false : ''"
              :invalid-feedback="errors.first('upphaed')"
            >
              <b-form-input
                id="upphaed"
                name="upphaed"
                type="text"
                v-model="form.upphaed"
                :state="submitted && errors.has('upphaed') ? false : ''"
                data-vv-as="upphæð"
                v-validate="{ max_value: form.tegund_gjalds ? form.tegund_gjalds.hamarksupph : '' }"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
      </table>
    </div>
    <b-btn variant="primary" @click="validateBeforeSubmit()" :disabled="loading">Vista</b-btn>
  </Slidebar>
</template>

<script>
import types from '@/api/types';
import curriculum from '@/api/curriculum';
import structure from '@/api/structure';
import common from '@/api/common';
import { mapGetters, mapActions } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';
import Loader from '@/components/common/Loader.vue';
import { Validator } from 'vee-validate';

const isSmaller = (value, { max } = {}) => {
  return !max || Number(max) >= value;
};
const paramNames = ['max'];

Validator.extend('max_value', isSmaller, {
  paramNames,
});

export default {
  name: 'module-edit-slidebar',
  components: {
    Slidebar,
    Loader,
  },
  props: ['id', 'type', 'large', 'extraLarge'],
  computed: {
    showPrimary() {
      return (
        this.schoolSettings &&
        this.schoolSettings.tegund_skola_id !== 3 &&
        this.schoolSettings.tegund_skola_id !== 4 &&
        this.schoolSettings.tegund_skola_id !== 5
      );
    },
    isPrimarySchool() {
      return this.schoolSettings && this.schoolSettings.tegund_skola_id === 3;
    },
    isLearningCenter() {
      return this.schoolSettings && (this.schoolSettings.tegund_skola_id === 4 || this.schoolSettings.tegund_skola_id === 5);
    },
    getIdentity() {
      return `${this.form.namsgrein && this.form.namsgrein.kodi ? this.form.namsgrein.kodi : ''}${this.form.afanga_nr}`;
    },
    afangaskoli() {
      return this.schoolSettings && this.schoolSettings.afangaskoli ? this.schoolSettings.afangaskoli === 1 : false;
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_afangar');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      item: '',
      submitted: false,
      loading: false,
      originalUnits: '',
      editSubject: false,
      form: {
        namsgrein: '',
        namsgrein_id: '',
        afanga_heiti: '',
        afanga_nr: '',
        afanga_heiti_enska: '',
        undirskoli: '',
        deildir: [],
        einingar: '',
        trep: '',
        gildi: true,
        aths: '',
        a_namsferil: true,
        i_fjarvistarferil: true,
        fornamsafangi: false,
        skolasokn: false,
        umsj_afangi: false,
        fjolval: false,
        teg_afanga: '',
        studentsprof: false,
        einingar_stprof: '',
        timar_onn_alls: '',
        max_fj_nema: '',
        fravik: '',
        efstu_mork: '',
        timar_viku_nemar: '',
        fj_samf_tima: '',
        teg_stofu: '',
        teg_stofu_2: '',
        teg_stofu_vara: '',
        max_fj_nema_2: '',
        fj_samf_tima_2: '',
        timar_viku_nemar_2: '',
        grskeink_fra: '',
        grskeink_til: '',
        fag: '',
        bokstafir: [
          { id: 'A', selected: false },
          { id: 'B+', selected: false },
          { id: 'B', selected: false },
          { id: 'C+', selected: false },
          { id: 'C', selected: false },
          { id: 'D', selected: false },
          { id: 'Annað', selected: false },
        ],
        tegund_gjalds: '',
        upphaed: '',
      },
      show: {
        classroomBackup: false,
        classroomExtra: false,
      },
      divisions: [],
      departments: [],
      classrooms: [],
      moduleTypes: [],
      primarySchoolSubjects: [],
      subjects: [],
      priceTypes: [],
    };
  },
  methods: {
    async loadDivisions() {
      try {
        const response = await structure.getDivisionList();
        this.divisions = response.data.items.map(x => ({
          ...x,
          label: `${x.code} - ${x.name}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadDepartments() {
      try {
        const response = await structure.getDepartmentList();
        this.departments = response.data.items.map(x => ({
          ...x,
          label: `${x.name}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadTypes() {
      try {
        const response = await types.getModuleList();
        this.moduleTypes = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadClassrooms() {
      try {
        const response = await types.getClassroomList({ active: 1 });
        this.classrooms = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadPrimarySchoolSubjects() {
      try {
        const response = await common.getPrimarySchoolSubjects();
        this.primarySchoolSubjects = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadSubjects(id) {
      try {
        const response = await curriculum.getSubjectsList();
        this.subjects = response.data.items
          .filter(x => x.virkur === 1 || x.namsgrein_id === id)
          .map(x => ({
            ...x,
            label: `${x.heiti ? x.heiti : ''} ${x.kodi ? x.kodi : ''}`,
          }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadPriceTypes() {
      try {
        const response = await types.getPriceList();
        this.priceTypes = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadDetails() {
      if (this.canEdit) {
        try {
          this.loading = true;
          const response = await curriculum.getModuleDetails(this.id);
          this.item = response.data;
          this.form.namsgrein_id = this.item.namsgrein_id;
          this.form.afanga_nr = this.item.afanga_nr;
          this.form.afanga_heiti = this.item.afanga_heiti;
          this.form.afanga_heiti_enska = this.item.afanga_heiti_enska;
          this.form.einingar = this.item.einingar;
          this.originalUnits = this.item.einingar;
          this.form.trep = this.item.trep;
          this.form.timar_onn_alls = this.item.timar_onn_alls;
          this.form.a_namsferil = this.item.a_namsferil === 1;
          this.form.i_fjarvistarferil = this.item.i_fjarvistarferil === 1;
          this.form.fornamsafangi = this.item.fornamsafangi === 1;
          this.form.skolasokn = this.item.skolasokn === 1;
          this.form.umsj_afangi = this.item.umsj_afangi === 1;
          this.form.fjolval = this.item.fjolval === 1;
          this.form.gildi = this.item.gildi === 1;
          this.form.studentsprof = this.item.studentsprof === 1;
          this.form.einingar_stprof = this.item.einingar_stprof;
          this.form.aths = this.item.aths;

          this.form.max_fj_nema = this.item.max_fj_nema;
          this.form.fravik = this.item.fravik;
          this.form.efstu_mork = this.item.efstu_mork;
          this.form.timar_viku_nemar = this.item.timar_viku_nemar;
          this.form.fj_samf_tima = this.item.fj_samf_tima;
          this.form.max_fj_nema_2 = this.item.max_fj_nema_2;
          this.form.fj_samf_tima_2 = this.item.fj_samf_tima_2;
          this.form.timar_viku_nemar_2 = this.item.timar_viku_nemar_2;

          this.form.grskeink_fra = this.item.grskeink_fra;
          this.form.grskeink_til = this.item.grskeink_til;

          await this.loadDivisions();
          this.form.undirskoli = this.divisions.find(x => x.division_id === this.item.undirskoli_id);
          await this.loadDepartments();
          this.form.deildir = this.departments.filter(x => this.item.deild.find(y => y.deild_id === x.department_id) !== undefined);

          await this.loadTypes();
          this.form.teg_afanga = this.moduleTypes.find(x => x.teg_afanga === this.item.teg_afanga);

          await this.loadClassrooms();
          this.form.teg_stofu = this.classrooms.find(x => x.teg_stofu === this.item.teg_stofu);
          this.form.teg_stofu_vara = this.classrooms.find(x => x.teg_stofu === this.item.teg_stofu_vara);
          this.form.teg_stofu_2 = this.classrooms.find(x => x.teg_stofu === this.item.teg_stofu_2);

          await this.loadPrimarySchoolSubjects();
          this.form.fag = this.primarySchoolSubjects.find(x => x.fagid === this.item.fag_id);
          const letters = ((this.item.grskeink_bokstafir || '').replaceAll(' ', '') || '').split(',');
          this.form.bokstafir.forEach(letter => {
            this.$set(letter, 'selected', letters.indexOf(letter.id) > -1);
          });
          await this.loadSubjects(this.item.namsgrein_id);
          this.form.namsgrein = this.subjects.find(x => x.namsgrein_id === this.item.namsgrein_id);

          await this.loadPriceTypes();
          this.form.tegund_gjalds = this.priceTypes.find(x => x.tegund_id === this.item.tegund_gjalda_id);
          this.form.upphaed = this.item.upphaed;
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading = false;
        }
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });
        let conf = true;
        if (this.form.namsgrein.namsgrein_id !== this.form.namsgrein_id) {
          conf = confirm('Ertu alveg viss um að þú viljir breyta um námsgrein ?'); // eslint-disable-line
        }
        if (conf) {
          const item = {
            undirskoli_id: this.form.undirskoli.division_id,
            namsgrein_id: this.form.namsgrein.namsgrein_id,
            deildir: this.form.deildir.map(x => x.department_id).join(','),
            afanga_nr: this.form.afanga_nr,
            afanga_heiti: this.form.afanga_heiti,
            einingar: this.form.einingar ? this.form.einingar.toString().replace('.', ',') : '',
            timar_viku_nemar: this.form.timar_viku_nemar,
            fj_samf_tima: this.form.fj_samf_tima,
            max_fj_nema: this.form.max_fj_nema,
            fravik: this.form.fravik,
            efstu_mork: this.form.efstu_mork,
            a_namsferil: this.form.a_namsferil ? 1 : 0,
            fjolval: this.form.fjolval ? 1 : 0,
            teg_afanga: this.form.teg_afanga ? this.form.teg_afanga.teg_afanga : '',
            studentsprof: this.form.studentsprof ? 1 : (!this.afangaskoli ? 0 : ''), // eslint-disable-line
            aths: this.form.aths,

            einingar_stprof: this.form.einingar_stprof,
            teg_stofu: this.form.teg_stofu ? this.form.teg_stofu.teg_stofu : '',
            i_fjarvistarferil: this.form.i_fjarvistarferil ? 1 : 0,
            grskeink_fra: this.form.grskeink_fra ? this.form.grskeink_fra.toString().replace('.', ',') : '',
            grskeink_til: this.form.grskeink_til ? this.form.grskeink_til.toString().replace('.', ',') : '',
            fag_id: this.form.fag ? this.form.fag.fagid : '',
            skolasokn: this.form.skolasokn ? 1 : 0,
            afanga_heiti_enska: this.form.afanga_heiti_enska,
            timar_onn_alls: this.form.timar_onn_alls,
            fornamsafangi: this.form.fornamsafangi ? 1 : 0,
            teg_stofu_vara: this.form.teg_stofu_vara ? this.form.teg_stofu_vara.teg_stofu : '',
            teg_stofu_2: this.form.teg_stofu_2 ? this.form.teg_stofu_2.teg_stofu : '',
            max_fj_nema_2: this.form.max_fj_nema_2,
            timar_viku_nemar_2: this.form.timar_viku_nemar_2,
            fj_samf_tima_2: this.form.fj_samf_tima_2,
            umsj_afangi: this.form.umsj_afangi ? 1 : 0,
            trep: this.form.trep,
            gildi: this.form.gildi ? 1 : 0,
            grskeink_bokstafir: this.form.bokstafir
              .filter(x => x.selected)
              .map(x => x.id)
              .join(', '),
            tegund_gjalda_id: this.form.tegund_gjalds ? this.form.tegund_gjalds.tegund_id : '',
            upphaed: this.form.upphaed ? this.form.upphaed.toString().replace('.', ',') : '',
          };

          await curriculum.updateModule(this.id, item);
          this.displaySuccess('Áfanga breytt');
          this.closeSlidebar();
        }
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        if (e.response && e.response.data && e.response.data.error) {
          this.displayError(e.response.data.error);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    closeSlidebar(item) {
      this.$emit('closeSlidebar', item);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.$nextTick(() => {
      if (this.$refs.focusInput) {
        this.$refs.focusInput.focus();
      }
    });
    this.loadDetails();
  },
};
</script>

<style lang="scss">
.module-create-table {
  font-size: 13px;
  input {
    height: 25px !important;
    font-size: 13px;
    &.vs__search {
      margin: 0;
    }
  }
  .form-group {
    margin-bottom: 0px;
  }
}
</style>
