<template>
  <div>
    <h5 class="student-title mt-3">
      <span v-b-tooltip title="Aðstandendur skráði í Innu">Aðstandendur</span>
      <b-link @click="openSendMessageSlidebar()"
        ><i class="fa fa-envelope pl-1" v-b-tooltip.hover title="Senda póst á aðstandendur"></i
      ></b-link>
      <b-link @click="openEditSlidbar()" v-if="canEdit"
        ><i class="fa fa-plus-circle float-right" v-b-tooltip.hover title="Bæta við aðstandanda"></i
      ></b-link>
    </h5>
    <table class="table less-padding no-border mb-1" style="font-size: 13px">
      <thead>
        <tr>
          <th>Nafn</th>
          <th>Kennitala</th>
          <th>Heimilisfang</th>
          <th>Sími</th>
          <th>Farsími</th>
          <th>Netfang</th>
          <th>
            Forráðamaður
            <i
              v-if="item && item.aldur < 18"
              class="fa fa-info-circle"
              v-b-tooltip
              title='Þegar nemandi er undir 18 ára aldri þarf rofinn "Forráðamaður" að vera "Já" til þess að aðstandandi geti skráð
              sig inn í Innu og fái póst frá kerfinu.'
            ></i>
          </th>
          <th>Tengsl</th>
          <th v-if="item && item.aldur > 18">
            Aðgangur
            <i
              class="fa fa-info-circle"
              v-b-tooltip
              title='Þegar nemandi er yfir 18 ára aldri þarf rofinn "Aðgangur" að vera "Já" til
              þess að aðstandandi geti skráð sig inn í Innu og fái póst frá kerfinu.'
            ></i>
          </th>
          <th>Röðun</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <TableRowLoader v-if="loading.relatives" colspan="11" :center="true" />
        <TableRowEmpty v-if="!loading.relatives && this.relatives.length === 0" colspan="11" :center="true" />
        <tr v-for="r in relatives" :key="r.forrad_id">
          <td>{{ r.nafn }}</td>
          <td>{{ r.ktala }}</td>
          <td>{{ r.postnr_adsetur }}</td>
          <td>{{ r.simi_as }}</td>
          <td>{{ r.simi_gsm }}</td>
          <td>{{ r.netfang }}</td>
          <td><yes-no :item="r.forrad_adstand"></yes-no></td>
          <td>{{ r.tengsl }}</td>
          <td v-if="item && item.aldur > 18"><yes-no :item="r.adgangur"></yes-no></td>
          <td>{{ r.rodun }}</td>
          <td>
            <i class="fa fa-pencil text-primary cursor-pointer" @click="openEditSlidbar(r)"></i>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-if="item && item.aldur < 18">
      <h5 class="student-title mt-3">Aðstandendur skv. þjóðskrá</h5>
      <table class="table less-padding no-border mb-1" style="font-size: 13px">
        <thead>
          <tr>
            <th>Nafn</th>
            <th>Kennitala</th>
            <th>Heimilisfang</th>
            <th>Fjölskyldunúmer</th>
          </tr>
        </thead>
        <tbody>
          <TableRowLoader v-if="loading.guardians" colspan="4" :center="true" />
          <TableRowEmpty v-if="!loading.guardians && this.guardians.length === 0" colspan="4" :center="true" />
          <tr v-for="g in guardians" :key="g.kennitala">
            <td>{{ g.nafn }}</td>
            <td>{{ g.kennitala }}</td>
            <td>{{ g.heimilisfang }}</td>
            <td>{{ g.kennitala_fjolskyldunumer }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <relative-edit-slidebar
      v-if="showEditSlidebar"
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
      :studentId="id"
      :item="selectedItem"
      :aldur="item && item.aldur"
    ></relative-edit-slidebar>
    <send-message-slidebar
      v-if="showSendMessageSlidebar"
      :notandi_id="item && item.notandi_id"
      :eingonguForradamenn="true"
      @closeSlidebar="closeSlidebar"
    >
    </send-message-slidebar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import students from '@/api/students';

import YesNo from '@/components/common/YesNo.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import TableRowLoader from '@/components/common/TableRowLoader.vue';

import RelativeEditSlidebar from '@/components/students/student/relatives/RelativeEditSlidebar.vue';
import SendMessageSlidebar from '@/components/common/SendMessageSlidebar.vue';

export default {
  name: 'student-relative-list',
  props: ['id', 'item'],
  components: {
    YesNo,
    TableRowEmpty,
    TableRowLoader,
    RelativeEditSlidebar,
    SendMessageSlidebar,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('nemendahald_nemendur');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('nemendahald_nemendur');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      loading: {
        relatives: false,
        guardians: false,
      },
      relatives: [],
      guardians: [],
      showSendMessageSlidebar: false,
      showEditSlidebar: false,
    };
  },
  methods: {
    async loadRelatives() {
      try {
        this.loading.relatives = true;
        const response = await students.getStudentRelatives(this.id);
        this.relatives = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.relatives = false;
      }
    },
    async loadGuardians() {
      try {
        this.loading.guardians = true;
        const response = await students.getStudentGuardians(this.id);
        this.guardians = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.guardians = false;
      }
    },
    openEditSlidbar(item) {
      this.showEditSlidebar = true;
      this.showSendMessageSlidebar = false;
      this.selectedItem = item;
    },
    openSendMessageSlidebar() {
      this.showSendMessageSlidebar = true;
      this.showEditSlidebar = false;
    },
    closeSlidebar() {
      this.showEditSlidebar = false;
      this.showSendMessageSlidebar = false;
      this.selectedItem = '';
    },
    closeAndReloadSlidebar() {
      this.closeSlidebar();
      this.loadRelatives();
    },
  },
  created() {
    this.loadRelatives();
    if (this.item && this.item.aldur < 18) {
      this.loadGuardians();
    }
  },
};
</script>
<style lang="scss"></style>
