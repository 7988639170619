<template>
  <tr>
    <td :colspan="colspan" class="table-empty" :class="{ 'text-center': center }">Engar niðurstöður fundust</td>
  </tr>
</template>

<script>
export default {
  name: 'table-row-empty',
  props: ['colspan', 'center'],
};
</script>

<style lang="scss" scoped>
.table-empty {
  font-style: italic;
}
</style>
