<!-- Kladdi -->

<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <b-form-group label="Kennslutímabil" label-for="term">
            <v-select id="term" name="term" v-model="search.termId" :options="terms" :clearable="false" @input="loadModules(search.termId)">
            </v-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Áfangi" label-for="module">
            <v-select
              id="afangi"
              name="afangi"
              v-model="search.moduleId"
              :options="modules"
              :clearable="true"
              label="namsgrein_afanganr"
            ></v-select>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group label="Forföll" label-for="absenceCode">
            <v-select id="teg_forfalla" name="heiti" v-model="search.absenceCodeId" :options="absenceCodes"> </v-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Viðvera" label-for="attendanceCode">
            <v-select id="teg_fjarvista" name="heiti" v-model="search.attendanceCodeId" :options="attendanceCodes"> </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Dags. frá" label-for="dateFrom">
            <datepicker
              id="dateFrom"
              name="dateFrom"
              v-model="search.dateFrom"
              :language="lang"
              :monday-first="true"
              format="dd.MM.yyyy"
              :typeable="false"
              ref="dateFromOpenPickr"
            ></datepicker>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Dags. til" label-for="dateTo">
            <datepicker
              id="dateTo"
              name="dateTo"
              v-model="search.dateTo"
              :language="lang"
              :monday-first="true"
              format="dd.MM.yyyy"
              :typeable="false"
              ref="dateFromOpenPickr"
            ></datepicker>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label-for="comment" label="Athugasemd">
            <b-form-input
              id="comment"
              name="comment"
              type="text"
              v-on:keyup.enter="loadList"
              v-model="search.comment"
              maxLength="4000"
              data-vv-as="athugasemd"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-btn variant="primary" class="w-100 mt-20 mt-4" @click="loadList()" v-if="terms.length > 0">Leita</b-btn>
        </b-col>
      </b-row>
    </b-card>
    <div>
      <table class="table mt-4" style="font-size: 13px">
        <thead v-if="!loading.list">
          <tr v-if="firsttime">
            <th style="width: 2%; white-space: nowrap">
              <b-form-checkbox v-if="canEdit" v-model="selectAll" @change="updateAll()" class="d-inline-block"></b-form-checkbox>
              <b-btn
                variant="primary"
                class="btn-xs d-inline-block"
                @click="openMassEditSlidebar()"
                v-if="selectedAttendances.length > 0"
                style="font-size: 12px"
              >
                <i class="fa fa-pencil"></i>
              </b-btn>
            </th>
            <th><LinkOrder title="Áfangi" :filteredList="list" :list="list" column="namsgrein_afanganr" /> ({{ list.length }})</th>
            <th>
              <LinkOrder title="Hópur" :filteredList="list" :list="list" column="hopur" />
            </th>
            <th>
              <LinkOrder title="Dags." :filteredList="list" :list="list" column="dags_fra" />
            </th>
            <th>
              <LinkOrder title="Tími frá" :filteredList="list" :list="list" column="timi_fra" />
            </th>
            <th>
              <LinkOrder title="Tími til" :filteredList="list" :list="list" column="timi_til" />
            </th>
            <th>
              <LinkOrder title="Fjarvistarkóði" :filteredList="getList" :list="list" column="kodi_fjarvist" />
            </th>
            <th>
              <LinkOrder title="Fjarvistir heiti" :filteredList="getList" :list="list" column="heiti_fjarvist" />
            </th>
            <th>
              <LinkOrder title="Forfallakóði" :filteredList="getList" :list="list" column="kodi_forfoll" />
            </th>
            <th>
              <LinkOrder title="Forföll heiti" :filteredList="getList" :list="list" column="heiti_forfoll" />
            </th>
            <th>
              <LinkOrder title="Athugasemd" :filteredList="getList" :list="list" column="athugasemd" />
            </th>
          </tr>
        </thead>
        <tbody>
          <TableRowLoader v-if="loading.list" colspan="100%" :center="true" />
          <TableRowEmpty v-if="!loading.list && firsttime && this.list.length === 0" colspan="100%" :center="true" />
          <TableRowSearchForResults v-if="!firsttime && terms.length > 0" colspan="100%" :center="true" />
          <tr v-if="terms.length === 0">
            <td colspan="100%" class="font-italic text-center">Ekkert virkt kennslutímabil og því ekki hægt að breyta viðveru</td>
          </tr>
          <tr v-for="item in getPaginatedList" :key="item.fjarv_forfoll_id">
            <td style="width: 2%"><b-form-checkbox v-if="canEdit" v-model="item.selected"></b-form-checkbox></td>
            <td>{{ item.namsgrein_afanganr }}</td>
            <td>{{ item.hopur }}</td>
            <td>{{ item.dags }}</td>
            <td>{{ item.timi_fra }}</td>
            <td>{{ item.timi_til }}</td>
            <td>{{ item.kodi_fjarvist }}</td>
            <td>{{ item.heiti_fjarvist }}</td>
            <td>{{ item.kodi_forfoll }}</td>
            <td>{{ item.heiti_forfoll }}</td>
            <td>{{ item.athugasemd }}</td>
          </tr>
        </tbody>
      </table>
      <Pagination
        :currentPage="currentPage"
        :totalRows="list.length"
        :perPage="perPage"
        v-if="list.length > 20"
        @change-page="changePage"
        @change-limit="changeLimit"
      ></Pagination>
      <attendance-mass-edit-slidebar
        v-if="showSlidebar"
        :studentId="studentId"
        :dateFrom="search.dateFrom"
        :dateTo="search.dateTo"
        :items="selectedAttendances"
        @closeSlidebar="closeSlidebar()"
        @closeAndReload="closeAndReload"
      ></attendance-mass-edit-slidebar>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import terms from '@/api/terms';
import types from '@/api/types';
import students from '@/api/students';
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';
import Pagination from '@/components/common/Pagination.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';
import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import TableRowSearchForResults from '@/components/common/TableRowSearchForResults.vue';

import AttendanceMassEditSlidebar from '@/components/students/attendance/AttendanceMassEditSlidebar.vue';

export default {
  name: 'change-student-attendance',
  props: ['studentId', 'type'],
  components: {
    Datepicker,
    Pagination,
    LinkOrder,
    TableRowLoader,
    TableRowEmpty,
    TableRowSearchForResults,
    AttendanceMassEditSlidebar,
  },
  computed: {
    getList() {
      return this.list;
    },
    getPaginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.list.slice(start, end);
    },
    selectedAttendances() {
      return this.list.filter(x => x.selected);
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('nemendahald_nemendur');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
  },
  data() {
    return {
      lang: is,
      perPage: 20,
      currentPage: 1,
      firsttime: false,
      colors: '',
      selectAll: false,
      showSlidebar: false,
      terms: [],
      absenceCodes: [],
      attendanceCodes: [],
      modules: [],
      list: [],
      loading: {
        terms: '',
        absenceCodes: false,
        attendanceCodes: false,
        modules: false,
        list: false,
      },
      search: {
        termId: '',
        dateFrom: '',
        dateTo: '',
        moduleId: '',
        comment: '',
      },
    };
  },
  methods: {
    async loadTerms() {
      try {
        this.loading.terms = true;
        const params = {
          only_active: 1,
          hide_past: 1,
          asc_order: 1,
          studentHasTerm: this.studentId,
        };
        const response = await terms.getTerms(params);
        if (response.data.items.length > 0) {
          this.terms = response.data.items.map(x => ({
            ...x,
            label: `${x.onn_eink} ${x.undirsk_audk} - ${x.onn_heiti}`,
          }));
        }
        this.search.termId = this.terms ? this.terms[0] : '';
        this.loadModules(this.search.termId); // áfangar
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.terms = false;
      }
    },
    async loadAbsence() {
      try {
        this.loading.absenceCodes = true;
        const response = await types.getAbsenceList({ active: 1 });
        this.absenceCodes = response.data.items.map(x => ({
          ...x,
          label: `${x.kodi} - ${x.heiti}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.absenceCodes = false;
      }
    },
    async loadAttendance() {
      try {
        this.loading.attendanceCodes = true;
        const response = await types.getAttendanceList({ active: 1 });
        this.attendanceCodes = response.data.items.map(x => ({
          ...x,
          label: `${x.kodi} - ${x.heiti}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.attendanceCodes = false;
      }
    },
    async loadModules(term) {
      try {
        let params;
        if (term) {
          params = {
            onn_id: term.onn_id,
          };
        }
        const response = await students.getStudentModuleList(this.studentId, params);
        this.modules = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadList() {
      try {
        this.loading.list = true;
        this.firsttime = true;
        this.selectAll = false;
        const data = {
          termId: this.search.termId.onn_id,
          moduleId: this.search.moduleId ? this.search.moduleId.afangi_id : '',
          dateFrom: this.search.dateFrom ? moment(this.search.dateFrom).format('DD.MM.YYYY') : '',
          dateTo: this.search.dateTo ? moment(this.search.dateTo).format('DD.MM.YYYY') : '',
          attendanceId: this.search.attendanceCodeId ? this.search.attendanceCodeId.teg_fjarvista : '',
          absenceId: this.search.absenceCodeId ? this.search.absenceCodeId.teg_forfalla : '',
          comment: this.search.comment,
        };
        const response = await students.getStudentsAttendanceList(this.studentId, data);
        this.list = response.data.items.map(x => ({
          ...x,
          selected: false,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.list = false;
      }
    },
    updateAll() {
      this.list.forEach(i => {
        this.$set(i, 'selected', this.selectAll);
      });
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    openMassEditSlidebar() {
      this.showSlidebar = true;
    },
    closeSlidebar() {
      this.showSlidebar = false;
      this.loadList();
    },
    closeAndReload() {
      this.closeSlidebar();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadTerms();
    this.loadAbsence(); // forföll
    this.loadAttendance(); // viðvera
    this.search.dateFrom = moment().toDate();
    this.search.dateTo = moment().toDate();
  },
};
</script>
<style lang="scss"></style>
