<template>
  <div class="pl-2 pr-2">
    <table class="table no-border less-padding">
      <tr class="study-record-term-table-background">
        <th style="font-size: 17px" :title="item.onn_heiti" v-b-tooltip>{{ item.onn_eink }}</th>
      </tr>
      <tr style="font-size: 12px">
        <td>
          {{ item.summa_val.length > 0 ? item.summa_val[0].fj_tima : ''
          }}<span v-if="item.summa_varaval.length > 0 && item.summa_varaval[0].fj_tima">+{{ item.summa_varaval[0].fj_tima }}</span> tímar
        </td>
      </tr>
      <tr style="font-size: 12px" v-if="totalUnits > 0">
        <td>
          <span>{{ totalUnits }} ein.</span>
          <span v-for="(s, idx) in item.summa_stada.filter(x => x.fj_ein > 0)" :key="s.stada_afanga" :class="getStatusColor(s.kodi)">
            {{ s.fj_ein }}{{ s.kodi }}<span v-if="idx !== item.summa_stada.length - 1">,</span>
          </span>
        </td>
      </tr>
      <tr style="font-size: 12px" v-if="totalUnitsSteps > 0">
        <td>
          <span>{{ totalUnitsSteps }} ein. á þr.</span>
          <span v-for="(s, idx) in item.summa_stada.filter(x => x.fj_ein_trep > 0)" :key="s.stada_afanga" :class="getStatusColor(s.kodi)">
            {{ s.fj_ein_trep }}{{ s.kodi }}<span v-if="idx !== item.summa_stada.length - 1">,</span>
          </span>
        </td>
      </tr>
      <tr>
        <td class="p-1"></td>
      </tr>
      <tr
        v-for="(afangi, idx) in item.afangar"
        :key="afangi.annir_afangar_id"
        :class="{ 'study-record-term-table-background': idx % 2 === 0 }"
      >
        <td :class="getStatusColor(afangi.stada_kodi, afangi.val_teg)">
          <span v-b-popover.hover.html="getPopover(afangi)" :title="afangi.afangi_audkenni">
            {{ afangi.afangi_audkenni }} {{ afangi.stada_kodi }}
            {{ afangi.lokaeinkunn !== '?' ? afangi.lokaeinkunn : '' }}
            <span
              style="position: relative; top: -0.5em; font-size: 80%; padding-right: 4px"
              v-if="afangi.stada_afanga === 1 || afangi.stada_afanga === 40 || afangi.stada_afanga === 41"
              >{{ afangi.val_rod }}</span
            >
          </span>
          <i
            class="fa fa-comment pr-1"
            style="font-size: 12px"
            v-if="afangi.aths && !afangi.aths_skrad_af"
            v-b-popover.hover.top="`${afangi.aths}`"
            :title="'Athugasemd'"
          ></i>
          <i
            class="fa fa-comment pr-1"
            style="font-size: 12px"
            v-if="afangi.aths && afangi.aths_skrad_af"
            v-b-popover.hover.top="`${afangi.aths} \n \n Skráð af ${afangi.aths_skrad_af}`"
            :title="'Athugasemd'"
          ></i>
          <b-link
            v-if="canReadModules"
            :class="getStatusColor(afangi.stada_kodi, afangi.val_teg)"
            :to="{ name: 'Module', params: { id: afangi.afangi_id } }"
            target="_blank"
            v-b-tooltip
            title="Skoða áfanga"
          >
            <i class="fa fa-external-link"></i>
          </b-link>
          <i @click="registerCourseSlidebar(afangi)" class="fa fa-pencil float-right cursor-pointer" v-b-tooltip title="Breyta"></i>
        </td>
      </tr>
    </table>
    <register-course-slidebar
      v-if="showRegisterCourseSlidebar"
      :studentId="studentId"
      :studentActive="studentActive"
      :termModuleId="selectedItem && selectedItem.annir_afangar_id"
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
    />
  </div>
</template>

<script>
import { sortMixin } from '@/utils';
import { mapGetters } from 'vuex';
import RegisterCourseSlidebar from '@/components/students/studyRecords/terms/RegisterCourseSlidebar.vue';

export default {
  name: 'student-study-record-term',
  props: ['item', 'studentId', 'studentActive'],
  mixins: [sortMixin],
  components: {
    RegisterCourseSlidebar,
  },
  computed: {
    totalUnits() {
      return this.item.summa_stada.map(x => x.fj_ein).reduce((a, b) => a + b, 0);
    },
    totalUnitsSteps() {
      return this.item.summa_stada.map(x => x.fj_ein_trep).reduce((a, b) => a + b, 0);
    },
    canReadModules() {
      return this.loggedInUserHasReadPermission('namskra_afangar');
    },
    ...mapGetters(['loggedInUserHasReadPermission']),
  },
  data() {
    return {
      selectedItem: '',
      showRegisterCourseSlidebar: false,
    };
  },
  methods: {
    getStatusColor(status, mainOrBackup) {
      switch (status) {
        case 'M': // Metið
          return 'studentStudyRecordMetid';
        case 'ME': // Metið án ein.
          return 'studentStudyRecordMetid';
        case 'L' || 'LE': // Lokið
          return 'studentStudyRecordLokid';
        case 'F' || 'MF' || 'FÖ': // FAll
          return 'studentStudyRecordFall';
        case 'Ó': // Ólokið
          return 'studentStudyRecordOlokid';
        case 'Á': // Áætlun
          if (mainOrBackup === 1) {
            // Aðalval

            return 'studentStudyRecordAetlunAdal';
          }
          if (mainOrBackup === 2) {
            // Varaval

            return 'studentStudyRecordAetlunVara';
          }
          return 'studentStudyRecordAetlun'; // Ekki skilgreint
        case 'Ú':
          return 'studentStudyRecordUrsogn'; // Úrsögn
        default:
          return '';
      }
    },
    // getStatusLabel(status) {
    //   if (status !== 'L' && status !== 'F' && status !== 'Ó') {
    //     return `(${status})`;
    //   }
    //   return '';
    // },
    getPopover(item) {
      let popover = `<b>Áfangi: </b> ${item.afangi_audkenni}</br>`;
      popover += `<b>Nafn:</b> ${item.afangi_heiti}</br>`;
      if (item.stada) {
        popover += `<b>Staða:</b> ${item.stada}</br>`;
      }
      if (item.lokaeinkunn && item.lokaeinkunn !== '?') {
        popover += `<b>Einkunn:</b> ${item.lokaeinkunn}</br>`;
      }
      if (item.einingar) {
        popover += `<b>Einingar:</b> ${item.einingar}</br>`;
      }
      if (item.einingar) {
        popover += `<b>Einingar:</b> ${item.einingar}</br>`;
      }
      if (item.stada_kodi === 'Á' || item.stada_kodi === 'V') {
        popover += `<b>Röð vals:</b> ${item.val_rod}</br>`;
      }
      if (item.val_teg === 1) {
        popover += `<b>Val:</b> Aðalval</br>`;
      }
      if (item.val_teg === 2) {
        popover += `<b>Val:</b> Varaval</br>`;
      }

      return popover;
    },
    registerCourseSlidebar(item) {
      this.showRegisterCourseSlidebar = true;
      this.selectedItem = item;
    },
    closeSlidebar() {
      this.selectedItem = '';
      this.showRegisterCourseSlidebar = false;
    },
    closeAndReloadSlidebar() {
      this.closeSlidebar();
      this.$emit('refresh');
    },
  },
};
</script>
<style lang="scss">
.study-record-term-table-background {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
