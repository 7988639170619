<template>
  <div>
    <b-row>
      <b-col cols="2" class="pl-0">
        <b-form-group label="Dags.frá" label-for="dateFrom">
          <datepicker
            class="datepicker_100_width"
            id="dateFrom"
            name="dateFrom"
            v-model="date_from"
            :language="lang"
            :monday-first="true"
            format="dd.MM.yyyy"
            ref="dateFromOpenPickr"
            @input="loadList()"
          ></datepicker>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label="Dags.til" label-for="dateTo">
          <datepicker
            class="datepicker_100_width"
            id="dateTo"
            name="dateTo"
            v-model="date_to"
            :language="lang"
            :monday-first="true"
            format="dd.MM.yyyy"
            ref="dateToOpenPickr"
            @input="loadList()"
          ></datepicker>
        </b-form-group>
      </b-col>
      <b-col>
        <div style="position: absolute; bottom: 5px">
          <b-form-checkbox v-model="notReturned" @input="loadList()" class="d-inline-block"></b-form-checkbox>
          Eingöngu óskilaðar pantanir
        </div>
      </b-col>
    </b-row>
    <Loader v-if="loading.list"></Loader>
    <div v-if="hasBeenSearched && list.length === 0">Engar niðurstöður fundust</div>
    <table class="table" v-if="list.length > 0">
      <thead>
        <tr>
          <th style="width: 4%">
            <b-form-checkbox v-model="allSelected" @input="updateAll()" class="d-inline-block"></b-form-checkbox>
            <b-btn
              variant="danger"
              class="btn-xs d-inline-block"
              style="font-size: 13px"
              v-if="selectedItems.length > 0"
              @click="deleteItems()"
              ><i class="fa fa-trash"></i
            ></b-btn>
          </th>
          <th>
            <LinkOrder title="Búnaður" :filteredList="list" :list="list" column="heiti" second_column="lysing" />
          </th>
          <th>
            <LinkOrder title="Lýsing" :filteredList="list" :list="list" column="lysing" second_column="heiti" />
          </th>
          <th>
            <LinkOrder title="Staðsetning" :filteredList="list" :list="list" column="stadsetning" second_column="heiti" />
          </th>
          <th>
            <LinkOrder title="Dags." :filteredList="list" :list="list" column="dags" second_column="heiti" />
          </th>
          <th>
            <LinkOrder title="Tími frá" :filteredList="list" :list="list" column="timi_fra" second_column="heiti" />
          </th>
          <th>
            <LinkOrder title="Timi til" :filteredList="list" :list="list" column="timi_til" second_column="heiti" />
          </th>
          <th>
            <LinkOrder title="Pantað af" :filteredList="list" :list="list" column="nafn" second_column="heiti" />
          </th>
          <th>
            <LinkOrder title="Skýring" :filteredList="list" :list="list" column="skyring" second_column="heiti" />
          </th>
          <th>
            <LinkOrder title="Sótt" :filteredList="list" :list="list" column="sott" second_column="heiti" />
          </th>
          <th>
            <LinkOrder title="Skilað" :filteredList="list" :list="list" column="skilad" second_column="heiti" />
          </th>
          <th>
            <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="cursor-pointer float-right">
              <i class="fa fa-file-excel-o excelText"></i>
            </download-csv>
          </th>
        </tr>
      </thead>
      <tr v-for="item in list" :key="item.bunadur_pantanir_id">
        <td>
          <b-form-checkbox v-if="canEdit || item.is_mine === 1" v-model="item.selected"></b-form-checkbox>
        </td>
        <td>{{ item.heiti }}</td>
        <td>{{ item.lysing }}</td>
        <td>{{ item.stadsetning }}</td>
        <td>{{ item.dags | moment('DD.MM.YYYY') }}</td>
        <td>{{ item.timi_fra }}</td>
        <td>{{ item.timi_til }}</td>
        <td>{{ item.nafn }}</td>
        <td>{{ item.skyring }}</td>
        <td>
          <span v-if="canEdit">
            <b-form-checkbox v-model="item.pickedUp" @input="markPickUp(item)" class="d-inline-block"></b-form-checkbox>
          </span>
          <span v-else>
            <yes-no :item="item.sott"></yes-no>
          </span>
          <i
            v-if="item.sott === 1"
            class="fa fa-info-circle d-inline-block"
            v-b-tooltip
            :title="`${formatDate(item.sott_dags)} - ${item.sott_persona_nafn}`"
          ></i>
        </td>
        <td>
          <span v-if="canEdit">
            <b-form-checkbox v-model="item.returned" @input="markReturned(item)" class="d-inline-block"></b-form-checkbox>
          </span>
          <span v-else>
            <yes-no :item="item.skilad"></yes-no>
          </span>
          <i
            v-if="item.skilad === 1"
            class="fa fa-info-circle d-inline-block"
            v-b-tooltip
            :title="`${formatDate(item.skilad_dags)} - ${item.skilad_persona_nafn}`"
          ></i>
        </td>
        <td>
          <b-btn
            variant="primary"
            class="float-right btn-xs"
            size="sm"
            @click="openSlidebar(item.bunadur_pantanir_id)"
            v-if="canEdit || item.is_mine === 1"
          >
            <i class="fa fa-pencil"></i>
          </b-btn>
        </td>
      </tr>
    </table>

    <Pagination
      :currentPage="currentPage"
      :totalRows="list.length"
      :perPage="perPage"
      v-if="list.length > 20"
      @change-page="changePage"
      @change-limit="changeLimit"
    ></Pagination>
    <equipment-order-slidebar
      @closeSlidebar="closeSlidebar"
      @closeAndReloadSlidebar="closeAndReloadSlidebar"
      :item="slidebarItem"
      v-if="showOrderSlidebar"
      :large="true"
    >
    </equipment-order-slidebar>
  </div>
</template>

<script>
import moment from 'moment';
import requests from '@/api/requests';
import Loader from '@/components/common/Loader.vue';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';
import { mapGetters, mapActions } from 'vuex';
import EquipmentOrderSlidebar from '@/components/requests/equipments/EquipmentOrderSlidebar.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';
import Pagination from '@/components/common/Pagination.vue';
import YesNo from '@/components/common/YesNo.vue';

export default {
  name: 'equipment-order-list',
  props: ['equipment', 'student', 'staff', 'load'],
  components: {
    Loader,
    Datepicker,
    EquipmentOrderSlidebar,
    LinkOrder,
    Pagination,
    YesNo,
  },
  computed: {
    getPaginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.list.slice(start, end);
    },
    selectedItems() {
      return this.list.filter(x => x.selected);
    },
    getExcelData() {
      const newList = [
        [],
        ['Búnaður'],
        [],
        ['Búnaður', 'Lýsing', 'Staðsetning', 'Dags.', 'Tími frá', 'Tími til', 'Pantað af', 'Skýring'],
        ...this.list.map(x => [
          x.heiti,
          x.lysing,
          x.stadsetning,
          moment(x.dags).format('DD.MM.YYYY'),
          x.timi_fra,
          x.timi_til,
          x.nafn,
          x.skyring,
        ]),
      ];
      return newList;
    },
    getExcelFileName() {
      return `bunadur_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    hasBeenSearched() {
      return !this.loading && (this.classroom || this.staff || this.student);
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('beidnir_bunadur');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      perPage: 20,
      currentPage: 1,
      showOrderSlidebar: false,
      slidebarItem: '',
      allSelected: false,
      lang: is,
      list: [],
      loading: false,
      date_from: moment().toDate(),
      date_to: moment().toDate(),
      notReturned: false,
    };
  },
  methods: {
    formatDate(date) {
      return moment(date).format('DD.MM.YYYY HH:mm:ss');
    },
    openSlidebar(id) {
      if (this.canEdit) {
        this.slidebarItem = {
          id,
        };
        this.showOrderSlidebar = true;
      }
    },
    closeSlidebar() {
      this.slidebarItem = '';
      this.showOrderSlidebar = false;
    },
    closeAndReloadSlidebar() {
      this.closeSlidebar();
      this.loadList();
    },
    updateAll() {
      this.list
        .filter(x => this.canEdit || x.is_mine === 1)
        .forEach(item => {
          this.$set(item, 'selected', this.allSelected);
        });
    },
    async loadList() {
      try {
        this.loading = true;
        this.list = [];
        const params = {};
        if (this.equipment) {
          params.equipmentId = this.equipment.bunadur_id;
        }
        if (this.student) {
          params.studentId = this.student.nemandi_id;
        }
        if (this.staff) {
          params.staffId = this.staff.starfsm_id;
        }
        if (this.notReturned) {
          params.notReturned = 1;
        }
        params.date_from = moment(this.date_from).format('DD.MM.YYYY');
        params.date_to = moment(this.date_to).format('DD.MM.YYYY');
        const response = await requests.getEquipmentRequestsList(params);
        this.list = response.data.items.map(x => ({ ...x, pickedUp: x.sott === 1, returned: x.skilad === 1 }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async markPickUp(item) {
      try {
        await requests.markEquipment(item.bunadur_pantanir_id, { sott: item.pickedUp ? 1 : 0 });
        const response = await requests.getEquipmentRequestsList({ id: item.bunadur_pantanir_id });
        if (response.data.items.length === 1) {
          const changedValue = response.data.items[0];
          this.$set(item, 'sott', changedValue.sott);
          this.$set(item, 'sott_dags', changedValue.sott_dags);
          this.$set(item, 'sott_persona_nafn', changedValue.sott_persona_nafn);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async markReturned(item) {
      try {
        await requests.markEquipment(item.bunadur_pantanir_id, { skilad: item.returned ? 1 : 0 });
        const response = await requests.getEquipmentRequestsList({ id: item.bunadur_pantanir_id });
        if (response.data.items.length === 1) {
          const changedValue = response.data.items[0];
          this.$set(item, 'skilad', changedValue.skilad);
          this.$set(item, 'skilad_dags', changedValue.skilad_dags);
          this.$set(item, 'skilad_persona_nafn', changedValue.skilad_persona_nafn);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async deleteItems() {
      /* eslint-disable */
      const conf = confirm(
        `Ertu viss um að þú viljir eyða ${this.selectedItems.length} pöntunum ? Ath. sá sem bókaði búnaðinn mun vera látinn vita ef pöntun er ekki liðin.`,
      );
      /* eslint-enable */
      if (conf) {
        try {
          const promises = [];
          this.selectedItems.forEach(item => {
            promises.push(requests.createEquipmentRequests({ orderId: item.bunadur_pantanir_id, deleted: 1 }));
          });

          Promise.all(promises).then(() => {
            this.allSelected = false;
            this.displaySuccess('Pöntunum eytt');
            this.loadList();
          });
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    load() {
      if (this.load) {
        this.loadList();
      }
    },
  },
  created() {
    if (this.load) {
      this.loadList();
    }
  },
};
</script>

<style lang="scss"></style>
