<template>
  <page>
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <b-row>
      <b-col>
        <PageTitle title="Aðalskóli" />
      </b-col>
      <b-col>
        <i class="fa fa-info-circle float-right cursor-pointer" style="font-size: 30px" v-b-popover.hover.left="info" />
      </b-col>
    </b-row>
    <b-card class="mb-3">
      <h5 class="mb-3">Tölfræði</h5>
      <table class="table no-border">
        <tbody>
          <tr>
            <th>Heildarfjöldi nemenda</th>
            <td>{{ summary.fjoldi_samtals }}</td>
            <th>Innan líkans</th>
            <td>{{ summary.greitt }}</td>
          </tr>
          <tr>
            <th>Fjöldi skráðir með aðalskóla</th>
            <td>{{ summary.fjoldi }}</td>
            <th>Utan líkans</th>
            <td>{{ summary.ekki_greitt }}</td>
          </tr>
          <tr>
            <th>Mismunur</th>
            <td>{{ summary.mismunur }}</td>
            <th>Óflokkað</th>
            <td>{{ summary.oflokkad }}</td>
          </tr>
          <tr>
            <th>Viðmiðunardagsetning</th>
            <td>
              <datepicker
                id="compare_date"
                name="compare_date"
                v-model="compareDate"
                :language="lang"
                :monday-first="true"
                format="dd.MM.yyyy"
                :typeable="false"
                :disabled-dates="disabledDates"
                @input="changeDate"
                ref="compareDateOpenPickr"
                v-validate="'required'"
              ></datepicker>
            </td>
            <th>Þar af yfir 25</th>
            <td>{{ summary.yfir_25 }}</td>
          </tr>
        </tbody>
      </table>
      <div class="pl-1" v-if="olderData">
        <i class="fa fa-info-circle" /> Ath. það er eingöngu hægt að sjá fjöldatölur fyrir eldri dagsetningar.
      </div>
    </b-card>
    <PageMenu route="MainSchool" v-if="!olderData" :selectedPage="page" :items="menu" />
    <Difference v-if="page === 'difference' && !olderData" />
    <Paid v-if="page === 'paid' && !olderData" />
    <NotPaid v-if="page === 'unpaid' && !olderData" />
    <UnknownStudents v-if="page === 'unknown' && !olderData" />
    <Courses v-if="page === 'courses' && !olderData" />
  </page>
</template>

<script>
import reports from '@/api/reports';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';

import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import PageTitle from '@/components/common/PageTitle.vue';
import PageMenu from '@/components/common/PageMenu.vue';

import Difference from '@/components/reports/mainschool/Difference.vue';
import Paid from '@/components/reports/mainschool/Paid.vue';
import NotPaid from '@/components/reports/mainschool/NotPaid.vue';
import UnknownStudents from '@/components/reports/mainschool/UnknownStudents.vue';
import Courses from '@/components/reports/mainschool/Courses.vue';

export default {
  name: 'mainschool',
  components: {
    Breadcrumbs,
    PageTitle,
    PageMenu,
    Difference,
    Paid,
    NotPaid,
    UnknownStudents,
    Courses,
    Datepicker,
  },
  computed: {
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      lang: is,
      item: '',
      loading: false,
      page: 'difference',
      info: 'Aðalskólavinnslan er næturvinnsla. Tengingar á brautum mun því ekki breyta niðurstöðum fyrr en daginn eftir.',
      breadcrumbs: [
        { name: 'Forsíða', route: 'Dashboard' },
        { name: 'Námskrá', route: '' },
        { name: 'Aðalskóli', route: 'MainSchool' },
      ],
      menu: [
        {
          name: 'Mismunur',
          page: 'difference',
        },
        {
          name: 'Innan líkans',
          page: 'paid',
        },
        {
          name: 'Utan líkans',
          page: 'unpaid',
        },
        {
          name: 'Óflokkað',
          page: 'unknown',
        },
        {
          name: 'Tengja brautir',
          page: 'courses',
        },
      ],
      compareDate: moment().toDate(),
      disabledDates: {
        from: moment().toDate(),
      },
      summary: {},
      summaryCount: 0,
      olderData: false,
      newestDate: moment(),
    };
  },
  methods: {
    changeDate() {
      this.olderData = true;
      if (moment(this.compareDate).format('YYYYMMDD') === moment(this.newestDate).format('YYYYMMDD')) {
        this.olderData = false;
      }
      this.loadSummary();
    },
    async loadSummary() {
      try {
        this.loading = true;
        this.summary = {};
        this.summaryCount = 0;
        const response = await reports.getHomeschoolSummary(moment(this.compareDate).format('DD.MM.YYYY'));
        if (response.data.items.length > 0) {
          this.summary = response.data.items[0];
          this.menu[0].name = `Mismunur (${this.summary.mismunur || 0})`;
          this.menu[1].name = `Innan líkans (${this.summary.greitt || 0})`;
          this.menu[2].name = `Utan líkans (${this.summary.ekki_greitt || 0})`;
          this.menu[3].name = `Óflokkað (${this.summary.oflokkad || 0})`;
          if (!this.olderData) {
            this.newestDate = moment(this.compareDate);
          }
        }
        this.summaryCount += 1;

        if (!this.summary.fjoldi_samtals && this.summaryCount < 2 && !this.olderData) {
          this.compareDate = moment(this.compareDate).subtract(1, 'days');
          this.loadSummary();
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  beforeRouteUpdate(to, from, next) {
    if (to && to.query && to.query.page) {
      this.page = to.query.page;
    }
    next();
  },
  created() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
    this.loadSummary();
  },
};
</script>
