<template>
  <div>
    <b-row>
      <b-col class="pl-0">
        <CourseSettings v-if="tabIndex === 'settings'" :item="item" @refresh="refreshCourse()" />
        <CourseHistory v-if="tabIndex === 'history' && item" :id="id" :item="item"></CourseHistory>
      </b-col>
      <b-col cols="2">
        <div class="right-side-nav noprint">
          <b-nav vertical>
            <b-nav-item v-if="canEdit" :to="{ name: 'CreateCourse', query: { copy: id } }" style="color: inherit"> Afrita </b-nav-item>
            <b-nav-item @click.prevent="changeTab('history')" :active="tabIndex === 'history'">Saga</b-nav-item>
            <b-nav-item
              @click.prevent="
                changeTab('settings');
                isApplication = false;
              "
              :active="tabIndex === 'settings'"
              >Stýringar</b-nav-item
            >
          </b-nav>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import CourseSettings from '@/components/curriculum/courses/CourseSettings.vue';
import CourseHistory from '@/components/curriculum/courses/CourseHistory.vue';

export default {
  name: 'course-details',
  props: ['id', 'item'],
  components: {
    CourseSettings,
    CourseHistory,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_brautir');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('namskra_brautir');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings', 'schoolHasSettings']),
  },
  data() {
    return {
      tabIndex: 'settings',
      page: 'details',
    };
  },
  methods: {
    refreshCourse() {
      this.$emit('refresh');
    },
    changeTab(tab) {
      this.tabIndex = tab;
      this.$router.push({ name: 'Course', query: { page: this.page, tab: this.tabIndex } });
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  beforeRouteUpdate(to, from, next) {
    if (to && to.query && to.query.page) {
      this.page = to.query.page;
    }
    if (to && to.query && to.query.tab) {
      this.tabIndex = to.query.tab;
    }
    next();
  },
  created() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
    if (this.$route.query.tab) {
      this.tabIndex = this.$route.query.tab;
    }
  },
};
</script>
<style lang="scss"></style>
