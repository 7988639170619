<template>
  <Slidebar @closeMe="closeSlidebar" :large="true">
    <h4>Stofna áætlaða hópa</h4>
    <div v-if="!loading">
      <div>Stofna áætlaða hópa fyrir eftirfarandi önn:</div>
      <div class="mb-3">
        <strong>{{ term.undirsk_heiti }} - {{ term.onn_heiti }} - {{ term.onn_eink }}</strong>
      </div>
    </div>

    <div v-if="modules.length === 0" class="text-danger">
      Ath. ALLIR áætlaðir hópar í ÖLLUM ÁFÖNGUM í boði á kennslutímabilinu verða stofnaðir en ekki bara þeir sem komu fram í leit.
    </div>
    <div v-if="modules.length > 0">
      <div>Stofna áætlaða hópa fyrir eftirfarandi áfanga:</div>
      <div v-for="m in modules" :key="m.annir_afangar_id">
        {{ m.namsgrein_afanganr }}
      </div>
    </div>

    <b-btn variant="primary" class="mt-3" @click="createPlannedGroups()"> Stofna </b-btn>
  </Slidebar>
</template>

<script>
import terms from '@/api/terms';

import { mapGetters, mapActions } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';

export default {
  name: 'create-groups-planned-slidebar',
  props: ['termId', 'modules'],
  components: {
    Slidebar,
  },
  computed: {
    canRead() {
      return this.loggedInUserHasReadPermission('nemendahald_hopar');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      loading: false,
      term: '',
    };
  },
  methods: {
    async loadTerm() {
      try {
        this.loading = true;
        const response = await terms.getTerm(this.termId);
        this.term = response.data.items[0];
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async createPlannedGroups() {
      try {
        let modules = null;
        if (this.modules.length > 0) {
          modules = { modules: this.modules.map(x => x.annir_afangar_id) };
          modules = JSON.stringify(modules);
        }
        await this.$groupsApi.createPlannedGroups({
          termId: this.termId,
          createPlannedGroupsInputItem: {
            modules,
          },
        });

        this.$emit('closeAndReloadSlidebar');
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.saving = false;
      }
    },
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadTerm();
  },
};
</script>

<style lang="scss"></style>
