<template>
  <b-form-group :label="label" class="mb-0">
    <p
      class="form-control-plaintext form-editable"
      :class="{ 'form-editable-label': !!label, 'form-can-edit': canEdit, 'inherit-background': inheritBackground }"
      v-if="!doEdit || !canEdit"
      @click="editField(true)"
    >
      <slot name="content"></slot> <i class="fa fa-fw fa-pencil form-editable-hover text-muted" v-if="canEdit && showPencil"></i>
    </p>
    <span v-if="doEdit && canEdit">
      <slot name="input" @submitOnEnter="saveField"></slot>
      <div class="form-editable-buttons pull-right" :class="{ 'form-editable-buttons-label': !!label }">
        <button type="button" class="btn btn-sm btn-success" @click="saveField" :disabled="!canSave">
          <i style="color: inherit" class="fa fa-check"></i>
        </button>
        <button type="button" class="btn btn-sm btn-dark" @click="editField(false)">
          <i style="color: inherit" class="fa fa-remove"></i>
        </button>
      </div>
    </span>
  </b-form-group>
</template>

<script>
export default {
  name: 'InlineEdit',
  props: {
    canEdit: {
      default: true,
    },
    canSave: {
      default: true,
    },
    inheritBackground: {
      default: false,
    },
    showPencil: {
      default: false,
    },
    label: {},
    doEdit: {},
    field: {},
  },
  methods: {
    editField(doEdit) {
      if (this.canEdit) {
        this.$emit('editField', { field: this.field, doEdit });
      }
    },
    saveField() {
      if (this.canEdit) {
        this.$emit('saveField');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control-plaintext {
  padding-left: 8px;
}

.form-editable {
  &.form-can-edit {
    cursor: pointer;
  }
  display: block;
  width: 100%;

  .form-editable-hover {
    display: none;
    position: absolute;
    right: -15px;
    top: 10px;
  }

  &.form-editable-label {
    .form-editable-hover {
      top: 42px;
    }
  }

  &:hover {
    background-color: #f5f5f5;
    &.inherit-background {
      background-color: inherit;
    }

    .form-editable-hover {
      display: block;
    }
  }
}

.form-editable-buttons {
  // position: absolute;
  z-index: 3;
  right: 4px;
  bottom: -30px;

  &.form-editable-buttons-label {
    right: 15px;
    bottom: -10px;
  }
}
</style>
