<template>
  <div>
    <Timetable :id="id" type="student" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import Timetable from '@/components/common/Timetable.vue';

export default {
  name: 'student-timetable',
  props: ['id'],
  components: {
    Timetable,
  },
  methods: {
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>

<style lang="scss"></style>
