<template>
  <div>
    <b-modal
      id="staffWorklogPdfModal"
      title="Vinnuskýrslur starfsmanns"
      size="xl"
      lazy
      :no-close-on-backdrop="true"
      ok-title="Loka"
      ok-only
      ref="createModal"
    >
      <div>
        <h5>Grunnupplýsingar</h5>
        <table class="table">
          <tr>
            <th>Nafn</th>
            <td>{{ staff.nafn }}</td>
          </tr>
          <tr>
            <th>Kennitala</th>
            <td>{{ staff.kennitala }}</td>
          </tr>
          <tr>
            <th>Heimilisfang</th>
            <td>{{ staff.heimilisfang }} {{ staff.postnumer }} {{ staff.heiti_poststodvar }}</td>
          </tr>
          <tr>
            <th>Farsími</th>
            <td>{{ staff.farsimi }}</td>
          </tr>
          <tr>
            <th>Netfang</th>
            <td>{{ staff.netfang }}</td>
          </tr>
        </table>
        <br /><br />
        <h5>Vinnuskýrslur</h5>
        <table class="table">
          <tr>
            <th>Heiti</th>
            <th>Í gildi</th>
            <th>Staðfest</th>
            <th>Staðfest af</th>
            <th>Staðfest af starfsmanni</th>
          </tr>
          <tr v-for="skjal in staff.skjol" :key="skjal.vinnuskyrslur_skjol_id">
            <td>
              <a class="cursor-pointer" @click="openUrl(skjal)">{{ skjal.vinnuskyrsla_heiti }}</a>
            </td>
            <td><i class="fa" :class="{ 'fa-check text-success': skjal.gildi === 1, 'fa-remove text-danger': skjal.gildi === 0 }" /></td>
            <td>{{ skjal.stadfest_yfirmadur_dags | moment('DD.MM.YYYY') }}</td>
            <td>{{ skjal.yfirmadur }}</td>
            <td>{{ skjal.stadfest_starfsm_dags | moment('DD.MM.YYYY') }}</td>
          </tr>
        </table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import auth from '@/api/auth';
import staff from '@/api/staff';

import { mapActions } from 'vuex';

export default {
  name: 'term-create-modal',
  data() {
    return {
      termId: '',
      staffId: '',
      staff: {},
      loading: {
        info: false,
      },
    };
  },
  methods: {
    async load() {
      this.loading.info = true;
      try {
        const response = await staff.getWorklogTermStaffDetail(this.termId, this.staffId);
        if (response.data.items.length > 0) {
          this.staff = response.data.items[0];
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.info = false;
      }
    },
    async openUrl(item) {
      const url = `/api/staff/worklog-term/${this.termId}/staff/${this.staffId}/pdf/${
        item.vinnuskyrslur_skjol_id
      }?token=${auth.getToken()}`;
      window.open(url, '_blank');
    },
    show(staffId, termId) {
      this.staffId = staffId;
      this.termId = termId;
      this.staff = '';
      this.load();
      this.$refs.createModal.show();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
<style lang="scss"></style>
