<template>
  <a
    class="sort-link"
    @click="
      notifySort();
      sortList(filteredList, list, column, secondColumn);
    "
  >
    {{ title }}
  </a>
</template>

<script>
import { sortMixin } from '@/utils';

export default {
  name: 'link-order',
  props: ['title', 'filteredList', 'list', 'column', 'secondColumn'],
  mixins: [sortMixin],
  methods: {
    notifySort() {
      this.$emit('notify', { column: this.column, secondColumn: this.secondColumn });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '../../style/variables';

.sort-link {
  cursor: pointer;
  color: $body-color;
}
</style>
