<template>
  <page>
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <PageTitle title="Vinnuskýrslur" />
    <b-row class="breadcumb-location">
      <b-col>
        <CustomButton type="create" title="Stofna kennslutímabil" @click.native="termCreateModal()" v-if="canEdit" />
      </b-col>
    </b-row>
    <b-card>
      <b-row>
        <b-col>
          <strong>Kennslutímabil</strong>
          <v-select v-model="selectedTerm" :clearable="false" :options="getTerms" label="label" @input="loadStaff()"> </v-select>
        </b-col>
        <b-col>
          <table v-if="selectedTerm">
            <tr>
              <td>Heiti</td>
              <td>
                {{ selectedTerm.label }}
                <i class="fa fa-pencil cursor-pointer" @click="termEditModal(selectedTerm.onn_id)" v-if="canEdit" />
              </td>
            </tr>
            <tr>
              <td>Gildir frá</td>
              <td>{{ selectedTerm.vinnuskyrsla_dags_fra | moment('DD.MM.YYYY') }}</td>
            </tr>
            <tr>
              <td>Gildir til</td>
              <td>{{ selectedTerm.vinnuskyrsla_dags_til | moment('DD.MM.YYYY') }}</td>
            </tr>
            <tr>
              <td class="pr-4">Upphafsmánuður launa</td>
              <td>{{ getMonth }}</td>
            </tr>
            <tr>
              <td>Opið starfsmönnum</td>
              <td><yes-no :item="selectedTerm.starfsmenn_opid" /></td>
            </tr>
            <tr>
              <td colspan="2">
                <b-btn variant="primary" @click="createPdfAll" class="w-100 mt-3" v-if="canEdit"> Stofna vinnuskýrslur fyrir alla </b-btn>
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-card>
    <br />
    <b-card v-if="selectedTerm">
      <h5>Starfsmenn</h5>
      <table class="table less-padding sticky-top">
        <thead style="position: sticky; top: 0; z-index: 2">
          <tr style="font-size: 12px">
            <th>Nafn</th>
            <th>Kennitala</th>
            <th class="text-center" title="Ráðningarhlutfall" v-b-tooltip>Ráðning</th>
            <th title="Starfsheiti" v-b-tooltip>Starf</th>
            <th class="text-center" title="Launatafla" v-b-tooltip>Tafla</th>
            <th class="text-center" title="Kennsluferill" v-b-tooltip>Ferill</th>
            <th class="text-center" title="Kennslustundir viku" v-b-tooltip>Stundir viku</th>
            <th class="text-center" title="Launaflokkur" v-b-tooltip>Launafl.</th>
            <th class="text-center" title="Orlofsprósenta" v-b-tooltip>Orlof</th>
            <th class="text-center" title="Afsal kennsluafsláttar" v-b-tooltip>Afsal</th>
            <th class="text-center" title="Kennsluafslátur" v-b-tooltip>Afsl.</th>
            <th class="text-center" title="Launahlutfall" v-b-tooltip>Hlutf.</th>
            <th class="text-center" title="Yfirvinna" v-b-tooltip>Yfirvinna</th>
            <th title="Athugasemd" v-b-tooltip>Aths.</th>
            <th title="Staðfest" v-b-tooltip>Staðf.</th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tr v-for="item in staffList" :key="item.starfsm_id" style="font-size: 14px">
          <td>{{ item.nafn }}</td>
          <td>{{ item.kennitala }}</td>
          <td class="text-center">
            <span v-if="item.vinnuskyrslur_starfsmenn_id">{{ default0(item.radningarhlutfall) }}%</span>
          </td>
          <td>
            <span v-if="item.vinnuskyrslur_starfsmenn_id">{{ item.tegund_starfsmanns }}</span>
          </td>
          <td class="text-center">
            <span v-if="item.vinnuskyrslur_starfsmenn_id">{{ item.launatafla }}</span>
          </td>
          <td class="text-center">
            <span v-if="item.vinnuskyrslur_starfsmenn_id">{{ default0(item.kennsluferill) }}</span>
          </td>
          <td class="text-center">
            <span v-if="item.vinnuskyrslur_starfsmenn_id">
              <div class="cursor-pointer" :id="`kennslustundir-${item.starfsm_id}`">
                {{ default0(item.kennslustundir_viku) }}
                <i class="fa fa-fw fa-search"></i>
              </div>
              <b-popover :target="`kennslustundir-${item.starfsm_id}`" triggers="hover focus">
                <template v-slot:title> {{ item.nafn }}</template>
                <table class="table-no-border">
                  <tr>
                    <th>Kennslustundir á viku: &nbsp;&nbsp;</th>
                    <td class="text-right">{{ default0(item.kennslustundir_viku) }}</td>
                  </tr>
                  <tr>
                    <th>Kennsluskylda:</th>
                    <td class="text-right">{{ default0(item.kennsluskylda) }}</td>
                  </tr>
                  <tr>
                    <th>Almenn kennsla:</th>
                    <td class="text-right">{{ default0(item.almenn_kennsla) }}</td>
                  </tr>
                  <tr>
                    <th>Nýbúakennsla:</th>
                    <td class="text-right">{{ default0(item.nybuakennsla) }}</td>
                  </tr>
                  <tr>
                    <th>Sérkennsla:</th>
                    <td class="text-right">{{ default0(item.serkennsla) }}</td>
                  </tr>
                </table>
              </b-popover>
            </span>
          </td>
          <td class="text-center">
            <span v-if="item.vinnuskyrslur_starfsmenn_id">
              <div class="cursor-pointer" :id="`launaflokkur-${item.starfsm_id}`">
                {{ default0(item.launaflokkur) }}
                <i class="fa fa-fw fa-search"></i>
              </div>
              <b-popover :target="`launaflokkur-${item.starfsm_id}`" triggers="hover focus">
                <template v-slot:title> {{ item.nafn }}</template>
                <table class="table-no-border">
                  <tr>
                    <th>Launaflokkur:</th>
                    <td class="text-right">{{ default0(item.launaflokkur) }}</td>
                  </tr>
                  <tr>
                    <th>Grunnröðun:</th>
                    <td class="text-right">{{ default0(item.grunnrodun) }}</td>
                  </tr>
                  <tr>
                    <td><i>Viðbótarlaunaflokkar</i></td>
                    <td></td>
                  </tr>
                  <tr v-if="item.launatafla === 1">
                    <th>Persónuálag v. viðbótarmenntunar:</th>
                    <td class="text-right">{{ default0(item.personualag) }}%</td>
                  </tr>
                  <tr v-else>
                    <th>Próf/Leyfisbréf:</th>
                    <td class="text-right">{{ default0(item.prof) }}</td>
                  </tr>
                  <tr>
                    <th>Staðgengilslaun:</th>
                    <td class="text-right">{{ default0(item.stadgengilslaun) }}</td>
                  </tr>
                  <tr>
                    <th>Kennsluferill/lífaldur/starfsþróun:</th>
                    <td class="text-right">{{ default0(item.ferill_aldur_throun) }}</td>
                  </tr>
                  <tr>
                    <th>Laun annað:</th>
                    <td class="text-right">{{ default0(item.laun_annad) }}</td>
                  </tr>
                </table>
              </b-popover>
            </span>
          </td>
          <td class="text-center">
            <span v-if="item.vinnuskyrslur_starfsmenn_id">{{ default0(item.orlofsprosenta) }}%</span>
          </td>
          <td class="text-center">
            <span v-if="item.vinnuskyrslur_starfsmenn_id"><yes-no :item="item.kennsluafslattur_afsal" /></span>
          </td>
          <td class="text-center">
            <span v-if="item.vinnuskyrslur_starfsmenn_id">{{ default0(item.kennsluafslattur) }}</span>
          </td>
          <td class="text-center">
            <span v-if="item.vinnuskyrslur_starfsmenn_id">{{ default0(item.launahlutfall) }}%</span>
          </td>
          <td class="text-center">
            <span v-if="item.vinnuskyrslur_starfsmenn_id">
              <div class="cursor-pointer" :id="`yfirvinna-${item.starfsm_id}`">
                {{ default0(item.yfirvinna) }}
                <i class="fa fa-fw fa-search"></i>
              </div>
              <b-popover :target="`yfirvinna-${item.starfsm_id}`" triggers="hover focus">
                <template v-slot:title> {{ item.nafn }}</template>
                <table class="table-no-border">
                  <tr>
                    <th>Yfirvinna:</th>
                    <td class="text-right">{{ default0(item.yfirvinna) }}</td>
                  </tr>
                  <tr>
                    <th>Kennslust. á viku:&nbsp;&nbsp;</th>
                    <td class="text-right">{{ default0(item.kennslustundir_viku) }}</td>
                  </tr>
                  <tr>
                    <th>Gæsla:</th>
                    <td class="text-right">{{ default0(item.gaesla) }}</td>
                  </tr>
                  <tr>
                    <th>Kennsluskylda:</th>
                    <td class="text-right">{{ default0(item.kennsluskylda) }}</td>
                  </tr>
                </table>
              </b-popover>
            </span>
          </td>
          <td>
            <div v-b-popover.hover.right="item.athugasemdir" :title="item.nafn" v-if="item.athugasemdir && item.athugasemdir.length > 0">
              <i class="fa fa-fw fa-comment"></i>
            </div>
          </td>
          <td>
            <i class="fa fa-fw fa-check text-success" v-if="item.skjal.length > 0 && item.skjal[0].stadfest_starfsm_dags" />
            <i class="fa fa-fw fa-remove text-danger" v-else />
          </td>
          <td>
            <a @click="openUrl(item)" class="cursor-pointer" v-if="item.skjal.length === 1"><i class="fa fa-fw fa-file-o" /></a>
          </td>
          <td>
            <b-dropdown variant="dark" size="sm" dropleft no-caret>
              <template v-slot:button-content>
                <i class="fa fa-fw fa-bars" aria-hidden="true"></i>
              </template>
              <b-dropdown-item @click="staffEditModal(item.starfsm_id)" v-if="canEdit"
                ><i class="fa fa-fw fa-pencil" /> Breyta</b-dropdown-item
              >
              <b-dropdown-item @click="createPdf(item)" v-if="canEdit">
                <i class="fa fa-fw fa-file-pdf-o" aria-hidden="true"></i> Stofna vinnuskýrslu
              </b-dropdown-item>
              <b-dropdown-item @click="worklogPdfListModal(item.starfsm_id)">
                <i class="fa fa-fw fa-search" aria-hidden="true"></i> Skoða lista af pdf skjölum
              </b-dropdown-item>
            </b-dropdown>
          </td>
        </tr>
      </table>
    </b-card>
    <term-create-modal ref="termCreateModal" @successful="reload"></term-create-modal>
    <staff-edit-modal ref="staffEditModal" @successful="loadStaff"></staff-edit-modal>
    <worklog-pdf-list-modal ref="worklogPdfListModal"></worklog-pdf-list-modal>
  </page>
</template>

<script>
import auth from '@/api/auth';
import staff from '@/api/staff';
import { mapGetters, mapActions } from 'vuex';

import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import PageTitle from '@/components/common/PageTitle.vue';
import YesNo from '@/components/common/YesNo.vue';
import CustomButton from '@/components/common/CustomButton.vue';

import TermCreateModal from '@/components/reports/worklog/TermCreateModal.vue';
import StaffEditModal from '@/components/reports/worklog/StaffEditModal.vue';
import WorklogPdfListModal from '@/components/reports/worklog/WorklogPdfListModal.vue';

export default {
  name: 'worklog',
  components: {
    Breadcrumbs,
    PageTitle,
    TermCreateModal,
    StaffEditModal,
    YesNo,
    WorklogPdfListModal,
    CustomButton,
  },
  computed: {
    getTerms() {
      return this.termList.filter(x => !this.selectedTerm || x.onn_id !== this.selectedTerm.onn_id);
    },
    getMonth() {
      return this.selectedTerm.upphafsmanudur ? this.monthList.find(x => x.id === this.selectedTerm.upphafsmanudur).name : '';
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skyrslur_vinnuskyrslur');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      breadcrumbs: [
        { name: 'Forsíða', route: 'Dashboard' },
        { name: 'Starfsmannahald', route: '' },
        { name: 'Vinnuskýrslur', route: 'Worklog' },
      ],
      loading: {
        terms: false,
        staff: false,
        creating: false,
      },
      termList: [],
      staffList: [],
      selectedTerm: '',
      monthList: [
        { id: 1, name: 'janúar' },
        { id: 2, name: 'febrúar' },
        { id: 3, name: 'mars' },
        { id: 4, name: 'apríl' },
        { id: 5, name: 'maí' },
        { id: 6, name: 'júní' },
        { id: 7, name: 'júlí' },
        { id: 8, name: 'ágúst' },
        { id: 9, name: 'september' },
        { id: 10, name: 'október' },
        { id: 11, name: 'nóvember' },
        { id: 12, name: 'desember' },
      ],
    };
  },
  methods: {
    default0(value) {
      if (!value) return 0;
      return value;
    },
    reload(data) {
      this.loadTerms(data.id);
    },
    async loadTerms(termId) {
      try {
        this.loading.terms = true;
        const response = await staff.getWorklogTermList();
        this.termList = response.data.items.map(x => ({
          ...x,
          label: `${x.onn_heiti} - ${x.onn_eink}`,
        }));
        let defaultTerm = null;
        if (termId !== null) {
          defaultTerm = this.termList.find(r => r.onn_id === termId);
        } else {
          defaultTerm = this.termList.find(r => r.virk === 1);
        }
        if (defaultTerm) {
          this.selectedTerm = defaultTerm;
        } else if (this.termList.length > 0) {
          this.selectedTerm = this.termList[0];
        }
        if (this.termList.length > 0) {
          this.loadStaff();
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.terms = false;
      }
    },
    async loadStaff() {
      try {
        this.loading.staff = true;
        const response = await staff.getWorklogTermStaffList(this.selectedTerm.onn_id);
        this.staffList = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.staff = false;
      }
    },
    async createPdf(item) {
      try {
        this.loading.creating = true;
        await staff.createWorklogTermStaffPdf(this.selectedTerm.onn_id, item.starfsm_id);
        this.loadStaff();
        this.displaySuccess('Vinnuskýrsla stofnuð');
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.creating = false;
      }
    },
    async createPdfAll() {
      try {
        this.loading.creating = true;
        const q = [];
        this.staffList.forEach(s => {
          q.push(staff.createWorklogTermStaffPdf(this.selectedTerm.onn_id, s.starfsm_id));
        });

        Promise.all(q)
          .then(() => {
            this.loadStaff();
            this.displaySuccess('Vinnuskýrsla stofnuð');
          })
          .catch(() => {
            this.loadStaff();
            this.displayError('Mistókst að stofna vinnuskýrslu');
          });
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.creating = false;
      }
    },
    async openUrl(item) {
      const url = `/api/staff/worklog-term/${this.selectedTerm.onn_id}/staff/${item.starfsm_id}/pdf/${
        item.skjal[0].vinnuskyrslur_skjol_id
      }?token=${auth.getToken()}`;
      window.open(url, '_blank');
    },
    /*
     * Modal gluggar
     */
    termCreateModal() {
      this.$refs.termCreateModal.show();
    },
    termEditModal(id) {
      this.$refs.termCreateModal.show(id);
    },
    staffEditModal(id) {
      this.$refs.staffEditModal.show(id, this.selectedTerm.onn_id);
    },
    worklogPdfListModal(id) {
      this.$refs.worklogPdfListModal.show(id, this.selectedTerm.onn_id);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadTerms();
  },
};
</script>
