<template>
  <Slidebar @closeMe="closeSlidebar" :large="large" :extraLarge="extraLarge">
    <h4 v-if="id">Breyta einkunn</h4>
    <h4 v-else>Stofna einkunn</h4>
    <div class="d-inline-block float-right mt-2">
      <b-btn variant="primary" size="sm" v-if="!id" @click="addItem()" title="Bæta við einkunn" v-b-tooltip
        ><i class="fa fa-plus-circle"></i
      ></b-btn>
    </div>
    <br />
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <div v-for="(grade, idx) in form.grades" :key="idx">
        <div class="d-inline-block mr-4 mb-2">
          <div v-if="idx === 0">Einkunn</div>
          <b-form-group
            :label-for="`einkunn${idx}`"
            :state="submitted && errors.has(`einkunn${idx}`) ? false : ''"
            :invalid-feedback="errors.first(`einkunn${idx}`)"
          >
            <b-form-input
              :id="`einkunn${idx}`"
              :name="`einkunn${idx}`"
              type="text"
              v-model="grade.einkunn"
              @change="validateGrade(grade)"
              :state="(submitted && errors.has(`einkunn${idx}`)) || grade.exists ? false : ''"
              maxLength="10"
              v-validate="'required'"
              data-vv-as="einkunn"
              style="width: 100px"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="d-inline-block mr-4">
          <div v-if="idx === 0">Hlutfall frá</div>
          <b-form-group
            :label-for="`hlutfall_fra${idx}`"
            :state="submitted && errors.has(`hlutfall_fra${idx}`) ? false : ''"
            :invalid-feedback="errors.first(`hlutfall_fra${idx}`)"
          >
            <b-form-input
              :id="`hlutfall_fra${idx}`"
              :name="`hlutfall_fra${idx}`"
              type="text"
              v-model="grade.hlutfall_fra"
              :state="submitted && errors.has(`hlutfall_fra${idx}`) ? false : ''"
              data-vv-as="hlutfall frá"
              style="width: 100px"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="d-inline-block mr-4">
          <div v-if="idx === 0">Hlutfall til</div>
          <b-form-group
            :label-for="`hlutfall_til${idx}`"
            :state="submitted && errors.has(`hlutfall_til${idx}`) ? false : ''"
            :invalid-feedback="errors.first(`hlutfall_til${idx}`)"
          >
            <b-form-input
              :id="`hlutfall_til${idx}`"
              :name="`hlutfall_til${idx}`"
              type="text"
              v-model="grade.hlutfall_til"
              :state="submitted && errors.has(`hlutfall_til${idx}`) ? false : ''"
              data-vv-as="hlutfall til"
              style="width: 100px"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="d-inline-block" v-if="form.grades.length > 1">
          <b-btn variant="primary" @click="removeItem(idx)" class="btn-xs">
            <i class="fa fa-trash"></i>
          </b-btn>
        </div>
        <div class="text-danger" style="font-size: 13px" v-if="grade.exists">
          Ath. þessi einkunn er nú þegar til í þessum einkunnaskala.
        </div>
      </div>
      <div>
        <b-form-group
          label="Lýsing"
          label-for="lysing"
          :state="submitted && errors.has('lysing') ? false : ''"
          :invalid-feedback="errors.first('lysing')"
        >
          <b-form-input
            id="lysing"
            name="lysing"
            type="text"
            v-model="form.lysing"
            :state="submitted && errors.has('lysing') ? false : ''"
            maxLength="100"
            data-vv-as="lysing"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="mt-2">
        <b-form-group
          label="Staðið"
          label-for="stadid"
          :state="submitted && errors.has('stadid') ? false : ''"
          :invalid-feedback="errors.first('stadid')"
        >
          <v-select id="stadid" name="stadid" v-model="form.stadid" :options="yesNo" :clearable="false" style="width: 100px"> </v-select>
        </b-form-group>
      </div>
      <div class="mt-2">
        <b-form-group
          label="0 einingar á lokaprófi"
          label-for="ein_lokaprof"
          :state="submitted && errors.has('ein_lokaprof') ? false : ''"
          :invalid-feedback="errors.first('ein_lokaprof')"
        >
          <v-select
            id="ein_lokaprof"
            name="ein_lokaprof"
            v-model="form.ein_lokaprof"
            :options="yesNo"
            :clearable="false"
            style="width: 100px"
          >
          </v-select>
        </b-form-group>
      </div>
      <div class="mt-2">
        <b-form-group
          label="Einingar"
          label-for="einingar"
          :state="submitted && errors.has('einingar') ? false : ''"
          :invalid-feedback="errors.first('einingar')"
        >
          <b-form-input
            id="einingar"
            name="einingar"
            type="number"
            v-model="form.einingar"
            min="0"
            :state="submitted && errors.has('einingar') ? false : ''"
            data-vv-as="einingar"
            style="width: 100px"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="mt-2">
        <b-form-group
          label="Fall á önn"
          label-for="fall_a_onn"
          :state="submitted && errors.has('fall_a_onn') ? false : ''"
          :invalid-feedback="errors.first('fall_a_onn')"
        >
          <v-select id="fall_a_onn" name="fall_a_onn" v-model="form.fall_a_onn" :options="yesNo" :clearable="false" style="width: 100px">
          </v-select>
        </b-form-group>
      </div>
      <div class="mt-2 mb-3">
        <b-form-group
          label="Mætir í próf"
          label-for="maetir_i_prof"
          :state="submitted && errors.has('maetir_i_prof') ? false : ''"
          :invalid-feedback="errors.first('maetir_i_prof')"
        >
          <v-select
            id="maetir_i_prof"
            name="maetir_i_prof"
            v-model="form.maetir_i_prof"
            :options="yesNo"
            :clearable="false"
            style="width: 100px"
          >
          </v-select>
        </b-form-group>
      </div>
      <b-btn variant="primary" class="mr-2" v-if="canEdit" :disabled="hasInvalidGrade" @click="validateBeforeSubmit">
        <span v-if="id">Breyta</span>
        <span v-else>Stofna</span>
      </b-btn>
      <b-btn variant="dark" @click="closeSlidebar">Hætta við</b-btn>
      <b-btn variant="danger" class="float-right" v-if="canEdit && id" @click="deleteGrade"> Eyða </b-btn>
    </b-form>
  </Slidebar>
</template>

<script>
import types from '@/api/types';
import { mapActions, mapGetters } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';

export default {
  name: 'grade-scale-edit-slidebar',
  props: ['item', 'scaleId', 'large', 'extraLarge'],
  components: {
    Slidebar,
  },
  computed: {
    hasInvalidGrade() {
      return this.form.grades.filter(x => x.exists).length > 0;
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      id: null,
      saving: false,
      submitted: false,
      loading: false,
      form: {
        grades: [
          {
            einkunn: '',
            hlutfall_fra: '',
            hlutfall_til: '',
            exists: false,
          },
        ],
        stadid: '',
        lysing: '',
        ein_lokaprof: '',
        einingar: '',
        fall_a_onn: '',
        maetir_i_prof: '',
      },
      yesNo: [
        { id: 1, label: 'Já' },
        { id: 0, label: 'Nei' },
      ],
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    addItem() {
      this.form.grades.push({
        einkunn: '',
        hlutfall_fra: '',
        hlutfall_til: '',
        exists: false,
      });
    },
    removeItem(idx) {
      this.form.grades.splice(idx, 1);
    },
    async validateGrade(g) {
      this.$set(g, 'exists', false);
      try {
        const response = await types.getGradeScaleGradeExists(this.scaleId, {
          gradeId: this.id,
          grade: (g.einkunn || '').replace('.', ','),
        });
        this.$set(g, 'exists', response.data.already_exists > 0);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.saving = true;
          const q = [];
          this.form.grades
            .filter(x => !x.exists)
            .forEach(g => {
              const data = {
                einkunn: (g.einkunn || '').toString().replace('.', ','),
                hlutfall_fra: (g.hlutfall_fra || '').replace('.', ','),
                hlutfall_til: (g.hlutfall_til || '').replace('.', ','),
                stadid: this.form.stadid.id,
                lysing: this.form.lysing,
                ein_lokaprof: this.form.ein_lokaprof.id,
                einingar: this.form.einingar,
                fall_a_onn: this.form.fall_a_onn.id,
                maetir_i_prof: this.form.maetir_i_prof.id,
              };
              if (this.id) {
                q.push(types.updateGradeScaleGrade(this.scaleId, this.id, data));
              } else {
                q.push(types.createGradeScaleGrade(this.scaleId, data));
              }
            });
          Promise.all(q)
            .then(() => {
              if (this.id) {
                this.displaySuccess('Einkunn hefur verið breytt.');
              } else {
                this.displaySuccess('Einkunn hefur verið stofnaður');
              }
              this.$emit('closeAndReloadSlidebar');
            })
            .catch(e => {
              if (e.response && e.response.data && e.response.data.error) {
                this.displayError(e.response.data.error);
              } else {
                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
              }
            });
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.data && e.response.data.error) {
            this.displayError(e.response.data.error);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.saving = false;
      }
    },
    async deleteGrade() {
      // Engar villur í formi.
      try {
        this.saving = true;
        await types.updateGradeScaleGrade(this.scaleId, this.id, { delete: 1 });
        this.displaySuccess('Einkunn hefur verið eytt.');
        this.$emit('closeAndReloadSlidebar');
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        if (e.response && e.response.data && e.response.data.error) {
          this.displayError(e.response.data.error);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } finally {
        this.saving = false;
      }
    },
    initialize() {
      this.form = {
        grades: [
          {
            einkunn: '',
            hlutfall_fra: '',
            hlutfall_til: '',
          },
        ],
        stadid: { id: 0, label: 'Nei' },
        lysing: '',
        ein_lokaprof: { id: 0, label: 'Nei' },
        einingar: '',
        fall_a_onn: { id: 0, label: 'Nei' },
        maetir_i_prof: { id: 1, label: 'Já' },
      };
      this.id = null;
      if (this.item && this.item.einkunnaskali_id) {
        this.id = this.item.einkunnaskali_id;
        this.form.grades = [
          {
            einkunn: this.item.einkunn_a ? this.item.einkunn_a : this.item.einkunn,
            hlutfall_fra: this.item.hlutfall_fra,
            hlutfall_til: this.item.hlutfall_til,
          },
        ];
        this.form.stadid = this.yesNo.find(x => x.id === this.item.stadid);
        this.form.lysing = this.item.lysing;
        this.form.ein_lokaprof = this.yesNo.find(x => x.id === this.item.ein_lokaprof);
        this.form.einingar = this.item.einingar;
        this.form.fall_a_onn = this.yesNo.find(x => x.id === this.item.fall_a_onn);
        this.form.maetir_i_prof = this.yesNo.find(x => x.id === this.item.maetir_i_prof);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    item() {
      this.initialize();
    },
  },
  created() {
    this.initialize();
  },
};
</script>

<style lang="scss"></style>
