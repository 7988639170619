<template>
  <page>
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <PageTitle :title="title" />
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <b-card>
        <h4>Grunnupplýsingar</h4>
        <b-row>
          <b-col cols="3">
            <b-form-group
              label="Dagsetning*"
              label-for="date"
              :state="submitted && errors.has('date') ? false : ''"
              :invalid-feedback="errors.first('date')"
            >
              <datepicker
                class="datepicker_100_width"
                id="date"
                name="date"
                v-model="form.date"
                :language="lang"
                :monday-first="true"
                format="dd.MM.yyyy"
                :typeable="false"
                v-validate="'required'"
                data-vv-as="dags"
                ref="dateOpenPickr"
              ></datepicker>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              label="Tegund viðtals*"
              label-for="category"
              :state="submitted && errors.has('category') ? false : ''"
              :invalid-feedback="errors.first('category')"
            >
              <v-select
                id="category"
                name="category"
                @change="
                  updateNumber();
                  updatePricing();
                "
                v-model="form.category"
                :clearable="false"
                v-validate="'required'"
                :state="submitted && errors.has('category') ? false : ''"
                :options="categories"
                label="counseling_category_name"
                data-vv-as="tegund"
              >
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              label="Ástæða viðtals*"
              label-for="reason"
              :state="submitted && errors.has('reason') ? false : ''"
              :invalid-feedback="errors.first('reason')"
            >
              <v-select
                id="reason"
                name="reason"
                v-model="form.reason"
                :clearable="false"
                v-validate="'required'"
                :state="submitted && errors.has('reason') ? false : ''"
                :options="reasons"
                label="counseling_reason_name"
                data-vv-as="ástæða"
              >
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <div class="form-group">
              <label>Verðútreikningur</label>
              <br />
              <strong>Númer viðtals</strong>: <span v-if="number !== '-1'">{{ number }}</span
              ><span v-else>Út frá skilyrðum</span>
              <br />
              <strong translate>Verð viðtals</strong>: <span v-if="pricing !== '-1'">{{ pricing | currency }}</span>
              <span v-else>Út frá skilyrðum</span>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="3">
            <b-form-group
              label="Staður*"
              label-for="location"
              :state="submitted && errors.has('location') ? false : ''"
              :invalid-feedback="errors.first('location')"
            >
              <v-select
                id="location"
                name="location"
                v-model="form.location"
                :clearable="false"
                v-validate="'required'"
                :state="submitted && errors.has('location') ? false : ''"
                :options="locations"
                label="heiti"
                data-vv-as="staður"
              >
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Staður viðtals" label-for="location_text">
              <b-form-input id="location_text" name="location_text" v-model="form.location_text" />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Niðurstaða viðtals (hámark 3)*"
              label-for="conclusion"
              :state="submitted && errors.has('conclusion') ? false : ''"
              :invalid-feedback="errors.first('conclusion')"
            >
              <v-select
                id="conclusion"
                name="conclusion"
                v-model="form.conclusion"
                multiple
                v-validate="'required'"
                :state="submitted && errors.has('conclusion') ? false : ''"
                :options="form.conclusion.length < 3 ? conclusions : []"
                label="counseling_conclusion_name"
                data-vv-as="ástæða"
              >
                <span slot="no-options">Eingöngu er hægt að velja að hámarki 3.</span>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Greitt af öðrum" label-for="paid_by_others">
              <b-form-checkbox id="paid_by_others" name="paid_by_others" v-model="form.paid_by_others" />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Rafrænt viðtal / símtal" label-for="digital_consult">
              <b-form-checkbox id="digital_consult" name="digital_consult" v-model="form.digital_consult" />
            </b-form-group>
          </b-col>
          <b-col /><b-col />
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Athugasemdir" label-for="comments">
              <quill-editor
                id="comments"
                name="comments"
                v-model="form.comments"
                :state="submitted && errors.has('comments') ? false : ''"
                ref="textQuillEditor"
                :options="editorOption"
              >
              </quill-editor>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-card v-if="form.category && form.category.is_group === 1" class="mt-3">
        <h4>Hópur</h4>
        <b-btn id="selectGroupBtn" v-if="!form.group.id" variant="outline-primary" @click="toggleNewGroup()">
          <span v-if="newGroup">Finna eldri hóp</span>
          <span v-else>Búa til nýjan hóp</span>
        </b-btn>
        <b-form-group
          label="Heiti hóps*"
          label-for="group_name"
          :state="submitted && errors.has('group_name') ? false : ''"
          :invalid-feedback="errors.first('group_name')"
        >
          <b-form-input
            id="group_name"
            name="group_name"
            ref="newGroupName"
            :disabled="form.group.id > 0"
            v-model="form.group.name"
            v-validate="'required'"
            :state="submitted && errors.has('group_name') ? false : ''"
            data-vv-as="heiti"
            v-if="newGroup"
          />
          <v-select
            id="group_name"
            name="group_name"
            ref="oldGroupName"
            :disabled="form.group.id > 0"
            v-model="form.group"
            v-validate="'required'"
            :state="submitted && errors.has('group_name') ? false : ''"
            :clearable="false"
            :options="groups"
            data-vv-as="heiti"
            label="name"
            v-else
            @input="value => loadOldInterview(value.id)"
          >
          </v-select>
        </b-form-group>
      </b-card>
      <br />
      <div v-for="(p, index) in form.persons" :key="index" class="mb-3">
        <b-card v-if="form.category && (form.category.is_group === 1 || index === 0)">
          <div v-if="p.confirmed" href class="text-muted pull-right" style="padding: 10px" @click="clearPerson(index)">
            <i class="fa fa-fw fa-trash"></i>
          </div>
          <h4>Einstaklingur # {{ index + 1 }}</h4>
          <b-row>
            <b-col cols="3">
              <b-form-group
                label="Kennitala*"
                :label-for="`person${index}ssn`"
                :state="submitted && errors.has(`person${index}ssn`) ? false : ''"
                :invalid-feedback="errors.first(`person${index}ssn`)"
              >
                <b-form-input
                  :id="`person${index}ssn`"
                  :name="`person${index}ssn`"
                  v-model="p.ssn"
                  v-validate="index === 0 ? 'required' : ''"
                  :disabled="p.confirmed"
                  :state="submitted && errors.has(`person${index}ssn`) ? false : ''"
                  data-vv-as="kennitala"
                />
              </b-form-group>
              <div class="text-danger" v-if="p.notFound">Kennitala finnst ekki</div>
            </b-col>
            <b-col cols="3" v-if="!p.confirmed">
              <b-btn
                variant="primary"
                :disabled="p.ssn.length !== 10 || p.checkingSSN"
                @click="checkPerson(p)"
                style="position: absolute; bottom: 15px"
              >
                <span v-if="!p.checkingSSN">Bæta við</span>
                <i v-else class="fa fa-spinner fa-spin" />
              </b-btn>
            </b-col>
            <b-col cols="3" v-if="p.confirmed">
              <b-form-group label="Nafn" :label-for="`person${index}name`">
                <b-form-input :id="`person${index}name`" :name="`person${index}name`" v-model="p.name" readonly="readonly" />
              </b-form-group>
            </b-col>
            <b-col cols="3" v-if="p.confirmed">
              <b-form-group label="Kyn og aldur" :label-for="`person${index}gendage`">
                <b-form-input
                  :id="`person${index}gendage`"
                  :name="`person${index}gendage`"
                  readonly="readonly"
                  :value="`${p.gender} / ${p.age}`"
                />
              </b-form-group>
            </b-col>
            <b-col cols="3" v-if="p.confirmed">
              <b-form-group label="Þjóðerni og búseta" :label-for="`person${index}natadr`">
                <b-form-input
                  :id="`person${index}natadr`"
                  :name="`person${index}natadr`"
                  readonly="readonly"
                  :value="`${p.nationality} / ${p.address}, ${p.postcode}`"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="p.confirmed">
            <b-col cols="3">
              <b-form-group
                label="Símanúmer"
                :label-for="`person${index}tel`"
                :state="submitted && errors.has(`person${index}tel`) ? false : ''"
                :invalid-feedback="errors.first(`person${index}tel`)"
              >
                <b-form-input
                  :id="`person${index}tel`"
                  :name="`person${index}tel`"
                  v-model="p.tel"
                  :state="submitted && errors.has(`person${index}tel`) ? false : ''"
                  data-vv-as="símanúmer"
                />
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group
                label="Netfang"
                :label-for="`person${index}email`"
                :state="submitted && errors.has(`person${index}email`) ? false : ''"
                :invalid-feedback="errors.first(`person${index}email`)"
              >
                <b-form-input
                  :id="`person${index}tel`"
                  :name="`person${index}email`"
                  v-model="p.email"
                  :state="submitted && errors.has(`person${index}email`) ? false : ''"
                  data-vv-as="netfang"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="p.confirmed">
            <b-col>
              <b-form-group
                label="Skólastig*"
                :label-for="`person${index}schoolLevel`"
                :state="submitted && errors.has(`person${index}schoolLevel`) ? false : ''"
                :invalid-feedback="errors.first(`person${index}schoolLevel`)"
              >
                <v-select
                  :id="`person${index}schoolLevel`"
                  :name="`person${index}schoolLevel`"
                  v-model="p.schoolLevel"
                  :clearable="false"
                  v-validate="index === 0 || p.confirmed ? 'required' : ''"
                  :state="submitted && errors.has(`person${index}schoolLevel`) ? false : ''"
                  :options="levels"
                  label="school_level_name"
                  data-vv-as="skólastig"
                >
                </v-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Stéttarfélag*"
                :label-for="`person${index}union`"
                :state="submitted && errors.has(`person${index}union`) ? false : ''"
                :invalid-feedback="errors.first(`person${index}union`)"
              >
                <v-select
                  :id="`person${index}union`"
                  :name="`person${index}union`"
                  v-model="p.union"
                  :clearable="false"
                  v-validate="index === 0 || p.confirmed ? 'required' : ''"
                  :state="submitted && errors.has(`person${index}union`) ? false : ''"
                  :options="unions"
                  label="union_name"
                  data-vv-as="stéttarfélag"
                >
                </v-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Staða á vinnumarkaði*"
                :label-for="`person${index}status`"
                :state="submitted && errors.has(`person${index}status`) ? false : ''"
                :invalid-feedback="errors.first('status')"
              >
                <v-select
                  :id="`person${index}status`"
                  :name="`person${index}status`"
                  v-model="p.status"
                  :clearable="false"
                  v-validate="index === 0 || p.confirmed ? 'required' : ''"
                  :state="submitted && errors.has(`person${index}status`) ? false : ''"
                  :options="status"
                  label="status_name"
                  data-vv-as="staða"
                >
                </v-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Vinnustaður"
                label-for="workplace"
                :state="submitted && errors.has('workplace') ? false : ''"
                :invalid-feedback="errors.first('workplace')"
              >
                <b-form-input id="workplace" name="workplace" v-model="p.workplace" data-vv-as="vinnustaður" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="p.confirmed">
            <b-col>
              <b-form-group
                label="Athugasemdir"
                label-for="comments"
                :state="submitted && errors.has('comments') ? false : ''"
                :invalid-feedback="errors.first('comments')"
              >
                <quill-editor
                  id="comments"
                  name="comments"
                  v-model="p.comments"
                  :state="submitted && errors.has('comments') ? false : ''"
                  :ref="`person${index}textQuillEditor`"
                  :options="editorOption"
                >
                </quill-editor>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </div>
      <b-btn variant="primary" @click="validateBeforeSubmit()" :disabled="!validPerson || saving"> Skrá viðtal </b-btn>
    </b-form>
  </page>
</template>

<script>
import counseling from '@/api/counseling';
import common from '@/api/common';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import vue from 'vue';

import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import PageTitle from '@/components/common/PageTitle.vue';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';
import { quillEditor } from 'vue-quill-editor';

export default {
  name: 'counseling-create-interview',
  components: {
    Breadcrumbs,
    PageTitle,
    Datepicker,
    quillEditor,
  },
  computed: {
    validPerson() {
      return this.form.persons.filter(x => x.confirmed).length > 0;
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      title: 'Skrá viðtal',
      breadcrumbs: [
        { name: 'Forsíða', route: 'Dashboard' },
        { name: 'Nemendahald', route: '' },
        { name: 'Námsráðgjöf', route: 'Counseling' },
        { name: 'Skrá viðtal', route: 'CounselingCreateInterview' },
      ],
      number: '-1',
      pricing: '-1',
      saving: false,
      form: {
        date: moment().toDate(),
        category: null,
        reason: null,
        location: null,
        location_text: '',
        conclusion: [],
        paid_by_others: false,
        digital_consult: false,
        comments: '',
        group: {
          id: 0,
          name: '',
        },
        persons: [
          {
            ssn: '',
            name: '',
            gender: '',
            age: '',
            nationality: '',
            address: '',
            postcode: '',
            tel: '',
            email: '',
            schoolLevel: '',
            union: '',
            status: '',
            workplace: '',
            comments: '',
            confirmed: false,
            checkingSSN: false,
          },
        ],
      },
      submitted: false,
      lang: is,
      loading: {
        category: false,
        conclusion: false,
        reason: false,
        level: false,
        status: false,
        union: false,
        location: false,
        group: false,
      },
      editorOption: {
        /* quill options */
      },
      categories: [],
      conclusions: [],
      reasons: [],
      levels: [],
      status: [],
      unions: [],
      locations: [],
      groups: [],
      newGroup: true,
    };
  },
  methods: {
    async loadOldInterview(id) {
      try {
        const response = await counseling.getItem(id);
        await this.loadCategory();
        await this.loadSchoolLevel();
        await this.loadStatus();
        await this.loadUnion();
        this.loadConclusion();
        this.loadReason();
        this.loadLocation();
        const old = response.data;
        if (old.counseling_group_id) {
          this.form.group.id = old.counseling_group_id;
          this.form.group.name = old.counseling_group_name;
        }
        this.form.category = this.categories.find(x => x.nsr_counseling_category_id === old.counseling_category_id);
        const persons = [];
        old.counselees.forEach(c => {
          persons.push({
            ssn: c.ssn,
            name: c.nafn,
            gender: c.kyn,
            age: c.aldur,
            nationality: c.rikisfang,
            address: c.heimilisfang,
            postcode: c.postnumer,
            tel: c.phone_number,
            email: c.email,
            schoolLevel: this.levels.find(x => x.nsr_school_level_id === c.school_level_id),
            union: this.unions.find(x => x.nsr_union_id === c.union_id),
            status: this.status.find(x => x.nsr_status_id === c.status_id),
            workplace: c.workplace,
            comments: c.comments,
            confirmed: true,
            checkingSSN: false,
          });
        });
        this.form.persons = persons;
        this.updateNumber();
        this.updatePricing();
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadCategory() {
      try {
        this.loading.category = true;
        const response = await counseling.getCategories();
        this.categories = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.category = false;
      }
    },
    async loadConclusion() {
      try {
        this.loading.conclusion = true;
        const response = await counseling.getConclusions();
        this.conclusions = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.conclusion = false;
      }
    },
    async loadReason() {
      try {
        this.loading.reason = true;
        const response = await counseling.getReasons();
        this.reasons = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.reason = false;
      }
    },
    async loadSchoolLevel() {
      try {
        this.loading.level = true;
        const response = await counseling.getSchoolLevels();
        this.levels = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.level = false;
      }
    },
    async loadStatus() {
      try {
        this.loading.status = true;
        const response = await counseling.getStatus();
        this.status = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.status = false;
      }
    },
    async loadUnion() {
      try {
        this.loading.union = true;
        const response = await counseling.getUnion();
        this.unions = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.union = false;
      }
    },
    async loadLocation() {
      try {
        this.loading.location = true;
        const response = await common.getPostalCodes();
        this.locations = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.location = false;
      }
    },
    async loadGroups() {
      try {
        this.loading.group = true;
        const response = await counseling.getGroupList();
        this.groups = response.data.items.map(i => ({
          id: i.last_counseling_id,
          name: i.group_name,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.group = false;
      }
    },
    async updateNumber() {
      // Uppfærir númer viðtals
      this.number = '-1';
      if (this.form.category && this.form.date && this.form.persons[0].confirmed) {
        if (this.form.category.is_group === 1) {
          if (this.form.group.id > 0) {
            const response = await counseling.getGroupNumber({
              counseling_group_id: this.form.group.id,
              counseling_date: moment(this.form.date).format('DD.MM.YYYY'),
            });

            if (response.data.number) {
              this.number = response.data.number;
            }
          }
        } else {
          const response = await counseling.getNumber({
            ssn: this.form.persons[0].ssn,
            counseling_category_id: this.form.category.nsr_counseling_category_id,
            counseling_date: moment(this.form.date).format('DD.MM.YYYY'),
          });
          if (response.data.number) {
            this.number = response.data.number;
          }
        }
      }
    },
    async updatePricing() {
      // Uppfærir verð
      this.pricing = '1';
      if (this.form.category && this.form.date && this.form.persons[0].confirmed) {
        const ssn = this.form.persons
          .filter(x => x.confirmed)
          .map(x => x.ssn)
          .join(',');
        try {
          const response = await counseling.getPricing({
            counseling_category_id: this.form.category.nsr_counseling_category_id,
            counseling_date: moment(this.form.date).format('DD.MM.YYYY'),
            ssn,
          });
          if (response.data.price) {
            this.pricing = response.data.price;
          }
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    async checkPerson(person) {
      try {
        this.$set(person, 'checkingSSN', true);
        this.$set(person, 'notFound', false);
        const response = await counseling.getSearchPerson(person.ssn);
        this.$set(person, 'confirmed', true);
        this.$set(person, 'name', response.data.nafn);
        this.$set(person, 'gender', response.data.kyn);
        this.$set(person, 'age', response.data.aldur);
        this.$set(person, 'nationality', response.data.rikisfang);
        this.$set(person, 'address', response.data.heimilisfang);
        this.$set(person, 'postcode', response.data.postnumer);
        this.form.persons.push({
          ssn: '',
          name: '',
          gender: '',
          age: '',
          nationality: '',
          address: '',
          postcode: '',
          tel: '',
          email: '',
          schoolLevel: '',
          union: '',
          status: '',
          workplace: '',
          comments: '',
          confirmed: false,
          checkingSSN: false,
        });
        this.updateNumber();
        this.updatePricing();
      } catch (e) {
        if (e.status === 404) {
          this.$set(person, 'notFound', false);
        } else {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } finally {
        this.$set(person, 'checkingSSN', false);
      }
    },
    clearPerson(index) {
      const conf = confirm(`Ert þú viss um að þú viljir fjarlægja einstakling #${index + 1}?`); // eslint-disable-line
      if (conf) {
        this.form.persons.splice(index, 1);
        const containsEmpty = this.form.persons.filter(x => x.kennitala === '').length > 0;
        if (containsEmpty) {
          this.form.persons.push({
            ssn: '',
            name: '',
            gender: '',
            age: '',
            nationality: '',
            address: '',
            postcode: '',
            tel: '',
            email: '',
            schoolLevel: '',
            union: '',
            status: '',
            workplace: '',
            comments: '',
            confirmed: false,
            checkingSSN: false,
          });
        }
        this.updateNumber();
        this.updatePricing();
      }
    },
    toggleNewGroup() {
      this.newGroup = !this.newGroup;

      // Bíðum efitr að breytingin er render-uð áður en við focus-um.
      vue.nextTick().then(() => {
        if (this.$refs.newGroupName) {
          this.$refs.newGroupName.$el.focus();
        } else if (this.$refs.oldGroupName) {
          this.$refs.oldGroupName.$el.querySelector('.vs__search').focus();
        }
      });
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });
        this.saving = true;

        try {
          const item = {
            category_id: this.form.category.nsr_counseling_category_id,
            conclusions: this.form.conclusion.map(x => x.nsr_counseling_conclusion_id).join(','),
            comments: this.form.comments,
            date: moment(this.form.date).format('DD.MM.YYYY'),
            group_id: this.form.group.id > 0 ? this.form.group.id : '',
            group_name: this.form.group.name,
            location_id: this.form.location.postnumer,
            location_text: this.form.location_text,
            reason_id: this.form.reason.nsr_counseling_reason_id,
            paid_by_others: this.form.paid_by_others ? 1 : 0,
            digital_consult: this.form.digital_consult ? 1 : 0,
            persons: this.form.persons
              .filter(x => x.confirmed)
              .map(x => ({
                kennitala: x.ssn,
                email: x.email,
                tel: x.tel,
                workplace: x.workplace,
                comments: x.comments,
                schoolLevel: x.schoolLevel.nsr_school_level_id,
                union: x.union.nsr_union_id,
                status: x.status.nsr_status_id,
              })),
          };
          const response = await counseling.createInterview(item);
          this.$router.push({ name: 'CounselingInterview', params: { id: response.data.id } });
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.saving = false;
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.saving = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    if (this.$route.query.old) {
      this.loadOldInterview(this.$route.query.old);
    } else {
      this.loadCategory();
      this.loadConclusion();
      this.loadReason();
      this.loadSchoolLevel();
      this.loadStatus();
      this.loadUnion();
      this.loadLocation();
      this.loadGroups();
    }
  },
};
</script>

<style scoped>
#selectGroupBtn {
  position: absolute;
  top: 1.25em;
  right: 1.25em;
}
</style>
