<template>
  <Slidebar :autoClose="false" @closeMe="closeSlidebar" :extraLarge="true">
    <h4>Skrá einkunn</h4>
    <b-row>
      <b-col class="pl-0">
        <div v-if="scale">
          <div>
            Skali: {{ scale.heiti }}
            <i class="fa fa-search cursor-pointer" v-if="!viewScale" @click="viewScale = true"></i>
            <i class="fa fa-remove cursor-pointer" v-else @click="viewScale = false"></i>
          </div>
          <div style="background-color: #eee; width: 50%" class="mb-3 p-4" v-if="viewScale">
            <div>Leyfðar einkunnir</div>
            <ul>
              <li v-for="(g, idx) in scale.skali" :key="idx" style="font-size: 12px">{{ g.grade }}</li>
            </ul>
          </div>
        </div>
        <div v-else>Skali: Tölur milli 0 og 10 eða bókstafir.</div>
      </b-col>
      <b-col>
        <div class="float-right"><b-form-checkbox v-model="showComments" class="d-inline-block"></b-form-checkbox> Birta athugasemdir</div>
      </b-col>
    </b-row>
    <b-row v-if="assignmentName" style="font-size: 13px">
      <b-col class="pl-0 pb-2">
        <div>
          <i class="fa fa-info-circle"></i> Ath. þessi einkunnaregla er tengd við verkefnið/prófið <strong>{{ assignmentName }}</strong
          >. Við það að breyta einkunn hér breytist einkunnin í verkefninu/prófinu líka!
        </div>
      </b-col>
    </b-row>

    <table class="table less-padding" style="font-size: 12px">
      <thead>
        <tr>
          <th>Nafn</th>
          <th>Kennitala</th>
          <th>Nemandanr.</th>
          <th>Einkunn</th>
          <th v-if="showComments">Athugasemd</th>
        </tr>
      </thead>
      <tr v-for="(item, idx) in list" :key="item.nemandi_id">
        <td>
          {{ item.nafn }}
          <i
            class="fa fa-exclamation-triangle"
            v-if="assignmentName && item.verkefni.length === 0"
            v-b-tooltip
            title="Ath. ekki fundust nein skil fyrir þennan nemanda, einkunn verður ekki uppfærð í verkefni."
          ></i>
          <i
            class="fa fa-exclamation-triangle"
            v-if="assignmentName && item.verkefni.length > 1"
            v-b-tooltip
            title="Ath. fundust fleiri en ein skil fyrir þennan nemanda. Einkunn fyrir öll skil vera uppfærð."
          ></i>
        </td>
        <td>{{ item.kennitala }}</td>
        <td>{{ item.nemandi_id }}</td>
        <td>
          <b-form-input
            v-model="item.grade"
            :class="{ 'is-invalid': !checkGradeValid(idx) }"
            style="width: 40px; height: 25px; padding: 0px 0px 0px 5px; font-size: 13px"
          ></b-form-input>
        </td>
        <td v-if="showComments">
          <quill-editor v-model="item.athugasemd" :options="editorOption" @ready="quillHeight($event)"> </quill-editor>
        </td>
      </tr>
    </table>
    <b-btn variant="primary" :disabled="notAllValid" @click="saveGrades()"> Vista </b-btn>
  </Slidebar>
</template>

<script>
import groups from '@/api/groups';
import Slidebar from '@/components/common/Slidebar.vue';

import { quillEditor } from 'vue-quill-editor';
import { isGradeValid } from '@/utils';

export default {
  name: 'register-grade-slidebar',
  components: {
    Slidebar,
    quillEditor,
  },
  props: ['groupId', 'gradeModuleId', 'gradeGroupId', 'assignmentName'],
  mixins: ['isGradeValid'],
  computed: {
    notAllValid() {
      return this.list.filter((x, idx) => !this.checkGradeValid(idx)).length > 0;
    },
  },
  data() {
    return {
      list: [],
      scale: '',
      loading: false,
      showComments: false,
      viewScale: false,
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'], // toggled buttons
            [{ color: [] }], // dropdown with defaults from theme
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ],
        },
      },
    };
  },
  methods: {
    quillHeight(event) {
      event.container.style.height = '100px'; // eslint-disable-line
    },
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    checkGradeValid(index) {
      let value = this.list[index].grade;
      if (this.gradeModuleId && value) {
        value = value.toString().replace('.', ',');
        return this.scale.skali.filter(x => (x.grade || '').toUpperCase() === value.toUpperCase()).length > 0;
      }
      if (this.gradeGroupId && value) {
        value = value.toString().replace(',', '.'); // Verðum að breyta , yfir í . útaf javascript
        return !!isGradeValid(value);
      }
      return true;
    },
    async loadGroup() {
      try {
        this.loading = true;
        const response = await groups.getGradeStudents(this.groupId, {
          einkunnirHopsId: this.gradeGroupId,
          einkunnirAfangaId: this.gradeModuleId,
        });
        this.list = response.data.items.map(x => ({
          ...x,
          grade: x.einkunn_a || (x.einkunn ? x.einkunn.toString().replace('.', ',') : ''),
          verkefni: (x.verkefnaskil || '').split(','),
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadScale() {
      try {
        const response = await groups.getGradeScaleByRule(this.gradeModuleId);
        this.scale = response.data.items.length > 0 ? response.data.items[0] : '';
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async saveGrades() {
      try {
        const data = {
          hopurId: this.groupId,
          einkunnirAfangaId: this.gradeModuleId,
          einkunnirHopsId: this.gradeGroupId,
          nemendur: this.list.map(x => ({
            ferill_id: x.ferill_id,
            einkunn: x.grade ? x.grade.replace('.', ',') : '',
            athugasemd: x.athugasemd,
            verkefnaskil: x.verkefnaskil,
          })),
        };
        await groups.registerGrades(data);
        this.$emit('closeAndReloadSlidebar');
      } catch (e) {
        // Aðgerð mistókst.
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
  },
  created() {
    this.loadGroup();
    if (this.gradeModuleId) {
      this.loadScale();
    }
  },
};
</script>

<style lang="scss"></style>
