<template>
  <Slidebar @closeMe="closeSlidebar" :large="large" :extraLarge="extraLarge">
    <h4 v-if="id">Breyta námsgrein</h4>
    <h4 v-else>Stofna námsgrein</h4>
    <br />
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <table class="table less-padding">
        <tr>
          <td>
            Auðkenni*
            <i
              class="fa fa-info-circle"
              title="Er stutt oftast 1-4 stafir, fyrstu stafir í auðkenni áfanga koma frá auðkenni námsgreinar. Dæmi: STÆ og ÍSL"
              v-b-tooltip
            ></i>
          </td>
          <td>
            <b-form-group label-for="code" :state="submitted && errors.has('code') ? false : ''" :invalid-feedback="errors.first('code')">
              <b-form-input
                id="code"
                name="code"
                type="text"
                v-model="form.kodi"
                :state="submitted && errors.has('code') ? false : ''"
                maxLength="8"
                data-vv-as="auðkenni"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Heiti* <i class="fa fa-info-circle" title="Nafn námsgreinar." v-b-tooltip></i></td>
          <td>
            <b-form-group
              label-for="heiti"
              :state="submitted && errors.has('heiti') ? false : ''"
              :invalid-feedback="errors.first('heiti')"
            >
              <b-form-input
                id="heiti"
                name="heiti"
                type="text"
                v-model="form.heiti"
                :state="submitted && errors.has('heiti') ? false : ''"
                maxLength="60"
                v-validate="'required'"
                data-vv-as="heiti"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Heiti (enska)</td>
          <td>
            <b-form-group
              label-for="heitiEn"
              :state="submitted && errors.has('heitiEn') ? false : ''"
              :invalid-feedback="errors.first('heitiEn')"
            >
              <b-form-input
                id="heitiEn"
                name="heitiEn"
                type="text"
                v-model="form.heiti_ens"
                :state="submitted && errors.has('heitiEn') ? false : ''"
                maxLength="60"
                data-vv-as="heitiEn"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>
            Heiti þgf
            <i
              class="fa fa-info-circle"
              title="Þarf ekki að fylla út nema námsgrein komi fram á skírteinum. Þetta er sjaldan notað."
              v-b-tooltip
            ></i>
          </td>
          <td>
            <b-form-group
              label-for="heitiThgf"
              :state="submitted && errors.has('heitiThgf') ? false : ''"
              :invalid-feedback="errors.first('heitiThgf')"
            >
              <b-form-input
                id="heitiThgf"
                name="heitiThgf"
                type="text"
                v-model="form.heiti_thgf"
                :state="submitted && errors.has('heitiThgf') ? false : ''"
                maxLength="60"
                data-vv-as="heitiThgf"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td colspan="2" style="padding-top: 20px !important">
            <i>
              Eftirfarandi atriði eru eingöngu stillingar fyrir tungumál og notað í uppbyggingu brautar ef nemendur mega velja á milli
              mismunandi tungumála.</i
            >
          </td>
        </tr>
        <tr>
          <td>
            Norðurlandamál
            <i
              class="fa fa-info-circle"
              title="Notað til að finna lista yfir norðurlandamál sem skólinn kennir m.a. fyrir umsóknarvef.
              Yfirgrein má ekki vera fyllt út til að það birtist í listum yfir norðurlandamál."
              v-b-tooltip
            ></i>
          </td>
          <td>
            <b-form-group
              label-for="annadMal"
              :state="submitted && errors.has('annadMal') ? false : ''"
              :invalid-feedback="errors.first('annadMal')"
            >
              <b-form-checkbox id="annadMal" name="annadMal" v-model="form.annad_mal"></b-form-checkbox>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>
            Þriðja mál
            <i
              class="fa fa-info-circle"
              title="Notað til að finna lista yfir þriðja mál sem skólinn kennir m.a. fyrir umsóknarvef.
              Yfirgrein má ekki vera fyllt út til að það birtist í listum yfir þriðja mál."
              v-b-tooltip
            ></i>
          </td>
          <td>
            <b-form-group
              label-for="thridjaMal"
              :state="submitted && errors.has('thridjaMal') ? false : ''"
              :invalid-feedback="errors.first('thridjaMal')"
            >
              <b-form-checkbox id="thridjaMal" name="thridjaMal" v-model="form.thridja_mal"></b-form-checkbox>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>
            Fjórða mál
            <i
              class="fa fa-info-circle"
              title="Notað til að finna lista yfir fjórða mál sem skólinn kennir m.a. fyrir umsóknarvef.
              Yfirgrein má ekki vera fyllt út til að það birtist í listum yfir fjórða mál."
              v-b-tooltip
            ></i>
          </td>
          <td>
            <b-form-group
              label-for="fjordaMal"
              :state="submitted && errors.has('fjordaMal') ? false : ''"
              :invalid-feedback="errors.first('fjordaMal')"
            >
              <b-form-checkbox id="fjordaMal" name="fjordaMal" v-model="form.fjorda_mal"></b-form-checkbox>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>
            Yfirgrein
            <i
              class="fa fa-info-circle"
              title="Þeir áfangar sem hafa yfirgrein eru notaðir í uppbyggingu brautar til þess að setja ekki öll tungumálin sem skólinn
              bíður uppá í uppbygginguna. Þá er t.d. stofnaður áfanginn 'Þriðja mál' með yfirgreinina þriðja mál, það er síðan sett
              í brautaruppbyggingu og áfangarnir gerðir jafngildir áföngum í þeim raunverulegu þriðju málum s.s. spænsku og þýsku.
              Síðan þegar áfangar eru lesnir í feril hjá nemanda þá
              er fundið hvaða þriðja mál hann er með valið og fundið hvort áfangi af því tungumáli er jafngildur áfanganum með yfirgrein."
              v-b-tooltip
            ></i>
          </td>
          <td>
            <b-form-group label-for="yfirgrein">
              <v-select id="yfirgrein" name="yfirgrein" v-model="form.mal" :options="languageTypes" label="heiti_mal" :clearable="true">
              </v-select>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>
            Samræmd námsgrein
            <i class="fa fa-info-circle" title="Þarf ekki að fylla út nema sé notað á skírteinum. Þetta er sjaldan notað." v-b-tooltip></i>
          </td>
          <td>
            <b-form-group label-for="samrNamsgrein">
              <v-select id="samrNamsgrein" name="samrNamsgrein" v-model="form.samr_namsgrein" :options="commonSubjects" :clearable="true">
              </v-select>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Virk</td>
          <td>
            <b-form-group label-for="virkur">
              <b-form-checkbox id="virkur" name="virkur" v-model="form.virkur"> </b-form-checkbox>
            </b-form-group>
          </td>
        </tr>
      </table>
      <b-btn variant="primary" class="mr-2" v-if="canEdit" @click="validateBeforeSubmit">
        <span v-if="id">Breyta</span>
        <span v-else>Stofna</span>
      </b-btn>
      <b-btn variant="dark" @click="closeSlidebar">Hætta við</b-btn>
      <b-btn variant="danger" class="float-right" v-if="canEdit && id && !form.has_modules" @click="deleteSubject"> Eyða </b-btn>
    </b-form>
  </Slidebar>
</template>

<script>
import types from '@/api/types';
import curriculum from '@/api/curriculum';
import { mapActions, mapGetters } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';

export default {
  name: 'subject-slidebar',
  props: ['item', 'large', 'extraLarge'],
  components: {
    Slidebar,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_namsgreinar');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      loading: {
        detail: false,
        commonSubjects: false,
        languageTypes: false,
      },
      submitted: false,
      id: null,
      form: {
        kodi: '',
        heiti: '',
        heiti_ens: '',
        heiti_thgf: '',
        annad_mal: false,
        thridja_mal: false,
        fjorda_mal: false,
        mal: '',
        samr_namsgrein: '',
        virkur: true,
        has_modules: true,
      },
      commonSubjects: [],
      languageTypes: [],
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async initialize() {
      this.form = {
        kodi: '',
        heiti: '',
        heiti_ens: '',
        heiti_thgf: '',
        annad_mal: false,
        thridja_mal: false,
        fjorda_mal: false,
        mal: '',
        samr_namsgrein: '',
        virkur: true,
        has_modules: true,
      };

      await this.loadCommonSubjects();
      await this.loadLanguageTypes();
      this.id = null;
      if (this.item && this.item.namsgrein_id) {
        this.id = this.item.namsgrein_id;
        this.loadSubjectDetail();
      }

      this.$nextTick(() => {
        if (this.$refs.focusInput) {
          this.$refs.focusInput.focus();
        }
      });
    },
    async loadSubjectDetail() {
      try {
        this.loading.detail = true;
        const response = await curriculum.getSubjectDetail(this.id);
        if (response.data.items && response.data.items.length > 0) {
          const item = response.data.items[0];
          this.form.kodi = item.kodi;
          this.form.heiti = item.heiti;
          this.form.heiti_ens = item.heiti_ens;
          this.form.heiti_thgf = item.heiti_thgf;
          this.form.annad_mal = item.annad_mal === 1;
          this.form.thridja_mal = item.thridja_mal === 1;
          this.form.fjorda_mal = item.fjorda_mal === 1;
          this.form.virkur = item.virkur === 1;
          this.form.mal = item.mal ? this.languageTypes.find(x => x.teg_mal === item.mal) : '';
          this.form.samr_namsgrein = item.samr_namsgrein ? this.commonSubjects.find(x => x.samr_namsgrein_id === item.samr_namsgrein) : '';
          this.form.has_modules = item.has_modules === 1;
        }
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.detail = false;
      }
    },
    async loadCommonSubjects() {
      try {
        this.loading.commonSubjects = true;
        const response = await types.getCommonSubjects();
        this.commonSubjects = response.data.items.map(x => ({
          ...x,
          label: `${x.kodi} ${x.heiti}`,
        }));
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.commonSubjects = false;
      }
    },
    async loadLanguageTypes() {
      try {
        this.loading.languageTypes = true;
        const response = await types.getCommonLanguageTypes();
        this.languageTypes = response.data.items;
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.languageTypes = false;
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          const data = {
            ...this.form,
            annad_mal: this.form.annad_mal ? 1 : 0,
            thridja_mal: this.form.thridja_mal ? 1 : 0,
            fjorda_mal: this.form.fjorda_mal ? 1 : 0,
            virkur: this.form.virkur ? 1 : 0,
            mal: this.form.mal ? this.form.mal.teg_mal : '',
            samr_namsgrein: this.form.samr_namsgrein ? this.form.samr_namsgrein.samr_namsgrein_id : '',
          };
          this.saving = true;
          if (this.id) {
            await curriculum.updateSubject(this.id, data);
            this.displaySuccess(`${this.form.heiti} hefur verið breytt.`);
          } else {
            await curriculum.createSubject(data);
            this.displaySuccess(`${this.form.heiti} hefur verið stofnuð.`);
          }
          this.$emit('closeAndReloadSlidebar');
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.data && e.response.data.error) {
            this.displayError(e.response.data.error);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.saving = false;
      }
    },
    async deleteSubject() {
      try {
        const conf = confirm(`Ert þú viss um að eyða ${this.item.heiti}?`); // eslint-disable-line
        if (conf) {
          await curriculum.updateSubject(this.id, { delete: 1 });
          this.displaySuccess(`${this.item.heiti} hefur verið eytt.`);
          this.$emit('closeAndReloadSlidebar');
        }
      } catch (e) {
        // Aðgerð mistókst.
        this.$log.debug(e);
        if (e.response && e.response.data && e.response.data.error) {
          this.displayError(e.response.data.error);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    item() {
      this.initialize();
    },
  },
  created() {
    this.initialize();
  },
};
</script>

<style lang="scss"></style>
