<template>
  <div>
    <h5>Afrita einkunnareglur</h5>
    <b-row>
      <b-col cols="6" class="pl-0">
        <b-form-group>
          <label for="term">Afrita frá</label>
          <v-select id="term" name="term" v-model="form.term" :clearable="false" :options="terms"> </v-select>
        </b-form-group>
        <b-form-group for="overwrite" class="mt-2" v-if="!isCreating">
          <label for="overwrite" class="mb-0"> Yfirskrifa fyrri reglur </label>
          <b-form-checkbox v-model="form.overwrite" id="overwrite" name="overwrite"></b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
    <div class="alert alert-info p-2 mt-3" v-if="modulesExists">
      <i class="fa fa-info-circle d-inline-block pr-1 pt-1" style="vertical-align: top"></i>
      <div class="d-inline-block">
        <div>Hér er hægt að velja að afrita einkunnareglur frá völdu kennslutímabili.</div>
        <div>
          Ef áfangi var ekki í boði á kennslutímabilinu sem afritað er frá eða innihélt ekki einkunnareglu afritast ekkert fyrir þann
          áfanga.
        </div>
        <div v-if="isCreating">
          Ef ekki á að afrita einkunnareglu milli kennslutímabila er hægt að smella á "Sleppa afritun einkunnareglna" og fara í næsta skref.
        </div>
        <div>Ath. þegar einkunnareglur eru afritaðar þá er vinnslan sett í runuvinnslu og þú færð póst að henni lokinni.</div>
      </div>
    </div>
    <div class="alert alert-danger p-2" v-else>
      <i class="fa fa-exclamation-triangle"></i>
      Ath. ekki er búið að tengja neina áfanga við kennslutímabilið. Vinsamlegast tengið áfanga við kennslutímabilið til að hægt sé að
      afrita einkunnareglur.
    </div>
    <b-btn
      variant="primary"
      style="margin-top: 10px"
      class="float-right ml-2"
      @click="copy()"
      :disabled="!form.term || submitting || !modulesExists"
    >
      <i class="fa fa-spin fa-spinner" v-if="submitting"></i>
      Afrita
    </b-btn>
  </div>
</template>

<script>
import terms from '@/api/terms';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'term-copy-graderule',
  props: ['id', 'divisionId', 'name', 'copyTerm', 'reload', 'isCreating'],
  computed: {
    ...mapGetters(['loggedInUser']),
  },
  data() {
    return {
      form: {
        overwrite: false,
        term: '',
      },
      terms: [],
      loading: false,
      submitting: false,
      modulesExists: true,
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async checkModulesExists() {
      try {
        const response = await terms.getTermModules(this.id);
        this.modulesExists = response.data.items.length > 0;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadTerms() {
      try {
        this.loading = true;
        this.firsttime = true;
        const params = {
          only_active: 0,
          division_id: this.divisionId,
        };
        const response = await terms.getTerms(params);
        this.terms = response.data.items
          .filter(x => x.onn_id !== parseInt(this.id, 10))
          .map(x => ({
            ...x,
            label: `${x.undirsk_audk} - ${x.onn_heiti}`,
          }));

        if (this.copyTerm) {
          this.form.term = this.terms.find(x => x.onn_id === parseInt(this.copyTerm, 10));
        } else {
          this.form.term = this.terms.find(x => x.is_active === 1);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async copy() {
      try {
        await terms.copyTermGraderule(this.id, this.form.term.onn_id, {
          overwrite: this.form.overwrite ? 1 : 0,
        });
        this.submitting = false;
        this.displaySuccess('Vinnslan hefur verið sett í runuvinnslur.');
        this.$emit('finishCopy');
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.submitting = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    reload() {
      this.checkModulesExists();
    },
  },
  created() {
    this.loadTerms();
    this.checkModulesExists();
  },
};
</script>

<style lang="scss"></style>
