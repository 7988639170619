<template>
  <Slidebar @closeMe="closeSlidebar" :extraLarge="true">
    <h4>Uppbygging</h4>
    <StudyRecordCourse :studentId="studentId" :courseId="courseId" :item="course" :slidebar="false"></StudyRecordCourse>
  </Slidebar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';
import StudyRecordCourse from '@/components/students/studyRecords/courses/StudyRecordCourse.vue';

export default {
  name: 'study-record-course-slidebar',
  props: ['studentId', 'courseId', 'course'],
  components: {
    Slidebar,
    StudyRecordCourse,
  },
  computed: {
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {};
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>

<style lang="scss"></style>
