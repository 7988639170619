import Vue from 'vue';

import store from 'store';

const API_URL = '/api/auth';

const LOGIN_TOKEN = 'id_token';
const LOGIN_TOKEN_EXPIRE = 'id_token_exp';

const redirectUrl = process.env.VUE_APP_LOGIN_REDIRECT_URL || 'https://inna.is/innskraning?c=http://admin.inna.is/innskraning&s=2';

export default {
  checkToken(data) {
    return Vue.http
      .post(`${API_URL}/token`, data, { skipAuthorization: true })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  getUserAccess() {
    return Vue.http
      .get(`${API_URL}/access`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  me() {
    return Vue.http
      .get(`${API_URL}/me`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  getUrlToChangeSystem(loginType, userId, url) {
    return Vue.http
      .post(`${API_URL}/system`, {
        userId,
        loginType,
        url,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  refreshToken() {
    return Vue.http
      .post(`${API_URL}/refresh`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  setTokenExpire(timeleft) {
    const d = new Date();
    d.setTime(d.getTime() + parseInt(timeleft, 10) * 1000);
    store.set(LOGIN_TOKEN_EXPIRE, d);
  },

  loginSuccess(token, name, ssn, logo, timeleft) {
    store.set(LOGIN_TOKEN, token);
    store.set('name', name);
    store.set('ssn', ssn);
    store.set('logo', logo);
    this.setTokenExpire(timeleft);
  },

  isTokenExpired() {
    const expireDate = this.getTokenExpire();
    return new Date().getTime() > new Date(expireDate);
  },

  removeToken() {
    store.remove(LOGIN_TOKEN);
    store.remove(LOGIN_TOKEN_EXPIRE);
    store.remove('name');
    store.remove('ssn');
    store.remove('logo');
    store.remove('me');
  },

  logout() {
    this.removeToken();
    window.location.href = redirectUrl;
  },

  getToken() {
    return store.get(LOGIN_TOKEN);
  },
  getTokenExpire() {
    return store.get(LOGIN_TOKEN_EXPIRE);
  },
  getName() {
    return store.get('name');
  },
  getSsn() {
    return store.get('ssn');
  },
  getLogo() {
    return store.get('logo');
  },
};
