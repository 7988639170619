<template>
  <Slidebar :autoClose="false" @closeMe="closeSlidebar" :extraLarge="true">
    <h4>Bæta við áföngum</h4>
    <div class="pb-2">
      Til að tengja áfanga við brautina þarf að vera til brautaruppbygging, ef hún er ekki til þá er hægt að smella hér til að búa til
      brautaruppbygginguna
      <i @click="openStructureSlidebar()" class="cursor-pointer fa fa-plus-circle text-primary" title="Bæta við uppbyggingu"></i>.
    </div>
    <b-form-group
      label="Uppbygging til að bæta áföngum við*"
      label-for="module"
      style="border-bottom: 2px solid #ddd; padding-bottom: 25px; margin-bottom: 15px"
    >
      <v-select id="structure" name="structure" v-model="structure" :options="structures" :clearable="false" label="uppbygging_heiti">
      </v-select>
    </b-form-group>
    <div v-if="selectedModules.length > 0" style="font-size: 19px">Valdir áfangar</div>
    <table class="table less-padding" style="font-size: 12px" v-if="selectedModules.length > 0">
      <tr>
        <th></th>
        <th>Áfangi ({{ selectedModules.length }}</th>
        <th>Heiti áfanga</th>
        <th>Einingar</th>
        <th>Þrep</th>
        <th>Deild</th>
        <th>Kennslutímabil</th>
        <th>Forgangur</th>
      </tr>
      <tr v-for="(m, idx) in selectedModules" :key="m.afangi_id">
        <td><b-form-checkbox :checked="true" @input="removeModule(idx)"></b-form-checkbox></td>
        <td>{{ m.namsgrein_afanganr }}</td>
        <td>{{ m.afanga_heiti }}</td>
        <td>{{ m.einingar }}</td>
        <td>{{ m.trep }}</td>
        <td>{{ m.deild }}</td>
        <td><b-form-input type="number" v-model="m.onn_nr" style="height: 25px; width: 60px; font-size: 12px"></b-form-input></td>
        <td><b-form-input type="number" v-model="m.forgangsrodun" style="height: 25px; width: 60px; font-size: 12px"></b-form-input></td>
      </tr>
    </table>
    <b-btn
      variant="primary"
      size="sm"
      v-if="selectedModules.length > 0"
      :disabled="selectedModules.length === 0 || !structure"
      @click="saveModules()"
      >Bæta við {{ selectedModules.length }} áföngum</b-btn
    >

    <div style="font-size: 19px" class="mt-3">Áfangaleit</div>
    <div v-if="!structure" class="pb-2">
      <i> <i class="fa fa-exclamation-triangle"></i> Ath. það verður að velja uppbyggingu til að hægt sé að leita eftir áföngum. </i>
    </div>
    <b-row>
      <b-col class="pl-0">
        <b-form-group label="Áfangi" label-for="module">
          <b-form-input
            id="module"
            name="module"
            v-model="search.search"
            style="height: 30px"
            v-on:keyup.enter="searchModules"
            :disabled="!structure"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Deild" label-for="department">
          <v-select
            id="department"
            name="department"
            v-model="search.department"
            :options="departments"
            :clearable="true"
            :disabled="!structure"
          >
          </v-select>
        </b-form-group>
      </b-col>
      <b-col cols="1">
        <b-form-group label="Einingar" label-for="units" class="d-inline-block">
          <b-form-input
            id="units"
            name="units"
            v-model="search.units"
            style="height: 30px; width: 60px"
            v-on:keyup.enter="searchModules"
            :disabled="!structure"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="1">
        <b-form-group label="Þrep" label-for="steps" class="d-inline-block">
          <b-form-input
            id="steps"
            name="steps"
            v-model="search.steps"
            style="height: 30px; width: 60px"
            v-on:keyup.enter="searchModules"
            :disabled="!structure"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-btn
          variant="primary"
          size="sm"
          style="width: 100%; position: absolute; bottom: 10px"
          :disabled="!divisionId"
          @click="searchModules()"
          >Leita</b-btn
        >
      </b-col>
    </b-row>
    <Loader v-if="loading"></Loader>
    <table class="table less-padding" style="font-size: 12px" v-if="modules.length > 0">
      <tr>
        <th></th>
        <th>Áfangi ({{ getModules.length }}/{{ modules.length }})</th>
        <th>Heiti áfanga</th>
        <th>Einingar</th>
        <th>Þrep</th>
        <th>Deild</th>
      </tr>
      <tr v-for="m in getModules" :key="m.afangi_id">
        <td><b-form-checkbox :checked="false" @input="selectModule(m)"></b-form-checkbox></td>
        <td>{{ m.namsgrein_afanganr }}</td>
        <td>{{ m.afanga_heiti }}</td>
        <td>{{ m.einingar }}</td>
        <td>{{ m.trep }}</td>
        <td>{{ m.deild }}</td>
      </tr>
    </table>

    <course-structure-slidebar
      :courseId="courseId"
      v-if="showStructureSlidebar"
      @closeSlidebar="closeStructureSlidebar"
      @closeAndReloadSlidebar="closeStructureSlidebar"
    >
    </course-structure-slidebar>
  </Slidebar>
</template>

<script>
import curriculum from '@/api/curriculum';
import structure from '@/api/structure';
import { mapGetters, mapActions } from 'vuex';
import Slidebar from '@/components/common/Slidebar.vue';
import Loader from '@/components/common/Loader.vue';
import CourseStructureSlidebar from '@/components/curriculum/courses/CourseStructureSlidebar.vue';

export default {
  name: 'courses-structure-module-slidebar',
  components: {
    Slidebar,
    Loader,
    CourseStructureSlidebar,
  },
  props: ['courseId', 'structureId'],
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_brautir');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('namskra_brautir');
    },
    /* selectedModules() {
      return this.getModules.filter(x => x.selected);
    }, */
    getModules() {
      return this.modules.filter(x => this.selectedModules.map(y => y.afangi_id).indexOf(x.afangi_id) === -1);
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      loading: false,
      divisionId: '',
      structure: '',
      search: {
        search: '',
        department: '',
        units: '',
        steps: '',
      },
      departments: [],
      modules: [],
      structures: [],
      selectedModules: [],
      showStructureSlidebar: false,
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    openStructureSlidebar() {
      this.showStructureSlidebar = true;
    },
    closeStructureSlidebar() {
      this.showStructureSlidebar = false;
      this.loadStructure();
    },
    selectModule(item) {
      this.selectedModules.push(item);
    },
    removeModule(index) {
      this.selectedModules.splice(index, 1);
    },
    async loadStructure() {
      try {
        const response = await curriculum.getCourseStructureModules(this.courseId);
        this.structures = response.data.items;
        if (this.structureId) {
          this.structure = this.structures.find(x => x.braut_uppbygg_id === this.structureId);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async loadCourse() {
      try {
        this.loading = true;
        const response = await curriculum.getCourseById(this.courseId);
        const item = response.data.items[0];
        this.divisionId = item.undirskoli_id;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadDepartments() {
      try {
        const response = await structure.getDepartmentList();
        this.departments = response.data.items.map(x => ({
          ...x,
          label: `${x.name}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async searchModules() {
      if (this.divisionId) {
        try {
          this.loading = true;
          this.modules = [];
          const search = {
            search: this.search.search,
            department: this.search.department ? this.search.department.department_id : '',
            division: this.divisionId,
            active: 1,
            steps: this.search.steps,
            units: this.search.units,
            showDepartment: 1,
          };
          const response = await curriculum.getModuleList(search);
          this.modules = response.data.items.map(x => ({
            ...x,
            // deildir: x.deild ? x.deild.split(',') : [],
            selected: false,
            onn_nr: '',
            forgangur: '',
          }));
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading = false;
        }
      }
    },
    async saveModules() {
      try {
        const items = this.selectedModules.map(x => ({ afangi_id: x.afangi_id, onn_nr: x.onn_nr, forgangsrodun: x.forgangsrodun }));

        await curriculum.addCourseStructureModules(this.structure.braut_uppbygg_id, items);
        this.displaySuccess('Áföngum bætt við uppbyggingu');

        this.$emit('closeAndReloadSlidebar');
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);

        if (e.response && e.response.data && e.response.data.error) {
          this.displayError(e.response.data.error);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadStructure();
    this.loadCourse();
    this.loadDepartments();
  },
};
</script>

<style lang="scss"></style>
