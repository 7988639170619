<template>
  <div>
    <div class="mt-2 mb-2">
      Ef sett hefur verið inn í brautaruppbygginguna á hvaða kennslutímabili á að taka áfangana þá er hægt að sjá hér hvernig þetta raðast
      niður eftir tímabilum.
    </div>
    <b-row>
      <b-col class="pl-0">
        <b-form-group>
          <label class="d-inline-block mr-3">Lita eftir</label>
          <b-form-radio v-model="displayTypes" class="d-inline-block mr-2" name="radio-inline" :value="true">Tegund</b-form-radio>
          <b-form-radio v-model="displayTypes" class="d-inline-block" name="radio-inline" :value="false">Uppbyggingu</b-form-radio>
        </b-form-group>
        <div v-if="displayTypes">
          <div
            class="d-inline-block mr-4"
            v-for="(t, idx) in types"
            :key="`type-${idx}`"
            @mouseenter="hoverName = t.name"
            @mouseleave="hoverName = ''"
          >
            <div class="d-inline-block" style="width: 10px; height: 10px" :style="`background-color:${t.border}`"></div>
            {{ t.name }}
          </div>
        </div>
        <div v-else>
          <div
            class="d-inline-block mr-4"
            v-for="(t, idx) in structureList"
            :key="`type-${idx}`"
            @mouseenter="hoverName = t.name"
            @mouseleave="hoverName = ''"
          >
            <div class="d-inline-block" style="width: 10px; height: 10px" :style="`background-color:${t.border}`"></div>
            {{ t.name }}
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col cols="2" v-for="(term, idx) in modulesByTerms" :key="idx" class="pl-0 mb-3"
        ><span v-if="!term.onn_nr">Ekkert kennslutímabil</span>
        <span v-else>{{ term.onn_nr }}. kennslutímabil</span>
        <div style="font-size: 13px">
          Ein: <span class="mr-2">{{ term.einingar }}</span> Tímar: <span class="mr-2">{{ term.timar_viku }}</span> Fjöldi:
          {{ term.afangar.length }}
        </div>
        <div
          v-for="(mod, idx2) in term.afangar"
          :key="idx2"
          :style="`border-color:${mod.border}; background-color:${mod.background}`"
          class="termModuleBox"
          v-b-popover.hover.top="
            `Einingar: ${mod.einingar} \n Þrep: ${mod.trep} \n Tegund: ${mod.tegund_heiti}
            Uppbygging: ${mod.uppbygging_heiti} \n Kennslustundir á viku: ${mod.timar_viku_nemar}`
          "
        >
          <div style="font-size: 10px">{{ mod.afanga_heiti }}</div>
          {{ mod.namsgrein_afanganr }}
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import curriculum from '@/api/curriculum';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'course-terms',
  props: ['courseId'],
  components: {},
  computed: {
    structureList() {
      return [...new Set(this.list.map(x => x.uppbygging_heiti))]
        .sort((a, b) => {
          return (a || 1000) - (b || 1000);
        })
        .map((x, idx) => ({
          name: x,
          border: this.colors.length > idx ? this.colors[idx].border : '',
          background: this.colors.length > idx ? this.colors[idx].background : '',
        }));
    },
    modulesByTerms() {
      return [...new Set(this.list.map(x => x.onn_nr))]
        .sort((a, b) => {
          return (a || 1000) - (b || 1000);
        })
        .map(x => ({
          onn_nr: x,
          afangar: this.list
            .filter(y => y.onn_nr === x)
            .map(y => ({
              ...y,
              border: this.getBorder(y.tegund_heiti, y.uppbygging_heiti),
              background: this.getBackground(y.tegund_heiti, y.uppbygging_heiti),
            })),
          einingar: this.list
            .filter(y => y.onn_nr === x)
            .map(y => y.einingar)
            .reduce((a, b) => {
              return a + b;
            }, 0),
          timar_viku: this.list
            .filter(y => y.onn_nr === x)
            .map(y => y.timar_viku_nemar)
            .reduce((a, b) => {
              return a + b;
            }, 0),
        }));
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('namskra_brautir');
    },
    canRead() {
      return this.loggedInUserHasReadPermission('namskra_brautir');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      loading: false,
      list: [],
      showModuleSlidebar: false,
      selectedItem: '',
      displayTypes: true,
      hoverName: '',
      types: [
        { name: 'Kjarni', border: 'rgb(152, 19, 156)', background: 'rgba(152, 19, 156, 0.05)' },
        { name: 'Val', border: 'rgb(104, 221, 149)', background: 'rgba(104, 221, 149, 0.05)' },
        { name: 'Bundið pakkaval', border: 'rgb(245, 199, 92)', background: 'rgba(245, 199, 92, 0.05)' },
        { name: 'Bundið áfangaval', border: 'rgb(42, 186, 217)', background: 'rgba(42, 186, 217, 0.05)' },
      ],
      colors: [
        { border: 'rgb(90, 135, 112)', background: 'rgba(90, 135, 112, 0.05)' },
        { border: 'rgb(111, 169, 171)', background: 'rgba(111, 169, 171, 0.05)' },
        { border: 'rgb(245, 175, 41)', background: 'rgba(245, 175, 41, 0.05)' },
        { border: 'rgb(0, 136, 185)', background: 'rgba(0, 136, 185, 0.05)' },
        { border: 'rgb(241, 134, 54)', background: 'rgba(241, 134, 54, 0.05)' },
        { border: 'rgb(217, 58, 55)', background: 'rgba(217, 58, 55, 0.05)' },
        { border: 'rgb(166, 177, 46)', background: 'rgba(166, 177, 46, 0.05)' },
        { border: 'rgb(92, 155, 188)', background: 'rgba(92, 155, 188, 0.05)' },
        { border: 'rgb(245, 136, 141)', background: 'rgba(245, 136, 141, 0.05)' },
        { border: 'rgb(154, 137, 181)', background: 'rgba(154, 137, 181, 0.05)' },
        { border: 'rgb(64, 120, 135)', background: 'rgba(64, 120, 135, 0.05)' },
        { border: 'rgb(211, 63, 51)', background: 'rgba(211, 63, 51, 0.05)' },
        { border: 'rgb(162, 176, 31)', background: 'rgba(162, 176, 31, 0.05)' },
        { border: 'rgb(240, 177, 38)', background: 'rgba(240, 177, 38, 0.05)' },
        { border: 'rgb(0, 135, 191)', background: 'rgba(0, 135, 191, 0.05)' },
        { border: 'rgb(178, 183, 187)', background: 'rgba(178, 183, 187, 0.05)' },
        { border: 'rgb(114, 172, 174)', background: 'rgba(114, 172, 174, 0.05)' },
        { border: 'rgb(156, 138, 180)', background: 'rgba(156, 138, 180, 0.05)' },
        { border: 'rgb(238, 180, 36)', background: 'rgba(238, 180, 36, 0.05)' },
      ],
    };
  },
  methods: {
    getBorder(type, structure) {
      if ((this.displayTypes && this.hoverName === type) || (!this.displayTypes && this.hoverName === structure)) {
        return 'rgb(255,0,0)';
      }
      if (this.displayTypes && this.types.find(x => x.name === type)) {
        return this.types.find(x => x.name === type).border;
      }
      if (!this.displayTypes && this.structureList.findIndex(x => x.name === structure) > -1) {
        return this.structureList.find(x => x.name === structure).border;
      }
      return '';
    },
    getBackground(type, structure) {
      if ((this.displayTypes && this.hoverName === type) || (!this.displayTypes && this.hoverName === structure)) {
        return 'rgba(255,0,0, 0.5)';
      }
      if (this.displayTypes && this.types.find(x => x.name === type)) {
        return this.types.find(x => x.name === type).background;
      }
      if (!this.displayTypes && this.structureList.findIndex(x => x.name === structure) > -1) {
        return this.structureList.find(x => x.name === structure).background;
      }
      return '';
    },
    async load() {
      try {
        const response = await curriculum.getCourseModules(this.courseId);
        this.list = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.load();
  },
};
</script>
<style lang="scss" scoped>
@import '../../../style/variables';
.termModuleBox {
  padding: 5px;
  margin-right: 10px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  font-size: 13px;
  &.borderKjarni {
    border-color: rgb(152, 19, 156); // $primary;
    background-color: rgba(152, 19, 156, 0.05); // $primary;
  }
  // &.borderKjorsvid {
  // }
  &.borderVal {
    border-color: rgb(104, 221, 149); // $success;
    background-color: rgba(104, 221, 149, 0.05); // $success;
  }
  // &.borderFornam {
  // }
  &.borderPakkaval {
    border-color: rgb(245, 199, 92); // $warning;
    background-color: rgba(245, 199, 92, 0.05); // $warning;
  }
  &.borderAfangaval {
    border-color: rgb(42, 186, 217); // $secondary;
    background-color: rgba(42, 186, 217, 0.05); // $secondary;
  }
}
</style>
