<template>
  <div>
    <b-row>
      <b-col>
        <Departments v-if="tabIndex === 'department'" />
        <Buildings v-if="tabIndex === 'building'" />
        <Divisions v-if="tabIndex === 'division'" />
        <Equipments v-if="tabIndex === 'equipment'" />
      </b-col>
      <b-col cols="2">
        <div class="right-side-nav noprint">
          <b-nav vertical>
            <b-nav-item @click.prevent="changeTab('equipment')" :active="tabIndex === 'equipment'">Búnaður</b-nav-item>
            <b-nav-item @click.prevent="changeTab('building')" :active="tabIndex === 'building'">Byggingar og stofur</b-nav-item>
            <b-nav-item @click.prevent="changeTab('department')" :active="tabIndex === 'department'">Deildir</b-nav-item>
            <b-nav-item @click.prevent="changeTab('division')" :active="tabIndex === 'division'">Undirskóli</b-nav-item>
          </b-nav>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import Divisions from '@/components/school/school/Divisions.vue';
import Departments from '@/components/school/school/Departments.vue';
import Buildings from '@/components/school/school/buildingsclassrooms/Buildings.vue';
import Equipments from '@/components/school/school/Equipments.vue';

export default {
  name: 'structure',
  components: {
    Divisions,
    Departments,
    Buildings,
    Equipments,
  },
  data() {
    return {
      tabIndex: 'department',
      page: 'types',
    };
  },
  methods: {
    toggle(item) {
      this.open[item] = !this.open[item];
    },
    changeTab(tab) {
      this.tabIndex = tab;
      this.$router.push({ name: 'School', query: { page: this.page, tab: this.tabIndex } });
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  beforeRouteUpdate(to, from, next) {
    if (to && to.query && to.query.page) {
      this.page = to.query.page;
    }
    if (to && to.query && to.query.tab) {
      this.tabIndex = to.query.tab;
    }
    next();
  },
  created() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
    if (this.$route.query.tab) {
      this.tabIndex = this.$route.query.tab;
    }
  },
};
</script>

<style lang="scss"></style>
