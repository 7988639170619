<template>
  <Slidebar @closeMe="closeSlidebar" :large="!showAll" :extraLarge="showAll">
    <h4>Námsferill nemanda</h4>
    <StudyRecordTerms :studentId="studentId" :termId="getTerm" :slidebar="true"></StudyRecordTerms>
    <div class="mt-5">
      <b-link @click="toggleShowAll()">
        <span v-if="showAll">Skoða eitt kennslutímabil</span>
        <span v-else>Skoða allan námsferilinn</span>
      </b-link>
    </div>
  </Slidebar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';

import StudyRecordTerms from '@/components/students/studyRecords/terms/StudyRecordTerms.vue';

export default {
  name: 'student-study-record-term-slidebar',
  props: ['termId', 'studentId'],
  components: {
    Slidebar,
    StudyRecordTerms,
  },
  computed: {
    getTerm() {
      return !this.showAll ? this.termId : '';
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      showAll: false,
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    toggleShowAll() {
      this.showAll = !this.showAll;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>

<style lang="scss"></style>
