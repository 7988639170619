<template>
  <Slidebar @closeMe="closeSlidebar" :large="large" :extraLarge="extraLarge">
    <h4 v-if="id">{{ item.heiti }}</h4>
    <h4 v-else>Stofna tegund sérúrræða</h4>
    <br />
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <table class="table less-padding">
        <tr>
          <td>Heiti*</td>
          <td>
            <b-form-group
              label-for="heiti"
              :state="submitted && errors.has('heiti') ? false : ''"
              :invalid-feedback="errors.first('heiti')"
            >
              <b-form-input
                id="heiti"
                name="heiti"
                type="text"
                v-model="form.heiti"
                :state="submitted && errors.has('heiti') ? false : ''"
                maxLength="200"
                v-validate="'required'"
                ref="focusInput"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Tegund</td>
          <td>
            <b-form-group label-for="type" :state="submitted && errors.has('type') ? false : ''" :feedback="errors.first('type')">
              <v-select
                id="type"
                name="type"
                v-model="form.tegund"
                :clearable="false"
                :state="submitted && errors.has('type') ? false : ''"
                :options="types"
                data-vv-as="tegund"
                v-validate="'required'"
                :disabled="id !== ''"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <td>Röð</td>
          <td>
            <b-form-group label-for="rod" :state="submitted && errors.has('rod') ? false : ''" :invalid-feedback="errors.first('rod')">
              <b-form-input
                id="rod"
                name="rod"
                type="number"
                v-model="form.rod"
                :state="submitted && errors.has('rod') ? false : ''"
              ></b-form-input>
            </b-form-group>
          </td>
        </tr>
        <tr v-if="id">
          <td>Virk</td>
          <td>
            <b-form-group label-for="active">
              <b-form-checkbox id="active" name="active" v-model="form.virkt"></b-form-checkbox>
            </b-form-group>
          </td>
        </tr>
      </table>
      <b-btn variant="primary" class="mr-2" v-if="canEdit" @click="validateBeforeSubmit">
        <span v-if="id">Breyta</span>
        <span v-else>Stofna</span>
      </b-btn>
      <b-btn variant="dark" @click="closeSlidebar">Hætta við</b-btn>
      <b-btn variant="danger" class="float-right" v-if="canEdit && id" @click="deleteSpecialResource"> Eyða </b-btn>
    </b-form>
  </Slidebar>
</template>

<script>
import types from '@/api/types';
import { mapActions, mapGetters } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';

export default {
  name: 'special-resource-type-slidebar',
  props: ['item', 'large', 'extraLarge'],
  components: {
    Slidebar,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      loading: false,
      submitted: false,
      id: null,
      form: {
        heiti: '',
        tegund: { id: 0, label: 'Já/Nei' },
        virk: true,
        rod: '',
      },
      types: [
        { id: 0, label: 'Já/Nei' },
        { id: 1, label: 'Texti' },
      ],
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async loadSpecialResourceDetail() {
      try {
        this.loading = true;
        const response = await types.getSpecialResourceDetail(this.id);
        const item = response.data.items && response.data.items.length > 0 ? response.data.items[0] : null;
        if (item) {
          this.form.heiti = item.heiti;
          this.form.tegund = this.types.find(x => x.id === item.tegund);
          this.form.rod = item.rod;
          this.form.virkt = item.virkt === 1;
        }
      } catch (e) {
        // Aðgerð mistókst.
        this.failed = true;
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async deleteSpecialResource() {
      try {
        const conf = confirm(`Ert þú viss um að eyða ${this.item.heiti}?`); // eslint-disable-line
        if (conf) {
          await types.updateSpecialResource(this.id, { delete: 1 });
          this.displaySuccess(`${this.item.heiti} hefur verið eytt.`);
          this.$emit('closeAndReloadSlidebar');
        }
      } catch (e) {
        // Aðgerð mistókst.
        this.$log.debug(e);
        if (e.response && e.response.data && e.response.data.error) {
          this.displayError(e.response.data.error);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.saving = true;
          const data = {
            heiti: this.form.heiti,
            tegund: this.form.tegund.id,
            rod: this.form.rod,
            virkt: this.form.virkt ? 1 : 0,
          };
          if (this.id) {
            await types.updateSpecialResource(this.id, data);
            this.displaySuccess(`${this.form.heiti} hefur verið breytt.`);
          } else {
            await types.createSpecialResource(data);
            this.displaySuccess(`${this.form.heiti} hefur verið stofnuð.`);
          }
          this.$emit('closeAndReloadSlidebar');
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.saving = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    item() {
      this.form.heiti = '';
      this.form.tegund = { id: 0, label: 'Já/Nei' };
      this.form.rod = '';
      this.form.virk = true;
      if (this.item && this.item.teg_serurraedi_id) {
        this.id = this.item.teg_serurraedi_id;
        this.loadSpecialResourceDetail();
      }
      this.$nextTick(() => {
        if (this.$refs.focusInput) {
          this.$refs.focusInput.focus();
        }
      });
    },
  },
  created() {
    this.form.heiti = '';
    this.form.tegund = { id: 0, label: 'Já/Nei' };
    this.form.rod = '';
    this.form.virk = true;
    this.id = null;
    if (this.item && this.item.teg_serurraedi_id) {
      this.id = this.item.teg_serurraedi_id;
      this.loadSpecialResourceDetail();
    }
    this.$nextTick(() => {
      if (this.$refs.focusInput) {
        this.$refs.focusInput.focus();
      }
    });
  },
};
</script>

<style lang="scss"></style>
