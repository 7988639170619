<template>
  <Slidebar :autoClose="true" @closeMe="closeSlidebar" :extraLarge="true">
    <h4>Umsókn</h4>
    <Application :applicationSchoolId="applicationSchoolId" :slidebar="true" />
  </Slidebar>
</template>

<script>
import { mapActions } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';
import Application from '@/components/applications/application/Application.vue';

export default {
  name: 'application-slidebar',
  props: ['applicationSchoolId'],
  components: {
    Slidebar,
    Application,
  },
  data() {
    return {};
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>

<style lang="scss"></style>
