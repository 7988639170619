<template>
  <Slidebar @closeMe="closeSlidebar">
    <h4>{{ this.title }}</h4>
    <div class="p-2 mb-3" style="border: 2px solid #ddd">
      <table class="table no-border mb-0">
        <tr>
          <th>Kennitala</th>
          <td>{{ user.kennitala }}</td>
        </tr>
        <tr>
          <th>Nafn</th>
          <td>{{ user.nafn }}</td>
        </tr>
        <tr>
          <th>Dags. lykilorðs</th>
          <td>{{ user.dags_lykilords }}</td>
        </tr>
        <tr>
          <th>Lykilorð úr gildi</th>
          <td>{{ user.lykilord_ur_gildi }}</td>
        </tr>
        <tr>
          <th>Fjöldi rangra tilrauna</th>
          <td>{{ user.fj_tilrauna }}</td>
        </tr>
      </table>
    </div>
    <b-form-group
      label="Lykilorð"
      label-for="pw1"
      :state="submitted && errors.has('pw1') ? false : ''"
      :invalid-feedback="errors.first('pw1')"
    >
      <b-form-input id="pw1" name="pw1" type="password" v-model="form.pw1" v-validate="'required'" data-vv-as="lykilorð"> </b-form-input>
    </b-form-group>
    <b-form-group
      label="Staðfesta lykilorð*"
      label-for="pw2"
      :state="submitted && errors.has('pw2') ? false : ''"
      :invalid-feedback="errors.first('pw2')"
    >
      <b-form-input
        id="pw2"
        name="pw2"
        type="password"
        v-model="form.pw2"
        v-validate="'required'"
        data-vv-as="lykilorð"
        v-on:keyup.enter="updatePassword()"
      >
      </b-form-input>
    </b-form-group>
    <div v-if="submitted && form.pw1 !== form.pw2" class="text-danger">
      <i class="fa fa-exclamation-triangle"></i> Lykilorðin verða að vera eins!
    </div>
    <div v-if="submitted && (form.pw1.length < 8 || !/\d/.test(this.form.pw1))" class="text-danger">
      <i class="fa fa-exclamation-triangle"></i> Lykilorð má vera 8-20 stafir og þarf að innihalda bæði bókstafi og tölustafi.
    </div>
    <b-btn variant="primary" size="sm" class="mt-3" @click="updatePassword()">
      {{ this.title }}<i class="fa fa-spin fa-spinner" v-if="saving"></i>
    </b-btn>
  </Slidebar>
</template>

<script>
import users from '@/api/users';
import { mapActions, mapGetters } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';

export default {
  name: 'update-password-slidebar',
  props: ['userId', 'openAccess'],
  components: {
    Slidebar,
  },
  computed: {
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      loading: false,
      saving: false,
      submitted: false,
      user: '',
      form: {
        pw1: '',
        pw2: '',
      },
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async loadUser() {
      try {
        this.loading = true;
        const response = await users.getUserInfo(this.userId);
        this.user = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async updatePassword() {
      try {
        this.submitted = true;
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response || this.form.pw1 !== this.form.pw2 || this.form.pw1.length < 8 || !/\d/.test(this.form.pw1)) {
            throw 'FormValidationError'; // eslint-disable-line no-throw-literal
          }
        });
        try {
          const conf = confirm(`${this.confirmMessage}fyrir ${this.user.nafn} kt.${this.user.kennitala}?`); // eslint-disable-line
          if (conf) {
            this.saving = true;
            if (this.openAccess) {
              await users.openUserPasswordAccess(this.userId, { pw1: this.form.pw1, pw2: this.form.pw2 });
            } else {
              await users.updateUserPassword(this.userId, { pw1: this.form.pw1, pw2: this.form.pw2 });
            }
            this.displaySuccess(this.successMessage);
            this.closeSlidebar();
          }
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.saving = false;
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.saving = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadUser();
    this.title = this.openAccess ? 'Opna lykilorðsaðgang' : 'Uppfæra lykilorð';
    this.successMessage = `Lykilorð uppfært ${this.openAccess ? 'og aðgangur opnaður í sjö daga' : ''}`;
    this.confirmMessage = `Ertu viss um að þú viljir uppfæra lykilorð ${this.openAccess ? 'og opna lykilorðsaðgang ' : ''}`;
  },
};
</script>

<style lang="scss"></style>
