<template>
  <div>
    <b-row class="breadcumb-location" v-if="!slidebar">
      <b-col>
        <b-btn variant="primary" :to="{ name: 'CreateTerm' }" style="height: 35px" v-if="canEdit">
          <i class="fa fa-plus"></i> Stofna kennslutímabil</b-btn
        >
      </b-col>
    </b-row>
    <table class="table" style="table-layout: fixed; font-size: 14px">
      <thead>
        <tr class="table-detail-search">
          <td :colspan="slidebar ? 1 : 2" :class="{ 'pb-0': detailedSearch }">
            <b-form-input
              v-model="search.term"
              class="search"
              v-on:keyup.enter="loadList"
              @change="loadList"
              placeholder="Einkenni kennslutímabils"
              ref="focusInput"
            />
          </td>
          <td :colspan="slidebar ? 1 : 2" :class="{ 'pb-0': detailedSearch }">
            <v-select
              id="division"
              name="division"
              class="v-select-form-control"
              placeholder="Veldu undirskóla"
              v-model="search.division"
              :options="divisions"
              @input="loadList"
            >
            </v-select>
          </td>
          <td :colspan="slidebar ? 1 : 2">
            <b-form-checkbox
              v-model="search.showPast"
              @input="loadList"
              class="d-inline-block"
              style="vertical-align: middle"
            ></b-form-checkbox>
            <div class="d-inline-block" style="vertical-align: middle">Sýna liðin kennslutímabil</div>
          </td>
          <td :colspan="slidebar ? 1 : 2" :class="{ 'pb-0': detailedSearch }">
            <b-btn variant="primary" class="w-100" style="margin-top: 25px" @click="loadList">Leita</b-btn>
            <div class="cursor-pointer text-right pt-2" @click="detailedSearch = !detailedSearch">
              <span v-if="detailedSearch"><i class="fa fa-angle-up"></i> Fela ítarleit</span>
              <span v-else><i class="fa fa-angle-down"></i> Sýna ítarleit</span>
            </div>
          </td>
        </tr>
        <tr v-if="detailedSearch" class="table-detail-search">
          <td>
            <b-form-group label="Tafla læst" label-for="locked">
              <v-select id="locked" name="locked" v-model="search.locked" @input="loadList" :options="locked"> </v-select>
            </b-form-group>
          </td>
          <td>
            <b-btn variant="dark" style="height: 35px; font-size: 12px; margin-top: 14px" cols="1" @click="clearSearch()">
              <i class="fa fa-eraser" aria-hidden="true"></i>
              Hreinsa</b-btn
            >
          </td>
        </tr>
        <tr v-if="firsttime">
          <th><LinkOrder title="Einkenni" :filteredList="list" :list="list" column="onn_eink" /> ({{ list.length }})</th>
          <th>
            <LinkOrder title="Heiti" :filteredList="list" :list="list" column="onn_heiti" second_column="onn_eink" />
          </th>
          <th>
            <LinkOrder title="Undirskóli" :filteredList="list" :list="list" column="undirsk_heiti" second_column="onn_eink" />
          </th>
          <th v-if="!slidebar">
            <LinkOrder title="Dags.frá" :filteredList="list" :list="list" column="dags_fra" second_column="onn_eink" />
          </th>
          <th v-if="!slidebar">
            <LinkOrder title="Dags.til" :filteredList="list" :list="list" column="dags_til" second_column="onn_eink" />
          </th>
          <th v-if="!slidebar" v-b-tooltip title="Stundatafla nemenda opnar">
            <LinkOrder title="Tafla nemenda" :filteredList="list" :list="list" column="stundatafla_nem_opnar" second_column="onn_eink" />
          </th>
          <th v-if="!slidebar" v-b-tooltip title="Stundatafla starfsmanna opnar">
            <LinkOrder title="Tafla starfsm." :filteredList="list" :list="list" column="stundatafla_kenn_opnar" second_column="onn_eink" />
          </th>
          <th>
            <span
              v-if="!slidebar && !schoolHasSettings('stundatoflukerfi')"
              title="Hvort búið sé að fletja út kennslutímabilið. Hægt er að fletja út kennslutímabilið ef það er ekki liðið
              og til er stofntafla."
              v-b-tooltip
            >
              <LinkOrder title="Útflött" :filteredList="list" :list="list" column="stundatafla_tegund" second_column="onn_eink" />
            </span>
            <download-csv :data="getExcelData" :name="getExcelFileName" delimiter=";" class="cursor-pointer float-right">
              <i class="fa fa-file-excel-o excelText"></i>
            </download-csv>
          </th>
        </tr>
      </thead>
      <tbody>
        <TableRowLoader v-if="loading.terms && firsttime" :colspan="slidebar ? 3 : 6" :center="true" />
        <TableRowEmpty v-if="!loading.terms && firsttime && this.list.length === 0" :colspan="slidebar ? 3 : 6" :center="true" />
        <tr
          v-for="item in getPaginatedList"
          :key="item.onn_id"
          :class="{ 'active-table-row': selectedItem && selectedItem.onn_id === item.onn_id, 'cursor-pointer': showChangeTypeSlidebar }"
        >
          <td>
            <b-link
              :to="{
                name: 'Term',
                params: { id: item.onn_id },
                query: {
                  search: search.term,
                  div: `${search.division ? search.division.division_id : ''}`,
                  showPast: `${search.showPast ? search.showPast : ''}`,
                  locked: `${search.locked ? search.locked.id : ''}`,
                },
              }"
              v-if="!slidebar"
            >
              {{ item.onn_eink }}
            </b-link>
            <b-link @click="changeTerm(item)" title="Velja kennslutímabil" v-b-tooltip v-else>
              {{ item.onn_eink }}
            </b-link>
          </td>
          <td>
            {{ item.onn_heiti }}
          </td>
          <td>{{ item.undirsk_heiti }}</td>
          <td v-if="!slidebar">{{ item.dags_fra | moment('DD.MM.YYYY') }}</td>
          <td v-if="!slidebar">{{ item.dags_til | moment('DD.MM.YYYY') }}</td>
          <td v-if="!slidebar">{{ item.stundatafla_nem_opnar | moment('DD.MM.YYYY') }}</td>
          <td v-if="!slidebar">
            {{ item.stundatafla_kenn_opnar | moment('DD.MM.YYYY') }}
          </td>
          <td>
            <span v-if="!slidebar && !schoolHasSettings('stundatoflukerfi')"> <yes-no :item="item.stundatafla_tegund" /> </span>
            <b-link :to="{ name: 'CreateTerm', query: { copy: item.onn_id } }" v-if="canEdit && !slidebar">
              <i class="fa fa-copy float-right cursor-pointer" title="Afrita kennslutímabil" v-b-tooltip></i>
            </b-link>
          </td>
        </tr>
      </tbody>
    </table>

    <Pagination
      :currentPage="currentPage"
      :totalRows="list.length"
      :perPage="perPage"
      v-if="list.length > 25"
      @change-page="changePage"
      @change-limit="changeLimit"
    />
    <change-term-type-slidebar v-if="showChangeTypeSlidebar" :item="selectedItem" @closeSlidebar="closeSlidebar">
    </change-term-type-slidebar>
    <create-term-slidebar v-if="showCreateSlidebar" :termToCopy="termToCopy" @closeSlidebar="closeSlidebar"> </create-term-slidebar>
  </div>
</template>

<script>
import terms from '@/api/terms';
import structure from '@/api/structure';

import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

import TableRowLoader from '@/components/common/TableRowLoader.vue';
import TableRowEmpty from '@/components/common/TableRowEmpty.vue';
import LinkOrder from '@/components/common/LinkOrder.vue';
import Pagination from '@/components/common/Pagination.vue';
import YesNo from '@/components/common/YesNo.vue';

import ChangeTermTypeSlidebar from '@/components/school/terms/ChangeTermTypeSlidebar.vue';
import CreateTermSlidebar from '@/components/school/terms/CreateTermSlidebar.vue';

export default {
  name: 'term-search',
  components: {
    TableRowLoader,
    TableRowEmpty,
    LinkOrder,
    Pagination,
    ChangeTermTypeSlidebar,
    CreateTermSlidebar,
    YesNo,
  },
  props: ['slidebar'],
  computed: {
    getExcelData() {
      const newList = [
        [],
        ['Kennslutímabil'],
        [],
        ['Einkenni', 'Heiti', 'Undirskóli', 'Dags. frá', 'Dags. til', 'Tafla nemenda', 'Tafla starfsmanna', 'Útflött'],
        ...this.list.map(x => [
          x.onn_eink,
          x.onn_heiti,
          x.undirsk_heiti,
          moment(x.dags_fra).format('DD.MM.YYYY'),
          moment(x.dags_til).format('DD.MM.YYYY'),
          x.stundatafla_nem_opnar ? moment(x.stundatafla_nem_opnar).format('DD.MM.YYYY') : '',
          x.stundatafla_kenn_opnar ? moment(x.stundatafla_kenn_opnar).format('DD.MM.YYYY') : '',
          x.stundatafla_tegund === 1 ? 'Já' : 'Nei',
        ]),
      ];
      return newList;
    },
    getExcelFileName() {
      return `kennslutimabil_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}.csv`;
    },
    getPaginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.list.slice(start, end);
    },
    canRead() {
      return this.loggedInUserHasReadPermission('skolahald_annir');
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_annir');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolHasSettings']),
  },
  data() {
    return {
      perPage: 25,
      currentPage: 1,
      detailedSearch: false,
      firsttime: false,
      loading: {
        terms: false,
        divisions: false,
      },
      list: [],
      divisions: [],
      locked: [
        { id: '0', label: 'Nei' },
        { id: '1', label: 'Já' },
      ],
      search: {
        term: '',
        division: '',
        locked: '',
        showPast: false,
      },
      showChangeTypeSlidebar: false,
      showCreateSlidebar: false,
      selectedItem: {},
      termToCopy: '',
    };
  },
  methods: {
    updateQuery() {
      // if (!this.slidebar) {
      const queries = JSON.parse(JSON.stringify(this.$route.query));
      queries.search = this.search.term;
      queries.div = this.search.division ? this.search.division.division_id.toString() : '';
      queries.locked = this.search.locked ? this.search.locked.id.toString() : '';
      queries.showPast = this.search.showPast ? this.search.showPast : false;
      if (JSON.stringify(this.$route.query) !== JSON.stringify(queries)) {
        this.$router.replace({ query: queries });
      }
      // }
    },
    async loadList() {
      if (this.canRead) {
        try {
          this.loading.terms = true;
          this.firsttime = true;
          this.updateQuery();
          const params = {
            only_active: 0,
          };
          if (this.search.division) {
            params.division_id = this.search.division.division_id;
          }
          if (this.search.locked) {
            params.locked_terms = this.search.locked.id;
          }
          if (this.search.term) {
            params.term_code = this.search.term;
          }
          if (!this.search.showPast) {
            params.hide_past = 1;
          }
          // ef search bar er útfylltur (ítarleit) þá á að birta ítarleitina
          if (this.search.locked !== '') {
            this.detailedSearch = true;
          }
          const response = await terms.getTerms(params);
          this.list = response.data.items;
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading.terms = false;
        }
      }
    },
    async loadDivisions(id) {
      if (this.canRead) {
        try {
          this.loading.divisions = true;
          const response = await structure.getDivisionList();
          this.divisions = response.data.items.map(x => ({
            ...x,
            label: `${x.code} - ${x.name}`,
          }));
          if (id) {
            this.search.division = this.divisions.find(x => x.division_id === parseInt(id, 10));
          } else {
            this.search.division = this.divisions.find(
              x => (x.name || '').toUpperCase().indexOf('DAG') > -1 || (x.code || '').toUpperCase().indexOf('DAG') > -1,
            );
          }
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading.divisions = false;
        }
      }
    },
    getPopover(term) {
      let text = '';
      if (term.tafla_nem_lokud + term.tafla_stm_lokud === 0) {
        return 'Stundatafla birtist bæði nemendum og starfsmönnum';
      }

      if (term.lokun_stundatafla === 1) {
        text += 'Lokað fyrir stundatöflur, stýring á skóla. \n';
      }
      if (term.lokun_nemendur === 1) {
        text += 'Lokað fyrir nemendur, stýring á skóla. \n';
      }
      if (term.tafla_nem_dags_lidin === 0) {
        text += `Stundatafla nemenda opnar: ${moment(term.tafla_nem_opnar).format('DD.MM.YYYY')}. \n`;
      }
      if (term.tafla_stm_dags_lidin === 0) {
        text += `Stundatafla starfsmanna opnar: ${moment(term.tafla_stm_opnar).format('DD.MM.YYYY')}. \n`;
      }

      return text;
    },
    changeTerm(item) {
      this.$emit('changeTerm', {
        id: item.onn_id,
      });
    },
    openChangeTypeSlidebar(item) {
      this.selectedItem = item;
      this.showChangeTypeSlidebar = true;
    },
    closeSlidebar(item) {
      this.showChangeTypeSlidebar = false;
      this.showCreateSlidebar = false;
      this.selectedItem = {};
      this.termToCopy = '';
      if (item && item.id) {
        this.$router.push({ name: 'Term', params: { id: item.id }, query: { copy: item.termToCopy } });
      } else {
        this.loadList();
      }
    },
    checkSlidebar(item) {
      if (this.showChangeTypeSlidebar) {
        this.openChangeTypeSlidebar(item);
      }
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    openCreateSlidebar(termId) {
      this.termToCopy = termId;
      this.showCreateSlidebar = true;
    },
    async loadData() {
      const queries = this.$route.query;
      this.search.term = queries.search || '';
      this.search.showPast = queries.showPast || false;
      this.search.locked = queries.locked ? this.locked.find(x => x.id === queries.locked) : '';
      await this.loadDivisions(queries.div);
      this.$nextTick(() => {
        if (this.$refs.focusInput) {
          this.$refs.focusInput.focus();
        }
        // if (Object.keys(queries).length > 0) {
        //   this.loadList();
        // }
        this.loadList();
      });
    },
    clearSearch() {
      this.search = {
        term: '',
        showPast: false,
        locked: '',
        div: '',
      };
      this.loadList();
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadData();
  },
};
</script>
