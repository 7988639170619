<template>
  <page>
    <Breadcrumbs :breadcrumbs="breadcrumbs" />
    <PageTitle title="Hópar" />
    <b-card>
      <b-row>
        <b-col>
          <b-form-group label="Kennslutímabil" label-for="term">
            <v-select id="term" name="term" v-model="selected.term" :options="getTerms" :clearable="false" @input="doSearch()"> </v-select>
          </b-form-group>
          <div style="margin-top: -5px">
            <b-form-checkbox v-model="showAll" class="d-inline-block"></b-form-checkbox>
            <div class="d-inline-block">Birta liðnar og læstar annir</div>
          </div>
        </b-col>
        <b-col>
          <b-form-group label="Deild" label-for="department">
            <v-select
              id="department"
              name="department"
              v-model="selected.department"
              :options="departments"
              label="name"
              @input="doSearch()"
            >
            </v-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Áfangi" label-for="module">
            <b-form-input
              id="module"
              name="module"
              v-model="selected.module"
              v-on:keyup.enter="loadData()"
              ref="focusInput"
              @change="doSearch()"
            />
          </b-form-group>
        </b-col>
        <b-col v-if="showGroups">
          <b-form-group label="Hópur" label-for="group">
            <b-form-input id="group" name="group" v-model="selected.group" v-on:keyup.enter="loadData()" @change="doSearch()" />
          </b-form-group>
        </b-col>
        <b-col v-if="!showGroups">
          <b-form-group label="Eingöngu hópalausir áfangar" label-for="noGroups">
            <v-select id="noGroups" name="noGroups" v-model="selected.noGroups" :options="yesNo" @change="loadData()" :clearable="false">
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <b-form-group>
            Skoða eftir:
            <b-form-radio-group id="showBy" v-model="showGroups" name="showBy" class="pl-2 d-inline-block" @input="doSearch()">
              <b-form-radio :value="false">Áföngum</b-form-radio>
              <b-form-radio :value="true">Hópum</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <b-btn variant="primary" class="w-100" @click="loadData()"> Leita </b-btn>
        </b-col>
      </b-row>
    </b-card>
    <br />
    <GroupsList
      :load="loadComponents"
      :selected="selected"
      v-if="showGroups"
      :searchQuery="getQuery()"
      :canEdit="getCanEditTerm"
    ></GroupsList>
    <GroupsByModuleList :load="loadComponents" :selected="selected" v-if="!showGroups" :canEdit="getCanEditTerm"></GroupsByModuleList>
  </page>
</template>

<script>
import terms from '@/api/terms';
import structure from '@/api/structure';

import { mapGetters, mapActions } from 'vuex';

import Breadcrumbs from '@/components/common/Breadcrumbs.vue';
import PageTitle from '@/components/common/PageTitle.vue';

import GroupsList from '@/components/students/groups/GroupsList.vue';
import GroupsByModuleList from '@/components/students/groups/GroupsByModuleList.vue';

export default {
  name: 'groups',
  components: {
    Breadcrumbs,
    PageTitle,
    GroupsList,
    GroupsByModuleList,
  },
  props: [],
  computed: {
    getCanEditTerm() {
      return (
        this.loggedInUserHasWritePermission('nemendahald_hopar') &&
        this.selected.term &&
        this.selected.term.is_past === 0 &&
        this.selected.term.tafla_laest === 0
      );
    },
    getTerms() {
      return this.terms.filter(x => this.showAll || (x.is_past === 0 && x.tafla_laest === 0));
    },
    canRead() {
      return this.loggedInUserHasReadPermission('nemendahald_hopar');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      breadcrumbs: [
        { name: 'Forsíða', route: 'Dashboard' },
        { name: 'Nemendahald', route: '' },
        { name: 'Hópar', route: 'Groups' },
      ],
      loading: {
        terms: false,
        departments: false,
      },
      showAll: false,
      terms: [],
      departments: [],
      selected: {
        term: '',
        module: '',
        group: '',
        department: '',
        noGroups: { id: 0, label: 'Nei' },
      },
      loadComponents: false,
      showGroups: true,
      debounce: null,
      yesNo: [
        { id: 1, label: 'Já' },
        { id: 0, label: 'Nei' },
      ],
      hasSearched: false,
    };
  },
  methods: {
    doSearch() {
      if (this.hasSearched) {
        this.loadData();
      }
    },
    getQuery() {
      return `${this.selected.term ? this.selected.term.onn_id : ''},${this.selected.module || ''},${this.selected.group || ''},${
        this.selected.department ? this.selected.department.department_id : ''
      }`;
    },
    updateQuery() {
      if (!this.slidebar) {
        const queries = JSON.parse(JSON.stringify(this.$route.query));
        queries.term = this.selected.term ? this.selected.term.onn_id.toString() : '';
        queries.dept = this.selected.department ? this.selected.department.department_id.toString() : '';
        queries.module = this.selected.module;
        queries.group = this.selected.group;
        if (JSON.stringify(this.$route.query) !== JSON.stringify(queries)) {
          this.$router.replace({ query: queries });
        }
      }
    },
    async loadTerms(id) {
      try {
        if (this.canRead) {
          this.loading.terms = true;
          const response = await terms.getTerms();
          this.terms = response.data.items.map(x => ({
            ...x,
            label: `${x.undirsk_audk} - ${x.onn_heiti}`,
          }));
          if (id) {
            this.selected.term = this.terms.find(x => x.onn_id === parseInt(id, 10));
            this.loadData();
          } else {
            this.selected.term = this.terms.find(x => x.is_default === 1);
          }
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.terms = false;
      }
    },
    async loadDepartments(id) {
      try {
        if (this.canRead) {
          this.loading.departments = true;
          const response = await structure.getDepartmentList();
          this.departments = response.data.items;
        }
        if (id) {
          this.selected.department = this.departments.find(x => x.department_id === parseInt(id, 10));
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.departments = false;
      }
    },
    loadData() {
      this.updateQuery();
      this.hasSearched = true;
      this.loadComponents = true;
      setTimeout(() => {
        this.loadComponents = false;
      }, 1000);
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    const queries = this.$route.query;
    this.selected.module = queries.module || '';
    this.selected.group = queries.group || '';
    this.loadTerms(queries.term);
    this.loadDepartments(queries.dept);

    this.$nextTick(() => {
      if (this.$refs.focusInput) {
        this.$refs.focusInput.focus();
      }
    });
  },
};
</script>
