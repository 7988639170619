<template>
  <Slidebar :autoClose="true" @closeMe="closeSlidebar" :large="large" :extraLarge="extraLarge">
    <h4>Brautaleit</h4>
    <br />
    <CourseSearch @changeCourse="closeSlidebar" :slidebar="true" />
  </Slidebar>
</template>

<script>
import Slidebar from '@/components/common/Slidebar.vue';
import CourseSearch from '@/components/curriculum/courses/CourseSearch.vue';

export default {
  name: 'courses-search-slidebar',
  components: {
    Slidebar,
    CourseSearch,
  },
  props: ['large', 'extraLarge'],
  methods: {
    closeSlidebar(item) {
      this.$emit('closeSlidebar', item);
    },
  },
};
</script>

<style lang="scss"></style>
