<template>
  <Slidebar @closeMe="closeSlidebar" :large="large" :extraLarge="extraLarge">
    <h4 v-if="id">Breyta pöntun</h4>
    <h4 v-else>Panta stofu</h4>
    <br />
    <b-form @submit.prevent="validateBeforeSubmit" novalidate>
      <table class="table less-padding no-border">
        <tr>
          <th style="width: 130px">Bygging</th>
          <td>
            <b-form-group
              label-for="bygging"
              :state="submitted && errors.has('bygging') ? false : ''"
              :invalid-feedback="errors.first('bygging')"
            >
              <v-select
                id="bygging"
                name="bygging"
                v-model="form.classroomBuilding"
                :options="buildings"
                :state="submitted && errors.has('bygging') ? false : ''"
                data-vv-as="stofa"
                :clearable="true"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <th style="width: 130px">Stofa*</th>
          <td>
            <b-form-group
              label-for="stofa"
              :state="submitted && errors.has('stofa') ? false : ''"
              :invalid-feedback="errors.first('stofa')"
            >
              <v-select
                id="stofa"
                name="stofa"
                v-model="form.classroom"
                :options="getClassrooms"
                :state="submitted && errors.has('stofa') ? false : ''"
                v-validate="'required'"
                data-vv-as="stofa"
                :clearable="false"
                ref="searchClassroomRef"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
        <tr v-if="!id">
          <td></td>
          <td>
            <div>
              <b-form-checkbox v-model="searchFree" class="d-inline-block" :disabled="!hasDates"></b-form-checkbox>
              Leita eftir lausum stofum á völdum dagsetningum
              <div v-if="!hasDates" class="pl-4" style="font-size: 13px">
                <i>Ath. það verður að velja dagsetningar fyrst ef leita á eftir lausri stofu.</i>
              </div>
            </div>
            <div v-if="searchFree">
              <div>
                <div style="font-size: 12px">
                  <i class="fa fa-info-circle"></i> Ath. mælt er með því að velja tegund stofu eða byggingu. Það gæti tekið smá tíma að
                  leita að lausum stofum, sérstaklega ef dagsetningarnar eru margar.
                </div>
                <b-row>
                  <b-col class="pl-0">
                    <div>Tegund stofu</div>
                    <v-select
                      id="tegStofu"
                      name="tegStofu"
                      v-model="form.classroomType"
                      :options="classroomTypes"
                      :clearable="false"
                    ></v-select>
                  </b-col>
                  <b-col class="pl-0">
                    <div>Bygging</div>
                    <v-select id="bygging" name="bygging" v-model="form.building" :options="buildings" :clearable="false"></v-select>
                  </b-col>
                </b-row>
                <b-btn variant="primary" class="btn-xs mt-2" @click="loadFreeClassrooms()"
                  >Leita <i class="fa fa-spin fa-spinner" v-if="loading.free"></i
                ></b-btn>
              </div>
            </div>
            <b-btn variant="primary" class="btn-xs mt-2" v-if="hasSearchedFree" @click="loadClassrooms()"
              >Sækja allar stofur
              <i
                class="fa fa-info-circle"
                v-b-tooltip
                title="Þegar þú leitaðir eftir lausum stofum breyttiru listanum af stofum í eingöngu þær sem voru í boði á
                völdum dagsetningum.
                Til þess að fá aftur lista yfir allar stofur skólans smellið á þennan takka."
              ></i>
            </b-btn>
          </td>
        </tr>
        <tr>
          <th>Starfsmaður*</th>
          <td>
            <b-form-group
              label-for="starfsm"
              :state="submitted && errors.has('starfsm') ? false : ''"
              :invalid-feedback="errors.first('starfsm')"
            >
              <v-select
                id="starfsm"
                name="starfsm"
                v-model="form.staff"
                :options="staff"
                :state="submitted && errors.has('starfsm') ? false : ''"
                v-validate="'required'"
                data-vv-as="starfsmaður"
                :clearable="false"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <th>
            Birta einnig í stundatöflu
            <i
              class="fa fa-info-circle"
              v-b-tooltip
              title="Ath. pöntunin birtist eingöngu í stundatöflu valinna starfsmanna. Þeir geta ekki skoðað, breytt eða eytt pöntuninni."
            ></i>
          </th>
          <td>
            <b-form-group
              label-for="extraStaff"
              :state="submitted && errors.has('extraStaff') ? false : ''"
              :invalid-feedback="errors.first('extraStaff')"
            >
              <v-select
                id="extraStaff"
                name="extraStaff"
                multiple
                v-model="form.extraStaff"
                :options="getExtraStaff"
                :state="submitted && errors.has('extraStaff') ? false : ''"
                :clearable="true"
              >
              </v-select>
            </b-form-group>
          </td>
        </tr>
        <tr>
          <th>Skýring</th>
          <td>
            <b-form-group
              label-for="skyring"
              :state="submitted && errors.has('skyring') ? false : ''"
              :invalid-feedback="errors.first('skyring')"
            >
              <b-form-textarea
                id="skyring"
                name="skyring"
                v-model="form.reason"
                :state="submitted && errors.has('skyring') ? false : ''"
                data-vv-as="skýring"
              >
              </b-form-textarea>
            </b-form-group>
          </td>
        </tr>
        <tr v-if="!id">
          <th>Panta eftir:</th>
          <td colspan="2">
            <b-form-group>
              <b-form-radio-group id="orderBy" v-model="orderBy" name="orderBy" class="d-inline-block">
                <b-form-radio value="dates">Dagsetningum</b-form-radio>
                <b-form-radio value="periods">Tímabili</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </td>
        </tr>
        <tr v-if="response">
          <td colspan="2">
            <div class="alert alert-warning">
              <i class="fa fa-info-circle d-inline-block pr-2" style="vertical-align: top"></i>
              <div class="d-inline-block">
                <div>Ekki tókst að panta eftirfarandi dagsetningar þar sem stofa er nú þegar pöntuð.</div>
                <div>Fjöldi dagsetning: {{ response.total }}</div>
                <div>Fjöldi tókst: {{ response.success }}</div>
                <div>Fjöldi ekki laust: {{ response.failed }}</div>
              </div>
            </div>
          </td>
        </tr>
        <tr v-if="orderBy === 'dates' || id">
          <th>Dags</th>
          <td>
            <div class="d-inline-block" v-if="!id">
              <b-btn variant="primary" size="sm" style="margin-top: -10px" @click="addDate()">Bæta við</b-btn>
            </div>
            <div class="d-inline-block pl-5" v-if="!id">
              <i>Lengd kennslustunda: </i>
              <b-form-input type="number" style="padding: 2px; width: 80px; height: 30px" v-model="classLength" />
            </div>
            <div class="d-inline-block pl-5" v-if="!id">
              <b-btn variant="primary" class="mr-2" v-if="!id && canEdit && orderBy === 'dates'" @click="validateBeforeSubmit">
                Stofna
              </b-btn>
            </div>
            <table class="table no-border less-padding">
              <tr>
                <th v-if="validated"></th>
                <th>Dags.</th>
                <th>Tími frá</th>
                <th>Tími til</th>
              </tr>
              <tbody v-for="(date, index) in form.dates" :key="date.index" style="border: 0">
                <tr>
                  <td v-if="validated" style="vertical-align: top">
                    <b-form-checkbox v-model="date.copy" />
                  </td>
                  <td>
                    <b-form-group
                      :label-for="`date${index}`"
                      :state="submitted && errors.has(`date${index}`) ? false : ''"
                      :invalid-feedback="errors.first(`date${index}`)"
                    >
                      <datepicker
                        :id="`date${index}`"
                        :name="`date${index}`"
                        v-model="date.date"
                        :language="lang"
                        :monday-first="true"
                        format="dd.MM.yyyy"
                        :typeable="false"
                        v-validate="'required'"
                        data-vv-as="dags"
                        @change="validated = false"
                        :state="submitted && errors.has(`date${index}`) ? false : ''"
                        :ref="`dateOpenPickr${index}`"
                      ></datepicker>
                    </b-form-group>
                  </td>
                  <td>
                    <b-form-group
                      :label-for="`timeFrom${index}`"
                      :state="submitted && errors.has(`timeFrom${index}`) ? false : ''"
                      :invalid-feedback="errors.first(`timeFrom${index}`)"
                    >
                      <masked-input
                        :id="`timeFrom${index}`"
                        :name="`timeFrom${index}`"
                        v-model="date.time_from"
                        mask="11:11"
                        placeholder="hh:mm"
                        style="padding: 2px 2px 2px 2px; width: 60px; height: 30px; border: 1px solid #ced4da"
                        v-validate="{ required: true, regex: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/ }"
                        data-vv-as="tími frá"
                        :state="submitted && errors.has(`timeFrom${index}`) ? false : ''"
                        @input="updateTimeTo(null, index)"
                      />
                    </b-form-group>
                  </td>
                  <td>
                    <b-form-group
                      :label-for="`timeTo${index}`"
                      :state="submitted && errors.has(`timeTo${index}`) ? false : ''"
                      :invalid-feedback="errors.first(`timeTo${index}`)"
                    >
                      <masked-input
                        :id="`timeTo${index}`"
                        :name="`timeTo${index}`"
                        :ref="`timeTo${index}`"
                        v-model="date.time_to"
                        mask="11:11"
                        placeholder="hh:mm"
                        style="padding: 2px 2px 2px 2px; width: 60px; height: 30px; border: 1px solid #ced4da"
                        v-validate="{ required: true, regex: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/ }"
                        data-vv-as="tími til"
                        :state="submitted && errors.has(`timeTo${index}`) ? false : ''"
                      />
                    </b-form-group>
                  </td>
                  <td>
                    <b-btn variant="primary" size="sm" @click="removeDate(index)" style="margin-top: -20px" v-if="!id"
                      ><i class="fa fa-trash"
                    /></b-btn>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr v-if="orderBy === 'periods' && !id">
          <th>Tímabil</th>
          <td>
            <div class="d-inline-block">
              <div v-for="(period, idx) in form.periods" :key="idx">
                <div class="d-inline-block">
                  <b-form-group
                    label="Frá"
                    :label-for="`dateFrom${idx}`"
                    :state="submitted && errors.has(`dateFrom${idx}`) ? false : ''"
                    :invalid-feedback="errors.first(`dateFrom${idx}`)"
                  >
                    <datepicker
                      :id="`dateFrom${idx}`"
                      :name="`dateFrom${idx}`"
                      v-model="period.dateFrom"
                      :language="lang"
                      :monday-first="true"
                      format="dd.MM.yyyy"
                      :typeable="false"
                      :ref="`dateFromOpenPickr${idx}`"
                      data-vv-as="dags frá"
                      v-validate="'required'"
                      :state="submitted && errors.has(`dateFrom${idx}`) ? false : ''"
                    ></datepicker>
                  </b-form-group>
                </div>
                <div class="d-inline-block pl-4">
                  <b-form-group
                    label="Til"
                    :label-for="`dateTo${idx}`"
                    :state="submitted && errors.has(`dateTo${idx}`) ? false : ''"
                    :invalid-feedback="errors.first(`dateTo${idx}`)"
                  >
                    <datepicker
                      :id="`dateTo${idx}`"
                      :name="`dateTo${idx}`"
                      v-model="period.dateTo"
                      :language="lang"
                      :monday-first="true"
                      format="dd.MM.yyyy"
                      :typeable="false"
                      :ref="`dateTopenPickr${idx}`"
                      data-vv-as="dags til"
                      v-validate="'required'"
                      :state="submitted && errors.has(`dateTo${idx}`) ? false : ''"
                    ></datepicker>
                  </b-form-group>
                </div>
                <div class="d-inline-block pl-4">
                  <b-btn variant="primary" size="sm" @click="removeMultiPeriod(idx)" style="vertical-align: bottom">
                    <i class="fa fa-trash" />
                  </b-btn>
                </div>
              </div>
            </div>
            <div class="d-inline-block pl-3 float-right" style="vertical-align: top; margin-top: 30px">
              <b-btn variant="primary" size="sm" @click="addMultiPeriod()">Bæta við tímabili</b-btn>
            </div>
          </td>
        </tr>
        <tr v-if="this.orderBy === 'periods'">
          <td></td>
          <td>
            <div class="d-inline-block align-bottom">
              <div>
                <i>Lengd kennslustunda: </i>
                <b-form-input type="number" class="d-inline-block" style="padding: 2px; width: 60px; height: 30px" v-model="classLength" />
              </div>
            </div>
          </td>
        </tr>
        <tr v-for="day in form.weekdays.filter(x => this.orderBy === 'periods')" :key="day.id">
          <td>{{ day.name }}</td>
          <td colspan="4">
            <div class="d-inline-block" style="font-size: 12px">
              <b-btn variant="primary" size="sm" @click="addMultiDate(day)"> Bæta við </b-btn>
            </div>
            <div class="d-inline-block float-right">
              <b-dropdown :id="`dropdown-${day.id}`" text="Afrita frá öðrum degi" size="sm" variant="dark">
                <b-dropdown-item v-for="d in getDays(day)" :key="d.id" @click="copyFromOtherDay(day, d.id)">{{ d.name }}</b-dropdown-item>
              </b-dropdown>
            </div>
            <table class="table less-padding no-border">
              <tr v-if="day.times.length > 0">
                <th>Tími frá</th>
                <th>Tími til</th>
                <th />
              </tr>
              <tr v-for="(date, index) in day.times" :key="index">
                <td>
                  <b-form-group
                    :label-for="`timeFrom${day.id}${index}`"
                    :state="submitted && errors.has(`timeFrom${day.id}${index}`) ? false : ''"
                    :invalid-feedback="errors.first(`timeFrom${day.id}${index}`)"
                  >
                    <masked-input
                      :id="`timeFrom${day.id}${index}`"
                      :name="`timeFrom${day.id}${index}`"
                      v-model="date.time_from"
                      mask="11:11"
                      placeholder="hh:mm"
                      style="padding: 2px 2px 2px 2px; width: 60px; height: 30px; border: 1px solid #ced4da"
                      v-validate="{ required: true, regex: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/ }"
                      data-vv-as="tími frá"
                      :state="submitted && errors.has(`timeFrom${day.id}${index}`) ? false : ''"
                      @input="updateTimeTo(day, index)"
                    />
                  </b-form-group>
                </td>
                <td>
                  <b-form-group
                    :label-for="`timeTo${day.id}${index}`"
                    :state="submitted && errors.has(`timeTo${day.id}${index}`) ? false : ''"
                    :invalid-feedback="errors.first(`timeTo${day.id}${index}`)"
                  >
                    <masked-input
                      :id="`timeTo${day.id}${index}`"
                      :name="`timeTo${day.id}${index}`"
                      :ref="`timeTo${day.id}${index}`"
                      v-model="date.time_to"
                      mask="11:11"
                      placeholder="hh:mm"
                      style="padding: 2px 2px 2px 2px; width: 60px; height: 30px; border: 1px solid #ced4da"
                      v-validate="{ required: true, regex: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/ }"
                      data-vv-as="tími til"
                      :state="submitted && errors.has(`timeTo${day.id}${index}`) ? false : ''"
                    />
                  </b-form-group>
                </td>
                <td>
                  <b-btn variant="primary" size="sm" @click="removeMultiDate(day, index)"><i class="fa fa-trash" /></b-btn>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <div class="alert alert-info p-1" style="font-size: 13px; margin-top: -20px" v-if="id && !isMine">
        <div class="d-inline-block pr-2" style="vertical-align: top"><i class="fa fa-info-circle"></i></div>
        <div class="d-inline-block">
          <div>Ef breyta á bókun á stofu mun sé sem bókaði stofuna fá póst þar sem hann er látinn vita að breyting hafi verið gerð.</div>
          <div>Ef eyða á bókun á stofu mun sá sem bókaði stofuna einungis vera látinn vita ef pöntun er ekki liðin.</div>
        </div>
      </div>
      <b-btn variant="primary" class="mr-2" v-if="canEdit && orderBy === 'dates'" :disabled="saving" @click="validateBeforeSubmit">
        <i class="fa fa-spin fa-spinner" v-if="saving"></i>
        <span v-if="id">Breyta</span>
        <span v-else>Stofna</span>
      </b-btn>
      <b-btn variant="primary" class="mr-2" v-if="canEdit && orderBy === 'periods'" @click="flattenDates"> Mynda dagsetningar </b-btn>
      <b-btn variant="dark" @click="closeSlidebar">Hætta við</b-btn>
      <b-btn variant="danger" class="float-right" v-if="canEdit && id" @click="deleteOrder"> Eyða </b-btn>
    </b-form>
  </Slidebar>
</template>

<script>
import moment from 'moment';
import structure from '@/api/structure';
import requests from '@/api/requests';
import staff from '@/api/staff';
import types from '@/api/types';
import { mapActions, mapGetters } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';
import MaskedInput from 'vue-masked-input';

export default {
  name: 'classroom-order-slidebar',
  props: ['item', 'large', 'extraLarge'],
  components: {
    Slidebar,
    Datepicker,
    MaskedInput,
  },
  computed: {
    getClassrooms() {
      return this.classrooms.filter(x => !this.form.classroomBuilding || this.form.classroomBuilding.building_id === x.bygging_id);
    },
    getDates() {
      return this.form.dates; /* / .filter(
        x => !this.form.holidays || (this.form.holidays && x.validation && x.validation.holidays && x.validation.holidays.length === 0),
      ); */
    },
    hasDates() {
      if (this.orderBy === 'periods') {
        return (
          this.form.periods.filter(x => x.dateFrom && x.dateTo).length > 0 && this.form.weekdays.filter(x => x.times.length > 0).length > 0
        );
      }
      return this.form.dates.filter(x => x.date && x.time_from && x.time_to).length > 0;
    },
    getExtraStaff() {
      return this.staff.filter(
        x =>
          (!this.form.staff || this.form.staff.starfsm_id !== x.starfsm_id) &&
          this.form.extraStaff.map(y => y.starfsm_id).indexOf(x.starfsm_id) === -1,
      );
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('beidnir_stofa');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'loggedInUser']),
  },
  data() {
    return {
      saving: false,
      classLength: 60,
      regexTime: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
      lang: is,
      loading: {
        classrooms: false,
        staff: false,
        buildings: false,
        classroomTypes: false,
        free: false,
      },
      classrooms: [],
      staff: [],
      buildings: [],
      classroomTypes: [],
      submitted: false,
      searchFree: false,
      hasSearchedFree: false,
      id: null,
      isMine: true,
      orderBy: 'dates',
      validated: false,
      response: '',
      form: {
        classroom: '',
        classroomBuilding: '',
        reason: '',
        staff: '',
        extraStaff: [],
        dates: [
          {
            date: '',
            time_from: '',
            time_to: '',
          },
        ],
        periods: [{ dateFrom: '', dateTo: '' }],
        holidays: true,
        weekdays: [
          { id: 1, name: 'Mánudagur', times: [], copyFrom: '' },
          { id: 2, name: 'Þriðjudagur', times: [], copyFrom: '' },
          { id: 3, name: 'Miðvikudagur', times: [], copyFrom: '' },
          { id: 4, name: 'Fimmtudagur', times: [], copyFrom: '' },
          { id: 5, name: 'Föstudagur', times: [], copyFrom: '' },
          // { id: 6, name: 'Laugardagur', times: [], copyFrom: '' },
          // { id: 7, name: 'Sunnudagur', times: [], copyFrom: '' },
        ],
        building: '',
        classroomType: '',
      },
    };
  },
  methods: {
    async loadClassrooms(id) {
      try {
        this.loading.classrooms = true;
        this.hasSearchedFree = false;
        this.classrooms = [];
        const response = await structure.getClassroomList({ active: 1 });
        this.classrooms = response.data.items.map(x => ({
          stofa_id: x.stofa_id,
          stofa_eink: x.stofa_eink,
          stofa_heiti: x.stofa_heiti,
          bygging_id: x.bygging_id,
          label: `${x.stofa_eink} - ${x.stofa_heiti}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.classrooms = false;
      }
      if (id) {
        this.form.classroom = this.classrooms.find(x => x.stofa_id === id);
      }
    },
    async loadStaff(id, extraStaff) {
      if (this.staff.length === 0) {
        try {
          this.loading.staff = true;
          this.staff = [];
          const response = await staff.getStaffList();
          this.staff = response.data.items.map(x => ({ ...x, label: `${x.nafn} - ${x.skammst}` }));
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading.staff = false;
        }
      }
      if (id) {
        this.form.staff = this.staff.find(x => x.starfsm_id === id);
      } else if (this.loggedInUser && this.loggedInUser.starfsm_id) {
        this.form.staff = this.staff.find(x => x.starfsm_id === this.loggedInUser.starfsm_id);
      }
      if (extraStaff) {
        this.form.extraStaff = [];
        this.form.extraStaff.push(
          ...this.staff.filter(
            x =>
              JSON.parse(extraStaff)
                .map(y => y.starfsm_id)
                .indexOf(x.starfsm_id) > -1,
          ),
        );
      }
    },
    async loadBuildings() {
      try {
        this.loading.buildings = true;
        this.buildings = [];
        const response = await structure.getBuildingList();
        this.buildings = response.data.items.map(x => ({ ...x, label: x.name }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.buildings = false;
      }
    },
    async loadClassroomTypes() {
      try {
        this.loading.classroomTypes = true;
        this.classroomTypes = [];
        const response = await types.getClassroomList();
        this.classroomTypes = response.data.items.map(x => ({ ...x, label: x.heiti }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.classroomTypes = false;
      }
    },
    addDate() {
      this.validated = false;
      if (this.form.dates.length > 0 && this.form.dates[this.form.dates.length - 1].time_to) {
        const item = this.form.dates[this.form.dates.length - 1];
        const timeTo = moment().set('hour', item.time_to.split(':')[0]).set('minute', item.time_to.split(':')[1]).set('second', 0);
        this.form.dates.push({
          date: moment(item.date).toDate(),
          time_from: item.time_to,
          time_to: moment(timeTo).add('minute', this.classLength).format('HH:mm'),
        });
      } else {
        this.form.dates.push({ date: '', time_from: '', time_to: '' });
      }
    },
    updateTimeTo(day, index) {
      if (day && this.regexTime.test(day.times[index].time_from)) {
        const timeTo = moment()
          .set('hour', day.times[index].time_from.split(':')[0])
          .set('minute', day.times[index].time_from.split(':')[1])
          .set('second', 0);
        this.$set(day.times[index], 'time_to', moment(timeTo).add('minute', this.classLength).format('HH:mm'));
        this.$refs[`timeTo${day.id}${index}`][0].$refs.input.focus();
      } else if (!day && this.regexTime.test(this.form.dates[index].time_from)) {
        this.validated = false;
        const timeTo = moment()
          .set('hour', this.form.dates[index].time_from.split(':')[0])
          .set('minute', this.form.dates[index].time_from.split(':')[1])
          .set('second', 0);
        this.$set(this.form.dates[index], 'time_to', moment(timeTo).add('minute', this.classLength).format('HH:mm'));
        this.$refs[`timeTo${index}`][0].$refs.input.focus();
      }
    },
    removeDate(index) {
      this.form.dates.splice(index, 1);
    },
    addMultiPeriod() {
      this.form.periods.push({ dateFrom: '', dateTo: '' });
    },
    removeMultiPeriod(index) {
      this.form.periods.splice(index, 1);
      if (this.form.periods.length === 0) {
        this.form.periods.push({ dateFrom: '', dateTo: '' });
      }
    },
    addMultiDate(day) {
      if (day.times.length > 0 && day.times[day.times.length - 1].time_to) {
        const item = day.times[day.times.length - 1];
        const timeTo = moment().set('hour', item.time_to.split(':')[0]).set('minute', item.time_to.split(':')[1]).set('second', 0);
        day.times.push({
          time_from: item.time_to,
          time_to: moment(timeTo).add('minute', this.classLength).format('HH:mm'),
        });
      } else {
        day.times.push({ time_from: '', time_to: '' });
      }
    },
    copyFromOtherDay(day, copyFrom) {
      if (copyFrom) {
        const item = this.form.weekdays.find(x => x.id === copyFrom);
        if (item.times.length > 0) {
          this.$set(day, 'times', JSON.parse(JSON.stringify(item.times)));
        }
      }
    },
    getDays(day) {
      return this.form.weekdays
        .slice(0, this.daysInWeek)
        .filter(x => x.id !== day.id)
        .map(x => ({ id: x.id, name: x.name }));
    },
    flattenDates() {
      this.form.dates = [];
      const dates = [];
      this.form.periods.forEach(p => {
        const startDate = moment(p.dateFrom).toDate();
        const endDate = moment(p.dateTo).toDate();
        const currentDate = startDate;
        while (currentDate <= endDate) {
          const weekday = this.form.weekdays.find(x => (x.id === 7 && currentDate.getDay() === 0) || x.id === currentDate.getDay());
          if (weekday && weekday.times) {
            weekday.times.forEach(d => {
              dates.push({
                date: new Date(currentDate),
                time_from: d.time_from,
                time_to: d.time_to,
              });
            });
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }
      });
      this.form.dates = dates;
      this.orderBy = 'dates';
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.response = '';
        this.errorList = [];
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal
        });

        // Engar villur í formi.
        try {
          this.saving = true;
          const item = {
            orderId: this.id,
            classroomId: this.form.classroom.stofa_id,
            staffId: this.form.staff.starfsm_id,
            reason: this.form.reason,
            extraStaff: this.form.extraStaff.map(x => x.starfsm_id).join(','),
            dags: this.getDates.map(x => ({
              dags: moment(x.date).format('DD.MM.YYYY'),
              timi_fra: x.time_from,
              timi_til: x.time_to,
            })),
          };
          const response = await requests.createClassroomRequests(item);
          if (response.data.notBooked.length === 0) {
            this.displaySuccess('Stofa pöntuð');
            this.$emit('closeAndReloadSlidebar');
          } else {
            this.response = response.data;
            this.form.dates = this.form.dates.filter(x =>
              response.data.notBooked.find(
                y => y.date === moment(x.date).format('DD.MM.YYYY') && y.time_from === x.time_from && y.time_to === x.time_to,
              ),
            );
          }
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          if (e.response && e.response.data && e.response.data.error) {
            this.displayError(e.response.data.error);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.saving = false;
      }
    },
    async deleteOrder() {
      try {
        const conf = confirm('Ert þú viss um að þú viljir eyða pöntuninni?'); // eslint-disable-line
        if (conf) {
          const item = {
            orderId: this.id,
            deleted: 1,
          };
          await requests.createClassroomRequests(item);
          this.displaySuccess('Pöntun eytt');
          this.$emit('closeAndReloadSlidebar');
        }
      } catch (e) {
        // Aðgerð mistókst.
        this.$log.debug(e);
        if (e.response && e.response.data && e.response.data.error) {
          this.displayError(e.response.data.error);
        } else {
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    async loadFreeClassrooms() {
      try {
        this.loading.free = true;
        this.hasSearchedFree = true;
        if (this.orderBy === 'periods') {
          this.flattenDates();
        }
        const item = {
          teg_stofu: this.form.classroomType ? this.form.classroomType.teg_stofu : '',
          bygging_id: this.form.building ? this.form.building.building_id : '',
          dags: {
            dags: [
              ...this.getDates.map(x => ({
                dags: moment(x.date).format('DD.MM.YYYY'),
                timi_fra: x.time_from,
                timi_til: x.time_to,
              })),
            ],
          },
        };
        const response = await requests.getClassroomFreeDates(item);
        this.classrooms = response.data.items.map(x => ({
          stofa_id: x.stofa_id,
          stofa_eink: x.stofa_eink,
          stofa_heiti: x.stofa_heiti,
          bygging_id: x.bygging_id,
          label: `${x.stofa_eink} - ${x.stofa_heiti}`,
        }));
        this.$nextTick(() => {
          const input = this.$refs.searchClassroomRef.$el.querySelector('input');
          if (input) {
            input.focus();
          }
        });
        this.searchFree = false;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.free = false;
      }
    },
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async loadOrderDetails() {
      try {
        const params = {
          id: this.id,
        };
        const response = await requests.getClassroomRequestList(params);
        const item = response.data.items && response.data.items.length > 0 ? response.data.items[0] : {};
        this.form.dates = [
          {
            date: moment(item.dags).toDate(),
            time_from: item.timi_fra,
            time_to: item.timi_til,
          },
        ];
        this.form.reason = item.skyring;
        this.isMine = item.is_mine === 1;
        this.loadClassrooms(item.stofa_id);
        this.loadStaff(item.starfsm_id, item.auka_stm);
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    initialize() {
      this.form.dates = [
        {
          date: this.item && this.item.date ? moment(this.item.date).toDate() : '',
          time_from: this.item && this.item.date ? moment(this.item.date).format('HH:mm') : '',
          time_to: this.item && this.item.date ? moment(this.item.date).add('minute', this.classLength).format('HH:mm') : '',
        },
      ];
      this.form.classroom = '';
      this.orderBy = 'dates';
      this.id = null;
      this.reason = '';
      this.isMine = true;
      this.periods = [{ dateFrom: '', dateTo: '' }];
      this.weekdays = [
        { id: 1, name: 'Mánudagur', times: [], copyFrom: '' },
        { id: 2, name: 'Þriðjudagur', times: [], copyFrom: '' },
        { id: 3, name: 'Miðvikudagur', times: [], copyFrom: '' },
        { id: 4, name: 'Fimmtudagur', times: [], copyFrom: '' },
        { id: 5, name: 'Föstudagur', times: [], copyFrom: '' },
      ];
      if (this.item && this.item.id) {
        this.id = this.item.id;
        this.loadOrderDetails();
      } else {
        this.loadClassrooms(this.item && this.item.classroomId);
        this.loadStaff(this.item && this.item.staffId);
        this.loadBuildings();
        this.loadClassroomTypes();
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    item() {
      this.initialize();
    },
  },
  created() {
    this.initialize();
  },
};
</script>

<style lang="scss"></style>
