<template>
  <div>
    <b-row>
      <b-col class="pl-0" v-if="id">
        <GradeJobs v-if="tabIndex == 'gradeJobs'" :id="id" :termPassed="termPassed"></GradeJobs>
        <AttendanceGrades
          v-if="tabIndex == 'attendance' && term"
          :divisionId="term.undirskoli_id"
          :id="id"
          :termPassed="termPassed"
        ></AttendanceGrades>
        <Lists v-if="tabIndex == 'lists'" :id="id" :item="item"></Lists>
        <TransferStudents v-if="tabIndex == 'transfer' && schoolWithGrades" :id="id" :termPassed="termPassed"></TransferStudents>
      </b-col>
      <b-col cols="2">
        <div class="right-side-nav noprint">
          <b-nav vertical>
            <b-nav-item @click.prevent="tabIndex = 'gradeJobs'" :active="tabIndex === 'gradeJobs'">Einkunnavinnslur</b-nav-item>
            <b-nav-item v-if="schoolWithGrades" @click.prevent="tabIndex = 'transfer'" :active="tabIndex === 'transfer'"
              >Flytja nemendur upp</b-nav-item
            >
            <b-nav-item @click.prevent="tabIndex = 'attendance'" :active="tabIndex === 'attendance'">Mætingareinkunn</b-nav-item>
            <b-nav-item @click.prevent="tabIndex = 'lists'" :active="tabIndex === 'lists'">Yfirlit</b-nav-item>
          </b-nav>
        </div></b-col
      >
    </b-row>
  </div>
</template>

<script>
import terms from '@/api/terms';
import { mapActions, mapGetters } from 'vuex';

import GradeJobs from '@/components/school/terms/end/GradeJobs.vue';
import AttendanceGrades from '@/components/school/terms/end/AttendanceGrades.vue';
import TransferStudents from '@/components/school/terms/end/TransferStudents.vue';
import Lists from '@/components/school/terms/end/Lists.vue';

export default {
  name: 'term-end',
  props: ['id', 'item', 'termPassed'],
  components: {
    GradeJobs,
    AttendanceGrades,
    TransferStudents,
    Lists,
  },
  computed: {
    canRead() {
      return this.loggedInUserHasReadPermission('skolahald_annir');
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_annir');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolHasSettings', 'schoolWithGrades']),
  },
  data() {
    return {
      term: '',
      loading: false,
      tabIndex: 'gradeJobs',
    };
  },
  methods: {
    async loadTerm() {
      if (this.canRead && this.id) {
        try {
          this.loading = true;
          const response = await terms.getTerm(this.id);
          this.term = response.data.items ? response.data.items[0] : '';
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        } finally {
          this.loading = false;
        }
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    id() {
      this.loadTerm();
    },
  },
  created() {
    this.loadTerm();
  },
};
</script>
<style lang="scss"></style>
