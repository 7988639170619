<template>
  <Slidebar :large="true" @closeMe="closeSlidebar">
    <h4 v-if="type === 0">Skrá fjarvist á hóp</h4>
    <h4 v-else>Skrá forföll á hóp</h4>

    <b-form-group>
      <label class="d-inline-block mr-2">Skrá eftir</label>
      <b-form-radio-group id="period" v-model="period" name="period" class="d-inline-block" @change="checkGetData()">
        <b-form-radio :value="true">Tímabili</b-form-radio>
        <b-form-radio :value="false">Dagsetningu</b-form-radio>
      </b-form-radio-group>
    </b-form-group>
    <div v-if="period">
      <div class="mb-2" style="font-size: 13px">
        <p class="mb-1">
          Hér eru skráðar fjarvistir/forföll fyrir ákveðið tímabil athugið að velja rétt í glugganum „Áfangar“ en boðið er upp á að skrá
          bara á þennan áfanga á tímabilinu eða skrá á alla áfanga sem nemandi/ur eru í á tímabilinu.
        </p>
        <p class="mb-1">Ef valið er að skrá á alla áfanga nemandans þarf að hafa í huga að sú aðgerð er ekki afturkræf.</p>
        <p class="mb-1">
          Dæmi þegar eingöngu er skráð á áfangann: Áfanginn er sundáfangi og nemandi fótbrotnar og mætir ekki í tíma næstu 4 vikurnar. Þá er
          þessi valmöguleiki valinn.
        </p>
        <p class="mb-1">
          Dæmi þegar skráð er á alla áfanga: Nemendur í áfanganum eru að fara í vettvangsferð á vegum skólans og munu því ekki mæta í neina
          tíma á meðan. Þá er þessi valmöguleiki valinn.
        </p>
      </div>
      <b-row>
        <b-col class="pl-0" md="6" sm="12" xs="12">
          <b-form-group
            label-for="dateFrom"
            label="Dags. frá*"
            :state="submitted && errors.has('dateFrom') ? false : ''"
            :invalid-feedback="errors.first('dateFrom')"
          >
            <datepicker
              id="dateFrom"
              name="dateFrom"
              v-model="form.dateFrom"
              :language="lang"
              :monday-first="true"
              format="dd.MM.yyyy"
              :typeable="false"
              :clear-button="false"
              ref="registerDateFromPickr"
              v-validate="'required'"
            ></datepicker>
          </b-form-group>
          <b-form-group
            label="Tími frá"
            label-for="timeFrom"
            :state="submitted && errors.has('timeFrom') ? false : ''"
            :invalid-feedback="errors.first('timeFrom')"
          >
            <masked-input
              id="timeFrom"
              name="timeFrom"
              v-model="form.timeFrom"
              mask="11:11"
              placeholder="hh:mm"
              style="padding: 2px 2px 2px 2px; width: 60px; height: 30px; border: 1px solid #ced4da"
              v-validate="{ required: false, regex: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/ }"
              data-vv-as="tími frá"
              :state="submitted && errors.has('timeFrom') ? false : ''"
            />
          </b-form-group>
          <b-form-group
            label-for="dateTo"
            label="Dags. til*"
            :state="submitted && errors.has('dateTo') ? false : ''"
            :invalid-feedback="errors.first('dateTo')"
          >
            <datepicker
              id="dateTo"
              name="dateTo"
              v-model="form.dateTo"
              :language="lang"
              :monday-first="true"
              format="dd.MM.yyyy"
              :typeable="false"
              :clear-button="false"
              ref="registerDateToPickr"
              v-validate="'required'"
            ></datepicker>
          </b-form-group>
          <b-form-group
            label="Tími til"
            label-for="timeTo"
            :state="submitted && errors.has('timeTo') ? false : ''"
            :invalid-feedback="errors.first('timeTo')"
          >
            <masked-input
              id="timeTo"
              name="timeTo"
              v-model="form.timeTo"
              mask="11:11"
              placeholder="hh:mm"
              style="padding: 2px 2px 2px 2px; width: 60px; height: 30px; border: 1px solid #ced4da"
              v-validate="{ required: false, regex: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/ }"
              data-vv-as="tími frá"
              :state="submitted && errors.has('timeTo') ? false : ''"
            />
          </b-form-group>
          <b-form-group
            label-for="tegund"
            label="Tegund*"
            :state="submitted && errors.has('tegund') ? false : ''"
            :invalid-feedback="errors.first('tegund')"
          >
            <v-select
              id="tegund"
              name="tegund"
              v-model="form.type"
              :clearable="false"
              v-validate="'required'"
              :state="submitted && errors.has('tegund') ? false : ''"
              :options="getTypes"
              data-vv-as="tegund"
            >
            </v-select>
          </b-form-group>
          <b-form-group
            label-for="athugasemd"
            label="Athugasemd"
            :state="submitted && errors.has('athugasemd') ? false : ''"
            :invalid-feedback="errors.first('athugasemd')"
          >
            <b-form-textarea
              id="athugasemd"
              name="athugasemd"
              v-model="form.comment"
              maxLength="1000"
              :state="submitted && errors.has('athugasemd') ? false : ''"
              data-vv-as="athugasemd"
            >
            </b-form-textarea>
          </b-form-group>
          <b-form-group
            label-for="onlyThisOne"
            label="Áfangar"
            :state="submitted && errors.has('onlyThisOne') ? false : ''"
            :invalid-feedback="errors.first('onlyThisOne')"
          >
            <v-select
              id="onlyThisOne"
              name="onlyThisOne"
              v-model="form.onlyThisOne"
              :clearable="false"
              v-validate="'required'"
              :state="submitted && errors.has('onlyThisOne') ? false : ''"
              :options="options"
            >
            </v-select>
          </b-form-group>
        </b-col>
        <b-col>
          <div v-if="!loading.students">Nemendur ({{ students.length }})</div>
          <div v-for="s in students" :key="s.nemandi_id">
            <b-form-checkbox v-model="s.selected" class="d-inline-block"></b-form-checkbox> {{ s.nafn }}
          </div>
        </b-col>
      </b-row>
      <div v-if="form.type" class="mt-3 mb-3">
        Skrá <span v-if="type === 0"> fjarvistategundina </span> <span v-if="type === 1">forfallategundina </span>
        <strong>{{ form.type.heiti }} ({{ form.type.kodi }})</strong> fyrir {{ selectedStudents.length }} nemanda/ur á tímabilinu
        {{ form.dateFrom | moment('DD.MM.YYYY') }} {{ form.timeFrom }} <span v-if="!form.timeFrom">00:00</span> -
        {{ form.dateTo | moment('DD.MM.YYYY') }}
        {{ form.timeTo }}
        <span v-if="!form.timeTo">23:59</span>.
        <div>
          <span v-if="form.onlyThisOne.id === 0">Ath. eingöngu verður skráð fyrir kennslustundir í þessum ákveðna áfanga.</span>
          <span v-else>Ath. það verður skráð fyrir allar kennslustundir sem nemendurnir eru í á tímabilinu.</span>
        </div>
      </div>
      <div v-if="periodLength >= 14" class="alert alert-info p-2">
        <i class="fa fa-exclemation-triangle"></i> Ath. Tímabilið sem þú ert með valið eru {{ periodLength }} dagar!
      </div>
      <b-btn variant="primary" @click="validateBeforeSubmit()" :disabled="saving">
        <i class="fa fa-spin fa-spinner" v-if="saving"></i> Stofna
      </b-btn>
    </div>
    <div v-else>
      <b-row>
        <b-col class="pl-0">
          <b-form-group
            label-for="date"
            label="Dags."
            :state="submitted && errors.has('date') ? false : ''"
            :invalid-feedback="errors.first('date')"
          >
            <datepicker
              id="date"
              name="date"
              v-model="date"
              :language="lang"
              :monday-first="true"
              format="dd.MM.yyyy"
              :typeable="false"
              :clear-button="false"
              ref="registerDatePickr"
              @input="getAttendanceData()"
            ></datepicker>
          </b-form-group>
        </b-col>
      </b-row>
      <Loader v-if="loading.attendanceData"></Loader>
      <div v-if="!loading.attendanceData && attendanceData.length === 0">Ekki er hægt að skrá viðveru þennan dag.</div>
      <div v-if="attendanceData.length > 0">
        <table class="table less-padding no-border" style="font-size: 13px">
          <thead>
            <tr>
              <td></td>
              <td style="font-weight: normal"><i>Sami kóði á alla:</i></td>
              <td v-for="(t, idx) in attendanceData" :key="`allTime${idx}`">
                <input
                  type="text"
                  v-model="t.commonCode"
                  @input="updateAll(t.commonCode, t)"
                  maxLength="1"
                  style="width: 35px; height: 25px; padding-left: 3px"
                />
              </td>
            </tr>
            <tr>
              <th>Nafn</th>
              <th>Kennitala</th>
              <th v-for="(t, idx) in attendanceData" :key="`time${idx}`">
                {{ t.timifra }}-{{ t.timitil }}
                <span v-if="type === 1 && absenceExists(t)" title="Hreinsa út forföll" v-b-tooltip
                  ><i class="fa fa-trash text-primary pl-1 cursor-pointer" @click="clearAllStudentAbsences(t)"></i
                ></span>
              </th>
            </tr>
          </thead>
          <tr v-for="(s, idx2) in attendanceDataStudents" :key="`student${idx2}`">
            <td>{{ s.nafn }}</td>
            <td>{{ s.kennitala }}</td>
            <td v-for="(att, idx3) in getStudentAttendance(s.nemandi_id)" :key="`student${idx2}att${idx3}`">
              <div v-if="type === 0">
                <input
                  class="d-inline-block"
                  type="text"
                  v-model="att.tegund_fjarvista"
                  maxLength="1"
                  style="width: 35px; height: 25px; padding-left: 3px"
                  :class="{
                    attendanceInvalidInput: !attendanceValid(att.tegund_fjarvista),
                    attendanceValidInput: attendanceValid(att.tegund_fjarvista),
                  }"
                />
                <div class="d-inline-block pl-1" v-if="att.tegund_forfalla" v-b-tooltip :title="att.heiti_forfoll" style="width: 20px">
                  {{ att.tegund_forfalla }}
                </div>
              </div>
              <div v-if="type === 1">
                <div class="d-inline-block pr-1" style="width: 20px">{{ att.tegund_fjarvista }}</div>
                <input
                  class="d-inline-block"
                  type="text"
                  v-model="att.tegund_forfalla"
                  maxLength="1"
                  style="width: 35px; height: 25px; padding-left: 3px"
                  :class="{
                    attendanceInvalidInput: !absenceValid(att.tegund_forfalla),
                    attendanceValidInput: absenceValid(att.tegund_forfalla),
                  }"
                />
                <span v-if="att.fjarv_forfoll_id && att.teg_forfalla_id" title="Hreinsa út forföll" v-b-tooltip
                  ><i class="fa fa-trash text-primary pl-1 cursor-pointer" @click="clearStudentAbsence(att)"></i
                ></span>
              </div>
            </td>
          </tr>
        </table>
        <b-btn variant="primary" class="mb-3" :disabled="invalidCode() || saving" @click="saveDayAttendance()">
          <i class="fa fa-spin fa-spinner" v-if="saving"></i>
          Staðfesta <span v-if="type === 0">fjarvistir</span><span v-else>forföll</span>
        </b-btn>
        <div style="font-size: 13px">
          <b>Fjarvistarkóðar:</b>
          <div v-for="a in attendanceCodes" :key="a.teg_fjarvista" class="pl-2 d-inline-block">{{ a.kodi }} - {{ a.heiti }}</div>
        </div>
        <div style="font-size: 13px" class="mt-2">
          <b>Forfallakóðar:</b>
          <div v-for="a in absenceCodes" :key="a.teg_forfalla" class="pl-2 d-inline-block">{{ a.kodi }} - {{ a.heiti }}</div>
        </div>
      </div>
    </div>
  </Slidebar>
</template>

<script>
import moment from 'moment';
import types from '@/api/types';
import groups from '@/api/groups';
import attendance from '@/api/attendance';
import Slidebar from '@/components/common/Slidebar.vue';
import Loader from '@/components/common/Loader.vue';
import { mapGetters, mapActions } from 'vuex';
import Datepicker from 'vuejs-datepicker';
import { is } from 'vuejs-datepicker/dist/locale';
import MaskedInput from 'vue-masked-input';

export default {
  name: 'group-register-attendance-slidebar',
  props: ['groupId', 'type'],
  components: {
    Loader,
    Slidebar,
    Datepicker,
    MaskedInput,
  },
  computed: {
    periodLength() {
      if (this.form.dateFrom && this.form.dateTo) {
        return moment(this.form.dateTo).diff(moment(this.form.dateFrom), 'days');
      }
      return 0;
    },
    getTypes() {
      return this.type === 0 ? this.attendanceCodes : this.absenceCodes;
    },
    attendanceDataStudents() {
      return this.attendanceData.length > 0 ? this.attendanceData[0].nemandalisti : [];
    },
    selectedStudents() {
      return this.students.filter(x => x.selected);
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      lang: is,
      period: false,
      loading: { attendanceCodes: false, absenceCodes: false, group: false, students: false, attendanceData: false },
      submitted: false,
      attendanceCodes: [],
      absenceCodes: [],
      students: [],
      options: [],
      saving: false,
      form: {
        dateFrom: moment().toDate(),
        timeFrom: '',
        dateTo: moment().toDate(),
        timeTo: '',
        type: '',
        comment: '',
        onlyThisOne: '',
      },
      date: moment().toDate(),
      attendanceData: [],
    };
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    async loadAttendance() {
      try {
        this.loading.attendanceCodes = true;
        const response = await types.getAttendanceList({ active: 1 });
        this.attendanceCodes = response.data.items.map(x => ({
          ...x,
          label: `${x.kodi} - ${x.heiti}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.attendanceCodes = false;
      }
    },
    async loadAbsence() {
      try {
        this.loading.absenceCodes = true;
        const response = await types.getAbsenceList({ active: 1 });
        this.absenceCodes = response.data.items.map(x => ({
          ...x,
          label: `${x.kodi} - ${x.heiti}`,
        }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.absenceCodes = false;
      }
    },
    async loadGroup() {
      try {
        this.loading.group = true;
        const response = await groups.getGroupDetails(this.groupId);
        this.group = response.data;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.group = false;
      }
    },
    async loadStudents() {
      try {
        this.loading.students = true;
        const response = await groups.getStudentList(this.groupId, {
          dags: moment().format('DD.MM.YYYY'),
        });
        this.students = response.data.items.map(x => ({ ...x, selected: true }));
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.students = false;
      }
    },
    async validateBeforeSubmit() {
      try {
        this.submitted = true;
        this.saving = true;
        await this.$validator.validateAll().then(response => {
          if (!response) throw 'FormValidationError'; // eslint-disable-line no-throw-literal, max-len
        });
        // Engar villur í formi.
        try {
          const item = {
            nemendur: this.selectedStudents.map(x => x.nemandi_id).join(','),
            afangi: this.form.onlyThisOne.id === 0 ? this.group.namsgrein_afanganr : '',
            dags_fra: moment(this.form.dateFrom).format('DD.MM.YYYY'),
            timi_fra: this.form.timeFrom ? this.form.timeFrom : '00:00',
            dags_til: moment(this.form.dateTo).format('DD.MM.YYYY'),
            timi_til: this.form.timeTo ? this.form.timeTo : '23:59',
            teg_fjarvista: this.type === 0 ? this.form.type.teg_fjarvista : '',
            teg_forfalla: this.type === 1 ? this.form.type.teg_forfalla : '',
            athugasemd: this.form.comment,
          };
          const response = await attendance.registerAttendance(item);
          let text = '';
          if (this.type === 0) {
            text = `${response.data.count} fjarvistir skráðar.`;
          } else {
            text = `${response.data.count} forföll skráð.`;
          }

          this.displaySuccess(text);
          this.$emit('closeAndReloadSlidebar');
        } catch (e) {
          // Aðgerð mistókst.
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      } catch (e) {
        // Villur í formi.
        this.$log.debug(e);
      } finally {
        this.saving = false;
      }
    },
    async getAttendanceData() {
      try {
        this.loading.attendanceData = true;
        const response = await attendance.getRegisterGroupAttendanceData({
          id: this.groupId,
          date: moment(this.date).format('DD.MM.YYYY'),
        });
        this.attendanceData = response.data.items;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.attendanceData = false;
      }
    },
    getStudentAttendance(student) {
      const data = [];
      this.attendanceData.forEach(day => {
        const item = day.nemandalisti.filter(x => x.nemandi_id === student);
        data.push(item.length > 0 ? item[0] : {});
      });
      return data;
    },
    checkGetData() {
      if (!this.period && this.attendanceData.length === 0) {
        this.getAttendanceData();
      }
    },
    invalidCode() {
      return (
        this.attendanceData.filter(
          x => x.nemandalisti.filter(y => !this.attendanceValid(y.tegund_fjarvista) || !this.absenceValid(y.tegund_forfalla)).length > 0,
        ).length > 0
      );
    },
    attendanceValid(code) {
      return !code || this.attendanceCodes.find(x => (x.kodi || '').toUpperCase() === code.toUpperCase());
    },
    absenceValid(code) {
      return !code || this.absenceCodes.find(x => (x.kodi || '').toUpperCase() === code.toUpperCase());
    },
    absenceExists(day) {
      return day.nemandalisti.filter(x => x.fjarv_forfoll_id && x.teg_forfalla_id).length > 0;
    },
    updateAll(code, day) {
      day.nemandalisti.forEach(d => {
        if (this.type === 0) {
          this.$set(d, 'tegund_fjarvista', code);
        } else if (this.type === 1) {
          this.$set(d, 'tegund_forfalla', code);
        }
      });
    },
    async saveDayAttendance() {
      try {
        this.saving = true;
        const data = [];
        this.attendanceData.forEach(a => {
          a.nemandalisti.forEach(n => {
            const item = {
              ferill_id: n.ferill_id,
              stofntafla_id: a.stofntafla_id,
              stundatafla_id: a.stundatafla_id,
              dags: moment(a.day).format('DD.MM.YYYY'),
              athugasemd: n.athugasemd,
            };
            if (this.type === 0) {
              item.teg_fjarvista_id = (
                this.attendanceCodes.find(x => (x.kodi || '').toUpperCase() === (n.tegund_fjarvista || '').toUpperCase()) || {
                  tegund_fjarvista: '',
                }
              ).teg_fjarvista;
            } else {
              item.teg_forfalla_id = (
                this.absenceCodes.find(x => (x.kodi || '').toUpperCase() === (n.tegund_forfalla || '').toUpperCase()) || {
                  teg_forfalla: '',
                }
              ).teg_forfalla;
            }
            data.push(item);
          });
        });
        await attendance.saveGroupAttendanceData(data, 0);
        const text = this.type === 0 ? 'Fjarvistir skráðar' : 'Forföll skráð';

        this.displaySuccess(text);
        this.$emit('closeAndReloadSlidebar');
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.saving = false;
      }
    },
    async clearStudentAbsence(student) {
      try {
        this.saving = true;
      const conf = confirm(`Ertu viss um að þú viljir hreinsa út forföll fyrir ${student.nafn}?`); // eslint-disable-line
        if (conf) {
          await attendance.clearAbsence(student.fjarv_forfoll_id);
          this.displaySuccess('Forföll hreinsuð út');
          this.getAttendanceData();
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.saving = false;
      }
    },
    async clearAllStudentAbsences(day) {
      try {
        this.saving = true;
        const students = day.nemandalisti.filter(x => x.fjarv_forfoll_id && x.teg_forfalla_id);
        const conf = confirm(`Ertu viss um að þú viljir hreinsa út forföll fyrir ${students.length} nemendur ?`); // eslint-disable-line
        if (conf) {
          for (let i = 0; i < students.length; i += 1) {
            await attendance.clearAbsence(students[i].fjarv_forfoll_id); // eslint-disable-line
          }
          this.displaySuccess('Forföll hreinsuð út');
          this.getAttendanceData();
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.saving = false;
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  created() {
    this.loadGroup();
    this.loadStudents();

    this.loadAttendance();
    this.loadAbsence();
    this.getAttendanceData();

    if (this.type === 0) {
      this.form.onlyThisOne = { id: 0, label: 'Eingöngu skrá fjarvist fyrir þennan áfanga.' };
      this.options = [
        { id: 0, label: 'Eingöngu skrá fjarvist fyrir þennan áfanga.' },
        { id: 1, label: 'Skrá fjarvist fyrir alla áfanga.' },
      ];
    } else {
      this.form.onlyThisOne = { id: 0, label: 'Eingöngu skrá forföll fyrir þennan áfanga.' };
      this.options = [
        { id: 0, label: 'Eingöngu skrá forföll fyrir þennan áfanga.' },
        { id: 1, label: 'Skrá forföll fyrir alla áfanga.' },
      ];
    }
  },
};
</script>

<style lang="scss">
.attendanceInvalidInput {
  border: 1px solid red;
  color: red;
}
.attendanceValidInput {
  color: blue;
}
</style>
