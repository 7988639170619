<template>
  <tr>
    <td :colspan="colspan" class="table-loader" :class="{ 'text-center': center }">
      <Loader />
    </td>
  </tr>
</template>

<script>
import Loader from '@/components/common/Loader.vue';

export default {
  name: 'table-row-loader',
  props: ['colspan', 'center'],
  components: {
    Loader,
  },
};
</script>

<style lang="scss">
.table-loader {
  font-size: 30px;
}
</style>
