<template>
  <Slidebar :autoClose="true" @closeMe="closeSlidebar">
    <h4>Stofuleit</h4>
    <br />
    <ClassroomSearch @changeClassroom="closeSlidebar" :slidebar="true" />
  </Slidebar>
</template>

<script>
import Slidebar from '@/components/common/Slidebar.vue';
import ClassroomSearch from '@/components/school/school/buildingsclassrooms/ClassroomSearch.vue';

export default {
  name: 'classroom-search-slidebar',
  components: {
    Slidebar,
    ClassroomSearch,
  },
  methods: {
    closeSlidebar(item) {
      this.$emit('closeSlidebar', item);
    },
    editItem() {
      this.$emit('editItem');
    },
  },
};
</script>

<style lang="scss"></style>
