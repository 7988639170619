<template>
  <Slidebar @closeMe="closeSlidebar" :large="large" :extraLarge="extraLarge">
    <b-row>
      <b-col class="pl-0">
        <h5>Áfangar</h5>
      </b-col>
      <b-col>
        <h5 class="d-inline-block">
          <span v-if="type === 'division'">Undirskóli: {{ item.name }}</span>
          <span v-if="type === 'department'">Deild: {{ item.name }}</span>
          <span v-if="type === 'subject'">Námsgrein: {{ item.kodi }} {{ item.heiti }}</span>
        </h5>
      </b-col>
      <b-col class="text-right"> Fjöldi: {{ list.length }} </b-col>
    </b-row>
    <div class="float-right">
      <div class="d-inline-block mr-4"><b-form-checkbox v-model="show.precursor" class="d-inline-block"></b-form-checkbox> Undanfarar</div>
      <div class="d-inline-block"><b-form-checkbox v-model="show.equal" class="d-inline-block"></b-form-checkbox> Jafngildi</div>
    </div>
    <div v-if="type === 'department' && canEditStructure">
      <v-select
        id="newModule"
        name="newModule"
        v-model="selectedModule"
        :options="getAllModules"
        @input="addModule"
        ref="selectModuleDropdown"
        placeholder="Smelltu hér til að bæta við áfanga"
      >
      </v-select>
      <br />
    </div>
    <Loader v-if="loading" />
    <div v-if="list.length === 0" class="mb-4">Engir áfangar fundust</div>
    <table class="table" v-if="list.length > 0" style="font-size: 13px">
      <thead>
        <tr>
          <th>Auðkenni</th>
          <th>Heiti</th>
          <th>Undirsk.</th>
          <th>Einingar</th>
          <th>Þrep</th>
          <th v-if="show.precursor">Undanfari</th>
          <th v-if="show.equal">Jafngildi</th>
          <th v-if="type === 'department' && canEditStructure"></th>
        </tr>
        <tr class="table-search">
          <td colspan="100%" class="p-0">
            <b-form-input v-model="search" class="search mt-0 mb-0" placeholder="Heiti áfanga" ref="searchInput" @input="resetPage()" />
          </td>
        </tr>
      </thead>
      <tr v-for="m in getPaginatedList" :key="m.afangi_id">
        <td>{{ m.namsgrein_afanganr }}</td>
        <td>{{ m.afanga_heiti }}</td>
        <td>{{ m.undirsk_audk }}</td>
        <td>{{ m.einingar }}</td>
        <td>{{ m.trep }}</td>
        <td v-if="show.precursor" style="font-size: 12px">
          <div v-for="(undanfari, index) in m.undanfarar" :key="index">
            {{ undanfari.undirsk_audk }} - {{ undanfari.namsgrein_afanganr }}
          </div>
        </td>
        <td v-if="show.equal" style="font-size: 12px">
          <div v-for="(jafngildi, index) in m.jafngildi" :key="index">
            {{ jafngildi.undirsk_audk }} - {{ jafngildi.namsgrein_afanganr }}
          </div>
        </td>
        <td v-if="type === 'department' && canEditStructure">
          <i class="fa fa-unlock cursor-pointer" @click="removeFromDepartment(m.afangi_id)" title="Aftengja" v-b-tooltip />
        </td>
      </tr>
    </table>
    <Pagination
      :currentPage="currentPage"
      :totalRows="getList.length"
      :perPage="perPage"
      v-if="list.length > 20"
      @change-page="changePage"
      @change-limit="changeLimit"
    />
  </Slidebar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import curriculum from '@/api/curriculum';
import structure from '@/api/structure';
import Slidebar from '@/components/common/Slidebar.vue';
import Loader from '@/components/common/Loader.vue';
import Pagination from '@/components/common/Pagination.vue';

export default {
  name: 'module-slide-bar',
  props: ['item', 'type', 'large', 'extraLarge'], // type: division, department
  components: {
    Slidebar,
    Loader,
    Pagination,
  },
  computed: {
    getAllModules() {
      return this.all.filter(x => !this.list.find(y => y.afangi_id === x.afangi_id));
    },
    getList() {
      return this.list.filter(
        x =>
          !this.search ||
          (x.namsgrein_afanganr || '').toUpperCase().startsWith(this.search.toUpperCase()) ||
          (x.afanga_heiti || '').toUpperCase().startsWith(this.search.toUpperCase()),
      );
    },
    getPaginatedList() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.getList.slice(start, end);
    },
    canEditStructure() {
      return this.loggedInUserHasWritePermission('skolahald_uppbygging');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      perPage: 20,
      currentPage: 1,
      loading: false,
      loadingAll: false,
      list: [],
      all: [],
      search: '',
      selectedModule: '',
      show: {
        equal: false,
        precursor: false,
      },
    };
  },
  methods: {
    resetPage() {
      if (this.currentPage !== 1) {
        this.currentPage = 1;
      }
    },
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    editItem() {
      this.$emit('editItem');
    },
    async loadList(dontFocus) {
      try {
        this.list = [];
        this.loading = true;
        const params = {};
        if (this.type === 'division') {
          params.division = this.item.division_id;
        } else if (this.type === 'department') {
          params.department = this.item.department_id;
        } else if (this.type === 'subject') {
          params.subject = this.item.namsgrein_id;
        }
        const response = await curriculum.getModuleList(params);
        this.list = response.data.items.map(x => ({
          ...x,
          jafngildi: x.jafngildi ? JSON.parse(x.jafngildi) : [],
          undanfarar: x.undanfarar ? JSON.parse(x.undanfarar) : [],
        }));
        this.$nextTick(() => {
          if (this.$refs.searchInput && !dontFocus) {
            this.$refs.searchInput.focus();
          }
        });
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading = false;
      }
    },
    async loadAll() {
      try {
        this.all = [];
        this.loadingAll = true;
        const params = {
          active: 1,
        };
        const response = await curriculum.getModuleList(params);
        this.all = response.data.items;
        this.all.forEach(e => {
          this.$set(e, 'label', `${e.namsgrein_afanganr} - ${e.undirsk_audk}`);
        });
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loadingAll = false;
      }
    },
    async addModule() {
      try {
        if (this.selectedModule) {
          const data = {
            deild_id: this.item.department_id,
            afangi_id: this.selectedModule.afangi_id,
            delete: 0,
          };
          await structure.setDepartmentModule(data);
          this.selectedModule = '';
          const input = this.$refs.selectModuleDropdown.$el.querySelector('input');
          if (input) {
            input.focus();
          }

          this.loadList(true);
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async removeFromDepartment(moduleId) {
      try {
        const data = {
          deild_id: this.item.department_id,
          afangi_id: moduleId,
          delete: 1,
        };
        await structure.setDepartmentModule(data);

        this.loadList();
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    changePage(page) {
      this.currentPage = page;
    },
    changeLimit(limit) {
      this.perPage = limit;
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    item() {
      this.loadList();
    },
  },
  created() {
    this.loadList();
    if (this.type === 'department') {
      this.loadAll();
    }
  },
};
</script>

<style lang="scss"></style>
