<!-- Kladdi -->

<template>
    <div>
        <div>
            <b-row>
                <b-col sm="6" md="4" lg="4" xl="3" v-for="item in colors" :key="item.id">
                    <span :style="`background-color:${item.litur_hex}`" 
                    style="height: 15px; width: 15px; border-radius: 50%; display:inline-block; vertical-align: sub;
                    border: 0.5px solid grey; "></span>
                    <span style="font-size: 80%;padding-bottom:4px">
                        ({{ item.kodi }}) {{ item.heiti }}</span>
                </b-col>
            </b-row>
        </div>
        <Timetable :id="studentId" type="student" :attendanceColorCode=1 class="pt-5"></Timetable>
</div>
</template>

<script>



import { mapActions, mapGetters } from 'vuex';
import common from '@/api/common';
import Timetable from '@/components/common/Timetable.vue';

export default {
    name: 'student-attendance-records',
    props: ['studentId', 'type'],
    components: { Timetable },
    computed: {
        ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
    },
    data() {
        return {
            loading: false,
            colors: '',
        };
    },
    methods: {
        async getTimetableColours() {
            try {
                const response = await common.getTimetableColours();
                this.colors = response.data.items;
            } catch (e) {
                this.$log.error(e);
                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            }
        },
        ...mapActions({
            displayError: 'displayError',
            displaySuccess: 'displaySuccess',
        }),
    },
    created() {
        this.getTimetableColours();
    }
};
</script>
<style lang="scss">
</style>
