<template>
  <div>
    <b-row>
      <b-col class="pl-0">
        <TermModules
          v-if="tabIndex === 'modules'"
          :id="item.onn_id"
          :item="item"
          :division="item.undirskoli_id"
          :termPassed="termPassed"
        ></TermModules>
        <TermClassrooms v-if="tabIndex === 'classrooms'" :id="item.onn_id" :item="item" :termPassed="termPassed"></TermClassrooms>
        <TermHistory v-if="tabIndex === 'history' && item" :id="item.onn_id" :item="item"></TermHistory>
        <TermRestrictedChoice
          v-if="schoolWithGrades && tabIndex === 'restrictedSelection'"
          :id="item.onn_id"
          :termPassed="termPassed"
        ></TermRestrictedChoice>
        <div class="alert alert-info p-2" style="font-size: 13px" v-if="tabIndex === 'applicationSettings'">
          <div>
            <strong>Opna fyrir umsóknir í Menntagátt:</strong> Stilling þarf að vera Já svo hægt sé að sækja um skólann þegar ráðuneytið
            opnar fyrir umsóknir grunnskólanema. Ef dagsetning í "Umsóknartímabil eldri nemenda hefst" er ekki liðin birtist skólinn ekki í
            listanum fyrr en þá.
            <!-- Opnar einungis fyrir nemendur sem eru ekki að koma beint úr grunnskóla. -->
            <!-- Ráðuneytið stýrir opnun umsókna fyrir grunnskólanema. -->
          </div>
          <div><strong>Fjöldi nýnema:</strong> Fjöldi nýnema sem voru að ljúka grunnskóla og skólinn ætlar að innrita.</div>
          <div>
            <strong>Umsóknartímabil eldri nemenda:</strong> Dagsetning sem opnar fyrir umsóknir í gegnum vef Menntagáttar fyrir nemendur sem
            koma ekki beint úr 10. bekk. ath. að einnig þarf að fylla út "Umsóknartímabil eldri nemenda lýkur" svo skólinn birtist í
            listanum á menntagátt.
          </div>
        </div>
        <b-row v-if="tabIndex === 'settings' || tabIndex === 'applicationSettings'">
          <b-col class="pl-0">
            <ItemEdit
              v-for="(i, idx) in dataLeft"
              :key="`info${idx}`"
              :id="i.param"
              :name="i.param"
              :label="i.label"
              :item="item[i.param]"
              :maxLength="i.maxLength"
              :editStatus="edit"
              :editParam="i.param"
              :date="i.date"
              :number="i.number"
              :checkbox="i.checkbox"
              :dropdown="i.dropdown"
              :dropdownList="i.dropdownList"
              :required="i.required"
              :disabled="i.disabled || !canEdit"
              @toChange="toChange"
              :small="true"
              @toEdit="toggleEdit"
              @saveData="saveChanges()"
            />
          </b-col>
          <b-col>
            <ItemEdit
              v-for="(i, idx) in dataRight"
              :key="`info${idx}`"
              :id="i.param"
              :name="i.param"
              :label="i.label"
              :item="item[i.param]"
              :maxLength="i.maxLength"
              :editStatus="edit"
              :editParam="i.param"
              :date="i.date"
              :number="i.number"
              :checkbox="i.checkbox"
              :dropdown="i.dropdown"
              :dropdownList="i.dropdownList"
              :required="i.required"
              :disabled="i.disabled || !canEdit"
              @toChange="toChange"
              :small="true"
              @toEdit="toggleEdit"
              @saveData="saveChanges()"
            />
            <b-btn variant="primary" class="float-right" v-if="edit" @click="saveChanges()">Vista breytingar</b-btn>
            <b-btn variant="cancel" class="mr-2 float-right" v-if="edit" @click="closeEdit()">Hætta við</b-btn>
            <b-btn variant="primary" @click="showInfoSlidebar = !showInfoSlidebar" v-if="tabIndex === 'settings'"
              ><i class="fa fa-search"></i> Skoða útskýringar</b-btn
            >
            <b-btn variant="danger" class="ml-3" v-if="canEdit && !edit && tabIndex === 'settings'" @click="deleteTerm()"
              ><i class="fa fa-trash"></i> Eyða kennslutímabili</b-btn
            >
          </b-col>
        </b-row>
        <div v-if="tabIndex === 'copy'">
          <CopyTerm :id="item ? item.onn_id : ''" :divisionId="item ? item.undirskoli_id : ''"></CopyTerm>
        </div>
        <div v-if="tabIndex === 'flatten'">
          <change-term-type :item="item" @finishCopy="reloadTerm()"> </change-term-type>
        </div>

        <div v-if="tabIndex === 'applicationSettings' && canReadCourses">
          <b-link @click="showCourseApplication = !showCourseApplication">
            Skoða brautir sem má sækja um í menntagátt
            <i class="fa ml-2" :class="{ 'fa-angle-up': !showCourseApplication, 'fa-angle-down': showCourseApplication }" />
          </b-link>
          <div style="border: 1px solid #98139c; padding: 10px" v-if="showCourseApplication">
            <div style="margin-bottom: -10px">
              <i class="fa fa-info-circle"></i>
              Ef opið er fyrir umsóknir í Menntagátt er hægt að sækja um eftirfarandi brautir í þessum undirskóla, til að skoða brautir í
              öðrum undirskóla þarf að vera valið kennslutímabil í þeim undirskóla.
            </div>
            <CoursesApplicationList :divisionId="item.undirskoli_id" :innrit_menntagatt="true"></CoursesApplicationList>
          </div>
        </div>
      </b-col>
      <b-col cols="2">
        <div class="right-side-nav noprint">
          <b-nav vertical>
            <b-nav v-if="canEdit">
              <b-nav-item @click.prevent="changeTab('copy')" :active="tabIndex === 'copy'">Afrita frá öðru kennslutímabili</b-nav-item>
            </b-nav>
            <b-nav-item @click.prevent="changeTab('modules')" :active="tabIndex === 'modules'">Áfangar í boði</b-nav-item>
            <b-nav-item
              v-if="schoolWithGrades"
              @click.prevent="changeTab('restrictedSelection')"
              :active="tabIndex === 'restrictedSelection'"
              >Bundið val</b-nav-item
            >
            <b-nav v-if="!schoolHasSettings('stundatoflukerfi') && canEdit && item.can_change_type === 1">
              <b-nav-item @click.prevent="changeTab('flatten')" :active="tabIndex === 'flatten'">Fletja út stundatöflu</b-nav-item>
            </b-nav>
            <b-nav>
              <b-nav-item @click.prevent="changeTab('history')" :active="tabIndex === 'history'">Saga</b-nav-item>
            </b-nav>
            <b-nav-item
              @click.prevent="
                changeTab('settings');
                isApplication = false;
              "
              :active="tabIndex === 'settings'"
              >Stillingar</b-nav-item
            >
            <b-nav-item
              @click.prevent="changeTab('classrooms')"
              :active="tabIndex === 'classrooms'"
              v-if="!schoolHasSettings('stundatoflukerfi')"
              >Stofur í boði</b-nav-item
            >
            <b-nav-item
              @click.prevent="
                changeTab('applicationSettings');
                isApplication = true;
              "
              :active="tabIndex === 'applicationSettings'"
              >Umsóknir í menntagátt</b-nav-item
            >
          </b-nav>
        </div>
      </b-col>
    </b-row>

    <Slidebar @closeMe="showInfoSlidebar = false" :large="false" v-if="showInfoSlidebar">
      <h4>Útskýringar</h4>
      <strong>DAGSETNINGAR</strong>
      <ul style="font-size: 13px">
        <li v-if="!isApplication">
          Upphafs- og lokadagur kennslutímabils segir til um á hvaða tímabili kennslutímabilið er virkt og hægt er að vinna með það.
        </li>
        <li v-if="!isApplication">
          Stundatafla og áfangar birtast nemendum og kennurum um leið og kennslutímabil er virkt nema að sett sé dagssetning í stundatafla
          nema/kennara opnar dags. þá birtist hún þann/þá daga.
        </li>
        <li v-if="!isApplication">
          Ef skóli notar stundatöfluforritið til að búa til stundatöflur þá er hægt að nota kennsla hefst og kennsla lýkur ef ekki er kennt
          frá upphafsdagsetningu til lokadagsetningar kennslutímabils.
        </li>
        <li v-if="!isApplication">
          Ef „Lokað fyrir breytingar hjá kennurum“ er útfyllt þá geta almennir kennarar ekki lengur breytt hópunum sínum á kennslutímabilinu
          þ.m.t. einkunnum, annars geta þeir það fram að lokadegi kennslutímabils.
        </li>
        <li v-if="isApplication">
          Umsóknartímabil eldri nemenda er dagsetning sem opið er fyrir umsóknir í gegnum vef Menntagáttar fyrir nemendur sem koma ekki
          beint úr 10. bekk.
        </li>
      </ul>
      <strong>AÐRAR STÝRINGAR</strong>
      <ul style="font-size: 13px">
        <li v-if="!isApplication">Heiti kennslutímabils - Ensku: Er meðal annars notað þegar einkunnablöð eru prentuð á ensku.</li>
        <li v-if="!isApplication">
          Kennslutímabil læst: Ef kennslutímabil er læst þá er ekki hægt að vinna með það lengur þar sem það birtist ekki lengur í neinum
          fellilistum. Þetta er m.a. notað þegar stundatafla er ekki tilbúin eða kennslutímabil löngu liðið og fellilistinn mjög langur.
        </li>
        <li v-if="!isApplication">Matsönn: Kennslutímabil þar sem bara metnir áfangar eru skráðir á.</li>
        <li v-if="isApplication">
          Opna fyrir umsóknir í Menntagátt: Á einungis við nemendur sem eru ekki að koma beint úr grunnskóla. Ráðuneytið stýrir opnun
          umsókna fyrir grunnskólanema.
        </li>
        <li v-if="!isApplication">
          Fjöldi daga á viku sem kenndir eru skv. stundatöflu og Lengd kennslustundar (mínútur): Hefur áhrif á stundatöflugerð.
        </li>
        <li v-if="isApplication">Fjöldi nýnema: Fjöldi nýnema sem voru að ljúka grunnskóla, stilling fyrir Menntagátt.</li>
      </ul>
    </Slidebar>
  </div>
</template>

<script>
import moment from 'moment';
import structure from '@/api/structure';
import types from '@/api/types';
import terms from '@/api/terms';
import { mapActions, mapGetters } from 'vuex';

import ItemEdit from '@/components/common/ItemEdit.vue';

import TermModules from '@/components/school/terms/TermModules.vue';
import TermClassrooms from '@/components/school/terms/TermClassrooms.vue';
import TermRestrictedChoice from '@/components/school/terms/TermRestrictedChoice.vue';
import Slidebar from '@/components/common/Slidebar.vue';
import CopyTerm from '@/components/school/terms/CopyTerm.vue';
import ChangeTermType from '@/components/school/terms/ChangeTermType.vue';
import TermHistory from '@/components/school/terms/TermHistory.vue';
import CoursesApplicationList from '@/components/curriculum/courses/CoursesApplicationList.vue';

export default {
  name: 'term-details',
  components: {
    ItemEdit,
    TermModules,
    TermClassrooms,
    Slidebar,
    TermRestrictedChoice,
    CopyTerm,
    ChangeTermType,
    TermHistory,
    CoursesApplicationList,
  },
  props: ['item', 'termPassed'],
  computed: {
    dataLeft() {
      return this.isApplication ? this.applicationData.left : this.data.left;
    },
    dataRight() {
      return this.isApplication ? this.applicationData.right : this.data.right;
    },
    canRead() {
      return this.loggedInUserHasReadPermission('skolahald_annir');
    },
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_annir');
    },
    canReadCourses() {
      return this.loggedInUserHasReadPermission('namskra_brautir');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolWithGrades', 'schoolHasSettings']),
  },
  data() {
    return {
      showCourseApplication: true,
      editItem: {},
      edit: false,
      tabIndex: 'settings',
      page: 'detail',
      showInfoSlidebar: false,
      isApplication: false,
      applicationData: {
        left: [
          { param: 'ma_saekja_um', label: 'Opna fyrir umsóknir í Menntagátt', checkbox: true },
          { param: 'fj_nynema', label: 'Fjöldi nýnema', number: true },
          { param: 'fj_nema_alls', label: 'Fjöldi nema alls', number: true },
        ],
        right: [
          { param: 'umstimabil_eldri_fra', label: 'Umsóknartímabil eldri nemenda hefst', date: true },
          { param: 'umstimabil_eldri_til', label: 'Umsóknartímabil eldri nemenda lýkur', date: true },
        ],
      },
      data: {
        left: [
          { param: 'onn_eink', label: 'Einkenni kennslutímabils', maxLength: '6', required: true },
          { param: 'onn_heiti', label: 'Heiti kennslutímabils', maxLength: '40', required: true },
          { param: 'onn_heiti_enska', label: 'Heiti kennslutímabils - Enska', maxLength: '50' },
          { param: 'undirskoli_id', label: 'Undirskóli', dropdown: true, dropdownList: [], required: true },
          { param: 'tafla_laest', label: 'Kennslutímabil læst', checkbox: true },
          { param: 'tegund_id', label: 'Tegund kennslutímabils', dropdown: true, dropdownList: [] },
          { param: 'lengd_kennslustundar', label: 'Lengd kennslustundar (mínútur)', number: true },
          // { param: 'nam_a_ensku', label: 'Nám á ensku', checkbox: true },
          { param: 'fjoldi_tofludaga', label: 'Fjöldi daga á viku sem kenndir eru skv. stundatöflu', number: true },
          { param: 'matsonn', label: 'Matsönn', checkbox: true },
        ],
        right: [
          { param: 'dags_fra', label: 'Upphafsdagur kennslutímabils', date: true, required: true },
          { param: 'dags_til', label: 'Lokadagur kennslutímabils', date: true, required: true },
          { param: 'stundatafla_nem_opnar', label: 'Stundatafla nema opnar dags.', date: true },
          { param: 'stundatafla_kenn_opnar', label: 'Stundatafla kennara opnar dags.', date: true },
          { param: 'kennsla_hefst', label: 'Kennsla hefst', date: true },
          { param: 'kennslu_lykur', label: 'Kennslu lýkur', date: true },
          // { param: 'prof_byrja', label: 'Dags. byrjun prófa', date: true },
          // { param: 'prof_enda', label: 'Dags. prófloka', date: true },
          { param: 'utgafudags_profa', label: 'Loka fyrir breytingar kennara', date: true },
          { param: 'samr_onn', label: 'Samræmd tölfræðiönn', dropdown: true, dropdownList: [] },
        ],
      },
    };
  },
  methods: {
    reloadTerm() {
      this.$emit('reload');
    },
    toChange(item) {
      this.editItem[item.column] = item.value;
    },
    toggleEdit() {
      this.edit = true;
    },
    closeEdit() {
      this.edit = false;
    },
    changeTab(tab) {
      this.tabIndex = tab;
      this.$router.push({ name: 'Term', query: { page: this.page, tab: this.tabIndex } });
    },
    async loadDivisions() {
      if (this.canRead) {
        try {
          const response = await structure.getDivisionList();
          this.data.left[3].dropdownList = response.data.items.map(x => ({
            value: x.division_id,
            text: `${x.code} - ${x.name}`,
          }));
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    async loadCoordinatedTerms() {
      if (this.canRead) {
        try {
          const response = await types.getCoordinatedTermsList();
          this.data.right[7].dropdownList = response.data.items.map(x => ({
            value: x.samr_onn,
            text: `${x.samr_onn} - ${x.samr_onn_heiti}`,
          }));
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    async loadTypes() {
      if (this.canRead) {
        try {
          const response = await types.getTermList();
          this.data.left[5].dropdownList = response.data.items.map(x => ({
            value: x.tegund_id,
            text: `${x.tegund_heiti}`,
          }));
        } catch (e) {
          this.$log.error(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    async saveChanges() {
      try {
        const data = {
          ...this.editItem,
          teg_annar: this.editItem.tegund_id,
          dags_fra: this.editItem.dags_fra ? moment(this.editItem.dags_fra).format('DD.MM.YYYY') : '',
          dags_til: this.editItem.dags_til ? moment(this.editItem.dags_til).format('DD.MM.YYYY') : '',
          // prof_byrja: this.editItem.prof_byrja ? moment(this.editItem.prof_byrja).format('DD.MM.YYYY') : '',
          // prof_enda: this.editItem.prof_enda ? moment(this.editItem.prof_enda).format('DD.MM.YYYY') : '',
          utgafudags_profa: this.editItem.utgafudags_profa ? moment(this.editItem.utgafudags_profa).format('DD.MM.YYYY') : '',
          stundatafla_nem_opnar: this.editItem.stundatafla_nem_opnar
            ? moment(this.editItem.stundatafla_nem_opnar).format('DD.MM.YYYY')
            : '',
          stundatafla_kenn_opnar: this.editItem.stundatafla_kenn_opnar
            ? moment(this.editItem.stundatafla_kenn_opnar).format('DD.MM.YYYY')
            : '',
          umstimabil_eldri_fra: this.editItem.umstimabil_eldri_fra ? moment(this.editItem.umstimabil_eldri_fra).format('DD.MM.YYYY') : '',
          umstimabil_eldri_til: this.editItem.umstimabil_eldri_til ? moment(this.editItem.umstimabil_eldri_til).format('DD.MM.YYYY') : '',
          kennsla_hefst: this.editItem.kennsla_hefst ? moment(this.editItem.kennsla_hefst).format('DD.MM.YYYY') : '',
          kennslu_lykur: this.editItem.kennslu_lykur ? moment(this.editItem.kennslu_lykur).format('DD.MM.YYYY') : '',
        };
        await terms.updateTerm(this.item.onn_id, data);
        this.$emit('reload');
        this.edit = false;
      } catch (e) {
        // Aðgerð mistókst.
        this.$log.debug(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async deleteTerm() {
      const conf = confirm(`Ert þú viss um að eyða kennslutímabilinu ${this.item.onn_eink}?`); // eslint-disable-line
      if (conf) {
        try {
          await terms.updateTerm(this.item.onn_id, { deleted: 1 });
          this.displaySuccess('Kennslutímabili eytt.');
          this.$router.push({ name: 'Terms' });
        } catch (e) {
          this.$log.error(e);
          if (e.response && e.response.data && e.response.data.error) {
            this.displayError(e.response.data.error);
          } else {
            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
          }
        }
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    item() {
      this.editItem = { ...this.item };
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (to && to.query && to.query.page) {
      this.page = to.query.page;
    }
    if (to && to.query && to.query.tab) {
      this.tabIndex = to.query.tab;
    }
    next();
  },
  created() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
    if (this.$route.query.tab) {
      this.tabIndex = this.$route.query.tab;
      if (this.tabIndex === 'applicationSettings') {
        this.isApplication = true;
      }
    }
    this.editItem = { ...this.item };
    this.loadDivisions();
    this.loadCoordinatedTerms();
    this.loadTypes();
  },
};
</script>
