<template>
  <Slidebar :large="true" @closeMe="closeSlidebar">
    <h4>Breyta beiðni</h4>
    <table class="table">
      <tr>
        <th>Nemandi</th>
        <td>{{ item.nafn }}</td>
      </tr>
      <tr>
        <th>Kennitala</th>
        <td>{{ item.kennitala }}</td>
      </tr>
      <tr>
        <th>Staða</th>
        <td>{{ item.stada_heiti }}</td>
      </tr>
      <tr>
        <th>Tegund</th>
        <td>
          <div v-if="!item.onn_eink && !item.namsgrein_afanganr">Almenn sérúrræði</div>
          <div v-else>Sérúrræði tengd áfanga</div>
        </td>
      </tr>
      <tr v-if="item.onn_eink">
        <th>Önn</th>
        <td>{{ item.onn_eink }}</td>
      </tr>
      <tr v-if="item.namsgrein_afanganr">
        <th>Áfangi</th>
        <td>{{ item.namsgrein_afanganr }}</td>
      </tr>
      <tr v-for="type in types" :key="type.teg_serurraedi_id">
        <th>{{ type.heiti }}</th>
        <td>
          <b-form-checkbox v-model="type.selected" v-if="type.tegund === 0"></b-form-checkbox>
          <b-form-input v-model="type.text" v-if="type.tegund === 1" style="height: 30px; font-size: 13px"></b-form-input>
        </td>
      </tr>
    </table>
    <b-btn variant="primary" @click="saveData()">Vista</b-btn>
    <b-btn variant="danger" class="float-right" @click="deleteRequest()">Eyða</b-btn>
  </Slidebar>
</template>

<script>
import requests from '@/api/requests';
import types from '@/api/types';
import Slidebar from '@/components/common/Slidebar.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'edit-special-resource-request-slidebar',
  props: ['id'],
  components: {
    Slidebar,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('beidnir_serurraedi');
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {
      types: [],
      item: '',
      loading: {
        types: false,
        item: false,
      },
      statuses: [
        { id: 0, label: 'Óafgreidd' },
        { id: 1, label: 'Samþykkt' },
        { id: 2, label: 'Í bið' },
        { id: 3, label: 'Hafnað' },
      ],
    };
  },
  methods: {
    async loadItem() {
      try {
        this.item = '';
        this.loading.item = true;
        const response = await requests.getSpecialResources({ id: this.id });
        const x = response.data.items[0];
        this.item = {
          ...x,
          tegundir: x.tegundir ? JSON.parse(x.tegundir) : [],
          stada_heiti: this.statuses.find(y => y.id === x.stada) ? this.statuses.find(y => y.id === x.stada).label : '',
        };
        this.loadTypes();
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.item = false;
      }
    },
    async loadTypes() {
      try {
        this.types = [];
        this.loading.types = true;
        const response = await types.getSpecialResourceList();
        this.types = response.data.items;
        this.types.forEach(t => {
          this.$set(t, 'selected', false);
          this.$set(t, 'text', '');
          const item = (this.item.tegundir || []).find(x => x.teg_serurraedi_id === t.teg_serurraedi_id);
          if (t.tegund === 0 && item) {
            this.$set(t, 'selected', true);
          }
          if (t.tegund === 1 && item) {
            this.$set(t, 'text', item.text);
          }
        });
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.types = false;
      }
    },
    async saveData() {
      try {
        const item = {
          types: this.types
            .filter(x => (x.tegund === 0 && x.selected) || (x.tegund === 1 && (x.text || '').length > 0))
            .map(x => ({
              id: x.teg_serurraedi_id,
              text: x.text,
            })),
        };

        await requests.updateStudentSpecialResources(this.item.nemandi_serurraedi_id, item);
        this.displaySuccess('Beiðni breytt');
        this.$emit('closeAndReload');
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    async deleteRequest() {
      try {
        const conf = confirm(`Ert þú viss um að þú viljir eyða beiðninni ? \nEkki verður sendur póstur.`); // eslint-disable-line
        if (conf) {
          await requests.massEditSpecialResources({
            deleted: 1,
            ids: this.item.nemandi_serurraedi_id.toString(),
          });

          this.displaySuccess('Beiðni eytt');
          this.$emit('closeAndReload');
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      }
    },
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    closeAndReloadSlidebar() {
      this.$emit('closeAndReload');
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
  watch: {
    id() {
      this.loadItem();
    },
  },
  created() {
    this.loadItem();
  },
};
</script>

<style lang="scss"></style>
