<template>
  <Slidebar :autoClose="true" @closeMe="closeSlidebar" :large="large" :extraLarge="extraLarge">
    <h4>Kennslutímabil</h4>
    <br />
    <TermSearch @changeTerm="closeSlidebar" :slidebar="true" />
  </Slidebar>
</template>

<script>
import Slidebar from '@/components/common/Slidebar.vue';
import TermSearch from '@/components/school/terms/TermSearch.vue';

export default {
  name: 'term-search-slidebar',
  components: {
    Slidebar,
    TermSearch,
  },
  props: ['large', 'extraLarge'],
  methods: {
    closeSlidebar(item) {
      this.$emit('closeSlidebar', item);
    },
  },
};
</script>

<style lang="scss"></style>
