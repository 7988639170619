<template>
  <Slidebar @closeMe="closeSlidebar" :large="true">
    <h4>Viðvera nemanda</h4>
    <StudentInfo v-if="studentId" :id="studentId" style="margin-bottom: -10px" />
    <StudentAttendance v-if="studentId" :studentId="studentId" :sideBarView=1 :termId="termId"></StudentAttendance>
  </Slidebar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Slidebar from '@/components/common/Slidebar.vue';
import StudentAttendance from '@/components/students/attendance/StudentAttendance.vue';
import StudentInfo from '@/components/common/StudentInfo.vue';

export default {
  name: 'student-attendance-slidebar',
  props: ['studentId', 'termId'],
  components: {
    StudentAttendance,
    Slidebar,
    StudentInfo,
  },
  computed: {
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission']),
  },
  data() {
    return {};
  },
  methods: {
    closeSlidebar() {
      this.$emit('closeSlidebar');
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>

<style lang="scss"></style>
