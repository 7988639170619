<template>
    <div>
        <b-row>
            <b-col class="pl-0 pt-2 pb-2" sm="4" xs="4" md="4" lg="3">
                <label for="term" class="font-weight-bold">Kennslutímabil</label>
                <v-select id="onn_id" name="onn_id" v-model="selectedTerm" :clearable="false" :options="terms"
                    label="onn_eink" @input="getMidtermNames(); getStudentMidtermInfo()"> </v-select>
            </b-col>
            <b-col v-if="scale.length > 0">
                <label for="term" class="font-weight-bold">Miðannarskali</label>
                <div v-for="(x, idx) in scale" :key="idx">
                    {{ x.einkunn }} -
                    <span style="font-size:90%" v-html="x.lysing"></span>
                </div>
            </b-col>
        </b-row>
        <Loader v-if="loading.terms"></Loader>
        <b-card>
            <table class="table less-padding table-bordered-header" style="font-size: 13px;max-width:97%">
                <Loader v-if="loading.data"></Loader>

                <thead>
                    <tr>
                        <th>Námsgrein ({{ data.length }})</th>
                        <th>Áfangi</th>
                        <th>Einingar</th>
                        <th>Mætingarhlutfall</th>
                        <th v-for="(s, idx) in midTermHead" :key="idx"> {{ s.heiti }}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody v-for="(d, index) in data" :key="index">
                    <tr v-if="saving">
                        <td colspan="100%">
                            <Loader></Loader>
                        </td>
                    </tr>
                    <tr v-if="!saving && !loading.data" style="border-width: 1px">
                        <td> {{ d.namsgrein_heiti }} </td>
                        <td> {{ d.namsgrein_afanganr }} </td>
                        <td> {{ d.einingar }} </td>
                        <td> {{ d.maeting }} <span v-if="d.maeting">%</span> </td>
                        <td v-for="(s, index) in d.grades" :key="index">
                            <span v-if="!d.edit && !loading.data">{{ s.einkunn }}
                                <div v-if="s.athugasemd">
                                    <i class="fa fa-comment" v-b-tooltip title="Athugasemd"></i>
                                    {{ s.athugasemd }}
                                </div>
                            </span>
                            <span v-if="d.edit">
                                <b-form-group v-if="scale.length > 0">
                                    <v-select v-model="s.einkunn" id="einkunn" name="einkunn"
                                        :options="scale"></v-select></b-form-group>
                                <b-form-textarea v-b-tooltip title="Athugasemd" v-model="s.athugasemd" class="mt-2"
                                    max="4000" style="height: 100px; padding: 0px 0px 0px 5px; font-size: 13px"
                                    rows="3"></b-form-textarea>
                            </span>
                        </td>
                        <td style="color:#98139c"> <i class="fa fa-pencil cursor-pointer pr-2"
                                v-if="!d.edit && midTermHead.length > 0" v-b-tooltip title="Breyta"
                                @click.prevent="toggleEditLine(d)"></i>
                            <i class="fa fa-times cursor-pointer pr-2" v-if="d.edit" v-b-tooltip title="Hætta við"
                                @click.prevent="toggleEditLine(d)"></i>
                            <b-btn variant="primary" size="sm" v-if="d.edit" @click="saveLine(d)">Vista</b-btn>

                        </td>
                    </tr>
                </tbody>
            </table>
            <b-row class="pt-2">
                <b-col class="pl-0" v-if="units">
                    Samtals einingar á önn: <b>{{ units }}</b>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="pl-0" v-if="attendanceSum">
                    Skólasókn: <b>{{ attendanceSum }} %</b>
                </b-col>
            </b-row>
        </b-card>

    </div>
</template>
  
<script>
import students from '@/api/students';
import { mapActions, mapGetters } from 'vuex';
import { is } from 'vuejs-datepicker/dist/locale';

import Loader from '@/components/common/Loader.vue';
import school from '@/api/school';

export default {
    name: 'student-midtermgrades',
    props: ['studentId'],
    components: {
        Loader,
    },
    computed: {
        canEdit() {
            return this.loggedInUserHasWritePermission('nemendahald_nemendur');
        },
        canReadSchool() {
            return this.loggedInUserHasReadPermission('skolahald_uppbygging');
        },
        ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolSettings']),
    },
    data() {
        return {
            lang: is,
            loading: {
                terms: false,
                thead: false,
                data: false,
            },
            term: {
                id: '',
                dateFrom: '',
                dateTo: '',
                name: '',
            },
            units: '',
            attendanceSum: '',
            terms: [],
            midTermHead: [],
            selectedTerm: '',
            data: [],
            scale: [],
            editAll: false,
            saving: false,

        };
    },
    methods: {
        async getStudentDefaultTerm() {
            try {
                this.noTerm = false;
                const response = await students.getStudentDefaultTerm(this.studentId);
                this.term.id = response.data.onn_id;
                this.term.dateFrom = response.data.dags_fra;
                this.term.dateTo = response.data.dags_til;
                this.term.name = response.data.onn_eink;
                this.loadTerms();
            } catch (e) {
                if (e.response.status === 404) {
                    this.noTerm = true;
                    this.loadTerms(); // sækjum samt annir
                } else {
                    this.$log.error(e);
                    this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
                }
            }
        },
        // sækja allar annir
        async loadTerms() {
            this.loading.terms = true;
            try {
                const response = await students.getStudentTerms(this.studentId, { future_terms: 0, past_terms: 1 });
                this.terms = response.data.items;
                if (this.term.id) {
                    this.selectedTerm = this.terms.find(x => x.onn_id === this.term.id);
                } else {
                    this.selectedTerm = this.terms[0];
                }
                if (this.selectedTerm) {
                    this.getMidtermNames();
                    this.getStudentMidtermInfo();
                    this.loadScale();
                    this.getStudentAttendanceSum();
                    this.getStudentUnits();
                }

            } catch (e) {
                this.$log.error(e);
                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            } finally {
                this.loading.terms = false;
            }
        },
        async getMidtermNames() {
            try {
                this.loading.thead = true;
                const response = await school.getMidtermNames();
                this.midTermHead = response.data.items;
            } catch (e) {
                this.$log.error(e);
                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            } finally {
                this.loading.thead = false;
            }
        },
        async getStudentMidtermInfo() {
            try {
                this.loading.data = true;
                const params = {
                    'termId': this.selectedTerm.onn_id,
                }
                const response = await students.getStudentMidtermInfo(this.studentId, params);
                this.data = response.data.items.map(x => ({
                    ...x,
                    edit: false,
                }));
            } catch (e) {
                this.$log.error(e);
                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            } finally {
                this.loading.data = false;
            }
        },
        async getStudentAttendanceSum() {
            try {
                const response = await students.getStudentAttendanceSum(this.studentId, this.selectedTerm.onn_id);
                this.attendanceSum = response.data.result;
            }
            catch (e) {
                this.$log.error(e);
                this.displayError(`Óvænt villa að sækja skólasókn. Villa (${e.response ? e.response.status : -1}) kom upp. 
                Vinsamlegast reyndu aftur.`);
            }
        },
        async getStudentUnits() {
            try {
                const response = await students.getStudentUnits(this.studentId, this.selectedTerm.onn_id);
                if (response.data.items.length > 0) {
                    this.units = response.data.items[0].sum_ein;
                }
            }
            catch (e) {
                this.$log.error(e);
                this.displayError(`Óvænt villa  að sækja einingar á önn. Villa (${e.response ? e.response.status : -1}) kom upp. 
                Vinsamlegast reyndu aftur.`);
            }
        },
        async loadScale() {
            try {
                const response = await school.getMidtermGradeScale();
                this.scale = response.data.items.map(x => ({
                    ...x,
                    label: x.einkunn,
                }))
            } catch (e) {
                this.$log.error(e);
                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            }
        },
        /* eslint-disable no-param-reassign */

        async saveLine(g) {
            try {
                this.saving = true;
                const promises = [];
                for (let i = 0; i < g.grades.length; i += 1) {
                    const data = {
                        ferillId: g.ferill_id,
                        tegundEinkunnarId: g.grades[i].teg_eink,
                        einkunn: g.grades[i].einkunn === null ? '' : (g.grades[i].einkunn.einkunn || g.grades[i].einkunn),
                        athugasemd: g.grades[i].athugasemd
                    }
                    promises.push(students.postStudentMidtermGrade(this.studentId, data));
                }
                Promise.all(promises).then((response) => {
                    response.forEach(item => {
                        if (item.data.errorText) {
                            this.displayError(item.data.errorText);
                        }
                    })
                    if (response.data && response.data.errorText) {
                        this.displayError(response.data.errorText);
                    } else {
                        this.displaySuccess('Miðannarmat hefur verið uppfært geg');
                    }
                    this.saving = false;
                    g.edit = !g.edit;
                    this.getStudentMidtermInfo(); // spurning hvort það sæki bara línuna?
                },
                    (e) => {
                        if (e.response && e.response.data && e.response.data.error) {
                            this.displayError(e.response.data.error);
                        } else {
                            this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. 
                                Vinsamlegast reyndu aftur.`);
                        }
                        this.saving = false;
                        g.edit = !g.edit;
                        this.getStudentMidtermInfo();
                    }
                );

            } catch (e) {
                this.$log.error(e);
                this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
            }

        },
        toggleEditLine(line) {
            this.$set(line, 'edit', !line.edit);
        },
        ...mapActions({
            displayError: 'displayError',
            displaySuccess: 'displaySuccess',
        }),
    },
    created() {
        this.getStudentDefaultTerm();
    },
};
</script>
<style></style>
  