<template>
  <tr
    class="grade-rule-list-item grade-rule-font-size"
    :class="{
      grB1: item.lev === 1,
      grB2: item.lev === 2,
      grB3: item.lev === 3,
      grB4: item.lev === 4,
      grB5: item.lev === 5,
      redErrorText: subgradesInvalid(),
    }"
  >
    <td>{{ index }}.</td>
    <td>
      <div
        :class="{
          grP1: item.lev === 1,
          grP2: item.lev === 2,
          grP3: item.lev === 3,
          grP4: item.lev === 4,
          grP5: item.lev === 5,
        }"
      >
        <inline-edit
          :doEdit="editField.heiti"
          :showPencil="false"
          @editField="toggleEdit('heiti')"
          @saveField="saveField('heiti')"
          :canEdit="edit && canEdit"
          class="d-inline-block"
          style="vertical-align: middle"
        >
          <span slot="content">
            {{ item.heiti }}
            <i
              class="fa fa-exclamation-triangle"
              v-if="subgradesInvalid()"
              v-b-tooltip
              :title="`Undireinkunnir vega ${getSubgradesWeight()}%. Þær verða að vera 100%`"
            ></i>
          </span>
          <span slot="input">
            <b-form-input
              v-if="isGroup"
              id="heiti"
              name="heiti"
              v-model="form.heiti"
              v-on:keyup.enter="saveField('heiti')"
              maxlength="200"
              style="width: 200px"
            >
            </b-form-input>
            <v-select
              v-else
              id="tegund_einkunnar"
              name="tegund_einkunnar"
              v-model="form.tegund_einkunnar"
              :options="gradeTypes"
              label="heiti"
              :clearable="false"
              style="width: 200px"
            >
            </v-select>
            <Loader v-if="loading.gradeTypes" />
          </span>
        </inline-edit>
        <div class="d-inline-block" style="vertical-align: middle" v-if="!editField.heiti">
          <b-btn
            variant="primary"
            @click="openSlidebar(true)"
            class="ml-2"
            style="margin-top: -5px"
            title="Bæta við undireinkunnum"
            v-b-tooltip
            v-if="edit && canEdit"
            ><i class="fa fa-plus-circle"></i
          ></b-btn>
        </div>
      </div>
    </td>
    <td>
      <inline-edit
        :doEdit="editField.reiknud_skrad"
        :showPencil="false"
        @editField="toggleEdit('reiknud_skrad')"
        @saveField="saveField('reiknud_skrad')"
        :canEdit="edit && canEdit"
      >
        <span slot="content">
          <span v-if="item.teg_einkunnar === 1"><i class="fa fa-pencil"></i> Skráð</span>
          <span v-else><i class="fa fa-calculator"></i> Reiknað</span>
        </span>
        <span slot="input">
          <v-select
            id="reiknud_skrad"
            name="reiknud_skrad"
            v-model="form.reiknud_skrad"
            :options="types"
            :clearable="false"
            style="width: 110px"
            v-on:keyup.enter="alert()"
          >
          </v-select>
        </span>
      </inline-edit>
    </td>
    <td>
      <inline-edit
        :doEdit="editField.vegur_upp_i"
        :showPencil="false"
        @editField="toggleEdit('vegur_upp_i')"
        @saveField="saveField('vegur_upp_i')"
        :canEdit="edit && canEdit && this.item.lokaeinkunn === 0"
      >
        <span slot="content" :class="{ 'p-1': !item.vegur_upp_i_heiti }"> {{ item.vegur_upp_i_heiti }}</span>
        <span slot="input">
          <v-select
            id="vegur_upp_i"
            name="vegur_upp_i"
            v-model="form.vegur_upp_i"
            :options="gradeTypeParents"
            label="heiti"
            :clearable="isGroup ? false : true"
            style="width: 200px"
          >
          </v-select>
          <Loader v-if="loading.gradeTypeParents" />
        </span>
      </inline-edit>
    </td>
    <td>
      <inline-edit
        :doEdit="editField.vaegi"
        :showPencil="false"
        @editField="toggleEdit('vaegi')"
        @saveField="saveField('vaegi')"
        :canEdit="edit && canEdit && item.lokaeinkunn === 0"
      >
        <span slot="content" :class="{ 'p-1': !item.vaegi }"> {{ item.vaegi }}<span v-if="item.vaegi">%</span> </span>
        <span slot="input">
          <b-form-input
            id="vaegi"
            name="vaegi"
            v-model="form.vaegi"
            v-on:keyup.enter="saveField('vaegi')"
            style="width: 60px"
            v-validate="{ regex: /^[0-9.,]*$/ }"
            :autofocus="true"
          />
        </span>
      </inline-edit>
    </td>
    <td>{{ item.vaegi_uppreiknad }}<span v-if="item.vaegi_uppreiknad">%</span></td>
    <td v-if="isModule">
      <inline-edit
        :doEdit="editField.skali"
        :showPencil="false"
        @editField="toggleEdit('skali')"
        @saveField="saveField('skali')"
        :canEdit="edit && canEdit"
      >
        <span slot="content" :class="{ 'p-1': !item.einkunnaskali }"> {{ item.einkunnaskali }}</span>
        <span slot="input">
          <v-select
            id="skali"
            name="skali"
            v-model="form.skali"
            :options="gradeScales"
            label="heiti"
            :clearable="false"
            style="width: 200px"
          >
          </v-select>
          <Loader v-if="loading.gradeScales" />
        </span>
      </inline-edit>
    </td>
    <td>
      <inline-edit
        :doEdit="editField.fj_bestu_undirpr"
        :showPencil="false"
        @editField="toggleEdit('fj_bestu_undirpr')"
        @saveField="saveField('fj_bestu_undirpr')"
        :canEdit="edit && canEdit"
      >
        <span slot="content" :class="{ 'p-1': !item.fj_bestu_undirpr }">
          {{ item.fj_bestu_undirpr }}
        </span>
        <span slot="input">
          <b-form-input
            id="fj_bestu_undirpr"
            name="fj_bestu_undirpr"
            type="number"
            min="1"
            v-model="form.fj_bestu_undirpr"
            v-on:keyup.enter="saveField('fj_bestu_undirpr')"
            style="width: 60px"
            :autofocus="true"
          />
        </span>
      </inline-edit>
    </td>
    <td v-if="isGroup">
      <inline-edit
        :doEdit="editField.lagmarks_einkunn"
        :showPencil="false"
        @editField="toggleEdit('lagmarks_einkunn')"
        @saveField="saveField('lagmarks_einkunn')"
        :canEdit="edit && canEdit"
      >
        <span slot="content" :class="{ 'p-1': !item.lagmarks_einkunn }">
          {{ item.lagmarks_einkunn }}
        </span>
        <span slot="input">
          <b-form-input
            v-model="form.lagmarks_einkunn"
            v-on:keyup.enter="saveField('lagmarks_einkunn')"
            id="lagmarks_einkunn"
            name="lagmarks_einkunn"
            style="width: 70px"
            v-validate="{ regex: /^[0-9.,]*$/ }"
            :autofocus="true"
          />
        </span>
      </inline-edit>
    </td>
    <td v-if="isModule">
      <inline-edit
        :doEdit="editField.fall_leyft"
        :showPencil="false"
        @editField="toggleEdit('fall_leyft')"
        @saveField="saveField('fall_leyft')"
        :canEdit="edit && canEdit"
      >
        <span slot="content" :class="{ 'p-1': !item.fall_leyft }">
          {{ item.fall_leyft === 1 ? 'Já' : '' }}
        </span>
        <span slot="input">
          <v-select id="fall_leyft" name="fall_leyft" v-model="form.fall_leyft" :options="yesNo" :clearable="false" style="width: 100px">
          </v-select>
        </span>
      </inline-edit>
    </td>
    <td>
      <inline-edit
        :doEdit="editField.lokaeinkunn"
        :showPencil="false"
        @editField="toggleEdit('lokaeinkunn')"
        @saveField="saveField('lokaeinkunn')"
        :canEdit="edit && canEdit && isModule && !finalGradeAlreadyUsed"
      >
        <span slot="content" :class="{ 'p-1': !item.lokaeinkunn }">
          {{ item.lokaeinkunn === 1 ? 'Já' : '' }}
        </span>
        <span slot="input">
          <v-select id="lokaeinkunn" name="lokaeinkunn" v-model="form.lokaeinkunn" :options="yesNo" :clearable="false" style="width: 100px">
          </v-select>
        </span>
      </inline-edit>
    </td>
    <td>
      <inline-edit
        :doEdit="editField.rod"
        :showPencil="false"
        @editField="toggleEdit('rod')"
        @saveField="saveField('rod')"
        :canEdit="edit && canEdit"
      >
        <span slot="content" :class="{ 'p-1': !item.rod }">
          {{ item.rod }}
        </span>
        <span slot="input">
          <b-form-input
            v-model="form.rod"
            v-on:keyup.enter="saveField('rod')"
            id="rod"
            name="rod"
            type="number"
            min="1"
            style="width: 60px; padding-left: 3px"
            :autofocus="true"
          />
        </span>
      </inline-edit>
    </td>
    <td v-if="isModule">
      <inline-edit
        :doEdit="editField.athugasemd"
        :showPencil="false"
        @editField="toggleEdit('athugasemd')"
        @saveField="saveField('athugasemd')"
        :canEdit="edit && canEdit"
      >
        <span slot="content" :class="{ 'p-1': !item.aths }">
          {{ item.aths }}
        </span>
        <span slot="input">
          <b-form-input
            v-model="form.athugasemd"
            v-on:keyup.enter="saveField('athugasemd')"
            style="width: 150px"
            id="athugasemd"
            name="athugasemd"
            maxLength="100"
            :autofocus="true"
          />
        </span>
      </inline-edit>
    </td>
    <td v-if="isModule">{{ item.aths_skrad_af_nafn }}</td>
    <td style="white-space: nowrap">
      <b-btn variant="primary" class="ml-2 mr-1" v-if="edit && canEdit" @click="openSlidebar(false)"><i class="fa fa-pencil"></i></b-btn>
      <b-btn
        variant="primary"
        v-if="edit && canEdit"
        @click="deleteGrade()"
        :disabled="hasSubgrades"
        :title="hasSubgrades ? 'Ekki má eyða einkunn sem aðrar einkunnir vega upp í. Eyð verður þeim fyrst.' : ''"
        ><i class="fa fa-trash"></i
      ></b-btn>
    </td>
  </tr>
</template>

<script>
import terms from '@/api/terms';
import types from '@/api/types';
import groups from '@/api/groups';
import { mapActions, mapGetters } from 'vuex';
import InlineEdit from '@/components/common/InlineEdit.vue';
import Loader from '@/components/common/Loader.vue';

export default {
  name: 'graderule-list-item',
  props: ['termModuleId', 'groupId', 'list', 'item', 'edit'],
  components: {
    InlineEdit,
    Loader,
  },
  computed: {
    canEdit() {
      return this.loggedInUserHasWritePermission('skolahald_annir');
    },
    isGroup() {
      return this.groupId !== undefined;
    },
    isModule() {
      return this.termModuleId !== undefined;
    },
    subgrades() {
      return this.list.filter(x => x.vegur_upp_i === (this.groupId ? this.item.einkunnir_hops_id : this.item.tegund_einkunnar_id));
    },
    hasSubgrades() {
      return this.subgrades.length > 0;
    },
    index() {
      return this.list.indexOf(this.item) + 1;
    },
    finalGradeAlreadyUsed() {
      const finalGrade = this.list.find(x => x.lokaeinkunn === 1);
      return !this.isGroup && finalGrade && finalGrade.einkunnir_afanga_id !== this.item.einkunnir_afanga_id;
    },
    ...mapGetters(['loggedInUserHasReadPermission', 'loggedInUserHasWritePermission', 'schoolHasSettings']),
  },
  data() {
    return {
      editing: false,
      loading: {
        gradeTypes: false,
        gradeTypeParents: false,
        gradeScales: false,
      },
      form: {
        tegund_einkunnar: null,
        heiti: null,
        reiknud_skrad: null,
        vegur_upp_i: null,
        vaegi: null,
        skali: null,
        fj_bestu_undirpr: null,
        fall_leyft: null,
        lokaeinkunn: null,
        rod: null,
        athugasemd: '',
        lagmarks_einkunn: null,
      },
      editField: {
        tegund_einkunnar: false,
        heiti: false,
        reiknud_skrad: false,
        vegur_upp_i: false,
        vaegi: false,
        skali: false,
        fj_bestu_undirpr: false,
        fall_leyft: false,
        lokaeinkunn: false,
        rod: false,
        athugasemd: false,
        lagmarks_einkunn: false,
      },
      yesNo: [
        { id: 1, label: 'Já' },
        { id: 0, label: 'Nei' },
      ],
      types: [
        { id: 0, label: 'Reiknuð' },
        { id: 1, label: 'Skráð' },
      ],
      gradeTypes: [],
      gradeTypeParents: [],
      gradeScales: [],
    };
  },

  methods: {
    getSubgradesWeight() {
      let subgrades = this.list.filter(x => x.vegur_upp_i === (this.groupId ? this.item.einkunnir_hops_id : this.item.tegund_einkunnar_id));
      if (this.item.fj_bestu_undirpr) {
        // Útaf einkunnareglu áfanga þarf að raða í báðar áttir eftir stærð til að
        // ganga úr skugga um að það séu örugglega ekki vitlausar prósentur
        subgrades = subgrades
          .sort((a, b) => {
            return b.vaegi - a.vaegi;
          })
          .filter((x, idx) => idx < this.item.fj_bestu_undirpr);
        // Ef stært í lítið er 100, athugum við hvort það sé líka í öfuga átt
        if (Number(Math.round(subgrades.map(x => x.vaegi).reduce((a, b) => a + b, 0)) === 100)) {
          subgrades = subgrades
            .sort((a, b) => {
              return a.vaegi - b.vaegi;
            })
            .filter((x, idx) => idx < this.item.fj_bestu_undirpr);
        }
      }
      const sum = subgrades.map(x => x.vaegi).reduce((a, b) => a + b, 0);
      return subgrades.length > 0 ? Number(Math.round(sum)) : 100;
    },
    subgradesInvalid() {
      return this.getSubgradesWeight() !== 100;
    },
    toggleEdit(item) {
      const isTrue = this.editField[item];
      Object.keys(this.editField).forEach(key => {
        this.editField[key] = false;
      });
      if (!isTrue) {
        this.editField[item] = true;
        this.initializeForEdit(item);
      }
    },
    initializeForEdit(item) {
      if (this.canEdit && this.edit) {
        if (this.isGroup) {
          this.form.heiti = this.item ? this.item.heiti : '';
          this.form.lagmarks_einkunn = this.item ? this.item.lagmarks_einkunn : null;
          this.form.lokaeinkunn = this.item ? this.item.lokaeinkunn : 0;
          this.form.reiknud_skrad = this.item ? this.types.find(x => x.id === this.item.reiknud_skrad) : this.types[0];
          this.form.vaegi = this.item ? this.item.vaegi : null;
          this.form.fj_bestu_undirpr = this.item ? this.item.fj_bestu_undirpr : null;
          this.form.rod = this.item ? this.item.rod : null;
          if (item === 'vegur_upp_i') {
            this.loadGradeTypesParents();
          }
        } else {
          this.form.reiknud_skrad = this.item ? this.types.find(x => x.id === this.item.reiknud_skrad) : this.types[0];
          this.form.vaegi = this.item ? this.item.vaegi : null;
          this.form.fj_bestu_undirpr = this.item ? this.item.fj_bestu_undirpr : null;
          this.form.fall_leyft = this.item ? this.yesNo.find(x => x.id === this.item.fall_leyft) : this.yesNo[1];
          this.form.lokaeinkunn = this.item ? this.yesNo.find(x => x.id === this.item.lokaeinkunn) : this.yesNo[1];
          this.form.rod = this.item ? this.item.rod : null;
          this.form.athugasemd = this.item ? this.item.aths : null;
          if (item === 'heiti') {
            this.loadGradeTypes();
          }
          if (item === 'vegur_upp_i') {
            this.loadGradeTypesParents();
          }
          if (item === 'skali') {
            this.loadGradeScales();
          }
        }
      }
    },
    async saveField(type) {
      const data = {
        ...this.item,
        reiknud_skrad: this.item.teg_einkunnar,
        tegund_einkunnar: this.item.tegund_einkunnar_id,
        einkunnaskali: this.item.tegund_skala_id,
        lagmarkseinkunn: this.item.lagmarks_einkunn,
      };
      switch (type) {
        case 'reiknud_skrad': // Reiknuð skráð
          if (this.isGroup) this.$set(data, 'teg_einkunnar', this.form.reiknud_skrad.id);
          else this.$set(data, 'reiknud_skrad', this.form.reiknud_skrad.id);
          break;
        case 'vaegi': // Vægi
          this.$set(data, 'vaegi', this.form.vaegi ? this.form.vaegi.toString().replace('.', ',') : null);
          break;
        case 'fj_bestu_undirpr': // Fj. bestu undirprófa
          this.$set(data, 'fj_bestu_undirpr', this.form.fj_bestu_undirpr);
          break;
        case 'fall_leyft': // Fall leyft
          this.$set(data, 'fall_leyft', this.form.fall_leyft.id);
          break;
        case 'lokaeinkunn': // Lokaeinkunn
          this.$set(data, 'lokaeinkunn', this.form.lokaeinkunn.id);
          break;
        case 'rod': // Röð
          this.$set(data, 'rod', this.form.rod ? this.form.rod.replace('.', ',') : '');
          break;
        case 'lagmarks_einkunn': // Lágmarkseinkunn
          this.$set(data, 'lagmarkseinkunn', this.form.lagmarks_einkunn ? this.form.lagmarks_einkunn.toString().replace('.', ',') : null);
          break;
        case 'athugasemd': // Athugasemd
          this.$set(data, 'aths', this.form.athugasemd);
          break;
        case 'heiti': // Heiti
          if (this.isGroup) this.$set(data, 'heiti', this.form.heiti);
          else this.$set(data, 'tegund_einkunnar', this.form.tegund_einkunnar.teg_eink);
          break;
        case 'vegur_upp_i': // Vegur upp í
          if (this.isGroup) this.$set(data, 'vegur_upp_i', this.form.vegur_upp_i ? this.form.vegur_upp_i.einkunnir_hops_id : null);
          else this.$set(data, 'vegur_upp_i', this.form.vegur_upp_i ? this.form.vegur_upp_i.teg_eink : null);
          break;
        case 'skali': // Skali
          this.$set(data, 'einkunnaskali', this.form.skali.teg_skala);
          break;
        default:
          break;
      }
      if (this.isGroup) {
        await groups.updateGroupGradeRule(this.groupId, this.item.einkunnir_hops_id, data);
      } else {
        await terms.updateModuleGraderule(this.termModuleId, this.item.einkunnir_afanga_id, data);
      }
      this.displaySuccess('Einkunnareglu hefur verið breytt.');
      this.$emit('reloadData');

      Object.keys(this.editField).forEach(key => {
        this.editField[key] = false;
      });
    },
    async loadGradeTypes() {
      try {
        this.loading.gradeTypes = true;
        const response = await types.getGradeList({
          tegund: 1,
          annirAfangarId: this.termModuleId,
          tegEink: this.item ? this.item.tegund_einkunnar_id : null,
        });
        this.gradeTypes = response.data.items;
        this.form.tegund_einkunnar = this.item ? this.gradeTypes.find(x => x.teg_eink === this.item.tegund_einkunnar_id) : null;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.gradeTypes = false;
      }
    },
    async loadGradeTypesParents() {
      try {
        this.loading.gradeTypeParents = true;
        if (this.isGroup) {
          const response = await groups.getGroupGradeRule(this.groupId);
          this.gradeTypeParents = response.data.items
            .filter(x => !this.subgrades.find(y => y.einkunnir_hops_id === x.einkunnir_hops_id))
            .filter(x => !this.item || x.einkunnir_hops_id !== this.item.einkunnir_hops_id);
          this.form.vegur_upp_i = this.item ? this.gradeTypeParents.find(x => x.einkunnir_hops_id === this.item.vegur_upp_i) : null;
        } else {
          const response = await types.getGradeList({
            vegurUppI: 1,
            annirAfangarId: this.termModuleId,
            einkunnirAfangaId: this.item ? this.item.einkunnir_afanga_id : null,
          });
          this.gradeTypeParents = response.data.items.filter(x => !this.subgrades.find(y => y.tegund_einkunnar_id === x.teg_eink));
          this.form.vegur_upp_i = this.item ? this.gradeTypeParents.find(x => x.teg_eink === this.item.vegur_upp_i) : null;
        }
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.gradeTypeParents = false;
      }
    },
    async loadGradeScales() {
      try {
        this.loading.gradeScales = true;
        const response = await types.getGradeScaleTypeList();
        this.gradeScales = response.data.items;
        this.form.skali = this.item ? this.gradeScales.find(x => x.teg_skala === this.item.tegund_skala_id) : null;
      } catch (e) {
        this.$log.error(e);
        this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
      } finally {
        this.loading.gradeScales = false;
      }
    },
    async deleteGrade() {
      const id = this.isGroup ? this.item.einkunnir_hops_id : this.item.einkunnir_afanga_id;
      const type = this.isGroup ? 'group' : 'module';
      const exists = await terms.getGraderuleGradesExists(id, type);
      let message = `Ert þú viss um að eyða ${this.item.heiti}?`;
      if (exists && exists.data.grade_exists === 1) {
        message = `${message} \nATH! Búið er að skrá einkunnir á eftirfarandi reglu.\nÞær einkunnir munu eyðast og ekki hægt að endurheimta!!`; // eslint-disable-line
      }

      const conf = confirm(message); // eslint-disable-line
      if (conf) {
        try {
          if (this.isGroup) {
            await groups.updateGroupGradeRule(this.groupId, this.item.einkunnir_hops_id, { delete: 1 });
          } else {
            await terms.updateModuleGraderule(this.termModuleId, this.item.einkunnir_afanga_id, { delete: 1 });
          }
          this.displaySuccess('Einkunnareglu hefur verið eytt.');

          this.$emit('reloadData');
        } catch (e) {
          // Aðgerð mistókst.
          this.failed = true;
          this.$log.debug(e);
          this.displayError(`Óvænt villa (${e.response ? e.response.status : -1}) kom upp. Vinsamlegast reyndu aftur.`);
        }
      }
    },
    openSlidebar(defaultAddGrades) {
      if (defaultAddGrades) {
        this.$emit('addGradesSlidebar');
      } else {
        this.$emit('editSlidebar');
      }
    },
    ...mapActions({
      displayError: 'displayError',
      displaySuccess: 'displaySuccess',
    }),
  },
};
</script>
<style lang="scss">
.grP1 {
  padding-left: 0px;
}
.grP2 {
  padding-left: 20px;
}
.grP3 {
  padding-left: 40px;
}
.grP4 {
  padding-left: 60px;
}
.grP5 {
  padding-left: 80px;
}
.grB1 {
  background-color: #fafafa; // #ededed;
}
.grB2 {
  background-color: #ededed; // #e0e0e0;
}
.grB3 {
  background-color: #e0e0e0; // #d3d3d3;
}
.grB4 {
  background-color: #d3d3d3; // #c7c7c7;
}
.grB5 {
  background-color: #c7c7c7; // #bababa;
}

.grade-rule-list-item {
  input {
    height: 25px;
    font-size: 13px;
  }
  .form-control-plaintext {
    padding: 0;
    color: inherit;
  }
  button {
    font-size: 11px;
    padding: 1px 4px;
  }
  .form-editable-buttons {
    float: none;
  }
  .v-select {
    background-color: #fff;
  }
}
.grade-rule-font-size {
  fieldset {
    p {
      font-size: 13px;
    }
  }
}
</style>
