import Vue from 'vue';

const API_URL = '/api/statistics';

export default {
  getElementaryStaff() {
    return Vue.http
      .get(`${API_URL}/elementary-staff`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getElementaryStudents() {
    return Vue.http
      .get(`${API_URL}/elementary-students`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getGradeModuleStatistics(params) {
    return Vue.http
      .get(`${API_URL}/grade-module-statistics`, { params })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },
  getGradeTaskStatistics(params) {
    return Vue.http
    .get(`${API_URL}/grade-task-stats`, { params })
    .then(response => Promise.resolve(response))
    .catch(error => Promise.reject(error));
  },
  getGradeTaskCounter(params) {
    return Vue.http
    .get(`${API_URL}/grade-task-counter`, { params })
    .then(response => Promise.resolve(response))
    .catch(error => Promise.reject(error));
  },
  getGradeTaskGraph(params) {
    return Vue.http
    .get(`${API_URL}/grade-task-graph`, { params })
    .then(response => Promise.resolve(response))
    .catch(error => Promise.reject(error));
  },
};
