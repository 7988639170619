<template>
  <div>
    <b-row>
      <b-col class="pl-0">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" @change="changePage" :hide-goto-end-buttons="true"> <!-- eslint-disable-line -->
          <template v-slot:prev-text>
            <i class="fa fa-angle-left"></i>
          </template>
          <template v-slot:next-text>
            <i class="fa fa-angle-right"></i>
          </template>
        </b-pagination>
      </b-col>
      <b-col>
        <div class="float-right">
          <select v-model.number="pageLimit" @change="changeLimit(pageLimit)" class="pagination-limit">
            <option v-for="(o, idx) in options" :key="idx" :value="o.value">
              {{ o.label }}
            </option>
            <!--option value="1">1 Færslur á síðu</option>
            <option value="5">5 Færslur á síðu</option>
            <option value="10">10 Færslur á síðu</option>
            <option value="25">25 Færslur á síðu</option>
            <option value="50">50 Færslur á síðu</option>
            <option value="100">100 Færslur á síðu</option-->
          </select>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'pagination',
  props: ['currentPage', 'totalRows', 'perPage'],
  data() {
    return {
      pageLimit: 0,
      options: [
        { value: 25, label: '25 færslur á síðu' },
        { value: 50, label: '50 færslur á síðu' },
        { value: 100, label: '100 færslur á síðu' },
        { value: 100000, label: 'Sýna allar' },
      ],
    };
  },
  methods: {
    verifyLimitExists() {
      if (this.options.filter(x => x.value === this.pageLimit).length === 0) {
        this.options.push({ value: this.pageLimit, label: `${this.pageLimit} færslur á síðu` });
        this.options.sort((a, b) => {
          return a.value - b.value;
        });
      }
    },
    changePage(page) {
      this.$emit('change-page', page);
    },
    changeLimit(limit) {
      this.$emit('change-limit', limit);
    },
  },
  created() {
    this.pageLimit = this.perPage;
    this.verifyLimitExists();
  },
};
</script>

<style lang="scss">
ul.pagination {
  li.page-item {
    margin-right: 5px;
    .page-link {
      &:focus {
        box-shadow: none;
      }
      height: 40px;
      width: 45px;
      line-height: 24px;
      text-align: center;
      border: 1px solid #e5e5e5;
      border-radius: 3px;
      box-sizing: border-box;
      background-color: #fff;
      color: #909090;
      font-size: 16px;
    }
    &.active {
      a {
        color: #303030;
      }
    }
  }
}

.pagination-limit {
  height: 40px;
  width: 170px;
  color: #303030;
  padding: 8px;
  border: 1px solid #e5e5e5;
}
</style>
